import React, { useState, useEffect } from 'react'
import { Stack, TextField } from '@mui/material'

import { Dialog } from '../dialogs/dialog'
import { Text } from '../text'
import { DateTime } from 'luxon'
import { color } from '../../theme'

export function ExtendAssignment(props: any) {
  const { open, onClose, onExtend, initialEndDate, contest } = props
  const [endDate, setEndDate] = useState('')
  const [endTime, setEndTime] = useState('')

  useEffect(() => {
    setEndTime(DateTime.now().toLocaleString(DateTime.TIME_24_SIMPLE))
  }, [])

  useEffect(() => {
    if (initialEndDate) {
      setEndDate(initialEndDate.toFormat('yyyy-MM-dd').toLocaleString())
      setEndTime(initialEndDate.toLocaleString(DateTime.TIME_24_SIMPLE))
    }
  }, [initialEndDate])

  const handleExtend = () => {
    onExtend({ endDateTime: `${endDate} ${endTime}` })
  }

  const handleClose = () => {
    onClose()
  }

  const selectedDate = DateTime.fromFormat(endDate, 'yyyy-MM-dd')
  const diff = selectedDate.diff(DateTime.local(), ['years', 'months', 'days', 'hours']).toMillis()

  return (
    <Dialog
      maxWidth="xs"
      onClose={handleClose}
      confirmBtnLabel={contest ? 'Extend Contest' : 'Extend Assignment'}
      title={contest ? 'Extend Contest' : 'Extend Assignment'}
      open={open}
      onConfirm={handleExtend}
      disableConfirm={diff < 0}
    >
      <Stack useFlexGap gap={1}>
        <Text
          variant="body1"
          text="End Date"
          style={{ color: diff < 0 ? color.palette.red : color.palette.black }}
        />
        <Stack direction="row" alignItems="center" useFlexGap gap={1} style={{ display: 'flex' }}>
          <TextField
            variant="outlined"
            helperText={diff < 0 ? 'Must be a future date. ' : null}
            error={diff < 0}
            size="small"
            type="date"
            {...{
              inputProps: {
                min: DateTime.local().plus({ days: 1 }).toFormat('yyyy-MM-dd'),
                max: DateTime.local().plus({ days: 730 }).toFormat('yyyy-MM-dd'),
              },
            }}
            onChange={(e) => {
              setEndDate(e.target.value)
            }}
            value={endDate ? endDate : DateTime.local().plus({ days: 1 }).toFormat('yyyy-MM-dd')}
          />
          <TextField
            type="time"
            size="small"
            variant="outlined"
            onChange={(e) => setEndTime(e.target.value)}
            value={endTime ? endTime : DateTime.now().toLocaleString(DateTime.TIME_24_SIMPLE)}
          />
          <Text variant="body1" text={Intl.DateTimeFormat().resolvedOptions().timeZone} />
        </Stack>
      </Stack>
    </Dialog>
  )
}
