import { types } from 'mobx-state-tree'

export const MessagePayload = types
  .model('MessagePayload')
  .props({
    subject: types.string,
    message_text: types.string,
    send_email: types.boolean,
    send_push: types.boolean,
    send_datetime: types.string,
    contest_ids: types.maybeNull(types.array(types.number)),
    group_ids: types.maybeNull(types.array(types.number)),
    user_ids: types.maybeNull(types.array(types.number)),
    message_type: types.enumeration(['generic', 'contest', 'nudge', 'survey_nudge']),
    survey_id: types.maybe(types.number),
    audience: types.maybe(
      types.model({
        audience: types.array(
          types.model({
            object_id: types.number,
            object_type: types.string,
          }),
        ),
      }),
    ),
  })
  .actions((self) => ({
    setSurveyId(id: number) {
      self.survey_id = id
    },
  }))
