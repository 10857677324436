import { ApiResponse, ApisauceInstance } from 'apisauce'
import { handleResponse } from '../handleResponse'

export const ReportsApi = {
  apisauce: {} as ApisauceInstance,

  /**
   * List all custom reports
   */
  getAllCustomReports: async () => {
    const response: ApiResponse<any> = await ReportsApi.apisauce.get(`/views/custom/`)
    return handleResponse(response)
  },

  /**
   * Get knowledge gain base stats
   */
  getKowledgeGainReport: async (start: string, end: string) => {
    const urlQuery = new URLSearchParams({
      start_datetime: start,
      end_datetime: end,
    })
    const response: ApiResponse<any> = await ReportsApi.apisauce.get(
      `/reports/knowledge_gain/?${urlQuery.toString()}`,
    )
    return handleResponse(response)
  },

  /**
   * Get knowledge gain stats history
   */
  getKnowledgeGainHistory: async (start: string, end: string) => {
    const urlQuery = new URLSearchParams({
      start_datetime: start,
      end_datetime: end,
    })

    const response: ApiResponse<any> = await ReportsApi.apisauce.get(
      `/reports/knowledge_gain_history/?${urlQuery.toString()}`,
    )
    return handleResponse(response)
  },
}
