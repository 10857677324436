import React from 'react'

import { Switch as MuiSwitch, SwitchProps as MuiSwitchProps } from '@mui/material'
import { observer } from 'mobx-react-lite'

interface SwitchProps extends MuiSwitchProps {
  variant?: 'default' | 'text'
  leftText?: string
  rightText?: string
}

export const Switch = observer((props: SwitchProps) => {
  const { color = 'primary', variant, size, ...rest } = props
  return <MuiSwitch disableFocusRipple size={size} color={color} {...rest} />
})
