import { ApiResponse, ApisauceInstance } from 'apisauce'
import { handleResponse } from '../handleResponse'
import {
  BadgeReadModelType,
  BagdeUsersReadModel,
  BadgeCreateModelType,
} from '../../models/base/badge'
import { SurveyGroup, SurveyUser } from '../../models/survey/SurveyUserGroup'
import { Instance } from 'mobx-state-tree'

export const BadgeApi = {
  apisauce: {} as ApisauceInstance,

  /**
   * List all badges
   */
  getAllBadges: async (
    type: 'users' | 'topics' | 'quizzes' | 'groups' | 'tags',
    meta?: boolean,
    columns?: any,
    view_name?: string,
    id?: number,
    filter?: 'custom' | 'trivie',
  ) => {
    const payload = { view_name }
    if (columns && columns.length > 0) {
      payload['columns'] = columns
    }

    const response: ApiResponse<any> = await BadgeApi.apisauce.post(
      `/badges/list/${filter}?meta=${meta}&asynchronous=${false}`,
      payload,
    )

    return handleResponse(response)
  },

  /**
   * List of Users for a Badge
   */
  async badgeUsers(badge_id: number) {
    const response: ApiResponse<BagdeUsersReadModel> = await BadgeApi.apisauce.get(
      `/badges/${badge_id}/users/`,
    )
    return handleResponse(response)
  },

  /**
   * Pre Check for Badge Deletion
   */
  async checkBadgeDeletion(badge_id: number) {
    const response: ApiResponse<Record<string, string>> = await BadgeApi.apisauce.get(
      `/badges/${badge_id}/check/`,
    )
    return handleResponse(response)
  },

  /**
   * Get a Badge
   */
  async getBadge(badge_id: number) {
    const response: ApiResponse<BadgeReadModelType> = await BadgeApi.apisauce.get(
      `/badges/${badge_id}/`,
    )
    return handleResponse(response)
  },

  /**
   * Delete a Badge
   */
  async deleteBadge(badge_id: number) {
    const response: ApiResponse<BadgeReadModelType> = await BadgeApi.apisauce.delete(
      `/badges/${badge_id}/`,
    )
    return handleResponse(response)
  },

  /**
   * Get Users/Groups available tobe awarded a badge
   */
  async getAvailableUsersForBadge(badge_id: number) {
    const response: ApiResponse<{
      groups: Instance<typeof SurveyGroup>[]
      users: Instance<typeof SurveyUser>[]
    }> = await BadgeApi.apisauce.get(`/badges/${badge_id}/award/`)
    return handleResponse(response)
  },

  /**
   * Badge Award to Users/Groups
   */
  async awardBadge(payload: { badge_id: number; user_ids: number[]; group_ids: number[] }) {
    const response: ApiResponse<BadgeReadModelType> = await BadgeApi.apisauce.post(
      `/badges/award/`,
      payload,
    )
    return handleResponse(response)
  },

  /**
   * Toggle Badge disabled
   */
  async toggleBadgeDisable(badge_id: number) {
    const response: ApiResponse<BadgeReadModelType[]> = await BadgeApi.apisauce.post(
      `/badges/${badge_id}/toggle_disabled/`,
    )
    return handleResponse(response)
  },

  /**
   * Create a Badge
   */
  async createBadge(payload: BadgeCreateModelType) {
    const formData = new FormData()
    if (payload.file) {
      formData.append('icon', payload.file)
      formData.append('description', payload?.description ?? '')
      formData.append('label', payload?.label ?? '')
      const response: ApiResponse<Record<string, never>> = await BadgeApi.apisauce.post(
        `/badges/create/`,
        formData,
      )
      return handleResponse(response)
    }
  },

  /**
   * Edit a Badge
   */
  async editBadge(badge_id: number, payload: BadgeCreateModelType) {
    const formData = new FormData()
    if (payload.file) {
      formData.append('icon', payload.file)
    }
    formData.append('description', payload?.description ?? '')
    formData.append('label', payload?.label ?? '')
    const response: ApiResponse<Record<string, string>> = await BadgeApi.apisauce.patch(
      `/badges/${badge_id}/`,
      formData,
    )
    return handleResponse(response)
  },

  /**
   * Revoke a Badge for User
   */
  async revokeBadge(payload: { badge_id: number; user_id: number }) {
    const response: ApiResponse<BadgeReadModelType[]> = await BadgeApi.apisauce.delete(
      `/badges/revoke/`,
      {},
      {
        data: payload,
      },
    )
    return handleResponse(response)
  },
}
