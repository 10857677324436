import { ApiResponse, ApisauceInstance } from 'apisauce'
import { Instance } from 'mobx-state-tree'
import { NewGroup, MemberCountPayload, NewGroupPayload } from '../../models/NewGroup'
import { GroupResponseModel } from '../../models/response-models/group'
import { UserGroup } from '../../models/UserGroup'
import { handleResponse } from '../handleResponse'

export const GroupApi = {
  apisauce: {} as ApisauceInstance,
  getAllGroups: async (
    type: 'users' | 'topics' | 'quizzes' | 'groups' | 'tags' | 'assignments',
    meta?: boolean,
    columns?: any,
    view_name?: string,
    id?: number,
  ) => {
    const payload = { view_name }
    if (columns && columns.length > 0) {
      payload['columns'] = columns
    }
    const response: ApiResponse<any> = await GroupApi.apisauce.post(
      Boolean(id)
        ? `/${type}/${id}/groups/?meta=${meta}&asynchronous=${false}`
        : `/${type}/?meta=${meta}&asynchronous=${false}`,
      payload,
    )
    return handleResponse(response)
  },
  getUserGroups: async (userId: number) => {
    const response: ApiResponse<Array<Instance<typeof UserGroup>>> = await GroupApi.apisauce.get(
      `/users/${userId}/groups/`,
    )

    return handleResponse(response)
  },

  createGroup: async (group: Instance<typeof NewGroupPayload>) => {
    const response: ApiResponse<Record<string, never>> = await GroupApi.apisauce.post(
      `/groups/create/`,
      group,
    )
    return handleResponse(response)
  },

  editGroup: async (group: Instance<typeof NewGroupPayload>, id: number) => {
    const response: ApiResponse<Record<string, never>> = await GroupApi.apisauce.patch(
      `/groups/${id}/`,
      group,
    )
    return handleResponse(response)
  },

  getRulesets: async (group_id: number) => {
    const response: ApiResponse<any> = await GroupApi.apisauce.get(`/groups/${group_id}/rulesets/`)
    return handleResponse(response)
  },

  getMemberCount: async (rulesets: Instance<typeof MemberCountPayload>) => {
    const response: ApiResponse<{ count: number }> = await GroupApi.apisauce.post(
      `/groups/member_count/`,
      rulesets,
    )
    return handleResponse(response)
  },
  getGroups: async () => {
    const response: ApiResponse<Array<Instance<typeof GroupResponseModel>>> =
      await GroupApi.apisauce.get(`/groups/`)

    return handleResponse(response)
  },
  getTotalUsers: async (groupIds: Array<number>) => {
    const urlQuery = new URLSearchParams(groupIds.map((id) => ['groups', String(id)]))

    const response: ApiResponse<{
      count: number
    }> = await GroupApi.apisauce.get(`/groups/total_users/?${urlQuery.toString()}`)

    return handleResponse(response)
  },
}
