import { Instance, types } from 'mobx-state-tree'

/**
 * ChoiceRead Model
 */
export const ChoiceReadModel = types.model('ChoiceRead').props({
  correct: types.boolean,
  id: types.identifierNumber,
  text: types.string,
  order: types.maybeNull(types.number),
})

/* The TypeScript type of an instance of ChoiceReadModel */
export interface ChoiceReadModelType extends Instance<typeof ChoiceReadModel.Type> {}
