import { types, getEnv, flow, getRoot, Instance, cast } from 'mobx-state-tree'
import { ResourceHelper } from '../../helpers/ResourceHelper'
import { ResourceApi } from '../../services/api-objects/ResourceApi'
import { Awaited } from '../../utility-types/Awaited'
import { Environment } from '../environment'
import { Resource } from '../Resource'

const wait = (ms) => new Promise((res) => setTimeout(res, ms))
export const ResourceStoreModel = types
  .model('ResourceStore')
  .props({
    availableResources: types.maybeNull(types.array(Resource)),
    status: types.optional(types.enumeration(['idle', 'pending', 'done', 'error']), 'idle'),
    quizResources: types.frozen(),
    startViewed: types.frozen(),
    endViewed: types.frozen(),
    resourceDetails: types.frozen(),
    viewedRequisite: types.frozen(),
    loadingResourcePost: types.frozen(false),
    requisite: types.frozen(),
    textContent: types.frozen(),
    textTitle: types.frozen(),
  })
  .actions((self) => ({
    setStatus(value?: 'idle' | 'pending' | 'done' | 'error') {
      self.status = value || 'idle'
    },
    setTextContent(content) {
      self.textContent = content
    },
    setTextTitle(title) {
      self.textTitle = title
    },
    setViewedRequisite(viewed: boolean) {
      self.viewedRequisite = viewed
    },
    setResourceDetails(details: any) {
      self.resourceDetails = details as any
    },
    setLoadingResourcePost(status: any) {
      self.loadingResourcePost = status
    },
    setAvailableResources(resources: any) {
      self.availableResources = resources
    },
    setAddResource(resource: Instance<typeof Resource>) {
      if (self.availableResources) {
        self.availableResources.push(resource)
      } else {
        self.availableResources = cast([resource])
      }
    },
    setAddResources(resources: Array<Instance<typeof Resource>>) {
      if (self.availableResources) {
        self.availableResources.replace(resources)
      } else {
        self.availableResources = cast(resources)
      }
    },
    setQuizResources(resources: any) {
      self.quizResources = resources
    },
    setStartViewed(timestamp: any) {
      self.startViewed = timestamp as any
    },
    setEndViewed(timestamp: any) {
      self.endViewed = timestamp as any
    },
    setRequisite(resource: typeof self.requisite) {
      self.requisite = { ...resource }
    },
  }))
  .views((self) => ({
    get environment() {
      return getEnv(self) as Environment
    },
    get isLoading() {
      return self.status === 'pending'
    },
  }))
  .actions((self) => ({
    uploadResource: flow(function* (resource: any) {
      self.setStatus('pending')
      try {
        const result = yield self.environment.api.uploadResource(resource)
        if (result.kind === 'ok') {
          self.setStatus('done')
        } else {
          self.setStatus('error')
        }
      } catch {
        self.setStatus('error')
      }
    }),
    uploadTextResource: flow(function* (text: string, title: string) {
      self.setStatus('pending')
      try {
        const result: Awaited<ReturnType<typeof ResourceApi.uploadTextResource>> =
          yield ResourceApi.uploadTextResource(text, title)
        if (result.ok) {
          self.setStatus('done')
          return result.data.resource_id
        } else {
          self.setStatus('error')
        }
      } catch {
        self.setStatus('error')
      }
    }),
    getAvailableResources: flow(function* () {
      self.setStatus('pending')
      try {
        const result: Awaited<ReturnType<typeof ResourceApi.getAvailableResources>> =
          yield ResourceApi.getAvailableResources()
        if (result.ok) {
          self.setStatus('done')
          self.setAvailableResources(ResourceHelper.fetchedResourcesToResources(result.data!))
          return ResourceHelper.fetchedResourcesToResources(result.data!)
        }
        self.setStatus('error')
      } catch {
        self.setStatus('error')
      }
    }),
    getResourceDetails: flow(function* (id: any) {
      self.setStatus('pending')
      try {
        const result = yield self.environment.api.getResourceDetails(id)
        if (result.kind === 'ok') {
          self.setStatus('done')
          self.setResourceDetails(result.data)
          return result.data
        }
        self.setStatus('error')
      } catch {
        self.setStatus('error')
      }
    }),
    markAsViewed: flow(function* (resource_id, metadata) {
      self.setStatus('pending')
      try {
        const { userStore } = getRoot(self) as any
        const result = yield self.environment.api.markAsViewed(
          userStore.user.id,
          resource_id,
          self.startViewed,
          self.endViewed,
          metadata,
        )
        if (result.kind === 'ok') {
          self.setAddResources(result.data)
          self.setStartViewed('')
          self.setEndViewed('')
          self.setStatus('done')
        } else {
          self.setStatus('error')
        }
      } catch {
        self.setStatus('error')
      }
    }),
    addResource: flow(function* (
      onUploadProgress: any,
      resourcePayload: {
        title: string
        url?: string
        file?: File
        file_size?: number
        mime_type?: string
        process?: boolean
      },
    ) {
      self.setStatus('pending')
      try {
        const result: Awaited<ReturnType<typeof ResourceApi.addResource>> =
          yield ResourceApi.addResource(onUploadProgress, resourcePayload)

        if (result.ok) {
          return result.data!
        } else {
          self.setStatus('error')
          return false
        }
      } catch (error) {
        self.setStatus('error')
        return false
      }
    }),
    updateAvailableResources: (resource: Instance<typeof Resource>) => {
      if (self.availableResources?.length) {
        self.availableResources.forEach((ar) => {
          if (ar.resource_id === resource.resource_id) {
            ar.set('title', resource.title)
          }
        })
      }
    },
  }))

type ResourceStoreType = typeof ResourceStoreModel.Type
export interface ResourceStore extends ResourceStoreType {}
type ResourceStoreSnapshotType = typeof ResourceStoreModel.SnapshotType
export interface ResourceStoreSnapshot extends ResourceStoreSnapshotType {}
