import React from 'react'
import { color } from '@trivie/ui-web/theme'
import { observer } from 'mobx-react-lite'
import { Instance } from 'mobx-state-tree'
import { Check } from '@mui/icons-material'
import { CircularProgress } from '@mui/material'

import { useStores } from '@trivie/core'
import { CreateMessageForm } from '@trivie/core/src/models/CreateMessage'
import { Text } from '@trivie/ui-web/components/text'

type ReviewMessageProps = {
  form: Instance<typeof CreateMessageForm>
}

export const ReviewMessage = observer((props: ReviewMessageProps) => {
  const { messageStore } = useStores()
  const { isLoading } = messageStore
  const { form } = props

  return isLoading ? (
    <div style={LOADER}>
      <CircularProgress />
    </div>
  ) : (
    <div style={CONTAINER}>
      <Text style={{ marginBottom: 15 }} text="Message" variant="body1" />
      <div style={SUBJECT}>
        <Text text={form.subject} variant="titleEmphasized" />
      </div>
      <div style={MESSAGE}>
        <Text text={form.message} variant="body1" />
      </div>
      <Text style={{ marginBottom: 20, color: color.shade70 }} text="Recipients" variant="body1" />
      {form.groups.map((group) => (
        <div style={GROUPS}>
          <div style={CHECK_ICON}>
            <Check htmlColor={color.white} style={{ fontSize: 16 }} />
          </div>
          <Text style={{ marginRight: 4 }} text={group.label || group.name} variant="body1Medium" />
          <Text
            style={{ color: color.shade30 }}
            text={String(group.number_users)}
            variant="body1Medium"
          />
        </div>
      ))}
      {form.users.map((user) => (
        <div style={USERS}>
          <div style={CHECK_ICON}>
            <Check htmlColor={color.white} style={{ fontSize: 16 }} />
          </div>
          <Text text={user.label || user.name} variant="body1Medium" />
        </div>
      ))}
      <Text style={{ marginBottom: 20, color: color.shade70 }} text="Send Via" variant="body1" />
      {form.email && (
        <div style={NTF_ROW}>
          <div style={CHECK_ICON}>
            <Check htmlColor={color.white} style={{ fontSize: 16 }} />
          </div>
          <Text text="Email" variant="body1Medium" />
        </div>
      )}
      {form.push && (
        <div style={NTF_ROW}>
          <div style={CHECK_ICON}>
            <Check htmlColor={color.white} style={{ fontSize: 16 }} />
          </div>
          <Text text="Push Notifications" variant="body1Medium" />
        </div>
      )}
      <Text style={{ marginBottom: 12, color: color.shade70 }} text="Send On" variant="body1" />
      <div style={{ flexDirection: 'row', display: 'flex', alignItems: 'center' }}>
        <Text style={{ marginRight: 4 }} text={`${form.displayDate}`} variant="body1Medium" />
        <Text
          text={`${form.displayTime} ${Intl.DateTimeFormat().resolvedOptions().timeZone}`}
          variant="body1Medium"
          style={{ color: color.shade50 }}
        />
      </div>
    </div>
  )
})

const CONTAINER: React.CSSProperties = {
  marginBottom: 12,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-start',
  overflowY: 'auto',
  overflowX: 'hidden',
  backgroundColor: '#F5F5F5',
  paddingTop: 23,
  height: '100%',
  borderRadius: 15,
  paddingLeft: 30,
  paddingRight: 30,
  paddingBottom: 30,
}
const CHECK_ICON: React.CSSProperties = {
  backgroundColor: color.green,
  height: 20,
  width: 20,
  position: 'relative',
  borderRadius: 100,
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  alignItems: 'center',
  marginRight: 11,
}
const SUBJECT: React.CSSProperties = {
  backgroundColor: color.white,
  height: 51,
  padding: 16,
  borderTopLeftRadius: 20,
  borderTopRightRadius: 20,
  marginBottom: 3,
}
const MESSAGE: React.CSSProperties = {
  backgroundColor: color.white,
  padding: 16,
  borderBottomLeftRadius: 20,
  borderBottomRightRadius: 20,
  marginBottom: 25,
}
const GROUPS: React.CSSProperties = {
  display: 'flex',
  alignItems: 'center',
  marginBottom: 20,
  flexDirection: 'row',
}
const NTF_ROW: React.CSSProperties = {
  display: 'flex',
  alignItems: 'center',
  marginBottom: 20,
  flexDirection: 'row',
}
const USERS: React.CSSProperties = {
  display: 'flex',
  alignItems: 'center',
  marginBottom: 20,
  flexDirection: 'row',
}
const LOADER: React.CSSProperties = {
  display: 'flex',
  flex: 1,
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
}
