import { ApiResponse, ApisauceInstance } from 'apisauce'
import { Instance } from 'mobx-state-tree'
import { Resource } from '../../models/Resource'
import {
  GetResource,
  GetResourceTopic,
  GetResourceQuiz,
} from '../../models/response-models/resource'
import { handleResponse } from '../handleResponse'
import { AxiosRequestConfig, CancelTokenSource } from 'axios'

export const ResourceApi = {
  apisauce: {} as ApisauceInstance,
  getAllResources: async (
    type: any,
    meta?: boolean,
    columns?: any,
    view_name?: string,
    // view_id?: number,
    id?: number,
  ) => {
    const payload = { view_name }
    if (columns && columns.length > 0) {
      payload['columns'] = columns
    }

    const response: ApiResponse<any> = await ResourceApi.apisauce.post(
      id
        ? `/${type}/${id}/resources/?meta=${meta}&asynchronous=${false}`
        : `/${type}/?meta=${meta}&asynchronous=${false}`,
      payload,
    )

    return handleResponse(response)
  },
  getAllResourcesOrSingleById: async (id?: number) => {
    const res = await ResourceApi.getAllResources(
      'resources',
      false,
      [
        { field: 'resource_title' },
        { field: 'resource_created_date' },
        { field: 'resource_filename_url' },
        { field: 'resource_file_size' },
        { field: 'resource_file_type' },
        { field: 'resource_view_count' },
        { field: 'resource_word_count' },
        { field: 'resource_topic_count' },
        { field: 'resource_quiz_count' },
        { field: 'resource_created_by' },
        { field: 'resource_last_edited_by' },
        { field: 'resource_last_edited_date' },
        { field: 'resource_unique_viewer_count' },
      ],
      'list_resources',
    )
    if (res.ok && res.data) {
      const rList = [] as any
      res.data?.rows.map((resource) => {
        const title = resource.columns.find((column) => column.field === 'resource_title')
        const created_at = resource.columns.find(
          (column) => column.field === 'resource_created_date',
        )
        const filename_or_url = resource.columns.find(
          (column) => column.field === 'resource_filename_url',
        )
        const file_size = resource.columns.find((column) => column.field === 'resource_file_size')
        const download_url = resource.columns.find((column) => column.field === 'download_url')
        const pdf_url = resource.columns.find((column) => column.field === 'pdf_url')
        const mime_type = resource.columns.find((column) => column.field === 'resource_file_type')
        const view_count = resource.columns.find((column) => column.field === 'resource_view_count')
        const topic_count = resource.columns.find(
          (column) => column.field === 'resource_topic_count',
        )
        const quiz_count = resource.columns.find((column) => column.field === 'resource_quiz_count')
        const created_by = resource.columns.find((column) => column.field === 'resource_created_by')
        const word_count = resource.columns.find((column) => column.field === 'resource_word_count')
        const unique_views = resource.columns.find(
          (column) => column.field === 'resource_unique_viewer_count',
        )
        const last_edited_by = resource.columns.find(
          (column) => column.field === 'resource_last_edited_by',
        )
        const last_edited_date = resource.columns.find(
          (column) => column.field === 'resource_last_edited_date',
        )

        const formattedResource = {
          pdf_url: pdf_url.value,
          download_url: download_url.value,
          resource_id: resource.id,
          created_at: created_at.value,
          title: title.value,
          filename_or_url: filename_or_url.value,
          file_size: file_size.value,
          mime_type: mime_type.value,
          extension: mime_type.value,
          view_count: view_count.value,
          topic_count: topic_count.value,
          quiz_count: quiz_count.value,
          word_count: word_count.value,
          created_by: created_by.value,
          last_edited_by: last_edited_by.value,
          last_edited_date: last_edited_date.value,
          unique_views: unique_views.value,
        }

        rList.push(formattedResource)
      })

      if (id) {
        const result = rList.find((f) => f.resource_id === id)
        return result
      }
      return rList
    } else {
      throw new Error('Error ocurred')
    }
  },
  getResourceStatus: async (id: number) => {
    const payload = {
      view_name: 'list_resources',
      columns: [{ field: 'resource_processing_status' }],
      primary_key: id,
    }

    const response: ApiResponse<any> = await ResourceApi.apisauce.post(
      `/resources/?meta=false&asynchronous=${false}`,
      payload,
    )
    const res = await handleResponse(response)
    if (res.ok && res.data?.rows?.length) {
      return res.data?.rows[0].columns.find((c) => c.field === 'resource_processing_status')?.value
    } else {
      return handleResponse(response)
    }
  },
  getAvailableResources: async (primary_key?: number) => {
    const payload = {
      view_name: 'list_resources',
      columns: [
        { field: 'resource_created_date' },
        { field: 'resource_title' },
        { field: 'resource_filename_url' },
        { field: 'resource_file_size' },
        { field: 'resource_file_type' },
        { field: 'resource_processing_status' },
      ],
      ...(primary_key && { primary_key }),
    }

    const response: ApiResponse<any> = await ResourceApi.apisauce.post(
      `/resources/?meta=false&asynchronous=${false}`,
      payload,
    )
    const res = await handleResponse(response)

    if (res.ok && res.data) {
      const rList = [] as any
      res.data?.rows.map((resource) => {
        const title = resource.columns.find((column) => column.field === 'resource_title')
        const created_at = resource.columns.find(
          (column) => column.field === 'resource_created_date',
        )
        const filename_or_url = resource.columns.find(
          (column) => column.field === 'resource_filename_url',
        )
        const file_size = resource.columns.find((column) => column.field === 'resource_file_size')
        const mime_type = resource.columns.find((column) => column.field === 'resource_file_type')
        const processing_status = resource.columns.find(
          (column) => column.field === 'resource_processing_status',
        )

        const formattedResource = {
          resource_id: resource.id,
          created_at: created_at.value,
          title: title.value,
          filename_or_url: filename_or_url.value,
          file_size: file_size.value,
          mime_type: mime_type.value,
          extension: mime_type.value,
          processing_status: processing_status.value,
        }
        rList.push(formattedResource)
      })
      return { ok: true, data: rList }
    } else {
      return handleResponse(response)
    }
  },
  generateQuestions: async (
    resource_id: number,
    count: number,
    keywords,
    audience: string,
    voice: string,
    difficulty: number,
    question_types: string[],
    node_ids: number[],
    summarize?: boolean,
  ) => {
    const response: ApiResponse<any> = await ResourceApi.apisauce.post(
      `/generate/questions/from_resource/`,
      {
        resource_id,
        count,
        keywords,
        question_types,
        audience: audience === '' ? null : audience,
        voice: voice === '' ? null : voice,
        difficulty,
        node_ids,
        summarize,
      },
    )
    return handleResponse(response)
  },
  addResource: async (
    onUploadProgress: (progressEvent: ProgressEvent) => void,
    resourcePayload: {
      title: string
      url?: string
      file?: File
      file_size?: number
      mime_type?: string
    },
    source?: CancelTokenSource,
  ) => {
    const formData: any = new FormData()

    formData.append('title', resourcePayload.title.slice(0, 127))

    if (resourcePayload.url) {
      formData.append('url', resourcePayload.url)
    } else {
      formData.append('resource', resourcePayload.file)
      formData.append('file_size', resourcePayload.file_size)
      formData.append('mime_type', resourcePayload.mime_type)
    }

    const options: AxiosRequestConfig = {
      onUploadProgress,
    }

    if (source) {
      options.cancelToken = source.token
    }

    if (resourcePayload.url) {
      const response: ApiResponse<{
        resource_id: number
      }> = await ResourceApi.apisauce.post(`/resources/create/url/`, formData, options)
      return handleResponse(response)
    } else {
      const response: ApiResponse<{
        resource_id: number
      }> = await ResourceApi.apisauce.post(`/resources/create/file/`, formData, options)
      return handleResponse(response)
    }
  },

  uploadTextResource: async (text, title) => {
    const response: ApiResponse<any> = await ResourceApi.apisauce.post(`/resources/create/text/`, {
      text,
      title,
    })
    return handleResponse(response)
  },

  updateResource: async (resource: Instance<typeof Resource>) => {
    const response: ApiResponse<null> = await ResourceApi.apisauce.patch(
      `/resources/${resource.resource_id}/`,
      {
        title: resource.title,
        is_locked: false,
        is_archived: false,
        is_deleted: false,
        alt_text: null,
        original_filename: resource.filename_or_url,
      },
    )

    return handleResponse(response)
  },
  getTopicsOfResource: async (resource_id: number) => {
    const response: ApiResponse<Array<Instance<typeof GetResourceTopic>>> =
      await ResourceApi.apisauce.get(`/resources/${resource_id}/topics/`)
    return handleResponse(response)
  },
  getQuizzesOfResource: async (resource_id: number) => {
    const response: ApiResponse<Array<Instance<typeof GetResourceQuiz>>> =
      await ResourceApi.apisauce.get(`/resources/${resource_id}/quizzes/`)
    return handleResponse(response)
  },
  addResourceToTopics: async (resource_id: number, topic_ids: Array<number>) => {
    const response: ApiResponse<any> = await ResourceApi.apisauce.post(
      `/resources/${resource_id}/add_topics/`,
      {
        topic_ids,
      },
    )
    return handleResponse(response)
  },
  addResourceToQuizzes: async (resource_id: number, quiz_ids: Array<number>, required: boolean) => {
    const response: ApiResponse<any> = await ResourceApi.apisauce.post(
      `/resources/${resource_id}/add_quizzes/`,
      {
        quiz_ids,
        required,
      },
    )
    return handleResponse(response)
  },
  getResourceDocument: async (resource_id) => {
    const response: ApiResponse<any> = await ResourceApi.apisauce.get(
      `/resources/${resource_id}/document/`,
    )
    return handleResponse(response)
  },
  getSummary: async (resource_id) => {
    const response: ApiResponse<any> = await ResourceApi.apisauce.get(
      `/resources/${resource_id}/summary/`,
    )
    return handleResponse(response)
  },
  updateSummary: async (resource_id: number, summary: string) => {
    const response: ApiResponse<null> = await ResourceApi.apisauce.patch(
      `resources/${resource_id}/summary/`,
      { summary },
    )

    return handleResponse(response)
  },
  getKeywords: async (resource_id) => {
    const response: ApiResponse<any> = await ResourceApi.apisauce.get(
      `/resources/${resource_id}/keywords/`,
    )
    return handleResponse(response)
  },
  checkSubject: async (subject: string) => {
    const response: ApiResponse<any> = await ResourceApi.apisauce.get(
      `/generate/check_subject/?subject=${subject}`,
    )
    return handleResponse(response)
  },
  findSources: async (subject: string) => {
    const response: ApiResponse<any> = await ResourceApi.apisauce.get(
      `/generate/sources/?subject=${subject}`,
    )
    return handleResponse(response)
  },

  processResource: async (resource_id: number) => {
    const response: ApiResponse<null> = await ResourceApi.apisauce.post(
      `/resources/${resource_id}/document/`,
    )

    // can't be processed or starts processing
    return handleResponse(response)
  },
}
