import React, { useState } from 'react'
import { observer } from 'mobx-react-lite'
import { BadgeTable } from '../../../../tables/badge-table'

import { TableHelper } from '../../../../tables'

const TrivieBadges: React.FunctionComponent = observer(() => {
  const [tableHelper] = useState(
    new TableHelper('list_trivie_badges', 'badges', 'badges', undefined, 'default'),
  )

  return <BadgeTable tableHelper={tableHelper} />
})

export const TrivieBadgesTab = () => {
  return {
    name: 'Trivie Badges',
    path: `/activities/badges/trivie`,
    component: () => <TrivieBadges />,
  }
}
