import { DateTime } from 'luxon'
import { getParent, types } from 'mobx-state-tree'
import { ContentMetadata } from '../UserQuiz'

export const NodeCondition = types
  .model('NodeCondition')
  .props({
    name: types.string,
    criteria: types.array(types.string),
  })
  .actions((self) => ({
    setCriteria(criteria: typeof self.criteria) {
      self.criteria = criteria
    },
  }))

export const Node = types
  .model('Node')
  .props({
    label: types.string,
    actions: types.array(types.string),
    required: types.boolean,
    object_id: types.maybe(types.number),
    order: types.number,
    conditions: types.array(NodeCondition),
    object_type: types.string,
    node_id: types.string,
    extension: types.maybe(types.string),
    warning: types.maybe(types.boolean),
    metadata: types.maybe(ContentMetadata),
    title: types.maybe(types.string),
    is_completed: types.maybe(types.boolean),
    unlocks_at: types.maybe(types.maybeNull(types.string)),
    is_start_node: types.maybe(types.boolean),
    is_terminal_node: types.maybe(types.boolean),
  })
  .actions((self) => ({
    setNodeId(id) {
      self.node_id = id
    },
    setStartNode(start: boolean) {
      self.is_start_node = start
    },
    setTerminalNode(terminal: boolean) {
      self.is_terminal_node = terminal
    },
    setLabel(label: string) {
      self.label = label
    },
    addAction(id: string) {
      self.actions.push(id)
    },
    setActions(actions: typeof self.actions) {
      self.actions.replace(actions)
    },
    setConditions(conditions: typeof self.conditions) {
      self.conditions.replace(conditions)
    },
    setObjectID(id: number) {
      self.object_id = id
    },
    setOrder(order: number) {
      self.order = order
    },
  }))
  .views((self) => ({
    get joinAction() {
      const parent = getParent(self, 2) as any
      const found = parent.actions.find(
        (action) => action.action_type === 'join' && action.from_id === self.node_id,
      )
      if (found) {
        return found
      }
    },
    get hasCert() {
      const parent = getParent(self, 2) as any
      const found = parent.actions.find((action) => action.action_type === 'award_certification')
      if (found) {
        return true
      }
      return false
    },
    get locked() {
      // if for some reason no content_user_id always mark as locked
      if (!self.metadata?.content_user_id) {
        return true
      }

      // if previous node is not complete, mark as locked
      const parent = getParent(self, 2) as any
      const currentIndex = parent.nodes.indexOf(self)
      const previousIndex = parent.nodes[currentIndex - 1]
      if (parent.nodes[previousIndex] && !parent.nodes[previousIndex].is_completed) {
        return true
      }

      // if there is an unlocks_at datetime not in the past, mark as locked
      if (self.unlocks_at) {
        const diff = DateTime.now()
          .diff(DateTime.fromISO(self.unlocks_at), 'milliseconds')
          .toObject()
        const { milliseconds } = diff
        return (milliseconds || 0) <= 0
      }

      // otherwise unlock
      return false
    },
    get unlockDisplay() {
      return `Unlocks ${DateTime.fromISO(self.unlocks_at || '').toRelative()}`
    },
    get steps() {
      const parent = getParent(self, 2) as any

      const actions = [] as any
      const conditions = [] as any

      self.conditions.map((condition) => {
        condition.criteria.map((cr) => {
          const found = parent.conditions.find((c) => c.reference_id === cr)
          if (found) {
            conditions.push(found)
          }
        })
      })
      self.actions.map((id) => {
        const found = parent.actions.find((a) => a.reference_id === id)
        if (found && found.action_type !== 'join') {
          actions.push(found)
        }
      })

      return [...conditions, ...actions]
    },
  }))
