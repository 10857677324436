import { observer } from 'mobx-react-lite'
import { Add, ArrowRight, LocalOffer, LocalOfferOutlined } from '@mui/icons-material'
import React, { useEffect, useRef, useState } from 'react'
import { ListItem } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { InputBase } from '@mui/material'
import styles from './tag-selector.module.css'
import { color } from '../../theme'
import { FullTag } from '../full-tag'
import { Text } from '../text'
import { FixedSizeList } from 'react-window'
import AutoSizer from 'react-virtualized-auto-sizer'
import { CreateTag } from '../create-tag/create-tag'

export interface TagSelectorProps {
  selectedTags: any
  onChange: (tags: any) => void
  tags?: any
  formattedTags: any
  singular?: boolean
  remove?: boolean
  tagListStyle?: React.CSSProperties
  allowCreateNew?: boolean
  onCreateNew?: any
  open?: boolean
}

const useStyles = makeStyles((theme) => ({
  inputWrapper: {
    maxHeight: 100,
    overflowY: 'auto',
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'center',
    borderStyle: 'solid',
    borderWidth: 1,
    borderColor: color.palette.paperBorder,
    borderRadius: 6,
    backgroundColor: color.palette.white,
    paddingLeft: 13,
    paddingRight: 13,
    flex: 1,
    // transition: theme.transitions.create(['outline', 'background-color', 'box-shadow']),
    '&:hover': {
      borderColor: 'rgba(223,226,249,1)',
    },
    '&:focus-within': {
      borderColor: 'rgba(27,89,248,1)',
    },
  },
}))

export const TagSelector = observer((props: TagSelectorProps) => {
  const [showPopper, setShowPopper] = useState(false)
  const {
    allowCreateNew,
    onCreateNew,
    remove,
    tagListStyle,
    selectedTags = [],
    formattedTags,
    open,
    onChange,
  } = props
  const [hoveredKey, setHoveredKey] = useState<undefined | string>(undefined)
  const [showCreateTag, setShowCreateTag] = useState(false)
  const [tagFilterText, setTagFilterText] = useState<string | undefined>()
  const classes = useStyles()
  const selectorRef = useRef(null)

  const handleBlur = (e: any) => {
    if (!e.composedPath().some((el: any) => el === selectorRef.current)) {
      setShowPopper(false)
    }
  }

  useEffect(() => {
    // runFilter()

    window.addEventListener('click', handleBlur)

    return () => {
      window.removeEventListener('click', handleBlur)
    }
  }, [])

  const handleTagAdd = (tag: any) => {
    setTagFilterText(undefined)
    onChange([...selectedTags, tag])
  }

  const handleTagRemove = (tagId: any) => {
    onChange(
      selectedTags.filter((t: any) => (t.id && t.id !== tagId) || (t.tag_id && t.tag_id !== tagId)),
    )
  }

  const handleTagsFilterChange = (e: any) => {
    setTagFilterText(e.target.value)
  }

  const handleFocus = () => {
    setShowPopper(true)
  }

  const TagValue = ({ index, style, data }) => {
    return (
      <div style={{ ...style }}>
        <FullTag
          tag={data[index]}
          onAdd={handleTagAdd}
          style={{ height: 29, backgroundColor: color.palette.paperBorder }}
        />
      </div>
    )
  }

  const tagValues = hoveredKey
    ? formattedTags[hoveredKey].values
        .filter((f: any) => !selectedTags.map((m: any) => m.id).includes(f.id))
        .filter((f: any) => {
          if (tagFilterText) {
            return f.value.toLowerCase().includes(tagFilterText.toLowerCase())
          }
          return true
        })
        .sort((a: any, b: any) => {
          if (a.value > b.value) {
            return 1
          } else if (b.value > a.value) {
            return -1
          } else {
            return 0
          }
        })
    : []

  return (
    <div style={{ position: 'relative', background: '#fff' }} ref={selectorRef}>
      <div onFocus={handleFocus} className={classes.inputWrapper}>
        {remove ? (
          <LocalOfferOutlined htmlColor={color.palette.grey} />
        ) : (
          <LocalOffer htmlColor={color.palette.grey} />
        )}
        {selectedTags?.map((tag: any) => {
          return (
            <FullTag
              tag={tag}
              onDelete={handleTagRemove}
              style={{
                height: 29,
                marginLeft: 12,
                marginBottom: 6,
                marginTop: 6,
              }}
            />
          )
        })}
        <InputBase
          value={tagFilterText || ''}
          placeholder={remove ? 'Remove Tag' : 'Add tag'}
          style={{ height: 29, marginLeft: 12, marginTop: 6, marginBottom: 6, flex: 1 }}
          onChange={handleTagsFilterChange}
        />
      </div>

      {allowCreateNew && (showPopper || showCreateTag || open) && (
        <ListItem
          key="new"
          onClick={(e) => {
            setShowCreateTag(true)
          }}
          sx={{
            ...ROW,
            ':hover': { backgroundColor: 'rgba(0, 0, 0, 0.04)' },
            border: `1px solid rgb(239, 240, 246)`,
            borderRadius: '6px',
            borderBottom: 'none',
            borderBottomLeftRadius: 0,
            borderBottomRightRadius: 0,
          }}
        >
          <Add style={{ right: 4, position: 'relative' }} />
          <Text style={{ right: 8, position: 'relative' }} variant="body1" text="Create New" />
        </ListItem>
      )}
      {showCreateTag && (
        <CreateTag
          onCreate={onCreateNew}
          open={showCreateTag}
          onClose={() => setShowCreateTag(false)}
        />
      )}
      <div
        style={{
          ...CONTAINER,
          visibility: showPopper || showCreateTag || open ? 'visible' : 'hidden',
        }}
      >
        <div style={{ ...TAG_LIST, ...tagListStyle }}>
          {Object.keys(formattedTags).map((key, index) => (
            <div
              key={key}
              className={styles.key}
              onMouseEnter={() => setHoveredKey(key)}
              style={{
                ...TAG,
                backgroundColor: hoveredKey === key ? color.palette.blueGrey : 'initial',
              }}
            >
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  flex: 1,
                  paddingLeft: 14,
                  height: 45,
                }}
              >
                <LocalOfferOutlined htmlColor={color.palette.grey} />
                <Text variant="body1" text={key} style={{ marginLeft: 8, marginRight: 8 }} />
              </div>
              <ArrowRight htmlColor={color.palette.grey} />
            </div>
          ))}
        </div>

        <div style={HOVER_CONTAINER}>
          {hoveredKey && formattedTags && formattedTags[hoveredKey] ? (
            <>
              <div style={{ marginBottom: 16 }}>
                <Text
                  variant="body2Emphasized"
                  text={hoveredKey ?? ''}
                  style={{ color: color.palette.grey, marginBottom: 17 }}
                />
              </div>
              <div style={{ display: 'flex', height: '100%' }}>
                <AutoSizer style={{ width: '100%' }}>
                  {({ height, width }) => {
                    return (
                      <FixedSizeList
                        itemData={tagValues}
                        height={height - 32}
                        itemCount={tagValues?.length}
                        itemSize={40}
                        width={width}
                      >
                        {TagValue}
                      </FixedSizeList>
                    )
                  }}
                </AutoSizer>
              </div>
            </>
          ) : null}
        </div>
      </div>
    </div>
  )
})

const CONTAINER: React.CSSProperties = {
  zIndex: 999,
  width: '100%',
  position: 'absolute',
  display: 'flex',
  border: `1px solid ${color.palette.paperBorder}`,
  borderRadius: '6px',
  backgroundColor: color.white,
  borderTop: 'none',
  borderTopLeftRadius: '0px',
  borderTopRightRadius: '0px',
}
const HOVER_CONTAINER: React.CSSProperties = {
  zIndex: 999,
  paddingLeft: 16,
  paddingTop: 12,
  borderLeftWidth: 1,
  borderLeftStyle: 'solid',
  borderLeftColor: color.palette.paperBorder,
  overflowY: 'hidden',
  height: 420,
  width: '100%',
}
const TAG_LIST: React.CSSProperties = {
  display: 'flex',
  minWidth: 280,
  flexDirection: 'column',
  height: 420,
  overflowY: 'auto',
}
const TAG: React.CSSProperties = {
  display: 'flex',
  alignItems: 'center',
  width: '100%',
  height: 45,
}
const ROW: React.CSSProperties = {
  cursor: 'pointer',
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  gap: '16px',
  padding: '12px !important',
  minHeight: '48px !important',
}
