import { types } from 'mobx-state-tree'
import { ReasonReadModel } from './ReasonReadModel'
import { UserShortSchemaModel } from '../user/UserShortSchemaModel'

/**
 * Typescript enum
 */
export enum ReportableClass {
  COMMENT = 'COMMENT',
  POST = 'POST',
}

/**
 * ReportableClass
 */
export const ReportableClassEnum = types.enumeration('ReportableClass', ['COMMENT', 'POST'])

/**
 * ReportRead
 */
export const ReportReadModel = types.model('ReportRead').props({
  __typename: types.optional(types.literal('ReportRead'), 'ReportRead'),
  id: types.integer,
  objectId: types.integer,
  /** An enumeration. */
  objectType: ReportableClassEnum,
  reason: types.late(() => ReasonReadModel),
  reporter: types.late(() => UserShortSchemaModel),
})
