import { types } from 'mobx-state-tree'
import { Workflow } from './Workflow'

export const Path = types
  .model({
    name: types.string,
    description: types.string,
    end_datetime: types.maybeNull(types.string),
    image_url: types.maybeNull(types.string),
    workflow_json: Workflow,
    path_id: types.maybe(types.number),
    duplicate_name: types.maybe(types.boolean),
    duplicated: types.maybe(types.boolean),
  })
  .volatile(() => ({
    imageFile: null as File | null,
  }))
  .actions((self) => ({
    setDuplicated(dupe) {
      self.duplicated = dupe
    },
    setDuplicateName(status) {
      self.duplicate_name = status as any
    },
    setImage(file: File | null) {
      self.image_url = null
      self.imageFile = file
    },
    setName(name: string) {
      self.name = name
    },
    setDescription(description: string) {
      self.description = description
    },
    setPathID(id: number) {
      self.path_id = id
    },
  }))
  .views((self) => ({
    get valid() {
      return !self.duplicate_name && self.workflow_json && self.workflow_json.valid && self.name
    },
    get image() {
      if (self.imageFile) {
        return URL.createObjectURL(self.imageFile)
      } else if (self.image_url) {
        return self.image_url
      } else {
        return null
      }
    },
  }))

export const UserPath = types.model({
  path_id: types.number,
  name: types.string,
  description: types.maybe(types.string),
  image_url: types.maybe(types.string),
  end_datetime: types.maybeNull(types.string),
  node_count: types.number,
  nodes_completed: types.number,
  assignment_id: types.number,
})
