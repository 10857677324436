import { types } from 'mobx-state-tree'
import { NodeCondition } from './Node'

export const Action = types
  .model('Action')
  .props({
    stub: types.maybe(types.boolean),
    action_type: types.string,
    is_completed: types.maybe(types.boolean),
    description: types.maybe(types.maybeNull(types.string)),
    force_evaluate: types.boolean,
    label: types.string,
    reference_id: types.string,
    object_id: types.maybe(types.number),
    user_visible: types.maybe(types.boolean),
    conditions: types.maybeNull(types.array(NodeCondition)),
    tag_ids: types.maybe(types.array(types.number)),
    recipients: types.maybe(types.array(types.number)),
    include_user: types.maybe(types.boolean),
    subject: types.maybe(types.string),
    body: types.maybe(types.string),
    template_name: types.maybe(types.maybeNull(types.string)),
    assignment: types.frozen(),
    group: types.frozen(),
    badge: types.frozen(),
    tags: types.frozen(),
    points: types.frozen(),
    wait_until_datetime: types.frozen(),
    minutes: types.frozen(),
    warning: types.maybe(types.boolean),
    from_id: types.maybe(types.string),
    to_id: types.maybe(types.string),
  })
  .actions((self) => ({
    setReferenceId(id: string) {
      self.reference_id = id
    },
    setToID(id: string) {
      self.to_id = id
    },
    setFromID(id: string) {
      self.from_id = id
    },
    setObjectID(id: number) {
      self.object_id = id
    },
    setStub(stub: boolean) {
      self.stub = stub
    },
    setActionType(action_type: string) {
      self.action_type = action_type
    },
    setLabel(label: string) {
      self.label = label
    },
    setForceEvaluate(force_evaluate: boolean) {
      self.force_evaluate = force_evaluate
    },
    setDescription(description: string) {
      self.description = description
    },
    setBadge(badge: any) {
      self.badge = badge
    },
    setUserVisible(visible: boolean) {
      self.user_visible = visible
    },
    setAssignment(assignment: any) {
      self.assignment = assignment
    },
    setGroup(group: any) {
      self.group = group
    },
    setTags(tags: any) {
      self.tags = tags
    },
    setTagIDs(ids: any) {
      self.tag_ids = ids
    },
    setPoints(points: number) {
      self.points = points
    },
    setWaitUntil(datetime: string) {
      self.wait_until_datetime = datetime
    },
    setDelay(minutes: number) {
      self.minutes = minutes
    },
  }))
