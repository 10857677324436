import { types } from 'mobx-state-tree'

export const NotificationCheck = types.model('NotificationCheck').props({
  awarded_at: types.maybeNull(types.string),
  badge_icon_url: types.maybeNull(types.string),
  label: types.maybeNull(types.string),
  notification_type: types.string,
  description: types.maybeNull(types.string),
  current_icon_url: types.maybeNull(types.string),
  previous_icon_url: types.maybeNull(types.string),
  min_points: types.maybeNull(types.number),
  current_points: types.maybeNull(types.number),
  level: types.maybeNull(types.string),
  medal_level: types.maybeNull(types.string),
  created_at: types.maybeNull(types.string),
  notification_text: types.maybeNull(types.string),
  highest_level: types.maybeNull(types.string),
  threshold: types.maybeNull(types.number),
  highest_threshold: types.maybeNull(types.number),
  dark: types.maybe(types.boolean),
  hide_celebration: types.maybe(types.boolean),
  locked: types.maybe(types.boolean),
})
