import { IMSTArray, Instance } from 'mobx-state-tree'
import {
  GroupRuleset,
  GroupRulesetPayload,
  MemberCountPayload,
  NewGroup,
  NewGroupPayload,
} from '../models/NewGroup'

let rootStore
export class GroupHelper {
  static setRootStore = (store: any) => {
    rootStore = store
  }

  static createNewGroup = () => {
    return NewGroup.create({
      label: '',
      is_locked: false,
      rulesets: [{ method: 'all', tag_rules: [] }],
    })
  }

  static toPayload = (group: Instance<typeof NewGroup>) => {
    return NewGroupPayload.create({
      label: group.label,
      is_locked: false,
      proposed_rulesets: group.rulesets.map((ruleset) => {
        return GroupRulesetPayload.create({
          tag_ids: ruleset.tag_rules.map((tag) => tag.id),
          method: ruleset.method,
        })
      }),
    })
  }

  static memberCountPayload = (rulesets: IMSTArray<typeof GroupRuleset>) => {
    return MemberCountPayload.create({
      rulesets: rulesets.map((ruleset) => {
        return {
          tag_ids: ruleset.tag_rules.map((rule) => rule.id),
          method: ruleset.method,
        }
      }),
    })
  }
}
