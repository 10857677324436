import React, { useState } from 'react'
import { observer } from 'mobx-react-lite'
import { useRouteMatch } from 'react-router-dom'

import { TableHelper } from '../../../../tables'
import { TopicTable } from '../../../../tables/topic-table'

const Topics = observer(() => {
  const match = useRouteMatch<any>('/content/paths/:id/topics')
  const pathID = Number(match?.params?.id)

  const [tableHelper] = useState(new TableHelper('list_path_topics', 'topics', 'paths', pathID))

  return <TopicTable tableHelper={tableHelper} />
})

export const PathTopicsTab = (props: any) => {
  const { id } = props
  return {
    name: 'Topics',
    path: `/content/paths/${id}/topics`,
    component: () => <Topics />,
  }
}
