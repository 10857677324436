import { types } from 'mobx-state-tree'

export const ToastStoreModel = types
  .model('ToastStore')
  .props({
    open: types.frozen(),
    toast: types.frozen(),
  })
  .actions((self) => ({
    show(config) {
      self.open = true
      self.toast = config
    },
    dismiss() {
      self.open = false
    },
    clear() {
      self.toast = undefined
    },
  }))
