import React from 'react'
import { VictoryBar, VictoryAxis, VictoryChart } from 'victory'
import { CircularProgress } from '@mui/material'
import { Text } from '../text'
import { color } from '../../theme/color'

export const SimpleBarChart = (props: any) => {
  const { data, barStyle, tickValues, loading = false } = props

  if (loading) {
    return (
      <div style={{ display: 'flex', justifyContent: 'center', flexDirection: 'column' }}>
        <Text
          variant="body1Medium"
          text="Waiting for data..."
          style={{ marginTop: 32, marginBottom: 50, textAlign: 'center' }}
        />
        <CircularProgress style={{ alignSelf: 'center' }} />
      </div>
    )
  }
  return (
    <VictoryChart>
      <VictoryBar
        style={{
          data: {
            fill: ({ datum, index }) => {
              return index === 0
                ? '#1B59F840'
                : index === 1
                  ? '#1B59F850'
                  : index === 2
                    ? '#1B59F860'
                    : index === 3
                      ? '#1B59F870'
                      : index === 4
                        ? '#1B59F870'
                        : index === 5
                          ? '#1B59F8'
                          : '#000'
            },
            fillOpacity: 1.0,
            width: 40,
          },
          ...barStyle,
        }}
        cornerRadius={{ topLeft: 12, topRight: 12, bottomLeft: 12, bottomRight: 12 }}
        data={data}
      />
      <VictoryAxis
        tickValues={tickValues}
        style={{
          axis: { stroke: 'transparent' },
          tickLabels: {
            fontFamily: 'Inter',
            fontSize: 14,
            fontWeight: 500,
            color: color.shade70,
          },
        }}
      />
    </VictoryChart>
  )
}
