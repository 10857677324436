import { types } from 'mobx-state-tree'

/**
 * Typescript enum
 */

export enum LikeableClass {
  COMMENT = 'Comment',
  POST = 'Post',
}

/**
 * LikeableClass
 */
export const LikeableClassEnum = types.enumeration('LikeableClass', ['Comment', 'Post'])
