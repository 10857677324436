import React from 'react'

// @ts-ignore
import { ReactComponent as InsightsLogo } from '../../routing/logo-long.svg'

// @ts-ignore
import { ReactComponent as QuantumLogo } from './quantum-logo.svg'

export const Logo = (props: any) => {
  const { style, quantum } = props
  return quantum ? (
    <QuantumLogo style={{ ...style }} width={148.9} height={32} />
  ) : (
    <InsightsLogo style={{ ...style }} width={148.9} height={32} />
  )
}
