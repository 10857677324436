import React from 'react'
import { IconButton, IconButtonProps, styled } from '@mui/material'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'

interface ExpandMoreProps extends IconButtonProps {
  expand: boolean
}

export const ExpandMore = styled((props: ExpandMoreProps) => {
  const { expand, ...other } = props
  return (
    <IconButton aria-expanded={expand} aria-label={!expand ? 'show more' : 'show less'} {...other}>
      <ExpandMoreIcon />
    </IconButton>
  )
})(({ theme, expand }: { theme: any; expand: boolean }) => ({
  transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
  marginLeft: 'auto',
  transition: theme.transitions.create('transform', {
    duration: theme.transitions.duration.shortest,
  }),
}))
