import React, { useEffect, useRef, useState } from 'react'
import { observer } from 'mobx-react-lite'
import { InputBase } from '@mui/material'
import { color } from '../../theme'
import { Text } from '../text'
// import { BoltRounded } from '../../../mobile/assets/icons/BoltRounded'

export interface QuizSelectorProps {
  selectedQuizzes: any
  quizzes: any
  onChange: (quizzes: any) => void
}

export const QuizSelector = observer((props: QuizSelectorProps) => {
  const { selectedQuizzes, onChange, quizzes } = props
  const [showPopper, setShowPopper] = useState(false)
  const [quizzesFilter, setQuizzesFilter] = useState('')
  const [filteredQuizzes, setFilteredQuizzes] = useState<Array<any>>([])

  const selectorRef = useRef(null)
  const inputRef = useRef(null)

  const handleBlur = (e?: any) => {
    if (!e || !e.composedPath().some((el: any) => el === selectorRef.current)) {
      setShowPopper(false)
    }
  }

  const handleFocus = () => {
    setShowPopper(true)
  }

  useEffect(() => {
    window.addEventListener('click', handleBlur)

    return () => {
      window.removeEventListener('click', handleBlur)
    }
  }, [])

  const handleQuizzesFilterChange = (e: any) => {
    setQuizzesFilter(e.target.value)
  }

  useEffect(() => {
    const filtered: Array<any> = []

    quizzes.forEach((q: any) => {
      const massagedQuizTitle = (q.quiz_title as string).replaceAll(' ', '').toLocaleLowerCase()
      const massagedQuizzesFilter = quizzesFilter?.replaceAll(' ', '').toLocaleLowerCase() ?? ''
      let shouldInclude = true

      // if the quiz title and the related topics don't match the filter then don't include
      if (!massagedQuizTitle.includes(massagedQuizzesFilter)) {
        shouldInclude = false
      }

      // if this quiz is already selected then filter out this quiz
      if (selectedQuizzes?.some((selectedQuiz: any) => selectedQuiz.quiz_id === q.quiz_id)) {
        shouldInclude = false
      }

      if (shouldInclude) {
        filtered.push(q)
      }
    })

    setFilteredQuizzes(filtered)
  }, [selectedQuizzes, quizzesFilter])

  return (
    <div style={{ position: 'relative' }} ref={selectorRef}>
      <div
        onFocus={handleFocus}
        style={{
          display: 'flex',
          flexWrap: 'wrap',
          alignItems: 'center',
          borderStyle: 'solid',
          borderWidth: 1,
          borderColor: showPopper ? color.palette.blueLink : color.palette.paperBorder,
          borderRadius: 6,
          backgroundColor: color.palette.white,
          paddingLeft: 13,
          paddingRight: 13,
        }}
      >
        <svg width={20} height={20} fill="rgba(0, 0, 0, 0.5)" viewBox="0 0 24 24">
          <path d="M10.67 21c-.35 0-.62-.31-.57-.66L11 14H7.5c-.88 0-.33-.75-.31-.78 1.26-2.23 3.15-5.53 5.65-9.93.1-.18.3-.29.5-.29.35 0 .62.31.57.66l-.9 6.34h3.51c.4 0 .62.19.4.66-3.29 5.74-5.2 9.09-5.75 10.05-.1.18-.29.29-.5.29z"></path>
        </svg>
        <InputBase
          ref={inputRef}
          value={quizzesFilter}
          placeholder="Search for a Quiz"
          style={{ height: 29, marginLeft: 12, marginTop: 6, marginBottom: 6 }}
          onChange={handleQuizzesFilterChange}
        />
      </div>

      {!!filteredQuizzes.length && (
        <div
          style={{
            visibility: showPopper ? 'visible' : 'hidden',
            height: 360,
            width: '100%',
            display: 'flex',
            flexDirection: 'column',
            border: `1px solid ${color.palette.paperBorder}`,
            borderRadius: 6,
            position: 'absolute',
            paddingTop: 4,
            paddingBottom: 4,
            backgroundColor: color.white,
            marginTop: 4,
            zIndex: 99,
            overflowY: 'auto',
          }}
        >
          {filteredQuizzes.map((quiz: any) => (
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                paddingTop: 12,
                paddingBottom: 12,
                paddingLeft: 16,
                cursor: 'pointer',
              }}
              onClick={() => {
                onChange(quiz)
                setQuizzesFilter('')
                handleBlur()
              }}
            >
              <Text variant="body1Emphasized" text={`${quiz.quiz_title}`} />
            </div>
          ))}
        </div>
      )}
    </div>
  )
})
