import { Instance, types } from 'mobx-state-tree'
import { ChoiceReadModel } from './ChoiceReadModel'

/**
 * QuestionRead Model
 */
export const QuestionReadModel = types
  .model('QuestionRead')
  .props({
    __typename: types.optional(types.literal('QuestionRead'), 'QuestionRead'),
    active: types.optional(types.boolean, false),
    adaptive_eligible: types.maybeNull(types.boolean),
    choices: types.array(types.late(() => ChoiceReadModel)),
    difficulty: types.number,
    factoid: types.frozen(),
    id: types.identifierNumber,
    is_locked: types.boolean,
    last_modified: types.string,
    text: types.string,
    resources: types.maybeNull(types.frozen()),
    answered: types.optional(types.boolean, false),
    correct: types.optional(types.boolean, false),
  })
  .actions((self) => ({
    afterCreate() {
      self.answered = false
    },
    answer(correct: boolean) {
      self.correct = correct
      self.answered = true
      self.active = false
    },
    setActive(status: boolean) {
      self.active = status
    },
  }))

/* The TypeScript type of an instance of QuestionReadModel */
export interface QuestionReadModelType extends Instance<typeof QuestionReadModel.Type> {}
