import { observer } from 'mobx-react-lite'
import React, { useEffect, useState } from 'react'
import { useRouteMatch } from 'react-router-dom'
import { Box, Stack } from '@mui/material'
import { Dialog } from '@trivie/ui-web/components/dialogs/dialog'
import { IAutocompleteGroupOptions, useStores } from '@trivie/core'
import { color } from '@trivie/ui-web/theme/color'
import { Text } from '@trivie/ui-web/components/text'
import { QuizApi } from '@trivie/core/src/services/api-objects'
import { AutocompleteGroupSelector } from '@trivie/ui-web/components/autocomplete-selector'
import { Switch } from '@trivie/ui-web/components/switch/switch'

// eslint-disable-next-line
const _array = require('lodash/array')

export interface AddToQuizDialogProps {
  open: boolean
  onClose: () => void
}

export const AddToQuizDialog = observer((props: AddToQuizDialogProps) => {
  const { open, onClose } = props
  const { manageResourceStore } = useStores()
  const { params } = useRouteMatch()
  const { getRelatedQuizzes, relatedQuizzes, addResourceToQuizzes } = manageResourceStore
  const [isRequired, setIsRequired] = useState(false)
  const [autocompleteOptions, setAutocompleteOptions] = useState<IAutocompleteGroupOptions[]>([])
  const [selectedAutocompleteOptions, setSelectedAutocompleteOptions] = useState<
    IAutocompleteGroupOptions[]
  >([])

  //@ts-ignore
  const { id } = params

  const handleClose = () => {
    onClose()
  }
  const handleSubmit = () => {
    const targetQuizzes = _array.differenceWith(
      selectedAutocompleteOptions,
      relatedQuizzes,
      (x: IAutocompleteGroupOptions, y: any) => x.id === y.quiz_id,
    ) as { id: number }[]

    const quiz_ids = targetQuizzes.map((val) => val.id)
    addResourceToQuizzes(id, quiz_ids, isRequired).then(() => {
      getRelatedQuizzes(id)
      setSelectedAutocompleteOptions([])
      onClose()
    })
  }

  const getAllQuizzes = async () => {
    const resp = await QuizApi.getAllQuizzes(
      'quizzes',
      false,
      [
        { field: 'quiz_title' },
        { field: 'quiz_completion_pct' },
        { field: 'quiz_topic_ids' },
        { field: 'quiz_topic_names' },
      ],
      'list_quizzes',
    )

    if (resp.ok) {
      const options: IAutocompleteGroupOptions[] = resp.data.rows.map((quiz) => {
        const title = quiz.columns.find((column) => column.field === 'quiz_title')
        const quiz_topic_ids = quiz.columns.find((column) => column.field === 'quiz_topic_ids')
        const quiz_topic_names = quiz.columns.find((column) => column.field === 'quiz_topic_names')
        // const topics = quiz_topic_ids.value?.map((e, i) => {
        //   return { quiz_id: quiz.id, title: title.value, topics: topics ?? [] }
        // })

        return {
          id: quiz.id,
          label: title.value,
          subLabel: quiz_topic_names?.value?.join(','),
          type: 'quizzes',
        }
      })

      setAutocompleteOptions(options)
    }
  }

  useEffect(() => {
    if (open) {
      getAllQuizzes()
    }
  }, [open])

  const handleAddItem = (selected: IAutocompleteGroupOptions[]) => {
    setSelectedAutocompleteOptions(selected)
  }

  return (
    <Dialog
      onCancel={onClose}
      onConfirm={handleSubmit}
      confirmBtnLabel="Add Source"
      disableConfirm={!(selectedAutocompleteOptions.length > 0)}
      onClose={handleClose}
      title="Add Source to Quiz"
      open={open}
      maxWidth="sm"
    >
      <Stack
        useFlexGap
        gap={2}
        alignItems="center"
        direction="row"
        mb={2}
        justifyContent="space-between"
      >
        <Stack>
          <Text variant="body1Emphasized" text="Required Source" />
          <Text
            variant="body2"
            text="Require users to view this source before taking the attached quiz"
            style={{ color: color.palette.grey, marginTop: 3 }}
          />
        </Stack>
        <Switch
          color="success"
          checked={isRequired}
          onChange={(e) => setIsRequired(e.target.checked)}
        />
      </Stack>
      <Box height={550}>
        <AutocompleteGroupSelector
          open
          noOptionsText="No matching reports."
          options={autocompleteOptions}
          defaultOptions={[]}
          selectedOptions={selectedAutocompleteOptions}
          handleAddUserGroup={handleAddItem}
        />
      </Box>
    </Dialog>
  )
})
