import React, { useState } from 'react'
import { Collapse } from '@mui/material'
import { Instance } from 'mobx-state-tree'
import { observer } from 'mobx-react-lite'

import { SurveyQuestion, SurveyQuestionChoice } from '@trivie/core/src/models/Survey'
import { color } from '@trivie/ui-web/theme/color'
import { Button } from '@trivie/ui-web/components/button'
import { Text } from '@trivie/ui-web/components/text'

import { BaseQuestion } from './base-question'

interface ShortAnswerQuestionProps {
  handleDrag?: () => void
  handleDrop?: () => void
  question: Instance<typeof SurveyQuestion>
  disabled?: boolean
  showQuestionPct?: boolean
}
export const ShortAnswerQuestion = observer((props: ShortAnswerQuestionProps) => {
  const { question, disabled, showQuestionPct, handleDrag, handleDrop } = props

  const [expanded, setExpanded] = useState(false)

  const response_texts = question?.open_text_responses ?? []

  const renderShortAnswers = () => {
    const shownResponses = response_texts.slice(0, 5)
    const collapseResponses = response_texts.slice(4, response_texts.length)
    return (
      <>
        {shownResponses.map((res: Instance<typeof SurveyQuestionChoice>) => (
          <div style={{ marginBottom: 20, paddingLeft: 10, paddingRight: 10 }}>
            <Text
              style={{ fontSize: 14, color: color.palette.quizSummary }}
              text={res?.text}
              variant="body2Medium"
            />
          </div>
        ))}
        {!expanded && collapseResponses.length ? (
          <Button
            style={{ maxWidth: 85 }}
            variant="text"
            color="primary"
            onClick={() => setExpanded(true)}
            text="View All"
          />
        ) : null}
        <Collapse in={expanded} timeout="auto" unmountOnExit>
          {collapseResponses.map((res: Instance<typeof SurveyQuestionChoice>) => (
            <div style={{ marginBottom: 20, paddingLeft: 10, paddingRight: 10 }}>
              <Text
                style={{ fontSize: 14, color: color.palette.quizSummary }}
                text={res?.text}
                variant="body2Medium"
              />
            </div>
          ))}
          <Button text="View Less" variant="text" onClick={() => setExpanded(false)} />
        </Collapse>
      </>
    )
  }
  return (
    <BaseQuestion
      handleDrop={handleDrop}
      handleDrag={handleDrag}
      question={question}
      disabled={disabled}
    >
      {showQuestionPct ? renderShortAnswers() : <div style={SHORT_ANSWER} />}
    </BaseQuestion>
  )
})

const SHORT_ANSWER: React.CSSProperties = {
  height: 88,
  borderRadius: 6,
  border: '2px solid rgba(239, 240, 246, 1)',
}
