import React from 'react'
import { observer } from 'mobx-react-lite'
import { Stack } from '@mui/material'

import { useStores } from '@trivie/core'
import { DateFilter } from '@trivie/ui-web/components/date-filter'
import { Header } from '@trivie/ui-web/components/header'
import { Leaderboard } from '@trivie/ui-web/components/leaderboard'
import { GenerateReport } from '@trivie/ui-web/components/generate-report'

export const LeaderboardsScreen: React.FunctionComponent = observer(() => {
  const { manageLeaderboardStore, manageUserStore } = useStores()
  const { userProfile } = manageUserStore
  const { getLeaderboardBase, userLeaderboard, groupLeaderboard } = manageLeaderboardStore

  const gdpr_enabled = userProfile?.workspace_settings?.gdpr_enabled

  return (
    <>
      <Header divider title="Leaderboards" />
      <Stack mb={2} direction="row" justifyContent="space-between" alignItems="center">
        <DateFilter onDateChange={getLeaderboardBase} />
        <GenerateReport filename="leaderboard" orientation="portrait" />
      </Stack>
      <Stack useFlexGap gap={2} direction="row" flexWrap="wrap">
        {!gdpr_enabled && <Leaderboard style={{ flex: 1 }} type="user" data={userLeaderboard} />}
        <Leaderboard style={{ flex: 1 }} type="group" data={groupLeaderboard} />
      </Stack>
    </>
  )
})
