import React, { useEffect, useState } from 'react'
import { observer } from 'mobx-react-lite'

import { useStores } from '@trivie/core'

import { TableHelper } from '../../../../tables'

import { ContestTable } from '../../../../tables/contest-table'

const CompletedContests: React.FunctionComponent = observer(() => {
  const { contestStore } = useStores()
  const { setTriggerRefresh, triggerRefresh } = contestStore

  const [tableHelper] = useState(
    new TableHelper('list_completed_contests', 'contests', 'contests', undefined, 'completed'),
  )

  useEffect(() => {
    if (triggerRefresh) {
      tableHelper.load().then(() => setTriggerRefresh(false))
    }
    return () => {
      setTriggerRefresh(undefined)
    }
  }, [triggerRefresh])

  return <ContestTable forceLoading={triggerRefresh} filter="completed" tableHelper={tableHelper} />
})

export const CompletedContestsTab = () => {
  return {
    name: 'Completed',
    path: `/activities/contests/completed`,
    component: () => <CompletedContests />,
  }
}
