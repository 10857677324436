import React, { useState } from 'react'
import { AutoAwesome, AutoMode, Check, Close, TipsAndUpdatesRounded } from '@mui/icons-material'
import { ListItemIcon, Menu, MenuItem } from '@mui/material'
import { observer } from 'mobx-react-lite'
import { getParentOfType } from 'mobx-state-tree'

import { createFileFromPath, useStores } from '@trivie/core'
import { QuestionHelper } from '@trivie/core/src/helpers/QuestionHelper'
import { Button } from '@trivie/ui-web/components/button'
import { QuestionChoice } from '@trivie/core/src/models/QuestionChoice'
import { Quiz } from '@trivie/core/src/models/Quiz'
import { color } from '@trivie/ui-web/theme'
import { Text } from '@trivie/ui-web/components/text'
import { getImages } from '../../../../utils/unsplash'

// eslint-disable-next-line
const _ = require('lodash')

export const QuestionCardFooter = observer((props: any) => {
  const { creatingTopic, editing, onSelectionChange, mercuryEnabled, question, standalone } = props
  const { contentCreationStore } = useStores()
  const { quiz } = contentCreationStore

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
  const openRewrite = Boolean(anchorEl)
  const handleClickRewrite = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
  }

  let parentQuiz
  try {
    parentQuiz = getParentOfType(question, Quiz)
  } catch {
    //@ts-ignore
  }

  const handleCloseRewrite = () => setAnchorEl(null)

  const disableDiscard = () => {
    if (question) {
      if (
        (parentQuiz && parentQuiz.questions?.length === 1 && !parentQuiz.questions[0].touched) ||
        question?.generatingAll ||
        question?.generating
      ) {
        return true
      }
    }
    return false
  }

  const loadImages = async () => {
    const result = await getImages({
      query: quiz?.title,
      page: 1,
      perPage: 10,
    })
    if (result?.errors) {
      // eslint-disable-next-line no-useless-return
      return
    } else if (result?.response?.results) {
      const fileData = await createFileFromPath(
        result?.response?.results[0]?.urls?.full,
        `${result?.response?.results[0]?.id}.jpg`,
      )
      quiz?.setImage(fileData)
      return true
    }
  }

  const addFinalCard = () => {
    const final = QuestionHelper.createNewQuestion('Multiple Choice', {
      removing: true,
      final: true,
    })

    if (!quiz?.title || !quiz?.description) {
      quiz?.generateTitle().then(() => {
        if (!quiz?.image) {
          quiz?.set('generatedImage', true)
          loadImages()
        }
      })
    } else if (!quiz?.image) {
      quiz?.set('generatedImage', true)
      loadImages()
    }

    parentQuiz.addQuestions([final])
    setTimeout(() => {
      onSelectionChange(final)
    }, 400)
  }

  const approveQuestion = () => {
    // mark form dirty
    question?.setTouched(true)
    question?.choices.map((c) => c.setTouched(true))

    if (question?.allErrors && question?.allErrors?.length === 0) {
      question?.set('approved', true)
      if (parentQuiz && parentQuiz.questions) {
        // find and select the next unapproved question if there is one
        const unapproved = (unapprovedQuestion) =>
          !unapprovedQuestion.removing && !unapprovedQuestion.approved
        const index = parentQuiz.questions.indexOf(question)
        const next = _.find(parentQuiz.questions, unapproved, index + 1)
        const fallback = _.findLast(parentQuiz.questions, unapproved, index - 1)

        setTimeout(() => {
          if (next) {
            onSelectionChange(next)
          } else if (fallback) {
            onSelectionChange(fallback)
          } else if (!editing && !creatingTopic) {
            // no more unapproved, show final card
            // if not editing
            addFinalCard()
          }
        }, 500)
      }
    } else {
      document.getElementById('Question')?.scrollTo({
        behavior: 'smooth',
        top: 0,
      })
      question?.set('showStatus', true)
    }
  }

  const discardQuestion = () => {
    if (parentQuiz && parentQuiz.questions && question) {
      const base = (baseQuestion) => !baseQuestion.removing
      const unapproved = (unapprovedQuestion) =>
        !unapprovedQuestion.removing && !unapprovedQuestion.approved

      const index = parentQuiz.questions.indexOf(question)
      const nextUnapproved = _.find(parentQuiz.questions, unapproved, index + 1)
      const nextUnapprovedFallback = _.findLast(parentQuiz.questions, unapproved, index - 1)
      const next = _.find(parentQuiz.questions, base, index + 1)
      const fallback = _.findLast(parentQuiz.questions, base, index - 1)

      if (parentQuiz.questions.filter((q) => !q.removing).length === 1) {
        // if only one question, reset form
        parentQuiz?.questions[index].reset()
      } else {
        // else find next unapproved question first, otherwise find the next (or previous if last in list)
        setTimeout(() => {
          question?.set('removing', true)
          if (nextUnapproved) {
            onSelectionChange(nextUnapproved)
          } else if (nextUnapprovedFallback) {
            onSelectionChange(nextUnapprovedFallback)
          } else if (!editing && !creatingTopic) {
            addFinalCard()
          } else if (next) {
            onSelectionChange(next)
          } else if (fallback) {
            onSelectionChange(fallback)
          }
        }, 500)
      }
    } else {
      question?.reset()
    }
  }

  const rewriteAll = async () => {
    handleCloseRewrite()

    question?.choices.map((choice) => choice.set('loading', true))
    const newQuestion = await question?.generateFromPartial(parentQuiz.settings)

    // move following block to the generate from partial method and update question
    // and parent quiz from the question model instead
    if (newQuestion) {
      question?.choices.map((choice) => choice.set('loading', false))
      question.set('text', newQuestion.query)
      const newChoices = newQuestion.choices.map((choice, index) => {
        const c = QuestionChoice.create({
          choice_id: null,
          text: choice,
          correct: newQuestion.correct_choices?.includes(index),
          touched: true,
          order: null,
          errors: null,
          errorsCorrected: null,
          warnings: null,
          acceptedWarnings: null,
        })
        return c
      })
      question.set('choices', newChoices)
      question.set('fact_id', newQuestion.fact_id)
      question.set('document_id', newQuestion.document_id)
      question.set('feedback', newQuestion.feedback)
      question.set('generated_question_id', newQuestion.generated_question_id)
      question.set(
        'question_type',
        newQuestion.question_type === 'multiple_response'
          ? 'Multiple Response'
          : newQuestion.question_type === 'multiple_choice'
            ? 'Multiple Choice'
            : newQuestion.question_type === 'true_false'
              ? 'True False'
              : 'Multiple Choice',
      )
      question.set('source_node_ids', newQuestion.source_node_ids)
      question.set('rewriting', false)
      question.set('generating', false)
    }
  }

  const rewriteChoices = async () => {
    handleCloseRewrite()

    question?.choices.map((choice) => choice.set('loading', true))

    const choices = await question?.generateChoices(
      question?.choices?.filter((choice) => !choice.correct)?.length,
    )

    if (choices) {
      question.set(
        'choices',
        choices.map((c) => QuestionChoice.create(c)),
      )
    }

    question?.choices.map((choice) => choice.set('loading', false))
    question.set('rewriting', false)
    question.set('generating', false)
  }

  const rewriteFeedback = async () => {
    handleCloseRewrite()
    question?.generateFeedback()
  }

  return (
    <div style={FOOTER}>
      {!standalone && (
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
          <Button
            disabled={disableDiscard()}
            variant="outlined"
            size="large"
            onClick={discardQuestion}
            startIcon={<Close />}
            color="error"
            text="Discard"
          />
        </div>
      )}

      {mercuryEnabled && (
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
          <Button
            variant="outlined"
            size="large"
            onClick={handleClickRewrite}
            startIcon={
              <svg
                width="18"
                height="18"
                viewBox="0 0 18 18"
                fill="none"
                style={{ marginRight: 4 }}
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M15.225 4.8187L14.5875 3.41245L13.1812 2.77495L14.5875 2.13745L15.225 0.731201L15.8625 2.13745L17.2687 2.77495L15.8625 3.41245L15.225 4.8187ZM6.1875 4.8187L5.55 3.41245L4.14375 2.77495L5.55 2.13745L6.1875 0.731201L6.825 2.13745L8.23125 2.77495L6.825 3.41245L6.1875 4.8187ZM15.225 13.8562L14.5875 12.45L13.1812 11.8125L14.5875 11.175L15.225 9.7687L15.8625 11.175L17.2687 11.8125L15.8625 12.45L15.225 13.8562ZM3.50625 16.275L1.725 14.4937C1.5875 14.3562 1.5125 14.1968 1.5 14.0156C1.4875 13.8343 1.5625 13.6625 1.725 13.5L10.1625 5.06245C10.3125 4.91245 10.4937 4.83745 10.7062 4.83745C10.9187 4.83745 11.1 4.91245 11.25 5.06245L12.9375 6.74995C13.0875 6.89995 13.1625 7.0812 13.1625 7.2937C13.1625 7.5062 13.0875 7.68745 12.9375 7.83745L4.5 16.275C4.35 16.425 4.18437 16.5 4.00312 16.5C3.82187 16.5 3.65625 16.425 3.50625 16.275ZM3.9375 15.2062L9.80625 9.33745L8.64375 8.17495L2.775 14.0437L3.9375 15.2062Z"
                  fill="url(#paint0_linear_1424_8812)"
                />
                <defs>
                  <linearGradient
                    id="paint0_linear_1424_8812"
                    x1="3.96103"
                    y1="16.0015"
                    x2="20.1447"
                    y2="4.9391"
                    gradientUnits="userSpaceOnUse"
                  >
                    <stop stopColor="#FF00FF" />
                    <stop offset="0.46" stopColor="#9116F9" />
                    <stop offset="1" stopColor="#3199FF" />
                  </linearGradient>
                </defs>
              </svg>
            }
            color="gradient"
            sx={{
              ':disabled': {
                color: color.shade30,
              },
            }}
          >
            <Text
              style={{
                backgroundSize: '100%',
                backgroundImage:
                  'linear-gradient(55.65deg, #FF00FF 10.56%, #9116F9 51.7%, #3199FF 100%)',
                WebkitBackgroundClip: 'text',
                WebkitTextFillColor: 'transparent',
                backgroundColor: color.white,
              }}
              variant="body1Emphasized"
              text="Rewrite"
            />
          </Button>
        </div>
      )}
      <Menu
        open={openRewrite}
        onClose={handleCloseRewrite}
        anchorEl={anchorEl}
        sx={{
          '& .MuiPaper-root': {
            borderRadius: '6px !important',
            border: `1px solid #EFF0F6`,
            padding: 0,
            boxShadow: 'none',
          },
        }}
        anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
        transformOrigin={{ vertical: 'bottom', horizontal: 'left' }}
      >
        <MenuItem disabled={!question?.factoid?.text} sx={{ height: 32 }} onClick={rewriteAll}>
          <ListItemIcon>
            <AutoMode htmlColor={color.shade70} fontSize="small" />
          </ListItemIcon>
          <Text style={{ color: color.shade70 }} variant="body2Medium" text="Rewrite All" />
        </MenuItem>
        <MenuItem
          disabled={
            !question?.factoid?.text ||
            question?.question_type === 'True or False' ||
            question?.generating
          }
          sx={{ height: 32 }}
          onClick={rewriteChoices}
        >
          <ListItemIcon>
            <AutoAwesome htmlColor={color.shade70} fontSize="small" />
          </ListItemIcon>
          <Text style={{ color: color.shade70 }} variant="body2Medium" text="Rewrite Answers" />
        </MenuItem>
        <MenuItem
          disabled={
            !question?.factoid?.text ||
            !question?.choices?.some((c) => c.text) ||
            !question?.text ||
            question?.generating
          }
          sx={{ height: 32 }}
          onClick={rewriteFeedback}
        >
          <ListItemIcon>
            <TipsAndUpdatesRounded htmlColor={color.shade70} fontSize="small" />
          </ListItemIcon>
          <Text style={{ color: color.shade70 }} variant="body2Medium" text="Rewrite Feedback" />
        </MenuItem>
      </Menu>
      <Button
        variant="outlined"
        size="large"
        onClick={approveQuestion}
        startIcon={<Check />}
        color="success"
        text="Approve"
      />
    </div>
  )
})

const FOOTER: React.CSSProperties = {
  alignItems: 'center',
  alignSelf: 'center',
  display: 'flex',
  justifyContent: 'space-between',
  width: 'calc(100% - 48px)',
  backgroundColor: color.transparent,
  paddingLeft: 24,
  paddingRight: 24,
  paddingTop: 12,
  paddingBottom: 12,
  background: '#fff',
  borderTopRightRadius: 12,
  borderTopLeftRadius: 12,
  boxShadow: '0px 0px 8px 0px rgba(0, 0, 0, 0.2)',
  maxWidth: 600,
  minWidth: 400,
  bottom: -60,
  zIndex: 2,
  position: 'absolute',
}
