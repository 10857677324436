import { types } from 'mobx-state-tree'
import { UserShortSchemaModel } from '../user/UserShortSchemaModel'

/**
 * FollowRead Model
 */
export const FollowReadModel = types.model('FollowRead').props({
  __typename: types.optional(types.literal('FollowRead'), 'FollowRead'),
  followedUser: types.union(
    types.null,
    types.late(() => UserShortSchemaModel),
  ),
  followedUserId: types.maybeNull(types.integer),
  followingUser: types.union(
    types.null,
    types.late(() => UserShortSchemaModel),
  ),
  followingUserId: types.maybeNull(types.integer),
  id: types.integer,
})
