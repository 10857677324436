export const toDataURL = (url: any) =>
  fetch(url)
    .then(response => response.blob())
    .then(
      blob =>
        new Promise((resolve, reject) => {
          const reader = new FileReader()
          reader.onloadend = () => resolve(reader.result)
          reader.onerror = reject
          reader.readAsDataURL(blob)
        }),
    )
    .catch(e => {
      console.log(e)
    })

const dataURLtoFile = (dataurl: any, filename: any) => {
  const arr = dataurl.split(',')
  const mime = arr[0].match(/:(.*?);/)[1]
  const bstr = atob(arr[1])
  let n = bstr.length
  const u8arr = new Uint8Array(n)
  while (n--) {
    u8arr[n] = bstr.charCodeAt(n)
  }
  return new File([u8arr], filename, {
    type: mime,
  })
}

export const createFileFromPath = async (FileUrl: any, name = 'default.jpg') => {
  // eslint-disable-next-line no-async-promise-executor
  return new Promise<File>(async resolve => {
    const dataUrl = await toDataURL(FileUrl)
    const fileData = dataURLtoFile(dataUrl, name)
    resolve(fileData)
  })
}

export const createFileFromBlob = async (blobUrl: string, fileName = 'default') => {
  const blobResponse = await (await fetch(blobUrl)).blob()
  const file = new File([blobResponse], `${fileName}.${blobResponse?.type?.split('/')[1]}`, {
    type: blobResponse.type,
  })
  return file
}

export const SUPPORTED_FILES = [
  'aac',
  'ai',
  'aif',
  'aifc',
  'aiff',
  'au',
  'avi',
  'bmp',
  'csv',
  'diff',
  'doc',
  'docx',
  'flac',
  'gif',
  'heic',
  'jpeg',
  'jpg',
  'm2v',
  'm4a',
  'm4v',
  'md',
  'mkv',
  'mov',
  'mp2',
  'mp3',
  'mp4',
  'mpeg',
  'mpg',
  'mpv',
  'odp',
  'ods',
  'odt',
  'ogg',
  'pages',
  'pdf',
  'png',
  'ppt',
  'pptx',
  'psd',
  'qt',
  'rtf',
  'svg',
  'tga',
  'tif',
  'tiff',
  'txt',
  'wav',
  'wma',
  'wmv',
  'wpd',
  'xls',
  'xlsb',
  'xlsm',
  'xlsx',
  'xml',
  'xsd',
  'xsl',
  'webp',
]

export const isFileSupported = (filename: string | undefined) => {
  if (!filename) return false
  const splittedName = filename.split('.')
  return SUPPORTED_FILES.includes(splittedName[splittedName.length - 1].toLocaleLowerCase())
}

export const isValidURL = string => {
  const pattern = new RegExp(
    '^(https?:\\/\\/)?' + // protocol
    '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
    '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
    '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
    '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
      '(\\#[-a-z\\d_]*)?$',
    'i',
  ) // fragment locator
  return !!pattern.test(string)
}

export const excelTypes = [
  'application/vnd.ms-excel',
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  'text/csv', // technically not excel, but I think it makes sense here
  'application/vnd.google-apps.spreadsheet',
  'xls',
  'xlsm',
  'xlsx',
  'xsl',
]

export const imageTypes = [
  'image/bmp',
  'image/gif',
  'image/vnd.microsoft.icon',
  'image/jpeg',
  'image/png',
  'image/svg+xml',
  'image/tiff',
  'image/webp',
  'ai',
  'bmp',
  'gif',
  'eps',
  'heic',
  'jpeg',
  'jpg',
  'png',
  'ps',
  'psd',
  'svg',
  'tif',
  'tiff',
  'dcm',
  'dicm',
  'dicom',
  'svs',
  'tga',
]

export const mediaTypes = [
  'video/x-msvideo',
  'mp4',
  'video/mp4',
  'video/mpeg',
  'video/ogg',
  'video/mp2t',
  'video/webm',
  'video/3gpp',
  'video/3gpp2',
  'audio/aac',
  'application/x-cdf',
  'audio/midi',
  'audio/x-midi',
  'audio/mpeg',
  'video/mp4',
  'audio/ogg',
  'audio/opus',
  'audio/wav',
  'audio/webm',
  'audio/3gpp',
  'audio/3gpp2',
]

export const pdfTypes = ['application/pdf', 'pdf']

export const slideTypes = [
  'application/vnd.ms-powerpoint',
  'application/vnd.openxmlformats-officedocument.presentationml.presentation',
  'application/vnd.google-apps.presentation',
  'ppt',
  'pptx',
]

export const wordTypes = [
  'application/msword',
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  'doc',
  'docx',
  'gdoc',
  'gsheet',
  'ods',
  'odt',
  'pages',
  'wpd',
]

export const soundTypes = ['mp3']

export const GetBackgroundColorOfMimeType = fileType => {
  const validUrl = fileType === 'url' ? true : isValidURL(fileType)

  if (mediaTypes.includes(fileType)) return '#F2C94C'
  else if (slideTypes.includes(fileType)) return 'rgba(255, 115, 36, 0.80)'
  else if (imageTypes.includes(fileType)) return '#F098FF'
  else if (excelTypes.includes(fileType)) return '#1FE08F'
  else if (pdfTypes.includes(fileType)) return '#ED7166'
  else if (wordTypes.includes(fileType)) return '#6E94F7'
  else if (soundTypes.includes(fileType)) return 'rgba(255, 62, 19, 0.70)'
  else if (validUrl) return 'rgba(85, 27, 250, 0.7)'
  else return '#1FE08F'
}
