import React from 'react'
import { Link } from 'react-router-dom'

import { color } from '../../theme'
import { Text } from '../text'
import { Avatar } from '../avatar'

export interface LeaderboardRowItemProps {
  user?: any
  group?: any
}

export const LeaderboardRowItem = (props: LeaderboardRowItemProps) => {
  const { user, group } = props

  return (
    <Link
      to={
        user
          ? `/people/users/${user?.user_id}/overview`
          : `/people/groups/${group?.group_id}/overview`
      }
      style={{
        display: 'flex',
        alignItems: 'center',
        width: '100%',
        textDecoration: 'none',
      }}
    >
      {user && (
        <Avatar size={42} name={`${user.first_name} ${user.last_name}`} url={user.photo_url} />
      )}
      <div
        style={{
          display: 'flex',
          flex: 1,
          justifyContent: 'space-between',
          // paddingRight: 24,
          paddingLeft: 20,
        }}
      >
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <Text variant="body1Emphasized" style={{ marginBottom: 4 }}>
            {user ? `${user.first_name} ${user.last_name}` : group!.group_label}
          </Text>
          <Text variant="body2Medium" style={{ color: color.shade50 }}>{`${
            user?.points ?? group!.points
          } Points${group ? '/User' : ''}`}</Text>
        </div>
        <div style={{ flexDirection: 'row', display: 'flex' }}>
          <Text style={{ alignSelf: 'center' }} variant="titleEmphasized">
            {user?.rank || group!.rank}
          </Text>
        </div>
      </div>
    </Link>
  )
}
