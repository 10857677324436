import React, { useState } from 'react'
import { IconButton, InputAdornment, Radio, TextField } from '@mui/material'
import { AddRounded, CloseRounded } from '@mui/icons-material'
import { color } from '@trivie/ui-web/theme'
import { Button } from '@trivie/ui-web/components/button'
import { Text } from '@trivie/ui-web/components/text'
import { Tooltip } from '@trivie/ui-web/components/tooltip'
import { BaseQuestion } from './base-question'
import { Instance } from 'mobx-state-tree'
import { SurveyQuestion, SurveyQuestionChoice } from '@trivie/core/src/models/Survey'
import { observer } from 'mobx-react-lite'
import { roundNumber } from '@trivie/core/src/utils/roundNumber'
import { Switch } from '@trivie/ui-web/components/switch/switch'

interface RadioQuestionProps {
  question: Instance<typeof SurveyQuestion>
  disabled?: boolean
  showQuestionPct?: boolean
  handleDrag?: () => void
  handleDrop?: () => void
}

export const RadioQuestion = observer((props: RadioQuestionProps) => {
  const { question, disabled, showQuestionPct, handleDrag, handleDrop } = props
  const { choices } = question

  const toggleMultiple = () =>
    question.isMultipleChoice
      ? question.setQuestionType('single_choice')
      : question.setQuestionType('multiple_choice')

  const [showCharacterCount, setShowCharacterCount] = useState(false)
  const [charCount, setCharCount] = useState(undefined)

  return (
    <BaseQuestion
      handleDrag={handleDrag}
      handleDrop={handleDrop}
      question={question}
      disabled={disabled}
    >
      <Tooltip
        open={question.needsMoreChoices}
        placement="left"
        title="More answers are needed"
        text="Multiple Choice questions need at least two answer choices."
      >
        <div style={CHOICE_LIST}>
          {choices?.map((choice: Instance<typeof SurveyQuestionChoice>, index: number) => (
            <RadioChoice
              setShowCharacterCount={setShowCharacterCount}
              setCharCount={setCharCount}
              choice={choice}
              index={index}
              disabled={disabled}
              showQuestionPct={showQuestionPct}
            />
          ))}
        </div>
      </Tooltip>
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'flex-end',
          visibility: showCharacterCount ? 'visible' : 'hidden',
        }}
      >
        <Text
          variant="body2"
          style={{
            paddingBottom: 6,
            color: charCount && charCount! <= 100 ? color.green : color.red,
          }}
          text={`${charCount || 0} / ${100} Characters`}
        />
      </div>
      <div style={QUESTION_FOOTER}>
        {disabled
          ? null
          : choices
            ? choices.length <= 3 && (
                <Button
                  variant="outlined"
                  color="info"
                  onClick={question.addChoice}
                  startIcon={<AddRounded />}
                  text="Add Option"
                />
              )
            : null}
        {disabled ? null : (
          <div style={TOGGLE_MC}>
            <Text style={MC_TEXT} variant="body2Medium" text="Allow Multiple Selection" />
            <Switch
              color="success"
              checked={question.isMultipleChoice}
              onChange={toggleMultiple}
              size="small"
            />
          </div>
        )}
      </div>
    </BaseQuestion>
  )
})

interface RadioChoiceProps {
  choice: Instance<typeof SurveyQuestionChoice>
  index: number
  disabled?: boolean
  setCharCount?: any
  setShowCharacterCount?: any
  showQuestionPct?: boolean
}
const RadioChoice = observer((props: RadioChoiceProps) => {
  const { choice, setShowCharacterCount, index, disabled, setCharCount, showQuestionPct } = props

  return (
    <div style={ANSWER_CONTAINER}>
      <div style={CHOICE_CONTAINER}>
        {disabled ? null : (
          <Radio
            checked={false}
            color="default"
            inputProps={{ 'aria-label': 'choice' }}
            style={RADIO_BUTTON}
            disabled={disabled}
          />
        )}
        <Tooltip
          open={choice.tooLong}
          placement="right"
          title="That's a long answer!"
          text="Make sure your answers are under 100 characters."
        >
          <TextField
            variant="standard"
            style={CHOICE_INPUT}
            onBlur={() => {
              setShowCharacterCount(false)
              choice.setShowErrors(true)
            }}
            onFocus={() => {
              setCharCount(choice.text.length)
              setShowCharacterCount(true)
            }}
            onChange={(e) => {
              choice.setText(e.target.value)
              setCharCount(e.target.value.length)
            }}
            value={choice.text}
            disabled={disabled}
            InputProps={{
              style: {
                color: choice.empty || choice.tooLong ? color.red : color.black,
                borderBottomColor: 'rgba(239, 240, 246, 1)',
              },
              endAdornment: disabled ? null : (
                <InputAdornment position="end">
                  <IconButton aria-label="remove choice" onClick={choice.remove} size="small">
                    <CloseRounded style={{ color: 'rgba(174, 175, 177, 1)', fontSize: 18 }} />
                  </IconButton>
                </InputAdornment>
              ),
              disableUnderline: disabled,
            }}
            placeholder={`Option ${index + 1}`}
          />
        </Tooltip>
      </div>
      {showQuestionPct ? (
        <Text
          style={{ fontSize: 14, color: color.palette.quizSummary }}
          text={`${roundNumber(choice?.pct_selected ?? 0)}%`}
          variant="body2Medium"
        />
      ) : null}
    </div>
  )
})

const RADIO_BUTTON: React.CSSProperties = {
  placeSelf: 'center',
  color: color.shade30,
}
const CHOICE_INPUT: React.CSSProperties = {
  flex: 1,
  fontSize: 14,
  color: color.palette.quizSummary,
}
const CHOICE_CONTAINER: React.CSSProperties = {
  display: 'flex',
  alignItems: 'center',
  paddingLeft: 10,
}
const ANSWER_CONTAINER: React.CSSProperties = {
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
}
const QUESTION_FOOTER: React.CSSProperties = {
  display: 'flex',
  alignItems: 'center',
  flexDirection: 'row',
  justifyContent: 'space-between',
  paddingLeft: 20,
}
const CHOICE_LIST: React.CSSProperties = { marginBottom: 32 }
const TOGGLE_MC: React.CSSProperties = {
  flexDirection: 'row',
  alignItems: 'center',
  display: 'flex',
}
const MC_TEXT: React.CSSProperties = { marginRight: 6 }
