import React, { useEffect, useState } from 'react'
import {
  PaymentElement,
  LinkAuthenticationElement,
  useStripe,
  useElements,
} from '@stripe/react-stripe-js'
import { CircularProgress } from '@mui/material'

import { UserApi } from '@trivie/core/src/services/api-objects'
import { Button } from '@trivie/ui-web/components/button'

export default function CheckoutForm(props: any) {
  const { customer_id, price } = props
  const stripe = useStripe()
  const elements = useElements()

  const [email, setEmail] = useState('')
  const [message, setMessage] = useState<null | string>(null)
  const [isLoading, setIsLoading] = useState(true)

  useEffect(() => {
    setTimeout(() => {
      setIsLoading(false)
    }, 600)
  }, [])

  const handleSubmit = async (e) => {
    e.preventDefault()

    if (!stripe || !elements) {
      // Stripe.js hasn't yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return
    }

    setIsLoading(true)

    // Trigger form validation and wallet collection
    const { error: submitError } = await elements.submit()
    if (submitError) {
      console.log(submitError)
      setIsLoading(false)
      return
    } else {
      UserApi.createSubscription({
        customer_id: customer_id,
        price_id: price.price_id,
      }).then(async (resp) => {
        setIsLoading(false)
        const { error } = (await stripe.confirmPayment({
          elements,
          clientSecret: resp.data.client_secret,
          confirmParams: {
            receipt_email: email,
            // Make sure to change this to your payment completion page
            return_url:
              process.env.REACT_APP_ENV === 'local'
                ? 'http://localhost:3000/upgrade/'
                : `https://insights.${process.env.REACT_APP_ENV}.trivie.com/upgrade/`,
          },
        })) as any

        // This point will only be reached if there is an immediate error when
        // confirming the payment. Otherwise, your customer will be redirected to
        // your `return_url`. For some payment methods like iDEAL, your customer will
        // be redirected to an intermediate site first to authorize the payment, then
        // redirected to the `return_url`.
        if (error.type === 'card_error' || error.type === 'validation_error') {
          setMessage(error.message)
        } else {
          setMessage('An unexpected error occurred.')
        }

        setIsLoading(false)
      })
    }
  }

  const paymentElementOptions = {
    layout: 'tabs',
  } as any

  return (
    <form style={FORM} id="payment-form" onSubmit={handleSubmit}>
      <LinkAuthenticationElement
        id="link-authentication-element"
        onChange={(e: any) => setEmail(e.target?.value ?? '')}
      />
      <PaymentElement id="payment-element" options={paymentElementOptions} />
      {isLoading ? (
        <CircularProgress style={{ left: 233, position: 'relative' }} />
      ) : (
        <Button
          id="submit"
          disabled={isLoading || !stripe || !elements}
          onClick={handleSubmit}
          text="Continue"
          fullWidth
        />
      )}
      {/* Show any error or success messages */}
      {message && <div id="payment-message">{message}</div>}
    </form>
  )
}

const FORM: React.CSSProperties = {
  height: '100%',
  justifyContent: 'center',
  flexDirection: 'column',
  alignItems: 'center',
}
