import React from 'react'
import { color } from '@trivie/ui-web/theme/color'
import { Text } from '@trivie/ui-web/components/text'
import { ReactComponent as GreenCheck } from '@trivie/ui-web/assets/icons/green-check.svg'
import moment from 'moment'
import { IAssigneeOptions, IFormInputs } from './assign-survey'

export default (props: { assignees: IAssigneeOptions[]; fd: IFormInputs }) => {
  const { assignees, fd } = props
  const renderAssignees = () => {
    if (assignees?.length > 0) {
      return (
        <div>
          <Text variant="body1Emphasized" text="Assignees" style={ASSIGNEES_WRAPPER} />
          {assignees.map((val: any) => (
            <div style={ASSIGNEE}>
              <GreenCheck />
              <div style={ASSIGNEE_TEXT}>{val.label}</div>
              {val.type == 'Groups' && (
                <Text
                  variant="body1Emphasized"
                  text={val.number_users}
                  style={{ paddingLeft: 10, color: color.lightGrey }}
                />
              )}
            </div>
          ))}
        </div>
      )
    }
  }

  return (
    <div style={REVIEW_CONTAINER}>
      <div style={TITLE_WRAPPER}>{fd.title}</div>
      {renderAssignees()}
      <div style={{ flexDirection: 'column', paddingTop: 30 }}>
        <span style={ASSIGNEES_WRAPPER}>Start Date</span>
        <div style={{ display: 'flex', paddingTop: 10 }}>
          <div style={DATE_WRAPPER}>{moment(fd.start_date).format('MMMM DD, YYYY')}</div>
          <span style={ASSIGNEES_WRAPPER}>{moment(fd.start_time, 'HH:mm').format('hh:mm A')}</span>
          <span style={ASSIGNEES_WRAPPER}>{Intl.DateTimeFormat().resolvedOptions().timeZone}</span>
        </div>

        <span style={ASSIGNEES_WRAPPER}>End Date</span>
        <div style={{ display: 'flex', paddingTop: 10 }}>
          <div style={DATE_WRAPPER}>{moment(fd.end_date).format('MMMM DD, YYYY')}</div>
          <span style={ASSIGNEES_WRAPPER}>{moment(fd.end_time, 'HH:mm').format('hh:mm A')}</span>
          <span style={ASSIGNEES_WRAPPER}>{Intl.DateTimeFormat().resolvedOptions().timeZone}</span>
        </div>
      </div>
      {(fd.notify || fd.is_anonymous) && (
        <div style={{ flexDirection: 'column', paddingTop: 10 }}>
          <span style={ASSIGNEES_WRAPPER}>Settings</span>
          {fd.notify && (
            <div style={TEXT_WRAPPER}>
              <GreenCheck />
              <div style={TEXT_STYLE}>Notify Learners</div>
            </div>
          )}

          {fd.is_anonymous && (
            <div style={TEXT_WRAPPER}>
              <GreenCheck />
              <div style={TEXT_STYLE}>Anonymize Responses</div>
            </div>
          )}
        </div>
      )}
    </div>
  )
}

const REVIEW_CONTAINER: React.CSSProperties = {
  backgroundColor: 'rgba(240, 242, 245, 0.5)',
  display: 'flex',
  paddingTop: 16,
  paddingRight: 20,
  paddingLeft: 20,
  paddingBottom: 16,
  justifyContent: 'space-evenly',
  borderRadius: 10,
  flexDirection: 'column',
}

const TITLE_WRAPPER: React.CSSProperties = {
  display: 'flex',
  flexDirection: 'row',
  marginBottom: 20,
  fontFamily: 'inter',
  fontStyle: 'normal',
  fontWeight: 'bold',
  fontSize: 20,
}

const ASSIGNEES_WRAPPER: React.CSSProperties = {
  fontSize: 14,
  marginBottom: 15,
  marginRight: 10,
  fontFamily: 'inter',
  fontStyle: 'normal',
  fontWeight: 'normal',
  color: 'rgba(0, 0, 0, 0.7)',
}

const ASSIGNEE: React.CSSProperties = {
  alignItems: 'center',
  paddingLeft: 10,
  margin: '0.5rem 0',
  display: 'flex',
}

const ASSIGNEE_TEXT: React.CSSProperties = {
  paddingLeft: 15,
  fontFamily: 'inter',
  fontStyle: 'normal',
  fontWeight: 'bold',
  fontSize: 14,
}

const DATE_WRAPPER: React.CSSProperties = {
  display: 'flex',
  flexDirection: 'row',
  marginBottom: 20,
  marginRight: 15,
  fontFamily: 'inter',
  fontStyle: 'normal',
  fontWeight: 'bold',
  fontSize: 14,
}

const TEXT_STYLE: React.CSSProperties = {
  fontFamily: 'inter',
  fontStyle: 'normal',
  fontWeight: 'bold',
  fontSize: 14,
}

const TEXT_WRAPPER: React.CSSProperties = {
  display: 'flex',
  paddingTop: 10,
  gap: 15,
  alignItems: 'center',
}
