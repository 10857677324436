import React, { useEffect, useState } from 'react'
import { VictoryPie } from 'victory'
// @ts-ignore
import { ReactComponent as GoldMedal } from '../../../assets/icons/gold-medal.svg'
// @ts-ignore
import { ReactComponent as SilverMedal } from '../../../assets/icons/silver-medal.svg'
// @ts-ignore
import { ReactComponent as BronzeMedal } from '../../../assets/icons/bronze-medal.svg'
// @ts-ignore
import { ReactComponent as InactiveMedal } from '../../../assets/icons/inactive-medal.svg'

interface MedalProps {
  level: string | null
  recall?: number | null
  style?: React.CSSProperties
  dark?: boolean
  size: number
  hideRecall?: boolean
}

const initData = [
  { y: 0, fill: 'none' },
  { y: 100, fill: 'none' },
]

export const Medal = (props: MedalProps) => {
  const { level, hideRecall, size, dark, recall, style } = props

  const [finalData, setFinalData] = useState(initData)

  const fillValue = Math.round((recall ?? 0) * 100)

  const fill = dark
    ? '#ffffff50'
    : level === 'Gold'
      ? '#F2C44F40'
      : level === 'Silver'
        ? '#8B909940'
        : level === 'Bronze'
          ? '#A7A18E50'
          : '#D2D2D4'

  useEffect(() => {
    const timer = setTimeout(() => {
      setFinalData([
        { y: fillValue, fill },
        { y: 100 - fillValue, fill: 'none' },
      ])
    }, 1000)
    return () => clearTimeout(timer)
  }, [])

  const renderBase = () => {
    switch (level) {
      case 'Gold':
        return <GoldMedal height={size} width={size} />
      case 'Silver':
        return <SilverMedal height={size} width={size} />
      case 'Bronze':
        return <BronzeMedal height={size} width={size} />
      default: {
        return <InactiveMedal height={size} width={size} />
      }
    }
  }

  return (
    <div
      style={{
        position: 'relative',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: size,
        width: size,
        ...style,
      }}
    >
      {!hideRecall && (
        <svg viewBox="0 0 60 60" height={size} width={size} style={{ position: 'absolute' }}>
          <circle
            cx={30}
            cy={30}
            r={level === null ? 24 : 28}
            stroke={fill}
            strokeWidth={level === null ? 7 : 4}
            style={{ opacity: level === null ? 0.5 : 1 }}
            fill="none"
          />

          <VictoryPie
            labelComponent={<></>}
            standalone={false}
            height={60}
            width={60}
            radius={level === null ? 28 : 30}
            origin={{ x: 30, y: 30 }}
            innerRadius={level === null ? 20 : 26}
            cornerRadius={5}
            animate={{ easing: 'sin', duration: 2000 }}
            data={finalData}
            style={{
              labels: { display: 'none' },
              data: {
                fill: ({ datum }) => datum.fill,
                opacity: ({ datum }) => datum.opacity,
              },
            }}
          />
        </svg>
      )}
      {renderBase()}
    </div>
  )
}
