import { types } from 'mobx-state-tree'
import { CommentReadModel } from './CommentReadModel'

/**
 * PostRead Model
 */
export const PostReadModel = types.model('PostRead').props({
  __typename: types.optional(types.literal('PostRead'), 'PostRead'),
  comments: types.array(types.late(() => CommentReadModel)),
  created_at: types.string,
  id: types.number,
  is_announcement: types.boolean,
  // resources: types.union(
  //   types.null,
  //   types.array(
  //     types.union(
  //       types.null,
  //       types.late(() => ResourceReadModel),
  //     ),
  //   ),
  // ),
  resources: types.frozen(),
  text: types.maybeNull(types.string),
  title: types.string,
  topic_id: types.maybe(types.integer),
  author: types.frozen(), // TODO need type
  liked_by_current_user: types.boolean,
})
