import { cast, getRoot, Instance, SnapshotOut, types } from 'mobx-state-tree'

import { AssignmentStoreModel } from '../stores/assignments-store'
import { ContentCreationStoreModel } from '../stores/content-creation-store'
import { FormStoreModel } from '../stores/form-store'
import { GroupStoreModel } from '../stores/group-store'
import { LeaderboardStoreModel } from '../stores/leaderboard-store'
import { ManageLeaderboardStoreModel } from '../stores/manage-leaderboard-store'
import { ManageQuizStoreModel } from '../stores/manage-quiz-store'
import { ManageResourceStoreModel } from '../stores/manage-resource-store'
import { ManageUserStoreModel } from '../stores/manage-user-store'
import { QuestionStoreModel } from '../stores/question-store'
import { QuizStoreModel } from '../stores/quiz-store'
import { ReportStoreModel } from '../stores/report-store'
import { ResourceStoreModel } from '../stores/resource-store'
import { TagStoreModel } from '../stores/tag-store'
import { TalkStoreModel } from '../stores/talk-store'
import { TopicsStoreModel } from '../stores/topics-store'
import { UserStoreModel } from '../stores/user-store'
import { UIStoreModel } from '../stores/ui-store'
import { SurveyStoreModel } from '../stores/survey-store'
import { BadgeStoreModel } from '../stores/badge-store'
import { BadgesStoreModel } from '../stores/badges-store'
import { LibraryStoreModel } from '../stores/library-store'
import { MessageStoreModel } from '../stores/messages-store'

import { omit } from 'ramda'
import { entries } from 'mobx'
import { ContestStoreModel } from '../stores/contest-store'
import { TaskStoreModel } from '../stores/task-store'
import { ChatStoreModel } from '../stores/chat-store'
import { PathStoreModel } from '../stores/path-store'
import { ToastStoreModel } from '../stores/toast-store'

const UserStoreSP = types.snapshotProcessor(UserStoreModel, {
  postProcessor(sn) {
    // omit from profile object
    return omit(['phoneNumber', 'formattedPhoneNumber'], sn)
  },
})

const RootCacheModel = types
  .model('RootCache')
  .props({})
  .views((self) => ({
    get rootStore(): any {
      return getRoot(self)
    },
  }))
  .actions((self) => ({
    invalidateGroup(group: any) {
      self.rootStore.invalidateGroup(group)
    },
    invalidateAction(action: any, store: any) {
      self.rootStore.invalidateAction(action, store)
    },
  }))

/**
 * A RootStore model.
 */
export const RootStoreModel = types
  .model('RootStore')
  .props({
    assignmentStore: types.optional(AssignmentStoreModel, {}),
    contentCreationStore: types.optional(ContentCreationStoreModel, {}),
    formStore: types.optional(FormStoreModel, {}),
    taskStore: types.optional(TaskStoreModel, {}),
    groupStore: types.optional(GroupStoreModel, {}),
    manageLeaderboardStore: types.optional(ManageLeaderboardStoreModel, {}),
    manageResourceStore: types.optional(ManageResourceStoreModel, {}),
    manageQuizStore: types.optional(ManageQuizStoreModel, {}),
    manageUserStore: types.optional(ManageUserStoreModel, {}),
    messageStore: types.optional(MessageStoreModel, {}),
    questionStore: types.optional(QuestionStoreModel, {}),
    quizStore: types.optional(QuizStoreModel, {}),
    reportStore: types.optional(ReportStoreModel, {}),
    resourceStore: types.optional(ResourceStoreModel, {}),
    tagStore: types.optional(TagStoreModel, {}),
    talkStore: types.optional(TalkStoreModel, { currentPage: 1, commentSort: 'top' }),
    libraryStore: types.optional(LibraryStoreModel, { flowCache: {} }),
    leaderboardStore: types.optional(LeaderboardStoreModel, {}),
    topicsStore: types.optional(TopicsStoreModel, {}),
    userStore: types.optional(UserStoreSP, {}),
    uiStore: types.optional(UIStoreModel, {}),
    surveyStore: types.optional(SurveyStoreModel, {}),
    flavor: types.frozen(),
    badgeStore: types.optional(BadgeStoreModel, {}),
    badgesStore: types.optional(BadgesStoreModel, {}),
    contestStore: types.optional(ContestStoreModel, {}),
    rootCache: types.optional(RootCacheModel, {}),
    chatStore: types.optional(ChatStoreModel, {}),
    toastStore: types.optional(ToastStoreModel, {}),
    pathStore: types.optional(PathStoreModel, { flowCache: {} }),
  })
  .actions((self) => ({
    afterCreate() {
      // Clear splash screen notifications when refreshing/revisitng the app
      self.userStore.setSplashNotifications(cast([]))
    },
    reset() {
      self.manageUserStore = ManageUserStoreModel.create({ userProfile: null })
      self.uiStore = UIStoreModel.create({})
    },
    invalidateAction(action, store) {
      entries(store?.flowCache).map((entry) => {
        if (entry[0] === action) {
          entries(entry[1]).map((c) => c[1].setInvalidate(true))
        }
      })
    },
    invalidateGroup(group) {
      switch (group) {
        case 'content':
          invalidateGroup(group, self.libraryStore)
          invalidateGroup(group, self.quizStore)
          break
        case 'surveys':
          invalidateGroup(group, self.surveyStore)
          break
        default:
          break
      }
    },
  }))

const CACHE_GROUPS = {
  content: [
    'getQuizzesForUser',
    'getTopicsForUser',
    'getPathsForUser',
    'getAssessments',
    'getAdaptive',
    'getUpNext',
  ],
  surveys: ['getAssignedSurveysForUser'],
}

const invalidateGroup = (group, store) =>
  entries(store.flowCache).map((entry) => {
    if (CACHE_GROUPS[group].includes(entry[0])) {
      entries(entry[1]).map((c) => c[1].setInvalidate(true))
    }
  })

/**
 * The RootStore instance.
 */
export type RootStoreType = Instance<typeof RootStoreModel>

/**
 * The data of a RootStore.
 */
export type RootStoreSnapshot = SnapshotOut<typeof RootStoreModel>
