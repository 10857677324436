import { types } from 'mobx-state-tree'
import { TagUserReadModel } from './TagUserReadModel'
import { UserLevelReadModel } from './UserLevelReadModel'

/**
 * UserLongSchema Model
 */
export const UserLongSchemaModelBase = types.model('UserLongSchema').props({
  __typename: types.optional(types.literal('UserLongSchema'), 'UserLongSchema'),
  accountCreated: types.string,
  adminAssignedPoints: types.maybeNull(types.integer),
  avatarChoice: types.maybeNull(types.integer),
  avatarTier: types.maybeNull(types.string),
  consecutiveCorrectAnswers: types.maybeNull(types.integer),
  data: types.maybeNull(types.string),
  editAdmins: types.maybeNull(types.boolean),
  editAssignments: types.maybeNull(types.boolean),
  editContent: types.maybeNull(types.boolean),
  editPeople: types.maybeNull(types.boolean),
  editSettings: types.maybeNull(types.boolean),
  email: types.string,
  emailEnabled: types.maybeNull(types.boolean),
  employeeId: types.maybeNull(types.string),
  firstName: types.string,
  forcePasswordReset: types.maybeNull(types.boolean),
  globalAdmin: types.maybeNull(types.boolean),
  hourlyResponse: types.maybeNull(types.boolean),
  id: types.integer,
  language: types.maybeNull(types.string),
  lastActivity: types.maybeNull(types.string),
  lastAdaptiveEmail: types.maybeNull(types.string),
  lastAdaptivePush: types.maybeNull(types.string),
  lastAnswer: types.maybeNull(types.string),
  lastLogin: types.maybeNull(types.string),
  lastName: types.string,
  lastPost: types.maybeNull(types.string),
  lastReply: types.maybeNull(types.string),
  lockedTags: types.array(
    types.union(
      types.null,
      types.late(() => TagUserReadModel),
    ),
  ),
  musicEnabled: types.maybeNull(types.boolean),
  onesignalId: types.maybeNull(types.string),
  pin: types.maybeNull(types.string),
  previewContent: types.maybeNull(types.boolean),
  profileTagKeys: types.maybeNull(types.array(types.maybeNull(types.frozen()))),
  pushEnabled: types.maybeNull(types.boolean),
  showWalkthrough: types.maybeNull(types.boolean),
  soundsEnabled: types.maybeNull(types.boolean),
  userLevel: types.union(
    types.null,
    types.late(() => UserLevelReadModel),
  ),
  webPushId: types.maybeNull(types.string),
})
