import React, { useState } from 'react'
import { observer } from 'mobx-react-lite'
import OtpInput, { OTPInputProps } from 'react-otp-input'

type OTPBaseProps = Pick<
  OTPInputProps,
  'inputType' | 'onChange' | 'onPaste' | 'placeholder' | 'renderSeparator' | 'shouldAutoFocus'
>

export interface OtpEntryProps extends OTPBaseProps {
  /**
   * Default value populating entry field
   */
  initialValue?: string
  inputStyle?: React.CSSProperties
  containerStyle?: React.CSSProperties
  /**
   * Whether the first input should be auto focused
   * @default true
   */
  shouldAutoFocus?: boolean
  /**
   * Number of OTP inputs to be rendered
   * @default 4
   */
  numInputs?: number
}

export const OtpEntry = observer((props: OtpEntryProps) => {
  const { initialValue } = props
  const [value, setValue] = useState(initialValue)
  const {
    onChange,
    inputStyle,
    shouldAutoFocus = true,
    containerStyle,
    numInputs = 4,
    ...rest
  } = props

  const handleChange = (value: string) => {
    setValue(value)
    onChange(value)
  }

  return (
    <OtpInput
      shouldAutoFocus={shouldAutoFocus}
      numInputs={numInputs}
      containerStyle={{ ...CONTAINER, ...containerStyle }}
      inputStyle={{ ...INPUT_STYLE, ...inputStyle }}
      {...rest}
      value={value}
      renderInput={(props, index) => <input data-qat={`otp_input_${index}`} {...props} />}
      onChange={handleChange}
    />
  )
})

const CONTAINER: React.CSSProperties = { display: 'flex', alignSelf: 'center', gap: 16 }
const INPUT_STYLE: React.CSSProperties = {
  height: 80,
  width: 80,
  borderRadius: 10,
  backgroundColor: 'rgba(230, 230, 230, 1)',
  border: '0px solid black',
  fontSize: 36,
  fontWeight: 700,
}
