import React, { useState } from 'react'
import { useRouteMatch } from 'react-router-dom'

import { BadgeUsersDialog } from '../../badge-users'
import { Text } from '../../text'
import { GridCellParams } from '@mui/x-data-grid-pro'

interface BadgesAwardedCell {
  params: GridCellParams
}
export const BadgesAwardedCell = (props: BadgesAwardedCell) => {
  const { params } = props
  const match = useRouteMatch<any>('/activities/badges/:tab')
  const tab = String(match?.params?.tab)
  const [showAwarded, setShowAwarded] = useState(false)
  return (
    <>
      <Text
        variant="body2Emphasized"
        color={params.row.is_custom ? 'primary' : 'info'}
        text={
          tab === 'custom'
            ? `${params.row.badge_awarded_count} ${params.row.badge_awarded_count === 1 ? 'Person' : 'People'}`
            : 'Automatically'
        }
        style={{ cursor: tab === 'custom' ? 'pointer' : 'default' }}
        onClick={() => (tab === 'custom' ? setShowAwarded(true) : {})}
      />
      {showAwarded && (
        <BadgeUsersDialog
          open={showAwarded}
          badge={params.row}
          requestClose={() => setShowAwarded(false)}
        />
      )}
    </>
  )
}
