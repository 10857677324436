import { destroy, getParentOfType, Instance, types } from 'mobx-state-tree'

export const TagRule = types
  .model('TagRule')
  .props({
    id: types.number,
    tag_id: types.maybe(types.number),
    key: types.maybe(types.string),
    value: types.maybe(types.string),
    user_count: types.maybe(types.number),
    is_locked: types.maybe(types.boolean),
  })
  .actions((self) => ({
    remove() {
      // @ts-ignore
      getParentOfType(self, GroupRuleset).removeRuleset(self)
    },
  }))

export const GroupRuleset = types
  .model('GroupRuleset')
  .props({
    ruleset_id: types.maybe(types.number),
    method: types.enumeration(['any', 'all', 'none']),
    tag_rules: types.array(TagRule),
  })
  .views((self) => ({
    get hasRules() {
      return self.tag_rules.length > 0
    },
  }))
  .actions((self) => ({
    setMethod(method: 'any' | 'all' | 'none') {
      self.method = method
    },
    setTagRules(rules: typeof self.tag_rules) {
      self.tag_rules = rules
    },
    remove() {
      // @ts-ignore
      getParentOfType(self, NewGroup).removeRuleset(self)
    },
    addTagRule(rule: Instance<typeof TagRule>) {
      self.tag_rules.push(TagRule.create(rule))
    },
    removeTagRule(rule: Instance<typeof TagRule>) {
      destroy(rule)
    },
  }))

export const NewGroup = types
  .model('NewGroup')
  .props({
    group_id: types.maybe(types.number),
    user_count: types.maybe(types.number),
    label: types.string,
    is_locked: types.maybe(types.boolean),
    rulesets: types.array(GroupRuleset),
  })
  .views((self) => ({
    get hasEmptyRuleset() {
      let emptyRuleset = false
      self.rulesets.forEach((ruleset) => {
        if (!ruleset.hasRules) {
          emptyRuleset = true
        }
      })
      return emptyRuleset
    },
  }))
  .actions((self) => ({
    setLabel(label: string) {
      self.label = label
    },
    addRuleset() {
      self.rulesets.push(
        GroupRuleset.create({
          method: 'all',
          tag_rules: [],
        }),
      )
    },
    removeRuleset(ruleset: Instance<typeof GroupRulesetPayload>) {
      destroy(ruleset)
    },
  }))

export const GroupRulesetPayload = types.model('GroupRulesetPayload').props({
  tag_ids: types.array(types.number),
  method: types.enumeration(['any', 'all', 'none']),
})

export const NewGroupPayload = types.model('NewGroupPayload').props({
  label: types.string,
  is_locked: types.boolean,
  proposed_rulesets: types.array(GroupRulesetPayload),
})

export const MemberCountPayload = types.model('MemberCountPayload').props({
  rulesets: types.array(GroupRulesetPayload),
})

export const GroupRulesetsResponse = types.model('GroupRulesetsResponse').props({
  group_id: types.number,
  label: types.string,
  rulesets: types.array(GroupRuleset),
  user_count: types.number,
})
