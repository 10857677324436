import React from 'react'
import { Text } from '../text'

interface NoRowsOverlayProps {
  filtered?: boolean
}

export const NoRowsOverlay = (props: NoRowsOverlayProps) => {
  const { filtered } = props
  return (
    <Text
      style={OVERLAY}
      component="div"
      align="center"
      variant="body1Medium"
      text={filtered ? 'No rows found that meet your filters.' : 'No rows found.'}
    />
  )
}

const OVERLAY: React.CSSProperties = {
  height: '100%',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
}
