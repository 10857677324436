/* eslint-disable */

import { Instance, types } from 'mobx-state-tree'
import { QuizReadModel } from '../quiz/QuizReadModel'

/**
 * AssignmentUserRead Model
 */
export const AssignmentUserReadModel = types.model('AssignmentUserRead').props({
  __typename: types.optional(types.literal('AssignmentUserRead'), 'AssignmentUserRead'),
  finish: types.maybeNull(types.string),
  id: types.identifierNumber,
  is_required: types.boolean,
  max_attempts: types.integer,
  name: types.string,
  notify: types.boolean,
  quiz_list: types.maybeNull(types.array(QuizReadModel)),
  quiz_ordering: types.maybeNull(types.array(types.maybeNull(types.integer))),
  start: types.maybeNull(types.string),
})

/* The TypeScript type of an instance of AssignmentUserRead */
export interface AssignmentUserReadType extends Instance<typeof AssignmentUserReadModel.Type> {}
