import { cast, SnapshotIn, types } from 'mobx-state-tree'

export const ListSelectorSubItem = types
  .model('ListSelectorSubItem')
  .props({
    parentId: types.maybe(types.number),
    id: types.number,
    checked: types.boolean,
    subLabel: types.string,
    userCount: types.maybe(types.number),
    label: types.string,
    type: types.maybe(types.string),
  })
  .actions((self) => {
    return {
      set<K extends keyof SnapshotIn<typeof self>, T extends SnapshotIn<typeof self>>(
        key: K,
        value: T[K],
      ) {
        // @ts-ignore
        self[key] = cast(value)
      },
      setChecked(status: boolean) {
        self.checked = status
      },
    }
  })

export const ListSelectorItem = types
  .model('ListSelectorItem')
  .props({
    id: types.number,
    type: types.maybeNull(types.string),
    label: types.string,
    subLabel: types.string,
    checked: types.boolean,
    userCount: types.maybe(types.number),
    subItems: types.maybeNull(types.array(ListSelectorSubItem)),
  })
  .actions((self) => {
    return {
      set<K extends keyof SnapshotIn<typeof self>, T extends SnapshotIn<typeof self>>(
        key: K,
        value: T[K],
      ) {
        self[key] = cast(value)
      },
    }
  })

export interface ISelectGroupOptions {
  type: string
  label: string
  subLabel?: string
  photo_url?: string
  id: number
  number_users: number | undefined
  showAvatar?: boolean
}

export interface IAutocompleteGroupChildOptions {
  id: number
  type: string
  label: string
  parentId?: boolean
  subLabel?: string
}

export interface IAutocompleteGroupOptions {
  id: number
  type: string
  label: string
  subLabel?: string
  childCount?: number
  photo_url?: string
  parentId?: number
  subItems?: IAutocompleteGroupChildOptions[]
}
