import React, { useEffect, useState } from 'react'
import { AutoFixHighOutlined, Close, ExpandMore as ExpandMoreIcon, Help } from '@mui/icons-material'
import {
  Box,
  Collapse,
  InputBase,
  MenuItem,
  Select,
  Slider,
  TextField,
  styled,
} from '@mui/material'
import { useStores } from '@trivie/core'
import { Button } from '@trivie/ui-web/components/button'
import { Text } from '@trivie/ui-web/components/text'
import { color } from '@trivie/ui-web/theme'
import { observer } from 'mobx-react-lite'
import { Tooltip } from '@trivie/ui-web/components/tooltip'
import { Switch } from '@trivie/ui-web/components/switch/switch'
import { ExpandMore } from '@trivie/ui-web/components/expand-button/expand-button'
import { scrollToCitation } from '@trivie/core/src/utils/scrollToCitation'

const TrivieDropdown = styled(InputBase)(({ theme }) => ({
  '& .MuiInputBase-input': {
    width: '100%',
    borderRadius: 6,
    position: 'relative',
    backgroundColor: color.white,
    fontSize: 16,
    padding: '10px 26px 10px 12px',
    lineHeight: '22px',
    minHeight: '0px !important',
  },
  '& .MuiPopover-root': {
    height: 400,
  },
}))

interface ContentGenerationsSettingsProps {
  quiz: any
  onGenerate: () => void
  onCancel: () => void
}
export const ContentGenerationSettings = observer((props: ContentGenerationsSettingsProps) => {
  const { onGenerate, onCancel, quiz } = props
  const { resourceStore } = useStores()
  const { getAvailableResources } = resourceStore

  useEffect(() => {
    getAvailableResources()
  }, [])

  if (!quiz) {
    return null
  }

  const { settings } = quiz
  const {
    voice,
    setVoice,
    audience,
    setAudience,
    difficulty,
    setDifficulty,
    setFillInTheBlank,
    multipleChoice,
    trueFalse,
    fillInTheBlank,
    setMultipleChoice,
    setTrueFalse,
    questionCount,
    setQuestionCount,
  } = settings

  const cancel = () => {
    onCancel()
  }

  const generateQuestions = () => {
    quiz.setGeneratingQuestions(true)
    quiz.set('stopGenerating', false)
    // quiz?.set('showQuizPanel', true)

    if (!quiz?.settings.questionCount || quiz?.settings.questionCount === 0) {
      quiz?.settings.setQuestionCount(10)
    }

    quiz
      ?.generateQuestions(
        quiz?.mercuryResource!.resource_id,
        quiz?.settings!.questionCount,
        quiz?.settings?.question_types,
      )
      .then(() => {
        setTimeout(() => {
          quiz?.selectQuestion(quiz?.questions[0])
          scrollToCitation(quiz?.questions[0].generated_question_id)
        }, 1000)
      })
    onGenerate()
  }

  const [styleExpanded, setStyleExpanded] = useState(true)
  const [toggleToolTip, setToggleToolTip] = useState(false)

  return (
    <div style={ROOT}>
      <div
        style={{
          overflowY: 'auto',
          flexDirection: 'column',
          height: '100%',
          display: 'flex',
          flex: 1,
        }}
      >
        <div style={CONTAINER}>
          <div style={{ flexDirection: 'column', width: '100%' }}>
            <div style={{ display: 'flex' }}>
              <Text style={{ marginBottom: 12 }} text="Generate a Quiz" variant="headline" />
              <Tooltip
                open={toggleToolTip}
                placement="top"
                title=" "
                text="We use GPT-powered artificial intelligence to translate vetted sources of truth - like PDFs, documents, or websites - into targeted microlearning."
                textStyle={{ fontSize: 13, fontWeight: 400 }}
              >
                <Help
                  onMouseEnter={() => setToggleToolTip(true)}
                  onMouseLeave={() => setToggleToolTip(false)}
                  htmlColor={color.shade30}
                  style={{ marginLeft: 10, fontSize: 20, cursor: 'pointer' }}
                />
              </Tooltip>
            </div>
            <div style={{ flexDirection: 'column', display: 'flex' }}>
              <Text
                style={{ marginTop: 12, marginBottom: 12, color: color.shade70, fontSize: 14 }}
                text="Start by selecting the source material for your quiz on the right panel."
                variant="body1Medium"
              />
              <Text
                style={{
                  marginBottom: 12,
                  color: quiz?.mercuryResource ? color.shade70 : color.shade30,
                  fontSize: 14,
                }}
                text="To generate your quiz all at once, adjust the options below and click the “Generate”."
                variant="body1Medium"
              />
              <Text
                style={{
                  marginBottom: 20,
                  color: quiz?.mercuryResource ? color.shade70 : color.shade30,
                  fontSize: 14,
                }}
                text="Or, highlight the most important sections of the selected source and click “Generate Question”."
                variant="body1Medium"
              />
              <Text style={{ marginBottom: 20 }} text="Settings" variant="labelEmphasized" />
            </div>
            <Box
              style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
                alignItems: 'center',
                paddingLeft: 12,
                marginBottom: 8,
                height: 41,
                paddingRight: 12,
              }}
            >
              <Text variant="body1" text="Question Count" style={LABEL} />
              <TextField
                size="small"
                variant="outlined"
                type="number"
                onChange={(e) => {
                  if (!e.target.value) {
                    setQuestionCount(null)
                  } else {
                    if (Number(e.target.value) > 20) {
                      setQuestionCount(20)
                    } else {
                      setQuestionCount(Number(e.target.value))
                    }
                  }
                }}
                inputProps={{
                  style: { backgroundColor: '#fff' },
                  width: 57,
                  borderRadius: 8,
                }}
                style={{ width: 64 }}
                defaultValue={10}
                value={questionCount}
              />
            </Box>
            <Box
              style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
                alignItems: 'center',
                paddingLeft: 12,
                marginBottom: 8,
                height: 41,
                paddingRight: 12,
              }}
            >
              <Text variant="body1Medium" text="Difficulty" />
              <Select
                variant="standard"
                displayEmpty
                input={<TrivieDropdown />}
                MenuProps={{
                  PaperProps: { style: { borderRadius: 6, paddingTop: 0 } },
                }}
                IconComponent={ExpandMoreIcon}
                value={difficulty}
                onChange={(e: any) => {
                  setTimeout(() => {
                    // @ts-ignore
                    document?.activeElement?.blur()
                  }, 0)
                  setDifficulty(e.target.value)
                }}
                style={{
                  placeSelf: 'center',
                  border: `1px solid ${color.shade20}`,
                  borderRadius: 6,
                  width: 130,
                }}
              >
                <MenuItem value="Easy">
                  <Text variant="body2Emphasized" text="Easy" />
                </MenuItem>
                <MenuItem value="Standard">
                  <Text variant="body2Emphasized" text="Standard" />
                </MenuItem>
                <MenuItem value="Challenging">
                  <Text variant="body2Emphasized" text="Challenging" />
                </MenuItem>
              </Select>
            </Box>
            <Box style={{ ...SWITCH_ROW, marginBottom: 16 }}>
              <Text variant="body1Medium" text="Shuffle Questions" />
              <Switch
                color="success"
                checked={Boolean(quiz.shuffle)}
                onChange={(e) => quiz.setShuffleOrder(e.target.checked)}
                size="small"
              />
            </Box>
            <Text style={{ marginBottom: 20 }} text="Question Types" variant="labelEmphasized" />
            <Box style={SWITCH_ROW}>
              <Text variant="body1Medium" text="Multiple Choice" />
              <Switch
                color="success"
                checked={multipleChoice}
                onChange={(e) => setMultipleChoice(e.target.checked)}
                size="small"
              />
            </Box>
            <Box style={SWITCH_ROW}>
              <Text variant="body1Medium" text="True / False" />
              <Switch
                color="success"
                checked={trueFalse}
                onChange={(e) => setTrueFalse(e.target.checked)}
                size="small"
              />
            </Box>
            <Box style={{ ...SWITCH_ROW, marginBottom: 16 }}>
              <Text variant="body1Medium" text="Fill-In-The-Blank" />
              <Switch
                color="success"
                checked={fillInTheBlank}
                onChange={(e) => setFillInTheBlank(e.target.checked)}
                size="small"
              />
            </Box>
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                flexDirection: 'row',
                justifyContent: 'space-between',
                marginBottom: 20,
              }}
            >
              <Text
                text="Style (Optional)"
                style={{ color: color.shade80 }}
                variant="labelEmphasized"
              />
              <ExpandMore expand={styleExpanded} onClick={() => setStyleExpanded(!styleExpanded)} />
            </div>
            <Collapse in={styleExpanded} timeout="auto">
              <>
                <div
                  style={{
                    alignItems: 'center',
                    flexDirection: 'row',
                    display: 'flex',
                    justifyContent: 'space-between',
                    marginBottom: 16,
                    paddingLeft: 12,
                  }}
                >
                  <Text variant="body1Medium" text="Write in the style of:" style={LABEL} />
                  <TextField
                    variant="outlined"
                    size="small"
                    placeholder="a subject-matter expert"
                    onChange={(e) => setVoice(e.target.value)}
                    inputProps={{
                      style: { backgroundColor: '#fff' },
                    }}
                    style={{ height: 33, width: 280 }}
                    value={voice}
                  />
                </div>
                <div
                  style={{
                    alignItems: 'center',
                    flexDirection: 'row',
                    display: 'flex',
                    justifyContent: 'space-between',
                    paddingLeft: 12,
                    marginBottom: 16,
                  }}
                >
                  <Text variant="body1Medium" text="Write for an audience of:" style={LABEL} />
                  <TextField
                    variant="outlined"
                    size="small"
                    placeholder="front-line workers"
                    onChange={(e) => setAudience(e.target.value)}
                    inputProps={{
                      style: { backgroundColor: '#fff' },
                    }}
                    style={{ height: 33, width: 280 }}
                    value={audience}
                  />
                </div>
                <div
                  style={{
                    alignItems: 'center',
                    flexDirection: 'row',
                    display: 'flex',
                    justifyContent: 'space-between',
                    paddingLeft: 12,
                  }}
                >
                  <Text
                    variant="body1Medium"
                    text="How creative should Trivie AI be?"
                    style={LABEL}
                  />
                  <div style={{ width: 250, flexDirection: 'column', display: 'flex' }}>
                    <Slider
                      size="small"
                      defaultValue={0}
                      max={100}
                      sx={{ width: '100%' }}
                      aria-label="Small"
                      valueLabelDisplay="off"
                    />
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                        position: 'relative',
                        width: 282,
                        left: -31,
                      }}
                    >
                      <Text style={{ color: color.shade60 }} variant="body2" text="Less Creative" />
                      <Text style={{ color: color.shade60 }} variant="body2" text="More Creative" />
                    </div>
                  </div>
                </div>
              </>
            </Collapse>
          </div>
        </div>
      </div>
      <div style={FOOTER}>
        <Button
          variant="outlined"
          color="error"
          sx={{ backgroundColor: color.white }}
          startIcon={<Close />}
          onClick={cancel}
          text="Cancel"
        />
        <Button
          sx={{
            ':disabled': { background: 'rgba(0, 0, 0, 0.12) !important' },
            '&:hover': { opacity: 0.8 },
          }}
          color="gradient"
          startIcon={<AutoFixHighOutlined />}
          onClick={generateQuestions}
          text="Generate"
          disabled={!quiz.mercuryResource || quiz?.loadingDocument}
        />
      </div>
    </div>
  )
})

const CONTAINER: React.CSSProperties = {
  display: 'flex',
  justifyContent: 'space-evenly',
  flex: 1,
  padding: 24,
  maxWidth: 675,
  width: '100%',
  alignSelf: 'center',
}
const ROOT: React.CSSProperties = {
  flexDirection: 'column',
  height: '100%',
  display: 'flex',
  flex: 1,
  position: 'relative',
}
const SWITCH_ROW: React.CSSProperties = {
  display: 'flex',
  marginTop: 0,
  marginBottom: 8,
  alignItems: 'center',
  justifyContent: 'space-between',
  paddingLeft: 12,
  height: 41,
  paddingRight: 12,
}

const LABEL: React.CSSProperties = {
  fontWeight: 500,
  fontSize: 14,
}
const FOOTER: React.CSSProperties = {
  alignItems: 'center',
  alignSelf: 'center',
  display: 'flex',
  justifyContent: 'space-evenly',
  paddingBottom: 24,
  paddingTop: 24,
  width: '100%',
  backgroundColor: color.transparent,
  maxWidth: 1000,
}
