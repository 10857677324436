import { ApiResponse, ApisauceInstance } from 'apisauce'
import { Instance } from 'mobx-state-tree'

import { Assessment } from '../../models/Assessment'
import { ContentCreation } from '../../models/ContentCreation'
import { Resource } from '../../models/Resource'
import { GetTopicAssignment } from '../../models/response-models/assignment'
import {
  GetTopicGroupStat,
  GetTopicQuestionKnowledgeGap,
  GetTopicQuiz,
  GetTopicQuizKnowledgeGap,
  GetTopicUser,
} from '../../models/response-models/topics'
import { TopicDetailStat } from '../../models/TopicDetailStat'
import { TopicMasteryDistributionStat } from '../../models/TopicMasteryDistributionStat'
import { UserTopicDetails } from '../../models/UserTopicDetails'
import { handleResponse } from '../handleResponse'
import { CertificationStats } from '../../models/CertificationStat'
const _ = require('lodash')

export const TopicApi = {
  apisauce: {} as ApisauceInstance,

  getAllTopics: async (
    type: 'users' | 'topics' | 'assignments' | 'quizzes' | 'groups' | 'tags',
    meta?: boolean,
    columns?: any,
    view_name?: string,
    id?: number,
  ) => {
    const payload = { view_name }
    if (columns && columns.length > 0) {
      payload['columns'] = columns
    }

    const response: ApiResponse<any> = await TopicApi.apisauce.post(
      Boolean(id)
        ? `/${type}/${id}/topics/?meta=${meta}&asynchronous=${false}`
        : `/${type}/?meta=${meta}&asynchronous=${false}`,
      payload,
    )

    return handleResponse(response)
  },
  getTopic: async (id: number) => {
    const response: ApiResponse<Instance<typeof ContentCreation>> = await TopicApi.apisauce.get(
      `/content_creation/topic/${id}/`,
    )

    return handleResponse(response)
  },
  getWorkflow: async (id: number) => {
    const response: ApiResponse<Instance<typeof ContentCreation>> = await TopicApi.apisauce.get(
      `/topics/${id}/workflow/`,
    )

    return handleResponse(response)
  },
  getTopicDetails: async (id: number, startDatetime?: string, endingDatetime?: string) => {
    const urlQuery = new URLSearchParams({
      start_datetime: startDatetime ?? '1920-07-19 04:15:03', // default to a super early time so we grab everything
      end_datetime: endingDatetime ?? new Date().toISOString().slice(0, 19).replace('T', ' '),
    })

    const response: ApiResponse<Instance<typeof TopicDetailStat>> = await TopicApi.apisauce.get(
      `/topics/${id}/?${urlQuery.toString()}`,
    )

    return handleResponse(response)
  },
  getTopicDetailForUser: async (user_id: number, metadata) => {
    const params = Object.keys(_.omitBy(metadata, _.isNull))
      .map((k) => `${k}=${metadata[k]}`)
      .join('&')

    const response: ApiResponse<Instance<typeof UserTopicDetails>> = await TopicApi.apisauce.get(
      `/users/${user_id}/topic_detail/?${params}&user_id=${user_id}`,
    )
    return handleResponse(response)
  },
  getTopicMastery: async (id: number, startDatetime: string, endingDatetime: string) => {
    const urlQuery = new URLSearchParams({
      start_datetime: startDatetime,
      end_datetime: endingDatetime,
    })

    const response: ApiResponse<Instance<typeof TopicMasteryDistributionStat>> =
      await TopicApi.apisauce.get(`/topics/${id}/mastery/?${urlQuery.toString()}`)

    return handleResponse(response)
  },
  getQuizzesForTopic: async (id: number) => {
    const response: ApiResponse<Array<Instance<typeof GetTopicQuiz>>> = await TopicApi.apisauce.get(
      `/topics/${id}/quizzes/`,
    )

    return handleResponse(response)
  },
  publishImage: async (id: number, photo: File) => {
    const formData = new FormData()
    formData.append('photo', photo)

    const response: ApiResponse<null> = await TopicApi.apisauce.patch(
      `topics/${id}/photo/`,
      formData,
    )

    return handleResponse(response)
  },
  removeImage: async (id: number) => {
    const response: ApiResponse<null> = await TopicApi.apisauce.delete(`topics/${id}/photo/`)

    return handleResponse(response)
  },
  getResources: async (meta?: boolean, columns?: any, view_name?: string, id?: number) => {
    const payload = { view_name }
    if (columns && columns.length > 0) {
      payload['columns'] = columns
    }

    const response: ApiResponse<any> = await TopicApi.apisauce.post(
      `/topics/${id}/resources/?meta=${meta}&asynchronous=${false}`,
      payload,
    )

    return handleResponse(response)
  },
  addResources: async (id: number, resources: Array<Instance<typeof Resource>>) => {
    const response: ApiResponse<null> = await TopicApi.apisauce.post(
      `/topics/${id}/resources/add`,
      {
        resources: resources.map((r) => r.resource_id),
      },
    )

    return handleResponse(response)
  },
  removeResources: async (id: number, resources: Array<number>) => {
    const response: ApiResponse<null> = await TopicApi.apisauce.delete(
      `/topics/${id}/resources/`,
      {},
      {
        data: {
          resources,
        },
      },
    )

    return handleResponse(response)
  },
  getUsersForTopic: async (id: number) => {
    const response: ApiResponse<Array<Instance<typeof GetTopicUser>>> = await TopicApi.apisauce.get(
      `/topics/${id}/users/`,
    )

    return handleResponse(response)
  },
  getQuizGaps: async (id: number, start: string, end: string) => {
    const urlQuery = new URLSearchParams({
      start_datetime: start,
      end_datetime: end,
    })

    const response: ApiResponse<Instance<typeof GetTopicQuizKnowledgeGap>> =
      await TopicApi.apisauce.get(`/topics/${id}/quiz_gaps/?${urlQuery.toString()}`)

    return handleResponse(response)
  },
  getQuestionGaps: async (id: number, start: string, end: string) => {
    const urlQuery = new URLSearchParams({
      start_datetime: start,
      end_datetime: end,
    })

    const response: ApiResponse<Instance<typeof GetTopicQuestionKnowledgeGap>> =
      await TopicApi.apisauce.get(`/topics/${id}/question_gaps/?${urlQuery.toString()}`)

    return handleResponse(response)
  },
  getAssignmentsForTopic: async (id: number, start: string, end: string) => {
    const response: ApiResponse<Array<Instance<typeof GetTopicAssignment>>> =
      await TopicApi.apisauce.get(
        `/topics/${id}/assignments/?start_datetime=${start}&end_datetime=${end}`,
        {},
      )

    return handleResponse(response)
  },
  getTopicGroups: async (id: number) => {
    const response: ApiResponse<Array<Instance<typeof GetTopicGroupStat>>> =
      await TopicApi.apisauce.get(`/topics/${id}/groups/`, {})

    return handleResponse(response)
  },
  getAssessments: async (user_id: number) => {
    const response: ApiResponse<Array<Instance<typeof Assessment>>> = await TopicApi.apisauce.get(
      `/users/${user_id}/assessments/`,
    )

    return handleResponse(response)
  },
  getCertificationStats: async (topic_id: number) => {
    const response: ApiResponse<Array<Instance<typeof CertificationStats>>> =
      await TopicApi.apisauce.get(`/topics/${topic_id}/certifications/ `)
    return handleResponse(response)
  },
  exportTopics: async (ids: number[]) => {
    const response: ApiResponse<any> = await TopicApi.apisauce.post(`/export/topics/`, {
      file_format: 'xlsx',
      version: 1,
      topic_ids: ids,
    })

    return handleResponse(response)
  },
}
