import React from 'react'
import { observer } from 'mobx-react-lite'
import { useHistory, useRouteMatch } from 'react-router-dom'
import { Create } from '@mui/icons-material'

import { useStores } from '@trivie/core'
import { Header } from '@trivie/ui-web/components/header'
import { NavigationTabs } from '@trivie/ui-web/components/navigation-tabs'
import { topicsTabs } from './tabs'

export const TopicsDetailsScreen: React.FunctionComponent = observer(() => {
  const match = useRouteMatch<any>('/content/topics/:id/:tab')
  const topicID = Number(match?.params?.id)

  const history = useHistory()
  const { topicsStore, contentCreationStore, manageUserStore } = useStores()
  const { resetContentStore } = contentCreationStore
  const { topicStats } = topicsStore
  const { permissions } = manageUserStore

  const primary = {
    disabled: !permissions?.permissions.some((p) => p.code === 'can_create_badge'),
    startIcon: <Create />,
    onClick: () => {
      resetContentStore()
      history.push(`/content/creation/topic/${Number(topicID)}/edit`)
    },
    text: 'Edit Topic',
  }

  return (
    <>
      <Header title={topicStats?.title} primaryAction={primary} />
      <NavigationTabs tabList={topicsTabs} id={topicID} />
    </>
  )
})
