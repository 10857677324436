import { types, Instance, getParent } from 'mobx-state-tree'
import { intersection } from '../utils'
import { UserRole } from './response-models/user'

export const Admin = types
  .model('Admin')
  .props({
    user_id: types.number,
    display_name: types.string,
    email: types.string,
    file_url: types.maybeNull(types.string),
    last_activity: types.maybeNull(types.string),
    roles: types.maybe(types.array(UserRole)),
    selectedRole: types.maybe(UserRole),
    defaultRole: types.maybe(UserRole),
  })
  .views((self) => ({
    get isOwner() {
      return self.roles?.find((r) => r.name === 'Owner')
    },
  }))
  .actions((self) => ({
    setDefaultRole(roles: any) {
      if (self.isOwner) {
        // @ts-ignore
        self.defaultRole = { ...self.roles?.find((r) => r.name === 'Owner') }
        // @ts-ignore
        self.selectedRole = { ...self.roles?.find((r) => r.name === 'Owner') }
      } else {
        const selected = intersection(
          roles,
          self.roles,
          (r1: Instance<typeof UserRole>, r2: Instance<typeof UserRole>) => r1.id === r2.id,
        )[0]

        self.selectedRole = { ...selected }
        self.defaultRole = { ...selected }
      }
    },
    updateRole(role: any) {
      self.selectedRole = { ...role }
    },
    revertRoleChange() {
      if (self.defaultRole) {
        self.selectedRole = { ...self.defaultRole }
      }
    },
  }))
export interface AdminModelType extends Instance<typeof Admin.Type> {}
