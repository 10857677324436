import {
  PaletteOptions,
  ThemeOptions,
  createTheme,
  responsiveFontSizes,
} from '@mui/material/styles'
import { color } from './color'
import { Theme } from '@mui/material/styles'
// @ts-ignore
import Inter from '../../assets/fonts/Inter.var.woff2'

// Shared typography config
import { typography } from './typography'

declare module '@mui/material/styles' {
  interface TypographyVariants {
    headline: React.CSSProperties
    label: React.CSSProperties
    labelMedium: React.CSSProperties
    labelEmphasized: React.CSSProperties
    title: React.CSSProperties
    titleMedium: React.CSSProperties
    titleEmphasized: React.CSSProperties
    body1Medium: React.CSSProperties
    body1Emphasized: React.CSSProperties
    body2Medium: React.CSSProperties
    body2Emphasized: React.CSSProperties
    subtitle: React.CSSProperties
  }

  // allow configuration using `createTheme`
  interface TypographyVariantsOptions {
    headline?: React.CSSProperties
    label?: React.CSSProperties
    labelMedium?: React.CSSProperties
    labelEmphasized: React.CSSProperties
    title: React.CSSProperties
    titleMedium: React.CSSProperties
    titleEmphasized: React.CSSProperties
    body1Medium: React.CSSProperties
    body1Emphasized: React.CSSProperties
    body2Medium: React.CSSProperties
    body2Emphasized: React.CSSProperties
    subtitle: React.CSSProperties
  }
}

// Update the Typography's variant prop options
declare module '@mui/material/Typography' {
  interface TypographyPropsVariantOverrides {
    headline: true
    label: true
    labelMedium: true
    labelEmphasized: true
    title: true
    titleMedium: true
    titleEmphasized: true
    body1: true
    body1Medium: true
    body1Emphasized: true
    body2: true
    body2Medium: true
    body2Emphasized: true
    subtitle: true
  }
}

declare module '@mui/material/Button/Button' {
  interface ButtonPropsColorOverrides {
    error: true
    info: true
    success: true
    warning: true
    gradient: true
    white: true
    black: true
  }
}

const palette = {
  mode: 'light',
  primary: {
    main: color.blue,
    light: '#c8cffd',
    dark: '#0023c3',
    contrastText: color.white,
  },
  white: {
    main: color.white,
    contrastText: color.shade70,
    light: color.white,
    dark: '#dedede',
  },
  black: {
    main: color.black,
    contrastText: color.white,
    light: color.shade10,
    dark: color.shade70,
  },
  secondary: {
    main: color.purple,
    light: '#efe6fe',
    dark: '#0000e2',
    contrastText: color.white,
  },
  warning: {
    main: color.yellow,
    light: '#fff7e1',
    dark: '#fdaa14',
    contrastText: color.white,
  },
  error: {
    main: color.red,
    light: '#ffa78d',
    dark: '#cb1b00',
    contrastText: color.white,
  },
  gradient: {
    main: color.white,
    light: color.white,
    dark: color.shade30,
    contrastText: color.white,
  },
  success: {
    main: color.green,
    light: '#e1faec',
    dark: '#00bd53',
    contrastText: color.white,
  },
  common: {
    black: '#000000',
    white: '#ffffff',
    border: color.border,
  },
  info: {
    main: color.shade70,
    contrastText: color.shade70,
    light: color.shade10,
    dark: color.shade10,
  },
  text: {
    primary: '#000000',
    secondary: 'rgba(0,0,0,0.7)',
    disabled: 'rgba(0,0,0,0.3)',
  },
  background: {
    paper: '#ffffff',
    default: '#F4F4F4',
  },
  table: {
    columnHeader: {
      main: '#F8FAFD',
      light: '#F8FAFD',
      dark: '#F8FAFD',
    },
  },
} as PaletteOptions

declare module '@mui/styles' {
  interface DefaultTheme extends Theme {}
}

export const baseTheme = {
  components: {
    MuiFilledInput: {
      styleOverrides: {
        root: ({ theme }) => ({
          backgroundColor: 'rgba(0,0,0,0.02)',
          borderTopLeftRadius: 0,
          borderTopRightRadius: 0,
          ':hover': {
            backgroundColor: 'rgba(0,0,0,0.04)',
          },
          '& .Mui-focused': {
            backgroundColor: `${color.blue}10 !important`,
          },
        }),
      },
    },
    MuiSwitch: {
      styleOverrides: {
        sizeMedium: {
          width: 42,
          height: 26,
          padding: 0,
        },
        sizeSmall: {
          width: 26,
          height: 16,
          padding: '0px !important',
          margin: '0px !important',
          '& .MuiSwitch-switchBase': {
            padding: '0px !important',
            margin: '0px !important',
            top: '2px !important',
            left: '2px !important',
            '&.Mui-checked': {
              transform: 'translateX(10px) !important',
            },
          },
          '& .MuiSwitch-thumb': {
            height: 12,
            width: 12,
          },
        },
        switchBase: ({ theme }) => ({
          padding: 0,
          margin: 2,
          transitionDuration: '150ms',
          '&.Mui-checked': {
            transform: 'translateX(16px)',
            color: theme.palette.common.white,
            '& + .MuiSwitch-track': {
              opacity: 1,
              border: 0,
            },
            '&.Mui-disabled + .MuiSwitch-track': {
              opacity: 0.5,
            },
          },
          '&.Mui-focusVisible .MuiSwitch-thumb': {
            color: '#33cf4d',
            border: '6px solid #fff',
          },
          '&.Mui-disabled .MuiSwitch-thumb': {
            color:
              theme.palette.mode === 'light' ? theme.palette.grey[100] : theme.palette.grey[600],
          },
          '&.Mui-disabled + .MuiSwitch-track': {
            opacity: theme.palette.mode === 'light' ? 0.7 : 0.3,
          },
        }),
        thumb: ({ theme }) => ({
          boxSizing: 'border-box',
          width: 22,
          height: 22,
          boxShadow: 'none',
        }),
        track: ({ theme }) => ({
          borderRadius: 26 / 2,
          backgroundColor: theme.palette.mode === 'light' ? '#E9E9EA' : '#39393D',
          opacity: 1,
          transition: theme.transitions.create(['background-color'], {
            duration: 500,
          }),
        }),
      },
    },
    MuiCssBaseline: {
      styleOverrides: `
          @font-face {
            font-display: swap;
            font-family: 'Inter';
            font-style: oblique 0deg 10deg;
            font-weight: 1 999;
            src: local('Inter'), local('Inter-Regular'), url(${Inter}) format('woff2');
          }
        `,
    },
    MuiTypography: {
      defaultProps: {
        variantMapping: {
          // Map custom variants to render different elements by default?
          // headline: 'h1',
        },
      },
    },
    MuiFormHelperText: {
      styleOverrides: {
        root: {
          top: 32,
          marginLeft: '0px !important',
        },
      },
    },
    MuiDivider: {
      styleOverrides: {
        root: {
          borderColor: 'rgba(0, 0, 0, 0.10)',
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        sizeMedium: {
          height: 36,
        },
        sizeSmall: {
          height: 32,
          padding: '6px 8px',
          borderRadius: '6px',
        },
        sizeLarge: {
          height: 42,
        },
        root: {
          padding: '11px 16px',
          borderRadius: '10px',
          textTransform: 'none',
        },
        // @ts-ignore
        containedGradient: {
          ':disabled': {
            background: `rgba(0, 0, 0, 0.12)`,
          },
          background: `linear-gradient(40deg, #FF00FF -17.26%, #9116F9 49.66%, #3199FF 99.55%)`,
        },
        outlinedGradient: {
          border: '1px solid transparent !important',
          background:
            'linear-gradient(white, white) padding-box, linear-gradient(55.65deg, rgba(255, 0, 255, 0.5) 10.56%, rgba(145, 22, 249, 0.5) 51.7%, rgba(49, 153, 255, 0.5) 100%)border-box !important',
          '&:hover': {
            borderWidth: '0px !important',
            background:
              'linear-gradient(55.65deg, rgba(255, 0, 255, 0.2) 10.56%, rgba(145, 22, 249, 0.2) 51.7%, rgba(49, 153, 255, 0.2) 100%) !important',
          },
          '.MuiTouchRipple-root span': {
            backgroundColor:
              'linear-gradient(55.65deg, rgba(255, 0, 255, 0.2) 10.56%, rgba(145, 22, 249, 0.2) 51.7%, rgba(49, 153, 255, 0.2) 100%) !important',
          },
        },
        outlinedInfo: {
          border: `1px solid rgb(230, 230, 230)`,
          ':hover': {
            border: `1px solid ${color.shade30}`,
          },
        },
      },
    },
    MuiDialog: {
      styleOverrides: {
        paper: {
          boxShadow:
            'rgba(0, 0, 0, 0.2) 0px 11px 15px -7px, rgba(0, 0, 0, 0.14) 0px 24px 38px 3px, rgba(0, 0, 0, 0.12) 0px 9px 46px 8px !important',
        },
      },
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          justifyContent: 'flex-start',
          '& + .MuiDivider-root': {
            marginTop: '0px',
            marginBottom: '0px',
          },
        },
      },
    },
    MuiMenu: {
      styleOverrides: {
        paper: {
          borderRadius: '10px',
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        outlined: {
          border: '1px solid #E6E6E6 !important',
        },
        root: ({ theme }) => ({
          // boxShadow: 'none',
          // borderRadius: theme.spacing(2),
          borderRadius: '20px',
        }),
      },
    },
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          padding: 0,
          maxWidth: 'none',
          backgroundColor: color.palette.bigStone,
          pointerEvents: 'auto',
        },
        arrow: {
          color: color.palette.bigStone,
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        notchedOutline: ({ theme }) => ({
          // @ts-ignore
          borderColor: theme.palette.common.border,
        }),
      },
    },
    MuiDataGrid: {
      styleOverrides: {
        columnSeparator: {
          visibility: 'visible',
        },
        root: ({ theme }) => ({
          backgroundColor: theme.palette.common.white,
          borderRadius: 16,
          border: '1px solid #E6E6E6',
          '& .MuiDataGrid-virtualScroller': {
            borderTopLeftRadius: '0px !important',
            borderTopRightRadius: '0px !important',
          },
          '& .MuiDataGrid-columnHeader': {
            borderBottom: 'none !important',
          },
          '& .MuiDataGrid-topContainer': {
            ':after': {
              width: '0px !important',
            },
          },
          '& .MuiDataGrid-iconButtonContainer > span > .MuiBadge-badge': {
            right: '4px !important',
          },
          '& .MuiDataGrid-columnHeaderTitleContainerContent': {
            width: '100% !important',
            '& > div > span': {
              color: '#4F5E74',
              fontWeight: 700,
            },
          },
          '& .MuiDataGrid-columnHeaderTitleContainerContent > .MuiCheckbox-root': {
            marginLeft: '4px',
          },
          '& .MuiDataGrid-columnHeader--filtered': {
            '& .FilterButton': {
              visibility: 'visible !important',
            },
            '& .FilterButton > button': {
              width: 'auto',
              padding: '8px',
            },
          },
        }),
        overlay: ({ theme }) => ({
          backgroundColor: theme.palette.common.white,
        }),
        cell: ({ theme }) => ({
          ':focus-within': {
            outline: 'none',
          },
          '> a, div > a': {
            textDecoration: 'none',
            color: theme.palette.common.black,
            fontWeight: 600,
            fontSize: 14,
            '&:hover': {
              textDecoration: 'underline !important',
            },
          },
          borderBottom: 'none',
          alignItems: 'center',
          borderTop: `1px solid ${theme.palette.common.border}`,
        }),
        footerContainer: ({ theme }) => ({
          padding: '0px 10px 0px 10px',
          borderColor: `${theme.palette.common.border} !important`,
        }),
        topContainer: {
          ':after': {
            width: '0px !important',
          },
        },

        columnHeaderTitleContainer: ({ theme }) => ({
          flexDirection: 'row !important',
          '& > .MuiDataGrid-iconButtonContainer > svg': {
            fill: theme.primary,
            height: 450,
          },
          '& > .FilterButton': {
            visibility: 'hidden',
            display: 'contents',
          },
          '& .FilterButton > button': {
            width: '0px',
            padding: '0px',
          },
          '&:hover': {
            '& > .FilterButton': {
              visibility: 'visible',
              display: 'contents',
            },
            '& .FilterButton > button': {
              width: 'auto',
              padding: '8px',
            },
          },
        }),
        columnHeader: {
          flexDirection: 'row',
          ':focus-within': {
            outline: 'none !important',
          },
        },
        columnHeaderRow: ({ theme }) => ({
          background: `${theme.palette.table.columnHeader.main} !important`,
          borderColor: theme.palette.common.border,
          borderBottom: 'none',
        }),
      },
    },
    MuiDialogActions: {
      styleOverrides: {
        root: {
          padding: '20px',
          '> :not(:first-of-type)': {
            marginLeft: '12px',
          },
        },
      },
    },
    MuiTablePagination: {
      styleOverrides: {
        root: {
          ':last-child': {
            border: 'none',
          },
        },
        select: {
          fontSize: 12,
        },
        toolbar: {
          padding: '0px !important',
        },
      },
    },
  },
  // spacing: [0, 4, 8, 16, 32, 40],
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 900,
      lg: 1200,
      xl: 1536,
    },
  },
  palette,
  typography: { ...typography, fontFamily: 'Inter' },
} as any

export const muiTheme = responsiveFontSizes(createTheme(baseTheme))
