import React, { SyntheticEvent, useState } from 'react'
import { IconButton, TextField } from '@mui/material'
import { CloseRounded, DragHandle } from '@mui/icons-material'
import { color } from '@trivie/ui-web/theme'
import { Tooltip } from '@trivie/ui-web/components/tooltip'
import { Text } from '@trivie/ui-web/components/text'
import { QuestionTypeMenu } from './Select'
import { observer } from 'mobx-react-lite'
import { SurveyQuestion } from '@trivie/core/src/models/Survey'
import { Instance } from 'mobx-state-tree'

interface BaseQuestionProps {
  question: Instance<typeof SurveyQuestion>
  children: any
  disabled?: boolean
  handleDrag?: () => void
  handleDrop?: () => void
}

export const BaseQuestion = observer((props: BaseQuestionProps) => {
  const { question, handleDrag, children, disabled, handleDrop } = props

  const [showCharacterCount, setShowCharacterCount] = useState(false)
  const [grabbing, setGrabbing] = useState(false)
  const [hover, setHover] = useState(false)

  const onDrag = (e: SyntheticEvent) => {
    e.preventDefault()
  }

  const onDrop = () => {
    if (handleDrop) {
      handleDrop()
    }
    setGrabbing(false)
  }

  const onInputBlur = () => {
    setShowCharacterCount(false)
    question.setShowErrors(true)
  }

  return (
    <div
      draggable
      onDragOver={onDrag}
      onDragStart={handleDrag}
      onPointerLeave={() => setHover(false)}
      onMouseEnter={() => setHover(true)}
      onDrop={onDrop}
      style={ROOT}
    >
      <div style={CONTAINER}>
        {hover && !disabled && (
          <DragHandle
            onMouseDown={() => setGrabbing(true)}
            onMouseUp={() => setGrabbing(false)}
            style={{ ...HANDLE, cursor: grabbing ? 'grabbing' : 'grab' }}
          />
        )}
        <div style={QUESTION_ROOT}>
          <div style={CONTENT}>
            <Tooltip
              open={question.tooLong}
              placement="right"
              title="That's a long question!"
              text="Make sure your questions are under 500 characters."
            >
              <div style={QUESTION_TEXT}>
                <TextField
                  variant="standard"
                  multiline
                  onFocus={() => setShowCharacterCount(true)}
                  onBlur={onInputBlur}
                  onChange={(e) => question.setText(e.target.value)}
                  value={question.text}
                  placeholder="Enter a question"
                  style={QUESTION_TEXT_FIELD}
                  InputProps={{
                    style: {
                      ...QUESTION_TEXT_INPUT,
                      color: question.tooLong || question.empty ? color.red : color.black,
                    },
                    disableUnderline: true,
                  }}
                  disabled={disabled}
                />
                {disabled ? null : (
                  <IconButton onClick={question.remove} style={DELETE_BUTTON} size="large">
                    <CloseRounded style={DELETE_ICON} />
                  </IconButton>
                )}
              </div>
            </Tooltip>
            {children}
            <div
              style={{
                ...CHAR_COUNT,
                visibility: showCharacterCount ? 'visible' : 'hidden',
                bottom: question.question_type === 'open_text' ? -10 : 57,
              }}
            >
              <Text
                variant="body2"
                style={{
                  color: question.text && question.text.length <= 500 ? color.green : color.red,
                }}
                text={`${question.text?.length || 0} / ${500} Characters`}
              />
            </div>
          </div>
        </div>
        <QuestionTypeMenu disabled={disabled} hide={disabled} question={question} />
      </div>
    </div>
  )
})

const ROOT: React.CSSProperties = { flexDirection: 'column', display: 'flex', width: '100%' }
const DELETE_BUTTON: React.CSSProperties = { padding: 0, right: -8 }
const DELETE_ICON: React.CSSProperties = { color: 'rgba(174, 175, 177, 1)', fontSize: 18 }
const QUESTION_TEXT_FIELD: React.CSSProperties = { display: 'flex', width: '100%' }
const QUESTION_TEXT_INPUT: React.CSSProperties = {
  borderBottom: 'none',
  color: color.black,
  fontWeight: 600,
  fontSize: 16,
  lineHeight: '20px',
}
const CONTAINER: React.CSSProperties = {
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'start',
  marginTop: 32,
  position: 'relative',
  left: -32,
}
const QUESTION_ROOT: React.CSSProperties = {
  width: '100%',
  border: 'none',
  paddingRight: 20,
  paddingLeft: 32,
}
const CONTENT: React.CSSProperties = {
  display: 'flex',
  width: '100%',
  height: '100%',
  flexDirection: 'column',
  justifyContent: 'space-between',
  minHeight: 0,
}
const QUESTION_TEXT: React.CSSProperties = {
  alignItems: 'center',
  flexDirection: 'row',
  display: 'flex',
  justifyContent: 'space-between',
  padding: 8.5,
  paddingRight: 20,
  paddingLeft: 20,
  borderRadius: 10,
  marginBottom: 20,
  backgroundColor: 'rgba(240, 242, 245, 0.5)',
}
const HANDLE: React.CSSProperties = { position: 'absolute', top: 12 }
const CHAR_COUNT: React.CSSProperties = {
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'flex-end',
  position: 'relative',
}
