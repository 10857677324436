import React, { useEffect, useRef, useState } from 'react'

import { observer } from 'mobx-react-lite'
import { Container, IconButton, TextField } from '@mui/material'
import { AutoFixHigh, KeyboardArrowDown, KeyboardArrowUp, Search } from '@mui/icons-material'
import { createPopper } from '@popperjs/core'
import { useEditor, EditorContent } from '@tiptap/react'
import StarterKit from '@tiptap/starter-kit'
import { TableHeader } from '@tiptap/extension-table-header'

import { useStores } from '@trivie/core'
import { scrollToCitation } from '@trivie/core/src/utils/scrollToCitation'
import { color } from '@trivie/ui-web/theme'
import { Text } from '@trivie/ui-web/components/text'

import { Switch } from '@trivie/ui-web/components/switch/switch'
import { VideoPlayer } from './video-player'

import { Dropdown, DropdownMenuItem, DropdownNestedMenuItem } from './dropdown/dropdown'
import { Sentence } from './nodes/paragraph/sentence'
import { TListItem } from './nodes/list/list-item'
import { TParagraph } from './nodes/paragraph/paragraph'
import { TTableRow } from './nodes/table/table-row'
import { TTable } from './nodes/table/table'

import './editor.css'
import { TTableCell } from './nodes/table/table-cell'

// const mock = require('./mock.json')

const Mark = require('mark.js')

interface DocumentViewerProps {
  quiz: any
  onboarding?: any
}

export const DocumentViewer = observer((props: DocumentViewerProps) => {
  const { onboarding, quiz } = props
  const { resourceStore } = useStores()

  const [mouseDown, setMouseDown] = useState(true)
  const [showSearch, setShowSearch] = useState(false)
  const [selectedText, setSelectedText] = useState('')
  const [selectedNodes, setSelectedNodes] = useState<any>([])

  const popper = document.querySelector('#popper') as any

  const editor = useEditor({
    editable: false,
    // eslint-disable-next-line
    onSelectionUpdate: ({ editor, transaction }) => {
      const { state } = editor
      const { selection } = transaction
      const { from, to } = selection

      // Set selected text content
      setSelectedText(state.doc.textBetween(from, to, ''))

      // Recursively gather node ids between selection positions
      const nodes = [] as any
      state.doc.nodesBetween(from, to, (node) => {
        if (node.attrs.node_id) {
          nodes.push(node.attrs.node_id)
        }
      })
      setSelectedNodes(nodes)
    },
    extensions: [
      StarterKit.configure({
        gapcursor: false,
        heading: { levels: [1, 2, 3] },
        paragraph: false,
        listItem: false,
      }),
      Sentence.configure({ quiz, selectQuestion: (q) => quiz?.selectCitation(q) }),
      TListItem.configure({ quiz, selectQuestion: (q) => quiz?.selectCitation(q) } as any),
      // @ts-ignore
      TTable.configure({ quiz, selectQuestion: (q) => quiz?.selectCitation(q) }),
      TParagraph.configure({ quiz, selectQuestion: (q) => quiz?.selectCitation(q) } as any),
      TTableCell.configure({
        HTMLAttributes: { class: 'document-table-cell' },
        // @ts-ignore
        quiz,
        selectQuestion: (q) => quiz?.selectCitation(q),
      }),
      TTableRow.configure({
        HTMLAttributes: {
          class: 'document-table-row',
        },
        quiz,
        selectQuestion: (q) => quiz?.selectCitation(q),
      } as any),
      TableHeader.configure({
        HTMLAttributes: {
          class: 'document-table-header',
        },
      }),
    ],
    content: quiz?.selectedDocument?.nodes ?? {},
    // content: mock.nodes,
  })

  useEffect(() => {
    if (quiz?.selectedDocument && editor) {
      setTimeout(() => {
        editor?.commands.setContent({ type: 'doc', content: quiz?.selectedDocument.nodes.content })
      }, 0)
    }
  }, [
    editor,
    quiz?.questions?.length,
    quiz?.generatingQuestions,
    quiz?.generatingNodes.length,
    quiz?.selectedDocument,
    quiz?.stopGenerating,
    quiz?.questions[0]?.generated_question_id,
  ])

  const generateGetBoundingClientRect = (x = 0, y = 0) => {
    return () => ({
      width: 0,
      height: 0,
      top: y,
      right: x,
      bottom: y,
      left: x,
    })
  }

  const [virtualElement, setVirtualElement] = useState<any>({
    getBoundingClientRect: generateGetBoundingClientRect(),
  })

  let mouseUpListener, selectionChangeListener, posX, posY

  const virtualElementRef = React.useRef(virtualElement)
  const syncVirtualElement = (data) => {
    virtualElementRef.current = data
    setVirtualElement(data)
  }

  const instance = createPopper(virtualElement, popper, {
    modifiers: [{ name: 'offset', options: { offset: [10, 20], placement: 'auto' } }],
  })

  const [pos, setPos] = useState({ x: 0, y: 0 })

  const handleMouseUp = ({ clientX: x, clientY: y }) => {
    if (onboarding) {
      return
    }

    setMouseDown(false)
    if (window.innerHeight - 100 < y) {
      posY = y - 40
    } else {
      posY = y
    }
    posX = x
    setPos({ x: posX, y: posY })
    syncVirtualElement({
      ...virtualElementRef.current,
      getBoundingClientRect: () => ({
        width: 0,
        height: 0,
        top: posY,
        right: posX,
        bottom: posY,
        left: posX,
      }),
    })
    instance.update()
  }

  useEffect(() => {
    mouseUpListener = document.addEventListener('mouseup', handleMouseUp)
    selectionChangeListener = document.addEventListener('selectionchange', (e) =>
      setMouseDown(true),
    )

    return () => {
      document.removeEventListener('mousedown', mouseUpListener)
      document.removeEventListener('selectionchange', selectionChangeListener)
    }
  }, [])

  const generateFromSelection = (type) => {
    if (editor && selectedNodes) {
      quiz?.set('showSettings', false)
      selectedNodes.map((id) => {
        document.querySelector(`[node_id="${id}"]`)?.classList.add('GeneratingFact')
      })
      const generatingNodes = quiz?.generatingNodes || []
      quiz.set('generatingNodes', [...generatingNodes, ...selectedNodes])
      quiz
        ?.generateQuestions(
          quiz?.mercuryResource.resource_id,
          1,
          [type],
          selectedNodes,
          selectedNodes.length > 1 || selectedText.length > 1000,
        )
        .then((data) => {
          quiz.set(
            'generatingNodes',
            quiz?.generatingNodes.filter((n) => !selectedNodes.includes(Number(n))),
          )
          quiz?.selectQuestion(quiz?.questions[quiz?.questions.length - 1])
          setTimeout(() => {
            scrollToCitation(quiz.selectedQuestion.generated_question_id)
          }, 500)
        })
        .finally(() => {
          selectedNodes.map((id) => {
            document.querySelector(`[node_id="${id}"]`)?.classList.remove('GeneratingFact')
          })
        })

      if (window.getSelection) {
        window.getSelection()?.removeAllRanges()
      } else if (document.getSelection) {
        document.getSelection()?.empty()
      }
      setSelectedNodes([])
    }
  }

  const attachResource = (e) => {
    if (e.target.checked) {
      quiz.addResource(quiz.mercuryResource)
    } else {
      quiz.removeResource(quiz.mercuryResource)
    }
  }

  const [keywords, setKeywords] = useState<any>()

  const markInstance = new Mark(document.querySelector('.ProseMirror'))
  const search = (keyword) =>
    markInstance.unmark({
      done: function () {
        markInstance.mark(keyword.trim(), {
          separateWordSearch: false,
          accuracy: 'complementary',
          diacritics: true,
          debug: false,
          done: () => {
            setKeywords(document.querySelectorAll('mark'))
          },
        })
      },
    })

  const [selectedKeyword, setSelectedKeyword] = useState()

  const page = (next: boolean) => {
    if (keywords && keywords.length > 0) {
      const idx = keywords?.length === 1 ? 0 : next ? 1 : -1
      const selected = keywords[Array.prototype.slice.call(keywords).indexOf(selectedKeyword) + idx]
      keywords.forEach((mark) => mark.setAttribute('class', ''))
      if (selected) {
        selected.setAttribute('class', 'SelectedKeyword')
        selected.scrollIntoView({ behavior: 'smooth', inline: 'center', block: 'center' })
        setSelectedKeyword(selected)
      }
    }
  }

  const onSearchChange = (e) => {
    search(e.target.value)
  }

  const playerRef = useRef() as any

  const attached = quiz?.resources?.find((r) => r.resource_id === quiz?.mercuryResource.resource_id)

  return (
    <div
      className="resourceContainer"
      style={{ height: '100%', flexDirection: 'column', display: 'flex', overflow: 'hidden' }}
    >
      <div style={TOOLBAR}>
        {(quiz?.mercuryResource?.converted_pdf_url || quiz?.mercuryResource?.filename_or_url) && (
          <a
            style={PREVIEW}
            target="_blank"
            rel="noopener noreferrer"
            href={
              quiz.mercuryResource?.filename_or_url
                ? quiz.mercuryResource?.filename_or_url
                : quiz.mercuryResource?.converted_pdf_url
                  ? quiz.mercuryResource?.converted_pdf_url
                  : ''
            }
          >
            <Text style={{ color: color.blue }} text="Preview" variant="body1Medium" />
          </a>
        )}
        <IconButton
          sx={{
            svg: { color: showSearch ? color.blue : color.black },
          }}
          onClick={() => {
            setShowSearch(!showSearch)
            setTimeout(() => {
              const input = document.getElementById('search-field') as any
              if (input) {
                input.focus()
              }
            }, 200)
          }}
        >
          <Search style={{ fontSize: 22 }} htmlColor={color.shade70} />
        </IconButton>
        <div style={ATTACH}>
          {!onboarding && (
            <>
              <Text
                style={{ marginRight: 8 }}
                text="Display Source To Users"
                variant="body2Medium"
              />
              <Switch
                color="success"
                checked={Boolean(attached)}
                onChange={(e) => attachResource(e)}
                size="small"
              />
            </>
          )}
        </div>
      </div>
      {showSearch && (
        <div style={{ paddingLeft: 24, paddingRight: 24, marginBottom: 12 }}>
          <TextField
            id="search-field"
            type="text"
            name="keyword"
            variant="standard"
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                page(true)
              }
            }}
            fullWidth
            InputProps={{
              style: { paddingRight: 90 },
              startAdornment: (
                <Search style={{ marginRight: 12, fontSize: 22 }} htmlColor={color.shade70} />
              ),
              endAdornment: (
                <div style={INPUT_SEARCH}>
                  <Text
                    style={{ marginRight: 8 }}
                    text={`${
                      !keywords || keywords?.length === 0
                        ? 0
                        : keywords?.length === 1
                          ? 1
                          : Array.prototype.slice.call(keywords).indexOf(selectedKeyword) + 1
                    } of ${keywords?.length ?? 0}`}
                    variant="body1"
                  />
                  <div style={{ flexDirection: 'column', display: 'flex' }}>
                    <IconButton sx={{ top: 2, padding: 0 }} onClick={() => page(false)}>
                      <KeyboardArrowUp style={{ fontSize: 14 }} htmlColor={color.shade70} />
                    </IconButton>
                    <IconButton sx={{ bottom: 2, padding: 0 }} onClick={() => page(true)}>
                      <KeyboardArrowDown style={{ fontSize: 14 }} htmlColor={color.shade70} />
                    </IconButton>
                  </div>
                </div>
              ),
            }}
            style={{ fontSize: 16, fontWeight: 600 }}
            placeholder="Search"
            onChange={onSearchChange}
          />
        </div>
      )}
      {selectedNodes?.length > 0 && selectedText?.length > 0 && !mouseDown && (
        <Dropdown
          posX={pos.x - 200}
          posY={pos.y}
          keepOpen={true}
          isOpen={true}
          trigger={<div />}
          menu={[
            <DropdownNestedMenuItem
              leftIcon={<AutoFixHigh htmlColor={color.shade70} fontSize="small" />}
              label="Generate Question"
              menu={[
                <DropdownMenuItem onClick={() => generateFromSelection('fill_in_the_blank')}>
                  <Text style={SHADE70} variant="body2Medium" text="Fill-In-The-Blank" />
                </DropdownMenuItem>,
                <DropdownMenuItem
                  sx={{ height: 32 }}
                  onClick={() => generateFromSelection('multiple_choice')}
                >
                  <Text style={SHADE70} variant="body2Medium" text="Multiple Choice" />
                </DropdownMenuItem>,
                <DropdownMenuItem onClick={() => generateFromSelection('true_false')}>
                  <Text style={SHADE70} variant="body2Medium" text="True / False" />
                </DropdownMenuItem>,
              ]}
            />,
          ]}
        />
      )}
      {quiz?.mercuryResource.isYoutube ? (
        <>
          <Container maxWidth="sm">
            <VideoPlayer url={quiz?.mercuryResource.filename_or_url} ref={playerRef} />
          </Container>
          <Text
            component="div"
            ml={3}
            text="Transcript:"
            variant="body2Emphasized"
            mt={2}
            mb={'4px'}
            sx={{ color: color.shade50 }}
          />
        </>
      ) : null}
      <div
        id="editor"
        className="editorContainer"
        style={{
          paddingBottom: 24,
          minHeight: 0,
          height: '100%',
          flexDirection: 'column',
          display: 'flex',
        }}
      >
        <EditorContent editor={editor} />
      </div>
    </div>
  )
})

const TOOLBAR: React.CSSProperties = {
  flexDirection: 'row',
  alignItems: 'center',
  display: 'flex',
  paddingLeft: 24,
  paddingRight: 24,
  justifyContent: 'space-between',
  marginBottom: 16,
}
const PREVIEW: React.CSSProperties = {
  border: `1px solid ${color.blue}`,
  paddingTop: 8,
  paddingBottom: 8,
  paddingRight: 16,
  paddingLeft: 16,
  borderRadius: 12,
  background: color.white,
  textDecoration: 'none',
  marginRight: 12,
}
const SHADE70: React.CSSProperties = { color: color.shade70 }
const ATTACH: React.CSSProperties = {
  flexDirection: 'row',
  display: 'flex',
  alignItems: 'center',
  flex: 1,
  justifyContent: 'flex-end',
}
const INPUT_SEARCH: React.CSSProperties = {
  alignItems: 'center',
  flexDirection: 'row',
  display: 'flex',
  position: 'absolute',
  right: 0,
}
