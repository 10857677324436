import React, { useEffect, useState } from 'react'
import { observer } from 'mobx-react-lite'
import { Mail } from '@mui/icons-material'
import { CircularProgress, Paper } from '@mui/material'

import { ReactComponent as ExcelFile } from '@trivie/ui-mobile/assets/icons/svg/ExcelFile.svg'
import { Text } from '@trivie/ui-web/components/text'
import { Button } from '@trivie/ui-web/components/button'
import { useStores } from '@trivie/core'
import { EmailReport } from '../email-report'

const TrivieReports: React.FunctionComponent = observer(() => {
  const { reportStore, manageUserStore } = useStores()
  const { exportableReports, getExportableReports, emailReport } = reportStore
  const { userProfile } = manageUserStore
  const [showEmailDialog, setShowEmailDialog] = useState(false)
  const [selectedReport, setSelectedReport] = useState() as any
  const [loading, setLoading] = useState(true)

  const sendReport = (email: string, report: any) => {
    emailReport(email, report.report_id)
  }

  useEffect(() => {
    getExportableReports().then(() => setLoading(false))
  }, [])

  return loading ? (
    <div style={LOADER}>
      <CircularProgress />
    </div>
  ) : exportableReports ? (
    <>
      {exportableReports.map((report: any, index: number) => {
        return (
          <Paper
            key={index}
            variant="outlined"
            style={{
              marginBottom: 20,
              paddingTop: 16,
              paddingBottom: 16,
              paddingLeft: 23,
              paddingRight: 23,
            }}
          >
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <ExcelFile />
                <div style={{ display: 'flex', flexDirection: 'column', marginLeft: 19 }}>
                  <Text variant="body1Emphasized" text={report.title} />
                  <Text variant="body2" text={report.description} />
                </div>
              </div>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <Button
                  variant="outlined"
                  color="info"
                  startIcon={<Mail />}
                  size="small"
                  onClick={() => {
                    setSelectedReport(report)
                    setShowEmailDialog(true)
                  }}
                  text="Email"
                />
              </div>
            </div>
          </Paper>
        )
      })}
      <EmailReport
        onClose={() => setShowEmailDialog(false)}
        open={showEmailDialog}
        report={selectedReport}
        userEmail={userProfile?.email}
        onSend={sendReport}
      />
    </>
  ) : null
})

export const TrivieReportsTab = () => {
  return {
    name: 'Standard Reports',
    path: `/insights/reports/trivie`,
    component: () => <TrivieReports />,
  }
}

const LOADER: React.CSSProperties = {
  display: 'flex',
  flex: 1,
  justifyContent: 'center',
  flexDirection: 'column',
  alignItems: 'center',
  height: window.innerHeight / 1.5,
}
