import React, { useEffect, useRef, useState } from 'react'
import { observer } from 'mobx-react-lite'
import { People } from '@mui/icons-material'
import { InputBase } from '@mui/material'
import { Avatar } from '../avatar'
import { color } from '../../theme'
import styles from './user-selector.module.css'
import { Text } from '../text'

export interface UserSelectorProps {
  selectedUsers: any
  users: any
  onChange: (users: any) => void
  onSearch: (filter: string) => void
}

export const UserSelector = observer((props: UserSelectorProps) => {
  const { selectedUsers, onChange, users, onSearch } = props
  // TODO pass in search function and returned users
  // const { getAddableUsers, addableUsers  } = groupStore
  const [showPopper, setShowPopper] = useState(false)
  const [usersFilter, setUsersFilter] = useState<string | undefined>(undefined)

  const selectorRef = useRef(null)
  const inputRef = useRef(null)

  const handleBlur = (e: any) => {
    if (!e.composedPath().some((el: any) => el === selectorRef.current)) {
      setShowPopper(false)
    }
  }

  const handleFocus = () => {
    setShowPopper(true)
  }

  useEffect(() => {
    window.addEventListener('click', handleBlur)

    return () => {
      window.removeEventListener('click', handleBlur)
    }
  }, [])

  useEffect(() => {
    if (usersFilter && usersFilter.length > 2) {
      onSearch(usersFilter)
    }
  }, [usersFilter])

  const handleUserSelection = (user: any) => {
    onChange(user)
  }

  const handleUsersFilterChange = (e: any) => {
    setUsersFilter(e.target.value)
  }

  const selectedUserIds = (selectedUsers ?? []).map((user: any) => user.id)
  const filteredUsers = (users ?? []).filter((user: any) => !selectedUserIds.includes(user.id))

  return (
    <div style={{ position: 'relative' }} ref={selectorRef}>
      <div
        onFocus={handleFocus}
        style={{
          display: 'flex',
          flexWrap: 'wrap',
          alignItems: 'center',
          borderStyle: 'solid',
          borderWidth: 1,
          borderColor: showPopper ? color.palette.blueLink : color.palette.paperBorder,
          borderRadius: 6,
          backgroundColor: color.palette.white,
          paddingLeft: 13,
          paddingRight: 13,
        }}
      >
        <People htmlColor={color.palette.grey} />
        <InputBase
          ref={inputRef}
          value={usersFilter}
          placeholder="Search name or emails"
          style={{ height: 29, marginLeft: 12, marginTop: 6, marginBottom: 6 }}
          onChange={handleUsersFilterChange}
        />
      </div>

      {filteredUsers.length ? (
        <div
          style={{
            visibility: showPopper ? 'visible' : 'hidden',
            width: '100%',
            display: 'flex',
            flexDirection: 'column',
            border: `1px solid ${color.palette.paperBorder}`,
            borderRadius: 6,
            position: 'absolute',
            paddingTop: 4,
            paddingBottom: 4,
            flexWrap: 'wrap',
            backgroundColor: color.white,
            marginTop: 4,
          }}
        >
          {filteredUsers.map((user: any) => (
            <div
              className={styles.selectable}
              style={{
                display: 'flex',
                paddingTop: 4,
                paddingBottom: 4,
                paddingLeft: 20,
                alignItems: 'center',
              }}
              onClick={() => handleUserSelection(user)}
            >
              <Avatar name={`${user.first_name} ${user.last_name}`} url={user.photo_url} />
              <div style={{ display: 'flex', flexDirection: 'column', marginLeft: 8 }}>
                <Text variant="body1Emphasized" text={`${user.first_name} ${user.last_name}`} />
                <Text
                  variant="body2Emphasized"
                  text={user.email}
                  style={{ color: color.shade50 }}
                />
              </div>
            </div>
          ))}
        </div>
      ) : null}
    </div>
  )
})
