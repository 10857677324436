export const typography = {
  headline: {
    color: '#000000',
    fontStyle: 'normal',
    fontWeight: 700,
    fontSize: 24,
    lineHeight: '29px',
    letterSpacing: '-0.2px',
  },
  label: {
    color: '#000000',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: 17,
    lineHeight: '22px',
    letterSpacing: '-0.4px',
  },
  labelMedium: {
    color: '#000000',
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: 17,
    lineHeight: '22px',
    letterSpacing: '-0.2px',
  },
  labelEmphasized: {
    color: '#000000',
    fontStyle: 'normal',
    fontWeight: 600,
    fontSize: 17,
    lineHeight: '22px',
    letterSpacing: '-0.1px',
  },
  title: {
    color: '#000000',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '16px',
    lineHeight: '20px',
    letterSpacing: '-0.3px',
  },
  titleMedium: {
    color: '#000000',
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: 16,
    lineHeight: '20px',
    letterSpacing: '-0.2px',
  },
  titleEmphasized: {
    color: '#000000',
    fontStyle: 'normal',
    fontWeight: 600,
    fontSize: 16,
    lineHeight: '20px',
    letterSpacing: '-0.2px',
  },
  body1: {
    color: '#000000',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: 14,
    lineHeight: '17px',
    letterSpacing: '-0.15px',
  },
  body1Medium: {
    color: '#000000',
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: 14,
    lineHeight: '17px',
    letterSpacing: '-0.15px',
  },
  body1Emphasized: {
    color: '#000000',
    fontStyle: 'normal',
    fontWeight: 600,
    fontSize: 14,
    lineHeight: '18px',
    letterSpacing: '-0.2px',
  },
  body2: {
    color: '#000000',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: 12,
    lineHeight: '15px',
    letterSpacing: '-0.2px',
  },
  body2Medium: {
    color: '#000000',
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: 12,
    lineHeight: '14.52px',
    letterSpacing: '-0.2px',
  },
  body2Emphasized: {
    color: '#000000',
    fontStyle: 'normal',
    fontWeight: 600,
    fontSize: 12,
    lineHeight: '14.52px',
    letterSpacing: '-0.2px',
  },
  subtitle: {
    color: '#000000',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: 9,
    lineHeight: '11px',
    letterSpacing: '-0.2px',
  },
  button: {
    fontStyle: 'normal',
    fontWeight: 600,
    fontSize: 14,
    lineHeight: '17px',
    letterSpacing: '-0.2px',
  },
} as any
