import React, { useEffect, useState } from 'react'
import { observer } from 'mobx-react-lite'
import { useStores } from '@trivie/core'
import { TableHelper } from '../../../../tables'
import { useRouteMatch } from 'react-router-dom'
import { TopicTable } from '../../../../tables/topic-table'

const Topics = observer(() => {
  const { manageUserStore } = useStores()
  const { getUserTopicKnowledgeGaps, getSelectedUserProfile, setSelectedUserId, selectedUserId } =
    manageUserStore

  const match = useRouteMatch<any>('/people/users/:id/topics')
  const userID = Number(match?.params.id)

  const [tableHelper] = useState(new TableHelper('list_user_topics', 'topics', 'users', userID))

  useEffect(() => {
    if (!selectedUserId) {
      setSelectedUserId(Number(match?.params.id))
    }
    setSelectedUserId(userID)
    getSelectedUserProfile()
    getUserTopicKnowledgeGaps()
  }, [])

  return <TopicTable tableHelper={tableHelper} />
})

export const UsersTopicsTab = (props: any) => {
  const { id } = props
  return {
    name: 'Topics',
    path: `/people/users/${id}/topics`,
    component: () => <Topics />,
  }
}
