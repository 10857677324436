import { TableApi } from '@trivie/core/src/services/api-objects'
import { initColumns } from '@trivie/ui-web/components/data-table/configure'
import { getFilterOperator } from '@trivie/ui-web/components/data-table/filter'
import { GridSortModel } from '@mui/x-data-grid-pro'

export type TableCategory =
  | 'users'
  | 'topics'
  | 'quizzes'
  | 'groups'
  | 'tags'
  | 'resources'
  | 'questions'
  | 'assignments'
  | 'surveys'
  | 'badges'
  | 'messages'
  | 'user_results'
  | 'quiz_results'
  | 'contests'
  | 'paths'

export class TableHelper {
  metaColumns: any[]
  options: any
  viewName: string
  filterModel: any
  columns: []
  columnVisibilityModel: any
  rows: any
  logicOperator: any
  parent: TableCategory
  id?: number | undefined
  viewID?: number
  type: any
  filter?: string
  custom?: boolean
  sortModel?: GridSortModel

  constructor(
    viewName: string,
    type: string,
    parent: TableCategory,
    id?: number | undefined,
    filter?: string,
    columns?: any,
  ) {
    this.metaColumns = []
    this.options = []
    this.viewName = viewName
    this.filterModel = { items: [] }
    this.columns = columns && columns.length > 0 ? columns : []
    this.columnVisibilityModel = {}
    this.rows = []
    this.logicOperator = 'and'
    this.id = id
    this.type = type
    this.parent = parent
    this.filter = filter
    this.custom = false
  }

  initRows = (rows, columns) => {
    return rows.map((row) => {
      const formattedRow = { id: row.id, is_gdpr: false, photo_url: row.photo_url } as any
      row.columns.forEach((col) => {
        if (col.content_alias) {
          formattedRow[col.content_alias] = col.value
        } else if (columns.some((v) => v.field === col.field || col.field === 'photo_url')) {
          formattedRow[col.field] = col.value
          formattedRow.is_gdpr = Boolean(col.is_gdpr)
        }
      })
      return formattedRow
    })
  }

  /**
   * initFilters
   * Create filter model for data table
   * @param columns
   * @returns FilterModel
   */
  initFilters = (columns, logicOperator) => ({
    items: columns
      .filter((column) => column.filter)
      .map((column, index) => {
        const option = this.options.find((opt: any) => opt.field === column.field) as any
        return {
          field: column.field,
          operator: getFilterOperator(column.filter.verb, column),
          value: option?.data_type === 'percent' ? column.filter.value * 100 : column.filter.value,
          id: index,
        }
      }),
    logicOperator,
    quickFilterExcludeHiddenColumns: false,
  })

  initSorting = (columns) => {
    this.sortModel = columns
      ?.filter((column) => column?.sort)
      .map((column) => ({ field: column.field, sort: column?.sort }))
  }

  load = async () => {
    try {
      // Configure Options
      const options = await TableApi.getViewOptions(this.viewName)
      this.viewID = options.data.view_id
      this.options = options.data.meta

      // Grab table column metadata and row data
      const [metadata, rows] = await TableApi.getTableData(
        this.parent,
        this.type,
        [],
        this.viewName,
        this.id,
        this.filter,
      )

      // Configure Columns
      this.metaColumns = metadata.data.columns
      const { columns, columnVisibilityModel } = initColumns(
        this.options,
        metadata.data.columns,
        this.filter,
      )
      this.columnVisibilityModel = columnVisibilityModel
      this.columns = columns

      // Configure Sorting Model
      this.initSorting(this.columns)

      // Configure Filters
      this.logicOperator = metadata.data.method
      this.filterModel = this.initFilters(this.columns, metadata.data.method)

      // Configure Rows
      this.rows = this.initRows(rows.data.rows, metadata.data.columns)
    } catch (e) {
      console.warn('Table failed to initialize data', e)
    }
  }

  saveLayout = async (columnState, orderedFields: string[], sorting) => {
    const columns = orderedFields
      .filter((field) => field !== '__check__' && this.columnVisibilityModel[field])
      .map((field) => {
        const sortModel = sorting.sortModel.find((c: any) => c.field === field)
        const { width, column_alias, filter, params } = columnState[field]
        return {
          column_alias,
          field: column_alias ? column_alias.split(':')[0] : field,
          width,
          sort: sortModel?.sort || null,
          ...(filter && { filter }),
          ...(params && column_alias && { params }),
        }
      })
    this?.save(columns, this.filterModel)
  }

  save = async (columns: any, filter: any) => {
    const arr = [...columns] as any

    // Always include identity/name columns for user tables
    if (this.type === 'users') {
      arr.push(
        {
          field: 'first_name',
          filter: null,
          is_gdpr: false,
          sort: null,
          width: null,
          column_alias: null,
        },
        {
          field: 'last_name',
          filter: null,
          is_gdpr: false,
          sort: null,
          width: null,
          column_alias: null,
        },
        {
          field: 'email',
          filter: null,
          is_gdpr: false,
          sort: null,
          width: null,
          column_alias: null,
        },
        {
          field: 'phone_number',
          filter: null,
          is_gdpr: false,
          sort: null,
          width: null,
          column_alias: null,
        },
      )
    }

    // Save and reload data
    try {
      return await TableApi.saveDataView({
        base_view_id: this.viewID,
        is_default_for_user: true,
        view_json: {
          view_name: this.viewName,
          method: filter.logicOperator,
          columns: arr,
        },
      }).then(async () => {
        return await this.load()
      })
    } catch (e) {
      return false
    }
  }
}
