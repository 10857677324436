import React, { useEffect, useState } from 'react'

import { Dialog } from '../dialogs/dialog'
import { Text } from '../text'
import { Button } from '../button'

export interface ConfirmDialogProps {
  open: boolean
  title: string
  message: string
  confirmedMessage?: string
  cancelMessage?: string
  confirmButton?: string
  danger?: boolean
  noButtonCapitalization?: boolean
  onConfirm: () => void
  onClose: () => void
  onPublish?: (() => void) | null
  handleCancel?: () => void
  hideCancel?: boolean
  bodyContent?: string | object
  contentStyle?: React.CSSProperties
}

export function ConfirmDialog(props: ConfirmDialogProps) {
  const {
    open,
    title,
    message,
    confirmedMessage,
    confirmButton,
    cancelMessage,
    danger,
    onConfirm,
    onClose,
    handleCancel,
    hideCancel,
    bodyContent,
    contentStyle,
  } = props

  const [isConfirmed, setIsConfirmed] = useState(false)

  const handleConfirm = () => {
    if (!confirmedMessage) {
      onConfirm()
      handleClose()
    } else {
      setIsConfirmed(true)
      onConfirm()
    }
  }

  const handleClose = () => {
    onClose()
    setTimeout(() => {
      setIsConfirmed(false)
    }, 200)
  }

  const getBodyContent = () => {
    switch (typeof bodyContent) {
      case 'string':
        return (
          <Text
            variant="body1Medium"
            text={bodyContent}
            style={{
              textAlign: 'center',
            }}
          />
        )
      case 'object':
        return bodyContent
      default:
        return null
    }
  }

  useEffect(() => {
    if (!open) {
      setIsConfirmed(false)
    }
  }, [open])

  const splitMessage = message?.split('\n')
  const splitConfirmMessage = confirmedMessage?.split('\n')

  return (
    <Dialog
      onClose={() => {
        if (handleCancel) {
          handleCancel()
        } else {
          handleClose()
        }
      }}
      title={title}
      maxWidth="xs"
      open={open}
      actions={
        isConfirmed ? (
          <Button onClick={handleClose} text="Close" />
        ) : (
          <>
            {!hideCancel && (
              <Button
                variant="outlined"
                color="info"
                onClick={() => {
                  if (handleCancel) {
                    handleCancel()
                  } else {
                    handleClose()
                  }
                }}
                text={cancelMessage ?? 'Cancel'}
              />
            )}
            {confirmButton && (
              <Button
                onClick={handleConfirm}
                color={danger ? 'error' : 'primary'}
                text={confirmButton}
              />
            )}
          </>
        )
      }
    >
      {isConfirmed ? (
        <>
          {splitConfirmMessage && splitConfirmMessage?.length > 1 ? (
            <div>
              {splitConfirmMessage?.map((m) => (
                <div style={{ marginBottom: 12 }}>
                  <Text variant="body1Medium" text={m} />
                </div>
              ))}
            </div>
          ) : (
            <Text
              component="div"
              variant="body1Medium"
              text={confirmedMessage}
              style={{ textAlign: 'center' }}
            />
          )}
        </>
      ) : (
        <>
          <div style={{ textAlign: 'center', ...contentStyle }}>
            <>
              {splitMessage?.length > 1 ? (
                <div>
                  {splitMessage?.map((m) => (
                    <div style={{ marginBottom: 12 }}>
                      <Text variant="body1Medium" text={m} />
                    </div>
                  ))}
                </div>
              ) : (
                message && (
                  <Text
                    component="div"
                    variant="body1Medium"
                    text={message}
                    style={{
                      textAlign: 'center',
                    }}
                  />
                )
              )}
              {getBodyContent()}
            </>
          </div>
        </>
      )}
    </Dialog>
  )
}
