import React, { useState } from 'react'
import { observer } from 'mobx-react-lite'

import { TableHelper } from '../../../../tables'
import { SurveyTable } from '../../../../tables/survey-table'

const ScheduledSurveys: React.FunctionComponent = observer(() => {
  const [tableHelper] = useState(
    new TableHelper('list_scheduled_surveys', 'surveys', 'surveys', undefined, 'scheduled'),
  )

  return <SurveyTable filter="scheduled" tableHelper={tableHelper} />
})

export const ScheduledSurveysTab = () => {
  return {
    name: 'Scheduled',
    path: `/activities/surveys/scheduled`,
    component: () => <ScheduledSurveys />,
  }
}
