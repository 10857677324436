import React from 'react'
import { Link } from 'react-router-dom'
import { Text } from '../text'
import { color } from '../../theme'
import { Instance } from 'mobx-state-tree'
import { QuizPctCorrectSummary } from '@trivie/core'
import { Meter } from '../meter/meter'

export interface QuizProgressProps {
  quiz: Instance<typeof QuizPctCorrectSummary>
  gradient: 'red' | 'green'
  style?: React.CSSProperties
}

export function QuizProgress(props: QuizProgressProps) {
  const { gradient, quiz, style } = props

  return (
    <div key={`${quiz.quiz_id}`} style={{ display: 'flex', flexDirection: 'row', ...style }}>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          flex: 1,
          justifyContent: 'center',
        }}
      >
        <Link to={`/content/topics/${quiz.quiz_id}/overview`} style={{ textDecoration: 'none' }}>
          <Text variant="body1Emphasized" text={quiz.quiz_title ?? ''} />
        </Link>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <Meter
            variant={gradient === 'red' ? 'weak' : 'strong'}
            value={quiz.pct_correct || 0}
            style={{ height: 6, flex: 1 }}
          />
          <Text
            variant="body1Emphasized"
            text={`${quiz.pct_correct || 0}%`}
            style={{ marginLeft: 28 }}
          />
          <Text
            variant="body1Emphasized"
            text="Correct"
            style={{ color: color.shade70, marginLeft: 4 }}
          />
        </div>
      </div>
    </div>
  )
}
