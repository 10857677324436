import React, { useEffect, useState } from 'react'
import { observer } from 'mobx-react-lite'
import { Box, Stack } from '@mui/material'
import Step from '@mui/material/Step'
import StepButton from '@mui/material/StepButton'
import Stepper from '@mui/material/Stepper'
import { ChevronLeftRounded } from '@mui/icons-material'
import { Instance } from 'mobx-state-tree'

import { Dialog } from '@trivie/ui-web/components/dialogs/dialog'
import { ContestCreateForm } from '@trivie/core/src/models/ContestCreateForm'
import { AssignmentApi } from '@trivie/core/src/services/api-objects/AssignmentApi'
import { ContestApi } from '@trivie/core/src/services/api-objects/ContestApi'
import { ContestHelper, useStores } from '@trivie/core'
import { Text } from '@trivie/ui-web/components/text'
import { Button } from '@trivie/ui-web/components/button'
import { color } from '@trivie/ui-web/theme/color'

import { ContestCompetitors, ContestDetails, ContestScoring, ContestContent } from './steps'

type CreateContestWizardProps = {
  open: boolean | undefined
  editingId?: number | null
  requestClose: () => void
}

export const CreateContestWizard = observer((props: CreateContestWizardProps) => {
  const { contestStore } = useStores()
  const { getContest, setTriggerRefresh } = contestStore
  const { open, editingId, requestClose } = props
  const [activeStep, setActiveStep] = useState(0)
  const [form, setForm] = useState<Instance<typeof ContestCreateForm> | null>(null)
  const steps = ['Competitors', 'Scoring', 'Content', 'Details']
  const [selectedAutocompleteOptions, setSelectedAutocompleteOptions] = useState<any>([])

  const handleAddItem = (selected: any[], removing?) => {
    selected.map((item: any) => {
      const found = form?.content?.find((c) => c.id === item.id && c.type === item.type)
      const toRemove = form?.content?.find(
        (c) => c.id === removing?.id && c.type === removing?.type,
      )
      if (found) {
        found.set('checked', true)
      }
      if (toRemove) {
        toRemove.set('checked', false)
      }
    })
    setSelectedAutocompleteOptions(selected)
  }

  useEffect(() => {
    if (open) {
      setActiveStep(0)
      let formDefaults = ContestHelper.createContestForm({})

      // Get available topics/quizzes
      AssignmentApi.getAssignmentContent().then((response: any) => {
        if (response.ok && response.data) {
          if (editingId) {
            getContest(editingId).then((data) => {
              const transformedTopics = response.data.topics.map((topic) => {
                const isTopicAssigned = data.topic_ids.some(
                  (topicId: any) => topicId === topic.topic_id,
                )
                return {
                  id: topic.topic_id,
                  type: 'topics',
                  label: topic.topic_title,
                  subLabel: `${topic.quizzes?.length ?? 0} ${
                    topic.quizzes?.length === 1 ? 'Quiz' : 'Quizzes'
                  }`,
                  checked: isTopicAssigned, // if editing fill this in based on form
                  subItems: topic.quizzes.map((q) => ({
                    parentId: topic.topic_id,
                    type: 'quizzes',
                    id: q.quiz_id,
                    label: q.quiz_title,
                    subLabel: `${q.question_count} ${
                      q.question_count === 1 ? 'Question' : 'Questions'
                    }`,
                    checked: false,
                  })),
                }
              })
              const transformedQuizzes = response.data.quizzes.map((quiz) => {
                const isQuizAssigned = data.quiz_ids.some((quizID: any) => quizID === quiz.quiz_id)
                return {
                  id: quiz.quiz_id,
                  type: 'quizzes',
                  label: quiz.quiz_title,
                  subLabel: `${quiz.question_count} ${
                    quiz.question_count === 1 ? 'Question' : 'Questions'
                  }`,
                  checked: isQuizAssigned, // if editing fill this in based on form
                }
              })
              const sel = [] as any
              transformedTopics.map((t) => {
                if (t.checked) {
                  sel.push(t)
                }
              })
              setSelectedAutocompleteOptions(sel)
              transformedQuizzes.map((q) => {
                if (q.checked) {
                  sel.push(q)
                }
              })
              setSelectedAutocompleteOptions(sel)
              formDefaults = ContestHelper.createContestForm(data)
              formDefaults.set('content', [...transformedTopics, ...transformedQuizzes])
              setForm(formDefaults)
            })
          } else {
            const transformedTopics = response.data.topics.map((topic) => {
              return {
                id: topic.topic_id,
                type: 'topics',
                label: topic.topic_title,
                subLabel: `${topic.quizzes?.length ?? 0} ${
                  topic.quizzes?.length === 1 ? 'Quiz' : 'Quizzes'
                }`,
                checked: false,
                subItems: topic.quizzes.map((q) => ({
                  parentId: topic.topic_id,
                  type: 'quizzes',
                  id: q.quiz_id,
                  label: q.quiz_title,
                  subLabel: `${q.question_count} ${
                    q.question_count === 1 ? 'Question' : 'Questions'
                  }`,
                  checked: false,
                })),
              }
            })
            const transformedQuizzes = response.data.quizzes.map((quiz) => {
              return {
                id: quiz.quiz_id,
                type: 'quizzes',
                label: quiz.quiz_title,
                subLabel: `${quiz.question_count} ${
                  quiz.question_count === 1 ? 'Question' : 'Questions'
                }`,
                checked: false,
              }
            })
            formDefaults.set('content', [...transformedQuizzes, ...transformedTopics])
            formDefaults.set('custom_scoring', {
              ...form?.custom_scoring,
              answers: true,
              badges: false,
              comments: false,
              posts: false,
              reinforcements: false,
              surveys: false,
              tests: false,
            })
            setForm(formDefaults)
          }
        } else {
          throw new Error(`Failed to get assignment content`)
        }
      })
    }
  }, [open])

  if (!form) {
    return null
  }

  const getStepContent = (step: number) => {
    switch (step) {
      case 0:
        return <ContestCompetitors editingId={editingId} form={form} />
      case 1:
        return <ContestScoring form={form} />
      case 2:
        return (
          <ContestContent
            handleAddItem={handleAddItem}
            selectedAutocompleteOptions={selectedAutocompleteOptions}
            form={form}
          />
        )
      case 3:
        return <ContestDetails form={form} />
      case 4:
        return (
          <div style={CONFIRM}>
            <Text
              style={{ color: color.shade70 }}
              variant="body1"
              text={
                editingId
                  ? 'Are you sure you want to edit this contest?'
                  : 'This will create a new contest. Are you sure?'
              }
            />
          </div>
        )
      case 5:
        return (
          <div style={CONFIRM}>
            <Text
              style={{ color: color.shade70 }}
              variant="body1"
              text={editingId ? 'Contest edited!' : 'Contest created!'}
            />
          </div>
        )
      default:
        return 'Unknown step'
    }
  }

  const isLastStep = () => activeStep > steps.length

  const handleNext = async () => {
    if (activeStep === 4) {
      const { payload } = form
      if (editingId) {
        ContestApi.editContest(payload).then(() => {
          setTriggerRefresh(true)
        })
      } else {
        ContestApi.createContest(payload).then(() => {
          setTriggerRefresh(true)
        })
      }
      setActiveStep(5)
    } else if (activeStep === 5) {
      setActiveStep(0)
      requestClose()
    } else {
      const newActiveStep = !isLastStep() ? activeStep + 1 : activeStep
      setActiveStep(newActiveStep)
    }
  }

  const handleBack = () => setActiveStep((prevActiveStep) => prevActiveStep - 1)

  const stepIsValid = () => {
    if (activeStep === 0) {
      return Boolean(form.user_ids.length > 0 || form.group_ids.length > 0)
    } else if (activeStep === 1) {
      return !form.emptyScoring
    } else if (activeStep === 2) {
      if (
        form.content_method === 'standard' ||
        (form.content_method === 'custom' &&
          (form.selectedQuizzes.length || form.selectedTopics.length > 0))
      ) {
        return true
      }
      return false
    } else if (activeStep === 3) {
      return !Object.keys(form.errors).length
    } else {
      return true
    }
  }

  const getButtonText = () => {
    if (editingId && activeStep === 3) {
      return 'Save'
    } else if (activeStep === 3) {
      return 'Create'
    } else if (activeStep === 4) {
      return 'Confirm'
    } else if (activeStep === 5) {
      return 'Close'
    } else {
      return 'Next'
    }
  }

  const handleClose = () => {
    setActiveStep(0)
    requestClose()
  }

  return (
    <Dialog
      onClose={handleClose}
      title={editingId ? 'Edit Contest' : 'Create Contest'}
      open={Boolean(open)}
      maxWidth={activeStep < 4 ? 'md' : 'xs'}
      disableBackdropClick
      actions={
        <Stack
          flex={1}
          direction="row"
          useFlexGap
          gap="12px"
          justifyContent={activeStep === 0 || activeStep === 5 ? 'flex-end' : 'space-between'}
        >
          {activeStep > 0 && activeStep < 5 && (
            <Button
              onClick={handleBack}
              startIcon={<ChevronLeftRounded />}
              variant="text"
              text="Back"
            />
          )}
          <Button onClick={handleNext} disabled={!stepIsValid()} text={getButtonText()} />
        </Stack>
      }
    >
      {activeStep < 4 && (
        <Stepper
          style={{ paddingLeft: 0, paddingRight: 0, marginBottom: 32 }}
          nonLinear
          activeStep={activeStep}
        >
          {steps.map((label) => (
            <Step key={label}>
              <StepButton disableRipple disabled>
                {label}
              </StepButton>
            </Step>
          ))}
        </Stepper>
      )}
      <Box height={activeStep < 4 ? 500 : 'auto'}>{getStepContent(activeStep)}</Box>
    </Dialog>
  )
})

const CONFIRM: React.CSSProperties = {
  display: 'flex',
  flex: 1,
  alignItems: 'center',
  flexDirection: 'column',
  justifyContent: 'center',
}
