import React from 'react'
import { observer } from 'mobx-react-lite'

import { Dialog } from '@trivie/ui-web/components/dialogs/dialog'

import { ContentGenerationTabs } from '../../content-generation/quiz-generation/content-generation-tabs'

export const ResourceUploadDialog = observer((props: any) => {
  const { open, close, topic, quiz, onUpload } = props
  return (
    <Dialog onClose={close} maxWidth="sm" open={open} title="Add Source">
      <ContentGenerationTabs
        onClose={close}
        quiz={quiz}
        topic={topic}
        onUpload={onUpload}
        standalone
      />
    </Dialog>
  )
})
