import React, { useEffect, useState } from 'react'
import { observer } from 'mobx-react-lite'
import { DateTime } from 'luxon'
import { useRouteMatch } from 'react-router-dom'

import { useStores } from '@trivie/core'
import { TableHelper } from '../../../../tables'
import { QuizTable } from '../../../../tables/quiz-table'

const Quizzes = observer(() => {
  const match = useRouteMatch<any>('/activities/assignments/:tab/:id/quizzes')
  const assignmentID = Number(match?.params?.id)
  const tab = String(match?.params?.id)

  const { assignmentStore } = useStores()
  const { assignmentOverviewStats, getAssignmentDetails } = assignmentStore

  useEffect(() => {
    if (!assignmentOverviewStats) {
      const endTime = DateTime.now()
      const startTime = endTime.plus({ days: -7 }).toISO().toString()
      getAssignmentDetails(assignmentID, startTime, endTime.plus({ days: -7 }).toISO())
    }
  }, [])

  const [tableHelper] = useState(
    new TableHelper('list_assignment_quizzes', 'quizzes', 'assignments', assignmentID, tab),
  )

  return <QuizTable tableHelper={tableHelper} />
})

export const AssignmentsQuizzesTab = (props: any) => {
  const { id, mainTab } = props
  return {
    name: 'Quizzes',
    path: `/activities/assignments/${mainTab}/${id}/quizzes`,
    component: () => <Quizzes />,
  }
}
