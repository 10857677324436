import { types } from 'mobx-state-tree'

export const Workspace = types.model('Workspace').props({
  logo: types.maybeNull(types.string),
  company_name: types.maybeNull(types.string),
  access_code: types.string,
  user_count: types.number,
  chargebee_customer_id: types.maybeNull(types.number),
  quiz_completion_count_day: types.number,
  quiz_completion_count_month: types.number,
  last_active_learner: types.string,
  last_active_admin: types.string,
})
