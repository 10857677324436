import React, { useState, useEffect } from 'react'
import { observer } from 'mobx-react-lite'
import { ResourceHelper, useStores } from '@trivie/core'
import { TextField, CircularProgress } from '@mui/material'

import { ResourceApi } from '@trivie/core/src/services/api-objects'

import { Dialog } from '../dialogs/dialog'
import { Button } from '../button'
import { Text } from '../text'
import { color } from '../../theme'

export interface EditResourceProps {
  open: boolean
  onClose: () => void
  resource: any
  quiz?: any
  standalone?: boolean
  onUpdate?: (deleted?: boolean) => void
}

export const EditResource = observer((props: EditResourceProps) => {
  const { open, onUpdate = (deleted?: boolean) => {}, quiz, standalone, resource, onClose } = props
  const { manageResourceStore } = useStores()
  const { deleteResources } = manageResourceStore

  const [showConfirm, setShowConfirm] = useState(false)
  const [resourceName, setResourceName] = useState(resource?.title ?? '')
  const [summary, setSummary] = useState('')
  const [loadingSummary, setLoadingSummary] = useState(true)
  const [deleteCheck, setDeleteCheck] = useState<any>()

  useEffect(() => {
    ResourceApi.getSummary(resource?.resource_id)
      .then((resp: any) => {
        setLoadingSummary(false)
        setSummary(resp.data.summary)
      })
      .catch(() => {
        setLoadingSummary(false)
      })
  }, [])

  const handleClose = () => {
    onClose()
  }

  return (
    <Dialog
      maxWidth="sm"
      onClose={handleClose}
      title={
        standalone && !showConfirm
          ? 'Edit Source'
          : standalone && showConfirm
            ? 'Delete Source'
            : showConfirm
              ? 'Remove Source'
              : 'Edit Source'
      }
      open={open}
      actions={
        !showConfirm ? (
          <div style={{ ...FOOTER, justifyContent: 'space-between' }}>
            {standalone ? (
              <Button
                text="Remove Source"
                variant="text"
                onClick={() => setShowConfirm(true)}
                color="error"
              />
            ) : (
              <div style={{ flex: 1 }} />
            )}
            <Button
              onClick={() => {
                resource?.set('title', resourceName)
                ResourceHelper.updateResource(resource)?.then((result) => {
                  onUpdate(false)
                })
                ResourceApi.updateSummary(resource?.resource_id, summary)
                onClose()
              }}
              text="Save"
            />
          </div>
        ) : (
          <div style={FOOTER}>
            {standalone ? (
              <>
                {!deleteCheck && (
                  <Button variant="outlined" color="info" onClick={onClose} text={'Cancel'} />
                )}
                <Button
                  onClick={() => {
                    // check safe=true first
                    if (deleteCheck) {
                      onClose()
                    } else {
                      deleteResources([resource?.resource_id], true).then((data) => {
                        if (data.message) {
                          setDeleteCheck(data.message)
                        } else {
                          // hard delete
                          onUpdate(true)
                          onClose()
                        }
                      })
                    }
                  }}
                  color="error"
                  text={deleteCheck ? 'Close' : 'Delete'}
                />
              </>
            ) : (
              <Button
                onClick={() => {
                  quiz?.setMercuryResource(undefined)
                  quiz.setResourceDocument(undefined)
                  quiz?.removeResource(resource)
                  if (quiz?.resources.length) {
                    quiz.setMercuryResource({
                      ...quiz?.resources[0],
                      identityBreaker: quiz?.resources[0].resource_id,
                    })
                  }
                  onClose()
                }}
                style={{ color: color.white, backgroundColor: color.blue }}
                text={'Remove'}
              />
            )}
          </div>
        )
      }
    >
      {!showConfirm ? (
        <>
          {loadingSummary ? (
            <CircularProgress style={{ alignSelf: 'center' }} />
          ) : (
            <>
              <TextField
                fullWidth
                variant="standard"
                style={{ marginBottom: 20 }}
                label="Name"
                value={resourceName}
                onChange={(e) => setResourceName(e.target.value)}
              />
              <TextField
                fullWidth
                variant="standard"
                multiline
                label="Summary"
                value={summary}
                onChange={(e) => setSummary(e.target.value)}
              />
            </>
          )}
        </>
      ) : (
        <>
          {standalone ? (
            <Text
              style={{ textAlign: 'center' }}
              text={
                deleteCheck
                  ? deleteCheck
                  : 'Deletion will remove the source from your workspace entirely.'
              }
              variant="body1"
            />
          ) : (
            <Text
              style={{ textAlign: 'center' }}
              text={
                deleteCheck
                  ? deleteCheck
                  : `Do you want to detach this source from ${
                      quiz?.title ? quiz?.title : 'Untitled Quiz'
                    }? This will break all citations between your quiz and the source, and cannot be undone.`
              }
              variant="body1"
            />
          )}
        </>
      )}
    </Dialog>
  )
})

const FOOTER: React.CSSProperties = {
  display: 'flex',
  flexDirection: 'row',
  flex: 1,
  justifyContent: 'space-between',
}
