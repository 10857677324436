import { ApiResponse, ApisauceInstance, create } from 'apisauce'
import { Instance } from 'mobx-state-tree'

import {
  AssignmentReadModelType,
  AssignmentUserReadType,
  QuestionReadModelType,
  ResourceReadModelType,
  UserLevelReadModelType,
  UserSchemaModelBase,
} from '../../models/base'
import { ApiConfig, DEFAULT_API_CONFIG } from './api-config'
import { getGeneralApiProblem } from './api-problem'
import * as storage from '../storage'
import { AuthenticationApi } from '../api-objects/AuthenticationApi'
import { ContentCreationApi } from '../api-objects/ContentCreationApi'
import { GroupApi } from '../api-objects/GroupApi'
import { TagApi } from '../api-objects/TagApi'
import { TopicApi } from '../api-objects/TopicApi'
import { UserApi } from '../api-objects/UserApi'
import { SurveyApi } from '../api-objects/SurveyApi'
import { QuizApi } from '../api-objects/QuizApi'
import { QuestionApi } from '../api-objects/QuestionApi'
import { ResourceApi } from '../api-objects/ResourceApi'
import { LeaderboardApi } from '../api-objects/LeaderboardApi'
import { AssignmentApi } from '../api-objects/AssignmentApi'
import { BadgeApi } from '../api-objects/BadgeApi'
import { MessagesApi } from '../api-objects/MessagesApi'
import { PostApi } from '../api-objects/PostApi'
import { TableApi } from '../api-objects/TableApi'
import { ContestApi } from '../api-objects/ContestApi'
import { GameApi, ReportsApi } from '../api-objects'
import { TaskApi } from '../api-objects/TaskApi'
import { WorkflowApi } from '../api-objects/WorkflowApi'

const _ = require('lodash')

/**
 * Manages all requests to the V8 API.
 */
export class Api {
  /**
   * The underlying apisauce instance which performs the requests.
   */
  apisauce!: ApisauceInstance

  /**
   * Configurable options.
   */
  config: ApiConfig

  token: string | null = null

  /**
   * Creates the api.
   *
   * @param config The configuration to use.
   */
  constructor(config: ApiConfig = DEFAULT_API_CONFIG) {
    this.config = config
  }

  /**
   * Sets up the API.  This will be called during the bootup
   * sequence and will happen before the first React component
   * is mounted.
   *
   * Be as quick as possible in here.
   */
  async setup(
    version: string,
    showMaintenance: (show: boolean) => void,
    timezone: string,
    showAlert?: (status: string, message: string, reset: boolean, upgrade?: boolean) => void,
    forceUpgrade?: (show: boolean) => void,
    insights?: boolean,
  ) {
    const token = await storage.loadString('token')
    const access_code = await storage.loadString('access_code')
    if (version) {
      this.setVersion(version)
    }
    this.apisauce = create({
      baseURL: this.config.url,
      timeout: this.config.timeout,
      headers: {
        Accept: '*/*',
        'x-build-version': version,
      },
    })
    this.apisauce.setHeader('Authorization', `Bearer ${token}`)
    this.apisauce.setHeader('x-access-code', `${access_code}`)
    this.apisauce.setHeader('x-timezone', timezone)

    this.apisauce.addMonitor((response) => {
      switch (response.status) {
        case 401:
          if (showAlert) {
            showAlert(
              'Log In Expired',
              'It looks like your log in has expired. Please log in again to continue.',
              true,
            )
          }
          break
        case 403:
          if (showAlert && response?.config?.url !== 'otc/evaluate/') {
            showAlert(
              'Permission Error',
              `You don't have permission to perform this action on this workspace. Please contact your workspace owner for more information.`,
              false,
            )
          }
          break
        case 402:
          if (showAlert) {
            showAlert(
              'Upgrade Required',
              `${
                response.data.detail ??
                `You're admin will need to upgrade their plan to perform this action.`
              }`,
              false,
              true,
            )
          }
          break
        case 426:
          if (forceUpgrade) {
            forceUpgrade(true)
          }
          break
        case 500:
          if (showAlert && insights) {
            showAlert('Unexpected Error', `An unexpected error has occurred.`, false)
          }
          break
        case 502:
          showMaintenance(true)
          break
        case 503:
          showMaintenance(true)
          break
        default:
          showMaintenance(false)
          break
      }
    })
    // set apisauce instance on each api object
    const apis = [
      AuthenticationApi,
      BadgeApi,
      GroupApi,
      TagApi,
      TopicApi,
      ContentCreationApi,
      UserApi,
      TableApi,
      SurveyApi,
      QuizApi,
      QuestionApi,
      ResourceApi,
      LeaderboardApi,
      MessagesApi,
      GameApi,
      PostApi,
      AssignmentApi,
      ContestApi,
      TaskApi,
      ReportsApi,
      WorkflowApi,
    ]
    apis.forEach((a: { apisauce: ApisauceInstance }) => (a.apisauce = this.apisauce))
  }

  async setVersion(version: string) {
    await storage.saveString('version', version)
  }

  async removeAuthHeaders() {
    delete this.apisauce.headers.Authorization
    delete this.apisauce.headers['x-access-code']
    await storage.remove('access_code')
    await storage.remove('token')
    await storage.remove('trivie')
  }

  async setAccessCode(access_code: string) {
    this.apisauce.setHeader('x-access-code', `${access_code}`)
    await storage.saveString('access_code', access_code)
  }

  async setToken(token: any) {
    this.apisauce.setHeader('Authorization', `Bearer ${token}`)
  }

  async setUserId(user_id: any) {
    await storage.save('user_id', user_id)
  }

  async login(user: any, access_code: string): Promise<any> {
    this.setAccessCode(access_code)
    const formData = new FormData()
    formData.append('username', user.username)
    formData.append('password', user.password)

    const response: ApiResponse<any> = await this.apisauce.post(`login/engage/`, formData)

    if (!response.ok) {
      const problem = getGeneralApiProblem(response)
      if (problem) return Promise.reject(problem)
    }

    try {
      const { data } = response
      const { access_token } = data
      this.setToken(access_token)

      return { kind: 'ok', data }
    } catch {
      return { kind: 'bad-data' }
    }
  }

  async subscriptionWebhook(payload: any, vendor: string): Promise<any> {
    const response: ApiResponse<any> = await this.apisauce.post(`${vendor}_webhook/`, payload)
    if (!response.ok) {
      const problem = getGeneralApiProblem(response)
      if (problem) return Promise.reject(problem)
    }

    try {
      const { data } = response
      return { kind: 'ok', data }
    } catch {
      return { kind: 'bad-data' }
    }
  }

  async loginWorkspace(token: string, access_code: string): Promise<any> {
    this.setAccessCode(access_code)

    const response: ApiResponse<any> = await this.apisauce.post(`workspace/login/`, {
      token,
      access_code,
    })

    if (!response.ok) {
      const problem = getGeneralApiProblem(response)
      if (problem) return Promise.reject(problem)
    }

    try {
      const { data } = response
      const { access_token, user_id } = data

      this.setToken(access_token)
      this.setUserId(user_id)

      return { kind: 'ok', data }
    } catch {
      return { kind: 'bad-data' }
    }
  }

  async workspaceVerify(access_code: string, vanity_url?: string): Promise<any> {
    let payload = {
      access_code,
    } as any
    if (vanity_url) {
      payload = { vanity_url }
    }
    const response: ApiResponse<any> = await this.apisauce.post(`workspace/verify/`, payload)

    if (!response.ok) {
      const problem = getGeneralApiProblem(response)
      if (problem) return Promise.reject(problem)
    }

    try {
      const { data } = response
      return { kind: 'ok', data }
    } catch {
      return { kind: 'bad-data' }
    }
  }

  async whitelabelSignup(payload: any, app_id: any): Promise<any> {
    const response: ApiResponse<any> = await this.apisauce.post(
      `create_account/?app_id=${app_id}`,
      payload,
    )

    if (!response.ok) {
      const problem = getGeneralApiProblem(response)
      if (problem) return Promise.reject(problem)
    }

    try {
      const { data } = response
      return { kind: 'ok', data }
    } catch {
      return { kind: 'bad-data' }
    }
  }

  async registerOnesignalID(onesignal_id: any): Promise<any> {
    const response: ApiResponse<any> = await this.apisauce.post(`register_onesignal_player/`, {
      onesignal_id,
    })

    if (!response.ok) {
      const problem = getGeneralApiProblem(response)
      if (problem) return Promise.reject(problem)
    }

    try {
      const { data } = response

      return { kind: 'ok', data }
    } catch {
      return { kind: 'bad-data' }
    }
  }

  async evaluateOTC(code: string, email?: string, phone_number?: string): Promise<any> {
    const response: ApiResponse<any> = await this.apisauce.post('otc/evaluate/', {
      one_time_code: Number(code),
      email,
      phone_number,
    })
    if (!response.ok) {
      const problem = getGeneralApiProblem(response)
      if (problem) return Promise.reject(problem)
    }
    try {
      const { data } = response
      return { kind: 'ok', data }
    } catch {
      return { kind: 'bad-data' }
    }
  }

  async sendMagicLink(data: string, app_id?: string, sms?: boolean): Promise<any> {
    this.removeAuthHeaders()
    const response: ApiResponse<any> = await this.apisauce.post(
      app_id ? `magic_link/request/?app_id=${app_id}` : `magic_link/request/`,
      sms
        ? { phone_number: data, for_insights: false, app_id }
        : {
            email: data,
            for_insights: false,
            app_id,
          },
    )

    if (!response.ok) {
      const problem = getGeneralApiProblem(response)
      if (problem) return Promise.reject(problem)
    }

    try {
      const { data } = response

      return { kind: 'ok', data }
    } catch {
      return { kind: 'bad-data' }
    }
  }

  async requestMagicLink(for_insights: boolean, email: string): Promise<any> {
    const response: ApiResponse<any> = await this.apisauce.post(`magic_link/request/`, {
      for_insights,
      email,
    })

    if (!response.ok) {
      const problem = getGeneralApiProblem(response)
      if (problem) return Promise.reject(problem)
    }

    try {
      const { data } = response
      return { kind: 'ok', data }
    } catch {
      return { kind: 'bad-data' }
    }
  }

  async evaluateMagicLink(payload: any): Promise<any> {
    const response: ApiResponse<any> = await this.apisauce.post(`magic_link/evaluate/`, payload)

    if (!response.ok) {
      const problem = getGeneralApiProblem(response)
      if (problem) return Promise.reject(problem)
    }

    try {
      const { data } = response
      return { kind: 'ok', data }
    } catch {
      return { kind: 'bad-data' }
    }
  }

  async loginManage(user: any, access_code: string): Promise<any> {
    this.setAccessCode(access_code)
    const formData = new FormData()
    formData.append('username', user.username)
    formData.append('password', user.password)

    const response: ApiResponse<any> = await this.apisauce.post(`login/insights/`, formData)

    if (!response.ok) {
      const problem = getGeneralApiProblem(response)
      if (problem) return Promise.reject(problem)
    }

    try {
      const { data } = response
      const { access_token } = data
      this.setToken(access_token)

      return { kind: 'ok', data }
    } catch {
      return { kind: 'bad-data' }
    }
  }

  async getUserProfile(id: number | string): Promise<any> {
    const response: ApiResponse<Instance<typeof UserSchemaModelBase>> = await this.apisauce.get(
      `users/${id}/profile/`,
      {},
    )

    if (!response.ok) {
      const problem = getGeneralApiProblem(response)
      if (problem) return Promise.reject(problem)
    }

    try {
      const { data } = response
      return { kind: 'ok', data }
    } catch {
      return { kind: 'bad-data' }
    }
  }

  async uploadResource(resource: any): Promise<any> {
    const response: ApiResponse<any> = await this.apisauce.post(`resources/upload/`, resource)

    if (!response.ok) {
      const problem = getGeneralApiProblem(response)
      if (problem) return Promise.reject(problem)
    }

    try {
      const { data } = response
      return { kind: 'ok', data }
    } catch {
      return { kind: 'bad-data' }
    }
  }

  async setProfilePhoto(id: any, photo: any): Promise<any> {
    const formData = new FormData()
    formData.append('photo', photo)

    const response: ApiResponse<any> = await this.apisauce.patch(`users/${id}/photo/`, formData)

    if (!response.ok) {
      const problem = getGeneralApiProblem(response)
      if (problem) return Promise.reject(problem)
    }

    try {
      const { data } = response
      return { kind: 'ok', data }
    } catch {
      return { kind: 'bad-data' }
    }
  }

  async uploadProfilePhoto(photo: any, id: number): Promise<any> {
    const response: ApiResponse<any> = await this.apisauce.patch(`users/${id}/photo/`, photo)

    if (!response.ok) {
      const problem = getGeneralApiProblem(response)
      if (problem) return Promise.reject(problem)
    }

    try {
      const { data } = response
      return { kind: 'ok', data }
    } catch {
      return { kind: 'bad-data' }
    }
  }

  async updateUser(id: number, user: any): Promise<any> {
    const response: ApiResponse<Instance<typeof UserSchemaModelBase>> = await this.apisauce.patch(
      `users/${id}/`,
      user,
    )

    if (!response.ok) {
      const problem = getGeneralApiProblem(response)
      if (problem) return Promise.reject(problem)
    }

    try {
      const { data } = response
      return { kind: 'ok', data }
    } catch {
      return { kind: 'bad-data' }
    }
  }

  /**
   * List notifications
   */
  async getNotifications(user_id: number): Promise<any> {
    const response: ApiResponse<UserLevelReadModelType> = await this.apisauce.get(
      `/users/${user_id}/notifications/`,
      {},
    )

    if (!response.ok) {
      const problem = getGeneralApiProblem(response)
      if (problem) return Promise.reject(problem)
    }

    try {
      const { data } = response
      return { kind: 'ok', data }
    } catch {
      return { kind: 'bad-data' }
    }
  }

  /**
   * Check notifications
   */
  async checkNotifications(
    user_id: number,
    notification_id?: number,
    include_viewed?: boolean,
  ): Promise<any> {
    const response: ApiResponse<UserLevelReadModelType> = await this.apisauce.get(
      `/users/${user_id}/notification_check/`,
      { notification_id, include_viewed },
    )

    if (!response.ok) {
      const problem = getGeneralApiProblem(response)
      if (problem) return Promise.reject(problem)
    }

    try {
      const { data } = response
      return { kind: 'ok', data }
    } catch {
      return { kind: 'bad-data' }
    }
  }

  /**
   * Read notification
   */
  async readNotification(user_id: number, notification_id: number): Promise<any> {
    const response: ApiResponse<UserLevelReadModelType> = await this.apisauce.patch(
      `/users/${user_id}/notifications/${notification_id}/`,
      {},
    )

    if (!response.ok) {
      const problem = getGeneralApiProblem(response)
      if (problem) return Promise.reject(problem)
    }

    try {
      const { data } = response
      return { kind: 'ok', data }
    } catch {
      return { kind: 'bad-data' }
    }
  }

  /**
   * List All Content Packs for User
   */
  async getAHAContentPacksForUser(user_id: number, vendor?: string): Promise<any> {
    const response: ApiResponse<any> = await this.apisauce.get(
      vendor
        ? `/users/${user_id}/content_packs/?vendor=${vendor}`
        : `/users/${user_id}/content_packs/`,
      {},
    )
    if (!response.ok) {
      const problem = getGeneralApiProblem(response)
      if (problem) return Promise.reject(problem)
    }

    try {
      const { data } = response
      return { kind: 'ok', data }
    } catch {
      return { kind: 'bad-data' }
    }
  }

  /**
   * List All Content Packs for User
   */
  async getContentPackDetails(
    user_id: any,
    content_pack_id: string,
    vendor?: string,
  ): Promise<any> {
    const response: ApiResponse<any> = await this.apisauce.get(
      vendor
        ? `/users/${user_id}/content_packs/${content_pack_id}/?vendor=${vendor}`
        : `/users/${user_id}/content_packs/${content_pack_id}/`,
      {},
    )
    if (!response.ok) {
      const problem = getGeneralApiProblem(response)
      if (problem) return Promise.reject(problem)
    }

    try {
      const { data } = response
      return { kind: 'ok', data }
    } catch {
      return { kind: 'bad-data' }
    }
  }

  /* TOPICS */
  async checkDeleteTopics(topic_ids: Array<number>): Promise<any> {
    const response: ApiResponse<any> = await this.apisauce.post(`/topics/delete/check/`, {
      topic_ids,
    })

    if (!response.ok) {
      const problem = getGeneralApiProblem(response)
      if (problem) return Promise.reject(problem)
    }

    try {
      const { data } = response
      return { kind: 'ok', data }
    } catch {
      return { kind: 'bad-data' }
    }
  }

  /**
   * Delete Topic
   */
  async deleteTopics(ids: any): Promise<any> {
    const response: ApiResponse<AssignmentUserReadType[]> = await this.apisauce.delete(
      `/topics/delete/`,
      {},
      { data: { topic_ids: ids } },
    )

    if (!response.ok) {
      const problem = getGeneralApiProblem(response)
      if (problem) return Promise.reject(problem)
    }

    try {
      const { data } = response
      return { kind: 'ok', data }
    } catch {
      return { kind: 'bad-data' }
    }
  }

  /**
   * Mark resource as viewed
   */
  async markAsViewed(
    user_id: any,
    resource_id: any,
    view_start: any,
    view_end: any,
    metadata: any,
  ): Promise<any> {
    const response: ApiResponse<AssignmentUserReadType[]> = await this.apisauce.post(
      `/resources/${resource_id}/mark_as_viewed/`,
      {
        user_id,
        view_start,
        view_end,
        metadata,
      },
    )

    if (!response.ok) {
      const problem = getGeneralApiProblem(response)
      if (problem) return Promise.reject(problem)
    }

    try {
      const { data } = response
      return { kind: 'ok', data }
    } catch {
      return { kind: 'bad-data' }
    }
  }

  async getAdaptive(user_id: number): Promise<any> {
    const response: ApiResponse<any> = await this.apisauce.get(`/users/${user_id}/adaptive/`)

    if (!response.ok) {
      const problem = getGeneralApiProblem(response)
      if (problem) return Promise.reject(problem)
    }

    try {
      const { data } = response
      return { kind: 'ok', data }
    } catch {
      return { kind: 'bad-data' }
    }
  }

  /**
   * Start Quiz
   */
  async startAdaptive(topicID?: number): Promise<any> {
    const response: ApiResponse<any> = await this.apisauce.post(
      topicID ? `/game/start/?topic_id=${topicID}` : '/game/start/',
      {},
    )

    if (!response.ok) {
      const problem = getGeneralApiProblem(response)
      if (problem) return Promise.reject(problem)
    }

    try {
      const { data } = response
      return { kind: 'ok', data }
    } catch {
      return { kind: 'bad-data' }
    }
  }

  /**
   * Start Quiz
   */
  async startQuiz(quiz_id: number, metadata): Promise<any> {
    const payload = { ..._.omitBy(metadata, _.isNull), quiz_id }
    const response: ApiResponse<any> = await this.apisauce.post(`/game/${quiz_id}/start/`, payload)

    if (!response.ok) {
      const problem = getGeneralApiProblem(response)
      if (problem) return Promise.reject(problem)
    }

    try {
      const { data } = response
      return { kind: 'ok', data }
    } catch {
      return { kind: 'bad-data' }
    }
  }

  async startAssessment(quiz_id: number, metadata): Promise<any> {
    const payload = { ..._.omitBy(metadata, _.isNull), quiz_id }
    const response: ApiResponse<any> = await this.apisauce.post(
      `/game/assessment/${quiz_id}/start/`,
      {},
    )

    if (!response.ok) {
      const problem = getGeneralApiProblem(response)
      if (problem) return Promise.reject(problem)
    }

    try {
      const { data } = response

      return { kind: 'ok', data }
    } catch {
      return { kind: 'bad-data' }
    }
  }

  /**
   * Submit Answer
   */
  async submitAnswer(payload: any): Promise<any> {
    const response: ApiResponse<AssignmentReadModelType> = await this.apisauce.post(
      `/game/${payload.quiz_id}/submit_answer/`,
      payload,
    )

    if (!response.ok) {
      const problem = getGeneralApiProblem(response)
      if (problem) return Promise.reject(problem)
    }

    try {
      const { data } = response

      return { kind: 'ok', data }
    } catch {
      return { kind: 'bad-data' }
    }
  }

  /**
   * Pre-Quiz Interstitial
   */
  async getQuizDetails(user_id: number, metadata): Promise<any> {
    const params = Object.keys(_.omitBy(metadata, _.isNull))
      .map((k) => `${k}=${metadata[k]}`)
      .join('&')

    const response: ApiResponse<any> = await this.apisauce.get(
      `/game/pre_quiz/?&user_id=${user_id}&${params}`,
    )

    if (!response.ok) {
      const problem = getGeneralApiProblem(response)
      if (problem) return Promise.reject(problem)
    }

    try {
      const { data } = response
      return { kind: 'ok', data }
    } catch {
      return { kind: 'bad-data' }
    }
  }

  /**
   * Post-Quiz Interstitial
   */
  async getPostQuiz(result_id: any, user_id: any, topic_id: any): Promise<any> {
    const urlQuery = topic_id
      ? new URLSearchParams({
          result_id,
          user_id,
          topic_id,
        })
      : new URLSearchParams({
          result_id,
          user_id,
        })
    const response: ApiResponse<any> = await this.apisauce.get(
      `/game/post_quiz_review/?${urlQuery.toString()}`,
    )
    if (!response.ok) {
      const problem = getGeneralApiProblem(response)
      if (problem) return Promise.reject(problem)
    }

    try {
      const { data } = response
      return { kind: 'ok', data }
    } catch {
      return { kind: 'bad-data' }
    }
  }

  async getPostsForTopic(page: number, topic_id: number): Promise<any> {
    const response: ApiResponse<any> = await this.apisauce.get(
      `/topics/${topic_id}/posts/?start_page=${page}&items_per_page=15`,
    )
    if (!response.ok) {
      const problem = getGeneralApiProblem(response)
      if (problem) return Promise.reject(problem)
    }

    try {
      const { data } = response
      return { kind: 'ok', data }
    } catch {
      return { kind: 'bad-data' }
    }
  }

  async getPostsForUser(page: number, user_id: number): Promise<any> {
    const response: ApiResponse<any> = await this.apisauce.get(
      `/users/${user_id}/posts/?items_per_page=15&start_page=${page}`,
    )
    if (!response.ok) {
      const problem = getGeneralApiProblem(response)
      if (problem) return Promise.reject(problem)
    }

    try {
      const { data } = response
      return { kind: 'ok', data }
    } catch {
      return { kind: 'bad-data' }
    }
  }

  async getRelatedQuizzesForResource(id: number): Promise<any> {
    const response: ApiResponse<AssignmentUserReadType[]> = await this.apisauce.get(
      `/resources/${id}/quizzes/`,
      {},
    )

    if (!response.ok) {
      const problem = getGeneralApiProblem(response)
      if (problem) return Promise.reject(problem)
    }

    try {
      const { data } = response
      return { kind: 'ok', data }
    } catch {
      return { kind: 'bad-data' }
    }
  }

  /**
   * Create a Post
   */
  async createPost(post: any): Promise<any> {
    const response: ApiResponse<any> = await this.apisauce.post(`/posts/create/`, post)
    if (!response.ok) {
      const problem = getGeneralApiProblem(response)
      if (problem) return Promise.reject(problem)
    }

    try {
      const { data } = response
      return { kind: 'ok', data }
    } catch {
      return { kind: 'bad-data' }
    }
  }

  async getRelatedQuizzes(id: number): Promise<any> {
    const response: ApiResponse<AssignmentUserReadType[]> = await this.apisauce.get(
      `/questions/${id}/quizzes/`,
      {},
    )

    if (!response.ok) {
      const problem = getGeneralApiProblem(response)
      if (problem) return Promise.reject(problem)
    }

    try {
      const { data } = response
      return { kind: 'ok', data }
    } catch {
      return { kind: 'bad-data' }
    }
  }

  /**
   * Delete your own Post
   */
  async deletePost(postId: number): Promise<any> {
    const response: ApiResponse<any> = await this.apisauce.delete(`/posts/${postId}/`)
    if (!response.ok) {
      const problem = getGeneralApiProblem(response)
      if (problem) return Promise.reject(problem)
    }

    try {
      const { data } = response
      return { kind: 'ok', data }
    } catch {
      return { kind: 'bad-data' }
    }
  }

  /**
   * List All Quizzes containing a given question
   */
  async getRelatedTopicsForResource(id: number): Promise<any> {
    const response: ApiResponse<AssignmentUserReadType[]> = await this.apisauce.get(
      `/resources/${id}/topics/`,
      {},
    )

    if (!response.ok) {
      const problem = getGeneralApiProblem(response)
      if (problem) return Promise.reject(problem)
    }

    try {
      const { data } = response
      return { kind: 'ok', data }
    } catch {
      return { kind: 'bad-data' }
    }
  }

  /**
   * List All Quizzes containing a given question
   */
  async getRelatedTopicsForQuestion(id: number): Promise<any> {
    const response: ApiResponse<AssignmentUserReadType[]> = await this.apisauce.get(
      `/questions/${id}/topics/`,
      {},
    )

    if (!response.ok) {
      const problem = getGeneralApiProblem(response)
      if (problem) return Promise.reject(problem)
    }

    try {
      const { data } = response
      return { kind: 'ok', data }
    } catch {
      return { kind: 'bad-data' }
    }
  }

  async addResourcesToQuiz(quizId: number, resources: any): Promise<any> {
    const resourcesData = resources.map((resource: any) => ({
      resource_id: resource.resource_id ?? resource.id,
      is_recommended: resource.is_recommended ?? false,
      is_required: resource.is_required,
    }))

    const response: ApiResponse<ResourceReadModelType[]> = await this.apisauce.post(
      `/quizzes/${quizId}/resources/`,
      {
        resources: resourcesData,
      },
    )

    if (!response.ok) {
      const problem = getGeneralApiProblem(response)
      if (problem) return Promise.reject(problem)
    }

    try {
      const { data } = response
      return { kind: 'ok', data }
    } catch {
      return { kind: 'bad-data' }
    }
  }

  async removeResourcesFromQuiz(quizId: number, resources: any): Promise<any> {
    const response: ApiResponse<ResourceReadModelType[]> = await this.apisauce.delete(
      `/quizzes/${quizId}/resources/`,
      {},
      {
        data: {
          resources,
        },
      },
    )

    if (!response.ok) {
      const problem = getGeneralApiProblem(response)
      if (problem) return Promise.reject(problem)
    }

    try {
      const { data } = response
      return { kind: 'ok', data }
    } catch {
      return { kind: 'bad-data' }
    }
  }

  async getAddedUsersForAssignment(id: number) {
    const response: ApiResponse<any> = await this.apisauce.get(`/assignments/${id}/users/added/`)

    if (!response.ok) {
      const problem = getGeneralApiProblem(response)
      if (problem) return Promise.reject(problem)
    }

    try {
      const { data } = response
      return { kind: 'ok', data }
    } catch {
      return { kind: 'bad-data' }
    }
  }

  async clearAssignmentAddedUsers(assignmentID: number, userIds: any = []) {
    const response: ApiResponse<any> = await this.apisauce.patch(
      `/assignments/${assignmentID}/users/added/clear/`,
      {
        user_ids: userIds,
      },
    )

    if (!response.ok) {
      const problem = getGeneralApiProblem(response)
      if (problem) return Promise.reject(problem)
    }

    try {
      const { data } = response
      return { kind: 'ok', data }
    } catch {
      return { kind: 'bad-data' }
    }
  }

  async getExcludedUsersForAssignment(id: number) {
    const response: ApiResponse<any> = await this.apisauce.get(`/assignments/${id}/users/excluded/`)

    if (!response.ok) {
      const problem = getGeneralApiProblem(response)
      if (problem) return Promise.reject(problem)
    }

    try {
      const { data } = response
      return { kind: 'ok', data }
    } catch {
      return { kind: 'bad-data' }
    }
  }

  async excludeUsersFromAssignment(assignmentID: number, userIds: { user_ids: any[] }) {
    const response: ApiResponse<any> = await this.apisauce.patch(
      `/assignments/${assignmentID}/users/exclude/`,
      userIds,
    )

    if (!response.ok) {
      const problem = getGeneralApiProblem(response)
      if (problem) return Promise.reject(problem)
    }

    try {
      const { data } = response
      return { kind: 'ok', data }
    } catch {
      return { kind: 'bad-data' }
    }
  }

  async restoreAssignmentExcludedUsers(assignmentID: number, userIds: any = []) {
    const response: ApiResponse<any> = await this.apisauce.patch(
      `/assignments/${assignmentID}/users/excluded/restore/`,
      {
        user_ids: userIds,
      },
    )

    if (!response.ok) {
      const problem = getGeneralApiProblem(response)
      if (problem) return Promise.reject(problem)
    }

    try {
      const { data } = response
      return { kind: 'ok', data }
    } catch {
      return { kind: 'bad-data' }
    }
  }

  /**
   * Get Assignment Mastery
   */
  async getAssignmentMastery(id: number, startDatetime: any, endingDatetime: any): Promise<any> {
    const urlQuery = new URLSearchParams({
      start_datetime: startDatetime,
      end_datetime: endingDatetime,
    })

    const response: ApiResponse<any> = await this.apisauce.get(
      `/assignments/${id}/mastery/?${urlQuery.toString()}`,
    )

    if (!response.ok) {
      const problem = getGeneralApiProblem(response)
      if (problem) return Promise.reject(problem)
    }

    try {
      const { data } = response
      return { kind: 'ok', data }
    } catch {
      return { kind: 'bad-data' }
    }
  }

  /**
   * Get Assignment Detail
   */
  async getAssignmentByID(id: number, startDatetime: any, endingDatetime: any): Promise<any> {
    const urlQuery = new URLSearchParams({
      start_datetime: startDatetime,
      end_datetime: endingDatetime,
    })

    const response: ApiResponse<any> = await this.apisauce.get(
      `/assignments/${id}/?${urlQuery.toString()}`,
    )

    if (!response.ok) {
      const problem = getGeneralApiProblem(response)
      if (problem) return Promise.reject(problem)
    }

    try {
      const { data } = response
      return { kind: 'ok', data }
    } catch {
      return { kind: 'bad-data' }
    }
  }

  async extendAssignment(id: number, newEndDateTime: any): Promise<any> {
    const response: ApiResponse<any> = await this.apisauce.patch(`/assignments/${id}/extend/`, {
      new_end_date: newEndDateTime.endDateTime,
    })

    if (!response.ok) {
      const problem = getGeneralApiProblem(response)
      if (problem) return Promise.reject(problem)
    }

    try {
      const { data } = response
      return { kind: 'ok', data }
    } catch {
      return { kind: 'bad-data' }
    }
  }

  async createQuiz(quiz: any): Promise<any> {
    const response: ApiResponse<any> = await this.apisauce.post(`/quizzes/create/`, quiz)
    if (!response.ok) {
      const problem = getGeneralApiProblem(response)
      if (problem) return Promise.reject(problem)
    }
    try {
      const { data } = response
      return { kind: 'ok', data }
    } catch {
      return { kind: 'bad-data' }
    }
  }

  /**
   * Retreive Quiz Detail
   */
  async getQuestionKnowledgeGain(
    id: number,
    startDatetime: any,
    endingDatetime: any,
  ): Promise<any> {
    const response: ApiResponse<any> = await this.apisauce.get(`/questions/${id}/knowledge_gain/`, {
      start_datetime: startDatetime,
      end_datetime: endingDatetime,
    })

    if (!response.ok) {
      const problem = getGeneralApiProblem(response)
      if (problem) return Promise.reject(problem)
    }

    try {
      const { data } = response
      return { kind: 'ok', data }
    } catch {
      return { kind: 'bad-data' }
    }
  }

  /**
   * Retreive Quiz Detail
   */
  async getQuestionMastery(id: number): Promise<any> {
    const response: ApiResponse<any> = await this.apisauce.get(`/questions/${id}/mastery/`, {})

    if (!response.ok) {
      const problem = getGeneralApiProblem(response)
      if (problem) return Promise.reject(problem)
    }

    try {
      const { data } = response
      return { kind: 'ok', data }
    } catch {
      return { kind: 'bad-data' }
    }
  }

  async updateQuestion(question_id: number, question: any): Promise<any> {
    const response: any = await this.apisauce.patch(`/questions/${question_id}/`, {
      adaptive_eligible: true,
      difficulty: question.difficulty,
      factoid: question.factoid,
      text: question.text,
      is_locked: false,
      choices: question.choices ?? [],
      question_type: question.question_type ?? 'Multiple Choice',
    })

    if (!response.ok) {
      const problem = getGeneralApiProblem(response)
      if (problem) return Promise.reject(problem)
    }

    try {
      const { data } = response
      return { kind: 'ok', data }
    } catch {
      return { kind: 'bad-data' }
    }
  }

  /**
   * Get User Quiz History
   */
  async getQuizHistory(user_id: number): Promise<any> {
    const response: ApiResponse<any> = await this.apisauce.get(
      `/users/${user_id}/history/quiz/`,
      {},
    )

    if (!response.ok) {
      const problem = getGeneralApiProblem(response)
      if (problem) return Promise.reject(problem)
    }

    try {
      const { data } = response
      return { kind: 'ok', data }
    } catch {
      return { kind: 'bad-data' }
    }
  }

  /**
   * Delete Assignments
   */
  async getResourceDetails(resource_id: any): Promise<any> {
    const response: ApiResponse<AssignmentUserReadType[]> = await this.apisauce.get(
      `/resources/${resource_id}/`,
      {},
    )

    if (!response.ok) {
      const problem = getGeneralApiProblem(response)
      if (problem) return Promise.reject(problem)
    }

    try {
      const { data } = response
      return { kind: 'ok', data }
    } catch {
      return { kind: 'bad-data' }
    }
  }

  /**
   * Delete Assignments
   */
  async deleteResources(resource_ids: any, safe = false): Promise<any> {
    const response: ApiResponse<AssignmentUserReadType[]> = await this.apisauce.post(
      `/resources/delete/?safe=${safe}`,
      { resource_ids },
    )

    if (!response.ok) {
      const problem = getGeneralApiProblem(response)
      if (problem) return Promise.reject(problem)
    }

    try {
      const { data } = response
      return { kind: 'ok', data }
    } catch {
      return { kind: 'bad-data' }
    }
  }

  /* QUESTIONS */

  async setQuestionAltText(questionId: any, text: string): Promise<any> {
    const response: ApiResponse<any> = await this.apisauce.patch(
      `/questions/${questionId}/image/alt_text/`,
      { alt_text: text },
    )

    if (!response.ok) {
      const problem = getGeneralApiProblem(response)
      if (problem) return Promise.reject(problem)
    }

    try {
      const { data } = response
      return { kind: 'ok', data }
    } catch {
      return { kind: 'bad-data' }
    }
  }

  async createQuestion(question: any): Promise<any> {
    const response: ApiResponse<QuestionReadModelType[]> = await this.apisauce.post(
      `/questions/create/`,
      question,
    )

    if (!response.ok) {
      const problem = getGeneralApiProblem(response)
      if (problem) return Promise.reject(problem)
    }

    try {
      const { data } = response
      return { kind: 'ok', data }
    } catch {
      return { kind: 'bad-data' }
    }
  }

  /**
   * List All Questions
   */
  async getQuestions(): Promise<any> {
    const response: ApiResponse<QuestionReadModelType[]> = await this.apisauce.get(
      `/questions/`,
      {},
    )

    if (!response.ok) {
      const problem = getGeneralApiProblem(response)
      if (problem) return Promise.reject(problem)
    }

    try {
      const { data } = response
      return { kind: 'ok', data }
    } catch {
      return { kind: 'bad-data' }
    }
  }

  async deleteQuizzes(quiz_ids: []): Promise<any> {
    const response: ApiResponse<any> = await this.apisauce.delete(
      '/quizzes/delete/',
      {},
      { data: { quiz_ids } },
    )

    if (!response.ok) {
      const problem = getGeneralApiProblem(response)
      if (problem) return Promise.reject(problem)
    }

    try {
      const { data } = response
      return { kind: 'ok', data }
    } catch {
      return { kind: 'bad-data' }
    }
  }

  async deleteQuestions(question_ids: []): Promise<any> {
    const response: ApiResponse<any> = await this.apisauce.delete(
      '/questions/delete/',
      {},
      { data: { question_ids } },
    )

    if (!response.ok) {
      const problem = getGeneralApiProblem(response)
      if (problem) return Promise.reject(problem)
    }

    try {
      const { data } = response
      return { kind: 'ok', data }
    } catch {
      return { kind: 'bad-data' }
    }
  }

  async addQuestionsToQuiz(quizID: number, payload: any): Promise<any> {
    const response: ApiResponse<any> = await this.apisauce.post(
      `/quizzes/${quizID}/questions/add/`,
      payload,
    )

    if (!response.ok) {
      const problem = getGeneralApiProblem(response)
      if (problem) return Promise.reject(problem)
    }

    try {
      const { data } = response
      return { kind: 'ok', data }
    } catch {
      return { kind: 'bad-data' }
    }
  }

  async setQuizzesForTopic(topicID: number, payload: any): Promise<any> {
    const response: ApiResponse<any> = await this.apisauce.post(
      `/topics/${topicID}/quizzes/add/`,
      payload,
    )

    if (!response.ok) {
      const problem = getGeneralApiProblem(response)
      if (problem) return Promise.reject(problem)
    }

    try {
      const { data } = response
      return { kind: 'ok', data }
    } catch {
      return { kind: 'bad-data' }
    }
  }

  async removeQuizzesFromTopic(topicID: number, payload: any): Promise<any> {
    const response: ApiResponse<any> = await this.apisauce.delete(
      `/topics/${topicID}/quizzes/`,
      {},
      {
        data: {
          ...payload,
        },
      },
    )

    if (!response.ok) {
      const problem = getGeneralApiProblem(response)
      if (problem) return Promise.reject(problem)
    }

    try {
      const { data } = response
      return { kind: 'ok', data }
    } catch {
      return { kind: 'bad-data' }
    }
  }

  async updateQuiz(id: number, payload: any): Promise<any> {
    const response: ApiResponse<any> = await this.apisauce.patch(`/quizzes/${id}/`, payload)

    if (!response.ok) {
      const problem = getGeneralApiProblem(response)
      if (problem) return Promise.reject(problem)
    }

    try {
      const { data } = response
      return { kind: 'ok', data }
    } catch {
      return { kind: 'bad-data' }
    }
  }

  async removeQuestionsFromQuiz(quizId: any, questions: any) {
    const response: ApiResponse<any> = await this.apisauce.delete(
      `/quizzes/${quizId}/questions/`,
      {},
      {
        data: {
          questions,
        },
      },
    )

    if (!response.ok) {
      const problem = getGeneralApiProblem(response)
      if (problem) return Promise.reject(problem)
    }

    try {
      const { data } = response
      return { kind: 'ok', data }
    } catch {
      return { kind: 'bad-data' }
    }
  }

  /**
   * List All Questions
   */
  async getQuestionDetails(id: any): Promise<any> {
    const response: ApiResponse<QuestionReadModelType[]> = await this.apisauce.get(
      `/questions/${id}/`,
      {},
    )

    if (!response.ok) {
      const problem = getGeneralApiProblem(response)
      if (problem) return Promise.reject(problem)
    }

    try {
      const { data } = response
      return { kind: 'ok', data }
    } catch {
      return { kind: 'bad-data' }
    }
  }

  /**
   * Retrieve Resource Details
   */
  async getResourceByID(id: number): Promise<any> {
    const response: ApiResponse<ResourceReadModelType> = await this.apisauce.get(
      `/resources/${id}/`,
      {},
    )

    if (!response.ok) {
      const problem = getGeneralApiProblem(response)
      if (problem) return Promise.reject(problem)
    }

    try {
      const { data } = response
      return { kind: 'ok', data }
    } catch {
      return { kind: 'bad-data' }
    }
  }

  /* TALK */

  /**
   * Get Posts
   */
  async getPosts(page: number): Promise<any> {
    const response: ApiResponse<any> = await this.apisauce.get(
      `/posts/?start_page=${page}&items_per_page=15`,
    )

    if (!response.ok) {
      const problem = getGeneralApiProblem(response)
      if (problem) return Promise.reject(problem)
    }

    try {
      const { data } = response
      // reorganize data so that child comments are below their parental comments
      data.posts.forEach((post: any) => {
        const childComments = post.comments.filter((comment: any) => !!comment.parent_comment_id)
        post.comments = post.comments.filter((comment: any) => !comment.parent_comment_id)
        post.comments.forEach((comment: any) => {
          comment.child_comments = childComments.filter(
            (c: any) => c.parent_comment_id === comment.id,
          )
          if (!comment.child_comments) {
            comment.child_comments = null
          }
        })
      })
      return { kind: 'ok', data }
    } catch {
      return { kind: 'bad-data' }
    }
  }

  /**
   * Like Post
   */
  async likePost(postId: number): Promise<any> {
    const response: ApiResponse<any> = await this.apisauce.post(`/like/`, {
      object_id: postId,
      object_type: 'Post',
    })

    if (!response.ok) {
      const problem = getGeneralApiProblem(response)
      if (problem) return Promise.reject(problem)
    }

    try {
      const { data } = response
      return { kind: 'ok', data }
    } catch {
      return { kind: 'bad-data' }
    }
  }

  /**
   * Refresh Post
   */
  async refreshPost(postId: number): Promise<any> {
    const response: ApiResponse<any> = await this.apisauce.get(`/posts/${postId}/`)
    if (!response.ok) {
      const problem = getGeneralApiProblem(response)
      if (problem) return Promise.reject(problem)
    }
    try {
      const { data } = response
      // reorganize data so that child comments are below their parental comments
      const childComments = data.comments.filter((comment: any) => !!comment.parent_comment_id)
      data.comments = data.comments.filter((comment: any) => !comment.parent_comment_id)
      data.comments.forEach((comment: any) => {
        comment.child_comments = childComments.filter(
          (c: any) => c.parent_comment_id === comment.id,
        )
        if (!comment.child_comments) {
          comment.child_comments = null
        }
      })
      return { kind: 'ok', data }
    } catch {
      return { kind: 'bad-data' }
    }
  }

  /**
   * Like Comment
   */
  async likeComment(commentId: number): Promise<any> {
    const response: ApiResponse<any> = await this.apisauce.post(`/like/`, {
      object_id: commentId,
      object_type: 'Comment',
    })

    if (!response.ok) {
      const problem = getGeneralApiProblem(response)
      if (problem) return Promise.reject(problem)
    }

    try {
      const { data } = response
      return { kind: 'ok', data }
    } catch {
      return { kind: 'bad-data' }
    }
  }

  /**
   * Edit Comment
   */
  async editComment(id: number, text: string): Promise<any> {
    const response: ApiResponse<any> = await this.apisauce.patch(`/comments/${id}/`, {
      text,
    })

    if (!response.ok) {
      const problem = getGeneralApiProblem(response)
      if (problem) return Promise.reject(problem)
    }

    try {
      const { data } = response
      return { kind: 'ok', data }
    } catch {
      return { kind: 'bad-data' }
    }
  }

  /**
   * Delete Comment
   */
  async deleteComment(id: number): Promise<any> {
    const response: ApiResponse<any> = await this.apisauce.delete(`/comments/${id}/`)

    if (!response.ok) {
      const problem = getGeneralApiProblem(response)
      if (problem) return Promise.reject(problem)
    }

    try {
      const { data } = response
      return { kind: 'ok', data }
    } catch {
      return { kind: 'bad-data' }
    }
  }

  /**
   * Post Comment
   */
  async commentOnPost(comment: any, postId: number, authorId: number): Promise<any> {
    const response: ApiResponse<any> = await this.apisauce.post(`/comments/${postId}/create/`, {
      author_id: authorId,
      title: comment.title,
      text: comment.text,
    })

    if (!response.ok) {
      const problem = getGeneralApiProblem(response)
      if (problem) return Promise.reject(problem)
    }

    try {
      const { data } = response
      return { kind: 'ok', data }
    } catch {
      return { kind: 'bad-data' }
    }
  }

  /**
   * Post Comment on Comment
   */
  async commentOnComment(
    comment: any,
    postId: number,
    parentCommentId: number,
    authorId: number,
  ): Promise<any> {
    const response: ApiResponse<any> = await this.apisauce.post(
      `/comments/${postId}/${parentCommentId}/create/`,
      {
        author_id: authorId,
        title: comment.title,
        text: comment.text,
      },
    )

    if (!response.ok) {
      const problem = getGeneralApiProblem(response)
      if (problem) return Promise.reject(problem)
    }

    try {
      const { data } = response
      return { kind: 'ok', data }
    } catch {
      return { kind: 'bad-data' }
    }
  }

  /**
   * Report Post
   */
  async reportPost(postId: number, reasonId: number, object_type): Promise<any> {
    const response: ApiResponse<any> = await this.apisauce.post(`/report_inappropriate/`, {
      object_id: postId,
      object_type: object_type,
      reason_id: reasonId,
    })

    if (!response.ok) {
      const problem = getGeneralApiProblem(response)
      if (problem) return Promise.reject(problem)
    }

    try {
      const { data } = response
      return { kind: 'ok', data }
    } catch {
      return { kind: 'bad-data' }
    }
  }

  async deleteUsers(iDs: number[]): Promise<any> {
    const response: ApiResponse<any> = await this.apisauce.delete(
      `/users/delete/`,
      {},
      {
        data: { user_ids: iDs },
      },
    )

    if (!response.ok) {
      const problem = getGeneralApiProblem(response)
      if (problem) return Promise.reject(problem)
    }

    try {
      const { data } = response
      return { kind: 'ok', data }
    } catch {
      return { kind: 'bad-data' }
    }
  }

  async addUsersToTags(userIds: any, tagIds: any): Promise<any> {
    const response: ApiResponse<any> = await this.apisauce.post(`/tags/add_users/`, {
      user_ids: userIds,
      tag_ids: tagIds,
    })

    if (!response.ok) {
      const problem = getGeneralApiProblem(response)
      if (problem) return Promise.reject(problem)
    }

    try {
      const { data } = response
      return { kind: 'ok', data }
    } catch {
      return { kind: 'bad-data' }
    }
  }

  async resendInvitation(userIds: any): Promise<any> {
    const response: ApiResponse<any> = await this.apisauce.post(`/users/resend_invitation/`, {
      user_ids: userIds,
    })

    if (!response.ok) {
      const problem = getGeneralApiProblem(response)
      if (problem) return Promise.reject(problem)
    }

    try {
      const { data } = response
      return { kind: 'ok', data }
    } catch {
      return { kind: 'bad-data' }
    }
  }

  async getPermissions(user_id: any): Promise<any> {
    const response: ApiResponse<any> = await this.apisauce.get(`/users/${user_id}/permissions/`)

    if (!response.ok) {
      const problem = getGeneralApiProblem(response)
      if (problem) return Promise.reject(problem)
    }

    try {
      const { data } = response
      return { kind: 'ok', data }
    } catch {
      return { kind: 'bad-data' }
    }
  }

  async removeAdmins(iDs: number[]): Promise<any> {
    const response: ApiResponse<any> = await this.apisauce.delete(
      `/settings/admins/remove/`,
      {},
      {
        data: { user_ids: iDs },
      },
    )

    if (!response.ok) {
      const problem = getGeneralApiProblem(response)
      if (problem) return Promise.reject(problem)
    }

    try {
      const { data } = response
      return { kind: 'ok', data }
    } catch {
      return { kind: 'bad-data' }
    }
  }

  async saveSettings(settings: any): Promise<any> {
    const response: ApiResponse<any> = await this.apisauce.post(`/settings/`, settings)

    if (!response.ok) {
      const problem = getGeneralApiProblem(response)
      if (problem) return Promise.reject(problem)
    }

    try {
      const { data } = response

      return { kind: 'ok', data }
    } catch {
      return { kind: 'bad-data' }
    }
  }

  async patchCompanyLogo(logo: any): Promise<any> {
    const formData = new FormData()

    formData.append('logo', logo)
    const response: ApiResponse<any> = await this.apisauce.patch(`/settings/logo/`, formData)

    if (!response.ok) {
      const problem = getGeneralApiProblem(response)
      if (problem) return Promise.reject(problem)
    }

    try {
      const { data } = response
      return { kind: 'ok', data }
    } catch {
      return { kind: 'bad-data' }
    }
  }

  async getGroupDetail(groupId: number, startTime: string, endTime: string) {
    const response: ApiResponse<any> = await this.apisauce.get(
      `/groups/${groupId}/?start_datetime=${startTime}&end_datetime=${endTime}`,
    )

    if (!response.ok) {
      const problem = getGeneralApiProblem(response)
      if (problem) return Promise.reject(problem)
    }

    try {
      const { data } = response
      return { kind: 'ok', data }
    } catch {
      return { kind: 'bad-data' }
    }
  }

  async getGroupAccomplishments(groupId: number, startTime: string, endTime: string) {
    const response: ApiResponse<any> = await this.apisauce.get(
      `/groups/${groupId}/accomplishments/?start_datetime=${startTime}&end_datetime=${endTime}`,
    )

    if (!response.ok) {
      const problem = getGeneralApiProblem(response)
      if (problem) return Promise.reject(problem)
    }

    try {
      const { data } = response
      return { kind: 'ok', data }
    } catch {
      return { kind: 'bad-data' }
    }
  }

  async getGroupKnowledgeGain(groupId: number, startTime: string, endTime: string) {
    const response: ApiResponse<any> = await this.apisauce.get(
      `/groups/${groupId}/knowledge_gain/?start_datetime=${startTime}&end_datetime=${endTime}`,
    )

    if (!response.ok) {
      const problem = getGeneralApiProblem(response)
      if (problem) return Promise.reject(problem)
    }

    try {
      const { data } = response
      return { kind: 'ok', data }
    } catch {
      return { kind: 'bad-data' }
    }
  }

  async getGroupTopicGaps(groupId: number, startTime: string, endTime: string) {
    const response: ApiResponse<any> = await this.apisauce.get(
      `/groups/${groupId}/topic_gaps/?start_datetime=${startTime}&end_datetime=${endTime}`,
    )

    if (!response.ok) {
      const problem = getGeneralApiProblem(response)
      if (problem) return Promise.reject(problem)
    }

    try {
      const { data } = response
      return { kind: 'ok', data }
    } catch {
      return { kind: 'bad-data' }
    }
  }

  async getGroupQuestionGaps(groupId: number, startTime: string, endTime: string) {
    const response: ApiResponse<any> = await this.apisauce.get(
      `/groups/${groupId}/question_gaps/?start_datetime=${startTime}&end_datetime=${endTime}`,
    )

    if (!response.ok) {
      const problem = getGeneralApiProblem(response)
      if (problem) return Promise.reject(problem)
    }

    try {
      const { data } = response
      return { kind: 'ok', data }
    } catch {
      return { kind: 'bad-data' }
    }
  }

  async getGroupSessionStats(groupId: number, startTime: string, endTime: string) {
    const response: ApiResponse<any> = await this.apisauce.get(
      `/groups/${groupId}/session_stats/?start_datetime=${startTime}&end_datetime=${endTime}`,
    )

    if (!response.ok) {
      const problem = getGeneralApiProblem(response)
      if (problem) return Promise.reject(problem)
    }

    try {
      const { data } = response
      return { kind: 'ok', data }
    } catch {
      return { kind: 'bad-data' }
    }
  }

  async getGroupHourlyActivity(groupId: number, startTime: string, endTime: string) {
    const response: ApiResponse<any> = await this.apisauce.get(
      `/groups/${groupId}/hourly_activity/?start_datetime=${startTime}&end_datetime=${endTime}`,
    )

    if (!response.ok) {
      const problem = getGeneralApiProblem(response)
      if (problem) return Promise.reject(problem)
    }

    try {
      const { data } = response
      return { kind: 'ok', data }
    } catch {
      return { kind: 'bad-data' }
    }
  }

  async getGroupTags(groupId: number) {
    const response: ApiResponse<any> = await this.apisauce.get(`/groups/${groupId}/tags/`)

    if (!response.ok) {
      const problem = getGeneralApiProblem(response)
      if (problem) return Promise.reject(problem)
    }

    try {
      const { data } = response
      return { kind: 'ok', data }
    } catch {
      return { kind: 'bad-data' }
    }
  }

  async getAddedGroupUsers(groupId: number) {
    const response: ApiResponse<any> = await this.apisauce.get(`/groups/${groupId}/users/added/`)

    if (!response.ok) {
      const problem = getGeneralApiProblem(response)
      if (problem) return Promise.reject(problem)
    }

    try {
      const { data } = response
      return { kind: 'ok', data }
    } catch {
      return { kind: 'bad-data' }
    }
  }

  async getExcludedGroupUsers(groupId: number) {
    const response: ApiResponse<any> = await this.apisauce.get(`/groups/${groupId}/users/excluded/`)

    if (!response.ok) {
      const problem = getGeneralApiProblem(response)
      if (problem) return Promise.reject(problem)
    }

    try {
      const { data } = response
      return { kind: 'ok', data }
    } catch {
      return { kind: 'bad-data' }
    }
  }

  async clearGroupAddedUsers(groupId: number, userIds: any = []) {
    const response: ApiResponse<any> = await this.apisauce.patch(
      `/groups/${groupId}/users/added/clear/`,
      {
        user_ids: userIds,
      },
    )

    if (!response.ok) {
      const problem = getGeneralApiProblem(response)
      if (problem) return Promise.reject(problem)
    }

    try {
      const { data } = response
      return { kind: 'ok', data }
    } catch {
      return { kind: 'bad-data' }
    }
  }

  async restoreGroupExcludedUsers(groupId: number, userIds: any = []) {
    const response: ApiResponse<any> = await this.apisauce.patch(
      `/groups/${groupId}/users/excluded/restore/`,
      {
        user_ids: userIds,
      },
    )

    if (!response.ok) {
      const problem = getGeneralApiProblem(response)
      if (problem) return Promise.reject(problem)
    }

    try {
      const { data } = response
      return { kind: 'ok', data }
    } catch {
      return { kind: 'bad-data' }
    }
  }

  async addUsersToGroup(groupId: number, userIds: { user_ids: any[] }) {
    const response: ApiResponse<any> = await this.apisauce.patch(
      `/groups/${groupId}/users/add/`,
      userIds,
    )

    if (!response.ok) {
      const problem = getGeneralApiProblem(response)
      if (problem) return Promise.reject(problem)
    }

    try {
      const { data } = response
      return { kind: 'ok', data }
    } catch {
      return { kind: 'bad-data' }
    }
  }

  async excludeUsersFromGroup(groupId: number, userIds: { user_ids: [number] }) {
    const response: ApiResponse<any> = await this.apisauce.patch(
      `/groups/${groupId}/users/exclude/`,
      userIds,
    )

    if (!response.ok) {
      const problem = getGeneralApiProblem(response)
      if (problem) return Promise.reject(problem)
    }

    try {
      const { data } = response
      return { kind: 'ok', data }
    } catch {
      return { kind: 'bad-data' }
    }
  }

  async getGroupMastery(groupId: any, startDatetime: any, endDatetime: any) {
    const urlQuery = new URLSearchParams({
      start_datetime: startDatetime,
      end_datetime: endDatetime,
    })

    const response: ApiResponse<any> = await this.apisauce.get(
      `/groups/${groupId}/mastery/?${urlQuery.toString()}`,
    )

    if (!response.ok) {
      const problem = getGeneralApiProblem(response)
      if (problem) return Promise.reject(problem)
    }

    try {
      const { data } = response
      return { kind: 'ok', data }
    } catch {
      return { kind: 'bad-data' }
    }
  }

  async getLeaderboardBase(startDatetime: any, endingDatetime: any): Promise<any> {
    const urlQuery = new URLSearchParams({
      start_datetime: startDatetime,
      end_datetime: endingDatetime,
    })

    const response: ApiResponse<any> = await this.apisauce.get(
      `/leaderboard/?${urlQuery.toString()}`,
    )

    if (!response.ok) {
      const problem = getGeneralApiProblem(response)
      if (problem) return Promise.reject(problem)
    }

    try {
      const { data } = response
      return { kind: 'ok', data }
    } catch {
      return { kind: 'bad-data' }
    }
  }

  async getLeaderboardByObject(
    object_id: any,
    object_type: any,
    startDatetime?: any,
    endingDatetime?: any,
  ): Promise<any> {
    const urlQuery = new URLSearchParams({
      start_datetime: startDatetime || '',
      end_datetime: endingDatetime || '',
    })

    const response: ApiResponse<any> = await this.apisauce.get(
      `/leaderboard/for/${object_type}/${object_id}/?${urlQuery.toString()}`,
    )

    if (!response.ok) {
      const problem = getGeneralApiProblem(response)
      if (problem) return Promise.reject(problem)
    }

    try {
      const { data } = response
      return { kind: 'ok', data }
    } catch {
      return { kind: 'bad-data' }
    }
  }

  async getSessionStats(startDatetime: any, endDatetime: any) {
    const urlQuery = new URLSearchParams({
      start_datetime: startDatetime,
      end_datetime: endDatetime,
    })
    const response: ApiResponse<any> = await this.apisauce.get(
      `/reports/session_stats/?${urlQuery.toString()}`,
    )

    if (!response.ok) {
      const problem = getGeneralApiProblem(response)
      if (problem) return Promise.reject(problem)
    }

    try {
      const { data } = response
      return { kind: 'ok', data }
    } catch {
      return { kind: 'bad-data' }
    }
  }

  async getActivity() {
    const response: ApiResponse<any> = await this.apisauce.get(`/reports/activity/`)

    if (!response.ok) {
      const problem = getGeneralApiProblem(response)
      if (problem) return Promise.reject(problem)
    }

    try {
      const { data } = response
      return { kind: 'ok', data }
    } catch {
      return { kind: 'bad-data' }
    }
  }

  async getExportableReports() {
    const response: ApiResponse<any> = await this.apisauce.get(`/reports/exportable_reports/`)
    if (!response.ok) {
      const problem = getGeneralApiProblem(response)
      if (problem) return Promise.reject(problem)
    }

    try {
      const { data } = response
      return { kind: 'ok', data }
    } catch {
      return { kind: 'bad-data' }
    }
  }

  async getReportsBaseStats(startDatetime: any, endDatetime: any) {
    const urlQuery = new URLSearchParams({
      start_datetime: startDatetime,
      end_datetime: endDatetime,
    })

    const response: ApiResponse<any> = await this.apisauce.get(
      `/reports/base/?${urlQuery.toString()}`,
    )

    if (!response.ok) {
      const problem = getGeneralApiProblem(response)
      if (problem) return Promise.reject(problem)
    }

    try {
      const { data } = response
      return { kind: 'ok', data }
    } catch {
      return { kind: 'bad-data' }
    }
  }

  async getReportsHourlyActivity(startDatetime: any, endDatetime: any) {
    const urlQuery = new URLSearchParams({
      start_datetime: startDatetime,
      end_datetime: endDatetime,
    })

    const response: ApiResponse<any> = await this.apisauce.get(
      `/reports/activity/hourly_activity/?${urlQuery.toString()}`,
    )

    if (!response.ok) {
      const problem = getGeneralApiProblem(response)
      if (problem) return Promise.reject(problem)
    }

    try {
      const { data } = response
      return { kind: 'ok', data }
    } catch {
      return { kind: 'bad-data' }
    }
  }

  async emailReport(email: string, report_id: any, assignment_id?: number) {
    const response: ApiResponse<any> = await this.apisauce.post(
      `/reports/email/${report_id}/${assignment_id ? `?assignment_id=${assignment_id}` : ''}`,
      {
        email,
      },
    )
    if (!response.ok) {
      const problem = getGeneralApiProblem(response)
      if (problem) return Promise.reject(problem)
    }

    try {
      const { data } = response
      return { kind: 'ok', data }
    } catch {
      return { kind: 'bad-data' }
    }
  }

  async getKnowledgeMastery(startDatetime: any, endDatetime: any) {
    const urlQuery = new URLSearchParams({
      start_datetime: startDatetime,
      end_datetime: endDatetime,
    })

    const response: ApiResponse<any> = await this.apisauce.get(
      `/reports/mastery/?${urlQuery.toString()}`,
    )

    if (!response.ok) {
      const problem = getGeneralApiProblem(response)
      if (problem) return Promise.reject(problem)
    }

    try {
      const { data } = response
      return { kind: 'ok', data }
    } catch {
      return { kind: 'bad-data' }
    }
  }

  async inviteAdmin(formData: any) {
    const response: ApiResponse<any> = await this.apisauce.post(`/users/invite_admin/`, formData)

    if (!response.ok) {
      const problem = getGeneralApiProblem(response)
      if (problem) return Promise.reject(problem)
    }

    try {
      const { data } = response
      return { kind: 'ok', data }
    } catch {
      return { kind: 'bad-data' }
    }
  }

  async inviteUser(formData: any) {
    const response: ApiResponse<any> = await this.apisauce.post(`/users/invite/`, formData)

    if (!response.ok) {
      const problem = getGeneralApiProblem(response)
      if (problem) return Promise.reject(problem)
    }

    try {
      const { data } = response
      return { kind: 'ok', data }
    } catch {
      return { kind: 'bad-data' }
    }
  }

  async importUsers(users: any) {
    const response: ApiResponse<any> = await this.apisauce.post(`/management/import/people/`, users)

    if (!response.ok) {
      const problem = getGeneralApiProblem(response)
      if (problem) return Promise.reject(problem)
    }

    try {
      const { data } = response
      return { kind: 'ok', data }
    } catch {
      return { kind: 'bad-data' }
    }
  }

  async getTagHourlyActivity(tagId: number, startTime: string, endTime: string) {
    const response: ApiResponse<any> = await this.apisauce.get(
      `/tags/${tagId}/hourly_activity/?start_datetime=${startTime}&end_datetime=${endTime}`,
    )

    if (!response.ok) {
      const problem = getGeneralApiProblem(response)
      if (problem) return Promise.reject(problem)
    }

    try {
      const { data } = response
      return { kind: 'ok', data }
    } catch {
      return { kind: 'bad-data' }
    }
  }

  async getTagAccomplishingStats(tagId: number, startTime: string, endTime: string) {
    const response: ApiResponse<any> = await this.apisauce.get(
      `/tags/${tagId}/accomplishing_stats/?start_datetime=${startTime}&end_datetime=${endTime}`,
    )

    if (!response.ok) {
      const problem = getGeneralApiProblem(response)
      if (problem) return Promise.reject(problem)
    }

    try {
      const { data } = response
      return { kind: 'ok', data }
    } catch {
      return { kind: 'bad-data' }
    }
  }

  async getTagSessionStats(tagId: number, startTime: string, endTime: string) {
    const response: ApiResponse<any> = await this.apisauce.get(
      `/tags/${tagId}/session_stats/?start_datetime=${startTime}&end_datetime=${endTime}`,
    )

    if (!response.ok) {
      const problem = getGeneralApiProblem(response)
      if (problem) return Promise.reject(problem)
    }

    try {
      const { data } = response
      return { kind: 'ok', data }
    } catch {
      return { kind: 'bad-data' }
    }
  }

  async getTagOverviewStats(tagId: number, startTime: string, endTime: string) {
    const response: ApiResponse<any> = await this.apisauce.get(
      `/tags/${tagId}/base_stats/?start_datetime=${startTime}&end_datetime=${endTime}`,
    )

    if (!response.ok) {
      const problem = getGeneralApiProblem(response)
      if (problem) return Promise.reject(problem)
    }

    try {
      const { data } = response
      return { kind: 'ok', data }
    } catch {
      return { kind: 'bad-data' }
    }
  }

  async getTagKnowledgeGainStats(tagId: number, startTime: string, endTime: string) {
    const response: ApiResponse<any> = await this.apisauce.get(
      `/tags/${tagId}/knowledge_gain/?start_datetime=${startTime}&end_datetime=${endTime}`,
    )

    if (!response.ok) {
      const problem = getGeneralApiProblem(response)
      if (problem) return Promise.reject(problem)
    }

    try {
      const { data } = response
      return { kind: 'ok', data }
    } catch {
      return { kind: 'bad-data' }
    }
  }

  async getTagTopicGapsStats(tagId: number, startTime: string, endTime: string) {
    const response: ApiResponse<any> = await this.apisauce.get(
      `/tags/${tagId}/topic_gaps/?start_datetime=${startTime}&end_datetime=${endTime}`,
    )

    if (!response.ok) {
      const problem = getGeneralApiProblem(response)
      if (problem) return Promise.reject(problem)
    }

    try {
      const { data } = response
      return { kind: 'ok', data }
    } catch {
      return { kind: 'bad-data' }
    }
  }

  async getTagQuestionGapsStats(tagId: number, startTime: string, endTime: string) {
    const response: ApiResponse<any> = await this.apisauce.get(
      `/tags/${tagId}/question_gaps/?start_datetime=${startTime}&end_datetime=${endTime}`,
    )

    if (!response.ok) {
      const problem = getGeneralApiProblem(response)
      if (problem) return Promise.reject(problem)
    }

    try {
      const { data } = response
      return { kind: 'ok', data }
    } catch {
      return { kind: 'bad-data' }
    }
  }

  async removePhotoFromQuestion(id: number): Promise<any> {
    const response: ApiResponse<Instance<typeof UserSchemaModelBase>> = await this.apisauce.delete(
      `/questions/${id}/image/`,
    )

    if (!response.ok) {
      const problem = getGeneralApiProblem(response)
      if (problem) return Promise.reject(problem)
    }

    try {
      const { data } = response
      return { kind: 'ok', data }
    } catch {
      return { kind: 'bad-data' }
    }
  }

  async setImageForQuestion(id: number, image: any): Promise<any> {
    const formData = new FormData()
    formData.append('image', image)

    const response: ApiResponse<Instance<typeof UserSchemaModelBase>> = await this.apisauce.patch(
      `questions/${id}/image/`,
      formData,
    )

    if (!response.ok) {
      const problem = getGeneralApiProblem(response)
      if (problem) return Promise.reject(problem)
    }

    try {
      const { data } = response
      return { kind: 'ok', data }
    } catch {
      return { kind: 'bad-data' }
    }
  }

  async getTagCategoryDetail(tagKey: string) {
    const response: ApiResponse<any> = await this.apisauce.get(`/tags/category/${tagKey}/`)

    if (!response.ok) {
      const problem = getGeneralApiProblem(response)
      if (problem) return Promise.reject(problem)
    }

    try {
      const { data } = response
      return { kind: 'ok', data }
    } catch {
      return { kind: 'bad-data' }
    }
  }

  async deleteTags(tagIds: any, safe?: boolean) {
    const response: ApiResponse<any> = await this.apisauce.delete(
      `/tags/delete/`,
      { safe },
      { data: { tag_ids: tagIds } },
    )

    if (!response.ok) {
      const problem = getGeneralApiProblem(response)
      if (problem) return Promise.reject(problem)
    }

    try {
      const { data } = response
      return { kind: 'ok', data }
    } catch {
      return { kind: 'bad-data' }
    }
  }

  async deleteGroups(groupIds: any) {
    const response: ApiResponse<any> = await this.apisauce.delete(
      `/groups/delete/`,
      {},
      { data: groupIds },
    )

    if (!response.ok) {
      const problem = getGeneralApiProblem(response)
      if (problem) return Promise.reject(problem)
    }

    try {
      const { data } = response
      return { kind: 'ok', data }
    } catch {
      return { kind: 'bad-data' }
    }
  }

  async removeUsersFromTags(userIds: [number], tagIds: [number]) {
    const response: ApiResponse<any> = await this.apisauce.post(`/tags/remove_users/`, {
      tag_ids: tagIds,
      user_ids: userIds,
    })

    if (!response.ok) {
      const problem = getGeneralApiProblem(response)
      if (problem) return Promise.reject(problem)
    }

    try {
      const { data } = response
      return { kind: 'ok', data }
    } catch {
      return { kind: 'bad-data' }
    }
  }

  async createTag(createGroup: boolean, tag: any) {
    const response: ApiResponse<any> = await this.apisauce.post(
      `/tags/create/?create_group=${createGroup}`,
      tag,
    )

    if (!response.ok) {
      const problem = getGeneralApiProblem(response)
      if (problem) return Promise.reject(problem)
    }

    try {
      const { data } = response
      return { kind: 'ok', data }
    } catch {
      return { kind: 'bad-data' }
    }
  }

  async getTaggableUsers(filter: string, tagId: number) {
    const response: ApiResponse<any> = await this.apisauce.get(
      `/tags/${tagId}/users/available/?q=${filter}`,
    )

    if (!response.ok) {
      const problem = getGeneralApiProblem(response)
      if (problem) return Promise.reject(problem)
    }

    try {
      const { data } = response
      return { kind: 'ok', data }
    } catch {
      return { kind: 'bad-data' }
    }
  }

  async getTagMastery(tagId: any, startDatetime: any, endDatetime: any) {
    const urlQuery = new URLSearchParams({
      start_datetime: startDatetime,
      end_datetime: endDatetime,
    })

    const response: ApiResponse<any> = await this.apisauce.get(
      `/tags/${tagId}/mastery/?${urlQuery.toString()}`,
    )

    if (!response.ok) {
      const problem = getGeneralApiProblem(response)
      if (problem) return Promise.reject(problem)
    }

    try {
      const { data } = response
      return { kind: 'ok', data }
    } catch {
      return { kind: 'bad-data' }
    }
  }

  async toggleExcludeFromLeaderboard(id: number) {
    const response: ApiResponse<any> = await this.apisauce.post(
      `/users/${id}/toggle_exclude_from_leaderboard/`,
    )

    if (!response.ok) {
      const problem = getGeneralApiProblem(response)
      if (problem) return Promise.reject(problem)
    }

    try {
      const { data } = response
      return { kind: 'ok', data }
    } catch {
      return { kind: 'bad-data' }
    }
  }

  async reportProblem(text: string, subject: string): Promise<any> {
    const response: ApiResponse<any> = await this.apisauce.post(`settings/support/`, {
      text,
      subject,
    })

    if (!response.ok) {
      const problem = getGeneralApiProblem(response)
      if (problem) return Promise.reject(problem)
    }

    try {
      const { data } = response
      return { kind: 'ok', data }
    } catch {
      return { kind: 'bad-data' }
    }
  }

  async getReportTopicGaps(startDatetime: any, endDatetime: any) {
    const urlQuery = new URLSearchParams({
      start_datetime: startDatetime,
      end_datetime: endDatetime,
    })
    const response: ApiResponse<any> = await this.apisauce.get(
      `/reports/topic_gaps/?${urlQuery.toString()}`,
    )

    if (!response.ok) {
      const problem = getGeneralApiProblem(response)
      if (problem) return Promise.reject(problem)
    }

    try {
      const { data } = response
      return { kind: 'ok', data }
    } catch {
      return { kind: 'bad-data' }
    }
  }

  async getReportQuestionGaps(startDatetime: any, endDatetime: any) {
    const urlQuery = new URLSearchParams({
      start_datetime: startDatetime,
      end_datetime: endDatetime,
    })
    const response: ApiResponse<any> = await this.apisauce.get(
      `/reports/question_gaps/?${urlQuery.toString()}`,
    )

    if (!response.ok) {
      const problem = getGeneralApiProblem(response)
      if (problem) return Promise.reject(problem)
    }

    try {
      const { data } = response
      return { kind: 'ok', data }
    } catch {
      return { kind: 'bad-data' }
    }
  }

  async removeResourcesFromTopic(topicId: any, resource_ids: any): Promise<any> {
    const response: ApiResponse<any> = await this.apisauce.delete(
      `/topics/${topicId}/resources/`,
      {},
      { data: { resources: resource_ids } },
    )

    if (!response.ok) {
      const problem = getGeneralApiProblem(response)
      if (problem) return Promise.reject(problem)
    }
    try {
      const { data } = response
      return { kind: 'ok', data }
    } catch {
      return { kind: 'bad-data' }
    }
  }

  async getLimitStatus(): Promise<any> {
    const response: ApiResponse<any> = await this.apisauce.get(`/settings/limits/`)
    if (!response.ok) {
      const problem = getGeneralApiProblem(response)
      if (problem) return Promise.reject(problem)
    }

    try {
      const { data } = response
      return { kind: 'ok', data }
    } catch {
      return { kind: 'bad-data' }
    }
  }
}
