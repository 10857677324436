import React, { useEffect, useState } from 'react'
import { Delete, LocalOfferRounded, PeopleRounded } from '@mui/icons-material'
import { observer } from 'mobx-react-lite'
import { CircularProgress } from '@mui/material'

import { useStores } from '@trivie/core'
import { ConfirmDialog } from '@trivie/ui-web/components/confirm-dialog'
import { DataTable, TableHelper } from '@trivie/ui-web/components/data-table/data-table'
import { Toolbar } from '@trivie/ui-web/components/table-toolbar'
import { CreateGroup } from '@trivie/ui-web/components/create-group'
import { TagUsers } from '@trivie/ui-web/components/tag-users'

interface TagTableProps {
  tableHelper: TableHelper
  forceLoading?: boolean
  openFilter?: boolean
  exportFilename?: string
}

export const TagTable = observer((props: TagTableProps) => {
  const { exportFilename, openFilter, tableHelper, forceLoading } = props

  const { manageUserStore, tagStore } = useStores()
  const { deleteTags } = tagStore
  const { gdpr, permissions } = manageUserStore

  const [selectedRows, setSelectedRows] = useState<any>([])
  const [loading, setLoading] = useState<boolean>(tableHelper.custom ? false : true)

  // Dialogs
  const [showConfirmDelete, setShowConfirmDelete] = useState(false)
  const [showCreateGroup, setShowCreateGroup] = useState<any>()
  const [showTagUsers, setShowTagUsers] = useState(false)

  // Dialog Messages
  const [headerTitle, setHeaderTitle] = useState<string>('')
  const [deleteMessage, setDeleteMessage] = useState<string>('')
  const [deleteConfirmationMessage, setDeleteConfirmationMessage] = useState<string>('')

  const setupDeleteModal = () => {
    const rowCount = selectedRows.length
    const row: any = tableHelper.rows.find((item: any) => item.id === selectedRows[0])
    const title = `Delete Tag${rowCount > 1 ? 's' : ''}`
    const message = `Are you sure you want to delete ${
      rowCount > 1 ? `these ${rowCount} tags?` : 'this tag?'
    }`
    const confirmMessage =
      rowCount === 1
        ? `${row?.tag_key} > ${row?.tag_value} has been deleted.`
        : `${rowCount} tags deleted.`

    setHeaderTitle(title)
    setDeleteMessage(message)
    setDeleteConfirmationMessage(confirmMessage)
    setShowConfirmDelete(true)
  }

  const getActions = () => {
    switch (tableHelper.parent) {
      case 'users':
        return []
      default:
        return [
          {
            label: 'Tag Users',
            icon: LocalOfferRounded,
            onClick: () => setShowTagUsers(true),
            disabled: !permissions?.permissions.some((p) => p.code === 'can_edit_user'),
          },
          {
            label: 'Create Group',
            icon: PeopleRounded,
            onClick: () => setShowCreateGroup(true),
            disabled: !permissions?.permissions.some((p) => p.code === 'can_edit_user'),
          },
          {
            label: 'Delete',
            icon: Delete,
            onClick: () => setupDeleteModal(),
            disabled: !permissions?.permissions.some((p) => p.code === 'can_delete_tag'),
          },
        ]
    }
  }

  const [tableActions, setTableActions] = useState<any>(getActions())

  useEffect(() => {
    if (permissions) {
      setTableActions(getActions())
    }
  }, [permissions?.permissions])

  useEffect(() => {
    setLoading(Boolean(forceLoading))
  }, [forceLoading])

  useEffect(() => {
    load()
  }, [])

  useEffect(() => {
    setTableActions(getActions())
  }, [selectedRows])

  const load = () => {
    if (!tableHelper.custom) {
      setLoading(true)
      tableHelper.load().then(() => setLoading(false))
    }
  }

  const save = async (columns: any, filter: any) => {
    setLoading(true)
    tableHelper.save(columns, filter).then(() => setLoading(false))
  }

  const getInitialTags = () => {
    const i = [] as any
    selectedRows.map((id) => {
      tableHelper.rows.map((tag: any) => {
        if (tag.id === id) {
          i.push({
            key: tag.tag_key,
            value: tag.tag_value,
            id: tag.id,
            user_count: tag.tag_user_count,
          })
        }
      })
    })
    return i
  }

  return loading ? (
    <div style={LOADER}>
      <CircularProgress />
    </div>
  ) : (
    <>
      <DataTable
        gdpr={gdpr}
        allowNewReport
        openFilter={openFilter}
        columnVisibilityModel={tableHelper.columnVisibilityModel}
        hideAddColumn
        tableHelper={tableHelper}
        filterModel={tableHelper.filterModel}
        sortModel={tableHelper.sortModel}
        searchPlaceholder="Search Tags"
        rows={tableHelper.rows}
        columns={tableHelper.columns}
        onSaveLayout={tableHelper?.saveLayout}
        onSaveFilters={save}
        toolbar={<Toolbar selected={selectedRows} actions={tableActions} />}
        exportFilename={
          exportFilename
            ? exportFilename
            : tableHelper.parent !== 'tags'
              ? `${tableHelper.parent}_tags`
              : 'tags'
        }
        rowSelectionModel={selectedRows}
        onRowSelectionModelChange={(param) => setSelectedRows(param)}
      />
      {showConfirmDelete && (
        <ConfirmDialog
          danger
          open={showConfirmDelete}
          title={headerTitle}
          message={deleteMessage}
          confirmedMessage={deleteConfirmationMessage}
          confirmButton="Confirm"
          onConfirm={() => deleteTags(selectedRows, false)}
          onClose={() => {
            setSelectedRows([])
            load()
            setShowConfirmDelete(false)
          }}
          handleCancel={() => setShowConfirmDelete(false)}
        />
      )}
      {showCreateGroup && (
        <CreateGroup
          reload={load}
          open={showCreateGroup}
          initialTags={getInitialTags()}
          onClose={() => {
            setSelectedRows([])
            setShowCreateGroup(false)
          }}
        />
      )}
      {showTagUsers && (
        <TagUsers
          open={showTagUsers}
          onClose={() => setShowTagUsers(false)}
          tagIds={selectedRows}
        />
      )}
    </>
  )
})

const LOADER: React.CSSProperties = {
  display: 'flex',
  flex: 1,
  justifyContent: 'center',
  flexDirection: 'column',
  alignItems: 'center',
  height: window.innerHeight / 1.5,
}
