import React from 'react'

import TableCell from '@mui/material/TableCell'
import { ListAltRounded } from '@mui/icons-material'
import { color } from '../../../theme'
import { Text } from '../../text'
import { Link } from 'react-router-dom'

export const AssignmentsShortCell = (props: any) => {
  const { row } = props
  return (
    <TableCell
      style={{
        borderBottom: 'none',
        padding: 0,
      }}
      align="left"
    >
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
        }}
      >
        <Link
          style={{
            flexDirection: 'row',
            display: 'flex',
            textDecoration: 'none',
            color: color.black,
            alignItems: 'center',
          }}
          to={`/activities/assignments/live/${row.assignment_id}/overview`}
        >
          <ListAltRounded style={{ marginRight: 8 }} />
          <div style={{ flexDirection: 'column' }}>
            <Text text={row.title} variant="body1Medium" />
            <Text
              text={`${row.number_topics} members`}
              variant="body1"
              style={{ color: color.shade50 }}
            />
          </div>
        </Link>
      </div>
    </TableCell>
  )
}
