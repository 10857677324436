import { types } from 'mobx-state-tree'
import { GroupRecipient, UserRecipient } from './MessageRecipients'

export const Message = types.model('Message').props({
  message_id: types.number,
  subject: types.string,
  message_text: types.string,
  sender_name: types.string,
  recipient_count: types.number,
  send_datetime: types.maybeNull(types.string),
  message_type: types.string,
})

export const MessagesResponse = types.model('Message').props({
  total_count: types.number,
  first_page: types.number,
  last_page: types.number,
  next_page: types.number,
  previous_page: types.maybeNull(types.number),
  objects: types.array(Message),
})

export const ScheduledSentMessage = types.model('ScheduledSentMessage').props({
  message_id: types.number,
  send_push: types.boolean,
  send_email: types.boolean,
  subject: types.string,
  message_text: types.string,
  send_datetime: types.maybeNull(types.string),
  users: types.maybeNull(types.array(UserRecipient)),
  groups: types.maybeNull(types.array(GroupRecipient)),
})
