import React, { useState } from 'react'
import { observer } from 'mobx-react-lite'
import { Paper, TextField } from '@mui/material'
import { Edit, AddRounded } from '@mui/icons-material'
import { color } from '@trivie/ui-web/theme'
import { Button } from '@trivie/ui-web/components/button'
import { Divider } from '@trivie/ui-web/components/divider'
import { RadioQuestion } from '../survey-creation/radio-question'
import { ShortAnswerQuestion } from '../survey-creation/short-answer-question'
import { RangeQuestion } from '../survey-creation/range-question'
import { TrueFalseQuestion } from '../survey-creation/true-false-question'
import { useStores } from '@trivie/core'
import { SurveyQuestion } from '@trivie/core/src/models/Survey'
import { getNodeId, Instance } from 'mobx-state-tree'

export const SurveyCard: React.FunctionComponent = observer(() => {
  const [isEditing, setIsEditing] = useState(false)
  const { surveyStore } = useStores()
  const { selectedSurvey, resetOriginalSurvey } = surveyStore
  const isEditable = !(selectedSurvey?.isCompleted || selectedSurvey?.isLive)
  const showQuestionPct = selectedSurvey?.isLive || selectedSurvey?.isCompleted
  const onCancelEdit = () => {
    setIsEditing(false)
    resetOriginalSurvey()
  }
  const handleUpdate = async () => {
    selectedSurvey?.setShowErrors(true)
    selectedSurvey?.setShowTitleError(true)
    if (selectedSurvey && selectedSurvey.valid) {
      surveyStore.updateSurvey(selectedSurvey)
      setIsEditing(false)
    } else {
      throw new Error('selectedSurvey is not found')
    }
  }

  const renderHeaderButtons = () => {
    return isEditing ? (
      <div style={{ display: 'flex', gap: 12 }}>
        <Button onClick={onCancelEdit} color="info" variant="outlined" text="Cancel" />
        <Button
          onClick={handleUpdate}
          variant="contained"
          text="Save"
          disabled={!selectedSurvey?.valid || selectedSurvey.questions?.length === 0}
        />
      </div>
    ) : (
      <Button
        onClick={() => setIsEditing(true)}
        variant="contained"
        color="white"
        startIcon={<Edit />}
        text="Edit"
      />
    )
  }
  const renderHeader = () => {
    return (
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <TextField
          variant="standard"
          onBlur={() => selectedSurvey?.setShowTitleError(true)}
          onChange={(e) => selectedSurvey?.setTitle(e.target.value)}
          value={selectedSurvey?.title}
          placeholder="Untitled Survey"
          style={QUESTION_TEXT_FIELD}
          multiline
          InputProps={{
            style: {
              ...QUESTION_TEXT_INPUT,
              color:
                selectedSurvey?.showTitleError &&
                (!selectedSurvey?.hasTitle || selectedSurvey?.longTitle)
                  ? color.red
                  : color.black,
            },
            disableUnderline: true,
          }}
          disabled={!isEditing}
        />
        <div>{isEditable ? renderHeaderButtons() : null}</div>
      </div>
    )
  }

  const [draggingQuestionID, setDraggingQuestionID] = useState<null | number>(null)

  const handleDrag = (question: Instance<typeof SurveyQuestion>) =>
    setDraggingQuestionID(getNodeId(question))

  const handleDrop = (question: Instance<typeof SurveyQuestion>) => {
    if (selectedSurvey) {
      const { questions } = selectedSurvey
      const dragQuestion = questions?.find((q) => getNodeId(q) === draggingQuestionID)
      const dropQuestion = questions?.find((q) => getNodeId(q) === getNodeId(question))

      if (dragQuestion && dropQuestion) {
        selectedSurvey.reorder(
          questions?.indexOf(dragQuestion) ?? 0,
          questions?.indexOf(dropQuestion) ?? 0,
        )
      }
    }
  }

  return (
    <Paper variant="outlined" style={CONTAINER}>
      {renderHeader()}
      <Divider style={{ marginTop: 20, marginBottom: 20 }} />
      {selectedSurvey?.questions?.map((question, index: number) => {
        switch (question.question_type) {
          case 'open_text':
            return (
              <ShortAnswerQuestion
                showQuestionPct={showQuestionPct}
                question={question}
                disabled={!isEditing}
                handleDrop={() => handleDrop(question)}
                handleDrag={() => handleDrag(question)}
              />
            )
          case 'range_response':
            return (
              <RangeQuestion
                showQuestionPct={showQuestionPct}
                question={question}
                disabled={!isEditing}
                handleDrop={() => handleDrop(question)}
                handleDrag={() => handleDrag(question)}
              />
            )
          case 'true_false':
            return (
              <TrueFalseQuestion
                showQuestionPct={showQuestionPct}
                question={question}
                disabled={!isEditing}
                handleDrop={() => handleDrop(question)}
                handleDrag={() => handleDrag(question)}
              />
            )
          default:
            return (
              <RadioQuestion
                showQuestionPct={showQuestionPct}
                question={question}
                disabled={!isEditing}
                handleDrop={() => handleDrop(question)}
                handleDrag={() => handleDrag(question)}
              />
            )
        }
      })}
      {isEditing ? (
        <Button
          color="black"
          startIcon={<AddRounded />}
          onClick={selectedSurvey?.addNewQuestion}
          text="Add Question"
          sx={{ marginTop: '20px' }}
        />
      ) : null}
    </Paper>
  )
})

const CONTAINER: React.CSSProperties = {
  flex: 1,
  display: 'flex',
  flexDirection: 'column',
  overflow: 'hidden',
  flexGrow: 3,
  marginRight: 20,
  padding: 45,
  paddingTop: 17,
  borderRadius: 20,
}
const ADD_QUESTION_BTN: React.CSSProperties = {
  padding: 0,
  borderRadius: 10,
  paddingLeft: 16,
  paddingRight: 16,
  paddingTop: 11,
  paddingBottom: 11,
  backgroundColor: color.black,
  width: 150,
  marginTop: 32,
}
const QUESTION_TEXT_FIELD: React.CSSProperties = {
  flex: 1,
  marginRight: '10px',
}
const QUESTION_TEXT_INPUT: React.CSSProperties = {
  borderBottom: 'none',
  color: color.black,
  fontWeight: 600,
  fontSize: 16,
  lineHeight: '20px',
}
