import React from 'react'
import { Instance } from 'mobx-state-tree'
import { color } from '@trivie/ui-web/theme'
import { Avatar } from '@trivie/ui-web/components/avatar'
import { Text } from '@trivie/ui-web/components/text'
import { SurveyUser } from '@trivie/core/src/models/survey/SurveyUserGroup'

export function UserItem({ user }: { user: Instance<typeof SurveyUser> }) {
  const { display_name, photo_url, email } = user

  return (
    <div style={{ display: 'flex', alignItems: 'center' }}>
      <Avatar name={display_name} url={photo_url} />
      <div style={{ marginLeft: 16 }}>
        <Text
          style={{
            textAlign: 'left',
            overflowWrap: 'break-word',
            maxWidth: '150px',
          }}
          variant="body1Emphasized"
          text={display_name}
        />
        <Text
          variant="body2"
          text={email}
          style={{
            color: color.shade50,
            overflowWrap: 'break-word',
            maxWidth: '150px',
          }}
        />
      </div>
    </div>
  )
}
