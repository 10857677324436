import React, { useState, useEffect } from 'react'
import { observer } from 'mobx-react-lite'
import { Instance, cast } from 'mobx-state-tree'
import { IconButton, Paper, Divider, TextField, InputAdornment } from '@mui/material'
import { Edit, Add, Search } from '@mui/icons-material'

import { Dialog } from '@trivie/ui-web/components/dialogs/dialog'
import { Button } from '@trivie/ui-web/components/button'
import { color } from '@trivie/ui-web/theme'
import { Text } from '@trivie/ui-web/components/text'
import { AssignmentUsersDialog } from './assign-users'
import { SurveyUser, SurveyGroup } from '@trivie/core/src/models/survey/SurveyUserGroup'
import { useStores } from '@trivie/core'
import { Close } from '@mui/icons-material'
import { GroupItem } from './group-item'
import { UserItem } from './user-item'

export const PeopleCard: React.FunctionComponent = observer(() => {
  const [isEditing, setIsEditing] = useState(false)
  const [openAssignDialog, setOpenAssignDialog] = useState(false)
  const [showRemoveUser, setShowRemoveUser] = useState(false)
  const [showRemoveGroup, setShowRemoveGroup] = useState(false)
  const [filter, setFilter] = useState('')
  const [dontAsk, setDontAsk] = useState(false)
  const [userId, setUserId] = useState<number | null>(null)
  const [groupId, setGroupId] = useState<number | null>(null)

  const { surveyStore } = useStores()
  const { selectedSurvey, updateSurveyAssignees, getSurveyDetails } = surveyStore
  const isLiveOrCompleted = selectedSurvey?.isCompleted || selectedSurvey?.isLive
  const isNotUnscheduled = selectedSurvey?.isScheduled || isLiveOrCompleted

  const [selectedAssignees, setSelectedAssignees] = useState<{
    groups: Instance<typeof SurveyGroup>[]
    users: Instance<typeof SurveyUser>[]
  }>()

  useEffect(() => {
    setSelectedAssignees({
      groups: selectedSurvey?.groups || [],
      users: selectedSurvey?.users || [],
    })
  }, [selectedSurvey])

  const handleDeleteUser = (id: number) => {
    if (dontAsk) {
      deleteUserOrGroup(id, null)
    } else {
      setUserId(id)
      setShowRemoveUser(true)
    }
  }

  const handleDeleteGroup = (id: number) => {
    if (dontAsk) {
      deleteUserOrGroup(null, id)
    } else {
      setGroupId(id)
      setShowRemoveGroup(true)
    }
  }

  const deleteUserOrGroup = (user_id: number | null, group_id: number | null) => {
    if (selectedSurvey && selectedSurvey.id) {
      const data = {
        group_ids: selectedSurvey?.groups
          .filter((gr: Instance<typeof SurveyGroup>) => gr.group_id !== group_id)
          .map((gr: Instance<typeof SurveyGroup>) => gr.group_id),
        user_ids: selectedSurvey?.users
          .filter((usr: Instance<typeof SurveyUser>) => usr.user_id !== user_id)
          .map((usr: Instance<typeof SurveyUser>) => usr.user_id),
      }
      updateSurveyAssignees(selectedSurvey.id, cast(data)).then(() => {
        if (selectedSurvey && selectedSurvey.id) {
          getSurveyDetails(selectedSurvey.id)
        }
        setShowRemoveUser(false)
        setShowRemoveGroup(false)
      })
    }
  }

  const getFilteredUsers = () => {
    if (isNotUnscheduled) {
      return selectedAssignees?.users?.filter((usr) =>
        filter
          ? usr.display_name?.toLowerCase()?.includes(filter?.toLowerCase()) ||
            usr.email?.toLowerCase()?.includes(filter.toLowerCase())
          : true,
      )
    }
    return selectedAssignees?.users
  }
  const getFilteredGroups = () => {
    if (isNotUnscheduled) {
      return selectedAssignees?.groups?.filter((grp) =>
        filter ? grp?.label?.toLowerCase()?.includes(filter?.toLowerCase()) : true,
      )
    }
    return selectedAssignees?.groups
  }

  const clearSelections = () => {
    const data = {
      groups:
        selectedAssignees?.groups?.filter((f) => selectedSurvey?.groupIds.includes(f.group_id)) ||
        [],
      users:
        selectedAssignees?.users?.filter((f) => selectedSurvey?.userIds.includes(f.user_id)) || [],
    }
    setSelectedAssignees(data)
    setIsEditing(false)
  }

  const renderHeaderButtons = () => {
    return isEditing ? (
      <div style={{ display: 'flex', gap: 12 }}>
        <Button
          size="small"
          onClick={clearSelections}
          variant="outlined"
          text="Cancel"
          color="info"
        />
        <Button size="small" onClick={handleOnSubmit} text="Save" />
      </div>
    ) : (
      <Button
        onClick={() => {
          setFilter('')
          setIsEditing(true)
        }}
        variant="outlined"
        color="info"
        size="small"
        startIcon={<Edit />}
        text="Edit"
      />
    )
  }
  const renderHeader = () => {
    return (
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <Text variant="body1Medium" text={'People'} />
        <div>{isLiveOrCompleted ? null : renderHeaderButtons()}</div>
      </div>
    )
  }

  const handleOnSubmit = () => {
    if (selectedSurvey?.id) {
      const params: { group_ids: number[]; user_ids: number[] } = {
        group_ids: selectedAssignees?.groups.map((e) => e.group_id) || [],
        user_ids: selectedAssignees?.users.map((e) => e.user_id) || [],
      }
      updateSurveyAssignees(selectedSurvey.id, cast({ ...params })).then(() => {
        if (selectedSurvey && selectedSurvey.id) {
          getSurveyDetails(selectedSurvey.id)
        }
        setIsEditing(false)
      })
    }
  }
  return (
    <Paper
      variant="outlined"
      style={{
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
        overflow: 'hidden',
        borderRadius: 20,
        padding: 20,
      }}
    >
      {renderHeader()}
      <Divider
        style={{ marginTop: 20, marginBottom: 20, backgroundColor: color.trivieGrey, opacity: 0.2 }}
      />
      {isNotUnscheduled && !isEditing ? (
        <TextField
          variant="standard"
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <Search htmlColor={color.palette.grey} />
              </InputAdornment>
            ),
          }}
          id="standard-search"
          placeholder="Search"
          type="search"
          onChange={(e) => setFilter(e.target.value)}
        />
      ) : null}
      {isEditing ? (
        <Button
          variant="outlined"
          onClick={() => setOpenAssignDialog(true)}
          startIcon={<Add />}
          text="Add Users"
        />
      ) : null}
      <div style={{ maxHeight: 800, overflow: 'auto' }}>
        <div style={{ marginTop: 22 }}>
          {selectedSurvey?.groups?.length ? (
            <Text variant="body2Emphasized" text={'GROUPS'} style={{ color: color.shade50 }} />
          ) : null}
          {getFilteredGroups()?.map((group) => (
            <div style={{ display: 'flex', flexDirection: 'row', marginTop: 24 }}>
              <div style={{ flex: 1 }}>
                <GroupItem group={group} />
              </div>
              {isEditing ? (
                <IconButton onClick={() => handleDeleteGroup(group.group_id)}>
                  <Close htmlColor={color.shade50} style={{ fontSize: 16 }} />
                </IconButton>
              ) : null}
            </div>
          ))}
        </div>
        <div style={{ marginTop: 22 }}>
          {selectedSurvey?.users?.length ? (
            <Text variant="body2Emphasized" text={'ALL'} style={{ color: color.shade50 }} />
          ) : null}
          {getFilteredUsers()?.map((user) => (
            <div style={{ display: 'flex', flexDirection: 'row', marginTop: 24 }}>
              <div style={{ flex: 1 }}>
                <UserItem user={user} />
              </div>
              {isEditing ? (
                <IconButton onClick={() => handleDeleteUser(user.user_id)}>
                  <Close htmlColor={color.shade50} style={{ fontSize: 16 }} />
                </IconButton>
              ) : null}
            </div>
          ))}
        </div>
      </div>
      <Dialog
        confirmBtnLabel="Yes, Remove"
        cancelBtnLabel="No, Go Back"
        onClose={() => setShowRemoveUser(false)}
        onCancel={() => setShowRemoveUser(false)}
        onConfirm={() => {
          if (userId) {
            deleteUserOrGroup(userId, null)
          }
        }}
        title="Remove User"
        maxWidth="xs"
        open={showRemoveUser}
        dialogText="Are you sure you want remove this user?"
      />
      <Dialog
        confirmBtnLabel="Yes, Remove"
        cancelBtnLabel="No, Go Back"
        onCancel={() => setShowRemoveGroup(false)}
        onClose={() => setShowRemoveGroup(false)}
        onConfirm={() => {
          if (groupId) {
            deleteUserOrGroup(null, groupId)
          }
        }}
        title="Remove Group"
        maxWidth="xs"
        open={showRemoveGroup}
        dialogText="Are you sure you want remove this group?"
      />
      <AssignmentUsersDialog
        setSelectedAssignees={setSelectedAssignees}
        open={openAssignDialog}
        requestClose={() => setOpenAssignDialog(false)}
      />
    </Paper>
  )
})
