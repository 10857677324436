import React from 'react'
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import { Text } from '@trivie/ui-web/components/text'
import { color } from '@trivie/ui-web/theme'
import { observer } from 'mobx-react-lite'
import { Button, Divider } from '@mui/material'
import { Check } from '@mui/icons-material'
import { useStores } from '@trivie/core'

export interface PlanCardProps {
  title: string
  subtitle: string
  planColor?: string
  monthlyPrice?: number
  annualPrice?: number
  isPopular?: boolean
  includesItems?: { count: string; text: string }[]
  featureItems?: string[]
  onClick: () => void
  containerStyle?: React.CSSProperties
  annual?: boolean
  current?: boolean
}

export const PlanCard = observer((props: PlanCardProps) => {
  const {
    title,
    subtitle,
    monthlyPrice,
    annualPrice,
    onClick,
    containerStyle,
    planColor,
    includesItems,
    featureItems,
    annual,
    isPopular,
    current,
    ...rest
  } = props

  const { manageUserStore } = useStores()
  const { userProfile } = manageUserStore

  return (
    <Card
      style={containerStyle}
      sx={{
        ...CONTAINER,
        border: isPopular ? `3px solid ${planColor}` : `3px solid #ABB5D8`,
      }}
    >
      <CardContent sx={TITLE}>
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <Text
            style={{ ...TITLE_TXT, color: title === 'Free' ? '#000' : planColor, fontSize: 24 }}
            text={title}
            variant="labelEmphasized"
          />
          <Text style={SUB_TITLE_TXT} text={subtitle} variant="body1Medium" />
        </div>
        {typeof monthlyPrice !== 'undefined' ? (
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              flexDirection: 'column',
              gap: 5,
              marginTop: 24,
            }}
          >
            <div style={{ flexDirection: 'row', alignItems: 'center', display: 'flex' }}>
              {monthlyPrice > 0 && annual ? (
                <Text
                  style={{
                    ...PLAN_PRICE_TEXT,
                    marginRight: 16,
                    fontSize: 24,
                    textDecoration: 'line-through',
                    color: color.black,
                  }}
                  text={`$${annual ? monthlyPrice : annualPrice}`}
                  variant="titleEmphasized"
                />
              ) : null}

              <Text
                style={{ ...PLAN_PRICE_TEXT, color: color.black }}
                text={`$${annual ? annualPrice : monthlyPrice}`}
                variant="titleEmphasized"
              />
            </div>
            <Text
              variant="body2Medium"
              style={{ ...SUB_TITLE_TXT, fontSize: 12 }}
              text={title === 'Free' ? 'Unlimted' : 'Per Month'}
            />
          </div>
        ) : (
          <div style={{ flexDirection: 'column', display: 'flex', gap: 5 }}>
            <Text
              style={{ ...PLAN_PRICE_TEXT, marginTop: 24, color: color.black }}
              text={'Custom'}
              variant="titleEmphasized"
            />
            <Text
              variant="body2Medium"
              style={{ ...SUB_TITLE_TXT, fontSize: 12 }}
              text={'Per Month'}
            />
          </div>
        )}
        <Button
          style={{
            width: '100%',
            border: title === 'Free' ? `1px solid  ${color.shade20}` : 'none',
            backgroundColor: title === 'Free' ? '#fff' : planColor,
            textAlign: 'center',
            height: 47,
            padding: 12,
            marginTop: 24,
          }}
          sx={{
            borderRadius: '100px !important',
          }}
          disabled={current}
          onClick={(e) => {
            if (title === 'Pro' || title === 'Enterprise') {
              e.preventDefault()
              e.stopPropagation()
              const email = 'andyw@trivie.com'
              const subjectText = `New Trivie ${title} Waitlist Request`
              const bodyText = `${userProfile?.first_name} ${userProfile?.last_name} (${userProfile?.email}) has added themselves to the waitlist for ${title}.`
              window.open(`mailto:${email}?subject=${subjectText}&body=${bodyText}`)
            } else {
              onClick()
            }
          }}
        >
          <Text
            text={
              current
                ? 'Current Plan'
                : title === 'Enterprise'
                  ? 'Contact Us'
                  : manageUserStore.settings.plan_tier.toLowerCase() !== 'free' &&
                      manageUserStore.settings.plan_tier.toLowerCase() !== 'pro' &&
                      manageUserStore.settings.plan_tier.toLowerCase() !== 'standard'
                    ? 'Change Plan'
                    : title === 'Pro'
                      ? 'Contact Us'
                      : 'Get Started'
            }
            variant="titleEmphasized"
            style={{ color: title === 'Free' ? '#000' : color.white }}
          />
        </Button>
        <Divider style={{ marginTop: 24, marginBottom: 24 }} />

        <div style={{ display: 'flex', flexDirection: 'column', gap: 12, marginBottom: 24 }}>
          <Text style={INCLUDE_TEXT} text="Includes:" variant="labelEmphasized" />
          {includesItems?.map((item) => (
            <div style={{ display: 'flex', gap: 10 }}>
              <Text text={item.count} variant="labelEmphasized" />
              <Text style={{ color: color.shade70 }} text={item.text} variant="body1" />
            </div>
          ))}
        </div>
        {title !== 'Free' && (
          <Text
            style={{ fontWeight: 700 }}
            text={`Everything in ${
              title === 'Pro'
                ? 'Standard'
                : title === 'Standard'
                  ? 'Free'
                  : title === 'Enterprise'
                    ? 'Pro'
                    : ''
            } plus:`}
            variant="body1Medium"
          />
        )}
        <div style={{ display: 'flex', flexDirection: 'column', gap: 20, marginTop: 20 }}>
          {featureItems?.map((item) => (
            <div style={{ display: 'flex', gap: 10 }}>
              <Check htmlColor={color.shade70} style={{ fontSize: 16 }} />
              <Text
                style={{
                  color: color.shade70,
                }}
                text={item}
                variant="body1"
              />
            </div>
          ))}
        </div>
      </CardContent>
    </Card>
  )
})

const CONTAINER: React.CSSProperties = {
  width: 320,
  boxShadow: 'none',
  borderRadius: '12px',
  border: `1px solid ${color.shade10}`,
  minHeight: 600,
  position: 'relative',
  padding: '32px',
  paddingTop: '48px',
  paddingBottom: '48px',
}
const INCLUDE_TEXT: React.CSSProperties = { color: '#081024' }
const TITLE: React.CSSProperties = {
  paddingBottom: '0px !important',
  paddingTop: '0px !important',
  paddingLeft: 0,
  paddingRight: 0,
}
const TITLE_TXT: React.CSSProperties = {
  textAlign: 'left',
  color: '#4E4E4E',
  marginBottom: 4,
}

const PLAN_PRICE_TEXT: React.CSSProperties = {
  color: '#3254F4',
  fontSize: 48,
  fontWeight: '700',
  lineHeight: 'normal',
}

const SUB_TITLE_TXT: React.CSSProperties = {
  color: 'rgba(0, 0, 0, 0.50)',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
}
