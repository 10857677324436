import React from 'react'
// @ts-ignore
import { ReactComponent as DefaultIcon } from './default-icon.svg'
// @ts-ignore
import { ReactComponent as VideoIcon } from './video-icon.svg'
// @ts-ignore
import { ReactComponent as PdfIcon } from './pdf-icon.svg'

// @ts-ignore
import { ReactComponent as WhiteWebsite } from '../../assets/icons/White-Website.svg'
// @ts-ignore
import { ReactComponent as WhiteWord } from '../../assets/icons/White-Word.svg'
// @ts-ignore
import { ReactComponent as WhitePDF } from '../../assets/icons/White-PDF.svg'
// @ts-ignore
import { ReactComponent as WhiteText } from '../../assets/icons/White-Text.svg'
// @ts-ignore
import { ReactComponent as WhiteSlide } from '../../assets/icons/White-Slide.svg'
// @ts-ignore
import { ReactComponent as WhiteMedia } from '../../assets/icons/White-Media.svg'
// @ts-ignore
import { ReactComponent as WhiteSound } from '../../assets/icons/White-Sound.svg'
// @ts-ignore
import { ReactComponent as WhiteImage } from '../../assets/icons/White-Image.svg'
// @ts-ignore
import { ReactComponent as WhiteDefault } from '../../assets/icons/White-Default.svg'

import {
  mediaTypes,
  slideTypes,
  imageTypes,
  excelTypes,
  pdfTypes,
  wordTypes,
  soundTypes,
} from '@trivie/core'

const videoExtensions = ['mp4', 'mov', 'avi', 'flv', 'mkv', 'wmv', 'avchd', 'webm', 'h264', 'mp4']

export const ResourceIcon = (props: any) => {
  const { extension, style, white } = props
  const massagedExtension = String(extension).toLocaleLowerCase().replaceAll('.', '')

  if (white) {
    if (mediaTypes.includes(massagedExtension))
      return <WhiteMedia style={style} width={24} height={24} />
    else if (slideTypes.includes(massagedExtension))
      return <WhiteSlide style={style} width={24} height={24} />
    else if (imageTypes.includes(massagedExtension))
      return <WhiteImage style={style} width={24} height={24} />
    else if (excelTypes.includes(massagedExtension))
      return <WhiteDefault style={style} width={24} height={24} />
    else if (pdfTypes.includes(massagedExtension))
      return <WhitePDF style={style} width={24} height={24} />
    else if (wordTypes.includes(massagedExtension))
      return <WhiteWord style={style} width={24} height={24} />
    else if (soundTypes.includes(massagedExtension))
      return <WhiteSound style={style} width={24} height={24} />
    else if (massagedExtension === 'url')
      return <WhiteWebsite style={style} width={24} height={24} />
    else return <WhiteDefault style={style} width={24} height={24} />
  } else {
    if (videoExtensions.includes(massagedExtension)) {
      return <VideoIcon style={style} />
    } else if (massagedExtension === 'pdf') {
      return <PdfIcon style={style} />
    } else {
      return <DefaultIcon style={style} />
    }
  }
}
