import { cast, getParentOfType, types } from 'mobx-state-tree'
import { Quiz } from './Quiz'

export const Resource = types
  .model('Resource')
  .props({
    created_at: types.maybeNull(types.string),
    converted_pdf_url: types.maybe(types.string),
    quiz_count: types.maybe(types.maybeNull(types.number)),
    thumbnail_url: types.maybe(types.string),
    filename_or_url: types.string,
    title: types.string,
    resource_id: types.number,
    preview_url: types.maybe(types.string),
    is_required: types.maybe(types.boolean),
    mime_type: types.maybeNull(types.string),
    file_size: types.maybeNull(types.number),
    identityBreaker: types.identifierNumber,
    extension: types.maybeNull(types.string),
    processing_status: types.maybe(types.maybeNull(types.string)),
    editing: types.maybe(types.boolean),
  })
  .actions((self) => ({
    set<K extends keyof typeof self, T extends typeof self>(key: K, value: T[K]) {
      if (key === 'is_required' && value === true) {
        try {
          const parentQuiz = getParentOfType(self, Quiz)
          parentQuiz.resources?.forEach((r) => {
            if (r !== self) {
              if (r.is_required) {
                parentQuiz?.updateRequiredResources(r)
              }
              r.set('is_required', false)
            }
          })
          self[key] = cast(value)
        } catch {
          // do nothing
        }
      } else {
        self[key] = cast(value)
      }
    },
  }))
  .views((self) => ({
    get isYoutube() {
      const reg =
        /^((?:https?:)?\/\/)?((?:www|m)\.)?((?:youtube\.com|youtu.be))(\/(?:[\w\-]+\?v=|embed\/|v\/)?)([\w\-]+)(\S+)?$/
      if (self.extension === 'url' && reg.test(self.filename_or_url)) {
        return true
      }
      return false
    },
  }))
