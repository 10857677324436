import { types } from 'mobx-state-tree'

export const UserRecipient = types.model('UserRecipient').props({
  user_id: types.number,
  display_name: types.maybeNull(types.string),
  email: types.maybeNull(types.string),
  photo_url: types.maybeNull(types.string),
})

export const UserDetailRecipient = types.model('UserRecipient').props({
  user_id: types.number,
  display_name: types.maybeNull(types.string),
})

export const GroupRecipient = types.model('GroupRecipient').props({
  group_id: types.number,
  label: types.string,
  number_users: types.number,
})

export const GroupDetailRecipient = types.model('GroupRecipient').props({
  group_id: types.number,
  label: types.string,
  recipient_count: types.number,
})

export const MessageRecipients = types.model('MessageRecipients').props({
  groups: types.array(GroupRecipient),
  users: types.array(UserRecipient),
})
