import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'

const resources = {
  en: { translation: require('./locales/en') },
  // ar: { translation: require('./locales/ar') },
  // de: { translation: require('./locales/de') },
  es: { translation: require('./locales/es') },
  'fr-CA': { translation: require('./locales/fr-CA') },
  fr: { translation: require('./locales/fr') },
  it: { translation: require('./locales/it') },
  // ja: { translation: require('./locales/ja') },
  // ko: { translation: require('./locales/ko') },
  // 'pt-BR': { translation: require('./locales/pt-BR') },
  // ru: { translation: require('./locales/ru') },
  // 'zh-Hans': { translation: require('./locales/zh-Hans') },
  // 'zh-Hant': { translation: require('./locales/zh-Hant') },
}

i18n.use(initReactI18next).init({
  resources,
  lng: 'en',
  fallbackLng: 'en',
  keySeparator: '.',

  interpolation: {
    escapeValue: false,
  },
})

export const i1 = i18n
