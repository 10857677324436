import React, { useState } from 'react'
import { CloseRounded } from '@mui/icons-material'
import { Autocomplete, Chip, TextField } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'

import { color } from '../../theme'

interface EmailSelectProps {
  onChange: (value: any) => void
  selected: string[]
  maxHeight?: number
  style?: React.CSSProperties
}

export const EmailSelect = (props: EmailSelectProps) => {
  const { onChange, maxHeight, selected, style } = props

  const useStyles = makeStyles({
    paper: { height: 0 },
    input: {
      '&::placeholder': {
        color: color.palette.darkGrey,
        fontFamily: 'Inter',
        fontStyle: 'normal',
        fontWeight: 'normal',
        fontSize: 14,
      },
      '& .MuiInputBase-root': {
        height: maxHeight ?? 175,
        overflow: 'auto',
        border: '1px solid lightgray',
        alignContent: 'flex-start',
      },
      '& .MuiInputBase-root::focused': {
        borderBottomLeftRadius: 0,
        borderBottomRightRadius: 0,
      },
      '& .MuiInputBase-root > fieldset': {
        border: 'none',
      },
    },
  })

  const classes = useStyles()

  const renderTags = (value: string[], getTagProps) => {
    return value.map((option: string, index: number) => (
      <Chip
        sx={{
          ...CHIP_CONTAINER,
          border: `1px solid ${!option.includes('@') ? `${color.red} ` : '#EFF0F6'} !important`,
          color: !option.includes('@') ? `${color.red}` : '#212121',
        }}
        deleteIcon={
          <CloseRounded
            style={{ fontSize: 18, color: !option.includes('@') ? `${color.red}` : '#9098A3' }}
          />
        }
        label={option}
        {...getTagProps({ index })}
      />
    ))
  }

  const [ival, setIval] = useState('')

  const handleKeyDown = (e) => {
    switch (e.key) {
      case ',':
      case 'Enter':
      case ' ': {
        e.preventDefault()
        e.stopPropagation()
        if (e.target.value.length > 0 && !selected.includes(e.target.value)) {
          onChange([...selected, e.target.value])
          setIval('')
        }
        break
      }
      default:
    }
  }

  return (
    <Autocomplete
      classes={{ ...classes }}
      onBlur={(e: any) => {
        if (e.target.value.length > 0 && !selected.includes(e.target.value)) {
          onChange([...selected, e.target.value])
          setIval('')
        }
      }}
      style={style}
      multiple
      options={[]}
      clearOnBlur={true}
      blurOnSelect={true}
      value={selected}
      inputValue={ival}
      freeSolo
      disableClearable
      filterSelectedOptions
      renderTags={renderTags}
      onInputChange={(e: any) => setIval(e.target.value)}
      onChange={(event, newValue) => onChange(newValue)}
      renderInput={(params) => {
        params.inputProps.onKeyDown = handleKeyDown
        return (
          <TextField
            {...params}
            className={classes.input}
            placeholder={selected.length === 0 ? 'Enter email addresses' : ''}
          />
        )
      }}
    />
  )
}

const CHIP_CONTAINER: React.CSSProperties = {
  backgroundColor: '#EFF0F6',
  borderRadius: '6px !important',
  padding: 0,
}
