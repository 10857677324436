import React, { useEffect, useState } from 'react'
import { ContentCopyRounded, Delete, ListAltRounded } from '@mui/icons-material'
import { useStores } from '@trivie/core'
import { ConfirmDialog } from '@trivie/ui-web/components/confirm-dialog'
import { DataTable, TableHelper } from '@trivie/ui-web/components/data-table/data-table'
import { observer } from 'mobx-react-lite'
import { CircularProgress, Stack, TextField } from '@mui/material'
import { Toolbar } from '@trivie/ui-web/components/table-toolbar'
import { Text } from '@trivie/ui-web/components/text'
import { AssignmentCreateDialog } from '../screens/activities/assignments/create-assignment/assignment-create'

import { useHistory } from 'react-router-dom'
import { Dialog } from '@trivie/ui-web/components/dialogs/dialog'
import { color } from '@trivie/ui-web/theme'

interface PathTableProps {
  tableHelper: TableHelper
  openFilter?: boolean
}

export const PathTable = observer((props: PathTableProps) => {
  const { tableHelper, openFilter } = props

  const { manageUserStore, pathStore, contentCreationStore } = useStores()
  const { checkDeletePaths, getPath, path, deletePaths } = pathStore
  const { resetContentStore } = contentCreationStore
  const { gdpr, permissions } = manageUserStore

  const [selectedRows, setSelectedRows] = useState<any>([])
  const [loading, setLoading] = useState<boolean>(tableHelper.custom ? false : true)

  // Dialogs
  const [showConfirmDelete, setShowConfirmDelete] = useState(false)
  const [deleteCheck, setDeleteCheck] = useState<any>()
  const [showAssignWizard, setShowAssignWizard] = useState(false)
  const [showDuplicatePath, setShowDuplicatePath] = useState(false)

  const history = useHistory()

  const getActions = () => {
    switch (tableHelper.parent) {
      case 'users':
        return []
      default:
        return [
          {
            label: 'Assign',
            icon: ListAltRounded,
            onClick: () => setShowAssignWizard(true),
            disabled: !permissions?.permissions.some((p) => p.code === 'can_create_assignment'),
          },
          {
            label: 'Duplicate',
            icon: ContentCopyRounded,
            onClick: () => {
              resetContentStore()
              getPath(selectedRows[0], true).then(() => {
                setShowDuplicatePath(true)
              })
            },
            disabled:
              selectedRows?.length > 1 ||
              !permissions?.permissions.some((p) => p.code === 'can_create_path'),
          },
          {
            label: 'Delete',
            icon: Delete,
            onClick: () => {
              checkDeletePaths(selectedRows).then((data: any) => {
                setDeleteCheck(data)
                setShowConfirmDelete(true)
              })
            },
            disabled: !permissions?.permissions.some((p) => p.code === 'can_delete_path'),
          },
        ]
    }
  }

  const [tableActions, setTableActions] = useState<any>(getActions())

  useEffect(() => {
    if (permissions) {
      setTableActions(getActions())
    }
  }, [permissions?.permissions])

  useEffect(() => {
    setTableActions(getActions())
  }, [selectedRows])

  useEffect(() => {
    load()
  }, [])

  const load = () => {
    if (!tableHelper.custom) {
      setLoading(true)
      tableHelper.load().then(() => setLoading(false))
    }
  }

  const save = async (columns: any, filter: any) => {
    setLoading(true)
    tableHelper.save(columns, filter).then(() => setLoading(false))
  }

  return loading ? (
    <div style={LOADER}>
      <CircularProgress />
    </div>
  ) : (
    <>
      <DataTable
        gdpr={gdpr}
        allowNewReport
        openFilter={openFilter}
        columnVisibilityModel={tableHelper.columnVisibilityModel}
        hideAddColumn
        tableHelper={tableHelper}
        filterModel={tableHelper.filterModel}
        sortModel={tableHelper.sortModel}
        searchPlaceholder="Search Paths"
        rows={tableHelper.rows}
        columns={tableHelper.columns}
        onSaveLayout={tableHelper?.saveLayout}
        onSaveFilters={save}
        toolbar={<Toolbar selected={selectedRows} actions={tableActions} />}
        exportFilename={tableHelper.parent !== 'paths' ? `${tableHelper.parent}_paths` : 'paths'}
        rowSelectionModel={selectedRows}
        onRowSelectionModelChange={(param) => setSelectedRows(param)}
      />
      {showConfirmDelete && (
        <ConfirmDialog
          danger
          open={showConfirmDelete}
          title="Remove Paths"
          message={`${
            deleteCheck && typeof deleteCheck.message !== 'undefined'
              ? deleteCheck.message
              : `Are you sure you want to delete ${selectedRows.length} path${
                  selectedRows.length > 1 ? 's' : ''
                }?`
          }`}
          confirmedMessage={`${selectedRows.length} path${
            selectedRows.length > 1 ? 's have' : ' has'
          } been removed.`}
          confirmButton="Confirm"
          onConfirm={() => deletePaths(selectedRows)}
          onClose={() => {
            setSelectedRows([])
            load()
            setShowConfirmDelete(false)
          }}
          handleCancel={() => setShowConfirmDelete(false)}
        />
      )}
      {showDuplicatePath && (
        <Dialog
          maxWidth="sm"
          title="Duplicate Path"
          onClose={() => {
            pathStore?.setPath(undefined)
            setShowDuplicatePath(false)
          }}
          onConfirm={async () => {
            // if (path?.image_url) {
            //   const fileData = await createFileFromPath(
            //     path?.image_url,
            //     `path_cover_${crypto.randomUUID()}.jpg`,
            //   )
            //   path?.setImage(fileData)
            // }
            history.push('/content/creation/path')
          }}
          confirmBtnLabel="Create Path"
          open={showDuplicatePath}
          disableConfirm={path?.name?.length === 0}
        >
          <Stack useFlexGap gap={1}>
            <Text
              variant="body1"
              text="This will create a new path with the same steps as the existing path."
            />
            <Text variant="body1" text="Enter a name for your new learning path." />
            <TextField
              sx={{
                '& .MuiFormLabel-root': {
                  color: color.shade60,
                },
              }}
              variant="standard"
              defaultValue={path?.name}
              label="Name"
              fullWidth
              error={path?.name?.length === 0}
              helperText={path?.name?.length === 0 ? 'A path name is required' : ''}
              onChange={(e) => path?.setName(e.target.value)}
            />
          </Stack>
        </Dialog>
      )}
      {showAssignWizard && (
        <AssignmentCreateDialog
          open={showAssignWizard}
          initialPaths={selectedRows}
          requestClose={() => {
            resetContentStore()
            setShowAssignWizard(false)
          }}
        />
      )}
    </>
  )
})

const LOADER: React.CSSProperties = {
  display: 'flex',
  flex: 1,
  justifyContent: 'center',
  flexDirection: 'column',
  alignItems: 'center',
  height: window.innerHeight / 1.5,
}
