import React from 'react'
import { Link } from 'react-router-dom'
import { GridColumnHeaderParams } from '@mui/x-data-grid-pro'
import Tooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip'
import { styled } from '@mui/material/styles'

import { formatBytes } from '@trivie/core/src/utils'

import { Image } from '../image'
import { ImageCell } from '../table-cells/image-cell'
import { NameCell } from '../table-cells/name-cell'
import { BadgePreviewCell, BadgesAwardedCell } from '../table-cells/badge-cell'
import { MessageCell } from '../table-cells/message-cell'
import { Text } from '../text'
import { color } from '../../theme'

import defaultCover from './default-topic-image.png'

const DescriptionTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} arrow classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: '#162142',
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: '#162142',
    padding: 8,
    maxWidth: 250,
    top: '0px !important',
  },
}))

export const renderHeader = (params: GridColumnHeaderParams) => {
  const column = { ...params.colDef } as any

  return (
    <DescriptionTooltip
      title={
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <Text
            style={{ color: color.white }}
            text={column.header_name}
            align="center"
            variant="body2Emphasized"
          />
          <Text style={{ color: color.white }} text={column.description} variant="body2" />
        </div>
      }
      placement="top"
    >
      <div style={{ flexDirection: 'column', display: 'flex', overflow: 'hidden' }}>
        {column.is_custom ? (
          <>
            <Text noWrap variant="body2Emphasized" text={column.headerName.toUpperCase()} />
            <Text
              align="center"
              noWrap
              variant="body2Emphasized"
              text={`(${Object.values(column?.params)[0]})`}
            />
          </>
        ) : (
          <Text noWrap variant="body2Emphasized" text={column.headerName?.toUpperCase()} />
        )}
      </div>
    </DescriptionTooltip>
  )
}

export const getCustomCell = (field: any, dataType: any, filter?: string) => {
  if (field === 'assignment_max_attempts') {
    return (params) => (params.row[field] === 999999 ? 'N/A' : params.row[field])
  } else if (field === 'path_assignment_name') {
    return (params) => {
      return filter || params.row.path_assignment_status ? (
        <Link
          style={TRUNCATE}
          to={`/activities/assignments/${filter || params.row.path_assignment_status?.toLowerCase()}/${params.id}/overview`}
        >{`${params.formattedValue}`}</Link>
      ) : (
        <Link
          style={TRUNCATE}
          to={`/activities/assignments/live`}
        >{`${params.formattedValue}`}</Link>
      )
    }
  } else if (field === 'path_name') {
    return (params) => (
      <Link to={`/content/paths/${params.id}/overview`}>{params.formattedValue}</Link>
    )
  } else if (field === 'contest_title') {
    return (params) => (
      <Link to={`/activities/contests/${filter}/${params.id}`}>{params.formattedValue} </Link>
    )
  } else if (field === 'survey_title') {
    return (params) => (
      <Link to={`/activities/surveys/live/${params.id}/overview`}>{params.formattedValue}</Link>
    )
  } else if (field === 'assignment_title') {
    return (params) => {
      return filter || params.row.assignment_status ? (
        <Link
          style={TRUNCATE}
          to={`/activities/assignments/${filter || params.row.assignment_status?.toLowerCase()}/${params.id}/overview`}
        >{`${params.formattedValue}`}</Link>
      ) : (
        <Link
          style={TRUNCATE}
          to={`/activities/assignments/live`}
        >{`${params.formattedValue}`}</Link>
      )
    }
  } else if (field === 'resource_file_size') {
    return (params) => formatBytes(params.row[field], 1)
  } else if (field === 'quiz_title' || field === 'path_quiz_title') {
    return (params) => (
      <Link
        style={TRUNCATE}
        to={`/content/quizzes/${params.id}/overview`}
      >{`${params.formattedValue}`}</Link>
    )
  } else if (field === 'resource_title') {
    return (params) => (
      <Link
        style={TRUNCATE}
        to={`/content/sources/${params.id}/overview`}
      >{`${params.formattedValue}`}</Link>
    )
  } else if (field === 'topic_title' || field === 'path_topic_title') {
    return (params) => <ImageCell defaultImage={defaultCover} params={params} />
  } else if (field === 'result_user_first_name') {
    return (params) => {
      const {
        result_user_first_name,
        result_user_last_name,
        photo_url,
        result_user_id,
        result_user_email,
      } = params.row as any
      return (
        <NameCell
          url={`/people/users/${result_user_id}/overview`}
          params={{
            ...params,
            row: {
              photo_url,
              last_name: result_user_last_name,
              first_name: result_user_first_name,
              email: result_user_email,
            },
          }}
        />
      )
    }
  } else if (field === 'result_quiz_title') {
    return (params) => {
      const { result_quiz_title, result_quiz_id, photo_url } = params.row as any
      return (
        <div style={{ paddingLeft: 16, display: 'flex', alignItems: 'center' }}>
          <Link
            style={{ flexDirection: 'row', display: 'flex', alignItems: 'center' }}
            to={`/quizzes/quiz/${result_quiz_id}`}
          >
            {photo_url ? (
              <Image src={photo_url} style={{ marginRight: 20 }} />
            ) : (
              <Image src={defaultCover} style={{ marginRight: 20 }} />
            )}
            {result_quiz_title}
          </Link>
        </div>
      )
    }
  } else if (field === 'group_name') {
    return (params) => (
      <Link
        style={TRUNCATE}
        to={`/people/groups/${params.id}/overview`}
      >{`${params.formattedValue}`}</Link>
    )
  } else if (field === 'question_text' || field === 'path_question_text') {
    return (params) => {
      return (
        <Link
          style={TRUNCATE}
          to={`/content/questions/${params.id}`}
        >{`${params.formattedValue}`}</Link>
      )
    }
  } else if (field === 'tag_key') {
    return (params) => <Text variant="body1Emphasized" text={params.value} />
  } else if (field === 'tag_value') {
    return (params) => <Link to={`/people/tags/${params.id}/overview/`}>{params.value}</Link>
  } else if (field === 'badge_awarded_count') {
    return (params: any) => <BadgesAwardedCell params={params} />
  } else if (field === 'badge_title') {
    return (params: any) => <BadgePreviewCell params={params} />
  } else if (field === 'message_body') {
    return (params) => <MessageCell params={params} />
  }

  switch (dataType) {
    case 'identity':
      return (params: any) => (
        <NameCell url={`/people/users/${params.id}/overview`} params={params} />
      )
  }
}

const TRUNCATE: React.CSSProperties = {
  display: 'block',
  overflow: 'hidden',
  whiteSpace: 'nowrap',
  textOverflow: 'ellipsis',
}
