import React, { useState } from 'react'
import { observer } from 'mobx-react-lite'
import { useRouteMatch } from 'react-router-dom'

import { TableHelper } from '../../../../tables'
import { QuestionTable } from '../../../../tables/question-table'

const Questions = observer(() => {
  const match = useRouteMatch<any>('/content/paths/:id/questions')
  const pathID = Number(match?.params?.id)

  const [tableHelper] = useState(
    new TableHelper('list_path_questions', 'questions', 'paths', pathID),
  )

  return <QuestionTable tableHelper={tableHelper} />
})

export const PathQuestionsTab = (props: any) => {
  const { id } = props
  return {
    name: 'Questions',
    path: `/content/paths/${id}/questions`,
    component: () => <Questions />,
  }
}
