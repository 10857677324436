import React, { useEffect, useState } from 'react'
import { observer } from 'mobx-react-lite'
import { Divider, IconButton, TextField } from '@mui/material'
import { Delete, DragIndicator, Edit, NewReleases, NoteAdd, Warning } from '@mui/icons-material'
import { Instance, getNodeId } from 'mobx-state-tree'

import { useStores } from '@trivie/core'
import { QuestionHelper } from '@trivie/core/src/helpers/QuestionHelper'
import { Text } from '@trivie/ui-web/components/text'
import { color } from '@trivie/ui-web/theme'
import { Quiz } from '@trivie/core/src/models/Quiz'

import { useHistory } from 'react-router-dom'
import { QuizImagePicker } from './quiz-image-picker'
import { ConfirmDialog } from '@trivie/ui-web/components/confirm-dialog'
import { ResourceUploadDialog } from './resource-upload-dialog'
import { ResourceComponent } from '@trivie/ui-web/components/resource-component'
import { ActionTooltip } from '@trivie/ui-web/components/table-toolbar'
import { Panel } from '@trivie/ui-web/components/panel/panel'
import { ImageCropDialog } from '@trivie/ui-web/components/image-cropper/image-crop-dialog'

interface QuizItemProps {
  quiz: Instance<typeof Quiz>
  grabbing: any
  handleDrag: any
  setGrabbing: any
  handleDrop: any
  setHoveredID: any
  hoveredID: any
  existingQuizNames: any
}
export const QuizItem = observer((props: QuizItemProps) => {
  const { contentCreationStore, manageQuizStore } = useStores()
  const { selectedTopic } = contentCreationStore
  const { checkDeleteQuizzes } = manageQuizStore
  const {
    existingQuizNames,
    quiz,
    grabbing,
    handleDrag,
    handleDrop,
    setHoveredID,
    setGrabbing,
    hoveredID,
  } = props

  const [showResourceDialog, setShowResourceDialog] = useState(false)
  const [showConfirmDelete, setShowConfirmDelete] = useState(false)
  const [deleteMessage, setDeleteMessage] = useState('')
  const [showCropper, setShowCropper] = useState(false)
  const [file, setFile] = useState<any>()

  const history = useHistory()

  const handleDelete = () => {
    if (!quiz.new) {
      checkDeleteQuizzes([quiz.quiz_id!]).then((responseData) => {
        if (responseData?.message) {
          setDeleteMessage(responseData.message)
        }
      })
    }
    setShowConfirmDelete(true)
  }

  useEffect(() => {
    if (
      existingQuizNames &&
      existingQuizNames.includes(quiz?.title?.replace(/\s+/g, '')?.toLowerCase() as never) &&
      quiz?.title?.replace(/\s+/g, '')?.toLowerCase() !==
        quiz?.original_name?.replace(/\s+/g, '')?.toLowerCase()
    ) {
      quiz?.set('duplicate_name', true)
    } else {
      quiz?.set('duplicate_name', false)
    }
  }, [existingQuizNames?.length, quiz?.title])

  const handleImage = (f: File | null) => {
    if (!f) {
      quiz.setImage(f)
    } else {
      setFile(f)
      setShowCropper(true)
    }
  }

  return (
    <div
      draggable={grabbing}
      onDrag={() => handleDrag(quiz)}
      onDragEnd={() => setGrabbing(false)}
      onDrop={() => handleDrop(quiz)}
      key={getNodeId(quiz)}
      style={{ flexDirection: 'row', display: 'flex', alignItems: 'center' }}
    >
      <DragIndicator
        onMouseLeave={() => setHoveredID(null)}
        onMouseEnter={() => setHoveredID(getNodeId(quiz))}
        onMouseDown={() => setGrabbing(true)}
        onMouseUp={() => {
          setHoveredID(null)
          setGrabbing(false)
        }}
        style={{
          ...HANDLE,
          color: hoveredID === getNodeId(quiz) ? color.shade70 : color.shade10,
          cursor: grabbing ? 'grabbing' : 'grab',
        }}
      />
      <Panel fullWidth>
        <div style={CONTAINER}>
          <div style={CONTENT}>
            {selectedTopic?.keep_item_order && (
              <Text
                variant="labelEmphasized"
                style={{ marginRight: 20 }}
                text={`${
                  selectedTopic.quizzes?.indexOf(quiz) || selectedTopic.quizzes?.indexOf(quiz) === 0
                    ? selectedTopic.quizzes?.indexOf(quiz) + 1
                    : 1
                }.`}
              />
            )}
            {quiz.image ? (
              <div style={IMG}>
                <img onClick={() => quiz.setImage(null)} src={quiz.image} style={QUIZ_IMAGE} />
              </div>
            ) : (
              <ActionTooltip placement="top" title="Upload Header Image For Quiz (Optional)">
                <div style={{ marginRight: 20 }}>
                  <QuizImagePicker
                    file={quiz.imageFile}
                    imageSrc={quiz.image}
                    onChange={handleImage}
                  />
                </div>
              </ActionTooltip>
            )}
            <div
              style={{
                flexDirection: 'column',
                display: 'flex',
                flex: 1,
                paddingRight: 20,
              }}
            >
              <TextField
                value={quiz.title}
                style={{ marginBottom: 8 }}
                sx={{
                  '& .MuiInput-underline:after': {
                    borderBottomColor:
                      quiz?.duplicate_name ||
                      (quiz?.touched && (quiz?.title?.length === 0 || quiz?.title === null))
                        ? color.red
                        : color.black,
                  },
                  '& .MuiInput-underline:before': {
                    borderBottomColor:
                      quiz?.duplicate_name ||
                      (quiz?.touched && (quiz?.title?.length === 0 || quiz?.title === null))
                        ? color.red
                        : 'rgb(148,148,148)',
                  },
                }}
                // helperText={quiz?.duplicate_name ? 'This name is already in use' : ''}
                // error={quiz?.duplicate_name}
                inputProps={{ style: { fontSize: 16, fontWeight: 600 } }}
                placeholder="Untitled Quiz"
                variant="standard"
                onBlur={(e) => quiz?.set('touched', true)}
                onChange={(e) => {
                  quiz?.set('touched', true)
                  quiz.set('title', e.target.value)
                }}
              />
              {quiz?.duplicate_name || (quiz?.allErrors?.length > 0 && quiz?.touched) ? (
                <Text
                  style={{ color: color.red }}
                  variant="body2"
                  text={quiz?.duplicate_name ? 'This name is already in use.' : 'Quiz has errors.'}
                />
              ) : !quiz?.approved && quiz?.touched ? (
                <Text
                  style={{ color: color.shade50 }}
                  variant="body2"
                  text="Quiz Awaiting Review"
                />
              ) : (
                <Text
                  style={{ color: color.shade50 }}
                  variant="body2"
                  text={`${quiz.questions?.filter((q) => !q.removing)?.length} Question${
                    quiz.questions?.filter((q) => !q.removing)?.length === 1 ? '' : 's'
                  }`}
                />
              )}
            </div>
          </div>
          <div style={ACTIONS}>
            <ActionTooltip
              placement="top"
              title={quiz?.questions?.length ? 'Edit Quiz' : 'Create Questions'}
            >
              <IconButton
                sx={{ border: `1px solid ${color.shade10}` }}
                onClick={() => {
                  if (quiz.questions?.length === 0) {
                    quiz.addQuestions([QuestionHelper.createNewQuestion()])
                  }
                  // if (quiz.resources?.length && !quiz.mercuryResource) {
                  //   quiz.set('mercuryResource', { ...quiz.resources[0] })
                  //   quiz.set('showResourcePanel', true)
                  // }
                  selectedTopic?.selectQuiz(quiz)
                  if (quiz.questions) {
                    quiz.selectQuestion(quiz.questions[0])
                  }
                  quiz?.set('touched', true)
                  history.push(
                    quiz.new
                      ? `/content/creation/quiz/new/edit?topic=true`
                      : `/content/creation/quiz/${Number(quiz.quiz_id)}/edit?topic=true`,
                  )
                }}
              >
                {quiz?.allErrors?.length > 0 && quiz?.touched ? (
                  <NewReleases style={{ fontSize: 20, color: color.red }} />
                ) : !quiz?.approved && quiz?.touched ? (
                  <Warning style={{ fontSize: 20, color: color.yellow }} />
                ) : (
                  <Edit style={{ fontSize: 20, color: color.shade70 }} />
                )}
              </IconButton>
            </ActionTooltip>
            <ActionTooltip placement="top" title="Add Source">
              <IconButton
                onClick={() => setShowResourceDialog(true)}
                sx={{ border: `1px solid ${color.shade10}` }}
              >
                <NoteAdd style={{ fontSize: 20, color: color.shade70 }} />
              </IconButton>
            </ActionTooltip>
            <ActionTooltip placement="top" title="Remove Quiz">
              <IconButton onClick={handleDelete} sx={{ border: `1px solid ${color.shade10}` }}>
                <Delete style={{ fontSize: 20, color: color.shade70 }} />
              </IconButton>
            </ActionTooltip>
          </div>
        </div>

        <div
          style={{
            paddingLeft: selectedTopic?.keep_item_order ? 32 : 0,
            flexDirection: 'column',
            display: 'flex',
            gap: 12,
          }}
        >
          {quiz?.resources && quiz?.resources.length > 0 ? (
            <>
              <Divider style={{ marginTop: 20, marginBottom: 20 }} />
              <div
                style={{ flexDirection: 'row', display: 'flex', justifyContent: 'space-between' }}
              >
                <div style={{ display: 'flex', flexDirection: 'row', gap: 8 }}>
                  <Text text="Sources" variant="body1Emphasized" />
                  <Text text={`${quiz?.resources?.length}`} variant="body1Medium" />
                </div>
                <Text
                  style={{ color: color.shade50, paddingRight: 67 }}
                  text="Required"
                  variant="body2Medium"
                />
              </div>
              {quiz?.resources.map((resource) => {
                return (
                  <div
                    key={`${resource.resource_id}`}
                    style={{ display: 'flex', flexDirection: 'row' }}
                  >
                    <ResourceComponent
                      inline
                      edit={resource.editing}
                      onRequire={(require) => {
                        resource.set('is_required', require)
                        quiz?.updateRequiredResources(resource)
                      }}
                      resource={resource}
                      isSelected={false}
                      onClick={(r) => {}}
                      onRemove={() => quiz?.removeResource(resource)}
                    />
                  </div>
                )
              })}
            </>
          ) : null}
        </div>
      </Panel>

      {showConfirmDelete && (
        <ConfirmDialog
          danger
          open={showConfirmDelete}
          title="Remove Quiz"
          message={`Are you sure you want to remove this quiz? ${deleteMessage}`}
          confirmedMessage="1 quiz has been removed."
          confirmButton="Confirm"
          onConfirm={() => {
            setShowConfirmDelete(false)
            selectedTopic?.removeQuiz(quiz)
          }}
          onClose={() => {
            setShowConfirmDelete(false)
            setDeleteMessage('')
          }}
          handleCancel={() => {
            setShowConfirmDelete(false)
            setDeleteMessage('')
          }}
        />
      )}
      {showResourceDialog && (
        <ResourceUploadDialog
          quiz={quiz}
          close={() => setShowResourceDialog(false)}
          open={showResourceDialog}
        />
      )}
      {showCropper && (
        <ImageCropDialog
          file={file}
          open={showCropper}
          onConfirm={(file: File) => {
            quiz.setImage(file)
            setShowCropper(false)
          }}
          onClose={() => {
            quiz.setImage(null)
            setShowCropper(false)
          }}
        />
      )}
    </div>
  )
})

const CONTAINER: React.CSSProperties = {
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'center',
  flex: 1,
}
const CONTENT: React.CSSProperties = {
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  flex: 1,
}
const ACTIONS: React.CSSProperties = {
  flexDirection: 'row',
  alignItems: 'center',
  gap: 12,
  display: 'flex',
}
const QUIZ_IMAGE: React.CSSProperties = {
  cursor: 'pointer',
  borderRadius: 10,
  marginRight: 20,
  objectFit: 'cover',
  width: 120,
  height: 60,
}
const HANDLE: React.CSSProperties = {
  alignSelf: 'center',
  color: color.shade20,
  marginRight: 12,
}
const IMG: React.CSSProperties = {
  position: 'relative',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
}
