import React from 'react'
import { AddPhotoAlternate } from '@mui/icons-material'
import { color } from '@trivie/ui-web/theme'

export const QuizImagePicker = React.forwardRef((props: any, ref: any) => {
  const { style, onChange, onBrowseClick, file, accept } = props

  const handleFile = (files: FileList) => {
    onChange(files[0])
  }

  const hasFile = !!file

  const fileClickHandler = (e: any) => {
    const fileInputClicked = e?.target?.id === 'file-input'
    const browsePhotosClicked = e?.target?.id === 'browse-photos'

    if (!fileInputClicked) {
      if (browsePhotosClicked) {
        onBrowseClick()
      }
      e.preventDefault()
      e.stopPropagation()
    }
  }

  return (
    <label
      onClickCapture={fileClickHandler}
      style={{ ...BASE, ...style }}
      onDrop={(e) => handleFile(e.dataTransfer.files)}
      ref={ref}
    >
      <div style={CONTAINER}>
        <div style={ICON}>
          <AddPhotoAlternate style={{ color: color.shade50, fontSize: 24 }} />
        </div>
        <input
          id="file-input"
          type="file"
          style={{ ...ACCESSIBLE_HIDDEN_STYLE }}
          onChange={(e) => {
            handleFile(e.target.files || ([] as any))
          }}
          accept={accept}
        />
      </div>
    </label>
  )
})

const CONTAINER: React.CSSProperties = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-start',
  flexDirection: 'column',
  alignSelf: 'center',
  flex: 1,
  cursor: 'pointer',
  position: 'relative',
  width: '100%',
  height: '100%',
}
const ICON: React.CSSProperties = {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  width: '100%',
  height: '100%',
}
const BASE: React.CSSProperties = {
  display: 'flex',
  backgroundColor: '#F0F2F580',
  borderStyle: 'dashed',
  borderWidth: 1,
  borderColor: color.shade30,
  borderRadius: 10,
  height: 60,
  width: 120,
}

const BASE_FILE: React.CSSProperties = {
  display: 'flex',
  backgroundColor: 'rgba(0, 0, 0, 0.04)',
  borderWidth: 1,
  borderColor: color.shade30,
  borderRadius: 10,
  height: 70,
  width: '70%',
  paddingLeft: '23px',
  alignItems: 'center',
}

const ACCESSIBLE_HIDDEN_STYLE: React.CSSProperties = {
  position: 'absolute',
  top: 0,
  width: '100%',
  height: '100%',
  opacity: 0,
  cursor: 'pointer',
}
