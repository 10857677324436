import { ApiResponse, ApisauceInstance } from 'apisauce'
import { handleResponse } from '../handleResponse'

export const PostApi = {
  apisauce: {} as ApisauceInstance,
  togglePostPin: async (postId: number | string) => {
    const response: ApiResponse<object> = await PostApi.apisauce.post(`posts/${postId}/toggle_pin/`)

    return handleResponse(response)
  },
}
