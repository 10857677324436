import React, { useState } from 'react'
import { observer } from 'mobx-react-lite'

import { useStores } from '@trivie/core'
import { Dialog } from '../dialogs/dialog'
import { Text } from '../text'
import { GroupSelector } from '../group-selector/group-selector'
import { Box } from '@mui/material'

export interface AddGroupsProps {
  open: boolean
  groups: any
  onClose: () => void
}

export const AddGroups = observer((props: AddGroupsProps) => {
  const { open, groups, onClose } = props
  const { manageUserStore } = useStores()
  const { addUserToGroups } = manageUserStore
  const [selectedGroups, setSelectedGroups] = useState<any>([])

  const handleSubmit = () => {
    addUserToGroups(selectedGroups.map((g: any) => g.group_id))
    setSelectedGroups([])
    onClose()
  }

  return (
    <Dialog
      maxWidth="sm"
      onClose={onClose}
      onCancel={onClose}
      onConfirm={handleSubmit}
      disableConfirm={!groups.length}
      title="Add Groups"
      aria-labelledby="add groups dialog"
      open={open}
    >
      <Text variant="body1" text="Groups" color="text.secondary" mb={2} />
      <Box minHeight={300}>
        <GroupSelector onChange={(selected: any) => setSelectedGroups(selected)} />
      </Box>
    </Dialog>
  )
})
