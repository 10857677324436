import React from 'react'

import TableCell from '@mui/material/TableCell'
import { Text } from '../../text'
import { Link } from 'react-router-dom'
import { BoltRounded, People } from '@mui/icons-material'
import { color } from '../../../theme'

export const QuizzesShortCell = (props: any) => {
  const { row, deleteButton: DeleteButton, hideIcon = false } = props

  return (
    <TableCell style={{ borderBottom: 'none', padding: 0 }} align="left">
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
      >
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <Link
            style={{
              flexDirection: 'row',
              display: 'flex',
              textDecoration: 'none',
              alignItems: 'center',
              color: color.black,
            }}
            to={`/content/quizzes/${row.quiz_id}/overview`}
          >
            {!hideIcon && <BoltRounded htmlColor={color.shade70} style={{ marginRight: 8 }} />}
            <div style={WRAPPER_STYLE}>
              {row.imageUrl && <img src={row.imageUrl} style={IMAGE_STYLE} />}
              <div>
                <Text text={row.title || row.quiz_title} variant="body1Medium" />
                {row.question_count && (
                  <Text
                    text={`${row.question_count} question${
                      row.question_count === 0 || row.question_count > 1 ? 's' : 's'
                    }`}
                    variant="body1"
                    style={{ color: color.shade50 }}
                  />
                )}
              </div>
            </div>
          </Link>
        </div>
        {DeleteButton && <DeleteButton {...row} />}
      </div>
    </TableCell>
  )
}
const WRAPPER_STYLE: React.CSSProperties = {
  display: 'flex',
  alignItems: 'center',
  gap: 10,
}
const IMAGE_STYLE: React.CSSProperties = {
  borderRadius: 6,
  width: 72,
  height: 36,
}
