import { types, Instance } from 'mobx-state-tree'

export const QuizScoreboard = types.model('QuizScoreboard').props({
  user_photo_url: types.string,
  topic_title: types.maybeNull(types.string),
  topic_completion_pct: types.maybeNull(types.number),
  topic_quiz_count: types.maybeNull(types.number),
  topic_quizzes_completed: types.maybeNull(types.number),
  user_level: types.maybe(types.number),
  user_level_label: types.maybe(types.string),
  user_level_icon: types.maybe(types.string),
  total_points: types.number,
  points_to_next_level: types.maybeNull(types.number),
  did_level_up: types.maybe(types.boolean),
})

export const QuizScoreboardRecall = types.model('QuizScoreboardRecall').props({
  recall_pct: types.number,
  recall_change: types.maybeNull(types.number),
})

export interface QuizScoreboardType extends Instance<typeof QuizScoreboard.Type> {}
export interface QuizScoreboardRecallType extends Instance<typeof QuizScoreboardRecall.Type> {}
