export function calculateFileSize(bytes: number) {
  let calc = bytes

  if (calc < 10000) {
    return `${Math.round(calc)} B`
  }

  calc = calc / 1000

  if (calc < 10000) {
    return `${Math.round(calc)} KB`
  }

  calc = calc / 1000

  return `${Math.round(calc)} MB`
}
