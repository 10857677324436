import { types, getEnv, flow } from 'mobx-state-tree'
import { Environment } from '../environment'
import { DateTime } from 'luxon'

export const QuestionStoreModel = types
  .model('QuestionStore')
  .props({
    status: types.optional(types.enumeration(['idle', 'pending', 'done', 'error']), 'idle'),
    questions: types.frozen(),
    questionStats: types.frozen(),
    mastery: types.frozen(),
    knowledgeGain: types.frozen(),
    relatedQuizzes: types.frozen(),
    relatedTopics: types.frozen(),
  })
  .actions((self) => ({
    setStatus(value?: 'idle' | 'pending' | 'done' | 'error') {
      self.status = value || 'idle'
    },
    setQuestions(questions: any) {
      self.questions = questions
    },
    setQuestionStats(stats: any) {
      self.questionStats = stats
    },
    setMastery(mastery: any) {
      self.mastery = mastery as any
    },
    setKnowledgeGain(knowledgeGain: any) {
      self.knowledgeGain = knowledgeGain
    },
    setRelatedQuizzes(quizzes: any) {
      self.relatedQuizzes = quizzes
    },
    setRelatedTopics(topics: any) {
      self.relatedTopics = topics
    },
  }))
  .views((self) => ({
    get environment() {
      return getEnv(self) as Environment
    },
    get isLoading() {
      return self.status === 'pending'
    },
  }))
  .actions((self) => ({
    getQuestions: flow(function* () {
      self.setStatus('pending')
      try {
        const result = yield self.environment.api.getQuestions()
        if (result.kind === 'ok') {
          self.setQuestions(result.data)
          self.setStatus('done')
        } else {
          self.setStatus('error')
        }
      } catch {
        self.setStatus('error')
      }
    }),
    getQuestionDetails: flow(function* (id: any) {
      self.setStatus('pending')
      try {
        const result = yield self.environment.api.getQuestionDetails(id)
        if (result.kind === 'ok') {
          self.setQuestionStats(result.data)
          self.setStatus('done')
          return result.data
        } else {
          self.setStatus('error')
        }
      } catch {
        self.setStatus('error')
      }
    }),
    getKnowledgeGain: flow(function* (id: any) {
      self.setStatus('pending')
      self.setKnowledgeGain(undefined)
      try {
        const result = yield self.environment.api.getQuestionKnowledgeGain(
          id,
          DateTime.local(1971).startOf('day').toJSDate(),
          DateTime.local().endOf('day').toJSDate(),
        )
        if (result.kind === 'ok') {
          self.setKnowledgeGain(result.data)
          self.setStatus('done')
        } else {
          self.setStatus('error')
        }
      } catch {
        self.setStatus('error')
      }
    }),
    getMastery: flow(function* (id: any) {
      self.setStatus('pending')
      self.setMastery(undefined)
      try {
        const result = yield self.environment.api.getQuestionMastery(id)
        if (result.kind === 'ok') {
          self.setMastery(result.data)
          self.setStatus('done')
        } else {
          self.setStatus('error')
        }
      } catch {
        self.setStatus('error')
      }
    }),
    deleteQuestions: flow(function* (ids: any) {
      self.setStatus('pending')
      let result
      try {
        result = yield self.environment.api.deleteQuestions(ids)
        if (result.kind === 'ok') {
          self.setStatus('done')
        } else {
          self.setStatus('error')
        }
      } catch {
        self.setStatus('error')
      }
    }),
    getRelatedQuizzes: flow(function* (id: number) {
      self.setStatus('pending')
      try {
        const result = yield self.environment.api.getRelatedQuizzes(id)
        if (result.kind === 'ok') {
          self.setRelatedQuizzes(result.data)
          self.setStatus('done')
        } else {
          self.setStatus('error')
        }
      } catch {
        self.setStatus('error')
      }
    }),
    getRelatedTopicsForQuestion: flow(function* (id: number) {
      self.setStatus('pending')
      try {
        const result = yield self.environment.api.getRelatedTopicsForQuestion(id)
        if (result.kind === 'ok') {
          self.setRelatedTopics(result.data)
          self.setStatus('done')
        } else {
          self.setStatus('error')
        }
      } catch {
        self.setStatus('error')
      }
    }),
    createQuestion: flow(function* (question: any) {
      self.setStatus('pending')
      try {
        const result = yield self.environment.api.createQuestion(question)
        if (result.kind === 'ok') {
          self.setStatus('done')
          return result.data.question_id
        } else {
          self.setStatus('error')
        }
      } catch {
        self.setStatus('error')
      }
    }),
    setAltText: flow(function* (questionId: any, text: string) {
      self.setStatus('pending')
      try {
        const result = yield self.environment.api.setQuestionAltText(questionId, text)
        if (result.kind === 'ok') {
          self.setStatus('done')
        } else {
          self.setStatus('error')
        }
      } catch {
        self.setStatus('error')
      }
    }),
    changePhotoForQuestion: flow(function* (id: number, photo: any) {
      self.setStatus('pending')
      try {
        const result = yield self.environment.api.setImageForQuestion(id, photo)
        if (result.kind === 'ok') {
          self.setStatus('done')
        } else {
          self.setStatus('error')
        }
      } catch {
        self.setStatus('error')
      }
    }),
    removePhotoFromQuestion: flow(function* (id: number) {
      self.setStatus('pending')
      try {
        const result = yield self.environment.api.removePhotoFromQuestion(id)
        if (result.kind === 'ok') {
          self.setStatus('done')
        } else {
          self.setStatus('error')
        }
      } catch {
        self.setStatus('error')
      }
    }),
    updateQuestion: flow(function* (question: any) {
      self.setStatus('pending')
      try {
        const result = yield self.environment.api.updateQuestion(
          question.id ?? question.question_id,
          question,
        )
        if (result.kind === 'ok') {
          self.setStatus('done')
        } else {
          self.setStatus('error')
        }
      } catch {
        self.setStatus('error')
      }
    }),
  }))

type QuestionStoreType = typeof QuestionStoreModel.Type
export interface QuestionStore extends QuestionStoreType {}
type QuestionStoreSnapshotType = typeof QuestionStoreModel.SnapshotType
export interface QuestionStoreSnapshot extends QuestionStoreSnapshotType {}
