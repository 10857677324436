import { types } from 'mobx-state-tree'

export const UserGameStats = types.model('UserGameStats').props({
  knowledge_gain: types.maybeNull(types.number),
  leaderboard_rank: types.maybeNull(types.number),
  quiz_count: types.maybeNull(types.number),
  pct_correct: types.maybeNull(types.number),
  overall_recall: types.maybeNull(types.number),
  challenges_won: types.maybeNull(types.number),
})
