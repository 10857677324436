import { Instance, types, getEnv } from 'mobx-state-tree'
import { Environment } from '../../environment'
import { QuestionReadModel, QuestionReadModelType } from '../question'
import { QuizResourceReadModel } from '../resource/QuizResourceReadModel'

/**
 * QuizRead Model
 */
export const QuizReadModel = types
  .model('QuizRead')
  .props({
    __typename: types.optional(types.literal('QuizRead'), 'QuizRead'),
    status: types.optional(types.enumeration(['idle', 'pending', 'done', 'error']), 'idle'),
    description: types.string,
    id: types.identifier,
    is_locked: types.boolean,
    is_reinforcement: types.boolean,
    is_challenge: types.boolean,
    number_of_questions: types.number,
    title: types.string,
    questions: types.array(types.late(() => QuestionReadModel)),
    resources: types.array(types.late(() => QuizResourceReadModel)),
    activeIndex: types.optional(types.number, 0),
    selected: types.optional(types.boolean, false),
  })
  .actions((self) => ({
    afterCreate() {
      if (self.questions.length > 0) {
        const firstQuestion = self.questions[0]
        firstQuestion.setActive(true)
      }
    },
    setStatus(value?: 'idle' | 'pending' | 'done' | 'error') {
      self.status = value || 'idle'
    },
    next() {
      self.activeIndex += 1
      self.questions[self.activeIndex]?.setActive(true)
    },
    setActiveIndex(index: number) {
      self.activeIndex = index
    },
    select() {
      self.selected = true
    },
  }))
  .views((self) => ({
    get environment() {
      return getEnv(self) as Environment
    },
    get isLoading() {
      return self.status === 'pending'
    },
    get activeQuestion() {
      return self.questions.filter((question: QuestionReadModelType) => {
        return question.active
      })[0]
    },
  }))

/* The TypeScript type of an instance of QuizReadModel */
export interface QuizReadModelType extends Instance<typeof QuizReadModel.Type> {}

/**
 * QuizRead Model
 */
export const AssignedQuizModel = types.model('AssignedQuizModel').props({
  __typename: types.optional(types.literal('AssignedQuiz'), 'AssignedQuiz'),
  assignment_id: types.number,
  quiz: QuizReadModel,
})
/* The TypeScript type of an instance of QuizReadModel */
export interface AssignedQuizModelType extends Instance<typeof AssignedQuizModel.Type> {}
