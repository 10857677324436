import { ApiResponse, ApisauceInstance } from 'apisauce'
import { Instance } from 'mobx-state-tree'
import { ContentCreation } from '../../models/ContentCreation'
import { handleResponse } from '../handleResponse'

export const ContentCreationApi = {
  apisauce: {} as ApisauceInstance,
  publishContent: async (payload: any, isSafeToForcePublish?: boolean) => {
    const response: ApiResponse<Instance<typeof ContentCreation>> =
      await ContentCreationApi.apisauce.post(
        `/content_creation/?publish=${true}&safe=${!isSafeToForcePublish}`,
        payload,
      )

    return handleResponse(response)
  },
  validateContent: async (payload: any, hardValidate = false) => {
    const response: ApiResponse<Instance<typeof ContentCreation>> =
      await ContentCreationApi.apisauce.post(
        `/content_creation/?publish=${hardValidate}&safe=${false}`,
        payload,
      )

    return handleResponse(response)
  },
}
