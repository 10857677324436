import { types } from 'mobx-state-tree'

export const TagKey = types.model('TagKey').props({
  key: types.string,
  value: types.string,
})
export const InviteUserPayload = types.model('InviteUserPayload').props({
  first_name: types.string,
  last_name: types.string,
  email: types.maybe(types.string),
  phone_number: types.maybe(types.string),
  send_email: types.boolean,
  send_sms: types.boolean,
  send_email_datetime: types.string,
  employee_id: types.string,
  tag_ids: types.array(types.number),
  tag_keys_values: types.array(TagKey),
})
