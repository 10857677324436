import React, { useEffect, useState } from 'react'
import { OpenInNew } from '@mui/icons-material'
import { observer } from 'mobx-react-lite'
import { Box, Grid, Paper, Skeleton, Stack } from '@mui/material'
import { DateTime } from 'luxon'
import { useRouteMatch } from 'react-router-dom'

import {
  GetBackgroundColorOfMimeType,
  formatBytes,
  getStrongestWeakest,
  roundNumber,
  useStores,
} from '@trivie/core'
import { ResourceApi } from '@trivie/core/src/services/api-objects'
import { Button } from '@trivie/ui-web/components/button'
import { Insight } from '@trivie/ui-web/components/insight'
import { Medal } from '@trivie/ui-web/components/medal'
import { SimpleBarChart } from '@trivie/ui-web/components/simple-bar-chart'
import { StrongestWeakest } from '@trivie/ui-web/components/strongest-weakest'
import { Text } from '@trivie/ui-web/components/text'
import { ResourceIcon } from '@trivie/ui-web/components/resource-icon'

import { TableHelper } from '../../../../tables'
import { QuizResultsTable } from '../../../../tables/quiz-results-table'
import { Panel } from '@trivie/ui-web/components/panel/panel'

export const QuizOverview = observer(() => {
  const { manageQuizStore, manageUserStore } = useStores()
  const { freeTier, enterpriseTier, permissions } = manageUserStore
  const {
    getQuizOverview,
    quizStats,
    getKnowledgeGaps,
    medalStats,
    getMedalStats,
    knowledgeGaps,
    loadingQuiz,
    selectedQuiz,
  } = manageQuizStore

  const match = useRouteMatch<any>('/content/quizzes/:id/:tab')
  const quizID = Number(match?.params?.id)

  const [tableHelper] = useState(
    new TableHelper('list_quiz_results', 'quiz_results', 'quiz_results', quizID),
  )

  const [resources, setResources] = useState([])

  useEffect(() => {
    getCompletionScores()
    if (enterpriseTier) {
      getMedalStats(quizID)
      loadOverview(
        DateTime.local(2021).startOf('day').toJSDate().toISOString(),
        DateTime.local().endOf('day').toJSDate().toISOString(),
      )
    } else {
      getKnowledgeGaps(
        quizID,
        DateTime.local(2021).startOf('day').toJSDate().toISOString(),
        DateTime.local().endOf('day').toJSDate().toISOString(),
      )
      loadResources()
    }
  }, [])

  const loadResources = async () => {
    const resourceResponse = await ResourceApi.getAllResources(
      'quizzes',
      false,
      [
        { field: 'resource_title' },
        { field: 'resource_file_type' },
        { field: 'resource_file_size' },
        { field: 'resource_filename_url' },
      ],
      'list_quiz_resources',
      quizID,
    )

    setResources(resourceResponse.data.rows)
  }

  const loadOverview = (start: string, end: string) => {
    getQuizOverview(quizID, start, end)
    getKnowledgeGaps(quizID, start, end)
  }

  const renderKnowledgeGain = (knowledge: number) => {
    let kg = '0'
    if (knowledge < 0) {
      kg = `-${knowledge}`
    } else if (knowledge > 0) {
      kg = `+${knowledge}`
    }
    return kg
  }

  const [loadingResults, setLoadingResults] = useState(true)

  const [scores, setScores] = useState([
    { x: 1, y: 0 },
    { x: 2, y: 0 },
    { x: 3, y: 0 },
    { x: 4, y: 0 },
    { x: 5, y: 0 },
    { x: 6, y: 0 },
  ])

  const getCompletionScores = () => {
    tableHelper.load().then(() => {
      setLoadingResults(false)
      const s = [
        { x: 1, y: 0 },
        { x: 2, y: 0 },
        { x: 3, y: 0 },
        { x: 4, y: 0 },
        { x: 5, y: 0 },
        { x: 6, y: 0 },
      ]
      tableHelper.rows.map((row) => {
        if (row.result_score < 60) {
          s[0].y += 1
        } else if (row.result_score >= 60 && row.result_score < 70) {
          s[1].y += 1
        } else if (row.result_score >= 70 && row.result_score < 80) {
          s[2].y += 1
        } else if (row.result_score >= 80 && row.result_score < 90) {
          s[3].y += 1
        } else if (row.result_score >= 90 && row.result_score < 100) {
          s[4].y += 1
        } else if (row.result_score === 100) {
          s[5].y += 1
        }
      })
      setScores(s)
    })
  }

  const weakestQuestions = getStrongestWeakest(knowledgeGaps?.questions ?? []).strongest
  const strongestQuestions = getStrongestWeakest(knowledgeGaps?.questions ?? []).weakest

  return (
    <>
      <Text component="h2" mb="16px" text="Statistics" variant="labelEmphasized" />
      {loadingQuiz ? (
        <Skeleton height={119} style={{ borderRadius: 20 }} variant="rectangular" />
      ) : (
        selectedQuiz && (
          <Grid container spacing={2} style={{ marginBottom: 40 }}>
            <Grid item md={3} sm={6} xs={12}>
              <Panel fill>
                <Insight
                  titleVariant="titleEmphasized"
                  title="Times Completed"
                  tooltip="Number of times this quiz has been completed"
                  value={`${selectedQuiz?.attempts}`}
                />
              </Panel>
            </Grid>
            <Grid item md={3} sm={6} xs={12}>
              <Panel fill>
                <Insight
                  titleVariant="titleEmphasized"
                  title="Unique Respondents"
                  tooltip="Number of unique respondents"
                  value={`${selectedQuiz?.uniqueRespondents}`}
                />
              </Panel>
            </Grid>
            <Grid item md={3} sm={6} xs={12}>
              <Panel fill>
                <Insight
                  titleVariant="titleEmphasized"
                  title="Average Score"
                  tooltip="Average score for this quiz"
                  value={`${roundNumber(selectedQuiz.pctCorrect * 100)}%`}
                />
              </Panel>
            </Grid>
            <Grid item md={3} sm={6} xs={12}>
              <Panel fill>
                <Insight
                  titleVariant="titleEmphasized"
                  title="Average Completion Time"
                  tooltip="Average time users took to complete this quiz"
                  value={`${selectedQuiz?.averageTime} Seconds`}
                />
              </Panel>
            </Grid>
            {enterpriseTier && (
              <>
                <Grid item md={3} sm={6} xs={12}>
                  <Panel fill>
                    <Insight
                      titleVariant="titleEmphasized"
                      title="Baseline Knowledge"
                      tooltip="Baseline knowledge tooltip"
                      value={quizStats ? `${quizStats.starting_knowledge.toFixed(0) ?? 0}%` : '0%'}
                    />
                  </Panel>
                </Grid>
                <Grid item md={3} sm={6} xs={12}>
                  <Panel fill>
                    <Insight
                      titleVariant="titleEmphasized"
                      title="Current Knowledge"
                      tooltip="Current knowledge tooltip"
                      value={quizStats ? `${quizStats.current_knowledge.toFixed(0) ?? 0}%` : '0%'}
                    />
                  </Panel>
                </Grid>
                <Grid item md={3} sm={6} xs={12}>
                  <Panel fill>
                    <Insight
                      titleVariant="titleEmphasized"
                      title="Knowledge Gain"
                      tooltip="Knowledge Gain tooltip"
                      value={
                        quizStats
                          ? `${renderKnowledgeGain(Math.round(quizStats.knowledge_gain))}%`
                          : '0%'
                      }
                    />
                  </Panel>
                </Grid>
              </>
            )}
            {enterpriseTier && (
              <Grid item md={4} sm={12} xs={12}>
                <Panel fill title="Medals">
                  {medalStats
                    .slice()
                    .sort((a, b) => Number(a?.order) - Number(b?.order))
                    .map((stat) => {
                      return (
                        <Stack
                          direction="row"
                          alignItems="center"
                          useFlexGap
                          gap={2}
                          left="-6px"
                          position="relative"
                          key={stat.level}
                        >
                          <Medal hideRecall size={51} level={stat.level} />
                          <Stack>
                            {stat.percent === 100 && stat.level === null ? null : (
                              <Text
                                variant="headline"
                                text={`${Math.round(stat.percent).toFixed(0)}%`}
                              />
                            )}
                            <Text
                              variant="body2Medium"
                              style={{ color: '#9098A3' }}
                              text={
                                stat.level === null
                                  ? stat.percent === 100
                                    ? `No users have taken this quiz yet`
                                    : `${stat.user_count} user${
                                        stat.user_count > 1 || stat.user_count === 0 ? 's' : ''
                                      } ${
                                        stat.user_count === 1 ? 'has' : 'have'
                                      } not earned a medal`
                                  : `${stat.user_count} user${
                                      stat.user_count > 1 || stat.user_count === 0 ? 's' : ''
                                    } earned a ${stat.level} medal`
                              }
                            />
                          </Stack>
                        </Stack>
                      )
                    })}
                </Panel>
              </Grid>
            )}
            <Grid item xs={12} sm={enterpriseTier ? 12 : 6} md={enterpriseTier ? 8 : 6}>
              {loadingResults ? (
                <Skeleton
                  style={{ borderRadius: 20, marginTop: 20, marginBottom: 32, display: 'flex' }}
                  variant="rectangular"
                  height={360}
                />
              ) : (
                <Panel title="Score Distribution">
                  <Box height={360}>
                    <SimpleBarChart
                      data={scores}
                      loading={loadingResults}
                      tickValues={['<60%', '>60%', '>70%', '>80%', '>90%', '100%']}
                    />
                  </Box>
                </Panel>
              )}
            </Grid>
            {!enterpriseTier && weakestQuestions?.length ? (
              <Grid item xs={12} sm={6} md={6}>
                <StrongestWeakest
                  type="Weakest"
                  variant="small"
                  freeTier={freeTier}
                  style={{ height: '100%' }}
                  questions={weakestQuestions.slice(0, 2)}
                />
              </Grid>
            ) : null}
            <>
              {enterpriseTier && weakestQuestions?.length ? (
                <Grid item xs={12} sm={12} md={6}>
                  <StrongestWeakest
                    style={{ height: '100%' }}
                    type="Weakest"
                    variant="small"
                    questions={weakestQuestions}
                  />
                </Grid>
              ) : null}
              {enterpriseTier && strongestQuestions?.length ? (
                <Grid item xs={12} sm={12} md={6}>
                  <StrongestWeakest
                    style={{ height: '100%' }}
                    type="Strongest"
                    variant="small"
                    questions={strongestQuestions}
                  />
                </Grid>
              ) : null}
            </>
            {resources?.length > 0 && !enterpriseTier && (
              <Grid item xs={12} sm={12} md={6}>
                <div style={{ ...PANEL, height: 213, flexDirection: 'column' }}>
                  <Text text="Sources" variant="titleEmphasized" style={{ marginBottom: 24 }} />
                  <div style={{ display: 'flex', flexDirection: 'column', overflowY: 'auto' }}>
                    {resources.map((resource: any) => {
                      const title = resource.columns.find(
                        (column) => column.field === 'resource_title',
                      ).value
                      const fileType = resource.columns.find(
                        (column) => column.field === 'resource_file_type',
                      ).value
                      const url = resource.columns.find(
                        (column) => column.field === 'original_file_url',
                      ).value
                      const size = resource.columns.find(
                        (column) => column.field === 'resource_file_size',
                      ).value
                      return (
                        <div style={RESOURCE}>
                          <div style={{ display: 'flex', alignItems: 'center' }}>
                            <div
                              style={{
                                display: 'flex',
                                alignItems: 'center',
                                flexDirection: 'row',
                                backgroundColor: GetBackgroundColorOfMimeType(fileType),
                                padding: 10,
                                margin: 5,
                                borderRadius: 10,
                              }}
                            >
                              <ResourceIcon extension={fileType} white={true} />
                            </div>
                            <div
                              style={{ marginLeft: 12, flexDirection: 'column', display: 'flex' }}
                            >
                              <Text
                                text={title}
                                style={{ marginBottom: 4 }}
                                variant="body1Emphasized"
                              />
                              {fileType !== 'url' && (
                                <Text
                                  text={formatBytes(size)}
                                  variant="body2"
                                  style={{ color: '#9098A3' }}
                                />
                              )}
                            </div>
                          </div>
                          <Button
                            color="white"
                            text="Preview"
                            startIcon={<OpenInNew />}
                            onClick={() => window.open(url, '_blank', 'noopener')}
                          />
                        </div>
                      )
                    })}
                  </div>
                </div>
              </Grid>
            )}
            <Grid item xs={12}>
              <Text component="h2" mb="16px" text="Results" variant="labelEmphasized" />
              <QuizResultsTable
                restricted={freeTier}
                forceLoading={loadingResults}
                tableHelper={tableHelper}
              />
            </Grid>
          </Grid>
        )
      )}
    </>
  )
})

const PANEL: React.CSSProperties = {
  minHeight: 119,
  padding: 20,
  borderRadius: 20,
  background: '#fff',
  border: `1px solid #EFF0F6`,
  display: 'flex',
}
const RESOURCE: React.CSSProperties = {
  display: 'flex',
  justifyContent: 'space-between',
  flexDirection: 'row',
  alignItems: 'center',
  marginBottom: 12,
}

export const QuizzesOverviewTab = (props: any) => {
  const { id } = props

  return {
    name: 'Overview',
    path: `/content/quizzes/${id}/overview`,
    component: () => <QuizOverview />,
  }
}
