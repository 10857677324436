import React, { useEffect, useState } from 'react'
import { Stack, TextField } from '@mui/material'
import { observer } from 'mobx-react-lite'

import { useStores } from '@trivie/core'
import { ImageSelector } from '@trivie/ui-web/components/image-selector'
import { Text } from '@trivie/ui-web/components/text'
import { color } from '@trivie/ui-web/theme'
import { Switch } from '@trivie/ui-web/components/switch/switch'
import { Panel } from '@trivie/ui-web/components/panel/panel'
import UnsplashImageDialog from '../unsplash-image-dialog'
import { ImageCropDialog } from '@trivie/ui-web/components/image-cropper/image-crop-dialog'
import { TopicApi } from '@trivie/core/src/services/api-objects'

export const TopicSettings = observer((props: any) => {
  const { contentCreationStore } = useStores()
  const { selectedTopic } = contentCreationStore

  const [showUnsplashDialog, setShowUnsplashDialog] = useState(false)
  const [showCropper, setShowCropper] = useState(false)
  const [file, setFile] = useState<any>()
  const [topicNames, setTopicNames] = useState([])

  if (!selectedTopic) {
    return null
  }

  useEffect(() => {
    TopicApi.getAllTopics('topics', false, [{ field: 'topic_title' }], 'list_topics').then(
      (res: any) =>
        setTopicNames(
          res.data.rows.map((row) => {
            return row.columns
              .find((c) => c.field === 'topic_title')
              ?.value?.replace(/\s+/g, '')
              ?.toLowerCase()
          }),
        ),
    )
  }, [])

  useEffect(() => {
    if (
      (selectedTopic?.topic_id &&
        topicNames.includes(selectedTopic?.title?.replace(/\s+/g, '')?.toLowerCase() as never) &&
        selectedTopic?.title?.replace(/\s+/g, '')?.toLowerCase() !==
          selectedTopic?.original_name?.replace(/\s+/g, '')?.toLowerCase()) ||
      (!selectedTopic?.topic_id &&
        topicNames.includes(selectedTopic?.title?.replace(/\s+/g, '')?.toLowerCase() as never))
    ) {
      selectedTopic?.set('duplicate_name', true)
    } else {
      selectedTopic?.set('duplicate_name', false)
    }
  }, [topicNames?.length, selectedTopic?.title])

  const handleImage = (f: File | null) => {
    if (!f) {
      selectedTopic.setImage(f)
    } else {
      setFile(f)
      setShowCropper(true)
    }
  }

  return (
    <Panel>
      <ImageSelector
        fullWidth
        pickerStyle={{ height: 200 }}
        onUnsplashClick={() => setShowUnsplashDialog(true)}
        imageSrc={selectedTopic.image}
        file={selectedTopic.imageFile}
        onChange={handleImage}
      />
      <Stack useFlexGap gap={4}>
        <TextField
          variant="standard"
          sx={{
            '& .MuiInputBase-root': {
              fontSize: 28,
              lineHeight: '34px',
              fontWeight: 600,
              paddingTop: '12px',
            },
          }}
          multiline
          size="medium"
          placeholder="Topic Name"
          value={selectedTopic.title}
          helperText={selectedTopic?.duplicate_name ? 'This name is already in use.' : ''}
          error={selectedTopic?.duplicate_name}
          onChange={(e) => {
            selectedTopic.set('title', e.target.value)
          }}
        />
        <Stack direction="row" justifyContent="space-between" alignItems="center">
          <Stack>
            <Text variant="body1Medium" text="Enforce Quiz Order" />
            <Text
              variant="body2"
              style={{ color: color.palette.grey }}
              text="Learners must complete quizzes in this order."
            />
          </Stack>
          <Switch
            color="success"
            checked={selectedTopic.keep_item_order}
            onChange={(e) => selectedTopic.set('keep_item_order', e.target.checked)}
            size="small"
          />
        </Stack>
      </Stack>
      {showUnsplashDialog && (
        <UnsplashImageDialog
          onChangeImage={handleImage}
          handleImageDialogClose={() => setShowUnsplashDialog(false)}
          open={showUnsplashDialog}
        />
      )}
      {showCropper && (
        <ImageCropDialog
          file={file}
          open={showCropper}
          onConfirm={(file: File) => {
            selectedTopic.setImage(file)
            setShowCropper(false)
          }}
          onClose={() => {
            selectedTopic.setImage(null)
            setShowCropper(false)
          }}
        />
      )}
    </Panel>
  )
})
