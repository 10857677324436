import { types, getEnv, flow, getRoot } from 'mobx-state-tree'
import { LeaderboardApi } from '../../services/api-objects/LeaderboardApi'
import { Environment } from '../environment'
import { Awaited } from '../../utility-types/Awaited'
import { LeaderboardTagItem } from '../LeaderboardTagItem'

import { FlowCacheModel, useFlowCacheMiddleware } from '../../middleware'

const ACTIONS_TO_CACHE = {
  getLeaderboards: { wait: 30 },
  getLeaderboardRank: { wait: 30 },
}

export const LeaderboardStoreModel = types
  .model('LeaderboardStore')
  .props({
    status: types.optional(
      types.enumeration([
        'idle',
        'pending',
        'pending-daily',
        'pending-weekly',
        'pending-monthly',
        'pending-alltime',
        'done',
        'error',
      ]),
      'idle',
    ),
    loadingAllTime: types.frozen(),
    loadingDaily: types.frozen(),
    loadingWeekly: types.frozen(),
    loadingMonthly: types.frozen(),
    userLeaderboard: types.frozen(),
    allTimeUserLeaderboard: types.frozen(),
    dailyUserLeaderboard: types.frozen(),
    weeklyUserLeaderboard: types.frozen(),
    monthlyUserLeaderboard: types.frozen(),
    groupLeaderboard: types.frozen(),
    tagLeaderboard: types.array(LeaderboardTagItem),
    dailyRank: types.frozen(),
    weeklyRank: types.frozen(),
    monthlyRank: types.frozen(),
    alltimeRank: types.frozen(),
    tags: types.array(types.string),
    flowCache: types.map(types.map(FlowCacheModel)),
  })
  .actions((self) => ({
    afterCreate() {
      useFlowCacheMiddleware({ store: self, cacheable: ACTIONS_TO_CACHE })
    },
    setStatus(
      value?:
        | 'idle'
        | 'pending'
        | 'done'
        | 'error'
        | 'pending-daily'
        | 'pending-weekly'
        | 'pending-monthly'
        | 'pending-alltime',
    ) {
      self.status = value || 'idle'
    },
    setLoadingAllTime(loading: any) {
      self.loadingAllTime = loading
    },
    setLoadingWeekly(loading: any) {
      self.loadingWeekly = loading
    },
    setLoadingMonthly(loading: any) {
      self.loadingMonthly = loading
    },
    setLoadingDaily(loading: any) {
      self.loadingDaily = loading
    },
    setTags(tag_keys: typeof self.tags) {
      self.tags = tag_keys
    },
    setTagLeaderboard(data: typeof self.tagLeaderboard) {
      self.tagLeaderboard = [] as any
      self.tagLeaderboard = data
    },
    setLeaderboards(data: any) {
      self.userLeaderboard = data.users as any
      self.groupLeaderboard = data.groups as any
    },
    setAllTimeLeaderboard(data: any) {
      self.allTimeUserLeaderboard = data.users as any
    },
    setDailyLeaderboard(data: any) {
      self.dailyUserLeaderboard = data.users as any
    },
    setWeeklyLeaderboard(data: any) {
      self.weeklyUserLeaderboard = data.users as any
    },
    setMonthlyLeaderboard(data: any) {
      self.monthlyUserLeaderboard = data.users as any
    },
    setUserLeaderboardRank(data: any, filter) {
      if (filter === 'all_time') {
        self.alltimeRank = data
      } else if (filter === 'daily') {
        self.dailyRank = data
      } else if (filter === 'weekly') {
        self.weeklyRank = data
      } else if (filter === 'monthly') {
        self.monthlyRank = data
      }
    },
  }))
  .views((self) => ({
    get environment() {
      return getEnv(self) as Environment
    },
    get isLoading() {
      return self.status === 'pending'
    },
    get isLoadingDaily() {
      return self.status === 'pending-daily'
    },
    get isLoadingWeekly() {
      return self.status === 'pending-weekly'
    },
    get isLoadingMonthly() {
      return self.status === 'pending-monthly'
    },
    get isLoadingAlltime() {
      return self.status === 'pending-alltime'
    },
    get rootStore(): any {
      return getRoot(self) as any
    },
  }))
  .actions((self) => ({
    getLeaderboards: flow(function* (filter: string) {
      if (filter === 'all_time') {
        self.setLoadingAllTime(true)
      } else if (filter === 'daily') {
        self.setLoadingDaily(true)
      } else if (filter === 'weekly') {
        self.setLoadingWeekly(true)
      } else if (filter === 'monthly') {
        self.setLoadingMonthly(true)
      }
      try {
        const result: Awaited<ReturnType<typeof LeaderboardApi.getLeaderboards>> =
          yield LeaderboardApi.getLeaderboards(filter)
        if (result.ok) {
          if (filter === 'all_time') {
            self.setAllTimeLeaderboard(result.data)
            self.setLoadingAllTime(false)
          } else if (filter === 'daily') {
            self.setDailyLeaderboard(result.data)
            self.setLoadingDaily(false)
          } else if (filter === 'weekly') {
            self.setWeeklyLeaderboard(result.data)
            self.setLoadingWeekly(false)
          } else if (filter === 'monthly') {
            self.setMonthlyLeaderboard(result.data)
            self.setLoadingMonthly(false)
          }
          self.setLeaderboards(result.data)
          self.setStatus('done')
          return result.data
        } else {
          self.setStatus('error')
        }
      } catch {
        self.setStatus('error')
      }
    }),
    getLeaderboardRank: flow(function* (user_id: number, filter: string) {
      self.setStatus('pending')
      try {
        const result: Awaited<ReturnType<typeof LeaderboardApi.getLeaderboardRank>> =
          yield LeaderboardApi.getLeaderboardRank(user_id, filter)
        if (result.ok) {
          self.setUserLeaderboardRank(result.data, filter)
          self.setStatus('done')
        } else {
          self.setStatus('error')
        }
      } catch {
        self.setStatus('error')
      }
    }),
    getTagLeaderboards: flow(function* (user_id: number) {
      self.setStatus('pending')
      try {
        const result: Awaited<ReturnType<typeof LeaderboardApi.getTags>> =
          yield LeaderboardApi.getTags(user_id)
        if (result.ok) {
          self.setTags(result.data!.tag_keys)
          self.setStatus('done')
          return result.data!.tag_keys
        } else {
          self.setStatus('error')
        }
      } catch {
        self.setStatus('error')
      }
    }),
    getLeaderboardByTag: flow(function* (tag_category: string, filter: string) {
      self.setStatus('pending')
      try {
        if (filter === 'all_time') {
          self.setLoadingAllTime(true)
        } else if (filter === 'daily') {
          self.setLoadingDaily(true)
        } else if (filter === 'weekly') {
          self.setLoadingWeekly(true)
        } else if (filter === 'monthly') {
          self.setLoadingMonthly(true)
        }
        const result: Awaited<ReturnType<typeof LeaderboardApi.getLeaderboardByTag>> =
          yield LeaderboardApi.getLeaderboardByTag(tag_category, filter)
        if (result.ok && result.data) {
          self.setTagLeaderboard(result.data.tags)
          self.setStatus('done')
          if (filter === 'all_time') {
            self.setLoadingAllTime(false)
          } else if (filter === 'daily') {
            self.setLoadingDaily(false)
          } else if (filter === 'weekly') {
            self.setLoadingWeekly(false)
          } else if (filter === 'monthly') {
            self.setLoadingMonthly(false)
          }
          return result.data
        } else {
          self.setStatus('error')
        }
      } catch {
        self.setStatus('error')
      }
    }),
  }))

type LeaderboardStoreType = typeof LeaderboardStoreModel.Type
export interface LeaderboardStore extends LeaderboardStoreType {}
type LeaderboardStoreSnapshotType = typeof LeaderboardStoreModel.SnapshotType
export interface LeaderboardStoreSnapshot extends LeaderboardStoreSnapshotType {}
