import { cast, Instance } from 'mobx-state-tree'
import { QuestionChoice } from '../models/QuestionChoice'
import { QuestionType } from '../models/QuestionType'

export const getChoicesByQuestionType = (type: Instance<typeof QuestionType>) => {
  let choices: Array<Instance<typeof QuestionChoice>> = []

  if (type === 'Multiple Choice' || type === 'Multiple Response') {
    choices = [
      QuestionChoice.create({
        text: null,
        correct: true,
      }),
      QuestionChoice.create({
        text: null,
        correct: false,
      }),
      QuestionChoice.create({
        text: null,
        correct: false,
      }),
    ]
  } else if (type === 'True or False') {
    choices = [
      QuestionChoice.create({
        text: 'True',
        correct: true,
      }),
      QuestionChoice.create({
        text: 'False',
        correct: false,
      }),
    ]
  }

  return choices
}
