import { types, getEnv, cast, flow, getRoot } from 'mobx-state-tree'
import { activityTransformer, transformHourlyActivity, getStrongestWeakest } from '../../utils'
import { Environment } from '../environment'
import { ReportsApi, TableApi } from '../../services/api-objects'
import { AsyncTaskModel } from './task-store'

export const CustomReportModel = types
  .model('CustomReport')
  .props({
    base_view_id: types.number,
    base_view_name: types.string,
    columns: types.frozen(),
    created_by_name: types.string,
    created_datetime: types.string,
    id: types.number,
    last_edited_by_name: types.maybeNull(types.string),
    last_edited_datetime: types.maybeNull(types.string),
    task: types.maybeNull(AsyncTaskModel),
    view_description: types.maybeNull(types.string),
    view_name: types.string,
  })
  .actions((self) => ({
    setTask(task: typeof self.task) {
      if (task) {
        self.task = { ...task }
      }
    },
  }))

// TODO need to clear stats on screen exits
export const ReportStoreModel = types
  .model('ReportStore')
  .props({
    status: types.optional(types.enumeration(['idle', 'pending', 'done', 'error']), 'idle'),
    activityLog: types.frozen(),
    exportableReports: types.frozen(),
    baseStats: types.frozen(),
    hourlyActivity: types.frozen(),
    knowledgeBaseStats: types.frozen(),
    knowledgeGapStats: types.frozen(),
    knowledgeMastery: types.frozen(),
    avgSessionTime: types.frozen(),
    questionGaps: types.frozen(),
    topicGaps: types.frozen(),
    customReports: types.array(CustomReportModel),
    customReport: types.maybe(CustomReportModel),
    baseViews: types.frozen(),
    openFilter: types.frozen(),
    showCreateReport: types.maybe(types.boolean),
  })
  .actions((self) => ({
    setStatus(value?: 'idle' | 'pending' | 'done' | 'error') {
      self.status = value || 'idle'
    },
    setShowCreateReport(status: boolean) {
      self.showCreateReport = status
    },
    setOpenFilter(status?: any) {
      self.openFilter = status
    },
    setCustomReports(reports: any) {
      self.customReports = cast(reports)
    },
    setBaseViews(reports: any) {
      self.baseViews = reports.data
    },
    setActivityLog(activityLog: any) {
      self.activityLog = activityLog
    },
    setExportableReports(reports: any) {
      self.exportableReports = reports
    },
    setBaseStats(stats: any) {
      self.baseStats = stats
    },
    setHourlyActivity(activity: any) {
      self.hourlyActivity = activity
    },
    setKnowledgeBaseStats(stats: any) {
      self.knowledgeBaseStats = stats
    },
    setKnowledgeGapStats(stats: any) {
      self.knowledgeGapStats = stats
    },
    setKnowledgeMastery(mastery: any) {
      self.knowledgeMastery = mastery
    },
    setAvgSessionTime(avgSessionTime: any) {
      self.avgSessionTime = avgSessionTime
    },
    setTopicGaps(topicGaps: any) {
      self.topicGaps = topicGaps
    },
    setQuestionGaps(questionGaps: any) {
      self.questionGaps = questionGaps
    },
    setCustomReport(report: typeof self.customReport) {
      self.customReport = report
    },
  }))
  .views((self) => ({
    get environment() {
      return getEnv(self) as Environment
    },
    get isLoading() {
      return self.status === 'pending'
    },
    get rootStore(): any {
      return getRoot(self) as any
    },
  }))
  .actions((self) => ({
    getCustomReports: flow(function* () {
      self.setStatus('pending')
      try {
        const result: Awaited<ReturnType<typeof ReportsApi.getAllCustomReports>> =
          yield ReportsApi.getAllCustomReports()
        if (result.ok && result.data) {
          const testReports = result.data.data.map((report) => {
            return {
              ...report,
              task: {
                ...report.task,
                status: 'started',
              },
            }
          })

          self.setCustomReports(result.data.data)
          // self.setCustomReports(testReports)
          self.setStatus('done')
          return result.data
        } else {
          self.setStatus('error')
        }
      } catch {
        self.setStatus('error')
      }
    }),
    getCustomReport: flow(function* (id: number) {
      self.setStatus('pending')
      try {
        const result: Awaited<ReturnType<typeof TableApi.getCustomReport>> =
          yield TableApi.getCustomReport(id)
        if (result.ok && result.data) {
          self.setCustomReport(result.data)
          self.setStatus('done')
          return result.data
        } else {
          self.setStatus('error')
          return result.data
        }
      } catch (e) {
        self.setStatus('error')
        return e
      }
    }),
    getBaseViews: flow(function* () {
      self.setStatus('pending')
      try {
        const result: Awaited<ReturnType<typeof TableApi.getBaseViews>> =
          yield TableApi.getBaseViews()
        if (result.ok && result.data) {
          self.setBaseViews(result.data)
          self.setStatus('done')
          return result
        } else {
          self.setStatus('error')
          return result
        }
      } catch (e) {
        self.setStatus('error')
        return e
      }
    }),
    deleteCustomReports: flow(function* (ids: number[]) {
      self.setStatus('pending')
      try {
        const result: Awaited<ReturnType<typeof TableApi.deleteCustomReports>> =
          yield TableApi.deleteCustomReports(ids)
        if (result.ok && result.data) {
          self.setStatus('done')
        } else {
          self.setStatus('error')
        }
      } catch {
        self.setStatus('error')
      }
    }),
    emailCustomReports: flow(function* (view_ids: number[], emails: string[]) {
      self.setStatus('pending')
      try {
        const result: Awaited<ReturnType<typeof TableApi.emailCustomReports>> =
          yield TableApi.emailCustomReports(view_ids, emails)
        if (result.ok && result.data) {
          self.setStatus('done')
        } else {
          self.setStatus('error')
        }
      } catch {
        self.setStatus('error')
      }
    }),
    getActivityLog: flow(function* () {
      self.setStatus('pending')
      self.setActivityLog(null)
      try {
        const result = yield self.environment.api.getActivity()
        if (result.kind === 'ok') {
          self.setActivityLog(activityTransformer(result.data))
          self.setStatus('done')
        } else {
          self.setStatus('error')
        }
      } catch (e) {
        console.error(e)
        self.setStatus('error')
      }
    }),
    getExportableReports: flow(function* () {
      self.setStatus('pending')
      self.setExportableReports(undefined)
      try {
        const result = yield self.environment.api.getExportableReports()
        if (result.kind === 'ok') {
          result.data['base_reports'].sort((a: any, b: any): any =>
            a.title.toLowerCase().localeCompare(b.title.toLowerCase()),
          )
          result.data['custom_reports'].sort((a: any, b: any): any =>
            a.title.toLowerCase().localeCompare(b.title.toLowerCase()),
          )
          self.setExportableReports([
            ...result.data['custom_reports'],
            ...result.data['base_reports'],
          ])
        }
      } catch (e) {
        self.setStatus('error')
      }
    }),
    getHourlyActivity: flow(function* (startingTime: string, endingTime: string) {
      self.setStatus('pending')

      try {
        const result = yield self.environment.api.getReportsHourlyActivity(startingTime, endingTime)
        if (result.kind === 'ok') {
          self.setHourlyActivity(transformHourlyActivity(result.data))
          self.setStatus('done')
        } else {
          self.setStatus('error')
        }
      } catch (e) {
        self.setStatus('error')
      }
    }),
    getSessionStats: flow(function* (startingTime: string, endingTime: string) {
      self.setStatus('pending')

      try {
        const result = yield self.environment.api.getSessionStats(startingTime, endingTime)
        if (result.kind === 'ok') {
          const sessionMinutes = Math.ceil(result.data.avg_session_length / 60)
          const userSessionMinutes = Math.ceil(result.data.avg_time_per_user / 60)
          const final = {
            avg_session_length: sessionMinutes,
            avg_time_per_user: userSessionMinutes,
            stats_history: result.data.stats_history,
          }
          self.setAvgSessionTime(final)
          self.setStatus('done')
        } else {
          self.setStatus('error')
        }
      } catch (e) {
        self.setStatus('error')
      }
    }),
    emailReport: flow(function* (email: string, report_id: any, assignment_id?: number) {
      self.setStatus('pending')
      try {
        const result = yield self.environment.api.emailReport(email, report_id, assignment_id)
        if (result.kind === 'ok') {
          self.setStatus('done')
        }
      } catch (e) {
        self.setStatus('error')
      }
    }),
    getBaseStats: flow(function* (startingTime: string, endingTime: string) {
      self.setStatus('pending')
      self.setBaseStats(undefined)
      try {
        const result = yield self.environment.api.getReportsBaseStats(startingTime, endingTime)
        if (result.kind === 'ok') {
          self.setBaseStats(result.data)
          self.setStatus('done')
        } else {
          self.setStatus('error')
        }
      } catch (e) {
        self.setStatus('error')
      }
    }),
    getKnowledgeBaseStats: flow(function* (start: string, end: string) {
      self.setStatus('pending')
      self.setKnowledgeBaseStats(undefined)

      try {
        const _historyResult = ReportsApi.getKnowledgeGainHistory(start, end)
        const statsResult = yield ReportsApi.getKowledgeGainReport(start, end)
        if (statsResult.ok) {
          const s = {
            ...self.knowledgeBaseStats,
            current_knowledge: statsResult.data.current_knowledge,
            knowledge_gain: statsResult.data.knowledge_gain,
            starting_knowledge: statsResult.data.starting_knowledge,
          }
          self.setKnowledgeBaseStats(s)
        }
        const historyResult = yield _historyResult
        if (historyResult.ok) {
          const s = {
            ...self.knowledgeBaseStats,
            stats_history: historyResult.data.stats_history,
          }
          self.setKnowledgeBaseStats(s)
          self.setStatus('done')
        } else {
          self.setStatus('error')
        }
      } catch (e) {
        self.setStatus('error')
      }
    }),
    getKnowledgeMastery: flow(function* (startingTime: string, endingTime: string) {
      self.setStatus('pending')
      self.setKnowledgeMastery(undefined)
      try {
        const result = yield self.environment.api.getKnowledgeMastery(startingTime, endingTime)
        if (result.kind === 'ok') {
          self.setKnowledgeMastery(result.data)
          self.setStatus('done')
        } else {
          self.setStatus('error')
        }
      } catch (e) {
        self.setStatus('error')
      }
    }),
    getTopicGaps: flow(function* (startingTime: string, endingTime: string) {
      self.setStatus('pending')
      try {
        const result = yield self.environment.api.getReportTopicGaps(startingTime, endingTime)
        if (result.kind === 'ok') {
          const { strongest, weakest } = getStrongestWeakest(result?.data?.topics ?? [])
          self.setTopicGaps({ strongest, weakest })
          self.setStatus('done')
        } else {
          self.setStatus('error')
        }
      } catch (e) {
        self.setStatus('error')
      }
    }),
    getQuestionGaps: flow(function* (startingTime: string, endingTime: string) {
      self.setStatus('pending')
      try {
        const result = yield self.environment.api.getReportQuestionGaps(startingTime, endingTime)
        if (result.kind === 'ok') {
          const { strongest, weakest } = getStrongestWeakest(result?.data?.questions ?? [])
          self.setQuestionGaps({ ...result.data, strongest, weakest })
          self.setStatus('done')
        } else {
          self.setStatus('error')
        }
      } catch (e) {
        self.setStatus('error')
      }
    }),
    refreshData: flow(function* (start: string, end: string) {
      ;(self as any).getBaseStats(start, end)
      ;(self as any).getHourlyActivity(start, end)
      ;(self as any).getSessionStats(start, end)
    }),
    refreshKnowledgeData: flow(function* (start: string, end: string) {
      ;(self as any).getKnowledgeBaseStats(start, end)
      ;(self as any).getKnowledgeMastery(start, end)
      ;(self as any).getTopicGaps(start, end)
      ;(self as any).getQuestionGaps(start, end)
    }),
  }))

type ReportStoreType = typeof ReportStoreModel.Type
export interface ReportStore extends ReportStoreType {}
type ReportStoreSnapshotType = typeof ReportStoreModel.SnapshotType
export interface ReportStoreSnapshot extends ReportStoreSnapshotType {}
