import React, { useState } from 'react'
import { observer } from 'mobx-react-lite'
import { CircularProgress, IconButton, InputAdornment, TextField } from '@mui/material'
import { ArrowForward, Close, OpenInNew } from '@mui/icons-material'

import { Text } from '@trivie/ui-web/components/text'
import { color } from '@trivie/ui-web/theme'
import { useStores } from '@trivie/core'
import { Button } from '@trivie/ui-web/components/button'
import { GetBackgroundColorOfMimeType } from '@trivie/core'
import { ResourceApi } from '@trivie/core/src/services/api-objects'
import { ResourceIcon } from '@trivie/ui-web/components/resource-icon'
import { ReactComponent as NullState } from '../../../content-creation/null-states/null-resource-settings.svg'

interface SearchResourceTabProps {
  quiz: any
  standalone?: any
  topic?: any
  onSelect?: any
}

export const SearchResourceTab = observer((props: SearchResourceTabProps) => {
  const { onSelect, topic, quiz, standalone } = props
  const { resourceStore } = useStores()
  const { getAvailableResources, addResource } = resourceStore
  const [sources, setSources] = useState([])
  const [searchText, setSearchText] = useState('')
  const [loading, setLoading] = useState(false)
  const [searching, setSearching] = useState(false)
  const [selected, setSelected] = useState<number | null>(null)
  const [error, setError] = useState<string>('')

  const selectResource = async (s: { title: string; link: string }) => {
    setLoading(true)
    const result = await addResource((currentProgress: any) => {}, {
      title: s.title,
      url: s.link,
    })

    if (!result) {
      return
    }

    const resourcesAfterAdd = await getAvailableResources()
    const resource = resourcesAfterAdd?.find((r: any) => r.resource_id === result.resource_id)

    if (topic) {
      topic.addResource(resource)
    } else if (quiz) {
      quiz.addResource(resource)
    }

    if (standalone) {
      onSelect(resource)
      return
    }

    quiz?.setMercuryResource(resource)
    quiz.addResource(resource)
    quiz?.set('showResourcePanel', true)
    setLoading(false)
  }

  const handleKeyup = async (e) => {
    if ((e.type === 'keyup' && e.code !== 'Enter') || !e.target.value) {
      return
    }
    await search()
  }

  const search = async () => {
    setSearching(true)
    setError('')
    const result = await ResourceApi.findSources(searchText)
    if (result) {
      setSearching(false)
      setSources(result.data.sources)
      return result.data.sources
    } else {
      setSearching(false)
      setError('We were unable to complete this search. Please try again.')
    }
  }

  const clear = () => {
    setError('')
    setSearchText('')
    setSources([])
    setSelected(null)
  }

  const getExtension = (resource) => {
    if (resource?.mimetype === 'text/html') return 'url'
    return resource?.mimetype
  }

  return quiz?.mercuryResource && !standalone ? null : (
    <>
      <TextField
        fullWidth
        placeholder="Search for a source"
        variant="outlined"
        value={searchText}
        disabled={loading}
        size="small"
        onChange={(e) => setSearchText(e.target.value)}
        style={{ marginBottom: 12 }}
        onKeyUp={handleKeyup}
        InputProps={{
          style: { backgroundColor: '#fff' },
          endAdornment: (
            <InputAdornment position="end">
              {searching ? (
                <CircularProgress style={{ height: 24, width: 24 }} />
              ) : (
                searchText && (
                  <>
                    <IconButton onClick={clear} size="small">
                      <Close htmlColor={color.shade30} />
                    </IconButton>
                    <IconButton onClick={search} size="small">
                      <ArrowForward
                        htmlColor={!searching ? color.palette.blueLink : color.shade30}
                      />
                    </IconButton>
                  </>
                )
              )}
            </InputAdornment>
          ),
        }}
        error={Boolean(error)}
        helperText={error}
      />
      <div style={{ ...LIST, maxHeight: 'calc(100vh - 440px)' }}>
        {sources?.length === 0 ? (
          <div style={NULL}>
            <NullState style={{ alignSelf: 'center', marginTop: 48, marginBottom: 48 }} />
            <Text
              variant="body1Medium"
              style={{ color: color.shade30, fontStyle: 'italic' }}
              className={searching ? 'loading-ellipsis' : ''}
              text={
                searching ? 'Searching' : "Enter a search query and we'll find you a good source."
              }
            />
          </div>
        ) : (
          sources.map((source: any, index: number) => {
            return (
              <div
                onClick={() => setSelected(index)}
                style={{
                  border:
                    index === selected ? `1px solid  rgba(25, 79, 240, .5)` : `1px solid #EFF0F6`,
                  background: index === selected ? '#f8fcff' : '#fff',
                  ...SOURCE_CONTAINER,
                }}
              >
                <div
                  style={{
                    backgroundColor: GetBackgroundColorOfMimeType(getExtension(source)),
                    ...SOURCE_ICON,
                  }}
                >
                  <ResourceIcon extension={getExtension(source)} white={true} />
                </div>
                <div style={{ flexDirection: 'column', overflow: 'hidden', display: 'flex' }}>
                  <Text
                    component="div"
                    noWrap
                    style={SOURCE_TITLE}
                    text={source.title}
                    variant="titleEmphasized"
                  />
                  <Text component="div" noWrap style={LINK} text={source.link} variant="body2" />
                  <Text
                    component="div"
                    noWrap
                    style={SNIPPET}
                    text={source.snippet}
                    variant="body2Medium"
                  />
                </div>
                <IconButton
                  style={{ height: 40, alignSelf: 'center' }}
                  onClick={() => window.open(source.link, '_blank')}
                >
                  <OpenInNew htmlColor={color.shade70} />
                </IconButton>
              </div>
            )
          })
        )}
      </div>
      <div style={FOOTER}>
        {loading ? (
          <CircularProgress />
        ) : (
          sources?.length > 0 && (
            <Button
              disabled={selected === null}
              onClick={() => {
                if (selected !== null) {
                  selectResource(sources[selected])
                }
              }}
              text="Use Source"
              color="primary"
            />
          )
        )}
      </div>
    </>
  )
})

const LIST: React.CSSProperties = {
  overflowY: 'auto',
  paddingRight: 12,
  overflowX: 'hidden',
}
const NULL: React.CSSProperties = {
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  flex: 1,
  alignItems: 'center',
}
const SOURCE_CONTAINER: React.CSSProperties = {
  cursor: 'pointer',
  borderRadius: 16,
  paddingTop: 12,
  paddingBottom: 12,
  paddingRight: 16,
  paddingLeft: 16,
  marginBottom: 8,
  display: 'flex',
  justifyContent: 'space-between',
}
const SOURCE_TITLE: React.CSSProperties = {
  overflowWrap: 'break-word',
  WebkitLineClamp: 1,
  WebkitBoxOrient: 'vertical',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
}
const SOURCE_ICON: React.CSSProperties = {
  display: 'flex',
  alignItems: 'center',
  flexDirection: 'row',
  padding: 15,
  borderRadius: 10,
  marginRight: 12,
}
const SNIPPET: React.CSSProperties = {
  color: color.shade70,
  overflowWrap: 'break-word',
  WebkitLineClamp: 2,
  WebkitBoxOrient: 'vertical',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
}
const LINK: React.CSSProperties = {
  color: color.shade50,
  overflowWrap: 'break-word',
  WebkitLineClamp: 1,
  WebkitBoxOrient: 'vertical',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
}
const FOOTER: React.CSSProperties = {
  flexDirection: 'row',
  display: 'flex',
  justifyContent: 'flex-end',
  marginTop: 20,
}
