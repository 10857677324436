import React, { useState } from 'react'
import { observer } from 'mobx-react-lite'
import { useRouteMatch } from 'react-router-dom'

import { TableHelper } from '../../../../tables'
import { AssignmentTable } from '../../../../tables/assignment-table'

const Assignments = observer(() => {
  const match = useRouteMatch<any>('/content/quizzes/:id/assignments')
  const quizID = Number(match?.params?.id)

  const [tableHelper] = useState(
    new TableHelper('list_quiz_assignments', 'assignments', 'quizzes', quizID),
  )

  return <AssignmentTable filter="active" tableHelper={tableHelper} />
})

export const QuizzesAssignmentsTab = (props: any) => {
  const { id } = props
  return {
    name: 'Assignments',
    path: `/content/quizzes/${id}/assignments`,
    component: () => <Assignments />,
  }
}
