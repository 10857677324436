import React, { useState } from 'react'
import { observer } from 'mobx-react-lite'
import { useHistory } from 'react-router'
import { Grid, Stack } from '@mui/material'

import { useStores } from '@trivie/core'
import { Header } from '@trivie/ui-web/components/header'
import { Insight } from '@trivie/ui-web/components/insight'
import { Punchcard } from '@trivie/ui-web/components/punchcard'
import { Leaderboard } from '@trivie/ui-web/components/leaderboard'
import { DateFilter } from '@trivie/ui-web/components/date-filter'
import { GenerateReport } from '@trivie/ui-web/components/generate-report'
import { Panel } from '@trivie/ui-web/components/panel/panel'

export const EngagementScreen: React.FunctionComponent = observer(() => {
  const history = useHistory()
  const { reportStore, manageLeaderboardStore, manageUserStore } = useStores()
  const { userProfile } = manageUserStore
  const { refreshData, baseStats, hourlyActivity } = reportStore
  const { groupLeaderboard, userLeaderboard, getLeaderboardBase } = manageLeaderboardStore

  const [timespan, setTimespan] = useState('All Time')

  const onDateChange = (start, end, label) => {
    setTimespan(label as any)
    refreshData(start, end)
    getLeaderboardBase(start, end)
  }

  const showMore = () => history.push('/insights/leaderboards')

  const gdpr_enabled = userProfile?.workspace_settings?.gdpr_enabled

  return (
    <>
      <Header divider title="Engagement" />
      <Stack mb={2} direction="row" justifyContent="space-between" alignItems="center">
        <DateFilter onDateChange={onDateChange} />
        <GenerateReport filename="engagement" />
      </Stack>
      <Grid container spacing={2}>
        <Grid item container xs={12} sm={12} md={8} spacing={2}>
          <Grid item xs={12} sm={12}>
            <Panel title="How many users are active?">
              <Stack direction="row" flexWrap="wrap">
                <Insight
                  noAveraging
                  unit=" Users"
                  title="Active Users"
                  value={baseStats?.active_users}
                  data={baseStats?.stats_history[0].data_points}
                  timespan={timespan}
                />
                <Insight
                  noAveraging
                  unit=" Users"
                  title="Total Users"
                  value={baseStats?.total_users}
                  data={baseStats?.stats_history[1].data_points}
                  timespan={timespan}
                />
              </Stack>
            </Panel>
          </Grid>
          <Grid item xs={12} sm={12}>
            <Panel title="What are they accomplishing?">
              <Stack direction="row" flexWrap="wrap">
                <Insight
                  noAveraging
                  title="Questions Answered"
                  unit=" Questions"
                  value={baseStats?.questions_answered}
                  data={baseStats?.stats_history[2].data_points}
                  timespan={timespan}
                />
                <Insight
                  noAveraging
                  unit=" Quizzes"
                  title="Quizzes Completed"
                  value={baseStats?.quizzes_completed}
                  data={baseStats?.stats_history[3].data_points}
                  timespan={timespan}
                />
              </Stack>
            </Panel>
          </Grid>
          <Grid item xs={12} sm={12}>
            <Panel title="How much time are users spending on Trivie?" divider>
              <Punchcard values={hourlyActivity} />
            </Panel>
          </Grid>
        </Grid>
        <Grid item xs={12} sm={12} md={4}>
          <Stack flex={1} height="100%" useFlexGap gap={2}>
            {!gdpr_enabled && (
              <Leaderboard hideExport showMore={showMore} type="user" data={userLeaderboard} />
            )}
            <Leaderboard hideExport showMore={showMore} type="group" data={groupLeaderboard} />
          </Stack>
        </Grid>
      </Grid>
    </>
  )
})
