import React, { useEffect, useState } from 'react'
import { observer } from 'mobx-react-lite'
import { useRouteMatch } from 'react-router-dom'

import { useStores } from '@trivie/core'
import { TableHelper } from '../../../../tables'
import { UserTable } from '../../../../tables/user-table'
import { GroupTable } from '../../../../tables/group-table'

const People: React.FunctionComponent = observer(() => {
  const match = useRouteMatch<any>('/people/tags/:id/people')
  const tagID = Number(match?.params.id)

  const { manageUserStore } = useStores()
  const { getTagCategories } = manageUserStore

  const [tableHelperPeople] = useState(new TableHelper('list_tag_users', 'users', 'tags', tagID))
  const [tableHelperGroups] = useState(new TableHelper('list_tag_groups', 'groups', 'tags', tagID))

  useEffect(() => {
    getTagCategories()
  }, [])

  return (
    <div style={{ flexDirection: 'column', display: 'flex', gap: 20 }}>
      <UserTable tableHelper={tableHelperPeople} />
      <GroupTable tableHelper={tableHelperGroups} />
    </div>
  )
})

export const TagsPeopleTab = (props: any) => {
  const { id } = props
  return {
    name: 'People',
    path: `/people/tags/${id}/people`,
    component: () => <People />,
  }
}
