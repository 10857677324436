import { types } from 'mobx-state-tree'
import { ContentMetadata } from './UserQuiz'

export const UpNextTileModel = types
  .model('UpNextTile')
  .props({
    due_date: types.maybeNull(types.string),
    extension: types.maybeNull(types.string),
    is_post_test: types.boolean,
    is_pre_test: types.boolean,
    metadata: types.maybeNull(ContentMetadata),
    object_id: types.number,
    object_image_url: types.maybeNull(types.string),
    object_title: types.string,
    object_type: types.string,
    topic_id: types.maybeNull(types.number),
    topic_image_url: types.maybeNull(types.string),
    topic_title: types.maybeNull(types.string),
  })
  .views((self) => ({
    get imageUrl() {
      return self.topic_image_url || self.object_image_url
    },
    get path() {
      return Boolean(self.metadata?.path_id)
    },
    get test() {
      return Boolean(self.is_post_test) || Boolean(self.is_pre_test)
    },
    get subtitleTx() {
      if (self.topic_title && self.object_type.toLowerCase() !== 'assessment') {
        return self.topic_title
      }
      switch (self.object_type.toLowerCase()) {
        case 'quiz':
          return 'Quiz.standalone'
        case 'survey':
          return 'Survey.survey'
        case 'assessment':
          if (self.is_pre_test) {
            return 'Quiz.preTest'
          }
          return 'Quiz.postTest'
        case 'resource':
          return 'Resources.viewResource'
        default:
          return ''
      }
    },
  }))
