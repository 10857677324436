import { types } from 'mobx-state-tree'
import { Factoid } from '../Factoid'
import { QuestionAnswerSummary } from '../QuestionAnswerSummary'
import { QuestionType } from '../QuestionType'

export const GetQuestionPctCorrectSummary = types.model('GetQuestionPctCorrectSummary').props({
  question_id: types.number,
  title: types.string,
  pct_correct: types.number,
  image_url: types.maybeNull(types.string),
  topic_title: types.maybeNull(types.string),
  question_type: types.string,
})

export const GetQuestionDetail = types.model('GetQuestionDetail').props({
  adaptive_eligible: types.boolean,
  question_id: types.number,
  text: types.string,
  difficulty: types.maybeNull(types.number),
  answers_summary: types.array(QuestionAnswerSummary),
  total_answers: types.number,
  image_url: types.string,
  factoid: types.maybeNull(Factoid),
  question_type: QuestionType,
  alt_text: types.maybeNull(types.string),
  keep_answer_order: types.boolean,
  order: types.maybeNull(types.number),
  external_id: types.maybeNull(types.string),
})

export const QuestionBase = types.model('QuestionBase').props({
  adaptive_eligible: types.maybeNull(types.boolean),
  difficulty: types.number,
  text: types.string,
})
