import { addMiddleware } from 'mobx-state-tree'
import * as Sentry from '@sentry/react'

const handleMagicLinkEval = (error: string, msg: string) => {
  localStorage.setItem(
    'ml-error',
    JSON.stringify({
      error,
      msg,
    }),
  )
}

export const useStatusCodeMiddleware = (rootStore: any, showAlert: any) => {
  const statusCodeMiddleware = (call: any, next: any, abort: any) => {
    if (call.type === 'flow_resume_error') {
      const problem = call.args[0]
      switch (problem && problem.kind) {
        case 'forbidden':
          switch (call.parentEvent.name) {
            case 'evaluateMagicLink':
              handleMagicLinkEval(
                'Permission Error',
                "You don't have permission to login into this workspace as an administrator.",
              )
              return next(call)
            default:
              return next(call)
          }
        case 'rejected':
          switch (call.parentEvent.name) {
            default:
              if (problem.message?.includes('Invalid access code')) {
                showAlert('Invalid Access Code', 'Workspace access code is invalid.', true)
              }
              return next(call)
          }
        case 'unprocessable-entity':
          Sentry.captureMessage(
            `422 - ${call.parentEvent.name} - ${JSON.stringify(problem.message)}`,
          )
          switch (call.parentEvent.name) {
            default:
              if (problem.message?.includes('Invalid access code')) {
                showAlert('Invalid Access Code', 'Workspace access code is invalid.', true)
              }
              return next(call)
          }
        default:
          return next(call)
      }
    }
    return next(call)
  }

  if (rootStore) {
    addMiddleware(rootStore, statusCodeMiddleware)
  }
}
