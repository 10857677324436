import { Instance, types } from 'mobx-state-tree'

/**
 * QuizResourceRead Model
 */
export const QuizResourceReadModel = types.model('QuizResourceRead').props({
  __typename: types.optional(types.literal('QuizResourceRead'), 'QuizResourceRead'),
  alt_text: types.maybeNull(types.string),
  description: types.maybeNull(types.string),
  extension: types.string,
  is_locked: types.boolean,
  is_deleted: types.boolean,
  id: types.identifierNumber,
  object_id: types.maybeNull(types.integer),
  object_type: types.maybeNull(types.string),
  original_filename: types.maybeNull(types.string),
  title: types.string,
  url: types.maybeNull(types.string),
  thumbnail_url: types.string,
  file_url: types.string,
})

/* The TypeScript type of an instance of QuizResourceModel */
export interface QuizResourceReadModelType extends Instance<typeof QuizResourceReadModel.Type> {}
