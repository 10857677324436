import { types } from 'mobx-state-tree'

export const FormStoreModel = types
  .model('FormStore')
  .props({
    submitted: types.maybe(types.boolean),
    formData: types.frozen(),
    video: types.frozen(),
    memberCountData: types.optional(types.number, 0),
    quizCountData: types.optional(types.number, 0),
    realMemberDifferenceData: types.optional(types.number, 0),
  })

  .actions((self) => ({
    setSubmitted(status: boolean) {
      self.submitted = status
    },
    setFormData(data: any) {
      self.formData = data
    },
    setVideo(data: any) {
      self.video = data
    },
    setMemberCountData(data: number) {
      self.memberCountData = data
    },
    setQuizCountData(data: number) {
      self.quizCountData = data
    },
    setRealMemberDifferenceData(data: number) {
      self.realMemberDifferenceData = data
    },
  }))

type FormStoreType = typeof FormStoreModel.Type
export interface formStore extends FormStoreType {}
type FormStoreSnapshotType = typeof FormStoreModel.SnapshotType
export interface FormStoreSnapshot extends FormStoreSnapshotType {}
