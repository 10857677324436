import { types } from 'mobx-state-tree'

export const UserWorkspaceSettings = types.model('UserWorkspaceSettings').props({
  feed_enabled: types.boolean,
  always_show_feedback: types.maybe(types.boolean),
  show_confirmation_popup: types.maybe(types.boolean),
  user_leaderboard_alltime_enabled: types.boolean,
  user_leaderboard_daily_enabled: types.boolean,
  user_leaderboard_weekly_enabled: types.boolean,
  user_leaderboard_monthly_enabled: types.boolean,
  group_leaderboard_alltime_enabled: types.boolean,
  group_leaderboard_daily_enabled: types.boolean,
  group_leaderboard_weekly_enabled: types.boolean,
  group_leaderboard_monthly_enabled: types.boolean,
  learning_paths_enabled: types.maybe(types.boolean),
  user_levels_enabled: types.boolean,
  policy: types.maybe(types.boolean),
  policy_text: types.maybe(types.maybeNull(types.string)),
  gdpr_enabled: types.boolean,
  mercury_enabled: types.boolean,
  allow_change_email: types.maybe(types.boolean),
  allow_change_name: types.maybe(types.boolean),
  time_zone: types.maybe(types.string),
  plan_tier: types.string,
})
