import React, { useEffect, useState } from 'react'
import { observer } from 'mobx-react-lite'
import { useHistory, useLocation, useParams } from 'react-router-dom'
import { DateTime } from 'luxon'
import { EditRounded, WarningRounded } from '@mui/icons-material'
import { CircularProgress } from '@mui/material'

import { useStores } from '@trivie/core'
import { Divider } from '@trivie/ui-web/components/divider'
import { Header } from '@trivie/ui-web/components/header'
import { Text } from '@trivie/ui-web/components/text'
import { color } from '@trivie/ui-web/theme'

import { CustomTableHelper } from '../../../tables/CustomTableHelper'
import { GroupTable } from '../../../tables/group-table'
import { QuestionTable } from '../../../tables/question-table'
import { QuizTable } from '../../../tables/quiz-table'
import { TagTable } from '../../../tables/tag-table'
import { TopicTable } from '../../../tables/topic-table'
import { UserTable } from '../../../tables/user-table'
import { CreateReportDialog } from '@trivie/ui-web/components/create-report/create-report-dialog'
import { TableApi } from '@trivie/core/src/services/api-objects'

function useQuery() {
  const { search } = useLocation()

  return React.useMemo(() => new URLSearchParams(search), [search])
}

export const ViewReportScreen: React.FunctionComponent = observer(() => {
  const { id } = useParams() as any
  const { reportStore, taskStore } = useStores()
  const { saveTask } = taskStore
  const { getCustomReport, setCustomReport, setOpenFilter, openFilter, customReport } = reportStore

  const query = useQuery()
  const history = useHistory()

  const [tableHelper, setTableHelper] = useState<any>()

  const getTableType = (template: string) => {
    switch (template) {
      case 'base_users':
        return 'users'
      case 'base_questions':
        return 'questions'
      case 'base_quizzes':
        return 'quizzes'
      case 'base_tags':
        return 'tags'
      case 'base_groups':
        return 'groups'
      case 'base_topics':
        return 'topics'
      default:
        return 'users'
    }
  }

  const [loading, setLoading] = useState(true)

  const viewReport = async (refresh?: boolean) => {
    setLoading(true)
    if (
      customReport?.task &&
      customReport?.task.completed &&
      !customReport?.task.stale &&
      !refresh
    ) {
      // fetched recently - find results and download
      const existingReport = (await customReport?.task.getResults(undefined, true)) as any
      const { url } = existingReport.data
      getReportData(url)
    } else {
      const newTask = await saveTask([{ object_id: id, object_type: 'CustomDataView' }])

      if (newTask) {
        customReport?.setTask(newTask.data[0]) as any
      }

      const result = await customReport?.task?.poll(customReport?.task.getResults)

      if (result) {
        getReportData(result.data.url)
      } else {
        console.log('no result')
      }
    }
  }

  const refresh = () => viewReport(true)

  const renderBaseTable = (template: string) => {
    switch (template) {
      case 'base_users':
        return (
          <UserTable
            exportFilename={tableHelper.name}
            tableHelper={tableHelper}
            openFilter={openFilter}
          />
        )
      case 'base_questions':
        return (
          <QuestionTable
            exportFilename={tableHelper.name}
            tableHelper={tableHelper}
            openFilter={openFilter}
          />
        )
      case 'base_quizzes':
        return (
          <QuizTable
            exportFilename={tableHelper.name}
            tableHelper={tableHelper}
            openFilter={openFilter}
          />
        )
      case 'base_tags':
        return (
          <TagTable
            exportFilename={tableHelper.name}
            tableHelper={tableHelper}
            openFilter={openFilter}
          />
        )
      case 'base_groups':
        return (
          <GroupTable
            exportFilename={tableHelper.name}
            tableHelper={tableHelper}
            openFilter={openFilter}
          />
        )
      case 'base_topics':
        return (
          <TopicTable
            exportFilename={tableHelper.name}
            tableHelper={tableHelper}
            openFilter={openFilter}
          />
        )
      // case 'base_assignments':
      //   return <AssignmentTable filter= tableHelper={tableHelper} />
      default:
        return null
    }
  }

  const [showCreateNewReport, setShowCreateNewReport] = useState(false)
  const [newCols, setNewCols] = useState([])
  const createNew = async (cols, filter) => {
    setShowCreateNewReport(true)
    setNewCols(cols)
  }

  const getReportData = async (url) => {
    const res = await fetch(url)
    const data = await res.json()
    if (customReport) {
      const th = new CustomTableHelper(
        customReport?.base_view_name,
        getTableType(customReport?.base_view_name),
        getTableType(customReport?.base_view_name),
        undefined,
        undefined,
        true,
        customReport?.view_name,
        customReport?.view_description ?? '',
        customReport?.base_view_id,
        customReport?.base_view_name,
        () => {
          getCustomReport(id).then(() => {
            viewReport(true)
          })
        },
        createNew,
      )
      th.load(customReport?.columns, data, id).then(() => {
        setTableHelper(th)
        if (query.get('open') === 'true') {
          query.delete('open')
          history.replace({ search: query.toString() })
          setOpenFilter(true)
        }
        setLoading(false)
      })
    }
  }

  useEffect(() => {
    getCustomReport(id)
    return () => {
      setCustomReport(undefined)
    }
  }, [])

  useEffect(() => {
    ;(async () => {
      if (customReport) {
        await viewReport()
      }
    })()
  }, [customReport])

  const formatDateTime = (date) =>
    `${DateTime.fromISO(date).toFormat('MM/dd/yyyy')} at ${DateTime.fromISO(date).toLocaleString(
      DateTime.TIME_WITH_SHORT_OFFSET,
    )}`

  const [createdID, setCreatedID] = useState<any>()

  const handleEditReport = () => {
    reportStore?.setStatus('pending')
    setOpenFilter(true)
    setTimeout(() => {
      reportStore?.setStatus('done')
    }, 200)
  }

  const primary = {
    startIcon: <EditRounded />,
    onClick: handleEditReport,
    text: 'Edit Report',
  }

  return (
    <>
      <Header title={customReport?.view_name} primaryAction={primary} />
      {customReport && (
        <div style={{ marginTop: 20 }}>
          <div style={{ display: 'flex', flexDirection: 'row', marginBottom: 4 }}>
            <Text
              style={{ color: color.shade50 }}
              variant="body2"
              text={customReport?.view_description}
            />
            {customReport?.last_edited_datetime && (
              <>
                {customReport?.view_description && (
                  <Text style={{ marginLeft: 8, marginRight: 8 }} variant="body2Medium" text="•" />
                )}
                <Text
                  style={{ fontStyle: 'italic', color: color.shade50 }}
                  variant="body2"
                  text={`Last edited ${DateTime.fromISO(
                    customReport?.last_edited_datetime,
                  ).toLocaleString(DateTime.DATE_FULL)} by ${customReport?.last_edited_by_name}`}
                />
              </>
            )}
          </div>
          {customReport.task && (!customReport.task.stale || customReport.task.loading) ? (
            <div style={{ flexDirection: 'row', display: 'flex' }}>
              <Text
                variant="body2"
                style={{ color: color.shade50, fontStyle: 'italic', marginRight: 4 }}
                text={
                  customReport.task.loading
                    ? 'Preparing report for download...'
                    : customReport.task.status === 'completed'
                      ? `Report last prepared ${formatDateTime(customReport.task.date_completed)}.`
                      : ''
                }
              />
              {!customReport.task.loading && (
                <Text
                  onClick={() => refresh()}
                  variant="body2"
                  style={{
                    cursor: 'pointer',
                    color: color.blue,
                    textDecoration: 'underline',
                    fontStyle: 'italic',
                  }}
                  text={customReport?.task.status === 'completed' ? 'Refresh' : ''}
                />
              )}
            </div>
          ) : null}
        </div>
      )}
      <Divider style={{ marginTop: 20, marginBottom: 20 }} />
      {loading &&
      customReport?.task?.status !== 'failed' &&
      customReport?.task?.status !== undefined ? (
        <div style={LOADER}>
          <CircularProgress />
        </div>
      ) : customReport?.task?.status === 'failed' ? (
        <div style={LOADER}>
          <WarningRounded
            style={{ marginBottom: 24, height: 48, width: 48 }}
            htmlColor={color.yellow}
          />
          <Text
            variant="body1"
            style={{ textDecoration: 'underline', color: color.blue }}
            onClick={() => refresh()}
            text="Download failed. Retry?"
          />
        </div>
      ) : (
        tableHelper && renderBaseTable(customReport?.base_view_name ?? '')
      )}
      {showCreateNewReport && (
        <CreateReportDialog
          duplicate={{
            view_name: tableHelper.baseViewName,
            name: tableHelper.baseViewName.split('_')[1],
            base_view_id: tableHelper.baseViewID,
            columns: newCols,
          }}
          open={showCreateNewReport}
          onClose={() => {
            setShowCreateNewReport(false)
            if (createdID) {
              setCreatedID(null)
            }
          }}
          onCreate={(payload) =>
            TableApi.saveCustomReport(payload).then((resp) => {
              setCreatedID(resp.data.id)
              history.replace(`/insights/reports/custom/${resp.data.id}?open=true`)
            })
          }
        />
      )}
    </>
  )
})

const LOADER: React.CSSProperties = {
  height: window.innerHeight - 400,
  flexDirection: 'column',
  flex: 1,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
}
