import React, { useEffect, useRef, useState } from 'react'
import { Box, IconButton, Slider, Stack } from '@mui/material'
import { Add, Remove } from '@mui/icons-material'
import ReactCrop, { centerCrop, makeAspectCrop, PixelCrop } from 'react-image-crop'
import 'react-image-crop/dist/ReactCrop.css'

import { Text } from '../text'
import { Dialog } from '../dialogs/dialog'
import cropImage from './cropImage'
import { color } from '../../theme'

interface ImageCropDialogProps {
  src?: any
  open: boolean
  onConfirm: any
  onClose: any
  circular?: boolean
  file: any
  aspect?: number
}
export const ImageCropDialog = (props: ImageCropDialogProps) => {
  const { aspect = 5 / 2, file, onClose, open, circular, src, onConfirm, ...rest } = props

  const [scale, setScale] = useState<any>(1)
  const [crop, setCrop] = useState<any>()
  const [imgSrc, setImgSrc] = useState<any>()
  const [completedCrop, setCompletedCrop] = useState<PixelCrop>()

  const onChangeFile = async (f: File | null) => {
    if (!f) {
      setImgSrc('')
      return
    }
    const reader = new FileReader()
    reader.addEventListener('load', () => {
      setImgSrc(reader.result?.toString() || '')
    })
    reader.readAsDataURL(f)
  }

  useEffect(() => {
    onChangeFile(file)
  }, [])

  const imgRef = useRef<HTMLImageElement>(null)

  const onImageLoad = (e: React.SyntheticEvent<HTMLImageElement>) => {
    // @ts-ignore
    imgRef.current = e.currentTarget
    const { width, height } = e.currentTarget
    setCrop(
      centerCrop(
        makeAspectCrop(
          {
            unit: '%',
            width: 90,
          },
          aspect,
          width,
          height,
        ),
        width,
        height,
      ),
    )
  }

  const handleSave = async () => {
    const modified = (await onCrop()) ?? ({} as any)
    const response = await fetch(modified.blobUrl)
    const data = await response.blob()
    const file = new File([data], modified.croppedBlob.name, { type: modified.croppedBlob.type })
    onConfirm(file)
  }

  const onCrop = async () => {
    if (crop?.height || crop?.width) {
      if (imgRef.current && file) {
        const {
          blob: croppedBlob,
          blobUrl,
          revokeUrl,
        } = await cropImage(imgRef.current, file, completedCrop, true, scale)
        return { croppedBlob, blobUrl, revokeUrl }
      }
    }
  }

  return (
    <Dialog
      confirmBtnLabel="Save"
      onConfirm={handleSave}
      maxWidth="sm"
      open={open}
      onClose={onClose}
      title="Crop Image"
      {...rest}
    >
      <Box alignItems="center" display="flex" flexDirection="column">
        <Text
          mb={2}
          component="div"
          variant="title"
          text="Select the region of the photo you want to use throughout the learner app."
          color="text.secondary"
        />
        {file ? (
          <Box sx={CROP_CONTAINER}>
            <ReactCrop
              onComplete={(c) => setCompletedCrop(c)}
              keepSelection
              aspect={aspect}
              crop={crop}
              onChange={(c) => setCrop(c)}
            >
              <img
                style={{ ...IMG, transform: `scale(${scale})` }}
                onLoad={onImageLoad}
                src={imgSrc}
              />
            </ReactCrop>
          </Box>
        ) : null}
        <Stack
          mt={2}
          alignItems="center"
          justifyContent="center"
          direction="row"
          useFlexGap
          gap={2}
        >
          <IconButton disabled={scale <= 0.5} onClick={() => setScale(scale - 0.1)} size="large">
            <Remove />
          </IconButton>
          <Slider
            sx={{ width: '300px' }}
            min={0.5}
            max={2.0}
            step={0.1}
            value={scale}
            onChange={(e: any) => setScale(Number(e.target.value))}
          />
          <IconButton disabled={scale >= 2.0} onClick={() => setScale(scale + 0.1)} size="large">
            <Add />
          </IconButton>
        </Stack>
      </Box>
    </Dialog>
  )
}

const IMG: React.CSSProperties = {
  maxHeight: 400,
  objectFit: 'contain',
}
const CROP_CONTAINER: React.CSSProperties = {
  display: 'flex',
  justifyContent: 'center',
  width: '100%',
  backgroundColor: color.shade30,
}
