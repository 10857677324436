import { styled } from '@mui/material/styles'
import * as React from 'react'
import Drawer from '@mui/material/Drawer'
import List from '@mui/material/List'
import IconButton from '@mui/material/IconButton'
import ListItem from '@mui/material/ListItem'
import ListItemButton from '@mui/material/ListItemButton'
import ListItemIcon from '@mui/material/ListItemIcon'
import { ActionTooltip } from '@trivie/ui-web/components/table-toolbar'

import {
  AccessTimeRounded,
  ArrowBack,
  BoltRounded,
  Close,
  DescriptionRounded,
  FactCheckRounded,
  GroupRounded,
  LocalOfferOutlined,
  LocalOfferRounded,
  TodayRounded,
  TopicRounded,
  WorkspacePremiumOutlined,
} from '@mui/icons-material'
import { Text } from '@trivie/ui-web/components/text'
import { color } from '@trivie/ui-web/theme'
import { useMediaQuery } from '@mui/material'
import { observer } from 'mobx-react-lite'
import { useStores } from '@trivie/core'
import { useEffect } from 'react'
import {
  AddTagToUser,
  AddToAssignment,
  AddToGroup,
  AwardBadge,
  AwardCertification,
  AwardPoints,
  CompleteQuiz,
  CompleteTopic,
  DelayFor,
  ViewSource,
  WaitUntil,
} from './edit-step'

const drawerWidth = 500

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  justifyContent: 'flex-start',
}))

export const StepPanel = observer((props: any) => {
  const { importing, onOpen, open, workflow, onClose } = props

  const { pathStore } = useStores()
  const { path } = pathStore

  const min1300 = useMediaQuery('(min-width:1300px)')
  const variant = importing ? 'temporary' : min1300 ? 'persistent' : 'temporary'

  const handleDrawerOpen = () => {
    onOpen()
  }

  const handleDrawerClose = () => {
    if (workflow?.stub) {
      workflow?.removeAction(workflow?.stub)
    }
    // Scroll add step btn into view if below viewport
    // const addStepBtn = document.getElementById('add_step_btn')
    // if (addStepBtn && !Boolean(workflow?.editing)) {
    //   addStepBtn.scrollIntoView()
    // }
    onClose()
  }

  useEffect(() => {
    if (workflow?.editing) {
      handleDrawerOpen()
    }
  }, [workflow?.editing])

  const content = [
    {
      label: 'View Source',
      icon: <DescriptionRounded style={ICON} />,
      onClick: () => workflow?.setEditing('view_source'),
    },
    {
      label: 'Complete Quiz',
      icon: <BoltRounded style={ICON} />,
      onClick: () => workflow.setEditing('complete_quiz'),
    },
    {
      label: 'Complete Topic',
      icon: <TopicRounded style={ICON} />,
      onClick: () => workflow.setEditing('complete_topic'),
    },
  ]

  const defaultDisabled =
    'There must be an existing content step added in order to attach an action'

  const actions = path
    ? [
        {
          label: 'Award Badge',
          icon: <WorkspacePremiumOutlined style={ICON} />,
          onClick: () => workflow?.setEditing('award_badge'),
          disabled: workflow?.nodes.length === 0,
          disabledTooltip: defaultDisabled,
        },
        // {
        //   label: 'Award Certification',
        //   onClick: () => workflow?.setEditing('award_certification'),
        //   disabledTooltip:
        //     workflow?.nodes.length === 0
        //       ? defaultDisabled
        //       : workflow?.currentNode.object_type !== 'Topic'
        //       ? 'Certifications can only be attached to a Topic'
        //       : 'There is already an existing certification for this topic.',
        //   disabled:
        //     workflow?.nodes.length === 0 ||
        //     workflow?.currentNode.object_type !== 'Topic' ||
        //     workflow?.currentNode?.hasCert,
        //   icon: (
        //     <svg
        //       style={{ marginRight: 8 }}
        //       width="18"
        //       height="18"
        //       viewBox="0 0 18 18"
        //       fill="none"
        //       xmlns="http://www.w3.org/2000/svg"
        //     >
        //       <path
        //         d="M7.67582 1.37952C8.39799 0.627204 9.60201 0.627204 10.3242 1.37952C10.7734 1.84752 11.4368 2.04349 12.0681 1.89446C13.078 1.65608 14.0875 2.31914 14.2905 3.33667C14.4177 3.97445 14.8716 4.50811 15.4837 4.728C16.4637 5.0801 16.9568 6.1826 16.5813 7.15391C16.3461 7.76232 16.4433 8.45834 16.8396 8.97643C17.4749 9.80694 17.3067 11.0012 16.4631 11.619C15.9375 12.004 15.6517 12.6451 15.7075 13.2941C15.7966 14.3287 15.0149 15.2503 13.9782 15.3091C13.3299 15.3458 12.7462 15.7263 12.4462 16.3022C11.966 17.2239 10.811 17.5707 9.90888 17.0548C9.34576 16.7327 8.65424 16.7327 8.09112 17.0548C7.189 17.5707 6.03397 17.2239 5.55383 16.3022C5.25383 15.7263 4.67013 15.3458 4.02185 15.3091C2.98514 15.2503 2.20345 14.3287 2.29248 13.2941C2.34835 12.6451 2.06247 12.004 1.5369 11.619C0.693326 11.0012 0.525109 9.80694 1.16039 8.97643C1.55669 8.45834 1.6539 7.76232 1.4187 7.15391C1.04322 6.1826 1.53628 5.0801 2.51632 4.728C3.12836 4.50811 3.58228 3.97445 3.7095 3.33667C3.91248 2.31914 4.92202 1.65608 5.93185 1.89446C6.56322 2.04349 7.22658 1.84752 7.67582 1.37952Z"
        //         fill="black"
        //         fillOpacity="0.7"
        //       />
        //       <path
        //         d="M7.67582 1.37952C8.39799 0.627204 9.60201 0.627204 10.3242 1.37952C10.7734 1.84752 11.4368 2.04349 12.0681 1.89446C13.078 1.65608 14.0875 2.31914 14.2905 3.33667C14.4177 3.97445 14.8716 4.50811 15.4837 4.728C16.4637 5.0801 16.9568 6.1826 16.5813 7.15391C16.3461 7.76232 16.4433 8.45834 16.8396 8.97643C17.4749 9.80694 17.3067 11.0012 16.4631 11.619C15.9375 12.004 15.6517 12.6451 15.7075 13.2941C15.7966 14.3287 15.0149 15.2503 13.9782 15.3091C13.3299 15.3458 12.7462 15.7263 12.4462 16.3022C11.966 17.2239 10.811 17.5707 9.90888 17.0548C9.34576 16.7327 8.65424 16.7327 8.09112 17.0548C7.189 17.5707 6.03397 17.2239 5.55383 16.3022C5.25383 15.7263 4.67013 15.3458 4.02185 15.3091C2.98514 15.2503 2.20345 14.3287 2.29248 13.2941C2.34835 12.6451 2.06247 12.004 1.5369 11.619C0.693326 11.0012 0.525109 9.80694 1.16039 8.97643C1.55669 8.45834 1.6539 7.76232 1.4187 7.15391C1.04322 6.1826 1.53628 5.0801 2.51632 4.728C3.12836 4.50811 3.58228 3.97445 3.7095 3.33667C3.91248 2.31914 4.92202 1.65608 5.93185 1.89446C6.56322 2.04349 7.22658 1.84752 7.67582 1.37952Z"
        //         fill="url(#paint0_linear_3044_27100)"
        //         fillOpacity="0.1"
        //       />
        //       <path
        //         opacity="0.8"
        //         d="M7.97209 10.3253L6.77394 9.1148C6.70943 9.04948 6.62185 9.01277 6.53052 9.01277C6.43918 9.01277 6.3516 9.04948 6.28709 9.1148C6.15243 9.25085 6.15243 9.47061 6.28709 9.60666L7.73039 11.0648C7.86505 11.2008 8.08258 11.2008 8.21724 11.0648L11.8704 7.3741C12.005 7.23806 12.005 7.01829 11.8704 6.88224C11.8059 6.81692 11.7183 6.78021 11.6269 6.78021C11.5356 6.78021 11.448 6.81692 11.3835 6.88224L7.97209 10.3253Z"
        //         fill="white"
        //         stroke="#FDFDFD"
        //         strokeWidth="0.381721"
        //       />
        //       <path
        //         d="M15.324 9.24315C15.324 12.736 12.4925 15.5675 8.99972 15.5675C5.50689 15.5675 2.67539 12.736 2.67539 9.24315C2.67539 5.75032 5.50689 2.91882 8.99972 2.91882C12.4925 2.91882 15.324 5.75032 15.324 9.24315ZM4.05173 9.24315C4.05173 11.9758 6.26702 14.1911 8.99972 14.1911C11.7324 14.1911 13.9477 11.9758 13.9477 9.24315C13.9477 6.51045 11.7324 4.29516 8.99972 4.29516C6.26702 4.29516 4.05173 6.51045 4.05173 9.24315Z"
        //         fill="white"
        //         fillOpacity="0.2"
        //       />
        //       <path
        //         d="M15.324 9.24309C15.324 12.7359 12.4925 15.5674 8.9997 15.5674C5.50687 15.5674 2.67537 12.7359 2.67537 9.24309C2.67537 5.75026 5.50687 2.91876 8.9997 2.91876C12.4925 2.91876 15.324 5.75026 15.324 9.24309ZM4.05171 9.24309C4.05171 11.9758 6.267 14.1911 8.9997 14.1911C11.7324 14.1911 13.9477 11.9758 13.9477 9.24309C13.9477 6.51039 11.7324 4.2951 8.9997 4.2951C6.267 4.2951 4.05171 6.51039 4.05171 9.24309Z"
        //         fill="white"
        //         fillOpacity="0.5"
        //       />
        //       <defs>
        //         <linearGradient
        //           id="paint0_linear_3044_27100"
        //           x1="-3.09122"
        //           y1="-1.40619"
        //           x2="10.5822"
        //           y2="22.9996"
        //           gradientUnits="userSpaceOnUse"
        //         >
        //           <stop stopColor="white" />
        //           <stop offset="1" stopColor="white" stopOpacity="0" />
        //         </linearGradient>
        //       </defs>
        //     </svg>
        //   ),
        // },
        // {
        //   label: 'Award Points',
        //   icon: <TrendingUpRounded style={ICON} />,
        //   onClick: () => workflow?.setEditing('award_points'),
        //   disabled: workflow?.nodes.length === 0,
        //   disabledTooltip: defaultDisabled,
        // },
        {
          label: 'Add User To Assignment',
          icon: <FactCheckRounded style={ICON} />,
          onClick: () => workflow?.setEditing('add_to_assignment'),
          disabled: workflow?.nodes.length === 0,
          disabledTooltip: defaultDisabled,
        },
        {
          label: 'Add User To Group',
          icon: <GroupRounded style={ICON} />,
          onClick: () => workflow?.setEditing('add_to_group'),
          disabled: workflow?.nodes.length === 0,
          disabledTooltip: defaultDisabled,
        },
        {
          label: 'Tag',
          icon: <LocalOfferRounded style={ICON} />,
          onClick: () => workflow?.setEditing('tag_user'),
          disabled: workflow?.nodes.length === 0,
          disabledTooltip: defaultDisabled,
        },
        {
          label: 'Untag',
          icon: <LocalOfferOutlined style={ICON} />,
          onClick: () => workflow?.setEditing('untag_user'),
          disabled: workflow?.nodes.length === 0,
          disabledTooltip: defaultDisabled,
        },
      ]
    : ([
        {
          label: 'Award Badge',
          icon: <WorkspacePremiumOutlined style={ICON} />,
          onClick: () => workflow?.setEditing('award_badge'),
          disabledTooltip: defaultDisabled,
          tooltip: 'Award an existing or new custom badge.',
        },
        {
          label: 'Award Certification',
          onClick: () => workflow?.setEditing('award_certification'),
          disabledTooltip: 'There is already an existing certification for this topic.',
          disabled: workflow?.currentNode?.hasCert,
          tooltip: "Award a tiered certification based on the learner's score on the topic.",
          icon: (
            <svg
              style={{ marginRight: 8 }}
              width="18"
              height="18"
              viewBox="0 0 18 18"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M7.67582 1.37952C8.39799 0.627204 9.60201 0.627204 10.3242 1.37952C10.7734 1.84752 11.4368 2.04349 12.0681 1.89446C13.078 1.65608 14.0875 2.31914 14.2905 3.33667C14.4177 3.97445 14.8716 4.50811 15.4837 4.728C16.4637 5.0801 16.9568 6.1826 16.5813 7.15391C16.3461 7.76232 16.4433 8.45834 16.8396 8.97643C17.4749 9.80694 17.3067 11.0012 16.4631 11.619C15.9375 12.004 15.6517 12.6451 15.7075 13.2941C15.7966 14.3287 15.0149 15.2503 13.9782 15.3091C13.3299 15.3458 12.7462 15.7263 12.4462 16.3022C11.966 17.2239 10.811 17.5707 9.90888 17.0548C9.34576 16.7327 8.65424 16.7327 8.09112 17.0548C7.189 17.5707 6.03397 17.2239 5.55383 16.3022C5.25383 15.7263 4.67013 15.3458 4.02185 15.3091C2.98514 15.2503 2.20345 14.3287 2.29248 13.2941C2.34835 12.6451 2.06247 12.004 1.5369 11.619C0.693326 11.0012 0.525109 9.80694 1.16039 8.97643C1.55669 8.45834 1.6539 7.76232 1.4187 7.15391C1.04322 6.1826 1.53628 5.0801 2.51632 4.728C3.12836 4.50811 3.58228 3.97445 3.7095 3.33667C3.91248 2.31914 4.92202 1.65608 5.93185 1.89446C6.56322 2.04349 7.22658 1.84752 7.67582 1.37952Z"
                fill="black"
                fillOpacity="0.7"
              />
              <path
                d="M7.67582 1.37952C8.39799 0.627204 9.60201 0.627204 10.3242 1.37952C10.7734 1.84752 11.4368 2.04349 12.0681 1.89446C13.078 1.65608 14.0875 2.31914 14.2905 3.33667C14.4177 3.97445 14.8716 4.50811 15.4837 4.728C16.4637 5.0801 16.9568 6.1826 16.5813 7.15391C16.3461 7.76232 16.4433 8.45834 16.8396 8.97643C17.4749 9.80694 17.3067 11.0012 16.4631 11.619C15.9375 12.004 15.6517 12.6451 15.7075 13.2941C15.7966 14.3287 15.0149 15.2503 13.9782 15.3091C13.3299 15.3458 12.7462 15.7263 12.4462 16.3022C11.966 17.2239 10.811 17.5707 9.90888 17.0548C9.34576 16.7327 8.65424 16.7327 8.09112 17.0548C7.189 17.5707 6.03397 17.2239 5.55383 16.3022C5.25383 15.7263 4.67013 15.3458 4.02185 15.3091C2.98514 15.2503 2.20345 14.3287 2.29248 13.2941C2.34835 12.6451 2.06247 12.004 1.5369 11.619C0.693326 11.0012 0.525109 9.80694 1.16039 8.97643C1.55669 8.45834 1.6539 7.76232 1.4187 7.15391C1.04322 6.1826 1.53628 5.0801 2.51632 4.728C3.12836 4.50811 3.58228 3.97445 3.7095 3.33667C3.91248 2.31914 4.92202 1.65608 5.93185 1.89446C6.56322 2.04349 7.22658 1.84752 7.67582 1.37952Z"
                fill="url(#paint0_linear_3044_27100)"
                fillOpacity="0.1"
              />
              <path
                opacity="0.8"
                d="M7.97209 10.3253L6.77394 9.1148C6.70943 9.04948 6.62185 9.01277 6.53052 9.01277C6.43918 9.01277 6.3516 9.04948 6.28709 9.1148C6.15243 9.25085 6.15243 9.47061 6.28709 9.60666L7.73039 11.0648C7.86505 11.2008 8.08258 11.2008 8.21724 11.0648L11.8704 7.3741C12.005 7.23806 12.005 7.01829 11.8704 6.88224C11.8059 6.81692 11.7183 6.78021 11.6269 6.78021C11.5356 6.78021 11.448 6.81692 11.3835 6.88224L7.97209 10.3253Z"
                fill="white"
                stroke="#FDFDFD"
                strokeWidth="0.381721"
              />
              <path
                d="M15.324 9.24315C15.324 12.736 12.4925 15.5675 8.99972 15.5675C5.50689 15.5675 2.67539 12.736 2.67539 9.24315C2.67539 5.75032 5.50689 2.91882 8.99972 2.91882C12.4925 2.91882 15.324 5.75032 15.324 9.24315ZM4.05173 9.24315C4.05173 11.9758 6.26702 14.1911 8.99972 14.1911C11.7324 14.1911 13.9477 11.9758 13.9477 9.24315C13.9477 6.51045 11.7324 4.29516 8.99972 4.29516C6.26702 4.29516 4.05173 6.51045 4.05173 9.24315Z"
                fill="white"
                fillOpacity="0.2"
              />
              <path
                d="M15.324 9.24309C15.324 12.7359 12.4925 15.5674 8.9997 15.5674C5.50687 15.5674 2.67537 12.7359 2.67537 9.24309C2.67537 5.75026 5.50687 2.91876 8.9997 2.91876C12.4925 2.91876 15.324 5.75026 15.324 9.24309ZM4.05171 9.24309C4.05171 11.9758 6.267 14.1911 8.9997 14.1911C11.7324 14.1911 13.9477 11.9758 13.9477 9.24309C13.9477 6.51039 11.7324 4.2951 8.9997 4.2951C6.267 4.2951 4.05171 6.51039 4.05171 9.24309Z"
                fill="white"
                fillOpacity="0.5"
              />
              <defs>
                <linearGradient
                  id="paint0_linear_3044_27100"
                  x1="-3.09122"
                  y1="-1.40619"
                  x2="10.5822"
                  y2="22.9996"
                  gradientUnits="userSpaceOnUse"
                >
                  <stop stopColor="white" />
                  <stop offset="1" stopColor="white" stopOpacity="0" />
                </linearGradient>
              </defs>
            </svg>
          ),
        },
      ] as any)

  const logic = [
    {
      label: 'Delay',
      icon: <AccessTimeRounded style={ICON} />,
      onClick: () => workflow.setEditing('delay'),
      disabled: workflow?.nodes?.length === 0,
      disabledTooltip: 'There must be an existing step before attaching any logic',
    },
    {
      label: 'Wait Until',
      icon: <TodayRounded style={ICON} />,
      onClick: () => workflow.setEditing('wait_until'),
      disabled: workflow?.nodes?.length === 0,
      disabledTooltip: 'There must be an existing step before attaching any logic',
    },
  ]

  return (
    <>
      <Drawer
        sx={{
          width: open ? drawerWidth : 0,
          flexShrink: 0,
          '& .MuiDrawer-paper': {
            width: open ? drawerWidth : 0,
            paddingTop: '64px',
            background: 'rgb(250,250,250)',
            borderLeft: `1px solid rgba(0,0,0,.05)`,
            borderRadius: '0px !important',
          },
        }}
        variant={variant}
        anchor="right"
        open={open}
      >
        <DrawerHeader>
          {workflow?.editing ? (
            <IconButton
              onClick={() => {
                workflow?.selectStep(undefined)
                workflow?.setEditing('')
              }}
            >
              <ArrowBack />
            </IconButton>
          ) : (
            <div style={{ width: 40 }} />
          )}
          <div style={TITLE}>
            <Text
              text={
                workflow?.editing
                  ? path
                    ? 'Edit Step'
                    : 'Edit Award'
                  : path
                    ? 'Add Step'
                    : 'Add Award'
              }
              variant="labelEmphasized"
            />
          </div>
          {!workflow?.editing ? (
            <IconButton
              onClick={() => {
                handleDrawerClose()
                workflow?.selectStep(undefined)
                workflow?.setEditing('')
              }}
            >
              <Close />
            </IconButton>
          ) : (
            <div style={{ width: 40 }} />
          )}
        </DrawerHeader>
        <div style={SUBTITLE}>
          <Text
            text={
              workflow?.editing
                ? workflow.editing === 'award_certification'
                  ? 'Set the properties for your certification'
                  : workflow.editing === 'award_badge'
                    ? 'Set the properties for your badge'
                    : `Modify your step to customize your ${path ? 'Learning Path' : 'Topic'}.`
                : path
                  ? 'Select a step or an action to add to your Learning Path'
                  : 'Select an award for learners to receive after completing this topic.'
            }
            variant="body1Medium"
            style={{ alignSelf: 'center' }}
          />
        </div>
        {workflow?.editing ? (
          <div style={{ padding: 20 }}>
            {workflow.editing === 'delay' || workflow.editing === 'delay_from' ? (
              <DelayFor onUpdate={handleDrawerClose} />
            ) : workflow.editing === 'wait_until' ? (
              <WaitUntil onUpdate={handleDrawerClose} />
            ) : workflow.editing === 'award_points' ? (
              <AwardPoints onUpdate={handleDrawerClose} />
            ) : workflow.editing === 'untag_user' ? (
              <AddTagToUser onUpdate={handleDrawerClose} untag />
            ) : workflow.editing === 'tag_user' ? (
              <AddTagToUser onUpdate={handleDrawerClose} />
            ) : workflow.editing === 'add_to_group' ? (
              <AddToGroup onUpdate={handleDrawerClose} />
            ) : workflow.editing === 'add_to_assignment' ? (
              <AddToAssignment onUpdate={handleDrawerClose} />
            ) : workflow.editing === 'view_source' ? (
              <ViewSource onUpdate={handleDrawerClose} />
            ) : workflow.editing === 'complete_quiz' || workflow.selectedStep?.quiz ? (
              <CompleteQuiz onUpdate={handleDrawerClose} />
            ) : workflow.editing === 'complete_topic' || workflow?.selectedStep?.topic ? (
              <CompleteTopic onUpdate={handleDrawerClose} />
            ) : workflow.editing === 'award_certification' ? (
              <AwardCertification onUpdate={handleDrawerClose} />
            ) : (
              workflow.editing === 'award_badge' && <AwardBadge onUpdate={handleDrawerClose} />
            )}
          </div>
        ) : (
          <>
            {path && (
              <>
                <div style={{ paddingLeft: 12, paddingRight: 12 }}>
                  <Text text="Content" variant="titleEmphasized" style={{ color: color.shade70 }} />
                </div>
                <List sx={{ padding: '12px' }}>
                  {content.map((condition) => (
                    <ListItem
                      onClick={condition.onClick}
                      sx={LIST_ITEM}
                      key={condition.label}
                      disablePadding
                    >
                      <ListItemButton sx={LI_BTN}>
                        <ListItemIcon onClick={condition.onClick} sx={{ minWidth: '36px' }}>
                          {condition.icon}
                        </ListItemIcon>
                        <Text text={condition.label} variant="body2Medium" />
                      </ListItemButton>
                    </ListItem>
                  ))}
                </List>
                <div style={{ paddingLeft: 12, paddingRight: 12 }}>
                  <Text text="Actions" variant="titleEmphasized" style={{ color: color.shade70 }} />
                </div>
              </>
            )}
            <List sx={{ padding: '12px' }}>
              {actions.map((action, index) => (
                <ActionTooltip
                  placement="left"
                  title={
                    action.disabled ? action.disabledTooltip : action.tooltip ? action.tooltip : ''
                  }
                >
                  <ListItem
                    onClick={action.disabled ? () => {} : action.onClick}
                    sx={LIST_ITEM}
                    key={action.label}
                    disablePadding
                  >
                    <ListItemButton sx={LI_BTN} disabled={action.disabled}>
                      <ListItemIcon onClick={action.onClick} sx={{ minWidth: '36px' }}>
                        {action.icon}
                      </ListItemIcon>
                      <Text text={action.label} variant="body2Medium" />
                    </ListItemButton>
                  </ListItem>
                </ActionTooltip>
              ))}
            </List>
            {path && (
              <>
                <div style={{ paddingLeft: 12, paddingRight: 12 }}>
                  <Text text="Logic" variant="titleEmphasized" style={{ color: color.shade70 }} />
                </div>
                <List sx={{ padding: '12px' }}>
                  {logic.map((item) => (
                    <ActionTooltip
                      placement="left"
                      title={item.disabled ? item.disabledTooltip : ''}
                    >
                      <ListItem
                        onClick={item.onClick}
                        sx={LIST_ITEM}
                        key={item.label}
                        disablePadding
                      >
                        <ListItemButton sx={LI_BTN} disabled={item.disabled}>
                          <ListItemIcon onClick={item.onClick} sx={{ minWidth: '36px' }}>
                            {item.icon}
                          </ListItemIcon>
                          <Text text={item.label} variant="body2Medium" />
                        </ListItemButton>
                      </ListItem>
                    </ActionTooltip>
                  ))}
                </List>
              </>
            )}
          </>
        )}
      </Drawer>
    </>
  )
})

const LIST_ITEM = {
  height: '42px',
  marginBottom: '12px',
  paddingTop: '8px',
  paddingBottom: '8px',
  background: color.white,
  border: `1px solid rgba(0,0,0,0.04)`,
  borderRadius: '6px',
}
const SUBTITLE: React.CSSProperties = {
  justifyContent: 'center',
  flexDirection: 'row',
  display: 'flex',
  marginBottom: 12,
}
const TITLE: React.CSSProperties = {
  justifyContent: 'center',
  flexDirection: 'row',
  display: 'flex',
  flex: 1,
}
const ICON: React.CSSProperties = { fontSize: 18, marginRight: 8, color: color.shade70 }
const LI_BTN = {
  height: '42px',
  borderRadius: '6px',
  border: `1px solid rgba(0,0,0,0.04)`,
}
