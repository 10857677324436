export const getBooleanOperatorsValue = (filter: any) => {
  switch (filter) {
    case 'eq':
      return 'is'
  }
}

export const getDateOperator = (filter: any, col?: any) => {
  if (filter === 'eq' && col.filter.value === null) {
    return 'isEmpty'
  }
  switch (filter) {
    case 'eq':
      return 'is'
    case 'ne':
      return 'not'
    case 'gt':
      return 'after'
    case 'gte':
      return 'onOrAfter'
    case 'lt':
      return 'before'
    case 'lte':
      return 'onOrBefore'
    default:
      return filter
  }
}

export const getStringOperatorValue = (filter: any) => {
  switch (filter) {
    case 'like':
      return 'contains'
    case 'eq':
      return 'equals'
    default:
      return filter
  }
}

export const getCustomOperatorValue = (filter: any) => {
  switch (filter) {
    case 'eq':
      return 'equals'
    case 'ne':
      return 'does not equal'
    default:
      return filter
  }
}

export const getNumericOperators = (filter: any) => {
  switch (filter) {
    case 'eq':
      return '='
    case 'ne':
      return '!='
    case 'gt':
      return '>'
    case 'lt':
      return '<'
    case 'gte':
      return '>='
    case 'lte':
      return '<='
    default:
      return filter
  }
}

export const getOperatorLabel = (label: string) => {
  switch (label) {
    case '>':
      return 'greater than'
    case '<':
      return 'less than'
    case '>=':
      return 'greater than or equal to'
    case '<=':
      return 'less than or equal to'
    case '=':
      return 'equals'
    case '!=':
      return 'does not equal'
    case 'is':
      return 'is'
    case 'like':
      return 'contains'
    case 'not_like':
      return 'does not contain'
    case 'eq':
      return 'equals'
    case 'gte':
      return 'greater than or equal to'
    case 'gt':
      return 'greater than'
    case 'lt':
      return 'less than'
    case 'lte':
      return 'less than or equal to'
    case 'ne':
      return 'does not equal'
    default:
      return label
  }
}

export const getDateOperatorLabel = (label: string) => {
  switch (label) {
    case 'isEmpty':
      return 'never'
    case 'eq':
      return 'equals'
    case 'ne':
      return 'does not equal'
    case 'gt':
      return 'greater than'
    case 'lt':
      return 'less than'
    case 'gte':
      return 'greater than or equal to'
    case 'lte':
      return 'less than or equal to'
    case 'after':
      return 'greater than'
    case 'before':
      return 'less than'
    case 'onOrAfter':
      return 'greater than or equal to'
    case 'onOrBefore':
      return 'less than or equal to'
    case 'is':
      return 'equals'
    case 'not':
      return 'does not equal'
    default:
      return label
  }
}

export const getFilterOperator = (verb, column) => {
  switch (column.type) {
    case 'date':
      return getDateOperator(verb, column)
    case 'string' || 'array':
      return getStringOperatorValue(verb)
    case 'boolean':
      return getBooleanOperatorsValue(verb)
    default:
      if (column.headerName === 'Name') {
        return getStringOperatorValue(verb)
      }
      return getNumericOperators(verb)
  }
}
