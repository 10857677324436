import React, { useState } from 'react'
import { useParams, useHistory } from 'react-router-dom'
import { observer } from 'mobx-react-lite'
import { Create } from '@mui/icons-material'

import { Header } from '@trivie/ui-web/components/header'
import { NavigationTabs } from '@trivie/ui-web/components/navigation-tabs'
import { EditUser } from '@trivie/ui-web/components/edit-user'
import { useStores } from '@trivie/core'

import { usersTabs } from './tabs'
import { Dialog } from '@trivie/ui-web/components/dialogs/dialog'

export const UsersDetailsScreen: React.FunctionComponent = observer(() => {
  const { manageUserStore } = useStores()
  const { selectedUserId, getSelectedUserProfile, selectedUserProfile, deleteUsers, permissions } =
    manageUserStore
  const [showEdit, setShowEdit] = useState(false)
  const [showUpdateDialog, setShowUpdateDialog] = useState(false)
  const [showConfirmDelete, setShowConfirmDelete] = useState(false)

  // @ts-ignore
  const { id } = useParams()
  const history = useHistory()

  const handleDelete = async () => {
    if (!selectedUserId) {
      return
    }
    await deleteUsers([String(selectedUserId)], () => {
      history.goBack()
    })
  }

  const primary = {
    disabled: !permissions?.permissions.some((p) => p.code === 'can_edit_user'),
    startIcon: <Create />,
    onClick: () => setShowEdit(true),
    text: 'Edit User',
  }

  return (
    <>
      <Header
        primaryAction={primary}
        title={`${selectedUserProfile?.first_name ?? ''} ${selectedUserProfile?.last_name ?? ''}`}
      />
      <NavigationTabs tabList={usersTabs} id={id} />
      {showEdit && (
        <EditUser
          open={showEdit}
          onClose={() => setShowEdit(false)}
          onUpdate={() => {
            getSelectedUserProfile()
            setShowUpdateDialog(true)
          }}
          onDelete={() => setShowConfirmDelete(true)}
        />
      )}
      <Dialog
        open={showUpdateDialog}
        title="User Updated"
        dialogText="This user's information has been updated."
        onConfirm={() => {
          setShowEdit(false)
          setShowUpdateDialog(false)
        }}
        confirmBtnLabel="Close"
        maxWidth="xs"
      />
      <Dialog
        danger
        title={`Delete ${selectedUserProfile?.first_name} ${selectedUserProfile?.last_name}`}
        maxWidth="xs"
        dialogText="Are you sure you want to delete this user?"
        onConfirm={handleDelete}
        onClose={() => {
          setShowConfirmDelete(false)
        }}
        onCancel={() => {
          setShowConfirmDelete(false)
        }}
        open={showConfirmDelete}
      />
    </>
  )
})
