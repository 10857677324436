import React, { useState } from 'react'
import { observer } from 'mobx-react-lite'
import { Instance } from 'mobx-state-tree'
import { InviteUserForm } from '@trivie/core/src/models/InviteUser'
import { Text } from '../../text'
import { TextField } from '../../text-field'
import { color } from '../../../theme'

interface UserDetailsProps {
  form: Instance<typeof InviteUserForm>
  admin?: boolean
}

export const UserDetails = observer((props: UserDetailsProps) => {
  const { form } = props

  const [touched, setTouched] = useState({
    first_name: false,
    last_name: false,
    email: false,
    phone_number: false,
    employee_id: false,
  })

  return (
    <div style={CONTAINER}>
      <Text style={{ marginBottom: 12 }} variant="body1" text="First Name" />
      <TextField
        helperText={touched.first_name && form.errors.first_name?.message}
        error={touched.first_name && !!form.errors.first_name}
        variant="outlined"
        placeholder="First Name"
        style={{ marginBottom: 25 }}
        inputStyle={{ height: 41 }}
        onChange={(e) => {
          setTouched({ ...touched, first_name: true })
          form.set('first_name', e.target.value)
        }}
        onBlur={() => setTouched({ ...touched, first_name: true })}
        value={form.first_name}
      />
      <Text style={{ marginBottom: 12 }} variant="body1" text="Last Name" />
      <TextField
        error={touched.last_name && !!form.errors.last_name}
        helperText={touched.last_name && form.errors.last_name?.message}
        variant="outlined"
        placeholder="Last Name"
        style={{ marginBottom: 25 }}
        inputStyle={{ height: 41 }}
        onChange={(e) => {
          setTouched({ ...touched, last_name: true })
          form.set('last_name', e.target.value)
        }}
        onBlur={() => setTouched({ ...touched, last_name: true })}
        value={form.last_name}
      />
      <Text style={{ marginBottom: 12 }} variant="body1" text="Email" />
      <TextField
        variant="outlined"
        placeholder="Email"
        error={touched.email && !!form.errors.email}
        helperText={form.errors.email?.message}
        style={{ marginBottom: 25 }}
        inputStyle={{ height: 41 }}
        onChange={(e) => {
          setTouched({ ...touched, email: true })
          form.set('email', e.target.value)
        }}
        onBlur={() => setTouched({ ...touched, email: true })}
        value={form.email}
      />
      {form.missingRequiredAdmin && (
        <Text
          style={{ color: color.red, position: 'relative', top: form.errors.email ? -24 : -16 }}
          variant="body2"
          text="Email is required when inviting a new administrator."
        />
      )}
      {form.missingRequired && (
        <Text
          style={{ color: color.red, position: 'relative', top: form.errors.email ? -24 : -16 }}
          variant="body2"
          text="Please enter an email or phone number before proceeding."
        />
      )}
      <Text style={{ marginBottom: 12 }} variant="body1" text="Phone Number" />
      <TextField
        helperText={form.errors.phone_number?.message}
        variant="outlined"
        placeholder="123-123-1234"
        style={{ marginBottom: 25 }}
        inputStyle={{ height: 41 }}
        error={touched.phone_number && !!form.phone_number}
        onChange={(e) => {
          setTouched({ ...touched, phone_number: true })
          form.set('phone_number', e.target.value)
        }}
        onBlur={() => setTouched({ ...touched, phone_number: true })}
        value={form.phone_number}
      />
      {form.missingRequired && (
        <Text
          style={{ color: color.red, position: 'relative', top: form.errors.email ? -24 : -16 }}
          variant="body2"
          text="Please enter an email or phone number before proceeding."
        />
      )}
      <Text style={{ marginBottom: 12 }} variant="body1" text="Employee ID" />
      <TextField
        variant="outlined"
        placeholder="Employee ID"
        style={{ marginBottom: 25 }}
        inputStyle={{ height: 41 }}
        onChange={(e) => {
          setTouched({ ...touched, employee_id: true })
          form.set('employee_id', e.target.value)
        }}
        onBlur={() => setTouched({ ...touched, employee_id: true })}
        value={form.employee_id}
      />
    </div>
  )
})

const CONTAINER: React.CSSProperties = { flex: 1, display: 'flex', flexDirection: 'column' }
