import React from 'react'
import { color } from '../../theme'

export interface VerticalDividerProps {
  short?: boolean
  style?: React.CSSProperties
  lineStyle?: React.CSSProperties
}

const BASE: React.CSSProperties = {
  width: 4,
  backgroundColor: color.shade10,
  borderRadius: 20,
}

const SHORT: React.CSSProperties = {
  ...BASE,
  height: 8,
}

const TALL: React.CSSProperties = {
  ...BASE,
  height: 24,
}

export function VerticalDivider(props: VerticalDividerProps) {
  const { style, lineStyle, short } = props
  const defaultStyle = short ? SHORT : TALL

  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        width: 40,
        paddingTop: 8,
        paddingBottom: 8,
        ...style,
      }}
    >
      <div style={{ ...defaultStyle, ...lineStyle }} />
    </div>
  )
}
