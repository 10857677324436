import React, { useEffect, useState } from 'react'
import { observer } from 'mobx-react-lite'
import { TextField, InputAdornment, Paper, Stack } from '@mui/material'
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete'
import { Add, LocalOffer } from '@mui/icons-material'

import { useStores } from '@trivie/core'
import { TagApi } from '@trivie/core/src/services/api-objects/TagApi'

import { Dialog } from '../dialogs/dialog'
import { Text } from '../text'
import { color } from '../../theme/color'
import { Switch } from '../switch/switch'
import { Divider } from '../divider'

const filter = createFilterOptions<any>()

export interface CreateTagProps {
  open: boolean
  onClose: (payload?: any) => void
  setShowConfirm?: (val: boolean) => void
  setConfirmMessage?: (val: JSX.Element) => void
  onCreate: any
  selectedTag?: any
}

export const CreateTag = observer((props: CreateTagProps) => {
  const { tagStore } = useStores()
  const { createTag, editTag } = tagStore
  const { open, onClose, onCreate, selectedTag, setShowConfirm, setConfirmMessage } = props

  const [tags, setTags] = useState([])
  const [categories, setCategories] = useState<any[]>([])

  const [selectedCategory, setSelectedCategory] = useState(
    selectedTag ? { title: selectedTag?.tag_key } : null,
  )
  const [tagValue, setTagValue] = useState(selectedTag?.tag_value || '')
  const [isSticky, setIsSticky] = useState(selectedTag?.is_sticky)
  const [createGroup, setCreateGroup] = useState(false)
  const [error, setError] = useState<any>('')

  useEffect(() => {
    TagApi.getAllTags('tags', false, [], 'list_tags').then((resp: any) => {
      if (resp.ok) {
        const t = resp.data.rows.map((tag) => {
          const key = tag.columns.find((column) => column.field === 'tag_key')
          const value = tag.columns.find((column) => column.field === 'tag_value')
          const is_sticky = tag.columns.find((column) => column.field === 'tag_is_sticky')
          return { id: tag.id, key: key.value, value: value.value, is_sticky: is_sticky?.value }
        })
        setTags(t)
      }
    })
  }, [])

  useEffect(() => {
    const t = {}
    tags.map((tag: any) => {
      t[tag.key] = []
    })
    tags.map((tag: any) => {
      t[tag.key].push({ ...tag })
    })
    const categories = Object.keys(t || []).map((k) => ({ title: k }))
    setCategories(categories)
  }, [tags])

  const handleClose = () => {
    onClose()
  }

  const onSubmit = () => {
    if (selectedTag) {
      editTag(selectedTag.id, {
        is_locked: false,
        is_sticky: isSticky,
        key: selectedCategory?.title,
        value: tagValue,
      }).then((resp: any) => {
        if (resp?.error) {
          setError(resp?.error?.message)
        } else {
          const msg = (
            <div style={CONFIRM_TEXT}>
              Tag “{selectedTag.tag_key} &gt; {selectedTag.tag_value}” has been updated to “
              {selectedCategory?.title} &gt; {tagValue}”
              {isSticky && (
                <p>
                  Tag “{selectedCategory?.title} &gt; {tagValue}” has been made sticky.
                </p>
              )}
            </div>
          )
          setConfirmMessage && setConfirmMessage(msg)
          setShowConfirm && setShowConfirm(true)
          onCreate()
          onClose()
        }
      })
    } else {
      createTag({
        is_locked: false,
        is_sticky: isSticky,
        key: selectedCategory?.title,
        value: tagValue,
        create_group: createGroup,
      }).then(() => {
        onCreate({
          is_locked: false,
          is_sticky: isSticky,
          key: selectedCategory?.title,
          value: tagValue,
          create_group: createGroup,
        })
      })
      onClose()
    }
  }
  return (
    <Dialog
      maxWidth="sm"
      disableConfirm={!selectedCategory || !tagValue}
      onConfirm={onSubmit}
      confirmBtnLabel={`${selectedTag ? 'Save' : 'Create Tag'}`}
      onCancel={onClose}
      title={`${selectedTag ? 'Edit Tag' : 'Create Tag'}`}
      onClose={handleClose}
      open={open}
    >
      <Stack useFlexGap gap={4}>
        <Text
          text="Tags can be used to sort users into categories by attributes. These tags can be combined using rules into groups to send users the content they need."
          variant="body1"
        />
        <Autocomplete
          sx={{ background: color.white }}
          PaperComponent={CustomPaper}
          onChange={(e: any, newValue: any) => {
            if (typeof newValue === 'string') {
              setSelectedCategory({
                title: newValue,
              })
            } else if (newValue && newValue.inputValue) {
              // Create a new value from the user input
              setSelectedCategory({
                title: newValue.inputValue,
              })
            } else {
              setSelectedCategory(newValue)
            }
          }}
          filterOptions={(options, params) => {
            const filtered = filter(options, params)
            const { inputValue } = params
            // Suggest the creation of a new value
            const isExisting = options.some((option) => inputValue === option.title)
            if (inputValue !== '' && !isExisting) {
              filtered.push({
                inputValue,
                title: `Add new "${inputValue}" Category`,
              })
            }
            return filtered
          }}
          getOptionLabel={(option) => {
            // Value selected with enter, right from the input
            if (typeof option === 'string') {
              return option
            }
            // Add "xxx" option created dynamically
            if (option.inputValue) {
              return option.inputValue
            }
            // Regular option
            return option.title
          }}
          selectOnFocus
          clearOnBlur
          value={selectedCategory}
          options={categories}
          freeSolo
          renderOption={(props, option) => {
            if (option.title.includes('Add new')) {
              return (
                <>
                  {props['data-option-index'] !== 0 && <Divider />}
                  <li {...props}>
                    <Stack alignItems="center" direction="row" useFlexGap gap={1}>
                      <Add />
                      {option.title}
                    </Stack>
                  </li>
                </>
              )
            }
            return <li {...props}>{option.title}</li>
          }}
          renderInput={(params) => {
            return (
              <TextField
                {...params}
                label="Select Category"
                placeholder="No Category Selected"
                variant="outlined"
              />
            )
          }}
        />
        <TextField
          fullWidth
          label="Tag Value"
          variant="outlined"
          placeholder="Type a Tag"
          value={tagValue}
          onChange={(e) => setTagValue(e.target.value)}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <LocalOffer htmlColor={color.shade30} />
              </InputAdornment>
            ),
          }}
        />
        <Stack
          alignItems="center"
          useFlexGap
          gap={4}
          direction="row"
          justifyContent="space-between"
        >
          <Stack>
            <Text mb="4px" text="Sticky Tag" variant="body1Emphasized" />
            <Text
              color="text.secondary"
              text="When enabled, users with this tag will keep it even if a user import modifies a user with this tag."
              variant="body1"
            />
          </Stack>
          <Switch
            value={isSticky}
            defaultChecked={isSticky}
            color="success"
            onChange={(e) => setIsSticky(e.target.checked)}
          />
        </Stack>
        {!selectedTag && (
          <Stack
            alignItems="center"
            useFlexGap
            gap={4}
            direction="row"
            justifyContent="space-between"
          >
            <Stack>
              <Text mb="4px" text="Create New Group" variant="body1Emphasized" />
              <Text
                color="text.secondary"
                text="When enabled, this will create a new group made up of this tag."
                variant="body1"
              />
            </Stack>
            <Switch
              color="success"
              onChange={(e) => setCreateGroup(e.target.checked)}
              value={createGroup}
            />
          </Stack>
        )}
        {error && <Text text={error} variant="body2" color={color.red} />}
      </Stack>
    </Dialog>
  )
})

const CONFIRM_TEXT: React.CSSProperties = {
  fontFamily: 'Inter',
  fontStyle: 'normal',
  fontWeight: 500,
  fontSize: 14,
  textAlign: 'center',
}

const CustomPaper: React.FunctionComponent = ({ children }: any) => {
  return (
    <Paper
      sx={{
        borderRadius: '12px',
        borderTopLeftRadius: '0px',
        borderTopRightRadius: '0px',
        boxShadow: `0px 5px 20px rgba(0,0,0,.25)`,
        '& > .MuiAutocomplete-noOptions': {
          padding: 0,
        },
      }}
    >
      {children}
    </Paper>
  )
}
