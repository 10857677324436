import { types } from 'mobx-state-tree'

export const SurveyUser = types.model('SurveyUser').props({
  display_name: types.optional(types.string, ''),
  email: types.optional(types.maybeNull(types.string), ''),
  photo_url: types.optional(types.string, ''),
  user_id: types.number,
})

export const SurveyGroup = types.model('SurveyGroup').props({
  group_id: types.number,
  label: types.optional(types.string, ''),
  number_users: types.number,
})
