import React, { ReactNode } from 'react'
import { Circle, Delete, Download, Edit, PersonAdd } from '@mui/icons-material'
import { Chip, IconButton, ListItemIcon, Menu, MenuItem } from '@mui/material'
import Card from '@mui/material/Card'
import CardActions from '@mui/material/CardActions'
import CardContent from '@mui/material/CardContent'
import CardMedia from '@mui/material/CardMedia'
import { Text } from '@trivie/ui-web/components/text'
import { color } from '@trivie/ui-web/theme'
import { observer } from 'mobx-react-lite'
import MoreVertIcon from '@mui/icons-material/MoreVert'
import defaultCover from './default-topic-image.png'
import { animated, useSpring } from '@react-spring/web'
import { MimeTypeIcon } from '@trivie/ui-web/components/mime-type-icon'

interface QuizCardProps {
  image?: string
  mime_type?: string
  imageElement?: any
  hideChip?: boolean
  title: string
  subtitle?: string
  questionCount: number
  lastUpdated: string
  onClick?: () => void
  status: string
  onEdit: () => void
  onExport: () => void
  onShare: () => void
  onDelete: () => void
  style?: React.CSSProperties
  imageStyle?: React.CSSProperties
  wordCount?: number
  hideMenu?: boolean
  fullWidth?: boolean
  topic?: any
  disableHover?: boolean
  path?: any
}

export const NewQuizCard = (props: any) => {
  const { createQuiz, style, ...rest } = props
  return (
    <Card
      style={style}
      sx={{ ...CONTAINER, display: 'flex', flexDirection: 'column', borderColor: color.shade30 }}
      {...rest}
    >
      <div style={NEW_QUIZ} onClick={() => createQuiz(true)}>
        <svg
          style={{ alignSelf: 'center', marginBottom: 24 }}
          width="48"
          height="48"
          viewBox="0 0 48 48"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M40.6 12.85L38.9 9.10001L35.15 7.40001L38.9 5.70001L40.6 1.95001L42.3 5.70001L46.05 7.40001L42.3 9.10001L40.6 12.85ZM16.5 12.85L14.8 9.10001L11.05 7.40001L14.8 5.70001L16.5 1.95001L18.2 5.70001L21.95 7.40001L18.2 9.10001L16.5 12.85ZM40.6 36.95L38.9 33.2L35.15 31.5L38.9 29.8L40.6 26.05L42.3 29.8L46.05 31.5L42.3 33.2L40.6 36.95ZM9.35003 43.4L4.60003 38.65C4.23336 38.2833 4.03336 37.8583 4.00003 37.375C3.9667 36.8917 4.1667 36.4333 4.60003 36L27.1 13.5C27.5 13.1 27.9834 12.9 28.55 12.9C29.1167 12.9 29.6 13.1 30 13.5L34.5 18C34.9 18.4 35.1 18.8833 35.1 19.45C35.1 20.0167 34.9 20.5 34.5 20.9L12 43.4C11.6 43.8 11.1584 44 10.675 44C10.1917 44 9.75003 43.8 9.35003 43.4ZM10.5 40.55L26.15 24.9L23.05 21.8L7.40003 37.45L10.5 40.55Z"
            fill="url(#paint0_linear_1530_54712)"
          />
          <defs>
            <linearGradient
              id="paint0_linear_1530_54712"
              x1="10.5628"
              y1="42.6707"
              x2="53.7193"
              y2="13.1711"
              gradientUnits="userSpaceOnUse"
            >
              <stop stopColor="#FF00FF" />
              <stop offset="0.46" stopColor="#9116F9" />
              <stop offset="1" stopColor="#3199FF" />
            </linearGradient>
          </defs>
        </svg>
        <Text text="Generate New Quiz" style={NEW_QUIZ_TITLE} variant="headline" />
      </div>

      <div style={CREATE_NEW}>
        <Text
          onClick={() => createQuiz(false)}
          text="Create Blank Quiz"
          variant="labelEmphasized"
          style={{ color: color.blue, cursor: 'pointer', alignSelf: 'center' }}
        />
      </div>
    </Card>
  )
}

export const QuizCard = observer((props: QuizCardProps) => {
  const {
    onEdit,
    onExport,
    onShare,
    onDelete,
    status,
    image,
    title,
    subtitle,
    questionCount,
    onClick,
    lastUpdated,
    style,
    hideChip = false,
    imageElement = 'img',
    imageStyle,
    mime_type,
    wordCount,
    fullWidth,
    hideMenu,
    disableHover,
    topic,
    path,
    ...rest
  } = props

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
  const menuOpen = Boolean(anchorEl)
  const handleOpenMenu = (event: React.MouseEvent<HTMLElement>) => setAnchorEl(event.currentTarget)
  const handleCloseMenu = () => setAnchorEl(null)

  const statusColor = `${status === 'Live' ? color.green : color.yellow} !important`

  const trans: any = (x, y, s) => `scale(${s})`
  const [spr, set] = useSpring(() => ({
    xys: [0, 0, 1],
    config: { mass: 4, tension: 350, friction: 60 },
  }))
  const calc: any = (x, y) => [
    -(y - window.innerHeight / 2) / 20,
    (x - window.innerWidth / 2) / 20,
    disableHover ? 1.0 : 1.03,
  ]

  const IMAGE_STYLE = { ...IMAGE, ...imageStyle }

  const imageUrl = imageElement === 'img' ? image ?? defaultCover : ''

  return (
    <animated.div
      onMouseEnter={({ clientX: x, clientY: y }: any) => set({ xys: calc(x, y) })}
      onMouseLeave={() => set({ xys: [0, 0, 1] })}
      style={{
        width: fullWidth ? '100%' : 262,
        height: 256,
        // @ts-ignore
        transform: spr.xys.interpolate(trans),
      }}
      {...rest}
    >
      <Card style={style} sx={{ ...CONTAINER, width: fullWidth ? '100%' : '262px' }}>
        {image && <div onClick={onClick} style={OVERLAY} />}
        {!hideChip && (
          <Chip
            sx={STATUS}
            icon={<Circle sx={{ ...INDICATOR, color: statusColor }} />}
            label={status}
          />
        )}
        <Menu
          open={menuOpen}
          onClose={handleCloseMenu}
          anchorEl={anchorEl}
          sx={MENU}
          anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
          transformOrigin={{ vertical: -40, horizontal: 40 }}
        >
          <MenuItem
            sx={MENU_ITEM}
            onClick={() => {
              handleCloseMenu()
              onEdit()
            }}
          >
            <ListItemIcon>
              <Edit htmlColor={color.shade70} fontSize="small" />
            </ListItemIcon>
            <Text style={SHADE_70} variant="body2Medium" text="Edit" />
          </MenuItem>
          <MenuItem
            sx={MENU_ITEM}
            onClick={() => {
              handleCloseMenu()
              onExport()
            }}
          >
            <ListItemIcon>
              <Download htmlColor={color.shade70} fontSize="small" />
            </ListItemIcon>
            <Text style={SHADE_70} variant="body2Medium" text="Export" />
          </MenuItem>
          <MenuItem
            sx={MENU_ITEM}
            onClick={() => {
              handleCloseMenu()
              onShare()
            }}
          >
            <ListItemIcon>
              <PersonAdd htmlColor={color.shade70} fontSize="small" />
            </ListItemIcon>
            <Text style={SHADE_70} variant="body2Medium" text="Share" />
          </MenuItem>
          <MenuItem
            sx={MENU_ITEM}
            onClick={() => {
              handleCloseMenu()
              onDelete()
            }}
          >
            <ListItemIcon>
              <Delete htmlColor={color.shade70} fontSize="small" />
            </ListItemIcon>
            <Text style={SHADE_70} variant="body2Medium" text="Delete" />
          </MenuItem>
        </Menu>
        {!hideChip && !hideMenu && (
          <IconButton
            onClick={(e) => {
              handleOpenMenu(e)
              set({ xys: [0, 0, 1] })
            }}
            sx={MORE_BTN}
          >
            <MoreVertIcon htmlColor="#fff" />
          </IconButton>
        )}
        {mime_type && (
          <div
            onClick={onClick}
            style={{ position: 'absolute', left: 110, top: 20, cursor: 'pointer' }}
          >
            <MimeTypeIcon mimeType={mime_type} filename={mime_type} width={40} height={40} white />
          </div>
        )}
        <CardMedia
          onClick={onClick}
          style={IMAGE_STYLE}
          component={imageElement}
          image={imageUrl}
        />

        <div style={{ cursor: 'pointer' }} onClick={onClick}>
          <CardContent sx={TITLE}>
            <div>
              <Text component="div" style={TITLE_TXT} text={title} variant="titleEmphasized" />
              <Text
                component="div"
                noWrap
                style={SUB_TITLE_TXT}
                text={subtitle}
                variant="body1Medium"
              />
            </div>
          </CardContent>
          <CardActions sx={FOOTER}>
            <div style={FOOTER_CONTENT}>
              <Text
                style={SHADE_70}
                variant="body1Medium"
                text={
                  path
                    ? `${path?.workflow_json?.stepCount} Step${path?.workflow_json?.stepCount === 1 ? '' : 's'}`
                    : topic
                      ? `${questionCount} Quiz${questionCount === 1 ? '' : 'zes'}`
                      : mime_type
                        ? `${wordCount} word${wordCount === 1 ? '' : 's'}`
                        : `${questionCount} Question${questionCount === 1 ? '' : 's'}`
                }
              />
              {!path ? (
                <Text style={SHADE_70} variant="body2" text={`Updated ${lastUpdated}`} />
              ) : null}
            </div>
          </CardActions>
        </div>
      </Card>
    </animated.div>
  )
})

const CONTAINER: React.CSSProperties = {
  width: 262,
  boxShadow: 'none',
  borderRadius: '12px',
  border: `1px solid ${color.shade10}`,
  height: 256,
  position: 'relative',
}
const IMAGE: React.CSSProperties = { cursor: 'pointer', height: 120 }
const STATUS = {
  '.MuiChip-labelMedium': { paddingRight: '8px !important' },
  fontSize: 10,
  fontWeight: 600,
  backgroundColor: '#212121',
  position: 'absolute',
  top: 12,
  left: 14,
  height: 24,
  zIndex: 999,
  border: `1px solid rgba(255, 255, 255, 0.50)`,
  color: '#fff',
}
const INDICATOR: React.CSSProperties = { marginRight: '-8px !important', fontSize: 8 }
const MORE_BTN: React.CSSProperties = {
  position: 'absolute',
  top: 12,
  right: 16,
  padding: 0,
  zIndex: 9999,
}
const TITLE: React.CSSProperties = {
  paddingTop: '24px',
  paddingBottom: '24px',
  paddingRight: '20px',
  paddingLeft: '20px',
}
const FOOTER: React.CSSProperties = {
  position: 'absolute',
  bottom: 0,
  paddingBottom: '24px',
  paddingRight: '20px',
  paddingLeft: '20px',
  width: '100%',
}
const SHADE_70: React.CSSProperties = { color: color.shade70 }
const FOOTER_CONTENT: React.CSSProperties = { display: 'flex', flexDirection: 'column' }
const MENU_ITEM = { height: 32, '&:hover': { backgroundColor: '#F2F7FF' } }
const MENU = {
  '& .MuiPaper-root': {
    borderRadius: '6px !important',
    border: `1px solid #EFF0F6`,
    padding: 0,
    boxShadow: 'none',
    width: 134,
  },
}
const TITLE_TXT: React.CSSProperties = {
  textAlign: 'left',
  overflowWrap: 'break-word',
  display: '-webkit-box',
  WebkitLineClamp: 2,
  WebkitBoxOrient: 'vertical',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  marginBottom: 4,
}

const SUB_TITLE_TXT: React.CSSProperties = {
  overflow: 'hidden',
  textOverflow: 'ellipsis',
}

const NEW_QUIZ: React.CSSProperties = {
  display: 'flex',
  flex: 1,
  alignItems: 'center',
  flexDirection: 'column',
  justifyContent: 'center',
  cursor: 'pointer',
}
const NEW_QUIZ_TITLE: React.CSSProperties = {
  fontSize: 20,
  fontWeight: 700,
  backgroundImage: `linear-gradient(40deg, #FF00FF -17.26%, #9116F9 49.66%, #3199FF 99.55%)`,
  backgroundClip: 'text',
  WebkitBackgroundClip: 'text',
  WebkitTextFillColor: 'transparent',
}
const CREATE_NEW: React.CSSProperties = {
  borderTop: `1px solid ${color.shade30}`,
  alignSelf: 'center',
  display: 'flex',
  flex: 0.5,
  flexDirection: 'column',
  justifyContent: 'center',
  width: '100%',
}
const OVERLAY: React.CSSProperties = {
  opacity: 0.8,
  width: '100%',
  position: 'absolute',
  zIndex: 998,
  height: 79,
  background: 'linear-gradient(180deg, #000 0%, rgba(0, 0, 0, 0.00) 100%)',
  borderTopLeftRadius: 12,
  borderTopRightRadius: 12,
  cursor: 'pointer',
}
