import React from 'react'
import ReactEChartsCore from 'echarts-for-react/lib/core'
import * as echarts from 'echarts/core'
import { TooltipComponent, LegendComponent } from 'echarts/components'
import { PieChart } from 'echarts/charts'
import { LabelLayout } from 'echarts/features'
import { CanvasRenderer } from 'echarts/renderers'
import { useEffect, useRef } from 'react'
import { color } from '../../theme'

// Register the required components
echarts.use([TooltipComponent, LegendComponent, PieChart, CanvasRenderer, LabelLayout])

interface CompletionChartProps {
  data: any[]
  height?: number | 'auto' | null
  width?: number | 'auto' | null
}

export const CompletionChart = (props: CompletionChartProps) => {
  const { data } = props

  const echartRef = useRef() as any

  const DEFAULT_OPTION = {
    tooltip: {
      trigger: 'item',
      formatter: function (params) {
        return `${params.name}<br />
                <span style="color: #000;">${params.data.users} User${
                  params.data.users === 1 ? '' : 's'
                } (${params.value}%)</span>`
      },
      borderColor: '#EFF0F6',
    },
    legend: {
      orient: 'vertical',
      right: '0%',
      top: '40%',
      icon: 'square',
      fontSize: 14,
      formatter: (name) => {
        const series = echartRef.current.getEchartsInstance().getOption().series[0]
        const value = series.data.filter((row) => row.name === name)[0].value
        return `${name} (${value})%`
      },
    },
    series: [
      {
        name: '',
        type: 'pie',
        radius: ['50%', '70%'],
        avoidLabelOverlap: false,
        label: {
          show: true,
          position: 'center',
          formatter: function (params) {
            return [`{a|${params.value}%}`, `{b|${params.name}}`].join('\n')
          },
          fontFamily: 'Inter',
          rich: {
            a: {
              lineHeight: 30,
              fontWeight: 700,
              fontSize: 24,
            },
            b: {
              textAlign: 'center',
              color: color.shade70,
              fontWeight: 500,
              fontSize: 14,
              lineHeight: 17,
            },
          },
        },
        emphasis: {
          label: {
            show: true,
            formatter: function (params) {
              return [`{a|${params.value}%}`, `{b|${params.name}}`].join('\n')
            },
            fontFamily: 'Inter',
            rich: {
              a: {
                backgroundColor: '#fff',
                lineHeight: 30,
                fontWeight: 700,
                fontSize: 24,
                width: 80,
              },
              b: {
                backgroundColor: '#fff',
                textAlign: 'center',
                color: color.shade70,
                fontWeight: 500,
                fontSize: 14,
                lineHeight: 17,
                width: 80,
              },
            },
          },
        },
        labelLine: {
          show: false,
        },
        color: ['#1B59F8', 'rgba(27, 89, 248, 0.30)', '#e0e0e0'],
        data,
      },
    ],
  }

  useEffect(() => {
    // @ts-ignore
    const echarts_instance = echartRef.current.getEchartsInstance()
    setTimeout(() => {
      echarts_instance.setOption(DEFAULT_OPTION)
    }, 400)
    return () => echarts_instance.clear()
  }, [])

  return (
    <ReactEChartsCore
      // @ts-ignore
      ref={echartRef}
      echarts={echarts}
      style={{ left: -64, height: 360, width: 'auto' }}
      option={{}}
      notMerge={true}
      theme={'theme_name'}
      opts={{ height: 360, width: 'auto' }}
    />
  )
}
