import { QuizzesUsersTab } from './quizzes-users-tab'
import { QuizzesResourcesTab } from './quizzes-resources-tab'
import { QuizzesQuestionsTab } from './quizzes-questions-tab'
import { QuizzesOverviewTab } from './quizzes-overview-tab'
import { QuizzesGroupsTab } from './quizzes-groups-tab'
import { QuizzesAssignmentsTab } from './quizzes-assignments-tab'

export const quizzesTabs = [
  QuizzesOverviewTab,
  QuizzesQuestionsTab,
  QuizzesResourcesTab,
  QuizzesUsersTab,
  QuizzesGroupsTab,
  QuizzesAssignmentsTab,
]
