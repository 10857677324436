import React, { useState } from 'react'
import { observer } from 'mobx-react-lite'
import { Stack } from '@mui/material'
import { useRouteMatch } from 'react-router-dom'

import { DateFilter } from '@trivie/ui-web/components/date-filter'
import { Insight } from '@trivie/ui-web/components/insight'
import { Mastery } from '@trivie/ui-web/components/mastery'
import { Punchcard } from '@trivie/ui-web/components/punchcard'
import { Text } from '@trivie/ui-web/components/text'
import { GenerateReport } from '@trivie/ui-web/components/generate-report'
import { StrongestWeakest } from '@trivie/ui-web/components/strongest-weakest'
import { Divider } from '@trivie/ui-web/components/divider'
import { useStores } from '@trivie/core'
import { Panel } from '@trivie/ui-web/components/panel/panel'

const Overview: React.FunctionComponent = observer(() => {
  const { groupStore } = useStores()
  const {
    refreshOverviewData,
    groupDetail,
    knowledgeGain,
    knowledgeGap,
    accomplishments,
    hourlyActivity,
    mastery,
    setSelectedGroupId,
  } = groupStore

  const match = useRouteMatch<any>('/people/groups/:id/overview')
  // @ts-ignore
  const { params } = match

  const refresh = (start: any, end: any) => {
    setSelectedGroupId(params.id)
    refreshOverviewData(start, end)
  }

  const onDateChange = (start, end, label) => {
    setTimespan(label as any)
    refresh(start, end)
  }

  const [timespan, setTimespan] = useState('All Time')

  return (
    <>
      <Stack mb={2} direction="row" justifyContent="space-between" alignItems="center">
        <DateFilter onDateChange={onDateChange} />
        <GenerateReport filename={groupDetail?.label ?? 'group-detail'} />
      </Stack>
      <Stack useFlexGap gap={2}>
        <Panel title="How much is this group learning?">
          <Stack gap={2} direction="row" flexWrap="wrap">
            <Insight
              timespan={timespan}
              unit="%"
              data={knowledgeGain?.stats_history[2].data_points}
              title="Baseline Knowledge"
              value={
                knowledgeGain
                  ? `${
                      Number.isInteger(knowledgeGain?.starting_knowledge)
                        ? knowledgeGain?.starting_knowledge
                        : knowledgeGain?.starting_knowledge.toFixed(1) ?? 0
                    }%`
                  : '0%'
              }
            />
            <Insight
              timespan={timespan}
              unit="%"
              data={knowledgeGain?.stats_history[1].data_points}
              title="Current Knowledge"
              value={
                knowledgeGain
                  ? `${
                      Number.isInteger(knowledgeGain?.current_knowledge)
                        ? knowledgeGain?.current_knowledge
                        : knowledgeGain?.current_knowledge.toFixed(1) ?? 0
                    }%`
                  : '0%'
              }
            />
            <Insight
              timespan={timespan}
              unit="%"
              data={knowledgeGain?.stats_history[0].data_points}
              title="Knowledge Gain"
              value={
                knowledgeGain
                  ? `${
                      Number.isInteger(knowledgeGain?.knowledge_gain)
                        ? knowledgeGain?.knowledge_gain
                        : knowledgeGain?.knowledge_gain.toFixed(1) ?? 0
                    }%`
                  : '0%'
              }
            />
          </Stack>
          <Text
            color="text.secondary"
            variant="body2Medium"
            text="We measure each person’s baseline knowledge by their first answer to each question. As they answer more questions, their current knowledge rises, resulting in knowledge gain."
          />
        </Panel>
        <Stack useFlexGap gap={2} direction="row" flexWrap="wrap">
          <Panel sx={{ flex: 1 }}>
            <Insight
              timespan={timespan}
              unit=" Users"
              outlined
              title="Active Users"
              loading={!groupDetail}
              value={groupDetail?.active_users}
              data={groupDetail?.stats_history[0].data_points}
              style={{ marginRight: 20 }}
            />
          </Panel>
          <Panel sx={{ flex: 1 }}>
            <Insight
              timespan={timespan}
              unit=""
              outlined
              title="Average Mastery"
              data={groupDetail?.stats_history[2].data_points}
              value={groupDetail?.mastery.toFixed(1)}
              ofVal="/ 4"
              style={{ marginRight: 20 }}
            />
          </Panel>
          <Panel sx={{ flex: 1 }}>
            <Insight
              timespan={timespan}
              unit="%"
              outlined
              title="Correct Answer %"
              data={groupDetail?.stats_history[1].data_points}
              value={`${groupDetail?.pct_correct.toFixed(1) || ''}%`}
            />
          </Panel>
        </Stack>
        <Stack flexWrap="wrap" direction="row" useFlexGap gap={2}>
          {knowledgeGap?.weakest_topics?.length || knowledgeGap?.weakest_questions?.length ? (
            <StrongestWeakest
              type="Weakest"
              variant="medium"
              topics={knowledgeGap?.weakest_topics}
              questions={knowledgeGap?.weakest_questions}
              style={{ flex: 1 }}
            />
          ) : null}
          {knowledgeGap?.strongest_topics?.length || knowledgeGap?.strongest_questions?.length ? (
            <StrongestWeakest
              type="Strongest"
              variant="medium"
              topics={knowledgeGap?.strongest_topics}
              questions={knowledgeGap?.strongest_questions}
              style={{ flex: 1 }}
            />
          ) : null}
        </Stack>
        <Stack useFlexGap gap={2} flexWrap="wrap" direction="row">
          <Panel style={{ flex: 1 }} title="What are people in this group accomplishing?">
            <Stack
              divider={<Divider />}
              height="100%"
              justifyContent="center"
              useFlexGap
              spacing={3}
            >
              <Insight
                timespan={timespan}
                title="Questions Answered"
                unit=" Questions"
                data={accomplishments?.stats_history[0].data_points}
                value={`${accomplishments?.questions_answered ?? 0}`}
              />
              <Insight
                timespan={timespan}
                title="Quizzes Completed"
                unit=" Quizzes"
                data={accomplishments?.stats_history[1].data_points}
                value={`${accomplishments?.quizzes_completed ?? 0}`}
              />
              <Insight
                timespan={timespan}
                title="Sources Viewed"
                unit=" Sources"
                data={accomplishments?.stats_history[2].data_points}
                value={`${accomplishments?.resources_viewed ?? 0}`}
              />
            </Stack>
          </Panel>
          <Panel style={{ flex: 2 }} divider title="When are people in this group learning most?">
            <Punchcard values={hourlyActivity} />
          </Panel>
        </Stack>
        <Panel title="Mastery Distribution">
          <Stack gap={3} direction="row" flexWrap="wrap">
            <Mastery
              variant="green"
              percentage={mastery?.most}
              message={`${mastery?.most_user_count} users in this group have mastered most of their questions.`}
            />
            <Mastery
              variant="yellow"
              percentage={mastery?.some}
              message={`${mastery?.some_user_count} users in this group have mastered some of their questions.`}
            />
            <Mastery
              variant="red"
              percentage={mastery?.few}
              message={`${mastery?.few_user_count} users in this group have mastered few of their questions.`}
            />
          </Stack>
        </Panel>
      </Stack>
    </>
  )
})

export const GroupsOverviewTab = (props: any) => {
  const { id } = props
  return {
    name: 'Overview',
    path: `/people/groups/${id}/overview`,
    component: () => <Overview />,
  }
}
