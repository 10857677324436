import { ApiResponse, ApisauceInstance } from 'apisauce'
import { handleResponse } from '../handleResponse'
import { Survey } from '../../models/Survey'
import { SurveyAssignPayload } from '../../models/survey/SurveyAssignPayload'
import { SurveySchedulePayload } from '../../models/survey/SurveySchedulePayload'
import { Instance } from 'mobx-state-tree'
import { PostSurveyAssignDetailsRequestType, SurveySubmitRequestModelType } from '../..'
import { AssignSurvey, AssignedSurvey } from '../../models/AssignSurvey'
import { NewSurveyResponse } from '../../models/response-models/newSurvey'
import { GeneralSurveyPayload } from '../../models/GeneralSurveyPayload'
import { ExtendSurveyPayload } from '../../models/ExtendSurveyPayload'

export const SurveyApi = {
  apisauce: {} as ApisauceInstance,

  /**
   * List All Surveys
   */
  getAllSurveys: async (
    type: 'users' | 'topics' | 'quizzes' | 'groups' | 'tags' | 'assignments' | 'surveys',
    meta?: boolean,
    columns?: any,
    view_name?: string,
    id?: number,
    filter?: 'active' | 'scheduled' | 'completed',
  ) => {
    const payload = { view_name }
    if (columns && columns.length > 0) {
      payload['columns'] = columns
    }

    const response: ApiResponse<any> = await SurveyApi.apisauce.post(
      `/${type}/list/${filter}/?meta=${meta}&asynchronous=${false}`,
      payload,
    )

    return handleResponse(response)
  },

  /**
   * List All Surveys - deprecated
   */
  async getSurveys(filter: 'live' | 'scheduled' | 'unscheduled' | 'completed'): Promise<any> {
    const response: ApiResponse<Instance<(typeof Survey)[]>> = await SurveyApi.apisauce.get(
      `/surveys/list/${filter}/`,
    )
    return handleResponse(response)
  },

  /**
   * Get Single Survey Details
   */
  async getSurvey(id: string | number): Promise<any> {
    const response: ApiResponse<Instance<typeof Survey>> = await SurveyApi.apisauce.get(
      `/surveys/${id}/`,
    )
    return handleResponse(response)
  },

  /**
   * Get Survey Stats
   */
  async getSurveyStats(id: string | number): Promise<any> {
    const response: ApiResponse<Instance<typeof Survey>> = await SurveyApi.apisauce.get(
      `/surveys/${id}/stats/`,
    )
    return handleResponse(response)
  },

  /**
   * Get Survey Questions Stats
   */
  async getSurveyQuestionStats(id: string | number): Promise<any> {
    const response: ApiResponse<Instance<typeof Survey>> = await SurveyApi.apisauce.get(
      `/surveys/${id}/question_stats/`,
    )
    return handleResponse(response)
  },

  /**
   * Create a survey
   */
  async createSurvey(payload: Instance<typeof Survey>): Promise<any> {
    const response: ApiResponse<Instance<typeof NewSurveyResponse>> = await SurveyApi.apisauce.post(
      `/surveys/create/`,
      { ...payload, keep_order: true },
    )
    return handleResponse(response)
  },

  /**
   * End Surveys
   */
  async endSurvey(payload: Instance<typeof GeneralSurveyPayload>): Promise<any> {
    const response: any = await SurveyApi.apisauce.post(`/surveys/end_early/`, payload)
    return handleResponse(response)
  },

  /**
   * Start Surveys
   */
  async startSurvey(payload: Instance<typeof GeneralSurveyPayload>): Promise<any> {
    const response: any = await SurveyApi.apisauce.post(`/surveys/start_immediately/`, payload)
    return handleResponse(response)
  },

  /**
   * Extend Surveys
   */
  async extendSurvey(payload: Instance<typeof ExtendSurveyPayload>): Promise<any> {
    const response: any = await SurveyApi.apisauce.post(`/surveys/extend/`, payload)
    return handleResponse(response)
  },

  /**
   * Duplicate Surveys
   */
  async duplicateSurvey(payload: Instance<typeof GeneralSurveyPayload>): Promise<any> {
    const response: any = await SurveyApi.apisauce.post(`/surveys/duplicate/`, payload)
    return handleResponse(response)
  },

  /**
   * Delete Surveys
   */
  async deleteSurvey(payload: Instance<typeof GeneralSurveyPayload>): Promise<any> {
    const response: any = await SurveyApi.apisauce.delete(`/surveys/delete/`, {}, { data: payload })
    return handleResponse(response)
  },

  /**
   * Nudge Surveys
   */
  async nudge(payload: Instance<typeof GeneralSurveyPayload>): Promise<any> {
    const response: any = await SurveyApi.apisauce.post(`/surveys/nudge/`, payload)
    return handleResponse(response)
  },

  /**
   * Get Survey Assignees Info
   */
  async getSurveyAssignees(id: number): Promise<any> {
    const response: ApiResponse<Instance<typeof Survey>> = await SurveyApi.apisauce.get(
      `/surveys/${id}/assign`,
    )
    return handleResponse(response)
  },

  /**
   * Update Survey Details
   */
  async updateSurvey(payload: Instance<typeof Survey>): Promise<any> {
    const response: ApiResponse<Instance<typeof Survey>> = await SurveyApi.apisauce.patch(
      `/surveys/${payload.id}/`,
      { ...payload, keep_order: true },
    )
    return handleResponse(response)
  },

  /**
   * Update Survey Dates
   */
  async updateSurveyDates(payload: Instance<typeof SurveySchedulePayload>): Promise<any> {
    const response: ApiResponse<Instance<typeof Survey>> = await SurveyApi.apisauce.patch(
      `/surveys/${payload.id}/dates`,
      payload,
    )
    return handleResponse(response)
  },

  /**
   * Get Users/Groups available tobe assigned survey
   */
  async getAvailableUsers(survey_id: number): Promise<any> {
    const response: ApiResponse<Instance<Partial<typeof Survey>>> = await SurveyApi.apisauce.get(
      `/surveys/${survey_id}/available_users/`,
    )
    return handleResponse(response)
  },

  /**
   * Update Survey Assignees Info
   */
  async updateSurveyAssignees(
    survey_id: number,
    payload: Instance<typeof SurveyAssignPayload>,
  ): Promise<any> {
    const response: ApiResponse<Instance<Partial<typeof Survey>>> = await SurveyApi.apisauce.post(
      `/surveys/${survey_id}/set_users`,
      payload,
    )
    return handleResponse(response)
  },

  /*Get survey details to be assigned */
  async getSurveyAssignDetails(survey_id: number): Promise<any> {
    const response: ApiResponse<Instance<typeof AssignSurvey>> = await SurveyApi.apisauce.get(
      `/surveys/${survey_id}/assign/`,
    )
    return handleResponse(response)
  },
  /*Assign survey to selected users&groups */
  async postSurveyAssignDetails(
    survey_id: number,
    data: PostSurveyAssignDetailsRequestType,
  ): Promise<any> {
    const response: ApiResponse<Instance<PostSurveyAssignDetailsRequestType>> =
      await SurveyApi.apisauce.patch(`/surveys/${survey_id}/assign`, {
        ...data,
      })
    return handleResponse(response)
  },
  /*Assign survey to selected users&groups */
  async getAssignedSurveysOfUser(user_id: number): Promise<any> {
    const response: ApiResponse<Instance<(typeof AssignedSurvey)[]>> = await SurveyApi.apisauce.get(
      `/users/${user_id}/surveys/`,
    )
    return handleResponse(response)
  },
  async postStartSurvey(user_id: number, survey_id: number): Promise<any> {
    const response: ApiResponse<Instance<typeof Survey>> = await SurveyApi.apisauce.post(
      `/surveys/start/`,
      {
        survey_id,
        user_id,
      },
    )
    return handleResponse(response)
  },
  async postSubmitSurveyResponses(payload: SurveySubmitRequestModelType): Promise<any> {
    const response: ApiResponse<object> = await SurveyApi.apisauce.post(`/surveys/submit/`, {
      ...payload,
    })
    return handleResponse(response)
  },
}
