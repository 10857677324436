import { Instance, types } from 'mobx-state-tree'
import { LikeReadModel } from './LikeReadModel'
import { UserSchemaModelBase } from '../user'

/**
 * CommentRead Model
 */
export const CommentReadModel = types.model('CommentRead').props({
  __typename: types.optional(types.literal('CommentRead'), 'CommentRead'),
  author: types.frozen(),
  id: types.identifierNumber,
  likes: types.maybeNull(types.array(types.late(() => LikeReadModel))),
  parent_comment_id: types.maybeNull(types.integer),
  post_id: types.integer,
  text: types.string,
  title: types.maybeNull(types.string),
  child_comments: types.maybe(types.frozen()),
  liked_by_current_user: types.boolean,
  created_at: types.string,
})

export const Comment = (
  post_id: number,
  text: string,
  title: string | null,
  user: Instance<typeof UserSchemaModelBase>,
) => {
  return {
    author: {
      email: user.email,
      first_name: user.first_name,
      id: user.id,
      last_name: user.last_name,
      file_url: user.photo_url,
    },
    id: -1,
    likes: null,
    parent_comment_id: null,
    post_id,
    text,
    title,
    child_comments: [],
    liked_by_current_user: false,
    created_at: new Date().toISOString(),
  } as Instance<typeof CommentReadModel>
}
