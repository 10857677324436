import React, { useEffect, useState } from 'react'
import { AutoFixHigh, Close, Delete } from '@mui/icons-material'
import { QuestionHelper, QuizHelper, useStores } from '@trivie/core'
import { ConfirmDialog } from '@trivie/ui-web/components/confirm-dialog'
import { DataTable, TableHelper } from '@trivie/ui-web/components/data-table/data-table'
import { observer } from 'mobx-react-lite'
import { CircularProgress } from '@mui/material'
import { Toolbar } from '@trivie/ui-web/components/table-toolbar'
import { QuizApi, ResourceApi } from '@trivie/core/src/services/api-objects'
import { useConfirm } from '@trivie/ui-web/components/dialogs/confirmation-dialog'
import { useHistory } from 'react-router-dom'

interface ResourceTableProps {
  tableHelper: TableHelper
  forceLoading?: boolean
}

export const ResourceTable = observer((props: ResourceTableProps) => {
  const { tableHelper, forceLoading } = props

  const { manageResourceStore, manageUserStore, resourceStore, contentCreationStore } = useStores()
  const { userProfile, gdpr, permissions } = manageUserStore
  const { deleteResources } = manageResourceStore
  const { resetContentStore, setQuiz } = contentCreationStore
  const { getAvailableResources, availableResources } = resourceStore

  const history = useHistory()

  useEffect(() => {
    resetContentStore()
    getAvailableResources()
  }, [])

  const confirm = useConfirm()

  const confirmRemove = () => {
    confirm({
      danger: true,
      title: 'Remove Quizzes',
      confirmationMessage: `${selectedRows.length} source${
        selectedRows.length > 1 ? 's have' : ' has'
      } been removed from this quiz.`,
      dialogText:
        deleteCheck && typeof deleteCheck.message !== 'undefined'
          ? deleteCheck.message
          : `Are you sure you want to remove ${selectedRows.length} source${
              selectedRows.length > 1 ? 's' : ''
            } from this quiz?`,
    })
      .then(() => {
        if (tableHelper.id) {
          QuizApi.removeResources(tableHelper.id, selectedRows).then(() => load())
        }
        setSelectedRows([])
      })
      .catch(() => {})
  }

  const [selectedRows, setSelectedRows] = useState<any>([])
  const [loading, setLoading] = useState<boolean>(true)
  const [deleteCheck, setDeleteCheck] = useState<any>()
  const [deleting, setDeleting] = useState<any>(false)

  // Dialogs
  const [showConfirmDelete, setShowConfirmDelete] = useState(false)

  const generateQuiz = () => {
    resetContentStore()
    const quiz = QuizHelper.createNewQuiz({
      showQuizPanel: true,
      showResourcePanel: true,
      generatingQuestions: true,
      stopGenerating: false,
      showSettings: false,
      settings: {
        questionCount: 10,
        audience: '',
        voice: '',
      },
    })
    quiz.addQuestions([QuestionHelper.createNewQuestion()])
    const resource = availableResources?.find((r) => r.resource_id === selectedRows[0])
    if (resource && resource.processing_status !== 'processed') {
      ResourceApi.processResource(Number(selectedRows[0])).then(async () => {
        const available = await getAvailableResources()
        const processedResource = available?.find(
          (r: any) => r.resource_id === Number(selectedRows[0]),
        )
        quiz.setMercuryResource(processedResource)
        quiz?.set('title', `${resource.title} Quiz`)
        quiz?.set('showSettings', true)
        setQuiz(quiz)
        history.push('/content/creation/quiz/new/edit/?generate=true&create=true')
      })
    } else if (resource && resource.processing_status === 'processed') {
      quiz.setMercuryResource(resource)
      quiz.addResource(resource)
      quiz?.set('title', `${resource.title} Quiz`)
      quiz?.set('showSettings', true)
      setQuiz(quiz)
      history.push('/content/creation/quiz/new/edit/?generate=true&create=true')
    }
  }

  const getActions = () => {
    switch (tableHelper.parent) {
      case 'paths':
        return []
      case 'assignments':
        return []
      case 'quizzes':
        return [
          {
            label: 'Remove From Quiz',
            icon: Close,
            onClick: confirmRemove,
            disabled: !permissions?.permissions.some((p) => p.code === 'can_delete_resource'),
          },
          {
            label: 'Delete',
            icon: Delete,
            onClick: () => setShowConfirmDelete(true),
            disabled: !permissions?.permissions.some((p) => p.code === 'can_delete_resource'),
          },
        ]
      case 'topics':
        return []
      case 'groups':
        return []
      case 'tags':
        return []
      case 'users':
        return []
      default:
        return [
          {
            disabled:
              !permissions?.permissions.some((p) => p.code === 'can_create_quiz') ||
              !userProfile?.workspace_settings?.mercury_enabled ||
              selectedRows?.length > 1,
            label: 'Generate Quiz',
            icon: AutoFixHigh,
            onClick: generateQuiz,
          },
          {
            label: 'Delete',
            icon: Delete,
            onClick: () => setShowConfirmDelete(true),
            disabled: !permissions?.permissions.some((p) => p.code === 'can_delete_resource'),
          },
        ]
    }
  }

  const [tableActions, setTableActions] = useState<any>(getActions())

  useEffect(() => {
    if (permissions) {
      setTableActions(getActions())
    }
  }, [permissions?.permissions])

  useEffect(() => {
    setLoading(Boolean(forceLoading))
  }, [forceLoading])

  useEffect(() => {
    setTableActions(getActions())
  }, [selectedRows])

  useEffect(() => {
    load()
  }, [])

  const load = () => {
    setLoading(true)
    tableHelper.load().then(() => setLoading(false))
  }

  const save = async (columns: any, filter: any) => {
    setLoading(true)
    tableHelper.save(columns, filter).then(() => setLoading(false))
  }

  return loading ? (
    <div style={LOADER}>
      <CircularProgress />
    </div>
  ) : (
    <>
      <DataTable
        gdpr={gdpr}
        columnVisibilityModel={tableHelper.columnVisibilityModel}
        hideAddColumn
        tableHelper={tableHelper}
        filterModel={tableHelper.filterModel}
        sortModel={tableHelper.sortModel}
        searchPlaceholder="Search Sources"
        rows={tableHelper.rows}
        columns={tableHelper.columns}
        onSaveLayout={tableHelper?.saveLayout}
        onSaveFilters={save}
        toolbar={<Toolbar selected={selectedRows} actions={tableActions} />}
        exportFilename={
          tableHelper.parent !== 'resources' ? `${tableHelper.parent}_sources` : 'sources'
        }
        rowSelectionModel={selectedRows}
        onRowSelectionModelChange={(param) => setSelectedRows(param)}
      />
      {showConfirmDelete && (
        <ConfirmDialog
          danger
          open={showConfirmDelete}
          title={
            deleteCheck
              ? `Cannot Delete Source${selectedRows.length > 1 ? 's' : ''}`
              : 'Delete Sources'
          }
          message={`Are you sure you want to delete ${selectedRows.length} source${
            selectedRows.length > 1 ? 's' : ''
          }?`}
          confirmedMessage={
            deleting
              ? `Are you sure you want to delete ${selectedRows.length} source${
                  selectedRows.length > 1 ? 's' : ''
                }?`
              : deleteCheck
                ? deleteCheck
                : `${selectedRows.length} source${
                    selectedRows.length > 1 ? 's have' : ' has'
                  } been removed from the system.`
          }
          confirmButton="Confirm"
          contentStyle={deleteCheck ? { textAlign: 'left' } : {}}
          onConfirm={() => {
            setDeleting(true)
            deleteResources(selectedRows, true).then((data) => {
              setDeleting(false)
              if (data.message) {
                setDeleteCheck(data?.message)
              }
            })
          }}
          handleCancel={() => setShowConfirmDelete(false)}
          onClose={() => {
            if (!deleteCheck) {
              load()
              setSelectedRows([])
            }
            setShowConfirmDelete(false)
            setDeleteCheck('')
          }}
        />
      )}
    </>
  )
})

const LOADER: React.CSSProperties = {
  display: 'flex',
  flex: 1,
  justifyContent: 'center',
  flexDirection: 'column',
  alignItems: 'center',
  height: window.innerHeight / 1.5,
}
