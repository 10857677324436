import React from 'react'
import { observer } from 'mobx-react-lite'
import { useEffect } from 'react'
import { useHistory, useRouteMatch } from 'react-router-dom'

import { useStores } from '@trivie/core'
import { FullScreenLoader } from '@trivie/ui-web/components/full-screen-loader'

export const MagicLink = observer(() => {
  const { manageUserStore } = useStores()
  const { loginWorkspace, userProfile, evaluateMagicLink, logout, enterpriseTier } = manageUserStore
  const { params } = useRouteMatch()

  const history = useHistory()

  useEffect(() => {
    logout()
    handleMagicLink()
  }, [])

  // loginWorkspace loads the user profile on success which triggers this effect
  useEffect(() => {
    if (userProfile) {
      history.replace(enterpriseTier ? '/insights/knowledge' : '/content/quizzes')
    }
  }, [userProfile])

  const handleMagicLink = () => {
    // @ts-ignore
    const { token, email } = params
    if (token && email) {
      evaluateMagicLink(token, email).then((data: any) => {
        if (manageUserStore.status === 'error') {
          history.replace('/magic')
        } else if (data.workspaces && data.workspaces.length > 1) {
          localStorage.removeItem('ml-error')
          history.replace('/workspaces')
        } else if (data.workspaces.length === 1) {
          localStorage.removeItem('ml-error')
          loginWorkspace(data.token, data.workspaces[0].access_code)
        }
      })
    }
  }

  return <FullScreenLoader status="Logging you in..." />
})
