import React, { useEffect, useState } from 'react'
import { observer } from 'mobx-react-lite'
import { Instance } from 'mobx-state-tree'

import { InviteUserForm } from '@trivie/core/src/models/InviteUser'
import { TagApi } from '@trivie/core/src/services/api-objects/TagApi'
import { TagSelector } from '../../tag-selector'

interface UserTagsProps {
  form: Instance<typeof InviteUserForm>
}

export const UserTagForm = observer((props: UserTagsProps) => {
  const { form } = props

  const [tags, setTags] = useState<any>([])
  const [formattedTags, setFormattedTags] = useState({})

  useEffect(() => {
    TagApi.getAllTags(
      'tags',
      false,
      [{ field: 'tag_key' }, { field: 'tag_value' }],
      'list_tags',
    ).then((resp) => {
      if (resp.ok) {
        // @ts-ignore
        const tags = resp.data.rows.map((tag) => {
          const key = tag.columns.find((column) => column.field === 'tag_key')
          const value = tag.columns.find((column) => column.field === 'tag_value')
          return { id: tag.id, key: key.value, value: value.value }
        })
        setTags(tags)
        const formatted = {}
        tags.map((tag) => {
          formatted[tag.key] = { values: [] }
        })
        tags.map((tag) => {
          formatted[tag.key].values.push(tag)
        })

        setFormattedTags(formatted)
      }
    })
  }, [])

  const [selectedTags, setSelectedTags] = useState([])
  return (
    <div style={{ display: 'flex', flexDirection: 'column', flex: 1, position: 'relative' }}>
      <TagSelector
        formattedTags={formattedTags}
        tags={tags}
        onChange={(tags) => {
          setSelectedTags(tags)
          form.set(
            'tag_keys_values',
            tags.map((t) => {
              return { tag_id: t.id, key: t.key, value: t.value }
            }),
          )
        }}
        selectedTags={selectedTags}
      />
    </div>
  )
})
