import React from 'react'
import { Circle } from '@mui/icons-material'
import { Box, Chip, Skeleton, Stack } from '@mui/material'

import { color } from '../../theme'
import { observer } from 'mobx-react-lite'
import { Button } from '../button/button'

const _snakeCase = require('lodash/snakeCase')

interface HeaderPanelProps {
  showOverlay?: boolean
  backdrop?: any
  status?: { label: string; color: string }
  footer?: any
  actions?: any
  backdropStyle?: React.CSSProperties
  containerStyle?: React.CSSProperties
  loading?: boolean
}
export const HeaderPanel = observer((props: HeaderPanelProps) => {
  const { showOverlay, backdropStyle, status, footer, backdrop, actions, loading } = props

  return loading ? (
    <Skeleton sx={{ borderRadius: '24px', my: '16px' }} variant="rectangular" height={214} />
  ) : (
    <Box
      borderRadius={6}
      justifyContent="space-between"
      sx={{
        height: 214,
        backgroundColor: color.shade50,
        my: '16px',
        p: 3,
        position: 'relative',
        display: 'flex',
      }}
    >
      {showOverlay && <div style={OVERLAY} />}
      {backdrop && <img style={{ ...BACKDROP, ...backdropStyle }} src={backdrop} />}
      {status && (
        <Chip
          sx={STATUS}
          icon={<Circle sx={{ ...INDICATOR, color: `${status.color} !important` }} />}
          label={status.label}
        />
      )}
      <Stack
        alignSelf="flex-end"
        flex={1}
        position="relative"
        zIndex={999}
        direction="row"
        justifyContent="space-between"
        alignItems="flex-end"
      >
        {footer}
        <Stack
          justifyContent="flex-end"
          flexWrap="wrap"
          alignItems="flex-end"
          direction="row"
          useFlexGap
          gap={2}
        >
          {actions.map((action) => {
            return (
              !action.hide &&
              !action.disabled && (
                <Button
                  key={action.label}
                  disabled={action.disabled}
                  text={action.label}
                  onClick={action.onClick}
                  startIcon={action.icon}
                  color={action.primary ? 'primary' : 'white'}
                  data-qat={_snakeCase(action.label)}
                />
              )
            )
          })}
        </Stack>
      </Stack>
    </Box>
  )
})

const STATUS = {
  position: 'absolute',
  top: '16px',
  left: '16px',
  '.MuiChip-labelMedium': { paddingRight: '8px !important' },
  fontSize: 14,
  fontWeight: 600,
  backgroundColor: '#212121',
  height: 24,
  border: `1px solid rgba(255, 255, 255, 0.50)`,
  color: '#fff',
}
const INDICATOR: React.CSSProperties = { marginRight: '-8px !important', fontSize: 10 }
const OVERLAY: React.CSSProperties = {
  background: 'linear-gradient(180deg, rgba(217, 217, 217, 0.00) 0%, rgba(0, 0, 0, 0.67) 78.81%)',
  position: 'absolute',
  width: '100%',
  top: 0,
  left: 0,
  zIndex: 998,
  height: 214,
  borderRadius: 24,
}
const BACKDROP: React.CSSProperties = {
  objectFit: 'cover',
  height: 214,
  width: '100%',
  position: 'absolute',
  zIndex: 997,
  top: 0,
  left: 0,
  borderRadius: 24,
  userSelect: 'none',
}
