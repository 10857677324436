import { Instance, types } from 'mobx-state-tree'

/**
 * AvailableResources Model
 */

export const AddResourceModel = types.model('AvailableResources').props({
  id: types.identifier,
  title: types.string,
  url: types.maybeNull(types.string),
  size: types.string,
  extension: types.string,
  isSelected: types.boolean,
  last_updated: types.string,
  original_filename: types.string,
})

export const AvailableResourcesModel = types.model('AvailableResources').props({
  resource_id: types.identifierNumber,
  thumbnail_url: types.string,
  filename_or_url: types.string,
  created_at: types.string,
  quiz_count: types.number,
})

/* The TypeScript type of an instance of AvailableResourcesModel */
export interface AvailableResourcesModelType
  extends Instance<typeof AvailableResourcesModel.Type> {}

export interface AddResourcesModelType extends Instance<typeof AddResourceModel.Type> {}
