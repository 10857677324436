export const palette = {
  blue: '#225FF6',
  black: '#000000',
  charcoal: '#151E23',
  white: '#ffffff',
  offWhite: '#f7f7f7',
  metadata: '#838383',
  lightGrey: '#DADADA',
  darkGrey: '#444444',
  orange: '#FBA928',
  orangeDarker: '#EB9918',
  lighterGrey: '#CDD4DA',
  question: '#6E7783',
  lime: '#93C852',
  panel: '#F6F8FB',
  purple: '#551BFA',
  green: '#1FE08F',
  red: '#FF3E13',
  yellow: '#FFCE51',
  lightYellow: '#ffce511a',
  thumbGreen: '#34C759',
  grey: '#9098A3',
  superLightGrey: '#F1F1F1',
  electricViolet: '#582FFC',
  blueRibbon: '#263AF4',
  blueLink: '#1B59F8',
  wildSand: '#f5f5f5',
  mercury: '#e5e5e5',
  athensGrey: '#F0F2F5',
  rollingStone: '#78797b',
  opaque: '#FF000000',
  iconBlue: '#1B59FA',
  lightBlue: '#96D9FF',
  darkerGrey: '#70768C',
  dividers: '#E6E6E6',
  subComment: 'rgba(240, 242, 245, 0.5)',
  selectedTag: '#F2F7FF',
  paperBorder: '#EFF0F6',
  blueGrey: '#F3F5FB',
  quizSummary: '#4F5E74',
  emerald: '#35C759',
  selectionSidebar: '#F8FAFD',
  manatee: '#92929D',
  mako: '#44444F',
  titanWhite: '#ECE9FF',
  bigStone: '#162142',
  alto: '#dfdfdf',
  abbey: '#4A4A4B',
  porcelain: '#F7F8F9',
  gradientRedFilled: 'linear-gradient(143.13deg, #FFBF1A 5.36%, #FF4080 94.64%)',
  gradientRedUnfilled:
    'linear-gradient(143.13deg, rgba(255, 191, 26, 0.2) 5.36%, rgba(255, 64, 128, 0.2) 94.64%)',
  gradientGreenFilled: 'linear-gradient(270deg, #2FEA9B 15.5%, #7FDD53 85.5%)',
  gradientGreenUnfilled:
    'linear-gradient(270deg, rgba(47, 234, 155, 0.2) 15.5%, rgba(127, 221, 83, 0.2) 85.5%)',
}
