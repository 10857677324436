import React from 'react'
import { Skeleton, Stack } from '@mui/material'

import { CirclePercentage } from '../circle-percentage'
import { Text } from '../text'

export interface MasteryProps {
  percentage: number
  message: string
  variant?: 'red' | 'yellow' | 'green'
}

export function Mastery(props: MasteryProps) {
  const { percentage, message, variant } = props

  return (
    <Stack direction="row" alignItems="center" flex={1}>
      <CirclePercentage value={percentage} variant={variant} style={{ marginRight: 20 }} />
      <Stack gap={1} flex={1}>
        {percentage !== undefined ? (
          <>
            <Text variant="headline" text={`${percentage}%`} />
            <Text variant="body1Medium" text={message} color="text.secondary" />
          </>
        ) : (
          <>
            <Skeleton variant="rectangular" width={40} height={29} />
            <Skeleton variant="rectangular" width={208} height={34} />
          </>
        )}
      </Stack>
    </Stack>
  )
}
