import React, { useState } from 'react'
import { Redirect, Route, Switch, useRouteMatch } from 'react-router-dom'
import { observer } from 'mobx-react-lite'
import { Add } from '@mui/icons-material'

import { Header } from '@trivie/ui-web/components/header'
import { NavigationTabs } from '@trivie/ui-web/components/navigation-tabs'
import { useStores } from '@trivie/core'

import { LiveContestsTab } from './tabs/contests-live-tab'
import { ScheduledContestsTab } from './tabs/contests-scheduled-tab'
import { CompletedContestsTab } from './tabs/contests-completed-tab'
import { CreateContestWizard } from './create-contest'
import { ContestDetailsScreen } from './contest-details-screen'

export const ContestsScreen: React.FunctionComponent = observer(() => {
  const { params } = useRouteMatch()
  // @ts-ignore
  const { mainTab } = params

  const { manageUserStore, contestStore } = useStores()
  const { editID, showEditWizard, setShowEditWizard } = contestStore
  const { permissions, userProfile } = manageUserStore
  const [showCreateWizard, setShowCreateWizard] = useState(false)

  const gdpr_enabled = userProfile?.workspace_settings?.gdpr_enabled

  if (gdpr_enabled) {
    return <Redirect to="/reports/knowledge" />
  }

  const primary = {
    disabled: !permissions?.permissions.some((p) => p.code === 'can_create_contest'),
    startIcon: <Add />,
    onClick: () => setShowCreateWizard(true),
    text: 'Create Contest',
  }

  return (
    <>
      <Switch>
        <Route exact path="/activities/contests/:mainTab">
          <Header
            title={`${mainTab.charAt(0).toUpperCase() + mainTab.slice(1)} Contests`}
            primaryAction={primary}
          />
          <NavigationTabs tabList={[LiveContestsTab, ScheduledContestsTab, CompletedContestsTab]} />
        </Route>
        <Route exact path="/activities/contests/:mainTab/:id">
          <ContestDetailsScreen />
        </Route>
      </Switch>
      {showCreateWizard && (
        <CreateContestWizard
          open={showCreateWizard}
          editingId={null}
          requestClose={() => setShowCreateWizard(false)}
        />
      )}
      {showEditWizard && (
        <CreateContestWizard
          open={showEditWizard}
          editingId={editID}
          requestClose={() => setShowEditWizard(false)}
        />
      )}
    </>
  )
})
