import React, { useState } from 'react'
import { observer } from 'mobx-react-lite'
import { useParams } from 'react-router-dom'
import { Create } from '@mui/icons-material'
import { DateTime } from 'luxon'

import { useStores } from '@trivie/core'
import { Header } from '@trivie/ui-web/components/header'
import { NavigationTabs } from '@trivie/ui-web/components/navigation-tabs'
import { CreateGroup } from '@trivie/ui-web/components/create-group'

import { groupsTabs } from './tabs'

export const GroupsDetailsScreen: React.FunctionComponent = observer(() => {
  // @ts-ignore
  const { id } = useParams()
  const { groupStore, manageUserStore } = useStores()
  const { permissions } = manageUserStore
  const { groupDetail, refreshOverviewData } = groupStore

  const [showEdit, setShowEdit] = useState(false)

  const primary = {
    disabled: !permissions?.permissions.some((p) => p.code === 'can_edit_group'),
    startIcon: <Create />,
    onClick: () => setShowEdit(true),
    text: 'Edit Group',
  }

  return (
    <>
      <Header title={`${groupDetail?.label ?? ''}`} primaryAction={primary} />
      <NavigationTabs tabList={groupsTabs} id={id} />
      {showEdit && (
        <CreateGroup
          reload={() => {}}
          edit
          open={showEdit}
          onClose={() => {
            setShowEdit(false)
            refreshOverviewData(
              DateTime.local(1971).startOf('day').toJSDate().toISOString(),
              DateTime.local().endOf('day').toJSDate().toISOString(),
            )
          }}
        />
      )}
    </>
  )
})
