import { mergeAttributes, Node } from '@tiptap/core'
import { ReactNodeViewRenderer } from '@tiptap/react'
import { SentenceNode } from './sentence-node'

export interface sentenceOptions {
  HTMLAttributes: Record<string, any>
  quiz: any
  selectQuestion: (q: any) => void
}

declare module '@tiptap/core' {
  interface Commands<ReturnType> {
    sentence: any
    table: any
  }
}

export const Sentence = Node.create<sentenceOptions>({
  name: 'sentence',

  addOptions() {
    return {
      HTMLAttributes: {},
      quiz: {},
      selectQuestion: (q: any) => {},
    }
  },

  content: 'inline*',

  group: 'block',

  addAttributes() {
    return {
      generated_question_ids: [],
      node_id: 0,
      question_id: [],
      citations: [],
      generating: false,
    }
  },
  draggable: false,
  parseHTML() {
    return [
      {
        tag: `p`,
        attrs: { generated_question_ids: [] },
        className: 'sentence-content',
      },
    ]
  },

  renderHTML({ node, HTMLAttributes }) {
    return [`p`, mergeAttributes(this.options.HTMLAttributes, HTMLAttributes), 0]
  },

  addNodeView() {
    return ReactNodeViewRenderer(SentenceNode, {
      as: 'p',
      contentDOMElementTag: 'span',
      attrs: { ...this.options.HTMLAttributes },
    })
  },
})
