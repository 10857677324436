import { observer } from 'mobx-react-lite'
import React from 'react'

import { CircularProgress, Divider } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableContainer from '@mui/material/TableContainer'
import TablePagination from '@mui/material/TablePagination'
import TableRow from '@mui/material/TableRow'
import { KeyboardArrowDown } from '@mui/icons-material'

import { Avatar } from '../avatar'
import { Text } from '../text'
import { AssignmentsShortCell } from './cells/assignments-short-cell'
import { GroupsShortCell } from './cells/groups-short-cell'
import { TopicsShortCell } from './cells/topics-short-cell'
import { QuizzesShortCell } from './cells/quizzes-short-cell'
import { UserShortCell } from './cells/user-short-cell'
import { TagShortCell } from './cells/tag-short-cell'
import { Panel } from '../panel/panel'

const useStyles = makeStyles({
  root: {
    width: '100%',
  },
  container: {
    display: 'flex',
    width: '100%',
    height: '100%',
    flexDirection: 'column',
    justifyContent: 'space-between',
    minHeight: 450,
  },
  pagination: {
    '& .MuiTablePagination-toolbar': {
      minHeight: 0,
    },
    '& .MuiSelect-selectMenu': {
      minHeight: 0,
    },
    '& .MuiSelect-icon': {
      fontSize: 16,
      color: 'rgba(0, 0, 0, 0.3)',
      top: 'none',
      right: 8,
    },
    '& .MuiSelect-select:focus': {
      backgroundColor: 'none',
    },
  },
})

export const ShortList = observer((props: any) => {
  const tableClasses = useStyles()
  const [page, setPage] = React.useState(0)

  const {
    label,
    style,
    containerStyle,
    data,
    nullState,
    rowAction,
    type,
    action,
    deleteButton,
    isLoading = false,
    titleVariant = 'titleEmphasized',
    hideIcon,
    itemsPerPage,
  } = props

  const [rowsPerPage, setRowsPerPage] = React.useState(itemsPerPage ?? 5)

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(+event.target.value)
    setPage(0)
  }

  const renderCell = (cellType: any, row: any) => {
    switch (cellType) {
      case 'Competitors':
        return row?.type === 'user' ? (
          <UserShortCell avatarSize={24} row={row} />
        ) : (
          <GroupsShortCell row={row} />
        )
      case 'Content':
        return row?.type === 'topic' ? (
          <TopicsShortCell row={row} />
        ) : (
          <QuizzesShortCell row={row} />
        )
      case 'Tags':
        return <TagShortCell remove action={rowAction} row={row} />
      case 'Assignments':
        return <AssignmentsShortCell row={row} />
      case 'Additional Users':
        return <UserShortCell remove action={rowAction} row={row} />
      case 'Excluded Users':
        return <UserShortCell action={rowAction} row={row} />
      case 'Groups':
        return <GroupsShortCell action={rowAction} row={row} />
      case 'Topics':
        return <TopicsShortCell deleteButton={deleteButton} row={row} />
      case 'Quizzes':
        return <QuizzesShortCell deleteButton={deleteButton} row={row} hideIcon={hideIcon} />
      default:
        return (
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <Avatar name={`${row.first_name} ${row.last_name}`} url={row.file_url} />
            <Text
              style={{ marginLeft: 16, textAlign: 'left' }}
              variant="body1Emphasized"
              text={`${row.first_name} ${row.last_name}`}
            />
          </div>
        )
    }
  }

  const renderTableBody = () => {
    if (isLoading) {
      return (
        <div style={LOADER}>
          <CircularProgress />
        </div>
      )
    } else if (data?.length === 0) {
      return nullState
    } else {
      return data
        ?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
        .map((row: any, index: number) => {
          return (
            <TableRow key={row.id ? row.id : index} style={{ height: 66 }} tabIndex={-1}>
              {renderCell(type, row)}
            </TableRow>
          )
        })
    }
  }

  return (
    <Panel sx={{ flex: 1 }} title={type} action={action}>
      <TableContainer className={tableClasses.container} style={containerStyle}>
        <Table stickyHeader aria-label="sticky table">
          {label}
          {type === 'Competitors' && <Divider />}
          <TableBody>{renderTableBody()}</TableBody>
        </Table>
        <TablePagination
          sx={{ minHeight: 0 }}
          className={tableClasses.pagination}
          labelDisplayedRows={({ from, to, count }) => `Showing: ${from}–${to} of ${count}`}
          rowsPerPageOptions={!!itemsPerPage ? [] : [1, 5, 10]}
          component="div"
          SelectProps={{ IconComponent: KeyboardArrowDown }}
          count={data ? data.length : 0}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </TableContainer>
    </Panel>
  )
})

const HEADER: React.CSSProperties = {
  alignItems: 'center',
  flexDirection: 'row',
  display: 'flex',
  justifyContent: 'space-between',
  padding: 20,
}
const LOADER: React.CSSProperties = {
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  width: '100%',
  height: 350,
  alignItems: 'center',
}
