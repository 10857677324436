import React from 'react'
import { IconButton, Tooltip as MuiTooltip } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { Text } from '../text'
import { observer } from 'mobx-react-lite'
import { Close } from '@mui/icons-material'
import { color } from '../../theme'

const useStyles = makeStyles(() => ({
  tooltip: {
    top: 8,
    borderRadius: 8,
  },
  arrow: {
    fontSize: 24,
  },
}))

export const Tooltip = observer((props: any) => {
  const { open, children, title, titleStyle, text, textStyle, placement, onDismiss, style } = props
  const classes = useStyles()

  return (
    <MuiTooltip
      classes={{
        tooltip: classes.tooltip,
        arrow: classes.arrow,
      }}
      style={{ padding: 0, ...style }}
      open={open}
      placement={placement ?? 'bottom'}
      interactive
      PopperProps={{ open }}
      title={
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            width: 318,

            paddingTop: 2,
            paddingLeft: 24,
            paddingBottom: 16,
            borderRadius: 8,
          }}
        >
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              flex: 1,
              alignItems: 'center',
              paddingRight: 9,
            }}
          >
            {title && (
              <Text
                variant="labelEmphasized"
                text={title}
                style={{ color: color.white, paddingTop: 10, paddingBottom: 10, ...titleStyle }}
              />
            )}
            {onDismiss ? (
              <IconButton onClick={onDismiss} size="large">
                <Close htmlColor={color.white50} />
              </IconButton>
            ) : null}
          </div>
          <Text
            variant="body1"
            text={text}
            style={{ marginTop: -3, color: color.white, marginRight: 24, ...textStyle }}
          />
        </div>
      }
      arrow
    >
      {children}
    </MuiTooltip>
  )
})
