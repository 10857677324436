import { getType, Instance, isStateTreeNode } from 'mobx-state-tree'
import { Action } from '../models/workflow/Action'
import { Condition } from '../models/workflow/Condition'

export const isAction = (action: any): action is Instance<typeof Action> => {
  return action && isStateTreeNode(action) && getType(action).name === 'Action'
}
export const isCondition = (condition: any): condition is Instance<typeof Condition> => {
  return condition && isStateTreeNode(condition) && getType(condition).name === 'Condition'
}
