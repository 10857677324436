import React, { useEffect, useState } from 'react'
import { Box, CircularProgress, IconButton, InputAdornment, Stack, TextField } from '@mui/material'
import { observer } from 'mobx-react-lite'
import { Close, Search } from '@mui/icons-material'

import { Dialog } from '@trivie/ui-web/components/dialogs/dialog'
import { Image } from '@trivie/ui-web/components/image'
import { Text } from '@trivie/ui-web/components/text'
import { color } from '@trivie/ui-web/theme'
import { QuizApi } from '@trivie/core/src/services/api-objects'

import defaultCover from '../../../../screens/content/quizzes/quiz-card/default-topic-image.png'

export const AddQuizDialog = observer((props: any) => {
  const { open, close, onSelect } = props

  const [searchText, setSearchText] = useState('')
  const [quizzes, setQuizzes] = useState<any>([])
  const [selected, setSelected] = useState<any>()
  const [loading, setLoading] = useState<any>(true)

  useEffect(() => {
    loadQuizzes()
  }, [])

  const handleSelect = () => {
    onSelect(selected)
    close()
  }

  const loadQuizzes = async () => {
    setLoading(true)
    const resp = await QuizApi.getAllQuizzes(
      'quizzes',
      false,
      [
        { field: 'quiz_title' },
        { field: 'photo_url' },
        { field: 'quiz_question_count' },
        { field: 'quiz_resource_count' },
      ],
      'list_quizzes',
    )
    const formatted = resp.data.rows
      .map((q) => {
        return {
          id: q.id,
          quiz_title: q.columns.find((column) => column.field === 'quiz_title').value,
          photo_url: q.columns.find((column) => column.field === 'photo_url').value,
          quiz_question_count: q.columns.find((column) => column.field === 'quiz_question_count')
            .value,
          quiz_resource_count: q.columns.find((column) => column.field === 'quiz_resource_count')
            .value,
        }
      })
      .sort((a, b) => {
        return a.quiz_title > b.quiz_title ? 1 : -1
      })
    setQuizzes(formatted)
    setLoading(false)
  }

  const clear = () => {
    setSearchText('')
  }

  return (
    <Dialog
      onConfirm={handleSelect}
      confirmBtnLabel="Select"
      disableConfirm={!selected}
      onClose={close}
      maxWidth="sm"
      title="Select Quiz"
      open={open}
    >
      {!loading && (
        <Stack useFlexGap gap={1}>
          <Text
            variant="body1"
            color="text.secondary"
            text="Search for an existing quiz to add to this topic."
          />
          <TextField
            fullWidth
            placeholder="Search your quiz library"
            variant="outlined"
            value={searchText}
            size="small"
            onChange={(e) => setSearchText(e.target.value)}
            style={{ backgroundColor: 'white', marginBottom: 12 }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Search htmlColor={color.shade50} />
                </InputAdornment>
              ),
              endAdornment: (
                <InputAdornment style={{ backgroundColor: '#fff' }} position="end">
                  {searchText && (
                    <IconButton onClick={clear} size="small">
                      <Close htmlColor={color.shade30} />
                    </IconButton>
                  )}
                </InputAdornment>
              ),
            }}
          />
        </Stack>
      )}
      <Box
        flexDirection="column"
        display="flex"
        justifyContent={loading ? 'center' : 'flex-start'}
        height={550}
      >
        {loading ? (
          <CircularProgress sx={{ alignSelf: 'center' }} />
        ) : (
          <>
            {quizzes
              ?.filter((quiz) => quiz.quiz_title.toLowerCase().includes(searchText.toLowerCase()))
              .map((quiz: any) => {
                return (
                  <div
                    onClick={() => setSelected(quiz)}
                    style={{
                      border:
                        quiz.id === selected?.id
                          ? `1px solid  rgba(25, 79, 240, .5)`
                          : `1px solid #EFF0F6`,
                      background: quiz.id === selected?.id ? '#f8fcff' : '#fff',
                      ...QUIZ_CONTAINER,
                    }}
                  >
                    <div style={{ flexDirection: 'row', display: 'flex' }}>
                      {quiz.photo_url ? (
                        <Image
                          height={'40px'}
                          width={'80px'}
                          src={quiz.photo_url}
                          style={{ marginRight: 16 }}
                        />
                      ) : (
                        <Image
                          height={'40px'}
                          width={'80px'}
                          src={defaultCover}
                          style={{ marginRight: 16 }}
                        />
                      )}
                      <div style={{ flexDirection: 'column', overflow: 'hidden' }}>
                        <Text style={QUIZ_TITLE} text={quiz.quiz_title} variant="titleEmphasized" />
                        <Text
                          style={{ color: color.shade70 }}
                          text={`${quiz.quiz_question_count} Question${
                            quiz.quiz_question_count === 1 ? '' : 's'
                          } ∙ ${quiz.quiz_resource_count} Source${
                            quiz.quiz_resource_count === 1 ? '' : 's'
                          }`}
                          variant="body2Medium"
                        />
                      </div>
                    </div>
                  </div>
                )
              })}
          </>
        )}
      </Box>
    </Dialog>
  )
})

const QUIZ_CONTAINER: React.CSSProperties = {
  cursor: 'pointer',
  borderRadius: 16,
  paddingTop: 12,
  paddingBottom: 12,
  paddingRight: 16,
  paddingLeft: 16,
  marginBottom: 8,
  display: 'flex',
  justifyContent: 'space-between',
}
const QUIZ_TITLE: React.CSSProperties = {
  overflowWrap: 'break-word',
  display: '-webkit-box',
  WebkitLineClamp: 1,
  WebkitBoxOrient: 'vertical',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  marginBottom: 4,
}
