import { ApiResponse, ApisauceInstance } from 'apisauce'
import { Instance } from 'mobx-state-tree'
import { MagicLinkPayload } from '../../models/MagicLinkPayload'
import { MagicLinkResponse } from '../../models/MagicLinkResponse'
import { AccessCodeDetailResponse } from '../../models/response-models/accessCodeDetails'
import { handleResponse } from '../handleResponse'
import { UserRole } from '../../models/response-models/user'

export const AuthenticationApi = {
  apisauce: {} as ApisauceInstance,
  evaluateMagicLink: async (payload: Instance<typeof MagicLinkPayload>) => {
    const response: ApiResponse<Instance<typeof MagicLinkResponse>> =
      await AuthenticationApi.apisauce.post(`magic_link/evaluate/`, payload)

    return handleResponse(response)
  },
  createWorkspace: async (payload) => {
    const response: any = await AuthenticationApi.apisauce.post(`create_new_client/`, payload)
    if (response.data && response.ok) {
      AuthenticationApi.apisauce.setHeader('Authorization', `Bearer ${response.data.access_token}`)
      AuthenticationApi.apisauce.setHeader('x-access-code', `${response.data.access_code}`)
      localStorage.setItem('token', response.data.access_token)
      localStorage.setItem('access_code', response.data.access_code)
    } else {
      AuthenticationApi.apisauce.deleteHeader('Authorization')
      AuthenticationApi.apisauce.deleteHeader('x-access-code')
    }
    return handleResponse(response)
  },
  insightsSignup: async (payload) => {
    const response = await AuthenticationApi.apisauce.post(`sign_up/`, payload)

    return handleResponse(response)
  },
  /* Find SSO redirect url for access code if available */
  getAccessCodeDetails: async (access_code: string, for_insights?: boolean) => {
    const response: ApiResponse<Instance<typeof AccessCodeDetailResponse>> =
      await AuthenticationApi.apisauce.get(
        `/workspace/${access_code}/sso/${for_insights ? '?target=insights' : ''}`,
      )

    return handleResponse(response)
  },
  loginInsights: async (username: string, password: string, accessCode: string) => {
    AuthenticationApi.apisauce.setHeader('x-access-code', `${accessCode}`)
    const formData = new FormData()
    formData.append('username', username)
    formData.append('password', password)

    const response: ApiResponse<any> = await AuthenticationApi.apisauce.post(
      `login/insights/`,
      formData,
    )

    if (response.data && response.ok) {
      AuthenticationApi.apisauce.setHeader('Authorization', `Bearer ${response.data.access_token}`)
    } else {
      AuthenticationApi.apisauce.deleteHeader('Authorization')
    }

    return handleResponse(response)
  },
  loginWorkspace: async (token: string, access_code: string) => {
    AuthenticationApi.apisauce.setHeader('x-access-code', `${access_code}`)

    const response: ApiResponse<any> = await AuthenticationApi.apisauce.post(`workspace/login/`, {
      token,
      access_code,
    })

    if (response.data && response.ok) {
      AuthenticationApi.apisauce.setHeader('Authorization', `Bearer ${response.data.access_token}`)
    } else {
      AuthenticationApi.apisauce.deleteHeader('Authorization')
      AuthenticationApi.apisauce.deleteHeader('x-access-code')
    }

    return handleResponse(response)
  },
  loginShared: async (code: string, access_code: string, quiz_id: number, email?: string) => {
    AuthenticationApi.apisauce.setHeader('x-access-code', `${access_code}`)

    const response: ApiResponse<any> = await AuthenticationApi.apisauce.post(`login/share/`, {
      code,
      email,
      quiz_id,
    })

    if (response.data && response.ok) {
      AuthenticationApi.apisauce.setHeader('Authorization', `Bearer ${response.data.token}`)
    } else {
      AuthenticationApi.apisauce.deleteHeader('Authorization')
      AuthenticationApi.apisauce.deleteHeader('x-access-code')
    }

    return handleResponse(response)
  },
  getRoles: async (include_learner: boolean) => {
    const response: ApiResponse<Array<Instance<typeof UserRole>>> =
      await AuthenticationApi.apisauce.get(`/roles/?include_learner=${include_learner}`)

    return handleResponse(response)
  },
  updateRoles: async (userID: number, role_ids: number[]) => {
    const response: ApiResponse<any> = await AuthenticationApi.apisauce.post(`/roles/${userID}/`, {
      role_ids,
    })

    return handleResponse(response)
  },
  clearAuthentication: () => {
    AuthenticationApi.apisauce.deleteHeader('Authorization')
    AuthenticationApi.apisauce.deleteHeader('x-access-code')
  },
  setToken: (token: string) => {
    AuthenticationApi.apisauce.setHeader('Authorization', `Bearer ${token}`)
  },
  setAccessCode: (accessCode: string) => {
    AuthenticationApi.apisauce.setHeader('x-access-code', `${accessCode}`)
  },
}
