import React, { useRef } from 'react'
import makeStyles from '@mui/styles/makeStyles'
import createStyles from '@mui/styles/createStyles'
import { Avatar as MaterialAvatar } from '@mui/material'
import { Text } from '../text'
import { color } from '../../theme'
import { Skeleton } from '@mui/material'
import { Create, Person } from '@mui/icons-material'
import { observer } from 'mobx-react-lite'

const useStyles = makeStyles(() =>
  createStyles({
    blue: {
      color: color.palette.blueLink,
      background: 'linear-gradient(95.61deg, #E4EBFF 2.11%, #ECE9FF 97.32%)',
    },
    editableLarge: {
      height: 90,
      width: 90,
    },
    letterLarge: {
      color: color.palette.blueLink,
      background: 'linear-gradient(95.61deg, #E4EBFF 2.11%, #ECE9FF 97.32%)',
      height: 90,
      width: 90,
    },
  }),
)

const LetterAvatar = (props: any) => {
  const classes = useStyles()
  const { name, size, fontStyle, ...rest } = props
  let initials = ''

  const splitName = name.split(' ')
  if (splitName.length === 1) {
    initials = splitName[0].substring(0, 1).toUpperCase()
  } else if (splitName.length > 1) {
    splitName.forEach((firstOrLast: string, index: number) => {
      initials += splitName[index].substring(0, 1).toUpperCase()
    })
  }

  return (
    <MaterialAvatar className={classes.blue} {...rest}>
      <Text
        variant="titleEmphasized"
        text={initials}
        style={{ color: color.palette.blueLink, textDecoration: 'none !important', ...fontStyle }}
      />
    </MaterialAvatar>
  )
}

const EditableAvatar = (props: any) => {
  const { url, onChange, name } = props
  const inputRef = useRef(null)
  const classes = useStyles()

  const handleAvatar = (file: any) => {
    onChange(file)
  }

  return (
    <div
      style={{ position: 'relative', cursor: 'pointer' }}
      onClick={() => (inputRef.current as any).click()}
    >
      {url ? (
        <MaterialAvatar className={classes.editableLarge} src={url} />
      ) : (
        <LetterAvatar
          name={name}
          alt={name}
          className={classes.letterLarge}
          fontStyle={{ fontSize: 30 }}
        />
      )}
      {name?.length === 1 && url?.length === 0 ? (
        <Person
          style={{
            position: 'absolute',
            top: 24,
            left: 25,
            zIndex: 999,
            fontSize: 40,
            color: 'rgba(0,0,0,0.7)',
          }}
        />
      ) : null}
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          position: 'absolute',
          height: 26,
          width: 26,
          right: 0,
          bottom: 0,
          borderRadius: '50%',
          backgroundColor: color.white,
          border: `1px solid ${color.shade10}`,
        }}
      >
        <Create htmlColor={color.black} style={{ fontSize: 16 }} />
      </div>

      <input
        ref={inputRef}
        style={{ display: 'none' }}
        type="file"
        onChange={(e) => handleAvatar(e.target.files ? e.target.files[0] : undefined)}
      />
    </div>
  )
}

export const Avatar = observer((props: any) => {
  const classes = useStyles()
  const { url, name, size, fontStyle, onChange, style } = props

  if (!url && !name) {
    return <Skeleton variant="circular" width={size} height={size} />
  } else if (onChange) {
    return <EditableAvatar onChange={onChange} url={url} name={name} />
  } else if (url) {
    return <MaterialAvatar style={{ height: size, width: size, ...style }} src={url} />
  } else {
    return (
      <LetterAvatar
        name={name}
        alt={name}
        fontStyle={fontStyle}
        style={{ height: size, width: size, ...style }}
        className={classes.blue}
      />
    )
  }
})
