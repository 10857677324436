import React, { useState, useEffect } from 'react'

import { InputAdornment, TextField } from '@mui/material'
import { Search } from '@mui/icons-material'

import { useElementOnScreen } from '../../../hooks'
import { createFileFromPath } from '@trivie/core'
import { getImages } from '../../../utils/unsplash'
import { debounce } from '../../../utils/debounce'
import { Dialog } from '@trivie/ui-web/components/dialogs/dialog'
import { color } from '@trivie/ui-web/theme/color'

type UnsplashImageDialogProps = {
  open: boolean
  handleImageDialogClose: () => void
  onChangeImage: (file: File) => void
}

export default function UnsplashImageDialog(props: UnsplashImageDialogProps) {
  const { handleImageDialogClose, open, onChangeImage } = props
  const PER_PAGE = 20
  const DEFAULT_FILTER = 'corporate training'
  const DEBOUNCE_TIME = 1000
  const [filter, setFilter] = useState('')
  const [currentPage, setCurrentPage] = useState(1)
  const [totalPages, setTotalPages] = useState<number | null>(null)
  const [data, setData] = useState<any>([])
  const [containerRef, isVisible] = useElementOnScreen({
    root: null,
    rootMargin: '0px',
    threshold: 0.2,
  })

  const noResultFound = !data?.length
  const hasMoreItems = !(currentPage === totalPages)

  const onfilterChange = debounce((e: any) => {
    loadImages([], 1, e?.target?.value ?? DEFAULT_FILTER)
  }, DEBOUNCE_TIME)

  const loadImages = async (pData: any, pCurrentPage: any, pFilter: any) => {
    const result = await getImages({
      query: pFilter || DEFAULT_FILTER,
      page: pCurrentPage,
      perPage: PER_PAGE,
    })
    if (result?.errors) {
      // eslint-disable-next-line no-useless-return
      return
    } else if (result?.response?.results) {
      setData([...pData, ...(result?.response?.results ?? {})])
      setCurrentPage(() => pCurrentPage)
      setTotalPages(result?.response?.total_pages)
      setFilter(pFilter)
    }
  }

  useEffect(() => {
    if (isVisible && hasMoreItems) {
      loadImages(data, currentPage + 1, filter)
    }
  }, [isVisible])

  useEffect(() => {
    if (open) {
      loadImages([], 1, '')
    }
  }, [open])

  const onClickImage = (item: any) => async () => {
    const fileData = await createFileFromPath(item?.urls?.regular, `${item?.id}.jpg`)
    onChangeImage(fileData)
    handleImageDialogClose()
  }

  return (
    <Dialog
      onClose={handleImageDialogClose}
      aria-labelledby="customized-dialog-title"
      open={open}
      title="Unsplash Image Library"
      sx={{
        '& .MuiDialog-paper': {
          width: '700px !important',
        },
      }}
    >
      <TextField
        data-qat="unsplash-search-input"
        variant="outlined"
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <Search htmlColor={color.shade70} />
            </InputAdornment>
          ),
        }}
        onKeyPress={(e: any) => {
          if (e.key === 'Enter') {
            e.preventDefault()
            e.stopPropagation()
          }
        }}
        style={{ marginBottom: 20 }}
        placeholder="Type a search term here"
        fullWidth
        onChange={onfilterChange}
      />
      <div
        style={{
          gridTemplateColumns: '220px 220px 220px',
          gridTemplateRows: '120px 120px 120px',
          display: 'grid',
          justifyItems: 'center',
          overflowX: 'hidden',
        }}
      >
        {noResultFound ? (
          <div style={{ position: 'absolute', top: '50%', color: 'rgba(0, 0, 0, 0.5)' }}>
            No Result Found
          </div>
        ) : (
          (data.length ? data : []).map((item: any, inx: number) => (
            <div key={`gallery-item-${inx}`}>
              <div
                key={item?.id}
                onKeyPress={(e) => {
                  const event = new MouseEvent('click')
                  e.target.dispatchEvent(event)
                }}
                role="button"
                tabIndex={0}
                onClick={onClickImage(item)}
                style={{
                  marginBottom: 17,
                }}
              >
                <img
                  style={{
                    borderRadius: 10,
                    width: 200,
                    height: 100,
                    cursor: 'pointer',
                    objectFit: 'cover',
                  }}
                  alt={item?.alt_description}
                  src={item?.urls?.thumb}
                />
              </div>
            </div>
          ))
        )}
      </div>
      {noResultFound ? null : hasMoreItems ? (
        <div ref={containerRef as any} style={{ textAlign: 'center' }}>
          Loading
        </div>
      ) : null}
    </Dialog>
  )
}
