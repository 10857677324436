import React, { useEffect, useState } from 'react'
import { observer } from 'mobx-react-lite'
import { Route, Switch, useHistory } from 'react-router-dom'
import { Add } from '@mui/icons-material'

import { useStores } from '@trivie/core'
import { Header } from '@trivie/ui-web/components/header'
import { TableHelper } from '../../../tables'
import { PathTable } from '../../../tables/path-table'
import { PathDetailsScreen } from './path-details-screen'

export const PathsScreen: React.FunctionComponent = observer(() => {
  const { contentCreationStore, manageUserStore } = useStores()
  const { setPublishedSuccessful, resetContentStore } = contentCreationStore
  const { permissions } = manageUserStore

  const [tableHelper] = useState(new TableHelper('list_paths', 'paths', 'paths'))

  const history = useHistory()

  useEffect(() => {
    setPublishedSuccessful(false)
    return () => {
      setPublishedSuccessful(false)
    }
  }, [])

  const handleCreatePath = () => {
    resetContentStore()
    history.push('/content/creation/path')
  }

  const primary = {
    disabled: !permissions?.permissions.some((p) => p.code === 'can_create_path'),
    startIcon: <Add />,
    onClick: handleCreatePath,
    text: 'Create Path',
  }

  return (
    <Switch>
      <Route exact path="/content/paths">
        <Header divider title="Paths" primaryAction={primary} />
        <PathTable tableHelper={tableHelper} />
      </Route>
      <Route path="/content/paths/:id/:tab">
        <PathDetailsScreen />
      </Route>
    </Switch>
  )
})
