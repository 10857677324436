import React, { useState } from 'react'
import { Route, Switch, useHistory } from 'react-router-dom'
import { observer } from 'mobx-react-lite'
import { AddRounded } from '@mui/icons-material'

import { Header } from '@trivie/ui-web/components/header'
import { NavigationTabs } from '@trivie/ui-web/components/navigation-tabs'

import { reportsTabs } from './tabs'
import { CreateReportDialog } from '@trivie/ui-web/components/create-report/create-report-dialog'
import { TableApi } from '@trivie/core/src/services/api-objects'
import { ViewReportScreen } from './view-report-screen'
import { useStores } from '@trivie/core'

export const ReportsScreen: React.FunctionComponent = observer(() => {
  const history = useHistory()
  const { reportStore } = useStores()
  const { baseViews, setShowCreateReport, showCreateReport } = reportStore
  const [createdID, setCreatedID] = useState<any>()

  const primary = {
    startIcon: <AddRounded />,
    onClick: () => setShowCreateReport(true),
    text: 'Create Report',
  }

  return (
    <Switch>
      <Route exact path="/insights/reports/:mainTab">
        <Header title="Reports" primaryAction={primary} />
        {showCreateReport && (
          <CreateReportDialog
            baseViews={baseViews}
            open={showCreateReport}
            onClose={() => {
              setShowCreateReport(false)
              if (createdID) {
                history.push(`/insights/reports/custom/${createdID}?open=true`)
                setCreatedID(null)
              }
            }}
            onCreate={(payload) =>
              TableApi.saveCustomReport(payload).then((resp) => setCreatedID(resp.data.id))
            }
          />
        )}
        <NavigationTabs tabList={reportsTabs} />
      </Route>
      <Route exact path="/insights/reports/:mainTab/:id">
        <ViewReportScreen />
      </Route>
    </Switch>
  )
})
