import { mergeAttributes } from '@tiptap/core'
import { ReactNodeViewRenderer } from '@tiptap/react'
import { TableNode } from './table-node'

import Table from '@tiptap/extension-table'

export const TTable = Table.extend({
  addOptions() {
    return {
      ...this.parent?.(),
      HTMLAttributes: {},
      quiz: {},
      selectQuestion: (q: any) => {},
      resizable: false,
      handleWidth: 0,
      cellMinWidth: 80,
      lastColumnResizable: false,
      allowTableNodeSelection: false,
    }
  },
  addAttributes() {
    return {
      ...this.parent?.(),
      generated_question_ids: [],
      node_id: 0,
      question_id: [],
      citations: [],
      generating: false,
    }
  },
  draggable: false,
  content: 'inline*',
  renderHTML({ node, HTMLAttributes }) {
    return [`div`, mergeAttributes(this.options.HTMLAttributes, HTMLAttributes), 0]
  },
  addNodeView() {
    return ReactNodeViewRenderer(TableNode, {
      contentDOMElementTag: 'tbody',
      attrs: {
        ...this.options.HTMLAttributes,
        style: `overflow-x: scroll;`,
      },
    })
  },
})

export interface tableOptions {
  HTMLAttributes: Record<string, any>
  quiz: any
  selectQuestion: (q: any) => void
}
