import React, { useState } from 'react'
import { formatBytes, isFileSupported } from '@trivie/core'
import { color } from '@trivie/ui-web/theme'
import { Text } from '@trivie/ui-web/components/text'
import { AutorenewRounded, Check, NoteAdd, WarningRounded } from '@mui/icons-material'

export interface FileUploadProps {
  style?: React.CSSProperties
  file?: any
  mainText?: string
  helperText?: string
  onChange: (e: any) => void
  value?: any
  accept: string
  progress?: any
  showSize?: boolean
  contentStyle?: React.CSSProperties
  bodyComponent?: JSX.Element
  onCancel?: () => void
  error?: string | null
  disabled?: boolean
}

export const FileUpload = React.forwardRef((props: FileUploadProps, ref: any) => {
  const {
    style,
    contentStyle,
    onChange,
    helperText,
    mainText,
    file,
    showSize,
    accept,
    progress,
    bodyComponent,
    onCancel,
    error,
    disabled,
  } = props

  const [fileTooLarge, setFileTooLarge] = useState(false)
  const [invalidFiletype, setInvalidFiletype] = useState(false)

  const handleFile = (files: FileList | null) => {
    if (disabled) {
      return
    }

    if (files && files[0]) {
      // use a regex to grab the characters after the last dot
      const re = /(?:\.([^.]+))?$/
      // @ts-ignore
      const extension = re.exec(files[0].name)[1]

      if (!isFileSupported(extension) || (accept && !accept.includes(files[0].type))) {
        setInvalidFiletype(true)
      } else if (files[0].size >= 100000000) {
        setFileTooLarge(true)
      } else {
        onChange(files ? files[0] : null)
        setFileTooLarge(false)
      }
    }
  }

  const addBreakOpportunities = (text: string) => {
    const words = text.split(' ')
    if (words.some((w) => w.length > 20)) {
      const segments = text.match(/.{1,20}/g)
      return segments?.flatMap((s, index) => {
        if (index + 1 > segments.length) {
          return [s]
        } else {
          return [s, <wbr />]
        }
      })
    } else {
      return text
    }
  }

  const hasFile = !!file

  return hasFile ? (
    <label
      style={{
        ...BASE,
        backgroundColor: error
          ? color.red
          : progress.loaded === progress.total
            ? color.green
            : 'rgba(240, 242, 245, 0.5)',
      }}
      onDragOver={(e) => e.nativeEvent.preventDefault()}
      onDrop={(e) => {
        e.nativeEvent.preventDefault()
        handleFile(e.dataTransfer.files)
      }}
      ref={ref}
    >
      <input
        disabled={Boolean(disabled)}
        type="file"
        style={{ ...ACCESSIBLE_HIDDEN_STYLE }}
        onChange={(e) => handleFile(e.target.files || null)}
        accept={accept}
      />

      {error ? (
        <WarningRounded htmlColor={color.white} />
      ) : progress.loaded === progress.total ? (
        <Check htmlColor={color.white} />
      ) : (
        <AutorenewRounded
          sx={{
            animation: 'spin 2s linear infinite',
            '@keyframes spin': {
              '0%': {
                transform: 'rotate(-360deg)',
              },
              '100%': {
                transform: 'rotate(0deg)',
              },
            },
          }}
          htmlColor={color.shade50}
        />
      )}
      <div
        style={{
          display: 'flex',
          flex: 1,
          flexDirection: 'column',
          flexWrap: 'nowrap',
          marginLeft: 16,
        }}
      >
        <Text
          variant="body1Medium"
          text={
            error ? error : progress.loaded === progress.total ? 'File Uploaded' : 'Uploading...'
          }
          style={{
            fontWeight: 500,
            color: progress.loaded === progress.total || error ? color.white : color.shade70,
            marginBottom: 6,
          }}
        />
        <Text
          variant="body1"
          // @ts-ignore
          text={
            error
              ? 'There was an issue processing the file provided'
              : `${addBreakOpportunities(file!.name)} - ${
                  progress.loaded !== progress.total ? 'Uploading - ' : 'Uploaded - '
                } ${formatBytes(file.size)} - ${Math.round(
                  (progress.loaded / progress.total) * 100,
                )}%`
          }
          style={{
            color: progress.loaded === progress.total || error ? color.white : color.shade70,
            fontSize: 12,
          }}
        />
      </div>
    </label>
  ) : (
    <>
      <label
        style={{ ...BASE, ...style }}
        onDragOver={(e) => e.nativeEvent.preventDefault()}
        onDrop={(e) => {
          e.nativeEvent.preventDefault()
          handleFile(e.dataTransfer.files)
        }}
        ref={ref}
      >
        <input
          disabled={Boolean(disabled)}
          type="file"
          style={{ ...ACCESSIBLE_HIDDEN_STYLE }}
          onChange={(e) => handleFile(e.target.files || null)}
          accept={accept}
        />
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-start',
            ...contentStyle,
          }}
        >
          <NoteAdd htmlColor={color.shade50} />
          <div style={{ display: 'flex', flexDirection: 'column', marginLeft: 16 }}>
            <div style={{ display: 'flex', flexWrap: 'nowrap', marginBottom: 6 }}>
              <Text
                variant="body1Medium"
                text="Upload"
                style={{
                  fontWeight: 600,
                  textDecoration: 'underline',
                  marginRight: 4,
                  color: color.shade70,
                }}
              />
              <Text variant="body1" text="or" style={{ color: color.shade70, marginRight: 4 }} />
              <Text
                variant="body1Medium"
                text="Drag and Drop"
                style={{ fontWeight: 600, color: color.shade70, marginRight: 4 }}
              />
              <Text variant="body1" text="a file here" style={{ color: color.shade70 }} />
            </div>
            <Text
              variant="body1"
              text="Works with PDF and most Office documents"
              style={{ fontSize: 12, color: color.shade70 }}
            />

            {bodyComponent ? bodyComponent : null}
            {!error && helperText && (
              <Text variant="body2Medium" text={helperText} style={{ color: color.shade70 }} />
            )}
            {invalidFiletype ? (
              <Text
                variant="body2Medium"
                text="Invalid file type."
                style={{ color: color.palette.red, marginTop: 4 }}
              />
            ) : fileTooLarge ? (
              <Text
                variant="body2Medium"
                text="Files must be smaller than 100 MB"
                style={{ color: color.palette.red, marginTop: 4 }}
              />
            ) : null}
          </div>
        </div>
      </label>
    </>
  )
})

const BASE: React.CSSProperties = {
  display: 'flex',
  backgroundColor: 'rgba(240, 242, 245, 0.5)',
  border: `1px dashed ${color.shade30}`,
  borderRadius: 10,
  padding: 24,
  cursor: 'pointer',
  height: 86,
  // width: 434,
  alignItems: 'center',
}

const ACCESSIBLE_HIDDEN_STYLE: React.CSSProperties = {
  clip: 'rect(1px, 1px, 1px, 1px)',
  clipPath: 'inset(50%)',
  height: 1,
  width: 1,
  margin: -1,
  overflow: 'hidden',
  padding: 0,
  position: 'absolute',
}
