import React, { useEffect, useState } from 'react'
import { observer } from 'mobx-react-lite'
import { useStores } from '@trivie/core'
import { TableHelper } from '../../../../tables'
import { useRouteMatch } from 'react-router-dom'
import { TopicTable } from '../../../../tables/topic-table'

const Topics: React.FunctionComponent = observer(() => {
  const { tagStore } = useStores()
  const { selectedTagId, setSelectedTagId } = tagStore

  const match = useRouteMatch<any>('/people/tags/:id/topics')
  const tagID = Number(match?.params.id)

  const [tableHelper] = useState(new TableHelper('list_tag_topics', 'topics', 'tags', tagID))

  useEffect(() => {
    if (!selectedTagId) {
      setSelectedTagId(tagID)
    }
  }, [])

  return <TopicTable tableHelper={tableHelper} />
})

export const TagsTopicsTab = (props: any) => {
  const { id } = props
  return {
    name: 'Topics',
    path: `/people/tags/${id}/topics`,
    component: () => <Topics />,
  }
}
