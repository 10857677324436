import React from 'react'
import { useHistory } from 'react-router-dom'

import { Dialog } from '../dialogs/dialog'

export const MiddlewareDialog = (props: any) => {
  const { message, upgrade, title, onClose, isOpen, forceLogout } = props

  const history = useHistory()

  const handleClose = () => {
    onClose()
    if (forceLogout) {
      history.replace('/logout')
    } else if (upgrade) {
      history.replace('/upgrade')
    }
  }

  return (
    <Dialog
      onConfirm={handleClose}
      confirmBtnLabel={upgrade ? 'Upgrade' : 'Close'}
      onClose={handleClose}
      title={title}
      maxWidth="xs"
      open={isOpen}
      dialogText={message}
    />
  )
}
