import { Dialog, DialogActions, DialogContent, IconButton } from '@mui/material'
import { Close } from '@mui/icons-material'
import { observer } from 'mobx-react-lite'
import React from 'react'
import { color } from '../../theme'
import { Button } from '../button'
import { Text } from '../text'
import { useStores } from '@trivie/core'

export const AppInfoDialog = observer((props: any) => {
  const { open, onClose } = props
  const { manageUserStore } = useStores()
  const { environment } = manageUserStore

  const handleClose = () => {
    onClose()
  }

  let env = 'Production'

  if (environment.api.config.url.includes('test')) {
    env = 'Test'
  } else if (environment.api.config.url.includes('dev')) {
    env = 'Development'
  }

  return (
    <Dialog maxWidth="xs" aria-labelledby="confirmation-dialog-title" open={open}>
      <div style={HEADER}>
        <Text variant="body1Emphasized" text="Environment Info" />
        <IconButton onClick={() => handleClose()} size="large">
          <Close htmlColor={color.shade50} style={{ fontSize: 22 }} />
        </IconButton>
      </div>

      <DialogContent>
        <Text
          variant="body1Medium"
          text={`You are on version ${localStorage.getItem(
            'version',
          )}, environment ${env}, workspace code ${localStorage.getItem('access_code')}.`}
          style={{ marginTop: 32, marginBottom: 50, textAlign: 'center' }}
        />
      </DialogContent>
      <DialogActions style={{ paddingRight: 20, paddingBottom: 20, paddingLeft: 20 }}>
        <Button text="Okay" onClick={handleClose} />
      </DialogActions>
    </Dialog>
  )
})

const HEADER: React.CSSProperties = {
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  paddingLeft: 20,
  paddingTop: 10,
  paddingBottom: 10,
}
