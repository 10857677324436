import React, { useEffect, useState } from 'react'
import { TextField } from '@mui/material'
import { observer } from 'mobx-react-lite'

import { useStores } from '@trivie/core'
import { ImageSelector } from '@trivie/ui-web/components/image-selector'
import { Panel } from '@trivie/ui-web/components/panel/panel'
import UnsplashImageDialog from '../../content-creation/unsplash-image-dialog'
import { ImageCropDialog } from '@trivie/ui-web/components/image-cropper/image-crop-dialog'
import { WorkflowApi } from '@trivie/core/src/services/api-objects/WorkflowApi'

export const PathSettings = observer(() => {
  const { pathStore } = useStores()
  const { path } = pathStore

  const [showUnsplashDialog, setShowUnsplashDialog] = useState(false)
  const [showCropper, setShowCropper] = useState(false)
  const [file, setFile] = useState<any>()
  const [pathNames, setPathNames] = useState([])
  const [originalName, setOriginalName] = useState<any>('')

  const handleImage = (f: File | null) => {
    if (!f) {
      path?.setImage(f)
    } else {
      setFile(f)
      setShowCropper(true)
    }
  }

  useEffect(() => {
    WorkflowApi.getPaths('paths', false, [{ field: 'path_name' }], 'list_paths').then(
      (res: any) => {
        setPathNames(
          res.data.rows.map((row) => {
            const name = row.columns
              .find((c) => c.field === 'path_name')
              ?.value?.replace(/\s+/g, '')
              ?.toLowerCase()
            if (row.id === path?.path_id) {
              setOriginalName(name)
            }
            return row.columns[0]?.value?.replace(/\s+/g, '')?.toLowerCase()
          }),
        )
      },
    )
  }, [])

  useEffect(() => {
    if (
      pathNames &&
      pathNames.includes(path?.name?.replace(/\s+/g, '')?.toLowerCase() as never) &&
      path?.name?.replace(/\s+/g, '').toLowerCase() !==
        originalName?.replace(/\s+/g, '')?.toLowerCase()
    ) {
      path?.setDuplicateName(true)
    } else {
      path?.setDuplicateName(false)
    }
  }, [pathNames, path?.name])

  return (
    <Panel>
      <ImageSelector
        fullWidth
        pickerStyle={{ height: 200, marginBottom: 0 }}
        onUnsplashClick={() => setShowUnsplashDialog(true)}
        imageSrc={path?.image}
        file={path?.imageFile}
        onChange={(f: File | null) => handleImage(f)}
      />
      <TextField
        variant="standard"
        sx={{
          '& .MuiInputBase-root': { fontSize: 28, lineHeight: '34px', fontWeight: 600 },
          '& .MuiFormHelperText-root': {
            color: path?.name?.length === 100 ? 'error.main' : '',
          },
        }}
        multiline
        inputProps={{ maxLength: 100 }}
        helperText={
          path?.duplicate_name ? 'This name is already in use.' : `${path?.name?.length}/100`
        }
        error={path?.duplicate_name}
        size="medium"
        placeholder="Learning Path Name"
        value={path?.name}
        onChange={(e: any) => path?.setName(e.target.value)}
      />
      <TextField
        multiline
        variant="standard"
        sx={{
          '& .MuiInputBase-root': {
            fontSize: 12,
            fontWeight: 400,
            width: '100%',
          },
          '& .MuiFormHelperText-root': {
            color: path?.description?.length === 1000 ? 'error.main' : '',
          },
        }}
        size="small"
        fullWidth
        inputProps={{ maxLength: 1000 }}
        helperText={`${path?.description?.length}/1000`}
        placeholder="Description (Optional)"
        value={path?.description}
        onChange={(e) => path?.setDescription(e.target.value)}
      />
      {showUnsplashDialog && (
        <UnsplashImageDialog
          onChangeImage={handleImage}
          handleImageDialogClose={() => setShowUnsplashDialog(false)}
          open={showUnsplashDialog}
        />
      )}
      {showCropper && (
        <ImageCropDialog
          file={file}
          open={showCropper}
          onConfirm={(file: File) => {
            path?.setImage(file)
            setShowCropper(false)
          }}
          onClose={() => {
            path?.setImage(null)
            setShowCropper(false)
          }}
        />
      )}
    </Panel>
  )
})
