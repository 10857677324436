import { SnapshotIn, types, cast } from 'mobx-state-tree'

export const CreateReportForm = types
  .model('CreateReportForm')
  .props({
    template: types.model({
      name: types.string,
      view_name: types.string,
      base_view_id: types.number,
      columns: types.frozen(),
    }),
    name: types.string,
    description: types.string,
  })
  .views((self) => {
    return {
      get payload() {
        return {
          view_name: self.name,
          view_description: self.description,
          method: 'and',
          columns: self.template.columns,
          base_view_id: self.template.base_view_id,
          is_report: true,
        }
      },
      get errors() {
        const errors: any = {}
        if (!self.name) {
          errors.name = {
            type: 'required',
            message: 'Name is required',
          }
        }

        if (self.name && self.name?.length > 50) {
          errors.name = {
            type: 'required',
            message: 'Maximum of 50 characters',
          }
        }

        if (self.description && self.description?.length > 250) {
          errors.description = {
            type: 'required',
            message: 'Maximum of 250 characters',
          }
        }

        // no need to validate beyond this since we're missing required fields
        if (Object.keys(errors).length) {
          return errors
        }

        return errors
      },
    }
  })
  .actions((self) => {
    return {
      set<K extends keyof SnapshotIn<typeof self>, T extends SnapshotIn<typeof self>>(
        key: K,
        value: T[K],
      ) {
        self[key] = cast(value)
      },
    }
  })
