import React, { useEffect } from 'react'
import { observer } from 'mobx-react-lite'
import { color } from '@trivie/ui-web/theme'
import { Header } from '@trivie/ui-web/components/header'
import { Avatar } from '@trivie/ui-web/components/avatar'
import { Text } from '@trivie/ui-web/components/text'
import { VerticalDivider } from '@trivie/ui-web/components/vertical-divider'
import { ActivityItem } from '@trivie/ui-web/components/activity-item'
import { TransformedActivityModel, useStores } from '@trivie/core'
import { Box } from '@mui/material'
import { GetApp } from '@mui/icons-material'
import { Skeleton } from '@mui/lab'

// @ts-ignore
import html2pdf from 'html2pdf.js'
import { Panel } from '@trivie/ui-web/components/panel/panel'

export const ActivityScreen: React.FunctionComponent = observer(() => {
  const { reportStore, manageUserStore } = useStores()
  const { activityLog, getActivityLog } = reportStore
  const { permissions } = manageUserStore

  useEffect(() => {
    getActivityLog()
  }, [])

  const getPdf = async () => {
    const opt = {
      filename: 'activity.pdf',
      image: { type: 'jpeg', quality: 0.98 },
      html2canvas: { scale: 2 },
      jsPDF: { unit: 'in', format: 'a4', orientation: 'portrait' },
    }
    const ipt = document.querySelector('main')
    html2pdf().set(opt).from(ipt).save()
  }

  const disabled = Boolean(
    !permissions?.roles.some((r) => ['Moderator', 'Administrator', 'Owner'].includes(r.name)),
  )

  if (disabled) {
    return null
  }

  const primary = {
    disabled: !permissions?.permissions.some((p) => p.code === 'can_create_assignment'),
    startIcon: <GetApp />,
    onClick: getPdf,
    text: 'Export',
  }

  return (
    <>
      <Header divider title="Administrator Activity Log" primaryAction={primary} />
      <Panel title={'Latest Activity'}>
        <Box>
          {activityLog ? (
            Object.entries(activityLog)
              .map((entry: any, entryIndex: number, entryArr: any) => {
                const [date, activities] = entry

                return [
                  <Text
                    variant="body2Emphasized"
                    text={date}
                    style={{ color: color.palette.quizSummary }}
                  />,
                  <VerticalDivider short />,
                  ...activities.map(
                    (
                      activity: TransformedActivityModel,
                      activityIndex: number,
                      activityArr: any,
                    ) => {
                      return (
                        <>
                          <div key={activity.id} style={{ display: 'flex', alignItems: 'center' }}>
                            <Avatar
                              name={activity.name}
                              url={activity.photo || activity.user_photo_url}
                            />
                            <div
                              style={{ display: 'flex', flexDirection: 'column', marginLeft: 16 }}
                            >
                              <Text variant="body1Emphasized" text={activity.name} />
                              <div style={{ display: 'flex' }}>
                                <ActivityItem
                                  marginFix={activity.marginFix}
                                  withName
                                  data={activity.link}
                                />
                                {activity.elapsedTime ? (
                                  <div style={{ marginLeft: 4 }}>
                                    <Text
                                      variant="body2"
                                      text={`• ${activity.elapsedTime}`}
                                      style={{ color: color.shade70 }}
                                    />
                                  </div>
                                ) : null}
                              </div>
                            </div>
                          </div>
                          {activityIndex !== activityArr.length - 1 ||
                          entryIndex !== entryArr.length - 1 ? (
                            <VerticalDivider />
                          ) : null}
                        </>
                      )
                    },
                  ),
                ]
              })
              .flat()
          ) : (
            <>
              <Skeleton variant="text" width={37} />
              <VerticalDivider short />
              {Array(3)
                .fill(null)
                .map((x: any, index: any) => (
                  <>
                    <div key={index} style={{ display: 'flex', alignItems: 'center' }}>
                      <Skeleton variant="circular" height={40} width={40} />
                      <div style={{ display: 'flex', flexDirection: 'column', marginLeft: 16 }}>
                        <Skeleton width={90} />
                        <Skeleton width={240} />
                      </div>
                    </div>
                    {index < 2 ? <VerticalDivider /> : null}
                  </>
                ))}
            </>
          )}
        </Box>
      </Panel>
    </>
  )
})
