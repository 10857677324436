import React, { useEffect, useState } from 'react'
import { observer } from 'mobx-react-lite'
import { Redirect, useHistory } from 'react-router'
import { InputAdornment, TextField } from '@mui/material'
import { ChevronLeftRounded, DomainRounded, Search } from '@mui/icons-material'

import { useStores } from '@trivie/core'
import { color } from '@trivie/ui-web/theme/color'
import { Text } from '@trivie/ui-web/components/text'
import { Button } from '@trivie/ui-web/components/button'

import { Logo } from '../logo'
import right from './right.png'
import { useLocation } from 'react-router-dom'

const useQuery = () => {
  const { search } = useLocation()

  return React.useMemo(() => new URLSearchParams(search), [search])
}

export const WorkspaceScreen: React.FunctionComponent = observer(() => {
  const { manageUserStore, uiStore } = useStores()
  const { quantum } = uiStore

  const { instance, userProfile, loginWorkspace, setSSOWorkspace, status } = manageUserStore

  const query = useQuery()
  const history = useHistory()

  const [forceReload, setForceReload] = useState(query.get('reload'))
  const [searchTerm, setSearchTerm] = useState('')
  const [selectedWorkspace, setSelectedWorkspace] = useState<any>({})

  const t = localStorage.getItem('token')
  useEffect(() => {
    if (userProfile) {
      if (status === 'error') {
        return
      }

      if (userProfile) {
        history.replace('/content/quizzes')

        if (forceReload) {
          window.location.reload()
        }
      }

      if (forceReload) {
        window.location.reload()
      }
    }
  }, [userProfile?.id, t])

  const handleBack = () =>
    loginWorkspace(instance.token, localStorage.getItem('access_code')).then(() =>
      history.replace('/login'),
    )

  const login = async () => {
    if (!instance) {
      throw new Error('No instance is set')
    }

    if (selectedWorkspace.has_sso_config) {
      setSSOWorkspace(selectedWorkspace)
      history.replace(`/login/sso/${selectedWorkspace.access_code}`)
    } else {
      loginWorkspace(instance.token, selectedWorkspace.access_code).then((data) => {
        if (userProfile && localStorage.getItem('token')) {
          history.replace('/content/quizzes')

          if (forceReload) {
            window.location.reload()
          }
        }
      })
    }
  }

  return instance ? (
    <div style={ROOT}>
      <div style={LEFT_CONTAINER}>
        <div style={CONTENT}>
          <div style={{ marginBottom: 32 }}>
            <Logo quantum={quantum} />
          </div>
          <div style={{ flexDirection: 'column', display: 'flex' }}>
            <Text
              style={{ marginBottom: 24 }}
              variant="headline"
              tx="Onboarding.Login.selectWorkspace"
            />
            <Text
              style={{ marginBottom: 60, color: color.shade70, fontWeight: 500, fontSize: 16 }}
              variant="titleMedium"
              text={`Choose the ${process.env.REACT_APP_SITE_TITLE} workspace you want to manage.`}
            />
          </div>
          <div
            style={{
              ...LIST_CONTAINER,
              height: `${76 * instance.workspaces?.length + 100}px`,
              maxHeight: `calc(100vh - 440px)`,
            }}
          >
            <TextField
              placeholder="Search Workspaces"
              style={{ marginBottom: 16, paddingRight: 20 }}
              variant="standard"
              onChange={(e) => setSearchTerm(e.target.value)}
              InputProps={{
                startAdornment: (
                  <InputAdornment style={{ backgroundColor: '#fff' }} position="start">
                    <Search htmlColor={color.shade70} />
                  </InputAdornment>
                ),
              }}
              inputProps={{ 'data-qat': 'search_workspaces_input' }}
              fullWidth
            />
            <ul style={LIST}>
              {instance.workspaces
                ?.filter((workspace) =>
                  workspace.company_name?.toLowerCase().includes(searchTerm.toLowerCase()),
                )
                .sort((a, b) => a.company_name.localeCompare(b.company_name))
                .map((workspace: any, index: number) => {
                  return (
                    <li
                      data-qat={`workspace_${index}`}
                      key={`${workspace.access_code}`}
                      onClick={() => setSelectedWorkspace(workspace)}
                      style={{
                        ...LIST_ITEM,
                        backgroundColor:
                          selectedWorkspace.access_code === workspace.access_code
                            ? color.blue
                            : '#F8FAFD',
                      }}
                    >
                      <div style={{ display: 'flex', alignItems: 'center' }}>
                        <DomainRounded
                          style={{
                            marginRight: 20,
                            color:
                              selectedWorkspace.access_code === workspace.access_code
                                ? color.white
                                : '#4F5E74',
                          }}
                        />
                        <Text
                          variant="body1Emphasized"
                          style={{
                            color:
                              selectedWorkspace.access_code === workspace.access_code
                                ? color.white
                                : color.black,
                          }}
                          text={workspace.company_name}
                        />
                      </div>
                      {workspace.logo_path && (
                        <img src={workspace.logo_path} height={32} alt="company logo" />
                      )}
                    </li>
                  )
                })}
            </ul>
          </div>
          <div style={{ gap: 12, display: 'flex', justifyContent: 'center', flexDirection: 'row' }}>
            <Button
              text="Previous Step"
              data-qat="previous_step"
              variant="text"
              color="info"
              onClick={handleBack}
              style={{ color: color.shade70, textTransform: 'none', marginRight: 32 }}
              startIcon={<ChevronLeftRounded style={{ color: color.shade70, fontSize: 24 }} />}
            />
            <div style={{ display: 'flex' }} />
            <Button
              data-qat="confirm_workspace"
              disabled={!selectedWorkspace.access_code}
              text="Confirm Workspace"
              onClick={login}
            />
          </div>
        </div>
      </div>
      <div style={RIGHT_CONTAINER}>
        <img style={{ alignSelf: 'center' }} alt="blobs" height={315} width={315} src={right} />
      </div>
    </div>
  ) : (
    <Redirect to="/login" />
  )
})

const ROOT: React.CSSProperties = {
  display: 'flex',
  flexDirection: 'row',
  width: '100%',
}
const LEFT_CONTAINER: React.CSSProperties = {
  width: '75%',
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'center',
  backgroundColor: '#fff',
}
const RIGHT_CONTAINER: React.CSSProperties = {
  display: 'flex',
  flexDirection: 'row',
  width: '25%',
  backgroundColor: '#F6F8FB',
  justifyContent: 'center',
}
const CONTENT: React.CSSProperties = {
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  paddingTop: 120,
  paddingBottom: 80,
}
const LIST: React.CSSProperties = {
  overflowY: 'auto',
  paddingRight: 20,
  marginBottom: 40,
  listStyle: 'none',
  paddingLeft: 0,
}
const LIST_ITEM: React.CSSProperties = {
  cursor: 'pointer',
  width: '100%',
  padding: 20,
  paddingTop: 18,
  paddingBottom: 18,
  height: 60,
  borderRadius: 6,
  flexDirection: 'row',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  marginBottom: 16,
}
const LIST_CONTAINER: React.CSSProperties = {
  maxHeight: 'calc(100vh - 440px)',
  height: '100%',
  width: 632,
  display: 'flex',
  flexDirection: 'column',
}
