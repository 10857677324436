import { observer } from 'mobx-react-lite'
import React, { useEffect, useId, useState } from 'react'
import { useHistory, useParams } from 'react-router-dom'

import { useStores } from '@trivie/core'
import { Header } from '@trivie/ui-web/components/header'
import { NavigationTabs } from '@trivie/ui-web/components/navigation-tabs'

import { assignmentTabs } from './tabs'
import {
  CalendarTodayRounded,
  CloseRounded,
  Delete,
  EditRounded,
  NotificationsRounded,
  PlayCircleOutline,
  PlusOneRounded,
} from '@mui/icons-material'
import { AssignmentCreateDialog } from './create-assignment/assignment-create'
import { createStyles, makeStyles } from '@mui/styles'

const useToolbarStyles = makeStyles(() =>
  createStyles({
    menu: {
      '& .MuiPaper-root': {
        borderRadius: '6px !important',
        // marginTop: 2,
        marginLeft: -17,
        boxShadow: 'none !important',
        border: '1px solid #EFF0F6',
      },
    },
    popOverRoot: {
      pointerEvents: 'none',
    },
  }),
)

export const AssignmentDetailsScreen: React.FunctionComponent = observer(() => {
  const { id, mainTab } = useParams() as any
  const [showEdit, setShowEdit] = useState(false)

  const { assignmentStore, manageUserStore } = useStores()
  const { permissions } = manageUserStore
  const { assignmentOverviewStats } = assignmentStore
  const history = useHistory()

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
  const openActions = Boolean(anchorEl)

  let currentlyHovering = false

  const handleClick = (event) => {
    if (anchorEl !== event.currentTarget) {
      setAnchorEl(event.currentTarget)
    }
  }

  const handleHover = () => (currentlyHovering = true)

  const handleCloseHover = () => {
    currentlyHovering = false
    setTimeout(() => {
      if (!currentlyHovering) {
        handleClose()
      }
    }, 50)
  }

  const handleClose = () => setAnchorEl(null)

  const classes = useToolbarStyles()

  // Dialogs
  const [showConfirmDelete, setShowConfirmDelete] = useState(false)
  const [showExtendAssignment, setShowExtendAssignment] = useState(false)
  const [showAddUsers, setShowAddUsers] = useState(false)
  const [showSendMessage, setShowSendMessage] = useState(false)
  const [showConfirmEnd, setShowConfirmEnd] = useState(false)
  const [showConfirmStartNow, setShowConfirmStartNow] = useState(false)

  const getActions = () => {
    if (mainTab === 'active') {
      return [
        {
          label: 'Add Users',
          icon: PlusOneRounded,
          onClick: () => setShowAddUsers(true),
          disabled: !permissions?.permissions.some((p) => p.code === 'can_edit_assignment'),
        },
        {
          label: 'Nudge',
          icon: NotificationsRounded,
          onClick: () => setShowSendMessage(true),
          disabled: !permissions?.permissions.some((p) => p.code === 'can_message_user'),
        },
        {
          label: 'Extend',
          icon: CalendarTodayRounded,
          onClick: () => setShowExtendAssignment(true),
          disabled: !permissions?.permissions.some((p) => p.code === 'can_edit_assignment'),
        },
        {
          label: 'End',
          icon: CloseRounded,
          onClick: () => setShowConfirmEnd(true),
          disabled: !permissions?.permissions.some((p) => p.code === 'can_edit_assignment'),
        },
      ]
    } else if (mainTab === 'scheduled') {
      return [
        {
          label: 'Add Users',
          icon: PlusOneRounded,
          onClick: () => setShowAddUsers(true),
          disabled: !permissions?.permissions.some((p) => p.code === 'can_edit_assignment'),
        },
        {
          label: 'Edit Assignment',
          icon: EditRounded,
          onClick: () => setShowEdit(true),
          disabled: !permissions?.permissions.some((p) => p.code === 'can_edit_assignment'),
        },
        {
          label: 'Nudge',
          icon: NotificationsRounded,
          onClick: () => setShowSendMessage(true),
          disable: !permissions?.permissions.some((p) => p.code === 'can_message_user'),
        },
        {
          label: 'Start Now',
          icon: PlayCircleOutline,
          onClick: () => setShowConfirmStartNow(true),
          disabled: !permissions?.permissions.some((p) => p.code === 'can_edit_assignment'),
        },
        {
          label: 'Extend',
          icon: CalendarTodayRounded,
          onClick: () => setShowExtendAssignment(true),
          disabled: !permissions?.permissions.some((p) => p.code === 'can_edit_assignment'),
        },
        {
          label: 'End',
          icon: CloseRounded,
          onClick: () => setShowConfirmEnd(true),
          disabled: !permissions?.permissions.some((p) => p.code === 'can_edit_assignment'),
        },

        {
          label: 'Delete',
          icon: Delete,
          onClick: () => setShowConfirmDelete(true),
          disabled: !permissions?.permissions.some((p) => p.code === 'can_edit_assignment'),
        },
      ]
    } else {
      return [
        {
          label: 'Extend',
          icon: CalendarTodayRounded,
          onClick: () => setShowExtendAssignment(true),
          disabled: !permissions?.permissions.some((p) => p.code === 'can_edit_assignment'),
        },
        {
          label: 'Delete',
          icon: Delete,
          onClick: () => setShowConfirmDelete(true),
          disabled: !permissions?.permissions.some((p) => p.code === 'can_delete_assignment'),
        },
      ]
    }
  }

  useEffect(() => {
    if (permissions) {
      setActions(getActions())
    }
  }, [permissions?.permissions])

  const [actions, setActions] = useState<any>(getActions())

  const primary = mainTab === 'scheduled' && {
    disabled: !permissions?.permissions.some((p) => p.code === 'can_edit_assignment'),
    startIcon: <EditRounded />,
    onClick: () => setShowEdit(true),
    text: 'Edit Assignment',
  }

  return (
    <>
      <Header
        title={assignmentOverviewStats ? assignmentOverviewStats?.title : ''}
        primaryAction={primary}
      />
      {/* <Button
              aria-haspopup="true"
              aria-owns={anchorEl ? 'action-menu' : undefined}
              text="Actions"
              startIcon={<ListAltRounded />}
              onClick={handleClick}
              onMouseOver={handleClick}
              onMouseLeave={handleCloseHover}
              // onClick={() => {
              //   setShowEdit(true)
              // }}
            /> */}
      {/* <Menu
              id="actions-menu"
              className={classes.menu}
              style={{
                marginLeft: 16,
                borderRadius: 6,
                boxShadow: 'none',
                border: '1px solid #EFF0F6',
              }}
              anchorEl={anchorEl}
              open={openActions}
              PopoverClasses={{
                root: classes.popOverRoot,
              }}
              onClose={() => setAnchorEl(null)}
              MenuListProps={{
                'aria-labelledby': 'basic-button',
                onMouseEnter: handleHover,
                onMouseLeave: handleCloseHover,
                style: { pointerEvents: 'auto' },
              }}
            >
              {actions.map(action => (
                <MenuItem key={useId()} disabled={action.disabled} onClick={action.onClick}>
                  <action.icon
                    style={{
                      color: action.label === 'Delete' ? color.red : color.black,
                      height: 16,
                      width: 16,
                      marginRight: 5,
                    }}
                  />
                  <Text
                    variant="body1Medium"
                    style={{
                      color: action.label === 'Delete' ? color.red : color.black,
                    }}
                  >
                    {action.label}
                  </Text>
                </MenuItem>
              ))}
            </Menu> */}

      <NavigationTabs tabList={assignmentTabs} id={id} />
      {showEdit && (
        <AssignmentCreateDialog
          open={showEdit}
          editingId={id}
          requestClose={() => {
            setShowEdit(false)
            history.push(`/activities/assignments/${mainTab}/${id}/overview`)
          }}
        />
      )}
    </>
  )
})
