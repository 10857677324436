import React from 'react'
import { Add, Close, People } from '@mui/icons-material'
import { Text } from '../text'
import { color } from '../../theme'
import { IconButton } from '@mui/material'

export interface GroupProps {
  group: any
  onDelete?: (group: any) => void
  onAdd?: (group: any) => void
  style?: React.CSSProperties
}

export function Group(props: GroupProps) {
  const { group, style, onAdd, onDelete } = props

  const isDelete = !!onDelete
  const isAdd = !!onAdd

  return (
    <div
      style={{
        display: 'flex',
        height: 29,
        width: 'min-content',
        alignItems: 'center',
        backgroundColor: color.palette.selectedTag,
        borderRadius: '6px',
        padding: '6px 8px',
        paddingRight: 0,
        ...style,
      }}
    >
      <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <People />
        <Text variant="body1" text={group.label} style={{ whiteSpace: 'nowrap', paddingLeft: 8 }} />
      </div>
      {isAdd ? (
        <IconButton size="small" onClick={() => onAdd!(group)}>
          <Add htmlColor={color.palette.grey} />
        </IconButton>
      ) : null}

      {isDelete ? (
        <IconButton size="small" onClick={() => onDelete!(group.group_id)}>
          <Close htmlColor={color.palette.grey} />
        </IconButton>
      ) : null}
    </div>
  )
}
