import React, { useEffect, useState } from 'react'
import { Box, Skeleton, Stack } from '@mui/material'
import { Help } from '@mui/icons-material'

import { Text } from '../text'
import { color } from '../../theme'
import { LineGraph } from '../line-graph'
import { DescriptionTooltip } from '../stat'

interface InsightProps {
  title?: string
  value: any
  outlined?: boolean
  ofVal?: string
  style?: React.CSSProperties
  variant?: 'green' | 'red'
  loading?: boolean
  data?: any
  unit?: any
  timespan?: any
  valueFontSize?: string
  large?: boolean
  tooltip?: string
  noAveraging?: boolean
  textStyle?: any
  sx?: any
  titleVariant?: any
}

export function Insight(props: InsightProps) {
  const {
    titleVariant = 'body1Emphasized',
    sx,
    textStyle,
    title = '',
    timespan,
    value,
    ofVal,
    data,
    loading,
    unit,
    tooltip,
  } = props

  const [maxInt, setMaxInt] = useState(0)
  const [showTooltip, setShowTooltip] = useState(false)

  let displayValue = value
  let isLoading = false

  if (value === '0%' || value === '0.0%' || value === '0' || value === 0) {
    displayValue = '-'
  } else if (!value) {
    isLoading = true
  }

  useEffect(() => {
    try {
      if (data) {
        const maxInteger = data.reduce((sum: any, d: any) => sum + d.value, 0)
        setMaxInt(maxInteger)
      }
    } catch (e) {
      setMaxInt(0)
    }
  }, [data])

  // if (loading) {
  //   return (
  //     <Stack useFlexGap gap={1}>
  //       <Skeleton />
  //       <Skeleton height={42} width="45%" />
  //     </Stack>
  //   )
  // }

  return (
    <Box flex={1} sx={sx}>
      <Stack gap={2} useFlexGap spacing={3} flex={1} flexWrap="wrap">
        <Stack useFlexGap gap={1} alignItems="center" direction="row">
          {title ? (
            <Text variant={titleVariant} text={title} color="text.secondary" whiteSpace="normal" />
          ) : null}
          {tooltip && (
            <DescriptionTooltip open={showTooltip} placement="top" title={tooltip ?? ''}>
              <Help
                onMouseLeave={() => setShowTooltip(false)}
                onMouseEnter={() => setShowTooltip(true)}
                fontSize="small"
                style={{ cursor: 'pointer' }}
                htmlColor={color.shade30}
              />
            </DescriptionTooltip>
          )}
        </Stack>
        {data || displayValue || ofVal ? (
          <Stack useFlexGap direction="row" alignItems="center" flex={1}>
            {displayValue ? (
              <Text style={textStyle} variant="headline" text={displayValue} />
            ) : null}
            {ofVal ? (
              <Text
                variant="headline"
                text={ofVal}
                style={{ marginLeft: 5, color: color.shade30, whiteSpace: 'nowrap' }}
              />
            ) : undefined}
            {data && !loading && !isLoading && maxInt > 1 && (
              <LineGraph
                height={null}
                width={null}
                unit={unit}
                format={
                  timespan === 'Today' || timespan === 'Yesterday' ? 'yyyy-MM-dd:hh' : 'yyyy-MM-dd'
                }
                data={[...data]}
              />
            )}
          </Stack>
        ) : null}
      </Stack>
    </Box>
  )
}
