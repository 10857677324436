import React, { useEffect, useState } from 'react'
import { DateTime } from 'luxon'
import { observer } from 'mobx-react-lite'
import { useHistory, useRouteMatch } from 'react-router-dom'
import { Grid, Skeleton } from '@mui/material'
import { Edit, Email } from '@mui/icons-material'
import { Instance } from 'mobx-state-tree'

import {
  getStrongestWeakest,
  QuestionPctCorrectSummary,
  roundNumber,
  useStores,
} from '@trivie/core'
import { StrongestWeakest } from '@trivie/ui-web/components/strongest-weakest'
import { Text } from '@trivie/ui-web/components/text'
import { color } from '@trivie/ui-web/theme'
import { Avatar } from '@trivie/ui-web/components/avatar'
import { UserApi } from '@trivie/core/src/services/api-objects'
import { MessageDialog } from '@trivie/ui-web/components/message-dialog'
import { SimpleBarChart } from '@trivie/ui-web/components/simple-bar-chart'
import { HeaderPanel } from '@trivie/ui-web/components/header-panel'

import { TableHelper } from '../../../tables'
import backdrop from './user-backdrop.png'
import { EditUser } from '@trivie/ui-web/components/edit-user'
import { UserResultsTable } from '../../../tables/user-results-table'
import { Insight } from '@trivie/ui-web/components/insight'
import { Dialog } from '@trivie/ui-web/components/dialogs/dialog'

export const UserOverviewScreen = observer(() => {
  const { manageUserStore } = useStores()
  const { freeTier, selectedUserProfile, getSelectedUserProfile, permissions, deleteUsers } =
    manageUserStore

  const match = useRouteMatch<any>('/people/users/:id/:tab')
  const userID = Number(match?.params?.id)

  const [tableHelper] = useState(
    new TableHelper('list_user_results', 'user_results', 'user_results', userID),
  )
  const [loading, setLoading] = useState(false)
  const [showSendMessage, setShowSendMessage] = useState(false)
  const [showEdit, setShowEdit] = useState(false)
  const [showUpdateDialog, setShowUpdateDialog] = useState(false)
  const [showConfirmDelete, setShowConfirmDelete] = useState(false)
  const [questionGaps, setQuestionGaps] = useState<null | Array<
    Instance<typeof QuestionPctCorrectSummary>
  >>(null)
  const [user, setUser] = useState<any>()

  const history = useHistory()

  const [loadingUser, setLoadingUser] = useState(true)
  const [loadingKG, setLoadingKG] = useState(true)

  useEffect(() => {
    getSelectedUserProfile(userID)
    UserApi.getUserQuestionGaps(userID).then((response) => {
      if (response.ok && response.data) {
        setLoadingKG(false)
        setQuestionGaps(response.data.questions)
      }
    })
    loadUser()
  }, [])

  const loadUser = async () => {
    const users = await UserApi.getAllUsers(
      false,
      [
        { field: 'photo_url' },
        { field: 'identity' },
        { field: 'user_pct_correct' },
        { field: 'user_last_active' },
        { field: 'user_total_answers' },
        { field: 'account_created' },
        { field: 'user_quizzes_completed_count' },
        { field: 'user_avg_completion_time' },
        { field: 'user_quizzes_available' },
      ],
      'list_users',
    )

    const item = users.data.rows.find((q) => q.id === Number(userID))

    const createdDate = item.columns.find((column) => column.field === 'account_created').value
    const accountCreated = createdDate
      ? DateTime.fromISO(createdDate).toLocaleString(DateTime.DATE_MED)
      : ''
    const lastActiveDate = item.columns.find((column) => column.field === 'user_last_active').value
    const lastActive = lastActiveDate
      ? DateTime.fromISO(lastActiveDate).toLocaleString(DateTime.DATE_MED)
      : ''

    setUser({
      averageScore: roundNumber(
        item.columns.find((column) => column.field === 'user_pct_correct').value * 100,
      ),
      quizzesCompleted: item.columns.find(
        (column) => column.field === 'user_quizzes_completed_count',
      ).value,
      averageTime: Math.round(
        item.columns.find((column) => column.field === 'user_avg_completion_time').value / 1000,
      ),
      availableQuizzes: item.columns.find((column) => column.field === 'user_quizzes_available')
        .value,
      lastActive,
      accountCreated,
      isActive: DateTime.now().diff(DateTime.fromISO(lastActiveDate), ['days']).days <= 30,
    })

    setLoadingUser(false)
  }

  const handleDelete = async () => {
    if (!userID) {
      return
    }
    await deleteUsers([String(userID)], () => {
      history.goBack()
    })
  }

  const headerActions = !freeTier
    ? [
        {
          label: 'Message',
          onClick: () => setShowSendMessage(true),
          icon: <Email htmlColor={color.shade70} />,
        },
        {
          primary: true,
          disabled: !permissions?.permissions.some((p) => p.code === 'can_edit_quiz'),
          label: 'Edit',
          onClick: () => setShowEdit(true),
          icon: <Edit htmlColor={color.white} />,
        },
      ]
    : [
        {
          primary: true,
          disabled: !permissions?.permissions.some((p) => p.code === 'can_edit_quiz'),
          label: 'Edit',
          onClick: () => setShowEdit(true),
          icon: <Edit htmlColor={color.white} />,
        },
      ]

  const [scores, setScores] = useState([
    { x: 1, y: 0 },
    { x: 2, y: 0 },
    { x: 3, y: 0 },
    { x: 4, y: 0 },
    { x: 5, y: 0 },
    { x: 6, y: 0 },
  ])

  const [loadingResults, setLoadingResults] = useState(true)

  useEffect(() => {
    setLoading(true)
    tableHelper.load().then(() => {
      setLoadingResults(false)
      const s = [
        { x: 1, y: 0 },
        { x: 2, y: 0 },
        { x: 3, y: 0 },
        { x: 4, y: 0 },
        { x: 5, y: 0 },
        { x: 6, y: 0 },
      ]
      tableHelper.rows.map((row) => {
        if (row.result_score < 60) {
          s[0].y += 1
        } else if (row.result_score >= 60 && row.result_score < 70) {
          s[1].y += 1
        } else if (row.result_score >= 70 && row.result_score < 80) {
          s[2].y += 1
        } else if (row.result_score >= 80 && row.result_score < 90) {
          s[3].y += 1
        } else if (row.result_score >= 90 && row.result_score < 100) {
          s[4].y += 1
        } else if (row.result_score === 100) {
          s[5].y += 1
        }
      })
      setScores(s)
      setLoading(false)
    })
  }, [])

  return (
    <>
      <HeaderPanel
        backdropStyle={{ backgroundColor: '#212121' }}
        backdrop={backdrop}
        status={{
          label: user?.isActive ? 'Active' : 'Inactive',
          color: user?.isActive ? color.green : color.yellow,
        }}
        loading={loadingUser}
        footer={
          user && (
            <div style={{ flexDirection: 'row', display: 'flex' }}>
              <Avatar
                name={`${selectedUserProfile?.first_name} ${selectedUserProfile?.last_name}`}
                url={selectedUserProfile?.photo_url}
                size={80}
                fontStyle={{ fontSize: 32 }}
                style={{ marginRight: 24 }}
              />
              <div
                style={{ flexDirection: 'column', display: 'flex', justifyContent: 'space-evenly' }}
              >
                <Text
                  style={{ color: color.white }}
                  variant="headline"
                  text={`${selectedUserProfile?.first_name} ${selectedUserProfile?.last_name}`}
                />
                <Text
                  style={{ color: 'rgba(255,255,255,.7)' }}
                  variant="body1Medium"
                  text={`Joined ${user?.accountCreated}`}
                />
                <Text
                  style={{ color: 'rgba(255,255,255,.7)' }}
                  variant="body1Medium"
                  text={`Last Active ${user?.lastActive ?? 'Never'}`}
                />
              </div>
            </div>
          )
        }
        actions={headerActions}
      />
      <div>
        <div style={{ marginBottom: 16 }}>
          <Text text="Statistics" variant="labelEmphasized" />
        </div>
        {loadingUser ? (
          <Skeleton height={119} style={{ borderRadius: 20 }} variant="rectangular" />
        ) : (
          user && (
            <div style={{ display: 'flex', flexDirection: 'row', marginBottom: 20 }}>
              <Grid container spacing={2}>
                <Grid item md={3} xs={6}>
                  <Insight
                    outlined
                    title="Quizzes Available"
                    tooltip="Number of available quizzes"
                    value={`${user?.availableQuizzes}`}
                    valueFontSize="24px"
                  />
                </Grid>
                <Grid item md={3} xs={6}>
                  <Insight
                    outlined
                    title="Quizzes Completed"
                    tooltip="Number of quizzes that have been completed"
                    value={`${user?.quizzesCompleted ?? 0}`}
                    valueFontSize="24px"
                  />
                </Grid>
                <Grid item md={3} xs={6}>
                  <Insight
                    outlined
                    title="Average Score"
                    tooltip="Average score across all quizzes taken"
                    value={`${user?.averageScore}%`}
                    valueFontSize="24px"
                  />
                </Grid>
                <Grid item md={3} xs={6}>
                  <Insight
                    outlined
                    title="Average Completion Time"
                    tooltip="Average time taken to complete a quiz"
                    value={`${user?.averageTime} Seconds`}
                    valueFontSize="24px"
                  />
                </Grid>
              </Grid>
            </div>
          )
        )}
      </div>
      <Grid container spacing={2} style={{ paddingBottom: 32 }}>
        <Grid
          item
          xs={12}
          sm={loadingKG || (questionGaps?.length && questionGaps?.length > 0) ? 6 : 12}
          md={loadingKG || (questionGaps?.length && questionGaps?.length > 0) ? 6 : 12}
        >
          {loadingResults ? (
            <Skeleton
              style={{ borderRadius: 20, display: 'flex' }}
              variant="rectangular"
              height={360}
            />
          ) : (
            <div style={{ ...PANEL, height: 360, flexDirection: 'column' }}>
              <Text text="Score Distribution" variant="titleEmphasized" />
              <SimpleBarChart
                data={scores}
                loading={loading}
                tickValues={['<60%', '>60%', '>70%', '>80%', '>90%', '100%']}
              />
            </div>
          )}
        </Grid>
        <Grid item xs={12} sm={6} md={6}>
          {loadingKG ? (
            <Skeleton
              style={{ borderRadius: 20, display: 'flex' }}
              variant="rectangular"
              height={360}
            />
          ) : (
            <StrongestWeakest
              style={{ height: 360 }}
              type="Weakest"
              variant="small"
              freeTier={freeTier}
              questions={getStrongestWeakest(questionGaps ?? []).weakest}
            />
          )}
        </Grid>
        <Grid item xs={12}>
          <Text text="Results" variant="labelEmphasized" style={{ marginBottom: 16 }} />
          <UserResultsTable forceLoading={loadingResults} restricted tableHelper={tableHelper} />
        </Grid>
      </Grid>
      {showSendMessage && (
        <MessageDialog
          ids={[userID]}
          open={showSendMessage}
          messageType="generic"
          onClose={() => setShowSendMessage(false)}
        />
      )}
      {showEdit && (
        <EditUser
          open={showEdit}
          onClose={() => setShowEdit(false)}
          onUpdate={() => {
            getSelectedUserProfile()
            setShowUpdateDialog(true)
          }}
          onDelete={() => setShowConfirmDelete(true)}
        />
      )}
      {showUpdateDialog && (
        <Dialog
          open={showUpdateDialog}
          title="User Updated"
          dialogText="This user's information has been updated."
          onConfirm={() => {
            setShowEdit(false)
            setShowUpdateDialog(false)
          }}
          confirmBtnLabel="Close"
          maxWidth="xs"
        />
      )}
      {showConfirmDelete && (
        <Dialog
          danger
          title={`Delete ${selectedUserProfile?.first_name} ${selectedUserProfile?.last_name}`}
          maxWidth="xs"
          dialogText="Are you sure you want to delete this user?"
          onConfirm={handleDelete}
          onClose={() => {
            setShowConfirmDelete(false)
          }}
          onCancel={() => {
            setShowConfirmDelete(false)
          }}
          open={showConfirmDelete}
        />
      )}
    </>
  )
})

const PANEL: React.CSSProperties = {
  minHeight: 119,
  padding: 24,
  borderRadius: 20,
  background: '#fff',
  border: `1px solid #EFF0F6`,
  display: 'flex',
}
