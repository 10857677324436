import { cast, flow, getSnapshot, Instance, SnapshotIn, types } from 'mobx-state-tree'
import { getChoicesByQuestionType } from '../utils'
import { Factoid } from './Factoid'
import { QuestionChoice } from './QuestionChoice'
import { QuestionType } from './QuestionType'
import { QuestionApi } from '../services/api-objects'
import { QuestionHelper } from '../helpers/QuestionHelper'

export const questionErrors = {
  incorrectQuestionLength: {
    key: 'incorrectQuestionLength',
    title: 'Question Length',
    text: 'Question length is not between 1 and 1000 characters.',
    problem: 'is not between 1 and 1000 characters.',
  },
  duplicateCreateQuestion: {
    key: 'duplicateCreateQuestion',
    title: 'Duplicate Question',
    text: 'This topic already exists',
    problem: 'Question appears to be a duplicate of an existing.',
  },
  mc24Choices: {
    key: 'mc24Choices',
    title: 'Choices',
    text: 'Multiple Choice question must have between 2 to 4 valid choices',
    problem: 'Multiple Choice question must have between 2 to 4 valid choices',
  },
  oneCorrectAnswer: {
    key: 'oneCorrectAnswer',
    title: 'Correct Answer',
    text: 'Multiple Choice question must have 1 correct answer only.',
    problem: 'Multiple Choice question must have 1 correct answer only. Correct answers provided:',
  },
  mca24Choices: {
    key: 'mca24Choices',
    title: 'Choices',
    text: 'Multiple Response question must have between 2 to 4 valid choices',
    problem: 'Multiple Response question must have between 2 to 4 valid choices',
  },
  mca04answers: {
    key: 'mca04answers',
    title: 'Correct Answer',
    text: 'Multiple Response question must have between 0 to 4 correct answers.',
    problem:
      'Multiple Response question must have between 0 to 4 correct answers. Correct answers provided:',
  },
  tf2Choices: {
    key: 'tf2Choices',
    title: 'Choices',
    text: 'True or False question must have 2 choices',
    problem: 'True or False question must have 2 choices',
  },
  tf1Correct: {
    key: 'tf1Correct',
    title: 'Correct Answer',
    text: 'True or False question must have 1 correct answer only.',
    problem: 'True or False question must have 1 correct answer only. Correct answers provided:',
  },
  invalidType: {
    key: 'invalidType',
    title: '',
    text: '',
    problem: 'question type invalid',
  },
  activeAssignment: {
    key: 'activeAssignment',
    title: '',
    text: '',
    problem: 'question belongs to an active assignment.',
  },
  factoidLength: {
    key: 'factoidLength',
    title: 'Factoid Length',
    text: 'Factoid text is too long. (1-500)',
    problem: 'factoid text character length not in allowed paramters',
  },
}

export const Question = types
  .model('Question')
  .props({
    keep_answer_order: types.boolean,
    shuffle: types.maybe(types.boolean),
    adaptive_eligible: types.maybeNull(types.boolean),
    difficulty: types.number,
    factoid: types.maybeNull(Factoid),
    is_locked: types.literal(false),
    question_id: types.maybeNull(types.number),
    question_type: QuestionType,
    choices: types.array(QuestionChoice),
    text: types.string,
    alt_text: types.maybeNull(types.string),
    image_src: types.maybeNull(types.string),
    image_url: types.maybeNull(types.string),
    errors: types.maybeNull(types.array(types.string)),
    errorsCorrected: types.maybeNull(types.array(types.string)),
    order: types.maybeNull(types.number),
    touched: types.maybe(types.boolean),
    approved: types.maybe(types.boolean),
    generating: types.maybe(types.boolean),
    rewriting: types.maybe(types.boolean),
    imageChanged: types.maybe(types.boolean),
    questionTypeChanged: types.maybe(types.boolean),
    selected: types.maybe(types.boolean),
    document_id: types.maybeNull(types.number),
    resource_id: types.maybeNull(types.number),
    fact_id: types.maybeNull(types.number),
    generated_question_id: types.maybeNull(types.number),
    source_node_ids: types.maybe(types.array(types.number)),
    removing: types.maybe(types.boolean),
    showStatus: types.maybe(types.boolean),
    final: types.maybe(types.boolean),
    advanced: types.maybe(types.boolean),
    external_id: types.maybeNull(types.string),
  })
  .volatile(() => ({
    imageFile: null as File | null,
  }))
  .actions((self) => ({
    afterCreate() {
      self.shuffle = !self.keep_answer_order
    },
    select() {
      self.selected = true
    },
    deselect() {
      self.selected = false
    },
    reset() {
      self.text = ''
      self.factoid = Factoid.create({
        text: '',
        factoid_id: null,
        resource_id: null,
        generating: undefined,
        modified: false,
      })
      if (self.choices.length < 3) {
        this.addNewChoice()
      }
      self.choices.map((c) => c.reset())
      self.image_src = ''
      self.image_url = ''
      self.alt_text = ''
      self.adaptive_eligible = true
      self.shuffle = true
      self.question_type = 'Multiple Choice'
      self.approved = false
      self.touched = false
      self.document_id = null
      self.fact_id = null
      self.question_id = null
      self.generated_question_id = null
      self.source_node_ids = undefined
    },
    set<K extends keyof SnapshotIn<typeof self>, T extends SnapshotIn<typeof self>>(
      key: K,
      value: T[K],
    ) {
      self[key] = cast(value)
    },
    setApproved(approved: boolean) {
      self.approved = approved
    },
    setTouched(touched: boolean) {
      self.touched = touched
    },
    setShuffleOrder(shuffle: boolean) {
      self.keep_answer_order = !shuffle
      self.shuffle = shuffle
      // self.touched = true
      self.approved = false
    },
    reorderChoices(drag: number, drop: number) {
      const temp = self.choices?.slice()
      if (temp) {
        temp.splice(drop, 0, temp.splice(drag, 1)[0])
        self.choices = cast(temp)
      }
      // self.touched = true
      self.approved = false
    },
    setImage(f: File | null) {
      // The only reason we should ever set this is to set it to a new file or to remove the image
      // otherwise we should receive the image_src from backend on init
      self.image_src = null
      self.imageFile = f
      // self.touched = true
      self.approved = false
      self.imageChanged = true
    },
    setCorrectChoice(choice: Instance<typeof QuestionChoice>, value?: boolean) {
      if (self.question_type === 'Multiple Response') {
        choice.correct = !!value
      } else {
        self.choices.forEach((c) => {
          c.correct = c === choice
        })
      }
      // self.touched = true
      self.approved = false
    },
    toPayload: () => {
      const payloadQuestion = getSnapshot(self)
      const payloadChoices = (payloadQuestion.choices ?? [])
        .filter((c) => c.text || c.correct)
        .map((c, index) => ({
          correct: c.correct,
          text: c.text ?? '',
          choice_id: c.choice_id,
          order: payloadQuestion.keep_answer_order ? index : null,
        }))

      return {
        ...payloadQuestion,
        question_id: self.question_id,
        generated_question_id: self.generated_question_id,
        choices: payloadChoices,
        factoid: payloadQuestion.factoid,
      }
    },
    clearErrors: (errorKeys?: Array<keyof typeof questionErrors>) => {
      if (!errorKeys) {
        self.errors = cast([])
        return
      } else if (!errorKeys.length || !self.errors?.length) {
        return
      }

      const problemTexts = errorKeys.map((k) => questionErrors[k].problem)

      self.errors = cast(self.errors.filter((e) => !problemTexts.includes(e)))
    },
    addError(error) {
      self.errors?.replace([...self.errors, error])
    },
    addNewChoice() {
      self.choices.push(QuestionChoice.create({ correct: false, touched: true }))
      this.set('approved', false)
    },
    removeChoice(choice: Instance<typeof QuestionChoice>) {
      const filteredChoices = (self.choices ?? ([] as Array<Instance<typeof Question>>)).filter(
        (c) => {
          if (choice === c) {
            return false
          }
          return true
        },
      )

      self.choices = cast(filteredChoices)

      this.set('touched', true)
      this.set('approved', false)
    },
    addToCorrectedErrors(correctedErrorsToAdd: Array<keyof typeof questionErrors>) {
      if (self.errorsCorrected) {
        self.errorsCorrected.push(...correctedErrorsToAdd)
      } else {
        self.errorsCorrected = cast(correctedErrorsToAdd)
      }
    },
    setQuestionType(type: Instance<typeof QuestionType>) {
      if (type === self.question_type) {
        return
      }

      self.approved = false

      if (self.question_type === 'True or False') {
        self.choices = cast(getChoicesByQuestionType(type))
      } else if (self.question_type === 'Multiple Choice') {
        if (type === 'True or False') {
          self.choices = cast(getChoicesByQuestionType(type))
        }
      } else if (self.question_type === 'Multiple Response') {
        if (type === 'True or False') {
          self.choices = cast(getChoicesByQuestionType(type))
        } else if (type === 'Multiple Choice') {
          let hasCorrect = false
          self.choices.forEach((c) => {
            if (c.correct && hasCorrect) {
              c.correct = false
            } else if (c.correct && !hasCorrect) {
              c.correct = true
              hasCorrect = true
            }
          })

          if (!hasCorrect) {
            self.choices[0].correct = true
          }
        }
      }

      self.question_type = type
    },
  }))
  .views((self) => ({
    get payloadQuestionType() {
      const qtypes = [] as any
      if (self.question_type === 'Multiple Choice') {
        return 'multiple_choice'
      }
      if (self.question_type === 'True or False') {
        return 'true_false'
      }
      if (self.question_type === 'Multiple Response') {
        return 'multiple_choice'
      }
      return qtypes
    },
    get generatingAll() {
      const generating = self.choices.filter((choice) => choice.generating)
      return self.generating || self.factoid?.generating || generating.length > 0
    },
    get formWarnings() {
      const warnings: any = {}

      if (self.questionTypeChanged) {
        warnings.questionTypeChanged = {
          type: 'error',
          message:
            "This question was tagged as True/False, but its answers were not valid True/False. We've changed it to Multiple Choice. You can either change it back to True/False with valid answers or leave it as a Multiple Choice question.",
        }
      }

      if (self.text && self.text.length > 1000) {
        warnings.text = {
          type: 'warning',
          message:
            'Question text is over the recommended character limit. It may not display correctly on certain devices.',
        }
      }

      if (self.factoid && self.factoid?.text && self.factoid.text.length > 1000) {
        warnings.feedback = {
          type: 'warning',
          message:
            'Feedback is longer than 1000 characters and may not display correctly on some devices.',
        }
      }
      return warnings
    },
    get formErrors() {
      const errors: any = {}

      if (!self.text) {
        errors.text = {
          type: 'error',
          message: 'Question text cannot be blank.',
        }
      }

      if (
        self.question_type === 'Multiple Choice' &&
        self.choices &&
        (self.choices.length > 4 || self.choices.length < 2)
      ) {
        errors.mc24Choices = {
          type: 'error',
          message: 'Multiple Choice questions must have between 2 and 4 choices.',
        }
      }

      if (self.question_type === 'True or False' && self.choices && self.choices.length !== 2) {
        errors.tf2Choices = {
          type: 'error',
          message: 'True / False questions must have exactly 2 choices.',
        }
      }

      if (
        self.question_type === 'Multiple Response' &&
        self.choices &&
        (self.choices.length > 4 || self.choices.length < 2)
      ) {
        errors.mca24Choices = {
          type: 'error',
          message: 'Multiple Correct Answer questions must have between 2 and 4 choices.',
        }
      }

      if (
        self.question_type === 'Multiple Choice' &&
        self.choices &&
        self.choices.filter((c) => c.correct).length !== 1
      ) {
        errors.mc1Correct = {
          type: 'error',
          message: 'Multiple Choice questions must have exactly 1 correct answer.',
        }
      }

      if (
        self.question_type === 'True or False' &&
        self.choices &&
        self.choices.filter((c) => c.correct).length !== 1
      ) {
        errors.tf1Correct = {
          type: 'error',
          message: 'True / False questions must have exactly 1 correct answer.',
        }
      }

      return errors
    },
    get choiceFormErrors() {
      const err = [] as any
      self.choices.map((choice) => {
        if (choice.formErrors && choice.touched) {
          err.push(...Object.values(choice.formErrors))
        }
      })
      return err
    },
    get choiceFormWarnings() {
      const warnings = [] as any
      self.choices.map((choice) => {
        if (this.new) {
          if (choice.formErrors && choice.touched) {
            warnings.push(...Object.values(choice.formWarnings))
          }
        } else if (choice.formWarnings) {
          warnings.push(...Object.values(choice.formWarnings))
        }
      })
      return warnings
    },
    get allErrors() {
      return [...Object.values(this.formErrors), ...this.choiceFormErrors]
    },
    get allWarnings() {
      return [...Object.values(this.formWarnings), ...this.choiceFormWarnings]
    },
    get new() {
      return !self.question_id
    },
    get image() {
      if (self.imageFile) {
        return URL.createObjectURL(self.imageFile)
      } else if (self.image_src) {
        return self.image_src
      } else if (self.image_url) {
        return self.image_url
      } else {
        return null
      }
    },
    get hasErrors() {
      // if no errors
      if (!self?.errors?.length) {
        return false
      }

      // if there are errors but no corrected errors
      if (!self.errorsCorrected?.length) {
        return true
      }

      const mappedErrors = self.errors.map((e) => {
        const error = Object.values(questionErrors).find((qe) => e.includes(qe.problem))
        if (!error) {
          throw new Error(`Could not map error ${e}`)
        } else {
          return error
        }
      })

      // if any errors have not been corrected
      const hasUncorrectedErrors = mappedErrors.some(
        (me) => !self.errorsCorrected!.includes(me.key),
      )

      if (hasUncorrectedErrors) {
        return true
      }

      // lastly lets do some frontend level validation.
      // check that the first 2 choices have text. For any question type we must have at least
      // 2 choices with text
      if (self.choices?.length && self.choices.slice(0, 2).some((c) => !c.text)) {
        return true
      }

      return false
    },
    get error() {
      if ((self as Instance<typeof Question>).hasError('incorrectQuestionLength')) {
        return questionErrors['incorrectQuestionLength']
      } else if ((self as Instance<typeof Question>).hasError('duplicateCreateQuestion')) {
        return questionErrors['duplicateCreateQuestion']
      } else {
        return null
      }
    },
    get totalErrors() {
      return Object.values(this.formErrors).length
    },
    get totalWarnings() {
      return Object.values(this.formWarnings).length
    },
    get choiceErrors() {
      const err = [] as any
      self.choices.map((choice) => {
        if (choice.errors) {
          err.push(...choice.errors)
        }
      })
      return err
    },
    get hasDeepErrors() {
      if (this.hasErrors) {
        return true
      }

      if (self.choices.some((c) => c.hasErrors)) {
        return true
      }

      return false
    },
    getError(errorKey: keyof typeof questionErrors) {
      return questionErrors[errorKey]
    },
    get answerError() {
      if (self.question_type === 'Multiple Choice' && this.hasError('oneCorrectAnswer')) {
        return questionErrors['oneCorrectAnswer']
      } else if (self.question_type === 'Multiple Response' && this.hasError('mca04answers')) {
        return questionErrors['mca04answers']
      } else if (self.question_type === 'True or False' && this.hasError('tf1Correct')) {
        return questionErrors['tf1Correct']
      } else {
        return null
      }
    },
    hasError(errorKey: keyof typeof questionErrors) {
      // has errors
      if (!self.errors?.length) {
        return false
      }

      // does not have this error
      if (!self.errors.some((e) => e.includes(questionErrors[errorKey].problem))) {
        return false
      }

      // this error has been corrected
      if (self.errorsCorrected?.includes(errorKey)) {
        return false
      }

      // has errors, has this error, and the error hasn't been corrected
      return true
    },
    get hasWarnings() {
      return self.choices.some((c) => c.hasWarnings)
    },
    get hasDeepWarnings() {
      if (!self.choices?.length) {
        return false
      }
      return self.choices.some((c) => c.warnings?.length)
    },
    get isBlankQuestion() {
      // question is blank if it has no text and no choices with text
      return !self.text && (!self.choices?.length || self.choices.every((c) => !c.text))
    },
  }))
  .actions((self) => ({
    generateChoices: flow(function* (
      distractors_count: number,
      distractors_only?: boolean,
      rejected?: string[],
    ) {
      self.set('generating', true)

      try {
        const correct_choices = self.choices
          .filter((c) => c.correct)
          .map((correctChoice) => correctChoice.text)

        const rejected_choices = rejected
          ? rejected
          : self.choices.filter((c) => !c.correct).map((correctChoice) => correctChoice.text)

        const payload = {
          query: self.text,
          fact: self.factoid?.text || '',
          distractors_count,
          correct_choices,
          rejected_choices,
          generated_question_id: self.generated_question_id,
          distractors_only,
        }

        const result = yield QuestionApi.generateDistractors(payload)
        if (result.ok && result.data) {
          self.set('generating', false)
          return result.data.choices
        } else {
          self.set('generating', false)
          return false
        }
      } catch (error) {
        self.set('generating', false)
        return false
      }
    }),
    generateFeedback: flow(function* () {
      self.factoid?.setGenerating(true)
      try {
        const result = yield QuestionApi.generateFeedback({
          original_fact: self.factoid?.text,
          query: self.text,
          correct_choice: self.choices.filter((c) => c.correct).map((choice) => choice.text),
        })
        if (result.ok && result.data) {
          self.factoid?.setText(result.data.fact)
          self.factoid?.setGenerating(false)
        } else {
          self.factoid?.setGenerating(false)
        }
      } catch (error) {
        self.factoid?.setGenerating(false)
      }
    }),
    generateFromPartial: flow(function* (settings) {
      self.set('generating', true)
      self.set('rewriting', true)
      try {
        const result = yield QuestionApi.generateFromPartial({
          document_id: self.document_id,
          generated_question_id: self.generated_question_id,
          fact: self.factoid?.text,
          question: self.text,
          audience: settings.audience,
          voice: settings.voice,
          difficulty:
            settings.difficulty === 'Easy' ? 0 : settings.difficulty === 'Standard' ? 50 : 100,
          question_type: self.payloadQuestionType,
        })

        if (result.ok && result.data) {
          self.set('generating', false)
          self.set('rewriting', false)
          return result.data
        } else {
          self.set('generating', false)
          self.set('rewriting', false)
          return false
        }
      } catch (error) {
        self.set('generating', false)
        self.set('rewriting', false)
        return false
      }
    }),
  }))
