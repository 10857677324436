import * as React from 'react'
import { Menu, MenuItem } from '@mui/material'
import {
  ArrowDropDownRounded,
  CheckBoxRounded,
  LinearScaleRounded,
  RadioButtonCheckedRounded,
  TextFields,
  TextFieldsRounded,
} from '@mui/icons-material'

import { Button } from '@trivie/ui-web/components/button'
import { Text } from '@trivie/ui-web/components/text'
import withStyles from '@mui/styles/withStyles'
import ListItemIcon from '@mui/material/ListItemIcon'
import { observer } from 'mobx-react-lite'

const StyledMenu = withStyles({
  paper: {
    border: '2px solid rgba(240, 240, 240, 0.7)',
    width: 190,
    borderTopRightRadius: 0,
    borderTopLeftRadius: 0,
    marginTop: '-6px',
    marginLeft: '-0.5px',
  },
  list: {
    paddingTop: 0,
    paddingBottom: 0,
  },
})((props: any) => (
  <Menu
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'center',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'center',
    }}
    open={props.open}
    {...props}
  />
))

const StyledMenuItem = withStyles(() => ({
  root: {
    padding: 15,
    height: 40,
    '&:hover': {
      backgroundColor: 'rgba(242, 247, 255, 1)',
    },
    '& .MuiListItemIcon-root': {
      minWidth: 13.5,
      paddingRight: 15,
    },
  },
}))(MenuItem)

export const QuestionTypeMenu = observer((props: any) => {
  const { question, disabled, hide } = props
  const [anchorEl, setAnchorEl] = React.useState(null)

  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const renderIcon = (type: string) => {
    switch (type) {
      case 'open_text':
        return <TextFields style={ICON} />
      case 'range_response':
        return <LinearScaleRounded style={ICON} />
      case 'true_false':
        return <CheckBoxRounded style={ICON} />
      default:
        return <RadioButtonCheckedRounded style={ICON} />
    }
  }

  const onSelect = (questionType: string) => {
    question.setQuestionType(questionType)
    setAnchorEl(null)
  }

  return (
    <div style={{ display: hide ? 'none' : 'block' }}>
      <Button
        aria-controls="customized-menu"
        aria-haspopup="true"
        variant="outlined"
        color="info"
        size="large"
        onClick={handleClick}
        sx={{ width: '190px', height: '46px', justifyContent: 'space-between' }}
        disabled={disabled}
        endIcon={
          <ArrowDropDownRounded fontSize="small" style={{ color: 'rgba(128, 128, 128, 1)' }} />
        }
      >
        <div style={{ display: 'flex', flexDirection: 'row' }}>
          {renderIcon(question.question_type)}
          <Text
            style={{ color: 'rgba(128, 128, 128, 1)' }}
            variant="body1Medium"
            text={question.questionLabel}
          />
        </div>
      </Button>
      <StyledMenu anchorEl={anchorEl} keepMounted open={Boolean(anchorEl)} onClose={handleClose}>
        <StyledMenuItem onClick={() => onSelect('open_text')}>
          <ListItemIcon>
            <TextFieldsRounded style={{ fontSize: 16 }} />
          </ListItemIcon>
          <Text style={LIST_ITEM_TXT} variant="body1Medium" text="Short answer" />
        </StyledMenuItem>
        <StyledMenuItem onClick={() => onSelect('single_choice')}>
          <ListItemIcon>
            <RadioButtonCheckedRounded style={{ fontSize: 16 }} />
          </ListItemIcon>
          <Text style={LIST_ITEM_TXT} variant="body1Medium" text="Multiple choice" />
        </StyledMenuItem>
        <StyledMenuItem onClick={() => onSelect('true_false')}>
          <ListItemIcon>
            <CheckBoxRounded style={{ fontSize: 16 }} />
          </ListItemIcon>
          <Text style={LIST_ITEM_TXT} variant="body1Medium" text="True / False" />
        </StyledMenuItem>
        <StyledMenuItem onClick={() => onSelect('range_response')}>
          <ListItemIcon>
            <LinearScaleRounded style={{ fontSize: 16 }} />
          </ListItemIcon>
          <Text style={LIST_ITEM_TXT} variant="body1Medium" text="Scale" />
        </StyledMenuItem>
      </StyledMenu>
    </div>
  )
})

const LIST_ITEM_TXT: React.CSSProperties = { color: 'rgba(128, 128, 128, 1)' }
const ICON: React.CSSProperties = { marginRight: 6, fontSize: 16, color: 'rgba(128, 128, 128, 1)' }
