import { types, getEnv, flow, Instance, cast, getRoot } from 'mobx-state-tree'
import { DateTime } from 'luxon'
import { Environment } from '../environment'
import { CommentReadModel, PostReadModel } from '../base'
import { GeneralApiProblem } from '../../services/api'

const CommentSorts = types.enumeration(['top', 'discussions', 'new'])

export const TalkStoreModel = types
  .model('TalkStore')
  .props({
    status: types.optional(
      types.enumeration(['idle', 'refreshing', 'pending', 'done', 'error']),
      'idle',
    ),
    currentPage: types.number,
    nextPage: types.maybe(types.number),
    lastPage: types.maybe(types.number),
    previousPage: types.maybeNull(types.number),
    posts: types.array(types.frozen()),
    topicPosts: types.array(types.frozen()),
    selectedPost: types.frozen(),
    selectedPostPhoto: types.frozen(),
    commentSort: CommentSorts,
    fetchingPosts: types.optional(types.boolean, false),
    pauseVideo: types.optional(types.boolean, false),
    test: types.frozen(),
    currentTopicPage: types.frozen(),
    nextTopicPage: types.frozen(),
    lastTopicPage: types.frozen(),
    previousTopicPage: types.frozen(),
    errorMessage: types.maybeNull(types.string),
  })
  .actions((self) => ({
    setTest(status: any) {
      self.test = status
    },
    setStatus(value?: 'idle' | 'refreshing' | 'pending' | 'done' | 'error') {
      self.status = value || 'idle'
    },
    setPauseVideo(status: boolean) {
      self.pauseVideo = status
    },
    setCurrentTopicPage(page: number) {
      self.currentTopicPage = page
    },
    setNextTopicPage(page: number) {
      self.nextTopicPage = page
    },
    setLastTopicPage(page: number) {
      self.lastTopicPage = page
    },
    setPreviousTopicPage(page: number) {
      self.previousTopicPage = page
    },
    setCurrentPage(page: number) {
      self.currentPage = page
    },
    setNextPage(page: number) {
      self.nextPage = page
    },
    setLastPage(page: number) {
      self.lastPage = page
    },
    setPreviousPage(page: number) {
      self.previousPage = page
    },
    appendPosts(posts: Instance<typeof PostReadModel>[]) {
      self.posts.push(...posts)
    },
    replacePosts(posts: Instance<typeof PostReadModel>[]) {
      self.posts = [] as any
      self.posts = cast(posts)
    },
    replaceTopicPosts(posts: Instance<typeof PostReadModel>[]) {
      self.topicPosts = cast(posts)
    },
    setSelectedPostPhoto(photo: any) {
      self.selectedPostPhoto = photo as any
    },
    setSelectedPost(post: any) {
      self.selectedPost = post as any
    },
    setFetchingPosts(fetchingPosts: boolean) {
      self.fetchingPosts = fetchingPosts
    },
    setErrorMessage(errorMessage: typeof self.errorMessage) {
      self.errorMessage = errorMessage
    },

    setCommentSort(sort: Instance<typeof CommentSorts>) {
      self.commentSort = sort
    },
    updatePostAtIndex(postIndex: number, post: any) {
      self.posts[postIndex] = post
      self.selectedPost = post
    },
  }))
  .views((self) => ({
    get commentSortDisplay() {
      if (self.commentSort === 'top') {
        return 'TOP COMMENTS'
      }
      if (self.commentSort === 'discussions') {
        return 'DISCUSSIONS'
      }
      if (self.commentSort === 'new') {
        return 'NEW'
      }
      return ''
    },
    get environment() {
      return getEnv(self) as Environment
    },
    get isLoading() {
      return self.status === 'pending'
    },
    get isRefreshing() {
      return self.status === 'refreshing'
    },
    get sortedComments() {
      return (self.selectedPost?.comments ?? [])
        .slice()
        .sort((a: Instance<typeof CommentReadModel>, b: Instance<typeof CommentReadModel>) => {
          if (self.commentSort === 'top') {
            return (b.likes?.length || 0) - (a.likes?.length || 0)
          }
          if (self.commentSort === 'discussions') {
            return (b.child_comments?.length || 0) - (a.child_comments?.length || 0)
          }
          if (self.commentSort === 'new') {
            const x = DateTime.fromISO(a.created_at)
            const y = DateTime.fromISO(b.created_at)
            if (x > y) {
              return -1
            }
            if (x === y) {
              return 0
            }
            if (x < y) {
              return 1
            }
          }

          return 0
        })
    },
    get sortedPosts() {
      const p = [...self.posts]
      return p.slice().sort((a: any, b: any) => {
        const x = DateTime.fromISO(a.created_at)
        const y = DateTime.fromISO(b.created_at)
        if (a.is_pinned && b.is_pinned) {
          if (x > y) {
            return -1
          } else if (x === y) {
            return 0
          } else {
            return 1
          }
        } else if (a.is_pinned) {
          return -1
        } else {
          return 1
        }
      })
    },
    get sortedTopicPosts() {
      return self.topicPosts.slice().sort((a: any, b: any) => {
        const x = DateTime.fromISO(a.created_at)
        const y = DateTime.fromISO(b.created_at)
        if (a.is_pinned && b.is_pinned) {
          if (x > y) {
            return -1
          } else if (x === y) {
            return 0
          } else {
            return 1
          }
        } else if (a.is_pinned) {
          return -1
        } else {
          return 1
        }
      })
    },
  }))
  .actions((self) => ({
    getPosts: flow(function* (page?: number) {
      self.setFetchingPosts(true)
      const rootStore = getRoot(self)
      const { userStore } = rootStore as any
      try {
        // @ts-ignore
        if (self.currentPage < self.lastPage) {
          // @ts-ignore
          self.setCurrentPage(self.nextPage)
          const result = yield self.environment.api.getPostsForUser(
            page || self.currentPage,
            userStore.user.id,
          )
          if (result.kind === 'ok') {
            self.setCurrentPage(page || self.currentPage)
            self.setNextPage(result.data.next_page)
            self.setLastPage(result.data.last_page)
            self.setPreviousPage(result.data.previous_page)
            self.appendPosts(result.data.posts)
          } else {
            self.setStatus('error')
            self.replacePosts([])
          }
        }

        self.setFetchingPosts(false)
      } catch {
        self.setFetchingPosts(false)
        self.replacePosts([])
        self.setStatus('error')
      }
    }),
    getPostsForTopic: flow(function* (topicId: number, page?: number) {
      self.setFetchingPosts(true)
      try {
        if (self.currentTopicPage < self.lastTopicPage) {
          self.setCurrentPage(self.nextTopicPage)
          const result = yield self.environment.api.getPostsForTopic(
            page || self.currentTopicPage,
            topicId,
          )
          if (result.kind === 'ok') {
            self.setCurrentTopicPage(page || self.currentPage)
            self.setNextTopicPage(result.data.next_page)
            self.setLastTopicPage(result.data.last_page)
            self.setPreviousTopicPage(result.data.previous_page)
            self.appendPosts(result.data.posts)
          } else {
            self.setStatus('error')
            self.replacePosts([])
          }
        }

        self.setFetchingPosts(false)
      } catch {
        self.setFetchingPosts(false)
        self.replacePosts([])
        self.setStatus('error')
      }
    }),
    refreshTopicPosts: flow(function* (selectedTopicId: any, indicator = true) {
      if (indicator) {
        self.setStatus('pending')
      }
      try {
        const result = yield self.environment.api.getPostsForTopic(1, selectedTopicId)
        self.setCurrentTopicPage(1)
        if (result.kind === 'ok') {
          self.setCurrentTopicPage(1)
          self.setNextTopicPage(result.data.next_page)
          self.setLastTopicPage(result.data.last_page)
          self.setPreviousTopicPage(result.data.previous_page)
          self.replaceTopicPosts(result.data.posts)
          self.setStatus('done')
        } else {
          self.setStatus('error')
        }
      } catch (e) {
        self.replaceTopicPosts([])
        self.setStatus('error')
      }
    }),
    refreshPosts: flow(function* (indicator = true) {
      if (indicator) {
        self.setStatus('pending')
      }
      try {
        const rootStore = getRoot(self)
        const { userStore } = rootStore as any
        const result = yield self.environment.api.getPostsForUser(1, userStore.user.id)
        self.setCurrentPage(1)
        if (result.kind === 'ok') {
          self.setCurrentPage(1)
          self.setNextPage(result.data.next_page)
          self.setLastPage(result.data.last_page)
          self.setPreviousPage(result.data.previous_page)
          self.replacePosts(result.data.posts)
          self.setStatus('done')
        } else {
          self.setStatus('error')
        }
      } catch (e) {
        self.replacePosts([])
        self.setStatus('error')
      }
    }),
    createPost: flow(function* (userId: number, post: any) {
      self.setStatus('pending')
      try {
        const result = yield self.environment.api.createPost({
          is_announcement: false,
          author_id: userId,
          resources: post.resources ? post.resources : [],
          title: post.title,
          text: post.text,
          topic_id: post.topic.topic_id ?? post.topic_id ?? post.topic.topic.topic_id,
        })
        if (result.kind === 'ok') {
          self.setStatus('done')
          return result.data.post_id
        }
        self.setStatus('error')
      } catch {
        self.setStatus('error')
      }
    }),
    deletePost: flow(function* (postId: number) {
      self.setStatus('pending')
      try {
        const result = yield self.environment.api.deletePost(postId)
        if (result.kind === 'ok') {
          self.setStatus('done')
        } else {
          self.setStatus('error')
        }
      } catch {
        self.setStatus('error')
      }
    }),
    likePost: flow(function* (postId: number) {
      try {
        const postToLike = self.posts.find((post: any) => post.id === postId)
        // if (postToLike && postToLike.liked_by_current_user) {
        //   return
        // }
        // MIGHT HAVE TO REMOVE NEXT LINE
        if (postToLike) {
          // postToLike.liked_by_current_user = true
        }
        const result = yield self.environment.api.likePost(postId)
        if (result.kind === 'ok') {
          // @ts-ignore
          self.refreshPost(postId, false)

          // @ts-ignore
          self.refreshPosts(false)
        } else {
          self.setStatus('error')
        }
      } catch {
        self.setStatus('error')
      }
    }),
    likeComment: flow(function* (comment: Instance<typeof CommentReadModel>) {
      self.setStatus('pending')
      try {
        if (comment.parent_comment_id) {
          const parentCommentIndex = self.selectedPost.comments.findIndex(
            (c: any) => c.id === comment.parent_comment_id,
          )
          const parentComment = self.selectedPost.comments[parentCommentIndex]
          const commentIndex = parentComment.child_comments.findIndex(
            (c: any) => c.id === comment.id,
          )
          parentComment.child_comments[commentIndex].liked_by_current_user = true
        } else {
          const _commentIndex = self.selectedPost.comments.findIndex(
            (c: any) => c.id === comment.id,
          )
          self.selectedPost.comments[_commentIndex].liked_by_current_user = true
        }
        const result = yield self.environment.api.likeComment(comment.id)
        if (result.kind === 'ok') {
          // @ts-ignore
          self.refreshPost(comment.post_id)
          self.setStatus('done')
        } else {
          self.setStatus('error')
        }
      } catch {
        self.setStatus('error')
      }
    }),
    editComment: flow(function* (commentId: number, commentText: string) {
      self.setStatus('pending')
      try {
        const commentIndex = self.selectedPost.comments.findIndex((c: any) => c.id === commentId)
        self.selectedPost.comments[commentIndex].text = commentText
        const result = yield self.environment.api.editComment(commentId, commentText)
        if (result.kind === 'ok') {
          // @ts-ignore
          self.refreshPost(self.selectedPost.comments[commentIndex].post_id)
          self.setStatus('done')
        } else {
          self.setStatus('error')
        }
      } catch {
        self.setStatus('error')
      }
    }),
    deleteComment: flow(function* (comment: Instance<typeof CommentReadModel>) {
      self.setStatus('pending')
      try {
        const result = yield self.environment.api.deleteComment(comment.id)
        if (result.kind === 'ok') {
          if (comment.parent_comment_id) {
            const parentCommentId = comment.parent_comment_id
            const parentComment = self.selectedPost.comments.find(
              (c: any) => c.id === parentCommentId,
            )
            parentComment.child_comments = parentComment.child_comments.filter(
              (c: any) => c.id !== comment.id,
            )
          } else {
            const coms = self.selectedPost.comments.filter((c: any) => c.id !== comment.id)
            self.setSelectedPost({
              ...self.selectedPost,
              comments: coms,
            })
          }
          // @ts-ignore
          self.refreshPost(comment.post_id)
          self.setStatus('done')
        } else {
          self.setStatus('error')
        }
      } catch {
        self.setStatus('error')
      }
    }),
    commentOnPost: flow(function* (comment: any, postId: number, authorId: number) {
      self.setStatus('pending')
      self.setTest(true)
      try {
        const result = yield self.environment.api.commentOnPost(comment, postId, authorId)
        if (result.kind === 'ok') {
          // @ts-ignore
          self.refreshPosts()
          self.setTest(false)
          self.setStatus('done')
        } else {
          self.setStatus('error')
        }
      } catch (e) {
        if (e instanceof Error) {
          self.setErrorMessage(e?.message || 'Unexpected error ocurred')
        } else {
          const err = e as GeneralApiProblem
          if (err?.kind === 'forbidden') {
            self.setErrorMessage('Discuss.post_feed_duplicate')
          }
          if (err?.kind === 'rejected') {
            self.setErrorMessage(err.message || 'Unexpected error ocurred')
          }
        }
        self.setStatus('error')
      }
    }),
    commentOnComment: flow(function* (
      comment: any,
      postId: number,
      parentCommentId: number,
      authorId: number,
    ) {
      self.setStatus('pending')
      try {
        const result = yield self.environment.api.commentOnComment(
          comment,
          postId,
          parentCommentId,
          authorId,
        )
        if (result.kind === 'ok') {
          self.setStatus('done')
        } else {
          self.setStatus('error')
        }
      } catch (e) {
        if (e instanceof Error) {
          self.setErrorMessage(e?.message || 'Unexpected error ocurred')
        } else {
          const err = e as GeneralApiProblem
          if (err?.kind === 'forbidden') {
            self.setErrorMessage('Discuss.post_feed_duplicate')
          }
          if (err?.kind === 'rejected') {
            self.setErrorMessage(err.message || 'Unexpected error ocurred')
          }
        }
        self.setStatus('error')
      }
    }),
    reportPost: flow(function* (postId: number, reasonId: number, object_type = 'Post') {
      self.setStatus('pending')
      try {
        const result = yield self.environment.api.reportPost(postId, reasonId, object_type)
        if (result.kind === 'ok') {
          self.setStatus('done')
        } else {
          self.setStatus('error')
        }
      } catch {
        self.setStatus('error')
      }
    }),
    refreshPost: flow(function* (postId: number, indicator = true) {
      if (indicator) {
        self.setStatus('refreshing')
      }
      try {
        const result = yield self.environment.api.refreshPost(postId)
        if (result.kind === 'ok') {
          const postIndex = self.posts.findIndex((post) => post.id === result.data.id)
          if (postIndex) {
            self.updatePostAtIndex(postIndex, result.data)
          }
          self.setTest(false)
          self.setStatus('done')
          self.setSelectedPost(result.data)
          return result.data
        } else {
          self.setStatus('error')
        }
      } catch {
        self.setStatus('error')
      }
    }),
  }))

type TalkStoreType = typeof TalkStoreModel.Type
export interface TalkStore extends TalkStoreType {}
type TalkStoreSnapshotType = typeof TalkStoreModel.SnapshotType
export interface TalkStoreSnapshot extends TalkStoreSnapshotType {}
