import { types, getEnv, flow } from 'mobx-state-tree'
import { Environment } from '../environment'
import { ResourceApi } from '../../services/api-objects/ResourceApi'
import { Awaited } from '../../utility-types/Awaited'

export const ManageResourceStoreModel = types
  .model('ManageResourceStore')
  .props({
    status: types.optional(types.enumeration(['idle', 'pending', 'done', 'error']), 'idle'),
    resources: types.frozen(),
    resourceDetails: types.frozen(),
    relatedQuizzes: types.frozen(),
    relatedTopics: types.frozen(),
  })
  .actions((self) => ({
    setStatus(value?: 'idle' | 'pending' | 'done' | 'error') {
      self.status = value || 'idle'
    },
    setResources(resources: any) {
      self.resources = resources as any
    },
    setResourceDetails(details: any) {
      self.resourceDetails = details as any
    },
    setRelatedQuizzes(quizzes: any) {
      self.relatedQuizzes = quizzes
    },
    setRelatedTopics(topics: any) {
      self.relatedTopics = topics
    },
  }))
  .views((self) => ({
    get environment() {
      return getEnv(self) as Environment
    },
    get isLoading() {
      return self.status === 'pending'
    },
  }))
  .actions((self) => ({
    getResourceDetails: flow(function* (id: number) {
      self.setStatus('pending')
      try {
        const result = yield self.environment.api.getResourceByID(id)
        if (result.kind === 'ok') {
          self.setResourceDetails(result.data)
          self.setStatus('done')
        } else {
          self.setStatus('error')
        }
      } catch {
        self.setStatus('error')
      }
    }),
    getRelatedQuizzes: flow(function* (id: number) {
      self.setStatus('pending')
      try {
        const result = yield self.environment.api.getRelatedQuizzesForResource(id)
        if (result.kind === 'ok') {
          self.setRelatedQuizzes(result.data)
          self.setStatus('done')
        } else {
          self.setStatus('error')
        }
      } catch {
        self.setStatus('error')
      }
    }),
    getRelatedTopics: flow(function* (id: number) {
      self.setStatus('pending')
      try {
        const result = yield self.environment.api.getRelatedTopicsForResource(id)
        if (result.kind === 'ok') {
          self.setRelatedTopics(result.data)
          self.setStatus('done')
        } else {
          self.setStatus('error')
        }
      } catch {
        self.setStatus('error')
      }
    }),
    deleteResources: flow(function* (ids: any, safe?: boolean) {
      self.setStatus('pending')
      try {
        const result = yield self.environment.api.deleteResources(ids, safe)
        if (result.kind === 'ok') {
          self.setStatus('done')
          return result.data
        } else {
          self.setStatus('error')
        }
      } catch (err) {
        self.setStatus('error')
        return err
      }
    }),
    addResourceToTopics: flow(function* (resource_id: number, topic_ids: Array<number>) {
      self.setStatus('pending')
      try {
        const result: Awaited<ReturnType<typeof ResourceApi.addResourceToTopics>> =
          yield ResourceApi.addResourceToTopics(resource_id, topic_ids)

        if (result.ok) {
          return result.data
        } else {
          self.setStatus('error')
          return false
        }
      } catch (error) {
        self.setStatus('error')
        return false
      }
    }),
    addResourceToQuizzes: flow(function* (
      resource_id: number,
      quiz_ids: Array<number>,
      required: boolean,
    ) {
      self.setStatus('pending')
      try {
        const result: Awaited<ReturnType<typeof ResourceApi.addResourceToQuizzes>> =
          yield ResourceApi.addResourceToQuizzes(resource_id, quiz_ids, required)

        if (result.ok) {
          return result.data
        } else {
          self.setStatus('error')
          return false
        }
      } catch (error) {
        self.setStatus('error')
        return false
      }
    }),
  }))

type ManageResourceStoreType = typeof ManageResourceStoreModel.Type
export interface ManageResourceStore extends ManageResourceStoreType {}
type ManageResourceStoreSnapshotType = typeof ManageResourceStoreModel.SnapshotType
export interface ManageResourceStoreSnapshot extends ManageResourceStoreSnapshotType {}
