import React, { useEffect, useState } from 'react'
import { observer } from 'mobx-react-lite'
import { CircularProgress } from '@mui/material'

import { DataTable, TableHelper } from '@trivie/ui-web/components/data-table/data-table'
import { Toolbar } from '@trivie/ui-web/components/table-toolbar'
import { useStores } from '@trivie/core'

interface MessagesTableProps {
  tableHelper: TableHelper
  forceLoading?: boolean
}

export const MessagesTable = observer((props: MessagesTableProps) => {
  const { tableHelper, forceLoading } = props
  const { manageUserStore } = useStores()
  const { gdpr } = manageUserStore

  const [selectedRows, setSelectedRows] = useState<any>([])
  const [loading, setLoading] = useState<any>(true)

  useEffect(() => {
    load()
  }, [])

  const load = () => {
    setLoading(true)
    tableHelper.load().then(() => setLoading(false))
  }

  const save = async (columns: any, filter: any) => {
    setLoading(true)
    tableHelper.save(columns, filter).then(() => setLoading(false))
  }

  return loading || forceLoading ? (
    <div style={LOADER}>
      <CircularProgress />
    </div>
  ) : (
    <>
      <DataTable
        gdpr={gdpr}
        columnVisibilityModel={tableHelper.columnVisibilityModel}
        hideAddColumn
        tableHelper={tableHelper}
        filterModel={tableHelper.filterModel}
        sortModel={tableHelper.sortModel}
        searchPlaceholder="Search Messages"
        rows={tableHelper.rows}
        columns={tableHelper.columns}
        onSaveLayout={tableHelper?.saveLayout}
        onSaveFilters={save}
        toolbar={<Toolbar selected={selectedRows} actions={[]} />}
        exportFilename={
          tableHelper.parent !== 'messages' ? `${tableHelper.parent}_messages` : 'messages'
        }
        rowSelectionModel={selectedRows}
        onRowSelectionModelChange={(param) => setSelectedRows(param)}
      />
    </>
  )
})

const LOADER: React.CSSProperties = {
  display: 'flex',
  flex: 1,
  justifyContent: 'center',
  flexDirection: 'column',
  alignItems: 'center',
  height: window.innerHeight / 1.5,
}
