import React, { useEffect, useState } from 'react'
import { observer } from 'mobx-react-lite'

import { useStores } from '@trivie/core'
import { TableHelper } from '../../../../tables'

import { BadgeTable } from '../../../../tables/badge-table'

const CustomBadges: React.FunctionComponent = observer(() => {
  const { badgesStore } = useStores()
  const { triggerRefresh, setTriggerRefresh } = badgesStore

  const [tableHelper] = useState(
    new TableHelper('list_custom_badges', 'badges', 'badges', undefined, 'custom'),
  )

  useEffect(() => {
    if (triggerRefresh) {
      tableHelper.load().then(() => setTriggerRefresh(false))
    }
  }, [triggerRefresh])

  return <BadgeTable forceLoading={!triggerRefresh} tableHelper={tableHelper} />
})

export const CustomBadgesTab = () => {
  return {
    name: 'Custom Badges',
    path: `/activities/badges/custom`,
    component: () => <CustomBadges />,
  }
}
