import { DateTime } from 'luxon'

export function elapsedTime(time: string, mode: 'long' | 'short' = 'short') {
  const createdAt = DateTime.fromISO(time, { zone: 'utc' })
  const now = DateTime.now()
  const elapsed = now.diff(createdAt).toMillis()

  const msPerMinute = 60 * 1000
  const msPerHour = msPerMinute * 60
  const msPerDay = msPerHour * 24
  const msPerMonth = msPerDay * 30
  const msPerYear = msPerDay * 365

  let elapsedValue = ''

  if (elapsed < msPerMinute) {
    elapsedValue = mode === 'short' ? 'Just Now' : 'just now'
  } else if (elapsed < msPerHour) {
    const totalMinutes = Math.round(elapsed / msPerMinute)
    elapsedValue = `${totalMinutes}${
      mode === 'short' ? 'm' : ` minute${totalMinutes > 1 ? 's' : ''} ago`
    }`
  } else if (elapsed < msPerDay) {
    const totalHours = Math.round(elapsed / msPerHour)
    elapsedValue = `${totalHours}${
      mode === 'short' ? 'h' : ` hour${totalHours > 1 ? 's' : ''} ago`
    }`
  } else if (elapsed < msPerMonth) {
    const totalDays = Math.round(elapsed / msPerDay)
    elapsedValue = `${totalDays}${mode === 'short' ? 'd' : ` day${totalDays > 1 ? 's' : ''} ago`}`
  } else if (elapsed < msPerYear) {
    const totalMonths = Math.round(elapsed / msPerMonth)
    elapsedValue = `${totalMonths}${
      mode === 'short' ? 'M' : ` month${totalMonths > 1 ? 's' : ''} ago`
    }`
  } else {
    const totalYears = Math.round(elapsed / msPerYear)
    elapsedValue = `${totalYears}${
      mode === 'short' ? 'y' : ` year${totalYears > 1 ? 's' : ''} ago`
    }`
  }

  return elapsedValue
}
