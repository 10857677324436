import * as React from 'react'
import styled from '@emotion/styled'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import NestedMenuItem from './nested-menu-item'

export const Dropdown = React.forwardRef(
  (
    {
      trigger,
      menu,
      keepOpen: keepOpenGlobal,
      isOpen: controlledIsOpen,
      onOpen: onControlledOpen,
      minWidth,
      posX,
      posY,
    }: any,
    ref,
  ) => {
    const [isInternalOpen, setInternalOpen] = React.useState(null)

    const isOpen = true

    let anchorRef = React.useRef(null)
    if (ref) {
      anchorRef = ref as any
    }

    const handleOpen = (event) => {
      event.stopPropagation()

      if (menu.length) {
        // @ts-ignore
        onControlledOpen
          ? onControlledOpen(event.currentTarget)
          : setInternalOpen(event.currentTarget)
      }
    }

    const handleClose = (event) => {
      event.stopPropagation()

      // @ts-ignore
      if (anchorRef.current && anchorRef.current.contains(event.target)) {
        return
      }

      handleForceClose()
    }

    const handleForceClose = () => {
      onControlledOpen ? onControlledOpen(null) : setInternalOpen(null)
    }

    const renderMenu = (menuItem, index) => {
      const { keepOpen: keepOpenLocal, ...props } = menuItem.props

      let extraProps = {}
      if (props.menu) {
        extraProps = {
          parentMenuOpen: isOpen,
        }
      }

      return React.createElement(menuItem.type, {
        ...props,
        key: index,
        ...extraProps,
        onClick: (event) => {
          event.stopPropagation()

          if (!keepOpenGlobal && !keepOpenLocal) {
            handleClose(event)
          }

          if (menuItem.props.onClick) {
            menuItem.props.onClick(event)
          }
        },
        sx: {
          '&.Mui-focusVisible': {
            backgroundColor: 'white !important',
          },
          '&:hover': {
            backgroundColor: 'rgba(0,0,0,0.1) !important',
          },
        },
        children: props.menu ? React.Children.map(props.menu, renderMenu) : props.children,
      })
    }

    return (
      <>
        {React.cloneElement(trigger, {
          onClick: isOpen ? handleForceClose : handleOpen,
          ref: anchorRef,
        })}

        <Menu
          PaperProps={{
            sx: {
              position: 'absolute',
              minWidth: minWidth ?? 0,
              left: `${posX}px !important`,
              top: `${posY}px !important`,
            },
          }}
          // anchorEl={isOpen}
          open={!!isOpen}
          onClose={handleClose}
        >
          {React.Children.map(menu, renderMenu)}
        </Menu>
      </>
    )
  },
)

export const DropdownMenuItem = styled(MenuItem)`
  display: flex;
  justify-content: space-between !important;

  & > svg {
    margin-left: 32px;
  }
`

export const DropdownNestedMenuItem = styled(NestedMenuItem)`
  display: flex;
  justify-content: space-between !important;

  & > svg {
    margin-left: 32px;
  }
`
