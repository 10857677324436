import React, { useState } from 'react'
import { observer } from 'mobx-react-lite'
import { Route, Switch, useRouteMatch } from 'react-router-dom'
import { ListAltRounded } from '@mui/icons-material'

import { useStores } from '@trivie/core'
import { NavigationTabs } from '@trivie/ui-web/components/navigation-tabs'
import { Header } from '@trivie/ui-web/components/header'

import { AssignmentDetailsScreen } from './assignments-details-screen'
import { AssignmentCreateDialog } from './create-assignment/assignment-create'
import { CompletedAssignmentsTab } from './tabs/assignments-completed-tab'
import { LiveAssignmentsTab } from './tabs/assignments-live-tab'
import { ScheduledAssignmentsTab } from './tabs/assignments-scheduled-tab'

export const AssignmentsScreen: React.FunctionComponent = observer(() => {
  const [showCreateAssignment, setShowCreateAssignment] = useState(false)
  const { params } = useRouteMatch()

  const { mainTab } = params as any

  const { assignmentStore, formStore, manageUserStore, topicsStore } = useStores()
  const { resetLoading } = topicsStore
  const { permissions } = manageUserStore
  const { resetChecked } = assignmentStore
  const { setFormData } = formStore

  const handleCreateAssignment = () => {
    resetChecked()
    resetLoading()
    setFormData({})
    setShowCreateAssignment(true)
  }

  const primary = {
    disabled: !permissions?.permissions.some((p) => p.code === 'can_create_assignment'),
    startIcon: <ListAltRounded />,
    onClick: handleCreateAssignment,
    text: 'Create Assignment',
  }

  return (
    <Switch>
      <Route exact path="/activities/assignments/:mainTab">
        <Header
          title={`${mainTab.charAt(0).toUpperCase() + mainTab.slice(1)} Assignments`}
          primaryAction={primary}
        />
        <NavigationTabs
          tabList={[LiveAssignmentsTab, ScheduledAssignmentsTab, CompletedAssignmentsTab]}
        />
        {showCreateAssignment && (
          <AssignmentCreateDialog
            open={showCreateAssignment}
            requestClose={() => setShowCreateAssignment(false)}
          />
        )}
      </Route>
      <Route exact path="/activities/assignments/:mainTab/:id/:tab">
        <AssignmentDetailsScreen />
      </Route>
    </Switch>
  )
})
