import React, { useEffect, useState } from 'react'
import { observer } from 'mobx-react-lite'
import { IconButton, Paper, Stack } from '@mui/material'
import { Divider } from '@trivie/ui-web/components/divider'
import { useParams } from 'react-router-dom'
import { color } from '@trivie/ui-web/theme'
import { Header } from '@trivie/ui-web/components/header'
import { Text } from '@trivie/ui-web/components/text'
import { GenerateReport } from '@trivie/ui-web/components/generate-report'
import { Insight } from '@trivie/ui-web/components/insight'
import { Edit } from '@mui/icons-material'
import { SurveySchedule } from './survey-schedule'
import { SurveyCard } from './survey-card'
import { PeopleCard } from './people-card'
import { useStores } from '@trivie/core'
import { DateTime } from 'luxon'
import { roundNumber } from '@trivie/core/src/utils/roundNumber'
import { Panel } from '@trivie/ui-web/components/panel/panel'

export const SurveyDetailsScreen: React.FunctionComponent = observer(() => {
  const { id } = useParams() as any
  const [isEditing, setIsEditing] = useState(false)
  const { surveyStore } = useStores()
  const {
    getSurveyDetails,
    getSurveyStats,
    getSurveyQuestionStats,
    getSelectedSurvey,
    selectedSurvey,
  } = surveyStore

  const handleSurveyStats = () => {
    if (getSelectedSurvey()?.isLive || getSelectedSurvey()?.isCompleted) {
      getSurveyStats(id)
      getSurveyQuestionStats(id)
    }
  }
  useEffect(() => {
    getSurveyDetails(id).then(() => {
      handleSurveyStats()
    })
  }, [])

  const renderDateRange = () => {
    if (selectedSurvey?.start_datetime != null && selectedSurvey?.end_datetime != null) {
      const start = DateTime.fromISO(selectedSurvey.start_datetime).toFormat('MMM d, yyyy')
      const end = DateTime.fromISO(selectedSurvey.end_datetime).toFormat('MMM d, yyyy')
      return `${start} - ${end}`
    }
  }

  const renderSurveyStats = () => {
    return (
      <Stack mb="16px" direction="row" justifyContent="space-between" useFlexGap gap={2}>
        <Panel sx={{ flex: 1 }}>
          <Insight
            title="Assignees"
            value={selectedSurvey?.users?.length || '0'}
            valueFontSize={'24px'}
          />
        </Panel>
        <Panel sx={{ flex: 1 }}>
          <Insight
            title="Response Rate "
            value={`${roundNumber(selectedSurvey?.response_rate ?? 0)}%`}
            valueFontSize={'24px'}
          />
        </Panel>
        <Panel sx={{ flex: 1 }}>
          <Insight
            title="Time Remaining"
            value={selectedSurvey?.end_datetime ? `${selectedSurvey?.timeRemaining} Days` : '0'}
            valueFontSize={'24px'}
          />
        </Panel>
      </Stack>
    )
  }

  return (
    <>
      <Header title={selectedSurvey?.title} />
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent:
            selectedSurvey?.isLive || selectedSurvey?.isCompleted ? 'space-between' : 'flex-start',
        }}
      >
        {selectedSurvey?.start_datetime ? (
          <Text style={{ opacity: 0.5 }} variant="body1" text={renderDateRange()} />
        ) : (
          selectedSurvey && (
            <Text
              style={{ opacity: 0.5, marginTop: 6, marginBottom: 6, fontStyle: 'italic' }}
              variant="body1"
              text="Unscheduled"
            />
          )
        )}
        <div style={{ flexDirection: 'row', display: 'flex', justifyContent: 'flex-end' }}>
          {selectedSurvey?.isLive || selectedSurvey?.isCompleted ? (
            <GenerateReport filename={`survey-${id}`} />
          ) : null}
        </div>

        {selectedSurvey && !selectedSurvey.isCompleted && !selectedSurvey.isLive ? (
          <IconButton onClick={() => setIsEditing(true)}>
            <Edit htmlColor={color.shade50} style={{ fontSize: 14 }} />
          </IconButton>
        ) : null}
      </div>

      <Divider sx={{ my: '32px' }} />

      {selectedSurvey?.isLive || selectedSurvey?.isCompleted ? renderSurveyStats() : null}
      <div style={{ display: 'flex', flexDirection: 'row', minHeight: 600 }}>
        <SurveyCard />
        <PeopleCard />
      </div>
      {isEditing ? (
        <SurveySchedule open={isEditing} requestClose={() => setIsEditing(false)} />
      ) : null}
    </>
  )
})
