import React, { useState } from 'react'
import { Add } from '@mui/icons-material'
import { observer } from 'mobx-react-lite'
import { Route, Switch, useHistory } from 'react-router-dom'
import { AutoFixHighOutlined } from '@mui/icons-material'

import { useStores } from '@trivie/core'
import { Header } from '@trivie/ui-web/components/header'

import { TableHelper } from '../../../tables'
import { QuizTable } from '../../../tables/quiz-table'
import { QuizzesDetailsScreen } from './quizzes-details-screen'

export const QuizzesScreen: React.FunctionComponent = observer(() => {
  const { manageUserStore, contentCreationStore } = useStores()
  const history = useHistory()

  const { resetContentStore } = contentCreationStore
  const { permissions, userProfile } = manageUserStore

  const [tableHelper] = useState(new TableHelper('list_quizzes', 'quizzes', 'quizzes'))

  const primary = {
    startIcon: <Add />,
    disabled: !permissions?.permissions.some((p) => p.code === 'can_create_quiz'),
    onClick: () => {
      resetContentStore()
      history.push('/content/creation/quiz/new/edit/?generate=true&create=true')
    },
    text: 'Create Quiz',
  }

  const secondary = {
    color: 'gradient',
    disabled:
      !permissions?.permissions.some((p) => p.code === 'can_create_quiz') ||
      !userProfile?.workspace_settings?.mercury_enabled,
    startIcon: <AutoFixHighOutlined />,
    onClick: () => {
      resetContentStore()
      history.push('/generate')
    },
    text: 'Generate Quiz with AI',
    sx: { '&:hover': { opacity: 0.8 } },
  }

  return (
    <Switch>
      <Route exact path="/content/quizzes">
        <Header divider title="Quizzes" primaryAction={primary} secondaryAction={secondary} />
        <QuizTable tableHelper={tableHelper} />
      </Route>
      <Route path="/content/quizzes/:id/:tab">
        <QuizzesDetailsScreen />
      </Route>
    </Switch>
  )
})
