import React, { useEffect, useRef, useState } from 'react'
import { observer } from 'mobx-react-lite'
import { People } from '@mui/icons-material'
import { color } from '../../theme'
import { InputBase } from '@mui/material'
import { useStores } from '@trivie/core'
import { Group } from '../group'
import { GroupApi } from '@trivie/core/src/services/api-objects/GroupApi'

export interface GroupSelectorProps {
  onChange: (groups: any) => void
  onExclude?: any
}

export const GroupSelector = observer((props: GroupSelectorProps) => {
  const { onChange } = props
  const { groupStore } = useStores()
  const { groupsFilter, setGroupsFilter } = groupStore
  const [selectedGroups, setSelectedGroups] = useState<any>([])
  const [showPopper, setShowPopper] = useState(false)

  const selectorRef = useRef(null)

  const handleBlur = (e: any) => {
    if (!e.composedPath().some((el: any) => el === selectorRef.current)) {
      setShowPopper(false)
    }
  }

  const handleFocus = () => {
    setShowPopper(true)
  }

  const [groups, setGroups] = useState([])
  const [availableGroups, setAvailableGroups] = useState<any>([])

  useEffect(() => {
    GroupApi.getAllGroups('groups', false, [], 'list_groups').then((resp) => {
      if (resp.ok) {
        // @ts-ignore
        const groupList = resp.data.rows.map((group) => {
          const label = group.columns.find((column) => column.field === 'group_name')
          const number_of_users = group.columns.find(
            (column) => column.field === 'group_user_count',
          )
          return { group_id: group.id, label: label.value, number_of_users: number_of_users.value }
        })

        setGroups(groupList)
        setAvailableGroups(
          groupList.filter(
            (group: any) => !selectedGroups.some((g: any) => g.group_id === group.group_id),
          ),
        )
      }
    })

    window.addEventListener('click', handleBlur)

    return () => {
      window.removeEventListener('click', handleBlur)
    }
  }, [])

  useEffect(() => {
    onChange(selectedGroups)
    setAvailableGroups(
      groups.filter(
        (group: any) => !selectedGroups.some((g: any) => g.group_id === group.group_id),
      ),
    )
  }, [selectedGroups])

  const handleGroupAdd = (group: any) => {
    setGroupsFilter(undefined)
    setSelectedGroups([...selectedGroups, group])
  }

  const handleGroupRemove = (groupId: any) => {
    setSelectedGroups(selectedGroups.filter((g: any) => g.group_id !== groupId))
  }

  const handleGroupsFilterChange = (e: any) => {
    setGroupsFilter(e.target.value)
  }

  return (
    <div style={{ position: 'relative' }} ref={selectorRef}>
      <div onFocus={handleFocus} style={SELECTED_CONTAINER}>
        <People htmlColor={color.palette.grey} />
        {selectedGroups.map((group: any) => {
          return (
            <Group
              group={group}
              onDelete={handleGroupRemove}
              style={{ marginLeft: 12, marginBottom: 6, marginTop: 6 }}
            />
          )
        })}
        <InputBase
          value={groupsFilter}
          placeholder="Add group"
          style={{ height: 29, marginLeft: 12, marginTop: 6, marginBottom: 6 }}
          onChange={handleGroupsFilterChange}
        />
      </div>

      <div style={{ ...GROUP_SELECTOR, visibility: showPopper ? 'visible' : 'hidden' }}>
        {availableGroups.map((group: any) => (
          <Group group={group} onAdd={handleGroupAdd} style={GROUP} />
        ))}
      </div>
    </div>
  )
})

const SELECTED_CONTAINER: React.CSSProperties = {
  display: 'flex',
  flexWrap: 'wrap',
  alignItems: 'center',
  borderStyle: 'solid',
  borderWidth: 1,
  borderColor: color.palette.paperBorder,
  borderRadius: 6,
  backgroundColor: color.palette.white,
  paddingLeft: 13,
  paddingRight: 13,
}
const GROUP_SELECTOR: React.CSSProperties = {
  width: '100%',
  display: 'flex',
  border: `1px solid ${color.palette.paperBorder}`,
  borderRadius: 6,
  position: 'absolute',
  paddingTop: 12,
  paddingLeft: 8,
  paddingRight: 8,
  flexWrap: 'wrap',
  backgroundColor: color.white,
  marginTop: 4,
}
const GROUP: React.CSSProperties = {
  backgroundColor: color.palette.paperBorder,
  marginRight: 12,
  marginBottom: 12,
}
