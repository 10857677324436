import { types } from 'mobx-state-tree'

export const UserQuestionStat = types.model('UserQuestionStat').props({
  question_id: types.number,
  question_text: types.string,
  total_answers: types.number,
  knowledge_gain: types.number,
  pct_correct: types.number,
  avg_answer_time: types.number,
})
