import React, { useState } from 'react'

import { BadgeAvatar } from '../../badge-avatar'
import { PreviewBadge } from '../../badge-users'
import { Text } from '../../text'

export const BadgePreviewCell = (props: any) => {
  const { params } = props
  const [showPreview, setShowPreview] = useState(false)

  return (
    <>
      <BadgeAvatar
        onClick={() => setShowPreview(true)}
        size={36}
        name={'avatar'}
        url={params.row.photo_url}
        hoverable={false}
      />
      <Text
        noWrap
        onClick={() => setShowPreview(true)}
        variant="body1Emphasized"
        text={params.row.badge_title}
        style={{ marginLeft: 12, cursor: 'pointer' }}
      />
      {showPreview && (
        <PreviewBadge open={showPreview} badge={params.row} onClose={() => setShowPreview(false)} />
      )}
    </>
  )
}
