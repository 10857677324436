import { mergeAttributes } from '@tiptap/core'
import { ReactNodeViewRenderer } from '@tiptap/react'

import Paragrah from '@tiptap/extension-paragraph'
import { ParagraphNode } from './paragraph-node'

export const TParagraph = Paragrah.extend({
  name: 'paragraph',
  addOptions() {
    return {
      HTMLAttributes: {},
      quiz: {},
      selectQuestion: (q: any) => {},
    }
  },
  addAttributes() {
    return {
      ...this.parent?.(),
      generated_question_ids: [],
      node_id: 0,
      question_id: [],
      citations: [],
      generating: false,
    }
  },
  draggable: false,
  parseHTML() {
    return [{ tag: 'p', attrs: { generated_question_ids: [] } }]
  },

  renderHTML({ node, HTMLAttributes }) {
    return [`p`, mergeAttributes(this.options.HTMLAttributes, HTMLAttributes), 0]
  },

  addNodeView() {
    return ReactNodeViewRenderer(ParagraphNode, {
      as: 'p',
      contentDOMElementTag: 'span',
      attrs: {
        ...this.options.HTMLAttributes,
      },
    })
  },
})
