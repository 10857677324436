import React, { useEffect, useState } from 'react'
import { Menu } from '@mui/material'
import FormControl from '@mui/material/FormControl'
import makeStyles from '@mui/styles/makeStyles'
import { Check, KeyboardArrowDownRounded } from '@mui/icons-material'
import { observer } from 'mobx-react-lite'
import { Text } from '../../text'
import { color } from '../../../theme'
import { Admin } from '@trivie/core'
import { Instance } from 'mobx-state-tree'

const useStyles = makeStyles(() => ({
  formControl: {
    minWidth: 120,
    height: '100%',
    justifyContent: 'center',
  },
  input: {
    position: 'relative',
    '& .MuiInput-input:hover': {
      border: 'none',
      borderColor: '#fff',
      boxShadow: 'none',
      outline: 'none',
      position: 'relative',
      marginLeft: 0,
    },
    '& .MuiInput-input': {
      padding: 0,
      border: 0,
      position: 'relative',
    },
    '& .MuiInput-input:focus': {
      border: 0,
      outline: 0,
      boxShadow: 'none',
      paddingLeft: 0,
      backgroundColor: 'transparent',
    },
    '& .MuiInput-underline': {
      borderBottom: 'none',
    },
  },
  menu: {
    '& .MuiListItem-gutters': {
      paddingLeft: 0,
      paddingRight: 0,
    },
    '& .MuiList-padding': {
      paddingTop: 0,
      paddingBottom: 0,
    },
  },

  menuItem: {
    '&:hover': {
      backgroundColor: 'rgba(239, 240, 246, .5) !important',
    },
  },
}))

interface DropdownCellProps {
  items: any[]
  handleChange: (admin: Instance<typeof Admin>, role: any) => void
  params: any
  selected: any
  disabled?: boolean
}
export const DropdownCell = observer((props: DropdownCellProps) => {
  const { items, params, disabled, selected, handleChange } = props

  const classes = useStyles()

  const [anchorEl, setAnchorEl] = React.useState(null)

  const onChange = (item: any) => {
    handleChange(params.row, item)
    handleClose()
  }

  const handleOpen = (event: any) => setAnchorEl(event.currentTarget)

  const handleClose = () => setAnchorEl(null)

  return (
    <FormControl variant="standard" disabled={disabled} className={classes.formControl}>
      <div
        onClick={disabled ? () => {} : handleOpen}
        style={{ ...SELECT, cursor: disabled ? 'default' : 'pointer' }}
      >
        <Text
          style={{ marginRight: 8, color: disabled ? color.shade50 : color.black }}
          variant="body1Medium"
          text={selected?.name}
        />
        <KeyboardArrowDownRounded style={{ fontSize: 18, color: '#9098A3' }} />
      </div>
      <DropdownMenu
        selected={selected}
        items={items}
        onChange={onChange}
        handleClose={handleClose}
        anchorEl={anchorEl}
      />
    </FormControl>
  )
})

interface DropdownMenuProps {
  items: any[]
  handleClose: () => void
  onChange: (item: any) => void
  anchorEl: any
  selected: any
}
export const DropdownMenu = observer((props: DropdownMenuProps) => {
  const { anchorEl, selected, handleClose, items, onChange } = props

  const classes = useStyles()

  return (
    <Menu
      className={classes.menu}
      anchorEl={anchorEl}
      keepMounted
      open={Boolean(anchorEl)}
      onClose={handleClose}
      style={{ padding: 0 }}
    >
      {items.map((item) => (
        <div
          onClick={() => onChange(item)}
          className={classes.menuItem}
          style={{
            ...MENU_ITEM_CONTAINER,
            backgroundColor: item.id === selected?.id ? '#EFF0F6' : color.transparent,
          }}
        >
          <div style={MENU_ITEM}>
            <div style={MENU_TEXT}>
              <Text style={{ marginBottom: 8 }} text={item.name} variant="body1Medium" />
              <Text text={item.summary} variant="body2" />
            </div>
            {item.id === selected?.id && <Check htmlColor={color.blue} style={{ fontSize: 20 }} />}
          </div>
        </div>
      ))}
    </Menu>
  )
})

const SELECT: React.CSSProperties = { display: 'flex', flexDirection: 'row', cursor: 'pointer' }
const MENU_ITEM_CONTAINER: React.CSSProperties = { paddingLeft: 16, paddingRight: 16, zIndex: 999 }
const MENU_TEXT: React.CSSProperties = { flexDirection: 'column', width: 472 }
const MENU_ITEM: React.CSSProperties = {
  flexDirection: 'row',
  paddingTop: 10,
  paddingBottom: 10,
  alignItems: 'center',
  justifyContent: 'space-between',
  display: 'flex',
  cursor: 'pointer',
}
