import { types } from 'mobx-state-tree'

export const LeaderboardTagItem = types.model('LeaderboardTagItem').props({
  tag_id: types.number,
  tag_key: types.string,
  tag_value: types.string,
  points: types.number,
  previous_rank: types.maybeNull(types.number),
  rank: types.maybeNull(types.number),
  contains_current_user: types.boolean,
})
