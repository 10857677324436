import { types } from 'mobx-state-tree'

export const factoidErrors = {
  length: {
    problem:
      '"factoid text character length not in allowed paramters (1-300). length: {factoid_length}"',
  },
}

export const Factoid = types
  .model('Factoid')
  .props({
    factoid_id: types.maybeNull(types.number),
    text: types.maybeNull(types.string),
    resource_id: types.maybeNull(types.number),
    generating: types.maybe(types.boolean),
    modified: types.maybe(types.boolean),
  })
  .actions((self) => ({
    setGenerating(status) {
      self.generating = status
    },
    setModified() {
      self.modified = true
    },
    setText(text) {
      this.setModified()
      self.text = text
    },
  }))
