import React, { useEffect, useState } from 'react'
import { observer } from 'mobx-react-lite'
import { Route, Switch, useHistory } from 'react-router-dom'
import { PublishRounded, Add } from '@mui/icons-material'
import { useStores } from '@trivie/core'
import { TableHelper } from '../../../tables'
import { Header } from '@trivie/ui-web/components/header'
import { TopicsDetailsScreen } from './topics-details-screen'
import { ImportTopicsDialog } from './import-topics'
import { AssignmentCreateDialog } from '../../activities/assignments/create-assignment/assignment-create'
import { TopicTable } from '../../../tables/topic-table'

export const TopicsScreen: React.FunctionComponent = observer(() => {
  const { topicsStore, formStore, contentCreationStore, manageUserStore } = useStores()
  const { setTopics } = topicsStore
  const { setFormData } = formStore
  const { setPublishedSuccessful, resetContentStore } = contentCreationStore
  const { permissions } = manageUserStore

  const [showImportTopics, setShowImportTopics] = useState(false)
  const [showWizard, setShowWizard] = useState(false)
  const [assignmentTopicIds, setAssignmentTopicIds] = useState<string[] | null>(null)

  const [tableHelper] = useState(new TableHelper('list_topics', 'topics', 'topics'))

  const history = useHistory()

  useEffect(() => {
    setPublishedSuccessful(false)
    return () => {
      setPublishedSuccessful(false)
    }
  }, [])

  const handleCreateTopic = () => {
    resetContentStore()
    setFormData(undefined)
    resetContentStore()
    history.push('/content/creation/topic')
  }

  const handleImportTopic = () => {
    resetContentStore()
    setShowImportTopics(true)
  }

  const primary = {
    disabled: !permissions?.permissions.some((p) => p.code === 'can_create_topic'),
    startIcon: <PublishRounded />,
    onClick: handleImportTopic,
    text: 'Import Topics',
  }

  const secondary = {
    disabled: !permissions?.permissions.some((p) => p.code === 'can_create_topic'),
    startIcon: <Add />,
    onClick: handleCreateTopic,
    text: 'Create Topic',
  }

  return (
    <Switch>
      <Route exact path="/content/topics">
        <Header divider title="Topics" primaryAction={primary} secondaryAction={secondary} />
        <TopicTable tableHelper={tableHelper} />
        {showImportTopics && (
          <ImportTopicsDialog
            onClose={() => {
              setTopics(null)
              setShowImportTopics(false)
            }}
            open={showImportTopics}
            onFileRead={() => {
              setShowImportTopics(false)
            }}
          />
        )}
        {showWizard && (
          <AssignmentCreateDialog
            fromTopics
            open={showWizard}
            initialTopics={
              assignmentTopicIds ? assignmentTopicIds.map((id) => parseInt(id)) : undefined
            }
            requestClose={() => {
              resetContentStore()
              setShowWizard(false)
              history.push(`/content/topics`)
            }}
          />
        )}
      </Route>
      <Route path="/content/topics/:id/:tab">
        <TopicsDetailsScreen />
      </Route>
    </Switch>
  )
})
