import { ApiResponse, ApisauceInstance } from 'apisauce'
import { Instance } from 'mobx-state-tree'
import { UserTag } from '../../models/UserTag'
import { handleResponse } from '../handleResponse'

export const TagApi = {
  apisauce: {} as ApisauceInstance,
  getAllTags: async (
    type: 'users' | 'topics' | 'quizzes' | 'groups' | 'tags',
    meta?: boolean,
    columns?: any,
    view_name?: string,
    id?: number,
  ) => {
    const payload = { view_name }
    if (columns && columns.length > 0) {
      payload['columns'] = columns
    }
    const response = await TagApi.apisauce.post(
      Boolean(id)
        ? `/${type}/${id}/tags/?meta=${meta}&asynchronous=${false}`
        : `/${type}/?meta=${meta}&asynchronous=${false}`,
      payload,
    )

    return handleResponse(response)
  },
  getUserTags: async (userId: number) => {
    const response: ApiResponse<Array<Instance<typeof UserTag>>> = await TagApi.apisauce.get(
      `/users/${userId}/tags/`,
    )

    return handleResponse(response)
  },
  getTagCategories: async () => {
    const response: ApiResponse<{
      tag_keys: Array<string>
    }> = await TagApi.apisauce.get(`/tags/categories/`)

    return handleResponse(response)
  },
  editTag: async (tag_id: number, payload: any) => {
    const response: ApiResponse<Record<string, string>> = await TagApi.apisauce.patch(
      `/tags/${tag_id}/`,
      payload,
    )
    return handleResponse(response)
  },
}
