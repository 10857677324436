import { ApiResponse } from 'apisauce'

export type GeneralApiProblem =
  /**
   * Times up.
   */
  | { kind: 'timeout'; temporary: true }
  /**
   * Cannot connect to the server for some reason.
   */
  | { kind: 'cannot-connect'; temporary: true }
  /**
   * The server experienced a problem. Any 5xx error.
   */
  | { kind: 'server'; message?: '' }

  /**
   * The server is undergoing maintenance. 503 error.
   */
  | { kind: 'unavailable'; message?: '' }
  /**
   * We're not allowed because we haven't identified ourself. This is 401.
   */
  | { kind: 'unauthorized' }
  /**
   * We don't have access to perform that request. This is 403.
   */
  | { kind: 'forbidden'; message: '' }
  /**
   * Unable to find that resource.  This is a 404.
   */
  | { kind: 'not-found' }
  /**
   * 409 - Conflict
   */
  | { kind: 'conflict'; message: '' }
  /**
   * 420 - Unprocessable Entity
   */
  | { kind: 'wait'; message: '' }
  /**
   * 422 - Unprocessable Entity
   */
  | { kind: 'unprocessable-entity'; message: '' }
  /**
   * All other 4xx series errors.
   */
  | { kind: 'rejected'; message: '' }
  /**
   * Something truly unexpected happened. Most likely can try again. This is a catch all.
   */
  | { kind: 'unknown'; temporary: true }
  /**
   * The data we received is not in the expected format.
   */
  | { kind: 'bad-data' }
  /**
   * The request was cancelled
   */
  | { kind: 'cancelled' }
  | null

/**
 * Attempts to get a common cause of problems from an api response.
 *
 * @param response The api response.
 */
export function getGeneralApiProblem(response: ApiResponse<any>): GeneralApiProblem | null {
  switch (response.problem) {
    case 'CONNECTION_ERROR':
      return { kind: 'cannot-connect', temporary: true }
    case 'NETWORK_ERROR':
      if (response.status === 500) {
        return { kind: 'server', message: response?.data?.detail }
      }
      return { kind: 'unavailable', message: response?.data?.detail }
    case 'TIMEOUT_ERROR':
      return { kind: 'timeout', temporary: true }
    case 'SERVER_ERROR':
      if (response.status === 503) {
        return { kind: 'unavailable', message: response?.data?.detail }
      }
      return { kind: 'server', message: response?.data?.detail }

    case 'CLIENT_ERROR':
      switch (response.status) {
        case 400:
          return { kind: 'rejected', message: response.data.detail }
        case 401:
          return { kind: 'unauthorized' }
        case 403:
          return { kind: 'forbidden', message: response.data.detail }
        case 404:
          return { kind: 'not-found' }
        case 409:
          if (response.data.length > 0) {
            return {
              kind: 'conflict',
              message: `${response.data.map((e) => `• ${e.warning}`).join('\n')}` as any,
            }
          }
          return {
            kind: 'conflict',
            message: response.data?.warning || response.data[0]?.warning || response.data?.detail,
          }
        case 420:
          return {
            kind: 'wait',
            message: response.data?.detail,
          }
        case 422:
          return {
            kind: 'unprocessable-entity',
            message: response.data?.detail,
          }
        default:
          return { kind: 'unknown', temporary: true }
      }
    case 'CANCEL_ERROR':
      return { kind: 'cancelled' }
    default:
      return null
  }
}
