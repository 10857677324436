import React from 'react'
import { color } from '../../theme'
import { Text } from '../text'
import { NavLink, useLocation } from 'react-router-dom'

const NAV_ITEM: React.CSSProperties = {
  height: 50,
  width: '100%',
  cursor: 'pointer',
}

const ACTIVE_LINK_TOP: React.CSSProperties = {
  textDecoration: 'none',
  alignSelf: 'center',
  alignItems: 'center',
  display: 'flex',
  width: '100%',
  color: color.blue,
  fontSize: 14,
  fontWeight: 600,
  height: '100%',
}

const LINK: React.CSSProperties = {
  height: 50,
  textDecoration: 'none',
  alignSelf: 'center',
  display: 'flex',
  color: 'rgba(0,0,0,0.7)',
  width: '100%',
  alignItems: 'center',
  fontWeight: 500,
  letterSpacing: '-0.154',
  marginBottom: 8,
}

const LEFT_BORDER: React.CSSProperties = {
  backgroundColor: color.palette.blueLink,
  width: 4,
  height: 32,
  borderTopRightRadius: 100,
  borderBottomRightRadius: 100,
  marginRight: 16,
}

export interface NavItemProps {
  label: string
  hash: string
  icon: any
  onClick: (id: string) => void
}

export const NavItem = (props: NavItemProps) => {
  const { label, icon, hash, onClick } = props
  const location = useLocation()
  const isActive = location.hash === hash

  return (
    <li style={{ ...NAV_ITEM, paddingTop: 0 }} onClick={() => onClick(hash)}>
      <NavLink
        activeStyle={ACTIVE_LINK_TOP}
        style={{
          ...LINK,
          color: isActive ? color.palette.blueLink : color.shade70,
          fontWeight: isActive ? 600 : 500,
        }}
        to={{
          hash,
        }}
      >
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            color: isActive ? color.palette.blueLink : color.shade50,
          }}
        >
          <div
            style={{
              ...LEFT_BORDER,
              backgroundColor: isActive ? color.palette.blueLink : 'unset',
            }}
          />
          {icon}
          <Text variant="body1Medium" text={label} style={{ marginLeft: 19, color: 'inherit' }} />
        </div>
      </NavLink>
    </li>
  )
}
