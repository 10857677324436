import ReactEChartsCore from 'echarts-for-react/lib/core'
import { LineChart } from 'echarts/charts'
import { GridComponent, DataZoomComponent, TooltipComponent } from 'echarts/components'
import * as echarts from 'echarts/core'
import { CanvasRenderer } from 'echarts/renderers'
import React, { useEffect, useRef } from 'react'
import { DateTime } from 'luxon'

const _ = require('lodash')

// Register the required components
echarts.use([GridComponent, DataZoomComponent, TooltipComponent, LineChart, CanvasRenderer])

interface LineGraphProps {
  data: any[]
  unit?: any
  format?: any
  height?: number | 'auto' | null
  width?: number | 'auto' | null
}

export const LineGraph = (props: LineGraphProps) => {
  const { data, height, width, unit, format } = props

  const averagedData = data
    ? _.chain(data as any)
        .groupBy((result: any) =>
          DateTime.fromISO(result.datetime_recorded).toFormat(format || 'yyyy-MM-dd'),
        )
        .map((entries: any, datetime: any) => [
          datetime,
          _.meanBy(entries, (entry: any) => {
            if (unit === ' Minutes') {
              return entry.value / 60
            } else {
              return entry.value
            }
          }),
        ])
        .value()
    : []

  const formattedData = data
    ? data.map((entry: any) => {
        let v
        if (unit === ' Minutes') {
          v = entry.value / 60
        } else {
          v = entry.value
        }
        return [DateTime.fromISO(entry.datetime_recorded).toFormat(format || 'yyyy-MM-dd'), v]
      })
    : []

  const DEFAULT_OPTION = {
    color: ['#0062FF'],
    grid: {
      height: '50%',
      top: 8,
    },
    xAxis: [
      {
        show: false,
        boundaryGap: false,
        type: 'category',
        step: false,
        data: formattedData?.map((item: any) =>
          DateTime.fromFormat(item[0], format || 'yyyy-MM-dd').toFormat('MMM dd'),
        ),
        axisPointer: {
          snap: true,
        },
      },
    ],
    yAxis: [
      {
        show: false,
        type: 'value',
      },
    ],
    series: [
      {
        name: 'Current Knowledge',
        type: 'line',
        smooth: true,
        lineStyle: {
          width: 2,
          color: '#0062FF',
          cap: 'round',
          shadowOffsetY: 3,
          shadowColor: '#fff',
        },
        sampling: 'average',
        animationEasing: 'quadraticInOut',
        showSymbol: false,
        areaStyle: {
          opacity: 0.1,
          color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
            {
              offset: 0.5,
              color: '#0062FF',
            },
            {
              offset: 1,
              color: 'rgba(255, 255, 255, 0.001)',
            },
          ]),
        },
        emphasis: {
          lineStyle: {
            width: 2,
          },
        },
        data: formattedData?.map((item: any) => item[1].toFixed(0)),
      },
    ],
  }

  const echartRef = useRef()

  useEffect(() => {
    // @ts-ignore
    const echarts_instance = echartRef.current.getEchartsInstance()
    echarts_instance.clear()
    setTimeout(() => {
      echarts_instance.setOption(DEFAULT_OPTION)
    }, 400)
    return () => echarts_instance.clear()
  }, [])

  return (
    <ReactEChartsCore
      // @ts-ignore
      ref={echartRef}
      echarts={echarts}
      style={{ height: height ?? 45, width: width ?? 120 }}
      option={{}}
      notMerge={true}
      theme={'theme_name'}
      opts={{ height: height ?? 45, width: width ?? 120 }}
    />
  )
}
