import * as React from 'react'
import { makeStyles } from '@mui/styles'
import HoverMenu from 'material-ui-popup-state/HoverMenu'
import MenuItem from '@mui/material/MenuItem'
import ChevronRight from '@mui/icons-material/ChevronRight'
import { usePopupState, bindHover, bindFocus, bindMenu } from 'material-ui-popup-state/hooks'
import { Add, AddCircle, AutoFixHigh, AutoFixNormal } from '@mui/icons-material'
import { observer } from 'mobx-react-lite'

import { color } from '@trivie/ui-web/theme'
import { Text } from '@trivie/ui-web/components/text'
import { Button } from '@trivie/ui-web/components/button'

const useCascadingMenuStyles = makeStyles((theme) => ({
  submenu: {
    marginTop: theme.spacing(-1),
  },
  title: {
    flexGrow: 1,
  },
  moreArrow: {
    marginRight: theme.spacing(-1),
  },
}))

const CascadingContext = React.createContext({
  parentPopupState: null,
  rootPopupState: null,
})

export const CascadingMenuItem = observer(({ onClick, ...props }: any) => {
  const { rootPopupState } = React.useContext(CascadingContext) as any
  if (!rootPopupState) throw new Error('must be used inside a CascadingMenu')
  const handleClick = React.useCallback(
    (event) => {
      rootPopupState.close(event)
      if (onClick) onClick(event)
    },
    [rootPopupState, onClick],
  )

  return <MenuItem {...props} onClick={handleClick} />
})

export const CascadingSubmenu = observer(({ title, popupId, disabled, ...props }: any) => {
  const classes = useCascadingMenuStyles()
  const { parentPopupState } = React.useContext(CascadingContext)
  const popupState = usePopupState({ popupId, variant: 'popover', parentPopupState })
  return (
    <React.Fragment>
      <MenuItem
        disabled={disabled}
        sx={MENU_ITEM}
        {...bindHover(popupState)}
        {...bindFocus(popupState)}
      >
        <div style={SUBMENU}>
          <div style={{ flexDirection: 'row', display: 'flex' }}>
            <AutoFixNormal style={ICON} htmlColor={color.shade70} />
            <span className={classes.title}>
              <Text style={LABEL} text={title} variant="body2Medium" />
            </span>
          </div>
          <ChevronRight className={classes.moreArrow} />
        </div>
      </MenuItem>
      <CascadingMenu
        {...props}
        classes={{ ...props.classes, paper: classes.submenu }}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        transformOrigin={{ vertical: 60, horizontal: 0 }}
        popupState={popupState}
      />
    </React.Fragment>
  )
})

export const CascadingMenu = observer(({ popupState, ...props }: any) => {
  const { rootPopupState } = React.useContext(CascadingContext)
  const context = React.useMemo(
    () => ({ rootPopupState: rootPopupState || popupState, parentPopupState: popupState }),
    [rootPopupState, popupState],
  )

  return (
    <CascadingContext.Provider value={context}>
      <HoverMenu {...props} {...bindMenu(popupState)} />
    </CascadingContext.Provider>
  )
})

const CascadingHoverMenus = observer((props: any) => {
  const {
    hideIcon,
    label,
    hideGenerateQuiz,
    mercuryEnabled,
    disabled,
    onGenerate,
    onImport,
    onAdd,
    buttonStyle,
    disableAdd,
    popupTransform,
  } = props
  const popupState = usePopupState({ popupId: 'demoMenu', variant: 'popover' })
  return (
    <>
      <Button
        variant="contained"
        color="black"
        fullWidth
        disabled={disableAdd}
        disableElevation
        {...bindHover(popupState)}
        {...bindFocus(popupState)}
        // style={{ ...ADD_BUTTON, ...buttonStyle }}
        text={label ?? 'Add'}
        startIcon={hideIcon ? null : <Add htmlColor={color.white} />}
      />
      <CascadingMenu
        popupState={popupState}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        transformOrigin={
          popupTransform
            ? popupTransform
            : { vertical: mercuryEnabled ? 128 : 72, horizontal: 'center' }
        }
      >
        {mercuryEnabled && !hideGenerateQuiz && (
          <CascadingMenuItem sx={MENU_ITEM} onClick={() => onGenerate('quiz')}>
            <AutoFixHigh style={ICON} htmlColor={color.shade70} />
            <Text style={LABEL} text="Generate Quiz" variant="body2Medium" />
          </CascadingMenuItem>
        )}
        {mercuryEnabled && (
          <CascadingSubmenu
            disabled={disabled}
            popupId="moreChoicesCascadingMenu"
            title="Generate Question"
          >
            <CascadingMenuItem sx={MENU_ITEM} onClick={() => onGenerate('TF')}>
              <Text style={LABEL} text="True/False" variant="body2Medium" />
            </CascadingMenuItem>
            <CascadingMenuItem sx={MENU_ITEM} onClick={() => onGenerate('FITB')}>
              <Text style={LABEL} text="Fill-In-The-Blank" variant="body2Medium" />
            </CascadingMenuItem>
            <CascadingMenuItem sx={MENU_ITEM} onClick={() => onGenerate('MC')}>
              <Text style={LABEL} text="Multiple Choice" variant="body2Medium" />
            </CascadingMenuItem>
          </CascadingSubmenu>
        )}
        <CascadingMenuItem sx={MENU_ITEM} onClick={onImport}>
          <AddCircle style={ICON} htmlColor={color.shade70} />
          <Text style={LABEL} text="Import Question" variant="body2Medium" />
        </CascadingMenuItem>
        <CascadingMenuItem sx={MENU_ITEM} onClick={onAdd}>
          <Add style={ICON} htmlColor={color.shade70} />
          <Text style={LABEL} text="Add Blank Question" variant="body2Medium" />
        </CascadingMenuItem>
      </CascadingMenu>
    </>
  )
})

const MENU_ITEM = { '&:hover': { backgroundColor: '#F2F7FF' } }
const ICON = { fontSize: 14, marginRight: 8 }
const LABEL = { color: color.shade70 }
const SUBMENU: React.CSSProperties = {
  width: 234,
  justifyContent: 'space-between',
  flexDirection: 'row',
  display: 'flex',
  alignItems: 'center',
}

export default CascadingHoverMenus
