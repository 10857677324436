import React, { useEffect, useState } from 'react'
import { observer } from 'mobx-react-lite'

interface CitationProps {
  citation: any
  onClick: any
}

export const Citation = observer((props: CitationProps) => {
  const { citation, onClick } = props

  const onHover = (citation) => {
    document.querySelectorAll('[node_id]').forEach((node) => {
      const gqids = node.getAttribute('generated_question_ids')
      const ids = gqids?.split(',').map((id) => Number(id)) ?? []
      const content =
        node.tagName === 'TR'
          ? node
          : node.classList.contains('list-item-content')
            ? node.querySelector('.paragraph-node-content>span')
            : node.children[0]
      if (gqids && ids?.includes(citation.gqid) && !content?.classList.contains('HoveredFact')) {
        content?.classList.add('HoveredFact')
      }
    })
  }

  const onLeave = () => {
    document.querySelectorAll('.HoveredFact').forEach((e) => e.classList.remove('HoveredFact'))
  }

  const selectFact = (citation) => {
    document.querySelectorAll('.SelectedFact').forEach((e) => e.classList.remove('SelectedFact'))
    document.querySelectorAll('[node_id]').forEach((node) => {
      const gqids = node.getAttribute('generated_question_ids')
      const ids = gqids?.split(',').map((id) => Number(id)) ?? []
      const content =
        node.tagName === 'TR'
          ? node
          : node.classList.contains('list-item-content')
            ? node.querySelector('.paragraph-node-content>span')
            : node.children[0]
      if (gqids && ids?.includes(citation.gqid)) {
        content?.classList.add('SelectedFact')
      }
    })
  }

  const handleClick = () => {
    selectFact(citation)
    onClick()
  }

  const hideDuplicateCitations = () => {
    const cits = document.querySelectorAll('sup')
    const citMap = {} as any

    cits.forEach((c, index) => {
      if (cits.length - 1 !== index || cits.length !== index) {
        citMap[`${c.innerText}`.trim()] = citMap[`${c.innerText}`.trim()]
          ? [...citMap[`${c.innerText}`.trim()], c]
          : [c]
      }
    })

    Object.values(citMap).map((citationArr: any) => {
      citationArr?.map((cit: HTMLElement, index) => {
        if (citationArr?.length - 1 !== index) {
          cit.setAttribute('style', 'display: none;')
        }
        const p = cit.parentElement?.querySelector('.paragraph-node-content')?.children[0]
        if (p) {
          p?.appendChild(cit)
        }
      })
    })
  }

  useEffect(() => {
    hideDuplicateCitations()
  }, [])

  return (
    <span>
      <sup
        className="citation"
        key={`${citation.level}`}
        style={{ fontStyle: 'normal' }}
        onMouseEnter={(e) => onHover(citation)}
        onMouseLeave={(e) => onLeave()}
        onClick={handleClick}
      >{`(${citation.level}) `}</sup>
    </span>
  )
})
