import React, { useState } from 'react'
import type { Meta, StoryObj } from '@storybook/react'

import { ConfirmationDialog } from './confirmation-dialog'
import { Button } from '../../button'

const meta: Meta<typeof ConfirmationDialog> = {
  title: 'Molecules/Dialogs/Confirmation',
  component: ConfirmationDialog,
  tags: ['autodocs'],
  parameters: {
    layout: 'centered',
  },
}

export default meta
type Story = StoryObj<typeof ConfirmationDialog>

const ConfirmationDialogTemplate: Story = {
  render: (args: any) => {
    const {
      confirmationMessage,
      disableConfirm,
      dialogText,
      danger,
      title,
      onClose,
      onCancel,
      onConfirm,
      children,
    } = args
    const [show, setShow] = useState(false)

    const handleClose = () => {
      onClose()
      setShow(false)
    }

    const handleCancel = () => {
      onCancel()
      setShow(false)
    }

    return (
      <>
        <Button
          color={danger ? 'error' : 'primary'}
          text="Open Confirmation Dialog"
          onClick={() => setShow(true)}
        />
        {show && (
          <ConfirmationDialog
            dialogText={dialogText}
            title={title}
            open={show}
            danger={danger}
            onClose={handleClose}
            onCancel={handleCancel}
            onConfirm={onConfirm}
            disableConfirm={disableConfirm}
            confirmationMessage={confirmationMessage}
          >
            {children}
          </ConfirmationDialog>
        )}
      </>
    )
  },
}

export const Base: Story = {
  ...ConfirmationDialogTemplate,
  args: {
    title: 'Reset Password',
    dialogText: 'Are you sure you want to send a password reset email to [user name]?',
    onClose: () => {},
    onConfirm: () => {},
    onCancel: () => {},
    confirmationMessage: 'A reset email has been sent to [user name]',
    open: true,
  },
}

export const DangerConfirmation: Story = {
  ...ConfirmationDialogTemplate,
  args: {
    title: 'Delete Assignment',
    dialogText: 'Are you sure you want to delete this assignment? This action is permanent.',
    confirmationMessage: '[Assignment Name] deleted.',
    onClose: () => {},
    onConfirm: () => {},
    onCancel: () => {},
    danger: true,
    open: true,
    disableConfirm: false,
  },
}
