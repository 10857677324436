import * as React from 'react'
import { TextField, Radio, InputAdornment, Chip, ListSubheader } from '@mui/material'
import { Check, Close, GroupRounded, PersonRounded, SearchOutlined } from '@mui/icons-material'
import Autocomplete from '@mui/material/Autocomplete'
import makeStyles from '@mui/styles/makeStyles'
import { color } from '../../theme/color'
import { Text } from '../text'
import { useEffect, useMemo, useState } from 'react'
import { observer } from 'mobx-react-lite'
import { VariableSizeList, ListChildComponentProps } from 'react-window'
import { matchSorter } from 'match-sorter'
import { ISelectGroupOptions } from '@trivie/core'

export interface IGroupedProps {
  handleAddUserGroup: (val: ISelectGroupOptions[]) => void
  options: ISelectGroupOptions
  selectedOptions: ISelectGroupOptions
  total: number
  style?: React.CSSProperties
  listHeight?: number
}

const LISTBOX_PADDING = 8 // px

const OuterElementContext = React.createContext({})

const OuterElementType = React.forwardRef<HTMLDivElement>((props, ref) => {
  const outerProps = React.useContext(OuterElementContext)
  return <div ref={ref} {...props} {...outerProps} />
})

export const UserGroupSelector = observer((props: any) => {
  const {
    style,
    listHeight,
    defaultOptions,
    handleAddUserGroup,
    options,
    selectedOptions,
    open,
    total,
    popperPortalWidth,
    showAvatar = false,
  } = props

  const [focused, setFocused] = useState(open)

  const onFocus = () => setFocused(true)
  const onBlur = () => setFocused(false)

  const [pos, setPos] = useState(0)

  function useResetCache(data: any) {
    const ref = React.useRef<VariableSizeList>(null)
    React.useEffect(() => {
      if (ref.current != null) {
        ref.current.resetAfterIndex(0, false)
      }
    }, [data])
    return ref
  }

  useEffect(() => {
    const listElem = document.getElementById('list')
    listElem?.scrollTo(0, pos)
  }, [pos])

  // Adapter for react-window
  const ListboxComponent = useMemo(
    () =>
      React.forwardRef<HTMLDivElement, React.HTMLAttributes<HTMLElement>>(
        function ListboxComponent(props, ref) {
          const { children, ...other } = props as any
          const itemData: React.ReactChild[] = []

          const cUsers = { key: 0, group: 'Users', children: [] } as any,
            cGroups = { key: 1, group: 'Groups', children: [] } as any

          const groupsGroups = children?.filter((c) => c.group === 'Groups')
          const usersGroups = children?.filter((c) => c.group === 'Users')

          groupsGroups.map((c) => {
            cGroups.children = [...cGroups.children, ...c.children]
          })

          usersGroups.map((c) => {
            cUsers.children = [...cUsers.children, ...c.children]
          })

          let mergedChildren = [] as any
          if (cGroups?.children?.length > 0) {
            mergedChildren = [cGroups, ...cGroups.children]
          }
          if (cUsers?.children?.length > 0) {
            mergedChildren = [...mergedChildren, cUsers, ...cUsers.children]
          }
          ;(mergedChildren as React.ReactChild[]).forEach(
            (item: React.ReactChild & { mergedChildren?: React.ReactChild[] }) => {
              itemData.push(item)
              itemData.push(...(item.mergedChildren || []))
            },
          )

          const itemCount = itemData.length
          const itemSize = 48

          const getChildSize = (child: React.ReactChild) => {
            // eslint-disable-next-line
            if (child.hasOwnProperty('group')) {
              return 48
            }
            return itemSize
          }

          const getHeight = () => {
            if (itemCount > 8) {
              return 8 * itemSize
            }
            return itemData.map(getChildSize).reduce((a, b) => a + b, 0)
          }

          const gridRef = useResetCache(itemCount)

          return (
            <div ref={ref}>
              <OuterElementContext.Provider value={other}>
                <VariableSizeList
                  itemData={itemData}
                  height={getHeight() + 2 * LISTBOX_PADDING}
                  width="100%"
                  ref={gridRef}
                  outerElementType={OuterElementType}
                  innerElementType="ul"
                  itemSize={(index) => getChildSize(itemData[index])}
                  overscanCount={5}
                  itemCount={itemCount}
                >
                  {renderRow}
                </VariableSizeList>
              </OuterElementContext.Provider>
            </div>
          )
        },
      ),
    [selectedOptions],
  )

  const useStyles = makeStyles({
    // Dropdown container
    paper: {
      borderRadius: 0,
      boxShadow: 'none',
      border: '1px solid lightgray',
      borderBottomLeftRadius: 20,
      borderBottomRightRadius: 20,
      top: -1,
      margin: 0,
      position: open ? 'relative' : 'absolute',
      width: '100%',
    },
    listbox: {
      maxHeight: listHeight,
    },
    root: {
      '& .MuiAutocomplete-tag': {
        margin: '0 3px',
      },
    },
    popperDisablePortal: {
      position: 'relative',
      width: 'calc(100% - 48px) !important',
    },
    groupLabel: {
      fontWeight: 600,
      textTransform: 'uppercase',
    },
    option: {
      // paddingLeft: '16px !important',
      height: 48,
    },
    input: {
      '&::placeholder': {
        color: color.palette.darkGrey,
        fontStyle: 'normal',
        fontWeight: 'normal',
        fontSize: 14,
      },
      '& .MuiInputBase-root': {
        maxHeight: 120,
        minHeight: 60,
        overflow: 'auto',
        border: '1px solid lightgray',
        borderBottomLeftRadius: open || focused ? 0 : 20,
        borderBottomRightRadius: open || focused ? 0 : 20,
        borderTopLeftRadius: 20,
        borderTopRightRadius: 20,
      },
      '& .MuiInputBase-root::focused': {
        borderBottomLeftRadius: 0,
        borderBottomRightRadius: 0,
      },
      '& .MuiInputBase-root > fieldset': {
        border: 'none',
      },
    },
  })

  const classes = useStyles()

  const renderTags = (value: any, getTagProps: any) => {
    return value.map((option: any, index: number) => {
      return (
        <Chip
          deleteIcon={<Close />}
          style={CHIP}
          avatar={
            option.type === 'Groups' ? (
              <div style={GROUP_ICON_MINI}>
                <GroupRounded style={{ fontSize: 12 }} htmlColor={color.shade70} />
              </div>
            ) : (
              <div style={GROUP_ICON_MINI}>
                <PersonRounded style={{ fontSize: 12 }} htmlColor={color.shade70} />
              </div>
            )
          }
          variant="outlined"
          label={option.label}
          {...getTagProps({ index })}
        />
      )
    })
  }

  const change = (e, selected, opt) => {
    if (selected && e.target.checked) {
      const sel = [] as any
      selectedOptions.map((o) => {
        if (o.id !== opt.id) {
          sel.push(o)
        }
      })
      handleAddUserGroup(sel)
    } else {
      handleAddUserGroup([...selectedOptions, opt])
    }
  }

  const renderRow = (props: ListChildComponentProps) => {
    const { data, index, style } = props
    const dataSet = data[index]
    const inlineStyle = {
      ...style,
      top: (style.top as number) + LISTBOX_PADDING,
    }

    // eslint-disable-next-line
    if (dataSet.hasOwnProperty('group')) {
      return (
        <ListSubheader
          key={dataSet.key}
          component="div"
          style={{ ...inlineStyle, fontWeight: 600, textTransform: 'uppercase' }}
        >
          {dataSet.group}
        </ListSubheader>
      )
    }
    const opt = dataSet[1]
    const { selected } = dataSet[2]
    const sel = selectedOptions.filter((o) => o.type === opt.type && o.id === opt.id).length > 0

    return (
      <li style={{ display: 'flex', alignItems: 'center', ...inlineStyle, paddingLeft: 8 }}>
        <Radio
          onClick={(e) => {
            const listElem = document.getElementById('list')
            setPos(listElem?.scrollTop ?? 0)
            change(e, selected, opt)
          }}
          checked={sel}
          checkedIcon={
            <div style={CHECK_ICON}>
              <Check htmlColor={color.white} style={{ fontSize: 16 }} />
            </div>
          }
          color="default"
          inputProps={{ 'aria-label': 'choice' }}
          style={{
            placeSelf: 'center',
            marginRight: 8,
            color: selected ? color.blue : color.shade30,
          }}
        />
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
          }}
        >
          <Text variant="body1Medium" text={opt.label} />
          {opt.subLabel ? (
            <Text style={{ color: color.shade30 }} variant="body2" text={opt.subLabel} />
          ) : null}
          {opt.type == 'Groups' && (
            <Text
              variant="body2Medium"
              text={`${opt.number_users} User${opt.number_users === 1 ? '' : 's'}`}
              style={{ color: color.shade30 }}
            />
          )}
        </div>
      </li>
    )
  }

  React.useEffect(() => {
    if (defaultOptions?.length > 0) {
      handleAddUserGroup(defaultOptions)
    }
    const listElem = document.getElementById('list')
    listElem?.scrollTo(0, pos)
  }, [defaultOptions])

  const filterOptions = (o, { inputValue }) => {
    return matchSorter(o, inputValue, { keys: ['*.label', '*.subLabel'] })
  }

  return (
    <div style={style}>
      <Autocomplete
        filterOptions={filterOptions}
        isOptionEqualToValue={(o: any, value: any) =>
          selectedOptions.find((op: any) => value.type === o.type && value.id === o.id)
        }
        fullWidth
        disablePortal
        onChange={(event: any, value: any) => handleAddUserGroup(value)}
        ListboxProps={{ id: 'list', role: 'list-box' }}
        ListboxComponent={ListboxComponent}
        disableListWrap
        open={open}
        defaultValue={selectedOptions}
        value={selectedOptions}
        disableCloseOnSelect
        multiple
        id="user-groups-autocomplete"
        classes={{ ...classes }}
        forcePopupIcon={false}
        options={options.sort(
          (a: { type: any }, b: { type: string }) => -b.type.localeCompare(a.type),
        )}
        renderOption={(props, option, state) => [props, option, state] as React.ReactNode}
        groupBy={(option) => option.type}
        renderGroup={(params) => params as unknown as React.ReactNode}
        getOptionLabel={(option) => option.label}
        renderTags={renderTags}
        renderInput={(params) => {
          params.InputProps.startAdornment = (
            <>
              <InputAdornment position="start">
                <SearchOutlined htmlColor={color.shade30} />
              </InputAdornment>
              {params.InputProps.startAdornment}
            </>
          )
          params.InputProps.endAdornment = null
          return (
            <TextField
              className={classes.input}
              placeholder="User or Group"
              {...params}
              variant="outlined"
              type="text"
              onFocus={onFocus}
              onBlur={onBlur}
            />
          )
        }}
      />
    </div>
  )
})

const GROUP_ICON_MINI: React.CSSProperties = {
  height: 21,
  width: 21,
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  borderRadius: 6,
  alignItems: 'center',
}
const GROUP_ICON_LARGE: React.CSSProperties = {
  ...GROUP_ICON_MINI,
  backgroundColor: color.shade10,
  height: 42,
  width: 42,
  marginRight: 12,
}
const CHIP: React.CSSProperties = {
  backgroundColor: '#EFF0F6',
  marginTop: 4,
  marginBottom: 4,
  border: 'none',
  borderRadius: 6,
}
const CHECK_ICON: React.CSSProperties = {
  backgroundColor: color.blue,
  height: 20,
  width: 20,
  marginLeft: 2,
  position: 'relative',
  borderRadius: 100,
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  alignItems: 'center',
  marginRight: 2,
}
const USER_COUNT: React.CSSProperties = {
  position: 'relative',
  right: 0,
  top: 4,
  color: color.shade50,
  fontSize: 12,
  fontWeight: '400',
}
