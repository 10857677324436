import React, { useState } from 'react'
import { observer } from 'mobx-react-lite'
import { useRouteMatch } from 'react-router-dom'

import { TableHelper } from '../../../../tables'
import { GroupTable } from '../../../../tables/group-table'

const Groups = observer(() => {
  const match = useRouteMatch<any>('/content/topics/:id/groups')
  const topicID = Number(match?.params?.id)

  const [tableHelper] = useState(new TableHelper('list_topic_groups', 'groups', 'topics', topicID))

  return <GroupTable tableHelper={tableHelper} />
})

export const TopicsGroupsTab = (props: any) => {
  const { id } = props
  return {
    name: 'Groups',
    path: `/content/topics/${id}/groups`,
    component: () => <Groups />,
  }
}
