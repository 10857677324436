import React, { useEffect, useState } from 'react'
import { Download, Edit, FactCheck, Reply, Settings } from '@mui/icons-material'
import { CircularProgress } from '@mui/material'
import { DateTime } from 'luxon'
import { observer } from 'mobx-react-lite'
import { useHistory, useLocation, useRouteMatch } from 'react-router-dom'

import { useStores } from '@trivie/core'
import { QuizApi } from '@trivie/core/src/services/api-objects'
import { HeaderPanel } from '@trivie/ui-web/components/header-panel'
import { Text } from '@trivie/ui-web/components/text'
import { color } from '@trivie/ui-web/theme'
import { ConfirmDialog } from '@trivie/ui-web/components/confirm-dialog'
import { NavigationTabs } from '@trivie/ui-web/components/navigation-tabs'

import defaultCover from './quiz-card/default-topic-image.png'
import { QuizSettingsDialog } from './quiz-settings-dialog'
import { ShareQuizDialog } from './share-quiz-dialog'
import { quizzesTabs } from './tabs'
import { QuizOverview } from './tabs/quizzes-overview-tab'
import { AssignmentCreateDialog } from '../../activities/assignments/create-assignment/assignment-create'

function useQuery() {
  const { search } = useLocation()

  return React.useMemo(() => new URLSearchParams(search), [search])
}
export const QuizzesDetailsScreen: React.FunctionComponent = observer(() => {
  const { manageQuizStore, manageUserStore, contentCreationStore } = useStores()
  const { freeTier, enterpriseTier, permissions } = manageUserStore
  const { exportQuizzes, selectedQuiz, setSelectedQuiz } = manageQuizStore
  const { resetContentStore } = contentCreationStore

  const match = useRouteMatch<any>('/content/quizzes/:id/:tab')
  const quizID = Number(match?.params?.id)

  const [showQuizSettings, setShowQuizSettings] = useState(false)
  const [showShareQuiz, setShowShareQuiz] = useState(false)
  const [shareLink, setShareLink] = useState('')
  const [status, setStatus] = useState<any>()
  const [confirmButtonText, setConfirmButtonText] = useState('')
  const [confirmDialogContent, setConfirmDialogContent] = useState<any>()
  const [showAssignWizard, setShowAssignWizard] = useState(false)
  const [loading, setLoading] = useState(true)

  const history = useHistory()

  const query = useQuery()
  const openShare = query.get('share') === 'true'

  useEffect(() => {
    if (!enterpriseTier) {
      QuizApi.getShareQuizLink(quizID).then((resp) => {
        setShareLink(resp.data.sharing_url)
        setStatus({
          label: resp.data.quiz_status === 'live' ? 'Live' : 'Draft',
          color: resp.data.quiz_status === 'live' ? color.green : color.yellow,
        })
      })
    }
    loadQuiz()
  }, [])

  const loadQuiz = async () => {
    setLoading(true)
    const quizResponse = await QuizApi.getAllQuizzes(
      'quizzes',
      false,
      [
        { field: 'quiz_title' },
        { field: 'quiz_question_count' },
        { field: 'quiz_status' },
        { field: 'quiz_last_edited_date' },
        { field: 'quiz_pct_correct' },
        { field: 'quiz_attempts_completed' },
        { field: 'quiz_unique_respondents' },
        { field: 'quiz_avg_completion_time' },
      ],
      'list_quizzes',
    )
    const item = quizResponse.data.rows.find((q) => q.id === Number(quizID))

    const lastUpdated = DateTime.fromISO(
      item.columns.find((column) => column.field === 'quiz_last_edited_date').value ?? '',
    ).toLocaleString(DateTime.DATE_MED)

    const quiz_status = item.columns.find((column) => column.field === 'quiz_status').value

    setSelectedQuiz({
      title: item.columns.find((column) => column.field === 'quiz_title').value,
      photo_url: item.columns.find((column) => column.field === 'photo_url').value,
      questionCount: item.columns.find((column) => column.field === 'quiz_question_count').value,
      status: quiz_status,
      lastUpdated,
      pctCorrect: item.columns.find((column) => column.field === 'quiz_pct_correct').value,
      averageTime: Math.round(
        item.columns.find((column) => column.field === 'quiz_avg_completion_time').value / 1000,
      ),
      attempts: Math.round(
        item.columns.find((column) => column.field === 'quiz_attempts_completed').value,
      ),
      uniqueRespondents: item.columns.find((column) => column.field === 'quiz_unique_respondents')
        .value,
    })
    setLoading(false)
  }

  const exportQuiz = async (quiz) => {
    const { file_url } = (await exportQuizzes(quiz)) as any
    window.open(file_url)
  }

  const headerActions = [
    {
      label: 'Export',
      onClick: () => exportQuiz([Number(quizID)]),
      icon: <Download htmlColor={color.shade70} />,
      disabled: !permissions?.permissions.some((p) => p.code === 'can_create_quiz'),
    },
    {
      label: enterpriseTier ? 'Assign' : 'Share',
      disabled: !permissions?.permissions.some((p) => p.code === 'can_create_assignment'),
      onClick: () => {
        if (enterpriseTier) {
          setShowAssignWizard(true)
        } else {
          setShowShareQuiz(true)
        }
      },
      icon: enterpriseTier ? (
        <FactCheck htmlColor={color.shade70} />
      ) : (
        <Reply htmlColor={color.shade70} />
      ),
    },
    {
      label: 'Settings',
      hide: enterpriseTier,
      onClick: () => setShowQuizSettings(true),
      icon: <Settings htmlColor={color.shade70} />,
    },
    {
      primary: true,
      disabled: !permissions?.permissions.some((p) => p.code === 'can_edit_quiz'),
      label: 'Edit',
      onClick: () => {
        resetContentStore()
        history.push(`/content/creation/quiz/${Number(quizID)}/edit`)
      },
      icon: <Edit htmlColor={color.white} />,
    },
  ]

  const share = (emails, ids) => {
    setConfirmButtonText('')
    setConfirmDialogContent(
      <div style={{ display: 'flex', justifyContent: 'center', flexDirection: 'column' }}>
        <Text
          variant="body1Medium"
          text="Preparing your quiz for sharing..."
          style={{ marginTop: 32, marginBottom: 50, textAlign: 'center' }}
        />
        <CircularProgress style={{ alignSelf: 'center' }} />
      </div>,
    )
    QuizApi.shareQuiz(quizID, emails, ids).then((resp) => {
      setConfirmButtonText('Close')
      setConfirmDialogContent(
        <Text
          variant="body1Medium"
          text={`${selectedQuiz?.title} has been shared with ${emails.length + ids.length} user${
            emails.length !== 1 ? 's' : ''
          }`}
          style={{ marginTop: 32, marginBottom: 50, textAlign: 'center' }}
        />,
      )
    })
  }

  const toggle = (quizStatus) => {
    QuizApi.toggleQuizStatus(quizID, quizStatus).then(() => {
      QuizApi.getShareQuizLink(quizID).then((resp) => {
        setShareLink(resp.data.sharing_url)
        setStatus({
          label: resp.data.quiz_status === 'live' ? 'Live' : 'Draft',
          color: resp.data.quiz_status === 'live' ? color.green : color.yellow,
        })
      })
      loadQuiz()
    })
  }

  useEffect(() => {
    if (openShare) {
      setShowShareQuiz(true)
    }
  }, [openShare])

  return (
    <>
      <HeaderPanel
        backdrop={selectedQuiz?.photo_url ? selectedQuiz?.photo_url : defaultCover}
        showOverlay
        status={enterpriseTier ? null : status}
        loading={loading}
        actions={selectedQuiz ? headerActions : []}
        footer={
          selectedQuiz && (
            <div style={{ flexDirection: 'column', display: 'flex' }}>
              <Text style={{ color: color.white }} variant="headline" text={selectedQuiz.title} />
              <Text
                style={{ color: color.white }}
                variant="body1Medium"
                text={`${selectedQuiz.questionCount} Question${
                  selectedQuiz.questionCount === 1 ? '' : 's'
                } ∙ Last Updated ${selectedQuiz.lastUpdated}`}
              />
            </div>
          )
        }
      />
      {enterpriseTier ? <NavigationTabs tabList={quizzesTabs} id={quizID} /> : <QuizOverview />}
      {showQuizSettings && (
        <QuizSettingsDialog
          freeTier={freeTier}
          open={showQuizSettings}
          value={status?.label}
          onClose={() => setShowQuizSettings(false)}
          onSubmit={() => {}}
          onChange={(s) => toggle(s.toLowerCase())}
        />
      )}
      {showShareQuiz && (
        <ShareQuizDialog
          freeTier={freeTier}
          shareLink={shareLink}
          toggle={() => toggle('live')}
          share={share}
          status={status?.label}
          open={showShareQuiz}
          onClose={() => setShowShareQuiz(false)}
        />
      )}
      {showAssignWizard && (
        <AssignmentCreateDialog
          open={showAssignWizard}
          initialQuizzes={[Number(quizID)]}
          requestClose={() => setShowAssignWizard(false)}
        />
      )}
      {confirmDialogContent && (
        <ConfirmDialog
          hideCancel
          open={!!confirmDialogContent}
          title="Share Quiz"
          message={''}
          confirmButton={confirmButtonText}
          bodyContent={confirmDialogContent}
          onConfirm={() => setConfirmDialogContent(undefined)}
          onClose={() => setConfirmDialogContent(undefined)}
        />
      )}
    </>
  )
})
