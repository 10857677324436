import React, { useEffect, useState } from 'react'
import { observer } from 'mobx-react-lite'

import { useStores } from '@trivie/core'

import { TableHelper } from '../../../../tables'

import { AssignmentTable } from '../../../../tables/assignment-table'

const LiveAssignments: React.FunctionComponent = observer(() => {
  const { assignmentStore } = useStores()
  const { setTriggerRefresh, triggerRefresh } = assignmentStore

  const [tableHelper] = useState(
    new TableHelper('list_active_assignments', 'assignments', 'assignments', undefined, 'active'),
  )

  useEffect(() => {
    if (triggerRefresh) {
      tableHelper.load().then(() => setTriggerRefresh(false))
    }
  }, [triggerRefresh])

  return <AssignmentTable forceLoading={triggerRefresh} filter="active" tableHelper={tableHelper} />
})

export const LiveAssignmentsTab = () => {
  return {
    name: 'Live',
    path: `/activities/assignments/live`,
    component: () => <LiveAssignments />,
  }
}
