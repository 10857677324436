import { types } from 'mobx-state-tree'

export const ActiveUser = types.model('UserProfile').props({
  user_id: types.number,
  display_name: types.string,
  phone_number: types.maybeNull(types.string),
  email: types.maybeNull(types.string),
  photo_url: types.string,
  mastery: types.maybeNull(types.number),
  total_answers: types.maybeNull(types.number),
  pct_correct: types.maybeNull(types.number),
  total_points: types.maybeNull(types.number),
  rank: types.maybeNull(types.number),
  tag_count: types.maybeNull(types.number),
  last_active: types.maybeNull(types.string),
})
