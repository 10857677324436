import React, { useState } from 'react'
import { observer } from 'mobx-react-lite'

import { TableHelper } from '../../../../tables'
import { QuizTable } from '../../../../tables/quiz-table'
import { useRouteMatch } from 'react-router-dom'

const Quizzes = observer(() => {
  const match = useRouteMatch<any>('/content/topics/:id/quizzes')
  const topicID = Number(match?.params?.id)

  const [tableHelper] = useState(
    new TableHelper('list_topic_quizzes', 'quizzes', 'topics', topicID),
  )

  return <QuizTable tableHelper={tableHelper} />
})

export const TopicsQuizzesTab = (props: any) => {
  const { id } = props
  return {
    name: 'Quizzes',
    path: `/content/topics/${id}/quizzes`,
    component: () => <Quizzes />,
  }
}
