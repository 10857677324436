import React, { useEffect, useState } from 'react'
import { NodeViewContent, NodeViewWrapper } from '@tiptap/react'
import { observer } from 'mobx-react-lite'

import { matchCitations } from '@trivie/core/src/utils/matchCitations'

import { Citation } from '../../citation'

export const ListItemNode = observer((props: any) => {
  const { quiz, selectQuestion } = props.extension.options

  const [citations, setCitations] = useState<any>([])

  useEffect(() => {
    setCitations(
      matchCitations(
        props.node.attrs.generated_question_ids,
        quiz?.questions.filter((q) => !q.removing),
      ),
    )
  }, [props.node.attrs?.generated_question_ids, quiz?.questions.filter((q) => !q.removing)?.length])

  const onCitationClick = (citation) =>
    selectQuestion(quiz.questions.find((q) => q.generated_question_id === citation.gqid))

  return (
    <NodeViewWrapper>
      <NodeViewContent {...props.node.attrs} className="list-item-content" />
      {citations?.map((citation) => (
        <Citation onClick={() => onCitationClick(citation)} citation={citation} />
      ))}
    </NodeViewWrapper>
  )
})
