import React, { useEffect, useState } from 'react'
import { Alert, CssBaseline, Slide } from '@mui/material'
import { ThemeProvider, StyledEngineProvider } from '@mui/material/styles'
import { getEnv } from 'mobx-state-tree'
import { observer } from 'mobx-react-lite'
import { PostHogProvider } from 'posthog-js/react'

import { MaintenanceDialog } from '@trivie/ui-web/components/maintenance-dialog'
import { BrowserRouter as Router, Route, Switch, useLocation } from 'react-router-dom'
import { useStatusCodeMiddleware } from './middleware'
import { muiTheme } from '@trivie/ui-web/theme'
import { PrivateRoutes } from './routing/private-routes'
import ScrollToTop from './routing/scrollToTop'
import { LoginScreen } from './screens/login'
import { LinkSentScreen } from './screens/login/link-sent'
import { WorkspaceScreen } from './screens/login/workspaces/workspaces'
import * as Sentry from '@sentry/react'
import { SSOEntryScreen } from './screens/login/sso'
import { MiddlewareDialog } from '@trivie/ui-web/components/middleware-dialog/middleware-dialog'
import { MagicLink } from './components/magic-link'
import { Logout } from './screens/logout/logout'
import { RootStoreType } from '@trivie/core/src/models/root-store/root-store'
import { setupRootStore } from '@trivie/core/src/models/setup-root-store'
import { RootStoreProvider, useStores } from '@trivie/core/src/models/root-store/root-store-context'
import { Toast } from '@trivie/ui-web/components/toast'
import { ConfirmProvider } from '@trivie/ui-web/components/dialogs/confirmation-dialog'

import { LicenseInfo } from '@mui/x-license'
import { OnboardingScreen } from './screens/onboarding/onboarding-screen'
import { setupHelpers } from '@trivie/core/src/utils/setupHelpers'
LicenseInfo.setLicenseKey(process.env.REACT_APP_MUI_KEY!)

const packageJson = require('../package.json')

const { version } = packageJson

const options = {
  api_host: process.env.REACT_APP_PUBLIC_POSTHOG_HOST,
}

if (process.env.REACT_APP_ENV !== 'local' && process.env.REACT_APP_ENV !== 'dev') {
  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_DSN,
    integrations: [new Sentry.BrowserTracing()],
    environment: process.env.REACT_APP_ENV,
    release: version,
    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0,
  })
}

window.addEventListener(
  'dragover',
  function (e) {
    e.preventDefault()
  },
  false,
)

window.addEventListener(
  'drop',
  function (e) {
    e.preventDefault()
  },
  false,
)

const ROOT = { display: 'flex', height: '100vh', overflow: 'hidden' } as React.CSSProperties

function useQuery() {
  const { search } = useLocation()

  return React.useMemo(() => new URLSearchParams(search), [search])
}

export const App: React.FunctionComponent = () => {
  const [rootStore, setRootStore] = useState<RootStoreType | undefined>(undefined)

  const [alert, setAlert] = useState(false)
  const [upgrade, setUpgrade] = useState(false)
  const [alertStatus, setAlertStatus] = useState('')
  const [alertMessage, setAlertMessage] = useState('')
  const [forceLogout, setForceLogout] = useState(false)
  const [showMaintenance, setShowMaintenance] = useState(false)

  const showAlert = (
    status: string,
    message: string,
    reset: boolean,
    upgradeRequired?: boolean,
  ) => {
    setAlertStatus(status)
    setAlertMessage(message)
    setForceLogout(reset)
    if (!alert) {
      setAlert(true)
    }

    if (upgradeRequired) {
      setUpgrade(true)
    }
  }

  // Setup root store
  useEffect(() => {
    ;(async () => {
      const config = {
        persist: true,
        storageKey: 'trivie-insights',
        version,
        dev_mode: true,
        timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
        insights: true,
      }
      console.log(`Trivie Insights v${version}`)
      const root = await setupRootStore(
        config,
        (show: boolean) => setShowMaintenance(show),
        showAlert,
      )
      setRootStore(root)
      setupHelpers(root)
    })()
  }, [])

  useEffect(() => {
    if (rootStore && localStorage.getItem('toast') === 'true') {
      rootStore?.toastStore?.show({
        message: 'You have been signed into another workspace',
        severity: 'error',
        icon: false,
        position: 'top',
        autoDismiss: 10000,
      })
      localStorage.removeItem('toast')
    }
  }, [rootStore])

  const onFocus = () => {
    if (rootStore) {
      const { api } = getEnv(rootStore)
      if (api.apisauce.headers['x-access-code'] !== localStorage.getItem('access_code')) {
        localStorage.setItem('toast', 'true')
        window.location.reload()
      }
    }
  }

  useEffect(() => {
    window.removeEventListener('focus', onFocus)
    window.addEventListener('focus', onFocus)
    return () => window.removeEventListener('focus', onFocus)
  }, [rootStore?.manageUserStore?.userProfile?.id])

  // Wait for rootStore to initilaize
  if (!rootStore) {
    return null
  }

  const closeAlert = () => {
    setAlert(false)
    if (upgrade) {
      setUpgrade(false)
    }
  }

  useStatusCodeMiddleware(rootStore, showAlert)

  return (
    <RootStoreProvider value={rootStore}>
      <PostHogProvider apiKey={process.env.REACT_APP_PUBLIC_POSTHOG_KEY} options={options}>
        <Router>
          <ScrollToTop>
            <StyledEngineProvider injectFirst>
              <ThemeProvider theme={muiTheme}>
                <ConfirmProvider defaultOptions={{}}>
                  <CssBaseline enableColorScheme />
                  <div style={{ flexDirection: 'column', display: 'flex' }}>
                    <Toast />
                    <div style={ROOT}>
                      <Switch>
                        <Route exact path="/login" render={() => <LoginScreen />} />
                        <Route
                          exact
                          path="/login/sso/:accessCode?"
                          render={() => <SSOEntryScreen />}
                        />
                        <Route exact path="/signup" render={() => <OnboardingScreen />} />
                        <Route exact path="/generate" render={() => <OnboardingScreen />} />
                        <Route path="/token/:token/email/:email" render={() => <MagicLink />} />
                        <Route exact path="/magic" render={() => <LinkSentScreen />} />
                        <Route exact path="/workspaces" render={() => <WorkspaceScreen />} />
                        <Route exact path="/logout" render={() => <Logout />} />
                        <PrivateRoutes />
                      </Switch>
                    </div>
                  </div>
                  <QuantumBranding />
                  <MiddlewareDialog
                    title={alertStatus}
                    message={alertMessage}
                    isOpen={alert}
                    forceLogout={forceLogout}
                    onClose={closeAlert}
                    upgrade={upgrade}
                  />
                  {showMaintenance && (
                    <MaintenanceDialog
                      onClose={() => setShowMaintenance(false)}
                      visible={showMaintenance}
                    />
                  )}
                </ConfirmProvider>
              </ThemeProvider>
            </StyledEngineProvider>
          </ScrollToTop>
        </Router>
      </PostHogProvider>
    </RootStoreProvider>
  )
}

const QuantumBranding = observer(() => {
  const { uiStore } = useStores() as any
  const query = useQuery()

  const quantum = query.get('q5') === 'true'
  const { setQuantum } = uiStore

  if (quantum) {
    setQuantum(quantum)
  }
  return null
})
