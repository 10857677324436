/**
 * We apply the .toFixed() method on the number and pass the number of digits after the decimal as the argument.
 * This method does not yield accurate results in some cases, and there are better methods than this. If a number
 * rounds of 1.2, then it will show 1.20. If a number like 2.005 is given, it will return 2.000 instead of 2.01.
 *
 * We take the number and add a very small number, Number.EPSILON, to ensure the number’s accurate rounding.
 * We then multiply by number with 100 before rounding to extract only the two digits after the decimal place.
 * Finally, we divide the number by 100 to get a max of 2 decimal places.
 *
 * @param value
 * @param toFixed
 * @returns
 */
export function roundNumber(value: number, toFixed = 0) {
  const multiplier = 10 ** toFixed
  return Math.round(((value ?? 0) + Number.EPSILON) * multiplier) / multiplier
}
