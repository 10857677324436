import { Instance } from 'mobx-state-tree'
import { RawHourlyActivity } from '../models/RawHourlyActivity'
import { DateTime } from 'luxon'

export function transformHourlyActivity(hourlyActivity: Array<Instance<typeof RawHourlyActivity>>) {
  // [day][hour]
  const data: Array<Array<{ value: number; scaledValue: number }>> = [
    Array(8).fill({ value: 0, scaledValue: 0 }),
    Array(8).fill({ value: 0, scaledValue: 0 }),
    Array(8).fill({ value: 0, scaledValue: 0 }),
    Array(8).fill({ value: 0, scaledValue: 0 }),
    Array(8).fill({ value: 0, scaledValue: 0 }),
    Array(8).fill({ value: 0, scaledValue: 0 }),
    Array(8).fill({ value: 0, scaledValue: 0 }),
  ]

  const maxSecondsSpent = hourlyActivity.reduce((acc, curr) => {
    if (curr.seconds_spent > acc) {
      return curr.seconds_spent
    } else {
      return acc
    }
  }, 0)

  const offset = DateTime.local().offset

  hourlyActivity.forEach((a) => {
    // 28 is chosen to match the design. We add 12 at the end so that the range is 12 to 40 pixels
    const scaledSecondsSpent = Math.round((a.seconds_spent / maxSecondsSpent) * 28) + 12
    const shiftedDateTime = DateTime.local()
      .set({ weekday: a.day_of_week, hour: a.hour })
      .plus({ minutes: offset })

    let firstIndex = shiftedDateTime.weekday - 1

    let secondIndex = Math.round(shiftedDateTime.hour / 3)

    if (secondIndex > 7) {
      firstIndex = firstIndex + 1
      secondIndex = 0
    }

    // edge case. The final hour should be added to the following day, but
    // because we don't have a following day for the last hour we just ignore
    if (firstIndex < 7) {
      let value = a.seconds_spent / 60
      value = value > 0 && value < 1 ? 1 : Math.round(value)
      data[firstIndex][secondIndex] = {
        value,
        scaledValue: scaledSecondsSpent,
      }
    }
  })

  return data.flat()
}
