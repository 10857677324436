import React from 'react'
import { observer } from 'mobx-react-lite'
import { color } from '@trivie/ui-web/theme'
import { Text } from '@trivie/ui-web/components/text'

import { ReactComponent as SuccessIcon } from '@trivie/ui-web/assets/icons/SuccessCheck.svg'

export const ValidationSuccessful = observer(() => {
  return (
    <div
      style={{
        backgroundColor: '#D8F4EB',
        borderRadius: 8,
        padding: 6,
        marginLeft: 50,
        marginRight: 6,
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
      }}
    >
      <SuccessIcon />
      <Text
        style={{ color: color.lightGreen, marginLeft: 4 }}
        variant="body2"
        text="Successful! You are ready to publish!"
      />
    </div>
  )
})
