import React from 'react'
import { Instance } from 'mobx-state-tree'
import { FormControl, InputBase, MenuItem, Select, styled } from '@mui/material'
import { observer } from 'mobx-react-lite'
import { Check } from '@mui/icons-material'

import { ContestCreateForm } from '@trivie/core/src/models/ContestCreateForm'
import { Text } from '@trivie/ui-web/components/text'
import { color } from '@trivie/ui-web/theme'
import { AutocompleteGroupSelector } from '@trivie/ui-web/components/autocomplete-selector'

const TrivieDropdown = styled(InputBase)(({ theme }) => ({
  '& .MuiInputBase-input': {
    width: '100%',
    borderRadius: 6,
    position: 'relative',
    backgroundColor: color.white,
    border: '1px solid #ced4da',
    fontSize: 16,
    padding: '10px 26px 10px 12px',
  },
  '& .MuiPopover-root': {
    height: 400,
  },
}))

interface ContestContentProps {
  form: Instance<typeof ContestCreateForm>
  selectedAutocompleteOptions: any
  handleAddItem: any
}
export const ContestContent = observer((props: ContestContentProps) => {
  const { form, selectedAutocompleteOptions, handleAddItem } = props

  const handleChangeContent = (e: { target: { value: string } }) =>
    form.set('content_method', e.target.value)

  const opts = form.content.slice()

  return (
    <div>
      <Text text="Contest Content" variant="body1Medium" />
      <FormControl style={{ marginBottom: 12, marginTop: 12, width: '100%' }} variant="standard">
        <Select
          MenuProps={{ PaperProps: { style: { width: 250, borderRadius: 6, paddingTop: 0 } } }}
          value={form.content_method}
          onChange={handleChangeContent}
          input={<TrivieDropdown />}
          renderValue={(val) => (val === 'standard' ? 'All Content' : 'Selected Content')}
        >
          <MenuItem value="standard">
            <div style={MENU_ITEM_CONTAINER}>
              <div style={{ flexDirection: 'column', justifyContent: 'center' }}>
                <Text text="All Content" variant="body1Medium" style={{ marginBottom: 8 }} />
                <Text
                  text="Points earned on any topics or quizzes will count towards this contest"
                  variant="body2"
                />
              </div>
              {form.content_method === 'standard' && (
                <Check htmlColor={color.blue} style={{ fontSize: 20 }} />
              )}
            </div>
          </MenuItem>
          <MenuItem value="custom">
            <div style={MENU_ITEM_CONTAINER}>
              <div style={{ flexDirection: 'column', justifyContent: 'center' }}>
                <Text text="Selected Content" variant="body1Medium" style={{ marginBottom: 8 }} />
                <Text
                  text="Only points earned on selected content will count towards the contest"
                  variant="body2"
                />
              </div>
              {form.content_method === 'custom' && (
                <Check htmlColor={color.blue} style={{ fontSize: 20 }} />
              )}
            </div>
          </MenuItem>
        </Select>
      </FormControl>
      {form.content_method === 'custom' && (
        <>
          <Text
            text="Only points earned on selected content will count towards the contest. This applies to questions, posts, and replies."
            variant="body2"
            style={{ marginBottom: 8, color: color.shade70 }}
          />
          <Text
            text="Note: The contest will not assign these topics and quizzes to these users."
            variant="body2"
            style={{ marginBottom: 20, color: color.red }}
          />
          <AutocompleteGroupSelector
            open
            resize
            noOptionsText="No content."
            options={opts}
            defaultOptions={[]}
            listHeight={372}
            selectedOptions={selectedAutocompleteOptions}
            handleAddUserGroup={handleAddItem}
          />
        </>
      )}
    </div>
  )
})

const MENU_ITEM_CONTAINER: React.CSSProperties = {
  flex: 1,
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'center',
}
