import { types } from 'mobx-state-tree'

export const SubscriptionPack = types.model('SubscriptionPack').props({
  color: types.string,
  contentpack_id: types.number,
  is_subscribed: types.boolean,
  sku: types.string,
  title: types.string,
  topic_count: types.number,
  vendor: types.string,
})
