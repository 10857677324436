import { types } from 'mobx-state-tree'

export const GetResource = types.model('GetResource').props({
  resource_id: types.number,
  title: types.string,
  converted_pdf_url: types.maybeNull(types.string),
  thumbnail_url: types.maybeNull(types.string),
  filename_or_url: types.string,
  quiz_count: types.maybeNull(types.number),
  created_at: types.string,
  file_size: types.maybeNull(types.number),
  mime_type: types.maybeNull(types.string),
  extension: types.maybeNull(types.string),
})

const ResourceBase = types.model('ResourceBase').props({
  alt_text: types.maybeNull(types.string),
  description: types.maybeNull(types.string),
  extension: types.maybeNull(types.string),
  is_locked: types.boolean,
  title: types.string,
  url: types.maybeNull(types.string),
})

const ObjectWithResource = types.union(
  types.literal('post'),
  types.literal('question'),
  types.literal('quiz'),
  types.literal('quiz_resource'),
)

export const GetResourceRead = ResourceBase.named('GetTopicResource').props({
  id: types.number,
  file_url: types.maybeNull(types.string),
  is_archived: types.maybeNull(types.boolean),
  is_deleted: types.maybeNull(types.boolean),
  original_filename: types.maybeNull(types.string),
  object_id: types.maybeNull(types.number),
  object_type: types.maybeNull(ObjectWithResource),
  converted_pdf_url: types.maybeNull(types.string),
  thumbnail_url: types.maybeNull(types.string),
  file_size: types.maybeNull(types.number),
  mime_type: types.maybeNull(types.string),
})

const ResourceRead = ResourceBase.named('ResourceRead').props({
  id: types.number,
  file_url: types.maybeNull(types.string),
  is_archived: types.maybeNull(types.boolean),
  is_deleted: types.maybeNull(types.boolean),
  original_filename: types.maybeNull(types.string),
  object_id: types.maybeNull(types.number),
  object_type: types.maybeNull(ObjectWithResource),
  converted_pdf_url: types.maybeNull(types.string),
  thumbnail_url: types.maybeNull(types.string),
  file_size: types.maybeNull(types.number),
  mime_type: types.maybeNull(types.string),
})

export const GetQuizResource = ResourceRead.named('GetQuizResource').props({
  created_at: types.string,
  is_recommended: types.maybeNull(types.boolean),
  is_required: types.maybeNull(types.boolean),
})

export const GetResourceTopic = types.model('GetResourceTopic').props({
  image: types.maybeNull(types.string),
  title: types.maybeNull(types.string),
  topic_id: types.number,
})

export const GetResourceQuiz = types.model('GetResourceQuiz').props({
  question_count: types.maybeNull(types.number),
  title: types.maybeNull(types.string),
  quiz_id: types.number,
})
