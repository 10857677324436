import React from 'react'
import { CheckRounded, CloseRounded } from '@mui/icons-material'
import { GridFilterItem, getGridStringOperators } from '@mui/x-data-grid-pro'
import { DateTime } from 'luxon'

import { roundNumber } from '@trivie/core/src/utils/roundNumber'

import { getCustomCell, renderHeader } from './custom-cells'

const initFilterOperators = (column) => {
  if (column.headerName === 'Name') {
    column.filterOperators = getGridStringOperators()
      .filter((operator) => operator.value === 'contains' || operator.value === 'equals')
      .map((operator) => {
        operator.getApplyFilterFn = (filterItem: GridFilterItem) => {
          return (params: any, row): boolean => {
            if (operator.value === 'equals') {
              return (
                filterItem.value?.toLowerCase() ===
                  `${String(row.first_name)?.toLowerCase()} ${String(
                    row.last_name,
                  )?.toLowerCase()}` ||
                filterItem.value?.toLowerCase() === String(row.email?.toLowerCase())
              )
            }
            return (
              String(row.first_name).toLowerCase()?.includes(filterItem.value?.toLowerCase()) ||
              String(row.last_name).toLowerCase()?.includes(filterItem.value?.toLowerCase()) ||
              String(row.email).toLowerCase()?.includes(filterItem.value?.toLowerCase())
            )
          }
        }
        return operator
      })
  }
}

const initFormatters = (column) => {
  switch (column.data_type) {
    case 'array':
      column.valueFormatter = (value, row) => {
        if (column.is_custom) {
          let st = ''
          const val = row[column.column_alias]
          val?.slice(0, 3).map((s, index) => {
            if (s) {
              st += index > 0 ? `, ${s.value}` : `${s.value}`
              if (index >= 2 && val.length - 3 !== 0) {
                st += ` + ${val.length - 3} more`
              }
            }
          })
          return st ? st : 'N/A'
        } else {
          return value?.length > 0 ? value?.join(', ') : 'N/A'
        }
      }
      break
    case 'boolean':
      column.renderCell = (params: any) =>
        Boolean(params.row[column.field]) ? (
          <CheckRounded fontSize="medium" />
        ) : (
          <CloseRounded fontSize="medium" style={{ opacity: 0.3 }} />
        )
      break
    case 'datetime':
      // Must be date obj for mui datagrid
      column.valueGetter = (value) => (value ? new Date(value) : value)
      // Format for display with luxon
      column.valueFormatter = (value) =>
        value ? DateTime.fromISO(value.toISOString()).toLocaleString(DateTime.DATE_MED) : 'Never'
      break
    case 'float':
      column.valueFormatter = (value) => {
        if (
          column.field === 'question_answer_time' ||
          column.field === 'quiz_avg_completion_time' ||
          column.field === 'result_completion_time' ||
          column.field === 'user_avg_completion_time' ||
          column.field === 'assign_question_answer_time'
        ) {
          return `${roundNumber(value / 1000, 1)} sec`
        }
        return value == null || value === 0 ? 'N/A' : `${roundNumber(value, 1)}`
      }
      break
    case 'percent':
      column.valueGetter = (value) => value * 100
      column.valueFormatter = (value) => (value == null ? 'N/A' : `${roundNumber(value, 1)}%`)
      break
    case 'str':
      column.valueFormatter = (value) => (value ? value : 'N/A')
  }
}

const COLUMN_TYPE_MAP = {
  integer: 'number',
  float: 'number',
  percent: 'number',
  datetime: 'date',
  str: 'string',
}

const formatColumn = (option, metadata = {}, filter?) => {
  if (!option) {
    return {}
  }

  const column = {
    ...option,
    ...metadata,
    renderHeader: (params) => renderHeader(params),
    headerName:
      option.field === 'result_user_first_name' ? 'Name' : option.header_name || option.headerName,
    hideable: option.header_name !== 'Name' || option.headerName !== 'Name',
    disableReorder: option.field === 'identity' || option.field === 'group_name',
    align: 'left',
    renderCell:
      option.renderCell ??
      getCustomCell(
        option.field,
        option.header_name === 'Name' && option.field === 'identity'
          ? 'identity'
          : option.data_type,
        filter,
      ),
    type: COLUMN_TYPE_MAP[option.data_type] || option.data_type,
  }

  initFilterOperators(column) // Set custom filter operators
  initFormatters(column) // Set value getters / formatters

  return column
}

export const initColumns = (options: any, metadata?: any, filter?: string) => {
  let columnVisibilityModel = {}

  const columns = options
    .filter((option) => !option.is_custom)
    .map((option) => {
      const metaColumn = metadata?.find((metaCol) => metaCol.field === option.field)
      const column = formatColumn(option, metaColumn, filter)

      columnVisibilityModel[column.field] =
        metadata?.filter((meta) => meta.field === column.field).length > 0 ||
        column.field === 'identity'

      return column
    })

  metadata?.map((metaColumn) => {
    // Handle multiple custom columns for same option field
    if (!columns.find((col) => col.column_alias === metaColumn.column_alias)) {
      const option = options.find((opt) => opt.field === metaColumn.field)
      const column = { ...formatColumn(option, metaColumn, filter), field: metaColumn.column_alias }
      columnVisibilityModel[column.column_alias] = true

      columns.push(column)
    } else if (!columns.find((col) => col.field === metaColumn.field)) {
      columns.push(metaColumn)
    }
  })

  columnVisibilityModel = {
    ...columnVisibilityModel,
    first_name: false,
    last_name: false,
    email: false,
    photo_url: false,
    phone_number: false,
    result_user_last_name: false,
    result_user_email: false,
    result_user_id: false,
  }

  return { columnVisibilityModel, columns }
}
