import React from 'react'
import { Box } from '@mui/material'
import { Text } from '../text'
import { ReactComponent as MaintenanceLogo } from './maintenance.svg'
import { Dialog } from '../dialogs/dialog'

export interface MaintenanceDialogProps {
  visible: boolean
  onClose?: () => void
}

export const MaintenanceDialog = (props: MaintenanceDialogProps) => {
  const { onClose, visible } = props

  return (
    <Dialog
      disableBackdropClick
      disableEscapeKeyDown
      hideDivider
      title=""
      maxWidth="sm"
      onClose={onClose}
      open={visible}
    >
      <Box
        style={{
          margin: 'auto',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
        }}
      >
        <div style={{ margin: 'auto' }}>
          <MaintenanceLogo width={400} height={264} />
        </div>
        <Text
          variant="headline"
          tx="Maintenance.header"
          style={{ marginTop: 32, marginBottom: 10, textAlign: 'center' }}
        />
        <Text
          variant="labelMedium"
          tx="Maintenance.tagline"
          style={{ marginTop: 32, marginBottom: 10, textAlign: 'center' }}
        />
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
            marginBottom: 20,
          }}
        >
          <Text
            variant="body2Medium"
            tx="Maintenance.questions"
            style={{ marginTop: 3, textAlign: 'center' }}
          />
          <a href={`mailto:${process.env.REACT_APP_SUPPORT}`} style={{ marginLeft: 4 }}>
            <Text
              variant="body2Medium"
              text={process.env.REACT_APP_SUPPORT}
              style={{ textAlign: 'center', color: '#225FF6' }}
            />
          </a>
        </div>
      </Box>
    </Dialog>
  )
}
