import React, { useEffect, useState } from 'react'
import { observer } from 'mobx-react-lite'
import { Instance } from 'mobx-state-tree'
import { CircularProgress } from '@mui/material'

import { GroupApi } from '@trivie/core/src/services/api-objects/GroupApi'
import { UserGroupSelector } from '@trivie/ui-web/components/user-group-selector'
import { UserApi } from '@trivie/core/src/services/api-objects/UserApi'
import { ContestCreateForm } from '@trivie/core/src/models/ContestCreateForm'

type ContestCompetitorsProps = {
  form: Instance<typeof ContestCreateForm>
  editingId?: any
}

interface IAssigneeOptions {
  type: string
  name: string
  id: number
  number_users: number | undefined
}

export const ContestCompetitors = observer((props: ContestCompetitorsProps) => {
  const { form, editingId } = props
  const [isLoading, setIsLoading] = useState(true)

  const [assigneeOptions, setAssigneeOptions] = useState<IAssigneeOptions[]>([])
  const [selectedOptions, setSelectedOptions] = useState<IAssigneeOptions[]>([])

  useEffect(() => {
    setIsLoading(true)
    const opts = [] as any

    Promise.all([
      UserApi.getUsers(
        'users',
        false,
        [{ field: 'first_name' }, { field: 'last_name' }, { field: 'photo_url' }],
        'list_users',
      ),
      GroupApi.getAllGroups(
        'groups',
        false,
        [{ field: 'group_name' }, { field: 'group_user_count' }],
        'list_groups',
      ),
    ])
      .then(([userData, groupsData]) => {
        if (userData.ok && userData.data && groupsData.ok && groupsData.data) {
          const selectedUserGroups = [] as any
          userData.data.rows.map((user) => {
            const first_name = user.columns.find((column) => column.field === 'first_name')
            const last_name = user.columns.find((column) => column.field === 'last_name')
            const photo_url = user.columns.find((column) => column.field === 'photo_url')
            opts.push({
              type: 'Users',
              label: `${first_name.value} ${last_name.value}`,
              number_users: undefined,
              photo_url: photo_url.value,
              id: user.id,
            })

            if (form.user_ids.map((u) => u.user_id).includes(user.id)) {
              const assigned = form.user_ids.filter((u) => u.user_id === user.id)
              if (assigned) {
                selectedUserGroups.push({
                  type: 'Users',
                  label: `${first_name.value} ${last_name.value}`,
                  number_users: undefined,
                  id: user.id,
                })
              }
            }
          })
          groupsData.data.rows.map((group) => {
            const label = group.columns.find((column) => column.field === 'group_name')
            const count = group.columns.find((column) => column.field === 'group_user_count')
            opts.push({
              type: 'Groups',
              label: label.value,
              number_users: count.value,
              id: group.id,
            })

            if (form.group_ids.map((g) => g.group_id).includes(group.id)) {
              const assigned = form.group_ids.filter((g) => g.group_id === group.id)
              if (assigned) {
                selectedUserGroups.push({
                  type: 'Groups',
                  label: label.value,
                  number_users: undefined,
                  id: group.id,
                })
              }
            }
          })

          setSelectedOptions(selectedUserGroups)
        }
      })
      .finally(() => {
        setAssigneeOptions(opts)
        setIsLoading(false)
      })
  }, [])

  const handleAddUserGroup = (selected: IAssigneeOptions[]) => {
    setSelectedOptions(selected)
    if (form) {
      form.set('group_ids', selected?.filter((selection) => selection.type === 'Groups') ?? [])
      form.set('user_ids', selected?.filter((selection) => selection.type === 'Users') ?? [])
    }
  }

  return isLoading ? (
    <div style={LOADER}>
      <CircularProgress />
    </div>
  ) : (
    <div style={CONTAINER}>
      <UserGroupSelector
        open
        popperPortalWidth={728}
        options={assigneeOptions}
        total={3}
        listHeight={370}
        defaultOptions={[]}
        selectedOptions={selectedOptions}
        handleAddUserGroup={handleAddUserGroup}
      />
    </div>
  )
})

const CONTAINER: React.CSSProperties = {
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-start',
  width: '100%',
  overflow: 'hidden',
}
const LOADER: React.CSSProperties = {
  ...CONTAINER,
  flex: 1,
  justifyContent: 'center',
  alignItems: 'center',
}
