import React, { useEffect, useRef, useState } from 'react'

import { observer } from 'mobx-react-lite'
import { useHistory } from 'react-router-dom'
import { Box, Container, Grid } from '@mui/material'
import { getSnapshot } from 'mobx-state-tree'

import { useStores } from '@trivie/core'
import { StepsList } from '../content-creation/components/steps-list'
import { StepPanel } from '../content-creation/components/step-drawer'
import ContentCreationHeader from '../content-creation/content-creation-header'
import { PathSettings } from './components/path-settings'
import ContentCreationHeaderTitle from '../content-creation/content-creation-header-title'

const _isEqual = require('lodash/isEqual')

export const CreatePathScreen = observer((props: { edit?: boolean }) => {
  const { pathStore } = useStores()
  const { edit } = props
  const { workflow, createWorkflow, path, createPath } = pathStore

  const history = useHistory()

  const [showStepPanel, setShowStepPanel] = useState(false)
  const [pathSnapshot, setPathSnapshot] = useState<any>()

  const handleBack = async () => {
    if (edit) {
      history.goBack()
    } else {
      history.push('/content/paths')
    }
  }

  const handleAddStep = () => {
    setShowStepPanel(true)
    if (!workflow) {
      createWorkflow()
    }
    if (workflow?.editing) {
      workflow?.setEditing('')
      workflow?.selectStep(undefined)
    }
  }

  useEffect(() => {
    if (!path) {
      createPath()
    } else {
      setPathSnapshot(getSnapshot(path))
      if (workflow?.stub) {
        workflow?.removeAction(workflow?.stub)
      }
    }
  }, [])

  useEffect(() => {
    if (path?.name) {
      path.workflow_json.setName(`${path.name} - Workflow`)
    }
  }, [path?.name])

  return (
    <>
      <ContentCreationHeader
        style={{ position: 'absolute', left: 0, top: 0, width: '100%' }}
        editingPath={edit}
        onBack={handleBack}
        showUnsavedPopup={!_isEqual(path ? getSnapshot(path) : {}, pathSnapshot)}
        publishData={path}
        titleHeader={
          <ContentCreationHeaderTitle
            titles={[{ title: path?.name || 'Untitled Learning Path', isPlaceholder: !path?.name }]}
          />
        }
      />
      <Box sx={ROOT}>
        <Container sx={CONTAINER} disableGutters>
          <Box p={6} sx={{ flexDirection: 'row' }}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={12} md={4}>
                <PathSettings />
              </Grid>
              <Grid item xs={12} sm={12} md={8}>
                <StepsList
                  edit={edit}
                  workflow={workflow}
                  path={path}
                  showStepPanel={showStepPanel}
                  handleAddStep={handleAddStep}
                />
              </Grid>
            </Grid>
          </Box>
          <StepPanel
            workflow={workflow}
            open={showStepPanel}
            onOpen={() => setShowStepPanel(true)}
            onClose={() => setShowStepPanel(false)}
          />
        </Container>
      </Box>
    </>
  )
})

const ROOT = {
  position: 'absolute',
  top: 0,
  left: 0,
  bottom: 0,
  right: 0,
  background: 'rgb(244, 244, 244)',
  paddingTop: '70px',
}
const CONTAINER = { maxWidth: '1440px !important', overflowY: 'auto', height: '100%', pb: '72px' }
