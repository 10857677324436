import { types, getEnv, flow, getRoot, Instance, cast } from 'mobx-state-tree'
import { Environment } from '../environment'
import { activityTransformer, getStrongestWeakest } from '../../utils'
import { Awaited } from '../../utility-types/Awaited'
import { UserProfile } from '../UserProfile'
import { ActiveUser } from '../ActiveUser'
import { PendingUser } from '../PendingUser'
import { UserOverviewStats } from '../UserOverviewStats'
import { UserLevelAndPoints } from '../UserLevelAndPoints'
import { UserGeneralStats } from '../UserGeneralStats'
import { UserTag } from '../UserTag'
import { UserGroup } from '../UserGroup'
import { HourlyActivity } from '../HourlyActivity'
import {
  AuthenticationApi,
  UserApi,
  TagApi,
  GroupApi,
  LeaderboardApi,
} from '../../services/api-objects'
import { MagicLinkResponse } from '../MagicLinkResponse'
import { UserAssignmentStat } from '../UserAssignmentStat'
import { AssignedTopic } from '../AssignedTopic'
import { UserQuestionStat } from '../UserQuestionStat'
import { Settings } from '../Settings'
import { UserTopicKnowledgeGaps } from '../UserTopicKnowledgeGaps'
import { APIKeys } from '../response-models'
import { UserQuestionKnowledgeGaps } from '../UserQuestionKnowledgeGaps'
import { Admin } from '../Admin'
import { WorkspaceResponse } from '../WorkspaceResponse'
import { UserLevelModel, UserLevelModelType } from '../base'
import { UserHelper } from '../../helpers/UserHelper'
import { userPermissionsResponse, UserRole } from '../response-models/user'
import { UserCertificationMedals } from '../UserCertificationMedal'
import { AuthenticationHelper } from '../../helpers/AuthenticationHelper'
import { save } from '../../services/storage'

export const ManageUserStoreModel = types
  .model('ManageUserStore')
  .props({
    status: types.optional(types.enumeration(['idle', 'pending', 'done', 'error']), 'idle'),
    userProfile: types.maybeNull(UserProfile),
    creatingWorkspace: types.maybe(types.boolean),
    selectedUserId: types.maybeNull(types.number),
    availableUsers: types.frozen(),
    selectedUserProfile: types.maybeNull(UserProfile),
    activeUsers: types.maybeNull(types.array(ActiveUser)),
    pendingUsers: types.maybeNull(types.array(PendingUser)),
    overviewStats: types.maybeNull(UserOverviewStats),
    levelAndPoints: types.maybeNull(UserLevelAndPoints),
    generalStats: types.maybeNull(UserGeneralStats),
    groups: types.maybeNull(types.array(UserGroup)),
    tags: types.maybeNull(types.array(UserTag)),
    hourlyActivity: types.maybeNull(types.array(HourlyActivity)),
    pendingUsersFilter: types.maybe(types.string),
    groupsFilter: types.maybe(types.string),
    rawUserActivity: types.frozen(), // has a view, see userActivity below, don't use directly
    authenticated: types.maybe(types.boolean),
    assignments: types.maybeNull(types.array(UserAssignmentStat)),
    topics: types.maybeNull(types.array(AssignedTopic)),
    questionStats: types.maybeNull(types.array(UserQuestionStat)),
    settings: types.maybeNull(Settings),
    levels: types.array(UserLevelModel),
    originalLevels: types.array(UserLevelModel),
    admins: types.array(Admin),
    instance: types.maybeNull(MagicLinkResponse),
    email: types.maybeNull(types.string),
    topicKnowledgeGaps: types.maybeNull(UserTopicKnowledgeGaps),
    questionKnowledgeGaps: types.maybeNull(UserQuestionKnowledgeGaps),
    signUpData: types.frozen(),
    upgradeData: types.frozen(),
    ssoRedirect: types.maybe(types.string),
    ssoWorkspace: types.maybe(WorkspaceResponse),
    zapierAPIKey: types.maybe(APIKeys),
    permissions: types.maybeNull(userPermissionsResponse),
    userCertsMedals: types.maybeNull(UserCertificationMedals),
    roles: types.array(UserRole),
    allUsers: types.frozen(),
    tagCategories: types.array(types.string),
    initializing: types.maybe(types.boolean),
    otpNotFound: types.frozen(),
  })
  .views((self) => ({
    get userActivity() {
      let result = {}
      if (self.rawUserActivity) {
        try {
          result = activityTransformer(self.rawUserActivity)
        } catch (error) {
          console.log(`activityTransformer error`, error)
        }
      }
      return result
    },
    get freeTier() {
      return self.userProfile?.workspace_settings?.plan_tier === 'free'
    },
    get standardTier() {
      return self.userProfile?.workspace_settings?.plan_tier === 'standard'
    },
    get proTier() {
      return self.userProfile?.workspace_settings?.plan_tier === 'pro'
    },
    get enterpriseTier() {
      return self.userProfile?.workspace_settings?.plan_tier === 'enterprise'
    },
    get maxLevelReached() {
      return self.levels.length >= 98
    },
    get gdpr() {
      return Boolean(self.userProfile?.workspace_settings?.gdpr_enabled)
    },
  }))
  .actions((self) => ({
    setStatus(value?: 'idle' | 'pending' | 'done' | 'error') {
      self.status = value || 'idle'
    },
    setCreatingWorkspace(status: boolean) {
      self.creatingWorkspace = status
    },
    setOtpNotFound(status: boolean) {
      self.otpNotFound = status
    },
    setWorkspace(workspace: any) {
      self.instance = workspace
    },
    setInitializing(status) {
      self.initializing = status
    },
    setAvailableUsers(users: any) {
      self.availableUsers = users
    },
    setRoles(roles: typeof self.roles) {
      self.roles = roles
    },
    setUserCertsMedals(data: typeof self.userCertsMedals) {
      self.userCertsMedals = data
    },
    setSignUpData(data: typeof self.signUpData) {
      self.signUpData = data
    },
    setEmail(email: typeof self.email) {
      self.email = email
    },
    setUserProfile(userProfile: typeof self.userProfile) {
      self.userProfile = userProfile
    },
    clearUserProfile() {
      self.userProfile = null
    },
    clearSettings() {
      self.settings = null
    },
    setAuthenticated(status: typeof self.authenticated) {
      self.authenticated = status
    },
    setSelectedUserId(userId: typeof self.selectedUserId) {
      self.selectedUserId = userId
    },
    setSelectedUserProfile(user: typeof self.selectedUserProfile) {
      self.selectedUserProfile = user
    },
    setActiveUsers(users: typeof self.activeUsers) {
      self.activeUsers = cast(users)
    },
    setPendingUsers(users: typeof self.pendingUsers) {
      self.pendingUsers = cast(users)
    },
    setOverviewStats(stats: typeof self.overviewStats) {
      self.overviewStats = stats
    },
    setGeneralStats(stats: typeof self.generalStats) {
      self.generalStats = stats
    },
    setTags(tags: typeof self.tags) {
      self.tags = cast(tags)
    },
    setGroups(groups: typeof self.groups) {
      self.groups = cast(groups)
    },
    setHourlyActivity(hourlyActivity: typeof self.hourlyActivity) {
      self.hourlyActivity = cast(hourlyActivity)
    },
    setPendingUsersFilter(filter: typeof self.pendingUsersFilter) {
      self.pendingUsersFilter = filter
    },
    setGroupsFilter(filter: typeof self.groupsFilter) {
      self.groupsFilter = filter
    },
    setUserLevelAndPoints(levelAndPoints: typeof self.levelAndPoints) {
      self.levelAndPoints = levelAndPoints
    },
    setRawUserActivity(activity: typeof self.rawUserActivity) {
      self.rawUserActivity = activity
    },
    setUserAssignments(assignments: typeof self.assignments) {
      self.assignments = cast(assignments)
    },
    setTopics(topics: typeof self.topics) {
      self.topics = cast(topics)
    },
    setQuestionStats(stats: typeof self.questionStats) {
      self.questionStats = cast(stats)
    },
    setSettings(settings: typeof self.settings) {
      self.settings = settings
    },
    setLevels(levels: typeof self.levels) {
      self.levels = levels
    },
    setOriginalLevels(data: typeof self.originalLevels) {
      self.originalLevels = data
    },
    resetOriginalLevels() {
      if (self.originalLevels) {
        self.levels = cast(self.originalLevels.toJSON())
      }
    },
    removeLevel(index: number) {
      const updatedLevels = self.levels
        .filter((level, ind) => ind !== index)
        .map((level, ind) => ({
          ...level,
          level: ind + 1,
          min_points: ind === 0 ? 0 : level.min_points,
        }))
      self.levels = cast(updatedLevels)
    },
    addNewLevel() {
      const lastMinPoint = self.levels[self.levels.length - 1]?.min_points ?? 0
      const secondLastMinPoint = self.levels[self.levels.length - 2]?.min_points ?? 0
      let minPoint = lastMinPoint + (lastMinPoint - secondLastMinPoint)
      if (self.levels?.length === 0) {
        minPoint = 0
      }
      if (self.levels?.length === 1) {
        minPoint = 200
      }
      self.levels.push(
        UserLevelModel.create({
          id: null,
          icon: '',
          label: '',
          level: self.levels.length + 1,
          min_points: minPoint,
        }),
      )
    },
    setAdmins(admins: typeof self.admins) {
      self.admins?.replace(admins)
    },
    setZapierAPIKey(ZapierAPIKey: typeof self.zapierAPIKey) {
      self.zapierAPIKey = ZapierAPIKey
    },
    setInstance(data: typeof self.instance) {
      self.instance = cast(data)
    },
    setTopicKnowledgeGaps(topicKnowledgeGaps: typeof self.topicKnowledgeGaps) {
      self.topicKnowledgeGaps = topicKnowledgeGaps
    },
    setQuestionKnowledgeGaps(questionKnowledgeGaps: typeof self.questionKnowledgeGaps) {
      self.questionKnowledgeGaps = questionKnowledgeGaps
    },
    setUpgradeData(data: typeof self.upgradeData) {
      self.upgradeData = data
    },
    setSSORedirectURL(url: string | undefined) {
      self.ssoRedirect = url
    },
    setSSOWorkspace(workspace: typeof self.ssoWorkspace) {
      self.ssoWorkspace = workspace
    },
    setPermissions(permissions: typeof self.permissions) {
      self.permissions = permissions
    },
    setTagCategories(data: typeof self.tagCategories) {
      self.tagCategories = data
    },
  }))
  .views((self) => ({
    get environment() {
      return getEnv(self) as Environment
    },
    get isLoading() {
      return self.status === 'pending'
    },
    get rootStore(): any {
      return getRoot(self) as any
    },
    get filteredGroups() {
      if (!self.groupsFilter) {
        return self.groups
      } else if (!self.groups) {
        return []
      } else {
        return self.groups.filter((g) => {
          return g.label.toLocaleLowerCase().includes(self.groupsFilter!.toLocaleLowerCase())
        })
      }
    },
    hasPermission: (permission: string) => {
      if (!self.permissions?.permissions?.length) {
        return false
      }

      return self.permissions.permissions.some((p) => p.code === permission)
    },
  }))
  .actions((self) => ({
    removeAuthHeaders() {
      self.environment.api.removeAuthHeaders()
    },
    getUserProfile: flow(function* (id: number) {
      self.setStatus('pending')
      try {
        const result: Awaited<ReturnType<typeof UserApi.getUserProfile>> =
          yield UserApi.getUserProfile(id)
        if (result.ok) {
          self.setUserProfile(result.data!)
          self.setStatus('done')
          return result.data!
        } else {
          self.setStatus('error')
        }
      } catch (err) {
        self.setStatus('error')
      }
    }),
    getAccessCodeDetails: flow(function* (access_code: string) {
      self.setStatus('pending')
      try {
        const result: Awaited<ReturnType<typeof AuthenticationApi.getAccessCodeDetails>> =
          yield AuthenticationApi.getAccessCodeDetails(access_code, true)
        if (result.ok && result.data) {
          self.setSSORedirectURL(result.data.redirect_url)
          self.setStatus('done')
          return result.data.redirect_url
        } else {
          self.setStatus('error')
        }
      } catch {
        self.setStatus('error')
      }
    }),
    evaluateOTC: flow(function* (code: string, email?: string, phone_number?: string) {
      self.setStatus('pending')
      try {
        const result = yield self.environment.api.evaluateOTC(code, email, phone_number)
        if (result.kind === 'ok') {
          self.setWorkspace(result.data)
          self.setStatus('done')
          self.setOtpNotFound(false)
          return result.data
        } else {
          self.setOtpNotFound(true)
        }
      } catch {
        self.setOtpNotFound(true)
        self.setStatus('error')
      }
    }),
    login: flow(function* (user: any, access_code: string) {
      self.setStatus('pending')
      try {
        const result = yield self.environment.api.loginManage(user, access_code)
        if (result.kind === 'ok') {
          self.setAuthenticated(true)
          ;(self as Instance<typeof ManageUserStoreModel>).getUserProfile(result.data.user_id)
          self.setStatus('done')
          return result.data
        } else {
          localStorage.removeItem('access_code')
          localStorage.removeItem('token')
          self.setAuthenticated(false)
          self.setStatus('error')
        }
      } catch {
        localStorage.removeItem('access_code')
        localStorage.removeItem('token')
        self.setAuthenticated(false)
        self.setStatus('error')
      }
    }),
    requestMagicLink: flow(function* (email?: string) {
      self.setStatus('pending')
      try {
        if (!email && !self.email) {
          self.setStatus('error')
          return
        }

        const result = yield self.environment.api.requestMagicLink(true, (email ?? self.email)!)
        if (result.kind === 'ok') {
          self.setStatus('done')
        } else {
          self.setStatus('error')
        }
      } catch {
        self.setStatus('error')
      }
    }),
    evaluateMagicLink: flow(function* (token, email) {
      self.setStatus('pending')
      self.setEmail(email)
      try {
        const result: Awaited<ReturnType<typeof AuthenticationApi.evaluateMagicLink>> =
          yield AuthenticationApi.evaluateMagicLink({
            for_insights: true,
            token,
            email,
          })
        if (result.ok) {
          self.setStatus('done')
          self.setInstance(result.data!)
          save('workspaces', result.data)
          return result.data!
        } else {
          self.setStatus('error')
        }
      } catch {
        self.setStatus('error')
      }
    }),
    loginWorkspace: flow(function* (token: string, access_code: string, callback?: any) {
      self.setStatus('pending')
      try {
        const result = yield AuthenticationHelper.loginWorkspace(token, access_code)

        if (result.ok && result.data) {
          self.setStatus('done')
          return (self as Instance<typeof ManageUserStoreModel>).getSettings().then(() => {
            self.setInitializing(false)
            return (self as Instance<typeof ManageUserStoreModel>)
              .loadUser(result.data.user_id)
              .then(() => {
                localStorage.setItem('token', result.data.access_token)
                return true
              })
          })
        } else {
          self.setStatus('error')
          return false
        }
      } catch {
        self.setStatus('error')
        return false
      }
    }),
    getAvailableUsers: flow(function* (
      meta: boolean,
      columns?: any,
      view_name?: string,
      view_id?: number,
    ) {
      self.setStatus('pending')
      try {
        const result: Awaited<ReturnType<typeof UserApi.getAllUsers>> = yield UserApi.getAllUsers(
          meta,
          columns,
          view_name,
          // view_id,
        )
        const users = [] as any
        if (result.ok) {
          if (!meta) {
            result.data.rows.forEach((row) => {
              const user = { id: row.id, is_gdpr: false } as any
              row.columns.forEach((col) => {
                user[col.field] = col.value
                user.is_gdpr = col.is_gdpr
              })
              users.push(user)
            })
          }
          self.setAvailableUsers(users)
          self.setStatus('done')
        } else {
          self.setStatus('error')
        }
      } catch {
        self.setStatus('error')
      }
    }),
    getSelectedUserProfile: flow(function* (id?: number) {
      self.setStatus('pending')
      if (!id && !self.selectedUserId) {
        self.setStatus('error')
        return
      }

      try {
        const result: Awaited<
          ReturnType<
            typeof UserApi.getUserProfile
            // @ts-ignore
          >
          // @ts-ignore
        > = yield UserApi.getUserProfile(id ? Number(id) : self.selectedUserId)
        if (result.ok) {
          self.setSelectedUserProfile(result.data!)
          self.setStatus('done')
        } else {
          self.setStatus('error')
        }
      } catch {
        self.setStatus('error')
      }
    }),
    getUserOverviewStats: flow(function* (startingTime: string, endingTime: string, id?: number) {
      self.setStatus('pending')
      if (!self.selectedUserId) {
        self.setStatus('error')
        return
      }

      try {
        const result: Awaited<ReturnType<typeof UserApi.getUserOverviewStats>> =
          yield UserApi.getUserOverviewStats(id ?? self.selectedUserId, startingTime, endingTime)
        if (result.ok && result.data) {
          self.setOverviewStats(result.data)
          self.setStatus('done')
        } else {
          self.setStatus('error')
        }
      } catch {
        self.setStatus('error')
      }
    }),
    getUserGeneralStats: flow(function* (startingTime: string, endingTime: string, id?: number) {
      self.setStatus('pending')
      // self.setGeneralStats(null)
      if (!id && !self.selectedUserId) {
        self.setStatus('error')
        return
      }

      try {
        const result: Awaited<
          ReturnType<
            typeof UserApi.getUserGeneralStats
            //@ts-ignore
          >
          // @ts-ignore
        > = yield UserApi.getUserGeneralStats(id ?? self.selectedUserId, startingTime, endingTime)
        if (result.ok) {
          self.setGeneralStats(result.data!)
          self.setStatus('done')
        } else {
          self.setStatus('error')
        }
      } catch {
        self.setStatus('error')
      }
    }),
    getTags: flow(function* (id?: number) {
      self.setStatus('pending')
      if (!self.selectedUserId && !Boolean(id)) {
        self.setStatus('error')
        return
      }

      if (!self.selectedUserId && !Boolean(id)) {
        self.setStatus('error')
        return
      }

      try {
        const result: Awaited<ReturnType<typeof TagApi.getUserTags>> = yield TagApi.getUserTags(
          //@ts-ignore
          self.selectedUserId ?? id,
        )

        if (result.ok) {
          self.setTags(null)
          self.setTags(cast(result.data!))
          self.setStatus('done')
        } else {
          self.setStatus('error')
        }
      } catch {
        self.setStatus('error')
      }
    }),
    getTagCategories: flow(function* (id?: number) {
      self.setStatus('pending')

      try {
        const result: Awaited<ReturnType<typeof TagApi.getTagCategories>> =
          yield TagApi.getTagCategories()

        if (result.ok && result.data) {
          self.setTagCategories(cast(result.data.tag_keys))
          self.setStatus('done')
        } else {
          self.setStatus('error')
        }
      } catch {
        self.setStatus('error')
      }
    }),
    getUserGroups: flow(function* () {
      self.setStatus('pending')
      if (!self.selectedUserId) {
        self.setStatus('error')
        return
      }

      try {
        const result: Awaited<ReturnType<typeof GroupApi.getUserGroups>> =
          yield GroupApi.getUserGroups(self.selectedUserId)
        if (result.ok) {
          self.setGroups(cast(result.data!))
          self.setStatus('done')
        } else {
          self.setStatus('error')
        }
      } catch {
        self.setStatus('error')
      }
    }),
    getUserHourlyActivity: flow(function* (startingTime: string, endingTime: string) {
      self.setStatus('pending')
      if (!self.selectedUserId) {
        self.setStatus('error')
        return
      }

      try {
        const result: Awaited<ReturnType<typeof UserApi.getUserHourlyActivity>> =
          yield UserApi.getUserHourlyActivity(self.selectedUserId, startingTime, endingTime)

        if (result.ok) {
          self.setHourlyActivity(cast(result.data!))
          self.setStatus('done')
        } else {
          self.setStatus('error')
        }
      } catch {
        self.setStatus('error')
      }
    }),
    removeAdmins: flow(function* (iDs: [string]) {
      self.setStatus('pending')

      try {
        const result = yield self.environment.api.removeAdmins(iDs.map((id) => Number(id)))
        if (result.kind === 'ok') {
          self.setStatus('done')
        } else {
          self.setStatus('error')
        }
      } catch {
        self.setStatus('error')
      }
    }),
    deleteUsers: flow(function* (iDs: [string], onSuccess = () => ({}), callErrorRes = () => ({})) {
      self.setStatus('pending')

      try {
        const result = yield self.environment.api.deleteUsers(iDs.map((id) => Number(id)))
        if (result.kind === 'ok') {
          self.setStatus('done')
          onSuccess()
        } else {
          self.setStatus('error')
        }
      } catch (error) {
        self.setStatus('error')
        callErrorRes(error)
      }
    }),
    getUserLevelAndPoints: flow(function* (startingTime: string, endingTime: string) {
      self.setStatus('pending')
      if (!self.selectedUserId) {
        self.setStatus('error')
        return
      }

      try {
        const result: Awaited<ReturnType<typeof UserApi.getUserLevelAndPoints>> =
          yield UserApi.getUserLevelAndPoints(self.selectedUserId, startingTime, endingTime, true)

        const leaderboardResult: Awaited<ReturnType<typeof LeaderboardApi.getLeaderboardRank>> =
          yield LeaderboardApi.getLeaderboardRank(self.selectedUserId, 'all_time')

        if (result.ok && result.data && leaderboardResult.ok && leaderboardResult.data) {
          self.setUserLevelAndPoints({
            ...result.data,
            current_rank: leaderboardResult.data.current_rank,
          })
          self.setStatus('done')
        } else {
          self.setStatus('error')
        }
      } catch (e) {
        console.log(`e`, e)
        self.setStatus('error')
      }
    }),
    inviteAdmin: flow(function* (formData: any) {
      self.setStatus('pending')

      try {
        const result = yield self.environment.api.inviteAdmin(formData)
        if (result.kind === 'ok') {
          self.setStatus('done')
        } else {
          self.setStatus('error')
        }
      } catch {
        self.setStatus('error')
      }
    }),
    inviteUser: flow(function* (formData: any) {
      self.setStatus('pending')

      try {
        const result = yield self.environment.api.inviteUser(formData)
        if (result.kind === 'ok') {
          self.setStatus('done')
        } else {
          self.setStatus('error')
        }
      } catch {
        self.setStatus('error')
      }
    }),
    importUsers: flow(function* (userData: any) {
      const importPromises = userData.map((user: any) => {
        return (self as Instance<typeof ManageUserStoreModel>).inviteUser(user)
      })

      yield Promise.all(importPromises)
    }),
    importAdmins: flow(function* (userData: any) {
      const importPromises = userData.map((user: any) => {
        return (self as Instance<typeof ManageUserStoreModel>).inviteAdmin(user)
      })

      yield Promise.all(importPromises)
    }),
    getUserActivity: flow(function* () {
      self.setStatus('pending')

      if (!self.selectedUserId) {
        self.setStatus('error')
        return
      }

      try {
        const result: Awaited<ReturnType<typeof UserApi.getUserActivity>> =
          yield UserApi.getUserActivity(self.selectedUserId)
        if (result.ok) {
          self.setRawUserActivity(result.data!)
          self.setStatus('done')
        } else {
          self.setStatus('error')
        }
      } catch {
        self.setStatus('error')
      }
    }),
    addUserToGroups: flow(function* (groupIds: any) {
      self.setStatus('pending')

      if (!self.selectedUserId) {
        self.setStatus('error')
        return
      }

      try {
        const promises = groupIds.map((groupId: any) => {
          return self.environment.api.addUsersToGroup(groupId, {
            user_ids: [self.selectedUserId!],
          })
        })
        const results = yield Promise.all(promises)
        if (results.some((result: any) => result.kind !== 'ok')) {
          self.setStatus('error')
        } else {
          self.setGroups(null)
          ;(self as Instance<typeof ManageUserStoreModel>).getUserGroups()
          self.setStatus('done')
        }
      } catch {
        self.setStatus('error')
      }
    }),
    getAssignments: flow(function* () {
      self.setStatus('pending')
      if (!self.selectedUserId) {
        self.setStatus('error')
      }

      try {
        const result: Awaited<ReturnType<typeof UserApi.getUserAssignments>> =
          yield UserApi.getUserAssignments(self.selectedUserId!)
        if (result.ok) {
          self.setUserAssignments(cast(result.data!))
          self.setStatus('done')
        } else {
          self.setStatus('error')
        }
      } catch {
        self.setStatus('error')
      }
    }),
    refreshOverviewData: flow(function* (start: string, end: string, id?: any) {
      ;(self as Instance<typeof ManageUserStoreModel>).getUserOverviewStats(start, end, id)
      ;(self as Instance<typeof ManageUserStoreModel>).getUserGeneralStats(start, end, id)
      if (self.enterpriseTier) {
        ;(self as Instance<typeof ManageUserStoreModel>).getUserLevelAndPoints(start, end)
        ;(self as Instance<typeof ManageUserStoreModel>).getUserHourlyActivity(start, end)
        ;(self as Instance<typeof ManageUserStoreModel>).getTags()
        ;(self as Instance<typeof ManageUserStoreModel>).getUserGroups()
      }
    }),
    clearOverviewData: () => {
      self.setUserLevelAndPoints(null)
      self.setOverviewStats(null)
      self.setGeneralStats(null)
      self.setHourlyActivity(null)
      self.setTags(null)
      self.setGroups(null)
    },
    getUserTopicKnowledgeGaps: flow(function* () {
      self.setStatus('pending')

      if (!self.selectedUserId) {
        self.setStatus('error')
        return
      }

      try {
        const result: Awaited<ReturnType<typeof UserApi.getUserTopicGaps>> =
          yield UserApi.getUserTopicGaps(self.selectedUserId!)

        if (result.ok) {
          const strongestWeakestTopics = getStrongestWeakest(result.data!.topics!)

          self.setTopicKnowledgeGaps({
            strongestTopics: cast(strongestWeakestTopics.strongest),
            weakestTopics: cast(strongestWeakestTopics.weakest),
          })
          self.setStatus('done')
        } else {
          self.setStatus('error')
        }
      } catch {
        self.setStatus('error')
      }
    }),
    getUserQuestionKnowledgeGaps: flow(function* () {
      self.setStatus('pending')

      if (!self.selectedUserId) {
        self.setStatus('error')
        return
      }

      try {
        const result: Awaited<ReturnType<typeof UserApi.getUserQuestionGaps>> =
          yield UserApi.getUserQuestionGaps(self.selectedUserId!)

        if (result.ok) {
          const strongestWeakestQuestions = getStrongestWeakest(result.data!.questions ?? [])
          self.setQuestionKnowledgeGaps({
            strongestQuestions: cast(strongestWeakestQuestions.strongest),
            weakestQuestions: cast(strongestWeakestQuestions.weakest),
          })
          self.setStatus('done')
        } else {
          self.setStatus('error')
        }
      } catch {
        self.setStatus('error')
      }
    }),
    getSettings: flow(function* () {
      self.setStatus('pending')

      try {
        const result: Awaited<ReturnType<typeof UserApi.getSettings>> = yield UserApi.getSettings()
        if (result.ok) {
          self.setSettings({
            ...result.data!,
            user_leaderboard_max_length_enabled: Boolean(result.data!.user_leaderboard_max_length),
          })
          self.setStatus('done')
          return {
            ...result.data!,
            user_leaderboard_max_length_enabled: Boolean(result.data!.user_leaderboard_max_length),
          }
        } else {
          self.setStatus('error')
        }
      } catch {
        self.setStatus('error')
      }
    }),
    getUserLevels: flow(function* () {
      self.setStatus('pending')

      try {
        const result = yield UserApi.getUserLevels()
        if (result.ok) {
          const userLevels = result.data?.sort(
            (a: UserLevelModelType, b: UserLevelModelType) => a.level - b.level,
          )
          self.setLevels(userLevels)
          self.setOriginalLevels(userLevels)
          self.setStatus('done')
        } else {
          self.setStatus('error')
        }
      } catch {
        self.setStatus('error')
      }
    }),
    updateUserLevels: flow(function* () {
      self.setStatus('pending')

      try {
        const result = yield UserApi.updateUserLevels(self.levels)
        if (result.ok) {
          let levelWithIconUpdate = self.levels.toJSON()
          // only those levels which have local icon as blob:
          levelWithIconUpdate = levelWithIconUpdate.filter((level) => level.icon?.includes('blob:'))
          // fetch user levels to have ids of newly added levels
          ;(self as Instance<typeof ManageUserStoreModel>).getUserLevels().then(() => {
            const promises: Promise<any>[] = []
            // add id to those levels which were just added
            levelWithIconUpdate = levelWithIconUpdate.map((level) => {
              if (!level.id) {
                const updatedLevel = self.levels.find((l) => l.level === level.level)
                if (updatedLevel && updatedLevel.id) {
                  return { ...level, id: updatedLevel.id }
                }
              }
              return { ...level }
            })
            levelWithIconUpdate.forEach((level, index) => {
              if (level.icon) {
                // convert blob to a file
                fetch(level.icon)
                  .then((res) => res.blob())
                  .then((iconBlob) => {
                    const file = new File(
                      [iconBlob],
                      `levelIcon${index + 1}.${iconBlob?.type?.split('/')[1]}`,
                      {
                        type: iconBlob.type,
                      },
                    )
                    if (level.id) {
                      promises.push(UserApi.updateUserLevelIcon({ file, userLevelId: level.id }))
                    }
                  })
              }
            })
            // fetch user levels again after updating icons
            Promise.allSettled(promises).then(() => {
              setTimeout(() => {
                // added timeout because the getUserLevels was being called earlier than icon update
                ;(self as Instance<typeof ManageUserStoreModel>).getUserLevels()
              }, 1000)
              self.setStatus('done')
            })
          })
        } else {
          self.setStatus('error')
        }
      } catch {
        self.setStatus('error')
      }
    }),
    saveSettings: flow(function* (settings: any) {
      self.setStatus('pending')

      const updatedSettings = {
        ...self.settings,
        ...settings,

        webhooks: {
          // TODO a bug on backend is giving this to me null so I have to remove this once that's fixed
          user_passed_quiz: '',
          user_failed_quiz: '',
          user_completed_assignment: '',
        },
      }

      if (settings.user_leaderboard_max_length === '') {
        updatedSettings.user_leaderboard_max_length = null
      } else if (settings.user_leaderboard_max_length) {
        updatedSettings.user_leaderboard_max_length = settings.user_leaderboard_max_length
      }

      try {
        const result = yield self.environment.api.saveSettings({
          ...updatedSettings,
          group_leaderboard_alltime_enabled: updatedSettings.user_leaderboard_alltime_enabled,
          group_leaderboard_daily_enabled: updatedSettings.user_leaderboard_daily_enabled,
          group_leaderboard_monthly_enabled: updatedSettings.user_leaderboard_monthly_enabled,
          group_leaderboard_weekly_enabled: updatedSettings.user_leaderboard_weekly_enabled,
        })
        if (result.kind === 'ok') {
          self.setStatus('done')
          const settingsResult = yield (self as Instance<typeof ManageUserStoreModel>).getSettings()
          return settingsResult
        } else {
          self.setStatus('error')
        }
      } catch {
        self.setStatus('error')
      }
    }),
    getAdmins: flow(function* () {
      self.setStatus('pending')
      try {
        const result: Awaited<ReturnType<typeof UserApi.getAdmins>> = yield UserApi.getAdmins()

        let rolesResult: Awaited<ReturnType<typeof AuthenticationApi.getRoles>>

        if (result.ok && result.data) {
          self.setAdmins(cast(result.data))
          if (self.enterpriseTier) {
            rolesResult = yield AuthenticationApi.getRoles(false)
            self.setRoles(cast(rolesResult.data))
            if (rolesResult.data) {
              self.admins?.forEach((admin) => admin.setDefaultRole(rolesResult.data))
            }
          }
          self.setStatus('done')
        } else {
          self.setStatus('error')
        }
      } catch {
        self.setStatus('error')
      }
    }),
    getZapierAPIKey: flow(function* () {
      self.setStatus('pending')

      try {
        const result: Awaited<ReturnType<typeof UserApi.getZapierAPIKey>> =
          yield UserApi.getZapierAPIKey()
        if (result.ok) {
          self.setZapierAPIKey(result.data)
          self.setStatus('done')
          return result.data
        } else {
          self.setStatus('error')
        }
      } catch {
        self.setStatus('error')
      }
    }),
    createZapierAPIKey: flow(function* (key_name: string) {
      self.setStatus('pending')

      try {
        const result: Awaited<ReturnType<typeof UserApi.createZapierAPIKey>> =
          yield UserApi.createZapierAPIKey(key_name)
        if (result.ok) {
          self.setStatus('done')
          return result.data
        } else {
          self.setStatus('error')
        }
      } catch {
        self.setStatus('error')
      }
    }),
    deleteZapierAPIKey: flow(function* (token: string) {
      self.setStatus('pending')
      try {
        const result: Awaited<ReturnType<typeof UserApi.deleteZapierAPIKey>> =
          yield UserApi.deleteZapierAPIKey(token)
        if (result.ok) {
          self.setZapierAPIKey(undefined)
          self.setStatus('done')
          return result.data
        } else {
          self.setStatus('error')
        }
      } catch {
        self.setStatus('error')
      }
    }),
    resendInvitation: flow(function* (userIds: any) {
      self.setStatus('pending')

      try {
        const result = yield self.environment.api.resendInvitation(userIds)
        if (result.kind === 'ok') {
          self.setStatus('done')
        } else {
          self.setStatus('error')
        }
      } catch {
        self.setStatus('error')
      }
    }),
    submitCompanyLogo: flow(function* (logo: any) {
      self.setStatus('pending')

      try {
        const result = yield self.environment.api.patchCompanyLogo(logo)
        if (result.kind === 'ok') {
          ;(self as any).getSettings()
          self.setStatus('done')
        } else {
          self.setStatus('error')
        }
      } catch {
        self.setStatus('error')
      }
    }),
    toggleExcludeFromLeaderboard: flow(function* (value: any) {
      self.setStatus('pending')

      if (
        !self.selectedUserId ||
        !self.selectedUserProfile ||
        value !== self.selectedUserProfile.exclude_from_leaderboard
      ) {
        return
      }

      try {
        const result = yield self.environment.api.toggleExcludeFromLeaderboard(self.selectedUserId)

        if (result.kind === 'ok') {
          ;(self as Instance<typeof ManageUserStoreModel>).getSelectedUserProfile()
          self.setStatus('done')
        } else {
          self.setStatus('error')
        }
      } catch {
        self.setStatus('error')
      }
    }),
    reportProblem: flow(function* (text: any) {
      self.setStatus('pending')
      try {
        const result = yield self.environment.api.reportProblem(text, 'New Support Message')
        if (result.kind === 'ok') {
          self.setStatus('done')
        } else {
          self.setStatus('error')
        }
      } catch {
        self.setStatus('error')
      }
    }),
    uploadProfilePhoto: flow(function* (photo, id) {
      self.setStatus('pending')

      if (!self.userProfile) {
        self.setStatus('error')
        return
      }

      try {
        const formData = new FormData()
        formData.append('photo', photo)
        const result = yield self.environment.api.uploadProfilePhoto(formData, self.userProfile.id)
        if (result.kind === 'ok') {
          ;(self as Instance<typeof ManageUserStoreModel>).getUserProfile(self.userProfile.id)
        } else {
          self.setStatus('error')
        }
      } catch {
        self.setStatus('error')
      }
    }),
    updateUser: flow(function* (userUpdate: any) {
      self.setStatus('pending')

      if (!self.userProfile) {
        self.setStatus('error')
        return
      }

      try {
        const result = yield self.environment.api.updateUser(self.userProfile.id, {
          ...self.userProfile,
          ...self.userProfile.user_comm_settings,
          ...userUpdate,
        })
        if (result.kind === 'ok') {
          ;(self as Instance<typeof ManageUserStoreModel>).getUserProfile(self.userProfile.id)
          self.setStatus('done')
        } else {
          self.setStatus('error')
        }
      } catch {
        self.setStatus('error')
      }
    }),
    updateSelectedUser: flow(function* (
      userUpdate: any,
      onSuccess = () => ({}),
      onError = () => ({}),
      id?: number,
    ) {
      self.setStatus('pending')
      if (!self.selectedUserId && !id) {
        self.setStatus('error')
        return
      }

      try {
        // @ts-ignore
        const result = yield self.environment.api.updateUser(id ?? self.selectedUserId, {
          ...self.selectedUserProfile,
          ...userUpdate,
        })
        if (result.kind === 'ok') {
          self.setSelectedUserProfile(result.data)
          self.setStatus('done')
          onSuccess()
        } else {
          self.setStatus('error')
        }
      } catch (error) {
        onError(error)
        self.setStatus('error')
      }
    }),
    getCertsMedalsForUser: flow(function* (id: number) {
      self.setStatus('pending')
      try {
        const result: Awaited<ReturnType<typeof UserApi.getCertsMedalsForUser>> =
          yield UserApi.getCertsMedalsForUser(id)

        if (result.ok && result.data) {
          self.setUserCertsMedals(result.data)
          self.setStatus('done')
          return result.data
        } else {
          self.setStatus('error')
        }
      } catch (e) {
        self.setStatus('error')
      }
    }),
    getRoles: flow(function* (includeLearner: boolean) {
      self.setStatus('pending')
      try {
        const result: Awaited<ReturnType<typeof AuthenticationApi.getRoles>> =
          yield AuthenticationApi.getRoles(includeLearner)
        if (result.ok && result.data) {
          self.setRoles(cast(result.data))
          self.admins?.forEach((admin) => admin.setDefaultRole(result.data))
          self.setStatus('done')
        } else {
          self.setStatus('error')
        }
      } catch {
        self.setStatus('error')
      }
    }),
    updateRoles: flow(function* (userID: number, roleIDs: number[]) {
      self.setStatus('pending')
      try {
        const result: Awaited<ReturnType<typeof AuthenticationApi.updateRoles>> =
          yield AuthenticationApi.updateRoles(userID, roleIDs)
        if (result.ok) {
          self.setStatus('done')
        } else {
          self.setStatus('error')
        }
      } catch {
        self.setStatus('error')
      }
    }),
    loadUser: flow(function* (id: string) {
      self.setStatus('pending')
      const { reportStore } = self.rootStore
      try {
        const data: Awaited<ReturnType<typeof UserHelper.loadUser>> = yield UserHelper.loadUser(id)
        self.setUserProfile(data.profile)
        if (self.enterpriseTier) {
          reportStore.getBaseViews()
        }
        self.setPermissions(data.permissions)
        self.setStatus('done')
      } catch (e) {
        self.setStatus('error')
        // @ts-ignore
        return e
      }
    }),
    clearAuthentication: flow(function* () {
      self.setAuthenticated(false)
      yield AuthenticationHelper.clearAuthentication()
    }),
    logout: flow(function* () {
      self.setUserProfile(null)
      self.environment.api.removeAuthHeaders()
      localStorage.clear()
    }),
  }))

type ManageUserStoreSnapshotType = typeof ManageUserStoreModel.SnapshotType
export interface ManageUserStoreSnapshot extends ManageUserStoreSnapshotType {}
