import { types } from 'mobx-state-tree'

/**
 * ChoiceForQuizStart Model
 */
export const ChoiceForQuizStartModel = types.model('ChoiceForQuizStart').props({
  __typename: types.optional(types.literal('ChoiceForQuizStart'), 'ChoiceForQuizStart'),
  correct: types.boolean,
  id: types.integer,
  stats: types.maybeNull(types.array(types.maybeNull(types.frozen()))),
  text: types.string,
  order: types.maybeNull(types.number),
})
