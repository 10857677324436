import React, { useEffect, useState } from 'react'
import { Box, IconButton } from '@mui/material'
import { Close } from '@mui/icons-material'
import { observer } from 'mobx-react-lite'

import { useStores } from '@trivie/core'
import { color } from '../../theme/color'
import { Dialog } from '../dialogs/dialog'
import { QuizSelector } from '../quiz-selector'
import { Text } from '../text'

export const AddQuizQuestionsModal = observer((props: any) => {
  const { open, onClose, questionIds, quizzes } = props
  const [selectedQuizzes, setSelectedQuizzes] = useState<any[]>([])
  const { manageQuizStore } = useStores()
  const { updateQuizQuestions } = manageQuizStore

  const handleSubmit = () => {
    selectedQuizzes.map((q: any) => {
      updateQuizQuestions(q.id, { questions: questionIds })
    })
    onClose()
  }

  const handleChange = (quiz: any) => {
    setSelectedQuizzes([...selectedQuizzes, quiz])
  }

  const removeSelectedQuiz = (quiz: any) => {
    setSelectedQuizzes(selectedQuizzes.filter((q) => q.quiz_id !== quiz.quiz_id))
  }

  useEffect(() => {
    setSelectedQuizzes([])
  }, [open])

  return (
    <Dialog
      onClose={onClose}
      title="Add Questions to Quiz"
      open={open}
      onCancel={onClose}
      onConfirm={handleSubmit}
      disableConfirm={!selectedQuizzes.length}
      confirmBtnLabel="Add to Quiz"
      maxWidth="sm"
    >
      <Box height={600}>
        <QuizSelector
          quizzes={quizzes}
          selectedQuizzes={selectedQuizzes}
          onChange={(quiz: any) => handleChange(quiz)}
        />
        {selectedQuizzes.length ? (
          <Text
            mt={4}
            variant="body1"
            text={`Selected Quizzes (${selectedQuizzes.length})`}
            style={{ color: color.shade70 }}
          />
        ) : null}
        {selectedQuizzes.map((quiz: any) => (
          <div style={{ display: 'flex', paddingBottom: 4, alignItems: 'center' }}>
            <div style={{ display: 'flex', flexDirection: 'column', flex: 1, marginLeft: 8 }}>
              <Text variant="body1Emphasized" text={`${quiz.quiz_title}`} />
            </div>
            <IconButton
              style={{ alignSelf: 'flex-end' }}
              onClick={() => removeSelectedQuiz(quiz)}
              size="large"
            >
              <Close />
            </IconButton>
          </div>
        ))}
      </Box>
    </Dialog>
  )
})
