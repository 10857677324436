import { addMiddleware, IMiddlewareEvent, types } from 'mobx-state-tree'
import { DateTime } from 'luxon'

export const FlowCacheModel = types
  .model('FlowCache')
  .props({
    lastFetched: types.maybe(types.number),
    fetching: types.maybe(types.boolean),
    invalidate: types.maybe(types.boolean),
  })
  .actions((self) => ({
    setLastFetched(timestamp: number | undefined) {
      self.lastFetched = timestamp
    },
    setFetching(fetching: boolean) {
      self.fetching = fetching
    },
    setInvalidate(invalidate: boolean) {
      self.invalidate = invalidate
    },
  }))

/**
 * Attach middleware to store and provide a list of flow actions to cache / block
 * Stores keep a map of actions keyed by name
 * Values are a map of cache models keyed by args
 * Use with flow functions making api requests
 * Ensure any screens don't rely on a return value / side effects from an aborted action
 */
interface FlowCacheProps {
  store: any
  cacheable: any
}
export const useFlowCacheMiddleware = (props: FlowCacheProps) => {
  const { store, cacheable } = props

  // Reset cache data on init
  store.flowCache.clear()

  const flowCacheMiddleware = (
    call: IMiddlewareEvent,
    next: (call: IMiddlewareEvent, callback?: (value: any) => any) => void,
    abort,
  ) => {
    const action = call.parentEvent?.name ?? ''
    const args = call.parentEvent?.args ?? []
    const key = args.toString()

    if (!action || !cacheable[action]) {
      return next(call)
    }

    // Check if action is a flow function  (almost always api requests) / what stage its in
    if (call.type === 'flow_spawn') {
      // flow_spawn: The invocation / kickoff of a process block

      // If action is not in the list cacheable let action continue / break out of middleware

      // Grab or create cache entries map if undefined
      if (store.flowCache.get(action) === undefined) {
        const newEntries = {}
        newEntries[key] = FlowCacheModel.create({
          fetching: false,
          lastFetched: -999999999,
          invalidate: false,
        })
        store.flowCache.set(action, newEntries)
      }

      const cacheEntries = store.flowCache.get(action)
      const cache = cacheEntries.get(key)

      if (cache) {
        // Check against last fetched timestamp
        const diff = -1 * DateTime.fromMillis(cache.lastFetched).diffNow('seconds').seconds
        const waiting = diff < cacheable[action].wait

        // Check if already fetching, recently requested, or for the invalidate flag and abort
        if ((cache.fetching || waiting) && !cache.invalidate) {
          return abort(call)
        }

        // Otherwise mark as fetching, reset invalidate flag, and continue
        cache.setFetching(true)
        cache.setInvalidate(false)
      } else {
        // No entry found - create one instead, add action and continue
        cacheEntries.set(
          key,
          FlowCacheModel.create({ fetching: true, lastFetched: Date.now(), invalidate: false }),
        )
      }
    } else if (call.type === 'flow_resume_error') {
      // flow_resume_error: A promise that was returned from yield earlier was rejected.

      const entries = store.flowCache.get(action)
      const cache = entries.get(key)

      // if 404 cache request - otherwise continue, but remove cache entry
      if (cache && call.args[0]?.kind === 'not-found') {
        cache.setFetching(false)
        cache.setLastFetched(Date.now()) // Update last fetched timestamp
      } else {
        entries.delete(key)
      }
    } else if (call.type === 'flow_return') {
      // flow_return: the generator completed successfully.

      const cacheEntries = store.flowCache.get(action)
      const cache = cacheEntries.get(key)

      if (cache) {
        cache.setFetching(false)
        cache.setLastFetched(Date.now()) // Update last fetched timestamp
      }
    } else if (call.type === 'flow_throw') {
      // flow_throw: The generator threw an uncaught exception.
      // Remove cache entry and continue
      // entries.delete(key)
    }

    return next(call)
  }

  if (store) {
    addMiddleware(store, flowCacheMiddleware)
  }
}
