import { observer } from 'mobx-react-lite'
import React, { useState } from 'react'

import { useInterval, useStores } from '@trivie/core'
import { color } from '@trivie/ui-web/theme/color'
import { Button } from '@trivie/ui-web/components/button'
import { Text } from '@trivie/ui-web/components/text'

import { Logo } from '../logo'
import magic from './magic.png'

export const LinkSentScreen: React.FunctionComponent = observer(() => {
  const { manageUserStore, uiStore } = useStores()
  const { quantum } = uiStore
  const { requestMagicLink } = manageUserStore

  const [timer, setTimer] = useState(60)
  const [error] = useState(
    localStorage.getItem('ml-error') && JSON.parse(localStorage.getItem('ml-error') as any),
  )

  useInterval(() => {
    if (!error) {
      setTimer(timer - 1)
    }
  }, 1000)

  const sendAgain = () => {
    requestMagicLink()
  }

  return (
    <div style={ROOT}>
      <Logo quantum={quantum} style={{ marginTop: 20, marginLeft: 20, marginBottom: 32 }} />
      <div style={LEFT_CONTAINER}>
        <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
          <div>
            <div style={{ flexDirection: 'column', display: 'flex' }}>
              <div style={{ flexDirection: 'column' }}>
                {error && (
                  <Text
                    style={{ marginBottom: 24 }}
                    variant="headline"
                    text={error ? error.error : 'Check your email'}
                  />
                )}
                {error && (
                  <div style={{ flexDirection: 'row', display: 'flex' }}>
                    <Text
                      style={{
                        marginBottom: 60,
                        color: color.shade50,
                        fontSize: 16,
                      }}
                      variant="titleMedium"
                      text={error.msg}
                    />
                  </div>
                )}
              </div>
              {error && (
                <>
                  <div
                    style={{
                      flexDirection: 'row',
                      display: 'flex',
                      marginTop: -35,
                      marginBottom: 35,
                    }}
                  >
                    <Button onClick={sendAgain} variant="text" size="large" text="Send it again" />
                  </div>
                  <div style={{ flexDirection: 'row', display: 'flex' }}>
                    <Text
                      variant="titleMedium"
                      style={{
                        color: color.shade50,
                      }}
                      text="Need help? Reach out to&nbsp;"
                    />
                    <a href={`mailto:${process.env.REACT_APP_SUPPORT}`}>
                      <Text
                        variant="titleMedium"
                        style={{
                          color: color.blue,
                          textDecoration: 'underline',
                        }}
                        text={process.env.REACT_APP_SUPPORT}
                      />
                    </a>
                    <Text
                      variant="titleMedium"
                      style={{
                        color: color.shade50,
                      }}
                      text="&nbsp;to get in touch with us."
                    />
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
      <div style={RIGHT_CONTAINER}>
        <img style={{ alignSelf: 'center' }} alt="blobs" height={399} width={415} src={magic} />
      </div>
    </div>
  )
})

const ROOT: React.CSSProperties = {
  display: 'flex',
  flexDirection: 'row',
  width: '100%',
  backgroundColor: '#fff',
}
const LEFT_CONTAINER: React.CSSProperties = {
  width: '71%',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  backgroundColor: '#fff',
}
const RIGHT_CONTAINER: React.CSSProperties = {
  display: 'flex',
  flexDirection: 'row',
  width: '29%',
  backgroundColor: '#F6F8FB',
  justifyContent: 'center',
}
