import React, { useState } from 'react'
import { observer } from 'mobx-react-lite'

import { useStores } from '@trivie/core'
import { TableHelper } from '../../../../tables'

import { SurveyTable } from '../../../../tables/survey-table'

const CompletedSurveys: React.FunctionComponent = observer(() => {
  const { surveyStore } = useStores()

  const [loading, setLoading] = useState(true) as any

  const [tableHelper] = useState(
    new TableHelper('list_completed_surveys', 'surveys', 'surveys', undefined, 'completed'),
  )

  return <SurveyTable filter="completed" tableHelper={tableHelper} />
})

export const CompletedSurveysTab = () => {
  return {
    name: 'Completed',
    path: '/activities/surveys/completed',
    component: () => <CompletedSurveys />,
  }
}
