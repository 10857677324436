import React from 'react'
import { DateTime } from 'luxon'
import { observer } from 'mobx-react-lite'

import { useStores } from '@trivie/core'
import { Text } from '../../text'

export const MessageCell = observer((props: any) => {
  const { params } = props
  const { messageStore } = useStores()
  const { setReviewing, setEditing } = messageStore

  const isSent = (date: string | null) => {
    if (date) {
      return DateTime.fromISO(date).diffNow('milliseconds').milliseconds < 0
    }
    return false
  }

  const onClickMessage = (message: any) => {
    if (isSent(message.message_send_date)) {
      messageStore.getMessage(message.id).then(() => {
        messageStore.setSelectedMessageID(message.id)
      })
      setReviewing(true)
    } else {
      messageStore.setSelectedMessageID(message.id)
      setEditing(true)
    }
  }

  return (
    <Text
      noWrap
      variant="body2Emphasized"
      text={params.row.message_body}
      onClick={() => onClickMessage(params.row)}
      style={{ cursor: 'pointer' }}
    />
  )
})
