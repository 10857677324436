import { Instance, types, flow, getEnv, cast, destroy, getRoot } from 'mobx-state-tree'
import { Environment } from '../environment'
import { Topic } from '../Topic'
import { Question } from '../Question'
import { Quiz } from '../Quiz'
import { TopicHelper } from '../../helpers/TopicHelper'
import { QuizHelper } from '../../helpers/QuizHelper'
import { QuestionHelper } from '../../helpers/QuestionHelper'
import { ContentCreation } from '../ContentCreation'
import { ApiResponse } from 'apisauce'
import { Resource } from '../Resource'
import { ContentCreationApi } from '../../services/api-objects/ContentCreationApi'
import { WorkflowApi } from '../../services/api-objects/WorkflowApi'

const _ = require('lodash')

export const ContentCreationStoreModel = types
  .model('ContentCreationStore')
  .props({
    status: types.optional(types.enumeration(['idle', 'pending', 'done', 'error']), 'idle'),
    isPublishingContent: types.optional(types.boolean, false),
    publishedSuccessful: types.optional(types.boolean, false),
    importedCorrectQuestions: types.array(types.frozen()),
    questionsWithError: types.array(types.frozen()),
    currentQuestionIndex: types.optional(types.number, 0),
    validationStarted: types.optional(types.boolean, false),
    topic: types.maybeNull(Topic),
    topics: types.maybe(types.array(Topic)),
    quiz: types.maybeNull(Quiz),
    question: types.maybeNull(Question),
    draggingResource: types.maybeNull(Resource),
    draggedFromQuizNodeId: types.maybeNull(types.number),
    validating: types.optional(types.boolean, false),
    publishMessage: types.maybeNull(types.string),
    importingFilename: types.maybeNull(types.string),
    showFilePicker: types.frozen(),
  })
  .actions((self) => ({
    setStatus(value?: 'idle' | 'pending' | 'done' | 'error') {
      self.status = value || 'idle'
    },
    setShowFilePicker(status) {
      self.showFilePicker = status
    },
    setImportingFilename(filename: string) {
      self.importingFilename = filename
    },
    setIsPublishingContent(value: boolean) {
      self.isPublishingContent = value
    },
    setValidationStarted(value: boolean) {
      self.validationStarted = value
    },
    setPublishedSuccessful(value: boolean) {
      self.publishedSuccessful = value
    },
    setPublishMessage(publishMessage: typeof self.publishMessage) {
      self.publishMessage = publishMessage
    },
    setQuestionIndex(value: number) {
      self.currentQuestionIndex = value
    },
    setTopic(topic: Instance<typeof Topic> | null) {
      self.topic = topic
    },
    setTopics(topics) {
      self.topics = [] as any
      self.topics = topics as any
    },
    setQuiz(quiz: Instance<typeof Quiz> | null) {
      self.quiz = quiz
    },
    setQuestion(question: Instance<typeof Question> | null) {
      self.question = question
    },
    setValidating(value: boolean) {
      self.validating = value
    },
  }))
  .views((self) => ({
    get environment() {
      return getEnv(self) as Environment
    },
    get selectedTopic() {
      return self.topics?.find((topic) => Boolean(topic?.selected))
    },
    get isLoading() {
      return self.status === 'pending'
    },
    get questionsWithErrors() {
      const questionsWithErrors: Array<Instance<typeof Question>> = []
      if (self.topics) {
        ;(self.topics ?? ([] as Array<Instance<typeof Topic>>)).forEach((t) => {
          if (t.quizzes) {
            const beforeTopicCheckLength = questionsWithErrors.length
            let questionIfTopicError = null
            t.quizzes.forEach((q: Instance<typeof Quiz>) => {
              // if the quiz has errors and none of the questions do, we need to add a question
              // so the user can correct quiz level errors
              const beforeQuizCheckLength = questionsWithErrors.length
              ;(q.questions ?? ([] as Array<Instance<typeof Question>>)).forEach((question) => {
                questionIfTopicError = question as any
                if (question?.errors?.length) {
                  questionsWithErrors.push(question)
                }
              })
              if (beforeQuizCheckLength === questionsWithErrors.length && q.errors?.length) {
                const anyQuestion = q.questions?.find((question) => !!question)
                if (anyQuestion) questionsWithErrors.push(anyQuestion)
              }
            })

            if (
              t.errors?.length &&
              beforeTopicCheckLength === questionsWithErrors.length &&
              questionIfTopicError
            ) {
              questionsWithErrors.push(questionIfTopicError)
            } else if (
              t.errors?.length &&
              beforeTopicCheckLength === questionsWithErrors.length &&
              !questionIfTopicError
            ) {
              throw new Error(`Has topic error, but no question to add to make the correction`)
            }
          }
        })
      } else if (self.quiz) {
        const beforeQuizCheckLength = questionsWithErrors.length
        ;(self.quiz.questions ?? ([] as Array<Instance<typeof Question>>)).forEach((question) => {
          if (question?.errors?.length) {
            questionsWithErrors.push(question)
          }
        })
        if (beforeQuizCheckLength === questionsWithErrors.length && self.quiz.errors?.length) {
          const anyQuestion = self.quiz.questions?.find((question) => !!question)
          if (anyQuestion) questionsWithErrors.push(anyQuestion)
        }
      } else if (self.question && self.question.errors?.length) {
        questionsWithErrors.push(self.question)
      }

      return questionsWithErrors
    },
    get questionsWithWarnings() {
      const questionsWithWarnings = [] as Array<Instance<typeof Question>>

      if (self.topics) {
        ;(self.topics ?? ([] as Array<Instance<typeof Topic>>)).forEach((t) => {
          if (t.quizzes) {
            t.quizzes.forEach((q: Instance<typeof Quiz>) => {
              ;(q.questions ?? ([] as Array<Instance<typeof Question>>)).forEach((question) => {
                if (question.hasDeepWarnings) {
                  questionsWithWarnings.push(question)
                }
              })
            })
          }
        })
      } else if (self.quiz) {
        ;(self.quiz.questions ?? ([] as Array<Instance<typeof Question>>)).forEach((question) => {
          if (question.choices.some((c) => c.warnings?.length)) {
            questionsWithWarnings.push(question)
          }
        })
      } else if (self.question && self.question.choices.some((c) => c.warnings?.length)) {
        questionsWithWarnings.push(self.question)
      }

      return questionsWithWarnings
    },
    get correctableQuestions(): Array<Instance<typeof Question>> {
      const errorQs = (self as Instance<typeof ContentCreationStoreModel>).questionsWithErrors
      const warningQs = (self as Instance<typeof ContentCreationStoreModel>).questionsWithWarnings

      return Array.from(new Set<Instance<typeof Question>>([...errorQs, ...warningQs]))
    },
    get hasCorrectQuestions() {
      const correctQuestions = [] as Array<Instance<typeof Question>>
      if (self.topics?.length) {
        ;(self.topics ?? ([] as Array<Instance<typeof Topic>>)).forEach((t) => {
          ;(t.quizzes ?? ([] as Array<Instance<typeof Quiz>>)).forEach((q) => {
            ;(q.questions ?? ([] as Array<Instance<typeof Question>>)).forEach((question) => {
              if (!question.hasErrors && !question.hasWarnings) {
                correctQuestions.push(question)
              }
            })
          })
        })
      }
      // else if (self.topic) {
      //   ;(self.topic.quizzes ?? ([] as Array<Instance<typeof Quiz>>)).forEach(q => {
      //     ;(q.questions ?? ([] as Array<Instance<typeof Question>>)).forEach(question => {
      //       if (!question.hasErrors && !question.hasWarnings) {
      //         correctQuestions.push(question)
      //       }
      //     })
      //   })
      // }
      else if (self.quiz) {
        ;(self.quiz.questions ?? ([] as Array<Instance<typeof Question>>)).forEach((question) => {
          if (!question.hasErrors && !question.hasWarnings) {
            correctQuestions.push(question)
          }
        })
      } else if (self.question && !self.question.hasErrors && !self.question.hasWarnings) {
        correctQuestions.push(self.question)
      }

      return Boolean(correctQuestions.length)
    },
    get correctQuestionCount() {
      const questionsWithoutErrors = [] as Array<Instance<typeof Question>>
      if (self.topic) {
        ;(self.topic.quizzes ?? ([] as Array<Instance<typeof Quiz>>)).forEach((q) => {
          ;(q.questions ?? ([] as Array<Instance<typeof Question>>)).forEach((question) => {
            if (!question.hasErrors && !question.hasWarnings) {
              questionsWithoutErrors.push(question)
            }
          })
        })
      } else if (self.quiz) {
        ;(self.quiz.questions ?? ([] as Array<Instance<typeof Question>>)).forEach((question) => {
          if (!question.hasErrors && !question.hasWarnings) {
            questionsWithoutErrors.push(question)
          }
        })
      } else if (self.question && !self.question.hasErrors && !self.question.hasWarnings) {
        questionsWithoutErrors.push(self.question)
      }

      return questionsWithoutErrors.length
    },

    get allQuestionsCorrect() {
      if (self.topics?.[0]?.hasErrors) {
        return false
      }

      return this.correctableQuestions.every((cq) => !cq.hasErrors && !cq.hasWarnings)
    },
    get currentQuestion() {
      if (this.correctableQuestions?.length) {
        return this.correctableQuestions[self.currentQuestionIndex]
      } else {
        return null
      }
    },
  }))
  .actions((self) => ({
    resetContentStore(fromPath = false) {
      self.status = 'idle'
      self.isPublishingContent = false
      self.publishedSuccessful = false
      self.importingFilename = null
      self.importedCorrectQuestions = cast([])
      self.questionsWithError = cast([])
      self.currentQuestionIndex = 0
      self.validationStarted = false
      self.topic = null
      self.quiz = null
      self.question = null
      self.topics = cast([])
      if (fromPath) {
        return
      }
      const root = getRoot(self) as any
      const { pathStore } = root
      const { workflow, path } = pathStore
      if (workflow && !path) {
        pathStore.deleteWorkflow()
      }
      if (path) {
        pathStore.deletePath()
      }
    },
  }))
  .actions((self) => ({
    selectTopic(topic) {
      const sel = self.topics?.find((t) => Boolean(t.selected))
      if (sel) {
        sel.deselect()
      }
      topic.select()
    },
    removeTopicFromImport(topicToDelete: Instance<typeof Topic>) {
      // can only remove if we have at least 1 remaining
      if (self.topics && self.topics.length > 1) {
        if (topicToDelete.selected) {
          const unselected = (topic) => !topic.selected
          const next = _.find(self.topics, unselected, self.topics.indexOf(topicToDelete))
          const last = _.findLast(self.topics, unselected, self.topics.indexOf(topicToDelete) - 1)
          if (next) {
            this.selectTopic(next)
          } else if (last) {
            this.selectTopic(last)
          }
        }
        destroy(topicToDelete)
      }
    },
    publishObjectContent: flow(function* () {
      self.setStatus('pending')
      self.setPublishMessage('')
      self.setIsPublishingContent(true)
      self.setQuestionIndex(0)
      self.setValidating(true)
      try {
        let resp
        if (self.topics && self.topics?.length > 0) {
          const isSafeToForce = TopicHelper.isSafeToForcePublish(self.topics)
          const topicPayloads = self.topics.map((t) => t.toPayload())
          resp = yield ContentCreationApi.publishContent(
            {
              topic: topicPayloads.map((topicPayload) => {
                return {
                  ...topicPayload,
                  // keep_item_order: true,
                  quizzes: topicPayload.quizzes.map((quiz, index0: number) => {
                    return {
                      ...quiz,
                      order: index0,
                      questions: quiz.questions
                        .filter((q) => !q.removing)
                        .map((question, index1: number) => {
                          return {
                            ...question,
                            order: quiz.keep_item_order ? index1 : null,
                            choices: question.choices
                              .filter((c) => c.text || c.correct)
                              .map((choice, index2: number) => {
                                return {
                                  ...choice,
                                  factoid: question?.advanced ? choice.factoid : null,
                                  order: question.keep_answer_order ? index2 : null,
                                }
                              }),
                          }
                        }),
                    }
                  }),
                }
              }),
            },
            isSafeToForce,
          )
          // check for workflow and update object_ids
          // then publish the workflow object
          const root = getRoot(self) as any
          const { pathStore } = root
          const { workflow } = pathStore
          if (self.topics && self.topics.length > 0) {
            resp.data.topic.map((topic, index) => {
              const wf = self.topics!.length === 1 ? workflow : self.topics![index]?.workflow
              // if imported topic has a workflow added, and workflow is not empty -> create a new one
              if (self.topics!.length > 1 && wf && wf.nodes.length > 0) {
                wf.updateTopicIDs(topic.topic_id)
                WorkflowApi.createWorkflow(wf, topic.topic_id, 'Topic')
              } else if (wf && wf.nodes.length > 0) {
                // else if single topic and workflow found - update ids
                const topicID = resp.data.topic[0].topic_id
                workflow.updateTopicIDs(topicID)
                // check if editing topic / workflow
                if (self.topics![0].topic_id && self.topics![0].workflow_id) {
                  WorkflowApi.editWorkflow(workflow, self.topics![0].workflow_id)
                } else {
                  WorkflowApi.createWorkflow(workflow, topicID, 'Topic')
                }
              }
            })

            // map for each published question/quiz/topic id in the imported topic set
            // for updating images / resources
            self.topics.map((topic, index) => {
              topic.set('topic_id', resp.data.topic[index].topic_id)
              topic.quizzes?.map((quiz, index2) => {
                quiz.set('quiz_id', resp.data.topic[index].quizzes[index2].quiz_id)
                quiz.questions
                  ?.filter((q) => !q.removing)
                  .map((question, index3) => {
                    question.set(
                      'question_id',
                      resp.data.topic[index].quizzes[index2].questions[index3].question_id,
                    )
                  })
              })
            })
          }

          self.setValidating(false)
        } else if (self.quiz) {
          const isSafeToForce = QuizHelper.isSafeToForcePublish(self.quiz)

          const payload = {
            quiz: [
              {
                ...self.quiz.toPayload(),
                questions: self.quiz?.questions
                  ?.filter((q) => !q.removing)
                  .map((question, index1: number) => {
                    const p = {
                      ...question,
                      generated_question_id: question.generated_question_id,
                      order: self.quiz?.keep_item_order ? index1 : null,
                      choices: question.choices
                        .filter((c) => c.text || c.correct)
                        .map((choice, index2: number) => {
                          return {
                            ...choice,
                            factoid: question?.advanced ? choice.factoid : null,
                            order: question.keep_answer_order ? index2 : null,
                          }
                        }),
                    }
                    return p
                  }),
              },
            ],
          }
          resp = yield ContentCreationApi.publishContent(payload, isSafeToForce)
          self.quiz.set('quiz_id', resp.data.quiz[0].quiz_id)
          self.quiz?.questions
            ?.filter((q) => !q.removing)
            .map((q, index) => {
              q.set('question_id', resp.data.quiz[0].questions[index].question_id)
            })
          self.setValidating(false)
        } else if (self.question) {
          const isSafeToForce = QuestionHelper.isSafeToForcePublish(self.question)
          resp = yield ContentCreationApi.publishContent(
            {
              question: [
                {
                  ...self.question.toPayload(),
                  choices: self.question?.choices
                    .filter((c) => c.text || c.correct)
                    .map((choice, index: number) => {
                      return {
                        ...choice,
                        factoid: self.question?.advanced ? choice.factoid : null,
                        order: self.question?.keep_answer_order ? index : null,
                      }
                    }),
                },
              ],
            },
            isSafeToForce,
          )
          self.question.set('question_id', resp.data.question[0].question_id)
          self.setValidating(false)
        }

        ;(self as Instance<typeof ContentCreationStoreModel>).publishResources()
        ;(self as Instance<typeof ContentCreationStoreModel>).publishImages()
        self.setIsPublishingContent(false)
        self.setPublishedSuccessful(true)
        return resp.data
      } catch (e) {
        if (e instanceof Error) {
          self.setPublishMessage(e.message || 'Unexpected error ocurred') // or leave default message empty, so dialog will not show up
        }
        self.setStatus('error')
        self.setIsPublishingContent(false)
      }
    }),
    publishImages: flow(function* () {
      if (self.topics && self.topics?.length > 0) {
        yield TopicHelper.publishImages(self.topics)
      } else if (self.quiz) {
        yield QuizHelper.publishImages(self.quiz)
      } else if (self.question?.question_id) {
        yield QuestionHelper.publishImage(self.question)
      } else {
        throw new Error(`Trying to publish images, but couldn't find content object`)
      }
    }),
    publishResources: flow(function* () {
      if (self.topics && self.topics?.length > 0) {
        yield TopicHelper.publishResources(self.topics)
      } else if (self.quiz) {
        yield QuizHelper.publishResources(self.quiz)
      }
    }),
    validateContent: flow(function* (topics?) {
      if (topics) {
        try {
          const topicPayloads = topics.map((t) => t.toPayload())

          const validateResponse: ApiResponse<Instance<typeof ContentCreation>> =
            yield ContentCreationApi.validateContent({
              topic: topicPayloads.map((topicPayload) => {
                return {
                  ...topicPayload,
                  quizzes: topicPayload.quizzes.map((quiz) => {
                    return {
                      ...quiz,
                      questions: quiz.questions.map((question, index: number) => {
                        return {
                          ...question,
                          order: quiz.keep_item_order ? index : null,
                          choices: question.choices.map((choice, index2: number) => {
                            return {
                              ...choice,
                              order: question.keep_answer_order ? index2 : null,
                            }
                          }),
                        }
                      }),
                    }
                  }),
                }
              }),
            })
          topics.map((t) => {
            t?.quizzes?.forEach((quiz) => {
              quiz?.questions?.forEach((question) => {
                if (question?.allErrors?.length === 0) {
                  question.set('approved', true)
                }
              })
            })
          })
          self.setValidating(false)
          return validateResponse
        } catch (e) {
          console.error(e)
          throw e
        }
      } else if (self.topic) {
        yield TopicHelper.validate(self.topic)
        self.setValidating(false)
      } else if (self.quiz) {
        yield QuizHelper.validate(self.quiz)
        self.setValidating(false)
      } else if (self.question) {
        yield QuestionHelper.validate(self.question)
        self.setValidating(false)
      } else {
        throw new Error(`Can't publish type that isn't a topic, quiz, or topic`)
      }
    }),
  }))

export interface ContentCreationStoreModelType
  extends Instance<typeof ContentCreationStoreModel.Type> {}
