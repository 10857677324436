import React, { useEffect, useRef, useState } from 'react'
import swap from 'lodash-move'
import { useSprings, config, animated } from '@react-spring/web'
import { useDrag } from 'react-use-gesture'
import { observer } from 'mobx-react-lite'

import { useStores } from '@trivie/core'
import { isAction, isCondition } from '@trivie/core/src/type-guards/isAction'
import { Step } from '../../content-creation/components/step-item'
import { InsertStepButton } from './insert-step-btn'

const clamp = require('lodash/clamp')

const fn = (open, order: number[], active = false, originalIndex = 0, curIndex = 0, y = 0) => {
  return (index: number) => {
    return active && index === originalIndex
      ? {
          y: curIndex * 134.52 + y,
          scale: 1.1,
          zIndex: 1,
          shadow: 18,
          immediate: (key: string) => key === 'y' || key === 'zIndex',
          config: (key: string) => (key === 'y' ? config.gentle : config.default),
        }
      : {
          y: order.indexOf(index) * 134.52,
          scale: 1,
          zIndex: 0,
          shadow: 0,
          immediate: !active && !open ? true : false,
        }
  }
}

export const DraggableList = observer(
  ({ topic, editing, workflow, readonly, handleAddStep }: any) => {
    const { toastStore } = useStores()
    const [items, setItems] = useState(workflow?.flat?.length ? workflow?.flat : [])
    const [dragging, setDragging] = useState(false)

    useEffect(() => {
      // If the steps list length changes (insert/removal),
      // then update the local items array and the order indicies ref
      if (workflow?.flat?.length) {
        setItems(workflow?.flat)
        order.current = workflow?.flat?.map((_, index) => index)
      }
    }, [
      workflow?.flat?.length,
      JSON.stringify(items),
      JSON.stringify(workflow?.flat),
      workflow?.stub,
    ])

    // Store indicies as a local ref, this represents the item order
    const order = useRef(items?.map((_, index) => index))

    // Create springs, each corresponds to an item, controlling its transform, scale, etc.
    const [springs, api] = useSprings(items?.length, fn(toastStore.open, order.current), [
      JSON.stringify(workflow?.flat),
      JSON.stringify(items),
      items?.length,
      toastStore?.open,
      dragging,
    ])

    useEffect(() => {
      if (!dragging) {
        // Finished dragging, no animations active, reorder the workflow json
        workflow?.reorder(order.current.map((newIdx) => items[newIdx]))
      }
    }, [dragging])

    const bind = useDrag((state: any) => {
      const {
        event,
        args: [originalIndex],
        active,
        movement: [, y],
        dragging,
      } = state

      // Prevent dragging on svg or button elements or if readonly, inserting, or editing
      if (
        workflow?.stub ||
        topic ||
        readonly ||
        editing ||
        event.target.tagName === 'path' ||
        event.target.tagName === 'svg' ||
        event.target.tagName === 'BUTTON' ||
        toastStore?.open
      ) {
        return
      }

      setDragging(dragging)

      const curIndex = order.current.indexOf(originalIndex)
      const curRow = clamp(Math.round((curIndex * 100 + y * 0.8) / 100), 0, items.length - 1)
      const newOrder = swap(order.current, curIndex, curRow)

      api.start(fn(toastStore.open, newOrder, active, originalIndex, curIndex, y))

      if (active) {
        // do something while dragging an item?
      } else {
        if (isAction(items[newOrder[0]])) {
          toastStore?.show({
            message: 'An action cannot be the first step in a path.',
            severity: 'error',
            icon: false,
            position: 'top',
            autoDismiss: 3000,
          })
        } else {
          order.current = newOrder
          toastStore?.dismiss()
          toastStore?.clear()
        }
      }
    })

    // If readonly (detail view, return a normal list of steps with no actions available)
    if (topic || readonly) {
      return items.map((item, i) =>
        topic && i === 0 ? null : (
          <Step
            readonly={readonly}
            handleAddStep={() => {}}
            root={i === 0}
            step={item}
            node={
              isCondition(item)
                ? workflow?.nodes.find((n) =>
                    n.conditions.find((c) => c.criteria.includes(item.reference_id)),
                  )
                : workflow?.nodes.find((n) => n.actions.includes(item.reference_id))
            }
            index={i}
            style={{ marginBottom: 16 }}
          />
        ),
      )
    }
    return (
      <div style={{ height: items.length * 134.52 }}>
        {springs.map(({ zIndex, y, scale, shadow }, i) => {
          return (
            <animated.div
              {...bind(i)}
              key={i}
              style={{
                boxShadow: shadow.to((s) => `rgba(0, 0, 0, 0.15) 0px ${s}px ${2 * s}px 0px`),
                borderRadius: 14,
                position: 'absolute',
                userSelect: 'none',
                width: '100%',
                cursor:
                  workflow?.stub || editing || readonly
                    ? 'default'
                    : dragging
                      ? 'grabbing'
                      : 'grab',
                zIndex,
                y,
                scale,
              }}
              className="PathStep"
            >
              <Step
                readonly={readonly}
                handleAddStep={() => {}}
                root={i === 0}
                step={items[i]}
                node={
                  isCondition(items[i])
                    ? workflow?.nodes.find((n) =>
                        n.conditions.find((c) => c.criteria.includes(items[i].reference_id)),
                      )
                    : workflow?.nodes.find((n) => n.actions.includes(items[i].reference_id))
                }
                index={i}
              />
              {!dragging && i !== items.length - 1 ? (
                <InsertStepButton
                  workflow={workflow}
                  readonly={readonly}
                  step={items[i]}
                  disabled={workflow?.stub || toastStore?.open}
                  node={
                    isCondition(items[i])
                      ? workflow?.nodes.find((n) =>
                          n.conditions.find((c) => c.criteria.includes(items[i].reference_id)),
                        )
                      : workflow?.nodes.find((n) => n.actions.includes(items[i].reference_id))
                  }
                  index={i}
                  handleAddStep={() => {
                    workflow?.reorder(order.current.map((newIdx) => items[newIdx]))
                    handleAddStep()
                  }}
                />
              ) : null}
            </animated.div>
          )
        })}
      </div>
    )
  },
)
