import { ApiResponse, ApisauceInstance } from 'apisauce'
import { Instance } from 'mobx-state-tree'
import { QuizContentCreatePayload } from '../../models/QuizContentCreatePayload'
import { Resource } from '../../models/Resource'
import {
  GetQuiz,
  GetQuizOverview,
  GetQuizQuestionKnowledgeGap,
  GetQuizUserStat,
  GetFullQuiz,
} from '../../models/response-models/quiz'
import { GetQuizResource } from '../../models/response-models/resource'
import { handleResponse } from '../handleResponse'
import { MedalStats } from '../../models/MedalStats'
import { GetQuizAssociatedTopic } from '../../models/response-models'

export const QuizApi = {
  apisauce: {} as ApisauceInstance,
  getAllQuizzes: async (
    type: 'users' | 'topics' | 'quizzes' | 'groups' | 'tags',
    meta?: boolean,
    columns?: any,
    view_name?: string,
    id?: number,
  ) => {
    const payload = { view_name }

    if (columns && columns.length > 0) {
      payload['columns'] = columns
    }

    const response: ApiResponse<any> = await QuizApi.apisauce.post(
      Boolean(id)
        ? `/${type}/${id}/quizzes/?meta=${meta}&asynchronous=${false}`
        : `/${type}/?meta=${meta}&asynchronous=${false}`,
      payload,
    )

    return handleResponse(response)
  },
  getQuizzes: async () => {
    const response: ApiResponse<Array<Instance<typeof GetQuiz>>> =
      await QuizApi.apisauce.get(`/quizzes/`)

    return handleResponse(response)
  },
  getSharedQuiz: async (code: string) => {
    const response: ApiResponse<Array<Instance<typeof GetQuiz>>> = await QuizApi.apisauce.get(
      `/share/${code}/`,
    )
    return handleResponse(response)
  },

  publishImage: async (id: number, photo: File) => {
    const formData = new FormData()
    formData.append('photo', photo)

    const response: ApiResponse<null> = await QuizApi.apisauce.patch(
      `quizzes/${id}/photo/`,
      formData,
    )

    return handleResponse(response)
  },

  getResults: async (
    type: 'users' | 'topics' | 'quizzes' | 'groups' | 'tags',
    meta?: boolean,
    columns?: any,
    view_name?: string,
    id?: number,
    view_id?: number,
  ) => {
    const payload = { view_name }
    if (columns && columns.length > 0) {
      payload['columns'] = columns
    }

    const response: ApiResponse<any> = await QuizApi.apisauce.post(
      `/quizzes/${id}/results/?meta=${meta}&asynchronous=${false}`,
      payload,
    )

    return handleResponse(response)
  },
  exportQuizzes: async (ids: number[]) => {
    const response: ApiResponse<Array<Instance<typeof GetQuiz>>> = await QuizApi.apisauce.post(
      `/export/quizzes/`,
      {
        file_format: 'xlsx',
        version: 1,
        quiz_ids: ids,
      },
    )

    return handleResponse(response)
  },
  getQuiz: async (id: number) => {
    const response: ApiResponse<{
      quiz: Array<Instance<typeof QuizContentCreatePayload>>
    }> = await QuizApi.apisauce.get(`/content_creation/quiz/${id}/`)

    return handleResponse(response)
  },
  getResources: async (meta?: boolean, columns?: any, view_name?: string, id?: number) => {
    const payload = { view_name }
    if (columns && columns.length > 0) {
      payload['columns'] = columns
    }

    const response: ApiResponse<any> = await QuizApi.apisauce.post(
      `/quizzes/${id}/resources/?meta=${meta}&asynchronous=${false}`,
      payload,
    )

    return handleResponse(response)
  },
  addResources: async (id: number, resources: Array<Instance<typeof Resource>>) => {
    const response: ApiResponse<any> = await QuizApi.apisauce.post(
      `/quizzes/${id}/add_resources/`,
      {
        resources: resources.map((r) => ({
          ...r,
          is_required: r.is_required,
          is_recommended: false,
        })),
      },
    )

    return handleResponse(response)
  },
  shareQuiz: async (id: number, emails: string[], user_ids: number[]) => {
    const response: ApiResponse<any> = await QuizApi.apisauce.post(`/quizzes/${id}/share/`, {
      emails,
      user_ids,
    })

    return handleResponse(response)
  },
  getShareQuizLink: async (id: number) => {
    const response: ApiResponse<any> = await QuizApi.apisauce.get(`/quizzes/${id}/share/`)

    return handleResponse(response)
  },
  toggleQuizStatus: async (id: number, status) => {
    const response: ApiResponse<any> = await QuizApi.apisauce.post(`/quizzes/${id}/toggle/`, {
      status,
    })

    return handleResponse(response)
  },
  removeResources: async (id: number, resources: Array<number>) => {
    const response: ApiResponse<any> = await QuizApi.apisauce.delete(
      `/quizzes/${id}/resources/`,
      {},
      {
        data: {
          resources,
        },
      },
    )

    return handleResponse(response)
  },
  getQuizOverview: async (id: number, startDatetime: string, endingDatetime: string) => {
    const urlQuery = new URLSearchParams({
      start_datetime: startDatetime,
      end_datetime: endingDatetime,
    })

    const response: ApiResponse<Instance<typeof GetQuizOverview>> = await QuizApi.apisauce.get(
      `/quizzes/${id}/?${urlQuery.toString()}`,
    )

    return handleResponse(response)
  },
  getQuizQuestionKnowledgeGap: async (
    id: number,
    startDatetime: string,
    endingDatetime: string,
  ) => {
    const urlQuery = new URLSearchParams({
      start_datetime: startDatetime,
      end_datetime: endingDatetime,
    })

    const response: ApiResponse<Instance<typeof GetQuizQuestionKnowledgeGap>> =
      await QuizApi.apisauce.get(`/quizzes/${id}/question_gaps/?${urlQuery.toString()}`)

    return handleResponse(response)
  },
  getRelatedTopicsForQuiz: async (id: number) => {
    const response: ApiResponse<Array<Instance<typeof GetQuizAssociatedTopic>>> =
      await QuizApi.apisauce.get(`/quizzes/${id}/topics/`)

    return handleResponse(response)
  },
  getUsersForQuiz: async (id: number) => {
    const response: ApiResponse<Array<Instance<typeof GetQuizUserStat>>> =
      await QuizApi.apisauce.get(`/quizzes/${id}/users/`)

    return handleResponse(response)
  },
  getResourcesForQuiz: async (id: number) => {
    const response: ApiResponse<Array<Instance<typeof GetQuizResource>>> =
      await QuizApi.apisauce.get(`/quizzes/${id}/resources/`)
    return handleResponse(response)
  },
  getFullQuiz: async (id: number) => {
    const response: ApiResponse<Instance<typeof GetFullQuiz>> = await QuizApi.apisauce.get(
      `/quizzes/${id}/full/`,
    )
    return handleResponse(response)
  },
  checkDeleteQuizzes: async (ids: Array<number>) => {
    const response: ApiResponse<any> = await QuizApi.apisauce.post(`/quizzes/delete/check/`, {
      quiz_ids: ids,
    })

    return handleResponse(response)
  },
  getMedalStats: async (quiz_id: number) => {
    const response: ApiResponse<Array<Instance<typeof MedalStats>>> = await QuizApi.apisauce.get(
      `/quizzes/${quiz_id}/medals/ `,
    )
    return handleResponse(response)
  },
  generateTitle: async (questions: any[]) => {
    const response: ApiResponse<any> = await QuizApi.apisauce.post(
      `/generate/suggestion/quiz_name_and_summary/`,
      { questions },
    )
    return handleResponse(response)
  },
}
