import React, { useEffect, useState } from 'react'
import { observer } from 'mobx-react-lite'
import {
  IconButton,
  MenuItem,
  Select,
  InputLabel,
  TextField,
  capitalize,
  FormControl,
  CircularProgress,
} from '@mui/material'
import Typewriter from 'typewriter-effect/dist/core'
import { EditorContent, useEditor } from '@tiptap/react'
import StarterKit from '@tiptap/starter-kit'
import MuiDrawer from '@mui/material/Drawer'
import styled from '@emotion/styled'

import { BLOCKED_FILE_EXTENSIONS, useInterval, useStores } from '@trivie/core'
import { color } from '@trivie/ui-web/theme/color'
import { Text } from '@trivie/ui-web/components/text'
import { Button } from '@trivie/ui-web/components/button'

import { Logo } from '../login/logo'
import { Chat } from './chat/chat'
import { UploadResourceTab } from '../content/content-generation/quiz-generation/tabs/upload-resource-tab'
import { WebResourceTab } from '../content/content-generation/quiz-generation/tabs/web-resource-tab'
import { SignupModal } from './signup-modal'

import { Avatar } from '@trivie/ui-web/components/avatar'
import { ResourceApi } from '@trivie/core/src/services/api-objects'
import { Close } from '@mui/icons-material'
import { ResourcePanel } from '../content/content-generation/quiz-generation/resource-panel'
import { Controller, useForm } from 'react-hook-form'
import { useHistory, useRouteMatch } from 'react-router-dom'
import { FileInput } from '@trivie/ui-web/components/file-input'
import UnsplashImageDialog from '../content/content-creation/unsplash-image-dialog'
import { ImageSelector } from '@trivie/ui-web/components/image-selector'
import { Sources } from './components/source-list'
import { QuizHelper } from '@trivie/core/src/helpers/QuizHelper'
import { QuestionHelper } from '@trivie/core/src/helpers/QuestionHelper'

const Keywords = observer((props: any) => {
  const { generateQuestions } = props
  const { contentCreationStore, chatStore } = useStores()
  const { quiz } = contentCreationStore
  const [loading, setLoading] = useState(true)
  const [confirmed, setConfirmed] = useState(false)
  const [keywords, setKeywords] = useState<any>([])

  const [selected, setSelected] = useState<any>([])

  useEffect(() => {
    setLoading(true)
    ResourceApi.getKeywords(quiz!.mercuryResource!.resource_id).then((resp: any) => {
      setLoading(false)
      setKeywords(resp.data.keywords.slice(0, 10))
    })
  }, [])

  const toggle = (keyword: string) => {
    if (selected.includes(keyword)) {
      setSelected(selected.filter((k) => k !== keyword))
    } else {
      setSelected([...selected, keyword])
    }
  }

  return confirmed ? null : (
    <>
      <div style={PANEL}>
        <Text
          text="Keywords"
          style={{ marginBottom: 24, textAlign: 'center' }}
          variant="labelEmphasized"
        />
        {loading ? (
          <CircularProgress style={{ alignSelf: 'center' }} />
        ) : (
          <div style={{ display: 'flex', gap: 10, flexWrap: 'wrap' }}>
            {keywords?.map((k: any) => (
              <div
                onClick={() => toggle(k.text)}
                style={{
                  ...KEYWORD,
                  cursor: 'pointer',
                  backgroundColor: selected.includes(k.text) ? '#1B59F820' : color.white,
                }}
              >
                <Text
                  text={k.text}
                  variant="body1Medium"
                  style={{ color: color.shade70, marginRight: 4 }}
                />
              </div>
            ))}
          </div>
        )}
      </div>
      <div style={{ flexDirection: 'row', display: 'flex', justifyContent: 'flex-end' }}>
        <Button
          onClick={() => {
            setConfirmed(true)
            quiz?.set('keywords', selected)
            quiz?.set('title', chatStore?.subject)
            setTimeout(() => {
              chatStore.sendMessage(
                'trivie',
                'Perfect. Last but not least, you can customize the cover image, style, and audience of your quiz.',
              )
              setTimeout(() => {
                chatStore.sendMessage(
                  'trivie',
                  'You can change these settings later in the Quiz Settings menu.',
                )
                setTimeout(() => {
                  chatStore.sendMessage(
                    'user',
                    '',
                    [],
                    false,
                    <CustomizeQuiz generateQuestions={generateQuestions} />,
                  )
                }, 600)
              }, 600)
            }, 600)
          }}
          text="Continue"
          variant="outlined"
        />
      </div>
    </>
  )
})

const CustomizeQuiz = observer((props: any) => {
  const { generateQuestions } = props
  const history = useHistory()
  const { contentCreationStore } = useStores()
  const { quiz, resetContentStore } = contentCreationStore
  const [showUnsplashDialog, setShowUnsplashDialog] = useState(false)

  return (
    <div style={PANEL}>
      <Text
        text="Customize your Quiz?"
        style={{ marginBottom: 24, textAlign: 'center' }}
        variant="labelEmphasized"
      />
      <div style={{ flexDirection: 'row', gap: 20, display: 'flex' }}>
        <ImageSelector
          fullWidth
          pickerStyle={{ height: 120, marginBottom: 0 }}
          compact
          minimal
          style={{ width: '100%' }}
          imageStyle={{ width: '100%', maxWidth: '100%', height: 120, alignItems: '' }}
          imageContainerStyle={{ marginBottom: 0 }}
          onUnsplashClick={() => setShowUnsplashDialog(true)}
          imageSrc={quiz?.image}
          file={quiz?.imageFile}
          onChange={(f: File | null) => quiz?.setImage(f)}
        />
        <div
          style={{
            flexDirection: 'column',
            width: '100%',
            display: 'flex',
            justifyContent: 'space-around',
          }}
        >
          <TextField
            onChange={(e) => quiz?.settings?.setVoice(e.target.value)}
            variant="standard"
            placeholder="Written in the style of..."
          />
          <TextField
            onChange={(e) => quiz?.settings?.setAudience(e.target.value)}
            variant="standard"
            placeholder="For an audience of..."
          />
        </div>
      </div>
      <div
        style={{
          marginTop: 24,
          gap: 12,
          flexDirection: 'row',
          display: 'flex',
          justifyContent: 'flex-end',
        }}
      >
        <Button
          onClick={() => {
            generateQuestions()
            quiz?.settings?.setAudience('')
            quiz?.settings?.setVoice('')
            quiz?.setImage(null)
            quiz?.set('showSettings', false)
            localStorage.removeItem('skip-tutorial')
            history.push(
              '/content/creation/quiz/new/edit/?generate=true&create=true&onboarding=true',
            )
          }}
          variant="outlined"
          text="Skip"
          color="info"
        />
        <Button
          onClick={() => {
            generateQuestions()
            quiz?.set('showSettings', false)
            localStorage.removeItem('skip-tutorial')
            history.push(
              '/content/creation/quiz/new/edit/?generate=true&create=true&onboarding=true',
            )
          }}
          text="Continue"
          variant="outlined"
        />
      </div>
      {showUnsplashDialog && (
        <UnsplashImageDialog
          onChangeImage={(f: File | null) => quiz?.setImage(f)}
          handleImageDialogClose={() => setShowUnsplashDialog(false)}
          open={showUnsplashDialog}
        />
      )}
    </div>
  )
})

const OrganizationInfo = observer((props) => {
  const [organizationURL, setOrganizationURL] = useState('')
  const [organizationName, setOrganizationName] = useState('')
  const { contentCreationStore, manageUserStore, chatStore } = useStores()
  const { quiz } = contentCreationStore
  const { saveSettings, getSettings, submitCompanyLogo } = manageUserStore
  const { control } = useForm({ shouldUnregister: false })
  const [errorMessage, setErrorMessage] = useState<string | undefined>()
  const [size, setSize] = useState<string | undefined>()
  const [industry, setIndustry] = useState<string | undefined>()
  const [confirmed, setConfirmed] = useState(false)

  const [companyLogo, setCompanyLogo] = useState<any>()

  const handleCompanyLogo = () => {
    if (companyLogo) {
      submitCompanyLogo(companyLogo).then(() => {
        getSettings()
      })
    }
  }

  return confirmed ? null : (
    <div style={PANEL}>
      <Text
        text="Tell us about your organization"
        style={{ marginBottom: 32, color: color.shade70, textAlign: 'center' }}
        variant="labelEmphasized"
      />
      <div style={{ flexDirection: 'row', display: 'flex', width: '100%' }}>
        <div style={{ flex: 1, marginRight: 40, flexDirection: 'column', display: 'flex' }}>
          <TextField
            fullWidth
            inputProps={{ inputMode: 'email' }}
            onChange={(e) => setOrganizationName(e.target.value)}
            variant="standard"
            style={{ marginBottom: 28 }}
            size="small"
            placeholder="Organization Name (Required)"
            helperText="We'll use this to let your users know who their quizzes are coming from."
          />
          <TextField
            fullWidth
            inputProps={{ inputMode: 'email' }}
            onChange={(e) => setOrganizationURL(e.target.value)}
            variant="standard"
            style={{ marginBottom: 32 }}
            size="small"
            placeholder="Organization URL"
          />
          <FormControl variant="standard">
            <InputLabel id="org-size">Size</InputLabel>
            <Select
              variant="standard"
              labelId="org-size"
              id="select-org-size"
              style={{ marginBottom: 32 }}
              value={size}
              label="Size"
              onChange={(e) => setSize(e.target.value)}
            >
              <MenuItem value={'1-5'}>1-5</MenuItem>
              <MenuItem value={'6-25'}>6-25</MenuItem>
              <MenuItem value={'26-50'}>26-50</MenuItem>
              <MenuItem value={'51-250'}>51-250</MenuItem>
              <MenuItem value={'251-500'}>251-500</MenuItem>
              <MenuItem value={'500+'}>500+</MenuItem>
            </Select>
          </FormControl>
          <FormControl variant="standard">
            <InputLabel id="industry">Industry</InputLabel>
            <Select
              variant="standard"
              labelId="industry"
              id="select-industry"
              value={industry}
              label="Industry"
              onChange={(e) => setIndustry(e.target.value)}
            >
              <MenuItem value={'Information Technology (IT)'}>Information Technology (IT)</MenuItem>
              <MenuItem value={'Human Resources'}>Human Resources</MenuItem>
              <MenuItem value={'Food Services'}>Food Services</MenuItem>
              <MenuItem value={'Education'}>Education</MenuItem>
              <MenuItem value={'Sales'}>Sales</MenuItem>
              <MenuItem value={'Finance'}>Finance</MenuItem>
              <MenuItem value={'Logistics'}>Logistics</MenuItem>
              <MenuItem value={'Other'}>Other</MenuItem>
            </Select>
          </FormControl>
        </div>
        <div
          style={{
            flexDirection: 'column',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <Controller
            control={control}
            render={({ value }) => (
              <FileInput
                imgUpload
                accept="image/png;image/jpeg;image/gif"
                file={companyLogo ?? null}
                helperText="It works for JPG, PNG, GIF - up to 5 MB"
                onChange={(file: any) => {
                  setCompanyLogo(file)
                }}
              />
            )}
            name="photo"
            defaultValue=""
          />
        </div>
      </div>
      <div
        style={{ marginTop: 24, flexDirection: 'row', display: 'flex', justifyContent: 'flex-end' }}
      >
        <Button
          disabled={!organizationName}
          onClick={() => {
            saveSettings({
              organization_industry: industry,
              organization_size: size,
              organization_url: organizationURL,
              company_name: organizationName,
            }).then(() => {
              handleCompanyLogo()
            })
            chatStore.sendMessage('trivie', '', [], false, <div />)
            chatStore.sendMessage('user', 'Continue', [], false)
            setConfirmed(true)
            quiz?.set('infoConfirmed', true)
            // update
            chatStore.sendMessage(
              'trivie',
              "Terrific. I'll use this info to set up your workspace.",
            )
            setTimeout(() => {
              if (quiz?.loadingDocument) {
                chatStore.sendMessage(
                  'trivie',
                  'Still working on your source. This could take a minute...',
                )
              }
            }, 800)
          }}
          variant="outlined"
          text="Continue"
        />
      </div>
    </div>
  )
})

const UserInfo = observer((props) => {
  const [firstName, setFirstName] = useState('')
  const [lastName, setLastName] = useState('')
  const { manageUserStore, chatStore } = useStores()
  const { userProfile, saveSettings, updateUser, uploadProfilePhoto } = manageUserStore
  const { control } = useForm({ shouldUnregister: false })
  const [errorMessage, setErrorMessage] = useState<string | undefined>()
  const [role, setRole] = useState<string | undefined>()
  const [confirmed, setConfirmed] = useState(false)
  return confirmed ? null : (
    <div
      style={{
        flexDirection: 'column',
        display: 'flex',
        paddingTop: 20,
        paddingBottom: 20,
        paddingLeft: 40,
        paddingRight: 40,
        borderRadius: 12,
        backgroundColor: '#fff',
        marginTop: 32,
        marginBottom: 32,
        border: `1px solid ${color.shade30}`,
        width: '100%',
      }}
    >
      <Text
        text="Tell us about yourself"
        style={{ marginBottom: 24, textAlign: 'center' }}
        variant="labelEmphasized"
      />
      <div style={{ flexDirection: 'row', display: 'flex', width: '100%' }}>
        <div style={{ flex: 1, marginRight: 40, flexDirection: 'column', display: 'flex' }}>
          <TextField
            fullWidth
            inputProps={{ inputMode: 'email' }}
            onChange={(e) => setFirstName(e.target.value)}
            variant="standard"
            style={{ marginBottom: 36 }}
            size="small"
            placeholder="First Name (Required)"
          />
          <TextField
            fullWidth
            inputProps={{ inputMode: 'email' }}
            onChange={(e) => setLastName(e.target.value)}
            variant="standard"
            style={{ marginBottom: 24 }}
            size="small"
            placeholder="Last Name (Required)"
          />
          <FormControl variant="standard">
            <InputLabel id="role">Role</InputLabel>
            <Select
              variant="standard"
              labelId="role"
              id="select-role"
              value={role}
              label="Role"
              onChange={(e) => setRole(e.target.value)}
            >
              <MenuItem value={'Teacher'}>Teacher</MenuItem>
              <MenuItem value={'Executive (e.g. VP or C-Suite)'}>
                Executive (e.g. VP or C-Suite)
              </MenuItem>
              <MenuItem value={'Team Member'}>Team Member</MenuItem>
              <MenuItem value={'Freelancer'}>Freelancer</MenuItem>
              <MenuItem value={'Business Owner'}>Business Owner</MenuItem>
              <MenuItem value={'Student'}>Student</MenuItem>
              <MenuItem value={'Other'}>Other</MenuItem>
            </Select>
          </FormControl>
        </div>
        <div style={{ flexDirection: 'column', display: 'flex', alignItems: 'center' }}>
          {manageUserStore?.isLoading ? (
            <CircularProgress style={{ alignSelf: 'center' }} />
          ) : (
            <Controller
              control={control}
              render={({ value }) => (
                <Avatar
                  style={{ marginBottom: 10 }}
                  size={90}
                  name={`${firstName} ${lastName}`}
                  url={value ? URL.createObjectURL(value) : userProfile?.photo_url}
                  onChange={(file: File) => {
                    if (file) {
                      if (BLOCKED_FILE_EXTENSIONS.includes(file.type)) {
                        let targetStr = file.type
                        if (targetStr.includes('/')) {
                          targetStr = targetStr.split('/')[1]
                          setErrorMessage(`Sorry, but we don't support ${targetStr} images`)
                        } else {
                          setErrorMessage(`Sorry, but we don't support ${file.type} files`)
                        }
                      } else {
                        const formData = new FormData()
                        formData.append('photo', file)
                        uploadProfilePhoto(formData, userProfile!.id)
                      }
                    }
                  }}
                />
              )}
              name="photo"
              defaultValue=""
            />
          )}
        </div>
      </div>
      <div
        style={{ marginTop: 24, flexDirection: 'row', display: 'flex', justifyContent: 'flex-end' }}
      >
        <Button
          disabled={!firstName || !lastName}
          onClick={() => {
            setConfirmed(true)
            chatStore.sendMessage('user', `My name is ${firstName} ${lastName}`, [], false)
            saveSettings({ owner_role: role })
            updateUser({ first_name: firstName, last_name: lastName })
            setTimeout(() => {
              chatStore.sendMessage('trivie', `Thanks, ${firstName}! Welcome to Trivie.`)
              setTimeout(() => {
                chatStore.sendMessage(
                  'trivie',
                  'Now, help me set up your Trivie experience by telling us a bit about your organization.',
                )
                setTimeout(() => {
                  chatStore.sendMessage('trivie', '', [], false, <OrganizationInfo />)
                }, 2000)
              }, 800)
            }, 800)
          }}
          style={{ marginRight: 0 }}
          text="Continue"
          variant="outlined"
        />
      </div>
    </div>
  )
})

export const OnboardingScreen: React.FunctionComponent = observer(() => {
  const { chatStore, uiStore, manageUserStore, resourceStore, contentCreationStore } = useStores()
  const { quantum } = uiStore
  const { quiz, resetContentStore, setQuiz, showFilePicker, setShowFilePicker } =
    contentCreationStore
  const { messages, sendMessage, sources, setSources } = chatStore
  const { userProfile, settings, setCreatingWorkspace, creatingWorkspace } = manageUserStore
  const { getAvailableResources, uploadTextResource, addResource } = resourceStore

  const [hoverCreateQuiz, setHoverCreateQuiz] = useState(false)
  const [placeholderIdx, setPlaceholderIdx] = useState(0)
  const [selectedSubject, setSelectedSubject] = useState(false)
  const [showSignup, setShowSignup] = useState(false)
  const [showURL, setShowURL] = useState(false)
  const [showEditor, setShowEditor] = useState(false)

  const fromGenerate = useRouteMatch<any>('/generate')

  const history = useHistory()

  const FONT_SIZE = 11
  const DEFAULT_INPUT_WIDTH = 200
  const [inputWidth, setInputWidth] = useState(DEFAULT_INPUT_WIDTH)
  useEffect(() => {
    if (chatStore?.subject && chatStore.subject.length * FONT_SIZE > DEFAULT_INPUT_WIDTH) {
      setInputWidth((chatStore?.subject.length + 1) * FONT_SIZE)
    } else {
      setInputWidth(DEFAULT_INPUT_WIDTH)
    }
  }, [chatStore.subject])

  const generateQuestions = () => {
    if (quiz && quiz.settings) {
      quiz.setGeneratingQuestions(true)
      quiz.set('stopGenerating', false)
      quiz?.set('showQuizPanel', true)

      if (!quiz?.settings.questionCount || quiz?.settings.questionCount === 0) {
        quiz?.settings!.setQuestionCount(10)
      }

      quiz?.generateQuestions(quiz?.mercuryResource!.resource_id, 10).then(() => {
        quiz?.selectQuestion(quiz?.questions![0])
      })
      quiz?.set('showSettings', false)
    }
  }

  const [errorShown, setErrorShown] = useState(false)
  const [documentReady, setDocumentReady] = useState(false)
  useEffect(() => {
    if (quiz?.mercuryResource && !quiz?.loadingDocument && quiz?.infoConfirmed && !documentReady) {
      setDocumentReady(true)
      chatStore.sendMessage(
        'trivie',
        'Your document is ready for generation. How do you want to pick the most important parts of your source? This will determine what your quiz focuses on.',
      )
      setTimeout(() => {
        sendMessage('user', '', [
          {
            label: 'Do It For Me',
            onPress: () => {
              setTimeout(() => {
                chatStore.sendMessage(
                  'trivie',
                  'Perfect. Last but not least, you can customize the cover image, style, and audience of your quiz.',
                )
                setTimeout(() => {
                  chatStore.sendMessage(
                    'trivie',
                    'You can change these settings later in the Quiz Settings menu.',
                  )
                  setTimeout(() => {
                    chatStore.sendMessage(
                      'user',
                      '',
                      [],
                      false,
                      <CustomizeQuiz generateQuestions={generateQuestions} />,
                    )
                  }, 600)
                }, 600)
              }, 600)
              quiz?.set('title', chatStore?.subject)
            },
            visible: true,
            type: 'button',
          },
          {
            label: 'Select Important Keywords',
            onPress: () => {
              setTimeout(() => {
                chatStore.sendMessage(
                  'trivie',
                  '',
                  [],
                  false,
                  <Keywords generateQuestions={generateQuestions} />,
                )
              }, 600)
            },
            visible: true,
            type: 'button',
          },
        ])
      }, 600)
    }
  }, [quiz?.infoConfirmed, quiz?.mercuryResource, quiz?.loadingDocument])

  const editor = useEditor({
    editable: true,
    extensions: [StarterKit.configure({ heading: { levels: [1, 2, 3] } })],
    content: {},
    editorProps: { attributes: { class: 'OnboardingProse' } },
    autofocus: true,
  })

  const [showNext, setShowNext] = useState(true)
  const submitTextResource = async () => {
    setShowNext(false)
    messages[messages.length - 1].setToggles()

    const text = editor?.getText() as string
    const resourceID = await uploadTextResource(
      text,
      `${capitalize(chatStore?.subject ?? '')} Source`,
    )

    if (quiz && resourceID) {
      ResourceApi.processResource(resourceID).then(async () => {
        const resourcesAfterAdd = await getAvailableResources()
        const addedResource = resourcesAfterAdd?.find((r) => r.resource_id === resourceID)
        if (addedResource) {
          quiz.addResource(addedResource)
          quiz?.setMercuryResource(undefined)
          quiz?.setMercuryResource(addedResource)
          quiz?.set('showResourcePanel', true)
          messages[messages.length - 1].setToggles()
          setShowEditor(false)
          setShowNext(false)
          promptUserInfo()
        }
      })
    }
  }

  const placeholders = [
    'new hire onboarding',
    'electric cars',
    'safe food handling',
    'change management',
  ]

  useInterval(() => {
    if (!hoverCreateQuiz && !chatStore.subject && document.hasFocus()) {
      setPlaceholderIdx(placeholderIdx === placeholders.length - 1 ? 0 : placeholderIdx + 1)
      typeoutSuggestion()
    }
  }, 3000)

  useEffect(() => {
    setShowFilePicker(false)
    setShowURL(false)
    setShowEditor(false)
    manageUserStore?.setStatus('done')
    resetContentStore()
    // setPageTitle(fromGenerate ? 'Generate a Quiz' : 'Trivie AI')
    setQuiz(QuizHelper.createNewQuiz({ showQuizPanel: true, showResourcePanel: false }))
    if (!creatingWorkspace) {
      chatStore.clear()
    } else {
      setCreatingWorkspace(false)
    }
  }, [])

  const [value, setValue] = useState('change management')
  const [confirmed, setConfirmed] = useState(false)

  const handleKeyup = async (e) => {
    if ((e.type === 'keyup' && e.code !== 'Enter') || !e.target.value) {
      return
    }
    document.getElementById('UserInput')?.blur()
    if (!localStorage.getItem('token')) {
      setShowSignup(true)
    }
  }

  const askForSources = async () => {
    setTimeout(() => {
      sendMessage('trivie', `Do you have any sources about ${chatStore.subject}?`, [])
      setTimeout(() => {
        sendMessage('user', '', [
          {
            label: 'No, find one for me.',
            onPress: () => handleFindResource(),
            visible: true,
            type: 'button',
          },
          {
            label: 'Yes! Let me add one.',
            onPress: () => handleProvideResource(),
            visible: true,
            type: 'button',
          },
        ])
      }, 800)
    }, 800)
  }

  const startOver = () => {
    sendMessage('trivie', "Sounds good! Let's start over...", [])
    chatStore.clear()
    chatStore.setSubject('')
    setShowSignup(false)
    setShowFilePicker(false)
    setShowEditor(false)
    setShowNext(true)
    setShowURL(false)
    setQuiz(QuizHelper.createNewQuiz({ showQuizPanel: true, showResourcePanel: false }))
    setConfirmed(false)
    setSelectedSubject(false)
    setPlaceholderIdx(placeholders.length - 2)
    setHoverCreateQuiz(false)
    setValue(placeholders[placeholderIdx])
  }

  const createQuiz = (retry?: boolean) => {
    if (!chatStore.subject) {
      chatStore.setSubject(value)
    }

    setConfirmed(true)

    const newQuiz = QuizHelper.createNewQuiz({ showQuizPanel: true, showResourcePanel: false })
    newQuiz.addQuestions([QuestionHelper.createNewQuestion()])
    newQuiz?.selectQuestion(newQuiz.questions![0])
    setQuiz(newQuiz)

    setSelectedSubject(true)

    if (!retry) {
      sendMessage('user', `Let's make a quiz about ${chatStore.subject}.`, [])
    }

    // llm call to check subject
    ResourceApi.checkSubject(chatStore.subject ?? '')
      .then((resp) => {
        if (resp.data && resp.data.allowed) {
          setTimeout(() => {
            sendMessage('trivie', "Great choice. Let's get started.")
            setTimeout(() => {
              askForSources()
            }, 800)
          }, 800)
        } else if (resp.data) {
          sendMessage(
            'trivie',
            `Hm. I'm not sure about that subject. Let's focus on something that's educational and safe. How about one of these instead?`,
          )
          sendMessage('user', '', [
            ...resp.data.alternatives.map((alt) => {
              return {
                label: capitalize(alt),
                labelOverride: true,
                onPress: () => {
                  chatStore.setSubject(alt)
                  askForSources()
                },
                visible: true,
                type: 'button',
              }
            }),
            {
              label: 'Something Else',
              labelOverride: true,
              onPress: () => startOver(),
              visible: true,
              type: 'button',
            },
          ])
        } else {
          showError(() => startOver())
        }
      })
      .catch((e) => {
        console.log(e)
        showError(() => startOver())
      })
  }

  const handleProvideResource = () => {
    setTimeout(() => {
      sendMessage(
        'trivie',
        "Terrific. What kind of source do you want to use? I'll grab some info from it and use that to generate questions.",
        [],
      )
      setTimeout(() => {
        sendMessage('user', '', [
          {
            label: 'Upload a File',
            onPress: () => {
              setShowURL(false)
              setShowFilePicker(true)
              setShowEditor(false)
            },
            visible: true,
            type: 'toggle',
          },
          {
            label: 'Enter a URL',
            onPress: () => {
              setShowURL(true)
              setShowFilePicker(false)
              setShowEditor(false)
            },
            visible: true,
            type: 'toggle',
          },
          {
            label: 'Paste In Text',
            onPress: () => {
              setShowEditor(true)
              setShowFilePicker(false)
              setShowURL(false)
            },
            visible: true,
            type: 'toggle',
          },
        ])
      }, 800)
    }, 800)
  }

  const handleFindResource = async () => {
    sendMessage('trivie', 'Alright, let me take a look...', [], false)
    let foundMessage

    setTimeout(() => {
      foundMessage = sendMessage('trivie', 'Here are some sources I found on the web.', [], true)
      ResourceApi.findSources(chatStore.subject ?? '')
        .then((resp) => {
          foundMessage.set('loading', false)
          if (resp.data.sources) {
            setSources(resp.data.sources)
          } else {
            quiz?.set('showResourcePanel', false)
            chatStore?.sendMessage(
              'user',
              '',
              [],
              false,
              <div
                style={{
                  padding: 24,
                  display: 'flex',
                  borderRadius: 12,
                  backgroundColor: color.shade10,
                  marginTop: 20,
                }}
              >
                <Text
                  text="We're sorry, but our servers are currently at capacity. Please wait a moment and try again."
                  variant="titleEmphasized"
                  style={{ textAlign: 'center' }}
                />
                <IconButton
                  onClick={() => {
                    chatStore?.pop()
                    chatStore?.pop()
                    chatStore?.pop()
                    askForSources()
                  }}
                >
                  <Close />
                </IconButton>
              </div>,
            )
          }
        })
        .catch((e) => {
          console.log('err', e)
        })
    }, 800)
  }

  const typeoutSuggestion = async () => {
    setValue('')
    const input = document.getElementById('UserInput') as any
    if (input) {
      // input.focus()
      const customNodeCreator = function (character) {
        setValue(input.placeholder + character)
        // Return null to skip internal adding of dom node
        return null
      }
      const typewriter = new Typewriter(null, {
        loop: false,
        delay: 0,
        onCreateTextNode: customNodeCreator,
      })
      const newText = placeholders[placeholderIdx]
      typewriter.pauseFor(200).typeString(newText).start()
    }
  }

  const promptUserInfo = () => {
    sendMessage('trivie', "Perfect. It'll take me a moment to process the document...", [], false)
    if ((!userProfile?.first_name || !userProfile?.last_name) && !fromGenerate) {
      setTimeout(() => {
        sendMessage(
          'trivie',
          "While we're waiting, let's finish setting up your workspace. To start, please tell me a little bit about yourself.",
          [],
          false,
        )
        setTimeout(() => {
          sendMessage('user', '', [], false, <UserInfo />)
        }, 800)
      }, 1200)
    } else if (!settings?.company_name && !fromGenerate) {
      setTimeout(() => {
        chatStore.sendMessage(
          'trivie',
          'Now, help me set up your Trivie experience by telling us a bit about your organization.',
        )
        setTimeout(() => {
          chatStore.sendMessage('trivie', '', [], false, <OrganizationInfo />)
        }, 2000)
      }, 800)
    } else {
      quiz?.set('infoConfirmed', true)
    }
  }

  const showError = (onClick) => {
    chatStore?.sendMessage(
      'user',
      '',
      [],
      false,
      <div
        style={{
          padding: 24,
          display: 'flex',
          borderRadius: 12,
          backgroundColor: color.shade10,
          marginTop: 20,
        }}
      >
        <Text
          text="We're sorry, but our servers are currently at capacity. Please wait a moment and try again."
          variant="titleEmphasized"
          style={{ textAlign: 'center' }}
        />
        <IconButton onClick={onClick}>
          <Close />
        </IconButton>
      </div>,
    )
  }

  useEffect(() => {
    if (quiz?.loadingDocumentStatus === 'Failed' && quiz?.infoConfirmed && !errorShown) {
      setErrorShown(true)
      quiz?.set('showResourcePanel', false)
      showError(() => {
        chatStore?.pop()
        chatStore?.pop()
        chatStore?.pop()
        chatStore?.pop()
        chatStore?.pop()
        setErrorShown(false)
        askForSources()
      })
    }
  }, [quiz?.loadingDocumentStatus, quiz?.infoConfirmed])

  const resourceDrawerWidth = '40vw'

  const resourcePanel = <ResourcePanel onboarding quiz={quiz} />

  return (
    <div style={ROOT}>
      {chatStore.subject && (
        <div style={HEADER_BAR}>
          <div style={{ flexDirection: 'row', display: 'flex', alignItems: 'center' }}>
            {chatStore?.subject && (
              <>
                <Text style={{ marginRight: 4 }} text="A quiz about" variant="labelEmphasized" />
                <Text
                  variant="labelEmphasized"
                  style={{
                    backgroundImage: `linear-gradient(40deg, #FF00FF -17.26%, #9116F9 49.66%, #3199FF 99.55%)`,
                    backgroundClip: 'text',
                    WebkitBackgroundClip: 'text',
                    fontWeight: 600,
                    WebkitTextFillColor: 'transparent',
                  }}
                >
                  {chatStore?.subject}
                </Text>
              </>
            )}
          </div>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              gap: 40,
              paddingRight: 24,
              alignItems: 'center',
            }}
          >
            {chatStore?.subject && (
              <Button onClick={startOver} text="Start Over" color="info" variant="outlined" />
            )}
            {settings?.company_name || settings?.logo_path ? (
              <div style={{ display: 'flex', alignItems: 'center', gap: 10 }}>
                {settings?.logo_path && (
                  <img
                    width={36}
                    height={36}
                    src={settings?.logo_path}
                    style={{
                      objectFit: 'contain',
                      borderRadius: 4,
                    }}
                  />
                )}
                {settings?.company_name && (
                  <Text variant="body1Emphasized">{settings?.company_name}</Text>
                )}
              </div>
            ) : null}
            {manageUserStore?.userProfile?.first_name && (
              <Avatar
                size={32}
                name={`${manageUserStore?.userProfile.first_name} ${manageUserStore?.userProfile.last_name}`}
                url={manageUserStore?.userProfile?.photo_url}
              />
            )}
          </div>
        </div>
      )}
      <div style={{ flexDirection: 'row', display: 'flex', height: '100%' }}>
        <div style={CONTAINER}>
          <div style={CHAT_CONTAINER}>
            <div
              style={{
                marginBottom: 12,
                WebkitUserSelect: 'none',
                userSelect: 'none',
                alignItems: 'flexEnd',
                width: '100%',
                maxWidth: 650,
              }}
            >
              <Chat />
              <div style={{ marginTop: 8 }}>
                {showEditor && (
                  <div style={{ animation: 'fadeIn 1.5s' }}>
                    <EditorContent editor={editor} />
                    <div style={EDITOR_FOOTER}>
                      {showNext ? (
                        <Button
                          disabled={!editor?.getText()}
                          variant="outlined"
                          onClick={submitTextResource}
                          text="Next"
                        />
                      ) : (
                        <CircularProgress />
                      )}
                    </div>
                  </div>
                )}
                {showURL && (
                  <WebResourceTab
                    onboarding
                    onUpload={async () => {
                      ResourceApi.processResource(quiz!.mercuryResource!.resource_id).then(
                        async () => {
                          const resourcesAfterAdd = await getAvailableResources()
                          const addedResource = resourcesAfterAdd?.find(
                            (r: any) => r.resource_id === quiz?.mercuryResource?.resource_id,
                          )
                          if (addedResource) {
                            quiz?.setMercuryResource(undefined)
                            quiz?.setMercuryResource(addedResource)
                            quiz?.addResource(addedResource)
                            setShowURL(false)
                            quiz?.set('showResourcePanel', true)
                          }
                          messages[messages.length - 1].setToggles()
                          promptUserInfo()
                        },
                      )
                    }}
                    inputType="standard"
                    quiz={quiz}
                  />
                )}
                {sources && sources?.length > 0 && (
                  <Sources
                    choose={async (s: { title: string; link: string }) => {
                      const result = await addResource((currentProgress: any) => {}, {
                        title: s.title,
                        url: s.link,
                      })
                      if (!result) {
                        // handle failed resource upload
                        // throw new Error(`Failed to upload url resource`)
                      } else {
                        ResourceApi.processResource(result.resource_id).then(async () => {
                          const resourcesAfterAdd = await getAvailableResources()
                          const addedResource = resourcesAfterAdd?.find(
                            (r: any) => r.resource_id === result.resource_id,
                          )
                          if (addedResource) {
                            quiz?.setMercuryResource(undefined)
                            quiz?.setMercuryResource(addedResource)
                            quiz?.addResource(addedResource)
                            quiz?.set('showResourcePanel', true)
                          }
                        })
                      }

                      sendMessage('user', s.title, [], false)
                      setSources(undefined)

                      promptUserInfo()
                    }}
                    sources={sources}
                  />
                )}
                {showFilePicker && !quiz?.mercuryResource && (
                  <UploadResourceTab
                    onUpload={async () => {
                      // after upload check processing status - if not processed, process and continue
                      // otherwise attach the resource and continue
                      ResourceApi.getResourceStatus(quiz!.mercuryResource!.resource_id).then(
                        async (processing_status) => {
                          if (processing_status !== 'processed') {
                            ResourceApi.processResource(quiz!.mercuryResource!.resource_id).then(
                              async () => {
                                const resourcesAfterAdd = await getAvailableResources()
                                const addedResource = resourcesAfterAdd?.find(
                                  (r: any) => r.resource_id === quiz?.mercuryResource?.resource_id,
                                )
                                if (addedResource) {
                                  quiz?.setMercuryResource(undefined)
                                  quiz?.setMercuryResource(addedResource)
                                  quiz?.addResource(addedResource)
                                }
                                setShowFilePicker(false)
                                quiz?.set('showResourcePanel', true)
                                messages[messages.length - 1].setToggles()
                                promptUserInfo()
                              },
                            )
                          } else {
                            const resourcesAfterAdd = await getAvailableResources()
                            const addedResource = resourcesAfterAdd?.find(
                              (r: any) => r.resource_id === quiz?.mercuryResource?.resource_id,
                            )
                            if (addedResource) {
                              quiz?.setMercuryResource(undefined)
                              quiz?.setMercuryResource(addedResource)
                              quiz?.addResource(addedResource)
                            }
                            setShowFilePicker(false)
                            quiz?.set('showResourcePanel', true)
                            messages[messages.length - 1].setToggles()
                            promptUserInfo()
                          }
                        },
                      )
                    }}
                    quiz={quiz}
                  />
                )}
              </div>
            </div>
            {!selectedSubject && (
              <>
                <div style={{ flexDirection: 'row', display: 'flex', gap: 32 }}>
                  <Button
                    size="large"
                    sx={{ backgroundColor: color.white }}
                    variant="outlined"
                    text="Create Quiz"
                    onClick={() => {
                      if (!localStorage.getItem('token')) {
                        if (!chatStore.subject) {
                          chatStore?.setSubject(value)
                        }
                        setShowSignup(true)
                      } else {
                        if (!chatStore.subject) {
                          chatStore?.setSubject(value)
                        }
                        createQuiz()
                      }
                    }}
                  />
                  {fromGenerate && (
                    <Button
                      size="large"
                      onClick={() => {
                        quiz?.set('showSettings', true)
                        quiz?.set('showResourcePanel', true)
                        quiz?.set('questions', [QuestionHelper.createNewQuestion()])
                        history.push(
                          '/content/creation/quiz/new/edit/?generate=true&create=true&onboarding=true',
                        )
                      }}
                      sx={{ backgroundColor: color.white }}
                      variant="outlined"
                      text="Skip Chat"
                    />
                  )}
                </div>
                <div style={INPUT_ROW}>
                  <Text variant="body1" style={PROMPT} text="Let's make a quiz about " />
                  <TextField
                    onKeyUp={handleKeyup}
                    // autoFocus
                    sx={{
                      '& .MuiInput-underline:after': {
                        borderBottom: '1px solid rgba(0, 0, 0, 0.42)',
                      },
                    }}
                    inputProps={{
                      style: { ...INPUT, width: `${inputWidth}px` },
                      className: confirmed ? 'selected' : hoverCreateQuiz ? 'gradient' : '',
                    }}
                    id="UserInput"
                    variant="standard"
                    value={chatStore.subject}
                    placeholder={value}
                    onChange={(e) => chatStore.setSubject(e.target.value)}
                  />
                </div>
              </>
            )}
            <Text
              variant="body1"
              style={SUBTITLE}
              text="Create a high-quality quiz and share it with learners in minutes."
            />
            <Logo quantum={quantum} style={{ animation: 'fadeIn .5s', transform: 'scale(1.5)' }} />
          </div>
        </div>
        <ResourceDrawer
          sx={{
            ...FULL_RESOURCE_DRAWER,
            width: !quiz?.mercuryResource ? '0px !important' : resourceDrawerWidth,
          }}
          variant="permanent"
          anchor="right"
          open={quiz?.showResourcePanel}
        >
          <DrawerHeader />
          {quiz?.showResourcePanel && resourcePanel}
        </ResourceDrawer>
        {showSignup && (
          <SignupModal close={() => setShowSignup(false)} open={showSignup} onSubmit={createQuiz} />
        )}
      </div>
    </div>
  )
})

const openedResourceMixin = (theme: any, width: number): any => ({
  width: width,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
})

const closedResourceMixin = (theme: any, width): any => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  width: width ? width : `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up('sm')]: {
    width: width ? width : `calc(${theme.spacing(8)} + 1px)`,
  },
})
const ResourceDrawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== 'open',
})((props) => {
  const { sx, theme, open } = props
  // @ts-ignore
  const { width } = sx

  return {
    width,
    flexShrink: 0,
    whiteSpace: 'nowrap',
    boxSizing: 'border-box',
    right: 0,
    position: 'relative',
    ...(open && {
      ...openedResourceMixin(theme, width),
      '& .MuiDrawer-paper': openedResourceMixin(theme, width),
    }),
    ...(!open && {
      ...closedResourceMixin(theme, width),
      '& .MuiDrawer-paper': closedResourceMixin(theme, width),
    }),
  }
})

const DrawerHeader = styled('div')(({ theme }: any) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  justifyContent: 'flex-end',
  minHeight: '70px !important',
}))

const ROOT: React.CSSProperties = {
  height: '100vh',
  backgroundColor: 'rgb(244, 244, 244)',
  width: '100%',
}
const CONTAINER: React.CSSProperties = {
  display: 'flex',
  flexDirection: 'column',
  // maxWidth: 670,
  margin: '0 auto',
  minHeight: '57vh',
  maxHeight: 'calc(100vh - 70px)',
  overflowX: 'hidden',
  // height: '100vh',
  overflowY: 'auto',
  justifyContent: 'center',
  alignItems: 'center',
  // width: '100%',
  flex: 1,
  backgroundColor: '#rgb(244, 244, 244)',
}
const SUBTITLE: React.CSSProperties = {
  color: color.shade70,
  fontSize: 16,
  marginTop: 32,
  marginBottom: 32,
  animation: 'fadeIn 1s',
}
const INPUT_ROW: React.CSSProperties = {
  flexDirection: 'row',
  display: 'flex',
  alignItems: 'center',
  marginBottom: 32,
  animation: 'fadeIn 1.5s',
}
const PROMPT: React.CSSProperties = {
  marginRight: 5,
  color: 'rgba(0,0,0,.78)',
  fontWeight: '500',
  fontSize: 18,
}
const INPUT: React.CSSProperties = {
  textAlign: 'center',
  fontSize: 18,
  fontWeight: '600',
  paddingBottom: 4,
}
const CREATE_QUIZ: React.CSSProperties = {
  border: `1px solid ${color.blue}`,
  paddingTop: 12,
  paddingBottom: 12,
  paddingRight: 16,
  paddingLeft: 16,
  borderRadius: 10,
  background: color.white,
  textDecoration: 'none',
  height: 41,
  margin: 1,
}
const HOVER_CREATE_QUIZ: React.CSSProperties = {
  ...CREATE_QUIZ,
  height: 41,
  border: `1px solid ${color.transparent}`,
}
const BUTTON_LABEL: React.CSSProperties = {
  backgroundImage: `linear-gradient(40deg, #FF00FF -17.26%, #9116F9 49.66%, #3199FF 99.55%)`,
  backgroundClip: 'text',
  WebkitBackgroundClip: 'text',
  WebkitTextFillColor: 'transparent',
}
const EDITOR_FOOTER: React.CSSProperties = {
  flexDirection: 'row',
  display: 'flex',
  marginTop: 12,
  justifyContent: 'flex-end',
}
const CHAT_CONTAINER: React.CSSProperties = {
  display: 'flex',
  flexDirection: 'column-reverse',
  overflowY: 'auto',
  overflowX: 'hidden',
  width: '100%',
  alignItems: 'center',
  paddingRight: 12,
  paddingLeft: 12,
  paddingTop: 12,
}

const HEADER_BAR: React.CSSProperties = {
  display: 'flex',
  backgroundColor: '#FFFFFF',
  borderBottomWidth: 1,
  minHeight: 70,
  justifyContent: 'space-between',
  flexDirection: 'row',
  alignItems: 'center',
  boxShadow: '0px -1px 0px 0px #E2E2EA inset',
  paddingLeft: 24,
  paddingTop: 12,
  paddingBottom: 12,
  width: '100%',
  position: 'relative',
  zIndex: 9999,
}

const BUTTON: React.CSSProperties = {
  // border: `1px solid ${color.blue}`,
  paddingTop: 12,
  paddingBottom: 12,
  paddingRight: 16,
  paddingLeft: 16,
  borderRadius: 10,
  background: color.white,
  textDecoration: 'none',
  height: 41,
  marginRight: 10,
}

const KEYWORD: React.CSSProperties = {
  padding: 10,
  borderRadius: 8,
  border: '1px solid rgba(0, 0, 0, 0.10)',
  display: 'flex',
}

const PANEL: React.CSSProperties = {
  flexDirection: 'column',
  display: 'flex',
  paddingTop: 20,
  paddingBottom: 20,
  paddingLeft: 40,
  paddingRight: 40,
  borderRadius: 12,
  backgroundColor: '#fff',
  marginTop: 32,
  marginBottom: 32,
  border: `1px solid ${color.shade30}`,
  width: '100%',
}
const FULL_RESOURCE_DRAWER = {
  backgroundColor: 'rgba(248, 250, 253)',
  '& .MuiDrawer-paper': {
    boxSizing: 'border-box',
    borderRadius: '0px !important',
    backgroundColor: 'rgba(248, 250, 253)',
    width: 500,
  },
}
