import * as React from 'react'
import { useState, useEffect } from 'react'
import { Duration, DateTime } from 'luxon'
import {
  Add,
  ArrowDropDown,
  BoltRounded,
  FactCheckRounded,
  PeopleRounded,
  TopicRounded,
  VisibilityRounded,
  WorkspacePremium,
} from '@mui/icons-material'
import {
  Autocomplete,
  CircularProgress,
  InputAdornment,
  InputBase,
  InputLabel,
  ListItem,
  MenuItem,
  Paper,
  Select,
  Switch,
  TextField,
  styled,
} from '@mui/material'
import { observer } from 'mobx-react-lite'

import { Button } from '@trivie/ui-web/components/button'

import { useStores } from '@trivie/core'
import { Text } from '@trivie/ui-web/components/text'
import { color } from '@trivie/ui-web/theme'
import { TagSelector } from '@trivie/ui-web/components/tag-selector'
import {
  BadgeApi,
  GroupApi,
  QuizApi,
  ResourceApi,
  TagApi,
  TopicApi,
} from '@trivie/core/src/services/api-objects'
import { AssignmentApi } from '@trivie/core/src/services/api-objects/AssignmentApi'
import { WorkflowApi } from '@trivie/core/src/services/api-objects/WorkflowApi'
import { ConfirmDialog } from '@trivie/ui-web/components/confirm-dialog'
import { ResourceIcon } from '@trivie/ui-web/components/resource-icon'
import { GetBackgroundColorOfMimeType } from '@trivie/core'
import { CreateEditBadge } from '../../../activities/badges/create-edit-badge'
import { ResourceUploadDialog } from './resource-upload-dialog'
import { Medallion } from '@trivie/ui-web/components/medallion'

export const DelayFor = observer((props: any) => {
  const { onUpdate } = props
  const { pathStore } = useStores()
  const { workflow } = pathStore

  const [description, setDescription] = useState<any>('')
  const [delay, setDelay] = useState(Duration.fromObject({ days: 1 }).as('minutes'))
  const [delayFrom, setDelayFrom] = useState('delay')
  const [days, setDays] = useState(0)
  const [hours, setHours] = useState(0)
  const [minutes, setMinutes] = useState(0)

  useEffect(() => {
    if (workflow?.selectedStep) {
      setDelayFrom(workflow?.selectedStep.action_type)
      const dur = Duration.fromObject({ minutes: workflow?.selectedStep.minutes }).shiftTo(
        'days',
        'hours',
        'minutes',
      )
      setDays(dur.days)
      setHours(dur.hours)
      setMinutes(dur.minutes)
      setDescription(workflow?.selectedStep.description)
    }
  }, [workflow?.selectedStep])

  return (
    <>
      <Text
        component="div"
        text="Delay:"
        variant="titleEmphasized"
        style={{ color: color.shade70, marginBottom: 20 }}
      />
      <Select
        variant="standard"
        labelId="delay-action"
        displayEmpty
        label="Delay From:"
        fullWidth
        input={<TrivieDropdown />}
        MenuProps={{ PaperProps: { style: { borderRadius: 6, paddingTop: 0 } } }}
        IconComponent={ArrowDropDown}
        value={delayFrom}
        onChange={(e: any) => {
          setTimeout(() => {
            // @ts-ignore
            document?.activeElement?.blur()
          }, 0)
          setDelayFrom(e.target.value)
        }}
        style={{ ...STATUS_DROPDOWN, marginBottom: 20 }}
      >
        <MenuItem value="delay">
          <Text variant="body1" text="Previous Step Completion" />
        </MenuItem>
        <MenuItem value="delay_from">
          <Text variant="body1" text="User Receives Assignment" />
        </MenuItem>
      </Select>
      <Text
        component="div"
        text="Delay Length:"
        variant="body2"
        style={{ color: color.shade70, marginBottom: 20 }}
      />
      <div
        style={{
          flexDirection: 'row',
          gap: 20,
          display: 'flex',
          marginBottom: 20,
          alignItems: 'center',
        }}
      >
        <TextField
          inputProps={{ style: { textAlign: 'center' } }}
          onChange={(e: any) => {
            if (e.target.value < 0) {
              setDays(0)
            } else {
              setDays(parseInt(e.target.value))
            }
          }}
          type="number"
          value={days}
          variant="standard"
          style={{ width: 40 }}
        />
        <Text text="Days," variant="body1Medium" style={{ color: color.shade70 }} />
        <TextField
          inputProps={{ style: { textAlign: 'center' } }}
          onChange={(e: any) => {
            if (e.target.value < 0) {
              setHours(0)
            } else {
              setHours(parseInt(e.target.value))
            }
          }}
          type="number"
          value={hours}
          variant="standard"
          style={{ width: 40 }}
        />
        <Text text="Hours, and" variant="body1Medium" style={{ color: color.shade70 }} />
        <TextField
          inputProps={{ style: { textAlign: 'center' } }}
          onChange={(e: any) => {
            if (e.target.value < 0) {
              setMinutes(0)
            } else {
              setMinutes(parseInt(e.target.value))
            }
          }}
          type="number"
          value={minutes}
          variant="standard"
          style={{ width: 40 }}
        />
        <Text text="Minutes." variant="body1Medium" style={{ color: color.shade70 }} />
      </div>
      <div style={{ ...SWITCH_ROW, marginTop: 0 }} />
      <TextField
        sx={{ background: color.white, marginBottom: '20px' }}
        placeholder="Add a description"
        variant="outlined"
        label={description ? 'Description' : ''}
        multiline
        value={description}
        onChange={(e) => setDescription(e.target.value)}
        fullWidth
      />
      <Button
        disabled={!days && !hours && !minutes}
        sx={{ display: 'flex', margin: '0 auto' }}
        onClick={() => {
          if (workflow?.selectedStep) {
            workflow?.selectedStep.setActionType(delayFrom)
            workflow?.selectedStep.setDelay(
              Duration.fromObject({ days: days || 0, hours: hours || 0, minutes: minutes || 0 }).as(
                'minutes',
              ),
            )
            workflow?.selectedStep?.setDescription(description)
            workflow?.selectedStep?.setUserVisible(true)
          } else {
            workflow?.delay(
              Duration.fromObject({ days: days || 0, hours: hours || 0, minutes: minutes || 0 }).as(
                'minutes',
              ),
              true,
              description,
              delayFrom === 'delay_from',
            )
          }
          workflow?.selectStep(undefined)
          workflow?.setEditing('')

          onUpdate()
        }}
        text={
          pathStore.workflow?.selectedStep
            ? pathStore.path
              ? 'Update Step'
              : 'Update Award'
            : pathStore.path
              ? 'Add Step'
              : 'Add Award'
        }
      />
    </>
  )
})

export const WaitUntil = observer((props: any) => {
  const { onUpdate } = props

  const { pathStore } = useStores()
  const { workflow } = pathStore

  const [description, setDescription] = useState<any>('')
  const [datetime, setDatetime] = useState(DateTime.local().toFormat('yyyy-MM-dd'))

  useEffect(() => {
    if (workflow?.selectedStep) {
      setDescription(workflow?.selectedStep.description)
      setDatetime(
        DateTime.fromISO(workflow?.selectedStep?.wait_until_datetime).toFormat('yyyy-MM-dd'),
      )
    }
  }, [workflow?.selectedStep])
  return (
    <>
      <div style={{ marginBottom: 20 }}>
        <Text text="Wait Until:" variant="titleEmphasized" style={{ color: color.shade70 }} />
      </div>
      <TextField
        label="Date"
        variant="outlined"
        {...{
          inputProps: { min: DateTime.local().toFormat('yyyy-MM-dd') },
        }}
        size="small"
        sx={{ background: color.white }}
        style={{
          marginRight: 8,
          marginBottom: 10,
          display: 'flex',
          width: '100%',
        }}
        type="date"
        onChange={(e) => {
          if (!e.target.value) {
            return
          }
          setDatetime(e.target.value)
        }}
        value={datetime}
      />
      <div style={{ ...SWITCH_ROW, marginTop: 0 }} />
      <div>
        <TextField
          sx={{ background: color.white, marginBottom: '20px' }}
          placeholder="Add a description"
          variant="outlined"
          label={description ? 'Description' : ''}
          multiline
          value={description}
          onChange={(e) => setDescription(e.target.value)}
          fullWidth
        />
      </div>
      <Button
        disabled={!datetime}
        sx={{
          display: 'flex',
          margin: '0 auto',
          marginTop: '20px',
        }}
        onClick={() => {
          if (workflow?.selectedStep) {
            workflow?.selectedStep.setWaitUntil(
              DateTime.fromFormat(datetime, 'yyyy-MM-dd').toUTC().toISO(),
            )
            workflow?.selectedStep?.setDescription(description)
            workflow?.selectedStep?.setUserVisible(true)
          } else {
            workflow?.waitUntil(
              DateTime.fromFormat(datetime, 'yyyy-MM-dd').toUTC().toISO(),
              true,
              description,
            )
          }
          workflow?.selectStep(undefined)
          workflow?.setEditing('')
          onUpdate()
        }}
        text={
          pathStore.workflow?.selectedStep
            ? pathStore.path
              ? 'Update Step'
              : 'Update Award'
            : pathStore.path
              ? 'Add Step'
              : 'Add Award'
        }
      />
    </>
  )
})

export const AwardPoints = observer((props: any) => {
  const { onUpdate } = props

  const [points, setPoints] = useState<any>()

  const { pathStore } = useStores()
  const { workflow } = pathStore

  useEffect(() => {
    if (workflow?.selectedStep) {
      setPoints(workflow.selectedStep?.points)
    }
  }, [workflow?.selectedStep])

  return (
    <>
      <div style={{ marginBottom: 20 }}>
        <Text text="Award Points:" variant="titleEmphasized" style={{ color: color.shade70 }} />
      </div>
      <TextField
        onChange={(e: any) => {
          if (e.target.value < 0) {
            setPoints(0)
          } else if (e.target.value > 100) {
            setPoints(100)
          } else {
            setPoints(parseInt(e.target.value))
          }
        }}
        type="number"
        value={points}
        fullWidth
        placeholder="Award Points"
        variant="standard"
      />
      <Button
        disabled={!Boolean(points)}
        sx={{
          display: 'flex',
          margin: '0 auto',
          marginTop: '20px',
        }}
        onClick={() => {
          if (workflow?.selectedStep) {
            workflow?.selectedStep.setPoints(points)
          } else {
            workflow?.awardPoints(points)
          }
          workflow?.selectStep(undefined)
          setPoints(0)
          workflow?.setEditing('')
          onUpdate()
        }}
        text={
          pathStore.workflow?.selectedStep
            ? pathStore.path
              ? 'Update Step'
              : 'Update Award'
            : pathStore.path
              ? 'Add Step'
              : 'Add Award'
        }
      />
    </>
  )
})

export const AddTagToUser = observer((props: any) => {
  const { untag, onUpdate } = props
  const [tags, setTags] = useState<any>([])
  const [availableTags, setAvailableTags] = useState<any>([])
  const [formattedTags, setFormattedTags] = useState({})
  const [loading, setLoading] = useState(true)
  const [userVisible, setUserVisible] = useState(false)
  const [description, setDescription] = useState<any>('')

  const { pathStore } = useStores()
  const { workflow } = pathStore

  const handleTagsUpdate = () => {
    if (tags?.length && workflow?.selectedStep) {
      workflow?.selectedStep?.setTags(tags)
      workflow?.selectedStep?.setTagIDs(tags.map((t) => t.id))
    }
  }

  const init = (preselect?: any) => {
    TagApi.getAllTags(
      'tags',
      false,
      [{ field: 'tag_key' }, { field: 'tag_value' }],
      'list_tags',
    ).then((resp) => {
      if (resp.ok) {
        // @ts-ignore
        const available = resp.data.rows.map((tag) => {
          const key = tag.columns.find((column) => column.field === 'tag_key')
          const value = tag.columns.find((column) => column.field === 'tag_value')
          return { id: tag.id, key: key.value, value: value.value }
        })
        const t = {}
        setAvailableTags(available)
        available.map((tag) => {
          t[tag.key] = { values: [] }
        })
        available.map((tag) => {
          t[tag.key].values.push(tag)
        })

        if (workflow?.selectedStep) {
          setTags(workflow?.selectedStep?.tags)
        }

        if (preselect) {
          const newTag = available.find(
            (t) => t.key === preselect?.key && t.value === preselect?.value,
          )
          if (newTag && workflow?.selectedStep?.tags?.length > 0) {
            setTags([...workflow.selectedStep.tags, newTag])
            handleTagsUpdate()
          } else if (newTag) {
            setTags([...tags, newTag])
          }
        }
        setFormattedTags(t)
        setLoading(false)
      }
    })
  }

  useEffect(() => {
    init()
  }, [])

  useEffect(() => {
    if (workflow?.selectedStep) {
      setTags(workflow?.selectedStep?.tags)
      setDescription(workflow?.selectedStep?.description)
      setUserVisible(Boolean(workflow?.selectedStep?.user_visible))
    }
  }, [workflow?.selectedStep])

  return (
    <>
      <div style={{ marginBottom: 20 }}>
        <Text
          text={untag ? 'Untag User:' : 'Tag User:'}
          variant="titleEmphasized"
          style={{ color: color.shade70 }}
        />
      </div>
      {loading ? (
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <CircularProgress />
        </div>
      ) : (
        <TagSelector
          tagListStyle={{ minWidth: 230 }}
          remove={untag}
          formattedTags={formattedTags}
          tags={availableTags}
          allowCreateNew={untag ? false : true}
          onCreateNew={(payload) => {
            init(payload)
          }}
          selectedTags={tags}
          onChange={(selected) => setTags(selected)}
        />
      )}
      <div style={SWITCH_ROW}>
        <Text variant="body1Medium" text="Visible To Users" />
        <Switch
          color="success"
          checked={userVisible}
          onChange={(e) => setUserVisible(e.target.checked)}
          size="small"
        />
      </div>
      <div>
        <TextField
          sx={{ background: color.white, marginBottom: '20px' }}
          placeholder="Add a description"
          variant="outlined"
          label={description ? 'Description' : ''}
          multiline
          value={description}
          onChange={(e) => setDescription(e.target.value)}
          fullWidth
        />
      </div>
      <Button
        disabled={!tags.length}
        sx={{
          display: 'flex',
          margin: '0 auto',
          marginTop: '20px',
        }}
        variant="contained"
        onClick={() => {
          if (workflow?.selectedStep) {
            handleTagsUpdate()
            workflow?.selectedStep.setDescription(description)
            workflow?.selectedStep.setUserVisible(userVisible)
          } else {
            untag
              ? workflow?.untagUser(tags, userVisible, description)
              : workflow?.tagUser(tags, userVisible, description)
          }
          workflow?.selectStep(undefined)
          onUpdate()
        }}
        text={
          pathStore.workflow?.selectedStep
            ? pathStore.path
              ? 'Update Step'
              : 'Update Award'
            : pathStore.path
              ? 'Add Step'
              : 'Add Award'
        }
      />
    </>
  )
})

export const AddToGroup = observer((props: any) => {
  const { onUpdate } = props
  const [groups, setGroups] = useState([])
  const [group, setGroup] = useState<any>()
  const [loading, setLoading] = useState(true)
  const [userVisible, setUserVisible] = useState(false)
  const [description, setDescription] = useState<any>('')

  const { pathStore } = useStores()
  const { workflow } = pathStore

  const loadGroups = async () => {
    const resp = await GroupApi.getAllGroups(
      'groups',
      false,
      [{ field: 'group_name' }],
      'list_groups',
    )
    if (resp) {
      setLoading(false)
    }
    setGroups(
      resp.data.rows
        .map((g) => {
          return {
            id: g.id,
            group_name: g.columns.find((column) => column.field === 'group_name').value,
          }
        })
        .sort((a, b) => (a.group_name > b.group_name ? 1 : -1)),
    )
  }

  useEffect(() => {
    loadGroups()
  }, [])

  useEffect(() => {
    if (workflow?.selectedStep && groups.length) {
      const found = groups.find((g: any) => g.id === workflow?.selectedStep?.object_id)
      setGroup(found)
      setDescription(workflow?.selectedStep.description)
      setUserVisible(Boolean(workflow?.selectedStep?.user_visible))
    }
  }, [workflow?.selectedStep, groups])

  return (
    <>
      <div style={{ marginBottom: 20 }}>
        <Text
          text="Add User To Group:"
          variant="titleEmphasized"
          style={{ color: color.shade70 }}
        />
      </div>
      {loading ? (
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <CircularProgress />
        </div>
      ) : (
        <Autocomplete
          sx={{ background: color.white }}
          PaperComponent={CustomPaper}
          onChange={(e: any, value: any) => {
            const a = groups.find((g: any) => g?.id === value?.id) as any
            if (a) {
              setGroup(a)
            } else {
              setGroup(null)
            }
          }}
          getOptionLabel={(option: any) => option.group_name}
          renderOption={(autocompleteProps, options, state) => {
            return (
              <li {...autocompleteProps} key={`${options.group_name} ${options.id}`} style={ROW}>
                <PeopleRounded style={ICON} />
                <Text variant="body1" text={options.group_name} />
              </li>
            )
          }}
          key={group}
          defaultValue={group}
          value={group}
          options={groups}
          clearOnEscape
          renderInput={(params) => {
            return (
              <TextField
                {...params}
                InputProps={{
                  ...params.InputProps,
                  startAdornment: (
                    <InputAdornment position="start">
                      <PeopleRounded htmlColor={color.shade70} />
                    </InputAdornment>
                  ),
                }}
                placeholder="Select Group"
                variant="outlined"
              />
            )
          }}
        />
      )}
      <div style={SWITCH_ROW}>
        <Text variant="body1Medium" text="Visible To Users" />
        <Switch
          color="success"
          checked={userVisible}
          onChange={(e) => setUserVisible(e.target.checked)}
          size="small"
        />
      </div>
      <div>
        <TextField
          sx={{ background: color.white, marginBottom: '20px' }}
          placeholder="Add a description"
          variant="outlined"
          label={description ? 'Description' : ''}
          multiline
          value={description}
          onChange={(e) => setDescription(e.target.value)}
          fullWidth
        />
      </div>
      <Button
        disabled={!group}
        sx={{
          display: 'flex',
          margin: '0 auto',
          marginTop: '20px',
        }}
        variant="contained"
        onClick={() => {
          if (workflow?.selectedStep) {
            workflow?.selectedStep?.setObjectID(group.id)
            workflow?.selectedStep.setGroup(group)
            workflow?.selectedStep?.setDescription(description)
            workflow?.selectedStep?.setUserVisible(userVisible)
          } else {
            workflow?.addToGroup(group, userVisible, description)
          }
          workflow?.selectStep(undefined)
          workflow?.setEditing('')

          onUpdate()
        }}
        text={
          pathStore.workflow?.selectedStep
            ? pathStore.path
              ? 'Update Step'
              : 'Update Award'
            : pathStore.path
              ? 'Add Step'
              : 'Add Award'
        }
      />
    </>
  )
})

export const AddToAssignment = observer((props: any) => {
  const { onUpdate } = props

  const [assignment, setAssignment] = useState<any>()
  const [assignments, setAssignments] = useState<any>([])
  const [loading, setLoading] = useState(true)
  const [userVisible, setUserVisible] = useState(false)
  const [description, setDescription] = useState<any>('')

  const { pathStore } = useStores()
  const { workflow } = pathStore

  const loadAssignments = async () => {
    const live = await AssignmentApi.getAllAssignments(
      'assignments',
      false,
      [{ field: 'assignment_title' }],
      'list_active_assignments',
      undefined,
      'active',
    )
    const sched = await AssignmentApi.getAllAssignments(
      'assignments',
      false,
      [{ field: 'assignment_title' }],
      'list_active_assignments',
      undefined,
      'scheduled',
    )
    if (live && sched) {
      setLoading(false)
    }
    setAssignments(
      [
        ...live.data.rows.map((s) => {
          return {
            id: s.id,
            assignment_title: s.columns.find((column) => column.field === 'assignment_title').value,
          }
        }),
        ...sched.data.rows.map((s) => {
          return {
            id: s.id,
            assignment_title: s.columns.find((column) => column.field === 'assignment_title').value,
          }
        }),
      ].sort((a, b) => (a.assignment_title > b.assignment_title ? 1 : -1)),
    )
  }

  useEffect(() => {
    loadAssignments()
  }, [])

  useEffect(() => {
    if (workflow?.selectedStep && assignments.length) {
      const found = assignments.find((a) => a.id === workflow?.selectedStep?.object_id)
      setAssignment(found)
      setDescription(workflow?.selectedStep.description)
      setUserVisible(Boolean(workflow?.selectedStep?.user_visible))
    }
  }, [workflow?.selectedStep, assignments])

  return (
    <>
      <div style={{ marginBottom: 20 }}>
        <Text
          text="Add User To Assignment:"
          variant="titleEmphasized"
          style={{ color: color.shade70 }}
        />
      </div>
      {loading ? (
        <div style={{ display: 'flex', justifyContent: 'center', marginBottom: 33 }}>
          <CircularProgress />
        </div>
      ) : (
        <Autocomplete
          sx={{ background: color.white }}
          PaperComponent={CustomPaper}
          onChange={(e: any, value: any) => {
            const found = assignments.find((a: any) => a?.id === value?.id) as any
            if (found) {
              setAssignment(found)
            } else {
              setAssignment(null)
            }
          }}
          getOptionLabel={(option: any) => option.assignment_title}
          renderOption={(autocompleteProps, options) => {
            return (
              <li
                {...autocompleteProps}
                key={`${options.assignment_title} ${options.id}`}
                style={ROW}
              >
                <FactCheckRounded style={ICON} />
                <Text variant="body1" text={options.assignment_title} />
              </li>
            )
          }}
          key={assignment}
          defaultValue={assignment}
          value={assignment}
          options={assignments}
          clearOnEscape
          renderInput={(params) => {
            return (
              <TextField
                {...params}
                InputProps={{
                  ...params.InputProps,
                  startAdornment: (
                    <InputAdornment position="start">
                      <FactCheckRounded htmlColor={color.shade70} />
                    </InputAdornment>
                  ),
                }}
                label="Select Assignment"
                placeholder="No Assignment Selected"
                variant="outlined"
              />
            )
          }}
        />
      )}
      <div style={SWITCH_ROW}>
        <Text variant="body1Medium" text="Visible To Users" />
        <Switch
          color="success"
          checked={userVisible}
          onChange={(e) => setUserVisible(e.target.checked)}
          size="small"
        />
      </div>
      <div>
        <TextField
          sx={{ background: color.white, marginBottom: '20px' }}
          placeholder="Add a description"
          variant="outlined"
          label={description ? 'Description' : ''}
          multiline
          value={description}
          onChange={(e) => setDescription(e.target.value)}
          fullWidth
        />
      </div>
      <Button
        disabled={!assignment}
        sx={{
          display: 'flex',
          margin: '0 auto',
          marginTop: '20px',
        }}
        onClick={() => {
          if (workflow?.selectedStep) {
            workflow?.selectedStep?.setObjectID(assignment.id)
            workflow?.selectedStep.setAssignment(assignment)
            workflow?.selectedStep?.setDescription(description)
            workflow?.selectedStep?.setUserVisible(userVisible)
          } else {
            workflow?.addToAssignment(assignment, userVisible, description)
          }
          workflow?.selectStep(undefined)
          workflow?.setEditing('')

          onUpdate()
        }}
        text={
          pathStore.workflow?.selectedStep
            ? pathStore.path
              ? 'Update Step'
              : 'Update Award'
            : pathStore.path
              ? 'Add Step'
              : 'Add Award'
        }
      />
    </>
  )
})

export const ViewSource = observer((props: any) => {
  const { onUpdate } = props

  const [source, setSource] = useState<any>()
  const [sources, setSources] = useState<any>([])
  const [loading, setLoading] = useState(true)
  const [showResourceDialog, setShowResourceDialog] = useState(false)

  const { pathStore } = useStores()
  const { workflow } = pathStore

  const getExtension = (resource) => {
    if (resource?.resource_file_type === 'text/html') return 'url'
    return resource?.resource_file_type
  }

  const loadSources = async () => {
    const resp = await ResourceApi.getAllResources(
      'resources',
      false,
      [{ field: 'resource_title' }, { field: 'resource_file_type' }],
      'list_resources',
    )
    if (resp) {
      setLoading(false)
    }
    setSources(
      resp.data.rows
        .map((s) => {
          return {
            id: s.id,
            resource_title: s.columns.find((column) => column.field === 'resource_title').value,
            resource_file_type: s.columns.find((column) => column.field === 'resource_file_type')
              .value,
          }
        })
        .sort((a, b) => (a.resource_title > b.resource_title ? 1 : -1)),
    )
  }

  const handleAddResource = () => {
    setLoading(true)
    loadSources()
    setShowResourceDialog(false)
  }

  const onUpload = (s) => {
    setSource({ ...s, resource_title: s.title, resource_file_type: s.mime_type })
  }

  useEffect(() => {
    loadSources()
  }, [])

  useEffect(() => {
    if (workflow?.selectedStep && sources?.length) {
      const found = sources.find((q) => q.id === workflow?.selectedStep?.object_id)
      setSource(found)
    }
  }, [workflow?.selectedStep, sources])

  return (
    <>
      <div style={{ marginBottom: 20 }}>
        <Text text="View Source:" variant="titleEmphasized" style={{ color: color.shade70 }} />
      </div>
      {loading ? (
        <div style={{ display: 'flex', justifyContent: 'center', marginBottom: 33 }}>
          <CircularProgress />
        </div>
      ) : (
        <Autocomplete
          sx={{ background: color.white }}
          PaperComponent={CustomPaper}
          onChange={(e: any, value: any) => {
            const found = sources.find((sourceItem: any) => value?.id === sourceItem?.id) as any
            if (found) {
              setSource(found)
            } else {
              setSource(null)
            }
          }}
          getOptionLabel={(option: any) => option.resource_title}
          renderOption={(autocompleteProps, options, state) => {
            return (
              <>
                {state.index === 0 && (
                  <li onClick={(e) => setShowResourceDialog(true)} style={ROW}>
                    <Add style={{ right: 3, position: 'relative' }} />
                    <Text
                      style={{ right: 8, position: 'relative' }}
                      variant="body1"
                      text="Create New"
                    />
                  </li>
                )}
                <li
                  {...autocompleteProps}
                  key={`${options.resource_title} ${options.id}`}
                  style={{ ...ROW }}
                >
                  <div
                    style={{
                      ...ICON_IMAGE,
                      ...RESOURCE_ICON,
                      background: GetBackgroundColorOfMimeType(getExtension(options)),
                    }}
                  >
                    <ResourceIcon extension={getExtension(options)} white={true} />
                  </div>
                  <Text variant="body1" text={options.resource_title} />
                </li>
              </>
            )
          }}
          key={source}
          defaultValue={source}
          value={source}
          options={sources}
          clearOnEscape
          renderInput={(params) => {
            return (
              <TextField
                {...params}
                InputProps={{
                  ...params.InputProps,
                  startAdornment: (
                    <InputAdornment position="start">
                      {source ? (
                        <div
                          style={{
                            ...ICON_IMAGE,
                            ...RESOURCE_ICON,
                            background: GetBackgroundColorOfMimeType(getExtension(source)),
                          }}
                        >
                          <ResourceIcon extension={getExtension(source)} white={true} />
                        </div>
                      ) : (
                        <VisibilityRounded htmlColor={color.shade70} />
                      )}
                    </InputAdornment>
                  ),
                }}
                label="Select Source"
                placeholder="No Source Selected"
                variant="outlined"
              />
            )
          }}
        />
      )}
      <Button
        disabled={!source}
        sx={{
          display: 'flex',
          margin: '0 auto',
          marginTop: '20px',
        }}
        onClick={() => {
          if (workflow?.selectedStep) {
            workflow?.nodes.map((node) => {
              node.conditions.map((condition) => {
                const found = condition.criteria.includes(workflow?.selectedStep.reference_id)
                if (found) {
                  node.setObjectID(source.id)
                }
              })
            })
            workflow?.selectedStep?.setObjectID(source.id)
            workflow?.selectedStep.setSource(source)
          } else {
            workflow?.viewSource(source)
          }
          workflow?.selectStep(undefined)
          setSource(null)
          workflow?.setEditing('')

          onUpdate()
        }}
        text={
          pathStore.workflow?.selectedStep
            ? pathStore.path
              ? 'Update Step'
              : 'Update Award'
            : pathStore.path
              ? 'Add Step'
              : 'Add Award'
        }
      />
      {showResourceDialog && (
        <ResourceUploadDialog
          onUpload={onUpload}
          close={() => setShowResourceDialog(false)}
          open={showResourceDialog}
          handleAddResource={handleAddResource}
        />
      )}
    </>
  )
})

export const CompleteQuiz = observer((props: any) => {
  const { onUpdate } = props

  const [quiz, setQuiz] = useState<any>()
  const [quizzes, setQuizzes] = useState<any>()
  const [loading, setLoading] = useState(true)

  const { pathStore } = useStores()
  const { workflow } = pathStore
  const [complete, setComplete] = useState('Complete')
  const [threshold, setThreshold] = useState(80)

  const loadQuizzes = async () => {
    const resp = await QuizApi.getAllQuizzes(
      'quizzes',
      false,
      [{ field: 'quiz_title' }],
      'list_quizzes',
    )
    if (resp) {
      setLoading(false)
    }
    setQuizzes(
      resp.data.rows
        .map((q) => {
          return {
            id: q.id,
            quiz_title: q.columns.find((column) => column.field === 'quiz_title').value,
            photo_url: q.columns.find((column) => column.field === 'photo_url').value,
          }
        })
        .sort((a, b) => (a.quiz_title > b.quiz_title ? 1 : -1)),
    )
  }

  useEffect(() => {
    loadQuizzes()
  }, [])

  useEffect(() => {
    if (workflow?.selectedStep && quizzes?.length) {
      const found = quizzes.find((q) => q.id === workflow?.selectedStep?.object_id)
      setQuiz(found)
      if (workflow?.selectedStep?.verb === 'complete') {
        setComplete('Complete')
      } else {
        setComplete('Score Over')
      }
    }
  }, [workflow?.selectedStep, quizzes])

  return (
    <>
      <div style={{ marginBottom: 20 }}>
        <Text text="Quiz" variant="titleEmphasized" style={{ color: color.shade70 }} />
      </div>
      <InputLabel id="select-action">Action</InputLabel>
      <Select
        variant="standard"
        labelId="select-action"
        displayEmpty
        fullWidth
        input={<TrivieDropdown />}
        MenuProps={{ PaperProps: { style: { borderRadius: 6, paddingTop: 0 } } }}
        IconComponent={ArrowDropDown}
        value={complete}
        onChange={(e: any) => {
          setTimeout(() => {
            // @ts-ignore
            document?.activeElement?.blur()
          }, 0)
          setComplete(e.target.value)
        }}
        style={STATUS_DROPDOWN}
      >
        <MenuItem value="Complete">
          <Text variant="body1" text="Complete" />
        </MenuItem>
        <MenuItem value="Score Over">
          <Text variant="body1" text="Score Over" />
        </MenuItem>
      </Select>
      {complete === 'Score Over' && (
        <TextField
          onChange={(e: any) => {
            if (e.target.value < 0) {
              setThreshold(0)
            } else if (e.target.value <= 100) {
              setThreshold(parseInt(e.target.value))
            }
          }}
          type="number"
          value={threshold}
          sx={{ marginBottom: '32px', background: color.white }}
          fullWidth
          placeholder="Threshold"
          label="Threshold %"
          variant="outlined"
        />
      )}
      {loading ? (
        <div style={{ display: 'flex', justifyContent: 'center', marginBottom: 33 }}>
          <CircularProgress />
        </div>
      ) : (
        <Autocomplete
          PaperComponent={CustomPaper}
          onChange={(e: any, value: any) => {
            const found = quizzes.find((quizItem: any) => value?.id === quizItem?.id) as any
            if (found) {
              setQuiz(found)
            } else {
              setQuiz(null)
            }
          }}
          getOptionLabel={(option: any) => option.quiz_title}
          renderOption={(autocompleteProps, options) => {
            return (
              <li {...autocompleteProps} key={`${options.quiz_title} ${options.id}`} style={ROW}>
                <BoltRounded style={ICON} />
                <Text variant="body1" text={options.quiz_title} />
              </li>
            )
          }}
          sx={{ background: color.white }}
          key={quiz}
          defaultValue={quiz}
          value={quiz}
          options={quizzes}
          clearOnEscape
          renderInput={(params) => {
            return (
              <TextField
                {...params}
                InputProps={{
                  ...params.InputProps,
                  startAdornment: (
                    <InputAdornment position="start">
                      <BoltRounded htmlColor={color.shade70} />
                    </InputAdornment>
                  ),
                }}
                label="Select Quiz"
                placeholder="No Quiz Selected"
                variant="outlined"
              />
            )
          }}
        />
      )}
      <Button
        disabled={!quiz || (complete === 'Score Over' && !threshold)}
        sx={{
          display: 'flex',
          margin: '0 auto',
          marginTop: '20px',
        }}
        variant="contained"
        onClick={() => {
          if (workflow?.selectedStep) {
            workflow?.selectedStep?.setObjectID(quiz.id)
            workflow?.selectedStep.setQuiz(quiz)
            workflow?.nodes.map((node) => {
              node.conditions.map((condition) => {
                const found = condition.criteria.includes(workflow?.selectedStep.reference_id)
                if (found) {
                  node.setObjectID(quiz.id)
                  node.setLabel(complete === 'Complete' ? 'Complete Quiz' : `Score X% + on Quiz`)
                }
              })
            })
            if (complete !== 'Complete') {
              workflow?.selectedStep.setValue(threshold)
              workflow?.selectedStep.setVerb('score')
              workflow?.selectedStep.setOperator(threshold < 100 ? 'gte' : 'eq')
              workflow?.selectedStep.setLabel(`Score ${threshold}%+ on Quiz`)
              workflow?.selectedStep.setConditionName(`score_${threshold}_on_all_quizzes`)
            } else {
              workflow?.selectedStep.setLabel(`Complete Quiz`)
              workflow?.selectedStep.setVerb('complete')
              workflow?.selectedStep.setOperator(null)
              workflow?.selectedStep.setConditionName(`complete_quiz`)
            }
          } else {
            if (complete === 'Complete') {
              workflow?.completeQuiz(quiz)
            } else {
              workflow?.scoreOverQuiz(quiz, threshold)
            }
          }
          workflow?.selectStep(undefined)
          setQuiz(null)
          workflow?.setEditing('')
          onUpdate()
        }}
        text={
          pathStore.workflow?.selectedStep
            ? pathStore.path
              ? 'Update Step'
              : 'Update Award'
            : pathStore.path
              ? 'Add Step'
              : 'Add Award'
        }
      />
    </>
  )
})

export const CompleteTopic = observer((props: any) => {
  const { onUpdate } = props

  const [topic, setTopic] = useState<any>()
  const [topics, setTopics] = useState<any>()
  const [complete, setComplete] = useState('Complete')
  const [threshold, setThreshold] = useState(80)
  const [loading, setLoading] = useState(true)

  const { pathStore } = useStores()
  const { workflow } = pathStore

  const loadTopics = async () => {
    const resp = await TopicApi.getAllTopics(
      'topics',
      false,
      [{ field: 'topic_title' }],
      'list_topics',
    )
    if (resp) {
      setLoading(false)
    }
    setTopics(
      resp.data.rows
        .map((q) => {
          return {
            id: q.id,
            topic_title: q.columns.find((column) => column.field === 'topic_title').value,
            photo_url: q.columns.find((column) => column.field === 'photo_url').value,
          }
        })
        .sort((a, b) => (a.topic_title > b.topic_title ? 1 : -1)),
    )
  }

  useEffect(() => {
    loadTopics()
  }, [])

  useEffect(() => {
    if (workflow?.selectedStep && topics?.length) {
      const found = topics.find((b) => b.id === workflow?.selectedStep?.object_id)
      setTopic(found)
      // @ts-ignore
      if (workflow?.selectedStep?.verb === 'complete') {
        setComplete('Complete')
      } else {
        setComplete('Score Over')
        // @ts-ignore
        setThreshold(workflow?.selectedStep?.value)
      }
    }
  }, [workflow?.selectedStep, topics])

  return (
    <>
      <div style={{ marginBottom: 20 }}>
        <Text text="Topic" variant="titleEmphasized" style={{ color: color.shade70 }} />
      </div>
      <InputLabel id="select-action">Action</InputLabel>
      <Select
        labelId="select-action"
        variant="standard"
        displayEmpty
        fullWidth
        input={<TrivieDropdown />}
        MenuProps={{ PaperProps: { style: { borderRadius: 6, paddingTop: 0 } } }}
        IconComponent={ArrowDropDown}
        value={complete}
        onChange={(e: any) => {
          setTimeout(() => {
            // @ts-ignore
            document?.activeElement?.blur()
          }, 0)
          setComplete(e.target.value)
        }}
        style={STATUS_DROPDOWN}
      >
        <MenuItem value="Complete">
          <Text variant="body1" text="Complete" />
        </MenuItem>
        <MenuItem value="Score Over">
          <Text variant="body1" text="Score Over" />
        </MenuItem>
      </Select>
      {complete === 'Score Over' && (
        <TextField
          onChange={(e: any) => {
            if (e.target.value < 0) {
              setThreshold(0)
            } else if (e.target.value <= 100) {
              setThreshold(parseInt(e.target.value))
            }
          }}
          type="number"
          value={threshold}
          sx={{ marginBottom: '32px', background: color.white }}
          fullWidth
          placeholder="Threshold"
          label="Threshold %"
          variant="outlined"
        />
      )}
      {loading ? (
        <div style={{ display: 'flex', justifyContent: 'center', marginBottom: 33 }}>
          <CircularProgress />
        </div>
      ) : (
        <Autocomplete
          PaperComponent={CustomPaper}
          onChange={(e: any, value: any) => {
            const found = topics.find((topicItem: any) => topicItem?.id === value?.id) as any
            if (found) {
              setTopic(found)
            } else {
              setTopic(null)
            }
          }}
          getOptionLabel={(option: any) => option.topic_title}
          renderOption={(autocompleteProps, options) => {
            return (
              <li {...autocompleteProps} key={`${options.topic_title} ${options.id}`} style={ROW}>
                <TopicRounded style={ICON} />
                <Text variant="body1" text={options.topic_title} />
              </li>
            )
          }}
          key={topic}
          defaultValue={topic}
          value={topic}
          options={topics}
          clearOnEscape
          renderInput={(params) => {
            return (
              <TextField
                label="Select Topic"
                {...params}
                InputProps={{
                  ...params.InputProps,
                  startAdornment: (
                    <InputAdornment position="start">
                      <TopicRounded htmlColor={color.shade70} />
                    </InputAdornment>
                  ),
                }}
                sx={{ background: color.white }}
                placeholder="No Topic Selected"
                variant="outlined"
              />
            )
          }}
        />
      )}
      <Button
        disabled={!topic || (complete === 'Score Over' && !threshold)}
        sx={{
          display: 'flex',
          margin: '0 auto',
          marginTop: '20px',
        }}
        onClick={() => {
          if (workflow?.selectedStep) {
            workflow?.selectedStep?.setObjectID(topic.id)
            workflow?.selectedStep.setTopic(topic)
            workflow?.nodes.map((node) => {
              node.conditions.map((condition) => {
                const found = condition.criteria.includes(workflow?.selectedStep.reference_id)
                if (found) {
                  node.setObjectID(topic.id)
                  node.setLabel(complete === 'Complete' ? 'Complete Topic' : `Score X% + on Topic`)
                }
              })
            })
            if (complete !== 'Complete') {
              workflow?.selectedStep.setValue(threshold)
              workflow?.selectedStep.setVerb('score')
              workflow?.selectedStep.setOperator(threshold < 100 ? 'gte' : 'eq')
              workflow?.selectedStep.setLabel(`Score ${threshold}% + on Topic`)
              workflow?.selectedStep.setConditionName(`score_${threshold}_on_all_quizzes`)
            } else {
              workflow?.selectedStep.setLabel(`Complete Topic`)
              workflow?.selectedStep.setVerb('complete')
              workflow?.selectedStep.setOperator(null)
              workflow?.selectedStep.setConditionName(`complete_topic`)
            }
          } else {
            if (complete === 'Complete') {
              workflow?.completeTopic(topic)
            } else {
              workflow?.scoreOverTopic(topic, threshold)
            }
          }
          workflow?.selectStep(undefined)
          setTopic(null)
          workflow?.setEditing('')
          onUpdate()
        }}
        text={
          pathStore.workflow?.selectedStep
            ? pathStore.path
              ? 'Update Step'
              : 'Update Award'
            : pathStore.path
              ? 'Add Step'
              : 'Add Award'
        }
      />
    </>
  )
})

export const AwardBadge = observer((props: any) => {
  const { onUpdate } = props

  const [userVisible, setUserVisible] = useState(true)
  const [badge, setBadge] = useState<any>()
  const [badges, setBadges] = useState<any>()
  const [description, setDescription] = useState<any>('')
  const [loading, setLoading] = useState(true)
  const [showCreateBadge, setShowCreateBadge] = useState(false)
  const [hoveringNew, setHoveringNew] = useState(false)

  const { pathStore, badgesStore } = useStores()
  const { createBadge } = badgesStore
  const { workflow } = pathStore

  useEffect(() => {
    if (showCreateBadge === true) {
      setBadge(null)
    }
  }, [showCreateBadge])

  const handleSaveBadge = (newBadge: any) => {
    createBadge(newBadge).then(() => {
      setShowCreateBadge(false)
      loadBadges().then((data) => {
        const found = data.find((b: any) => b.label === newBadge?.label) as any
        if (found) {
          setBadge(found)
        }
      })
    })
  }

  const loadBadges = async () => {
    const allBadges = await BadgeApi.getAllBadges(
      'users',
      false,
      undefined,
      'list_custom_badges',
      undefined,
      'custom',
    )

    if (allBadges) {
      setLoading(false)
    }

    const badgeList = [] as any
    allBadges.data?.rows.map((badgeRow) => {
      const badge_title = badgeRow.columns.find((c) => c.field === 'badge_title')
      const photo_url = badgeRow.columns.find((c) => c.field === 'photo_url')
      badgeList.push({ label: badge_title.value, photo_url: photo_url.value, id: badgeRow.id })
    })
    setBadges(badgeList)
    return badgeList
  }

  const updateStep = () => {
    if (workflow?.selectedStep) {
      workflow?.selectedStep?.setObjectID(badge.id)
      workflow?.selectedStep?.setBadge(badge)
      workflow?.selectedStep?.setDescription(description)
      workflow?.selectedStep?.setUserVisible(userVisible)
    } else if (!workflow) {
      pathStore.createWorkflow()
      workflow?.awardBadge(badge, userVisible, description)
    } else {
      workflow.awardBadge(badge, userVisible, description)
    }
    workflow?.selectStep(undefined)
    workflow?.setEditing('')
    onUpdate()
  }

  useEffect(() => {
    loadBadges()
  }, [])

  useEffect(() => {
    if (workflow?.selectedStep && badges?.length) {
      const found = badges.find((b) => b.id === workflow?.selectedStep?.object_id)
      setBadge(found)
      setDescription(workflow?.selectedStep.description)
      setUserVisible(Boolean(workflow?.selectedStep?.user_visible))
    }
  }, [workflow?.selectedStep, badges])

  return (
    <>
      <div style={{ marginBottom: 20 }}>
        <Text text="Award Badge:" variant="titleEmphasized" style={{ color: color.shade70 }} />
      </div>
      {loading ? (
        <div style={{ display: 'flex', justifyContent: 'center', marginBottom: 33 }}>
          <CircularProgress />
        </div>
      ) : (
        <Autocomplete
          sx={{
            background: color.white,
            marginBottom: '20px',
            '& .MuiAutocomplete-noOptions': {
              padding: 0,
            },
          }}
          PaperComponent={CustomPaper}
          onChange={(e: any, value: any) => {
            const found = badges.find((b: any) => b?.id === value?.id) as any
            if (found) {
              setBadge(found)
            } else {
              setBadge(null)
            }
          }}
          getOptionLabel={(option: any) => option.label}
          noOptionsText={
            <ListItem
              key="new"
              onMouseEnter={() => setHoveringNew(true)}
              onMouseLeave={() => setHoveringNew(false)}
              onClick={(e) => setShowCreateBadge(true)}
              sx={{
                ...ROW,
                ':hover': { backgroundColor: 'rgba(0, 0, 0, 0.04)' },
              }}
            >
              <Add style={{ right: 4, position: 'relative' }} />
              <Text style={{ right: 8, position: 'relative' }} variant="body1" text="Create New" />
            </ListItem>
          }
          renderOption={(autocompleteProps, options, state) => {
            return (
              <>
                {state.index === 0 && (
                  <ListItem
                    key="new"
                    onMouseEnter={() => setHoveringNew(true)}
                    onMouseLeave={() => setHoveringNew(false)}
                    onClick={(e) => setShowCreateBadge(true)}
                    sx={{
                      ...ROW,
                      ':hover': { backgroundColor: 'rgba(0, 0, 0, 0.04)' },
                    }}
                  >
                    <Add style={{ right: 4, position: 'relative' }} />
                    <Text
                      style={{ right: 8, position: 'relative' }}
                      variant="body1"
                      text="Create New"
                    />
                  </ListItem>
                )}
                <ListItem
                  {...autocompleteProps}
                  sx={{
                    ...ROW,
                    ':hover': {
                      backgroundColor: hoveringNew ? '#fff' : 'rgba(0,0,0,0.04) !important',
                    },
                  }}
                >
                  <img
                    style={{ height: 16, width: 16, borderRadius: 100 }}
                    src={options.photo_url}
                  />
                  <Text variant="body1" text={options.label} />
                </ListItem>
              </>
            )
          }}
          key={badge}
          defaultValue={badge}
          value={badge}
          options={badges}
          clearOnEscape
          renderInput={(params) => (
            <TextField
              {...params}
              InputProps={{
                ...params.InputProps,
                startAdornment: (
                  <InputAdornment position="start">
                    {badge ? (
                      <img
                        style={{ height: 24, width: 24, borderRadius: 100 }}
                        src={badge.photo_url}
                      />
                    ) : (
                      <WorkspacePremium htmlColor={color.shade70} />
                    )}
                  </InputAdornment>
                ),
              }}
              label="Select Badge"
              placeholder="No Badge Selected"
              variant="outlined"
            />
          )}
        />
      )}
      <div>
        <TextField
          sx={{ background: color.white, marginBottom: '20px' }}
          placeholder="Add a description"
          variant="outlined"
          label={description ? 'Description' : ''}
          multiline
          value={description}
          onChange={(e) => setDescription(e.target.value)}
          fullWidth
        />
      </div>
      <Button
        disabled={!badge}
        sx={{ display: 'flex', margin: '0 auto' }}
        onClick={updateStep}
        text={
          pathStore.workflow?.selectedStep
            ? pathStore.path
              ? 'Update Step'
              : 'Update Award'
            : pathStore.path
              ? 'Add Step'
              : 'Add Award'
        }
      />
      {showCreateBadge && (
        <CreateEditBadge
          open={showCreateBadge}
          onClose={() => setShowCreateBadge(false)}
          onSaveBadge={handleSaveBadge}
        />
      )}
    </>
  )
})

export const AwardCertification = observer((props: any) => {
  const { onUpdate } = props

  const { pathStore } = useStores()
  const { workflow } = pathStore
  const [certificationTitle, setCertificationTitle] = useState('')
  const [userVisible, setUserVisible] = useState(true)
  const [description, setDescription] = useState<any>('')

  const [error, setError] = useState('')

  useEffect(() => {
    if (workflow?.selectedStep) {
      setCertificationTitle(workflow?.selectedStep?.label)
      setDescription(workflow?.selectedStep.description)
      setUserVisible(Boolean(workflow?.selectedStep?.user_visible))
    }
  }, [workflow?.selectedStep])

  const handleAwardCert = async () => {
    try {
      const resp = await WorkflowApi.createCertification(certificationTitle)
      const certification = resp.data
      workflow?.setEditing('')
      // undefined topic id default for topic flow, will be updated on publish
      // TODO: if coming from path - grab parent node object_id
      workflow?.awardCertification(certificationTitle, certification, userVisible, description)
    } catch (e) {
      // @ts-ignore
      setError(e.message)
    }
  }

  return (
    <>
      <div style={{ marginBottom: 20 }}>
        <Text
          text="Award Certifications:"
          variant="titleEmphasized"
          style={{ color: color.shade70 }}
        />
      </div>
      <div>
        <TextField
          placeholder="Certification Name"
          variant="standard"
          multiline
          fullWidth
          value={certificationTitle}
          onChange={(e) => setCertificationTitle(e.target.value)}
          sx={{ marginBottom: '20px' }}
        />
      </div>
      <div>
        <TextField
          sx={{ background: color.white, marginBottom: '20px' }}
          placeholder="Add a description"
          variant="outlined"
          label={description ? 'Description' : ''}
          multiline
          value={description}
          onChange={(e) => setDescription(e.target.value)}
          fullWidth
        />
      </div>
      <div style={{ textAlign: 'center' }}>
        <Text
          text="The user will be awarded a tiered certification based on their score on the quizzes in the topic."
          variant="body1Medium"
        />
      </div>
      <div
        style={{
          width: 254,
          height: 192,
          alignSelf: 'center',
          background: '#fff',
          flexDirection: 'column',
          borderRadius: 20,
          display: 'flex',
          margin: '20px auto',
          justifyContent: 'center',
        }}
      >
        <div style={{ height: 48, flexDirection: 'row', display: 'flex' }}>
          <Medallion style={{ left: -12, position: 'relative' }} scale={0.4} type="Gold" />
          <div
            style={{
              alignSelf: 'center',
              flexDirection: 'column',
              display: 'flex',
              left: -32,
              position: 'relative',
            }}
          >
            <Text text="Gold" variant="body2Emphasized" />
            <Text
              text="Score 100% on all Quizzes"
              variant="body2Medium"
              style={{ color: '#9098A3' }}
            />
          </div>
        </div>
        <div style={{ height: 48, flexDirection: 'row', display: 'flex' }}>
          <Medallion style={{ left: -12, position: 'relative' }} scale={0.4} type="Silver" />
          <div
            style={{
              alignSelf: 'center',
              flexDirection: 'column',
              display: 'flex',
              left: -32,
              position: 'relative',
            }}
          >
            <Text text="Silver" variant="body2Emphasized" />
            <Text
              text="Score 90%+ on all Quizzes"
              variant="body2Medium"
              style={{ color: '#9098A3' }}
            />
          </div>
        </div>
        <div style={{ height: 48, flexDirection: 'row', display: 'flex' }}>
          <Medallion style={{ left: -12, position: 'relative' }} scale={0.4} type="Bronze" />
          <div
            style={{
              alignSelf: 'center',
              flexDirection: 'column',
              display: 'flex',
              left: -32,
              position: 'relative',
            }}
          >
            <Text text="Bronze" variant="body2Emphasized" />
            <Text
              text="Score 80%+ on all Quizzes"
              variant="body2Medium"
              style={{ color: '#9098A3' }}
            />
          </div>
        </div>
      </div>
      <Button
        disabled={!certificationTitle}
        sx={{
          display: 'flex',
          margin: '0 auto',
        }}
        onClick={async () => {
          if (workflow?.selectedStep) {
            try {
              // label has changed - attempt to create a new cert
              if (certificationTitle !== workflow?.selectedStep.label) {
                const resp = await WorkflowApi.createCertification(certificationTitle)
                const certification = resp.data
                if (certification) {
                  workflow?.updateCertification(
                    workflow.selectedStep,
                    certificationTitle,
                    userVisible,
                    description,
                    certification,
                  )
                }
              } else {
                workflow?.updateCertification(
                  workflow.selectedStep,
                  certificationTitle,
                  userVisible,
                  description,
                )
              }
              workflow?.selectStep(undefined)
              workflow?.setEditing('')
            } catch (e) {
              // @ts-ignore
              setError(e.message)
            }
          } else if (!workflow) {
            pathStore.createWorkflow()
            workflow?.selectedStep?.setLabel(certificationTitle)
            workflow?.selectedStep?.setDescription(description)
            workflow?.selectedStep?.setUserVisible(userVisible)
            workflow?.selectStep(undefined)
            setCertificationTitle('New Certification')
            workflow?.setEditing('')
          } else {
            handleAwardCert()
          }
          onUpdate()
        }}
        text={
          pathStore.workflow?.selectedStep
            ? pathStore.path
              ? 'Update Step'
              : 'Update Award'
            : pathStore.path
              ? 'Add Step'
              : 'Add Award'
        }
      />
      {error && (
        <ConfirmDialog
          danger
          open={Boolean(error)}
          title="Duplicate Certification Title"
          message={error}
          confirmButton="Okay"
          onConfirm={() => {
            setError('')
            workflow?.selectStep(undefined)
            setCertificationTitle('')
            workflow?.setEditing('')
          }}
          onClose={() => {
            setError('')
            workflow?.selectStep(undefined)
            setCertificationTitle('')
            workflow?.setEditing('')
          }}
        />
      )}
    </>
  )
})

const TrivieDropdown = styled(InputBase)(({ theme }) => ({
  '& .MuiInputBase-input': {
    width: '100%',
    borderRadius: 6,
    position: 'relative',
    backgroundColor: color.white,
    fontSize: 16,
    padding: '10px 26px 10px 12px',
    lineHeight: '22px',
    minHeight: '0px !important',
  },
  '& .MuiPopover-root': {
    height: 400,
  },
}))

const CustomPaper: React.FunctionComponent = ({ children }: any) => {
  return (
    <Paper
      sx={{
        borderRadius: '12px',
        borderTopLeftRadius: '0px',
        borderTopRightRadius: '0px',
        boxShadow: `0px 5px 20px rgba(0,0,0,.25)`,
        '& > .MuiAutocomplete-noOptions': {
          padding: 0,
        },
      }}
    >
      {children}
    </Paper>
  )
}
const ROW: React.CSSProperties = {
  cursor: 'pointer',
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  gap: '16px',
  padding: '12px !important',
  minHeight: '48px !important',
}
const SWITCH_ROW: React.CSSProperties = {
  display: 'flex',
  marginTop: 20,
  marginBottom: 20,
  alignItems: 'center',
  justifyContent: 'space-between',
}
const ICON: React.CSSProperties = { fontSize: 18, marginRight: 8, color: color.shade70 }
const STATUS_DROPDOWN: React.CSSProperties = {
  border: `1px solid ${color.shade20}`,
  borderRadius: 4,
  marginBottom: 32,
  height: '53.13px',
  background: color.white,
}

const ICON_IMAGE: React.CSSProperties = {
  borderRadius: 4,
  marginRight: 12,
  objectFit: 'cover',
  width: 32,
  height: 32,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  background: color.shade20,
}
const RESOURCE_ICON: React.CSSProperties = {
  borderRadius: 4,
  display: 'flex',
  alignItems: 'center',
  flexDirection: 'row',
  padding: 6,
  marginRight: 0,
}
