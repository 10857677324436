import { types } from 'mobx-state-tree'
import { ChoiceForQuizStartModel } from './ChoiceForQuizStartModel'

/**
 * QuestionsForQuizStart
 */
export const QuestionsForQuizStartModel = types.model('QuestionsForQuizStart').props({
  __typename: types.optional(types.literal('QuestionsForQuizStart'), 'QuestionsForQuizStart'),
  choices: types.array(
    types.union(
      types.null,
      types.late(() => ChoiceForQuizStartModel),
    ),
  ),
  factoid: types.maybeNull(types.string),
  id: types.integer,
  powerups: types.maybeNull(types.array(types.maybeNull(types.frozen()))),
  text: types.string,
})
