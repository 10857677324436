import { Instance, getParentOfType, types } from 'mobx-state-tree'

export const CertificationCondition = types
  .model('CertificationCondition')
  .props({
    condition_name: types.enumeration([
      'complete_topic',
      'score_100_on_all_quizzes',
      'score_90_on_all_quizzes',
      'score_80_on_all_quizzes',
    ]),
    label: types.enumeration([
      'Complete Topic',
      'Score 100% on all quizzes',
      'Score 90% + on all quizzes',
      'Score 80% + on all quizzes',
    ]),
    value: types.maybeNull(types.number),
    verb: types.enumeration(['complete', 'score', 'award']),
    operator: types.maybeNull(types.enumeration(['eq', 'gte', 'lte', 'gt', 'lt'])),
    object_type: types.enumeration(['Quiz']),
    parent_object_type: types.enumeration(['Topic']),
    parent_object_id: types.number,
    reference_id: types.string,
  })
  .actions((self) => ({
    setName(name: typeof self.condition_name) {
      self.condition_name = name
    },
    setLabel(label: typeof self.label) {
      self.label = label
    },
    setVerb(verb: typeof self.verb) {
      self.verb = verb
    },
    setOperator(operator: typeof self.operator) {
      self.operator = operator
    },
    setObjectType(type: typeof self.object_type) {
      self.object_type = type
    },
    setParentObjectType(type: typeof self.parent_object_type) {
      self.parent_object_type = type
    },
    setParentObjectID(id: typeof self.parent_object_id) {
      self.parent_object_id = id
    },
    setReferenceID(id: typeof self.reference_id) {
      self.reference_id = id
    },
    remove() {
      // getParentOfType(self, Certification).removeCondition(self)
    },
  }))

export const CertificationNodeCondition = types.model('CertificationNodeCondition').props({
  name: types.enumeration(['all']),
  criteria: types.array(
    types.enumeration([
      'complete_topic',
      'score_100_on_all_quizzes',
      'score_90_on_all_quizzes',
      'score_80_on_all_quizzes',
    ]),
  ),
})

export const CertificationNode = types.model('CertificationNode').props({
  label: types.string,
  object_type: types.enumeration(['Topic']),
  object_id: types.number,
  required: types.boolean,
  conditions: types.array(CertificationNodeCondition),
  reference_id: types.string,
  actions: types.maybeNull(types.array(types.string)),
})

export const CertificationLevel = types.model('CertificationLevel').props({
  label: types.string,
  level_name: types.string,
  order: types.number,
  conditions: types.array(CertificationNodeCondition),
  reference_id: types.string,
})

export const Action = types.model('Action').props({
  reference_id: types.string,
  action_type: types.string,
  force_evaluate: types.boolean,
  label: types.string,
  sub_conditions: types.frozen(),
  object_id: types.number,
})

export const CertificationCriteria = types
  .model('CertificationCriteria')
  .props({
    version: types.number,
    certification_name: types.string,
    conditions: types.array(CertificationCondition),
    nodes: types.array(CertificationNode),
    actions: types.maybeNull(types.array(Action)),
    levels: types.maybeNull(types.array(CertificationLevel)),
  })
  .views((self) => ({
    get hasCert() {
      return self.levels && self.levels?.length > 0
    },
    get hasBadge() {
      return self.actions && self.actions?.length > 0
    },
    get badge() {
      return self.actions && self.actions[0].object_id
    },
  }))
  .actions((self) => ({
    setVersion(version: number) {
      self.version = version
    },
    addCondition(condition: Instance<typeof CertificationCondition>) {
      self.conditions.push(condition)
    },
    addNode(node: Instance<typeof CertificationNode>) {
      self.nodes.push(node)
    },
    addLevel(level: Instance<typeof CertificationLevel>) {
      if (self.levels) {
        self.levels.push(level)
      } else {
        self.levels = [level] as any
      }
    },
    // removeCondition() {
    //   getParentOfType(self, Certification).removeCondition(self)
    // },
  }))

export const Certification = types
  .model('Certification')
  .props({
    criteria: CertificationCriteria,
    title: types.string,
  })
  .actions((self) => ({
    setVersion(version: number) {
      self.criteria.setVersion(version)
    },
    addCondition(condition: Instance<typeof CertificationCondition>) {
      self.criteria.addCondition(condition)
    },
    addNode(node: Instance<typeof CertificationNode>) {
      self.criteria.addNode(node)
    },
    addLevel(level: Instance<typeof CertificationLevel>) {
      self.criteria.addLevel(level)
    },
    // removeCondition(condition: Instance<typeof CertificationCondition>) {
    //   destroy(question)
    // },
  }))
