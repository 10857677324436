import React, { useEffect, useState } from 'react'
import { DateTime } from 'luxon'
import { observer } from 'mobx-react-lite'
import { Instance } from 'mobx-state-tree'
import { Divider, Grid, Stack, useMediaQuery } from '@mui/material'

import { getStrongestWeakest, roundNumber, useStores } from '@trivie/core'
import { QuestionPctCorrectSummary } from '@trivie/core/src/models/QuestionPctCorrectSummary'
import { TopicPctCorrectSummary } from '@trivie/core/src/models/TopicPctCorrectSummary'
import { AssignmentApi } from '@trivie/core/src/services/api-objects/AssignmentApi'
import { TopicApi } from '@trivie/core/src/services/api-objects/TopicApi'
import { StrongestWeakest } from '@trivie/ui-web/components/strongest-weakest'
import { Text } from '@trivie/ui-web/components/text'
import { AssignmentDetailPanel } from '../components/assignment-detail-panel'
import { CompletionChart } from '@trivie/ui-web/components/completion-chart'
import { Panel } from '@trivie/ui-web/components/panel/panel'

const Overview = observer((props: { assignmentID: number; mainTab?: string }) => {
  const { mainTab, assignmentID } = props

  const { assignmentStore, manageLeaderboardStore, manageUserStore } = useStores()
  const { getAssignmentDetails, assignmentOverviewStats } = assignmentStore
  const { getLeaderboardByObject, userLeaderboard, groupLeaderboard } = manageLeaderboardStore

  const [groups, setGroups] = useState([])
  const [users, setUsers] = useState([])
  const [content, setContent] = useState()
  const [uniqueCount, setUniqueCount] = useState()
  const [analytics, setAnalytics] = useState<any>()

  const [strongestTopics, setStrongestTopics] = useState<null | Array<
    Instance<typeof TopicPctCorrectSummary>
  >>(null)
  const [weakestTopics, setWeakestTopics] = useState<null | Array<
    Instance<typeof TopicPctCorrectSummary>
  >>(null)
  const [strongestQuestions, setStrongestQuestions] = useState<null | Array<
    Instance<typeof QuestionPctCorrectSummary>
  >>(null)
  const [weakestQuestions, setWeakestQuestions] = useState<null | Array<
    Instance<typeof QuestionPctCorrectSummary>
  >>(null)

  const handleAnalytics = (assignment) => {
    if (assignment) {
      const not_started = assignment.columns.find(
        (c) => c.field === 'assignment_pct_not_started',
      ).value
      const started = assignment.columns.find((c) => c.field === 'assignment_pct_started').value
      const completed = assignment.columns.find((c) => c.field === 'assignment_pct_completed').value
      const pct_correct = assignment.columns.find((c) => c.field === 'assignment_pct_correct').value
      const pre_test = assignment.columns.find(
        (c) => c.field === 'assignment_pre_test_pct_correct',
      ).value
      const post_test = assignment.columns.find(
        (c) => c.field === 'assignment_post_test_pct_correct',
      ).value
      const has_pre_test = assignmentOverviewStats?.includes_pre_test
      const has_post_test = assignmentOverviewStats?.includes_post_test
      const started_count = assignment.columns.find(
        (c) => c.field === 'assignment_started_user_count',
      ).value
      const completed_count = assignment.columns.find(
        (c) => c.field === 'assignment_completed_user_count',
      ).value
      const not_started_count = assignment.columns.find(
        (c) => c.field === 'assignment_not_started_user_count',
      ).value
      const total_answers = assignment.columns.find(
        (c) => c.field === 'assignment_total_answers',
      ).value

      const chart_data = [
        {
          value: roundNumber(completed * 100, 1),
          users: completed_count,
          name: 'Completed',
        },
        {
          value: roundNumber(started * 100, 1),
          users: started_count,
          name: 'In Progress',
        },
        {
          value: roundNumber(not_started * 100, 1),
          users: not_started_count,
          name: 'Not Started',
        },
      ]

      setAnalytics({
        chart_data,
        pct_correct,
        pre_test,
        post_test,
        has_pre_test,
        has_post_test,
        total_answers,
      })
    }
  }
  const loadOverview = async (start: string, end: string) => {
    AssignmentApi.getAllAssignments(
      'assignments',
      false,
      [
        { field: 'assignment_pct_started' },
        { field: 'assignment_pct_not_started' },
        { field: 'assignment_pct_completed' },
        { field: 'assignment_pct_correct' },
        { field: 'assignment_post_test_pct_correct' },
        { field: 'assignment_pre_test_pct_correct' },
        { field: 'assignment_not_started_user_count' },
        { field: 'assignment_started_user_count' },
        { field: 'assignment_completed_user_count' },
        { field: 'assignment_total_answers' },
      ],
      `list_${mainTab}_assignments`,
      undefined,
      mainTab as any,
      Number(assignmentID),
    ).then((resp) => {
      handleAnalytics(resp.data.rows.find((row) => row.id === Number(assignmentID)))
    })

    getAssignmentDetails(assignmentID, start, end)
    getLeaderboardByObject(assignmentID, 'assignment', start, end)

    AssignmentApi.getAssignmentDetailsContent(assignmentID).then((resp) => {
      setContent(resp.data)
    })

    AssignmentApi.getAssignmentAssignees(assignmentID).then((resp) => {
      setGroups(resp.data.groups)
      setUsers(resp.data.users)
      setUniqueCount(resp.data.metadata.unique_user_count)
    })

    AssignmentApi.getAssignmentQuestionGaps(assignmentID, start, end).then((response) => {
      if (response.ok && response.data) {
        const strongestWeakestQuestions = getStrongestWeakest(response.data.questions)
        setStrongestQuestions(strongestWeakestQuestions.strongest)
        setWeakestQuestions(strongestWeakestQuestions.weakest)
      } else {
        throw new Error(`Couldn't get assessment stats`)
      }
    })

    TopicApi.getAllTopics(
      'assignments',
      false,
      [{ field: 'topic_title' }, { field: 'assign_topic_pct_correct' }],
      'list_assignment_topics',
      assignmentID,
    ).then((resp) => {
      const topics = resp.data.rows.map((t: any) => {
        const title = t.columns.find((column) => column.field === 'topic_title')
        const photo = t.columns.find((column) => column.field === 'photo_url')
        const pct_correct = t.columns.find((column) => column.field === 'assign_topic_pct_correct')
        return {
          topic_id: t.id,
          pack_color: null,
          title: title.value,
          pct_correct: pct_correct.value * 100,
          image_url: photo.value,
        }
      })
      const strongestWeakestTopics = getStrongestWeakest(topics)
      setStrongestTopics(strongestWeakestTopics.strongest)
      setWeakestTopics(strongestWeakestTopics.weakest)
    })
  }

  useEffect(() => {
    loadOverview(
      DateTime.local(2021).startOf('day').toJSDate().toISOString(),
      DateTime.local().endOf('day').toJSDate().toISOString(),
    )
  }, [])

  const min1200 = useMediaQuery('(min-width:1200px)')

  return (
    <>
      <Text
        component="h2"
        sx={{ mb: '20px' }}
        variant="titleEmphasized"
        text="Assignment Information"
      />
      <AssignmentDetailPanel
        content={content}
        assignment={assignmentOverviewStats}
        assignees={{ groups, users }}
        count={uniqueCount}
        scheduled={mainTab === 'scheduled'}
        completed={mainTab === 'completed'}
      />
      <Text sx={{ my: '20px' }} variant="titleEmphasized" text="Analytics" component="h2" />
      <Grid container spacing={2}>
        {analytics && (
          <>
            <Grid item xs={12} sm={12} md={min1200 ? 7 : 12}>
              <Panel title="Assignment Completion Status">
                <CompletionChart data={analytics.chart_data} />
              </Panel>
            </Grid>
            <Grid item xs={12} sm={12} md={min1200 ? 5 : 12}>
              <Panel fill title="Statistics">
                <Stack flexWrap="wrap" gap={3} useFlexGap divider={<Divider />}>
                  <Stack useFlexGap gap={3} direction="row">
                    <Stack flex={1} gap={3} useFlexGap>
                      <Text text="Questions Answered" variant="body1Medium" />
                      <Text
                        color="text.secondary"
                        text={String(Number(analytics?.total_answers))}
                        variant="headline"
                      />
                    </Stack>
                    {assignmentOverviewStats?.includes_pre_test &&
                      assignmentOverviewStats?.includes_post_test && (
                        <Stack flex={1} useFlexGap gap={3}>
                          <Text text="Correct %" variant="body1Medium" />
                          <Text
                            color="text.secondary"
                            text={`${roundNumber(analytics?.pct_correct * 100, 1)}%`}
                            variant="headline"
                          />
                        </Stack>
                      )}
                  </Stack>
                  <Stack useFlexGap gap={3} direction="row">
                    {!assignmentOverviewStats?.includes_pre_test &&
                      !assignmentOverviewStats?.includes_post_test && (
                        <Stack flex={1} useFlexGap gap={3}>
                          <Text text="Correct %" variant="body1Medium" />
                          <Text
                            color="text.secondary"
                            text={`${roundNumber(analytics?.pct_correct * 100, 1)}%`}
                            variant="headline"
                          />
                        </Stack>
                      )}
                    {assignmentOverviewStats?.includes_pre_test && (
                      <Stack flex={1} useFlexGap gap={3}>
                        <Text text="Pre-Test Average" variant="body1Medium" />
                        <Text
                          color="text.secondary"
                          text={`${roundNumber(analytics?.pre_test * 100, 1)}%`}
                          variant="headline"
                        />
                      </Stack>
                    )}
                    {assignmentOverviewStats?.includes_post_test && (
                      <Stack flex={1} useFlexGap gap={3}>
                        <Text text="Post-Test Average" variant="body1Medium" />
                        <Text
                          color="text.secondary"
                          text={`${roundNumber(analytics?.post_test * 100, 1)}%`}
                          variant="headline"
                        />
                      </Stack>
                    )}
                  </Stack>
                </Stack>
              </Panel>
            </Grid>
          </>
        )}
        {weakestTopics?.length || weakestQuestions?.length ? (
          <Grid item md={6} sm={12} xs={12}>
            <StrongestWeakest
              type="Weakest"
              variant="medium"
              topics={weakestTopics ?? []}
              questions={weakestQuestions ?? []}
              style={{ height: '100%' }}
            />
          </Grid>
        ) : null}
        {strongestTopics?.length || strongestQuestions?.length ? (
          <Grid item md={6} sm={12} xs={12}>
            <StrongestWeakest
              type="Strongest"
              variant="medium"
              topics={strongestTopics ?? []}
              questions={strongestQuestions ?? []}
              style={{ height: '100%' }}
            />
          </Grid>
        ) : null}
        {/* {!userProfile?.workspace_settings?.gdpr_enabled && (
          <Grid item md={6} sm={12} xs={12}>
            <Leaderboard type="user" data={userLeaderboard} />
          </Grid>
        )}
        {groupLeaderboard?.length > 0 && (
          <Grid item md={6} sm={12} xs={12}>
            <Leaderboard type="group" data={groupLeaderboard} />
          </Grid>
        )} */}
      </Grid>
    </>
  )
})

export const AssignmentsOverviewTab = (props: any) => {
  const { id, mainTab, locationKey } = props
  return {
    name: 'Overview',
    path: `/activities/assignments/${mainTab}/${id}/overview`,
    component: () => <Overview assignmentID={id} mainTab={mainTab} key={locationKey} />,
  }
}
