import React, { useEffect, useState } from 'react'
import { CircularProgress } from '@mui/material'
import { useStores } from '@trivie/core'
import { DataTable, TableHelper } from '@trivie/ui-web/components/data-table/data-table'
import { Toolbar } from '@trivie/ui-web/components/table-toolbar'
import { observer } from 'mobx-react-lite'

interface UserTableProps {
  tableHelper: TableHelper
  forceLoading?: boolean
  openFilter?: boolean
  restricted?: boolean
  columns?: any
  load?: any
}

export const UserResultsTable = observer((props: UserTableProps) => {
  const { tableHelper, restricted, openFilter, forceLoading } = props

  const { manageUserStore } = useStores()
  const { gdpr, permissions, tagCategories } = manageUserStore

  const [selectedRows, setSelectedRows] = useState<any>([])
  const [loading, setLoading] = useState<boolean>(tableHelper.custom ? false : true)

  // Dialogs

  /* Get toolbar actions */
  const getActions = () => {
    return []
  }

  const [tableActions, setTableActions] = useState<any>(getActions())

  useEffect(() => {
    if (permissions) {
      setTableActions(getActions())
    }
  }, [permissions?.permissions])

  useEffect(() => {
    setLoading(Boolean(forceLoading))
  }, [forceLoading])

  useEffect(() => {
    setLoading(true)
  }, [])

  const save = (columns: any, filter: any) => {
    setLoading(true)
    tableHelper.save(columns, filter).then(() => setLoading(false))
  }

  return loading ? (
    <div style={LOADER}>
      <CircularProgress />
    </div>
  ) : (
    <DataTable
      gdpr={gdpr}
      restricted={restricted}
      allowNewReport
      tableHelper={tableHelper}
      columnVisibilityModel={tableHelper.columnVisibilityModel}
      filterModel={tableHelper.filterModel}
      sortModel={tableHelper.sortModel}
      searchPlaceholder="Search People"
      rows={tableHelper.rows}
      columns={tableHelper.columns}
      onSaveLayout={tableHelper?.saveLayout}
      onSaveFilters={save}
      toolbar={<Toolbar selected={selectedRows} actions={tableActions} />}
      exportFilename={tableHelper.parent !== 'users' ? `${tableHelper.parent}_users` : 'users'}
      tagCategories={tagCategories}
      onRowSelectionModelChange={(param) => setSelectedRows(param)}
      rowSelectionModel={selectedRows}
      openFilter={openFilter}
      checkboxSelection={false}
    />
  )
})

const LOADER: React.CSSProperties = {
  display: 'flex',
  flex: 1,
  justifyContent: 'center',
  flexDirection: 'column',
  alignItems: 'center',
  height: window.innerHeight / 1.5,
}
