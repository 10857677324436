import { QuestionContentCreatePayload } from '../models/QuestionContentCreatePayload'
import { QuizContentCreatePayload } from '../models/QuizContentCreatePayload'
import { Resource } from '../models/Resource'
import { ContentCreationApi } from '../services/api-objects/ContentCreationApi'
import { TopicApi } from '../services/api-objects/TopicApi'
import { ApiResponse } from 'apisauce'
import { castToSnapshot, getNodeId, getParentOfType, Instance } from 'mobx-state-tree'
import { Question } from '../models/Question'
import { Topic } from '../models/Topic'
import { ContentCreation } from '../models/ContentCreation'
import { Quiz } from '../models/Quiz'
import { TopicContentCreatePayload } from '../models/TopicContentCreatePayload'
import { QuizHelper } from './QuizHelper'
import { ResourceHelper } from './ResourceHelper'

let rootStore

export class TopicHelper {
  static setRootStore = (store: any) => {
    rootStore = store
  }

  static loadTopic = async (topicId: number) => {
    let newTopic: Instance<typeof Topic> | null = null
    const [getTopicResponse, getTopicResourcesResponse] = await Promise.all([
      TopicApi.getTopic(topicId),
      TopicApi.getResources(
        false,
        [
          { field: 'resource_created_date' },
          { field: 'resource_title' },
          { field: 'resource_filename_url' },
          { field: 'resource_file_size' },
          { field: 'resource_file_type' },
          { field: 'topic_resource_required' },
        ],
        'list_topic_resources',
        topicId,
      ),
    ])

    const topic = getTopicResponse.data?.topic?.pop()
    if (getTopicResponse.ok && topic) {
      newTopic = Topic.create({
        ...topic,
        selected: true,
        original_name: topic.title,
        imageUrl: topic.image,
        keep_item_order: topic.keep_item_order,
        quizzes: (topic.quizzes ?? ([] as Array<Instance<typeof QuizContentCreatePayload>>))
          .sort((a, b) => Number(a?.order) - Number(b?.order))
          .map((q) => {
            return {
              ...q,
              order: q.order,
              keep_item_order: q.keep_item_order,
              is_locked: false,
              original_name: q.title,
              questions: (q.questions ?? ([] as Array<Instance<typeof Question>>))
                .sort((a, b) => Number(a?.order) - Number(b?.order))
                .map((question) => {
                  return {
                    ...question,
                    advanced: Boolean(question.choices?.some((choice) => choice.factoid)),
                    approved: true,
                    touched: true,
                    choices: question.choices
                      .sort((a, b) => Number(a?.order) - Number(b?.order))
                      .map((choice) => ({
                        ...choice,
                        touched: true,
                      })),
                    id: question.question_id,
                    is_locked: false,
                    image_src: question.image,
                    keep_answer_order: question.keep_answer_order,
                  }
                }),
            }
          }),
      })
    } else {
      throw new Error(`failed to get topic ${topicId}`)
    }

    if (getTopicResourcesResponse.ok && getTopicResourcesResponse.data?.rows.length) {
      newTopic.set(
        'resources',
        ResourceHelper.getResourceReadToResource(getTopicResourcesResponse.data.rows),
      )
    }

    if (newTopic.quizzes?.length) {
      newTopic.quizzes[0].select()
      if (newTopic.quizzes[0].questions) {
        newTopic.quizzes[0].questions[0].select()
      }
      newTopic.quizzes.forEach((q) =>
        QuizHelper.loadResources(q).then(() => {
          const unattachedResources = [] as any
          q?.questions?.map((question) => {
            if (question.resource_id) {
              const found = q?.resources?.find((r) => r.resource_id === question.resource_id)
              if (!found) {
                unattachedResources.push(question.resource_id)
              }
            }
          })
          q?.set('removingResources', unattachedResources)
        }),
      )
    }

    return newTopic
  }

  static loadResources = async () => {}

  static createNewTopic = () => {
    return Topic.create({
      title: '',
      selected: true,
      keep_item_order: false,
      quizzes: [],
      resources: [],
    })
  }

  static validate = async (topic: Instance<typeof Topic>) => {
    try {
      if (topic.quizzes?.length) {
        TopicHelper.removeBlankQuizzes(topic)
      }

      const validateResponse: ApiResponse<Instance<typeof ContentCreation>> =
        await ContentCreationApi.validateContent({
          topic: [
            {
              ...topic.toPayload(),
              quizzes: topic.toPayload().quizzes.map((quiz, index0: number) => {
                return {
                  ...quiz,
                  order: index0,
                  questions: quiz.questions.map((question, index1: number) => {
                    return {
                      ...question,
                      order: quiz.keep_item_order ? index1 : null,
                      choices: question.choices.map((choice, index2: number) => {
                        return {
                          ...choice,
                          order: question.keep_answer_order ? index2 : null,
                        }
                      }),
                    }
                  }),
                }
              }),
            },
          ],
        })

      if (validateResponse.ok && validateResponse.data?.topic) {
        TopicHelper.collectErrors(validateResponse.data.topic[0], topic)
        TopicHelper.collectWarnings(validateResponse.data.topic[0], topic)
      }
    } catch (e) {
      console.error(e)
      throw e
    }
  }

  static hasResource = (
    resourceToFind: Instance<typeof Resource>,
    topic: Instance<typeof Topic>,
  ) => {
    if (!topic.resources?.length) {
      return false
    }

    // check if in the resources array already or if its going to be added
    return (
      !!topic.resources.find((r) => r.resource_id === resourceToFind.resource_id) ||
      !!(
        topic.addingResources &&
        topic?.addingResources.find((r) => r.resource_id === resourceToFind.resource_id)
      )
    )
  }

  static publishImages = async (topics: Array<Instance<typeof Topic>>) => {
    if (!topics.length) {
      return
    }

    topics.forEach((t) => {
      if (t.topic_id && t.imageFile) {
        TopicApi.publishImage(t.topic_id, t.imageFile)
      } else if (t.image) {
        // do nothing
      } else if (t.topic_id) {
        TopicApi.removeImage(t.topic_id)
      }

      if (t.quizzes?.length) {
        t.quizzes.forEach((q) => QuizHelper.publishImages(q))
      }
    })
  }

  static collectErrors = (
    responseTopic: Instance<typeof TopicContentCreatePayload>,
    topic: Instance<typeof Topic>,
  ) => {
    let hasErrors = false
    if (responseTopic.topic_id) {
      topic.set('topic_id', responseTopic.topic_id)
    }

    topic.set('errorsCorrected', [])

    if (
      responseTopic.errors?.length &&
      !responseTopic.errors.every((e) => e.includes('an active assignment'))
    ) {
      hasErrors = true
      topic.set('errors', responseTopic.errors)
    } else {
      topic.set('errors', [])
    }

    if (topic.quizzes?.length && responseTopic.quizzes?.length) {
      responseTopic.quizzes.forEach((q, i) => {
        const indexedQuiz = topic.quizzes![i]
        if (indexedQuiz) {
          hasErrors = QuizHelper.collectErrors(q, indexedQuiz) || hasErrors
        }
      })
    }

    return hasErrors
  }

  static collectWarnings = (
    responseTopic: Instance<typeof TopicContentCreatePayload>,
    topic: Instance<typeof Topic>,
  ) => {
    let hasWarnings = false

    if (topic.quizzes?.length && responseTopic.quizzes?.length) {
      responseTopic.quizzes.forEach((q, i) => {
        const indexedQuiz = topic.quizzes![i]
        if (indexedQuiz) {
          hasWarnings = QuizHelper.collectWarnings(q, indexedQuiz) || hasWarnings
        }
      })
    }

    return hasWarnings
  }

  static removeBlankQuizzes = (topic: Instance<typeof Topic>) => {
    let newQuizzes = [] as Array<Instance<typeof Quiz>>
    if (topic.quizzes?.length) {
      newQuizzes = topic.quizzes.filter((q) => {
        return !q.isBlankQuiz
      })
    }

    topic.set('quizzes', castToSnapshot(newQuizzes))
  }

  static topicParentHasErrors = (question: Instance<typeof Question>) => {
    try {
      const topicParent = getParentOfType(question, Topic)
      return topicParent.hasErrors
    } catch {
      return false
    }
  }

  static isSafeToForcePublish = (topics: Array<Instance<typeof Topic>>) => {
    // in order to force publish the only error currently we can force is if a question
    // is a part of an assignment.

    return topics.every((topic) => {
      if (!topic.quizzes?.length) {
        return false
      }

      // if we have 1 error and that error is not topicInAssignment
      if (topic.errors?.length === 1 && !topic.hasError('topicInAssignment')) {
        return false
      } else if (topic.errors?.length && topic.errors.length > 1) {
        return false
      } else {
        return topic.quizzes.every((q) => QuizHelper.isSafeToForcePublish(q))
      }
    })
  }

  static hasOneNonBlankQuestion = (topic: Instance<typeof Topic>) => {
    if (!topic.quizzes?.length) {
      return false
    }

    if (topic.quizzes.every((q) => q.isBlankQuiz)) {
      return false
    }

    return true
  }

  static publishResources = async (topics: Array<Instance<typeof Topic>>) => {
    if (!topics.length) {
      return
    }

    topics.forEach(async (t) => {
      if (!t.topic_id) {
        // throw new Error(`topic needs an id to publish resources`)
        return
      }

      if (t?.removingResources && t?.removingResources.length) {
        await TopicApi.removeResources(t.topic_id, t?.removingResources)
      }

      if (t.addingResources && t?.addingResources?.length) {
        const payload = t.addingResources.filter((r) => {
          return t?.quizResources?.find((qr) => qr.resource_id === r.resource_id) ? false : true
        })
        await TopicApi.addResources(t.topic_id!, payload)
      }

      if (t.quizzes?.length) {
        t.quizzes.forEach((q) => QuizHelper.publishResources(q))
      }
    })
  }

  static updateResources = async (
    topic: Instance<typeof Topic>,
    resource: Instance<typeof Resource>,
  ) => {
    if (topic.resources?.length) {
      topic.resources.forEach((r) => {
        if (r.resource_id === resource.resource_id) {
          r.set('title', resource.title)
        }
      })
    }

    if (topic.quizzes?.length) {
      topic.quizzes.forEach((q) => QuizHelper.updateResources(q, resource))
    }
  }

  static removeResourceFromQuizByNodeId = async (
    topic: Instance<typeof Topic>,
    nodeId: number,
    resource: Instance<typeof Resource>,
  ) => {
    if (!topic.quizzes?.length) {
      throw new Error(`Topic has no quizzes to remove resource from`)
    }

    topic.quizzes.forEach((q) => {
      if (getNodeId(q) === nodeId) {
        q.removeResource(resource)
      }
    })
  }

  static coalesceTopics(topics: Array<Instance<typeof Topic>>): Array<Instance<typeof Topic>> {
    if (!topics.length) {
      return []
    }

    // coalesce topics
    const topicsToDestroy: Array<Instance<typeof Topic>> = []
    topics.forEach((t) => {
      if (!topicsToDestroy.includes(t)) {
        const foundTopics =
          topics.filter((topic) => {
            return topic.title === t.title && topic !== t
          }) ?? []
        topicsToDestroy.push(...foundTopics)

        foundTopics.forEach((topic) => {
          const detachedQuizzes = topic.detachQuizzes()
          if (detachedQuizzes) {
            t.addQuizzes(detachedQuizzes)
          }
        })

        if (t.quizzes?.length) {
          const quizzesToRemove: Array<Instance<typeof Quiz>> = []
          t.quizzes.forEach((q) => {
            if (!quizzesToRemove.includes(q)) {
              const foundQuizzes =
                t.quizzes?.filter((quiz) => quiz.title === q.title && quiz !== q) ?? []
              quizzesToRemove.push(...foundQuizzes)

              foundQuizzes.forEach((quiz) => {
                const detachedQuestions = quiz.detachQuestions()
                if (detachedQuestions) {
                  q.addQuestions(detachedQuestions)
                }
              })
            }
          })

          quizzesToRemove.forEach((q) => {
            t.removeQuiz(q)
          })
        }
      }
    })

    return topics.filter((t) => !topicsToDestroy.includes(t))
  }
}
