import { ApiResponse, ApisauceInstance } from 'apisauce'
import { Instance } from 'mobx-state-tree'
import { MagicLinkPayload } from '../../models/MagicLinkPayload'
import { MagicLinkResponse } from '../../models/MagicLinkResponse'
import { AccessCodeDetailResponse } from '../../models/response-models/accessCodeDetails'
import { handleResponse } from '../handleResponse'

export const GameApi = {
  apisauce: {} as ApisauceInstance,
  startPreQuizAssessment: async (quiz_id: number) => {
    const response: ApiResponse<Instance<typeof MagicLinkResponse>> = await GameApi.apisauce.post(
      `game/assessment/${quiz_id}/start`,
    )

    return handleResponse(response)
  },
  submitAnswers: async (payload) => {
    const response: ApiResponse<Instance<typeof MagicLinkResponse>> = await GameApi.apisauce.post(
      `game/answers/`,
      payload,
    )

    return handleResponse(response)
  },
}
