import React, { useEffect, useId, useState } from 'react'
import { observer } from 'mobx-react-lite'
import { GetAppRounded } from '@mui/icons-material'
import { Box } from '@mui/material'
import TablePaginationActions from '@mui/material/TablePagination/TablePaginationActions'
import { GridToolbarContainer } from '@mui/x-data-grid-pro'
import { Bolt, SaveRounded, Search, TuneRounded } from '@mui/icons-material'
import {
  Badge,
  InputAdornment,
  ListItemIcon,
  Menu,
  MenuItem,
  Button as MuiButton,
  Tooltip,
  TooltipProps,
  styled,
  tooltipClasses,
} from '@mui/material'
import { createStyles, makeStyles } from '@mui/styles'
import { GridToolbarQuickFilter } from '@mui/x-data-grid-pro'

const _snakeCase = require('lodash/snakeCase')

import { color } from '../../theme'
import { Text } from '../text'
import { Button } from '../button/button'

export interface ToolbarProps {
  actions: any
  selected: any
  additionalActions?: any
}

const useToolbarStyles = makeStyles(() =>
  createStyles({
    menu: {
      '& .MuiPaper-root': {
        borderRadius: '6px !important',
        marginLeft: -17,
        // boxShadow: 'none !important',
        border: '1px solid #EFF0F6',
      },
    },
    popOverRoot: {
      pointerEvents: 'none',
    },
  }),
)

export const ActionTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} arrow classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: '#162142',
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: '#162142',
    fontSize: 12,
    padding: 8,
    fontFamily: 'Inter',
    maxWidth: 250,
    top: '0px !important',
  },
}))

export const BaseToolbar = observer((props: any) => {
  const {
    // data,
    exportData,
    searchPlaceholder,
    toolbar,
    filterCount,
    hideActions,
    onSaveLayout,
    onClickFilter,
    pagination,
    disableSearch,
    restricted,
  } = props

  const [savingLayout, setSavingLayout] = useState(false)
  const [showLayoutTooltip, setShowLayoutTooltip] = useState(false)

  const saveLayout = () => {
    setSavingLayout(true)
    setTimeout(() => {
      setSavingLayout(false)
    }, 5000)

    onSaveLayout()
  }

  const handleLayoutLeave = () => {
    setTimeout(() => {
      setSavingLayout(false)
    }, 400)
    setShowLayoutTooltip(false)
  }

  useEffect(() => {
    const next = document.querySelector('[aria-label="next"]')
    const previous = document.querySelector('[aria-label="previous"]')
    if (next) {
      next.setAttribute('data-qat', 'dt-toolbar-next-page')
    }
    if (previous) {
      previous.setAttribute('data-qat', 'dt-toolbar-previous-page')
    }
  }, [])

  return (
    <div style={TOOLBAR_CONTAINER}>
      <>
        {!disableSearch && (
          <GridToolbarQuickFilter
            placeholder={searchPlaceholder || 'Search'}
            variant="outlined"
            style={{ minWidth: 200, maxWidth: 500, width: '100%' }}
            InputProps={{
              style: { padding: 0, borderRadius: '8px', height: 40 },
              startAdornment: (
                <InputAdornment position="start" style={{ marginLeft: 6 }}>
                  <Search />
                </InputAdornment>
              ),
            }}
            inputProps={{
              'data-qat': 'dt_search_input',
            }}
          />
        )}
        {toolbar}
        {!hideActions && (
          <div style={ACTIONS}>
            {!restricted && (
              <ActionTooltip title="Customize" placement="top">
                <Badge
                  anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                  }}
                  sx={{
                    span: {
                      height: 14,
                      minWidth: 14,
                      width: 14,
                      borderRadius: 14,
                      fontSize: 8,
                      top: 3,
                      right: 8,
                    },
                  }}
                  overlap="circular"
                  color="primary"
                  badgeContent={filterCount}
                  max={9}
                >
                  <MuiButton
                    disableRipple
                    size="small"
                    style={{ minWidth: 36, color: color.shade70, alignSelf: 'center' }}
                    onClick={onClickFilter}
                    startIcon={
                      <TuneRounded
                        style={{ position: 'relative', left: 4, height: 14, width: 14 }}
                      />
                    }
                    data-qat="dt_customize"
                  />
                </Badge>
              </ActionTooltip>
            )}
            {!restricted && (
              <>
                <div style={DIVIDER} />
                <ActionTooltip
                  open={showLayoutTooltip}
                  title={savingLayout ? 'Layout Saved!' : 'Save Layout'}
                  placement="top"
                >
                  <MuiButton
                    disableRipple
                    size="small"
                    onMouseEnter={() => setShowLayoutTooltip(true)}
                    onMouseLeave={handleLayoutLeave}
                    style={{ minWidth: 36, color: color.shade70, alignSelf: 'center' }}
                    onClick={saveLayout}
                    startIcon={
                      <SaveRounded
                        style={{ position: 'relative', left: 4, height: 14, width: 14 }}
                      />
                    }
                    data-qat="dt_save_layout"
                  />
                </ActionTooltip>
                <div style={DIVIDER} />
              </>
            )}
            <ActionTooltip title="Export Table" placement="top">
              <MuiButton
                disableRipple
                size="small"
                style={{
                  minWidth: 36,
                  color: color.shade70,
                  alignSelf: 'center',
                }}
                // disabled={!data?.length}
                onClick={exportData}
                data-qat="dt_export_data"
                startIcon={
                  <GetAppRounded style={{ position: 'relative', left: 4, height: 14, width: 14 }} />
                }
              />
            </ActionTooltip>
          </div>
        )}
        {pagination}
      </>
    </div>
  )
})

export const Toolbar = (props: ToolbarProps) => {
  const { selected, additionalActions = [], actions } = props

  const classes = useToolbarStyles()

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
  const openActions = Boolean(anchorEl)

  let currentlyHovering = false

  const handleClick = (event) => {
    if (anchorEl !== event.currentTarget) {
      setAnchorEl(event.currentTarget)
    }
  }

  const handleHover = () => (currentlyHovering = true)

  const handleCloseHover = () => {
    currentlyHovering = false
    setTimeout(() => {
      if (!currentlyHovering) {
        handleClose()
      }
    }, 50)
  }

  const handleClose = () => setAnchorEl(null)

  return (
    <div style={CONTAINER}>
      {selected.length > 0 && (
        <Text
          component="div"
          sx={{ minWidth: '76px' }}
          color="primary"
          align="right"
          variant="body2Medium"
          text={`${selected.length} Selected`}
        />
      )}
      {actions.length > 0 && (
        <Button
          onClick={handleClick}
          onMouseOver={handleClick}
          onMouseLeave={handleCloseHover}
          aria-owns={anchorEl ? 'action-menu' : undefined}
          disabled={selected.length === 0}
          size="small"
          sx={{ textWrap: 'nowrap' }}
          aria-haspopup="true"
          startIcon={<Bolt />}
          data-qat="dt_actions_dropdown"
          text="Actions"
        />
      )}
      {additionalActions.length === 1 && (
        <Button
          size="small"
          color="info"
          sx={{ textWrap: 'nowrap' }}
          variant="outlined"
          disabled={additionalActions[0].disabled}
          onClick={additionalActions[0].onClick}
          startIcon={additionalActions[0].icon}
          text={additionalActions[0].label}
        />
      )}
      <Menu
        id="actions-menu"
        className={classes.menu}
        style={{ marginLeft: 16, borderRadius: 6, boxShadow: 'none', border: '1px solid #EFF0F6' }}
        anchorEl={anchorEl}
        open={openActions}
        PopoverClasses={{ root: classes.popOverRoot }}
        onClose={() => setAnchorEl(null)}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
          onMouseEnter: handleHover,
          onMouseLeave: handleCloseHover,
          style: { pointerEvents: 'auto' },
        }}
      >
        {actions.map((action) => (
          <MenuItem
            key={useId()}
            disabled={selected.length === 0 || action.disabled}
            onClick={action.onClick}
            data-qat={`dt_action_${_snakeCase(action.label)}`}
            dense
          >
            <ListItemIcon>
              <action.icon
                fontSize="8"
                color={action.color ?? action.label === 'Delete' ? 'error' : 'black'}
              />
            </ListItemIcon>
            <Text
              variant="body1Medium"
              text={action.label}
              color={action.color ?? action.label === 'Delete' ? 'error' : 'black'}
            />
          </MenuItem>
        ))}
      </Menu>
    </div>
  )
}

export const DataTableToolbar = (props) => {
  const {
    hidePagination,
    page,
    rowsPerPage,
    handleChangePage,
    disableSearch,
    hideActions,
    saveTableLayout,
    searchPlaceholder,
    toolbar,
    restricted,
    onClickFilter,
    exportData,
    count,
    filterCount,
  } = props

  return restricted ? null : (
    <GridToolbarContainer
      sx={{
        padding: '16px',
        paddingRight: hidePagination ? '16px' : '10px',
      }}
    >
      <BaseToolbar
        restricted={restricted}
        pagination={
          hidePagination ? null : (
            <Box sx={{ '> div': { width: '80px' } }}>
              <TablePaginationActions
                onPageChange={(e, page) => handleChangePage(page)}
                page={page}
                rowsPerPage={rowsPerPage}
                showFirstButton={false}
                showLastButton={false}
                count={count}
                getItemAriaLabel={(type: 'first' | 'last' | 'next' | 'previous') => type}
              />
            </Box>
          )
        }
        onClickFilter={onClickFilter}
        disableSearch={disableSearch}
        hideActions={hideActions}
        onSaveLayout={saveTableLayout}
        filterCount={filterCount ?? 0}
        toolbar={toolbar}
        searchPlaceholder={searchPlaceholder}
        exportData={exportData}
      />
    </GridToolbarContainer>
  )
}

const CONTAINER: React.CSSProperties = {
  flex: 1,
  display: 'flex',
  alignItems: 'center',
  width: '100%',
  flexDirection: 'row',
  justifyContent: 'flex-end',
  gap: 12,
}

const TOOLBAR_CONTAINER: React.CSSProperties = {
  flexDirection: 'row',
  display: 'flex',
  flex: 1,
  justifyContent: 'space-between',
  alignItems: 'center',
  gap: 12,
}
const ACTIONS: React.CSSProperties = {
  height: 32,
  border: '1px solid #EFF0F6',
  borderRadius: 6,
  flexDirection: 'row',
  justifyContent: 'space-evenly',
  display: 'flex',
}
const DIVIDER: React.CSSProperties = {
  alignSelf: 'center',
  height: 11,
  width: 1,
  backgroundColor: '#D9D9D9',
}
