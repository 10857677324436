import { mergeAttributes } from '@tiptap/core'
import ListItem from '@tiptap/extension-list-item'
import { ReactNodeViewRenderer } from '@tiptap/react'
import { ListItemNode } from './list-item-node'

export const TListItem = ListItem.extend({
  name: 'listItem',
  addOptions() {
    return {
      bulletListTypeName: 'bulletList',
      orderedListTypeName: 'bulletList',
      HTMLAttributes: {},
      quiz: {},
      selectQuestion: (q: any) => {},
    }
  },
  addAttributes() {
    return {
      ...this.parent?.(),
      generated_question_ids: [],
      node_id: 0,
      question_id: [],
      citations: [],
      generating: false,
    }
  },
  draggable: false,
  parseHTML() {
    return [{ tag: 'li', attrs: { generated_question_ids: [] } }]
  },
  renderHTML({ node, HTMLAttributes }) {
    return [`li`, mergeAttributes(this.options.HTMLAttributes, HTMLAttributes), 0]
  },
  addNodeView() {
    return ReactNodeViewRenderer(ListItemNode, {
      as: 'li',
      contentDOMElementTag: 'span',
      attrs: {
        ...this.options.HTMLAttributes,
      },
    })
  },
})
