import React, { useEffect, useState } from 'react'
import { observer } from 'mobx-react-lite'
import { DateTime } from 'luxon'
import { Route, Switch, useRouteMatch } from 'react-router-dom'

import { useStores } from '@trivie/core'
import { TableHelper } from '../../../../tables'

import { GroupTable } from '../../../../tables/group-table'

const Groups = observer(() => {
  const match = useRouteMatch<any>('/activities/assignments/:tab/:id/groups')
  const assignmentID = Number(match?.params?.id)

  const { assignmentStore } = useStores()
  const { assignmentOverviewStats, getAssignmentDetails } = assignmentStore

  useEffect(() => {
    if (!assignmentOverviewStats) {
      const endTime = DateTime.now()
      const startTime = endTime.plus({ days: -7 }).toISO().toString()
      getAssignmentDetails(assignmentID, startTime, endTime.plus({ days: -7 }).toISO())
    }
  }, [])

  const [tableHelper] = useState(
    new TableHelper('list_assignment_groups', 'groups', 'assignments', assignmentID),
  )

  return (
    <Switch>
      <Route path="/activities/assignments/:mainTab/:id/groups/">
        <GroupTable tableHelper={tableHelper} />
      </Route>
    </Switch>
  )
})

export const AssignmentsGroupsTab = (props: any) => {
  const { id, mainTab } = props
  return {
    name: 'Groups',
    path: `/activities/assignments/${mainTab}/${id}/groups`,
    component: () => <Groups />,
  }
}
