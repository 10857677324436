import React, { useEffect, useState } from 'react'
import { observer } from 'mobx-react-lite'

import { useStores } from '@trivie/core'
import { TableHelper } from '../../../../tables'

import { AssignmentTable } from '../../../../tables/assignment-table'

const CompletedAssignments: React.FunctionComponent = observer(() => {
  const { assignmentStore } = useStores()
  const { setTriggerRefresh, triggerRefresh } = assignmentStore

  const [tableHelper] = useState(
    new TableHelper(
      'list_completed_assignments',
      'assignments',
      'assignments',
      undefined,
      'completed',
    ),
  )

  useEffect(() => {
    if (triggerRefresh) {
      tableHelper.load().then(() => setTriggerRefresh(false))
    }
  }, [triggerRefresh])

  return (
    <AssignmentTable forceLoading={triggerRefresh} filter="completed" tableHelper={tableHelper} />
  )
})

export const CompletedAssignmentsTab = () => {
  return {
    name: 'Completed',
    path: `/activities/assignments/completed`,
    component: () => <CompletedAssignments />,
  }
}
