import { types } from 'mobx-state-tree'
import { UserCommSettings } from './UserCommSettings'
import { UserLevel } from './UserLevel'
import { UserWorkspaceSettings } from './UserWorkspaceSettings'

export const UserProfile = types.model('UserProfile').props({
  id: types.number,
  email: types.maybeNull(types.string),
  phone_number: types.maybeNull(types.string),
  first_name: types.maybeNull(types.string),
  preferred_name: types.maybeNull(types.string),
  last_name: types.maybeNull(types.string),
  employee_id: types.maybeNull(types.string),
  allow_profile_editing: types.maybeNull(types.boolean),
  is_admin: types.maybeNull(types.boolean),
  photo_url: types.maybeNull(types.string),
  time_zone_name: types.maybeNull(types.string),
  account_created: types.string,
  last_activity: types.maybeNull(types.string),
  language: types.maybeNull(types.string),
  exclude_from_leaderboard: types.maybeNull(types.boolean),
  user_comm_settings: types.maybeNull(UserCommSettings),
  sounds_enabled: types.maybeNull(types.boolean),
  music_enabled: types.maybeNull(types.boolean),
  force_password_reset: types.maybeNull(types.boolean),
  force_password_set: types.maybeNull(types.boolean),
  user_level: types.maybeNull(UserLevel),
  hourly_warning: types.maybeNull(types.boolean),
  hourly_override: types.maybeNull(types.string),
  workspace_settings: types.maybeNull(UserWorkspaceSettings),
  plan_tier: types.maybeNull(types.string),
  gdpr_consent: types.maybeNull(
    types.model('GDPRConsent').props({
      consent_granted: types.boolean,
      timestamp: types.string,
    }),
  ),
})
