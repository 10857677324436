import React, { useEffect, useState } from 'react'
import { TextField, Radio, InputAdornment, Chip, IconButton, CircularProgress } from '@mui/material'
import {
  Check,
  GroupRounded,
  SearchOutlined,
  ExpandLessRounded,
  ExpandMoreRounded,
  Topic,
  Close,
  BoltRounded,
  Description,
} from '@mui/icons-material'
import Autocomplete, { AutocompleteChangeReason } from '@mui/material/Autocomplete'
import makeStyles from '@mui/styles/makeStyles'
import { color } from '../../theme/color'
import { Text } from '../text'
import { Avatar } from '../avatar'
import { observer } from 'mobx-react-lite'
import { IAutocompleteGroupOptions } from '@trivie/core'

export interface AutocompleteGroupSelectorProps {
  style?: React.CSSProperties
  listHeight?: number | string
  defaultOptions: IAutocompleteGroupOptions[]
  handleAddUserGroup: (items: IAutocompleteGroupOptions[], removal?: any) => void
  options: any[]
  selectedOptions: IAutocompleteGroupOptions[]
  open: boolean
  popperPortalWidth?: any
  placeHolderText?: string
  selectedItemsText?: string
  noOptionsText?: string
  showAvatar?: boolean
  resize?: boolean
}

export interface ParentRowProps {
  option: IAutocompleteGroupOptions
  selected: boolean
  showAvatar: boolean
  hidden: boolean
  toggleRow: (id: number) => void
}

const ParentRow = ({ listProps, context }: { listProps: any; context: ParentRowProps }) => {
  const { option, showAvatar, selected, hidden = false, toggleRow } = context

  const [isOpen, setIsOpen] = useState(false)

  const handleToggleClick = (e) => {
    e.stopPropagation()
    toggleRow(option.id)
    setIsOpen(!isOpen)
  }

  return (
    <li
      {...listProps}
      style={{
        flexDirection: 'column',
        alignItems: 'flex-start',
        marginLeft: 0,
        paddingLeft: 4,
        background: 'none',
      }}
    >
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <Radio
          checked={selected}
          checkedIcon={
            <div style={CHECK_ICON}>
              <Check htmlColor={color.white} style={{ fontSize: 16 }} />
            </div>
          }
          color="default"
          inputProps={{ 'aria-label': 'choice' }}
          style={{
            marginRight: 8,
            placeSelf: 'center',
            color: selected ? color.blue : color.shade30,
          }}
        />
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          {option.label}
          {!!option.subLabel && (
            <Text variant="body2Medium" text={option.subLabel} style={{ color: color.shade30 }} />
          )}
        </div>

        {option.type == 'Groups' && (
          <Text
            variant="body1Emphasized"
            text={option.childCount?.toString()}
            style={{ paddingLeft: 10, color: color.lightGrey }}
          />
        )}
        {option.subItems && option.subItems?.length > 0 && (
          <IconButton
            style={{ position: 'absolute', right: 8 }}
            onClick={handleToggleClick}
            size="large"
          >
            {isOpen ? <ExpandLessRounded /> : <ExpandMoreRounded />}
          </IconButton>
        )}
      </div>
      {isOpen &&
        option.subItems?.map((c) => {
          return (
            <li {...listProps} style={{ marginLeft: 36, background: 'none', paddingLeft: 0 }}>
              <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                {c.type === 'topics' ? (
                  <div style={GROUP_ICON_MINI}>
                    <Topic style={{ fontSize: 18 }} htmlColor={color.shade70} />
                  </div>
                ) : c.type === 'resources' ? (
                  <div style={GROUP_ICON_MINI}>
                    <Description style={{ fontSize: 18 }} htmlColor={color.shade70} />
                  </div>
                ) : c.type === 'quizzes' ? (
                  <div style={GROUP_ICON_MINI}>
                    <BoltRounded style={{ fontSize: 18 }} htmlColor={color.shade70} />
                  </div>
                ) : c.type === 'Groups' ? (
                  <div style={GROUP_ICON_MINI}>
                    <GroupRounded style={{ fontSize: 18 }} htmlColor={color.shade70} />
                  </div>
                ) : null}
                <div style={{ marginLeft: 12, display: 'flex', flexDirection: 'column' }}>
                  {c.label}
                  {!!c.subLabel && (
                    <Text
                      variant="body2Medium"
                      text={c.subLabel}
                      style={{ color: color.shade30 }}
                    />
                  )}
                </div>
              </div>
            </li>
          )
        })}
    </li>
  )
}
export const AutocompleteGroupSelector = observer((props: AutocompleteGroupSelectorProps) => {
  const {
    style = {},
    listHeight,
    resize,
    defaultOptions,
    handleAddUserGroup,
    options,
    selectedOptions,
    open,
    placeHolderText = 'Search',
    noOptionsText = 'No options',
    selectedItemsText,
    showAvatar = false,
  } = props

  const [_selectedOptions, setSelectedOptions] =
    useState<IAutocompleteGroupOptions[]>(selectedOptions)
  const [dataOptions, setDataOptions] = useState<IAutocompleteGroupOptions[]>([])
  const [focused, setFocused] = useState(open)
  const [hiddenRows, setHiddenRows] = useState<number[]>([])

  const [offset, setOffset] = useState(60)

  useEffect(() => {
    setSelectedOptions(selectedOptions)
    setTimeout(() => {
      const inp = document.querySelector('.MuiAutocomplete-root > div > .MuiInputBase-root')
      if (inp) {
        setOffset(inp.getBoundingClientRect().height)
      }
    }, 100)
  }, [selectedOptions])

  const onFocus = () => setFocused(true)
  const onBlur = () => setFocused(false)

  useEffect(() => {
    const temp: IAutocompleteGroupOptions[] = []
    options.forEach((element) => {
      if (element.subItems && element.subItems?.length > 0) {
        // temp.push(element)
        // element.subItems.forEach(item => {
        //   temp.push({ ...item, parentId: element.id })
        // })
      } else {
        temp.push(element)
      }
    })
    setDataOptions(options)
  }, [options])

  const useStyles = makeStyles({
    // Dropdown container
    paper: {
      borderRadius: 0,
      boxShadow: 'none',
      border: '1px solid lightgray',
      borderBottomLeftRadius: 20,
      borderBottomRightRadius: 20,
      top: -1,
      margin: 0,
      position: open ? 'relative' : 'absolute',
      width: '100% !important',
      // width: '719px !important',
    },
    root: {
      '& .MuiAutocomplete-tag': {
        margin: '0 3px',
      },
    },
    popperDisablePortal: {
      position: 'relative',
      width: 'calc(100% - 48px) !important',
    },
    groupLabel: {
      fontWeight: 600,
      textTransform: 'uppercase',
    },
    option: {
      // paddingLeft: '16px !important',
      // height: 48,
    },
    listbox: {
      maxHeight: resize && typeof listHeight === 'number' ? listHeight - offset : listHeight,
      transition: 'max-height 0.05s ease-in-out',
    },
    input: {
      '&::placeholder': {
        color: color.palette.darkGrey,
        // fontFamily: 'Inter',
        // fontStyle: 'normal',
        // fontWeight: 'normal',
        fontSize: 14,
      },
      '& .MuiInputBase-root': {
        maxHeight: 120,
        minHeight: 60,
        overflow: 'auto',
        border: '1px solid lightgray',
        borderBottomLeftRadius: open || focused ? 0 : 20,
        borderBottomRightRadius: open || focused ? 0 : 20,
        borderTopLeftRadius: 20,
        borderTopRightRadius: 20,
      },
      '& .MuiInputBase-root::focused': {
        borderBottomLeftRadius: 0,
        borderBottomRightRadius: 0,
      },
      '& .MuiInputBase-root > fieldset': {
        border: 'none',
      },
    },
  })

  const classes = useStyles()

  const renderTags = (value: any, getTagProps: any) => {
    return value.map((option: IAutocompleteGroupOptions, index: number) => {
      return (
        <Chip
          deleteIcon={<Close />}
          style={CHIP}
          avatar={
            option.type === 'topics' ? (
              <div style={GROUP_ICON_MINI}>
                <Topic style={{ fontSize: 12 }} htmlColor={color.shade70} />
              </div>
            ) : option.type === 'learning paths' ? (
              <div style={GROUP_ICON_MINI}>
                <svg
                  width="16"
                  height="17"
                  viewBox="0 0 16 17"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M12.7668 14.5C12.3113 14.5 11.9141 14.3639 11.5752 14.0917C11.2363 13.8194 11.0224 13.4889 10.9335 13.1H7.3835C6.65016 13.1 6.04183 12.8583 5.5585 12.375C5.07516 11.8917 4.8335 11.2833 4.8335 10.55C4.8335 9.81667 5.07516 9.20833 5.5585 8.725C6.04183 8.24167 6.65016 8 7.3835 8H8.66683C9.12239 8 9.49461 7.85556 9.7835 7.56667C10.0724 7.27778 10.2168 6.90556 10.2168 6.45C10.2168 5.99444 10.0724 5.62222 9.7835 5.33333C9.49461 5.04444 9.12239 4.9 8.66683 4.9H5.06683C4.96683 5.28889 4.75016 5.61944 4.41683 5.89167C4.0835 6.16389 3.68905 6.3 3.2335 6.3C2.70016 6.3 2.25016 6.11667 1.8835 5.75C1.51683 5.38333 1.3335 4.93333 1.3335 4.4C1.3335 3.86667 1.51683 3.41667 1.8835 3.05C2.25016 2.68333 2.70016 2.5 3.2335 2.5C3.68905 2.5 4.0835 2.63611 4.41683 2.90833C4.75016 3.18056 4.96683 3.51111 5.06683 3.9H8.66683C9.40016 3.9 10.0085 4.14167 10.4918 4.625C10.9752 5.10833 11.2168 5.71667 11.2168 6.45C11.2168 7.18333 10.9752 7.79167 10.4918 8.275C10.0085 8.75833 9.40016 9 8.66683 9H7.3835C6.92794 9 6.55572 9.14444 6.26683 9.43333C5.97794 9.72222 5.8335 10.0944 5.8335 10.55C5.8335 11.0056 5.97794 11.3778 6.26683 11.6667C6.55572 11.9556 6.92794 12.1 7.3835 12.1H10.9335C11.0335 11.7111 11.2502 11.3806 11.5835 11.1083C11.9168 10.8361 12.3113 10.7 12.7668 10.7C13.3002 10.7 13.7502 10.8833 14.1168 11.25C14.4835 11.6167 14.6668 12.0667 14.6668 12.6C14.6668 13.1333 14.4835 13.5833 14.1168 13.95C13.7502 14.3167 13.3002 14.5 12.7668 14.5ZM3.2335 5.3C3.48905 5.3 3.70294 5.21389 3.87516 5.04167C4.04739 4.86944 4.1335 4.65556 4.1335 4.4C4.1335 4.14444 4.04739 3.93056 3.87516 3.75833C3.70294 3.58611 3.48905 3.5 3.2335 3.5C2.97794 3.5 2.76405 3.58611 2.59183 3.75833C2.41961 3.93056 2.3335 4.14444 2.3335 4.4C2.3335 4.65556 2.41961 4.86944 2.59183 5.04167C2.76405 5.21389 2.97794 5.3 3.2335 5.3Z"
                    fill="black"
                    fill-opacity="0.7"
                  />
                </svg>
              </div>
            ) : option.type === 'quizzes' ? (
              <div style={GROUP_ICON_MINI}>
                <BoltRounded style={{ fontSize: 12 }} htmlColor={color.shade70} />
              </div>
            ) : option.type === 'Groups' ? (
              <div style={GROUP_ICON_MINI}>
                <GroupRounded style={{ fontSize: 12 }} htmlColor={color.shade70} />
              </div>
            ) : (
              <Avatar
                style={{ left: 4, position: 'relative' }}
                fontStyle={{ fontSize: 8 }}
                size={21}
                name={option.label}
                url={showAvatar ? option.photo_url : ''}
              />
            )
          }
          variant="outlined"
          label={option.label}
          {...getTagProps({ index })}
        />
      )
    })
  }

  const toggleRow = (id: number) => {
    const isExpand = hiddenRows.includes(id)
    if (isExpand) {
      setHiddenRows(hiddenRows.filter((f) => f != id))
    } else {
      setHiddenRows([...hiddenRows, id])
    }
  }
  const renderOption = (props, option: IAutocompleteGroupOptions, { selected }) => {
    const hidden = hiddenRows.includes(option.parentId || -1)

    return (
      <ParentRow listProps={props} context={{ option, selected, showAvatar, hidden, toggleRow }} />
    )
  }

  React.useEffect(() => {
    if (defaultOptions?.length > 0) {
      handleAddUserGroup(defaultOptions)
    }
  }, [defaultOptions])

  const autoSelectSubItems = (parentId: number) => {
    return dataOptions.filter((f) => f.parentId === parentId)
  }

  return (
    <div style={style}>
      <Autocomplete
        fullWidth
        disablePortal
        noOptionsText={noOptionsText}
        isOptionEqualToValue={(o: IAutocompleteGroupOptions, value: IAutocompleteGroupOptions) =>
          _selectedOptions?.find(
            (op: IAutocompleteGroupOptions) => value.type === o.type && value.id === o.id,
          ) as any
        }
        open={open}
        defaultValue={_selectedOptions}
        value={_selectedOptions}
        onChange={(
          event: React.SyntheticEvent<Element, Event>,
          value: IAutocompleteGroupOptions[],
          reason: AutocompleteChangeReason,
        ) => {
          let si: IAutocompleteGroupOptions[] = []
          if (reason === 'selectOption') {
            const addedItem = value[value.length - 1]
            if (addedItem.subItems && addedItem.subItems?.length > 0) {
              si = autoSelectSubItems(addedItem.id)
            }
            handleAddUserGroup([...value, ...si])
          }

          if (reason === 'removeOption') {
            const removedItem = selectedOptions.filter(
              (f) => !value.map((m) => m.id).includes(f.id),
            )[0]
            if (removedItem.subItems && removedItem.subItems?.length > 0) {
              value = value.filter((f) => f.parentId != removedItem.id)
            }
            handleAddUserGroup(value, removedItem)
          }
        }}
        disableCloseOnSelect
        multiple
        id="user-groups-autocomplete"
        classes={{ ...classes }}
        forcePopupIcon={false}
        options={dataOptions.sort((a: IAutocompleteGroupOptions, b: IAutocompleteGroupOptions) => {
          return b.type.localeCompare(a.type)
        })}
        groupBy={(option) => option.type}
        getOptionLabel={(option) => option.label}
        renderTags={renderTags}
        renderInput={(params) => {
          params.InputProps.startAdornment = (
            <>
              <InputAdornment position="start">
                <SearchOutlined htmlColor={color.shade30} />
              </InputAdornment>
              {params.InputProps.startAdornment}
            </>
          )
          params.InputProps.endAdornment = null
          return (
            <TextField
              className={classes.input}
              placeholder={placeHolderText}
              {...params}
              variant="outlined"
              type="text"
              onFocus={onFocus}
              onBlur={onBlur}
            />
          )
        }}
        renderOption={renderOption}
      />
    </div>
  )
})

const GROUP_ICON_MINI: React.CSSProperties = {
  height: 21,
  width: 21,
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  borderRadius: 6,
  alignItems: 'center',
}
const GROUP_ICON_LARGE: React.CSSProperties = {
  ...GROUP_ICON_MINI,
  backgroundColor: color.shade10,
  height: 42,
  width: 42,
  marginRight: 12,
}
const CHIP: React.CSSProperties = {
  backgroundColor: '#EFF0F6',
  marginTop: 4,
  marginBottom: 4,
  border: 'none',
  borderRadius: 6,
}
const CHECK_ICON: React.CSSProperties = {
  backgroundColor: color.blue,
  height: 20,
  width: 20,
  marginLeft: 2,
  position: 'relative',
  borderRadius: 100,
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  alignItems: 'center',
  marginRight: 2,
}
const USER_COUNT: React.CSSProperties = {
  position: 'relative',
  right: 0,
  top: 4,
  color: color.shade50,
  fontSize: 12,
  fontWeight: '400',
}
