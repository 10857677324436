import { roundNumber } from './roundNumber'
const _partition = require('lodash/partition')

export const getStrongestWeakest = <T extends { pct_correct: number }>(
  data: Array<T>,
  threshold = 70,
) => {
  const partition = _partition(data, (o) => o.pct_correct >= threshold)
  try {
    const strongest = partition[0]
      .slice(0, 3)
      .map((o) => ({ ...o, pct_correct: roundNumber(o.pct_correct) }))
    const weakest = partition[1]
      .slice(-3)
      .map((o) => ({ ...o, pct_correct: roundNumber(o.pct_correct) }))
    return { strongest, weakest }
  } catch {
    return { strongest: [], weakest: [] }
  }
}
