import React, { useState } from 'react'
import { observer } from 'mobx-react-lite'
import { useRouteMatch } from 'react-router-dom'

import { TableHelper } from '../../../../tables'
import { UserTable } from '../../../../tables/user-table'

const Users = observer(() => {
  const match = useRouteMatch<any>('/content/paths/:id/users')
  const pathID = Number(match?.params?.id)

  const [tableHelper] = useState(new TableHelper('list_path_users', 'users', 'paths', pathID))

  return <UserTable tableHelper={tableHelper} />
})

export const PathUsersTab = (props: any) => {
  const { id } = props
  return {
    name: 'Users',
    path: `/content/paths/${id}/users`,
    component: () => <Users />,
  }
}
