import { DateTime } from 'luxon'
import { cast, destroy, getParent, getParentOfType, Instance, types } from 'mobx-state-tree'
import { QuestionType } from './survey/QuestionType'
import { isSurvey } from '../type-guards/isSurvey'
import { SurveyUser, SurveyGroup } from './survey/SurveyUserGroup'

export const SurveyQuestionChoice = types
  .model('SurveyQuestionChoice')
  .props({
    id: types.maybe(types.number),
    text: types.string,
    pct_selected: types.maybe(types.number),
    showErrors: types.maybe(types.boolean),
  })
  .views((self) => ({
    get tooLong() {
      return self.text!.length > 100
    },
    get parentSurvey() {
      if (!isSurvey(getParent(self, 4))) {
        throw Error('Root is not of type Survey')
      }
      return getParent(self, 4) as any
    },
    get empty() {
      return (self.showErrors || this.parentSurvey.showErrors) && !self.text
    },
    get valid() {
      return !this.empty && !this.tooLong
    },
    get errorCount() {
      let count = 0
      if (!self.text) {
        count++
      }
      if (this.tooLong) {
        count++
      }
      return count
    },
  }))
  .actions((self) => ({
    setText(text: string) {
      self.text = text
    },
    setShowErrors(show: boolean) {
      self.showErrors = show
    },
    remove() {
      // @ts-ignore
      getParentOfType(self, SurveyQuestion).removeChoice(self)
    },
  }))

export const SurveyQuestion = types
  .model('SurveyQuestion')
  .props({
    id: types.maybe(types.number),
    order: types.maybe(types.number),
    question_type: types.maybe(QuestionType),
    choices: types.maybeNull(types.array(SurveyQuestionChoice)),
    text: types.maybe(types.string),
    min_label: types.maybeNull(types.string),
    max_label: types.maybeNull(types.string),
    open_text_responses: types.maybeNull(types.array(SurveyQuestionChoice)),
    showErrors: types.maybe(types.boolean),
  })
  .views((self) => ({
    get isMultipleChoice() {
      return self.question_type === 'multiple_choice'
    },
    get questionLabel() {
      switch (self.question_type) {
        case 'open_text':
          return 'Short answer'
        case 'range_response':
          return 'Scale'
        case 'true_false':
          return 'True / False'
        default:
          return 'Multiple Choice'
      }
    },
    get parentSurvey() {
      if (!isSurvey(getParent(self, 2))) {
        throw Error('Root is not of type Survey')
      }
      return getParent(self, 2) as any
    },
    get tooLong() {
      return self.text && self.text.length > 500
    },
    get minLabelTooLong() {
      return self.min_label && self.min_label.length > 20
    },
    get maxLabelTooLong() {
      return self.max_label && self.max_label.length > 20
    },
    get needsMoreChoices() {
      return (
        (self.question_type === 'multiple_choice' || self.question_type === 'single_choice') &&
        self.choices &&
        self.choices.length < 2
      )
    },
    get empty() {
      return (self.showErrors || this.parentSurvey.showErrors) && !self.text
    },
    get emptyMinLabel() {
      return (self.showErrors || this.parentSurvey.showErrors) && !self.min_label
    },
    get emptyMaxLabel() {
      return (self.showErrors || this.parentSurvey.showErrors) && !self.max_label
    },
    get valid() {
      let validChoices = true
      if (
        self.choices &&
        (self.question_type === 'single_choice' || self.question_type === 'multiple_choice')
      ) {
        self.choices?.forEach((choice) => {
          if (!choice.valid) {
            validChoices = false
          }
        })
      }
      let validLabels = true
      if (self.question_type === 'range_response') {
        validLabels = !this.minLabelTooLong && !this.maxLabelTooLong
      }

      return !this.tooLong && !this.needsMoreChoices && !this.empty && validChoices && validLabels
    },
    get errorCount() {
      let count = 0
      if (!self.text) {
        count++
      }
      if (this.tooLong) {
        count++
      }
      if (this.emptyMinLabel && self.question_type === 'range_response') {
        count++
      }
      if (this.emptyMaxLabel && self.question_type === 'range_response') {
        count++
      }
      if (this.needsMoreChoices) {
        count++
      }
      self.choices?.forEach((choice) => (count += choice.errorCount))
      return count
    },
  }))
  .actions((self) => ({
    setText(text: string) {
      self.text = text
    },
    setShowErrors(show: boolean) {
      self.showErrors = show
    },
    setMinLabel(text: string) {
      self.min_label = text
    },
    setMaxLabel(text: string) {
      self.max_label = text
    },
    setChoices(choices: typeof self.choices) {
      self.choices = cast(choices)
    },
    removeChoice(choice: Instance<typeof SurveyQuestionChoice>) {
      destroy(choice)
    },
    addChoice() {
      if (self?.choices) {
        self?.choices.push(SurveyQuestionChoice.create({ text: '' }))
      } else {
        self.choices = cast([SurveyQuestionChoice.create({ text: '' })])
      }
    },
    remove() {
      // @ts-ignore
      getParentOfType(self, Survey).removeQuestion(self)
    },
    setQuestionType(
      questionType:
        | 'single_choice'
        | 'multiple_choice'
        | 'open_text'
        | 'range_response'
        | 'true_false',
    ) {
      if (questionType !== self.question_type) {
        self.min_label = null
        self.max_label = null
        switch (questionType) {
          case 'open_text':
            self.choices = cast([])
            break
          case 'range_response':
            self.choices = cast([])
            self.min_label = ''
            self.max_label = ''
            SurveyQuestionChoice.create({ text: '' })
            break
          case 'true_false':
            self.choices = cast([
              SurveyQuestionChoice.create({ text: 'True' }),
              SurveyQuestionChoice.create({ text: 'False' }),
            ])
            break
          default:
            if (
              self.question_type !== 'single_choice' &&
              self.question_type !== 'multiple_choice'
            ) {
              self.choices = cast([
                SurveyQuestionChoice.create({ text: '' }),
                SurveyQuestionChoice.create({ text: '' }),
                SurveyQuestionChoice.create({ text: '' }),
              ])
            }
        }
        self.question_type = questionType
      }
    },
  }))

export const Survey = types
  .model('Survey')
  .props({
    survey_id: types.maybe(types.number),
    id: types.maybe(types.number),
    title: types.optional(types.string, ''),
    questions: types.maybeNull(types.array(SurveyQuestion)),
    assignee_count: types.maybeNull(types.number),
    is_anonymous: types.optional(types.boolean, false),
    start_datetime: types.maybeNull(types.string),
    end_datetime: types.maybeNull(types.string),
    notify: types.optional(types.boolean, true),
    response_rate: types.maybeNull(types.number),
    groups: types.array(SurveyGroup),
    users: types.array(SurveyUser),
    showErrors: types.maybe(types.boolean),
    showTitleError: types.maybe(types.boolean),
    publishing: types.maybe(types.boolean),
    user_id: types.maybe(types.number),
    responded: types.maybe(types.boolean),
  })
  .views((self) => ({
    get groupIds() {
      return self.groups?.map((group: Instance<typeof SurveyGroup>) => group.group_id) ?? []
    },
    get userIds() {
      return self.users?.map((user: Instance<typeof SurveyUser>) => user.user_id) ?? []
    },
    get hasTitle() {
      return self.title!.length > 0
    },
    get longTitle() {
      if (self.title) {
        return self.title.length > 100
      }
      return false
    },
    get isUnscheduled() {
      return !!self.start_datetime
    },
    get isScheduled() {
      if (!self.start_datetime || !self.end_datetime) {
        return false
      }
      const startDate = DateTime.fromISO(self.start_datetime).toMillis()
      return startDate > DateTime.now().toMillis()
    },
    get isLive() {
      if (!self.start_datetime || !self.end_datetime) {
        return false
      }
      const startDate = DateTime.fromISO(self.start_datetime).toMillis()
      const endDate = DateTime.fromISO(self.end_datetime).toMillis()
      return startDate < DateTime.now().toMillis() && endDate > DateTime.now().toMillis()
    },
    get isCompleted() {
      if (!self.start_datetime || !self.end_datetime) {
        return false
      }
      const endDate = DateTime.fromISO(self.end_datetime).toMillis()
      return endDate < DateTime.now().toMillis()
    },
    get timeRemaining() {
      if (!self.start_datetime || !self.end_datetime) {
        return 0
      }
      const endDate = DateTime.fromISO(self.end_datetime)
      const diffTime = endDate.diff(DateTime.now(), ['days'])
      let diffInDays = diffTime.toObject().days
      diffInDays = diffInDays ?? 0
      diffInDays = diffInDays < 0 ? 0 : diffInDays
      return Math.ceil(diffInDays)
    },
    get valid() {
      let validQuestions = true
      self.questions?.forEach((question) => {
        if (!question.valid) {
          validQuestions = false
        }
      })
      return this.hasTitle && !this.longTitle && validQuestions
    },
    get errorCount() {
      let count = 0
      if (!this.hasTitle) {
        count++
      }
      if (this.longTitle) {
        count++
      }
      self.questions?.forEach((question) => {
        count += question.errorCount
      })
      return count
    },
  }))
  .actions((self) => ({
    setShowErrors(show: boolean) {
      self.showErrors = show && (self.questions || []).length > 0
    },
    setShowTitleError(show: boolean) {
      self.showTitleError = show
    },
    setPublishing(publishing: boolean) {
      self.publishing = publishing
    },
    setQuestions(questions: typeof self.questions) {
      self.questions = cast(questions)
    },
    setQuestion(question: Instance<typeof SurveyQuestion>, index: number) {
      if (self.questions) {
        self.questions[index] = question
      }
    },
    setTitle(title: string) {
      self.title = title
    },
    reorder(drag: number, drop: number) {
      const temp = self.questions?.slice()
      if (temp) {
        temp.splice(drop, 0, temp.splice(drag, 1)[0])
        self.questions = cast(temp)
      }
    },
    addNewQuestion() {
      self.questions?.push(
        SurveyQuestion.create({
          question_type: 'single_choice',
          text: '',
          choices: [
            SurveyQuestionChoice.create({ text: '' }),
            SurveyQuestionChoice.create({ text: '' }),
            SurveyQuestionChoice.create({ text: '' }),
          ],
        }),
      )
    },
    removeQuestion(question: Instance<typeof SurveyQuestion>) {
      destroy(question)
    },
  }))
