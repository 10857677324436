import { observer } from 'mobx-react-lite'
import React, { useEffect, useState } from 'react'
import { Instance } from 'mobx-state-tree'
import { Box, Stack } from '@mui/material'

import {
  GetQuestionPctCorrectSummary,
  GetQuizPctCorrectSummary,
  getStrongestWeakest,
  useStores,
} from '@trivie/core'
import { color } from '@trivie/ui-web/theme'
import { DateFilter } from '@trivie/ui-web/components/date-filter'
import { Insight } from '@trivie/ui-web/components/insight'
import { Text } from '@trivie/ui-web/components/text'
import { GenerateReport } from '@trivie/ui-web/components/generate-report'
import { StrongestWeakest } from '@trivie/ui-web/components/strongest-weakest'
import { TopicApi } from '@trivie/core/src/services/api-objects/TopicApi'
import { Image } from '@trivie/ui-web/components/image'
import { Medallion } from '@trivie/ui-web/components/medallion'
import { Panel } from '@trivie/ui-web/components/panel/panel'

const Overview = observer((props: { topicID: number }) => {
  const { topicsStore } = useStores()
  const {
    topicStats,
    getTopicDetails,
    getMastery,
    setTopicStats,
    setMastery,
    getCertificationStats,
    certificationStats,
  } = topicsStore

  const { topicID } = props

  const [imageSrc, setImageSrc] = useState<string | null>(null)
  const [quizGaps, setQuizGaps] = useState<null | Array<Instance<typeof GetQuizPctCorrectSummary>>>(
    null,
  )
  const [questionGaps, setQuestionGaps] = useState<null | Array<
    Instance<typeof GetQuestionPctCorrectSummary>
  >>(null)

  useEffect(() => {
    setTopicStats(null)
    setMastery(null)
    getCertificationStats(topicID)
  }, [])

  useEffect(() => {
    if (topicStats) {
      setImageSrc(topicStats.file_url)
    }
  }, [topicStats])

  const loadOverview = async (startDate: string, endDate: string) => {
    getTopicDetails(topicID, startDate, endDate)
    getMastery(topicID, startDate, endDate)
    TopicApi.getQuizGaps(topicID, startDate, endDate).then((response) => {
      if (response.ok && response.data) {
        setQuizGaps(response.data.quizzes)
      } else {
        throw new Error(`Failed to get quiz gaps for topic`)
      }
    })
    TopicApi.getQuestionGaps(topicID, startDate, endDate).then((response) => {
      if (response.ok && response.data) {
        setQuestionGaps(response.data.questions)
      } else {
        throw new Error(`Failed to get question gaps for topic`)
      }
    })
  }

  const renderKnowledgeGain = (knowledge: number) => {
    let kg = '0'
    if (knowledge < 0) {
      kg = `-${knowledge}`
    } else if (knowledge > 0) {
      kg = `+${knowledge}`
    }
    return kg
  }

  const [timespan, setTimespan] = useState('All Time')

  const strongestQuizzes = getStrongestWeakest(quizGaps ?? []).strongest
  const strongestQuestions = getStrongestWeakest(questionGaps ?? []).strongest
  const weakestQuizzes = getStrongestWeakest(quizGaps ?? []).weakest
  const weakestQuestions = getStrongestWeakest(questionGaps ?? []).weakest

  const onDateChange = (startDate, endDate, label) => {
    setTimespan(label as any)
    loadOverview(startDate, endDate)
  }

  return (
    <>
      <Stack mb={2} direction="row" justifyContent="space-between" alignItems="center">
        <DateFilter onDateChange={onDateChange} />
        <GenerateReport filename={`topic-${topicID}`} />
      </Stack>
      <Stack direction="row" useFlexGap gap={2}>
        <Stack useFlexGap gap={2}>
          <Box position="relative">
            <div style={{ ...TOPIC_IMAGE, backgroundImage: `url(${imageSrc ?? ''})` }} />
            <div style={TOPIC_IMAGE_CONTAINER}>
              <Stack alignItems="center">
                <Image
                  imageStyle={{ objectFit: 'cover' }}
                  src={imageSrc}
                  style={{ height: 90, borderRadius: 100, width: 90, marginBottom: 15 }}
                />
                <Text
                  text={topicStats?.title}
                  variant="titleEmphasized"
                  style={{ color: color.white }}
                />
              </Stack>
            </div>
          </Box>
          {certificationStats && certificationStats?.length > 1 && (
            <Panel sx={{ width: '300px' }} divider title="Certifications">
              <Stack style={{ left: -16, position: 'relative' }}>
                {certificationStats?.map((stat) => {
                  return (
                    <Stack direction="row" alignItems="center">
                      <Medallion scale={0.74} type={stat.level} />
                      <Stack>
                        <Text variant="headline" text={`${Math.round(stat.percent).toFixed(0)}%`} />
                        <Text
                          variant="body2Medium"
                          style={{ color: '#9098A3' }}
                          text={
                            stat.level === null
                              ? `${stat.user_count} user${
                                  stat.user_count > 1 || stat.user_count === 0 ? 's' : ''
                                } have not been certified`
                              : `${stat.user_count} user${
                                  stat.user_count > 1 || stat.user_count === 0 ? 's' : ''
                                } ${stat.user_count === 1 ? 'is' : 'are'} ${stat.level} Certified`
                          }
                        />
                      </Stack>
                    </Stack>
                  )
                })}
              </Stack>
            </Panel>
          )}
        </Stack>
        <Stack useFlexGap gap={2}>
          <Stack flexWrap="wrap" direction="row" useFlexGap gap={2}>
            <Panel sx={{ flex: 1 }}>
              <Insight
                titleVariant="titleEmphasized"
                timespan={timespan}
                title="Baseline Knowledge"
                unit="%"
                data={topicStats?.stats_history[2].data_points}
                value={
                  topicStats?.starting_knowledge
                    ? `${topicStats.starting_knowledge.toFixed(1)}%`
                    : '0%'
                }
              />
            </Panel>
            <Panel sx={{ flex: 1 }}>
              <Insight
                titleVariant="titleEmphasized"
                timespan={timespan}
                title="Current Knowledge"
                unit="%"
                data={topicStats?.stats_history[1].data_points}
                value={
                  topicStats?.current_knowledge
                    ? `${topicStats.current_knowledge.toFixed(1)}%`
                    : '0%'
                }
              />
            </Panel>
            <Panel sx={{ flex: 1 }}>
              <Insight
                titleVariant="titleEmphasized"
                timespan={timespan}
                data={topicStats?.stats_history[0].data_points}
                title="Knowledge Gain"
                unit="%"
                value={
                  topicStats?.knowledge_gain
                    ? `${renderKnowledgeGain(Number(topicStats.knowledge_gain.toFixed(1)))}%`
                    : '0%'
                }
              />
            </Panel>
          </Stack>
          <Stack flexWrap="wrap" direction="row" useFlexGap gap={2}>
            {weakestQuestions?.length || weakestQuizzes?.length ? (
              <StrongestWeakest
                type="Weakest"
                variant="medium"
                quizzes={weakestQuizzes}
                questions={weakestQuestions}
                style={{ flex: 1 }}
              />
            ) : null}
            {strongestQuestions?.length || strongestQuizzes?.length ? (
              <StrongestWeakest
                type="Strongest"
                variant="medium"
                quizzes={strongestQuizzes}
                questions={strongestQuestions}
                style={{ flex: 1 }}
              />
            ) : null}
          </Stack>
        </Stack>
      </Stack>
    </>
  )
})

const TOPIC_IMAGE: React.CSSProperties = {
  height: 200,
  width: 300,
  position: 'absolute',
  backgroundColor: color.shade10,
  backgroundPosition: 'center',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  overflow: 'hidden',
  borderBottomRightRadius: 0,
  borderBottomLeftRadius: 0,
  borderRadius: 20,
}
const TOPIC_IMAGE_CONTAINER: React.CSSProperties = {
  justifyContent: 'center',
  display: 'flex',
  alignItems: 'center',
  backdropFilter: 'blur(15px)',
  borderRadius: 20,
  height: 200,
  width: 300,
}

export const TopicsOverviewTab = (props: any) => {
  const { id } = props
  return {
    name: 'Overview',
    path: `/content/topics/${id}/overview`,
    component: () => <Overview topicID={id} />,
  }
}
