import { observer } from 'mobx-react-lite'
import React from 'react'
import { Text } from '@trivie/ui-web/components/text'
import { QuizValidationRow } from './quiz-validation-row'

export interface TopicSectionProps {
  title?: string
  quizzes: any
}

export const TopicSection = observer((props: TopicSectionProps) => {
  const { title, quizzes } = props

  return (
    <>
      <div style={{ marginTop: 10 }}>
        <Text
          variant="labelEmphasized"
          text={title && title !== 'undefined' ? title : 'No Topic Name'}
          style={title ? {} : { fontStyle: 'italic', opacity: 0.5 }}
        />
        {quizzes?.map((q: any) => <QuizValidationRow quiz={q} />)}
      </div>
    </>
  )
})
