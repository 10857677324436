import React, { useEffect, useState } from 'react'
import { observer } from 'mobx-react-lite'
import { DateTime } from 'luxon'
import { Route, Switch, useRouteMatch } from 'react-router-dom'
import { AddRounded, CloseRounded } from '@mui/icons-material'

import { useStores } from '@trivie/core'
import { color } from '@trivie/ui-web/theme'
import { Text } from '@trivie/ui-web/components/text'
import { Button } from '@trivie/ui-web/components/button'

import { UserTable } from '../../../../tables/user-table'
import { ShortList } from '@trivie/ui-web/components/short-list'

import { TableHelper } from '../../../../tables'
import { Stack } from '@mui/material'
import { ConfirmationDialog } from '@trivie/ui-web/components/dialogs/confirmation-dialog'

const Users = observer(() => {
  const match = useRouteMatch<any>('/activities/assignments/:tab/:id/users')
  const assignmentID = Number(match?.params?.id)
  const tab = String(match?.params?.id)

  const { assignmentStore, manageUserStore } = useStores()
  const {
    addedUsers,
    excludedUsers,
    getAddedUsers,
    getExcludedUsers,
    restoreExcludedUsers,
    removeAddedUsers,
    assignmentOverviewStats,
    getAssignmentDetails,
  } = assignmentStore
  const { permissions } = manageUserStore

  useEffect(() => {
    if (!assignmentOverviewStats) {
      const endTime = DateTime.now()
      const startTime = endTime.plus({ days: -7 }).toISO().toString()
      getAssignmentDetails(assignmentID, startTime, endTime.plus({ days: -7 }).toISO())
    }
    getAddedUsers(assignmentID)
    getExcludedUsers(assignmentID)
  }, [])

  const [showRestoreExcludedUsers, setShowRestoreExcludedUsers] = useState(false)
  const [showRemoveAdditionalUsers, setShowRemoveAdditionalUsers] = useState(false)

  const handleCloseRemove = () => {
    setShowRemoveAdditionalUsers(false)
  }

  const handleCloseExclude = () => {
    setShowRestoreExcludedUsers(false)
  }

  const [loading, setLoading] = useState(false)
  const [tableHelper] = useState(
    new TableHelper('list_assignment_users', 'users', 'assignments', assignmentID),
  )

  const handleRemoveAdded = (id) => {
    setLoading(true)
    removeAddedUsers(Number(assignmentID), [id]).then(() =>
      tableHelper.load().then(() => setLoading(false)),
    )
  }

  const handleRestoreUsers = (id) => {
    restoreExcludedUsers(assignmentID, [id]).then(() => {
      setLoading(true)
      tableHelper.load().then(() => setLoading(false))
    })
  }

  const handleRemoveAll = () => {
    setLoading(true)
    removeAddedUsers(
      Number(assignmentID),
      addedUsers?.map((u: any) => u.id),
    ).then(() => tableHelper.load().then(() => setLoading(false)))
  }

  const handleRestoreAll = () => {
    setLoading(true)
    restoreExcludedUsers(
      Number(assignmentID),
      excludedUsers?.map((u: any) => u.id),
    ).then(() => tableHelper.load().then(() => setLoading(false)))
  }

  return (
    <Switch>
      <Route path="/activities/assignments/:mainTab/:id/users/">
        <UserTable forceLoading={loading} tableHelper={tableHelper} />
        <Stack mt={2} direction="row" useFlexGap gap={2}>
          <ShortList
            data={addedUsers}
            type="Additional Users"
            label={
              <Text
                variant="body2"
                style={{ margin: 20, color: color.shade70 }}
                text={`${addedUsers?.length} user${
                  addedUsers?.length === 0 || addedUsers?.length > 1 ? 's' : ''
                } do not fulfill the group's tag requirements but have been manually added.`}
              />
            }
            action={
              tab !== 'completed' ? (
                <Button
                  disabled={
                    !permissions?.permissions.some((p) => p.code === 'can_edit_assignment') ||
                    addedUsers?.length === 0
                  }
                  onClick={() => setShowRemoveAdditionalUsers(true)}
                  text="Remove All"
                  variant="outlined"
                  color="info"
                  startIcon={<CloseRounded />}
                />
              ) : null
            }
            rowAction={
              tab !== 'completed' &&
              permissions?.permissions.some((p) => p.code === 'can_edit_assignment')
                ? handleRemoveAdded
                : null
            }
          />
          <ShortList
            data={excludedUsers}
            type="Excluded Users"
            label={
              <Text
                variant="body2"
                style={{ margin: 20, color: color.shade70 }}
                text={`${excludedUsers?.length} user${
                  excludedUsers?.length === 0 || excludedUsers?.length > 1 ? 's' : ''
                } fulfill the group's tag requirements but have been manually removed.`}
              />
            }
            action={
              tab !== 'completed' ? (
                <Button
                  disabled={
                    !permissions?.permissions.some((p) => p.code === 'can_edit_assignment') ||
                    excludedUsers?.length === 0
                  }
                  onClick={() => setShowRestoreExcludedUsers(true)}
                  startIcon={<AddRounded />}
                  text="Restore All"
                  variant="outlined"
                  color="info"
                />
              ) : null
            }
            rowAction={
              tab !== 'completed' &&
              permissions?.permissions.some((p) => p.code === 'can_edit_assignment')
                ? handleRestoreUsers
                : null
            }
          />
        </Stack>
        {showRestoreExcludedUsers && (
          <ConfirmationDialog
            danger
            open={showRestoreExcludedUsers}
            title="Restore Excluded Users"
            dialogText="Are you sure you want to restore excluded users?"
            confirmationMessage="Excluded users have been restored to the system."
            onConfirm={handleRestoreAll}
            onClose={handleCloseExclude}
            onCancel={handleCloseExclude}
          />
        )}
        {showRemoveAdditionalUsers && (
          <ConfirmationDialog
            danger
            open={showRemoveAdditionalUsers}
            title="Remove Additional Users"
            dialogText="Are you sure you want to remove additional users?"
            confirmationMessage="Additional users have been removed from the system."
            onConfirm={handleRemoveAll}
            onClose={handleCloseRemove}
            onCancel={handleCloseRemove}
          />
        )}
      </Route>
    </Switch>
  )
})

export const AssignmentsUsersTab = (props: any) => {
  const { id, mainTab } = props
  return {
    name: 'Users',
    path: `/activities/assignments/${mainTab}/${id}/users`,
    component: () => <Users />,
  }
}
