import React, { useState } from 'react'
import { IconButton } from '@mui/material'
import { observer } from 'mobx-react-lite'

import { Text } from '@trivie/ui-web/components/text'
import { Button } from '@trivie/ui-web/components/button'
import { color } from '@trivie/ui-web/theme/color'

import { ChevronLeft, ChevronRight, OpenInNew } from '@mui/icons-material'
import { GetBackgroundColorOfMimeType } from '@trivie/core'
import { ResourceIcon } from '@trivie/ui-web/components/resource-icon'

export const Sources = observer((props: any) => {
  const { sources, choose } = props
  const [selected, setSelected] = useState(null)
  const [confirmed, setConfirmed] = useState(false)
  const [start, setStart] = useState(0)
  const [end, setEnd] = useState(3)

  const onSelect = (index) => {
    setSelected(index)
  }

  const confirm = () => {
    choose(sources[selected!])
    setConfirmed(true)
  }

  const getExtension = (resource) => {
    if (resource?.mimetype === 'text/html') return 'url'
    return resource?.mimetype
  }

  const handlePrevious = () => {
    const s = start - 3
    const e = end - 3
    setStart(s)
    setEnd(e)
  }

  const handleNext = () => {
    const s = start + 3
    const e = end + 3
    setStart(s)
    setEnd(e)
  }

  return (
    <div style={LIST}>
      {sources.map((source, index) => {
        return index >= start && index < end ? (
          <div
            onClick={() => {
              if (!confirmed) {
                onSelect(index)
              }
            }}
            style={{
              ...RESOURCE_CONTAINER,
              cursor: confirmed ? 'default' : 'pointer',
              border: index === selected ? `1px solid  rgba(25, 79, 240, .5)` : `1px solid #EFF0F6`,
              background: index === selected ? '#f8fcff' : '#fff',
            }}
          >
            <div
              style={{
                ...RESOURCE_CONTENT,
                backgroundColor: GetBackgroundColorOfMimeType(getExtension(source)),
              }}
            >
              <ResourceIcon extension={getExtension(source)} white={true} />
            </div>
            <div style={{ flexDirection: 'column' }}>
              <Text style={TITLE} text={source.title} variant="titleEmphasized" />
              <Text style={LINK} text={source.link} variant="body2" />
              <Text style={SNIPPET} text={source.snippet} variant="body2Medium" />
            </div>
            <IconButton
              style={{ height: 40, alignSelf: 'center' }}
              onClick={() => window.open(source.link, '_blank')}
            >
              <OpenInNew htmlColor={color.shade70} />
            </IconButton>
          </div>
        ) : null
      })}
      <div style={PAGINATION}>
        <Text
          text={`Showing: ${start + 1}-${
            end >= sources?.length - 1 ? sources?.length - 1 : end
          } of ${sources?.length}`}
        />
        <div style={{ flexDirection: 'row', display: 'flex' }}>
          <IconButton disabled={start === 0} style={{ marginRight: 16 }} onClick={handlePrevious}>
            <ChevronLeft htmlColor={start === 0 ? color.shade30 : color.shade70} />
          </IconButton>
          <IconButton disabled={end >= sources?.length - 1} onClick={handleNext}>
            <ChevronRight htmlColor={end >= sources?.length - 1 ? color.shade30 : color.shade70} />
          </IconButton>
        </div>
      </div>
      {!confirmed && (
        <div style={FOOTER}>
          <Button
            disabled={selected === null}
            onClick={confirm}
            text="Continue"
            variant={selected ? 'contained' : 'outlined'}
          />
        </div>
      )}
    </div>
  )
})

const LIST: React.CSSProperties = {
  flexDirection: 'column',
  display: 'flex',
  marginTop: 32,
  marginBottom: 32,
  width: '100%',
}
const BUTTON: React.CSSProperties = {
  border: `1px solid ${color.blue}`,
  paddingTop: 12,
  paddingBottom: 12,
  paddingRight: 16,
  paddingLeft: 16,
  borderRadius: 10,
  background: color.white,
  textDecoration: 'none',
  height: 41,
  marginRight: 0,
}
const RESOURCE_CONTAINER: React.CSSProperties = {
  borderRadius: 16,
  paddingTop: 12,
  paddingBottom: 12,
  paddingRight: 16,
  paddingLeft: 16,
  marginBottom: 8,
  display: 'flex',
  justifyContent: 'space-between',
}
const RESOURCE_CONTENT: React.CSSProperties = {
  display: 'flex',
  alignItems: 'center',
  flexDirection: 'row',
  padding: 15,
  margin: 5,
  borderRadius: 10,
}
const TITLE: React.CSSProperties = {
  overflowWrap: 'break-word',
  display: '-webkit-box',
  WebkitLineClamp: 1,
  WebkitBoxOrient: 'vertical',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  maxWidth: '485px',
}
const LINK: React.CSSProperties = { color: color.shade50, ...TITLE }
const SNIPPET: React.CSSProperties = { ...TITLE, color: color.shade70, WebkitLineClamp: 2 }
const FOOTER: React.CSSProperties = {
  marginTop: 12,
  flexDirection: 'row',
  display: 'flex',
  justifyContent: 'flex-end',
}
const PAGINATION: React.CSSProperties = {
  alignItems: 'center',
  flexDirection: 'row',
  display: 'flex',
  justifyContent: 'space-between',
}
