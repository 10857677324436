import { Instance } from 'mobx-state-tree'
import { DateTime } from 'luxon'
import { ContestCreateForm } from '../models/ContestCreateForm'

export class ContestHelper {
  static createContestForm = (initial?: Partial<Instance<typeof ContestCreateForm>>) => {
    return ContestCreateForm.create({
      id: initial?.id,
      start_date: initial?.start_datetime
        ? DateTime.fromISO(initial.start_datetime).toFormat('yyyy-MM-dd')
        : DateTime.local().toISODate(),
      start_time: initial?.start_datetime
        ? DateTime.fromISO(initial.start_datetime).toLocaleString(DateTime.TIME_24_SIMPLE)
        : DateTime.local().toLocaleString(DateTime.TIME_24_SIMPLE),
      end_date: initial?.end_datetime
        ? DateTime.fromISO(initial.end_datetime).toFormat('yyyy-MM-dd')
        : DateTime.local().plus({ months: 1 }).toISODate(),
      end_time: initial?.end_datetime
        ? DateTime.fromISO(initial.end_datetime).toLocaleString(DateTime.TIME_24_SIMPLE)
        : DateTime.local().toLocaleString(DateTime.TIME_24_SIMPLE),
      title: initial?.title ?? '',
      description: initial?.description ?? '',
      group_ids:
        initial?.group_ids?.map((g: any) => ({
          group_id: g,
          label: '',
          subLabel: '',
          checked: true,
          subItems: null,
          count: 0,
        })) ?? [],
      user_ids:
        initial?.user_ids?.map((u: any) => ({
          user_id: u,
          label: '',
          subLabel: '',
          checked: true,
          subItems: null,
          count: 0,
        })) ?? [],
      topic_ids: initial?.topic_ids ?? [],
      quiz_ids: initial?.quiz_ids ?? [],
      content: undefined,
      content_method:
        (initial?.topic_ids?.length === 0 && initial?.quiz_ids?.length === 0) || !initial?.title
          ? 'standard'
          : 'custom',
      max_leaderboard_length_enabled: initial?.max_leaderboard_length ? true : false,
      max_leaderboard_length: initial?.max_leaderboard_length ?? 7,
      notify_users: initial?.notify_users ?? false,
      scoring_method: initial?.scoring_method ?? 'standard',
      custom_scoring: initial?.custom_scoring ?? {
        answers: false,
        badges: false,
        comments: false,
        posts: false,
        reinforcements: false,
        surveys: false,
        tests: false,
      },
    })
  }
}
