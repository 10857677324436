import React, { useEffect, useState } from 'react'
import { observer } from 'mobx-react-lite'
import { Box } from '@mui/material'

import { useStores } from '@trivie/core'
import { TagApi } from '@trivie/core/src/services/api-objects/TagApi'

import { TagSelector } from '../tag-selector'
import { Dialog } from '../dialogs/dialog'
import { Text } from '../text'

export interface AddTagsProps {
  open: boolean
  users: any
  onClose: () => void
  onConfirm: () => void
  table?: boolean
  existing?: any
}

export const AddTags = observer((props: AddTagsProps) => {
  const { existing = [], onConfirm, open, users, onClose } = props
  const { tagStore } = useStores()
  const { addUsersToTags } = tagStore
  const [tags, setTags] = useState<any>([])
  const [availableTags, setAvailableTags] = useState<any>([])
  const [formattedTags, setFormattedTags] = useState({})

  useEffect(() => {
    TagApi.getAllTags(
      'tags',
      false,
      [{ field: 'tag_key' }, { field: 'tag_value' }],
      'list_tags',
    ).then((resp) => {
      if (resp.ok) {
        // @ts-ignore
        const available = resp.data.rows.map((tag) => {
          const key = tag.columns.find((column) => column.field === 'tag_key')
          const value = tag.columns.find((column) => column.field === 'tag_value')
          return { id: tag.id, key: key.value, value: value.value }
        })
        const t = {}
        setAvailableTags(available)
        available.map((tag) => {
          t[tag.key] = { values: [] }
        })
        available.map((tag) => {
          t[tag.key].values.push(tag)
        })

        if (existing) {
          setTags(existing)
        }

        setFormattedTags(t)
      }
    })
  }, [])

  const handleSubmit = () => {
    addUsersToTags(
      users,
      tags.map((tag: any) => tag.id),
    ).then(() => {
      onConfirm()
      onClose()
    })
  }

  return (
    <Dialog
      maxWidth="sm"
      onCancel={onClose}
      onConfirm={handleSubmit}
      disableConfirm={!tags.length}
      confirmBtnLabel="Add Tags"
      onClose={onClose}
      title="Add Tags"
      open={open}
    >
      <Text variant="body1" text="Tags" color="text.secondary" mb={2} />
      <Box minHeight={460}>
        <TagSelector
          formattedTags={formattedTags}
          tags={availableTags}
          selectedTags={tags}
          onChange={(selected) => setTags(selected)}
          open
        />
      </Box>
    </Dialog>
  )
})
