import React, { useState } from 'react'
import { observer } from 'mobx-react-lite'
import { useRouteMatch } from 'react-router-dom'

import { TableHelper } from '../../../../tables'
import { ResourceTable } from '../../../../tables/resource-table'

const Sources = observer(() => {
  const match = useRouteMatch<any>('/content/paths/:id/sources')
  const pathID = Number(match?.params?.id)

  const [tableHelper] = useState(
    new TableHelper('list_path_resources', 'resources', 'paths', pathID),
  )

  return <ResourceTable tableHelper={tableHelper} />
})

export const PathSourcesTab = (props: any) => {
  const { id } = props
  return {
    name: 'Sources',
    path: `/content/paths/${id}/sources`,
    component: () => <Sources />,
  }
}
