import React, { useEffect, useState } from 'react'
import { observer } from 'mobx-react-lite'
import { Instance } from 'mobx-state-tree'
import { Box, InputAdornment, Stack, TextField } from '@mui/material'
import { Search } from '@mui/icons-material'

import { Dialog } from '../dialogs/dialog'
import { useStores } from '@trivie/core'
import { color } from '../../theme'
import { Text } from '../text'
import { BadgeAvatar } from '../badge-avatar'
import { ConfirmDialog } from '../confirm-dialog'
import { BadgeUserItem } from './badge-user-item'
import { BadgeUserReadModel } from '@trivie/core/src/models/base/badge'

export const BadgeUsersDialog = observer(
  (props: { open: boolean; requestClose: () => void; badge: any | null }) => {
    const { badgesStore } = useStores()
    const { open, badge } = props
    const { badgeUsers, getBadgeUsers, revokeBadgeFromUser } = badgesStore
    const [search, setSearch] = useState('')
    const [isLoading, setIsLoading] = useState(false)
    const [selectedUser, setSelectedUser] = useState<Instance<typeof BadgeUserReadModel> | null>(
      null,
    )
    const [showRevokeUser, setShowRevokeUser] = useState(false)

    const noOptions = !badgeUsers.length
    useEffect(() => {
      if (open) {
        setIsLoading(true)
        setSearch('')
        if (badge?.id) {
          getBadgeUsers(badge.id).then(() => {
            setIsLoading(false)
          })
        }
      }
    }, [open])

    return (
      <Dialog maxWidth="sm" onClose={props.requestClose} title="Users With This Badge" open={open}>
        <Stack mb={2} useFlexGap gap={2} direction="row">
          <BadgeAvatar
            size={40}
            name={badge?.badge_title}
            url={badge?.photo_url}
            hoverable={false}
          />
          <Stack>
            <Text variant="body1Emphasized" text={badge?.badge_title ?? ''} />
            <Text variant="body2Medium" color="text.secondary" text={badge?.badge_criteria ?? ''} />
          </Stack>
        </Stack>
        <TextField
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <Search htmlColor={color.palette.grey} />
              </InputAdornment>
            ),
          }}
          fullWidth
          placeholder="Search"
          type="search"
          onChange={(e) => setSearch(e.target.value)}
        />
        <div
          style={{
            height: '100%',
            justifyContent: 'flex-start',
            display: 'flex',
            flexDirection: 'column',
          }}
          id="scroll-dialog-description"
        >
          {noOptions && !isLoading ? (
            <Text
              variant="headline"
              text={'No Users Found'}
              style={{ color: color.shade50, marginTop: 24 }}
            />
          ) : null}
          <Box maxHeight={500}>
            {badgeUsers
              .filter((usr) =>
                search
                  ? usr.display_name?.toLowerCase()?.includes(search.toLowerCase()) ||
                    usr.awarded_by?.toLowerCase().includes(search.toLowerCase())
                  : true,
              )
              ?.map((user) => (
                <div style={{ display: 'flex', flexDirection: 'row' }}>
                  <BadgeUserItem
                    user={user}
                    onRevokeClick={() => {
                      setSelectedUser(user)
                      setShowRevokeUser(true)
                    }}
                  />
                </div>
              ))}
          </Box>
        </div>
        {showRevokeUser && (
          <ConfirmDialog
            open={showRevokeUser}
            danger
            title="Revoke Badge"
            message={`Are you sure you want to revoke the ${badge?.badge_title} badge from ${selectedUser?.display_name}?`}
            confirmedMessage={`The "${badge?.badge_title}" badge was revoked from ${selectedUser?.display_name}.`}
            confirmButton="Confirm"
            onConfirm={() => {
              if (badge?.id && selectedUser?.user_id) {
                revokeBadgeFromUser({ badge_id: badge.id, user_id: selectedUser.user_id })
              }
            }}
            onClose={() => {
              // setShowRevokeUser(false)
              // props.requestClose()
            }}
          />
        )}
      </Dialog>
    )
  },
)
