import { types, getEnv, flow, cast, Instance, getSnapshot } from 'mobx-state-tree'
import { Environment } from '../environment'
import { TopicApi } from '../../services/api-objects/TopicApi'
import { Awaited } from '../../utility-types/Awaited'
import { TopicDetailStat } from '../TopicDetailStat'
import { TopicMasteryDistributionStat } from '../TopicMasteryDistributionStat'
import {
  GetResourceRead,
  GetTopicGroupStat,
  GetTopicListStat,
  GetTopicQuiz,
  GetTopicUser,
} from '../response-models'
import { GetTopicAssignment } from '../response-models/assignment'
import { CertificationStats } from '../CertificationStat'

export const TopicsStoreModel = types
  .model('TopicsStore')
  .props({
    status: types.optional(types.enumeration(['idle', 'pending', 'done', 'error']), 'idle'),
    topics: types.maybeNull(types.array(GetTopicListStat)),
    mastery: types.maybeNull(TopicMasteryDistributionStat),
    topicStats: types.maybeNull(TopicDetailStat),
    users: types.maybeNull(types.array(GetTopicUser)),
    assignments: types.maybeNull(types.array(GetTopicAssignment)),
    resources: types.maybeNull(types.array(GetResourceRead)),
    topicQuizzes: types.maybeNull(types.array(GetTopicQuiz)),
    groups: types.maybeNull(types.array(GetTopicGroupStat)),
    quizzesForTopics: types.map(types.array(GetTopicQuiz)),
    loadingQuizzes: types.map(types.boolean),
    loadingTopics: types.optional(types.enumeration(['idle', 'pending', 'done', 'error']), 'idle'),
    selectedTopic: types.optional(types.number, 0),
    certificationStats: types.array(CertificationStats),
  })
  .actions((self) => ({
    setStatus(value?: 'idle' | 'pending' | 'done' | 'error') {
      self.status = value || 'idle'
    },
    setTopics(topics: typeof self.topics) {
      self.topics = topics
      if (topics) {
        topics.forEach((topic) => {
          self.quizzesForTopics.set(String(topic.topic_id), [])
          self.loadingQuizzes.set(String(topic.topic_id), true)
        })
      }
    },
    setCertificationStats(data: typeof self.certificationStats) {
      self.certificationStats = data
    },
    resetLoading() {
      self.loadingQuizzes.clear()
    },
    setLoadingTopics(status: typeof self.loadingTopics) {
      self.loadingTopics = status
    },
    setQuizMap(topic_id: number, quizzes: Array<Instance<typeof GetTopicQuiz>>) {
      self.quizzesForTopics.set(String(topic_id), quizzes)
    },
    setLoadingQuizzes(id: number, loading: boolean) {
      self.loadingQuizzes.set(String(id), loading)
    },
    setTopicStats(stats: typeof self.topicStats) {
      self.topicStats = stats
    },
    setMastery(mastery: typeof self.mastery) {
      self.mastery = mastery
    },
    setUsers(users: typeof self.users) {
      self.users = users
    },
    setAssignments(assignments: typeof self.assignments) {
      self.assignments = assignments
    },
    setResources(resources: typeof self.resources) {
      self.resources = resources
    },
    setTopicQuizzes(quizzes: typeof self.topicQuizzes) {
      self.topicQuizzes = quizzes
    },
    setGroups(groups: typeof self.groups) {
      self.groups = groups
    },
    setSelectedTopic(id: typeof self.selectedTopic) {
      self.selectedTopic = id
    },
  }))
  .views((self) => ({
    get environment() {
      return getEnv(self) as Environment
    },
    get isLoading() {
      return self.status === 'pending'
    },
    get isLoadingTopics() {
      return self.loadingTopics === 'pending'
    },
  }))
  .actions((self) => ({
    getAssignmentsForTopic: flow(function* (id: number, start: string, end: string) {
      self.setStatus('pending')
      try {
        const result: Awaited<ReturnType<typeof TopicApi.getAssignmentsForTopic>> =
          yield TopicApi.getAssignmentsForTopic(id, start, end)

        if (result.ok && result.data) {
          self.setAssignments(cast(result.data))
          self.setStatus('done')
        } else {
          self.setStatus('error')
        }
      } catch {
        self.setStatus('error')
      }
    }),
    checkDeleteTopics: flow(function* (ids: Array<number>) {
      let result
      try {
        result = yield self.environment.api.checkDeleteTopics(ids)
        if (result.kind === 'ok') {
          self.setStatus('done')
          return result.data
        } else {
          self.setStatus('error')
        }
      } catch (err) {
        self.setStatus('error')
        return err
      }
    }),
    setQuizzesForTopic: flow(function* (id, payload) {
      self.setStatus('pending')
      try {
        const result = yield self.environment.api.setQuizzesForTopic(id, payload)
        if (result.kind === 'ok') {
          self.setStatus('done')
        } else {
          self.setStatus('error')
        }
      } catch {
        self.setStatus('error')
      }
    }),
    removeQuizzesFromTopic: flow(function* (id, payload) {
      self.setStatus('pending')
      try {
        const result = yield self.environment.api.removeQuizzesFromTopic(id, payload)
        if (result.kind === 'ok') {
          self.setStatus('done')
        } else {
          self.setStatus('error')
        }
      } catch {
        self.setStatus('error')
      }
    }),
    getTopicDetails: flow(function* (id: number, start: string, end: string) {
      self.setStatus('pending')
      self.setTopicStats(null)
      try {
        const result: Awaited<ReturnType<typeof TopicApi.getTopicDetails>> =
          yield TopicApi.getTopicDetails(id, start, end)

        if (result.ok) {
          self.setTopicStats(result.data!)
          self.setStatus('done')
        } else {
          self.setStatus('error')
        }
      } catch {
        self.setStatus('error')
      }
    }),
    getMastery: flow(function* (id: number, start: string, end: string) {
      self.setStatus('pending')
      try {
        const result: Awaited<ReturnType<typeof TopicApi.getTopicMastery>> =
          yield TopicApi.getTopicMastery(id, start, end)

        if (result.ok) {
          self.setMastery(result.data!)
          self.setStatus('done')
        } else {
          self.setStatus('error')
        }
      } catch {
        self.setStatus('error')
      }
    }),
    getGroupsForTopic: flow(function* (id: number) {
      self.setStatus('pending')
      try {
        const result: Awaited<ReturnType<typeof TopicApi.getTopicGroups>> =
          yield TopicApi.getTopicGroups(id)

        if (result.ok && result.data) {
          self.setGroups(cast(result.data))
          self.setStatus('done')
        } else {
          self.setStatus('error')
        }
      } catch {
        self.setStatus('error')
      }
    }),
    deleteTopics: flow(function* (ids: Array<number>) {
      self.setStatus('pending')
      try {
        const result = yield self.environment.api.deleteTopics(ids)
        if (result.kind === 'ok') {
          self.setStatus('done')
        } else {
          self.setStatus('error')
        }
      } catch {
        self.setStatus('error')
      }
    }),
    removeResourcesFromTopic: flow(function* (id: number, resourceIds: Array<number>) {
      self.setStatus('pending')

      try {
        const result = yield self.environment.api.removeResourcesFromTopic(id, resourceIds)
        if (result.kind === 'ok') {
          self.setStatus('done')
        } else {
          self.setStatus('error')
        }
      } catch {
        self.setStatus('error')
      }
    }),
    getCertificationStats: flow(function* (id: number) {
      self.setStatus('pending')
      try {
        const result: Awaited<ReturnType<typeof TopicApi.getCertificationStats>> =
          yield TopicApi.getCertificationStats(id)

        if (result.ok && result.data) {
          self.setStatus('done')
          self.setCertificationStats(cast(result.data))
          return result.data
        } else {
          self.setStatus('error')
        }
      } catch {
        self.setStatus('error')
      }
    }),
    exportTopics: flow(function* (ids: number[]) {
      self.setStatus('pending')
      try {
        const result: Awaited<ReturnType<typeof TopicApi.exportTopics>> =
          yield TopicApi.exportTopics(ids)
        if (result.ok && result.data) {
          return result.data
        } else {
          self.setStatus('error')
        }
      } catch {
        self.setStatus('error')
      }
    }),
  }))

type TopicsStoreType = typeof TopicsStoreModel.Type
export interface TopicsStore extends TopicsStoreType {}
type TopicsStoreSnapshotType = typeof TopicsStoreModel.SnapshotType
export interface TopicsStoreSnapshot extends TopicsStoreSnapshotType {}
