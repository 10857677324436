import React, { useEffect, useState } from 'react'

import { Instance } from 'mobx-state-tree'
import { observer } from 'mobx-react-lite'

import { CircularProgress, InputAdornment, TextField } from '@mui/material'
import { Search } from '@mui/icons-material'

import { ResourceComponent } from '@trivie/ui-web/components/resource-component'
import { Text } from '@trivie/ui-web/components/text'
import { color } from '@trivie/ui-web/theme'
import { useStores } from '@trivie/core'
import { Resource } from '@trivie/core/src/models/Resource'
import { ResourceApi } from '@trivie/core/src/services/api-objects'

interface ExistingResourcesTabProps {
  quiz: any
  standalone?: any
  topic?: any
  onSelect?: any
  selected?: any
}

const allowedFileTypes = ['doc', 'docx', 'ppt', 'pptx', 'txt', 'url', 'pdf']
export const ExistingResourcesTab = observer((props: ExistingResourcesTabProps) => {
  const { selected, onSelect, quiz, standalone } = props
  const { resourceStore } = useStores()
  const { getAvailableResources, availableResources } = resourceStore
  const [searchText, setSearchText] = useState('')
  const [loading, setLoading] = useState(standalone)

  useEffect(() => {
    getAvailableResources().then(() => setLoading(false))
  }, [])

  const selectResource = (resource: Instance<typeof Resource>) => {
    if (standalone) {
      onSelect(resource)
      return
    } else {
      resourceStore?.getResourceDetails(resource.resource_id)
      quiz.setMercuryResource(resource)
      quiz.addResource(resource)
    }
  }

  return quiz?.mercuryResource && !standalone ? null : (
    <>
      <TextField
        fullWidth
        placeholder="Search your source library"
        variant="outlined"
        value={searchText}
        size="small"
        onChange={(e) => setSearchText(e.target.value)}
        style={{ backgroundColor: 'white', marginBottom: 12 }}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <Search htmlColor={color.shade50} />
            </InputAdornment>
          ),
        }}
      />
      <div
        id="existing-resources-list"
        style={{ ...LIST, maxHeight: standalone ? 595 : 'calc(100vh - 364px)' }}
      >
        {loading ? (
          <CircularProgress style={LOADER} />
        ) : availableResources?.length === 0 ? (
          <div style={NULL}>
            <Text
              variant="body1Medium"
              style={{ color: color.shade30 }}
              text="No existing sources to choose from"
            />
          </div>
        ) : (
          availableResources
            ?.filter((resource) => {
              if (standalone) {
                return (
                  !searchText || resource.title.toLowerCase().includes(searchText.toLowerCase())
                )
              } else {
                return (
                  allowedFileTypes.includes(resource.mime_type || '') &&
                  (!searchText || resource.title.toLowerCase().includes(searchText.toLowerCase()))
                )
              }
            })
            .map((resource) => {
              return (
                <ResourceComponent
                  key={`${resource}.title_${resource.resource_id}`}
                  resource={resource}
                  isSelected={selected?.resource_id === resource.resource_id}
                  onClick={(r) => selectResource(r)}
                  style={RESOURCE}
                />
              )
            })
        )}
      </div>
    </>
  )
})

const LIST: React.CSSProperties = {
  overflowY: 'auto',
}
const RESOURCE: React.CSSProperties = {
  cursor: 'pointer',
  marginBottom: 12,
  border: `1px solid ${color.shade5}`,
  borderRadius: 12,
}
const NULL: React.CSSProperties = {
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'center',
  flex: 1,
  paddingTop: 24,
}
const LOADER: React.CSSProperties = { margin: '0 auto', marginTop: 120, display: 'flex' }
