import { types } from 'mobx-state-tree'

export const CommSettingsModel = types.model('CommSettings').props({
  onesignal_id: types.maybeNull(types.string),
  push_enabled: types.boolean,
  push_notify_likes: types.boolean,
  feedback_email_enabled: types.boolean,
  reminder_email_enabled: types.boolean,
  product_email_enabled: types.boolean,
  support_email_enabled: types.boolean,
  push_notify_quizzes: types.string,
  push_notify_tests: types.string,
  push_notifications_allowed: types.maybeNull(types.string),
  push_challenge_received: types.string,
  push_challenge_completed: types.string,
})
