import { parsePhoneNumber } from 'libphonenumber-js'

export const getDisplayNumber = (number) => {
  if (!number) {
    return ''
  }
  try {
    const num = parsePhoneNumber(number)
    if (num.country !== 'US') {
      return parsePhoneNumber(number, num.country).formatInternational()
    }
    return num.formatNational()
  } catch {
    return parsePhoneNumber(number, 'US').formatNational()
  }
}

export const validateAndFormatNumber = (number = '') => {
  try {
    const intl = parsePhoneNumber(`+${number}`)
    if (intl.isValid()) {
      return intl.format('E.164')
    }
  } catch {
    try {
      const natl = parsePhoneNumber(`${number}`, 'US')
      if (natl.isValid()) {
        return natl.format('E.164')
      }
    } catch {
      return ''
    }
  }
}
