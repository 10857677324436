import { types } from 'mobx-state-tree'

export const MasteryDistributionStat = types.model('MasteryDistributionStat').props({
  most: types.number,
  some: types.number,
  few: types.number,
  most_user_count: types.number,
  some_user_count: types.number,
  few_user_count: types.number,
})
