import React from 'react'
import { TextField } from '@mui/material'
import { color } from '@trivie/ui-web/theme/color'
import { Text } from '@trivie/ui-web/components/text'
import { BaseQuestion } from './base-question'
import { Instance } from 'mobx-state-tree'
import { SurveyQuestion, SurveyQuestionChoice } from '@trivie/core/src/models/Survey'
import { observer } from 'mobx-react-lite'
import { roundNumber } from '@trivie/core/src/utils/roundNumber'

interface RangeQuestionProps {
  question: Instance<typeof SurveyQuestion>
  disabled?: boolean
  showQuestionPct?: boolean
  handleDrag?: () => void
  handleDrop?: () => void
}
export const RangeQuestion = observer((props: RangeQuestionProps) => {
  const { question, disabled, showQuestionPct, handleDrag, handleDrop } = props

  const renderDummyLabel = () => {
    const dummyLabels = [1, 2, 3, 4, 5]
    return dummyLabels.map((value, idx) => (
      <div style={LABEL_CONTAINER}>
        <div style={MIN_LABEL}>
          <Text style={LABEL_TXT} text={String(value)} variant="body2Medium" />
        </div>
        {idx === 0 || idx === dummyLabels.length - 1 ? (
          <TextField
            variant="standard"
            value={idx === 0 ? question?.min_label : question?.max_label}
            placeholder="Label"
            disabled={true}
            InputProps={{
              style: {
                color:
                  idx === 0
                    ? question.minLabelTooLong || !question.min_label
                      ? color.red
                      : color.black
                    : question.maxLabelTooLong || !question.max_label
                      ? color.red
                      : color.black,
              },
              disableUnderline: disabled,
            }}
          />
        ) : null}
      </div>
    ))
  }

  const renderLabelText = () => {
    const { choices } = question
    return choices?.map((value: Instance<typeof SurveyQuestionChoice>, idx: number) => (
      <div style={ANSWER_CONTAINER}>
        <div style={LABEL_CONTAINER}>
          <div style={MIN_LABEL}>
            <Text style={LABEL_TXT} text={String(value.text)} variant="body2Medium" />
          </div>
          {idx === 0 || idx === choices.length - 1 ? (
            <TextField
              variant="standard"
              value={idx === 0 ? question?.min_label : question?.max_label}
              placeholder="Label"
              disabled={true}
              InputProps={{
                style: {
                  color:
                    idx === 0
                      ? question.minLabelTooLong || !question.min_label
                        ? color.red
                        : color.black
                      : question.maxLabelTooLong || !question.max_label
                        ? color.red
                        : color.black,
                },
                disableUnderline: disabled,
              }}
            />
          ) : null}
        </div>
        {showQuestionPct ? (
          <Text
            style={{ fontSize: 14, color: color.palette.quizSummary }}
            text={`${roundNumber(value?.pct_selected ?? 0)}%`}
            variant="body2Medium"
          />
        ) : null}
      </div>
    ))
  }
  return (
    <BaseQuestion
      handleDrop={handleDrop}
      handleDrag={handleDrag}
      question={question}
      disabled={disabled}
    >
      <div style={CONTAINER}>
        <Text style={RANGE_TXT} text="Scale 1-5" variant="body2Medium" />
        {disabled ? (
          showQuestionPct ? (
            renderLabelText()
          ) : (
            renderDummyLabel()
          )
        ) : (
          <>
            <div style={LABEL_CONTAINER}>
              <div style={MIN_LABEL}>
                <Text style={LABEL_TXT} text="1" variant="body2Medium" />
              </div>
              <TextField
                variant="standard"
                onBlur={() => question.setShowErrors(true)}
                onChange={(e) => question.setMinLabel(e.target.value)}
                placeholder="Label"
                disabled={disabled}
                value={question.min_label}
                InputProps={{
                  style: {
                    color:
                      question.minLabelTooLong || question.emptyMinLabel ? color.red : color.black,
                  },
                  disableUnderline: disabled,
                }}
              />
            </div>
            <div style={LABEL_CONTAINER}>
              <div style={MAX_LABEL}>
                <Text style={LABEL_TXT} text="5" variant="body2Medium" />
              </div>
              <TextField
                variant="standard"
                onBlur={() => question.setShowErrors(true)}
                onChange={(e) => question.setMaxLabel(e.target.value)}
                placeholder="Label"
                disabled={disabled}
                value={question.max_label}
                InputProps={{
                  style: {
                    color:
                      question.maxLabelTooLong || question.emptyMaxLabel ? color.red : color.black,
                  },
                  disableUnderline: disabled,
                }}
              />
            </div>
          </>
        )}
      </div>
    </BaseQuestion>
  )
})

const CONTAINER: React.CSSProperties = { flexDirection: 'column', paddingLeft: 20 }
const RANGE_TXT: React.CSSProperties = { color: color.palette.quizSummary, marginBottom: 20 }
const LABEL: React.CSSProperties = {
  backgroundColor: 'rgba(240, 242, 245, 1)',
  width: 16,
  height: 16,
  borderRadius: 100,
  alignItems: 'center',
  display: 'flex',
  marginRight: 14,
  justifyContent: 'center',
  textAlign: 'center',
}
const MIN_LABEL: React.CSSProperties = {
  ...LABEL,
}
const MAX_LABEL: React.CSSProperties = {
  ...LABEL,
}
const LABEL_CONTAINER: React.CSSProperties = {
  marginBottom: 16,
  flexDirection: 'row',
  display: 'flex',
  alignItems: 'center',
}
const LABEL_TXT: React.CSSProperties = { fontWeight: 600 }
const ANSWER_CONTAINER: React.CSSProperties = {
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
}
