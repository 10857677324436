import { GroupsTopicsTab } from './groups-topics-tab'
import { GroupsQuestionsTab } from './groups-questions-tab'
import { GroupsPeopleTab } from './groups-people-tab'
import { GroupsAssignmentsTab } from './groups-assignments-tab'
import { GroupsOverviewTab } from './groups-overview-tab'

export const groupsTabs = [
  GroupsOverviewTab,
  GroupsAssignmentsTab,
  GroupsTopicsTab,
  GroupsQuestionsTab,
  GroupsPeopleTab,
]
