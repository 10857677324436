import React, { useEffect, useState } from 'react'
import { Divider, Tab, Tabs } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { Link, Route, useLocation, useRouteMatch } from 'react-router-dom'

const _snakeCase = require('lodash/snakeCase')

const tabA11yProps = (index: any) => {
  return {
    id: `a11y-tab-${index}`,
    'aria-controls': `a11y-tabpanel-${index}`,
  }
}

interface NavigationTabsProps {
  tabList: any
  id?: any
  active?: any
  disabled?: boolean
  engage?: boolean
  align?: 'left' | 'center'
}

export const NavigationTabs = (props: NavigationTabsProps) => {
  const { engage, align, tabList, id, active, disabled } = props
  const [value, setValue] = useState<number | undefined>(0)
  const location = useLocation()
  const { params } = useRouteMatch()

  // @ts-ignore
  const { mainTab, extra } = params

  const handleTabChange = (event: React.ChangeEvent<any>, newValue: number) => {
    setValue(newValue)
  }

  useEffect(() => {
    const selectedTab = tabList.findIndex(
      (tab: any) => tab({ id, mainTab, extra }).path === location.pathname,
    )

    // workaround for nested added / excluded views under people tab
    if (active === 'people') {
      setValue(4)
    } else {
      setValue(selectedTab === -1 ? 0 : selectedTab)
    }
  }, [mainTab])

  // workaround for refreshing / linking into a tab view
  // after removing fixed/minwidth widths for tabs, the indicator was not
  // calculating the offset position correctly - until resize or tab press
  setTimeout(() => {
    // window.dispatchEvent(new Event('resize'))
  }, 0)

  const useTabsStyles = makeStyles({
    // Dropdown container
    indicator: {
      height: 0,
    },
    fixed: {
      display: 'flex',
      justifyContent: align === 'left' ? 'flex-start' : 'center',
    },
  })

  const useTabStyles = makeStyles({
    // Dropdown container
    root: {
      position: 'relative',
      padding: '6px 12px',
      overflow: 'hidden',
      fontSize: '0.875rem',
      letterSpacing: '0px !important',
      maxWidth: '264px',
      minWidth: '0px',
      boxSizing: 'border-box',
      minHeight: '48px',
      textAlign: 'center',
      flexShrink: 0,
      fontFamily: 'Inter',
      fontWeight: 500,
      lineHeight: 1.75,
      whiteSpace: 'normal',
      textTransform: 'none',
      justifyContent: 'center',
    },
  })

  const tabsClasses = useTabsStyles()
  const tabClasses = useTabStyles()

  return (
    <>
      <Tabs
        classes={engage ? { ...tabsClasses } : {}}
        selectionFollowsFocus
        variant="standard"
        indicatorColor="primary"
        value={value}
        aria-labelledby="a11y-tabs-automatic-label"
        onChange={handleTabChange}
        aria-label="user details screen - tab bar navigation"
      >
        {tabList.map((tab: any, index: number) => (
          <Tab
            classes={tabClasses}
            disabled={disabled}
            key={index}
            // selected={location.pathname === tab({ id, mainTab, extra }).path}
            disableRipple
            component={Link}
            to={tab({ id, mainTab, extra }).path}
            label={tab({ id, mainTab, extra }).name}
            {...tabA11yProps(index)}
            data-qat={`${_snakeCase(tab({ id, mainTab, extra }).name)}_nav_tab`}
          />
        ))}
      </Tabs>
      {!engage && <Divider />}
      <div
        style={{
          marginTop: 20,
          overflowY: 'hidden',
          height: '100%',
        }}
      >
        {tabList.map((tab: any, index: number) => {
          return (
            <Route
              key={index}
              exact
              path={tab({ id, mainTab, extra }).path}
              render={tab({ id, mainTab, extra, locationKey: location.key }).component}
            />
          )
        })}
      </div>
    </>
  )
}
