import React from 'react'
import CircularProgress, { circularProgressClasses } from '@mui/material/CircularProgress'
import { Text } from '../text'

interface FullScreenLoaderProps {
  status?: string
}
export const FullScreenLoader = (props: FullScreenLoaderProps) => {
  const { status } = props
  return (
    <div style={LOADER}>
      <div style={{ position: 'relative' }}>
        <CircularProgress
          variant="determinate"
          sx={{ color: '#ccc' }}
          size={32}
          thickness={5}
          value={100}
        />
        <CircularProgress
          variant="indeterminate"
          sx={{
            color: '#808080',
            position: 'absolute',
            left: 0,
            [`& .${circularProgressClasses.circle}`]: {
              strokeLinecap: 'round',
            },
          }}
          size={32}
          thickness={5}
        />
      </div>
      <Text text={status || 'Loading...'} style={{ color: 'rgba(0,0,0,0.78)' }} variant="body2" />
    </div>
  )
}

const LOADER: React.CSSProperties = {
  justifyContent: 'center',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  flex: 1,
  gap: 20,
}
