import { Instance } from 'mobx-state-tree'
import { QUESTION_TYPES } from '../constants/enums'
import { ImportContent } from '../models/ImportContent'
import { isShortQuestionType } from '../type-guards/isShortQuestionType'
import { QuestionType } from '../models/QuestionType'

export const inferQuestionType = (row: ImportContent): Instance<typeof QuestionType> => {
  const questionType =
    row.question_type && isShortQuestionType(row.question_type)
      ? QUESTION_TYPES[row.question_type]
      : null

  if (questionType) {
    return questionType
  }

  const answers = [row.answer_1, row.answer_2, row.answer_3, row.answer_4].filter((a) => a)
  // if no answers default to MC
  if (!answers.length) {
    return 'Multiple Choice'
    // if every answer is true or false and there are only 2 answers
  } else if (
    answers.every((a) =>
      ['true', 'false', 'yes', 'no', 't', 'f'].includes(a!.trim().toLowerCase()),
    ) &&
    answers.length === 2
  ) {
    return 'True or False'
    // if there are more than 1 correct answers
  } else if (answers.reduce((acc, answer) => (answer?.includes('*') ? acc + 1 : acc), 0) > 1) {
    return 'Multiple Response'
    // if there is only 1 correct answer
  } else if (answers.reduce((acc, answer) => (answer?.includes('*') ? acc + 1 : acc), 0) === 1) {
    return 'Multiple Choice'
    // if all else fails, default to MC
  } else {
    return 'Multiple Choice'
  }
}
