import { observer } from 'mobx-react-lite'
import React, { useEffect } from 'react'
import { Controller, useForm } from 'react-hook-form'

import { TextField } from '@mui/material'
import { Dialog } from '../dialogs/dialog'
import { useStores } from '@trivie/core'
import { Avatar } from '../avatar'
import { Text } from '../text'
import { color } from '../../theme'

export const PersonalSettingsDialog = observer((props: any) => {
  const { open, onClose } = props
  const { manageUserStore } = useStores()
  const { userProfile, uploadProfilePhoto, updateUser } = manageUserStore

  const { control, handleSubmit, setValue, watch, formState } = useForm({ shouldUnregister: false })

  const data = watch()

  useEffect(() => {
    if (userProfile) {
      setValue('firstName', userProfile?.first_name)
      setValue('lastName', userProfile?.last_name)
      setValue('photo', userProfile?.photo_url ?? '')
    }
  }, [userProfile])

  const onSubmit = (formData: any) => {
    if (!userProfile?.id || typeof userProfile.id !== 'number') {
      throw new Error('id is not set on user')
    }

    if (formData.photo && typeof formData.photo !== 'string') {
      uploadProfilePhoto(data.photo, userProfile!.id)
    }

    if (
      userProfile?.first_name !== formData.firstName ||
      userProfile?.last_name !== userProfile?.last_name
    ) {
      updateUser({ first_name: formData.firstName, last_name: formData.lastName })
    }

    onClose()
  }

  return (
    <Dialog
      onCancel={onClose}
      onConfirm={handleSubmit(onSubmit)}
      onClose={onClose}
      title="Personal Settings"
      maxWidth="sm"
      aria-labelledby="personal settings"
      open={open}
      disableConfirm={!formState.isDirty || !data.firstName || !data.lastName}
      confirmBtnLabel="Save Changes"
    >
      <div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
        <Controller
          control={control}
          render={({ onChange, value }) => (
            <Avatar
              size={90}
              name={`${userProfile?.first_name} ${userProfile?.last_name}`}
              url={typeof value === 'string' ? value : value ? URL.createObjectURL(value) : ''}
              onChange={(file: any) => {
                if (file) {
                  onChange(file)
                }
              }}
            />
          )}
          name="photo"
          defaultValue=""
        />
      </div>
      <Text
        variant="body1"
        text="First Name"
        style={{ color: color.shade70, marginTop: 24, marginBottom: 12 }}
      />
      <Controller
        control={control}
        render={({ onChange, value }) => (
          <TextField
            fullWidth
            size="small"
            error={!value}
            helperText={!value ? 'First name cannot be blank.' : null}
            variant="outlined"
            value={value}
            onChange={onChange}
          />
        )}
        name="firstName"
        defaultValue=""
      />
      <Text
        variant="body1"
        text="Last Name"
        style={{ color: color.shade70, marginTop: 39, marginBottom: 12 }}
      />
      <Controller
        control={control}
        render={({ onChange, value }) => (
          <TextField
            fullWidth
            size="small"
            error={!value}
            helperText={!value ? 'Last name cannot be blank.' : null}
            variant="outlined"
            value={value}
            onChange={onChange}
          />
        )}
        name="lastName"
        defaultValue=""
      />
    </Dialog>
  )
})
