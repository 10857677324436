import React, { useEffect, useState } from 'react'
import { CircularProgress, Divider, TextField } from '@mui/material'
import { DateTime } from 'luxon'
import { useHistory } from 'react-router'

import { observer } from 'mobx-react-lite'
import { Route, Switch } from 'react-router-dom'
import { color } from '@trivie/ui-web/theme'
import Card from '@mui/material/Card'
import { Text } from '@trivie/ui-web/components/text'
import { useStores, GetBackgroundColorOfMimeType } from '@trivie/core'
import { TableHelper } from '../../../tables'
import { Header } from '@trivie/ui-web/components/header'
import { ResourceTable } from '../../../tables/resource-table'
import { ResourcesDetailsScreen } from './resources-details-screen'
import { ResourcesDetailsLiteScreen } from './resources-details-lite-screen'
import { Search } from '@mui/icons-material'
import { ResourceApi } from '@trivie/core/src/services/api-objects'

import { Add } from '@mui/icons-material'
import { QuizCard } from '../quizzes/quiz-card/quiz-card'
import { ResourceUploadDialog } from '../content-creation/components/resource-upload-dialog'

export const ResourcesScreen: React.FunctionComponent = observer(() => {
  const { manageUserStore } = useStores()
  const { enterpriseTier, permissions } = manageUserStore

  const history = useHistory()

  const [showResourceDialog, setShowResourceDialog] = useState(false)

  const [tableHelper] = useState(new TableHelper('list_resources', 'resources', 'resources'))
  const [loading, setLoading] = useState(false)
  const [searchText, setSearchText] = useState('')
  const [resources, setResources] = useState([])
  let counter = -1

  const handleAddResource = () => {
    setLoading(true)
    if (!enterpriseTier) {
      loadResources()
    } else {
      tableHelper.load().then(() => setLoading(false))
    }
    setShowResourceDialog(false)
  }

  const createResource = (generate?: boolean) => {}

  const loadResources = async () => {
    setLoading(true)
    ResourceApi.getAllResources(
      'resources',
      false,
      [
        { field: 'resource_created_date' },
        { field: 'resource_title' },
        { field: 'resource_filename_url' },
        { field: 'resource_file_size' },
        { field: 'resource_file_type' },
        { field: 'resource_view_count' },
        { field: 'resource_word_count' },
      ],
      'list_resources',
    ).then((resp) => {
      const items = resp.data.rows
      setResources(items)
      setLoading(false)
    })
  }

  const [init, setInit] = useState(false)
  useEffect(() => {
    if (!init && !enterpriseTier) {
      loadResources()
    }
    setInit(true)
  }, [])

  const primary = {
    disabled: !permissions?.permissions.some((p) => p.code === 'can_create_resource'),
    startIcon: <Add />,
    onClick: () => setShowResourceDialog(true),
    text: 'Add Source',
  }

  return (
    <Switch>
      <Route exact path="/content/sources">
        <Header divider={enterpriseTier} title="Sources" primaryAction={primary} />
        {!enterpriseTier ? (
          <>
            {loading ? (
              <div style={LOADER}>
                <CircularProgress />
              </div>
            ) : (
              <>
                {!loading && (
                  <TextField
                    value={searchText}
                    onChange={(e) => setSearchText(e.target.value)}
                    type="text"
                    variant="standard"
                    InputProps={{
                      style: { paddingRight: 90, marginBottom: 16 },
                      startAdornment: (
                        <Search
                          style={{ marginRight: 12, fontSize: 22 }}
                          htmlColor={color.shade70}
                        />
                      ),
                    }}
                    style={SEARCH}
                    placeholder="Search"
                  />
                )}
                <div style={ITEM_LIST}>
                  <AddResourceCard
                    setShowResourceDialog={() => setShowResourceDialog(true)}
                    createQuiz={createResource}
                  />
                  {resources
                    .filter((item: any) => {
                      const title = item.columns.find(
                        (column) => column.field === 'resource_title',
                      ).value
                      return !searchText || title.toLowerCase().includes(searchText.toLowerCase())
                    })
                    .map((item: any) => {
                      const filename_url = item.columns.find(
                        (column) => column.field === 'resource_filename_url',
                      ).value
                      const _title = item.columns.find(
                        (column) => column.field === 'resource_title',
                      ).value
                      const title = _title.substring(0, _title.lastIndexOf('.')) || _title
                      const file_type = item.columns.find(
                        (column) => column.field === 'resource_file_type',
                      ).value
                      const viewCount = item.columns.find(
                        (column) => column.field === 'resource_view_count',
                      ).value
                      const word_count = item.columns.find(
                        (column) => column.field === 'resource_word_count',
                      ).value
                      const lastUpdated = item.columns.find(
                        (column) => column.field === 'resource_created_date',
                      ).value
                      const date = DateTime.fromISO(lastUpdated).toFormat('MMM d, yyyy')
                      counter++
                      return (
                        <QuizCard
                          mime_type={file_type}
                          hideChip
                          imageStyle={{
                            height: 80,
                            backgroundColor: GetBackgroundColorOfMimeType(file_type),
                          }}
                          wordCount={word_count}
                          imageElement="div"
                          key={item.id}
                          title={title}
                          subtitle={`${filename_url.slice(0, 50)}${
                            filename_url?.length > 50 ? '...' : ''
                          }`}
                          questionCount={viewCount}
                          lastUpdated={date}
                          onClick={() => history.push(`/content/sources/${item.id}`)}
                          status={status === 'Assigned' ? 'Live' : 'Draft'}
                          onEdit={() => {}}
                          onExport={() => {}}
                          onShare={() => {}}
                          onDelete={() => {}}
                        />
                      )
                    })}
                </div>
              </>
            )}
          </>
        ) : (
          <ResourceTable forceLoading={loading} tableHelper={tableHelper} />
        )}
        {showResourceDialog && (
          <ResourceUploadDialog
            onUpload={handleAddResource}
            close={() => setShowResourceDialog(false)}
            open={showResourceDialog}
          />
        )}
      </Route>
      <Route path="/content/sources/:id">
        {!enterpriseTier ? <ResourcesDetailsLiteScreen /> : <ResourcesDetailsScreen />}
      </Route>
    </Switch>
  )
})

export const AddResourceCard = (props: any) => {
  const { createQuiz, style, setShowResourceDialog, ...rest } = props

  return (
    <Card
      onClick={setShowResourceDialog}
      style={style}
      sx={{
        ...CONTAINER,
        display: 'flex',
        flexDirection: 'column',
        borderColor: color.shade30,
        cursor: 'pointer',
      }}
      {...rest}
    >
      <div style={CREATE_NEW}>
        <Add htmlColor="#212121" style={{ fontSize: 64 }} />
        <Text
          text="Add New Source"
          variant="labelEmphasized"
          style={{ color: '#212121', cursor: 'pointer', alignSelf: 'center' }}
        />
      </div>
    </Card>
  )
}
const CREATE_NEW: React.CSSProperties = {
  alignSelf: 'center',
  alignItems: 'center',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  width: '100%',
  gap: 5,
  paddingBottom: 20,
}
const CONTAINER: React.CSSProperties = {
  display: 'flex',
  justifyContent: 'center',
  width: 262,
  boxShadow: 'none',
  borderRadius: '12px',
  border: `1px dashed  ${color.shade10}`,
  height: 256,
  position: 'relative',
}

const LOADER: React.CSSProperties = {
  height: window.innerHeight / 2 + 70,
  justifyContent: 'center',
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
}

const ITEM_LIST: React.CSSProperties = {
  display: 'flex',
  flexDirection: 'row',
  flexWrap: 'wrap',
  gap: 24,
  paddingBottom: 40,
  paddingTop: 16,
}
const SEARCH: React.CSSProperties = { marginTop: 20, fontSize: 16, fontWeight: 600, width: '100%' }
