import React, { useEffect, useState } from 'react'
import { TextField } from '@mui/material'

import { Dialog } from '../dialogs/dialog'
import { color } from '../../theme/color'

export interface AltTextDialogProps {
  open: boolean
  imgSrc: string | null
  altText?: string | null
  onConfirm: (text: string) => void
  onClose: () => void
}

export function AltTextDialog(props: AltTextDialogProps) {
  const { open, altText, onConfirm, onClose } = props

  const [text, setText] = useState('')

  useEffect(() => {
    if (open && altText) {
      setText(altText)
    } else if (!open) {
      setText('')
    }
  }, [open])

  const handleConfirm = () => {
    onConfirm(text)
    handleClose()
  }

  const handleClose = () => {
    onClose()
  }

  return (
    <Dialog
      maxWidth="sm"
      onCancel={handleClose}
      onConfirm={handleConfirm}
      onClose={handleClose}
      disableConfirm={!altText && !text}
      title="Photo Alt Text"
      confirmBtnLabel="Add Alt Text"
      aria-labelledby="confirmation-dialog-title"
      open={open}
    >
      <TextField
        sx={{
          '& .MuiFormLabel-root': {
            color: color.shade50,
          },
        }}
        variant="standard"
        multiline
        label="Add an image description for visually impaired users"
        onChange={(e) => setText(e.target.value)}
        value={text}
        fullWidth
      />
    </Dialog>
  )
}
