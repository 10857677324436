import { types, Instance } from 'mobx-state-tree'
import { UserPointsSummary } from './UserPointsSummary'

export const UserLevelAndPoints = types
  .model('UserLevelAndPoints')
  .props({
    user_id: types.number,
    user_level: types.number,
    label: types.string,
    level_icon: types.maybeNull(types.string),
    next_level_icon: types.maybeNull(types.string),
    level_max_points: types.maybeNull(types.number),
    level_min_points: types.maybeNull(types.number),
    current_rank: types.maybeNull(types.number),
    points: UserPointsSummary,
  })
  .views((self) => ({
    get level_pct() {
      const userLevelPoints = (self?.points?.total_points ?? 0) - (self?.level_min_points ?? 0)
      const levelMaxPoints = (self?.level_max_points ?? 0) - (self?.level_min_points ?? 0)
      if (!userLevelPoints || !levelMaxPoints) {
        return 0
      }
      return Number((userLevelPoints / levelMaxPoints).toFixed(2)) * 100
    },
  }))

export interface UserLevelAndPointsType extends Instance<typeof UserLevelAndPoints.Type> {}
export interface UserPointsSummaryType extends Instance<typeof UserPointsSummary.Type> {}
