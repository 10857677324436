import { PixelCrop } from 'react-image-crop'
interface BlobProps extends Blob {
  name: string
  lastModified: number
}
const getBlobFromCanvas = (
  canvas: HTMLCanvasElement,
  file: File,
  withUrl: boolean,
): Promise<{ blob: Blob; blobUrl: string; revokeUrl: () => void }> =>
  new Promise((resolve, reject) => {
    canvas.toBlob(
      (blob: Partial<BlobProps> | null) => {
        if (blob) {
          blob.name = file.name
          blob.lastModified = file.lastModified

          let blobUrl = ''
          let revokeUrl = () => {}

          if (withUrl) {
            blobUrl = URL.createObjectURL(blob as Blob)
            revokeUrl = () => URL.revokeObjectURL(blobUrl)
          }

          resolve({ blob: blob as Blob, blobUrl, revokeUrl })
        } else {
          reject(new Error('Canvas is empty'))
        }
      },
      file.type,
      0.8,
    )
  })

const TO_RADIANS = Math.PI / 180

const cropImage = async (
  imageElm: HTMLImageElement,
  file: File,
  crop: PixelCrop | undefined,
  withUrl = false,
  scale = 1,
) => {
  const canvas = document.createElement('canvas'),
    ctx = canvas.getContext('2d')

  const scaleX = imageElm.naturalWidth / imageElm.width
  const scaleY = imageElm.naturalHeight / imageElm.height
  // const pixelRatio = 5 / 2
  const pixelRatio = window.devicePixelRatio

  if (crop) {
    canvas.width = Math.floor(crop.width * scaleX * pixelRatio)
    canvas.height = Math.floor(crop.height * scaleY * pixelRatio)
  }

  ctx?.scale(pixelRatio, pixelRatio)
  if (ctx?.imageSmoothingQuality) {
    ctx.imageSmoothingQuality = 'high'
  }

  const cropX = crop ? crop.x * scaleX : scaleX
  const cropY = crop ? crop?.y * scaleY : scaleY

  const rotateRads = 0
  const centerX = imageElm.naturalWidth / 2
  const centerY = imageElm.naturalHeight / 2

  ctx?.save()

  // 5) Move the crop origin to the canvas origin (0,0)
  ctx?.translate(-cropX, -cropY)
  // 4) Move the origin to the center of the original position
  ctx?.translate(centerX, centerY)
  // 3) Rotate around the origin
  ctx?.rotate(rotateRads)
  // 2) Scale the image
  ctx?.scale(scale, scale)
  // 1) Move the center of the image to the origin (0,0)
  ctx?.translate(-centerX, -centerY)
  ctx?.drawImage(
    imageElm,
    0,
    0,
    imageElm.naturalWidth,
    imageElm.naturalHeight,
    0,
    0,
    imageElm.naturalWidth,
    imageElm.naturalHeight,
  )
  ctx?.restore()
  return getBlobFromCanvas(canvas, file, withUrl)
}

export default cropImage
