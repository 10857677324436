import React, { useEffect } from 'react'
import { FilterList, KeyboardArrowDownRounded } from '@mui/icons-material'
import { Box, IconButton } from '@mui/material'
import {
  DataGridPro,
  gridFilteredSortedRowEntriesSelector,
  gridFilteredTopLevelRowCountSelector,
  useGridApiRef,
} from '@mui/x-data-grid-pro'
import { observer } from 'mobx-react-lite'
import XLSX from 'xlsx'
import { DateTime } from 'luxon'
const _omit = require('lodash/omit')

import { roundNumber } from '@trivie/core'

import { color } from '../../theme'
import { CustomFilterPanel } from './custom-filter-panel'
import { DataTableToolbar } from '../table-toolbar'
import { Paywall } from '../paywall/paywall'
import { NoRowsOverlay } from './no-rows-overlay'
import { Dsc } from './sort-dsc'
import { Asc } from './sort-asc'
import { Und } from './sort-und'
import { DataTableProps } from './data-table.props'

export * from './TableHelper'

export const DataTable = observer((props: DataTableProps) => {
  const {
    restricted,
    hideActions,
    toolbar = <></>,
    checkboxSelection = true,
    tagCategories = [],
    rows = [],
    columns,
    tableHelper,
    onSaveFilters = () => {},
    exportFilename = 'table_data',
    searchPlaceholder,
    hideAddColumn,
    openFilter = false,
    hideHeader = false,
    hidePagination = false,
    allowNewReport,
    rowsPerPageOptions,
    disableSearch,
    gdpr = false,
    columnVisibilityModel,
    sortModel = [],
    onSaveLayout,
    filterModel,
    sx,
    ...rest
  } = props

  const apiRef = useGridApiRef()

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    apiRef.current.setPageSize(Number(event.target.value))
    window.localStorage.setItem('rowsPerPageStore', event.target.value)
  }

  const exportData = (columns, rows) => {
    const excludedFields = ['identity', 'photo_url', 'is_gdpr', 'id']
    const data = rows.map((row) => {
      const formattedRow = {}
      Object.keys(_omit(row.model, excludedFields)).map((field) => {
        const column = columns.lookup[field] as any
        if (column.column_alias && column.params) {
          formattedRow[`${Object.values(column.params)[0]}`] = String(
            !row.model[field] ? '' : row.model[field].map((v) => v.value),
          )
        } else if (column?.type === 'array') {
          formattedRow[field] = row.model[field]?.toString()
        } else if (column?.type === 'number') {
          formattedRow[field] = roundNumber(row.model[field], 1)
        } else if (column?.type === 'date') {
          const dt = DateTime.fromISO(row.model[field])
          formattedRow[field] = dt.isValid ? dt.toLocaleString(DateTime.DATE_MED) : 'Never'
        } else {
          formattedRow[field] = row.model[field]
        }
      })
      return formattedRow
    })

    const order = columns.lookup.identity
      ? ['first_name', 'last_name', 'email', 'phone_number']
      : []
    const worksheet = XLSX.utils.json_to_sheet(data, { header: order })
    const new_workbook = XLSX.utils.book_new()
    XLSX.utils.book_append_sheet(new_workbook, worksheet, 'SheetJS')
    XLSX.writeFile(new_workbook, `${exportFilename.split(' ').join('_')}.xlsx`)
  }

  const saveTableLayout = () => {
    if (onSaveLayout) {
      const { sorting, columns } = apiRef.current.state
      const { lookup, orderedFields } = columns
      onSaveLayout(lookup, orderedFields, sorting)
    }
  }

  useEffect(() => {
    if (openFilter) {
      apiRef.current.showFilterPanel()
    }
  }, [openFilter])

  useEffect(() => {
    setTimeout(() => {
      const next = document.querySelector('[aria-label="Go to next page"]')
      const previous = document.querySelector('[aria-label="Go to previous page"]')
      if (next) {
        next.setAttribute('data-qat', 'dt-footer-next-page')
      }
      if (previous) {
        previous.setAttribute('data-qat', 'dt-footer-previous-page')
      }
    }, 0)
  }, [document.querySelector('[aria-label="Go to next page"]')])

  return (
    <Box sx={{ position: 'relative' }}>
      <DataGridPro
        sx={sx}
        initialState={{
          sorting: { sortModel },
          filter: { filterModel },
          pagination: {
            paginationModel: {
              pageSize: Number(window.localStorage.getItem('rowsPerPageStore') || 5),
            },
          },
        }}
        columnVisibilityModel={columnVisibilityModel}
        apiRef={apiRef}
        slots={{
          noRowsOverlay: NoRowsOverlay as any,
          noResultsOverlay: NoRowsOverlay as any,
          columnHeaderFilterIconButton: (props: any) => {
            if (hideActions) {
              return null
            }
            const c = columns.find((c) => c.field === props.field)
            // @ts-ignore
            return restricted || props.field === '__check__' || c?.data_type === 'array' ? null : (
              <div className="FilterButton" style={{ width: 'auto' }}>
                <IconButton
                  data-qat={`col_filter_${props.field}`}
                  onClick={(e: any) => {
                    e.stopPropagation()
                    apiRef.current.showFilterPanel(props.field)
                  }}
                >
                  <FilterList
                    style={{ fontSize: 18 }}
                    htmlColor={props.counter > 0 ? color.blue : color.shade20}
                  />
                </IconButton>
              </div>
            )
          },
          filterPanel: () =>
            apiRef.current.state.preferencePanel.open || openFilter ? (
              <CustomFilterPanel
                apiRef={apiRef}
                tableHelper={tableHelper}
                onSave={onSaveFilters}
                tagCategories={tagCategories}
                custom={tableHelper?.custom}
                hideAddColumn={hideAddColumn}
                allowNewReport={allowNewReport}
              />
            ) : null,
          columnSortedAscendingIcon: () => <Asc fill={color.blue} />,
          columnSortedDescendingIcon: () => <Dsc fill={color.blue} />,
          columnUnsortedIcon: () => <Und />,
          toolbar: toolbar
            ? () => (
                <DataTableToolbar
                  count={gridFilteredTopLevelRowCountSelector(
                    apiRef.current.state,
                    apiRef.current.instanceId,
                  )}
                  toolbar={toolbar}
                  onClickFilter={() =>
                    apiRef.current.state.preferencePanel.open
                      ? apiRef.current.hidePreferences()
                      : apiRef.current.showFilterPanel()
                  }
                  exportData={() =>
                    exportData(
                      apiRef.current.state.columns,
                      gridFilteredSortedRowEntriesSelector(apiRef),
                    )
                  }
                  hidePagination={hidePagination}
                  page={apiRef.current.state.pagination.paginationModel.page}
                  rowsPerPage={apiRef.current.state.pagination.paginationModel.pageSize}
                  handleChangePage={apiRef.current.setPage}
                  disableSearch={disableSearch}
                  hideActions={hideActions}
                  saveTableLayout={saveTableLayout}
                  filterCount={apiRef.current.state.filter.filterModel.items.length}
                  searchPlaceholder={searchPlaceholder}
                  restricted={restricted}
                />
              )
            : null,
        }}
        slotProps={{
          toolbar: { showQuickFilter: true },
          pagination: {
            labelDisplayedRows: ({ from, to, count }) => `Showing: ${from}–${to} of ${count}`,
            SelectProps: { IconComponent: KeyboardArrowDownRounded },
            onRowsPerPageChange: handleChangeRowsPerPage,
          },
          noResultsOverlay: { filtered: true } as any,
        }}
        disableRowSelectionOnClick
        ignoreDiacritics
        disableColumnPinning
        disableColumnFilter={!Boolean(onSaveFilters)}
        disableColumnMenu
        columnHeaderHeight={hideHeader ? 0 : 56}
        rowHeight={68}
        hideFooterSelectedRowCount
        autoHeight
        rows={restricted ? rows?.slice(0, 10) : rows}
        columns={gdpr ? columns.filter((c: any) => !c.is_gdpr) : columns}
        pageSizeOptions={
          restricted ? [10] : rowsPerPageOptions ? rowsPerPageOptions : [5, 10, 20, 100]
        }
        pagination={restricted ? false : !hidePagination}
        checkboxSelection={checkboxSelection}
        {...rest}
      />
      {restricted && (
        <>
          <div
            style={{
              position: 'absolute',
              bottom: -1,
              height: '25%',
              width: '100%',
              left: 0,
              background: 'linear-gradient(180deg, rgba(217, 217, 217, 0.00) 0%, #F4F4F4 60%)',
            }}
          />
          <Paywall
            style={{ bottom: -5 }}
            plan="Standard"
            tagline="to view the full attempt history"
          />
        </>
      )}
    </Box>
  )
})
