import { Instance, types } from 'mobx-state-tree'

/**
 * UserLevelModel
 */
export const UserLevelModel = types
  .model('UserLevelModel')
  .props({
    icon: types.maybeNull(types.string),
    id: types.maybeNull(types.number),
    label: types.string,
    level: types.integer,
    min_points: types.integer,
  })
  .views((self) => ({
    get tooLongLabel() {
      return Boolean(self.label && self.label.length > 40)
    },
    get emptyLabel() {
      return !self.label || !self.label?.trim()
    },
    get emptyLabelText() {
      return 'Please add a level title before saving'
    },
    get tooLongText() {
      return 'Title must be less than 40 characters'
    },
    incorrectMinPoints(prevValue = Number.NEGATIVE_INFINITY, nextValue = Number.POSITIVE_INFINITY) {
      if (self.min_points > prevValue && self.min_points < nextValue) return false
      return true
    },
    get incorrectMinPointsText() {
      return 'Score must be between last and next level'
    },
    get hasError() {
      return this.tooLongLabel || this.emptyLabel
    },
  }))
  .actions((self) => ({
    setLabel(label: string) {
      self.label = label
    },
    setMinPoints(min_points: number) {
      self.min_points = min_points
    },
    setIcon(icon: string) {
      self.icon = icon
    },
  }))

/* The TypeScript type of an instance of UserLevelModel */
export interface UserLevelModelType extends Instance<typeof UserLevelModel> {}
