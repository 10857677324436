import { observer } from 'mobx-react-lite'
import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'

import { Divider } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import createStyles from '@mui/styles/createStyles'
import Button from '@mui/material/Button'
import ButtonGroup from '@mui/material/ButtonGroup'
import ClickAwayListener from '@mui/material/ClickAwayListener'
import Grow from '@mui/material/Grow'
import MenuItem from '@mui/material/MenuItem'
import MenuList from '@mui/material/MenuList'
import Paper from '@mui/material/Paper'
import Popper from '@mui/material/Popper'
import { load, useStores } from '@trivie/core'
import { color } from '@trivie/ui-web/theme/color'
import { Avatar } from '@trivie/ui-web/components/avatar'
import { PersonalSettingsDialog } from '@trivie/ui-web/components/personal-settings-dialog'
import { Text } from '@trivie/ui-web/components/text'

const useStyles = makeStyles(() =>
  createStyles({
    navItem: {
      '&:hover': {
        backgroundColor: color.palette.paperBorder,
      },
      borderRadius: 0,
      height: 50,
      width: '100%',
      cursor: 'pointer',
      justifyContent: 'flex-start',
    },
  }),
)

export const ProfileDropdown = observer(() => {
  const rootStore = useStores()
  const { manageUserStore } = rootStore
  const {
    userProfile,
    permissions,
    clearUserProfile,
    clearSettings,
    loadUser,
    getSettings,
    settings,
  } = manageUserStore

  const [open, setOpen] = React.useState(false)
  const [showPersonalSettings, setShowPersonalSettings] = React.useState(false)
  const [workspaces, setWorkspaces] = useState<any>()

  useEffect(() => {
    getSettings()
    load('workspaces').then((data) => setWorkspaces(data))
    loadUser(userProfile?.id).then((resp) => {
      if (resp && resp.kind === 'unprocessable-entity') {
        history.replace('/logout')
      }
    })
  }, [])

  const anchorRef = React.useRef<HTMLDivElement>(null)

  const history = useHistory()
  const classes = useStyles()

  const handleMenuItemClick = (
    event: React.MouseEvent<HTMLLIElement, MouseEvent>,
    index: number,
  ) => {
    if (index === 2) {
      setShowPersonalSettings(true)
    } else if (index === 3) {
      history.replace('/logout')
    }

    setOpen(false)
  }

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen)
  }

  const handleClose = (event: any) => {
    if (anchorRef.current && anchorRef.current.contains(event.target as HTMLElement)) {
      return
    }

    setOpen(false)
  }

  return (
    <div
      style={{
        width: '100%',
        maxWidth: 1440,
        display: 'flex',
        justifyContent: 'flex-end',
        alignItems: 'center',
        position: 'relative',
        right: 40,
      }}
    >
      <div style={AVATAR_CONTAINER}>
        <div style={WORKSPACE_IDENTIFIER}>
          {settings?.logo_path && (
            <img
              width={36}
              height={36}
              src={settings?.logo_path}
              style={{
                objectFit: 'contain',
                borderRadius: 4,
              }}
            />
          )}
          {settings?.company_name && (
            <Text variant="body1Emphasized">{settings?.company_name}</Text>
          )}
        </div>
        <ButtonGroup
          style={BUTTON_CONTAINER}
          variant="contained"
          color="primary"
          ref={anchorRef}
          aria-label="split button"
        >
          <Button
            style={AVATAR_BUTTON}
            size="small"
            aria-controls={open ? 'split-button-menu' : undefined}
            aria-expanded={open ? 'true' : undefined}
            aria-haspopup="menu"
            onClick={handleToggle}
            data-qat="profile-dropdown"
          >
            {userProfile && (
              <Avatar
                size={32}
                name={`${userProfile?.first_name} ${userProfile?.last_name}`}
                url={userProfile?.photo_url}
              />
            )}
          </Button>
        </ButtonGroup>
      </div>
      <Popper
        style={{ zIndex: 9999 }}
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
      >
        {({ TransitionProps, placement }) => (
          <Grow
            timeout={{ appear: 200, enter: 200, exit: 200 }}
            {...TransitionProps}
            style={{
              zIndex: 9999,
              transformOrigin: placement === 'bottom' ? 'center top' : 'center left',
            }}
          >
            <Paper style={DROPDOWN} variant="outlined">
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList style={{ padding: 0 }} id="split-button-menu">
                  <MenuItem
                    style={PROFILE_CONTAINER}
                    disabled
                    onClick={(event) => handleMenuItemClick(event, 0)}
                    className={classes.navItem}
                  >
                    <Avatar
                      size={32}
                      name={`${userProfile?.first_name} ${userProfile?.last_name}`}
                      url={userProfile?.photo_url}
                    />
                    <div
                      style={{
                        flexDirection: 'column',
                        display: 'flex',
                        marginLeft: 8,
                        overflowWrap: 'anywhere',
                        whiteSpace: 'break-spaces',
                      }}
                    >
                      <Text
                        text={`${userProfile?.first_name} ${userProfile?.last_name}`}
                        variant="body1Emphasized"
                      />
                      <Text
                        text={userProfile?.email}
                        variant="body1Medium"
                        style={{ color: color.shade50 }}
                      />
                    </div>
                  </MenuItem>
                  <Divider />
                  <MenuItem
                    style={MENU_ITEM_2}
                    onClick={(event) => handleMenuItemClick(event, 2)}
                    className={classes.navItem}
                    data-qat="personal_settings"
                  >
                    <Text text="Personal Settings" variant="body1Medium" />
                  </MenuItem>
                  <Divider />
                  <MenuItem
                    style={MENU_ITEM_2}
                    onClick={() => window.open('https://www.trivie.com/terms-of-service', '_blank')}
                    className={classes.navItem}
                    data-qat="terms_of_service"
                  >
                    <Text text="Terms of Service" variant="body1Medium" />
                  </MenuItem>
                  <MenuItem
                    style={MENU_ITEM_2}
                    onClick={() => window.open('https://www.trivie.com/privacy', '_blank')}
                    data-qat="privacy_policy"
                  >
                    <Text text="Privacy Policy" variant="body1Medium" />
                  </MenuItem>
                  <Divider />
                  <MenuItem
                    style={MENU_ITEM_2}
                    className={classes.navItem}
                    data-qat="launch_to_learn"
                  >
                    <a
                      target="_blank"
                      rel="noopener"
                      href={`https://engage.${
                        process.env.REACT_APP_ENV === 'production'
                          ? ''
                          : `${process.env.REACT_APP_ENV}.`
                      }trivie.com/token/${localStorage.getItem('token')}/email/${
                        userProfile?.email
                      }/?workspace_id=${localStorage.getItem('access_code')}`}
                      style={{ textDecoration: 'none' }}
                    >
                      <Text text="Launch to Learn App" variant="body1Medium" />
                    </a>
                  </MenuItem>
                  {workspaces && workspaces?.workspaces?.length > 1 && (
                    <MenuItem
                      onClick={() => {
                        clearUserProfile()
                        clearSettings()
                        localStorage.removeItem('token')
                        manageUserStore.setInstance(workspaces)
                        history.replace('/workspaces/?reload=true')
                      }}
                      data-qat="switch_workspaces"
                      style={MENU_ITEM_2}
                      className={classes.navItem}
                    >
                      <a style={{ textDecoration: 'none' }}>
                        <Text text="Switch Workspaces" variant="body1Medium" />
                      </a>
                    </MenuItem>
                  )}

                  <MenuItem
                    onClick={(event) => handleMenuItemClick(event, 3)}
                    style={MENU_ITEM_3}
                    className={classes.navItem}
                    data-qat="logout"
                  >
                    <Text
                      text="Log Out"
                      variant="body1Medium"
                      style={{ color: color.palette.red }}
                    />
                  </MenuItem>
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
      {showPersonalSettings && (
        <PersonalSettingsDialog
          open={showPersonalSettings}
          onClose={() => setShowPersonalSettings(false)}
        />
      )}
    </div>
  )
})

const AVATAR_BUTTON: React.CSSProperties = {
  background: 'none',
  borderRadius: '1000px',
  boxShadow: 'none',
  height: 32,
  width: 32,
  padding: 0,
  minWidth: 32,
}
const BUTTON_CONTAINER: React.CSSProperties = {
  background: 'none',
  padding: 0,
  borderRadius: '1000px',
  height: 32,
  width: 32,
  boxShadow: 'none',
  minWidth: 32,
}
const DROPDOWN: React.CSSProperties = {
  zIndex: 9999,
  position: 'relative',
  top: 18,
  right: '40px',
  width: 233,
  borderRadius: 10,
  padding: 0,
  boxShadow: '0px 4px 8px 8px rgba(0,0,0,0.03)',
}
const PROFILE_CONTAINER: React.CSSProperties = {
  paddingBottom: 22,
  paddingTop: 22,
  paddingLeft: 20,
  height: 78,
  width: '100%',
  flexDirection: 'row',
  borderTopLeftRadius: 10,
  borderTopRightRadius: 10,
  opacity: 1.0,
}
const MENU_ITEM: React.CSSProperties = {
  paddingBottom: 22,
  paddingTop: 22,
  paddingLeft: 24,
  paddingRight: 24,
  width: '100%',
  flexDirection: 'row',
}
const MENU_ITEM_2: React.CSSProperties = {
  ...MENU_ITEM,
  paddingBottom: 17,
  paddingTop: 17,
  justifyContent: 'flex-start',
}
const MENU_ITEM_3: React.CSSProperties = {
  ...MENU_ITEM_2,
  borderBottomLeftRadius: 10,
  borderBottomRightRadius: 10,
}

const AVATAR_CONTAINER: React.CSSProperties = { display: 'flex', gap: 50, alignItems: 'center' }
const WORKSPACE_IDENTIFIER: React.CSSProperties = { display: 'flex', alignItems: 'center', gap: 10 }
