import React, { useEffect, useState } from 'react'
import { observer } from 'mobx-react-lite'
import { Instance } from 'mobx-state-tree'
import { equals } from 'ramda'
import { useHistory } from 'react-router-dom'
import XLSX from 'xlsx'
import { uniq } from 'ramda'

import {
  getChoicesByQuestionType,
  ImportContent,
  inferQuestionType,
  Question,
  Topic,
  useStores,
} from '@trivie/core'
import { Factoid } from '@trivie/core/src/models/Factoid'
import { QuestionChoice } from '@trivie/core/src/models/QuestionChoice'
import { Quiz } from '@trivie/core/src/models/Quiz'
import { Workflow } from '@trivie/core/src/models/workflow/Workflow'

import { FileInput } from '@trivie/ui-web/components/file-input'
import { Text } from '@trivie/ui-web/components/text'
import { Dialog } from '@trivie/ui-web/components/dialogs/dialog'
import { color } from '@trivie/ui-web/theme'

// @ts-ignore
import { ReactComponent as ExcelFile } from '@trivie/ui-web/assets/icons/Excel.svg'

export const ImportTopicsDialog = observer((props: any) => {
  const { onClose, open, onFileRead = () => ({}) } = props
  const [file, setFile] = useState<any>(null)
  const [templateError, setTemplateError] = useState(false)
  const [emptyError, setEmptyError] = useState(false)

  const { contentCreationStore } = useStores()

  useEffect(() => {
    return () => {
      setTemplateError(false)
      setEmptyError(false)
    }
  }, [])

  useEffect(() => {
    if (!open) {
      setFile(null)
    }
  })

  const readXlsx = (xlsxFile: any) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader()

      reader.onabort = () => reject()
      reader.onerror = () => reject()
      reader.onloadend = () => {
        const workbook = XLSX.read(reader.result, { type: 'array' })
        const sheetNames = workbook.SheetNames
        const worksheet = workbook.Sheets[sheetNames[0]]
        const headers = [] as any
        // @ts-ignore
        const range = XLSX.utils.decode_range(worksheet['!ref'])
        let C
        const R = range.s.r
        for (C = range.s.c; C <= range.e.c; ++C) {
          /* walk every column in the range */
          const cell = worksheet[XLSX.utils.encode_cell({ c: C, r: R })]
          /* find the cell in the first row */
          let hdr = false as any
          if (cell && cell.t) {
            hdr = XLSX.utils.format_cell(cell)
          }
          if (hdr) {
            headers.push(hdr)
          }
        }
        const defaultHeaders = [
          'topic',
          'quiz',
          'question_type',
          'question',
          'answer_1',
          'answer_2',
          'answer_3',
          'answer_4',
          'about_the_answer',
          'key',
          'external_id',
        ]

        if (equals(headers, defaultHeaders)) {
          const jsonOutput = XLSX.utils.sheet_to_json(worksheet, { raw: false }) as any
          resolve(jsonOutput)
        } else {
          reject('invalid_template')
        }
      }

      reader.readAsArrayBuffer(xlsxFile)
    })
  }

  const formatText = (text: string) => {
    if (text.toLocaleLowerCase() === 'true' || text?.toLocaleLowerCase() === 't') {
      return 'True'
    } else if (text.toLocaleLowerCase() === 'false' || text?.toLocaleLowerCase() === 'f') {
      return 'False'
    } else {
      return text
    }
  }

  const parseTF = (answer: string) => {
    let formatted
    if (answer[0] === '*') {
      formatted = formatText(answer.slice(1))
    } else {
      formatted = formatText(answer)
    }
    return formatted
  }

  const history = useHistory()

  const parseData = async (data: Array<ImportContent>, filename: string) => {
    const topics: Array<Instance<typeof Topic>> = []
    if (data?.length === 0) {
      setEmptyError(true)
      return
    }
    data.forEach((row) => {
      const questionType = inferQuestionType(row)

      let choices: Array<Instance<typeof QuestionChoice>>

      const key = row.key ?? ''

      let answerKey = [] as any
      try {
        answerKey = key
          .replace(/\s+/g, '')
          .split(',')
          .map((k) => {
            if (k.toLowerCase() === 'a') {
              return 0
            } else if (k.toLowerCase() === 'b') {
              return 1
            } else if (k.toLowerCase() === 'c') {
              return 2
            } else if (k.toLowerCase() === 'd') {
              return 3
            } else if (parseInt(k) - 1 >= 0 && parseInt(k) - 1 <= 4) {
              return parseInt(k) - 1
            }
          })
      } catch {
        console.warn('key not formatted properly')
      }

      if (answerKey) {
        answerKey = uniq(answerKey)
      }

      const external_id = row.external_id ?? ''

      const a1 = parseTF(row.answer_1 ?? '')
      const a2 = parseTF(row.answer_2 ?? '')

      const testA1 = ['true', 'false', 't', 'f'].includes(a1.toLowerCase().trim())
      const testA2 = ['true', 'false', 't', 'f'].includes(a2.toLowerCase().trim())

      let mcNoCorrect = false
      if (questionType === 'Multiple Choice' || questionType === 'True or False') {
        const correct = [row.answer_1, row.answer_2, row.answer_3, row.answer_4]
          .filter((c) => c)
          .filter((c2) => c2!.includes('*'))
        if (correct.length === 0 || answerKey?.length === 0) {
          mcNoCorrect = true
        }
      }

      if (questionType === 'True or False' && testA1 && testA2) {
        // if no correct answer marked default to true as the correct answer
        if (
          !row.answer_1?.includes('*') &&
          !row.answer_2?.includes('*') &&
          !answerKey.includes(0) &&
          !answerKey.includes(1)
        ) {
          choices = [
            QuestionChoice.create({
              touched: true,
              correct: true,
              text: parseTF(row.answer_1 ?? ''),
            }),
            QuestionChoice.create({
              touched: true,
              correct: false,
              text: parseTF(row.answer_2 ?? ''),
            }),
          ]
          // if true is marked true is correct
        } else {
          choices = [
            QuestionChoice.create({
              touched: true,
              correct: row.answer_1?.includes('*') || answerKey.includes(0) ? true : false,
              text: parseTF(row.answer_1 ?? ''),
            }),
            QuestionChoice.create({
              touched: true,
              correct: row.answer_2?.includes('*') || answerKey.includes(1) ? true : false,
              text: parseTF(row.answer_2 ?? ''),
            }),
          ]
        }
      } else {
        choices = [row.answer_1, row.answer_2, row.answer_3, row.answer_4]
          .filter((c) => c)
          .map((c, index) =>
            QuestionChoice.create({
              touched: true,
              correct:
                answerKey.includes(index) || (mcNoCorrect && index === 0) ? true : c!.includes('*'),
              text: c?.includes('*') ? c.slice(1) : c,
            }),
          )
      }

      if (!choices.length) {
        choices = getChoicesByQuestionType(questionType)
      }

      let questionTypeChanged = false
      if (choices.length === 2 && questionType !== 'Multiple Choice' && (!testA1 || !testA2)) {
        // set qtype changed warning on question model
        questionTypeChanged = true
      }

      const importedTopic: Instance<typeof Topic> | undefined = row.topic
        ? topics.find((t) => row.topic === t.title)
        : undefined

      const importedQuiz: Instance<typeof Quiz> | undefined = row.quiz
        ? importedTopic?.quizzes?.find((q) => q.title === row.quiz)
        : undefined
      const importedQuestion: Instance<typeof Question> | undefined = row.question
        ? importedQuiz?.questions?.find(
            (q) =>
              q.text === row.question &&
              q.choices.every((c) => choices.includes(c)) &&
              choices.every((c) => q.choices.includes(c)),
          )
        : undefined

      if (importedTopic) {
        if (importedQuiz) {
          if (!importedQuestion) {
            const q = Question.create({
              questionTypeChanged,
              approved: false,
              touched: true,
              text: row.question ?? '',
              choices,
              adaptive_eligible: true,
              keep_answer_order: false,
              difficulty: 0,
              is_locked: false,
              image_src: row.image_url ?? null,
              factoid: row.about_the_answer ? Factoid.create({ text: row.about_the_answer }) : null,
              question_type:
                questionType === 'True or False' && !(testA1 || testA2) // if TF type but not true/false format convert to MC
                  ? 'Multiple Choice'
                  : answerKey?.length > 1
                    ? 'Multiple Response'
                    : questionType,
              external_id,
            })
            if (
              q.allErrors &&
              q.allErrors.length === 0 &&
              q.allWarnings &&
              q.allWarnings.length === 0
            ) {
              q.set('approved', true)
            }
            importedQuiz.addQuestions([q])
          } else {
            // question already exists so do nothing
          }
        } else {
          const q = Question.create({
            questionTypeChanged,
            approved: false,
            touched: true,
            text: row.question ?? '',
            choices,
            adaptive_eligible: true,
            keep_answer_order: false,
            difficulty: 0,
            is_locked: false,
            image_src: row.image_url ?? null,
            factoid: row.about_the_answer ? Factoid.create({ text: row.about_the_answer }) : null,
            question_type:
              questionType === 'True or False' && (!testA1 || !testA2) // if TF type but not true/false format convert to MC
                ? 'Multiple Choice'
                : answerKey?.length > 1
                  ? 'Multiple Response'
                  : questionType,
            external_id,
          })
          if (
            q.allErrors &&
            q.allErrors.length === 0 &&
            q.allWarnings &&
            q.allWarnings.length === 0
          ) {
            q.set('approved', true)
          }
          const newQuiz = Quiz.create({
            title: row.quiz ?? null,
            keep_item_order: false,
            touched: true,
            questions: [q],
          })

          importedTopic.addQuizzes([newQuiz])
        }
      } else {
        const q = Question.create({
          questionTypeChanged,
          approved: false,
          touched: true,
          text: row.question ?? '',
          choices,
          adaptive_eligible: true,
          keep_answer_order: false,
          difficulty: 0,
          is_locked: false,
          image_src: row.image_url ?? null,
          factoid: row.about_the_answer ? Factoid.create({ text: row.about_the_answer }) : null,
          question_type:
            questionType === 'True or False' && (!testA1 || !testA2) // if TF type but not true/false format convert to MC
              ? 'Multiple Choice'
              : answerKey?.length > 1
                ? 'Multiple Response'
                : questionType,
          external_id,
        })
        if (
          q.allErrors &&
          q.allErrors.length === 0 &&
          q.allWarnings &&
          q.allWarnings.length === 0
        ) {
          q.set('approved', true)
        }
        const topic = Topic.create({
          title: row.topic,
          keep_item_order: false,
          touched: true,
          workflow: Workflow.create({
            version: 1.1,
            name: `${row.topic} Workflow`,
          }) as any,
          quizzes: [
            Quiz.create({
              touched: true,
              keep_item_order: false,
              title: row.quiz ?? null,
              order: null,
              questions: [q],
            }),
          ],
        })
        topics.push(topic)
      }
    })

    contentCreationStore.setImportingFilename(filename)
    contentCreationStore.setTopics(topics.slice() as any)
    contentCreationStore.selectTopic(topics[0] as any)
    onFileRead()
    history.push('/content/creation/import')
  }

  const onDrop = async (droppedFile: any) => {
    setFile(droppedFile)
    setTemplateError(false)
    setEmptyError(false)
    if (droppedFile) {
      try {
        const data = (await readXlsx(droppedFile)) as Array<ImportContent>
        parseData(data, droppedFile.name)
      } catch (e) {
        if (e === 'invalid_template') {
          setTemplateError(true)
        }
      }
    }
  }

  const downloadImportTemplate = (url?: string) => {
    const a = document.createElement('a')
    a.download = 'v8-content-import-template'
    a.href = url ?? '/v8-content-import-template.xlsx'
    document.body.appendChild(a)
    a.click()
    document.body.removeChild(a)
  }

  return (
    <Dialog
      maxWidth="sm"
      title="Import Content"
      onClose={onClose}
      aria-labelledby="customized-dialog-title"
      open={open}
    >
      <div style={{ marginBottom: 20 }}>
        <FileInput
          file={file}
          mainText="or drag a file right here"
          helperText="Supports Excel or Google Sheets"
          accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;application/vnd.ms-excel"
          onChange={onDrop}
        />
      </div>
      {emptyError ? (
        <div style={{ color: color.red, fontSize: 14, marginBottom: 16 }}>
          The file appears to be empty. Please check the import template and try again.
        </div>
      ) : (
        templateError && (
          <div style={{ color: color.red, fontSize: 14, marginBottom: 16 }}>
            The headers for this file are not correct. Please check the import template and try
            again.
          </div>
        )
      )}
      <div style={{ display: 'flex', flexDirection: 'column', overflow: 'hidden' }}>
        <div style={{ display: 'flex', alignItems: 'center', marginBottom: 13 }}>
          <Text
            variant="body2Emphasized"
            text="TEMPLATE PREVIEW"
            style={{ color: color.shade30 }}
          />
        </div>
        <div
          style={{
            display: 'grid',
            gridTemplateColumns: 'repeat(8, auto)',
            borderRadius: 6,
            border: `1px solid ${color.palette.paperBorder}`,
            marginBottom: 42,
          }}
        >
          <div
            style={{
              height: 31,
              backgroundColor: color.palette.porcelain,
              textAlign: 'center',
              borderRight: `1px solid ${color.palette.paperBorder}`,
              borderBottom: `1px solid ${color.palette.paperBorder}`,
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <Text
              variant="custom"
              text="topic"
              style={{ color: color.shade60, fontSize: 10, lineHeight: '12px', fontWeight: 600 }}
            />
          </div>
          <div
            style={{
              height: 31,
              backgroundColor: color.palette.porcelain,
              textAlign: 'center',
              borderRight: `1px solid ${color.palette.paperBorder}`,
              borderBottom: `1px solid ${color.palette.paperBorder}`,
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <Text
              variant="custom"
              text="quiz"
              style={{ color: color.shade60, fontSize: 10, lineHeight: '12px', fontWeight: 600 }}
            />
          </div>
          <div
            style={{
              height: 31,
              backgroundColor: color.palette.porcelain,
              textAlign: 'center',
              borderRight: `1px solid ${color.palette.paperBorder}`,
              borderBottom: `1px solid ${color.palette.paperBorder}`,
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <Text
              variant="custom"
              text="question_type"
              style={{ color: color.shade60, fontSize: 10, lineHeight: '12px', fontWeight: 600 }}
            />
          </div>
          <div
            style={{
              height: 31,
              backgroundColor: color.palette.porcelain,
              textAlign: 'center',
              borderRight: `1px solid ${color.palette.paperBorder}`,
              borderBottom: `1px solid ${color.palette.paperBorder}`,
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <Text
              variant="custom"
              text="question"
              style={{ color: color.shade60, fontSize: 10, lineHeight: '12px', fontWeight: 600 }}
            />
          </div>
          <div
            style={{
              height: 31,
              backgroundColor: color.palette.porcelain,
              textAlign: 'center',
              borderRight: `1px solid ${color.palette.paperBorder}`,
              borderBottom: `1px solid ${color.palette.paperBorder}`,
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <Text
              variant="custom"
              text="answer_1"
              style={{ color: color.shade60, fontSize: 10, lineHeight: '12px', fontWeight: 600 }}
            />
          </div>
          <div
            style={{
              height: 31,
              backgroundColor: color.palette.porcelain,
              textAlign: 'center',
              borderRight: `1px solid ${color.palette.paperBorder}`,
              borderBottom: `1px solid ${color.palette.paperBorder}`,
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <Text
              variant="custom"
              text="answer_2"
              style={{ color: color.shade60, fontSize: 10, lineHeight: '12px', fontWeight: 600 }}
            />
          </div>
          <div
            style={{
              height: 31,
              backgroundColor: color.palette.porcelain,
              textAlign: 'center',
              borderRight: `1px solid ${color.palette.paperBorder}`,
              borderBottom: `1px solid ${color.palette.paperBorder}`,
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <Text
              variant="custom"
              text="answer_3"
              style={{ color: color.shade60, fontSize: 10, lineHeight: '12px', fontWeight: 600 }}
            />
          </div>
          <div
            style={{
              height: 31,
              backgroundColor: color.palette.porcelain,
              textAlign: 'center',
              borderBottom: `1px solid ${color.palette.paperBorder}`,
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <Text
              variant="custom"
              text="about_the_answer"
              style={{ color: color.shade60, fontSize: 10, lineHeight: '12px', fontWeight: 600 }}
            />
          </div>

          <div style={{ height: 45, borderRight: `1px solid ${color.palette.paperBorder}` }}></div>
          <div style={{ height: 45, borderRight: `1px solid ${color.palette.paperBorder}` }}></div>
          <div style={{ height: 45, borderRight: `1px solid ${color.palette.paperBorder}` }}></div>
          <div style={{ height: 45, borderRight: `1px solid ${color.palette.paperBorder}` }}></div>
          <div style={{ height: 45, borderRight: `1px solid ${color.palette.paperBorder}` }}></div>
          <div style={{ height: 45, borderRight: `1px solid ${color.palette.paperBorder}` }}></div>
          <div style={{ height: 45, borderRight: `1px solid ${color.palette.paperBorder}` }}></div>
          <div style={{ height: 45, borderRight: `1px solid ${color.palette.paperBorder}` }}></div>
        </div>
      </div>

      <Text
        style={{
          color: 'rgba(144, 152, 163, 1)',
          fontSize: 12,
          fontWeight: 600,
          marginBottom: 16,
        }}
      >
        DOWNLOAD TEMPLATE
      </Text>
      <div style={{ display: 'flex', gap: 16 }}>
        <div
          data-qat="download_template"
          onClick={() => downloadImportTemplate()}
          style={DL_TEMPLATE}
        >
          <ExcelFile />
          <Text variant="body1Medium" text="Excel Template" />
        </div>
        <a
          target="_blank"
          href={`https://docs.google.com/spreadsheets/d/1Ut1A2qIJoCq3g4rO0HkLvtKV5ojlpfsexNw2w69Ce04/edit#gid=0`}
          style={DL_TEMPLATE}
        >
          <ExcelFile />
          <Text variant="body1Medium" text="Google Sheet Template" />
        </a>
      </div>
    </Dialog>
  )
})

const DL_TEMPLATE: React.CSSProperties = {
  textDecoration: 'none',
  color: color.black,
  display: 'flex',
  backgroundColor: color.palette.athensGrey,
  borderRadius: 10,
  height: 56,
  alignItems: 'center',
  cursor: 'pointer',
  padding: 20,
  flexDirection: 'row',
  gap: 12,
}
