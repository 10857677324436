import React, { useEffect, useState } from 'react'
import { CircularProgress, IconButton, Stack } from '@mui/material'
import { ArrowBack, Download, FactCheck, IosShare } from '@mui/icons-material'
import { observer } from 'mobx-react-lite'
import { Instance } from 'mobx-state-tree'
import { useHistory } from 'react-router-dom'

import { color } from '@trivie/ui-web/theme'
import { Button } from '@trivie/ui-web/components/button'
import { Dialog } from '@trivie/ui-web/components/dialogs/dialog'
import { ConfirmDialog } from '@trivie/ui-web/components/confirm-dialog'
import { SuccessfulImport } from '@trivie/ui-web/components/successful-import'
import { isTopic, Question, Topic, useStores } from '@trivie/core'
import { Quiz } from '@trivie/core/src/models/Quiz'
import { AssignmentCreateDialog } from '../../activities/assignments/create-assignment/assignment-create'
import { DateTime } from 'luxon'

import { Text } from '@trivie/ui-web/components/text'
import { ValidationDialog } from './validation-dialog'
import { QuizCard } from '../quizzes/quiz-card/quiz-card'
import { QuizApi } from '@trivie/core/src/services/api-objects'
import { Path } from '@trivie/core/src/models/workflow/Path'

type ContentCreationHeaderProps = {
  publishData:
    | Instance<typeof Path>
    | Instance<typeof Topic>
    | Instance<typeof Quiz>
    | Instance<typeof Question>
    | null
  showUnsavedPopup?: boolean
  titleHeader: JSX.Element
  onBack: () => void
  onResources?: () => void
  hidePublish?: boolean
  importing?: boolean
  edit?: boolean
  path?: any
  editingPath?: boolean
  save?: boolean
  style?: React.CSSProperties
}

const ContentCreationHeader = observer((props: ContentCreationHeaderProps) => {
  const {
    publishData,
    editingPath,
    hidePublish,
    onBack,
    showUnsavedPopup,
    titleHeader,
    edit,
    save,
    style,
  } = props

  const { contentCreationStore, pathStore, manageUserStore } = useStores()
  const { path } = pathStore
  const { enterpriseTier } = manageUserStore
  const {
    setPublishedSuccessful,
    quiz,
    resetContentStore,
    publishObjectContent,
    setValidating,
    publishMessage,
    setPublishMessage,
    selectedTopic,
    topics,
    question,
  } = contentCreationStore

  const [showValidationDialog, setShowValidationDialog] = useState(false)
  const [showUnsavedChanges, setShowUnsavedChanges] = useState(false)
  const [showSuccessModal, setShowSuccessModal] = useState(false)
  const [showWizard, setShowWizard] = useState(false)
  const history = useHistory()

  const onPublishSuccessful = () => {
    setPublishedSuccessful(false)
    setShowSuccessModal(true)
    setShowValidationDialog(false)
  }

  useEffect(() => {
    const { onbeforeunload } = window
    window.onbeforeunload = () => {
      localStorage.setItem('content-temp', JSON.stringify(contentCreationStore))
      return 'dont go'
    }

    return () => {
      window.onbeforeunload = onbeforeunload
    }
  }, [])

  const handleSubmit = async () => {
    setValidating(true)
    if (path) {
      setShowValidationDialog(true)
    } else if (topics?.length) {
      setValidating(false)
      // setShowValidationDialog(true)
    } else if (quiz) {
      setValidating(false)
    } else if (question) {
      publishObjectContent()
      setShowValidationDialog(true)
    } else {
      throw new Error(`Can't publish type that isn't a topic, quiz, or topic`)
    }
  }

  const handleBack = () => {
    if (showUnsavedPopup) {
      setShowUnsavedChanges(true)
    } else {
      onBack()
    }
  }

  const publishDisabled = () => {
    if (!publishData) {
      return true
    }
    if (path && !path.valid) {
      return true
    }
    if (topics && topics?.length > 0) {
      let disabled
      topics &&
        topics?.map((topic) => {
          if (
            (topic.allErrors && topic.allErrors.length > 0) ||
            (topic.quizzes && topic.quizzes?.length === 0) ||
            !topic.title ||
            topic.duplicate_name
          ) {
            disabled = true
          }
        })
      return disabled
    } else if (
      (quiz && quiz.allErrors && quiz.allErrors.length > 0) ||
      (quiz?.questions && quiz.questions.length === 0)
    ) {
      return true
    } else if (question) {
      return !question.approved
    }
    return false
  }

  return (
    <div style={{ ...ROOT, ...style }}>
      <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
        {!!onBack && (
          <IconButton style={{ left: -20 }} onClick={handleBack} size="large">
            <ArrowBack htmlColor={color.black} />
          </IconButton>
        )}
        {titleHeader}
      </div>
      <div>
        {hidePublish ? null : (
          <Button
            disabled={!save && enterpriseTier ? publishDisabled() : quiz?.allErrors.length}
            onClick={() => {
              if (save) {
                handleBack()
              } else {
                localStorage.setItem('skip-tutorial', 'true')
                quiz?.set('touched', true)
                quiz?.questions?.map((q) => {
                  q.set('touched', true)
                  q?.choices.map((c) => c.set('touched', true))
                })
                if (!quiz?.allErrors.length) {
                  handleSubmit()
                  setShowValidationDialog(true)
                }
              }
            }}
            variant="contained"
            color="primary"
            text={save ? 'Save' : 'Publish'}
          />
        )}
      </div>

      {showUnsavedChanges && (
        <ConfirmDialog
          danger
          open={showUnsavedChanges}
          title="Unsaved Changes"
          message={`Your ${
            path ? 'path' : isTopic(publishData) ? 'topic' : 'quiz'
          } has not been published. Are you sure you want to exit without saving?`}
          confirmButton="Discard Changes"
          onConfirm={() => {
            onBack()
            // resetContentStore()
            setShowUnsavedChanges(false)
          }}
          onClose={() => setShowUnsavedChanges(false)}
          handleCancel={() => setShowUnsavedChanges(false)}
        />
      )}

      {showValidationDialog && ((topics && topics?.length === 1) || quiz || path) && (
        <PublishDialog
          edit={edit}
          path={path}
          editingPath={editingPath}
          topics={topics}
          setShowWizard={setShowWizard}
          quiz={quiz}
          open={showValidationDialog}
          onConfirm={() => {
            setShowValidationDialog(false)
          }}
          onClose={() => {
            setShowValidationDialog(false)
          }}
        />
      )}

      {showValidationDialog && ((topics && topics?.length > 1) || question) && (
        <ValidationDialog
          onClose={() => {
            setShowValidationDialog(false)
          }}
          open={showValidationDialog}
          onPublishSuccess={onPublishSuccessful}
        />
      )}

      {showSuccessModal && (
        <SuccessfulImport
          open={showSuccessModal}
          onClose={() => {
            const goto = topics && topics?.length > 0 ? 'topics' : quiz ? 'quizzes' : 'questions'
            resetContentStore()
            setShowValidationDialog(false)
            setShowSuccessModal(false)
            history.push(!enterpriseTier ? '/content/quizzes' : `/content/${goto}`)
          }}
          onAssign={() => {
            setShowSuccessModal(false)
            setShowValidationDialog(false)
            setShowWizard(true)
          }}
          onGo={() => {
            setShowSuccessModal(false)
            setShowValidationDialog(false)
            const goto = topics && topics?.length > 0 ? 'topics' : quiz ? 'quizzes' : 'questions'
            resetContentStore()
            history.push(!enterpriseTier ? '/content/quizzes' : `/content/${goto}`)
          }}
        />
      )}

      {showWizard && (
        <AssignmentCreateDialog
          fromContentCreation
          open={showWizard}
          initialPaths={path && path.path_id ? [path.path_id] : []}
          initialTopics={selectedTopic?.topic_id ? [selectedTopic.topic_id] : undefined}
          initialQuizzes={quiz && quiz.quiz_id ? [quiz.quiz_id] : []}
          requestClose={() => {
            setShowWizard(false)
            if (path) {
              history.push(`/content/paths`)
            } else {
              history.push(`/content/topics`)
            }
            resetContentStore()
          }}
        />
      )}
      <Dialog
        open={!!publishMessage}
        dialogText={publishMessage || ''}
        onClose={() => setPublishMessage('')}
        confirmBtnLabel="Close"
        title="Publish Failed"
      />
    </div>
  )
})

export interface PublishDialogProps {
  open: boolean
  onConfirm: () => void
  onClose: () => void
  quiz?: any
  topics?: any[]
  edit?: boolean
  setShowWizard?: any
  path?: any
  editingPath?: boolean
}

export const PublishDialog = observer((props: PublishDialogProps) => {
  const { path, editingPath, open, edit, setShowWizard, onConfirm, topics, onClose, quiz } = props

  const { contentCreationStore, pathStore, manageQuizStore, manageUserStore, topicsStore } =
    useStores()
  const { enterpriseTier } = manageUserStore
  const { updatePath, publishPath } = pathStore
  const { exportTopics } = topicsStore
  const { publishObjectContent, resetContentStore, isPublishingContent } = contentCreationStore
  const { exportQuizzes } = manageQuizStore
  const [showSettings, setShowSettings] = useState(quiz?.approved ? true : false)
  const [showConfirm, setShowConfirm] = useState(false)

  const [publishData, setPublishData] = useState<any>()

  const history = useHistory()

  const creatingTopic = topics && topics?.length > 0

  useEffect(() => {
    if (path || (creatingTopic && topics[0]?.approved) || (quiz && quiz?.approved)) {
      setShowConfirm(true)
      publish()
    }
  }, [])

  const approveAll = () => {
    if (creatingTopic) {
      topics.map((topic) => {
        topic?.quizzes?.map((tq) => {
          tq?.questions.map((question) => {
            question?.set('approved', true)
          })
        })
      })

      setShowConfirm(true)
      publish()
    } else {
      quiz?.questions.map((question) => {
        question?.set('approved', true)
      })
      setShowConfirm(true)
      publish()
    }
  }

  const handleClose = () => {
    if (path) {
      history.replace(`/content/paths/${path.path_id}/overview`)
      onClose()
      resetContentStore()
    } else if (topics?.length && !topics[0]?.approved) {
      onClose()
    } else {
      if (creatingTopic && publishData) {
        history.replace(`/content/topics/${publishData.topic[0].topic_id}/overview`)
      } else if (publishData) {
        history.replace(`/content/quizzes/${publishData.quiz[0].quiz_id}/overview`)
      }
      onClose()
      resetContentStore()
    }
  }

  const publish = () => {
    if (path) {
      if (editingPath) {
        updatePath().then((data: any) => {
          setShowConfirm(true)
        })
      } else {
        publishPath().then((data: any) => {
          setShowConfirm(true)
        })
      }
    } else {
      publishObjectContent().then((data) => {
        setShowConfirm(true)
        setPublishData(data)
        if (!edit && !isTopic) {
          QuizApi.toggleQuizStatus(data?.quiz[0].quiz_id, 'live')
        }
      })
    }
  }

  const exportData = async () => {
    if (creatingTopic && publishData) {
      const { file_url } = (await exportTopics([publishData?.topic[0].topic_id])) as any
      window.open(file_url)
    } else if (publishData?.quiz[0]) {
      const { file_url } = (await exportQuizzes([publishData.quiz[0]?.quiz_id])) as any
      window.open(file_url)
    }
  }

  const share = () => {
    if (enterpriseTier) {
      onClose()
      setShowWizard(true)
    } else {
      history.replace(`/content/quizzes/${publishData?.quiz[0].quiz_id}/overview/?share=true`)
    }
  }

  return (
    <Dialog
      onClose={handleClose}
      hideCloseIcon={isPublishingContent}
      maxWidth="xs"
      title={
        showConfirm
          ? path
            ? 'Path Published!'
            : topics && topics?.length > 0
              ? 'Topic Published!'
              : 'Quiz Published!'
          : showSettings
            ? 'Confirm Quiz Settings'
            : topics && topics?.length > 0
              ? 'Quizzes Awaiting Review'
              : 'Questions Awaiting Review'
      }
      aria-labelledby="exit-dialog-title"
      open={open}
      actions={
        !isPublishingContent && (
          <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end' }}>
            <div style={{ display: 'flex', gap: 12 }}>
              {showConfirm && !path && (
                <Button
                  text="Export"
                  variant="outlined"
                  color="info"
                  startIcon={<Download />}
                  onClick={exportData}
                />
              )}
              {showConfirm && !creatingTopic && !path && (
                <Button
                  variant={enterpriseTier ? 'outlined' : 'contained'}
                  onClick={share}
                  color={enterpriseTier ? 'primary' : 'info'}
                  startIcon={enterpriseTier ? <FactCheck /> : <IosShare />}
                  text={enterpriseTier ? 'Assign' : 'Share'}
                />
              )}
              {creatingTopic && showConfirm && (
                <Button
                  variant="outlined"
                  color="info"
                  onClick={() => {
                    onClose()
                    setShowWizard(true)
                  }}
                  startIcon={<FactCheck />}
                  text="Assign Topic"
                />
              )}
              {path && showConfirm && (
                <Button
                  variant="outlined"
                  color="info"
                  onClick={() => {
                    onClose()
                    setShowWizard(true)
                  }}
                  startIcon={<FactCheck />}
                  text="Assign Path"
                />
              )}
              {showConfirm && <Button onClick={handleClose} text="Done" />}
              {!showConfirm && (
                <>
                  <Button variant="outlined" color="info" onClick={onClose} text="Cancel" />
                  <Button
                    onClick={showSettings ? publish : approveAll}
                    text={showSettings ? 'Publish' : 'Approve All'}
                  />
                </>
              )}
            </div>
          </div>
        )
      }
    >
      {isPublishingContent ? (
        <Stack direction="row" justifyContent="center">
          <CircularProgress />
        </Stack>
      ) : showConfirm ? (
        <div style={{ flexDirection: 'column', display: 'flex', alignItems: 'center' }}>
          {path ? (
            <QuizCard
              path={path}
              disableHover
              onEdit={() => {}}
              onExport={() => {}}
              onShare={() => {}}
              onDelete={() => {}}
              title={path.name}
              image={path.image}
              questionCount={0}
              lastUpdated={DateTime.now().toFormat('MMM d, yyyy')}
              onClick={() => {}}
              hideMenu
              status={''}
              hideChip
              fullWidth
            />
          ) : creatingTopic && topics && topics?.length > 0 ? (
            <QuizCard
              topic
              disableHover
              onEdit={() => {}}
              onExport={() => {}}
              onShare={() => {}}
              onDelete={() => {}}
              title={topics[0]?.title}
              image={topics[0]?.image}
              questionCount={topics[0]?.quizzes?.length}
              lastUpdated={DateTime.now().toFormat('MMM d, yyyy')}
              onClick={() => {}}
              hideMenu
              status={''}
              hideChip
              fullWidth
            />
          ) : (
            <QuizCard
              disableHover
              onEdit={() => {}}
              onExport={() => {}}
              onShare={() => {}}
              onDelete={() => {}}
              title={quiz?.title}
              image={quiz?.image}
              questionCount={quiz?.questions?.filter((q) => !q.removing).length}
              lastUpdated={DateTime.now().toFormat('MMM d, yyyy')}
              onClick={() => {}}
              hideMenu
              status={'Live'}
              fullWidth
            />
          )}
        </div>
      ) : creatingTopic && topics && topics?.length > 0 ? (
        <Text
          variant="body1Medium"
          text={`${topics[0]?.quizzes?.filter((q) => !q.approved)?.length} quiz${
            topics[0]?.quizzes?.filter((q) => !q.approved)?.length === 1 ? '' : 'zes'
          } ${
            topics[0]?.quizzes?.filter((q) => !q.approved)?.length === 1 ? 'is' : 'are'
          } still awaiting review. Do you want to approve ${
            topics[0]?.quizzes?.filter((q) => !q.approved)?.length === 1 ? 'this' : 'these'
          } ${
            topics[0]?.quizzes?.filter((q) => !q.approved)?.length === 1 ? 'quiz' : 'quizzes'
          } and proceed?`}
          style={{ marginTop: 30, marginBottom: 30, textAlign: 'center' }}
        />
      ) : (
        <Text
          variant="body1Medium"
          text={`${quiz?.questions?.filter((q) => !q.approved && !q.removing)?.length} question${
            quiz?.questions?.filter((q) => !q.approved && !q.removing)?.length === 1 ? '' : 's'
          } ${
            quiz?.questions?.filter((q) => !q.approved && !q.removing)?.length === 1 ? 'is' : 'are'
          } still awaiting review. Do you want to approve ${
            quiz?.questions?.filter((q) => !q.approved && !q.removing)?.length === 1
              ? 'this'
              : 'these'
          } ${
            quiz?.questions?.filter((q) => !q.approved && !q.removing)?.length === 1
              ? 'question'
              : 'questions'
          } and proceed?`}
          style={{ marginTop: 30, marginBottom: 30, textAlign: 'center' }}
        />
      )}
    </Dialog>
  )
})

const ROOT: React.CSSProperties = {
  display: 'flex',
  justifyContent: 'space-between',
  paddingTop: 10,
  paddingBottom: 10,
  paddingRight: 24,
  paddingLeft: 28,
  backgroundColor: color.white,
  borderBottomWidth: 1,
  boxShadow: '0px -1px 0px 0px #E2E2EA inset',
  alignItems: 'center',
  height: 70,
  zIndex: 1300,
  position: 'relative',
}

export default ContentCreationHeader
