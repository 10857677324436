import React, { useEffect, useState } from 'react'
import { observer } from 'mobx-react-lite'
import { useHistory, useRouteMatch } from 'react-router-dom'
import { Grid, Stack } from '@mui/material'
import {
  Edit,
  RadioButtonChecked,
  ThumbsUpDown,
  CheckBox,
  RadioButtonUnchecked,
  CheckBoxOutlineBlankRounded,
  CheckBoxRounded,
} from '@mui/icons-material'

import { roundNumber, useStores } from '@trivie/core'
import { QuestionApi } from '@trivie/core/src/services/api-objects'

import { color } from '@trivie/ui-web/theme'
import { DataTable } from '@trivie/ui-web/components/data-table'
import { Header } from '@trivie/ui-web/components/header'
import { Insight } from '@trivie/ui-web/components/insight'
import { Text } from '@trivie/ui-web/components/text'
import { ShortList } from '@trivie/ui-web/components/short-list'
import { Panel } from '@trivie/ui-web/components/panel/panel'
import { Switch } from '@trivie/ui-web/components/switch/switch'

export const QuestionDetailsScreen = observer(() => {
  const { questionStore, contentCreationStore, manageUserStore } = useStores()
  const history = useHistory()

  const { resetContentStore } = contentCreationStore
  const {
    questionStats,
    getQuestionDetails,
    getKnowledgeGain,
    getRelatedQuizzes,
    getRelatedTopicsForQuestion,
    knowledgeGain,
    relatedQuizzes,
    relatedTopics,
  } = questionStore
  const { permissions } = manageUserStore

  const { params } = useRouteMatch()
  const { id } = params as any

  const [loadingQuestion, setLoadingQuestion] = useState(true)
  const [loadingKG, setLoadingKG] = useState(true)
  const [loadingMemberships, setLoadingMemberships] = useState(true)

  const [answerCount, setAnswerCount] = useState(0)
  const [pctCorrect, setPctCorrect] = useState(0)
  const [answerTime, setAnswerTime] = useState(0)

  useEffect(() => {
    QuestionApi.getAllQuestions(
      'questions',
      false,
      [
        { field: 'question_answer_count' },
        { field: 'question_pct_correct' },
        { field: 'question_answer_time' },
      ],
      'list_questions',
      undefined,
      Number(id),
    ).then((resp) => {
      if (resp.ok) {
        const question = resp.data.rows[0]
        setAnswerTime(
          roundNumber(
            question.columns.find((column) => column.field === 'question_answer_time')?.value /
              1000 || 0,
            1,
          ),
        )
        setPctCorrect(
          roundNumber(
            question.columns.find((column) => column.field === 'question_pct_correct')?.value * 100,
            1,
          ),
        )
        setAnswerCount(
          question.columns.find((column) => column.field === 'question_answer_count')?.value,
        )
      }
    })
    getQuestionDetails(id).then(() => setLoadingQuestion(false))
    getKnowledgeGain(id).then(() => setLoadingKG(false))
    Promise.all([getRelatedQuizzes(id), getRelatedTopicsForQuestion(id)]).then(() =>
      setLoadingMemberships(false),
    )
  }, [])

  const primary = {
    disabled: !permissions?.permissions.some((p) => p.code === 'can_edit_question'),
    startIcon: <Edit />,
    onClick: () => {
      resetContentStore()
      history.push(`/content/question/${(params as any).id}/edit`)
    },
    text: 'Edit Question',
  }

  return (
    <>
      <Header divider title="Question Details" primaryAction={primary} />
      <Text component="h2" mb={2} text="Statistics" variant="labelEmphasized" />
      {!loadingKG && (
        <Grid container spacing={2} mb={4}>
          <Grid item lg={4} md={6} sm={12}>
            <Panel fill>
              <Insight
                tooltip="The number of times the question has been answered"
                title="Answer Count"
                value={answerCount}
              />
            </Panel>
          </Grid>
          <Grid item lg={4} md={6} sm={12}>
            <Panel fill>
              <Insight
                tooltip="Percentage of all answers that were answered correctly"
                title="Correct %"
                value={`${pctCorrect}%`}
              />
            </Panel>
          </Grid>
          {/* <Grid item lg={3} md={6} sm={12}>
            <Panel fill>
              <Insight
                tooltip="Tooltip"
                noAveraging
                unit="%"
                title="Unique Respondents"
                value={answerCount}
              />
            </Panel>
          </Grid> */}
          <Grid item lg={4} md={6} sm={12}>
            <Panel fill>
              <Insight
                tooltip="Average time in seconds it takes users to answer the question"
                title="Average Answer Time"
                value={answerTime ? `${answerTime} secs` : '-'}
              />
            </Panel>
          </Grid>
          <Grid item lg={4} md={6} sm={12}>
            <Panel fill>
              <Insight
                tooltip="Starting knowledge for this question"
                title="Starting Knowledge"
                value={
                  knowledgeGain ? `${Math.round(knowledgeGain.starting_knowledge) ?? 0}%` : '-'
                }
              />
            </Panel>
          </Grid>
          <Grid item lg={4} md={6} sm={12}>
            <Panel fill>
              <Insight
                tooltip="Current knowledge gain for this question"
                title="Current Knowledge"
                value={knowledgeGain ? `${Math.round(knowledgeGain.current_knowledge) ?? 0}%` : '-'}
              />
            </Panel>
          </Grid>
          <Grid item lg={4} md={6} sm={126}>
            <Panel fill>
              <Insight
                tooltip="Average difference between the first and most recent answers for the question"
                title="Knowledge Gain"
                value={knowledgeGain ? `${Math.round(knowledgeGain.knowledge_gain) ?? 0}%` : '-'}
              />
            </Panel>
          </Grid>
        </Grid>
      )}
      {!loadingQuestion && (
        <>
          <Text component="h2" mb={2} text="Question" variant="labelEmphasized" />
          <Panel sx={{ mb: 4 }}>
            <Stack useFlexGap gap={2}>
              {questionStats.question_type === 'Multiple Choice' && (
                <Stack
                  pb={1}
                  sx={{ borderBottom: `1px solid ${color.shade10}` }}
                  direction="row"
                  alignItems="center"
                >
                  <RadioButtonChecked
                    sx={{ marginRight: '12px' }}
                    htmlColor={color.shade70}
                    fontSize="small"
                  />
                  <Text
                    style={{ color: color.shade70 }}
                    variant="body2Emphasized"
                    text="Multiple Choice"
                  />
                </Stack>
              )}
              {questionStats.question_type === 'True or False' && (
                <Stack
                  pb={1}
                  sx={{ borderBottom: `1px solid ${color.shade10}` }}
                  direction="row"
                  alignItems="center"
                >
                  <ThumbsUpDown
                    sx={{ marginRight: '12px' }}
                    htmlColor={color.shade70}
                    fontSize="small"
                  />
                  <Text
                    style={{ color: color.shade70 }}
                    variant="body2Emphasized"
                    text="True / False"
                  />
                </Stack>
              )}
              {questionStats.question_type === 'Multiple Response' && (
                <Stack
                  pb={1}
                  sx={{ borderBottom: `1px solid ${color.shade10}` }}
                  direction="row"
                  alignItems="center"
                >
                  <CheckBox
                    sx={{ marginRight: '12px' }}
                    htmlColor={color.shade70}
                    fontSize="small"
                  />
                  <Text
                    style={{ color: color.shade70 }}
                    variant="body2Emphasized"
                    text="Checkbox"
                  />
                </Stack>
              )}
              {questionStats?.image_url && <img src={questionStats?.image_url} style={IMAGE} />}
              <Text
                component="div"
                pb={1}
                text={questionStats?.text}
                variant="labelEmphasized"
                sx={{ borderBottom: `1px solid ${color.shade10}` }}
              />
              <DataTable
                disableSearch
                hideFooter
                checkboxSelection={false}
                sx={{
                  border: 'none',
                  '& .MuiDataGrid-columnHeaders > div': {
                    borderTopLeftRadius: '20px !important',
                    borderTopRightRadius: '20px !important',
                  },
                }}
                columns={[
                  {
                    field: 'correct',
                    headerName: ' ',
                    width: 40,
                    renderCell: (p: any) => {
                      return (
                        <div
                          style={{
                            height: '100%',
                            justifyContent: 'center',
                            display: 'flex',
                            width: '100%',
                          }}
                        >
                          {questionStats?.question_type === 'Multiple Response' && p.row.correct ? (
                            <CheckBoxRounded style={CORRECT_ICON} />
                          ) : questionStats?.question_type === 'Multiple Response' &&
                            !p.row.correct ? (
                            <CheckBoxOutlineBlankRounded style={CORRECT_ICON} />
                          ) : p.row.correct ? (
                            <RadioButtonChecked style={CORRECT_ICON} />
                          ) : (
                            <RadioButtonUnchecked style={CORRECT_ICON} />
                          )}
                        </div>
                      )
                    },
                  },
                  {
                    field: 'text',
                    headerName: 'ANSWER',
                    flex: 1,
                    resizable: false,
                    type: 'string',
                  },
                  {
                    field: 'pct_selected',
                    headerName: '% SELECTED',
                    width: 150,
                    resizable: false,
                    type: 'number',
                    renderCell: (p: any) => `${roundNumber(p.row.pct_selected, 1)}%`,
                  },
                  {
                    field: 'avg_time',
                    headerName: 'AVERAGE TIME',
                    width: 150,
                    resizable: false,
                    type: 'number',
                    renderCell: (p: any) => `${roundNumber(p.row.avg_time / 1000, 1)} secs`,
                  },
                ]}
                toolbar={null}
                onRowSelectionModelChange={(param) => {}}
                hidePagination
                hideActions
                getRowId={(row) => row.choice_id}
                rows={questionStats?.answers_summary ?? []}
              />
              {questionStats?.factoid?.text && (
                <Stack useFlexGap gap={1}>
                  <Text
                    component="div"
                    variant="body2Emphasized"
                    text="Feedback"
                    style={{ color: color.shade50 }}
                  />
                  <Text
                    component="div"
                    variant="body2"
                    text={questionStats?.factoid?.text}
                    pb={1}
                    sx={{ borderBottom: `1px solid ${color.shade10}` }}
                  />
                </Stack>
              )}
              <Stack useFlexGap gap={2}>
                <Text
                  component="div"
                  variant="body2Emphasized"
                  text="Settings"
                  style={{ color: color.shade50 }}
                />
                <Stack direction="row" justifyContent="space-between">
                  <Stack useFlexGap gap={0.5}>
                    <Text variant="body2Emphasized" text="Reinforcement Eligible" />
                    <Text
                      color="text.secondary"
                      variant="body2"
                      text="Include this question in reinforcement quizzes."
                    />
                  </Stack>
                  <Switch
                    color="success"
                    checked={Boolean(questionStats?.adaptive_eligible)}
                    disabled
                  />
                </Stack>
                <Stack direction="row" justifyContent="space-between">
                  <Stack useFlexGap gap={0.5}>
                    <Text variant="body2Emphasized" text="Shuffle Answer Order" />
                    <Text
                      color="text.secondary"
                      variant="body2"
                      text="Randomize the order of this question's answers."
                    />
                  </Stack>
                  <Switch
                    color="success"
                    checked={!Boolean(questionStats?.keep_answer_order)}
                    disabled
                  />
                </Stack>
                {questionStats.external_id && (
                  <Stack direction="row" justifyContent="space-between">
                    <Stack useFlexGap gap={0.5}>
                      <Text variant="body2Emphasized" text="External ID" />
                      <Text
                        color="text.secondary"
                        variant="body2"
                        text="Refer to this question outside of Trivie."
                      />
                    </Stack>
                    <Text
                      component="div"
                      text={questionStats.external_id}
                      variant="title"
                      sx={{
                        minWidth: 160,
                        lineHeight: '32px',
                        borderBottom: `1px solid ${color.shade10}`,
                      }}
                    />
                  </Stack>
                )}
              </Stack>
            </Stack>
          </Panel>
        </>
      )}
      {!loadingMemberships && (
        <>
          <Text mb={2} component="h2" text="Memberships" variant="labelEmphasized" />
          <Grid container spacing={2}>
            <Grid item lg={6} md={6} sm={12}>
              <ShortList data={relatedQuizzes} type="Quizzes" />
            </Grid>
            <Grid item lg={6} md={6} sm={12}>
              <ShortList data={relatedTopics} type="Topics" />
            </Grid>
          </Grid>
        </>
      )}
    </>
  )
})

const CORRECT_ICON: React.CSSProperties = {
  alignSelf: 'center',
  left: 20,
  fontSize: 20,
}
const IMAGE: React.CSSProperties = {
  marginBottom: 28,
  borderRadius: '10px',
  objectFit: 'cover',
  height: 300,
  width: 470,
  alignSelf: 'center',
}
