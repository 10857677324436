import { SnapshotIn, types, cast } from 'mobx-state-tree'
import { validateAndFormatNumber } from '../utils'

export const EditUserForm = types
  .model('EditUserForm')
  .props({
    first_name: types.string,
    last_name: types.string,
    email: types.maybeNull(types.string),
    phone_number: types.maybeNull(types.string),
    touched: types.boolean,
    duplicatePhone: types.boolean,
    duplicateEmail: types.boolean,
  })
  .views((self) => {
    return {
      get errors() {
        const errors: any = {}
        if (!self.first_name) {
          errors.first_name = {
            type: 'required',
            message: 'First name is required',
          }
        }

        if (!self.last_name) {
          errors.last_name = {
            type: 'required',
            message: 'Last name is required',
          }
        }

        if (self.email && !self.email.includes('@')) {
          errors.email = {
            type: 'required',
            message: 'Email must include an @ symbol',
          }
        }

        if (self.phone_number && !validateAndFormatNumber(self.phone_number)) {
          errors.phone_number = {
            type: 'required',
            message: 'Please enter a valid phone number',
          }
        }

        if (!self.email) {
          if (!self.phone_number) {
            errors.email = {
              type: 'required',
              message: 'Email or phone number is required',
            }
          }
        }

        if (!self.phone_number) {
          if (!self.email) {
            errors.phone_number = {
              type: 'required',
              message: 'Email or phone number is required',
            }
          }
        }

        if (self.duplicateEmail) {
          errors.email = {
            type: 'required',
            message: 'Another user with that email already exists',
          }
        }

        if (self.duplicatePhone) {
          errors.phone_number = {
            type: 'required',
            message: 'Another user with that phone number already exists',
          }
        }

        // no need to validate beyond this since we're missing required fields
        if (Object.keys(errors).length) {
          return errors
        }

        return errors
      },
    }
  })
  .actions((self) => {
    return {
      set<K extends keyof SnapshotIn<typeof self>, T extends SnapshotIn<typeof self>>(
        key: K,
        value: T[K],
      ) {
        self[key] = cast(value) as any
      },
    }
  })
