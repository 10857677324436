import React from 'react'
import { useTranslation } from 'react-i18next'
import { Typography, TypographyProps } from '@mui/material'
import { observer } from 'mobx-react-lite'

// type TextBaseProps = Pick<
//   TypographyProps,
//   'align' | 'classes' | 'paragraph' | 'sx' | 'className' | 'ref'
// >

export interface TextProps extends TypographyProps {
  text?: string | null | undefined
  variant?: any
  tx?: string
  txOptions?: object
  children?: any
  style?: React.CSSProperties
  component?: React.ElementType<any>
}

export const Text = observer((props: TextProps) => {
  const { tx, variant, color, txOptions, text, children, style, ...rest } = props

  // Localization if applicable / available
  const { t } = useTranslation()
  const translated = tx && t(tx, txOptions)
  const content = translated || text || children

  return (
    <Typography color={color || 'text.primary'} variant={variant} style={style} {...rest}>
      {content ?? ''}
    </Typography>
  )
})
