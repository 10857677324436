import React, { useState, useMemo, useEffect, useRef } from 'react'
import { useTrail, useTransition } from '@react-spring/web'
import { Container, Message, Content, ChatBubble, Row, ChatAvatar, Loading, Circle } from './styles'
import { observer } from 'mobx-react-lite'
import { useInterval, useStores } from '@trivie/core'

import { Button } from '@trivie/ui-web/components/button'
import { color } from '@trivie/ui-web/theme/color'

import { ReactComponent as TrivieAvatar } from './trivie-avatar.svg'
import { ReactComponent as QuantumAvatar } from './quantum-avatar.svg'

export interface ChatProps {
  config?: {
    tension: number
    friction: number
    precision: number
  }
  timeout?: number
}

export const Chat = observer(
  ({ config = { tension: 150, friction: 30, precision: 0.4 } }: ChatProps) => {
    const { chatStore, uiStore } = useStores()
    const { quantum } = uiStore
    const { messages } = chatStore

    const refMap = useMemo(() => new WeakMap(), [])
    const [items, setItems] = useState<any[]>(messages?.length > 0 ? messages : [])

    const transitions = useTransition(items, {
      from: { opacity: 0, height: 0 },
      keys: (item) => item.key,
      enter: (item) => async (next, cancel) => {
        try {
          if (refMap.get(item)) {
            await next({ opacity: 1, height: refMap.get(item)?.offsetHeight + 16 })
          }
        } catch (e) {
          console.log(e)
        }
      },
      leave: [{ opacity: 0 }, { height: 0 }],
      config,
    })

    useEffect(() => {
      setItems([...messages])
    }, [messages.length])

    return (
      <Container style={{ overflowY: 'hidden' }}>
        {transitions(({ ...style }, item) => {
          try {
            if (item) {
              const { batch, loading, sender, text, actions, followsBatch } = item
              const trivie = sender === 'trivie'

              const defaultBubbleStyle = {
                marginLeft: batch && trivie ? 34 : 0,
                borderBottomLeftRadius: trivie && batch ? 2 : 12,
                borderBottomRightRadius: 12,
                borderTopLeftRadius: trivie && followsBatch ? 2 : 12,
                borderTopRightRadius: 12,
              }

              return (
                <>
                  {((item.actions && actions?.length > 0) || text) && (
                    <Message
                      style={{
                        ...style,
                        alignSelf: trivie ? 'flex-start' : 'flex-end',
                      }}
                    >
                      <Content
                        style={{ marginTop: followsBatch ? 2 : batch ? 16 : 2 }}
                        ref={(ref: HTMLDivElement) => ref && refMap.set(item, ref)}
                      >
                        {loading ? (
                          <Row>
                            {trivie && !batch && (
                              <ChatAvatar>
                                {quantum ? <QuantumAvatar /> : <TrivieAvatar />}
                              </ChatAvatar>
                            )}
                            <ChatBubble
                              className={`${batch ? 'no-tail' : ''} ${
                                trivie ? 'trivie' : 'user'
                              } resourceContainer`}
                              style={defaultBubbleStyle}
                            >
                              <Loader />
                            </ChatBubble>
                          </Row>
                        ) : actions && actions.length > 0 ? (
                          <div
                            style={{
                              display: 'flex',
                              flexDirection: 'row',
                              gap: 10,
                              justifyContent: 'flex-end',
                              flexWrap: 'wrap',
                            }}
                          >
                            {actions.map((action) => {
                              return action.visible ? (
                                <Button
                                  variant={action.active ? 'contained' : 'outlined'}
                                  onClick={action.type === 'toggle' ? action.toggle : action.select}
                                  style={{
                                    backgroundColor: action.active ? color.blue : color.white,
                                    animation: action.visible ? 'fadeIn 1.5s' : 'fadeOut 1.5s',
                                  }}
                                  text={action.label}
                                />
                              ) : action.selected ? (
                                <ChatBubble
                                  className={`${batch ? 'no-tail' : ''} ${
                                    trivie ? 'trivie' : 'user'
                                  }`}
                                  style={{ ...defaultBubbleStyle, marginLeft: batch ? 34 : 0 }}
                                >
                                  {action.label}
                                </ChatBubble>
                              ) : null
                            })}
                          </div>
                        ) : (
                          <Row>
                            {trivie && !batch && (
                              <ChatAvatar>
                                {quantum ? <QuantumAvatar /> : <TrivieAvatar />}
                              </ChatAvatar>
                            )}
                            <ChatBubble
                              className={`${batch ? 'no-tail' : ''} ${trivie ? 'trivie' : 'user'}`}
                              style={defaultBubbleStyle}
                            >
                              {text}
                            </ChatBubble>
                          </Row>
                        )}
                      </Content>
                    </Message>
                  )}
                  {item.component}
                </>
              )
            }
          } catch (e) {
            console.log(e)
          }
        })}
      </Container>
    )
  },
)

const Loader = () => {
  const loadingBubbles = ['', '', '']
  const [trail, api] = useTrail(loadingBubbles.length, () => ({ scale: 0.7 }))

  const show = useRef(false)

  const trigger = () => {
    if (show.current) {
      api.start({ scale: 0 })
      show.current = false
    } else {
      api.start({ scale: 0.7 })
      show.current = true
    }
  }

  useInterval(() => {
    trigger()
  }, 1000)

  return (
    <Loading>
      {trail.map(({ scale }, i) => (
        <Circle
          key={loadingBubbles[i]}
          style={{ scale, borderRadius: 100, backgroundColor: color.shade50 }}
        />
      ))}
    </Loading>
  )
}
