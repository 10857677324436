import React, { useEffect, useState } from 'react'
import { observer } from 'mobx-react-lite'
import { Instance, getNodeId } from 'mobx-state-tree'
import { Stack } from '@mui/material'

import { useStores } from '@trivie/core'
import { Text } from '@trivie/ui-web/components/text'
import { color } from '@trivie/ui-web/theme'
import { Quiz } from '@trivie/core/src/models/Quiz'

import { ReactComponent as NullState } from '../null-states/null-quiz-settings.svg'
import { QuizItem } from './quiz-item'
import { QuizApi } from '@trivie/core/src/services/api-objects'

export const QuizList: React.FunctionComponent = observer(() => {
  const { contentCreationStore } = useStores()
  const { selectedTopic } = contentCreationStore

  const [draggingQuizID, setDraggingQuizID] = useState<null | number>(null)
  const [hoveredID, setHoveredID] = useState<null | number>(null)
  const [grabbing, setGrabbing] = useState(false)
  const [existingQuizNames, setExistingQuizNames] = useState([])

  const handleDrag = (quiz: Instance<typeof Quiz>) => setDraggingQuizID(getNodeId(quiz))

  const handleDrop = (quiz: Instance<typeof Quiz>) => {
    setGrabbing(false)
    if (selectedTopic) {
      const { quizzes } = selectedTopic
      const dragQuiz = quizzes?.find((c) => getNodeId(c) === draggingQuizID)
      const dropQuiz = quizzes?.find((c) => getNodeId(c) === getNodeId(quiz))
      if (dragQuiz && dropQuiz) {
        selectedTopic.reorderQuizzes(
          quizzes?.indexOf(dragQuiz) ?? 0,
          quizzes?.indexOf(dropQuiz) ?? 0,
        )
      }
    }
  }

  useEffect(() => {
    QuizApi.getAllQuizzes('quizzes', false, [{ field: 'quiz_title' }], 'list_quizzes').then(
      (res: any) =>
        setExistingQuizNames(
          res.data.rows.map((row) =>
            row.columns
              .find((c) => c.field === 'quiz_title')
              ?.value?.replace(/\s+/g, '')
              ?.toLowerCase(),
          ),
        ),
    )
  }, [])

  if (!selectedTopic) {
    return null
  }

  return selectedTopic.quizzes?.length === 0 ? (
    <>
      <NullState style={{ alignSelf: 'center' }} />
      <Text
        align="center"
        alignSelf="center"
        color={color.shade50}
        my={'16px'}
        text="You don't have any quizzes attached this topic yet! Add a quiz to get rolling."
      />
    </>
  ) : (
    <Stack useFlexGap gap={2}>
      {selectedTopic.quizzes?.map((q: Instance<typeof Quiz>) => {
        return (
          <QuizItem
            existingQuizNames={existingQuizNames}
            grabbing={grabbing}
            handleDrag={handleDrag}
            handleDrop={handleDrop}
            setHoveredID={setHoveredID}
            setGrabbing={setGrabbing}
            hoveredID={hoveredID}
            quiz={q}
          />
        )
      })}
    </Stack>
  )
})
