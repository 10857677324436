import { ApiResponse, ApisauceInstance } from 'apisauce'
import { Instance } from 'mobx-state-tree'
import { Question } from '../../models/Question'
import { GetQuestionDetail } from '../../models/response-models/question'
import { handleResponse } from '../handleResponse'

export const QuestionApi = {
  apisauce: {} as ApisauceInstance,
  getAllQuestions: async (
    type: 'users' | 'topics' | 'quizzes' | 'groups' | 'tags' | 'questions',
    meta?: boolean,
    columns?: any,
    view_name?: string,
    id?: number,
    primary_key?: number,
  ) => {
    const payload = { view_name, ...(primary_key && { primary_key }) }

    if (columns && columns.length > 0) {
      payload['columns'] = columns
    }

    const response: ApiResponse<any> = await QuestionApi.apisauce.post(
      Boolean(id)
        ? `/${type}/${id}/questions/?meta=${meta}&asynchronous=${false}`
        : `/${type}/?meta=${meta}&asynchronous=${false}`,
      payload,
    )
    return handleResponse(response)
  },
  getQuestion: async (id: number) => {
    const response: ApiResponse<Instance<typeof GetQuestionDetail>> =
      await QuestionApi.apisauce.get(`/questions/${id}/`)

    return handleResponse(response)
  },
  generateFeedback: async (payload) => {
    const response: ApiResponse<any> = await QuestionApi.apisauce.post(
      `/generate/fact_variant/`,
      payload,
    )
    return handleResponse(response)
  },
  generateFromPartial: async (payload) => {
    const response: ApiResponse<any> = await QuestionApi.apisauce.post(
      `/generate/question_variant/for_question_partial/`,
      payload,
    )

    return handleResponse(response)
  },
  generateDistractors: async (payload: {
    query: string
    fact: string
    correct_choices: string[]
    rejected_choices: string[] | null
    distractors_only: boolean
    distractors_count: number
    generated_question_id: number | null
  }) => {
    const response: ApiResponse<any> = await QuestionApi.apisauce.post(
      `/generate/choices/for_question/`,
      payload,
    )

    return handleResponse(response)
  },
  publishImage: async (id: number, image: File) => {
    const formData = new FormData()
    formData.append('image', image)

    const response: ApiResponse<null> = await QuestionApi.apisauce.patch(
      `questions/${id}/image/`,
      formData,
    )

    return handleResponse(response)
  },
  removeImage: async (id: number) => {
    const response: ApiResponse<null> = await QuestionApi.apisauce.delete(`questions/${id}/image/`)

    return handleResponse(response)
  },
  deleteQuestion: async (question: Instance<typeof Question>) => {
    const response: ApiResponse<null> = await QuestionApi.apisauce.delete(
      `questions/delete/`,
      {},
      {
        data: {
          question_ids: [question.question_id],
        },
      },
    )

    return handleResponse(response)
  },
}
