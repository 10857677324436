import React, { useRef, useEffect, useState } from 'react'
import { observer } from 'mobx-react-lite'
import { TextField } from '@mui/material'
import ChevronLeft from '@mui/icons-material/ChevronLeft'

import { color } from '@trivie/ui-web/theme'
import { Text } from '@trivie/ui-web/components/text'
import { ImageCropper } from '@trivie/ui-web/components/image-cropper'
import { Button } from '@trivie/ui-web/components/button'
import { BadgeCreateModelType } from '@trivie/core/src/models/base/badge'
import { Dialog } from '@trivie/ui-web/components/dialogs/dialog'

import { FileInput } from './upload-badge'

interface CreateEditBadgeProps {
  onClose: () => void
  open: boolean
  badge?: any
  onSaveBadge?: (value: BadgeCreateModelType) => void
}

export const CreateEditBadge = observer((props: CreateEditBadgeProps) => {
  const { onClose, open, badge, onSaveBadge } = props
  const [file, setFile] = useState<File | null>(null)
  const [imgSrc, setImgSrc] = useState('')
  const [title, setTitle] = useState('')
  const [desc, setDesc] = useState('')
  const [showTitleCharacterCount, setShowTitleCharacterCount] = useState(false)
  const [showDescCharacterCount, setShowDescCharacterCount] = useState(false)
  const [circularCrop, setCircularCrop] = useState(true)

  const [error, setError] = useState<null | string>(null)
  const cropperRef = useRef<any>(null)

  const [step, setStep] = useState(0)

  useEffect(() => {
    if (open) {
      if (badge) {
        setTitle(badge?.badge_title || '')
        setDesc(badge?.badge_criteria || '')
        setImgSrc(badge?.photo_url || '')
      } else {
        setTitle('')
        setDesc('')
        setFile(null)
        setImgSrc('')
      }
      setStep(0)
    }
  }, [open])

  const handleFile = async (f: File | null) => {
    setFile(f)
    const max5MB = 5 * 1000 * 1000
    if (!f) {
      setImgSrc('')
      return
    } else {
      const splittedName = f?.name.split('.')
      if (f.size > max5MB) {
        setError('Maximum file size exceeded. Please try again with a smaller file (5MB).')
        return
      } else if (
        !['jpeg', 'png', 'gif', 'jpg'].includes(
          splittedName[splittedName.length - 1].toLocaleLowerCase(),
        )
      ) {
        setError('This file must be a .png, .gif, or .jpg. Please try again.')
        return
      } else {
        setError(null)
      }
    }
    const reader = new FileReader()
    reader.addEventListener('load', () => {
      setImgSrc(reader.result?.toString() || '')
      setStep(1)
    })
    reader.readAsDataURL(f)
  }

  return (
    <Dialog
      title={badge ? 'Edit Badge' : 'Create Badge'}
      aria-labelledby="create-edit-badge-title"
      open={open}
      maxWidth="sm"
      onClose={onClose}
      dialogActionsProps={{ sx: { justifyContent: step === 1 ? 'space-between' : 'flex-end' } }}
      actions={
        <>
          {step === 1 ? (
            <Button
              color="info"
              text="Back"
              data-qat="dialog_footer_back"
              variant="text"
              onClick={() => setStep(0)}
              startIcon={<ChevronLeft />}
            />
          ) : null}
          <Button
            data-qat="dialog_footer_save"
            disabled={
              step === 0 && !(title && title.length <= 40 && desc && desc.length <= 150 && imgSrc)
            }
            onClick={async () => {
              if (step === 1) {
                const { blobUrl } = (await cropperRef?.current?.onCrop()) ?? {}
                setImgSrc(blobUrl)
                setStep(0)
              } else {
                if (onSaveBadge) {
                  onSaveBadge({ icon_url: imgSrc, label: title, description: desc })
                }
              }
            }}
            text="Save"
          />
        </>
      }
    >
      {step === 1 ? (
        file ? (
          <ImageCropper
            ref={cropperRef}
            imgSrc={imgSrc}
            file={file}
            circularCrop={circularCrop}
            setCircularCrop={setCircularCrop}
          />
        ) : null
      ) : (
        <>
          <FileInput
            accept="image/png;image/jpeg;image/gif"
            imgSrc={imgSrc}
            helperText="Max file size 5MB. Accepts .png, .gif, and .jpg"
            onChange={handleFile}
            error={error}
            style={{ borderRadius: circularCrop ? '50%' : 0 }}
            circularCrop={circularCrop}
          />
          <div style={{ marginTop: 24 }}>
            <Text text={'TITLE'} variant="body2Emphasized" style={{ color: color.trivieGrey }} />
            <TextField
              id="badge-title"
              placeholder="Enter a title for the badge"
              variant="standard"
              onFocus={() => setShowTitleCharacterCount(true)}
              onBlur={() => {
                setShowTitleCharacterCount(false)
              }}
              onChange={(e) => setTitle(e.target.value)}
              value={title}
              style={{ paddingTop: 16, marginBottom: 5, width: '100%' }}
            />
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'flex-end',
                visibility: showTitleCharacterCount ? 'visible' : 'hidden',
              }}
            >
              <Text
                variant="body2"
                style={{
                  color: title.length <= 40 ? color.green : color.red,
                }}
                text={`${title?.length || 0} / ${40} Characters`}
              />
            </div>
          </div>
          <div>
            <Text text={'CRITERIA'} variant="body2Emphasized" style={{ color: color.trivieGrey }} />
            <TextField
              id="badge-criteria"
              multiline
              placeholder="Enter a description of how users can earn this badge"
              variant="standard"
              onFocus={() => setShowDescCharacterCount(true)}
              onBlur={() => {
                setShowDescCharacterCount(false)
              }}
              onChange={(e) => setDesc(e.target.value)}
              value={desc}
              style={{ paddingTop: 16, width: '100%' }}
            />
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'flex-end',
                visibility: showDescCharacterCount ? 'visible' : 'hidden',
              }}
            >
              <Text
                variant="body2"
                style={{
                  color: desc.length <= 150 ? color.green : color.red,
                }}
                text={`${desc?.length || 0} / ${150} Characters`}
              />
            </div>
          </div>
        </>
      )}
    </Dialog>
  )
})
