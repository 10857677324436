import { types } from 'mobx-state-tree'
import { CommSettingsModel } from './CommSettingsModel'

export const UserSchemaModelBase = types.model('UserSchema').props({
  email: types.string,
  id: types.number,
  first_name: types.string,
  last_name: types.string,
  photo_url: types.string,
  user_comm_settings: CommSettingsModel,
  sounds_enabled: types.boolean,
  music_enabled: types.boolean,
  language: types.string,
  last_activity: types.string,
})
