import React, { forwardRef, useEffect, useState } from 'react'
import { Divider, Paper } from '@mui/material'
import { AddRounded, Delete, MailRounded } from '@mui/icons-material'
import { Admin, UserRole, useStores } from '@trivie/core'
import { ConfirmDialog } from '@trivie/ui-web/components/confirm-dialog'
import { DataTable } from '@trivie/ui-web/components/data-table'
import { Text } from '@trivie/ui-web/components/text'
import { Button } from '@trivie/ui-web/components/button'
import { observer } from 'mobx-react-lite'
import { GridCellParams } from '@mui/x-data-grid-pro'
import { NameCell, DropdownCell } from '@trivie/ui-web/components/table-cells'
import { Instance } from 'mobx-state-tree'
import { InviteUsers } from '@trivie/ui-web/components/invite-users'
import { initColumns } from '@trivie/ui-web/components/data-table/configure'
import { color } from '@trivie/ui-web/theme'
import { useConfirm } from '@trivie/ui-web/components/dialogs/confirmation-dialog'
import { Toolbar } from '@trivie/ui-web/components/table-toolbar'

export const AdminsCard = observer(
  forwardRef((props: any, ref: any) => {
    const { manageUserStore } = useStores()
    const {
      getAdmins,
      userProfile,
      resendInvitation,
      updateRoles,
      roles,
      admins,
      removeAdmins,
      enterpriseTier,
      importAdmins,
    } = manageUserStore
    const { id } = props

    const [selectedRows, setSelectedRows] = useState<any>([])
    const [showInviteUsers, setShowInviteUsers] = useState(false)

    const isPlural = selectedRows?.length > 1

    const [disableSave, setDisableSave] = useState<any>(true)

    const onCancel = () => {
      setDisableSave(true)
      admins?.forEach((admin) => admin.revertRoleChange())
    }

    const getUpdateList = () => {
      // update all other admins prior to own user first
      const updateAdmins = admins?.filter(
        (admin) =>
          admin.user_id !== userProfile?.id && admin.defaultRole?.id !== admin.selectedRole?.id,
      ) as any[]
      const userAdmin = admins?.find((a) => a.user_id === userProfile?.id)
      if (userAdmin && userAdmin?.defaultRole?.id !== userAdmin?.selectedRole?.id) {
        return [...updateAdmins, userAdmin]
      } else {
        return updateAdmins
      }
    }

    const save = (adminList: any[], count: number) => {
      // wait for each request to finish before updating another admin
      if (adminList && adminList[count] && count < adminList.length) {
        updateRoles(adminList[count].user_id, [adminList[count].selectedRole?.id]).then(() =>
          save(adminList, count + 1),
        )
      } else {
        setLoading(true)
        getAdmins().then(() => {
          setLoading(false)
        })
      }
    }

    const onSave = () => {
      setDisableSave(true)
      const adminList = getUpdateList()
      if (adminList.find((a: any) => a.selectedRole.name === 'Owner')) {
        setShowConfirmOwner(true)
      } else {
        save(adminList, 0)
      }

      setSelectedRows([])
    }

    const [loading, setLoading] = useState<boolean>(false)

    const handleRoleChange = (admin: Instance<typeof Admin>, role: any) => {
      setDisableSave(false)

      // if role to change is owner - replace owner from others with admin
      if (role.name === 'Owner') {
        admins?.forEach((a) => {
          if (admin.user_id !== a.user_id && a.selectedRole?.name === 'Owner') {
            a.updateRole(roles.find((r: Instance<typeof UserRole>) => r.name === 'Administrator'))
          }
        })
      }

      admin.updateRole(role)

      // ensure at least one owner
      const hasOwners = admins?.filter((a) => a.selectedRole?.name === 'Owner').length
      if (!hasOwners) {
        admin.revertRoleChange()
        setDisableSave(true)
      }
    }

    const handleCancel = () => {
      admins?.forEach((a) => {
        if (a.defaultRole?.name === 'Owner') {
          a.updateRole(roles.find((r: Instance<typeof UserRole>) => r.name === 'Owner'))
        }
        if (a.selectedRole?.name === 'Owner') {
          a.revertRoleChange()
        }
      })
    }

    const onConfirm = () => {
      save(getUpdateList(), 0)
    }

    useEffect(() => {
      setLoading(true)
      getAdmins().then(() => {
        setLoading(false)
      })
    }, [])

    const { columns, columnVisibilityModel } = initColumns([
      {
        field: 'display_name',
        headerName: 'Name',
        width: 322,
        renderCell: (params: GridCellParams) => (
          <NameCell url={`/people/users/${params.id}/overview`} params={params} />
        ),
      },
      {
        hide: !enterpriseTier,
        field: 'roles',
        headerName: 'Role',
        width: 175,
        data_type: 'str',
        renderCell: (params: GridCellParams) => {
          return (
            roles && (
              <DropdownCell
                disabled={
                  // cannot change owner if you aren't an owner
                  // cannot demote own user from owner without assigning to another user
                  (params.row.roles?.find((r: any) => r.name === 'Owner') &&
                    !roles?.find((r) => r.name === 'Owner')) ||
                  (roles?.find((r) => r.name === 'Owner') && userProfile?.id === params.row.user_id)
                }
                selected={params.row.selectedRole}
                params={params}
                handleChange={handleRoleChange}
                items={roles}
              />
            )
          )
        },
      },
      {
        field: 'last_activity',
        data_type: 'datetime',
        headerName: 'Last Active',
        width: 150,
      },
    ])

    const confirm = useConfirm()

    const getActions = () => {
      return [
        {
          label: 'Resend Invite',
          icon: MailRounded,
          onClick: confirmResend,
        },
        {
          label: 'Remove Admin',
          color: 'danger',
          icon: Delete,
          onClick: confirmRemove,
        },
      ]
    }

    const confirmResend = () => {
      confirm({
        title: 'Resend Invitations',
        confirmationMessage: `${selectedRows.length} admin${selectedRows.length === 1 ? '' : 's'} ${selectedRows.length === 1 ? 'has' : 'have'} been sent an invitation message.`,
        dialogText: `Are you sure you want to resend a welcome message to ${selectedRows.length} admin${selectedRows.length === 1 ? '' : 's'}?`,
      })
        .then(() => {
          resendInvitation(selectedRows)
          setSelectedRows([])
        })
        .catch(() => {})
    }

    const confirmRemove = () => {
      confirm({
        danger: true,
        title: 'Remove Administrator',
        confirmationMessage: `Administration privileges revoked.`,
        dialogText: `Are you sure you want to revoke administrator privileges for ${
          selectedRows.length
        } admin${isPlural ? 's' : ''}? Please note that their account${
          isPlural ? 's' : ''
        } will not be deleted.`,
      })
        .then(() => {
          removeAdmins(selectedRows).then(() => {
            setLoading(true)
            getAdmins().then(() => setLoading(false))
          })
          setSelectedRows([])
        })
        .catch(() => {})
    }

    const [showConfirmOwner, setShowConfirmOwner] = useState(false)
    const [tableActions, setTableActions] = useState<any>([
      {
        label: 'Resend Invite',
        icon: MailRounded,
        onClick: confirmResend,
      },
    ])
    const [additionalActions] = useState<any>([
      {
        label: 'Invite Admin',
        icon: <AddRounded htmlColor={color.shade70} />,
        onClick: () => setShowInviteUsers(true),
      },
    ])

    useEffect(() => {
      setTableActions(getActions())
    }, [selectedRows])

    const getOwnerMessage = () => {
      const user = admins?.find((a) => a?.selectedRole?.name === 'Owner')
      return `Are you sure you want to promote ${user?.display_name} to the Owner role? You can only have one Owner on each workspace, so your role will be changed to Administrator.`
    }

    const getOwnerConfirmMessage = () => {
      const user = admins?.find((a) => a?.selectedRole?.name === 'Owner')
      return `${user?.display_name} has been promoted to Owner. Your role is now Administrator.`
    }

    return (
      <Paper id={id} variant="outlined" elevation={0} style={{ marginBottom: 24 }} ref={ref}>
        {showInviteUsers && (
          <InviteUsers
            admin
            open={showInviteUsers}
            close={() => setShowInviteUsers(false)}
            setLoading={setLoading}
            onSave={() =>
              getAdmins().then(() => {
                setLoading(false)
              })
            }
          />
        )}
        <div style={TABLE_HEADER}>
          <Text variant="labelEmphasized" text="Admins" />
          <div style={{ display: 'flex', gap: 12 }}>
            <Button
              disabled={disableSave}
              color="info"
              variant="outlined"
              onClick={onCancel}
              text="Cancel"
            />
            <Button disabled={disableSave} text="Save Changes" onClick={onSave} />
          </div>
        </div>

        <Divider />

        <div style={TABLE}>
          {admins?.length ? (
            <DataTable
              sx={{ border: 'none' }}
              loading={loading}
              toolbar={
                <Toolbar
                  additionalActions={additionalActions}
                  selected={selectedRows}
                  actions={tableActions}
                />
              }
              hideActions
              searchPlaceholder="Search Admins"
              columns={columns.filter((c: any) => !c.hide) as any}
              getRowId={(row) => row.user_id}
              rows={loading ? ([] as any) : admins}
              onRowSelectionModelChange={(param) => setSelectedRows(param)}
              rowSelectionModel={selectedRows}
              style={{ border: 'none' }}
            />
          ) : null}
          <ConfirmDialog
            open={showConfirmOwner}
            title="Change Owner"
            message={getOwnerMessage()}
            confirmedMessage={getOwnerConfirmMessage()}
            confirmButton="Confirm"
            onConfirm={onConfirm}
            handleCancel={handleCancel}
            onClose={() => setShowConfirmOwner(false)}
          />
        </div>
      </Paper>
    )
  }),
)

const TABLE_HEADER: React.CSSProperties = {
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  paddingLeft: 20,
  paddingRight: 20,
  paddingTop: 15,
  paddingBottom: 15,
  alignItems: 'center',
}
const TABLE: React.CSSProperties = {
  display: 'flex',
  flexDirection: 'column',
  // marginTop: 16,
  // paddingLeft: 20,
  // paddingRight: 20,
}
