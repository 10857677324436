import React, { useEffect, useState } from 'react'
import { observer } from 'mobx-react-lite'
import { Box, IconButton, InputAdornment, TextField } from '@mui/material'
import { CheckCircle, RadioButtonUnchecked, Search } from '@mui/icons-material'

import { useStores } from '@trivie/core'
import { QuestionApi } from '@trivie/core/src/services/api-objects/QuestionApi'

import { Dialog } from '../dialogs/dialog'
import { color } from '../../theme'
import { Text } from '../text'

export interface AddQuestionsFromLibraryProps {
  open: boolean
  onClose: (selectedQuestions: any) => void
  existing?: any
}

export const AddQuestionsFromLibrary = observer((props: AddQuestionsFromLibraryProps) => {
  const { existing, open, onClose } = props
  const { questionStore } = useStores()
  const { getQuestionDetails } = questionStore
  const [availableQuestions, setAvailableQuestions] = useState<any>([])
  const [search, setSearch] = useState('')

  const [questions, setQuestions] = useState([])

  const handleSubmit = async () => {
    // TODO currently defaulted to Multiple Response, but in the future this will need to be read from the backend.
    const selectedQuestions = availableQuestions
      .filter((q: any) => q.selected)
      .map((q: any) => ({
        ...q,
        // question_type: 'Multiple Response',
      }))
    const detailPromises = selectedQuestions.map(async (q: any, index: number) => {
      const result = await getQuestionDetails(q.id)
      const choices = result.answers_summary.map((a: any) => {
        return {
          text: a.text,
          correct: a.correct,
        }
      })
      selectedQuestions[index] = {
        ...selectedQuestions[index],
        ...result,
        id: result.question_id,
        choices,
        is_locked: false,
        difficulty: selectedQuestions[index].difficulty ?? 0,
      }
    })

    await Promise.all(detailPromises)

    onClose(selectedQuestions)
  }

  const populateAvailableQuestions = () => {
    if (!availableQuestions.length) {
      QuestionApi.getAllQuestions(
        'questions',
        false,
        [{ field: 'question_text' }],
        'list_questions',
      ).then((resp) => {
        if (resp.ok) {
          const qList = [] as any
          const q = resp.data.rows.map((question) => {
            const question_text = question.columns.find(
              (column) => column.field === 'question_text',
            )
            qList.push({ id: question.id, question_text: question_text.value })
          })
          setQuestions(qList)
        }
      })
    } else {
      setAvailableQuestions(
        availableQuestions
          .filter((fq) => {
            let notFound = true
            existing.map((e) => {
              if (e.question_id === fq.id) {
                notFound = false
              }
            })
            return notFound
          })
          .map((ques: any) => ({ ...ques, selected: false })),
      )
    }
  }

  useEffect(() => {
    if (open) {
      setSearch('')
      populateAvailableQuestions()
    }
  }, [open])

  useEffect(() => {
    populateAvailableQuestions()
  }, [])

  useEffect(() => {
    setAvailableQuestions(questions ?? [])
  }, [questions])

  const handleQuestionSelected = (question_id: number, value: boolean) => {
    const updatedQuestions = availableQuestions.map((question: any) => {
      if (question.id === question_id) {
        return { ...question, selected: value }
      } else {
        return question
      }
    })
    setAvailableQuestions(updatedQuestions)
  }

  const filteredQuestions =
    availableQuestions?.filter((q: any) =>
      q.question_text.toLocaleLowerCase().includes(search.toLocaleLowerCase()),
    ) ?? []
  return (
    <Dialog
      title="Add Questions from Library"
      aria-labelledby="add questions from library dialog"
      open={open}
      onClose={() => onClose([])}
      onCancel={() => onClose([])}
      onConfirm={handleSubmit}
      disableConfirm={!availableQuestions.filter((q: any) => q.selected).length}
      maxWidth="md"
      confirmBtnLabel="Add Questions"
    >
      <TextField
        variant="standard"
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <Search htmlColor={color.palette.grey} />
            </InputAdornment>
          ),
        }}
        style={{ marginBottom: 32, width: '50%', paddingLeft: 10 }}
        id="standard-search"
        placeholder="Search All Questions"
        type="search"
        onChange={(e) => setSearch(e.target.value)}
        value={search}
      />
      <Box overflow="auto" height={600}>
        <div
          style={{
            display: 'flex',
            padding: '12px 16px',
            borderRadius: 10,
            backgroundColor: color.palette.paperBorder,
            width: '100%',
            marginBottom: 10,
          }}
        >
          <Text variant="body2Emphasized" text="All Questions" />
        </div>
        <div
          style={{
            display: 'flex',
            flex: 1,
            flexDirection: 'column',
            overflowY: 'auto',
            paddingLeft: 16,
            paddingRight: 16,
          }}
        >
          {filteredQuestions.map((q: any, index: number) => {
            const lastItem = index >= filteredQuestions.length - 1
            const b = lastItem ? '' : '1px solid'
            return (
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                  borderBottom: b,
                  borderBottomColor: color.shade10,
                }}
              >
                <Text variant="body1Medium" text={q.question_text} style={{ flex: 1 }} />
                <IconButton onClick={() => handleQuestionSelected(q.id, !q.selected)} size="large">
                  {q.selected ? (
                    <CheckCircle htmlColor={color.palette.blueLink} />
                  ) : (
                    <RadioButtonUnchecked />
                  )}
                </IconButton>
              </div>
            )
          })}
        </div>
        <div
          style={{
            display: 'flex',
            placeItems: 'flex-end',
            placeContent: 'flex-end',
            marginTop: 10,
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              paddingLeft: 10,
            }}
          >
            {availableQuestions.filter((q: any) => q.selected).length ? (
              <Text
                variant="body1Medium"
                text={`${
                  availableQuestions.filter((q: any) => q.selected).length
                } Questions Selected`}
                style={{ color: color.palette.grey }}
              />
            ) : (
              <div />
            )}
          </div>
        </div>
      </Box>
    </Dialog>
  )
})
