import React, { useState } from 'react'
import { FolderRounded } from '@mui/icons-material'
import { Box, Button, InputAdornment, Paper, TextField } from '@mui/material'
import { observer } from 'mobx-react-lite'
import { includes } from 'ramda'

import { useStores } from '@trivie/core'
import { Dialog } from '../dialogs/dialog'
import { Image } from '../image'
import { Text } from '../text'
import { color } from '../../theme'

export const AddToTopicDialog = observer((props: any) => {
  const { open, onClose, topics, quizIDs } = props
  const { topicsStore } = useStores()
  const { setQuizzesForTopic } = topicsStore
  const [selectedTopics, setSelectedTopics] = useState<any>([])
  const [topicsFilter, setTopicsFilter] = useState('')

  const onChange = (e: any) => {
    setTopicsFilter(e.target.value)
  }

  const select = (topic: any) => {
    if (!includes(topic, selectedTopics)) {
      setSelectedTopics([...selectedTopics, topic])
    }
  }

  const addToTopics = () => {
    const payload = {
      quizzes: quizIDs,
    }
    selectedTopics.forEach((topic: any) => {
      setQuizzesForTopic(topic.id, payload)
    })
    onClose()
  }

  return (
    <Dialog
      onClose={onClose}
      title="Add Quizzes to Topic"
      open={open}
      onCancel={onClose}
      onConfirm={addToTopics}
      disableConfirm={!selectedTopics.length}
      confirmBtnLabel="Add to Topic"
      maxWidth="sm"
    >
      <TextField
        id="search"
        size="small"
        variant="outlined"
        value={topicsFilter}
        placeholder="Search for a topic"
        fullWidth
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <FolderRounded style={{ fontSize: 16 }} htmlColor={color.palette.grey} />
            </InputAdornment>
          ),
        }}
        onChange={onChange}
      />
      <Box height={600}>
        {topicsFilter && (
          <Paper
            variant="outlined"
            style={{
              paddingTop: 12,
              paddingBottom: 12,
              paddingLeft: 16,
              paddingRight: 16,
            }}
          >
            {(topics ?? [])
              .filter((topic) => {
                return topic.topic_title.toLowerCase().includes(topicsFilter.toLowerCase())
              })
              .map((topic, index, data) => {
                return (
                  <Button
                    disableRipple
                    onClick={() => {
                      select(topic)
                      setTopicsFilter('')
                    }}
                    style={{
                      width: '100%',
                      padding: 0,
                      display: 'flex',
                      flexDirection: 'row',
                      alignItems: 'center',
                      textTransform: 'none',
                      justifyContent: 'flex-start',
                      marginBottom: index === data.length - 1 ? 0 : 24,
                    }}
                  >
                    <Image
                      style={{ marginRight: 20 }}
                      height="36px"
                      width="72px"
                      src={topic.photo_url}
                    />
                    <Text variant="body1Emphasized" text={topic.topic_title} />
                  </Button>
                )
              })}
          </Paper>
        )}
        {selectedTopics.length > 0 && !topicsFilter && (
          <Text
            variant="body1"
            my={3}
            text={`Selected Topics (${selectedTopics.length})`}
            style={{ color: color.shade70 }}
          />
        )}
        {!topicsFilter && selectedTopics.length > 0 && (
          <div style={{ maxHeight: 400, overflowY: 'scroll' }}>
            {selectedTopics
              .filter((topic: any) => {
                return topic.topic_title.toLowerCase().includes(topicsFilter.toLowerCase())
              })
              .map((topic: any) => {
                return (
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      alignItems: 'center',
                      marginBottom: 24,
                    }}
                  >
                    <Image
                      style={{ marginRight: 20 }}
                      height="36px"
                      width="72px"
                      src={topic.photo_url}
                    />

                    <Text
                      style={{ textTransform: 'none' }}
                      variant="body1Emphasized"
                      text={topic.topic_title}
                    />
                  </div>
                )
              })}
          </div>
        )}
      </Box>
    </Dialog>
  )
})
