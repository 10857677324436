import { Instance, types } from 'mobx-state-tree'

/**
 * UserLevelRead Model
 */
export const UserLevelReadModel = types.model('UserLevelRead').props({
  __typename: types.optional(types.literal('UserLevelRead'), 'UserLevelRead'),
  icon: types.maybeNull(types.string),
  id: types.identifierNumber,
  label: types.string,
  level: types.integer,
  max_points: types.integer,
  min_points: types.integer,
})

/* The TypeScript type of an instance of UserLevelReadModel */
export interface UserLevelReadModelType extends Instance<typeof UserLevelReadModel.Type> {}
