import React, { useEffect, useState } from 'react'

import { observer } from 'mobx-react-lite'
import { EditorContent, useEditor } from '@tiptap/react'
import StarterKit from '@tiptap/starter-kit'

import { useStores } from '@trivie/core'
import { Button } from '@trivie/ui-web/components/button'

import { CircularProgress, TextField } from '@mui/material'
import Placeholder from '@tiptap/extension-placeholder'

interface TextResourceTabProps {
  quiz: any
  standalone?: boolean
  topic?: any
  onUpdate?: any
  onSelect?: any
  selected?: any
}
export const TextResourceTab = observer((props: TextResourceTabProps) => {
  const { onSelect, quiz, standalone, topic } = props
  const { resourceStore } = useStores()
  const {
    setTextContent,
    textContent,
    setTextTitle,
    textTitle,
    uploadTextResource,
    getResourceDetails,
    getAvailableResources,
  } = resourceStore

  const editor = useEditor({
    extensions: [
      StarterKit.configure({ heading: { levels: [1, 2, 3] } }),
      Placeholder.configure({
        emptyEditorClass: 'is-editor-empty',
        placeholder: 'Paste in text to create a new source.',
      }),
    ],
    onUpdate: ({ editor, transaction }) => {
      setTextContent(editor.getJSON())
    },
    content: textContent ? textContent : '',
    editorProps: { attributes: { class: standalone ? 'StandaloneText' : 'EditTextResource' } },
  })

  useEffect(() => {
    if (editor) {
      setTimeout(() => {
        editor?.commands.setContent(textContent)
      }, 0)
    }
  }, [textContent])

  const [loading, setLoading] = useState(false)
  const submitTextResource = async () => {
    const text = editor?.getText() as string
    setLoading(true)
    const resourceID = await uploadTextResource(text, textTitle).then((id) => {
      if (!standalone) {
        setLoading(false)
      }
      setTextContent({})
      setTextTitle('')
      return id
    })

    const resourcesAfterAdd = await getAvailableResources()
    if (resourcesAfterAdd) {
      const addedResource = resourcesAfterAdd.find((r) => r.resource_id === resourceID)

      if (standalone && addedResource) {
        getResourceDetails(addedResource.resource_id).then((details) => {
          setLoading(false)
          addedResource.set('preview_url', details?.converted_pdf_url || details?.filename_or_url)
          if (topic) {
            topic.addResource(addedResource)
          } else if (quiz) {
            quiz.addResource(addedResource)
          }
          onSelect(addedResource)
        })
      } else if (addedResource) {
        getResourceDetails(addedResource.resource_id).then((details) => {
          setLoading(false)
          addedResource.set('preview_url', details?.converted_pdf_url || details?.filename_or_url)
          quiz.setMercuryResource(addedResource)
          quiz.addResource(addedResource)
        })
      }
    }
  }

  ///128 is max allowed length of Name field
  const cropText = (text) => {
    if (text?.length > 128) {
      setTextTitle(text.slice(0, 128))
    } else {
      setTextTitle(text)
    }
  }

  return quiz?.mercuryResource && !standalone ? null : (
    <div style={{ flexDirection: 'column', display: 'flex' }}>
      <TextField
        size="small"
        variant="outlined"
        placeholder="Name"
        onChange={(e) => cropText(e.target.value)}
        style={{ marginBottom: 12 }}
        inputProps={{ style: { backgroundColor: '#fff' }, borderRadius: 8 }}
        value={textTitle}
      />
      <EditorContent editor={editor} />
      {loading ? (
        <CircularProgress
          style={{ alignSelf: standalone ? 'flex-end' : 'center', marginTop: 12 }}
        />
      ) : standalone ? (
        <Button
          sx={{ marginTop: '16px !important', alignSelf: 'flex-end' }}
          disabled={!editor?.getText() || !textTitle}
          onClick={submitTextResource}
          text="Add Source"
        />
      ) : (
        <Button
          sx={{
            width: '185px',
            margin: '0 auto !important',
            display: 'flex',
            marginTop: '16px !important',
          }}
          disabled={!editor?.getText() || !textTitle}
          onClick={submitTextResource}
          size="small"
          text="Next"
        />
      )}
    </div>
  )
})
