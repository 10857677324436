import { types } from 'mobx-state-tree'
import { Webhook } from './Webhook'

export const Settings = types.model('Settings').props({
  // GDPR
  gdpr_enabled: types.boolean,

  // Branding
  display_cobranding: types.boolean,
  logo_path: types.maybeNull(types.string),
  company_name: types.maybeNull(types.string),
  whitelabel_logo_url: types.maybe(types.maybeNull(types.string)),
  whitelabel_name: types.maybe(types.maybeNull(types.string)),

  // Communication Settings
  push_enabled: types.boolean,
  email_enabled: types.boolean,
  adaptive_push_enabled: types.boolean,
  adaptive_email_enabled: types.boolean,
  assignment_completed_enabled: types.boolean,
  allow_change_name: types.boolean,
  allow_change_email: types.boolean,

  // Custom URL
  vanity_url: types.maybe(types.maybeNull(types.string)),

  // Hourly Warning
  hourly_warning: types.boolean,
  hourly_override: types.string,

  // Company Policy
  policy: types.boolean,
  policy_text: types.maybeNull(types.string),

  // Feature Flags
  learning_path_enabled: types.maybe(types.boolean),
  challenges_enabled: types.boolean,
  cooldown_required: types.boolean,
  feed_enabled: types.boolean,
  restrict_talk: types.boolean,
  user_levels_enabled: types.boolean,

  // Points Configuration
  points_badge: types.number,
  points_challenge: types.number,
  points_learn_standard: types.number,
  points_survey: types.number,
  points_post: types.number,
  points_reinforce_standard: types.number,
  points_reply: types.number,
  points_test_standard: types.number,

  // Sound & Music
  sounds_default: types.boolean,
  music_default: types.boolean,

  // User Leaderboard Settings
  user_leaderboard_alltime_enabled: types.boolean,
  user_leaderboard_daily_enabled: types.boolean,
  user_leaderboard_weekly_enabled: types.boolean,
  user_leaderboard_monthly_enabled: types.boolean,
  user_leaderboard_max_length: types.maybeNull(types.number),
  user_leaderboard_max_length_enabled: types.maybe(types.boolean),

  // Group Leaderboard Settings
  group_leaderboard_alltime_enabled: types.boolean,
  group_leaderboard_daily_enabled: types.boolean,
  group_leaderboard_weekly_enabled: types.boolean,
  group_leaderboard_monthly_enabled: types.boolean,
  group_leaderboard_categories: types.optional(types.array(types.string), []),
  group_leaderboard_pivot_category: types.maybeNull(types.string),

  // Reinforcemenet Settings
  reinforcement_question_limit: types.number,
  reinforcement_question_minimum: types.number,

  plan_tier: types.string,

  always_show_feedback: types.maybe(types.boolean),
  show_confirmation_popup: types.maybe(types.boolean),

  // Webhooks
  webhooks: types.maybeNull(Webhook),
})
