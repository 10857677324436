import { ApiResponse, ApisauceInstance } from 'apisauce'
import { handleResponse } from '../handleResponse'
import { WorkflowApi } from './WorkflowApi'
import { SurveyApi } from './SurveyApi'
import { AssignmentApi } from './AssignmentApi'
import { ContestApi } from './ContestApi'
import { GroupApi } from './GroupApi'
import { TopicApi } from './TopicApi'
import { UserApi } from './UserApi'
import { QuizApi } from './QuizApi'
import { QuestionApi } from './QuestionApi'
import { ResourceApi } from './ResourceApi'
import { TagApi } from './TagApi'
import { BadgeApi } from './BadgeApi'
import { MessagesApi } from './MessagesApi'

export const TableApi = {
  apisauce: {} as ApisauceInstance,

  /* Get custom view id for view name */
  getViewID: async (name: string) => {
    const response: ApiResponse<any> = await TableApi.apisauce.get(`/views/id?view_name=${name}`)
    return handleResponse(response)
  },

  /* Save data view */
  saveDataView: async (payload) => {
    const response: ApiResponse<any> = await TableApi.apisauce.post(`/views/custom_view/`, payload)
    return handleResponse(response)
  },

  /* Save custom data view */
  saveCustomDataView: async (payload, id) => {
    const response: ApiResponse<any> = await TableApi.apisauce.patch(`/views/custom/${id}`, payload)
    return handleResponse(response)
  },

  /* Get table view options*/
  getViewOptions: async (view_name: string) => {
    const response: ApiResponse<any> = await TableApi.apisauce.get(
      `views/view_options/?view_name=${view_name}`,
    )
    return handleResponse(response)
  },

  /* Get base table view options*/
  getBaseViews: async () => {
    const response: ApiResponse<any> = await TableApi.apisauce.get(`views/base_views/`)
    return handleResponse(response)
  },

  /* Get custom report details */
  getCustomReport: async (id: number) => {
    const response: ApiResponse<any> = await TableApi.apisauce.get(`views/custom/${id}`)
    return handleResponse(response)
  },

  /* Delete custom reports */
  deleteCustomReports: async (ids: number[]) => {
    const response: ApiResponse<any> = await TableApi.apisauce.delete(
      `views/custom/`,
      {},
      { data: { view_ids: ids } },
    )
    return handleResponse(response)
  },

  /* Create new custom report */
  saveCustomReport: async (payload: any) => {
    const response: ApiResponse<any> = await TableApi.apisauce.post(`views/custom/`, payload)

    return handleResponse(response)
  },

  // /* Edit a custom report */
  // editCustomReport: async (payload: any) => {
  //   const response: ApiResponse<any> = await TableApi.apisauce.patch(`views/custom/`, payload)
  //   return handleResponse(response)
  // },

  /* Email custom reports */
  emailCustomReports: async (view_ids: number[], emails: string[], user_ids: number[] = []) => {
    const response: ApiResponse<any> = await TableApi.apisauce.post(`views/custom/email`, {
      view_ids,
      emails,
      user_ids,
    })
    return handleResponse(response)
  },
  /* Grab table data / meta information */
  getTableData: async (parent, type, columns, viewName, id, filter) => {
    switch (type) {
      case 'paths':
        return await Promise.all([
          WorkflowApi.getPaths(parent, true, columns ?? [], viewName),
          WorkflowApi.getPaths(parent, false, columns ?? [], viewName),
        ])
      case 'surveys':
        return await Promise.all([
          SurveyApi.getAllSurveys(parent, true, columns ?? [], viewName, id, filter),
          SurveyApi.getAllSurveys(parent, false, columns ?? [], viewName, id, filter),
        ])
      case 'assignments':
        return await Promise.all([
          AssignmentApi.getAllAssignments(parent, true, columns ?? [], viewName, id, filter),
          AssignmentApi.getAllAssignments(parent, false, columns ?? [], viewName, id, filter),
        ])
      case 'contests':
        return await Promise.all([
          ContestApi.getAllContests(parent, true, columns ?? [], viewName, id),
          ContestApi.getAllContests(parent, false, columns ?? [], viewName, id),
        ])
      case 'groups':
        return await Promise.all([
          GroupApi.getAllGroups(parent, true, columns ?? [], viewName, id),
          GroupApi.getAllGroups(parent, false, columns ?? [], viewName, id),
        ])
      case 'topics':
        return await Promise.all([
          TopicApi.getAllTopics(parent, true, columns ?? [], viewName, id),
          TopicApi.getAllTopics(parent, false, columns ?? [], viewName, id),
        ])
      case 'users':
        return await Promise.all([
          UserApi.getUsers(parent, true, columns ?? [], viewName, id),
          UserApi.getUsers(parent, false, columns ?? [], viewName, id),
        ])
      case 'quiz_results':
        return await Promise.all([
          QuizApi.getResults(parent, true, columns ?? [], viewName, id),
          QuizApi.getResults(parent, false, columns ?? [], viewName, id),
        ])
      case 'user_results':
        return await Promise.all([
          UserApi.getResults(parent, true, columns ?? [], viewName, id),
          UserApi.getResults(parent, false, columns ?? [], viewName, id),
        ])
      case 'quizzes':
        return await Promise.all([
          QuizApi.getAllQuizzes(parent, true, columns ?? [], viewName, id),
          QuizApi.getAllQuizzes(parent, false, columns ?? [], viewName, id),
        ])
      case 'questions':
        return await Promise.all([
          QuestionApi.getAllQuestions(parent, true, columns ?? [], viewName, id),
          QuestionApi.getAllQuestions(parent, false, columns ?? [], viewName, id),
        ])
      case 'resources':
        return await Promise.all([
          ResourceApi.getAllResources(parent, true, columns ?? [], viewName, id),
          ResourceApi.getAllResources(parent, false, columns ?? [], viewName, id),
        ])
      case 'tags':
        return await Promise.all([
          TagApi.getAllTags(parent, true, columns ?? [], viewName, id),
          TagApi.getAllTags(parent, false, columns ?? [], viewName, id),
        ])
      case 'badges':
        return await Promise.all([
          BadgeApi.getAllBadges(parent, true, columns ?? [], viewName, id, filter),
          BadgeApi.getAllBadges(parent, false, columns ?? [], viewName, id, filter),
        ])
      case 'messages':
        return await Promise.all([
          MessagesApi.getAllMessages(parent, true, columns ?? [], viewName, id, filter),
          MessagesApi.getAllMessages(parent, false, columns ?? [], viewName, id, filter),
        ])
      default:
        return []
    }
  },
}
