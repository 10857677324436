import React from 'react'

import { Skeleton } from '@mui/material'

export interface ImageProps {
  src: string | null | undefined
  height?: string
  width?: string
  style?: React.CSSProperties
  imageStyle?: React.CSSProperties
  loading?: boolean
}

export function Image(props: ImageProps) {
  const { loading, imageStyle, src, height, width, style } = props

  return !loading ? (
    <div
      style={{
        borderRadius: '6px',
        height: height ?? '36px',
        width: width ?? '72px',
        overflow: 'hidden',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        objectFit: 'contain',
        ...style,
      }}
    >
      {src ? (
        <img
          src={src}
          style={{ height, width, minWidth: '100%', minHeight: '100%', ...imageStyle }}
        />
      ) : (
        <div style={{ height, width, minWidth: '100%', minHeight: '100%' }} />
      )}
    </div>
  ) : (
    <Skeleton variant="rectangular" style={{ borderRadius: 6 }} height={height} width={width} />
  )
}
