import { Instance } from 'mobx-state-tree'
import { Survey } from '../models/Survey'
import { SurveyApi } from '../services/api-objects/SurveyApi'

let rootStore

export class SurveyHelper {
  static setRootStore = (store: any) => {
    rootStore = store
  }

  static loadSurvey = async (surveyId: number) => {
    let newSurvey: Instance<typeof Survey> | null = null
    const getSurveyResponse = await SurveyApi.getSurvey(surveyId)

    const survey = getSurveyResponse.data
    if (getSurveyResponse.ok && survey) {
      newSurvey = Survey.create(survey)
    } else {
      throw new Error(`failed to get survey ${surveyId}`)
    }

    return newSurvey
  }

  static createNewSurvey = () => {
    return Survey.create({
      title: '',
      questions: [
        {
          choices: [{ text: '' }, { text: '' }, { text: '' }],
          question_type: 'single_choice',
        },
      ],
    })
  }

  static hasOneNonBlankQuestion = (survey: Instance<typeof Survey>) => {
    if (!survey.questions?.length) {
      return false
    }

    if (survey.questions.every((q) => !q.text)) {
      return false
    }

    return true
  }
}
