import { Instance, types } from 'mobx-state-tree'

/**
 * SurveyAssigneesUserModel Model
 */
export const SurveyAssigneesUserModel = types.model('SurveyAssigneesUser').props({
  user_id: types.number,
  display_name: types.string,
  email: types.string,
  photo_url: types.string,
})

/* The TypeScript type of an instance of SurveyAssigneesUserModel */
export interface SurveyAssigneesUserModelType
  extends Instance<typeof SurveyAssigneesUserModel.Type> {}
