import { types } from 'mobx-state-tree'
import { UserShortSchemaModel } from '../user/UserShortSchemaModel'

/**
 * FollowingMe Model
 */
export const FollowingMeModel = types.model('FollowingMe').props({
  __typename: types.optional(types.literal('FollowingMe'), 'FollowingMe'),
  followedUserId: types.maybeNull(types.integer),
  followingUser: types.late(() => UserShortSchemaModel),
  followingUserId: types.maybeNull(types.integer),
})
