import React from 'react'
import { Add } from '@mui/icons-material'
import { Header } from '@trivie/ui-web/components/header'
import { NavigationTabs } from '@trivie/ui-web/components/navigation-tabs'
import { observer } from 'mobx-react-lite'
import { Route, Switch, useHistory, useRouteMatch } from 'react-router-dom'
import { SurveyDetailsScreen } from './survey-details'
import { CompletedSurveysTab } from './tabs/surveys-completed-tab'
import { LiveSurveysTab } from './tabs/surveys-live-tab'
import { ScheduledSurveysTab } from './tabs/surveys-scheduled-tab'
import { UnscheduledSurveysTab } from './tabs/surveys-unscheduled-tab'
import { useStores } from '@trivie/core'

export const SurveysScreen: React.FunctionComponent = observer(() => {
  const history = useHistory()
  const { surveyStore, manageUserStore } = useStores()
  const { permissions } = manageUserStore

  const { params } = useRouteMatch()
  const { mainTab } = params as any

  const goToCreateSurvey = () => history.push('/content/survey/create')

  const primary = {
    disabled: !permissions?.permissions.some((p) => p.code === 'can_create_survey'),
    startIcon: <Add />,
    onClick: goToCreateSurvey,
    text: 'Create Survey',
  }

  return (
    <Switch>
      <Route exact path="/activities/surveys/:mainTab">
        <Header
          title={`${mainTab.charAt(0).toUpperCase() + mainTab.slice(1)} Surveys`}
          primaryAction={primary}
        />
        <NavigationTabs
          disabled={surveyStore.liveSurveys && surveyStore.liveSurveys?.total_survey_count === 0}
          tabList={[
            LiveSurveysTab,
            ScheduledSurveysTab,
            UnscheduledSurveysTab,
            CompletedSurveysTab,
          ]}
        />
      </Route>
      <Route exact path="/activities/surveys/:mainTab/:id/:tab">
        <SurveyDetailsScreen />
      </Route>
    </Switch>
  )
})
