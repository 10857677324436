import { calculateFileSize, isFileSupported } from '@trivie/core'
import React, { useState } from 'react'
import IconButton from '@mui/material/IconButton'
import CloseIcon from '@mui/icons-material/Close'
import { color } from '../../theme'
import { Text } from '../text'
// @ts-ignore
import { ReactComponent as FileIcon } from './FileIcon.svg'
// @ts-ignore
import { ReactComponent as FileFilledIcon } from './FileFilledIcon.svg'
import { AddPhotoAlternate } from '@mui/icons-material'

export interface FileInputProps {
  style?: React.CSSProperties
  file?: any
  mainText?: string
  helperText?: string
  onChange: (e: any) => void
  value?: any
  accept: string
  progress?: any
  showSize?: boolean
  contentStyle?: React.CSSProperties
  bodyComponent?: JSX.Element
  onCancel?: () => void
  error?: string | null
  disabled?: boolean
  onClose?: (e: any) => void
  isMobile?: boolean
  imgUpload?: boolean
  creatingQuestion?: boolean
}

const BASE: React.CSSProperties = {
  display: 'flex',
  backgroundColor: 'rgba(240, 242, 245, 0.5)',
  borderStyle: 'dashed',
  borderWidth: 1,
  borderColor: color.shade30,
  borderRadius: 10,
  paddingTop: 30,
  paddingBottom: 30,
  cursor: 'pointer',
  height: 99,
}

const BASE_FILE: React.CSSProperties = {
  display: 'flex',
  backgroundColor: 'rgba(0, 0, 0, 0.04)',
  borderWidth: 1,
  borderColor: color.shade30,
  borderRadius: 10,
  cursor: 'pointer',
  // height: 70,
  paddingLeft: '23px',
  alignItems: 'center',
  paddingTop: 8,
  paddingBottom: 8,
}

const ACCESSIBLE_HIDDEN_STYLE: React.CSSProperties = {
  clip: 'rect(1px, 1px, 1px, 1px)',
  clipPath: 'inset(50%)',
  height: 1,
  width: 1,
  margin: -1,
  overflow: 'hidden',
  padding: 0,
  position: 'absolute',
}

export const FileInput = React.forwardRef((props: FileInputProps, ref: any) => {
  const {
    style,
    contentStyle,
    onChange,
    helperText,
    mainText,
    file,
    showSize,
    accept,
    progress,
    bodyComponent,
    onCancel,
    error,
    disabled,
    onClose,
    isMobile,
    imgUpload,
    creatingQuestion,
  } = props

  const [fileTooLarge, setFileTooLarge] = useState(false)
  const [invalidFiletype, setInvalidFiletype] = useState(false)

  const handleFile = (files: FileList | null) => {
    if (disabled) {
      return
    }

    if (files && files[0]) {
      // use a regex to grab the characters after the last dot
      const re = /(?:\.([^.]+))?$/
      // @ts-ignore
      const extension = re.exec(files[0].name)[1]

      if (!isFileSupported(extension) || (accept && !accept.includes(files[0].type))) {
        setInvalidFiletype(true)
      } else if (files[0].size >= 100000000) {
        setFileTooLarge(true)
      } else {
        onChange(files ? files[0] : null)
        setFileTooLarge(false)
      }
    }
  }

  const addBreakOpportunities = (text: string) => {
    const words = text.split(' ')
    if (words.some((w) => w.length > 20)) {
      const segments = text.match(/.{1,20}/g)
      return segments?.flatMap((s, index) => {
        if (index + 1 > segments.length) {
          return [s]
        } else {
          return [s, <wbr />]
        }
      })
    } else {
      return text
    }
  }

  const hasFile = !!file

  return imgUpload ? (
    <label
      onDragOver={(e) => e.nativeEvent.preventDefault()}
      onDrop={(e) => {
        e.nativeEvent.preventDefault()
        handleFile(e.dataTransfer.files)
      }}
      style={{
        cursor: 'pointer',
      }}
      ref={ref}
    >
      <input
        disabled={Boolean(disabled)}
        type="file"
        style={{ ...ACCESSIBLE_HIDDEN_STYLE }}
        onChange={(e) => handleFile(e.target.files || null)}
        accept={accept}
      />
      {hasFile ? (
        <img
          style={{ objectFit: 'contain' }}
          src={URL.createObjectURL(file)}
          height="100"
          width="100"
          alt="updated company logo"
        />
      ) : (
        <>
          <div
            style={{
              width: 100,
              height: 100,
              borderRadius: 12,
              backgroundColor: '#D9D9D9',
              position: 'relative',
              border: `1px dashed ${color.shade30}`,
            }}
          >
            <AddPhotoAlternate
              htmlColor={color.shade50}
              style={{ position: 'absolute', fontSize: 24, left: 36, top: 40 }}
            />
          </div>
          {invalidFiletype ? (
            <Text
              variant="body2Medium"
              text="Invalid file type."
              style={{ color: color.palette.red, marginTop: 4 }}
            />
          ) : fileTooLarge ? (
            <Text
              variant="body2Medium"
              text="Files must be smaller than 100 MB"
              style={{ color: color.palette.red, marginTop: 4 }}
            />
          ) : (
            error && (
              <Text variant="body2Medium" text={error} style={{ color: color.palette.red }} />
            )
          )}
        </>
      )}
      <div style={{ display: 'flex', justifyContent: 'center' }}>
        <Text
          text="Upload Logo"
          style={{ textAlign: 'center', marginTop: 10, cursor: 'pointer', color: color.blue }}
          variant="body1Emphasized"
        />
      </div>
    </label>
  ) : (
    <label>
      {hasFile ? (
        <label style={{ ...BASE_FILE, ...style }}>
          <div>
            <FileFilledIcon />
          </div>
          <div
            style={{
              display: 'flex',
              flex: 1,
              flexDirection: 'column',
              flexWrap: 'nowrap',
              marginLeft: 18,
            }}
          >
            <Text
              variant="body1Emphasized"
              // @ts-ignore
              text={addBreakOpportunities(file!.name)}
              style={{ color: color.shade70 }}
            />

            {error ? (
              <Text variant="body2Medium" text={error} style={{ color: color.palette.red }} />
            ) : showSize ? (
              <Text
                variant="body2Medium"
                style={{ color: '#9098A3' }}
                text={`${calculateFileSize(file.size)}`}
              />
            ) : progress ? (
              <div style={{ display: 'flex', flexWrap: 'nowrap' }}>
                <Text
                  variant="body2Medium"
                  text={`${calculateFileSize(file.size)} - ${Math.round(
                    (progress.loaded / progress.total) * 100,
                  )}% - `}
                />
                <div onClick={() => onCancel && onCancel()}>
                  <Text variant="body2Emphasized" text="Cancel" style={{ cursor: 'pointer' }} />
                </div>
              </div>
            ) : null}
          </div>
          <IconButton
            aria-label="close"
            onClick={(e) => {
              onChange(null)
              setInvalidFiletype(false)
              e.preventDefault()
              e.stopPropagation()
            }}
            style={{ marginRight: 8 }}
            size="large"
          >
            <CloseIcon />
          </IconButton>
        </label>
      ) : (
        <>
          <label
            style={{ ...BASE, ...style }}
            onDragOver={(e) => e.nativeEvent.preventDefault()}
            onDrop={(e) => {
              e.nativeEvent.preventDefault()
              handleFile(e.dataTransfer.files)
            }}
            data-qat="file-input-label"
            ref={ref}
          >
            <input
              disabled={Boolean(disabled)}
              type="file"
              style={{ ...ACCESSIBLE_HIDDEN_STYLE }}
              onChange={(e) => handleFile(e.target.files || null)}
              data-qat="file-input"
              // accept={accept}
            />
            {creatingQuestion ? (
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  paddingLeft: 20,
                  paddingRight: 20,
                  flex: 1,
                  ...contentStyle,
                }}
              >
                <div style={{ flexDirection: 'column', alignItems: 'center', display: 'flex' }}>
                  <AddPhotoAlternate />
                  {invalidFiletype ? (
                    <Text
                      variant="body2Medium"
                      text="Invalid file type."
                      style={{ color: color.palette.red }}
                    />
                  ) : fileTooLarge ? (
                    <Text
                      variant="body2Medium"
                      text="Files must be smaller than 100 MB"
                      style={{ color: color.palette.red }}
                    />
                  ) : (
                    error && (
                      <Text
                        variant="body2Medium"
                        text={error}
                        style={{ color: color.palette.red }}
                      />
                    )
                  )}
                </div>
              </div>
            ) : (
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'flex-start',
                  paddingLeft: 20,
                  paddingRight: 20,
                  ...contentStyle,
                }}
              >
                <FileIcon />
                <div style={{ display: 'flex', flexDirection: 'column', marginLeft: 18 }}>
                  <div style={{ display: 'flex', flexWrap: 'nowrap', marginBottom: 4 }}>
                    {isMobile ? (
                      <Text
                        variant="body1Medium"
                        text={`Tap here to upload an ${mainText}.`}
                        style={{ color: color.shade70 }}
                      />
                    ) : (
                      <>
                        <Text
                          variant="body1Medium"
                          text="Upload"
                          style={{
                            textDecoration: 'underline',
                            marginRight: 4,
                            color: color.shade70,
                          }}
                        />
                        <Text
                          variant="body1Medium"
                          text={mainText ?? 'or drag an image here to replace'}
                          style={{ color: color.shade70, marginRight: 4 }}
                        />
                        {Boolean(onClose) && (
                          <Text
                            // @ts-ignore
                            onClick={onClose}
                            variant="body1Medium"
                            text="Cancel."
                            style={{
                              color: color.shade70,
                              cursor: 'pointer',
                              textDecoration: 'underline',
                            }}
                          />
                        )}
                      </>
                    )}
                  </div>
                  {bodyComponent ? bodyComponent : null}
                  {!error && helperText && (
                    <Text
                      variant="body2Medium"
                      text={helperText}
                      style={{ color: color.shade70 }}
                    />
                  )}
                  {invalidFiletype ? (
                    <Text
                      variant="body2Medium"
                      text="Invalid file type."
                      style={{ color: color.palette.red, marginTop: 4 }}
                    />
                  ) : fileTooLarge ? (
                    <Text
                      variant="body2Medium"
                      text="Files must be smaller than 100 MB"
                      style={{ color: color.palette.red, marginTop: 4 }}
                    />
                  ) : (
                    error && (
                      <Text
                        variant="body2Medium"
                        text={error}
                        style={{ color: color.palette.red }}
                      />
                    )
                  )}
                </div>
              </div>
            )}
          </label>
        </>
      )}
    </label>
  )
})
