import { palette } from './palette'

export const color = {
  blue: '#1b59f8',
  black: '#000000',
  white: '#ffffff',
  background: '#f7f7f7',
  grey: '#F6F8FB',
  lightGrey: '#DADADA',
  darkGrey: '#444444',
  darkGrey_2: '#70768C',
  darkenGrey: '#737373',
  trivieGrey: '#9098A3',
  orange: '#FBA928',
  systemBlue: '#5F91F1',
  blueGrey: '#F3F5FB',
  blueSteel: '#147CE0',
  transparent: 'rgba(0, 0, 0, 0)',
  offWhite: '#f7f7f7',
  quizBackground: palette.charcoal,
  blueDisabled: 'rgb(34,95,246, 0.4)',
  /**
   * Company Colors
   */
  blueLink: '#1B59F8',
  green: '#1FE08F',
  lightGreen: '#1EE08F',
  yellow: '#FFCE51',
  pumpkin: '#FF7324',
  red: '#FF3E13',
  purple: '#551BFA',
  lightPurple: '#9116F9',
  companyColors: ['#1B59F8', '#1FE08F', '#FFCE51', '#FF7324', '#FF3E13', '#551BFA'],

  /**
   * A transparent shade to darken the color behind an element
   */
  shade5: 'rgba(0, 0, 0, 0.05)',
  shade10: 'rgba(0, 0, 0, 0.1)',
  shade20: 'rgba(0, 0, 0, 0.2)',
  shade25: 'rgba(0, 0, 0, 0.25)',
  shade30: 'rgba(0, 0, 0, 0.3)',
  shade40: 'rgba(0, 0, 0, 0.4)',
  shade50: 'rgba(0, 0, 0, 0.5)',
  shade60: 'rgba(0, 0, 0, 0.6)',
  shade70: 'rgba(0, 0, 0, 0.7)',
  shade80: 'rgba(0, 0, 0, 0.8)',
  shade90: 'rgba(0, 0, 0, 0.9)',

  /**
   * A transparent shade to whiten the color behind an element
   */
  white10: 'rgba(255, 255, 255, 0.1)',
  white50: 'rgba(255, 255, 255, 0.5)',
  white70: 'rgba(255, 255, 255, 0.7)',
  /**
   * A subtle color used for borders and lines.
   */
  border: '#EDEFF1',

  palette,
}
