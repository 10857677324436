import React, { useState, useEffect } from 'react'
import { Stack, TextField } from '@mui/material'

import { Dialog } from '../dialogs/dialog'
import { Text } from '../text'
import { DateTime } from 'luxon'
import { color } from '../../theme'

export function ExtendSurvey(props: any) {
  const { open, onClose, onExtend, end } = props
  const [endDate, setEndDate] = useState(DateTime.fromISO(end).toFormat('yyyy-MM-dd'))
  const [endTime, setEndTime] = useState('')
  const [formattedDate, setFormattedDate] = useState('')

  useEffect(() => {
    setEndTime(DateTime.fromISO(end).toLocaleString(DateTime.TIME_24_SIMPLE))
  }, [])

  const [showConfirm, setShowConfirm] = useState(false)
  const handleExtend = () => {
    onExtend(DateTime.fromFormat(`${endDate} ${endTime}`, 'yyyy-MM-dd hh:mm').toUTC().toISO())
    setFormattedDate(
      `${DateTime.fromFormat(`${endDate}`, 'yyyy-MM-dd').toFormat(
        'M/dd/yyyy,',
      )} ${DateTime.fromFormat(`${endTime}`, 'hh:mm').toLocaleString(DateTime.TIME_SIMPLE)}`,
    )
    setShowConfirm(true)
  }

  const DateNow = DateTime.local()
  const selectedDate = DateTime.fromFormat(endDate, 'yyyy-MM-dd')
  const diff = selectedDate.diff(DateNow, ['years', 'months', 'days', 'hours']).toMillis()

  return (
    <Dialog
      maxWidth="xs"
      onCancel={!showConfirm ? onClose : undefined}
      onConfirm={!showConfirm ? handleExtend : onClose}
      disableConfirm={diff < 0}
      confirmBtnLabel={!showConfirm ? 'Extend Survey' : 'Done'}
      onClose={onClose}
      title={showConfirm ? 'Success!' : 'Extend Survey'}
      open={open}
    >
      {showConfirm ? (
        <Text
          style={{ textAlign: 'center' }}
          variant="body1"
          text={`Your survey has been extended until ${formattedDate} (${
            Intl.DateTimeFormat().resolvedOptions().timeZone
          })`}
        />
      ) : (
        <>
          <Text
            variant="body1"
            text="End Date"
            style={{ marginBottom: 12, color: diff < 0 ? color.palette.red : color.palette.black }}
          />
          <Stack alignItems="center" direction="row" useFlexGap gap={1}>
            <TextField
              variant="outlined"
              helperText={diff < 0 ? 'Must be a future date. ' : null}
              error={diff < 0}
              size="small"
              type="date"
              {...{
                inputProps: {
                  min: DateTime.local().plus({ days: 1 }).toFormat('yyyy-MM-dd'),
                  max: DateTime.local().plus({ days: 730 }).toFormat('yyyy-MM-dd'),
                },
              }}
              onChange={(e) => {
                setEndDate(e.target.value)
              }}
              value={endDate ? endDate : DateTime.local().plus({ days: 1 }).toFormat('yyyy-MM-dd')}
            />
            <TextField
              type="time"
              size="small"
              variant="outlined"
              onChange={(e) => setEndTime(e.target.value)}
              value={endTime ? endTime : DateTime.now().toLocaleString(DateTime.TIME_24_SIMPLE)}
            />
            <Text variant="body1" text={Intl.DateTimeFormat().resolvedOptions().timeZone} />
          </Stack>
        </>
      )}
    </Dialog>
  )
}
