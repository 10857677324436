import React, { useState } from 'react'
import { Circle, Close } from '@mui/icons-material'
import { IconButton } from '@mui/material'
import { observer } from 'mobx-react-lite'
import { Instance, getNodeId } from 'mobx-state-tree'

import { Topic, useStores } from '@trivie/core'
import { Text } from '@trivie/ui-web/components/text'
import { color } from '@trivie/ui-web/theme'
import { ConfirmDialog } from '@trivie/ui-web/components/confirm-dialog'

export const ContentImportSidebar = observer(() => {
  const { contentCreationStore } = useStores()
  const { topics, removeTopicFromImport, selectTopic, selectedTopic } = contentCreationStore

  const [removingTopic, setRemovingTopic] = useState<null | Instance<typeof Topic>>()

  const handleRemove = () => {
    if (removingTopic) {
      removeTopicFromImport(removingTopic)
      setRemovingTopic(null)
    }
  }

  if (!topics) {
    return null
  }

  return (
    <div style={ROOT}>
      <div style={CONTAINER}>
        <div style={SIDEBAR_HEADER}>
          <Text variant="body1Emphasized" style={{ color: color.shade80 }}>
            Topics
          </Text>
        </div>
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          {(topics ?? ([] as Array<Instance<typeof Topic>>)).map((topic, idx) => {
            return (
              <div
                onClick={() => selectTopic(topic)}
                className="sidebar-question"
                key={getNodeId(topic)}
                style={{
                  ...TOPIC_ROW,
                  backgroundColor: '#F8FAFD',
                  display: 'flex',
                  background:
                    topic === selectedTopic
                      ? 'linear-gradient(81.39deg, #E4EBFF -188.61%, #ECE9FF 93.43%)'
                      : 'none',
                }}
              >
                <div style={TOPIC_ROW_CONTENT}>
                  <div style={TOPIC_ROW_CONTAINER}>
                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                      <Text
                        style={{ ...TITLE, fontStyle: !topic?.title ? 'italic' : 'normal' }}
                        text={topic?.title ?? 'No Title'}
                      />
                      <Text
                        style={{ color: color.shade60, fontStyle: 'italic' }}
                        variant="body2"
                        text={`${topic?.quizzes?.length} Quizzes`}
                      />
                    </div>

                    {topic?.approved ? (
                      <Circle
                        style={{ marginRight: 8, fontSize: 8, alignSelf: 'center' }}
                        htmlColor={color.green}
                      />
                    ) : topic?.allErrors.length || topic?.quizzes?.length === 0 ? (
                      <Circle
                        htmlColor={color.red}
                        style={{ marginRight: 8, fontSize: 8, alignSelf: 'center' }}
                      />
                    ) : topic?.allWarnings.length || !topic?.approved ? (
                      <Circle
                        htmlColor={color.yellow}
                        style={{ marginRight: 8, fontSize: 8, alignSelf: 'center' }}
                      />
                    ) : (
                      <Circle
                        style={{ marginRight: 8, fontSize: 8, alignSelf: 'center' }}
                        htmlColor={color.green}
                      />
                    )}
                    {topics && topics.length > 1 && (
                      <IconButton
                        onClick={(e) => {
                          e.stopPropagation()
                          setRemovingTopic(topic)
                        }}
                      >
                        <Close style={{ color: color.shade30 }} />
                      </IconButton>
                    )}
                  </div>
                </div>
              </div>
            )
          })}
        </div>
      </div>
      {removingTopic && (
        <ConfirmDialog
          danger
          open={Boolean(removingTopic)}
          title="Remove Topic"
          message={`Are you sure you want to remove this topic?`}
          confirmedMessage="Topic has been removed."
          confirmButton="Confirm"
          onConfirm={handleRemove}
          onClose={() => setRemovingTopic(null)}
          handleCancel={() => setRemovingTopic(null)}
        />
      )}
    </div>
  )
})

const ROOT: React.CSSProperties = {
  background: '#F8FAFD',
  borderRight: `1px solid ${color.shade5}`,
  maxWidth: 340,
  minWidth: 340,
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
}
const CONTAINER: React.CSSProperties = {
  flex: 1,
  overflowY: 'auto',
}
const SIDEBAR_HEADER: React.CSSProperties = {
  paddingLeft: 24,
  paddingRight: 24,
  paddingTop: 24,
  paddingBottom: 24,
  flexDirection: 'row',
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  borderBottom: `1px solid #EFF0F6`,
}
const TOPIC_ROW: React.CSSProperties = {
  position: 'relative',
  cursor: 'pointer',
  width: '100%',
  alignItems: 'center',
  justifyContent: 'space-between',
  padding: 16,
  paddingLeft: 24,
  paddingRight: 24,
  borderBottom: `1px solid #EFF0F6`,
  minHeight: 50,
  backgroundColor: color.white,
}
const TOPIC_ROW_CONTENT: React.CSSProperties = {
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  width: '100%',
}
const TOPIC_ROW_CONTAINER: React.CSSProperties = {
  display: 'flex',
  alignItems: 'center',
  flex: 1,
  position: 'relative',
  justifyContent: 'space-between',
}
const TITLE: React.CSSProperties = {
  textAlign: 'left',
  fontWeight: 400,
  fontSize: 14,
  lineHeight: '17px',
  overflowWrap: 'break-word',
  userSelect: 'none',
  display: '-webkit-box',
  WebkitLineClamp: 2,
  WebkitBoxOrient: 'vertical',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  width: 255,
  paddingRight: 12,
  marginBottom: 4,
}
