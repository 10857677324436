// This file was created from file-input.tsx because it was too clustered for the changes needed
// TODO: merge this with file-input.tsx
import { calculateFileSize } from '@trivie/core'
import React from 'react'
import IconButton from '@mui/material/IconButton'
import CloseIcon from '@mui/icons-material/Close'
import { color } from '../../theme'
import { Text } from '../text'
// @ts-ignore
import { ReactComponent as FileIcon } from './FileIcon.svg'
// @ts-ignore
import { ReactComponent as FileFilledIcon } from './FileFilledIcon.svg'
import { AddPhotoAlternate } from '@mui/icons-material'

export interface ImageFileInputProps {
  style?: React.CSSProperties
  file?: any
  mainText?: string
  helperText?: string
  onChange: (f: File) => void
  onBrowseClick: () => void
  value?: any
  accept: string
  progress?: any
  showSize?: boolean
  minimal?: boolean
  error?: string | null
  contentStyle?: React.CSSProperties
  mini?: boolean
}

const BASE: React.CSSProperties = {
  display: 'flex',
  backgroundColor: '#F0F2F580',
  borderStyle: 'dashed',
  borderWidth: 1,
  borderColor: color.shade30,
  borderRadius: 10,
  paddingTop: 30,
  paddingBottom: 30,
  height: 200,
}

const BASE_FILE: React.CSSProperties = {
  display: 'flex',
  backgroundColor: 'rgba(0, 0, 0, 0.04)',
  borderWidth: 1,
  borderColor: color.shade30,
  borderRadius: 10,
  height: 70,
  width: '70%',
  paddingLeft: '23px',
  alignItems: 'center',
}

const ACCESSIBLE_HIDDEN_STYLE: React.CSSProperties = {
  position: 'absolute',
  top: 0,
  width: '100%',
  opacity: 0,
  cursor: 'pointer',
}

export const ImageFileInput = React.forwardRef((props: ImageFileInputProps, ref: any) => {
  const {
    style,
    minimal,
    contentStyle,
    onChange,
    onBrowseClick,
    helperText,
    mainText,
    file,
    showSize,
    accept,
    progress,
    error,
    mini,
  } = props

  const handleFile = (files: FileList) => {
    onChange(files[0])
  }

  const hasFile = !!file

  const fileClickHandler = (e: any) => {
    const fileInputClicked = e?.target?.id === 'file-input'
    const browsePhotosClicked = e?.target?.id === 'browse-photos'

    if (!fileInputClicked) {
      if (browsePhotosClicked) {
        onBrowseClick()

        e.preventDefault()
        e.stopPropagation()
      }
    }
  }

  return (
    <label>
      {hasFile && !minimal ? (
        <label style={{ ...BASE_FILE, ...style }}>
          <div>
            <FileFilledIcon />
          </div>
          <div
            style={{
              display: 'flex',
              flex: 1,
              flexDirection: 'column',
              flexWrap: 'nowrap',
              marginLeft: 18,
            }}
          >
            <Text variant="body1Emphasized" text={file!.name} style={{ color: color.shade70 }} />
            {error ? (
              <Text variant="body2Medium" text={error} style={{ color: color.palette.red }} />
            ) : showSize ? (
              <Text
                variant="body2Medium"
                style={{ color: '#9098A3' }}
                text={`${calculateFileSize(file.size)}`}
              />
            ) : (
              progress && (
                <Text
                  variant="body2Medium"
                  text={`${calculateFileSize(file.size)} - ${progress.loaded} - ${progress.total}`}
                />
              )
            )}
          </div>
          <IconButton
            aria-label="close"
            onClick={(e) => {
              onChange(null)
              e.preventDefault()
              e.stopPropagation()
            }}
            size="large"
          >
            <CloseIcon />
          </IconButton>
        </label>
      ) : (
        <>
          <label
            onClickCapture={fileClickHandler}
            style={{ ...BASE, ...style }}
            onDrop={(e) => handleFile(e.dataTransfer.files)}
            ref={ref}
          >
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'flex-start',
                flexDirection: 'column',
                alignSelf: 'center',
                flex: 1,
                cursor: mini ? 'pointer' : 'default',
                ...contentStyle,
              }}
            >
              <AddPhotoAlternate
                style={{
                  marginBottom: mini ? 0 : 21,
                  color: color.shade50,
                  fontSize: mini ? 32 : 40,
                }}
              />
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  marginLeft: 0,
                }}
              >
                {!minimal && !mini && (
                  <div
                    style={{
                      display: 'flex',
                      flexWrap: 'nowrap',
                      justifyContent: 'center',
                      marginBottom: 4,
                    }}
                  >
                    <div style={{ position: 'relative' }}>
                      <Text
                        variant="body1Medium"
                        text="Upload,"
                        style={{
                          textAlign: 'center',
                          textDecoration: 'underline',
                          marginRight: 4,
                          color: color.shade50,
                          cursor: 'pointer',
                        }}
                      />
                      <input
                        id="file-input"
                        type="file"
                        style={{ ...ACCESSIBLE_HIDDEN_STYLE }}
                        onChange={(e) => {
                          handleFile(e.target.files || ([] as any))
                        }}
                        accept={accept}
                      />
                    </div>
                    <Text
                      variant="body1Medium"
                      text={mainText ?? ' Drag or '}
                      style={{
                        textAlign: 'center',
                        color: color.shade50,
                      }}
                    />
                    <Text
                      id="browse-photos"
                      variant="body1Medium"
                      text="Browse"
                      style={{
                        textAlign: 'center',
                        textDecoration: 'underline',
                        marginLeft: 4,
                        color: color.shade50,
                        cursor: 'pointer',
                      }}
                    />
                  </div>
                )}
                {!error && helperText && !mini && (
                  <Text
                    variant="body2Medium"
                    text={helperText}
                    style={{
                      textAlign: 'center',
                      color: color.shade50,
                    }}
                  />
                )}
                {error && !mini && (
                  <Text
                    variant="body2Medium"
                    text={error}
                    style={{
                      textAlign: 'center',
                      color: color.palette.red,
                    }}
                  />
                )}
              </div>
            </div>
          </label>
        </>
      )}
    </label>
  )
})
