import { Instance, types } from 'mobx-state-tree'

/**
 * SurveyAssigneesGroupModel Model
 */
export const SurveyAssigneesGroupModel = types.model('SurveyAssigneesGroup').props({
  group_id: types.number,
  label: types.string,
  number_users: types.number,
})

/* The TypeScript type of an instance of SurveyAssigneesGroupModel */
export interface SurveyAssigneesGroupModelType
  extends Instance<typeof SurveyAssigneesGroupModel.Type> {}
