import { SnapshotIn, cast, types } from 'mobx-state-tree'

export const ContentMetadata = types.model('QuizMetadata').props({
  assignment_id: types.number,
  content_user_id: types.maybeNull(types.number),
  path_id: types.maybeNull(types.number),
  quiz_id: types.maybeNull(types.number),
  topic_id: types.maybeNull(types.number),
})

export const QuizResource = types.model({
  resource_id: types.number,
  title: types.string,
  url: types.maybe(types.maybeNull(types.string)),
  mime_type: types.maybeNull(types.string),
  extension: types.maybeNull(types.string),
  is_required: types.maybe(types.boolean),
  is_recommended: types.maybe(types.boolean),
  last_viewed: types.maybe(types.maybeNull(types.string)),
  metadata: ContentMetadata,
})

export const AssignedQuiz = types
  .model('AssignedQuiz')
  .props({
    resources: types.maybeNull(types.array(QuizResource)),
    assignment_id: types.number,
    best_score: types.maybeNull(types.number),
    completed_attempts: types.maybeNull(types.number),
    due_date: types.maybeNull(types.string),
    end_date: types.maybeNull(types.string),
    is_locked: types.maybeNull(types.boolean),
    is_reinforcement: types.maybeNull(types.boolean),
    knowledge_pct: types.maybeNull(types.number),
    locked_by_pre_test: types.maybeNull(types.boolean),
    last_activity: types.maybeNull(types.string),
    max_attempts: types.maybeNull(types.number),
    medal_level: types.maybeNull(types.string),
    number_questions: types.number,
    pack_color: types.maybeNull(types.string),
    quiz_id: types.number,
    quiz_status: types.maybeNull(types.string),
    title: types.string,
    topic_id: types.maybeNull(types.number),
    topic_image: types.maybeNull(types.string),
    topic_title: types.maybeNull(types.string),
    unlocks_at: types.maybeNull(types.string),
    adaptive: types.maybe(types.boolean),
    photo_url: types.maybeNull(types.string),
    unlocks_after: types.maybe(types.string),
    prerequisite_quiz_id: types.maybeNull(types.number),
    quiz_locked: types.maybe(types.boolean),
    metadata: ContentMetadata,
  })
  .actions((self) => {
    return {
      set<K extends keyof SnapshotIn<typeof self>, T extends SnapshotIn<typeof self>>(
        key: K,
        value: T[K],
      ) {
        self[key] = cast(value)
      },
    }
  })

const PreQuizStats = types.model('QuizStats').props({
  knowledge_gain: types.maybeNull(types.number),
  best_score: types.maybeNull(types.number),
})

export const QuizResult = types.model('QuizResult').props({
  result_id: types.number,
  number_of_questions: types.number,
  number_correct: types.number,
  date_taken: types.string,
  medal_level: types.maybeNull(types.string),
})

export const HistoryResult = types.model('HistoryResult').props({
  quiz_id: types.number,
  result_id: types.number,
  date_finished: types.string,
  title: types.string,
  is_reinforcement: types.boolean,
  is_test: types.boolean,
  stars: types.maybe(types.number),
  topic_id: types.maybeNull(types.number),
  topic_image: types.string,
  questions_total: types.number,
  questions_correct: types.number,
  pack_color: types.maybeNull(types.string),
})

export const PreQuiz = types.model('PreQuiz').props({
  average_score: types.maybeNull(types.number),
  description: types.maybeNull(types.string),
  completed_attempts: types.number,
  current_recall: types.maybeNull(types.number),
  end_date: types.maybeNull(types.string),
  due_date: types.maybeNull(types.string),
  history: types.array(QuizResult),
  locked_by_pretest: types.boolean,
  locked_until: types.maybeNull(types.string),
  max_attempts: types.maybeNull(types.number),
  number_of_questions: types.number,
  pre_test_quiz_id: types.maybeNull(types.number),
  quiz_status: types.string,
  resources: types.array(QuizResource),
  stats: PreQuizStats,
  title: types.string,
  difficulty: types.maybe(types.number),
  medal_level: types.maybeNull(types.string),
  best_score: types.maybe(types.number),
  photo_url: types.maybe(types.string),
})
