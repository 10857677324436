import { types } from 'mobx-state-tree'

export const UserCertification = types.model('UserCertificationMedals').props({
  certification_id: types.number,
  criteria: types.string,
  level: types.string,
  recall: types.maybeNull(types.number),
  title: types.string,
})

export const UserMedal = types.model('UserCertificationMedals').props({
  medal_id: types.maybeNull(types.number),
  user_medal_id: types.number,
  criteria: types.string,
  level: types.string,
  recall: types.maybeNull(types.number),
  title: types.string,
})

export const UserCertificationMedals = types.model('UserCertificationMedals').props({
  certifications: types.array(UserCertification),
  medals: types.array(UserMedal),
})

export const UserCertificationDetail = types.model('UserCertificationDetail').props({
  topic_id: types.number,
  criteria_met: types.string,
  datetime_awarded: types.string,
  recall: types.maybeNull(types.number),
  title: types.string,
})

export const MedalDetailResponse = types.model('MedalDetailResponse').props({
  id: types.number,
  name: types.string,
  threshold: types.number,
})

export const UserMedalDetail = types.model('UserMedalDetail').props({
  active_assignment_id: types.maybeNull(types.number),
  date_earned: types.string,
  medal_level: types.string,
  original_assignment_id: types.maybeNull(types.number),
  quiz_id: types.number,
  quiz_title: types.string,
  recall: types.maybeNull(types.number),
  result_id: types.number,
  threshold: types.number,
  user_medal_id: types.number,
})
