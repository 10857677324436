import React, { useEffect, useState } from 'react'
import { Route, Switch, useHistory } from 'react-router-dom'
import { DateTime } from 'luxon'
import { observer } from 'mobx-react-lite'

import { Search } from '@mui/icons-material'
import { CircularProgress, Divider, TextField } from '@mui/material'

import { useStores } from '@trivie/core'
import { Header } from '@trivie/ui-web/components/header'
import { color } from '@trivie/ui-web/theme'
import { QuizApi } from '@trivie/core/src/services/api-objects'
import { ConfirmDialog } from '@trivie/ui-web/components/confirm-dialog'

import { NewQuizCard, QuizCard } from './quiz-card/quiz-card'
import { QuizzesDetailsScreen } from './quizzes-details-screen'

export const LibraryScreen: React.FunctionComponent = observer(() => {
  const { chatStore, manageQuizStore, contentCreationStore } = useStores()
  const { resetContentStore } = contentCreationStore
  const { deleteQuizzes, exportQuizzes, checkDeleteQuizzes } = manageQuizStore

  const [quizzes, setQuizzes] = useState([])
  const [loading, setLoading] = useState(true)
  const [showConfirmDelete, setShowConfirmDelete] = useState(false)
  const [deleteCheck, setDeleteCheck] = useState<any>()
  const [quizToDelete, setQuizToDelete] = useState<any>([])
  const [searchText, setSearchText] = useState('')

  const history = useHistory()

  const selectQuiz = (id) => {
    history.push(`/content/quizzes/${id}/overview`)
  }

  const editQuiz = (id) => {
    resetContentStore()
    history.push(`/content/creation/quiz/${Number(id)}/edit`)
  }

  const createQuiz = (generate?: boolean) => {
    resetContentStore()
    localStorage.setItem('skip-tutorial', 'true')
    if (generate) {
      chatStore?.clear()
      history.push('/generate')
    } else {
      history.push(`/content/creation/quiz/new/edit?generate=${generate}&create=true`)
    }
  }

  const exportQuiz = async (quiz) => {
    const { file_url } = (await exportQuizzes(quiz)) as any
    window.open(file_url)
  }

  const deleteQuiz = (quiz) => {
    checkDeleteQuizzes(quiz).then((data: any) => {
      setDeleteCheck(data)
      setQuizToDelete(quiz)
      setShowConfirmDelete(true)
    })
  }

  const loadQuizzes = async () => {
    setLoading(true)
    QuizApi.getAllQuizzes(
      'quizzes',
      false,
      [
        { field: 'quiz_title' },
        { field: 'quiz_question_count' },
        { field: 'quiz_status' },
        { field: 'quiz_last_edited_date' },
        { field: 'photo_url' },
      ],
      'list_quizzes',
    ).then((resp) => {
      setQuizzes(resp.data.rows)
      setLoading(false)
    })
  }

  const [init, setInit] = useState(false)
  useEffect(() => {
    if (!init) {
      loadQuizzes()
    }
    setInit(true)
  }, [])

  return (
    <Switch>
      <Route exact path="/content/quizzes">
        <div style={HEADER_STICKY_CONTAINER}>
          <div style={HEADER_FLEX_CONTAINER}>
            <div style={HEADER_ITEM1}>
              <Header title="Quizzes" />
              {!loading && (
                <TextField
                  value={searchText}
                  onChange={(e) => setSearchText(e.target.value)}
                  type="text"
                  variant="standard"
                  InputProps={{
                    style: { paddingRight: 90, marginBottom: 16 },
                    startAdornment: (
                      <Search style={{ marginRight: 12, fontSize: 22 }} htmlColor={color.shade70} />
                    ),
                  }}
                  style={SEARCH}
                  placeholder="Search"
                />
              )}
            </div>
          </div>
        </div>
        {loading ? (
          <div style={LOADER}>
            <CircularProgress />
          </div>
        ) : (
          <div style={QUIZ_LIST}>
            <NewQuizCard key={0} createQuiz={createQuiz} />
            {quizzes
              .filter((item: any) => {
                const title = item.columns.find((column) => column.field === 'quiz_title').value
                return !searchText || title.toLowerCase().includes(searchText.toLowerCase())
              })
              .map((item: any) => {
                const title = item.columns.find((column) => column.field === 'quiz_title').value
                const photo = item.columns.find((column) => column.field === 'photo_url').value
                const status = item.columns.find((column) => column.field === 'quiz_status').value
                const questionCount = item.columns.find(
                  (column) => column.field === 'quiz_question_count',
                ).value
                const lastUpdated = item.columns.find(
                  (column) => column.field === 'quiz_last_edited_date',
                ).value
                const date = DateTime.fromISO(lastUpdated).toFormat('MMM d, yyyy')
                return (
                  <QuizCard
                    key={item.id}
                    title={title}
                    image={photo ? photo : null}
                    questionCount={questionCount}
                    lastUpdated={date}
                    onClick={() => selectQuiz(item.id)}
                    status={status === 'Assigned' ? 'Live' : 'Draft'}
                    onEdit={() => editQuiz(item.id)}
                    onExport={() => exportQuiz([item.id])}
                    onShare={() => history.push(`/content/quizzes/${item.id}/overview/?share=true`)}
                    onDelete={() => deleteQuiz([item.id])}
                  />
                )
              })}
          </div>
        )}
        <Route path="/content/quizzes/:id/:tab">
          <QuizzesDetailsScreen />
        </Route>
        {showConfirmDelete && (
          <ConfirmDialog
            danger
            open={showConfirmDelete}
            title="Remove Quizzes"
            message={
              deleteCheck && typeof deleteCheck.message !== 'undefined'
                ? deleteCheck.message
                : `Are you sure you want to delete this quiz?`
            }
            confirmedMessage={`1 quiz has been removed.`}
            confirmButton="Confirm"
            onConfirm={() => deleteQuizzes(quizToDelete)}
            onClose={() => {
              setShowConfirmDelete(false)
              loadQuizzes()
              setQuizToDelete([])
            }}
            handleCancel={() => {
              setShowConfirmDelete(false)
              setQuizToDelete([])
            }}
          />
        )}
      </Route>
    </Switch>
  )
})

const QUIZ_LIST: React.CSSProperties = {
  display: 'flex',
  flexDirection: 'row',
  flexWrap: 'wrap',
  gap: 24,
  paddingBottom: 40,
  paddingTop: 16,
}
const SEARCH: React.CSSProperties = { marginTop: 20, fontSize: 16, fontWeight: 600, width: '100%' }
const LOADER: React.CSSProperties = {
  height: window.innerHeight / 2 + 70,
  justifyContent: 'center',
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
}
const HEADER_STICKY_CONTAINER: React.CSSProperties = {
  position: 'sticky',
  top: 0,
  width: '100%',
  zIndex: 99,
}
const HEADER_FLEX_CONTAINER: React.CSSProperties = {
  display: 'flex',
  justifyContent: 'space-between',
}
const HEADER_ITEM1: React.CSSProperties = { width: '100%' }
