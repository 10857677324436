import { types } from 'mobx-state-tree'
import { ContentMetadata } from '../UserQuiz'

export const UserPermissionResponse = types.model('UserPermissionResponse').props({
  code: types.string,
  description: types.string,
})

export const UserRole = types.model('UserRole').props({
  id: types.number,
  is_custom: types.boolean,
  name: types.string,
  order: types.number,
  summary: types.string,
})

export const userPermissionsResponse = types.model('userPermissionsResponse').props({
  permissions: types.array(UserPermissionResponse),
  restrict_talk: types.boolean,
  roles: types.array(UserRole),
})

export const UserResourceResponse = types.model('UserResourceResponse').props({
  resource_id: types.number,
  title: types.string,
  extension: types.maybeNull(types.string),
  url: types.maybeNull(types.string),
  metadata: ContentMetadata,
})
