import React, { useId } from 'react'
import { Circle } from '../circle'
import { Text } from '../text'

type PunchcardValue = {
  value: number
  scaledValue: number
}
interface PunchcardProps {
  values: Array<PunchcardValue>
  style?: React.CSSProperties
}

const days = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday']
const times = ['12am', '3am', '6am', '9am', '12pm', '3pm', '6pm', '9pm']

export function Punchcard(props: PunchcardProps) {
  const { style, values } = props

  const timesRow = [<div key={'empty cell'}></div>]
  timesRow.push(
    ...times.map((time: string) => (
      <div key={time} style={{ placeSelf: 'center' }}>
        {time}
      </div>
    )),
  )

  return (
    <div style={{ ...GRID, ...style }}>
      {values
        ?.map((value: PunchcardValue, index: number) => {
          const elements = [] as any
          if (index % times.length === 0) {
            elements.push(
              <Text
                key={index + 'uniqueKey'}
                variant="body1Medium"
                text={days[index / times.length]}
                style={{ justifySelf: 'end', alignSelf: 'center' }}
              />,
            )
          }
          elements.push(
            <div key={useId()} style={{ placeSelf: 'center' }}>
              <Circle key={index} size={value.scaledValue} max={40} />
            </div>,
          )
          return elements
        })
        .concat(timesRow)
        .flat()}
    </div>
  )
}

const GRID: React.CSSProperties = {
  display: 'grid',
  gridTemplateColumns: `100px repeat(${times.length}, auto)`,
  gridTemplateRows: 'repeat(8, 50px)',
}
