import { ApiResponse } from 'apisauce'
import { getParentOfType, Instance } from 'mobx-state-tree'

import { QuestionContentCreatePayload } from '../models/QuestionContentCreatePayload'
import { Quiz } from '../models/Quiz'
import { Resource } from '../models/Resource'
import { ContentCreationApi } from '../services/api-objects/ContentCreationApi'
import { QuizApi } from '../services/api-objects/QuizApi'
import { ContentCreation } from '../models/ContentCreation'
import { QuizContentCreatePayload } from '../models/QuizContentCreatePayload'
import { QuestionHelper } from './QuestionHelper'
import { ResourceHelper } from './ResourceHelper'
import { Question } from '../models/Question'

let rootStore
export class QuizHelper {
  static setRootStore = (store: any) => {
    rootStore = store
  }

  static loadQuiz = async (id: number, init?: any) => {
    let newQuiz: Instance<typeof Quiz> | null = null
    const getQuizResponse = await QuizApi.getQuiz(id)

    const quiz = getQuizResponse.data?.quiz?.pop()
    if (getQuizResponse.ok && quiz) {
      newQuiz = Quiz.create({
        ...quiz,
        original_name: quiz?.title,
        is_locked: false,
        questions: (quiz.questions ?? ([] as Array<Instance<typeof Question>>)).map(
          (question, index) => {
            return {
              ...question,
              advanced: Boolean(question.choices.some((choice) => choice.factoid)),
              approved: true,
              touched: true,
              id: question.question_id,
              is_locked: false,
              image_src: question.image,
            }
          },
        ),
        ...init,
      })
    } else {
      throw new Error(`failed to get quiz ${id}`)
    }

    QuizHelper.loadResources(newQuiz).then(() => {
      const unattachedResources = [] as any
      newQuiz?.questions?.map((question) => {
        if (question.resource_id) {
          const found = newQuiz?.resources?.find((r) => r.resource_id === question.resource_id)
          if (!found) {
            unattachedResources.push(question.resource_id)
          }
        }
      })
      newQuiz?.set('removingResources', unattachedResources)
    })

    return newQuiz
  }

  static loadResources = async (quiz: Instance<typeof Quiz>) => {
    if (!quiz.quiz_id) {
      return
    }

    const getQuizResourcesResponse = await QuizApi.getResources(
      false,
      [
        { field: 'resource_created_date' },
        { field: 'resource_title' },
        { field: 'resource_filename_url' },
        { field: 'resource_file_size' },
        { field: 'resource_file_type' },
        { field: 'quiz_resource_required' },
        { field: 'resource_processing_status' },
      ],
      'list_quiz_resources',
      quiz.quiz_id,
    )

    if (getQuizResourcesResponse.ok && getQuizResourcesResponse.data?.rows.length) {
      quiz.set(
        'resources',
        ResourceHelper.getQuizResourceToResources(getQuizResourcesResponse.data.rows),
      )
    }
    return getQuizResourcesResponse
  }

  /**
   * returns a new quiz
   */
  static createNewQuiz = (init?: any) => {
    return Quiz.create({
      title: '',
      keep_item_order: false,
      order: null,
      questions: [],
      ...init,
    })
  }

  static updateQuizResourceTitles = (
    fetchedResources: Array<Instance<typeof Resource>>,
    quiz: Instance<typeof Quiz>,
  ) => {
    if (!quiz.resources?.length) {
      return true
    }

    quiz.resources.forEach((r) => {
      const existingResource = fetchedResources.find(
        (fetchedResource) => fetchedResource.resource_id === r.resource_id,
      )
      if (existingResource) {
        r.set('title', existingResource.title)
      }
    })
  }

  static hasResource = (resourceToFind: Instance<typeof Resource>, quiz: Instance<typeof Quiz>) => {
    if (!quiz.resources?.length) {
      return false
    }

    try {
      return !!quiz.resources.find((r) => r.resource_id === resourceToFind.resource_id)
    } catch {
      return false
    }
  }

  static validate = async (quiz: Instance<typeof Quiz>) => {
    const validateResponse: ApiResponse<Instance<typeof ContentCreation>> =
      await ContentCreationApi.validateContent({ quiz: [quiz.toPayload()] })

    if (validateResponse.ok && validateResponse.data?.quiz) {
      QuizHelper.collectErrors(validateResponse.data.quiz[0], quiz)
      QuizHelper.collectWarnings(validateResponse.data.quiz[0], quiz)
    }
  }

  static publishImages = async (quiz: Instance<typeof Quiz>) => {
    if (!quiz) {
      return
    }

    if (quiz.quiz_id && quiz.imageFile) {
      QuizApi.publishImage(quiz.quiz_id, quiz.imageFile)
    } else if (quiz.image) {
      // do nothing
    }
    if (quiz.questions?.length) {
      quiz.questions.filter((q) => q.question_id).forEach((q) => QuestionHelper.publishImage(q))
    }
  }

  static publishResources = async (quiz: Instance<typeof Quiz>) => {
    if (!quiz.quiz_id) {
      throw new Error(`quiz needs an id to publish resources`)
    }

    if (quiz?.removingResources && quiz?.removingResources.length) {
      await QuizApi.removeResources(quiz.quiz_id, quiz?.removingResources)
    }

    const adding = quiz?.addingResources?.filter((r) => {
      const req = quiz?.requiredResources?.map((r) => r.resource_id)
      return !req.includes(r.resource_id)
    })

    if (adding && adding?.length) {
      QuizApi.addResources(quiz.quiz_id!, adding)
    }

    if (quiz.requiredResources && quiz?.requiredResources?.length) {
      QuizApi.addResources(quiz.quiz_id!, quiz.requiredResources)
    }
  }

  static collectErrors = (
    responseQuiz: Instance<typeof QuizContentCreatePayload>,
    quiz: Instance<typeof Quiz>,
  ) => {
    if (responseQuiz.quiz_id) {
      quiz.set('quiz_id', responseQuiz.quiz_id)
    }

    quiz.set('errorsCorrected', [])

    let hasErrors = false
    if (
      responseQuiz.errors?.length &&
      !responseQuiz.errors.every((e) => e.includes('an active assignment'))
    ) {
      hasErrors = true
      quiz.set('errors', responseQuiz.errors)
    } else {
      quiz.set('errors', [])
    }

    if (quiz.questions?.length && responseQuiz.questions?.length) {
      responseQuiz.questions.forEach((q, i) => {
        const indexedQuestion = quiz.questions![i]
        if (indexedQuestion) {
          hasErrors = QuestionHelper.collectErrors(q, indexedQuestion) || hasErrors
        }
      })
    }

    return hasErrors
  }

  static collectWarnings = (
    responseQuiz: Instance<typeof QuizContentCreatePayload>,
    quiz: Instance<typeof Quiz>,
  ) => {
    let hasWarnings = false

    if (quiz.questions?.length && responseQuiz.questions?.length) {
      responseQuiz.questions.forEach((q, i) => {
        const indexedQuestion = quiz.questions![i]
        if (indexedQuestion) {
          hasWarnings = QuestionHelper.collectWarnings(q, indexedQuestion) || hasWarnings
        }
      })
    }

    return hasWarnings
  }

  static hasDeepErrors = (quiz: Instance<typeof Quiz>) => {
    if (quiz.errors?.length) {
      return true
    }

    if (quiz.questions?.length) {
      return quiz.questions.some((q) => q.errors?.length)
    } else {
      return false
    }
  }

  static quizParentHasErrors = (question: Instance<typeof Question>) => {
    try {
      const parentQuiz = getParentOfType(question, Quiz)
      return parentQuiz.hasErrors
    } catch {
      return false
    }
  }

  static isSafeToForcePublish = (quiz: Instance<typeof Quiz>) => {
    // in order to force publish the only error currently we can force is if a question
    // is a part of an assignment.

    if (!quiz.questions?.length) {
      return false
    }

    // if we have 1 error and that error is not topicInAssignment
    if (quiz.errors?.length === 1 && !quiz.hasError('quizInAssignment')) {
      return false
    } else if (quiz.errors?.length && quiz.errors.length > 1) {
      return false
    } else {
      return quiz.questions.every((q) => QuestionHelper.isSafeToForcePublish(q))
    }
  }

  static hasOneNonBlankQuestion = (quiz: Instance<typeof Quiz>) => {
    if (!quiz.questions?.length) {
      return false
    }

    return !quiz.questions.every((q) => q.isBlankQuestion)
  }

  static updateResources = (quiz: Instance<typeof Quiz>, resource: Instance<typeof Resource>) => {
    if (quiz.resources?.length) {
      quiz.resources.forEach((r) => {
        if (r.resource_id === resource.resource_id) {
          r.set('title', resource.title)
        }
      })
    }
  }
}
