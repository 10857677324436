import React, { useEffect, useState } from 'react'
import { VictoryPie } from 'victory'
// @ts-ignore
import { ReactComponent as GoldMedallion } from '../../../assets/icons/gold-medallion.svg'
// @ts-ignore
import { ReactComponent as SilverMedallion } from '../../../assets/icons/silver-medallion.svg'
// @ts-ignore
import { ReactComponent as BronzeMedallion } from '../../../assets/icons/bronze-medallion.svg'
// @ts-ignore
import { ReactComponent as InactiveMedallion } from '../../../assets/icons/inactive-medallion.svg'
// @ts-ignore
import { ReactComponent as WhiteIncompleteMedallion } from '../../../assets/icons/white-incomplete-medallion.svg'
// @ts-ignore
import { ReactComponent as WhiteCompleteMedallion } from '../../../assets/icons/white-complete-medallion.svg'
// @ts-ignore
import { ReactComponent as NotStartedMedallion } from '../../../assets/icons/not-started-medallion.svg'
// @ts-ignore
import { ReactComponent as StartedMedallion } from '../../../assets/icons/started-medallion.svg'
// @ts-ignore
import { ReactComponent as Locked } from '../../../assets/icons/locked.svg'

interface MedallionProps {
  type: string | null
  recall?: number | null
  style?: React.CSSProperties
  scale?: number
  medalIconColor?: string
}

const initData = [
  { y: 0, fill: 'none' },
  { y: 100, fill: 'none' },
]

export const Medallion = (props: MedallionProps) => {
  const { type, medalIconColor = '#ffffff', scale = 1, recall, style } = props

  const [finalData, setFinalData] = useState(initData)

  const fillValue = Math.round((recall ?? 0) * 100)
  const size = 100

  const fill = type === 'white' ? '#000' : type === 'grey' ? '#fff' : '#ffffff80'
  const stroke = type === 'white' ? '#ffffff50' : type === 'grey' ? '#ffffff20' : '#ffffff50'

  useEffect(() => {
    const timer = setTimeout(() => {
      setFinalData([
        { y: fillValue, fill },
        { y: 100 - fillValue, fill: 'none' },
      ])
    }, 1000)
    return () => clearTimeout(timer)
  }, [])

  const renderMedalIcon = () => {
    return (
      <svg
        style={{ position: 'absolute', zIndex: 1, left: 15, top: 8 }}
        viewBox="0 0 60 60"
        height={60}
        width={60}
      >
        <path
          transform="translate(14,28) scale(4, 4)"
          d="M3.20582 5.75189L8.46756 0.272568C8.81656 -0.0908561 9.38926 -0.0908561 9.73826 0.272568C10.0872 0.635993 10.0872 1.22306 9.73826 1.58649L3.84116 7.72743C3.49217 8.09086 2.92841 8.09086 2.57942 7.72743L0.261745 5.31392C-0.0872483 4.95049 -0.0872483 4.36342 0.261745 4C0.610738 3.63658 1.1745 3.63658 1.52349 4L3.20582 5.75189Z"
          fill={medalIconColor}
          fillOpacity={1}
        />
      </svg>
    )
  }

  const renderBase = () => {
    switch (type) {
      case 'Bronze':
        return (
          <>
            {renderMedalIcon()}
            <BronzeMedallion height={size} width={size} />
          </>
        )
      case 'Silver':
        return (
          <>
            {renderMedalIcon()}
            <SilverMedallion height={size} width={size} />
          </>
        )
      case 'Gold':
        return (
          <>
            {renderMedalIcon()}
            <GoldMedallion height={size} width={size} />
          </>
        )
      case 'White-Incomplete':
        return <WhiteIncompleteMedallion height={size} width={size} />
      case 'White-Complete':
        return <WhiteCompleteMedallion height={size} width={size} />
      case 'Not-Started':
        return <NotStartedMedallion height={size} width={size} />
      case 'Started':
        return (
          <>
            {renderMedalIcon()}
            <StartedMedallion height={size} width={size} />
          </>
        )
      case 'Locked':
        return (
          <>
            <Locked
              height={size}
              width={70}
              color={medalIconColor}
              style={{
                position: 'absolute',
                zIndex: 1,
                alignContent: 'center',
                alignSelf: 'center',
              }}
            />
            <StartedMedallion height={size} width={size} />
          </>
        )
      default:
        return <InactiveMedallion height={size} width={size} />
    }
  }

  return (
    <div style={{ zIndex: 999, transform: `scale(${scale})`, alignSelf: 'center', ...style }}>
      {type !== null && (
        <div style={RECALL}>
          <svg viewBox={`-7 -8 ${size * 1.5} ${size * 1.5}`} height={size} width={size}>
            <circle
              cx={size / 2.0}
              cy={size / 2.0}
              r={size / 2.0}
              stroke={stroke}
              strokeWidth={12}
              fill="none"
            />
            <VictoryPie
              standalone={false}
              radius={size / 2.2}
              origin={{ x: size / 2.0, y: size / 2.0 }}
              innerRadius={size / 1.79}
              cornerRadius={40}
              animate={{ easing: 'sin', duration: 2000 }}
              data={finalData}
              style={{
                labels: { display: 'none' },
                data: {
                  fill: ({ datum }) => datum.fill,
                  opacity: ({ datum }) => datum.opacity,
                },
              }}
            />
          </svg>
        </div>
      )}
      {renderBase()}
    </div>
  )
}

const RECALL: React.CSSProperties = {
  position: 'absolute',
  marginLeft: 12,
  marginTop: 12,
  zIndex: 999,
}
