import React, { useEffect, useState } from 'react'
import {
  AddRounded,
  Delete,
  LocalOffer,
  LocalOfferOutlined,
  MailRounded,
  Sms,
} from '@mui/icons-material'
import { CircularProgress } from '@mui/material'
import { useStores } from '@trivie/core'
import { AddTags } from '@trivie/ui-web/components/add-tags'
import { DataTable, TableHelper } from '@trivie/ui-web/components/data-table/data-table'
import { MessageDialog } from '@trivie/ui-web/components/message-dialog'
import { observer } from 'mobx-react-lite'
import { Toolbar } from '@trivie/ui-web/components/table-toolbar'
import { AddUsersToGroup } from '@trivie/ui-web/components/add-users/add-users'
import { color } from '@trivie/ui-web/theme'
import { AddUsersToTag } from '../screens/people/tags/add-users/add-users-dialog'
import { useConfirm } from '@trivie/ui-web/components/dialogs/confirmation-dialog'

interface UserTableProps {
  tableHelper: TableHelper
  forceLoading?: boolean
  openFilter?: boolean
  restricted?: boolean
  columns?: any
  exportFilename?: string
}

export const UserTable = observer((props: UserTableProps) => {
  const { exportFilename, tableHelper, columns, restricted, openFilter, forceLoading } = props

  const confirm = useConfirm()

  const { manageUserStore, assignmentStore, tagStore, groupStore } = useStores()
  const {
    permissions,
    resendInvitation,
    deleteUsers,
    getTagCategories,
    tagCategories,
    enterpriseTier,
    freeTier,
    gdpr,
  } = manageUserStore
  const { removeUsersFromTags } = tagStore
  const { getAddedGroupUsers, getExcludedGroupUsers } = groupStore

  const [selectedRows, setSelectedRows] = useState<any>([])
  const [loading, setLoading] = useState<boolean>(tableHelper.custom ? false : true)

  // Dialogs
  const [showSendMessage, setShowSendMessage] = useState(false) // message
  const [showAddTags, setShowAddTags] = useState(false) // add tags to user
  const [showAddUsers, setShowAddUsers] = useState(false) // add user to group
  const [showAddUserToTag, setShowAddUserToTag] = useState(false) // add user to tag

  /* Doesn't require a selection - can appear in toolbar or in more menu if > 1 */
  const getAdditionalActions = () => {
    switch (tableHelper.parent) {
      case 'assignments':
        return []
      case 'groups':
        return [
          {
            label: 'Add Users',
            icon: (
              <AddRounded
                htmlColor={
                  !permissions?.permissions.some((p) => p.code === 'can_edit_user')
                    ? color.shade20
                    : color.shade70
                }
              />
            ),
            onClick: () => setShowAddUsers(true),
            disabled: !permissions?.permissions.some((p) => p.code === 'can_edit_user'),
          },
        ]
      case 'tags':
        return [
          {
            label: 'Add Users',
            icon: (
              <LocalOffer
                htmlColor={
                  !permissions?.permissions.some((p) => p.code === 'can_edit_user')
                    ? color.shade20
                    : color.shade70
                }
              />
            ),
            onClick: () => setShowAddUserToTag(true),
            disabled: !permissions?.permissions.some((p) => p.code === 'can_edit_user'),
          },
        ]
      default:
        return []
    }
  }

  const defaultActions = freeTier
    ? []
    : [
        {
          label: 'Message',
          icon: Sms,
          onClick: () => setShowSendMessage(true),
          disabled: !permissions?.permissions.some((p) => p.code === 'can_message_user'),
        },
      ]

  /* Get toolbar actions */
  const getActions = () => {
    switch (tableHelper.parent) {
      case 'assignments':
        return [
          ...defaultActions,
          {
            label: 'Exclude Users',
            icon: AddRounded,
            onClick: () => confirmExclude(false),
            disabled: !permissions?.permissions.some((p) => p.code === 'can_edit_user'),
          },
        ]
      case 'topics':
        return [...defaultActions]
      case 'quizzes':
        return [...defaultActions]
      case 'groups':
        return [
          ...defaultActions,
          {
            label: 'Exclude Users',
            icon: AddRounded,
            onClick: () => confirmExclude(true),
            disabled: !permissions?.permissions.some((p) => p.code === 'can_edit_user'),
          },
        ]
      case 'tags':
        return [
          ...defaultActions,
          {
            label: 'Untag Users',
            icon: LocalOfferOutlined,
            onClick: () =>
              removeUsersFromTags(selectedRows, [tableHelper.id]).then(() => {
                load()
                setSelectedRows([])
              }),
            disabled: !permissions?.permissions.some((p) => p.code === 'can_edit_user'),
          },
        ]
      default:
        return !enterpriseTier
          ? [...defaultActions]
          : [
              ...defaultActions,
              {
                label: 'Resend Invite',
                icon: MailRounded,
                onClick: confirmResend,
                disabled: !permissions?.permissions.some((p) => p.code === 'can_create_user'),
              },
              {
                label: 'Add Tag',
                icon: LocalOffer,
                onClick: () => setShowAddTags(true),
                disabled: !permissions?.permissions.some((p) => p.code === 'can_edit_user'),
              },
              {
                label: 'Delete',
                icon: Delete,
                onClick: confirmDelete,
                disabled: !permissions?.permissions.some((p) => p.code === 'can_delete_user'),
              },
            ]
    }
  }

  const confirmResend = () => {
    confirm({
      title: 'Resend Invitations',
      confirmationMessage: `${selectedRows.length} user${selectedRows.length === 1 ? '' : 's'} ${selectedRows.length === 1 ? 'has' : 'have'} been sent an invitation message.`,
      dialogText: `Are you sure you want to resend a welcome message to ${selectedRows.length} user${selectedRows.length === 1 ? '' : 's'}?`,
    })
      .then(() => {
        resendInvitation(selectedRows)
        setSelectedRows([])
      })
      .catch(() => {})
  }

  const confirmDelete = () => {
    confirm({
      danger: true,
      title: 'Delete Users',
      confirmationMessage: `${selectedRows.length} user${
        selectedRows.length > 1 ? 's have' : ' has'
      } been removed from the system.`,
      dialogText: `Are you sure you want to delete ${selectedRows.length} user${
        selectedRows.length > 1 ? 's' : ''
      }?`,
    })
      .then(() => {
        deleteUsers(selectedRows).then(() => {
          setTimeout(() => {
            load()
            setSelectedRows([])
          }, 400)
        })
      })
      .catch(() => {})
  }

  const confirmExclude = (group?: boolean) => {
    confirm({
      title: 'Exclude Users',
      dialogText: `Are you sure you want to exclude ${selectedRows.length} user${
        selectedRows.length > 1 ? 's' : ''
      }?`,
      confirmationMessage: `${selectedRows.length} user${
        selectedRows.length > 1 ? 's have' : ' has'
      } been excluded.`,
    })
      .then(() => {
        if (group) {
          groupStore.excludeUsers({ user_ids: selectedRows }).then(() => {
            load()
            if (tableHelper.id) {
              assignmentStore.getAddedUsers(tableHelper.id)
              assignmentStore.getExcludedUsers(tableHelper.id)
            }
            setSelectedRows([])
          })
        } else {
          if (tableHelper.id) {
            assignmentStore.excludeUsers(tableHelper.id, { user_ids: selectedRows }).then(() => {
              load()
              assignmentStore.getAddedUsers(tableHelper.id!)
              setSelectedRows([])
            })
          }
        }
      })
      .catch(() => {})
  }

  const [tableActions, setTableActions] = useState<any>(getActions())
  const [additionalActions] = useState<any>(getAdditionalActions())

  useEffect(() => {
    if (permissions) {
      setTableActions(getActions())
    }
  }, [permissions?.permissions])

  useEffect(() => {
    setLoading(Boolean(forceLoading))
  }, [forceLoading])

  useEffect(() => {
    load()
    if (enterpriseTier) {
      getTagCategories()
    }
  }, [])

  useEffect(() => {
    setTableActions(getActions())
  }, [selectedRows])

  const load = () => {
    if (!tableHelper.custom) {
      setLoading(true)
      tableHelper.load().then(() => setLoading(false))
    }
  }

  const save = (cols: any, filter: any) => {
    setLoading(true)
    tableHelper.save(cols, filter).then(() => setLoading(false))
  }

  return loading ? (
    <div style={LOADER}>
      <CircularProgress />
    </div>
  ) : (
    <>
      <DataTable
        gdpr={gdpr}
        filterModel={tableHelper.filterModel}
        sortModel={tableHelper.sortModel}
        columnVisibilityModel={tableHelper.columnVisibilityModel}
        rows={tableHelper.rows}
        columns={tableHelper.columns}
        restricted={restricted}
        allowNewReport
        tableHelper={tableHelper}
        searchPlaceholder="Search People"
        onSaveLayout={tableHelper?.saveLayout}
        onSaveFilters={save}
        toolbar={
          <Toolbar
            additionalActions={additionalActions}
            selected={selectedRows}
            actions={tableActions}
          />
        }
        exportFilename={
          exportFilename
            ? exportFilename
            : tableHelper.parent !== 'users'
              ? `${tableHelper.parent}_users`
              : 'users'
        }
        tagCategories={tagCategories}
        onRowSelectionModelChange={(param) => setSelectedRows(param)}
        rowSelectionModel={selectedRows}
        openFilter={openFilter}
      />
      {showSendMessage && (
        <MessageDialog
          ids={selectedRows}
          open={showSendMessage}
          userCount={(selectedRows ?? []).length}
          messageType="generic"
          onClose={() => {
            setSelectedRows([])
            setShowSendMessage(false)
          }}
        />
      )}
      {showAddTags && (
        <AddTags
          table
          open={showAddTags}
          users={selectedRows}
          onClose={() => {
            setShowAddTags(false)
            setSelectedRows([])
          }}
          onConfirm={() => {
            setShowAddTags(false)
            setSelectedRows([])
            load()
          }}
        />
      )}
      {showAddUsers && (
        <AddUsersToGroup
          open={showAddUsers}
          onClose={() => {
            getExcludedGroupUsers()
            getAddedGroupUsers()
            setShowAddUsers(false)
            load()
          }}
        />
      )}
      {showAddUserToTag && (
        <AddUsersToTag
          refresh={load}
          open={showAddUserToTag}
          tagId={tableHelper.id}
          onClose={() => setShowAddUserToTag(false)}
        />
      )}
    </>
  )
})

const LOADER: React.CSSProperties = {
  display: 'flex',
  flex: 1,
  justifyContent: 'center',
  flexDirection: 'column',
  alignItems: 'center',
  height: window.innerHeight / 1.5,
}
