import { types } from 'mobx-state-tree'

export const QuestionPctCorrectSummary = types.model('QuestionPctCorrectSummary').props({
  question_id: types.number,
  title: types.string,
  pct_correct: types.number,
  image_url: types.maybeNull(types.string),
  topic_title: types.maybeNull(types.string),
  question_type: types.string,
})
