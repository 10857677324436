import React, { useState, useEffect } from 'react'
import { observer } from 'mobx-react-lite'
import { includes } from 'ramda'
import { Box, InputAdornment, Paper, TextField } from '@mui/material'
import { People } from '@mui/icons-material'
import { useStores } from '@trivie/core'
import { color } from '@trivie/ui-web/theme'
import { NameCell } from '@trivie/ui-web/components/table-cells'
import { Text } from '@trivie/ui-web/components/text'
import { Dialog } from '@trivie/ui-web/components/dialogs/dialog'

export const AddUsersToTag = observer((props: any) => {
  const { open, onClose, refresh, tagId } = props
  const { tagStore, manageUserStore } = useStores()
  const { availableUsers, getAvailableUsers } = manageUserStore
  const { addUsersToTags } = tagStore
  const [selectedUsers, setSelectedUsers] = useState<any>([])
  const [usersFilter, setUsersFilter] = useState('')

  useEffect(() => {
    // move to new endpoint
    if (!availableUsers || !availableUsers.length) {
      getAvailableUsers(false, [], 'list_users')
    }
  }, [])

  const onChange = (e: any) => {
    setUsersFilter(e.target.value)
  }

  const select = (user: any) => {
    if (!includes(user, selectedUsers)) {
      setSelectedUsers([...selectedUsers, user])
    }
  }

  const addToTag = () => {
    const selected = selectedUsers.map((user: any) => user.id)
    addUsersToTags(selected, [tagId], () => refresh())
    onClose()
  }

  return (
    <Dialog
      onCancel={() => {
        setSelectedUsers([])
        onClose()
      }}
      onConfirm={addToTag}
      maxWidth="sm"
      onClose={onClose}
      title="Add Users to Tag"
      confirmBtnLabel="Add Users"
      aria-labelledby="add users to tag"
      open={open}
    >
      <Box height={600}>
        <Text mb={1} variant="body1" text="Search" style={{ color: color.shade70 }} />
        <TextField
          id="search"
          size="small"
          variant="outlined"
          value={usersFilter}
          placeholder="Search name or emails"
          style={{ width: '100%' }}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <People style={{ fontSize: 16 }} htmlColor={color.palette.grey} />
              </InputAdornment>
            ),
          }}
          onChange={onChange}
        />
        {!usersFilter && selectedUsers.length === 0 && (
          <Text
            mt={1}
            variant="body2"
            text="Search for users to manually assign this content to them."
            style={{ color: color.shade50, marginBottom: 32 }}
          />
        )}
        {usersFilter && (
          <Paper
            variant="outlined"
            style={{
              marginTop: 5,
              paddingTop: 12,
              paddingBottom: 12,
              paddingLeft: 16,
              paddingRight: 16,
              borderRadius: 6,
            }}
          >
            {(availableUsers ?? [])
              .filter((user: any) => {
                const u = { ...user, display_name: `${user.first_name} ${user.last_name}` }
                return (
                  u.display_name?.toLowerCase().includes(usersFilter?.toLowerCase()) ||
                  u.email?.toLowerCase().includes(usersFilter?.toLowerCase())
                )
              })
              .map((user: any, index: any, data: any) => {
                return (
                  <div
                    onClick={() => {
                      select(user)
                      setUsersFilter('')
                    }}
                    style={{
                      cursor: 'pointer',
                      width: '100%',
                      padding: 0,
                      display: 'flex',
                      flexDirection: 'row',
                      alignItems: 'center',
                      textTransform: 'none',
                      justifyContent: 'flex-start',
                      marginBottom: index === data.length - 1 ? 0 : 24,
                    }}
                  >
                    <NameCell disableLink params={{ row: user }} />
                  </div>
                )
              })}
          </Paper>
        )}
        {selectedUsers.length > 0 && !usersFilter && (
          <Text
            variant="body1"
            mt={1}
            text={`Selected Users (${selectedUsers.length})`}
            style={{ color: color.shade70, marginBottom: 24 }}
          />
        )}
        {!usersFilter && selectedUsers.length > 0 && (
          <div style={{ maxHeight: 400, overflowY: 'scroll' }}>
            {selectedUsers
              .filter((user: any) => {
                const u = { ...user, display_name: `${user.first_name} ${user.last_name}` }
                return u.display_name.toLowerCase().includes(usersFilter.toLowerCase())
              })
              .map((user: any) => {
                return (
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      alignItems: 'center',
                      marginBottom: 24,
                    }}
                  >
                    <NameCell disableLink params={{ row: user }} />
                  </div>
                )
              })}
          </div>
        )}
      </Box>
    </Dialog>
  )
})
