import { TextField } from '@mui/material'
import { color } from '../../theme'
import { observer } from 'mobx-react-lite'
import React, { useState } from 'react'
import { DateTime } from 'luxon'
import { Text } from '../text'
import { CreateMessageForm } from '@trivie/core/src/models/CreateMessage'
import { Instance } from 'mobx-state-tree'
import { Switch } from '../switch/switch'

type MessageFormProps = {
  form: Instance<typeof CreateMessageForm>
}

export const MessageForm = observer((props: MessageFormProps) => {
  const { form } = props

  const [touched, setTouched] = useState({
    subject: false,
    message: false,
    start_date: false,
    start_time: false,
    push: false,
    email: false,
    schedule: false,
  })

  return (
    <div style={CONTAINER}>
      <form style={FORM}>
        <div style={{ flexDirection: 'row', marginBottom: 16 }}>
          <TextField
            error={touched.subject && !!form.errors.subject}
            style={{ width: '100%' }}
            size="small"
            variant="outlined"
            defaultValue={form.subject}
            placeholder="Subject"
            onChange={(e) => {
              setTouched({ ...touched, subject: true })
              form.set('subject', e.target.value)
            }}
            onBlur={() => setTouched({ ...touched, subject: true })}
            value={form.subject}
          />
          {form.push && (
            <div style={{ paddingTop: 4, display: 'flex', justifyContent: 'flex-end' }}>
              <Text
                style={{
                  ...ERROR,
                  color: touched.subject && form.subject?.length > 50 ? '#f44336' : color.shade50,
                }}
                variant="body1"
                text={(form.subject?.length ?? 0) + ' / 50'}
              />
            </div>
          )}
        </div>
        <div style={{ flexDirection: 'row', marginBottom: 30 }}>
          <TextField
            error={touched.message && !!form.errors.message}
            style={{ width: '100%' }}
            InputProps={{ style: { height: 120 } }}
            rows={5}
            size="small"
            variant="outlined"
            multiline
            placeholder="Message"
            onChange={(e) => {
              setTouched({ ...touched, message: true })
              form.set('message', e.target.value)
            }}
            onBlur={() => setTouched({ ...touched, message: true })}
            value={form.message}
          />
          {form.push && (
            <div style={{ paddingTop: 4, display: 'flex', justifyContent: 'flex-end' }}>
              <Text
                style={{
                  ...ERROR,
                  color: touched.message && form.message?.length > 150 ? '#f44336' : color.shade50,
                }}
                variant="body1"
                text={(form.message?.length ?? 0) + ' / 150'}
              />
            </div>
          )}
        </div>
        {form.message_type !== 'survey_nudge' && form.message_type !== 'nudge' && (
          <>
            <div style={SWITCH_ROW}>
              <Text variant="body1" text="Send via email" />
              <Switch
                color="success"
                checked={form.email}
                onChange={(e) => form.set('email', e.target.checked)}
              />
            </div>
            <div style={SWITCH_ROW}>
              <Text variant="body1" text="Send via push notification" />
              <Switch
                color="success"
                checked={form.push}
                onChange={(e) => form.set('push', e.target.checked)}
              />
            </div>
          </>
        )}
        <div style={SWITCH_ROW}>
          <Text variant="body1" text="Schedule for later" />
          <Switch
            color="success"
            checked={form.schedule}
            onChange={(e) => {
              if (!e.target.checked) {
                form.set('start_date', DateTime.local().toISODate())
                form.set('start_time', DateTime.local().toLocaleString(DateTime.TIME_24_SIMPLE))
              }
              form.set('schedule', e.target.checked)
            }}
          />
        </div>
        <div style={{ display: 'flex', flexDirection: 'row', flex: 1, marginRight: 14 }}>
          <TextField
            disabled={!form.schedule}
            error={!!form.errors.start_date}
            helperText={form.errors.start_date?.message}
            variant="outlined"
            {...{ inputProps: { min: DateTime.local().toFormat('yyyy-MM-dd') } }}
            size="small"
            style={{ marginRight: 8, marginBottom: 10, display: 'flex', width: '100%' }}
            type="date"
            onChange={(e) => {
              if (!e.target.value) {
                return
              }
              setTouched({ ...touched, start_date: true })
              form.set('start_date', e.target.value)
            }}
            onBlur={() => setTouched({ ...touched, start_date: true })}
            value={form.start_date}
          />
          <TextField
            disabled={!form.schedule}
            error={!!form.errors.start_time}
            type="time"
            size="small"
            variant="outlined"
            style={{ width: '100%' }}
            InputLabelProps={{ shrink: true }}
            onChange={(e) => {
              setTouched({ ...touched, start_time: true })
              form.set('start_time', e.target.value)
            }}
            onBlur={() => setTouched({ ...touched, start_time: true })}
            value={form.start_time}
          />
          <Text
            style={{ padding: 8, paddingRight: 0, color: color.shade50 }}
            variant="body1"
            text={Intl.DateTimeFormat().resolvedOptions().timeZone}
          />
        </div>
      </form>
    </div>
  )
})

const CONTAINER: React.CSSProperties = {
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-start',
  overflowY: 'auto',
  overflowX: 'hidden',
}
const SWITCH_ROW: React.CSSProperties = {
  display: 'flex',
  marginTop: 0,
  marginBottom: 20,
  alignItems: 'center',
  justifyContent: 'space-between',
}
const ERROR: React.CSSProperties = { marginBottom: -10, padding: 0, paddingRight: 0 }
const FORM: React.CSSProperties = { justifyContent: 'space-evenly' }
