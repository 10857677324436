import { cast, getParentOfType, Instance, SnapshotIn, types } from 'mobx-state-tree'
import { AssignedTopic } from './AssignedTopic'
import { LibraryStoreModel } from './stores/library-store'
import { ContentMetadata, QuizResource } from './UserQuiz'

export const TopicTest = types.model('TopicTest').props({
  quiz_id: types.number,
  quiz_title: types.string,
  completed: types.boolean,
  score: types.number,
  topic_image_url: types.string,
})

export const TopicQuiz = types
  .model('TopicQuiz')
  .props({
    assignment_end_date: types.maybeNull(types.string),
    user_assignment_due_date: types.maybeNull(types.string),
    assignment_id: types.number,
    best_score: types.number,
    completed_attempts: types.number,
    cooldown_enabled: types.boolean,
    in_progress: types.boolean,
    is_locked: types.boolean,
    knowledge_pct: types.maybeNull(types.number),
    number_questions: types.number,
    pack_color: types.maybeNull(types.string),
    quiz_id: types.number,
    resources: types.maybeNull(types.array(QuizResource)),
    title: types.string,
    topic_image: types.maybeNull(types.string),
    unlocks_at: types.maybeNull(types.string),
    medal_level: types.maybeNull(types.string),
    unlocks_after: types.maybe(types.string),
    metadata: types.frozen(),
  })
  .actions((self) => {
    return {
      set<K extends keyof SnapshotIn<typeof self>, T extends SnapshotIn<typeof self>>(
        key: K,
        value: T[K],
      ) {
        self[key] = cast(value)
      },
    }
  })

export const UserCertificationCriteria = types.model('UserCertificationCriteria').props({
  criteria: types.string,
  level: types.string,
})

export const ContentOrder = types.model('ContentOrder').props({
  object_id: types.number,
  object_type: types.string,
  order: types.maybeNull(types.number),
})

export const UserTopicDetails = types
  .model('UserTopicDetails')
  .props({
    topic_id: types.frozen(),
    assignment_id: types.number,
    due_date: types.maybeNull(types.string),
    end_date: types.maybeNull(types.string),
    knowledge_pct: types.maybeNull(types.number),
    max_attempts: types.number,
    post_test: types.maybeNull(TopicTest),
    posts: types.frozen(),
    pre_test: types.maybeNull(TopicTest),
    quizzes: types.array(TopicQuiz),
    quizzes_completed: types.number,
    resources: types.array(QuizResource),
    has_certification: types.boolean,
    has_active_assignment: types.boolean,
    has_reinforcement_quiz: types.boolean,
    certification_level: types.maybeNull(types.string),
    certification_criteria: types.maybeNull(types.array(UserCertificationCriteria)),
    topic_title: types.string,
    topic_image: types.maybeNull(types.string),
    pack_color: types.maybeNull(types.string),
    content_order: types.maybeNull(types.array(ContentOrder)),
    keep_item_order: types.maybe(types.boolean),
    workflow_id: types.maybeNull(types.number),
    metadata: types.frozen(),
  })
  .actions((self) => ({
    set<K extends keyof SnapshotIn<typeof self>, T extends SnapshotIn<typeof self>>(
      key: K,
      value: T[K],
    ) {
      self[key] = cast(value)
    },
    enrichData() {
      const library = getParentOfType(self, LibraryStoreModel)
      const topic = library.topics.find(
        (t: Instance<typeof AssignedTopic>) => t.topic.title === self.topic_title,
      )
      if (topic) {
        self.topic_id = topic.topic.topic_id ? topic.topic.topic_id : null
        self.topic_image = topic.topic.photo
        self.pack_color = topic.topic.pack_color
      }
    },
  }))
