import React, { useEffect, useState } from 'react'
import { observer } from 'mobx-react-lite'

import { useStores } from '@trivie/core'

import { TableHelper } from '../../../../tables'

import { MessagesTable } from '../../../../tables/message-table'

const ScheduledMessages: React.FunctionComponent = observer(() => {
  const { messageStore } = useStores()
  const { setTriggerRefresh, triggerRefresh } = messageStore

  const [tableHelper] = useState(
    new TableHelper('list_all_messages', 'messages', 'messages', undefined, 'scheduled'),
  )

  useEffect(() => {
    if (triggerRefresh) {
      tableHelper.load().then(() => setTriggerRefresh(false))
    }
  }, [triggerRefresh])

  return <MessagesTable forceLoading={triggerRefresh} tableHelper={tableHelper} />
})

export const ScheduledMessagesTab = () => {
  return {
    name: 'Scheduled',
    path: `/activities/messages/scheduled`,
    component: () => <ScheduledMessages />,
  }
}
