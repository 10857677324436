import React, { useState } from 'react'

import { observer } from 'mobx-react-lite'
import { DateTime } from 'luxon'
import { Instance } from 'mobx-state-tree'
import { FormControl } from '@mui/material'

import { color } from '@trivie/ui-web/theme'
import { TextField } from '@trivie/ui-web/components/text-field'
import { Text } from '@trivie/ui-web/components/text'
import { ContestCreateForm } from '@trivie/core/src/models/ContestCreateForm'
import { Switch } from '@trivie/ui-web/components/switch/switch'

type ContestDetailsProps = {
  form: Instance<typeof ContestCreateForm>
}

export const ContestDetails = observer((props: ContestDetailsProps) => {
  const { form } = props

  const [touched, setTouched] = useState({
    title: false,
    start_date: false,
    start_time: false,
    end_date: false,
    end_time: false,
    description: false,
  })

  let startTimeMax = '23:59'
  if (
    DateTime.fromISO(form.start_date).toFormat('yyyy-MM-dd') ===
    DateTime.fromISO(form.end_date).toFormat('yyyy-MM-dd')
  ) {
    startTimeMax = DateTime.fromISO(`${form.end_date}T${form.end_time}:00`).toFormat('HH:mm')
  }
  let endTimeMin = '00:00'
  if (
    DateTime.fromISO(form.start_date).toFormat('yyyy-MM-dd') ===
    DateTime.fromISO(form.end_date).toFormat('yyyy-MM-dd')
  ) {
    endTimeMin = DateTime.fromISO(`${form.start_date}T${form.start_time}:00`).toFormat('HH:mm')
  }

  return (
    <div style={CONTAINER}>
      <form style={FORM}>
        <div style={{ flexDirection: 'row', marginBottom: 20 }}>
          <Text variant="body2" text="Name" style={{ marginBottom: 7, color: color.shade70 }} />
          <TextField
            error={touched.title && !!form.errors.title}
            helperText={touched.title && form.errors.title?.message}
            style={{ width: '100%' }}
            placeholder="Name"
            onChange={(e) => {
              setTouched({ ...touched, title: true })
              form.set('title', e.target.value)
            }}
            onBlur={() => setTouched({ ...touched, title: true })}
            value={form.title}
          />
        </div>
        <div style={{ flexDirection: 'row', marginBottom: 20 }}>
          <Text
            variant="body2"
            text="Description"
            style={{ marginBottom: 9, color: color.shade70 }}
          />
          <TextField
            multiline
            error={touched.description && !!form.errors.description}
            helperText={touched.description && form.errors.description?.message}
            style={{ width: '100%' }}
            sx={{
              '& .MuiInputBase': {
                padding: 0,
              },
            }}
            placeholder="Description"
            onChange={(e) => {
              setTouched({ ...touched, description: true })
              form.set('description', e.target.value)
            }}
            onBlur={() => setTouched({ ...touched, description: true })}
            value={form.description}
          />
        </div>
        <div style={DATE_CONTAINER}>
          <div style={START_DATE}>
            <Text variant="body2" text="Start" style={{ marginBottom: 7, color: color.shade70 }} />
            <TextField
              error={!!form.errors.start_date}
              helperText={form.errors.start_date?.message}
              variant="outlined"
              {...{
                inputProps: {
                  min: DateTime.local().toFormat('yyyy-MM-dd'),
                  max: DateTime.local().plus({ years: 1 }).toFormat('yyyy-MM-dd'),
                },
              }}
              size="small"
              style={START_DATE_FIELD}
              type="date"
              onChange={(e) => {
                if (!e.target.value) {
                  return
                }
                setTouched({ ...touched, start_date: true })
                form.set('start_date', e.target.value)
              }}
              onBlur={() => setTouched({ ...touched, start_date: true })}
              value={form.start_date}
            />
            <TextField
              error={!!form.errors.start_time}
              type="time"
              size="small"
              variant="outlined"
              style={{ width: '100%' }}
              {...{ inputProps: { max: startTimeMax } }}
              InputLabelProps={{ shrink: true }}
              onChange={(e) => {
                setTouched({ ...touched, start_time: true })
                form.set('start_time', e.target.value)
              }}
              onBlur={() => setTouched({ ...touched, start_time: true })}
              value={form.start_time}
            />
            <Text
              style={{ padding: 8, paddingLeft: 0, paddingRight: 0, color: color.shade50 }}
              variant="body1"
              text={Intl.DateTimeFormat().resolvedOptions().timeZone}
            />
          </div>
          <div style={END_DATE}>
            <Text variant="body2" text="End" style={{ marginBottom: 7, color: color.shade70 }} />
            <TextField
              error={touched.end_date && !!form.errors.end_date}
              helperText={touched.end_date && form.errors.end_date?.message}
              variant="outlined"
              {...{
                inputProps: {
                  min: DateTime.fromISO(form.start_date).toFormat('yyyy-MM-dd'),
                },
              }}
              InputLabelProps={{ shrink: false }}
              size="small"
              style={{ marginRight: 8, marginBottom: 10, display: 'flex', width: '100%' }}
              type="date"
              onChange={(e) => {
                setTouched({ ...touched, end_date: true })
                if (!e.target.value) {
                  return
                }
                form.set('end_date', e.target.value)
              }}
              onBlur={() => {
                setTouched({ ...touched, end_date: true })
              }}
              value={form.end_date}
            />
            <TextField
              error={!!form.errors.end_time}
              type="time"
              size="small"
              variant="outlined"
              style={{ width: '100%' }}
              {...{ inputProps: { min: endTimeMin } }}
              InputLabelProps={{ shrink: true }}
              onChange={(e) => {
                setTouched({ ...touched, end_time: true })
                form.set('end_time', e.target.value)
              }}
              onBlur={() => setTouched({ ...touched, end_time: true })}
              value={form.end_time}
            />
            <Text
              style={{ padding: 8, paddingLeft: 0, paddingRight: 0, color: color.shade50 }}
              variant="body1"
              text={Intl.DateTimeFormat().resolvedOptions().timeZone}
            />
          </div>
        </div>
        <div style={{ flexDirection: 'row', marginTop: -20 }}>
          <FormControl variant="standard" style={{ width: '100%' }}>
            <div style={SWITCH_ROW}>
              <div style={SWITCH_TEXT}>
                <Text
                  variant="body1Medium"
                  style={{ marginBottom: 8 }}
                  text="Max Competitor Leaderboard Length"
                />
                <Text
                  variant="body2Medium"
                  text="Display only the top # users on the leaderboard."
                  style={{ color: color.shade50 }}
                />
              </div>
              <div style={{ display: 'flex', flexWrap: 'nowrap' }}>
                <TextField
                  variant="outlined"
                  type="number"
                  disabled={!form.max_leaderboard_length_enabled}
                  onChange={(e) => {
                    if (!e.target.value) {
                      form.set('max_leaderboard_length', null)
                    } else {
                      form.set('max_leaderboard_length', parseInt(e.target.value))
                    }
                  }}
                  style={{ width: 60, marginRight: 22 }}
                  value={form.max_leaderboard_length}
                />
              </div>
              <Switch
                checked={form.max_leaderboard_length_enabled}
                onChange={(e) => form.set('max_leaderboard_length_enabled', e.target.checked)}
              />
            </div>
            <div style={SWITCH_ROW}>
              <div style={SWITCH_TEXT}>
                <Text style={{ marginBottom: 8 }} variant="body1Medium" text="Notify Users" />
                <Text
                  variant="body2Medium"
                  text="Send a notification to all users in this contest."
                  style={{ color: color.shade50, marginRight: 60 }}
                />
              </div>
              <Switch
                checked={form.notify_users}
                onChange={(e) => form.set('notify_users', e.target.checked)}
              />
            </div>
          </FormControl>
        </div>
      </form>
    </div>
  )
})

const CONTAINER: React.CSSProperties = {
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-start',
  overflowY: 'auto',
  // overflowX: 'hidden',
}
const FORM: React.CSSProperties = {
  justifyContent: 'space-evenly',
  paddingRight: 8,
  paddingLeft: 8,
}
const SWITCH_ROW: React.CSSProperties = {
  display: 'flex',
  marginTop: 0,
  marginBottom: 22,
  alignItems: 'center',
  justifyContent: 'space-between',
}
const SWITCH_TEXT: React.CSSProperties = { flex: 1, display: 'flex', flexDirection: 'column' }
const DATE_CONTAINER: React.CSSProperties = {
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  marginBottom: 15,
}
const START_DATE: React.CSSProperties = {
  display: 'flex',
  flexDirection: 'column',
  marginBottom: 20,
  flex: 1,
  marginRight: 14,
}
const END_DATE: React.CSSProperties = {
  display: 'flex',
  flexDirection: 'column',
  marginBottom: 20,
  flex: 1,
}
const START_DATE_FIELD: React.CSSProperties = {
  marginRight: 8,
  marginBottom: 10,
  display: 'flex',
  width: '100%',
}
