import React, { useState } from 'react'
import { observer } from 'mobx-react-lite'
import { Route, Switch } from 'react-router-dom'
import { LocalOffer } from '@mui/icons-material'

import { Header } from '@trivie/ui-web/components/header'
import { CreateTag } from '@trivie/ui-web/components/create-tag'
import { useStores } from '@trivie/core'
import { TableHelper } from '../../../tables'
import { TagsDetailsScreen } from './tags-details-screen'
import { TagTable } from '../../../tables/tag-table'

export const TagsScreen: React.FunctionComponent = observer(() => {
  const { manageUserStore } = useStores()
  const { permissions } = manageUserStore

  const [showCreate, setShowCreate] = useState(false)
  const [tableHelper] = useState(new TableHelper('list_tags', 'tags', 'tags'))
  const [loading, setLoading] = useState(false)

  const onCreate = () => {
    setLoading(true)
    tableHelper.load().then(() => setLoading(false))
  }

  const primary = {
    disabled: !permissions?.permissions.some((p) => p.code === 'can_create_assignment'),
    startIcon: <LocalOffer />,
    onClick: () => setShowCreate(true),
    text: 'Create Tag',
  }

  return (
    <Switch>
      <Route exact path="/people/tags">
        <Header divider title="Tags" primaryAction={primary} />
        <TagTable forceLoading={loading} tableHelper={tableHelper} />
        {showCreate && (
          <CreateTag onCreate={onCreate} open={showCreate} onClose={() => setShowCreate(false)} />
        )}
      </Route>
      <Route path="/people/tags/:id">
        <TagsDetailsScreen />
      </Route>
    </Switch>
  )
})
