import React from 'react'
import { TextField as MuiTextField, TextFieldProps as MuiTextFieldProps } from '@mui/material'
import { alpha } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { TextStyle } from 'react-native'
import { color } from '../../theme'

// const useStyles = makeStyles((theme) => ({
//   root: {
//     '& .MuiInputBase-multiline': {
//       padding: 0,
//     },
//     '& .MuiInputBase-input': {
//       width: '100%',
//       fontSize: 14,
//       fontFamily: 'Inter',
//       marginLeft: -46,
//       paddingLeft: 39,
//       border: '1px solid rgba(0, 0, 0, 0.1)',
//       borderRadius: 10,
//       transition: theme.transitions.create(['outline', 'background-color', 'box-shadow']),
//       '&:hover': {
//         border: '2px solid rgba(223,226,249,1)',
//         marginLeft: -47,
//         marginTop: -1,
//         marginBottom: -1,
//       },

//       '&:focus': {
//         boxShadow: `${alpha(theme.palette.primary.main, 0.25)} 0 0 0 0.2rem`,
//         border: '1px solid rgba(27,89,248,1)',
//         outline: '2px solid rgba(223,226,249,1)',
//         marginTop: 0,
//         marginBottom: 0,
//         marginLeft: -46,
//       },
//     },
//     '& .MuiOutlinedInput-multiline': {
//       paddingLeft: 0,
//       paddingRight: 0,
//       padding: '0px !important',
//     },
//     '& 	.MuiOutlinedInput-notchedOutline': {
//       border: 'none',
//     },
//   },
//   rootSansAdornment: {
//     '& .MuiInputBase-root, & .MuiInputBase-multiline': {
//       padding: 0,
//     },
//     '& 	.MuiInputBase-input': {
//       '&::placeholder': {
//         color: color.darkenGrey,
//         opacity: 1,
//       },

//       width: '100%',
//       marginLeft: 0,
//       padding: 12,
//       border: '1px solid rgba(0, 0, 0, 0.1)',
//       borderRadius: 6,
//       transition: theme.transitions.create(['outline', 'background-color', 'box-shadow']),
//       '&:hover': {
//         border: '2px solid rgba(223,226,249,1)',
//         paddingLeft: 12,
//         marginRight: -1,
//         marginLeft: -1,
//         marginBottom: -1,
//         marginTop: -1,
//       },
//       '&:focus': {
//         boxShadow: `${alpha(theme.palette.primary.main, 0.25)} 0 0 0 0.2rem`,
//         borderColor: theme.palette.primary.main,
//         border: '1px solid rgba(27,89,248,1)',
//         outline: '2px solid rgba(223,226,249,1)',
//         paddingLeft: 12,
//         marginRight: 0,
//         marginLeft: 0,
//         marginBottom: 0,
//         marginTop: 0,
//       },
//     },

//     '& .MuiOutlinedInput-multiline': {
//       paddingLeft: 0,
//       paddingRight: 0,
//     },
//     '& 	.MuiOutlinedInput-notchedOutline': {
//       border: 'none',
//     },
//   },
// }))

interface TextFieldProps {
  onChangeText?: (e: any) => void
  InputProps?: any
  placeholderTextColor?: string
  inputStyle?: TextStyle
  helperTextClass?: string
}

export function TextField(props: TextFieldProps & MuiTextFieldProps) {
  const {
    InputProps,
    size,
    rows,
    onChange,
    onChangeText,
    onFocus,
    onBlur,
    value,
    placeholder,
    placeholderTextColor,
    defaultValue,
    error,
    multiline,
    variant,
    children,
    style,
    inputStyle,
    label,
    id,
    helperText,
    helperTextClass,
    type,
    disabled,
    ...rest
  } = props

  const sAdornment = InputProps?.startAdornment ? InputProps.startAdornment : null

  // const classes = useStyles()

  return (
    <MuiTextField
      disabled={disabled}
      multiline={multiline}
      // className={sAdornment ? classes.root : classes.rootSansAdornment}
      InputProps={{
        startAdornment: sAdornment,
        style: InputProps?.inputStyle ?? {},
      }}
      inputProps={{
        style: {
          padding: multiline ? 10 : undefined,
        },
      }}
      variant={variant}
      value={value}
      onChange={onChange}
      onFocus={onFocus}
      onBlur={onBlur}
      // placeholderTextColor={placeholderTextColor || color.darkenGrey}
      size={size}
      rows={rows}
      style={style}
      // inputStyle={inputStyle}
      defaultValue={defaultValue}
      placeholder={placeholder}
      error={error}
      label={label}
      id={id}
      helperText={helperText}
      FormHelperTextProps={{
        classes: {
          root: helperTextClass,
        },
      }}
      type={type}
      {...rest}
    >
      {children}
    </MuiTextField>
  )
}
