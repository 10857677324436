import { AssignedQuiz } from '../../models/UserQuiz'
import { ApiResponse, ApisauceInstance } from 'apisauce'
import { Instance } from 'mobx-state-tree'
import { AssignedTopic } from '../../models/AssignedTopic'
import { RawHourlyActivity } from '../../models/RawHourlyActivity'
import { RawUserActivity } from '../../models/RawUserActivity'
import { UserAssignmentStat } from '../../models/UserAssignmentStat'
import { UserGeneralStats } from '../../models/UserGeneralStats'
import { UserLevelAndPoints } from '../../models/UserLevelAndPoints'
import { UserOverviewStats } from '../../models/UserOverviewStats'
import { UserProfile } from '../../models/UserProfile'
import { transformHourlyActivity } from '../../utils/transformHourlyActivity'
import { handleResponse } from '../handleResponse'
import { Settings } from '../../models/Settings'
import { UserTopicKnowledgeGapResponse } from '../../models/UserTopicKnowledgeGapResponse'
import { UserQuestionKnowledgeGap } from '../../models/UserQuestionKnowledgeGap'
import { Admin } from '../../models/Admin'
import { Branding } from '../../models/Branding'
import { UserGameStats } from '../../models/UserGameStats'
import { UserAdaptive } from '../../models/UserAdaptive'
import { UserSession } from '../../models/UserSession'
import { Badge } from '../../models/Badge'
import { APIKeys } from '../../models/response-models'
import { QuizScoreboard, QuizScoreboardRecall } from '../../models/QuizScoreboard'
import { userPermissionsResponse, UserResourceResponse } from '../../models/response-models/user'
import {
  MedalDetailResponse,
  UserCertificationDetail,
  UserCertificationMedals,
  UserMedalDetail,
} from '../../models/UserCertificationMedal'
import { UserLevelModelType } from '../../models/base/user/UserLevelModel'

export const UserApi = {
  apisauce: {} as ApisauceInstance,
  getUserDataRequest: async () => {
    const response: ApiResponse<object> = await UserApi.apisauce.get(`/reports/data_request/`, {})
    return handleResponse(response)
  },
  getUpNext: async (id: number) => {
    const response: ApiResponse<any> = await UserApi.apisauce.get(`/users/${id}/up_next/`, {})
    return handleResponse(response)
  },
  handleTerms: async (payload: { decision: string; token: string; workspace_id: string }) => {
    const response: ApiResponse<object> = await UserApi.apisauce.post(`/users/terms/`, payload)
    return handleResponse(response)
  },

  gdprConsent: async (id: number, payload: { consent_granted: boolean; timestamp: string }) => {
    const response: ApiResponse<object> = await UserApi.apisauce.post(
      `/users/${id}/consent/`,
      payload,
    )
    return handleResponse(response)
  },

  getUserProfile: async (id: number | string) => {
    const response: ApiResponse<Instance<typeof UserProfile>> = await UserApi.apisauce.get(
      `users/${id}/profile/`,
      {},
    )

    return handleResponse(response)
  },
  getPermissions: async (id: number | string) => {
    const response: ApiResponse<Instance<typeof userPermissionsResponse>> =
      await UserApi.apisauce.get(`users/${id}/permissions/`, {})

    return handleResponse(response)
  },
  getUserOverviewStats: async (userId: number, startDatetime: string, endingDatetime: string) => {
    const urlQuery = new URLSearchParams({
      start_datetime: startDatetime,
      end_datetime: endingDatetime,
    })

    const response: ApiResponse<Instance<typeof UserOverviewStats>> = await UserApi.apisauce.get(
      `/users/${userId}/?${urlQuery.toString()}`,
    )

    return handleResponse(response)
  },
  getUserLevelAndPoints: async (
    userId: number,
    startingDatetime: string,
    endingDatetime: string,
    details?: boolean,
  ) => {
    const urlQuery = new URLSearchParams({
      start_datetime: startingDatetime,
      end_datetime: endingDatetime,
      details: details ? 'true' : 'false',
    })

    const response: ApiResponse<Instance<typeof UserLevelAndPoints>> = await UserApi.apisauce.get(
      `/users/${userId}/level_and_points/?${urlQuery.toString()}`,
    )
    return handleResponse(response)
  },
  getUserLevels: async () => {
    const response: ApiResponse<Array<UserLevelModelType>> =
      await UserApi.apisauce.get('/user_levels/')
    return handleResponse(response)
  },
  bulkImportUsers: async (payload) => {
    const formData = new FormData()
    formData.append('upload_file', payload.upload_file)
    formData.append('send_email_invite', payload.send_email_invite)
    formData.append('send_sms_invite', payload.send_sms_invite)
    if (payload.send_sms_invite || payload.send_email_invite) {
      formData.append('send_datetime', payload.send_datetime)
    }
    const response: ApiResponse<any> = await UserApi.apisauce.post(`/users/bulk_upload/`, formData)
    return handleResponse(response)
  },
  updateUserLevels: async (levels: UserLevelModelType[]) => {
    const response: ApiResponse<Array<UserLevelModelType>> = await UserApi.apisauce.patch(
      '/user_levels/',
      levels,
    )
    return handleResponse(response)
  },
  updateUserLevelIcon: async (payload: { file: File; userLevelId: number }) => {
    const formData = new FormData()

    if (payload.file) {
      formData.append('icon', payload.file)
    }

    const response: ApiResponse<{
      resource_id: number
    }> = await UserApi.apisauce.patch(`/user_levels/${payload.userLevelId}/icon/`, formData)

    return handleResponse(response)
  },
  getUserGeneralStats: async (userId: number, startingDatetime: string, endingDatetime: string) => {
    const urlQuery = new URLSearchParams({
      start_datetime: startingDatetime,
      end_datetime: endingDatetime,
    })

    const response: ApiResponse<Instance<typeof UserGeneralStats>> = await UserApi.apisauce.get(
      `/users/${userId}/general_stats/?${urlQuery.toString()}`,
    )

    return handleResponse(response)
  },
  getUserHourlyActivity: async (
    userId: number,
    startingDatetime: string,
    endingDatetime: string,
  ) => {
    const urlQuery = new URLSearchParams({
      start_datetime: startingDatetime,
      end_datetime: endingDatetime,
    })

    const response: ApiResponse<Array<Instance<typeof RawHourlyActivity>>> =
      await UserApi.apisauce.get(`/users/${userId}/hourly_activity/?${urlQuery.toString()}`)

    const transformedResponse = {
      ...response,
      data: response.ok ? transformHourlyActivity(response.data!) : null,
    }

    return handleResponse(transformedResponse)
  },
  getUserActivity: async (userId: number) => {
    const response: ApiResponse<Array<Instance<typeof RawUserActivity>>> =
      await UserApi.apisauce.get(`/users/${userId}/activity/`)

    return handleResponse(response)
  },
  getUserAssignments: async (userId: number) => {
    const response: ApiResponse<Array<Instance<typeof UserAssignmentStat>>> =
      await UserApi.apisauce.get(`/users/${userId}/assignments/`)

    return handleResponse(response)
  },
  getUserTopics: async (userId: number) => {
    const response: ApiResponse<Array<Instance<typeof AssignedTopic>>> = await UserApi.apisauce.get(
      `/users/${userId}/topics/`,
    )

    return handleResponse(response)
  },
  getUserQuizzes: async (userId: number | string) => {
    const response: ApiResponse<Array<Instance<typeof AssignedQuiz>>> = await UserApi.apisauce.get(
      `/users/${userId}/quizzes/`,
    )

    return handleResponse(response)
  },
  getUserResources: async (userId: number | string) => {
    const response: ApiResponse<Array<Instance<typeof UserResourceResponse>>> =
      await UserApi.apisauce.get(`/users/${userId}/resources/`)

    return handleResponse(response)
  },
  getScoreboardForQuiz: async (result_id: string, user_id: string, topic_id: string | null) => {
    const urlQuery = topic_id
      ? new URLSearchParams({
          result_id,
          user_id,
          topic_id,
        })
      : new URLSearchParams({
          result_id,
          user_id,
        })
    const response: ApiResponse<Instance<typeof QuizScoreboard>> = await UserApi.apisauce.get(
      `/game/scoreboard/?${urlQuery.toString()}`,
      {},
    )
    return handleResponse(response)
  },
  getScoreboardRecallForQuiz: async (
    result_id: string,
    user_id: string,
    topic_id: string | null,
  ) => {
    const urlQuery = topic_id
      ? new URLSearchParams({
          result_id,
          user_id,
          topic_id,
        })
      : new URLSearchParams({
          result_id,
          user_id,
        })
    const response: ApiResponse<Instance<typeof QuizScoreboardRecall>> = await UserApi.apisauce.get(
      `/game/scoreboard_recall/?${urlQuery.toString()}`,
      {},
    )
    return handleResponse(response)
  },
  getSettings: async () => {
    const response: ApiResponse<Instance<typeof Settings>> =
      await UserApi.apisauce.get(`/settings/`)
    return handleResponse(response)
  },
  getUserTopicGaps: async (userId: number) => {
    const response: ApiResponse<Instance<typeof UserTopicKnowledgeGapResponse>> =
      await UserApi.apisauce.get(`/users/${userId}/topic_gaps/`)

    return handleResponse(response)
  },
  getUserQuestionGaps: async (userId: number) => {
    const response: ApiResponse<Instance<typeof UserQuestionKnowledgeGap>> =
      await UserApi.apisauce.get(`/users/${userId}/question_gaps/`)

    return handleResponse(response)
  },
  getAdmins: async () => {
    const response: ApiResponse<Array<Instance<typeof Admin>>> =
      await UserApi.apisauce.get(`/settings/admins/`)
    return handleResponse(response)
  },
  createZapierAPIKey: async (key_name: string) => {
    const response: ApiResponse<any> = await UserApi.apisauce.post(`/api_key/create/`, {
      key_name,
    })
    return handleResponse(response)
  },
  deleteZapierAPIKey: async (token: string) => {
    const response: ApiResponse<any> = await UserApi.apisauce.delete(
      `/api_key/delete/`,
      {},
      { data: { key: token } },
    )
    return handleResponse(response)
  },

  getZapierAPIKey: async () => {
    const response: ApiResponse<Instance<typeof APIKeys>> =
      await UserApi.apisauce.get(`/api_key/list/`)
    return handleResponse(response)
  },
  getBranding: async (userId: number) => {
    const response: ApiResponse<Instance<typeof Branding>> = await UserApi.apisauce.get(
      `/users/${userId}/branding/`,
    )

    return handleResponse(response)
  },

  getGameStats: async (userId: number) => {
    const response: ApiResponse<Instance<typeof UserGameStats>> = await UserApi.apisauce.get(
      `/users/${userId}/game_stats/`,
    )

    return handleResponse(response)
  },

  getAdaptive: async (userId: number) => {
    const response: ApiResponse<Instance<typeof UserAdaptive>> = await UserApi.apisauce.get(
      `/users/${userId}/adaptive/`,
    )

    return handleResponse(response)
  },
  startSession: async (userId: number) => {
    const response: ApiResponse<Instance<typeof UserSession>> = await UserApi.apisauce.get(
      `/users/${userId}/session/start/`,
    )

    return handleResponse(response)
  },
  endSession: async (userId: number, sessionId: number) => {
    const response: ApiResponse<Record<string, never>> = await UserApi.apisauce.post(
      `/users/${userId}/session/${sessionId}/end/`,
    )

    return handleResponse(response)
  },
  getBadgesForUser: async (id: number) => {
    const response: ApiResponse<Array<Instance<typeof Badge>>> = await UserApi.apisauce.get(
      `/users/${id}/badges/`,
    )
    return handleResponse(response)
  },
  getBadgeDetail: async (id: number) => {
    const response: ApiResponse<Instance<typeof Badge>> = await UserApi.apisauce.get(
      `/badges/${id}/`,
    )
    return handleResponse(response)
  },
  getCertsMedalsForUser: async (id: number) => {
    const response: ApiResponse<Instance<typeof UserCertificationMedals>> =
      await UserApi.apisauce.get(`/users/${id}/certifications_medals/`)

    return handleResponse(response)
  },
  getMedalDetail: async () => {
    const response: ApiResponse<Array<Instance<typeof MedalDetailResponse>>> =
      await UserApi.apisauce.get(`/medals/`)
    return handleResponse(response)
  },
  getCertificationDetail: async (user_id: number, certification_id: number) => {
    const response: ApiResponse<Instance<typeof UserCertificationDetail>> =
      await UserApi.apisauce.get(`/users/${user_id}/certifications/${certification_id}/`)
    return handleResponse(response)
  },
  getUserMedalDetail: async (user_id: number, user_medal_id: number) => {
    const response: ApiResponse<Instance<typeof UserMedalDetail>> = await UserApi.apisauce.get(
      `/users/${user_id}/medal/${user_medal_id}/`,
    )
    return handleResponse(response)
  },
  getAppRating: async (user_id: number) => {
    const response: ApiResponse<{ ready_for_feedback: boolean }> = await UserApi.apisauce.get(
      `/users/${user_id}/app_ratings/`,
    )
    return handleResponse(response)
  },
  saveAppRating: async (user_id: number, rating: number | null, dont_show_again?: boolean) => {
    const response: ApiResponse<any> = await UserApi.apisauce.post(
      `/users/${user_id}/app_ratings/`,
      { rating, dont_show_again },
    )
    return handleResponse(response)
  },
  getUsers: async (
    type: 'users' | 'topics' | 'quizzes' | 'groups' | 'tags' | 'paths',
    meta?: boolean,
    columns?: any,
    view_name?: string,
    id?: number,
    view_id?: number,
  ) => {
    const payload = { view_name }
    if (columns && columns.length > 0) {
      payload['columns'] = columns
    }

    const response: ApiResponse<any> = await UserApi.apisauce.post(
      id
        ? `/${type}/${id}/users/?meta=${meta}&asynchronous=${false}`
        : `/${type}/?meta=${meta}&asynchronous=${false}`,
      payload,
    )
    return handleResponse(response)
  },
  getResults: async (
    type: 'users' | 'topics' | 'quizzes' | 'groups' | 'tags',
    meta?: boolean,
    columns?: any,
    view_name?: string,
    id?: number,
    view_id?: number,
  ) => {
    const payload = { view_name }
    if (columns && columns.length > 0) {
      payload['columns'] = columns
    }

    const response: ApiResponse<any> = await UserApi.apisauce.post(
      `/users/${id}/results?meta=${meta}&asynchronous=${false}`,
      payload,
    )
    return handleResponse(response)
  },
  getAllUsers: async (
    meta?: boolean,
    columns?: any,
    view_name?: string,
    // view_id?: number
  ) => {
    const payload = { view_name }
    if (columns && columns.length > 0) {
      payload['columns'] = columns
    }

    const response: ApiResponse<any> = await UserApi.apisauce.post(
      `/users/?meta=${meta}&asynchronous=${false}`,
      payload,
    )
    return handleResponse(response)
  },

  getBilling: async () => {
    const response: ApiResponse<any> = await UserApi.apisauce.get('/billing/')
    return handleResponse(response)
  },

  createSubscription: async (payload: { customer_id: string; price_id: string }) => {
    const response: ApiResponse<any> = await UserApi.apisauce.post(
      '/billing/subscription/',
      payload,
    )
    return handleResponse(response)
  },
}
