import React, { useEffect } from 'react'
import { Alert, AlertProps, Slide } from '@mui/material'
import { useStores } from '@trivie/core'
import { observer } from 'mobx-react-lite'

interface ToastProps extends AlertProps {}

export const Toast = observer((props: ToastProps) => {
  const { toastStore } = useStores()
  const { toast, clear, dismiss } = toastStore

  const onClose = () => {
    dismiss()
    setTimeout(() => {
      clear()
    }, 400)
  }

  useEffect(() => {
    if (toast?.autoDismiss) {
      setTimeout(() => {
        onClose()
      }, toast?.autoDismiss)
    }
  }, [toast?.autoDismiss])

  const sx =
    toast?.position === 'bottom-right'
      ? ({
          zIndex: 9999,
          minWidth: '500px',
          position: 'absolute',
          bottom: 40,
          right: 40,
          alignItems: 'center',
          borderRadius: '8px',
          fontSize: '14px',
          '& .MuiAlert-action': {
            padding: '0px !important',
          },
          '& .MuiAlert-message': {
            flex: 1,
          },
        } as any)
      : {
          zIndex: 9999,
          fontSize: '14px',
          width: '100%',
          alignItems: 'center',
          borderRadius: 0,
          textAlign: 'center',
          '& .MuiAlert-action': {
            padding: '0px !important',
          },
          '& .MuiAlert-message': {
            flex: 1,
          },
        }

  return (
    <Slide
      direction={toast?.position === 'bottom-right' ? 'up' : 'down'}
      in={toastStore.open}
      mountOnEnter
      unmountOnExit
    >
      <Alert
        variant="filled"
        severity={toast?.severity}
        onClose={onClose}
        icon={toast?.icon || false}
        sx={sx}
      >
        {toast?.message}
      </Alert>
    </Slide>
  )
})
