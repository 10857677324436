import React, { forwardRef, useEffect, useState } from 'react'
import ReactPlayer, { ReactPlayerProps } from 'react-player/lazy'
import { Text } from '@trivie/ui-web/components/text'
import { Box, Container, IconButton, Slider, Stack } from '@mui/material'
import {
  Fullscreen,
  PauseRounded,
  PlayArrowRounded,
  ReplayRounded,
  VolumeOffRounded,
  VolumeUpRounded,
} from '@mui/icons-material'
import { Duration } from 'luxon'

import { color } from '@trivie/ui-web/theme'

export interface VideoPlayerProps extends ReactPlayerProps {}
export const VideoPlayer = forwardRef((props: VideoPlayerProps, ref: any) => {
  const { url } = props

  const [progress, setProgress] = useState({
    loaded: 0,
    loadedSeconds: 0,
    played: 0,
    playedSeconds: 0,
  })

  const [volume, setVolume] = useState<undefined | number>()
  const [pos, setPos] = useState(0)
  const [muted, setMuted] = useState<boolean>(false)
  const [playing, setPlaying] = useState<boolean>(false)
  const [duration, setDuration] = useState(0)
  const [ready, setReady] = useState(false)
  const [buffering, setBuffering] = useState(false)
  const [scrubbing, setScrubbing] = useState(false)

  const { playedSeconds, loaded, played } = progress
  const cur = Duration.fromObject({ seconds: playedSeconds }).toFormat('m:ss')
  const dur = Duration.fromObject({ seconds: duration }).toFormat('m:ss')

  const showCustomControls = false

  useEffect(() => {
    // Update playback position if user is not scrubbing
    if ((!scrubbing && !buffering && pos !== duration) || Math.ceil(pos) === duration) {
      setPos(progress.playedSeconds)
    }
  }, [progress.playedSeconds])

  const scrub = (e: any) => {
    // Start scrub - visually update slider, but wait to seek
    setScrubbing(true)
    setPos(e.target.value)
  }

  const onScrub = (e: any) => {
    // End scrub - on mouse up slider - seek to position (sec)
    setScrubbing(false)
    seekTo(pos)
  }

  const seekTo = (seconds) => {
    ref?.current?.seekTo(seconds)
  }

  const toggleVolume = () => {
    setMuted(!muted)
  }

  const playPause = () => {
    if (playing) {
      onPause()
    } else if (Math.ceil(pos) === duration) {
      setPos(0)
      seekTo(0)
      onPlay()
    } else {
      onPlay()
    }
  }

  const onPlay = () => {
    setPlaying(true)
  }

  const onPause = () => {
    setPlaying(false)
  }

  const onDuration = (sec) => {
    setDuration(sec)
  }

  const onProgress = (state) => {
    setProgress(state)
  }

  const onBuffer = () => {
    setBuffering(true)
  }

  const onBufferEnd = () => {
    setBuffering(false)
  }

  return (
    <div>
      <div id="video-player" style={{ zIndex: 9999, position: 'relative', paddingTop: '56.25%' }}>
        <ReactPlayer
          controls={!showCustomControls}
          onDuration={onDuration}
          pip={false}
          playing={playing}
          light
          onBuffer={onBuffer}
          onBufferEnd={onBufferEnd}
          volume={volume}
          onPlay={onPlay}
          onProgress={onProgress}
          ref={ref}
          muted={muted}
          onPause={onPause}
          style={{ position: 'absolute', top: 0, left: 0 }}
          width="100%"
          height="100%"
          url={url}
          progressInterval={50}
          onReady={(p) => {
            setReady(true)
          }}
        />
      </div>
      {ready && showCustomControls && (
        <Box
          p={1}
          sx={{
            border: `1px solid ${color.shade30}`,
            borderBottomLeftRadius: 2,
            borderBottomRightRadius: 2,
            borderTop: 'none',
          }}
          id="video-controls"
        >
          <Stack useFlexGap direction="row" gap={1}>
            <IconButton size="small" onClick={playPause}>
              {Math.ceil(pos) === duration ? (
                <ReplayRounded />
              ) : !playing ? (
                <PlayArrowRounded />
              ) : (
                <PauseRounded />
              )}
            </IconButton>
            <Stack width="100%" gap={2} alignItems="center" useFlexGap direction="row">
              <Stack mr={1} gap="4px" useFlexGap direction="row">
                <Text text={cur} variant="body2" />
                <Text text="/" variant="body2" />
                <Text text={dur} variant="body2" />
              </Stack>
              <Stack alignItems="center" sx={{ width: '100%' }}>
                <Slider
                  // sx={{ mb: 1 }}
                  size="small"
                  onChange={scrub}
                  onChangeCommitted={onScrub}
                  min={0}
                  step={1}
                  max={duration}
                  value={pos}
                  valueLabelDisplay="off"
                />
                {/* <LinearProgress variant="buffer" sx={{ width: '100%' }} value={loaded * 100} /> */}
              </Stack>
              <Stack direction="row" useFlexGap gap="4px">
                <IconButton size="small" onClick={toggleVolume}>
                  {!muted ? <VolumeUpRounded /> : <VolumeOffRounded />}
                </IconButton>
                {/* <IconButton size="small" onClick={toggleVolume}>
                  <Fullscreen />
                </IconButton> */}
              </Stack>
            </Stack>
          </Stack>
        </Box>
      )}
    </div>
  )
})
