import { types } from 'mobx-state-tree'

export const QuestionAnswerSummary = types.model('QuestionAnswerSummary').props({
  choice_id: types.number,
  text: types.string,
  correct: types.boolean,
  pct_selected: types.number,
  avg_time: types.number,
  order: types.maybeNull(types.number),
})
