import { cast, detach, flow, getSnapshot, Instance, SnapshotIn, types } from 'mobx-state-tree'
import { Question } from './Question'
import { Resource } from './Resource'
import { QuizApi, ResourceApi } from '../services/api-objects'
import { QuestionHelper } from '../helpers/QuestionHelper'
import { scrollToCitation } from '../utils/scrollToCitation'

const wait = (ms) => new Promise((res) => setTimeout(res, ms))

const quizErrors = {
  incorrectQuizLength: {
    key: 'incorrectQuizLength',
    problem: 'is not between 1 and 40 characters.',
    title: 'Title Length',
    text: 'Quizzes should have a title and it should be between 1 and 40 characters',
  },
  duplicateCreateQuiz: {
    key: 'duplicateCreateQuiz',
    title: 'Duplicated Quiz',
    text: 'This quiz already exists',
    problem: 'attempting to create new quiz with existing title and description.',
  },
  noValidQuestion: {
    key: 'noValidQuestion',
    problem: 'quiz must have at least one valid question associated.',
  },
  duplicateUpdateQuiz: {
    key: 'duplicateUpdateQuiz',
    title: 'Duplicate Quiz',
    text: 'A quiz with that name already exists',
    problem:
      'attempting to update existing quiz with a title and description that already exists in seperate quiz',
  },
  quizInAssignment: {
    key: 'quizInAssignment',
    problem: 'Quiz belongs to an active assignment.',
  },
}

const QuizSettings = types
  .model('Settings')
  .props({
    audience: types.maybe(types.string),
    voice: types.maybe(types.string),
    difficulty: types.maybe(types.string),
    multipleChoice: types.maybe(types.boolean),
    trueFalse: types.maybe(types.boolean),
    fillInTheBlank: types.maybe(types.boolean),
    questionCount: types.maybe(types.maybeNull(types.number)),
  })
  .actions((self) => {
    return {
      afterCreate() {
        self.audience = ''
        self.multipleChoice = true
        self.trueFalse = true
        self.fillInTheBlank = true
        self.voice = ''
        self.difficulty = 'Standard'
        self.questionCount = 10
      },
      setAudience(audience) {
        self.audience = audience
      },
      setMultipleChoice(mmc) {
        self.multipleChoice = mmc
      },
      setTrueFalse(tf) {
        self.trueFalse = tf
      },
      setFillInTheBlank(fitb) {
        self.fillInTheBlank = fitb
      },
      setVoice(voice) {
        self.voice = voice
      },
      setDifficulty(difficulty) {
        self.difficulty = difficulty
      },
      setQuestionCount(count) {
        self.questionCount = count
      },
    }
  })
  .views((self) => ({
    get question_types() {
      const qtypes = [] as any
      if (self.multipleChoice) {
        qtypes.push('multiple_choice')
      }
      if (self.trueFalse) {
        qtypes.push('true_false')
      }
      if (self.fillInTheBlank) {
        qtypes.push('fill_in_the_blank')
      }
      return qtypes
    },
  }))

export const ResourceDocument = types.model('ResourceDocument').props({
  resource_id: types.number,
  document_id: types.number,
  title: types.string,
  nodes: types.frozen(),
  created_at: types.string,
})
export const Quiz = types
  .model('Quiz')
  .props({
    infoConfirmed: types.maybe(types.boolean),
    touched: types.maybe(types.boolean),
    shuffle: types.maybeNull(types.boolean),
    quiz_id: types.maybeNull(types.number),
    title: types.maybeNull(types.string),
    questions: types.maybeNull(types.array(Question)),
    resources: types.maybeNull(types.array(Resource)),
    mercuryResource: types.maybe(Resource),
    is_locked: types.optional(types.literal(false), false),
    errors: types.maybeNull(types.array(types.string)),
    errorsCorrected: types.maybeNull(types.array(types.string)),
    keep_item_order: types.boolean,
    order: types.maybeNull(types.number),
    generatingQuestions: types.maybe(types.boolean),
    generatedCalls: types.maybe(types.number),
    chunks: types.maybe(types.frozen()),
    settings: types.maybe(QuizSettings),
    showSettings: types.maybe(types.boolean),
    showResourcePanel: types.optional(types.boolean, true),
    userClosedResourcePanel: types.optional(types.boolean, false),
    userClosedQuizPanel: types.optional(types.boolean, false),
    stopGenerating: types.maybe(types.boolean),
    generatingSummary: types.maybe(types.boolean),
    loadingDocument: types.maybe(types.boolean),
    resourceDocument: types.maybe(ResourceDocument),
    loadingDocumentStatus: types.maybe(types.string),
    continueGenerating: types.maybe(types.boolean),
    questionPool: types.maybe(types.array(Question)),
    generationCount: types.maybe(types.number),
    showQuizPanel: types.optional(types.boolean, true),
    generatingNodes: types.frozen(),
    selected: types.maybe(types.boolean),
    removingResources: types.frozen(),
    addingResources: types.frozen(),
    requiredResources: types.frozen(),
    imageUrl: types.frozen(),
    description: types.frozen(),
    keywords: types.frozen(),
    photo_url: types.frozen(),
    initialized: types.frozen(),
    generatedTitle: types.maybe(types.boolean),
    generatedImage: types.maybe(types.boolean),
    loadedResources: types.frozen(),
    documents: types.maybe(types.array(ResourceDocument)),
    duplicate_name: types.maybe(types.boolean),
    original_name: types.maybeNull(types.string),
  })
  .volatile(() => ({
    imageFile: null as File | null,
  }))
  .actions((self) => {
    return {
      afterCreate() {
        self.generatingNodes = []
        self.showSettings = false
        self.shuffle = !self.keep_item_order
        self.settings = QuizSettings.create({
          voice: '',
          audience: '',
          difficulty: 'Standard',
          multipleChoice: true,
          trueFalse: true,
          fillInTheBlank: true,
        })
        self.addingResources = []
        self.requiredResources = []
      },
      setImage(file: File | null) {
        self.imageUrl = null
        self.photo_url = null
        self.imageFile = file
      },
      select() {
        self.selected = true
      },
      deselect() {
        self.selected = false
      },
      setLoadingDocumentStatus(message: string) {
        self.loadingDocumentStatus = message
      },
      setLoadingDocument(status: boolean) {
        self.loadingDocument = status
      },
      selectQuestion(question) {
        const sel = self.questions?.find((q) => q.selected)
        if (sel) {
          sel.deselect()
        }
        question.select()
      },
      selectCitation(question) {
        const sel = self.questions?.find((q) => q.selected)
        if (sel) {
          sel.deselect()
        }
        const match = self.questions?.find(
          (q) => q.generated_question_id === question.generated_question_id,
        )
        match.select()
      },
      setResourceDocument(doc) {
        if (doc) {
          doc.nodes.content.unshift({
            type: 'heading',
            content: [
              {
                text: doc.title,
                type: 'text',
              },
            ],
            attrs: {
              level: 1,
            },
          })
          if (self.documents?.length) {
            const updated = self.documents?.filter((d) => doc.resource_id !== d.resource_id)
            self.documents = cast([...updated, doc])
          } else {
            self.documents = cast([doc])
          }
        }
        self.resourceDocument = doc
      },
      setGeneratingQuestions(status: boolean) {
        self.generatingQuestions = status
      },
      increaseGeneratedCalls() {
        self.generatedCalls = (self.generatedCalls ?? 1) + 1
      },
      resetGeneratedCalls() {
        self.generatedCalls = 0
      },
      setChunks(chunks: number) {
        self.chunks = chunks
      },
      setShuffleOrder(shuffle: boolean) {
        self.keep_item_order = !shuffle
        self.shuffle = shuffle
      },
      set<K extends keyof SnapshotIn<typeof self>, T extends SnapshotIn<typeof self>>(
        key: K,
        value: T[K],
      ) {
        self[key] = cast(value)
      },
      setMercuryResource: (resource: Instance<typeof Resource> | undefined) => {
        if (resource) {
          const identityBrokenResource = Resource.create({
            ...resource,
            identityBreaker: Math.random() * 9999999999,
          })
          self.mercuryResource = cast(identityBrokenResource)
        } else {
          self.mercuryResource = undefined
        }
      },
      reorderQuestions(drag: number, drop: number) {
        const temp = self.questions?.slice()
        if (temp) {
          temp.splice(drop, 0, temp.splice(drag, 1)[0])
          self.questions = cast(temp)
        }
      },
      addQuestions(questions: Array<Instance<typeof Question>>) {
        // create questionObjects then filter out questions that are already on this quiz
        if (!self.questions) {
          self.questions = cast(questions)
        } else {
          const questionsToAdd = questions.filter(
            (q) =>
              !q.question_id ||
              self.questions!.some((question) => question.question_id !== q.question_id),
          )
          self.questions.replace(self.questions.concat(questionsToAdd))
        }
      },
      removeQuestions(questions: Array<Instance<typeof Question>>) {
        const filteredQuestions = (
          self.questions ?? ([] as Array<Instance<typeof Question>>)
        ).filter((q) => {
          // if q in questions where q === questions
          if (questions.some((question) => question === q)) {
            return false
            // if q is not new (meaning it has an id) and q.question_id is in questions
          } else if (
            !q.new &&
            questions.some((question) => question.question_id === q.question_id)
          ) {
            return false
            // else we didn't find it in questions so we can keep it
          } else {
            return true
          }
        })

        self.questions = cast(filteredQuestions)
      },
      updateRequiredResources(resource: any) {
        if (self.requiredResources && self.requiredResources?.length) {
          self.requiredResources = self.requiredResources.filter(
            (r) => r.resource_id !== resource.resource_id,
          )
        }

        if (self.requiredResources && self.requiredResources?.length) {
          self.requiredResources = [...self.requiredResources, resource]
        } else {
          self.requiredResources = [resource]
        }
      },
      addResource(resource: Instance<typeof Resource>) {
        if ((self as Instance<typeof Quiz>).hasResource(resource)) {
          return
        }

        if (self.removingResources && self.removingResources?.length) {
          self.removingResources = self.removingResources
            .filter((r) => r !== resource.resource_id)
            .map((r) => r)
        }

        if (self.addingResources && self.addingResources?.length) {
          self.addingResources = [...self.addingResources, resource]
        } else {
          self.addingResources = [resource]
        }

        const identityBrokenResource = Resource.create({
          ...getSnapshot(resource),
          identityBreaker: Math.random() * 9999999999,
        })
        if (!self.resources) {
          self.resources = cast([identityBrokenResource])
        } else {
          self.resources.push(identityBrokenResource)
        }
      },
      removeResource(resource: Instance<typeof Resource>) {
        let adding,
          remove = [] as any

        if (self.removingResources && self.removingResources?.length) {
          remove = [...self.removingResources, resource.resource_id]
        } else {
          remove = [resource.resource_id]
        }

        if (self.addingResources && self.addingResources?.length) {
          adding = self.addingResources.filter((r) => r.resource_id !== resource.resource_id)
        }

        const filteredResources = (
          self.resources ?? ([] as Array<Instance<typeof Resource>>)
        ).filter((r) => r.resource_id !== resource.resource_id)

        if (self.resources) {
          self.resources.replace(filteredResources)
        }

        self.removingResources = remove
        self.addingResources = adding
      },
      hasResource: (resource: Instance<typeof Resource>) => {
        if (!self.resources) {
          return false
        }

        return self.resources.some((r) => r.resource_id === resource.resource_id)
      },
      toPayload: () => {
        const payloadQuiz = getSnapshot(self)
        const final = {
          ...payloadQuiz,
          description: payloadQuiz?.description,
          title: payloadQuiz.title ?? '',
          questions: (self.questions ?? ([] as Array<Instance<typeof Question>>)).map(
            (q, index: number) => {
              return {
                ...q.toPayload(),
                order: payloadQuiz.keep_item_order ? index : null,
                choices: q.choices
                  .filter((c) => c.text || c.correct)
                  .map((choice, index2: number) => {
                    return {
                      ...choice,
                      order: q.keep_answer_order ? index2 : null,
                    }
                  }),
              }
            },
          ),
        }
        return final
      },
      clearErrors: () => {
        self.errors = cast([])
      },
      addToCorrectedErrors(correctedErrorsToAdd: Array<keyof typeof quizErrors>) {
        if (self.errorsCorrected) {
          self.errorsCorrected.push(...correctedErrorsToAdd)
        } else {
          self.errorsCorrected = cast(correctedErrorsToAdd)
        }
      },
      detachQuestions: () => {
        if (!self.questions) {
          return null
        }

        return self.questions.map((q) => detach(q))
      },
    }
  })
  .views((self) => ({
    get image() {
      if (self.imageFile) {
        return URL.createObjectURL(self.imageFile)
      } else if (self.imageUrl) {
        return self.imageUrl
      } else if (self.photo_url) {
        return self.photo_url
      } else {
        return null
      }
    },
    get selectedDocument() {
      if (self.documents?.length && self.mercuryResource) {
        const found = self.documents.find(
          (doc) => doc.resource_id === self.mercuryResource?.resource_id,
        )
        if (found) {
          return found
        }
      }
      return undefined
    },
    get generatingAll() {
      const g = self.questions?.filter((q) => q.generatingAll) ?? []
      return g.length > 0
    },
    get selectedQuestion() {
      return self.questions?.find((q) => q.selected)
    },
    get new() {
      return self.quiz_id === null
    },
    get approved() {
      const notApproved = self.questions?.filter((q) => !q.removing && !q.approved)
      return notApproved?.length === 0 && self.title && !this.generatingAll
    },
    get questionFormErrors() {
      const err = [] as any
      if (self.questions) {
        self.questions
          .filter((question) => !question.removing)
          .map((question) => {
            err.push(...question.allErrors)
          })
      }
      return err
    },
    get formErrors() {
      const errors: any = {}

      if (self.title?.length === 0 || self.title === null) {
        errors.quizTitle = {
          type: 'error',
          message: 'Quiz must have a title.',
        }
      } else if (self.duplicate_name) {
        errors.duplicateTitle = {
          type: 'error',
          message: 'This name is already in use.',
        }
      }
      return errors
    },
    get formWarnings() {
      const warnings: any = {}

      return warnings
    },
    get allErrors() {
      return [...Object.values(this.formErrors), ...this.questionFormErrors]
    },
    get allWarnings() {
      return [...Object.values(this.formWarnings), ...this.questionFormErrors]
    },
    get hasQuestions() {
      if (!self.questions?.length) {
        return false
      }

      return !self.questions.every((q) => q.isBlankQuestion)
    },
    get hasErrors() {
      // if no errors
      if (!self?.errors?.length) {
        return false
      }

      // if there are errors but no corrected errors
      if (!self.errorsCorrected?.length) {
        return true
      }

      const mappedErrors = self.errors.map((e) => {
        const error = Object.values(quizErrors).find((qe) => e.includes(qe.problem))
        if (!error) {
          throw new Error(`Could not map error ${e}`)
        } else {
          return error
        }
      })

      // if any errors have not been corrected
      return mappedErrors.some((me) => !self.errorsCorrected!.includes(me.key))
    },
    get hasDeepErrors() {
      if (this.hasErrors) {
        return true
      }

      if (self.questions?.some((q) => q.hasDeepErrors)) {
        return true
      }

      return false
    },
    get error() {
      if ((self as Instance<typeof Quiz>).hasError('incorrectQuizLength')) {
        return quizErrors['incorrectQuizLength']
      } else if ((self as Instance<typeof Quiz>).hasError('duplicateCreateQuiz')) {
        return quizErrors['duplicateCreateQuiz']
      } else if ((self as Instance<typeof Quiz>).hasError('duplicateUpdateQuiz')) {
        return quizErrors['duplicateUpdateQuiz']
      } else {
        return null
      }
    },
    hasError(errorKey: keyof typeof quizErrors) {
      // has errors
      if (!self.errors?.length) {
        return false
      }

      // does not have this error
      if (!self.errors.some((e) => e.includes(quizErrors[errorKey].problem))) {
        return false
      }

      // this error has been corrected
      if (self.errorsCorrected?.includes(errorKey)) {
        return false
      }

      // has errors, has this error, and the error hasn't been corrected
      return true
    },
    get isBlankQuiz() {
      // quiz is blank if it doesn't have a title and has no non-blank questions
      return (
        !self.title && (!self.questions?.length || self.questions.every((q) => q.isBlankQuestion))
      )
    },
    get hasDeepWarnings() {
      if (self.questions?.some((q) => q.hasDeepWarnings)) {
        return true
      }

      return false
    },
  }))
  .actions((self) => ({
    generateQuestions: flow(function* (
      resource_id: number,
      count: number,
      questionTypes = [],
      nodeIDs = [],
      summarize?,
    ) {
      // check generating already
      self.setGeneratingQuestions(true)
      self.set('generationCount', (self.generationCount ?? 0) + count)

      try {
        const result: Awaited<ReturnType<typeof ResourceApi.generateQuestions>> =
          yield ResourceApi.generateQuestions(
            resource_id,
            count,
            self.keywords ?? [],
            self.settings?.audience ? self.settings?.audience : '',
            self.settings?.voice ? self.settings?.voice : '',
            self.settings?.difficulty === 'Easy'
              ? 0
              : self.settings?.difficulty === 'Standard'
                ? 50
                : 100,
            questionTypes?.length
              ? questionTypes
              : ['multiple_choice', 'true_false', 'fill_in_the_blank'],
            nodeIDs,
            summarize,
          )

        if (result.ok && result.data) {
          yield (self as any).getResourceDocument(self.mercuryResource!.resource_id, true)

          const questions = result.data?.questions.map((q: any) => {
            const question = QuestionHelper.createNewQuestion(
              q.correct_choices?.length > 1
                ? 'Multiple Response'
                : q.question_type === 'true_false'
                  ? 'True or False'
                  : 'Multiple Choice',
            )
            const choices = q.choices.map((c, index) => {
              return {
                choice_id: null,
                text: c,
                correct: q.correct_choices?.includes(index) ?? false,
                touched: true,
                order: null,
                errors: null,
                errorsCorrected: null,
                warnings: null,
                acceptedWarnings: null,
              }
            })
            question.set('generated_question_id', q.generated_question_id)
            question.set('document_id', q.document_id)
            question.set('resource_id', self.mercuryResource?.resource_id)
            question.set('fact_id', q.fact_id)
            question.set('source_node_ids', q.source_node_ids)
            question.set('choices', choices)
            question.set('text', q.query)
            question.set('factoid', {
              factoid_id: null,
              text: q.feedback,
              resource_id: null,
            })
            question.set('touched', true)
            return question
          })

          if (!self.stopGenerating) {
            if (self.questions?.length === 1) {
              // if new question and hasn't been touched
              // else append to questions array as normal
              if (self.questions[0].new && !self.questions[0].touched) {
                self.set('questions', questions)
                self.questions[0].select()
                self.questions[0].set('touched', true)
                self.questions[0].set('generated_question_id', questions[0].generated_question_id)
              } else {
                self.set('questions', [...(self.questions ?? []), ...questions])
              }
            } else {
              self.set('questions', [...(self.questions ?? []), ...questions])
            }
          }
          self.setGeneratingQuestions(false)
          self.set(
            'generationCount',
            (self.generationCount ?? 0) - count < 0 ? 0 : (self.generationCount ?? 0) - count,
          )
          self.set('stopGenerating', false)

          return questions
        } else {
          self.set(
            'generationCount',
            (self.generationCount ?? 0) - count < 0 ? 0 : (self.generationCount ?? 0) - count,
          )
          self.setGeneratingQuestions(false)
          return false
        }
      } catch (error) {
        // self.set('generationCount', 0)
        self.set(
          'generationCount',
          (self.generationCount ?? 0) - count < 0 ? 0 : (self.generationCount ?? 0) - count,
        )
        self.setGeneratingQuestions(false)
        return false
      }
    }),
    generateTitle: flow(function* () {
      self.set('generatingSummary', true)
      if (self.questions) {
        try {
          const result: Awaited<ReturnType<typeof QuizApi.generateTitle>> =
            yield QuizApi.generateTitle(self.questions?.map((q) => q.text))
          if (result.ok && result.data) {
            self.set('generatingSummary', false)
            if (!self.title) {
              self.title = result.data.quiz_name
              self.set('generatedTitle', true)
            }
            self.description = result.data.summary
          }
        } catch (error) {
          return false
        }
      }
    }),
    getResourceDocument: async (resource_id: number, disableLoad?: boolean) => {
      if (!disableLoad) {
        self.setLoadingDocument(true)
      }
      return new Promise((res, rej) => {
        return ResourceApi.getResourceDocument(resource_id)
          .then((resp: any) => {
            if (resp.ok) {
              // check status and handle accordingly
              if (!disableLoad) {
                ResourceApi.getResourceStatus(resource_id).then((processing_status) => {
                  const found = self.resources?.find((r) => r.resource_id === resource_id)
                  self.mercuryResource?.set('processing_status', processing_status)
                  if (found) {
                    found.set('processing_status', processing_status)
                  }
                })
              }

              self.setLoadingDocument(false)
              if (resp.data && resp.data.nodes) {
                self.setResourceDocument(resp.data)
              }
              if (self.selectedQuestion) {
                setTimeout(() => {
                  scrollToCitation(self.selectedQuestion.generated_question_id)
                }, 500)
              }
              return res(resp.data)
            } else {
              return rej()
            }
          })
          .catch((e) => {
            if (e.kind === 'wait') {
              self.setLoadingDocumentStatus(e.message)
              return rej(e)
            } else {
              self.setLoadingDocumentStatus('Failed')
              self.setLoadingDocument(false)
            }
          })
      })
    },
  }))
