import { types } from 'mobx-state-tree'
import { StatisticHistory } from './StatisticHistory'

export const TopicDetailStat = types.model('TopicDetailStat').props({
  topic_id: types.number,
  title: types.string,
  file_url: types.maybeNull(types.string),
  current_knowledge: types.number,
  starting_knowledge: types.number,
  knowledge_gain: types.number,
  stats_history: types.array(StatisticHistory),
  pack_color: types.maybeNull(types.string),
  certification_enabled: types.boolean,
})
