import { types } from 'mobx-state-tree'

/**
 * TagRead Model
 */
export const TagReadModel = types.model('TagRead').props({
  __typename: types.optional(types.literal('TagRead'), 'TagRead'),
  id: types.integer,
  key: types.string,
  value: types.string,
})
