import React from 'react'

import { color } from '../../theme'
import { useHistory } from 'react-router'

export const Paywall = (props: any) => {
  const { plan, tagline, style } = props
  const history = useHistory()
  return (
    <>
      <div style={{ ...ROOT, ...style }}>
        <div onClick={() => history.push('/upgrade')} style={CONTAINER}>
          <div style={CONTENT}>
            <svg
              style={{ marginRight: 10 }}
              width="25"
              height="24"
              viewBox="0 0 25 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M12.2501 3C11.7901 3 11.3201 3.04 10.8501 3.14C8.09007 3.67 5.89007 5.9 5.37007 8.66C4.89007 11.27 5.85007 13.67 7.59007 15.22C8.02007 15.6 8.25007 16.13 8.25007 16.69V19C8.25007 20.1 9.15007 21 10.2501 21H10.5301C10.8801 21.6 11.5101 22 12.2501 22C12.9901 22 13.6301 21.6 13.9701 21H14.2501C15.3501 21 16.2501 20.1 16.2501 19V16.69C16.2501 16.14 16.4701 15.6 16.8901 15.23C18.3401 13.95 19.2501 12.08 19.2501 10C19.2501 6.13 16.1201 3 12.2501 3ZM12.7501 14H11.7501V11.41L9.92007 9.59L10.6301 8.88L12.2501 10.5L13.8701 8.88L14.5801 9.59L12.7501 11.42V14ZM13.7501 19C13.7401 19 13.7301 18.99 13.7201 18.99V19H10.7801V18.99C10.7701 18.99 10.7601 19 10.7501 19C10.4701 19 10.2501 18.78 10.2501 18.5C10.2501 18.22 10.4701 18 10.7501 18C10.7601 18 10.7701 18.01 10.7801 18.01V18H13.7201V18.01C13.7301 18.01 13.7401 18 13.7501 18C14.0301 18 14.2501 18.22 14.2501 18.5C14.2501 18.78 14.0301 19 13.7501 19ZM13.7501 17H10.7501C10.4701 17 10.2501 16.78 10.2501 16.5C10.2501 16.22 10.4701 16 10.7501 16H13.7501C14.0301 16 14.2501 16.22 14.2501 16.5C14.2501 16.78 14.0301 17 13.7501 17Z"
                fill="url(#paint0_linear_1721_53755)"
              />
              <defs>
                <linearGradient
                  id="paint0_linear_1721_53755"
                  x1="6.99723"
                  y1="3"
                  x2="21.8387"
                  y2="17.5845"
                  gradientUnits="userSpaceOnUse"
                >
                  <stop stop-color="#FFBF1A" />
                  <stop offset="1" stop-color="#FF4080" />
                </linearGradient>
              </defs>
            </svg>
            <span style={{ fontFamily: 'Inter', fontSize: 14, fontWeight: 400 }}>
              Upgrade to&nbsp;
              <span style={{ fontFamily: 'Inter', fontSize: 14, fontWeight: 600 }}>{plan}</span>
              &nbsp;{tagline}
            </span>
          </div>
        </div>
      </div>
    </>
  )
}

const ROOT: React.CSSProperties = {
  position: 'absolute',
  width: '100%',
  flexDirection: 'row',
  padding: 20,
  left: 0,
  zIndex: 999,
}
const CONTAINER: React.CSSProperties = {
  border: `1px solid ${color.shade10}`,
  padding: 20,
  background: '#fff',
  minHeight: 60,
  display: 'flex',
  alignSelf: 'center',
  borderRadius: 16,
  alignItems: 'center',
  flexDirection: 'row',
  justifyContent: 'center',
  cursor: 'pointer',
}
const CONTENT: React.CSSProperties = {
  flexDirection: 'row',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}
