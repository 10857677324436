import React from 'react'
import { observer } from 'mobx-react-lite'
import { color } from '@trivie/ui-web/theme'
import { Text } from '@trivie/ui-web/components/text'
import { ReactComponent as WarningIcon } from '@trivie/ui-web/assets/icons/Warning.svg'

export interface ValidationWarningProps {
  hasWarning?: boolean
  message: string
}

export const ValidationWarning = observer((props: ValidationWarningProps) => {
  const { hasWarning = false, message } = props

  if (!hasWarning) {
    return null
  }

  return (
    <div
      style={{
        backgroundColor: color.palette.lightYellow,
        borderRadius: 8,
        padding: 6,
        marginRight: 6,
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
      }}
    >
      <WarningIcon />
      <Text style={{ color: color.palette.yellow, marginLeft: 4 }} variant="body2" text={message} />
    </div>
  )
})
