import React, { useEffect, useState } from 'react'
import { Delete, MailRounded } from '@mui/icons-material'
import { observer } from 'mobx-react-lite'
import { CircularProgress } from '@mui/material'

import { useStores } from '@trivie/core'
import { ConfirmDialog } from '@trivie/ui-web/components/confirm-dialog'
import { DataTable, TableHelper } from '@trivie/ui-web/components/data-table/data-table'
import { Toolbar } from '@trivie/ui-web/components/table-toolbar'
import { MessageDialog } from '@trivie/ui-web/components/message-dialog'
import { CustomTableHelper } from './CustomTableHelper'

interface GroupTableProps {
  tableHelper: TableHelper | CustomTableHelper
  forceLoading?: boolean
  openFilter?: boolean
  exportFilename?: string
}

export const GroupTable = observer((props: GroupTableProps) => {
  const { exportFilename, tableHelper, openFilter, forceLoading } = props

  const { manageUserStore, groupStore } = useStores()
  const { deleteGroups } = groupStore
  const { gdpr, permissions } = manageUserStore

  const [selectedRows, setSelectedRows] = useState<any>([])
  const [loading, setLoading] = useState<boolean>(true)

  // Dialogs
  const [showConfirmDelete, setShowConfirmDelete] = useState(false)
  const [showSendMessage, setShowSendMessage] = useState(false) // message

  useEffect(() => {
    setLoading(Boolean(forceLoading))
  }, [forceLoading])

  const getActions = () => {
    switch (tableHelper.parent) {
      case 'assignments':
        return []
      case 'tags':
        return [
          {
            label: 'Message',
            icon: MailRounded,
            onClick: () => setShowSendMessage(true),
            disabled: !permissions?.permissions.some((p) => p.code === 'can_message_user'),
          },
        ]
      case 'users':
        return []
      case 'topics':
        return [
          {
            label: 'Message',
            icon: MailRounded,
            onClick: () => setShowSendMessage(true),
            disabled: !permissions?.permissions.some((p) => p.code === 'can_message_user'),
          },
        ]
      default:
        return [
          {
            label: 'Message',
            icon: MailRounded,
            onClick: () => setShowSendMessage(true),
            disabled: !permissions?.permissions.some((p) => p.code === 'can_message_user'),
          },
          {
            label: 'Delete',
            icon: Delete,
            onClick: () => setShowConfirmDelete(true),
            disabled: !permissions?.permissions.some((p) => p.code === 'can_edit_group'),
          },
        ]
    }
  }

  const [tableActions, setTableActions] = useState<any>(getActions())

  useEffect(() => {
    if (permissions) {
      setTableActions(getActions())
    }
  }, [permissions?.permissions])

  const [init] = useState(false)
  useEffect(() => {
    if (!init) {
      load()
    }
  }, [])

  const load = () => {
    if (!tableHelper?.custom) {
      setLoading(true)
      tableHelper.load().then(() => setLoading(false))
    }
  }

  const save = async (columns: any, filter: any) => {
    setLoading(true)
    tableHelper.save(columns, filter).then(() => setLoading(false))
  }

  return loading ? (
    <div style={LOADER}>
      <CircularProgress />
    </div>
  ) : (
    <>
      <DataTable
        gdpr={gdpr}
        allowNewReport
        openFilter={openFilter}
        columnVisibilityModel={tableHelper.columnVisibilityModel}
        hideAddColumn
        tableHelper={tableHelper}
        filterModel={tableHelper.filterModel}
        sortModel={tableHelper.sortModel}
        searchPlaceholder="Search Groups"
        rows={tableHelper.rows}
        columns={tableHelper.columns}
        onSaveLayout={tableHelper?.saveLayout}
        onSaveFilters={save}
        toolbar={<Toolbar selected={selectedRows} actions={tableActions} />}
        exportFilename={
          exportFilename
            ? exportFilename
            : tableHelper.parent !== 'groups'
              ? `${tableHelper.parent}_groups`
              : 'groups'
        }
        rowSelectionModel={selectedRows}
        onRowSelectionModelChange={(param) => setSelectedRows(param)}
      />
      {showConfirmDelete && (
        <ConfirmDialog
          danger
          open={showConfirmDelete}
          title="Remove Groups"
          message={`Are you sure you want to remove ${selectedRows.length} group${
            selectedRows.length > 1 ? 's' : ''
          }?`}
          confirmedMessage={`${selectedRows.length} group${
            selectedRows.length > 1 ? 's have' : ' has'
          } been removed.`}
          confirmButton="Confirm"
          onConfirm={() => deleteGroups(selectedRows)}
          onClose={() => {
            setSelectedRows([])
            load()
            setShowConfirmDelete(false)
          }}
          handleCancel={() => setShowConfirmDelete(false)}
        />
      )}
      {showSendMessage && (
        <MessageDialog
          ids={selectedRows}
          entity="group"
          open={showSendMessage}
          userCount={(selectedRows ?? []).length}
          messageType="generic"
          onClose={() => {
            setSelectedRows([])
            setShowSendMessage(false)
          }}
        />
      )}
    </>
  )
})

const LOADER: React.CSSProperties = {
  display: 'flex',
  flex: 1,
  justifyContent: 'center',
  flexDirection: 'column',
  alignItems: 'center',
  height: window.innerHeight / 1.5,
}
