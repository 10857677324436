import React from 'react'
import { Add, Close } from '@mui/icons-material'
import { Text } from '../text'
import { color } from '../../theme'
import { IconButton } from '@mui/material'

export interface TagKeyProps {
  tagKey: string
  onDelete?: (tag: any) => void
  onAdd?: (tag: any) => void
  style?: React.CSSProperties
}

export function TagKey(props: TagKeyProps) {
  const { tagKey, style, onAdd, onDelete } = props

  const isDelete = !!onDelete
  const isAdd = !!onAdd

  return (
    <div
      style={{
        display: 'flex',
        height: 29,
        width: 'min-content',
        alignItems: 'center',
        backgroundColor: color.palette.selectedTag,
        borderRadius: '6px',
        padding: '6px 8px',
        ...style,
      }}
      onClick={() => {
        if (onAdd) {
          onAdd(tagKey)
        }
      }}
    >
      <Text variant="body1" text={tagKey} style={{ whiteSpace: 'nowrap' }} />
      {isAdd ? (
        <IconButton size="small">
          <Add htmlColor={color.palette.grey} />
        </IconButton>
      ) : null}

      {isDelete ? (
        <IconButton size="small" onClick={() => onDelete!(tagKey)}>
          <Close htmlColor={color.palette.grey} />
        </IconButton>
      ) : null}
    </div>
  )
}
