import React, { useState, useEffect } from 'react'
import { observer } from 'mobx-react-lite'
import { useHistory, useRouteMatch } from 'react-router-dom'
import { Grid, Stack } from '@mui/material'

import { getStrongestWeakest, roundNumber, useStores } from '@trivie/core'
import { Text } from '@trivie/ui-web/components/text'
import { CompletionChart } from '@trivie/ui-web/components/completion-chart'
import { WorkflowApi } from '@trivie/core/src/services/api-objects/WorkflowApi'
import { UserApi } from '@trivie/core/src/services/api-objects'
import { StepsList } from '../../content-creation/components/steps-list'
import { Panel } from '@trivie/ui-web/components/panel/panel'
import { Insight } from '@trivie/ui-web/components/insight'
import { StrongestWeakest } from '@trivie/ui-web/components/strongest-weakest/strongest-weakest'

const Overview = observer((props: { pathID: number }) => {
  const { pathStore } = useStores()
  const {
    workflow,
    path,
    getTopicGaps,
    getQuizGaps,
    getQuestionGaps,
    topicGaps,
    quizGaps,
    questionGaps,
  } = pathStore

  const [pathStats, setPathStats] = useState<any>()

  const history = useHistory()
  const match = useRouteMatch<any>('/content/paths/:id/:tab')
  const pathID = Number(match?.params?.id)

  useEffect(() => {
    getTopicGaps(pathID)
    getQuizGaps(pathID)
    getQuestionGaps(pathID)
    Promise.all([
      WorkflowApi.getPaths(
        'paths',
        false,
        [
          { field: 'path_pct_not_started' },
          { field: 'path_pct_completed' },
          { field: 'path_nodes_completed_count' },
          { field: 'path_active_user_count' },
          { field: 'path_current_knowledge' },
          { field: 'path_starting_knowledge' },
          { field: 'path_knowledge_gain' },
        ],
        'list_paths',
      ),
      UserApi.getUsers(
        'paths',
        false,
        [{ field: 'first_name' }],
        'list_path_users',
        Number(pathID),
      ),
    ]).then(([pathData, userData]) => {
      if (pathData.ok && pathData.data && userData.ok && userData.data) {
        const pathRow = pathData.data.rows.find((row) => row.id === Number(pathID))
        const assigned_count = userData.data.rows.length
        const completed_value = pathRow.columns.find(
          (column) => column.field === 'path_pct_completed',
        ).value
        const path_nodes_completed_count = pathRow.columns.find(
          (column) => column.field === 'path_nodes_completed_count',
        ).value
        const path_active_user_count = pathRow.columns.find(
          (column) => column.field === 'path_active_user_count',
        ).value
        const not_started_value = pathRow.columns.find(
          (column) => column.field === 'path_pct_not_started',
        ).value
        const starting_knowledge = pathRow.columns.find(
          (column) => column.field === 'path_starting_knowledge',
        ).value
        const current_knowledge = pathRow.columns.find(
          (column) => column.field === 'path_current_knowledge',
        ).value
        const knowledge_gain = pathRow.columns.find(
          (column) => column.field === 'path_knowledge_gain',
        ).value

        const in_progress_value =
          completed_value === 0 && not_started_value === 0
            ? 0
            : 1 - (completed_value + not_started_value)

        const chart_data = [
          {
            value: roundNumber(completed_value * 100, 1),
            users: roundNumber(completed_value * assigned_count, 1),
            name: 'Completed',
          },
          {
            value: roundNumber(in_progress_value * 100, 1),
            users: roundNumber(in_progress_value * assigned_count, 1),
            name: 'In Progress',
          },
          {
            value: roundNumber(not_started_value * 100, 1),
            users: roundNumber(not_started_value * assigned_count, 1),
            name: 'Not Started',
          },
        ]

        setPathStats({
          ...{
            path_nodes_completed_count: pathRow.columns.find(
              (column) => column.field === 'path_nodes_completed_count',
            ),
            chart_data,
          },
          path_nodes_completed_count,
          path_active_user_count,
          assigned_count,
          starting_knowledge: roundNumber(starting_knowledge, 1),
          current_knowledge: roundNumber(current_knowledge, 1),
          knowledge_gain: roundNumber(knowledge_gain, 1),
        })
      }
    })
  }, [])

  const handleAddStep = () => {
    if (workflow?.editing) {
      workflow?.setEditing('')
      workflow?.selectStep(undefined)
    }
    history.push(`/content/path/${props.pathID}/edit`)
  }

  const strongestWeakestTopics = topicGaps ? getStrongestWeakest(topicGaps?.slice()) : ([] as any)
  const strongestWeakestQuizzes = quizGaps ? getStrongestWeakest(quizGaps?.slice()) : ([] as any)
  const strongestWeakestQuestions = questionGaps
    ? getStrongestWeakest(questionGaps?.slice())
    : ([] as any)

  return (
    <>
      {pathStats?.chart_data ? (
        <>
          <Text component="h2" sx={{ mb: '20px' }} variant="titleEmphasized" text="Statistics" />
          <Grid container spacing={2} style={{ marginBottom: 16 }}>
            <Grid item xs={12} sm={12} md={12} lg={8}>
              <Panel title="Completion Status">
                <CompletionChart data={pathStats.chart_data} />
              </Panel>
            </Grid>
            <Grid item spacing={2} lg={4} md={12}>
              <Stack useFlexGap gap={2} flexWrap="wrap" direction="row">
                <Panel style={{ flex: 1 }}>
                  <Insight
                    titleVariant="titleEmphasized"
                    title="Assigned Users"
                    tooltip="Number of users this path is been assigned to"
                    value={`${Number(pathStats.assigned_count)}`}
                  />
                </Panel>
                <Panel style={{ flex: 1 }}>
                  <Insight
                    titleVariant="titleEmphasized"
                    title="Active Users"
                    tooltip="Number of users this path is been assigned to"
                    value={`${Number(pathStats.path_active_user_count)}`}
                  />
                </Panel>
                <Panel style={{ flex: 1 }}>
                  <Insight
                    titleVariant="titleEmphasized"
                    title="Steps Completed"
                    tooltip="Number of steps that users have completed"
                    value={`${Number(pathStats.path_nodes_completed_count)}`}
                  />
                </Panel>
              </Stack>
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12} md={4} lg={3}>
              <Panel fill>
                <Insight
                  titleVariant="titleEmphasized"
                  title="Average Score"
                  tooltip="Average score for all users assigned this path"
                  value={`${Number(pathStats.assigned_count)}%`}
                />
              </Panel>
            </Grid>
            <Grid item xs={12} sm={12} md={4} lg={3}>
              <Panel fill>
                <Insight
                  titleVariant="titleEmphasized"
                  tooltip="Average score for the first answer for each question"
                  title="Baseline Knowledge"
                  value={`${Number(pathStats.starting_knowledge)}%`}
                />
              </Panel>
            </Grid>
            <Grid item xs={12} sm={12} md={4} lg={3}>
              <Panel fill>
                <Insight
                  titleVariant="titleEmphasized"
                  title="Current Knowledge"
                  tooltip="Average score for the most recent answer for each question"
                  value={`${Number(pathStats.current_knowledge)}%`}
                />
              </Panel>
            </Grid>
            <Grid item xs={12} sm={12} md={4} lg={3}>
              <Panel fill>
                <Insight
                  titleVariant="titleEmphasized"
                  title="Knowledge Gain"
                  tooltip="Average difference between the first and most recent answers for each question"
                  value={`${Number(pathStats.path_nodes_completed_count)}%`}
                />
              </Panel>
            </Grid>
          </Grid>
        </>
      ) : null}
      <Stack mt={2} flexWrap="wrap" direction="row" useFlexGap gap={2}>
        {strongestWeakestTopics?.strongest?.length ||
        strongestWeakestQuizzes?.strongest?.length ||
        strongestWeakestQuestions?.strongest?.length ? (
          <StrongestWeakest
            type="Strongest"
            variant="medium"
            topics={strongestWeakestTopics?.strongest}
            quizzes={strongestWeakestQuizzes?.strongest}
            questions={strongestWeakestQuestions?.strongest}
            style={{ flex: 1 }}
          />
        ) : null}
        {strongestWeakestTopics?.weakest?.length ||
        strongestWeakestQuizzes?.weakest?.length ||
        strongestWeakestQuestions?.weakest?.length ? (
          <StrongestWeakest
            type="Weakest"
            variant="medium"
            topics={strongestWeakestTopics?.weakest}
            quizzes={strongestWeakestQuizzes?.weakest}
            questions={strongestWeakestQuestions?.weakest}
            style={{ flex: 1 }}
          />
        ) : null}
      </Stack>
      <Text component="h2" sx={{ my: '20px' }} variant="titleEmphasized" text="Path Details" />
      <StepsList workflow={workflow} path={path} readonly handleAddStep={handleAddStep} />
    </>
  )
})

export const PathOverviewTab = (props: any) => {
  const { id } = props

  return {
    name: 'Overview',
    path: `/content/paths/${id}/overview`,
    component: () => <Overview pathID={id} />,
  }
}
