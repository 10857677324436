import { Instance } from 'mobx-state-tree'
import { AssessmentStats } from '../models/AssessmentsStats'
import { AssessmentStat } from '../models/AssessmentStat'
import { AssignmentCreateForm } from '../models/AssignmentCreateForm'
import { GetAssessments } from '../models/response-models/assignment'
import { AssignmentApi } from '../services/api-objects/AssignmentApi'
import { Awaited } from '../utility-types'
import { DateTime } from 'luxon'

let rootStore

export class AssignmentHelper {
  static setRootStore = (store: any) => {
    rootStore = store
  }

  static fromGetToModel = (assessmentStats: Instance<typeof GetAssessments>) => {
    return AssessmentStats.create({
      id: assessmentStats.assignment_id,
      preTestStats: assessmentStats.pre_test
        ? AssessmentStat.create({
            title: assessmentStats.pre_test.quiz_title ?? '',
            avgScore: assessmentStats.pre_test.avg_score ?? 0,
            usersCompleted: assessmentStats.pre_test.count_users_completed,
          })
        : null,
      postTestStats: assessmentStats.post_test
        ? AssessmentStat.create({
            title: assessmentStats.post_test?.quiz_title ?? '',
            avgScore: assessmentStats.post_test?.avg_score ?? 0,
            usersCompleted: assessmentStats.post_test.count_users_completed,
          })
        : null,
    })
  }

  static getAssessmentStats = async (id: number, start: string, end: string) => {
    const response: Awaited<ReturnType<typeof AssignmentApi.getAssignmentAssessmentStats>> =
      await AssignmentApi.getAssignmentAssessmentStats(id, start, end)

    if (response.ok && response.data) {
      return AssignmentHelper.fromGetToModel(response.data)
    } else {
      throw new Error(`Couldn't get assessment stats`)
    }
  }

  static createAssignmentCreateForm = (
    initial?: Partial<Instance<typeof AssignmentCreateForm>>,
  ) => {
    return AssignmentCreateForm.create({
      start_date: initial?.start_date ?? DateTime.local().toISODate(),
      start_time: initial?.start_time ?? DateTime.local().toLocaleString(DateTime.TIME_24_SIMPLE),
      end_date: initial?.end_date ?? DateTime.local().plus({ months: 1 }).toISODate(),
      endDateEnabled: initial?.endDateEnabled ?? true,
      end_time: initial?.end_time ?? DateTime.local().toLocaleString(DateTime.TIME_24_SIMPLE),
      title: initial?.title ?? '',
      content: initial?.content ?? [],
      groups: initial?.groups ?? [],
      users: initial?.users ?? [],
      attempts: initial?.attempts ?? 5,
      attemptsEnabled: initial?.attemptsEnabled ?? false,
      daysToComplete: initial?.daysToComplete ?? 7,
      daysToCompleteEnabled: initial?.daysToCompleteEnabled ?? false,
      notify: initial?.notify ?? false,
      include_pre_tests: initial?.include_pre_tests ?? false,
      include_post_tests: initial?.include_post_tests ?? false,
    })
  }

  static loadAssignmentCreateForm = async (id: number) => {
    const assignmentEditDataResponse = await AssignmentApi.getAssignmentEditData(id)

    if (assignmentEditDataResponse.ok && assignmentEditDataResponse.data) {
      return AssignmentHelper.createAssignmentCreateForm({ ...assignmentEditDataResponse.data })
    } else {
      throw new Error(`Failed to fetch assignment`)
    }
  }
}
