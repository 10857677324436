import React, { useState } from 'react'
import { Add } from '@mui/icons-material'
import { Header } from '@trivie/ui-web/components/header'
import { NavigationTabs } from '@trivie/ui-web/components/navigation-tabs'
import { observer } from 'mobx-react-lite'
import { Route, Switch } from 'react-router-dom'
import { badgesTabs } from './tabs'
import { useStores } from '@trivie/core'
import { CreateEditBadge } from './create-edit-badge'
import { BadgeCreateModelType } from '@trivie/core/src/models/base/badge'

export const BadgesScreen: React.FunctionComponent = observer(() => {
  const { badgesStore, manageUserStore } = useStores()
  const { permissions } = manageUserStore
  const { createBadge } = badgesStore
  const [showCreateBadge, setShowCreateBadge] = useState(false)

  const handleSaveBadge = (badge: BadgeCreateModelType) => {
    createBadge(badge).then(() => {
      setShowCreateBadge(false)
    })
  }

  const primary = {
    disabled: !permissions?.permissions.some((p) => p.code === 'can_create_badge'),
    startIcon: <Add />,
    onClick: () => setShowCreateBadge(true),
    text: 'Create Badge',
  }

  return (
    <Switch>
      <Route exact path="/activities/badges/:mainTab">
        <Header title="Badges" primaryAction={primary} />
        <NavigationTabs disabled={false} tabList={badgesTabs} />
        {showCreateBadge && (
          <CreateEditBadge
            open={showCreateBadge}
            onClose={() => setShowCreateBadge(false)}
            onSaveBadge={handleSaveBadge}
          />
        )}
      </Route>
    </Switch>
  )
})
