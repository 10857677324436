import { observer } from 'mobx-react-lite'
import React, { useState } from 'react'
import { IconButton, Menu, MenuItem } from '@mui/material'
import { MoreHoriz, Person } from '@mui/icons-material'
import { Text } from '../text'

interface RevokeBadgeProps {
  onRevokeClick?: () => void
}

export const RevokeBadge = observer((props: RevokeBadgeProps) => {
  const [menuAnchor, setMenuAnchor] = useState<any>(undefined)
  const [showMenu, setShowMenu] = useState(false)

  const handleMenuClick = (e: any) => {
    setMenuAnchor(e.target)
    setShowMenu(true)
  }

  const handleClick = () => {
    if (props.onRevokeClick) {
      props.onRevokeClick()
    }
    setShowMenu(false)
  }

  return (
    <div style={{ display: 'flex', flexDirection: 'row' }}>
      <IconButton onClick={handleMenuClick} size="large">
        <MoreHoriz />
      </IconButton>
      <Menu open={showMenu} anchorEl={menuAnchor} onClose={() => setShowMenu(false)} keepMounted>
        <MenuItem onClick={handleClick} style={{ marginBottom: 5 }}>
          <Person />
          <Text
            variant="body1Medium"
            text={'Revoke Badge'}
            style={{ textTransform: 'none', marginLeft: 8 }}
          />
        </MenuItem>
      </Menu>
    </div>
  )
})

RevokeBadge.defaultProps = {
  onRevokeClick: () => ({}),
}
