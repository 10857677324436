import React, { useMemo } from 'react'
import { QuestionPctCorrectSummary } from '@trivie/core/src/models/QuestionPctCorrectSummary'
import { Instance } from 'mobx-state-tree'
import { Link } from 'react-router-dom'

import { color } from '../../theme'
import { Text } from '../text'

export interface QuestionStatProps {
  style?: React.CSSProperties
  question?: Instance<typeof QuestionPctCorrectSummary>
  strongest?: boolean
  small?: boolean
}

export function QuestionStat(props: QuestionStatProps) {
  const { question, strongest, small, style } = props

  const truncatedQuestionText = useMemo(
    () =>
      (question?.title?.length || 0) > 150
        ? `${question?.title?.substring(0, 150)}...`
        : question?.title,
    [question?.title],
  )

  return (
    <div
      key={`${question?.question_id}`}
      style={{ display: 'flex', flexDirection: 'column', ...style }}
    >
      <Link to={`/content/questions/${question?.question_id}`} style={{ textDecoration: 'none' }}>
        <Text
          style={{
            overflowWrap: 'break-word',
            display: '-webkit-box',
            WebkitLineClamp: 2,
            WebkitBoxOrient: 'vertical',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
          }}
          variant="body1Emphasized"
          text={truncatedQuestionText}
        />
      </Link>

      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          marginTop: small ? 9 : 5,
          alignItems: 'center',
        }}
      >
        <Text
          variant="body2Emphasized"
          text={`${question?.pct_correct || 0}% correct`}
          style={{ color: strongest ? color.green : color.palette.red }}
        />
        {question?.topic_title && (
          <Text
            variant="body2Emphasized"
            text="•"
            style={{ color: color.palette.dividers, marginRight: 4, marginLeft: 4 }}
          />
        )}
        {question?.topic_title && (
          <Text
            variant="body2Emphasized"
            text={question?.topic_title ?? ''}
            color="text.secondary"
          />
        )}
        {!small && question?.question_type && (
          <>
            <Text
              variant="body2Emphasized"
              text="•"
              style={{ color: color.palette.dividers, marginRight: 4, marginLeft: 4 }}
            />
            <Text
              variant="body2Emphasized"
              text={question?.question_type ?? ''}
              color="text.secondary"
            />
          </>
        )}
      </div>
    </div>
  )
}
