import React, { useEffect, useState } from 'react'
import { NodeViewContent, NodeViewWrapper } from '@tiptap/react'
import { observer } from 'mobx-react-lite'

import { matchCitations } from '@trivie/core'

import { Citation } from '../../citation'

export const TableNode = observer((props: any) => {
  const { quiz, selectQuestion } = props.extension.options

  const [citations, setCitations] = useState<any>([])

  useEffect(() => {
    const cits = [] as any
    props.node.content?.content?.map((row) => {
      cits.push(...matchCitations(row.attrs?.generated_question_ids, quiz.questions))
    })
    setCitations(cits)
  }, [])

  const onCitationClick = (citation) =>
    selectQuestion(quiz.questions.find((q) => q.generated_question_id === citation.gqid))

  return (
    <NodeViewWrapper style={{ paddingBottom: 16 }}>
      <NodeViewContent
        style={{ position: 'relative' }}
        {...props.node.attrs}
        className="document-table"
        as="table"
      >
        <div style={{ position: 'absolute', bottom: -4 }}>
          {citations?.map((citation) => (
            <Citation onClick={() => onCitationClick(citation)} citation={citation} />
          ))}
        </div>
      </NodeViewContent>
    </NodeViewWrapper>
  )
})
