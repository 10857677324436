import { mergeAttributes } from '@tiptap/core'
import { ReactNodeViewRenderer } from '@tiptap/react'

import TableCell from '@tiptap/extension-table-cell'
import { TableCellNode } from './table-cell-node'

export const TTableCell = TableCell.extend({
  name: 'tableCell',
  addOptions() {
    return {
      HTMLAttributes: {},
      quiz: {},
      selectQuestion: (q: any) => {},
    }
  },
  addAttributes() {
    return {
      ...this.parent?.(),
      generated_question_ids: {
        default: [],
      },
      node_id: {
        default: 0,
      },
    }
  },
  draggable: false,
  parseHTML() {
    return [{ tag: 'td', attrs: { generated_question_ids: [] } }]
  },

  renderHTML({ node, HTMLAttributes }) {
    return [`td`, mergeAttributes(this.options.HTMLAttributes, HTMLAttributes), 0]
  },

  addNodeView() {
    return ReactNodeViewRenderer(TableCellNode, {
      as: 'td',
      attrs: {
        ...this.options.HTMLAttributes,
      },
    })
  },
})
