import { types, getEnv, flow } from 'mobx-state-tree'
import { Environment } from '../environment'

export const ManageLeaderboardStoreModel = types
  .model('ManageLeaderboardStore')
  .props({
    status: types.optional(types.enumeration(['idle', 'pending', 'done', 'error']), 'idle'),
    userLeaderboard: types.frozen(),
    groupLeaderboard: types.frozen(),
  })
  .actions((self) => ({
    setStatus(value?: 'idle' | 'pending' | 'done' | 'error') {
      self.status = value || 'idle'
    },
    setUserLeaderboards(data: any) {
      self.userLeaderboard = data
    },

    setGroupLeaderboards(data: any) {
      self.groupLeaderboard = data
    },
  }))
  .views((self) => ({
    get environment() {
      return getEnv(self) as Environment
    },
    get isLoading() {
      return self.status === 'pending'
    },
  }))
  .actions((self) => ({
    getLeaderboardBase: flow(function* (start?: string, end?: string) {
      self.setStatus('pending')
      self.setUserLeaderboards(undefined)
      self.setGroupLeaderboards(undefined)
      try {
        const result = yield self.environment.api.getLeaderboardBase(start, end)
        if (result.kind === 'ok') {
          const sortedGroups =
            result.data?.groups?.sort((a: any, b: any) => b.points - a.points) ?? []
          self.setUserLeaderboards(result.data.users)
          self.setGroupLeaderboards(sortedGroups)
          self.setStatus('done')
        } else {
          self.setStatus('error')
        }
      } catch {
        self.setStatus('error')
      }
    }),
    getLeaderboardByObject: flow(function* (
      objectID: number,
      objectType: string,
      start?: string,
      end?: string,
    ) {
      self.setStatus('pending')
      try {
        const result = yield self.environment.api.getLeaderboardByObject(
          objectID,
          objectType,
          start,
          end,
        )
        if (result.kind === 'ok') {
          self.setStatus('done')
          self.setUserLeaderboards(result.data.users)
          self.setGroupLeaderboards(result.data.groups)
        } else {
          self.setStatus('error')
        }
      } catch {
        self.setStatus('error')
      }
    }),
  }))

type ManageLeaderboardStoreType = typeof ManageLeaderboardStoreModel.Type
export interface ManageLeaderboardStore extends ManageLeaderboardStoreType {}
type ManageLeaderboardStoreSnapshotType = typeof ManageLeaderboardStoreModel.SnapshotType
export interface ManageLeaderboardStoreSnapshot extends ManageLeaderboardStoreSnapshotType {}
