import React, { useEffect, useState } from 'react'
import { observer } from 'mobx-react-lite'
import { useRouteMatch } from 'react-router-dom'
import { DateTime } from 'luxon'
import { useStores } from '@trivie/core'
import { TableHelper } from '../../../../tables'
import { TopicTable } from '../../../../tables/topic-table'

const Topics: React.FunctionComponent = observer(() => {
  const { groupStore } = useStores()

  const { setSelectedGroupId, selectedGroupId, refreshOverviewData } = groupStore

  const match = useRouteMatch<any>('/people/groups/:id/topics')
  const groupID = Number(match?.params.id)

  const [tableHelper] = useState(new TableHelper('list_group_topics', 'topics', 'groups', groupID))

  useEffect(() => {
    if (!selectedGroupId) {
      setSelectedGroupId(Number(match?.params.id))
      refreshOverviewData(
        DateTime.local(1971).startOf('day').toISO(),
        DateTime.local().endOf('day').toISO(),
      )
    }
  }, [])

  return <TopicTable tableHelper={tableHelper} />
})

export const GroupsTopicsTab = (props: any) => {
  const { id } = props
  return {
    name: 'Topics',
    path: `/people/groups/${id}/topics`,
    component: () => <Topics />,
  }
}
