import { Instance, types } from 'mobx-state-tree'

import { Branding } from '../Branding'

export const UIStoreModel = types
  .model('UIStore')
  .props({
    status: types.optional(types.enumeration(['idle', 'pending', 'done', 'error']), 'idle'),
    quantum: types.maybe(types.boolean),
    branding: types.optional(Branding, {
      id: 0,
      is_active: true,
      last_modified: '',
      web_font_url:
        'https://fonts.gstatic.com/s/inter/v7/UcCO3FwrK3iLTeHuS_fvQtMwCp50KnMw2boKoduKmMEVuLyfAZ9hjp-Ek-_EeA.woff',
      font_family: 'Inter',
      accent_color: '#1B59F8',
      native_font_url: '',
      correct_answer: '#00CB76',
      feedback_background: '#FFFFFF',
      feedback_header: '#551BFA',
      feedback_text: '#000000',
      incorrect_answer: '#ED3939',
      logo_url: '',
      quiz_background: '#151E23',
      review_background: '#1D252B',
      review_background_text: '#ffffff',
      text_color: '#ffffff',
    }),
  })
  .actions((self) => ({
    setStatus(value?: 'idle' | 'pending' | 'done' | 'error') {
      self.status = value || 'idle'
    },
    setQuantum(status) {
      self.quantum = status
    },
    setBranding(data: typeof self.branding) {
      self.branding = data
    },
  }))

type UIStoreType = Instance<typeof UIStoreModel>
export interface UIStore extends UIStoreType {}
