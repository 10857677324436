import { types } from 'mobx-state-tree'

export const Condition = types
  .model('Condition')
  .props({
    stub: types.maybe(types.boolean),
    verb: types.string,
    label: types.string,
    value: types.maybeNull(types.number),
    operator: types.maybeNull(types.string),
    object_id: types.maybe(types.number),
    object_type: types.string,
    reference_id: types.string,
    condition_name: types.string,
    certification_level_id: types.maybeNull(types.number),
    image: types.maybe(types.string),
    topic: types.frozen(),
    quiz: types.frozen(),
    source: types.frozen(),
  })
  .actions((self) => ({
    setReferenceId(id: string) {
      self.reference_id = id
    },
    setObjectID(id: number) {
      self.object_id = id
    },
    setVerb(verb: string) {
      self.verb = verb
    },
    setOperator(operator: string | null) {
      self.operator = operator
    },
    updateLevelId(id: number) {
      self.certification_level_id = id
    },
    setImage(url: string) {
      self.image = url
    },
    setBadge(label: string) {
      self.label = label
    },
    setLabel(label: string) {
      self.label = label
    },
    setConditionName(name: string) {
      self.condition_name = name
    },
    setTopic(topic: any) {
      self.topic = topic
    },
    setQuiz(quiz: any) {
      self.quiz = quiz
    },
    setSource(source: any) {
      self.source = source
    },
    setValue(value: number) {
      self.value = value
    },
  }))
