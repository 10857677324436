import { types, Instance } from 'mobx-state-tree'
import { GetQuestionPctCorrectSummary } from './question'
import { GetQuizPctCorrectSummary } from './quiz'

export const TopicQuizStat = types.model('TopicQuizStat').props({
  question_count: types.number,
  pct_correct: types.number,
  knowledge_gain: types.maybeNull(types.number),
  last_completed: types.maybeNull(types.string),
})

export const GetTopicQuiz = types.model('GetTopicQuiz').props({
  quiz_id: types.number,
  title: types.string,
  stats: types.maybeNull(TopicQuizStat),
})

export type GetTopicQuizType = Instance<typeof GetTopicQuiz>

export const GetTopicStat = types.model('GetTopicStat').props({
  topic_id: types.number,
  title: types.string,
  file_url: types.maybeNull(types.string),
  quiz_count: types.number,
  question_count: types.number,
  user_count: types.maybeNull(types.number),
  pct_correct: types.number,
  knowledge_gain: types.number,
  last_activity: types.maybeNull(types.string),
  pack_color: types.maybeNull(types.string),
})

export const GetTopicListStat = types.model('GetTopicListStat').props({
  topic_id: types.number,
  title: types.string,
  file_url: types.maybeNull(types.string),
  quiz_count: types.number,
  question_count: types.number,
  user_count: types.number,
  pct_correct: types.number,
  knowledge_gain: types.number,
  pack_color: types.maybeNull(types.string),
})

const TopicUserStat = types.model('TopicUserStat').props({
  recall: types.maybeNull(types.number),
  certification_level: types.maybeNull(types.string),
  answer_count: types.maybeNull(types.number),
  pct_correct: types.maybeNull(types.number),
  total_points: types.maybeNull(types.number),
  leaderboard_rank: types.maybeNull(types.number),
  tag_count: types.maybeNull(types.number),
  last_active: types.maybeNull(types.string),
})

export const GetTopicUser = types.model('GetTopicUser').props({
  user_id: types.number,
  display_name: types.string,
  email: types.string,
  photo_url: types.string,
  stats: TopicUserStat,
})

export const GetTopicQuizKnowledgeGap = types.model('GetTopicQuizKnowledgeGap').props({
  topic_id: types.number,
  quizzes: types.maybeNull(types.array(GetQuizPctCorrectSummary)),
})

export const GetTopicQuestionKnowledgeGap = types.model('GetTopicQuestionKnowledgeGap').props({
  topic_id: types.number,
  questions: types.maybeNull(types.array(GetQuestionPctCorrectSummary)),
})

export const GetTopicGroupStat = types.model('GetTopicGroupStat').props({
  group_id: types.number,
  label: types.string,
  user_count: types.number,
  rank: types.maybeNull(types.number),
  pct_correct: types.maybeNull(types.number),
})

export const GetQuizAssociatedTopic = types.model('GetQuizAssociatedTopic').props({
  topic_id: types.number,
  title: types.string,
})
