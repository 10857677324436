import { observer } from 'mobx-react-lite'
import React, { useState, useEffect } from 'react'
import {
  Dialog,
  Divider,
  DialogContent as MuiDialogContent,
  DialogTitle as MuiDialogTitle,
  DialogActions as MuiDialogActions,
  IconButton,
  Input,
} from '@mui/material'
import { Topic, TopicHelper, useStores } from '@trivie/core'
import { ArrowBack, ArrowForwardIos } from '@mui/icons-material'
import { Theme } from '@mui/material/styles'
import { WithStyles } from '@mui/styles'
import createStyles from '@mui/styles/createStyles'
import withStyles from '@mui/styles/withStyles'
import { color } from '@trivie/ui-web/theme'
import { Button } from '@trivie/ui-web/components/button'
import { Text } from '@trivie/ui-web/components/text'
import { Tooltip } from '@trivie/ui-web/components/tooltip'
import { TopicSection, ValidationResult, ValidationSuccessful } from '../topics/contentimport'
import { QuizValidationRow } from '../topics/contentimport/quiz-validation-row'
import { Quiz } from '@trivie/core/src/models/Quiz'
import { cast, getIdentifier, getParentOfType } from 'mobx-state-tree'
import { ValidationWarning } from '../topics/contentimport/validation-warning'
import { Skeleton } from '@mui/material'
import { ConfirmDialog } from '@trivie/ui-web/components/confirm-dialog'

const styles = (theme: Theme) =>
  createStyles({
    root: {
      margin: 0,
      padding: 20,
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
    },
    closeButton: {
      color: theme.palette.grey[500],
    },
  })

export interface DialogTitleProps extends WithStyles<typeof styles> {
  id: string
  children: React.ReactNode
  onClose?: () => void
  onPublish?: () => void
}

const DialogTitle = withStyles(styles)((props: DialogTitleProps) => {
  const { children, classes, onClose, onPublish, ...other } = props

  return (
    <MuiDialogTitle className={classes.root} {...other}>
      {children}
      {onClose ? (
        <div style={{ flex: 1, display: 'flex', flexDirection: 'row', justifyContent: 'flex-end' }}>
          <Button
            color="info"
            variant="outlined"
            text="Cancel"
            aria-label="close"
            onClick={onClose}
          />
        </div>
      ) : null}
      {onPublish ? (
        <div style={{ flex: 1, display: 'flex', flexDirection: 'row', justifyContent: 'flex-end' }}>
          <Button sx={{ width: '200px' }} text="Publish" onClick={onPublish} />
        </div>
      ) : null}
    </MuiDialogTitle>
  )
})

const DialogContent = withStyles(() => ({
  root: {
    padding: 20,
  },
}))(MuiDialogContent)

const DialogActions = withStyles(() => ({
  root: {
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    height: 150,
    display: 'flex',
  },
}))(MuiDialogActions)

interface ValidationDialogProps {
  onClose: () => void
  open: boolean
  onPublishSuccess?: () => void
}
export const ValidationDialog = observer((props: ValidationDialogProps) => {
  const { onClose, open, onPublishSuccess = () => {} } = props
  const { contentCreationStore } = useStores()
  const {
    topic,
    setTopic,
    quiz,
    question,
    validateContent,
    currentQuestionIndex,
    currentQuestion,
    setQuestionIndex,
    publishObjectContent,
    publishedSuccessful,
    validationStarted,
    setValidationStarted,
    questionsWithErrors,
    questionsWithWarnings,
    correctableQuestions,
    allQuestionsCorrect,
    hasCorrectQuestions,
    validating,
    resetContentStore,
    topics,
    setTopics,
    setValidating,
  } = contentCreationStore
  const [showLeaveDialog, setShowLeaveDialog] = useState(false)
  const handleLeave = () => {
    setShowLeaveDialog(false)
    onClose()
  }

  const handleClickNext = async () => {
    if (currentQuestionIndex + 1 >= correctableQuestions?.length) {
      if (topics) {
        const coalescedTopics = TopicHelper.coalesceTopics(topics)
        setTopics(cast(coalescedTopics))
      }
      // else if (topic) {
      //   const coalescedTopics = TopicHelper.coalesceTopics([topic])
      //   if (coalescedTopics) {
      //     setTopic(coalescedTopics[0])
      //   }
      // }
      setValidating(true)
      validateContent()
      setQuestionIndex(0)
      setValidationStarted(false)
    } else {
      setQuestionIndex(currentQuestionIndex + 1)
    }
  }

  useEffect(() => {
    if (open) {
      setValidationStarted(false)
    }
  }, [open])

  useEffect(() => {
    if (publishedSuccessful) {
      onPublishSuccess()
      // resetContentStore()
    }
  }, [publishedSuccessful])

  const renderActionItems = () => {
    if (validationStarted && (topics || quiz)) {
      return (
        <>
          <Button
            text="Back"
            onClick={() =>
              setQuestionIndex(
                currentQuestionIndex === 0
                  ? correctableQuestions?.length
                  : currentQuestionIndex - 1,
              )
            }
            disabled={currentQuestionIndex === 0}
            style={{
              paddingTop: 20,
              paddingBottom: 20,
              marginBottom: 10,
              width: 160,
            }}
          />
          <div style={{ paddingLeft: 140, paddingRight: 140 }}>
            <Text
              variant="labelEmphasized"
              text={`Corrections ${currentQuestionIndex + 1} of ${
                correctableQuestions?.length ?? 0
              }`}
            />
          </div>
          <Button
            text="Next"
            onClick={handleClickNext}
            disabled={
              !currentQuestion || currentQuestion.allErrors.length > 0
              // !currentQuestion ||
              // currentQuestion.hasErrors ||
              // TopicHelper.topicParentHasErrors(currentQuestion) ||
              // QuizHelper.quizParentHasErrors(currentQuestion)
            }
            style={{
              paddingTop: 20,
              paddingBottom: 20,
              marginBottom: 10,
              width: 160,
            }}
          />
        </>
      )
    } else if (
      !validationStarted &&
      !questionsWithErrors?.length &&
      questionsWithWarnings?.length
    ) {
      return (
        <>
          <Button
            text="View Warnings"
            onClick={() => {
              setQuestionIndex(0)
              setValidationStarted(true)
            }}
            style={{
              paddingTop: 20,
              paddingBottom: 20,
              paddingLeft: 140,
              paddingRight: 140,
              marginBottom: 10,
              width: 200,
            }}
          />
          <Button
            text="Publish"
            disabled={validating}
            onClick={publishObjectContent}
            style={{
              paddingTop: 20,
              paddingBottom: 20,
              paddingLeft: 140,
              paddingRight: 140,
              marginTop: 10,
              width: 200,
            }}
          />
        </>
      )
    } else if (!validationStarted && !allQuestionsCorrect) {
      return (
        <>
          <Button
            text="Make Corrections"
            onClick={() => {
              setQuestionIndex(0)
              setValidationStarted(true)
            }}
            style={{
              paddingTop: 20,
              paddingBottom: 20,
              paddingLeft: 140,
              paddingRight: 140,
              marginBottom: 10,
              width: 200,
            }}
          />

          <Button
            text="Publish2"
            disabled={validating || !allQuestionsCorrect || !hasCorrectQuestions}
            onClick={publishObjectContent}
            style={{
              paddingTop: 20,
              paddingBottom: 20,
              paddingLeft: 140,
              paddingRight: 140,
              marginTop: 10,
              width: 200,
            }}
          />
        </>
      )
    } else {
      return (
        <Button
          text="Publish"
          disabled={validating || !allQuestionsCorrect || !hasCorrectQuestions}
          onClick={publishObjectContent}
          sx={{ width: '200px' }}
        />
      )
    }
  }
  const renderHeaderItems = () => {
    if (question) {
      return null
    }

    let t = topic
    let q = quiz

    try {
      if (!t && currentQuestion) {
        t = getParentOfType(currentQuestion, Topic)
      }
    } catch {
      // do nothing
    }

    try {
      if (!q && currentQuestion) {
        q = getParentOfType(currentQuestion, Quiz)
      }
    } catch {
      // do nothing
    }

    return (
      <>
        {validating ? (
          <Skeleton variant="rectangular" style={{ borderRadius: 6 }} height={20} width={250} />
        ) : null}

        {!validating && validationStarted && t && q && (
          <>
            <Tooltip
              open={t.error}
              placement="bottom-start"
              title={
                t.error && q.error
                  ? 'Topic and Quiz Titles'
                  : t.error && !q.error
                    ? t.error.title
                    : null
              }
              text={
                t.error && q.error
                  ? 'Topics and quizzes must have titles between 1 and 40 characters'
                  : t.error?.text ?? ''
              }
            >
              <Input
                disableUnderline
                placeholder="Enter Topic Name"
                style={{
                  // ...insightsTypography.labelEmphasized,
                  color: t.hasError('incorrectTopicLength') ? color.red : '',
                  marginLeft: 20,
                }}
                value={t.title ?? ''}
                onChange={(e) => {
                  if (!t) {
                    throw new Error(`No parent topic to set title on`)
                  }
                  t.set('title', e.target.value)
                  if (e.target.value.length >= 1 && e.target.value.length <= 40) {
                    t.addToCorrectedErrors(['incorrectTopicLength'])
                  }
                  t.addToCorrectedErrors(['duplicateCreateTopic', 'duplicateUpdateTopic'])
                }}
              />
            </Tooltip>
            <ArrowForwardIos
              style={{ height: 18, width: 18 }}
              fontSize="small"
              htmlColor={color.black}
            />
            <Tooltip
              open={!t.error && q.error}
              placement="bottom-end"
              title={q.error?.title}
              text={q.error?.text}
            >
              <Input
                disableUnderline
                placeholder="Enter Quiz Name"
                style={{
                  // ...insightsTypography.labelEmphasized,
                  color: q.error ? color.red : '',
                  marginLeft: 20,
                  marginRight: 50,
                }}
                value={q.title ?? ''}
                onChange={(e) => {
                  if (!q) {
                    throw new Error(`No parent quiz to set title on`)
                  }
                  q.set('title', e.target.value)

                  if (e.target.value.length >= 1 && e.target.value.length <= 40) {
                    q.addToCorrectedErrors(['incorrectQuizLength'])
                  }

                  q.addToCorrectedErrors(['duplicateCreateQuiz', 'duplicateUpdateQuiz'])
                }}
              />
            </Tooltip>
          </>
        )}
        {!validating && validationStarted && !t && q && (
          <>
            <Tooltip
              open={q.hasErrors}
              placement="bottom-start"
              title={q.error?.title}
              text={q.error?.text}
            >
              <Input
                disableUnderline
                placeholder="Enter Quiz Name"
                style={{
                  // ...insightsTypography.labelEmphasized,
                  color: q.error ? color.red : '',
                  marginLeft: 20,
                  marginRight: 50,
                }}
                value={q.title ?? ''}
                onChange={(e) => {
                  if (!q) {
                    throw new Error(`No parent quiz to set title on`)
                  }
                  q.set('title', e.target.value)

                  if (e.target.value.length >= 1 && e.target.value.length <= 40) {
                    q.addToCorrectedErrors(['incorrectQuizLength'])
                  }

                  q.addToCorrectedErrors(['duplicateCreateQuiz', 'duplicateUpdateQuiz'])
                }}
              />
            </Tooltip>
          </>
        )}
        {!validating && !validationStarted && (
          <>
            <Text variant="labelEmphasized" text="Create" style={{ marginRight: 50 }} />
            {allQuestionsCorrect && <ValidationSuccessful />}
            {!allQuestionsCorrect && Boolean(questionsWithErrors.length) && (
              <ValidationResult
                hasError={!!questionsWithErrors.length}
                message={`${questionsWithErrors.length} ${
                  questionsWithErrors.length === 1 ? 'Error was' : 'Errors were'
                } found. Please correct before publishing!`}
              />
            )}

            {!allQuestionsCorrect && Boolean(questionsWithWarnings.length) && (
              <ValidationWarning
                hasWarning={!!questionsWithWarnings.length}
                message={`${questionsWithWarnings.length} Warning${
                  questionsWithWarnings.length > 1 ? 's were' : ' was'
                } found.`}
              />
            )}
          </>
        )}
      </>
    )
  }

  // if the last question was deleted we should revalidate
  // to do that we verify this is the last question and that it has no errors at
  // the question/quiz/topic levels. If it does not we call handleClickNext
  ;(() => {
    const isLastCorrectableQuestion = currentQuestionIndex >= correctableQuestions?.length
    const questionHasDeepErrors = Boolean(currentQuestion?.hasDeepErrors)

    let topicHasErrors = false
    try {
      if (currentQuestion) {
        const parentTopic = getParentOfType(currentQuestion, Topic)
        topicHasErrors = parentTopic.hasErrors
      }
    } catch {
      // do nothing
    }

    let quizHasErrors = false
    try {
      if (currentQuestion) {
        const parentQuiz = getParentOfType(currentQuestion, Quiz)
        quizHasErrors = parentQuiz.hasErrors
      }
    } catch {
      // do nothing
    }

    if (
      validationStarted &&
      isLastCorrectableQuestion &&
      (!questionHasDeepErrors || !topicHasErrors || !quizHasErrors)
    ) {
      handleClickNext()
    }
  })()

  return (
    <>
      <Dialog scroll="paper" fullScreen aria-labelledby="content-publish-dialog-title" open={open}>
        <DialogTitle
          id="content-publish-dialog-title"
          onClose={question ? undefined : () => setShowLeaveDialog(true)}
          onPublish={question ? publishObjectContent : undefined}
        >
          <IconButton onClick={() => setShowLeaveDialog(true)} size="large">
            <ArrowBack htmlColor={color.black} />
          </IconButton>
          {renderHeaderItems()}
        </DialogTitle>
        <Divider />
        <DialogContent style={{ minHeight: 427, width: '100%' }}>
          <div
            style={{
              display: 'flex',
              margin: 'auto',
              flexDirection: 'column',
              justifyContent: 'center',
            }}
          >
            {/* {(validationStarted || question) && (
              <QuestionForm edit showErrors question={currentQuestion || question} />
            )} */}

            {!validationStarted && quiz && <QuizValidationRow quiz={quiz} />}

            {/* {!validationStarted && topic && topics?.length === 0 && (
              <TopicSection title={topic.title ?? ''} quizzes={topic.quizzes} />
            )} */}

            {!validationStarted &&
              topics &&
              topics.map((t) => (
                <TopicSection key={getIdentifier(t)} title={t.title ?? ''} quizzes={t.quizzes} />
              ))}
          </div>
        </DialogContent>
        {!question && (
          <>
            <Divider />
            <DialogActions style={{ flexDirection: validationStarted ? 'row' : 'column' }}>
              {renderActionItems()}
            </DialogActions>
          </>
        )}
      </Dialog>
      {showLeaveDialog && (
        <ConfirmDialog
          open={showLeaveDialog}
          title="Back"
          message="Are you sure you want to go back? Your content will not be published."
          confirmButton="Confirm"
          onConfirm={handleLeave}
          onClose={() => setShowLeaveDialog(false)}
          handleCancel={() => setShowLeaveDialog(false)}
        />
      )}
    </>
  )
})
