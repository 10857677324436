import { ApiResponse, ApisauceInstance } from 'apisauce'
import { Instance } from 'mobx-state-tree'
import { AssignmentUserReadType } from '../../models/base/user/AssignmentUserReadModel'
import {
  AssignmentCreate,
  AssignmentResource,
  GetAssessments,
  GetAssignmentQuestionGaps,
  AssignmentContentResponse,
} from '../../models/response-models/assignment'
import { GetTopicStat } from '../../models/response-models/topics'
import { handleResponse } from '../handleResponse'

export const AssignmentApi = {
  apisauce: {} as ApisauceInstance,
  getAllAssignments: async (
    type: 'users' | 'topics' | 'quizzes' | 'groups' | 'tags' | 'assignments',
    meta?: boolean,
    columns?: any,
    view_name?: string,
    id?: number,
    filter?: 'active' | 'scheduled' | 'completed',
    primary_key?: number,
  ) => {
    const payload = { view_name, ...(primary_key && { primary_key }) }
    if (columns && columns.length > 0) {
      payload['columns'] = columns
    }

    const response: ApiResponse<any> = await AssignmentApi.apisauce.post(
      Boolean(id)
        ? `/${type}/${id}/assignments/?meta=${meta}&asynchronous=${false}`
        : `/${type}/filter/${filter}/?meta=${meta}&asynchronous=${false}`,
      payload,
    )

    return handleResponse(response)
  },
  getAssignmentDetailsContent: async (assignment_id: number) => {
    const response: ApiResponse<any> = await AssignmentApi.apisauce.get(
      `assignments/${assignment_id}/content/`,
    )

    return handleResponse(response)
  },
  getAssignmentUserCount: async (groups: number[], users: number[]) => {
    const response: ApiResponse<any> = await AssignmentApi.apisauce.get(
      `assignments/unique_user_count/`,
      {},
      { data: { groups, users } },
    )

    return handleResponse(response)
  },
  getAssignmentAssignees: async (assignment_id: number) => {
    const response: ApiResponse<any> = await AssignmentApi.apisauce.get(
      `assignments/${assignment_id}/assignees/`,
    )

    return handleResponse(response)
  },
  getAssignmentAssessmentStats: async (id: number, start: string, end: string) => {
    const urlQuery = new URLSearchParams({
      start_datetime: start,
      end_datetime: end,
    })
    const response: ApiResponse<Instance<typeof GetAssessments>> = await AssignmentApi.apisauce.get(
      `assignments/${id}/assessments/?${urlQuery.toString()}`,
    )

    return handleResponse(response)
  },
  getAssignmentQuestionGaps: async (id: number, start: string, end: string) => {
    const urlQuery = new URLSearchParams({
      start_datetime: start,
      end_datetime: end,
    })
    const response: ApiResponse<Instance<typeof GetAssignmentQuestionGaps>> =
      await AssignmentApi.apisauce.get(`assignments/${id}/question_gaps/?${urlQuery.toString()}`)

    return handleResponse(response)
  },
  getAssignmentTopicStats: async (id: number) => {
    const response: ApiResponse<Array<Instance<typeof GetTopicStat>>> =
      await AssignmentApi.apisauce.get(`assignments/${id}/topics/`, {})

    return handleResponse(response)
  },
  deleteAssignments: async (ids: Array<number>) => {
    const response: ApiResponse<Record<string, never>> = await AssignmentApi.apisauce.delete(
      `assignments/delete/`,
      {},
      { data: { assignment_ids: ids } },
    )

    return handleResponse(response)
  },
  createAssignment: async (assignment: Instance<typeof AssignmentCreate>) => {
    const response: ApiResponse<AssignmentUserReadType[]> = await AssignmentApi.apisauce.post(
      `/assignments/create/`,
      assignment,
    )

    return handleResponse(response)
  },
  endAssignment: async (id: number) => {
    const response: ApiResponse<Record<string, never>> = await AssignmentApi.apisauce.patch(
      `/assignments/${id}/end/`,
    )

    return handleResponse(response)
  },
  startAssignmentsNow: async (assignmentIDs: Array<number>) => {
    const response: ApiResponse<Record<string, never>> = await AssignmentApi.apisauce.patch(
      `/assignments/start/`,
      {
        assignment_ids: assignmentIDs,
      },
    )
    return handleResponse(response)
  },
  addUsersToAssignment: async (userIds: Array<number>, assignmentId: number) => {
    const response: ApiResponse<Record<string, never>> = await AssignmentApi.apisauce.patch(
      `/assignments/${assignmentId}/users/add/`,
      {
        user_ids: userIds,
      },
    )

    return handleResponse(response)
  },
  nudge: async (id: number, message: string) => {
    const response: ApiResponse<AssignmentUserReadType[]> = await AssignmentApi.apisauce.post(
      `/assignments/${id}/nudge/`,
      { message_body: message },
    )

    return handleResponse(response)
  },
  getResourcesForAssignment: async (id: number) => {
    const response: ApiResponse<Array<Instance<typeof AssignmentResource>>> =
      await AssignmentApi.apisauce.get(`/assignments/${id}/resources/`, {})

    return handleResponse(response)
  },
  getAssignmentContent: async () => {
    const response: ApiResponse<Instance<typeof AssignmentContentResponse>> =
      await AssignmentApi.apisauce.get('/assignments/assignable_content/')

    return handleResponse(response)
  },
  getAssignmentEditData: async (id: number) => {
    const response: ApiResponse<any> = await AssignmentApi.apisauce.get(`/assignments/${id}/edit/`)

    return handleResponse(response)
  },
  patchAssignment: async (id: number, assignment: any) => {
    const response: ApiResponse<any> = await AssignmentApi.apisauce.patch(
      `/assignments/${id}/`,
      assignment,
    )

    return handleResponse(response)
  },
  getExportableReports: async () => {
    const response: ApiResponse<any> = await AssignmentApi.apisauce.get(`/assignments/reports/`)

    return handleResponse(response)
  },
}
