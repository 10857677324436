import React from 'react'

import TableCell from '@mui/material/TableCell'
import { Link } from 'react-router-dom'

import { color } from '../../../theme'
import { Text } from '../../text'
import { Avatar } from '../../avatar'
import { AddRounded, CloseRounded } from '@mui/icons-material'
import { IconButton } from '@mui/material'
import { getDisplayNumber } from '@trivie/core/src/utils/phoneNumber'

export const UserShortCell = (props: any) => {
  const { row, avatarSize, action, remove } = props

  return (
    <TableCell style={CELL} align="left">
      <div style={CONTAINER}>
        <Link style={LINK} to={`/people/users/${row.id}/overview`}>
          <Avatar
            fontStyle={{ fontSize: avatarSize ? 12 : 16 }}
            style={{ marginRight: 8 }}
            name={`${row.first_name} ${row.last_name}`}
            url={row.file_url || row.photo_url}
            size={avatarSize ?? 42}
          />
          <div style={{ flexDirection: 'column' }}>
            <Text text={`${row.first_name} ${row.last_name}`} variant="body1Medium" />
            {row.email && (
              <Text variant="body2" text={row.email} style={{ color: color.shade50 }} />
            )}
            {row.phone_number && !row.email && (
              <Text
                variant="body2"
                text={getDisplayNumber(row.phone_number)}
                style={{ color: color.shade50 }}
              />
            )}
          </div>
        </Link>
        {Boolean(action) && (
          <IconButton onClick={() => action(row.id)}>
            {remove ? <CloseRounded /> : <AddRounded />}
          </IconButton>
        )}
      </div>
    </TableCell>
  )
}

const CELL: React.CSSProperties = { borderBottom: 'none', padding: 0 }
const CONTAINER: React.CSSProperties = {
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'space-between',
}
const LINK: React.CSSProperties = {
  flexDirection: 'row',
  display: 'flex',
  textDecoration: 'none',
  color: color.black,
  alignItems: 'center',
}
