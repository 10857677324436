import React, { useEffect, useState } from 'react'
import { Close } from '@mui/icons-material'
import { Autocomplete, IconButton, TextField } from '@mui/material'
import { color } from '../../theme'
import makeStyles from '@mui/styles/makeStyles'
import { getDateOperatorLabel, getOperatorLabel } from './filter'

export const useFilterStyles = makeStyles({
  // Dropdown container
  paper: {
    boxShadow: 'none',
    border: '1px solid lightgray',
    borderRadius: 6,
    // top: -1,
    margin: 0,
  },
  option: {
    paddingLeft: '20px !important',
    paddingRight: '20px !important',
    paddingTop: '9.5px !important',
    paddingBottom: '9.5px !important',
  },
})

interface FilterItemProps {}
export const FilterItem = (props: any) => {
  const {
    filter,
    columns,
    handleColumnFilterUpdate,
    handleOperatorFilterUpdate,
    handleValueFilterUpdate,
    filterList,
    removeFilter,
  } = props

  const [operatorValues, setOperatorValues] = useState<any>([])
  const [possibleValues, setPossibleValues] = useState([])

  const classes = useFilterStyles()

  const [operator, setOperator] = useState<any>()
  const [value, setValue] = useState<any>(filter.value)
  const [column, setColumn] = useState<any>()

  useEffect(() => {
    if (filter && columns) {
      const columnData = Object.values(columns).filter((c: any) => c.field === filter.field) as any

      if (columnData?.length) {
        setColumn({
          value: columnData[0],
          label: columnData[0]?.is_custom
            ? `${columnData[0].headerName}: ${Object.values(columnData[0].params)[0]}`
            : columnData[0].headerName,
        })
        setOperator({
          label:
            filter.operator?.label === 'never' || filter.operator?.label === 'isEmpty'
              ? 'never'
              : columnData[0].type === 'date'
                ? getDateOperatorLabel(filter.operator?.value ?? filter.operator)
                : getOperatorLabel(filter.operator?.value ?? filter.operator),
          value:
            filter.operator?.label === 'never' ? null : filter.operator?.value ?? filter.operator,
        })
        setValue(filter.operator?.label === 'never' ? null : filter.value ?? filter.value)
      }
    }
  }, [filter, columns])

  useEffect(() => {
    if (column) {
      setPossibleValues(
        column.value.type === 'boolean' ? ['True', 'False'] : column.value.possible_values,
      )
      const ov = column?.value.operators
        ?.filter((o) =>
          column.value.type === 'string' ||
          column.value.headerName === 'Name' ||
          column.value.type === 'array'
            ? o !== 'ne' && o !== 'not_like'
            : true,
        )
        .map((operator: any) => {
          return {
            label: getOperatorLabel(operator),
            value: operator,
          }
        })
      if (column.value.type === 'date') {
        setOperatorValues([...ov, { label: 'never', value: 'never' }])
      } else {
        setOperatorValues(ov)
      }
    }
  }, [column])

  // exclude checkbox selection,and array types
  const columnOptions = Object.values(columns)
    .filter(
      (c: any) =>
        c.field !== '__check__' &&
        c.data_type !== 'array' &&
        c.field !== 'first_name' &&
        c.field !== 'last_name' &&
        c.field !== 'email' &&
        c.field !== 'phone_number',
    )
    .map((c: any) => {
      return {
        label: c?.is_custom ? `${c.headerName}: ${Object.values(c.params)[0]}` : c.headerName,
        value: c,
      }
    })

  return column ? (
    <div style={CONTAINER}>
      <div style={FILTER_CONTENT}>
        <Autocomplete
          classes={{ ...classes }}
          disableClearable
          onChange={(e: any, value: any) => {
            handleColumnFilterUpdate(value, column)
            setValue('')
            setOperator('')
            setColumn(value)
          }}
          getOptionDisabled={(option) => {
            return filterList.some((f) => f.field === option.value.field)
          }}
          style={{ marginBottom: 16, flex: 1 }}
          disablePortal
          defaultValue={column}
          size="small"
          getOptionLabel={(option) => option.label || ''}
          value={column}
          isOptionEqualToValue={(option, value) => {
            return option.value.field === value.value.field
          }}
          options={columnOptions}
          renderInput={(params) => <TextField variant="standard" {...params} label="Column" />}
        />
        <div style={FILTER_ROW}>
          <Autocomplete
            classes={{ ...classes }}
            disableClearable
            disabled={!column}
            onChange={(e: any, value: any) => {
              handleOperatorFilterUpdate(value, column)
              setOperator(value)
            }}
            style={{ marginRight: 8, flex: 1 }}
            disablePortal
            size="small"
            value={operator}
            defaultValue={operatorValues[0]}
            options={operatorValues ?? []}
            renderInput={(params) => (
              <TextField
                variant="standard"
                {...params}
                label={operator.value ? 'Operator' : 'Select Operator'}
              />
            )}
          />
          {possibleValues.length > 0 ? (
            <Autocomplete
              classes={{ ...classes }}
              onChange={(e: any, value: any) => {
                let v = value
                if (column.value.type === 'boolean') {
                  if (value === 'True') {
                    v = true
                  } else {
                    v = false
                  }
                }
                handleValueFilterUpdate(value, column)
                setValue(value)
              }}
              style={{ marginLeft: 8, flex: 1 }}
              disablePortal
              size="small"
              disableClearable
              value={String(value).charAt(0).toUpperCase() + String(value).slice(1)}
              options={possibleValues}
              renderInput={(params) => <TextField variant="standard" {...params} label="Value" />}
            />
          ) : column?.value?.type === 'date' &&
            filter?.operator?.label !== 'never' &&
            filter.operator !== 'isEmpty' ? (
            <TextField
              disabled={filter?.operator?.label === 'never'}
              variant="standard"
              size="small"
              style={{ marginLeft: 8, display: 'flex', flex: 1, top: 16 }}
              type="date"
              onChange={(e) => {
                handleValueFilterUpdate(e.target.value, column)
                setValue(e.target.value)
              }}
              value={value}
              defaultValue={value}
            />
          ) : filter?.operator?.label === 'never' || filter.operator === 'isEmpty' ? (
            <div style={{ marginLeft: 8, display: 'flex', flex: 1 }} />
          ) : (
            <TextField
              style={{ marginLeft: 8, display: 'flex', flex: 1, bottom: 3 }}
              variant="standard"
              onFocus={() => handleOperatorFilterUpdate(operator.value, column)}
              onChange={(e) => {
                handleValueFilterUpdate(e.target.value, column)
                setValue(e.target.value)
              }}
              label="Value"
              value={value}
            />
          )}
        </div>
      </div>
      <IconButton size="small" onClick={() => removeFilter(column.value.field)}>
        <Close fontSize="small" htmlColor={color.red} />
      </IconButton>
    </div>
  ) : null
}

const FILTER_ROW: React.CSSProperties = {
  flexDirection: 'row',
  display: 'flex',
  height: 50,
}
const FILTER_CONTENT: React.CSSProperties = {
  flexDirection: 'column',
  flex: 1,
  border: '1px solid rgba(0,0,0,.1)',
  borderRadius: 4,
  padding: 8,
  paddingBottom: 4,
  marginRight: 8,
}
const CONTAINER: React.CSSProperties = {
  flexDirection: 'row',
  alignItems: 'center',
  display: 'flex',
  marginBottom: 16,
}
