import { observer } from 'mobx-react-lite'
import React, { useEffect, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { Link, Redirect, useHistory } from 'react-router-dom'
import { InputAdornment, TextField, Typography, useMediaQuery } from '@mui/material'
import { GroupRounded } from '@mui/icons-material'

import { save, useStores } from '@trivie/core'
import { color } from '@trivie/ui-web/theme/color'
import { Text } from '@trivie/ui-web/components/text'
import { Button } from '@trivie/ui-web/components/button'
import SentimentVeryDissatisfiedIcon from '@mui/icons-material/SentimentVeryDissatisfied'

// @ts-ignore
import { ReactComponent as Blob } from './blob.svg'

import preview from './preview.png'
import { Logo } from './logo'
import { OtpEntry } from '@trivie/ui-web/components/otp-entry/otp-entry'

export const LoginScreen: React.FunctionComponent = observer(() => {
  const { control, handleSubmit, watch } = useForm()
  const { manageUserStore, uiStore } = useStores()
  const { quantum } = uiStore

  const { requestMagicLink, setOtpNotFound, otpNotFound } = manageUserStore
  const { setEmail, email, enterpriseTier } = manageUserStore

  const [showOTP, setShowOTP] = useState(false)
  const [otp, setOtp] = useState('')

  const matches = useMediaQuery('(min-width: 940px)')
  const history = useHistory()

  const onSubmit = (data: any) => {
    const e = data?.email
    requestMagicLink(e).then(() => {
      setEmail(e)
      if (manageUserStore.status === 'done') {
        localStorage.removeItem('ml-error')
        setShowOTP(true)
      }
    })
  }

  const watchEmail = watch('email', false)

  useEffect(() => {
    setOtpNotFound(false)
  }, [])

  const loginOTC = () => {
    if (otp && email) {
      manageUserStore.evaluateOTC(otp, email).then(async (data: any) => {
        if (manageUserStore.status === 'error') {
          // history.replace('/magic')
        } else if (data.workspaces && data.workspaces.length > 1) {
          save('workspaces', data)
          localStorage.removeItem('ml-error')
          history.replace('/workspaces')
        } else if (data.workspaces.length === 1) {
          save('workspaces', data)
          localStorage.removeItem('ml-error')
          if (data.workspaces[0].has_sso_config) {
            manageUserStore.setSSOWorkspace(data.workspaces[0])
            const ssoRedirect = await manageUserStore?.getAccessCodeDetails(
              data.workspaces[0].access_code,
            )
            if (ssoRedirect) {
              window.location.replace(ssoRedirect)
            }
          } else {
            manageUserStore.loginWorkspace(data.token, data.workspaces[0].access_code).then(() => {
              history.replace(enterpriseTier ? '/insights/knowledge' : '/content/quizzes')
            })
          }
        }
      })
    }
  }

  const onChangeOtp = (value) => {
    setOtpNotFound(false)
    setOtp(value)
  }

  const resend = () => {
    if (email) {
      requestMagicLink(email)
    }
  }

  return localStorage.getItem('token') ? (
    <Redirect to={{ pathname: enterpriseTier ? '/insights/knowledge' : '/content/quizzes' }} />
  ) : (
    <div style={ROOT}>
      <div
        style={{
          ...LEFT_CONTAINER,
          width: matches ? '50%' : '100%',
          paddingLeft: matches ? 120 : 20,
          paddingRight: matches ? 120 : 20,
        }}
      >
        <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
          <div style={{ position: 'absolute', left: 100, top: 40 }}>
            <Logo quantum={quantum} />
          </div>
          {showOTP ? (
            <div style={OTC_ROOT}>
              <div style={CONTAINER}>
                <div style={CONTENT}>
                  <div style={HEADER}>
                    <Text
                      style={HEADLINE}
                      tx="Onboarding.Login.checkYourEmail"
                      variant="headline"
                    />
                    <div
                      style={{
                        flexDirection: 'row',
                        display: 'flex',
                        paddingLeft: 12,
                        paddingRight: 12,
                      }}
                    >
                      <Text
                        style={OTC_TAGLINE}
                        text={`To sign in, use the link we sent to ${email}`}
                      />
                    </div>
                  </div>
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'center',
                      gap: 32,
                    }}
                  >
                    <Text
                      align="center"
                      text="Or enter the one-time password you received in your email."
                    />
                    <OtpEntry containerStyle={{ alignSelf: 'center' }} onChange={onChangeOtp} />
                    <Button
                      data-qat="continue_otp"
                      fullWidth
                      disabled={otp.length !== 4}
                      type="submit"
                      size="large"
                      sx={{ marginBottom: '32px' }}
                      onClick={loginOTC}
                      text="Continue"
                    />
                  </div>
                  {otpNotFound && (
                    <div style={ERROR_STATE}>
                      <SentimentVeryDissatisfiedIcon
                        htmlColor={color.red}
                        style={{ marginRight: 12, fontSize: 20 }}
                      />
                      <span
                        style={{ fontWeight: '700', fontSize: 12, color: 'rgba(255, 0, 0, 0.8)' }}
                      >
                        We don't recognize that code.&nbsp;
                        <Typography variant="body2Medium">
                          Please try again, select Resend Code below, or contact your administrator.
                        </Typography>
                      </span>
                    </div>
                  )}
                  <span
                    style={{
                      textAlign: 'center',
                      fontSize: 14,
                      color: color.shade70,
                    }}
                  >
                    Haven't received the email? Check your spam folder,&nbsp;
                    <span
                      onClick={resend}
                      style={{
                        fontSize: 14,
                        color: color.blue,
                        cursor: 'pointer',
                      }}
                      data-qat="send_again"
                    >
                      send it again,&nbsp;
                    </span>
                    or email&nbsp;
                    <span style={{ fontSize: 14 }}>
                      <a
                        data-qat="support_email"
                        style={{ fontSize: 14, color: color.blue }}
                        href="support@trivie.com"
                      >
                        support@trivie.com
                      </a>
                      &nbsp;to get in touch.
                    </span>
                  </span>
                </div>
              </div>
            </div>
          ) : (
            <div style={{ display: 'flex', flexDirection: 'column', paddingTop: 80 }}>
              <Text style={{ marginBottom: 32 }} variant="headline" text="Log In" />
              <Text
                style={{ color: color.shade70 }}
                variant="body2"
                text="Enter your email address and we'll send you a magic link to securely log you in."
              />
              <form onSubmit={handleSubmit(onSubmit)}>
                <div style={EMAIL}>
                  <Text variant="titleEmphasized" text="Email Address" />
                </div>
                <Controller
                  defaultValue=""
                  name="email"
                  control={control}
                  render={({ onChange, onBlur, value }) => (
                    <TextField
                      type="email"
                      sx={{
                        '& .MuiFormHelperText-root.Mui-error': {
                          top: '0px !important',
                          paddingLeft: '0px !important',
                        },
                      }}
                      required
                      onChange={onChange}
                      onBlur={onBlur}
                      value={value}
                      style={{ width: '100%', marginBottom: 51 }}
                      size="small"
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <GroupRounded htmlColor={color.palette.grey} />
                          </InputAdornment>
                        ),
                      }}
                      inputProps={{ 'data-qat': 'email_input' }}
                      helperText={
                        watchEmail && !watchEmail.includes('@')
                          ? 'Enter a valid email address.'
                          : ''
                      }
                      placeholder="Enter your work email"
                      variant="outlined"
                      error={
                        typeof watchEmail === 'string' &&
                        watchEmail !== '' &&
                        !watchEmail.includes('@')
                      }
                    />
                  )}
                />
                <Button
                  disabled={!watchEmail || !watchEmail.includes('@')}
                  type="submit"
                  fullWidth
                  sx={{
                    marginBottom: '32px',
                  }}
                  text="Continue"
                  data-qat="continue"
                />
                <div style={SEPARATOR}>
                  <div style={{ height: 1, width: '100%', backgroundColor: color.shade20 }} />
                  <Text
                    variant="titleEmphasized"
                    text="or"
                    style={{ marginLeft: 10, marginRight: 10 }}
                  />
                  <div style={{ height: 1, width: '100%', backgroundColor: color.shade20 }} />
                </div>
                <Button
                  style={{ marginBottom: '32px' }}
                  fullWidth
                  variant="outlined"
                  color="info"
                  size="large"
                  data-qat="continue_google"
                  onClick={() =>
                    window.location.replace(`${process.env.REACT_APP_SSO_GOOG_URL}&intent=signin`)
                  }
                  text="Continue with Google"
                  startIcon={
                    <svg
                      width="23"
                      height="24"
                      viewBox="0 0 23 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M22.54 12.2615C22.54 11.446 22.4668 10.6619 22.3309 9.90918H11.5V14.3576H17.6891C17.4225 15.7951 16.6123 17.013 15.3943 17.8285V20.714H19.1109C21.2855 18.7119 22.54 15.7637 22.54 12.2615Z"
                        fill="#4285F4"
                      />
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M11.4999 23.4998C14.6049 23.4998 17.2081 22.47 19.1108 20.7137L15.3942 17.8282C14.3644 18.5182 13.0472 18.9259 11.4999 18.9259C8.50467 18.9259 5.96945 16.903 5.06513 14.1848H1.22308V17.1644C3.11536 20.9228 7.00445 23.4998 11.4999 23.4998Z"
                        fill="#34A853"
                      />
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M5.06523 14.185C4.83523 13.495 4.70455 12.7579 4.70455 12C4.70455 11.242 4.83523 10.505 5.06523 9.81499V6.83545H1.22318C0.444318 8.38795 0 10.1443 0 12C0 13.8557 0.444318 15.612 1.22318 17.1645L5.06523 14.185Z"
                        fill="#FBBC05"
                      />
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M11.4999 5.07386C13.1883 5.07386 14.7042 5.65409 15.896 6.79364L19.1944 3.49523C17.2029 1.63955 14.5997 0.5 11.4999 0.5C7.00445 0.5 3.11536 3.07705 1.22308 6.83545L5.06513 9.815C5.96945 7.09682 8.50468 5.07386 11.4999 5.07386Z"
                        fill="#EA4335"
                      />
                    </svg>
                  }
                />
                <div style={{ textAlign: 'center' }}>
                  <Text variant="titleMedium">
                    You can also&nbsp;
                    <Link data-qat="continue_sso" to="/login/sso" style={LOGIN_SSO}>
                      log in with SAML SSO.
                    </Link>
                  </Text>
                </div>
              </form>
            </div>
          )}
        </div>
      </div>
      <div
        style={{
          width: '50%',
          display: matches ? 'flex' : 'none',
          alignItems: 'center',
          justifyContent: 'center',
          flexDirection: 'column',
        }}
      >
        <div
          style={{ display: 'flex', flexDirection: 'column', paddingBottom: 20, paddingTop: 20 }}
        >
          <img
            style={{ alignSelf: 'center' }}
            alt="app preview"
            src={preview}
            width="420"
            height="567.33"
          />
          <Text
            text="Know what your employees know."
            style={{ textAlign: 'center', marginTop: 40, fontSize: 24, color: color.white }}
            variant="headline"
          />
          <Text
            text="View metrics and reports with actionable insights to help your employees retain the information they need."
            style={{
              width: 366,
              textAlign: 'center',
              fontSize: 16,
              marginTop: 20,
              marginRight: 0,
              color: color.white,
              alignSelf: 'center',
            }}
            variant="titleMedium"
          />
        </div>

        <div style={{ position: 'absolute', zIndex: -1, height: '100vh', width: '50%' }}>
          <Blob width="100%" height="100%" />
        </div>
      </div>
    </div>
  )
})

const ROOT: React.CSSProperties = {
  display: 'flex',
  flexDirection: 'row',
  width: '100%',
  overflowY: 'auto',
}
const LEFT_CONTAINER: React.CSSProperties = {
  width: '50%',
  display: 'flex',
  paddingTop: 20,
  paddingBottom: 20,
  alignItems: 'center',
  backgroundColor: color.white,
  flexDirection: 'row',
  justifyContent: 'center',
}
const EMAIL: React.CSSProperties = { marginTop: 32, marginBottom: 32 }
const LOGIN_SSO: React.CSSProperties = {
  color: color.blue,
  borderBottom: `1px solid ${color.blue}`,
  textDecoration: 'none',
}
const SEPARATOR: React.CSSProperties = {
  flexDirection: 'row',
  justifyContent: 'center',
  alignItems: 'center',
  display: 'flex',
  paddingRight: 40,
  paddingLeft: 40,
  marginBottom: 32,
}
const OTC_ROOT: React.CSSProperties = {
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  width: '100%',
}
const CONTAINER: React.CSSProperties = {
  flexDirection: 'column',
  flex: 1,
  display: 'flex',
  maxHeight: 576,
  justifyContent: 'space-evenly',
  // width: '33%',
  minWidth: 400,
  alignSelf: 'center',
}
const CONTENT: React.CSSProperties = {
  height: '100%',
  flex: 1,
  backgroundColor: '#fff',
  display: 'flex',
  alignItems: 'center',
  flexDirection: 'column',
  justifyContent: 'space-evenly',
  alignSelf: 'center',
}
const HEADER: React.CSSProperties = {
  minWidth: 319,
  flexDirection: 'column',
  display: 'flex',
  marginBottom: 32,
}
const OTC_TAGLINE: React.CSSProperties = {
  color: color.black,
  textAlign: 'center',
}
const HEADLINE: React.CSSProperties = { textAlign: 'center', marginBottom: 32 }
const ERROR_STATE: React.CSSProperties = {
  width: 349,
  height: 64,
  marginBottom: 25,
  borderRadius: 16,
  backgroundColor: 'rgba(249, 234, 234, 1)',
  paddingTop: 10,
  paddingBottom: 10,
  paddingRight: 20,
  display: 'flex',
  flexDirection: 'row',
  paddingLeft: 20,
  alignItems: 'center',
  alignSelf: 'center',
}
