import { types } from 'mobx-state-tree'

export const AdaptiveTopic = types.model('AdaptiveTopic').props({
  image_url: types.maybeNull(types.string),
  topic_id: types.number,
  topic_title: types.string,
  recall: types.maybeNull(types.number),
})

export const UserAdaptive = types.model('UserAdaptive').props({
  count: types.number,
  quiz_id: types.maybeNull(types.number),
  has_quiz: types.boolean,
  adaptive_topics: types.array(AdaptiveTopic),
})
