import { ApiResponse, ApisauceInstance } from 'apisauce'
import { handleResponse } from '../handleResponse'

export const WorkflowApi = {
  apisauce: {} as ApisauceInstance,
  publishPath: async (path) => {
    const response: ApiResponse<any> = await WorkflowApi.apisauce.post(`/paths/create/`, path)
    return handleResponse(response)
  },
  updatePathPhoto: async (id: number, photo: File) => {
    const formData = new FormData()
    formData.append('photo', photo)

    const response: ApiResponse<null> = await WorkflowApi.apisauce.patch(
      `paths/${id}/image/`,
      formData,
    )

    return handleResponse(response)
  },
  getTopicGaps: async (path_id) => {
    const response: ApiResponse<any> = await WorkflowApi.apisauce.get(
      `/paths/${path_id}/topic_gaps/`,
    )
    return handleResponse(response)
  },
  getQuizGaps: async (path_id) => {
    const response: ApiResponse<any> = await WorkflowApi.apisauce.get(
      `/paths/${path_id}/quiz_gaps/`,
    )
    return handleResponse(response)
  },
  getQuestionGaps: async (path_id) => {
    const response: ApiResponse<any> = await WorkflowApi.apisauce.get(
      `/paths/${path_id}/question_gaps/`,
    )
    return handleResponse(response)
  },
  updatePath: async (path) => {
    const response: ApiResponse<null> = await WorkflowApi.apisauce.patch(`paths/${path.path_id}/`, {
      ...path,
    })

    return handleResponse(response)
  },
  getPathsForUser: async (user_id) => {
    const response: ApiResponse<any> = await WorkflowApi.apisauce.get(`/users/${user_id}/paths/`)
    return handleResponse(response)
  },
  getPathForUser: async (user_id, params) => {
    const { assignment_id, path_id } = params
    const response: ApiResponse<any> = await WorkflowApi.apisauce.get(
      `/users/${user_id}/assignment/${assignment_id}/path/${path_id}/`,
    )
    return handleResponse(response)
  },
  getPath: async (id) => {
    const response: ApiResponse<any> = await WorkflowApi.apisauce.get(`/paths/${id}/`)
    return handleResponse(response)
  },
  getAssignmentPathDetails: async () => {
    const response: ApiResponse<any> = await WorkflowApi.apisauce.get(`/assignments/path_details/`)
    return handleResponse(response)
  },
  deletePaths: async (path_ids: number[]) => {
    const response: ApiResponse<null> = await WorkflowApi.apisauce.delete(
      `paths/delete/`,
      {},
      { data: { path_ids } },
    )

    return handleResponse(response)
  },
  checkDeletePaths: async (path_ids) => {
    const response: ApiResponse<any> = await WorkflowApi.apisauce.post(`/paths/delete/check/`, {
      path_ids,
    })
    return handleResponse(response)
  },
  getPaths: async (
    type: 'paths' | 'users',
    meta?: boolean,
    columns?: any,
    view_name?: string,
    id?: number,
  ) => {
    const payload = { view_name }
    if (columns && columns.length > 0) {
      payload['columns'] = columns
    }

    const response: ApiResponse<any> = await WorkflowApi.apisauce.post(
      `/${type}/?meta=${meta}&asynchronous=${false}`,
      payload,
    )
    return handleResponse(response)
  },
  getWorkflow: async (id: number) => {
    const response: ApiResponse<any> = await WorkflowApi.apisauce.get(`/workflow/${id}/`)
    return handleResponse(response)
  },
  createWorkflow: async (workflow: any, object_id, object_type) => {
    const response: ApiResponse<any> = await WorkflowApi.apisauce.post(`/workflow/`, {
      workflow_json: workflow,
      object_id,
      object_type,
    })
    return handleResponse(response)
  },
  editWorkflow: async (workflow: any, workflow_id) => {
    const response: ApiResponse<any> = await WorkflowApi.apisauce.patch(
      `/workflow/${workflow_id}/`,
      { ...workflow },
    )
    return handleResponse(response)
  },
  createCertification: async (title: string) => {
    const response: ApiResponse<any> = await WorkflowApi.apisauce.post(`/certifications/`, {
      title,
    })
    return handleResponse(response)
  },
}
