import React, { useEffect, useState } from 'react'
import { CircularProgress } from '@mui/material'
import Step from '@mui/material/Step'
import StepButton from '@mui/material/StepButton'
import Stepper from '@mui/material/Stepper'
import { ChevronLeftRounded, Close } from '@mui/icons-material'
import { DateTime } from 'luxon'
import { observer } from 'mobx-react-lite'
import { Instance } from 'mobx-state-tree'

import { useStores } from '@trivie/core'
import { Button } from '@trivie/ui-web/components/button'
import { CreateMessageForm } from '@trivie/core/src/models/CreateMessage'
import { UserGroupSelector } from '@trivie/ui-web/components/user-group-selector'
import { MessageForm } from '@trivie/ui-web/components/message-form'
import { ReviewMessage } from './review-message'
import { MessagePayload } from '@trivie/core/src/models/MessagePayload'
import { Dialog } from '@trivie/ui-web/components/dialogs/dialog'

type CreateMessageDialogProps = {
  open: boolean
  initialTopics?: Array<number>
  initialQuizzes?: Array<number>
  requestClose: (date: string, time: string, users: number) => void
  handleDelete: (id: number) => void
  selectedMessageID?: number
  cancel: () => void
}

export interface IAssigneeOptions {
  type: string
  label: string
  id: number
  number_users: number | undefined
}

export const CreateMessageDialog = observer((props: CreateMessageDialogProps) => {
  const { messageStore } = useStores()
  const {
    createMessage,
    editing,
    selectedMessageID,
    editMessage,
    getMessage,
    getAvailableRecipients,
    setEditing,
    setReviewing,
  } = messageStore

  const { open, handleDelete, cancel, requestClose } = props
  const [activeStep, setActiveStep] = useState(0)
  const [form, setForm] = useState<Instance<typeof CreateMessageForm> | null>(null)
  const steps = ['Recipients', 'Details', 'Review']
  const [assigneeOptions, setAssigneeOptions] = useState<IAssigneeOptions[]>([])
  const [selectedOptions, setSelectedOptions] = useState<IAssigneeOptions[]>([])
  const [userCount, setUserCount] = useState(0)

  const [loading, setLoading] = useState(true)

  const handleAddUserGroup = (selected: IAssigneeOptions[]) => {
    setSelectedOptions(selected)
    if (form) {
      form.set('groups', selected?.filter((selection) => selection.type === 'Groups') ?? [])
      form.set('users', selected?.filter((selection) => selection.type === 'Users') ?? [])
    }

    setUserCount(
      selected.reduce(
        (acc, selection) => (selection?.number_users ? acc + selection?.number_users : acc + 1),
        0,
      ),
    )
  }

  const [defaultOpts, setDefaultOps] = useState<any>()

  useEffect(() => {
    getAvailableRecipients().then((data) => {
      setLoading(false)

      if (editing && selectedMessageID) {
        getMessage(selectedMessageID).then((d) => {
          const u = d?.users || []
          const g = d?.groups || []
          const selectedUserGroups = [...u, ...g]

          setDefaultOps(
            selectedUserGroups?.map((option: any) => {
              return {
                type: option.group_id ? 'Groups' : 'Users',
                label: option.group_id ? option.label : option.display_name,
                number_users: option.group_id ? option.number_users : undefined,
                id: option.group_id ? option.group_id : option.user_id,
              }
            }),
          )

          if (u && g) {
            const userGroups = [...u, ...g]
            const filterOptions = userGroups?.map((option) => {
              return {
                type: option.group_id ? 'Groups' : 'Users',
                label: option.group_id ? option.label : option.display_name,
                number_users: option.group_id ? option.number_users : undefined,
                id: option.group_id ? option.group_id : option.user_id,
              }
            })
            setAssigneeOptions(filterOptions)
          }

          setForm(
            CreateMessageForm.create({
              start_date: DateTime.fromISO(d.send_datetime).toFormat('yyyy-MM-dd'),
              start_time: DateTime.fromISO(d.send_datetime).toLocaleString(DateTime.TIME_24_SIMPLE),
              subject: d.subject,
              message: d.message_text,
              users: selectedOptions.filter((o: any) => o.type === 'Users'),
              groups: selectedOptions.filter((o: any) => o.type === 'Groups'),
              push: d.send_push,
              email: d.send_email,
              schedule: true,
              message_type: 'generic',
            }),
          )
        })
      } else {
        setForm(
          CreateMessageForm.create({
            start_date: DateTime.local().toISODate(),
            start_time: DateTime.local().toLocaleString(DateTime.TIME_24_SIMPLE),
            subject: '',
            message: '',
            users: [],
            groups: [],
            push: false,
            email: false,
            schedule: false,
            message_type: 'generic',
          }),
        )
        if (data && data.users && data && data.groups) {
          const userGroups = [...data.users, ...data.groups]
          const filterOptions = userGroups?.map((option) => {
            return {
              type: option.group_id ? 'Groups' : 'Users',
              label: option.group_id ? option.label : option.display_name,
              number_users: option.group_id ? option.number_users : undefined,
              id: option.group_id ? option.group_id : option.user_id,
            }
          })
          setAssigneeOptions(filterOptions)
        }
      }
    })
  }, [])

  if (!form) {
    return null
  }

  const getStepContent = (step: number) => {
    switch (step) {
      case 0:
        return loading ? (
          <div style={{ display: 'flex', margin: 'auto' }}>
            <CircularProgress />
          </div>
        ) : (
          <UserGroupSelector
            listHeight={310}
            defaultOptions={defaultOpts}
            total={userCount}
            open
            options={assigneeOptions}
            selectedOptions={selectedOptions}
            handleAddUserGroup={handleAddUserGroup}
          />
        )
      case 1:
        return <MessageForm form={form} />
      case 2:
        return <ReviewMessage form={form} />
      default:
        return null
    }
  }

  const isLastStep = () => activeStep > steps.length - 1

  const handleNext = async () => {
    if (activeStep === 2) {
      const p = {
        subject: form.subject,
        message_text: form.message,
        send_email: form.email,
        send_push: form.push,
        send_datetime: form.datetimeUTC,
        user_ids: form.users.map((user) => user.id),
        message_type: form.message_type,
      }

      if (form.groups.length > 0) {
        p['audience'] = {
          audience: form.groups.map((group) => ({
            object_type: 'Group',
            object_id: Number(group.id),
          })),
        }
        p['group_ids'] = null
      }

      const message = MessagePayload.create(p)

      if (editing && selectedMessageID) {
        editMessage(message, selectedMessageID).then(() => {
          setEditing(false)
          setReviewing(false)
          requestClose(form.displayDate, form.displayTime, userCount)
        })
      } else {
        createMessage(message).then(() => {
          setReviewing(false)
          requestClose(form.displayDate, form.displayTime, userCount)
        })
      }
    }
    const newActiveStep = !isLastStep() ? activeStep + 1 : activeStep
    setActiveStep(newActiveStep)
  }

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1)
  }

  const stepIsValid = () => {
    if (activeStep === 0) {
      return Boolean(form.users.length || form.groups.length)
    } else if (activeStep === 1) {
      return !Object.keys(form.errors).length
    } else {
      return true
    }
  }

  return (
    <Dialog
      title={activeStep === 2 ? 'Review' : editing ? 'Edit Message' : 'Send Message'}
      open={open}
      onClose={() => {
        setActiveStep(0)
        cancel()
      }}
      dialogActionsProps={{ sx: { justifyContent: 'space-between' } }}
      sx={{ '& .MuiDialog-paper': { width: '750px !important' } }}
      actions={
        <>
          {activeStep > 0 && (
            <Button
              onClick={handleBack}
              text="Back"
              variant="text"
              startIcon={<ChevronLeftRounded />}
            />
          )}
          <div style={{ display: 'flex', flex: 1, justifyContent: 'flex-end', gap: '12px' }}>
            {editing && (
              <Button
                onClick={() => {
                  if (selectedMessageID) {
                    handleDelete(selectedMessageID)
                  }
                }}
                disabled={!stepIsValid()}
                variant="text"
                color="error"
                text="Delete Message"
              />
            )}
            <Button
              onClick={handleNext}
              disabled={!stepIsValid()}
              text={activeStep === 2 ? 'Send / Schedule' : 'Next'}
            />
          </div>
        </>
      }
    >
      <div style={{ height: 550, display: 'flex', flexDirection: 'column' }}>
        {activeStep !== 2 && (
          <Stepper style={{ marginBottom: 32 }} nonLinear activeStep={activeStep}>
            {steps.map((label) => (
              <Step key={label}>
                <StepButton disableRipple disabled>
                  {label}
                </StepButton>
              </Step>
            ))}
          </Stepper>
        )}
        {getStepContent(activeStep)}
      </div>
    </Dialog>
  )
})
