import React from 'react'
import { Divider, Paper } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import createStyles from '@mui/styles/createStyles'
import { color } from '@trivie/ui-web/theme'
import { Text } from '@trivie/ui-web/components/text'
import { observer } from 'mobx-react-lite'
import { useHistory } from 'react-router'
import { Link } from 'react-router-dom'

import { Logo } from '../login/logo'
import { ReactComponent as AssignmentIcon } from './icons/assignment.svg'
import { ReactComponent as BuildingIcon } from './icons/building.svg'
import { ReactComponent as DoubleCheckIcon } from './icons/double-check.svg'
import { ReactComponent as FaceIcon } from './icons/face.svg'
import { ReactComponent as GroupIcon } from './icons/group.svg'
import { ReactComponent as InviteIcon } from './icons/invite.svg'
import { ReactComponent as LearnerIcon } from './icons/learners.svg'
import { ReactComponent as MercuryIcon } from './icons/mercury.svg'
import { AuthenticationHelper } from '@trivie/core/src/helpers/AuthenticationHelper'
import { useStores } from '@trivie/core'
import { Button } from '@trivie/ui-web/components/button/button'
import { AutoFixHighOutlined } from '@mui/icons-material'

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      background: 'rgb(255, 255, 255)',
      padding: '15px',
      border: '2px solid #FFFFFF',
      borderRadius: '8px',
      boxSizing: 'border-box',
      display: 'flex',
      marginTop: '20px',
      width: '90%',
      '&:hover': {
        border: '2px solid #fffefe8c',
        boxShadow: '0px 1px 20px rgb(0 0 0 / 5%)',
      },
    },
  }),
)

const DIVIDER: React.CSSProperties = {
  height: '600px',
  padding: '0.5px',
  backgroundColor: '#CCCCCC',
}
const LINKS = [
  {
    Icon: BuildingIcon,
    title: 'Set up your company workspace and logo ',
    to: '/settings',
  },
  {
    Icon: DoubleCheckIcon,
    title: 'Create and publish topics',
    to: '/content/topics',
  },
  {
    Icon: InviteIcon,
    title: 'Invite admins and content authors',
    to: '/settings#admin',
  },
  {
    Icon: FaceIcon,
    title: 'Add learners',
    to: '/people/users',
  },
  {
    Icon: GroupIcon,
    title: 'Create a group and measure performance ',
    to: '/people/groups',
  },
  {
    Icon: AssignmentIcon,
    title: 'Create and send assignments',
    to: '/activities/assignments/live',
  },
  {
    Icon: LearnerIcon,
    title: 'See what learners see ',
    to: `https://engage${
      process.env.REACT_APP_ENV === 'test'
        ? '.test.'
        : process.env.REACT_APP_ENV === 'dev'
          ? '.dev.'
          : '.'
    }trivie.com/play`,
    external: true,
  },
]

export const GettingStarted = observer(() => {
  const history = useHistory()
  const classes = useStyles()
  const { manageUserStore } = useStores()
  const { userProfile } = manageUserStore

  const mercury_enabled = userProfile?.workspace_settings?.mercury_enabled

  return (
    <div style={{ display: 'flex' }}>
      <div style={{ width: '45%', paddingRight: 32, display: 'flex', flexDirection: 'column' }}>
        <Logo style={{ height: 42 }} />
        <Text
          variant="custom"
          text={`Welcome to ${process.env.REACT_APP_SITE_TITLE}!`}
          style={{
            fontWeight: 700,
            fontSize: 32,
            lineHeight: '39px',
            fontStyle: 'normal',
            marginTop: 20,
            marginBottom: 24,
          }}
        />
        <div style={{ marginBottom: 25 }}>
          <Text
            variant="labelMedium"
            text={`We're so glad you're here! ${process.env.REACT_APP_SITE_TITLE} is the all-in-one learning retention system that your team will love.`}
            style={{ color: color.shade50 }}
          />
        </div>
        {mercury_enabled == false && (
          <Button onClick={() => history.push('/settings')} text="Get Started" />
        )}

        {mercury_enabled && (
          <div style={{ display: 'flex', gap: 25, flexDirection: 'column' }}>
            <Text
              variant="labelMedium"
              text="To get started, start in on the Quick Reference guide."
              style={{ color: color.shade50 }}
            />
            <Text
              variant="labelMedium"
              text="Or start generating quizzes now with Trivie AI!"
              style={{ color: color.shade50 }}
            />
            <Button
              sx={{ '&:hover': { opacity: 0.8 } }}
              startIcon={<AutoFixHighOutlined />}
              color="gradient"
              onClick={() =>
                history.push('/content/creation/quiz/new/edit?generate=true&create=true', {
                  openAI: true,
                })
              }
              text="Generate Quiz"
            />
          </div>
        )}
        <script src="https://player.vimeo.com/api/player.js"></script>
      </div>
      <div style={{ display: 'flex', flex: 1, marginTop: '120px', paddingBottom: '80px' }}>
        <Divider orientation="vertical" style={DIVIDER} />
        <div style={{ flex: 1, paddingLeft: 32 }}>
          <Text variant="body1Emphasized" text="Quick Reference" />
          {LINKS.map(({ Icon, title, to, external }, index: number) =>
            external ? (
              <a
                style={{
                  textDecoration: 'none',
                }}
                target="_blank"
                rel="noreferrer"
                href={`${to}?token=${AuthenticationHelper.token}&access_code=${AuthenticationHelper.accessCode}`}
              >
                <Paper elevation={4} variant="outlined" classes={{ root: classes.root }}>
                  <Icon />
                  <Text
                    style={{ marginLeft: '10px', cursor: 'pointer' }}
                    variant="body1Emphasized"
                    text={title}
                  />
                </Paper>
              </a>
            ) : (
              <>
                {index === 1 && (
                  <div
                    style={{
                      textDecoration: 'none',
                      display: mercury_enabled ? 'block' : 'none',
                    }}
                    onClick={() => {
                      history.push('/content/creation/quiz/new/edit?generate=true&create=true', {
                        openAI: true,
                      })
                    }}
                  >
                    <Paper
                      elevation={4}
                      variant="outlined"
                      classes={{ root: classes.root }}
                      style={{ alignItems: 'center' }}
                    >
                      <MercuryIcon />
                      <Text
                        style={{ marginLeft: '10px', cursor: 'pointer' }}
                        variant="body1Emphasized"
                        text={'Generate quizzes with AI'}
                      />
                    </Paper>
                  </div>
                )}
                <Link
                  style={{
                    textDecoration: 'none',
                  }}
                  to={to}
                >
                  <Paper elevation={4} variant="outlined" classes={{ root: classes.root }}>
                    <Icon />
                    <Text
                      style={{ marginLeft: '10px', cursor: 'pointer' }}
                      variant="body1Emphasized"
                      text={title}
                    />
                  </Paper>
                </Link>
              </>
            ),
          )}
        </div>
      </div>
    </div>
  )
})
