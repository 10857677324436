import { types, getEnv, getRoot, flow, Instance } from 'mobx-state-tree'
import { getStrongestWeakest, intersection, transformHourlyActivity } from '../../utils'
import { TagBaseModel } from '../base'
import { Environment } from '../environment'
import { TagApi } from '../../services/api-objects/TagApi'

export const TagStoreModel = types
  .model('TagStore')
  .props({
    status: types.optional(types.enumeration(['idle', 'pending', 'done', 'error']), 'idle'),
    tags: types.frozen(),
    topics: types.frozen(),
    questions: types.frozen(),
    tagsFilter: types.maybe(types.string),
    tagKeyFilter: types.optional(types.string, ''),
    selectedTagId: types.maybe(types.number),
    overviewStats: types.maybe(types.frozen()),
    accomplishingStats: types.frozen(),
    tagSessionStats: types.frozen(),
    hourlyActivity: types.frozen(),
    knowledgeGainStats: types.frozen(),
    knowledgeGapStats: types.frozen(),
    tagTopicsFilter: types.maybe(types.string),
    loadingGroups: types.optional(types.boolean, false),
    groups: types.frozen(),
    users: types.frozen(),
    selectedCategory: types.frozen(),
    categoryTags: types.frozen(),
    taggableUsers: types.frozen(),
    mastery: types.frozen(),
  })
  .actions((self) => ({
    setStatus(value?: 'idle' | 'pending' | 'done' | 'error') {
      self.status = value || 'idle'
    },
    setTags(tags: { [index: string]: [Instance<typeof TagBaseModel>] }) {
      self.tags = tags
    },
    setTagsFilter(filter: string | undefined) {
      self.tagsFilter = filter
    },
    setTagKeyFilter(filter: string) {
      self.tagKeyFilter = filter
    },
    setSelectedTagId(id: number) {
      self.selectedTagId = id
    },
    setTagOverviewStats(stats: any) {
      self.overviewStats = stats
    },
    setAccomplishingStats(stats: any) {
      self.accomplishingStats = stats
    },
    setTagSessionStats(tagSessionStats: any) {
      self.tagSessionStats = tagSessionStats
    },
    setHourlyActivity(hourlyActivity: any) {
      self.hourlyActivity = hourlyActivity
    },
    setKnowledgeGainStats(stats: any) {
      self.knowledgeGainStats = stats
    },
    setKnowledgeGapStats(stats: any) {
      self.knowledgeGapStats = stats
    },
    setTagTopics(topics: any) {
      self.topics = topics
    },
    setTagQuestions(questions: any) {
      self.questions = questions
    },
    setTagTopicsFilter(filter: any) {
      self.tagTopicsFilter = filter
    },
    setTagUsers(users: any) {
      self.users = users
    },
    setTagGroups(groups: any) {
      self.groups = groups
    },
    setLoadingGroups(value: boolean) {
      self.loadingGroups = value
    },
    setSelectedCategory(category: any) {
      self.selectedCategory = category
      ;(self as any).getTagCategoryDetail()
    },
    setCategoryTags(tags: any) {
      self.categoryTags = tags
    },
    setTaggableUsers(users: any) {
      self.taggableUsers = users
    },
    setMastery(mastery: any) {
      self.mastery = mastery
    },
  }))
  .views((self) => ({
    get environment() {
      return getEnv(self) as Environment
    },
    get isLoading() {
      return self.status === 'pending'
    },
    get filteredTags() {
      if (!self.tagsFilter) {
        return self.tags
      }

      return Object.entries(self.tags).reduce((acc: any, entry) => {
        const [key, tags] = entry
        const filteredTags = (tags as any).filter((tag: any) =>
          tag.value.toLocaleLowerCase().includes(self.tagsFilter!.toLocaleLowerCase()),
        )

        if (filteredTags.length) {
          acc[key] = filteredTags
        }

        return acc
      }, {})
    },
    get rootStore(): any {
      return getRoot(self) as any
    },
  }))
  .actions((self) => ({
    refreshOverviewData: flow(function* (start: string, end: string) {
      self.setStatus('pending')

      if (!self.selectedTagId) {
        return
      }

      try {
        // TODO run these all at the same time

        let result = yield self.environment.api.getTagOverviewStats(self.selectedTagId, start, end)
        if (result.kind === 'ok') {
          self.setTagOverviewStats(result.data)
          self.setStatus('done')
        } else {
          self.setStatus('error')
        }

        result = yield self.environment.api.getTagSessionStats(self.selectedTagId, start, end)
        self.setTagSessionStats({
          ...result.data,
          avg_session_length: (result.data.avg_session_length / 60).toFixed(0),
          avg_time_per_user: (result.data.avg_time_per_user / 60).toFixed(0),
        })

        result = yield self.environment.api.getTagAccomplishingStats(self.selectedTagId, start, end)
        self.setAccomplishingStats(result.data)

        result = yield self.environment.api.getTagHourlyActivity(self.selectedTagId, start, end)
        self.setHourlyActivity(transformHourlyActivity(result.data))

        result = yield self.environment.api.getTagKnowledgeGainStats(self.selectedTagId, start, end)
        self.setKnowledgeGainStats(result.data)

        const resultTopics = yield self.environment.api.getTagTopicGapsStats(
          self.selectedTagId,
          start,
          end,
        )
        const resultQuestions = yield self.environment.api.getTagQuestionGapsStats(
          self.selectedTagId,
          start,
          end,
        )

        const strongestWeakestTopics = getStrongestWeakest(resultTopics?.data?.topics)
        const strongestWeakestQuestions = getStrongestWeakest(resultQuestions?.data?.questions)

        const data = {
          tag_id: self.selectedTagId,
          strongest_questions: strongestWeakestQuestions.strongest,
          strongest_topics: strongestWeakestTopics.strongest,
          weakest_questions: strongestWeakestQuestions.weakest,
          weakest_topics: strongestWeakestTopics.weakest,
        }
        self.setKnowledgeGapStats(data)
        ;(self as any).getTagMastery(start, end)
      } catch {
        self.setStatus('error')
      }
    }),
    deleteTags: flow(function* (tag_ids: any, safe?: boolean) {
      self.setStatus('pending')

      try {
        const result = yield self.environment.api.deleteTags(tag_ids, safe)

        if (result.kind === 'ok') {
          self.setStatus('done')
        } else {
          self.setStatus('error')
        }
      } catch {
        self.setStatus('error')
      }
    }),

    addUsersToTags: flow(function* (userIds: any, tagIds: any, successCb?: any) {
      self.setStatus('pending')

      try {
        const result = yield self.environment.api.addUsersToTags(userIds, tagIds)
        if (result.kind === 'ok') {
          self.setStatus('done')
          if (successCb) {
            successCb()
          }
        } else {
          self.setStatus('error')
        }
      } catch {
        self.setStatus('error')
      }
    }),
    removeUsersFromTags: flow(function* (userIds: any, tagIds: any) {
      self.setStatus('pending')

      try {
        const result = yield self.environment.api.removeUsersFromTags(userIds, tagIds)
        if (result.kind === 'ok') {
          self.setStatus('done')
        } else {
          self.setStatus('error')
        }
      } catch {
        self.setStatus('error')
      }
    }),
    createTag: flow(function* (tagData: any) {
      self.setStatus('pending')

      try {
        const result = yield self.environment.api.createTag(tagData.create_group, {
          key: tagData.key,
          value: tagData.value,
          is_sticky: tagData.is_sticky,
        })
        if (result.kind === 'ok') {
          self.setStatus('done')
        } else {
          self.setStatus('error')
        }
      } catch {
        self.setStatus('error')
      }
    }),
    editTag: flow(function* (tag_id: number, payload: any) {
      self.setStatus('pending')
      try {
        const result: Awaited<ReturnType<typeof TagApi.editTag>> = yield TagApi.editTag(
          tag_id,
          payload,
        )
        if (result.ok) {
          self.setStatus('done')
        } else {
          self.setStatus('error')
        }
      } catch (e) {
        self.setStatus('error')
        return { error: e }
      }
    }),
    getTagCategoryDetail: flow(function* () {
      self.setStatus('pending')

      if (!self.selectedCategory) {
        self.setStatus('error')
        return
      }

      try {
        const result = yield self.environment.api.getTagCategoryDetail(self.selectedCategory)
        if (result.kind === 'ok') {
          self.setCategoryTags(result.data)
          self.setStatus('done')
        } else {
          self.setStatus('error')
        }
      } catch {
        self.setStatus('error')
      }
    }),
    getTaggableUsers: flow(function* (filter: string, tagIds: number[]) {
      self.setStatus('pending')
      self.setTaggableUsers(undefined)

      try {
        const promises = tagIds.map((id) => self.environment.api.getTaggableUsers(filter, id))
        const results = yield Promise.all(promises)

        if (results.every((result: any) => result.kind === 'ok')) {
          const finalResults = results.reduce((acc: any, result: any, index: number) => {
            if (index === 0) {
              return result.data
            }

            return intersection(acc, result.data, (val1: any, val2: any) => val1.id === val2.id)
          }, [])

          self.setTaggableUsers(finalResults)
          self.setStatus('done')
        } else {
          self.setStatus('error')
        }
      } catch {
        self.setStatus('error')
      }
    }),
    getTagMastery: flow(function* (start: string, end: string) {
      self.setStatus('pending')

      if (!self.selectedTagId) {
        self.setStatus('error')
        return
      }

      try {
        const result = yield self.environment.api.getTagMastery(self.selectedTagId, start, end)
        if (result.kind === 'ok') {
          self.setMastery(result.data)
          self.setStatus('done')
        } else {
          self.setStatus('error')
        }
      } catch {
        self.setStatus('error')
      }
    }),
  }))

type TagStoreType = typeof TagStoreModel.Type
export interface TagStore extends TagStoreType {}
type TagStoreSnapshotType = typeof TagStoreModel.SnapshotType
export interface TagStoreSnapshot extends TagStoreSnapshotType {}
