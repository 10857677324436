import { SnapshotIn, types, cast } from 'mobx-state-tree'
import { DateTime } from 'luxon'
import { validateAndFormatNumber } from '../utils/phoneNumber'

export const TagKeyItem = types.model('TagKey').props({
  tag_id: types.number,
  key: types.string,
  value: types.string,
})

export const InviteUserForm = types
  .model('InviteUserForm')
  .props({
    start_date: types.string,
    start_time: types.string,
    first_name: types.string,
    last_name: types.string,
    email: types.string,
    phone_number: types.string,
    employee_id: types.string,
    send_email: types.boolean,
    send_sms: types.boolean,
    tag_keys_values: types.array(TagKeyItem),
    file: types.frozen(),
    missingRequired: types.boolean,
    missingRequiredAdmin: types.boolean,
  })
  .views((self) => {
    return {
      get datetimeUTC() {
        return DateTime.fromJSDate(new Date(`${self.start_date} ${self.start_time}`))
          .toUTC()
          .toISO()
      },
      get displayTime() {
        return DateTime.fromJSDate(
          new Date(`${self.start_date} ${self.start_time}`),
        ).toLocaleString(DateTime.TIME_SIMPLE)
      },
      get displayDate() {
        return DateTime.fromJSDate(new Date(`${self.start_date} ${self.start_time}`)).toFormat(
          'MMM d, yyyy',
        )
      },
      get errors() {
        const errors: any = {}
        if (!self.first_name) {
          errors.first_name = {
            type: 'required',
            message: 'First name is required',
          }
        }

        if (!self.last_name) {
          errors.last_name = {
            type: 'required',
            message: 'Last name is required',
          }
        }

        if (self.email && !self.email.includes('@')) {
          errors.email = {
            type: 'required',
            message: 'Email must include an @ symbol',
          }
        }

        if (self.phone_number && !validateAndFormatNumber(self.phone_number)) {
          errors.phone_number = {
            type: 'required',
            message: 'Please enter a valid phone number',
          }
        }

        // no need to validate beyond this since we're missing required fields
        if (Object.keys(errors).length) {
          return errors
        }

        const startDateTime = DateTime.fromISO(`${self.start_date}T${self.start_time}:00`)

        if (!startDateTime.isValid) {
          return {
            start_date: {
              type: 'invalid',
              message: 'Date is Invalid',
            },
          }
        }

        return errors
      },
    }
  })
  .actions((self) => {
    return {
      set<K extends keyof SnapshotIn<typeof self>, T extends SnapshotIn<typeof self>>(
        key: K,
        value: T[K],
      ) {
        self[key] = cast(value)
      },
    }
  })
