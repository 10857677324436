import { observer } from 'mobx-react-lite'
import React, { useState } from 'react'
import { IconButton, Skeleton, Stack } from '@mui/material'
import {
  AccessTimeRounded,
  BoltRounded,
  Delete,
  DescriptionRounded,
  Edit,
  FactCheckRounded,
  GroupRounded,
  LocalOfferOutlined,
  LocalOfferRounded,
  TodayRounded,
  Topic,
  Warning,
  WarningRounded,
  WorkspacePremiumOutlined,
} from '@mui/icons-material'

import { useStores, GetBackgroundColorOfMimeType } from '@trivie/core'
import { Text } from '@trivie/ui-web/components/text'
import { color } from '@trivie/ui-web/theme'
import { ConfirmDialog } from '@trivie/ui-web/components/confirm-dialog'
import { ActionTooltip } from '@trivie/ui-web/components/table-toolbar'
import { ResourceIcon } from '@trivie/ui-web/components/resource-icon'

import { Duration, DateTime } from 'luxon'
import { FullTag } from '@trivie/ui-web/components/full-tag'
import { isAction } from '@trivie/core/src/type-guards/isAction'

interface StepProps {
  step: any
  node?: any
  root?: boolean
  index: number
  handleAddStep: () => void
  readonly?: boolean
  block?: any
  style?: React.CSSProperties
}

export const Step = observer((props: StepProps) => {
  const { pathStore } = useStores()
  const { workflow, path } = pathStore
  const { style, block, handleAddStep, readonly, node, root, step, index } = props

  const [showConfirmDelete, setShowConfirmDelete] = useState(false)

  const handleDelete = () => {
    setShowConfirmDelete(true)
  }

  const edit = () => {
    workflow?.selectStep(undefined)
    workflow?.selectStep(step)
  }

  const onDelete = () => {
    workflow?.selectStep(undefined)
    setShowConfirmDelete(false)
    if (!path && workflow?.actions.filter((a) => a.action_type !== 'join')?.length === 1) {
      // if on topic workflow and not a full path
      // and there's only one action - delete the entire workflow model
      pathStore.deleteWorkflow()
    } else if (step.condition_name) {
      workflow?.removeNode(
        workflow?.nodes.find((n) =>
          n.conditions.find((c) => c.criteria.includes(step.reference_id)),
        ),
      )
    } else {
      // if step is an action destroy action
      workflow?.removeAction(step)
    }
  }

  const getExtension = (resource) => {
    if (resource?.resource_file_type === 'text/html') return 'url'
    return resource?.resource_file_type
  }

  const getDelayLabel = () => {
    const dur = Duration.fromObject({ minutes: step.minutes }).shiftTo('days', 'hours', 'minutes')
    const { days, hours, minutes } = dur

    const result = [] as string[]

    if (days > 0) {
      result.push(`${days} day${days === 1 ? '' : 's'}`)
    }

    if (hours > 0) {
      result.push(`${hours} hour${hours === 1 ? '' : 's'}`)
    }

    if (minutes > 0) {
      result.push(`${minutes} minute${minutes === 1 ? '' : 's'}`)
    }

    return `${result.join(', ')} ${
      step.action_type === 'delay'
        ? 'from Previous Step Completion'
        : step.action_type === 'delay_from'
          ? 'from User Receives Assignment'
          : ''
    }`
  }

  return (
    <Stack
      height="86.52px"
      direction="row"
      position="relative"
      alignItems="center"
      style={{ margin: '0 auto', ...style }}
      width="100%"
    >
      <div style={MAIN}>
        <div style={CONTAINER}>
          <div style={CONTENT}>
            <div style={IMG}>
              <div
                style={{
                  ...ICON_IMAGE,
                  border:
                    step.action_type === 'award_badge' || step.action_type === 'award_certification'
                      ? `1px solid ${color.shade30}`
                      : '',
                  backgroundColor:
                    step.action_type === 'award_badge' || step.action_type === 'award_certification'
                      ? color.white
                      : color.shade30,
                }}
              >
                {step.condition_name === 'view_source' && (
                  <div
                    style={{
                      ...ICON_IMAGE,
                      ...RESOURCE_ICON,
                      background: GetBackgroundColorOfMimeType(getExtension(step.source)),
                    }}
                  >
                    <ResourceIcon extension={getExtension(step.source)} white={true} />
                  </div>
                )}
                {(step.condition_name === 'complete_topic' || step?.verb === 'score') &&
                step?.topic?.photo_url ? (
                  <img style={TOPIC_IMG} src={step?.topic?.photo_url} />
                ) : step?.quiz?.photo_url ? (
                  <img style={TOPIC_IMG} src={step?.quiz?.photo_url} />
                ) : step?.topic ? (
                  <Topic htmlColor={color.white} style={{ fontSize: 32 }} />
                ) : step?.quiz ? (
                  <BoltRounded htmlColor={color.white} style={{ fontSize: 32 }} />
                ) : null}
                {step.action_type === 'add_to_assignment' && (
                  <FactCheckRounded htmlColor={color.white} style={{ fontSize: 32 }} />
                )}
                {step.action_type === 'add_to_group' && (
                  <GroupRounded htmlColor={color.white} style={{ fontSize: 32 }} />
                )}
                {step.action_type === 'tag_user' && (
                  <LocalOfferRounded htmlColor={color.white} style={{ fontSize: 32 }} />
                )}
                {step.action_type === 'untag_user' && (
                  <LocalOfferOutlined htmlColor={color.white} style={{ fontSize: 32 }} />
                )}
                {step.action_type === 'award_badge' && (
                  <img
                    style={{ borderRadius: 100 }}
                    src={step.badge?.photo_url || step.badge?.icon_url}
                    height={32}
                    width={32}
                  />
                )}
                {step.action_type === 'wait_until' && (
                  <TodayRounded htmlColor={color.white} style={{ fontSize: 32 }} />
                )}
                {(step.action_type === 'delay' || step.action_type === 'delay_from') && (
                  <AccessTimeRounded htmlColor={color.white} style={{ fontSize: 32 }} />
                )}
                {step.action_type === 'award_certification' && (
                  <svg
                    width="32"
                    height="33"
                    viewBox="0 0 32 33"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M14.1562 2.42082C15.1621 1.37292 16.8379 1.37293 17.8438 2.42082L18.6326 3.24252C19.2589 3.89498 20.1834 4.16785 21.0636 3.96008L22.1633 3.7005C23.5711 3.36817 24.974 4.26942 25.257 5.68801L25.4904 6.8584C25.6658 7.7376 26.289 8.46063 27.1328 8.76375L28.2401 9.16158C29.5911 9.64696 30.2775 11.1495 29.7598 12.4885L29.3166 13.6351C28.9957 14.4652 29.1298 15.4026 29.6705 16.1095L30.4144 17.082C31.2847 18.2198 31.0502 19.8505 29.8946 20.6969L28.9225 21.4088C28.2025 21.9361 27.8097 22.8008 27.8863 23.6899L27.9907 24.9032C28.1142 26.3387 27.0274 27.5925 25.5889 27.6741L24.4421 27.7391C23.5425 27.7901 22.7363 28.311 22.32 29.1101L21.7822 30.1425C21.1119 31.4292 19.506 31.9006 18.2466 31.1803L17.2689 30.6211C16.4827 30.1714 15.5173 30.1714 14.7311 30.6211L13.7534 31.1803C12.494 31.9006 10.8881 31.4292 10.2178 30.1425L9.67996 29.1101C9.26369 28.311 8.45746 27.7901 7.55792 27.7391L6.41109 27.6741C4.97258 27.5925 3.88577 26.3387 4.00932 24.9032L4.11374 23.6899C4.19027 22.8008 3.79746 21.9361 3.07747 21.4088L2.10545 20.6969C0.949809 19.8505 0.715285 18.2198 1.58557 17.082L2.3295 16.1095C2.87025 15.4026 3.0043 14.4652 2.68338 13.6351L2.24015 12.4885C1.72253 11.1495 2.40892 9.64696 3.75993 9.16158L4.86725 8.76375C5.71097 8.46063 6.33418 7.7376 6.50956 6.8584L6.74303 5.68801C7.02601 4.26942 8.42885 3.36817 9.83671 3.7005L10.9364 3.96008C11.8166 4.16785 12.7411 3.89497 13.3674 3.24252L14.1562 2.42082Z"
                      fill="url(#paint0_linear_3126_3140)"
                    />
                    <path
                      d="M14.1562 2.42082C15.1621 1.37292 16.8379 1.37293 17.8438 2.42082L18.6326 3.24252C19.2589 3.89498 20.1834 4.16785 21.0636 3.96008L22.1633 3.7005C23.5711 3.36817 24.974 4.26942 25.257 5.68801L25.4904 6.8584C25.6658 7.7376 26.289 8.46063 27.1328 8.76375L28.2401 9.16158C29.5911 9.64696 30.2775 11.1495 29.7598 12.4885L29.3166 13.6351C28.9957 14.4652 29.1298 15.4026 29.6705 16.1095L30.4144 17.082C31.2847 18.2198 31.0502 19.8505 29.8946 20.6969L28.9225 21.4088C28.2025 21.9361 27.8097 22.8008 27.8863 23.6899L27.9907 24.9032C28.1142 26.3387 27.0274 27.5925 25.5889 27.6741L24.4421 27.7391C23.5425 27.7901 22.7363 28.311 22.32 29.1101L21.7822 30.1425C21.1119 31.4292 19.506 31.9006 18.2466 31.1803L17.2689 30.6211C16.4827 30.1714 15.5173 30.1714 14.7311 30.6211L13.7534 31.1803C12.494 31.9006 10.8881 31.4292 10.2178 30.1425L9.67996 29.1101C9.26369 28.311 8.45746 27.7901 7.55792 27.7391L6.41109 27.6741C4.97258 27.5925 3.88577 26.3387 4.00932 24.9032L4.11374 23.6899C4.19027 22.8008 3.79746 21.9361 3.07747 21.4088L2.10545 20.6969C0.949809 19.8505 0.715285 18.2198 1.58557 17.082L2.3295 16.1095C2.87025 15.4026 3.0043 14.4652 2.68338 13.6351L2.24015 12.4885C1.72253 11.1495 2.40892 9.64696 3.75993 9.16158L4.86725 8.76375C5.71097 8.46063 6.33418 7.7376 6.50956 6.8584L6.74303 5.68801C7.02601 4.26942 8.42885 3.36817 9.83671 3.7005L10.9364 3.96008C11.8166 4.16785 12.7411 3.89497 13.3674 3.24252L14.1562 2.42082Z"
                      fill="url(#paint1_linear_3126_3140)"
                      fillOpacity="0.1"
                    />
                    <path
                      opacity="0.8"
                      d="M14.1726 18.856L12.0426 16.7041C11.9279 16.588 11.7722 16.5227 11.6098 16.5227C11.4474 16.5227 11.2917 16.588 11.177 16.7041C10.9376 16.9459 10.9376 17.3366 11.177 17.5785L13.7429 20.1708C13.9823 20.4126 14.369 20.4126 14.6084 20.1708L21.1029 13.6095C21.3423 13.3677 21.3423 12.977 21.1029 12.7351C20.9882 12.619 20.8325 12.5537 20.6701 12.5537C20.5078 12.5537 20.3521 12.619 20.2374 12.7351L14.1726 18.856Z"
                      fill="white"
                      stroke="#FDFDFD"
                      strokeWidth="0.381721"
                    />
                    <path
                      d="M27.2427 16.9322C27.2427 23.1417 22.2089 28.1755 15.9995 28.1755C9.79 28.1755 4.75623 23.1417 4.75623 16.9322C4.75623 10.7227 9.79 5.68896 15.9995 5.68896C22.2089 5.68896 27.2427 10.7227 27.2427 16.9322ZM7.20305 16.9322C7.20305 21.7903 11.1413 25.7286 15.9995 25.7286C20.8576 25.7286 24.7959 21.7903 24.7959 16.9322C24.7959 12.0741 20.8576 8.13579 15.9995 8.13579C11.1413 8.13579 7.20305 12.0741 7.20305 16.9322Z"
                      fill="white"
                      fillOpacity="0.2"
                    />
                    <path
                      d="M27.2427 16.9322C27.2427 23.1417 22.2089 28.1755 15.9995 28.1755C9.79 28.1755 4.75623 23.1417 4.75623 16.9322C4.75623 10.7227 9.79 5.68896 15.9995 5.68896C22.2089 5.68896 27.2427 10.7227 27.2427 16.9322ZM7.20305 16.9322C7.20305 21.7903 11.1413 25.7286 15.9995 25.7286C20.8576 25.7286 24.7959 21.7903 24.7959 16.9322C24.7959 12.0741 20.8576 8.13579 15.9995 8.13579C11.1413 8.13579 7.20305 12.0741 7.20305 16.9322Z"
                      fill="white"
                      fillOpacity="0.5"
                    />
                    <defs>
                      <linearGradient
                        id="paint0_linear_3126_3140"
                        x1="5.51195"
                        y1="3.5586"
                        x2="23.5041"
                        y2="31.6295"
                        gradientUnits="userSpaceOnUse"
                      >
                        <stop stopColor="#FDCF5C" />
                        <stop offset="1" stopColor="#ECAF17" />
                      </linearGradient>
                      <linearGradient
                        id="paint1_linear_3126_3140"
                        x1="-5.4955"
                        y1="-2"
                        x2="18.8128"
                        y2="41.3882"
                        gradientUnits="userSpaceOnUse"
                      >
                        <stop stopColor="white" />
                        <stop offset="1" stopColor="white" stopOpacity="0" />
                      </linearGradient>
                    </defs>
                  </svg>
                )}
              </div>
            </div>
            <div style={LABEL}>
              {step?.stub && (
                <>
                  <Skeleton style={{ marginBottom: 2 }} variant="rounded" width={60} height={18} />
                  <Skeleton style={{ marginTop: 2 }} variant="rounded" width={100} height={18} />
                </>
              )}
              {step?.source && step.condition_name === 'view_source' && (
                <>
                  <Text
                    component="div"
                    style={{ marginBottom: 8 }}
                    variant="body2Medium"
                    text={step.label}
                  />
                  <div style={{ flexDirection: 'row', display: 'flex', alignItems: 'center' }}>
                    <DescriptionRounded
                      htmlColor={color.shade70}
                      style={{ fontSize: 20, marginRight: 8 }}
                    />
                    <Text
                      noWrap
                      component="div"
                      variant="body1"
                      style={{ width: 0, flex: 1 }}
                      text={step?.source.resource_title}
                    />
                  </div>
                </>
              )}
              {step?.topic && step.condition_name === 'complete_topic' && (
                <>
                  <Text
                    component="div"
                    style={{ marginBottom: 8 }}
                    variant="body2Medium"
                    text={step.label}
                  />
                  <div style={{ flexDirection: 'row', display: 'flex', alignItems: 'center' }}>
                    <Topic htmlColor={color.shade70} style={{ fontSize: 20, marginRight: 8 }} />
                    <Text
                      noWrap
                      component="div"
                      style={{ width: 0, flex: 1 }}
                      variant="body1"
                      text={step?.topic.topic_title}
                    />
                  </div>
                </>
              )}
              {step.verb === 'score' && (
                <>
                  <Text
                    component="div"
                    style={{ marginBottom: 8 }}
                    variant="body2Medium"
                    text={step.label}
                  />
                  <div style={{ flexDirection: 'row', display: 'flex', alignItems: 'center' }}>
                    {step?.topic ? (
                      <Topic htmlColor={color.shade70} style={{ fontSize: 20, marginRight: 8 }} />
                    ) : (
                      <BoltRounded
                        htmlColor={color.shade70}
                        style={{ fontSize: 20, marginRight: 8 }}
                      />
                    )}
                    <Text
                      noWrap
                      component="div"
                      style={{ width: 0, flex: 1 }}
                      variant="body1"
                      text={step?.topic ? step?.topic.topic_title : step?.quiz?.quiz_title}
                    />
                  </div>
                </>
              )}
              {step?.quiz && step.condition_name === 'complete_quiz' && (
                <>
                  <Text
                    component="div"
                    style={{ marginBottom: 8 }}
                    variant="body2Medium"
                    text={step.label}
                  />
                  <div style={{ flexDirection: 'row', display: 'flex', alignItems: 'center' }}>
                    <BoltRounded
                      htmlColor={color.shade70}
                      style={{ fontSize: 20, marginRight: 8 }}
                    />
                    <Text
                      noWrap
                      component="div"
                      style={{ width: 0, flex: 1 }}
                      variant="body1"
                      text={step?.quiz.quiz_title}
                    />
                  </div>
                </>
              )}
              {step.action_type === 'tag_user' && (
                <>
                  <Text
                    component="div"
                    style={{ marginBottom: 8 }}
                    variant="body2Medium"
                    text={step.label}
                  />
                  <div
                    style={{
                      flexDirection: 'row',
                      display: 'flex',
                      paddingRight: 20,
                      flexWrap: 'wrap',
                    }}
                  >
                    {step.tags?.map((tag) => {
                      return <FullTag style={{ height: 29, marginRight: 8 }} tag={tag} />
                    })}
                  </div>
                </>
              )}
              {step.action_type === 'untag_user' && (
                <>
                  <Text
                    component="div"
                    style={{ marginBottom: 8 }}
                    variant="body2Medium"
                    text={step.label}
                  />
                  <div
                    style={{
                      flexDirection: 'row',
                      display: 'flex',
                      paddingRight: 20,
                      flexWrap: 'wrap',
                    }}
                  >
                    {step.tags?.map((tag) => {
                      return (
                        <FullTag
                          style={{
                            height: 29,
                            marginRight: 8,
                            marginTop: 8,
                          }}
                          tag={tag}
                        />
                      )
                    })}
                  </div>
                </>
              )}
              {step.action_type === 'add_to_group' && (
                <>
                  <Text
                    component="div"
                    style={{ marginBottom: 8 }}
                    variant="body2Medium"
                    text={step.label}
                  />
                  <Text
                    noWrap
                    component="div"
                    style={{ flex: 1 }}
                    variant="body1"
                    text={step.group?.group_name}
                  />
                </>
              )}
              {step.action_type === 'wait_until' && (
                <>
                  <Text
                    component="div"
                    style={{ marginBottom: 8 }}
                    variant="body2Medium"
                    text={step.label}
                  />
                  <Text
                    noWrap
                    component="div"
                    style={{ flex: 1 }}
                    variant="body1"
                    text={DateTime.fromISO(step.wait_until_datetime).toFormat('MMM. d, yyyy')}
                  />
                </>
              )}
              {(step.action_type === 'delay' || step.action_type === 'delay_from') && (
                <>
                  <Text
                    component="div"
                    style={{ marginBottom: 8 }}
                    variant="body2Medium"
                    text={step.label}
                  />
                  <Text
                    noWrap
                    component="div"
                    style={{ flex: 1 }}
                    variant="body1"
                    text={getDelayLabel()}
                  />
                </>
              )}

              {step.action_type === 'add_to_assignment' && (
                <>
                  <Text
                    component="div"
                    style={{ marginBottom: 8 }}
                    variant="body2Medium"
                    text={step.label}
                  />
                  <Text
                    noWrap
                    component="div"
                    style={{ flex: 1 }}
                    variant="body1"
                    text={step.assignment?.assignment_title}
                  />
                </>
              )}
              {step.action_type === 'award_badge' && (
                <>
                  <Text
                    component="div"
                    style={{ marginBottom: 8 }}
                    variant="body2Medium"
                    text={step.label}
                  />
                  <div style={{ flexDirection: 'row', display: 'flex', alignItems: 'center' }}>
                    <WorkspacePremiumOutlined
                      htmlColor={color.shade70}
                      style={{ fontSize: 20, marginRight: 8 }}
                    />
                    <Text
                      noWrap
                      component="div"
                      style={{ width: 0, flex: 1 }}
                      variant="body1"
                      text={step.badge?.label}
                    />
                  </div>
                </>
              )}
              {step.action_type === 'award_certification' && (
                <>
                  <Text
                    component="div"
                    style={{ marginBottom: 8 }}
                    variant="body2Medium"
                    text="Award Certification:"
                  />
                  <Text
                    noWrap
                    component="div"
                    style={{ flex: 1 }}
                    variant="body1"
                    text={step.label}
                  />
                </>
              )}
            </div>
          </div>
          <div style={ACTIONS}>
            {!step?.stub && !readonly && (
              <ActionTooltip placement="top" title="Edit Step">
                <IconButton
                  disabled={workflow?.stub}
                  sx={{ border: `1px solid ${color.shade10}` }}
                  onClick={edit}
                >
                  <Edit
                    style={{ fontSize: 20, color: workflow?.stub ? color.shade20 : color.shade70 }}
                  />
                </IconButton>
              </ActionTooltip>
            )}
            {!readonly && (
              <ActionTooltip placement="top" title="Remove Step">
                <IconButton
                  disabled={
                    workflow?.selectedStep &&
                    workflow?.selectedStep?.reference_id !== step?.reference_id
                  }
                  onClick={(e) => {
                    e.stopPropagation()
                    e.preventDefault()
                    handleDelete()
                  }}
                  sx={{ border: `1px solid ${color.shade10}` }}
                >
                  <Delete
                    style={{
                      fontSize: 20,
                      color:
                        workflow?.selectedStep &&
                        workflow?.selectedStep?.reference_id !== step?.reference_id
                          ? color.shade20
                          : color.shade70,
                    }}
                  />
                </IconButton>
              </ActionTooltip>
            )}
          </div>
        </div>
      </div>
      {showConfirmDelete && (
        <ConfirmDialog
          danger
          open={showConfirmDelete}
          title="Remove Step"
          message={
            root && workflow?.rootHasActions
              ? 'Are you sure you want to remove this step? Any actions attached to this step will also be removed.'
              : 'Are you sure you want to remove this step?'
          }
          confirmedMessage="1 step has been removed."
          confirmButton="Confirm"
          onConfirm={onDelete}
          onClose={() => {
            setShowConfirmDelete(false)
          }}
          handleCancel={() => {
            setShowConfirmDelete(false)
          }}
        />
      )}
    </Stack>
  )
})

const CONTAINER: React.CSSProperties = {
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'center',
  flex: 1,
}
const CONTENT: React.CSSProperties = {
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  flex: 1,
}
const ACTIONS: React.CSSProperties = {
  flexDirection: 'row',
  alignItems: 'center',
  gap: 12,
  display: 'flex',
}
const ICON_IMAGE: React.CSSProperties = {
  borderRadius: 4,
  marginRight: 20,
  objectFit: 'cover',
  width: 80,
  height: 40,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  background: color.shade20,
}
const IMG: React.CSSProperties = {
  position: 'relative',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
}
const MAIN: React.CSSProperties = {
  padding: 20,
  border: `2px solid #EFF0F6`,
  backgroundColor: color.white,
  borderRadius: 12,
  flex: 1,
  flexDirection: 'column',
  display: 'flex',
  height: '100%',
  justifyContent: 'center',
}
const LABEL: React.CSSProperties = {
  flexDirection: 'column',
  display: 'flex',
  flex: 1,
  paddingRight: 20,
  justifyContent: 'space-around',
}
const TOPIC_IMG: React.CSSProperties = {
  borderRadius: 4,
  objectFit: 'cover',
  width: 80,
  height: 40,
}
const RESOURCE_ICON: React.CSSProperties = {
  borderRadius: 4,
  display: 'flex',
  alignItems: 'center',
  flexDirection: 'row',
  padding: 15,
  marginRight: 0,
}
