import { observer } from 'mobx-react-lite'
import React from 'react'
import Dialog from '@mui/material/Dialog'
import MuiDialogContent from '@mui/material/DialogContent'
import withStyles from '@mui/styles/withStyles'
import { color } from '../../theme'
import { Button } from '../button'
import { BadgeAvatar } from '../badge-avatar'
import { Text } from '../text'

const DialogContent = withStyles(() => ({
  root: {
    padding: 20,
    width: 600,
    maxHeight: '60vh',
  },
}))(MuiDialogContent)

interface PreviewBadgeProps {
  onClose: () => void
  open: boolean
  badge?: any
}

export const PreviewBadge = observer((props: PreviewBadgeProps) => {
  const { onClose, open, badge } = props
  return (
    <Dialog
      disableScrollLock={false}
      onClose={onClose}
      aria-labelledby="preview-badge-title"
      open={open}
    >
      <DialogContent
        style={{
          minHeight: 630,
          width: 375,
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
          backgroundColor: color.quizBackground,
        }}
      >
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            textAlign: 'center',
            flex: 1,
          }}
        >
          <div style={{ marginTop: 30 }}>
            <BadgeAvatar
              size={280}
              name={badge?.badge_title}
              url={badge?.photo_url}
              hoverable={false}
            />
          </div>
          <Text
            text={badge?.badge_title}
            variant="headline"
            style={{ color: color.white, marginTop: 32 }}
          />
          <Text
            text={badge?.badge_criteria ?? ''}
            variant="labelMedium"
            style={{ color: color.white, marginTop: 8 }}
          />
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'center',
              flex: 1,
              alignItems: 'flex-end',
              width: '100%',
            }}
          >
            <Button fullWidth onClick={onClose} text="Continue" />
          </div>
        </div>
      </DialogContent>
    </Dialog>
  )
})
