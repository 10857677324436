import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { Header } from '@trivie/ui-web/components/header'
import { NavigationTabs } from '@trivie/ui-web/components/navigation-tabs'
import { observer } from 'mobx-react-lite'
import { useStores } from '@trivie/core'
import { tagsTabs } from './tabs'
import { Edit } from '@mui/icons-material'
import { CreateTag } from '@trivie/ui-web/components/create-tag'
import { TagApi } from '@trivie/core/src/services/api-objects'
import { ConfirmDialog } from '@trivie/ui-web/components/confirm-dialog'

export const TagsDetailsScreen: React.FunctionComponent = observer(() => {
  // @ts-ignore
  const { id } = useParams()
  const { manageUserStore } = useStores()
  const { permissions } = manageUserStore

  const [showEdit, setShowEdit] = useState(false)
  const [selectedTag, setSelectedTag] = useState<any>()
  const [title, setTitle] = useState('')
  const [showConfirm, setShowConfirm] = useState(false)
  const [confirmMessage, setConfirmMessage] = useState(<></>)

  useEffect(() => {
    loader()
  }, [])
  const loader = async () => {
    const response = await TagApi.getAllTags(
      'tags',
      false,
      [{ field: 'tag_key' }, { field: 'tag_is_sticky' }, { field: 'tag_value' }],
      'list_tags',
    )
    const result: any = response.data
    const row: any = result.rows.find((item: any) => item.id === Number(id))
    const tag = {
      id: Number(id),
      tag_key: row.columns.find((column) => column.field === 'tag_key')?.value,
      tag_value: row.columns.find((column) => column.field === 'tag_value')?.value,
      is_sticky: row.columns.find((column) => column.field === 'tag_is_sticky')?.value,
    }
    setSelectedTag(tag)
    if (tag.tag_key && tag.tag_value) setTitle(`${tag.tag_key} > ${tag.tag_value}`)
  }

  const onEdit = () => {
    loader().then(() => {
      setShowEdit(false)
    })
  }

  const primary = {
    disabled: !permissions?.permissions.some((p) => p.code === 'can_edit_tag'),
    startIcon: <Edit />,
    onClick: () => setShowEdit(true),
    text: 'Edit Tag',
  }

  return (
    <>
      <Header title={title} primaryAction={primary} />
      {showEdit && (
        <CreateTag
          onCreate={onEdit}
          open={showEdit}
          onClose={() => setShowEdit(false)}
          selectedTag={selectedTag}
          setConfirmMessage={setConfirmMessage}
          setShowConfirm={setShowConfirm}
        />
      )}
      {showConfirm && (
        <ConfirmDialog
          hideCancel
          open={showConfirm}
          title="Edit Tag"
          message=""
          bodyContent={confirmMessage}
          confirmButton="Close"
          onConfirm={() => setShowConfirm(false)}
          onClose={() => setShowConfirm(false)}
          handleCancel={() => setShowConfirm(false)}
        />
      )}
      <NavigationTabs tabList={tagsTabs} id={id} />
    </>
  )
})
