import React from 'react'

// @ts-ignore
import { ReactComponent as DefaultFile } from '../../assets/icons/DefaultFile.svg'
// @ts-ignore
import { ReactComponent as Excel } from '../../assets/icons/Excel.svg'
// @ts-ignore
import { ReactComponent as Image } from '../../assets/icons/Image.svg'
// @ts-ignore
import { ReactComponent as Media } from '../../assets/icons/Media.svg'
// @ts-ignore
import { ReactComponent as PDF } from '../../assets/icons/PDF.svg'
// @ts-ignore
import { ReactComponent as Slide } from '../../assets/icons/Slide.svg'
// @ts-ignore
import { ReactComponent as Website } from '../../assets/icons/Website.svg'
// @ts-ignore
import { ReactComponent as Word } from '../../assets/icons/Word.svg'
// @ts-ignore
import { ReactComponent as WhiteWebsite } from '../../assets/icons/White-Website.svg'
// @ts-ignore
import { ReactComponent as WhiteWord } from '../../assets/icons/White-Word.svg'
// @ts-ignore
import { ReactComponent as WhitePDF } from '../../assets/icons/White-PDF.svg'
// @ts-ignore
import { ReactComponent as WhiteText } from '../../assets/icons/White-Text.svg'
// @ts-ignore
import { ReactComponent as WhiteSlide } from '../../assets/icons/White-Slide.svg'
// @ts-ignore
import { ReactComponent as WhiteMedia } from '../../assets/icons/White-Media.svg'
// @ts-ignore
import { ReactComponent as WhiteSound } from '../../assets/icons/White-Sound.svg'
import {
  excelTypes,
  imageTypes,
  mediaTypes,
  pdfTypes,
  slideTypes,
  soundTypes,
  wordTypes,
} from '@trivie/core'

export const MimeTypeIcon = (props: any) => {
  const { mimeType, filename, white } = props

  let validUrl = false
  try {
    validUrl = mimeType === 'url' ? true : filename ? !!new URL(filename) : false
  } catch {
    validUrl = false
  }

  const renderExcel = () => {
    return white ? <Excel style={{ flexShrink: 0 }} /> : <Excel style={{ flexShrink: 0 }} />
  }
  const renderImageTypes = () => {
    return white ? <WhiteText style={{ flexShrink: 0 }} /> : <Image style={{ flexShrink: 0 }} />
  }
  const renderMediaTypes = () => {
    return white ? <WhiteMedia style={{ flexShrink: 0 }} /> : <Media style={{ flexShrink: 0 }} />
  }
  const renderPDF = () => {
    return white ? <WhitePDF style={{ flexShrink: 0 }} /> : <PDF style={{ flexShrink: 0 }} />
  }
  const renderSlide = () => {
    return white ? <WhiteSlide style={{ flexShrink: 0 }} /> : <Slide style={{ flexShrink: 0 }} />
  }
  const renderSound = () => {
    return white ? <WhiteSound style={{ flexShrink: 0 }} /> : <Media style={{ flexShrink: 0 }} />
  }
  const renderWord = () => {
    return white ? <WhiteWord style={{ flexShrink: 0 }} /> : <Word style={{ flexShrink: 0 }} />
  }
  const renderURL = () => {
    return white ? (
      <WhiteWebsite style={{ flexShrink: 0 }} />
    ) : (
      <Website style={{ flexShrink: 0 }} />
    )
  }
  const renderDefault = () => {
    return white ? (
      <WhiteText style={{ flexShrink: 0 }} />
    ) : (
      <DefaultFile style={{ flexShrink: 0 }} />
    )
  }

  if (excelTypes.includes(mimeType)) {
    return renderExcel()
  } else if (imageTypes.includes(mimeType)) {
    return renderImageTypes()
  } else if (mediaTypes.includes(mimeType)) {
    return renderMediaTypes()
  } else if (pdfTypes.includes(mimeType)) {
    return renderPDF()
  } else if (slideTypes.includes(mimeType)) {
    return renderSlide()
  } else if (soundTypes.includes(mimeType)) {
    return renderSound()
  } else if (wordTypes.includes(mimeType)) {
    return renderWord()
  } else if (validUrl) {
    return renderURL()
  } else {
    return renderDefault()
  }
}
