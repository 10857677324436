import React, { useEffect, useState } from 'react'
import { useHistory, useRouteMatch } from 'react-router-dom'
import { observer } from 'mobx-react-lite'
import { DateTime } from 'luxon'
import { Edit, FactCheck } from '@mui/icons-material'
import { Stack } from '@mui/material'

import { useStores } from '@trivie/core'
import { NavigationTabs } from '@trivie/ui-web/components/navigation-tabs'
import { HeaderPanel } from '@trivie/ui-web/components/header-panel'
import { color } from '@trivie/ui-web/theme'
import { Text } from '@trivie/ui-web/components/text'
import { WorkflowApi } from '@trivie/core/src/services/api-objects/WorkflowApi'
import { AssignmentCreateDialog } from '../../activities/assignments/create-assignment/assignment-create'
import defaultCover from '../quizzes/quiz-card/default-topic-image.png'
import { pathTabs } from './tabs'

export const PathDetailsScreen: React.FunctionComponent = observer(() => {
  const history = useHistory()
  const { manageUserStore, contentCreationStore, pathStore } = useStores()
  const { permissions } = manageUserStore
  const { resetContentStore } = contentCreationStore
  const { path, getPath } = pathStore

  const match = useRouteMatch<any>('/content/paths/:id/:tab')
  const pathID = Number(match?.params?.id)

  const [pathDetails, setPathDetails] = useState<any>()
  const [showAssignWizard, setShowAssignWizard] = useState(false)

  useEffect(() => {
    resetContentStore()
    getPath(pathID)
    WorkflowApi.getPaths(
      'paths',
      false,
      [{ field: 'path_last_edited_date' }, { field: 'path_assigned' }],
      'list_paths',
    ).then((resp) => {
      const pathRow = resp.data.rows.find((p) => p.id === Number(pathID))
      if (pathRow) {
        const dt = DateTime.fromISO(
          new Date(
            pathRow.columns.find((column) => column.field === 'path_last_edited_date').value,
          ).toISOString(),
        )
        setPathDetails({
          path_last_edited_date: dt.toLocaleString(DateTime.DATE_MED),
          path_assigned: pathRow.columns.find((column) => column.field === 'path_assigned').value,
        })
      }
    })
  }, [])

  const headerActions = [
    {
      label: 'Assign',
      onClick: () => setShowAssignWizard(true),
      disabled: !permissions?.permissions.some((p) => p.code === 'can_create_assignment'),
      icon: <FactCheck htmlColor={color.shade70} />,
    },
    {
      primary: true,
      label: 'Edit',
      onClick: () => {
        resetContentStore(true)
        history.push(`/content/path/${Number(pathID)}/edit`)
      },
      disabled: !permissions?.permissions.some((p) => p.code === 'can_edit_path'),
      icon: <Edit htmlColor={color.white} />,
    },
  ]

  const editedDate = pathDetails?.path_last_edited_date
    ? `${path?.workflow_json.stepCount} Step${
        path?.workflow_json.stepCount === 1 ? '' : 's'
      } ∙ Last Updated ${pathDetails?.path_last_edited_date}`
    : ''

  return (
    <>
      <HeaderPanel
        backdrop={path?.image_url || defaultCover}
        showOverlay
        loading={pathStore?.loading}
        actions={path ? headerActions : []}
        footer={
          path && (
            <Stack>
              <Text
                component="h1"
                style={{ color: color.white }}
                variant="headline"
                text={path?.name}
              />
              {editedDate && (
                <Text style={{ color: color.white }} variant="body1Medium" text={editedDate} />
              )}
            </Stack>
          )
        }
      />
      <NavigationTabs tabList={pathTabs} id={pathID} />
      {showAssignWizard && (
        <AssignmentCreateDialog
          open={showAssignWizard}
          initialPaths={[pathID]}
          requestClose={() => setShowAssignWizard(false)}
        />
      )}
    </>
  )
})
