import { ApiResponse, ApisauceInstance } from 'apisauce'
import { handleResponse } from '../handleResponse'

export const ContestApi = {
  apisauce: {} as ApisauceInstance,
  getAllContests: async (
    type: 'contests',
    meta?: boolean,
    columns?: any,
    view_name?: string,
    id?: number,
  ) => {
    const payload = { view_name }
    if (columns && columns.length > 0) {
      payload['columns'] = columns
    }
    const response: ApiResponse<any> = await ContestApi.apisauce.post(
      `/contests/list/?meta=${meta}&asynchronous=${false}`,
      payload,
    )
    return handleResponse(response)
  },
  getContestActive: async (payload) => {
    const response: ApiResponse<any> = await ContestApi.apisauce.get(
      `/contests/content_active/`,
      payload,
    )
    return handleResponse(response)
  },
  getContestsForUser: async (id: number) => {
    const response: ApiResponse<any> = await ContestApi.apisauce.get(`/users/${id}/contests/`)
    return handleResponse(response)
  },
  getContest: async (id: number) => {
    const response: ApiResponse<any> = await ContestApi.apisauce.get(`/contests/${id}/`)
    return handleResponse(response)
  },
  getHowToWin: async (id: number) => {
    const response: ApiResponse<any> = await ContestApi.apisauce.get(`/contests/${id}/how_to_win/`)
    return handleResponse(response)
  },
  getContestLeaderboard: async (id: number, useLimit: boolean) => {
    const response: ApiResponse<any> = await ContestApi.apisauce.get(
      `/contests/${id}/leaderboard/?use_limit=${useLimit}`,
    )
    return handleResponse(response)
  },
  getContestScoring: async (id: number) => {
    const response: ApiResponse<any> = await ContestApi.apisauce.get(`/contests/${id}/scoring/`)
    return handleResponse(response)
  },
  deleteContests: async (ids: Array<number>) => {
    const response: ApiResponse<any> = await ContestApi.apisauce.delete(
      `contests/`,
      {},
      { data: { contest_ids: ids } },
    )
    return handleResponse(response)
  },
  createContest: async (contest: any) => {
    const response: ApiResponse<any> = await ContestApi.apisauce.post(`/contests/`, contest)
    return handleResponse(response)
  },
  editContest: async (contest: any) => {
    const response: ApiResponse<any> = await ContestApi.apisauce.patch(`/contests/`, contest)
    return handleResponse(response)
  },
  extendContests: async (ids: Array<number>, endDate: any) => {
    const response: ApiResponse<any> = await ContestApi.apisauce.post(`/contests/extend/`, {
      contest_ids: ids,
      extend_until: endDate.endDateTime,
    })
    return handleResponse(response)
  },
  endContests: async (ids: Array<number>) => {
    const response: ApiResponse<any> = await ContestApi.apisauce.post(`/contests/end/`, {
      contest_ids: ids,
    })
    return handleResponse(response)
  },
  startContestsNow: async (contestIDs: Array<number>) => {
    const response: ApiResponse<any> = await ContestApi.apisauce.post(`/contests/start/`, {
      contest_ids: contestIDs,
    })
    return handleResponse(response)
  },
  patchContest: async (id: number, contest: any) => {
    const response: ApiResponse<any> = await ContestApi.apisauce.patch(`/contests/${id}/`, contest)
    return handleResponse(response)
  },
}
