import React, { useEffect, useState } from 'react'
import { ArrowBackRounded } from '@mui/icons-material'
import { Autocomplete, TextField } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'

import { Button } from '../button'
import { color } from '../../theme'

export const useColumnStyles = makeStyles({
  // Dropdown container
  paper: {
    borderRadius: 0,
    boxShadow: 'none',
    border: '1px solid lightgray',
    borderBottomLeftRadius: 6,
    borderBottomRightRadius: 6,
    top: -1,
    margin: 0,
    width: '100%',
  },
  option: {
    paddingLeft: '20px !important',
    paddingRight: '20px !important',
    paddingTop: '9.5px !important',
    paddingBottom: '9.5px !important',
  },
})
export const CustomColumn = (props: any) => {
  const { setShowCustomColumn, tagCategories, addCustomColumn } = props

  const [selectedColumn, setSelectedColumn] = useState<any>(null)
  const [selectedTagCategory, setSelectedTagCategory] = useState<any>(null)
  const [customColumn, setCustomColumn] = useState<any>(true)
  const [disableAddColumn, setDisableAddColumn] = useState(true)

  const handleDone = () => {
    addCustomColumn(customColumn)
    setShowCustomColumn(false)
    setDisableAddColumn(true)
  }

  const handleAdd = (tag_category: string) => {
    const params = {}
    const customField = `tag_category:${crypto.randomUUID()}`
    params['tag_category'] = tag_category
    const custom = {
      column_alias: customField,
      field: customField,
      params,
      header_name: 'Tag Category',
      value: 'tag_category',
      enabled: true,
      is_custom: true,
      width: 150,
      possible_values: [],
      operators: ['eq'],
      type: 'array',
    }

    setCustomColumn(custom)
  }

  const classes = useColumnStyles()

  useEffect(() => {
    if (selectedTagCategory) {
      setDisableAddColumn(false)
    }
  }, [selectedTagCategory])

  return (
    <>
      <Autocomplete
        classes={{ ...classes }}
        onChange={(event: any, value: any) => setSelectedColumn(value)}
        style={{ paddingTop: 10, marginBottom: 20 }}
        disablePortal
        value={selectedColumn}
        sx={COLUMN_FORM_ITEM}
        options={[{ label: 'Tag Category', value: 'tag_category' }]}
        renderInput={(params) => <TextField {...params} label="Column Type:" />}
      />
      {selectedColumn && selectedColumn.value === 'tag_category' && (
        <Autocomplete
          classes={{ ...classes }}
          disablePortal
          disableClearable
          onChange={(event: any, value: any) => {
            setSelectedTagCategory(value)
            if (value.label) {
              handleAdd(value.label)
            }
          }}
          style={{ marginBottom: 20 }}
          value={selectedTagCategory}
          sx={COLUMN_FORM_ITEM}
          options={tagCategories?.map((category) => {
            return { label: category }
          })}
          renderInput={(params) => <TextField {...params} label="Tag:" />}
        />
      )}
      <Button
        disabled={disableAddColumn}
        sx={{ borderRadius: '100px !important', width: 122, height: 30 }}
        endIcon={<ArrowBackRounded />}
        onClick={handleDone}
        text="Save"
      />
    </>
  )
}

const COLUMN_FORM_ITEM = {
  paper: {
    borderRadius: 6,
  },
  root: {
    borderRadius: 6,
  },
  '& .MuiAutocomplete-root': {
    borderRadius: 6,
    '& > ul': {
      padding: 0,
      height: 36,
      '& > li': {
        padding: 0,
        height: 36,
      },
    },
  },
}
