import React, { useEffect, useState } from 'react'
import { Theme } from '@mui/material'
import createStyles from '@mui/styles/createStyles'
import makeStyles from '@mui/styles/makeStyles'
import { Text } from '../text'
import { color } from '../../theme'
import { DateTime } from 'luxon'
//@ts-ignore
import DateRangePicker from 'react-bootstrap-daterangepicker'
import { ArrowDropDownRounded } from '@mui/icons-material'
import '../../theme/react-day-picker-style.css'
import '../../theme/daterangepicker.css'

export interface DateFilterProps {
  onDateChange: (start: string, end: string, label?: string) => void
  dateRanges?: any
  noCustom?: boolean
  initialVal?: string
  skipInit?: boolean
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    formControl: {
      minWidth: 120,
      selectEmpty: {
        marginTop: 30,
      },
    },
    container: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
    },
    selected: {
      '&.Mui-selected': {
        backgroundColor: 'none',
      },
    },
    root: {
      paddingTop: 10,
      paddingBottom: 10,
      display: 'flex',
    },
  }),
)

const defaultRanges = {
  Today: [DateTime.local().minus({ days: 1 }).toJSDate(), DateTime.local().toJSDate()],
  Yesterday: [
    DateTime.local().minus({ days: 1 }).toJSDate(),
    DateTime.local().minus({ days: 1 }).toJSDate(),
  ],
  'Last 7 Days': [DateTime.local().minus({ days: 6 }).toJSDate(), DateTime.local().toJSDate()],
  'Last 30 Days': [DateTime.local().minus({ days: 29 }).toJSDate(), DateTime.local().toJSDate()],
  'This Month': [
    DateTime.local().startOf('month').toJSDate(),
    DateTime.local().endOf('month').toJSDate(),
  ],
  'Last Month': [
    DateTime.local().minus({ month: 1 }).startOf('month').toJSDate(),
    DateTime.local().minus({ month: 1 }).endOf('month').toJSDate(),
  ],
  'All Time': [
    DateTime.local(2021).startOf('day').toJSDate(),
    DateTime.local().endOf('day').toJSDate(),
  ],
}

export const DateFilter = (props: DateFilterProps) => {
  const { skipInit, onDateChange, noCustom } = props

  const classes = useStyles()
  const [state, setState] = useState({
    start: DateTime.local(2021).startOf('day').toJSDate(),
    end: DateTime.local().endOf('day').toJSDate(),
  })

  const [datelabel, setDateLabel] = useState('All Time')

  const handleCallback = (start: any, end: any, label: any) => {
    onDateChange(start.toISOString(), end.toISOString(), label)
    setDateLabel(label)
    setState({ start, end })
  }

  useEffect(() => {
    if (!skipInit) {
      handleCallback(state.start, state.end, 'All Time')
    }
  }, [])

  return (
    <div className={classes.container} style={{ alignContent: 'baseline' }}>
      <Text
        variant="labelMedium"
        style={{ marginRight: 10, color: color.palette.manatee }}
        text="Show:"
      />
      <DateRangePicker
        initialSettings={{
          showCustomRangeLabel: !noCustom,
          locale: {
            format: 'MM/DD/YYYY',
            separator: ' - ',
            applyLabel: 'Apply',
            cancelLabel: 'Cancel',
            fromLabel: 'From',
            toLabel: 'To',
            customRangeLabel: 'Custom',
            weekLabel: 'W',
            daysOfWeek: ['Su', 'Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa'],
            monthNames: [
              'January',
              'February',
              'March',
              'April',
              'May',
              'June',
              'July',
              'August',
              'September',
              'October',
              'November',
              'December',
            ],
            firstDay: 1,
          },
          showDropdowns: true,
          startDate: state.start,
          endDate: state.end,
          ranges: defaultRanges,
        }}
        onCallback={handleCallback}
      >
        <div
          id="reportrange"
          className="col-4"
          style={{
            cursor: 'pointer',
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <Text variant="labelMedium" text={datelabel} style={{ color: color.shade70 }} />
          <ArrowDropDownRounded />
        </div>
      </DateRangePicker>
    </div>
  )
}
