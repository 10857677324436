import React from 'react'
import { Button as MuiButton } from '@mui/material'
import { observer } from 'mobx-react-lite'
import { useTranslation } from 'react-i18next'

import { ButtonProps } from './button.props'

export const Button = observer((props: ButtonProps) => {
  const {
    text,
    tx,
    style,
    children,
    txOptions,
    fullWidth,
    variant = 'contained',
    color = 'primary',
    disabled,
    sx,
    round,
    ...rest
  } = props

  const { t } = useTranslation()
  const i18nText = tx && t(tx, txOptions)

  const content = i18nText || text || children

  return (
    <MuiButton
      color={color}
      sx={{
        width: fullWidth ? '100%' : 'fit-content',
        ...(color === 'white' && {
          border: `1px solid rgb(230, 230, 230) !important`,
        }),
        ...(round && {
          borderRadius: '100px !important',
        }),
        ...sx,
      }}
      style={style}
      disableElevation
      variant={variant}
      disabled={disabled}
      {...rest}
    >
      {content}
    </MuiButton>
  )
})
