import React, { useState, useRef, forwardRef, useImperativeHandle } from 'react'
import ReactCrop, {
  centerCrop,
  makeAspectCrop,
  Crop,
  PixelCrop,
  convertToPixelCrop,
} from 'react-image-crop'
import {
  Box,
  Slider,
  IconButton,
  Checkbox,
  RadioGroup,
  FormControlLabel,
  Radio,
} from '@mui/material'
import { Add, Remove } from '@mui/icons-material'
import makeStyles from '@mui/styles/makeStyles'
import createStyles from '@mui/styles/createStyles'
import { Text } from '../text'
import cropImage from './cropImage'

import 'react-image-crop/dist/ReactCrop.css'

const useResizerStyles = makeStyles(() =>
  createStyles({
    root: {
      color: '#E6E6E6',
    },
    thumb: {
      backgroundColor: '#ffffff',
      boxShadow: '0px 0px 8px rgb(0 0 0 / 20%)',
    },
  }),
)

const Resizer = ({
  value,
  setValue,
  min,
  max,
  step,
}: {
  value: number
  setValue: (val: number) => void
  min: number
  max: number
  step: number
}) => {
  const classes = useResizerStyles()
  const handleChange = (
    event: React.ChangeEvent<Record<string, never>>,
    newValue: number | number[],
  ) => {
    setValue(newValue as number)
  }

  return (
    <Box width={300} style={{ alignSelf: 'center' }}>
      <Slider
        classes={{
          root: classes.root,
          thumb: classes.thumb,
        }}
        min={min}
        max={max}
        step={step}
        value={value}
        onChange={handleChange}
        aria-label="Resizer"
      />
    </Box>
  )
}

export interface ImageCropperProps {
  imgSrc: string
  file: File
  circularCrop: boolean
  setCircularCrop: (val: boolean) => void
}

export const ImageCropper = forwardRef(
  ({ imgSrc, file, circularCrop = true, setCircularCrop }: ImageCropperProps, ref) => {
    const imgRef = useRef<HTMLImageElement>(null)
    const [crop, setCrop] = useState<Crop>({
      unit: '%', // Can be 'px' or '%'
      x: 25,
      y: 25,
      width: 50,
      height: 50,
    })
    const [completedCrop, setCompletedCrop] = useState<PixelCrop>()
    const [scale, setScale] = useState(1.0)
    const [rotate, setRotate] = useState(0)
    const previewCanvasRef = useRef<HTMLCanvasElement>(null)
    function onImageLoad(e: React.SyntheticEvent<HTMLImageElement>) {
      // @ts-ignore
      imgRef.current = e.currentTarget

      const { width, height } = e.currentTarget
      // This is to demonstate how to make and center a % aspect crop
      // which is a bit trickier so we use some helper functions.
      const newCrop = centerCrop(
        makeAspectCrop(
          {
            unit: '%',
            width: circularCrop ? 50 : 60,
          },
          1,
          width,
          height,
        ),
        width,
        height,
      )
      const newPXCrop = centerCrop(
        makeAspectCrop(
          {
            unit: 'px',
            width: width / 2,
          },
          1,
          width,
          height,
        ),
        width,
        height,
      )
      setCrop(newCrop)
      setCompletedCrop(convertToPixelCrop(newPXCrop, newPXCrop.width, newPXCrop.height))
    }

    useImperativeHandle(ref, () => ({
      async onCrop() {
        if (crop?.height || crop?.width) {
          if (imgRef.current) {
            const {
              blob: croppedBlob,
              blobUrl,
              revokeUrl,
            } = await cropImage(imgRef.current, file, completedCrop, true, scale)
            return { croppedBlob, blobUrl, revokeUrl }
          }
        }
      },
    }))

    return (
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        {Boolean(imgSrc) && (
          <ReactCrop
            crop={crop}
            onChange={(_, percentCrop) => {
              setCrop(percentCrop)
            }}
            onComplete={(c) => setCompletedCrop(c)}
            aspect={1}
            circularCrop={circularCrop}
            keepSelection
            locked
          >
            <img
              alt="Crop me"
              src={imgSrc}
              style={{ transform: `scale(${scale}) rotate(${rotate}deg)`, maxHeight: 400 }}
              onLoad={onImageLoad}
            />
          </ReactCrop>
        )}
        <div style={{ display: 'flex', justifyContent: 'center', marginTop: 16 }}>
          <IconButton disabled={scale <= 0.8} onClick={() => setScale(scale - 0.1)} size="large">
            <Remove />
          </IconButton>
          <Resizer
            step={0.1}
            min={0.5}
            max={1.8}
            value={scale}
            setValue={(value) => setScale(value)}
          />
          <IconButton
            disabled={scale >= 1.8}
            onClick={() => {
              setScale(scale + 0.1)
            }}
            size="large"
          >
            <Add />
          </IconButton>
        </div>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <Text text="We recommend circular badge images " variant="body1Medium" />
          <Text text="with a transparent background in PNG format" variant="body1Medium" />
        </div>
      </div>
    )
  },
)
