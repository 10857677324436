import React, { useEffect, useState } from 'react'
import { useStores } from '@trivie/core'
import { ConfirmDialog } from '@trivie/ui-web/components/confirm-dialog'
import { DataTable, TableHelper } from '@trivie/ui-web/components/data-table/data-table'
import { observer } from 'mobx-react-lite'
import { CircularProgress } from '@mui/material'
import { Toolbar } from '@trivie/ui-web/components/table-toolbar'
import { ExtendAssignment } from '@trivie/ui-web/components/extend-dialog'
import { DateTime } from 'luxon'
import {
  CalendarTodayRounded,
  CloseRounded,
  Delete,
  NotificationsRounded,
  PlayCircleOutline,
  PlusOneRounded,
} from '@mui/icons-material'
import { MessageDialog } from '@trivie/ui-web/components/message-dialog'
import { AddUsersToAssignment } from '../screens/activities/assignments/add-users/add-users-dialog'

interface AssignmentTableProps {
  tableHelper: TableHelper
  filter: 'active' | 'scheduled' | 'completed'
  forceLoading?: boolean
}

export const AssignmentTable = observer((props: AssignmentTableProps) => {
  const { tableHelper, filter, forceLoading } = props

  const { manageUserStore, assignmentStore } = useStores()
  const { endAssignment, extendAssignments, deleteAssignments, startAssignmentsNow } =
    assignmentStore
  const { gdpr, permissions } = manageUserStore

  useEffect(() => {
    if (typeof forceLoading === 'boolean') {
      setLoading(Boolean(forceLoading))
    }
  }, [forceLoading])

  const [selectedRows, setSelectedRows] = useState<any>([])
  const [loading, setLoading] = useState<boolean>(true)

  // Dialogs
  const [showConfirmDelete, setShowConfirmDelete] = useState(false)
  const [showExtendAssignment, setShowExtendAssignment] = useState(false)
  const [showAddUsers, setShowAddUsers] = useState(false)
  const [showSendMessage, setShowSendMessage] = useState(false)
  const [showConfirmEnd, setShowConfirmEnd] = useState(false)
  const [showConfirmStartNow, setShowConfirmStartNow] = useState(false)

  const getActions = () => {
    switch (tableHelper.parent) {
      case 'assignments':
        if (filter === 'active') {
          return [
            {
              label: 'Add Users',
              icon: PlusOneRounded,
              onClick: () => setShowAddUsers(true),
              disabled: !permissions?.permissions.some((p) => p.code === 'can_edit_assignment'),
            },
            {
              label: 'Nudge',
              icon: NotificationsRounded,
              onClick: () => setShowSendMessage(true),
              disabled:
                selectedRows.length > 1 ||
                !permissions?.permissions.some((p) => p.code === 'can_message_user'),
            },
            {
              label: 'Extend',
              icon: CalendarTodayRounded,
              onClick: () => setShowExtendAssignment(true),
              disabled: !permissions?.permissions.some((p) => p.code === 'can_edit_assignment'),
            },
            {
              label: 'End',
              icon: CloseRounded,
              onClick: () => setShowConfirmEnd(true),
              disabled: !permissions?.permissions.some((p) => p.code === 'can_edit_assignment'),
            },
          ]
        } else if (filter === 'scheduled') {
          return [
            {
              label: 'Add Users',
              icon: PlusOneRounded,
              onClick: () => setShowAddUsers(true),
              disabled: !permissions?.permissions.some((p) => p.code === 'can_edit_assignment'),
            },
            {
              label: 'Nudge',
              icon: NotificationsRounded,
              onClick: () => setShowSendMessage(true),
              disable: !permissions?.permissions.some((p) => p.code === 'can_message_user'),
            },
            {
              label: 'Start Now',
              icon: PlayCircleOutline,
              onClick: () => setShowConfirmStartNow(true),
              disabled: !permissions?.permissions.some((p) => p.code === 'can_edit_assignment'),
            },
            {
              label: 'Extend',
              icon: CalendarTodayRounded,
              onClick: () => setShowExtendAssignment(true),
              disabled: !permissions?.permissions.some((p) => p.code === 'can_edit_assignment'),
            },
            {
              label: 'End',
              icon: CloseRounded,
              onClick: () => setShowConfirmEnd(true),
              disabled: !permissions?.permissions.some((p) => p.code === 'can_edit_assignment'),
            },

            {
              label: 'Delete',
              icon: Delete,
              onClick: () => setShowConfirmDelete(true),
              disabled: !permissions?.permissions.some((p) => p.code === 'can_edit_assignment'),
            },
          ]
        } else {
          return [
            {
              label: 'Extend',
              icon: CalendarTodayRounded,
              onClick: () => setShowExtendAssignment(true),
              disabled: !permissions?.permissions.some((p) => p.code === 'can_edit_assignment'),
            },
            {
              label: 'Delete',
              icon: Delete,
              onClick: () => setShowConfirmDelete(true),
              disabled: !permissions?.permissions.some((p) => p.code === 'can_delete_assignment'),
            },
          ]
        }
      case 'groups':
        return []
      case 'users':
        return [
          {
            label: 'Extend',
            icon: CalendarTodayRounded,
            onClick: () => setShowExtendAssignment(true),
            disabled: !permissions?.permissions.some((p) => p.code === 'can_edit_assignment'),
          },
          {
            label: 'End',
            icon: CloseRounded,
            onClick: () => setShowConfirmEnd(true),
            disabled: !permissions?.permissions.some((p) => p.code === 'can_edit_assignment'),
          },
        ]
      case 'topics':
        return [
          {
            label: 'Extend',
            icon: CalendarTodayRounded,
            onClick: () => setShowExtendAssignment(true),
            disabled: !permissions?.permissions.some((p) => p.code === 'can_edit_assignment'),
          },
          {
            label: 'End',
            icon: CloseRounded,
            onClick: () => setShowConfirmEnd(true),
            disabled: !permissions?.permissions.some((p) => p.code === 'can_edit_assignment'),
          },
        ]
      default:
        return []
    }
  }

  const [tableActions, setTableActions] = useState<any>(getActions())

  useEffect(() => {
    if (permissions) {
      setTableActions(getActions())
    }
  }, [permissions?.permissions])

  useEffect(() => {
    load()
  }, [])

  const load = () => {
    setLoading(true)
    tableHelper.load().then(() => setLoading(false))
  }

  const save = async (columns: any, assignmentFilter: any) => {
    setLoading(true)
    tableHelper.save(columns, assignmentFilter).then(() => setLoading(false))
  }

  const onExtend = (endDateTime: string) => {
    extendAssignments(selectedRows, endDateTime).then(() => load())
    setShowExtendAssignment(false)
    setSelectedRows([])
  }

  const [initialEndDate, setInitialEndDate] = useState<DateTime | null>(null)

  useEffect(() => {
    if (selectedRows.length > 0) {
      const selectedAssignment = Math.max(
        ...(tableHelper.rows
          .filter((row: any) => selectedRows.includes(row.id))
          .map((assignment: any) => DateTime.fromISO(assignment.assignment_end_date)) as any),
      )
      const date = DateTime.fromMillis(selectedAssignment, { zone: 'utc' })

      setInitialEndDate(date)
    } else {
      setInitialEndDate(null)
    }
  }, [selectedRows])

  return loading ? (
    <div style={LOADER}>
      <CircularProgress />
    </div>
  ) : (
    <>
      <DataTable
        gdpr={gdpr}
        columnVisibilityModel={tableHelper.columnVisibilityModel}
        filterModel={tableHelper.filterModel}
        sortModel={tableHelper.sortModel}
        hideAddColumn
        tableHelper={tableHelper}
        searchPlaceholder="Search Assignments"
        rows={tableHelper.rows}
        columns={tableHelper.columns}
        onSaveFilters={save}
        onSaveLayout={tableHelper?.saveLayout}
        toolbar={<Toolbar selected={selectedRows} actions={tableActions} />}
        exportFilename={
          tableHelper.parent !== 'assignments' ? `${tableHelper.parent}_assignments` : 'assignments'
        }
        rowSelectionModel={selectedRows}
        onRowSelectionModelChange={(param) => setSelectedRows(param)}
      />

      {showExtendAssignment && (
        <ExtendAssignment
          open={showExtendAssignment}
          initialEndDate={initialEndDate}
          onClose={() => {
            setSelectedRows([])
            load()
            setShowExtendAssignment(false)
          }}
          onExtend={onExtend}
        />
      )}
      {showAddUsers && (
        <AddUsersToAssignment
          open={showAddUsers}
          assignmentIDs={selectedRows}
          onClose={() => {
            setSelectedRows([])
            load()
            setShowAddUsers(false)
          }}
        />
      )}
      {showSendMessage && (
        <MessageDialog
          ids={selectedRows}
          open={showSendMessage}
          messageType="nudge"
          onClose={() => {
            setSelectedRows([])
            setShowSendMessage(false)
          }}
        />
      )}
      {showConfirmEnd && (
        <ConfirmDialog
          danger
          open={showConfirmEnd}
          title="End Assignments"
          message={`Are you sure you want to end ${selectedRows.length} assignment${
            selectedRows.length > 1 ? 's' : ''
          } early?`}
          confirmedMessage={`${selectedRows.length} assignment${
            selectedRows.length > 1 ? 's have' : ' has'
          } been ended.`}
          confirmButton="Confirm"
          onConfirm={() => {
            for (let i = 0; i < selectedRows.length; i++) {
              endAssignment(selectedRows[i])
            }
          }}
          onClose={() => {
            setSelectedRows([])
            load()
            setShowConfirmEnd(false)
          }}
          handleCancel={() => setShowConfirmEnd(false)}
        />
      )}
      {showConfirmDelete && (
        <ConfirmDialog
          danger
          open={showConfirmDelete}
          title="Delete Assignments"
          message={`Are you sure you want to delete ${selectedRows.length} assignment${
            selectedRows.length > 1 ? 's' : ''
          }`}
          confirmedMessage={`${selectedRows.length} assignment${
            selectedRows.length > 1 ? 's have' : ' has'
          } been deleted.`}
          confirmButton="Confirm"
          onConfirm={() => deleteAssignments(selectedRows)}
          onClose={() => {
            setSelectedRows([])
            load()
            setShowConfirmDelete(false)
          }}
          handleCancel={() => setShowConfirmDelete(false)}
        />
      )}
      {showConfirmStartNow && (
        <ConfirmDialog
          open={showConfirmStartNow}
          title="Confirm Start Assignments"
          message={`Are you sure you want to start ${selectedRows.length} assignment${
            selectedRows.length > 1 ? 's now?' : ' now?'
          }`}
          confirmedMessage={`${selectedRows.length} assignment${
            selectedRows.length > 1 ? 's have' : ' has'
          } started.`}
          confirmButton="Confirm"
          onConfirm={() => startAssignmentsNow(selectedRows)}
          onClose={() => {
            setSelectedRows([])
            load()
            setShowConfirmStartNow(false)
          }}
          handleCancel={() => setShowConfirmStartNow(false)}
        />
      )}
    </>
  )
})

const LOADER: React.CSSProperties = {
  display: 'flex',
  flex: 1,
  justifyContent: 'center',
  flexDirection: 'column',
  alignItems: 'center',
  height: window.innerHeight / 1.5,
}
