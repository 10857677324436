import { Instance } from 'mobx-state-tree'
import { ChoiceContentCreatePayload } from '../models/ChoiceContentCreatePayload'
import {
  QuestionChoice,
  questionChoiceErrors,
  questionChoiceWarnings,
} from '../models/QuestionChoice'

let rootStore

export class QuestionChoiceHelper {
  static setRootStore = (store: any) => {
    rootStore = store
  }

  static collectErrors = (
    responseChoice: Instance<typeof ChoiceContentCreatePayload>,
    choice: Instance<typeof QuestionChoice>,
  ) => {
    choice.set('errorsCorrected', [])

    let hasErrors = false
    if (responseChoice.errors?.length) {
      hasErrors = true
      const filteredErrors = responseChoice.errors.filter(
        (e) =>
          e.includes(questionChoiceErrors['choiceLength'].problem) ||
          !e.includes(questionChoiceWarnings.choiceLength.problem),
      )
      choice.set('errors', filteredErrors)
    } else {
      choice.set('errors', [])
    }

    return hasErrors
  }

  static collectWarnings = (
    responseChoice: Instance<typeof ChoiceContentCreatePayload>,
    choice: Instance<typeof QuestionChoice>,
  ) => {
    let hasWarnings = false
    if (
      responseChoice.errors?.length &&
      responseChoice.errors.some((e) => {
        return (
          !e.includes(questionChoiceErrors['choiceLength'].problem) &&
          e.includes(questionChoiceWarnings['choiceLength'].problem)
        )
      })
    ) {
      hasWarnings = true
      choice.set('warnings', responseChoice.errors)
    } else {
      choice.set('warnings', [])
    }

    return hasWarnings
  }
}
