import { observer } from 'mobx-react-lite'
import React, { useEffect, useState } from 'react'
import { useRouteMatch } from 'react-router-dom'
import { Box } from '@mui/material'
import { IAutocompleteGroupOptions, useStores } from '@trivie/core'
import { Dialog } from '@trivie/ui-web/components/dialogs/dialog'
import { TopicApi } from '@trivie/core/src/services/api-objects'
import { AutocompleteGroupSelector } from '@trivie/ui-web/components/autocomplete-selector'
// eslint-disable-next-line
const _array = require('lodash/array')

export const AddToTopicsDialog = observer((props: any) => {
  const { open, onClose } = props
  const { manageResourceStore } = useStores()
  const { params } = useRouteMatch()
  const { getRelatedTopics, relatedTopics, addResourceToTopics } = manageResourceStore
  const [autocompleteOptions, setAutocompleteOptions] = useState<IAutocompleteGroupOptions[]>([])
  const [selectedAutocompleteOptions, setSelectedAutocompleteOptions] = useState<
    IAutocompleteGroupOptions[]
  >([])
  //@ts-ignore
  const { id } = params

  const handleClose = () => {
    onClose()
  }
  const handleSubmit = () => {
    const newSelects = _array.differenceWith(
      selectedAutocompleteOptions,
      relatedTopics,
      (x: IAutocompleteGroupOptions, y: any) => x.id === y.topic_id,
    ) as { id: number }[]

    const topic_ids = newSelects.map((val) => val.id)

    addResourceToTopics(id, topic_ids).then(() => {
      getRelatedTopics(id)
      setSelectedAutocompleteOptions([])
      onClose()
    })
  }

  const getAllTopics = async () => {
    const resp = await TopicApi.getAllTopics('topics', false, [], 'list_topics')
    if (resp.ok) {
      const q: IAutocompleteGroupOptions[] = resp.data.rows.map((topic) => {
        const title = topic.columns.find((column) => column.field === 'topic_title')
        const photo_url = topic.columns.find((column) => column.field === 'photo_url')
        return {
          id: topic.id,
          label: title.value,
          type: 'topics',
          photo_url: photo_url.value,
        }
      })
      setAutocompleteOptions(q)
    }
  }

  useEffect(() => {
    if (open) {
      getAllTopics()
    }
  }, [open])

  const handleAddItem = (selected: IAutocompleteGroupOptions[]) => {
    setSelectedAutocompleteOptions(selected)
  }

  return (
    <Dialog
      onCancel={onClose}
      onConfirm={handleSubmit}
      confirmBtnLabel="Add Source"
      disableConfirm={!(selectedAutocompleteOptions.length > 0)}
      onClose={handleClose}
      title="Add Source to Topic"
      open={open}
      maxWidth="sm"
    >
      <Box height={550}>
        <AutocompleteGroupSelector
          open
          noOptionsText="No matching reports."
          options={autocompleteOptions}
          defaultOptions={[]}
          selectedOptions={selectedAutocompleteOptions}
          handleAddUserGroup={handleAddItem}
        />
      </Box>
    </Dialog>
  )
})
