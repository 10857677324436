import {
  cast,
  castToSnapshot,
  detach,
  getSnapshot,
  Instance,
  SnapshotIn,
  types,
} from 'mobx-state-tree'
import { QuizHelper } from '../helpers/QuizHelper'
import { Quiz } from './Quiz'
import { Resource } from './Resource'
import { Workflow } from './workflow/Workflow'

const topicErrors = {
  incorrectTopicLength: {
    key: 'incorrectTopicLength',
    title: 'Title Length',
    text: 'Topics must have a title between 1 and 40 characters',
    problem: 'is not between 1 and 40 characters.',
  },
  duplicateCreateTopic: {
    key: 'duplicateCreateTopic',
    title: 'Duplicate Topic',
    text: 'This topic already exists',
    problem: 'attempting to create new topic with existing title and description.',
  },
  duplicateUpdateTopic: {
    key: 'duplicateUpdateTopic',
    problem:
      'attempting to update existing topic with a title and description that already exists in seperate topic.',
  },
  noValidQuiz: {
    key: 'noValidQuiz',
    problem: 'topic must have at least one valid quiz associated.',
  },
  topicInAssignment: {
    key: 'topicInAssignment',
    problem: 'Topic belongs to an active assignment.',
  },
}

/**
 * Topic
 *
 * The intention here is for this object to be used only on the frontend side. Do not add
 * backend properties to this object unless explicitly used on the frontend side.
 */

export const Topic = types
  .model('Topic')
  .props({
    touched: types.maybe(types.boolean),
    topic_id: types.maybeNull(types.number),
    title: types.maybeNull(types.string),
    quizzes: types.maybeNull(types.array(Quiz)),
    imageUrl: types.maybeNull(types.string),
    resources: types.maybeNull(types.array(Resource)),
    is_locked: types.optional(types.literal(false), false),
    errors: types.maybeNull(types.array(types.string)),
    errorsCorrected: types.maybeNull(types.array(types.string)),
    keep_item_order: types.boolean,
    selected: types.maybe(types.boolean),
    removingResources: types.frozen(),
    addingResources: types.frozen(),
    badge_enabled: types.maybe(types.boolean),
    certification_enabled: types.maybe(types.boolean),
    badge_id: types.maybeNull(types.number),
    workflow_id: types.maybeNull(types.number),
    workflow: types.maybe(Workflow),
    duplicate_name: types.maybe(types.boolean),
    original_name: types.maybeNull(types.string),
  })
  .volatile(() => ({
    imageFile: null as File | null,
  }))
  .actions((self) => {
    return {
      select() {
        self.selected = true
      },
      deselect() {
        self.selected = false
      },
      selectQuiz(quiz: any) {
        const sel = self.quizzes?.find((q) => Boolean(q.selected))
        if (sel) {
          sel.deselect()
        }
        quiz.select()
      },
      reorderQuizzes(drag: number, drop: number) {
        const temp = self.quizzes?.slice()
        if (temp) {
          temp.splice(drop, 0, temp.splice(drag, 1)[0])
          self.quizzes = cast(temp)
        }
      },
      set<K extends keyof SnapshotIn<typeof self>, T extends SnapshotIn<typeof self>>(
        key: K,
        value: T[K],
      ) {
        self[key] = cast(value)
      },
      addNewQuiz() {
        if (!self.quizzes?.length) {
          self.quizzes = castToSnapshot([QuizHelper.createNewQuiz()])
        } else {
          self.quizzes.push(QuizHelper.createNewQuiz())
        }
      },
      setImage(file: File | null) {
        self.imageUrl = null
        self.imageFile = file
      },
      removeQuiz(quiz: Instance<typeof Quiz>) {
        if (!self.quizzes?.length) {
          return
        }

        self.quizzes.remove(quiz)
      },
      addResource: (resource: Instance<typeof Resource>) => {
        if ((self as Instance<typeof Topic>).hasResource(resource)) {
          return
        }

        if (self.removingResources && self.removingResources?.length) {
          self.removingResources = self.removingResources
            .filter((r) => r !== resource?.resource_id)
            .map((r) => r?.resource_id)
        }

        if (self.addingResources && self.addingResources?.length) {
          self.addingResources = [...self.addingResources, resource]
        } else {
          self.addingResources = [resource]
        }

        const identityBrokenResource = Resource.create({
          ...getSnapshot(resource),
          identityBreaker: Math.random() * 9999999999,
        })

        if (!self.resources) {
          self.resources = cast([identityBrokenResource])
        } else {
          self.resources.push(identityBrokenResource)
        }
      },
      removeResource: (resource: Instance<typeof Resource>) => {
        if (!self.resources) {
          return
        }

        let adding,
          remove = [] as any

        if (self.removingResources && self.removingResources?.length) {
          remove = [...self.removingResources, resource.resource_id]
        } else {
          remove = [resource.resource_id]
        }

        if (self.addingResources && self.addingResources?.length) {
          adding = self.addingResources.filter((r) => r.resource_id !== resource.resource_id)
        }

        const filteredResources = (
          self.resources ?? ([] as Array<Instance<typeof Resource>>)
        ).filter((r) => r.resource_id !== resource.resource_id)

        self.resources.replace(filteredResources)

        self.removingResources = remove
        self.addingResources = adding
      },
      hasResource: (resource: Instance<typeof Resource>) => {
        if (!self.resources) {
          return false
        }

        return self.resources.some((r) => r.resource_id === resource.resource_id)
      },
      toPayload: () => {
        const payloadTopic = getSnapshot(self)

        return {
          ...payloadTopic,
          badge_enabled: payloadTopic.badge_enabled ?? false,
          certification_enabled: payloadTopic.certification_enabled ?? false,
          title: payloadTopic.title ?? '',
          quizzes: (self.quizzes ?? ([] as Array<Instance<typeof Quiz>>)).map((q) => q.toPayload()),
        }
      },
      clearErrors: () => {
        self.errors = cast([])
      },
      addToCorrectedErrors(correctedErrorsToAdd: Array<keyof typeof topicErrors>) {
        if (self.errorsCorrected) {
          self.errorsCorrected.push(...correctedErrorsToAdd)
        } else {
          self.errorsCorrected = cast(correctedErrorsToAdd)
        }
      },
      addQuizzes(quizzes: Array<Instance<typeof Quiz>>) {
        // create questionObjects then filter out questions that are already on this quiz
        if (!self.quizzes) {
          self.quizzes = cast(quizzes)
        } else {
          const quizzesToAdd = quizzes.filter(
            (q) => !q.quiz_id || self.quizzes!.some((quiz) => quiz.quiz_id !== q.quiz_id),
          )
          self.quizzes.replace(self.quizzes.concat(quizzesToAdd))
        }
      },
      detachQuizzes(): Array<Instance<typeof Quiz>> | null {
        if (!self.quizzes) {
          return null
        }

        return self.quizzes.map((q) => detach(q))
      },
    }
  })
  .views((self) => ({
    get quizResources() {
      const r = [] as any
      self.quizzes?.map((q) => {
        if (q.resources?.length) {
          r.push(...q.resources)
        }
      })
      return r
    },
    get new() {
      return !self.topic_id
    },
    get selectedQuiz() {
      return self.quizzes?.find((quiz) => Boolean(quiz.selected))
    },
    get approved() {
      const notApproved = self.quizzes?.filter((q) => !q.approved)
      return (
        notApproved?.length === 0 &&
        self.title &&
        self.title.length > 0 &&
        self.quizzes &&
        self.quizzes?.length > 0
      )
    },
    get allErrors() {
      const err = [] as any
      if (self.quizzes) {
        self.quizzes.map((quiz) => {
          err.push(...quiz.allErrors)
        })
      }
      return err
    },
    get allWarnings() {
      const err = [] as any
      if (self.quizzes) {
        self.quizzes.map((quiz) => {
          err.push(...quiz.allWarnings)
        })
      }
      return err
    },
    get image() {
      if (self.imageFile) {
        return URL.createObjectURL(self.imageFile)
      } else if (self.imageUrl) {
        return self.imageUrl
      } else {
        return null
      }
    },
    get hasErrors() {
      // if no errors
      if (!self?.errors?.length) {
        return false
      }

      // if there are errors but no corrected errors
      if (!self.errorsCorrected?.length) {
        return true
      }

      const mappedErrors = self.errors.map((e) => {
        const error = Object.values(topicErrors).find((te) => e.includes(te.problem))
        if (!error) {
          throw new Error(`Could not map error ${e}`)
        } else {
          return error
        }
      })

      // if any errors have not been corrected
      return mappedErrors.some((me) => !self.errorsCorrected!.includes(me.key))
    },
    get error() {
      if ((self as Instance<typeof Topic>).hasError('incorrectTopicLength')) {
        return topicErrors['incorrectTopicLength']
      } else if ((self as Instance<typeof Topic>).hasError('duplicateCreateTopic')) {
        return topicErrors['duplicateCreateTopic']
      } else {
        return null
      }
    },
    hasError(errorKey: keyof typeof topicErrors) {
      // has errors
      if (!self.errors?.length) {
        return false
      }

      // does not have this error
      if (!self.errors.some((e) => e.includes(topicErrors[errorKey].problem))) {
        return false
      }

      // this error has been corrected
      if (self.errorsCorrected?.includes(errorKey)) {
        return false
      }

      // has errors, has this error, and the error hasn't been corrected
      return true
    },
  }))
