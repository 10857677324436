import React, { useEffect, useState } from 'react'
import {
  Dialog,
  DialogActions,
  DialogContent,
  Divider,
  IconButton,
  InputBase,
  MenuItem,
  Select,
  TextField,
  styled,
} from '@mui/material'
import { Check, Close } from '@mui/icons-material'
import { observer } from 'mobx-react-lite'

import { CreateReportForm } from '@trivie/core/src/models/CreateReportForm'
import { Button } from '../button'
import { Text } from '../text'
import { color } from '../../theme'

interface CreateReportDialogProps {
  open?: boolean
  onCreate: (payload: any, base?: string) => void
  onClose: () => void
  baseViews?: any
  duplicate?: any
  tableHelper?: any
  nonCustom?: boolean
  columns?: any
}

const TrivieDropdown = styled(InputBase)(({ theme }) => ({
  '& .MuiInputBase-input': {
    width: 354,
    borderRadius: 6,
    position: 'relative',
    backgroundColor: color.white,
    border: '1px solid #ced4da',
    fontSize: 16,
    padding: '10px 26px 10px 12px',
  },
  '& .MuiPopover-root': {
    height: 400,
  },
}))

export const CreateReportDialog = observer((props: CreateReportDialogProps) => {
  const { tableHelper, open, onCreate, onClose, baseViews, duplicate, nonCustom } = props

  const [created, setCreated] = useState(false)

  const [options, setOptions] = useState([])
  const [form, setForm] = useState<any>()

  useEffect(() => {
    const o = [] as any
    if (nonCustom) {
      const f = {
        template: {
          name: tableHelper.type,
          view_name: `base_${tableHelper.type}`,
          base_view_id: 0,
          columns: tableHelper.metaColumns,
        },
        name: '',
        description: '',
      }
      const base = baseViews.find((view) => view.view_name === `base_${tableHelper.type}`)
      f.template.base_view_id = base.view_id
      setForm(CreateReportForm.create(f))
    } else {
      if (baseViews) {
        baseViews.map((view) => {
          const { view_name, columns } = view
          switch (view.view_name) {
            case 'base_users':
              o.push({ name: 'users', view_name, base_view_id: view.view_id, columns })
              break
            case 'base_quizzes':
              o.push({ name: 'quizzes', view_name, base_view_id: view.view_id, columns })
              break
            case 'base_tags':
              o.push({ name: 'tags', view_name, base_view_id: view.view_id, columns })
              break
            case 'base_groups':
              o.push({ name: 'groups', view_name, base_view_id: view.view_id, columns })
              break
            case 'base_questions':
              o.push({ name: 'questions', view_name, base_view_id: view.view_id, columns })
              break
            case 'base_topics':
              o.push({ name: 'topics', view_name, base_view_id: view.view_id, columns })
              break
            default:
              break
          }
        })
        setOptions(o)
        setForm(
          CreateReportForm.create({
            template: o[0],
            name: '',
            description: '',
          }),
        )
      } else {
        o.push(duplicate)
        setForm(
          CreateReportForm.create({
            template: o[0],
            name: '',
            description: '',
          }),
        )
      }
    }
  }, [baseViews])

  const handleCreate = () => {
    setCreated(true)
    if (form.template.name === 'users') {
      // Add email / phone defaults
      const p = {
        ...form.payload,
        columns: [
          ...form.payload.columns,
          {
            field: 'email',
            filter: null,
            is_gdpr: false,
            sort: null,
            width: null,
            column_alias: null,
          },
          {
            field: 'phone_number',
            filter: null,
            is_gdpr: false,
            sort: null,
            width: null,
            column_alias: null,
          },
        ],
      }
      if (duplicate || nonCustom) {
        p.columns.push(
          {
            field: 'first_name',
            filter: null,
            is_gdpr: false,
            sort: null,
            width: null,
            column_alias: null,
          },
          {
            field: 'last_name',
            filter: null,
            is_gdpr: false,
            sort: null,
            width: null,
            column_alias: null,
          },
        )
      }
      onCreate(p, form.template.name)
    } else {
      onCreate(form.payload)
    }
  }

  const [touched, setTouched] = useState({
    template: false,
    name: false,
    description: false,
  })

  const handleChangeTemplate = (e: { target: { value: any } }) =>
    form.set('template', e.target.value)

  if (!form) {
    return null
  }

  return (
    <Dialog open={Boolean(open)}>
      <div style={{ ...DIALOG_CONTAINER, height: created ? 200 : 620 }}>
        <div style={HEADER}>
          <Text
            variant="body1Emphasized"
            text={duplicate ? 'Save As New Report' : 'Create Report'}
          />
          <IconButton onClick={onClose} size="large">
            <Close htmlColor={color.shade50} style={{ fontSize: 22 }} />
          </IconButton>
        </div>
        <Divider />
        {created ? (
          <>
            <DialogContent
              style={{ paddingLeft: 20, paddingRight: 20, paddingTop: 20, textAlign: 'center' }}
            >
              <Text
                variant="body1Medium"
                text={`Report '${form.payload.view_name}' created!`}
                style={{ textAlign: 'center' }}
              />
            </DialogContent>
            <DialogActions
              style={{ paddingLeft: 20, paddingRight: 20, paddingBottom: 20, paddingTop: 15 }}
            >
              <Button text="Close" onClick={onClose} />
            </DialogActions>
          </>
        ) : (
          <>
            <DialogContent style={{ paddingLeft: 20, paddingRight: 20, paddingTop: 20 }}>
              <Text
                variant="body1Medium"
                text={
                  duplicate
                    ? 'Enter a new name and description to create a copy of the current report and save it to My Reports.'
                    : 'Generate a new customizable report using one of our templates as a starting point.'
                }
              />
              <div style={CONTAINER}>
                <form style={FORM}>
                  {!duplicate && (
                    <div style={{ flexDirection: 'row', marginBottom: 20 }}>
                      <div style={{ flexDirection: 'column' }}>
                        <Text
                          variant="body1"
                          text="Template"
                          style={{ marginBottom: 12, color: color.shade70 }}
                        />
                        <Select
                          MenuProps={{
                            PaperProps: { style: { borderRadius: 6, paddingTop: 0 } },
                          }}
                          style={{ textTransform: 'capitalize' }}
                          value={form?.template}
                          onChange={handleChangeTemplate}
                          input={<TrivieDropdown />}
                          renderValue={(val) => val?.name}
                        >
                          {options.map((template: any) => (
                            //@ts-ignore
                            <MenuItem value={template}>
                              <div style={MENU_ITEM_CONTAINER}>
                                <div
                                  style={{
                                    height: 33,
                                    flexDirection: 'column',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    display: 'flex',
                                  }}
                                >
                                  <Text
                                    text={template?.name}
                                    variant="body1Medium"
                                    style={{ alignContent: 'center', textTransform: 'capitalize' }}
                                  />
                                </div>
                                {form.template === template && (
                                  <Check htmlColor={color.blue} style={{ fontSize: 20 }} />
                                )}
                              </div>
                            </MenuItem>
                          ))}
                        </Select>
                      </div>
                    </div>
                  )}
                  <div style={{ flexDirection: 'row', marginBottom: 20 }}>
                    <div style={{ flexDirection: 'column' }}>
                      <Text
                        variant="body1"
                        text="Name"
                        style={{ marginBottom: 12, color: color.shade70 }}
                      />
                      <TextField
                        error={touched.name && !!form.errors.name}
                        helperText={touched.name && form.errors.name?.message}
                        style={{ width: '100%' }}
                        InputProps={{ style: { height: 41 } }}
                        rows={5}
                        size="small"
                        variant="outlined"
                        placeholder="Give your report a short name"
                        onChange={(e) => {
                          setTouched({ ...touched, name: true })
                          form.set('name', e.target.value)
                        }}
                        onBlur={() => setTouched({ ...touched, name: true })}
                        value={form.name}
                      />
                    </div>
                  </div>
                  <div style={{ display: 'flex', flexDirection: 'row', flex: 1 }}>
                    <div style={{ flexDirection: 'column', width: '100%' }}>
                      <Text
                        variant="body1"
                        text="Description"
                        style={{ marginBottom: 12, color: color.shade70 }}
                      />
                      <TextField
                        error={touched.description && !!form.errors.description}
                        helperText={touched.description && form.errors.name?.description}
                        style={{ width: '100%' }}
                        InputProps={{ style: { height: 120 } }}
                        rows={5}
                        size="small"
                        variant="outlined"
                        multiline
                        placeholder="A short description of this report"
                        onChange={(e) => {
                          setTouched({ ...touched, description: true })
                          form.set('description', e.target.value)
                        }}
                        onBlur={() => setTouched({ ...touched, description: true })}
                        value={form.description}
                      />
                    </div>
                  </div>
                </form>
              </div>
            </DialogContent>
            <DialogActions style={{ padding: 20 }}>
              <div style={{ display: 'flex', gap: 12 }}>
                <Button variant="outlined" color="info" onClick={onClose} text="Cancel" />
                <Button
                  disabled={form && !!Object.keys(form?.errors)?.length}
                  onClick={handleCreate}
                  text="Create"
                />
              </div>
            </DialogActions>
          </>
        )}
      </div>
    </Dialog>
  )
})

const DIALOG_CONTAINER: React.CSSProperties = {
  display: 'flex',
  justifyContent: 'space-between',
  flexDirection: 'column',
  overflowY: 'hidden',
  width: 440,
}
const HEADER: React.CSSProperties = {
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  paddingLeft: 20,
  paddingRight: 8,
  paddingTop: 10,
  paddingBottom: 10,
}
const CONTAINER: React.CSSProperties = {
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-start',
  overflowY: 'auto',
  overflowX: 'hidden',
  marginTop: 20,
}
const FORM: React.CSSProperties = {
  justifyContent: 'space-evenly',
}
const MENU_ITEM_CONTAINER: React.CSSProperties = {
  flex: 1,
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'center',
}
