import { createContext } from 'react'

import React, { useState, useCallback, useContext } from 'react'
import { DialogProps } from '@mui/material/Dialog'
import { DialogActionsProps } from '@mui/material/DialogActions'
import { DialogTitleProps } from '@mui/material/DialogTitle'
import { DialogContentProps } from '@mui/material/DialogContent'
import { ButtonProps } from '@mui/material/Button'
import { TextFieldProps } from '@mui/material/TextField'
import { ConfirmationDialog } from './confirmation-dialog'

export interface ConfirmOptions {
  title?: string
  titleProps?: DialogTitleProps
  dialogText?: string
  confirmationMessage?: string
  content?: React.ReactNode | null
  contentProps?: DialogContentProps
  confirmationText?: string
  cancellationText?: string
  dialogProps?: Omit<DialogProps, 'open'>
  dialogActionsProps?: DialogActionsProps
  confirmationButtonProps?: ButtonProps
  cancellationButtonProps?: ButtonProps
  allowClose?: boolean
  confirmationKeyword?: string
  confirmationKeywordTextFieldProps?: TextFieldProps
  hideCancelButton?: boolean
  buttonOrder?: string[]
  danger?: boolean
}

const ConfirmContext = createContext<any>({})

const DEFAULT_OPTIONS = {
  title: 'Are you sure?',
  confirmationMessage: '',
  dialogText: '',
  content: null,
  confirmationText: 'Confirm',
  cancellationText: 'Cancel',
  dialogProps: {},
  dialogActionsProps: {},
  confirmationButtonProps: {},
  cancellationButtonProps: {},
  titleProps: {},
  contentProps: {},
  allowClose: true,
  confirmationKeywordTextFieldProps: {},
  hideCancelButton: false,
  buttonOrder: ['cancel', 'confirm'],
  danger: false,
}

const buildOptions = (defaultOptions, options) => {
  const dialogProps = {
    ...(defaultOptions.dialogProps || DEFAULT_OPTIONS.dialogProps),
    ...(options.dialogProps || {}),
  }
  const dialogActionsProps = {
    ...(defaultOptions.dialogActionsProps || DEFAULT_OPTIONS.dialogActionsProps),
    ...(options.dialogActionsProps || {}),
  }
  const confirmationButtonProps = {
    ...(defaultOptions.confirmationButtonProps || DEFAULT_OPTIONS.confirmationButtonProps),
    ...(options.confirmationButtonProps || {}),
  }
  const cancellationButtonProps = {
    ...(defaultOptions.cancellationButtonProps || DEFAULT_OPTIONS.cancellationButtonProps),
    ...(options.cancellationButtonProps || {}),
  }
  const titleProps = {
    ...(defaultOptions.titleProps || DEFAULT_OPTIONS.titleProps),
    ...(options.titleProps || {}),
  }
  const contentProps = {
    ...(defaultOptions.contentProps || DEFAULT_OPTIONS.contentProps),
    ...(options.contentProps || {}),
  }
  const confirmationKeywordTextFieldProps = {
    ...(defaultOptions.confirmationKeywordTextFieldProps ||
      DEFAULT_OPTIONS.confirmationKeywordTextFieldProps),
    ...(options.confirmationKeywordTextFieldProps || {}),
  }

  return {
    ...DEFAULT_OPTIONS,
    ...defaultOptions,
    ...options,
    dialogProps,
    dialogActionsProps,
    confirmationButtonProps,
    cancellationButtonProps,
    titleProps,
    contentProps,
    confirmationKeywordTextFieldProps,
  }
}

export interface ConfirmProviderProps {
  children: React.ReactNode
  defaultOptions?: ConfirmOptions
}

export const ConfirmProvider = ({ children, defaultOptions = {} }) => {
  const [options, setOptions] = useState<ConfirmOptions>({})
  const [resolveReject, setResolveReject] = useState<any>([])
  const [key, setKey] = useState(0)
  const [resolve, reject] = resolveReject

  const confirm = useCallback((options = {}) => {
    return new Promise((resolve: any, reject: any) => {
      setKey((key) => key + 1)
      setOptions(options)
      setResolveReject([resolve, reject] as any)
    })
  }, [])

  const handleClose = useCallback(() => {
    setResolveReject([])
  }, [])

  const handleCancel = useCallback(() => {
    if (reject) {
      reject()
      handleClose()
    }
  }, [reject, handleClose])

  const handleConfirm = useCallback(() => {
    if (resolve) {
      resolve()
    }
  }, [resolve, handleClose])

  const opts = buildOptions(defaultOptions, options)

  return (
    <>
      <ConfirmContext.Provider value={confirm}>{children}</ConfirmContext.Provider>
      {resolveReject.length === 2 ? (
        <ConfirmationDialog
          key={key}
          open={resolveReject.length === 2}
          onCancel={handleCancel}
          hideCancelButton={opts.hideCancelButton}
          hideCloseIcon={!opts.allowClose}
          onClose={handleClose}
          onConfirm={handleConfirm}
          dialogText={opts.dialogText}
          confirmationMessage={opts.confirmationMessage}
          confirmBtnLabel={opts.confirmationText}
          cancelBtnLabel={opts.cancellationText}
          title={opts.title ?? ''}
          danger={opts.danger}
        />
      ) : null}
    </>
  )
}

export const useConfirm = () => useContext(ConfirmContext)
