import { types } from 'mobx-state-tree'

export const SourceSuggestion = types.model('SourceSuggestion').props({
  file_size: types.frozen(),
  link: types.maybe(types.string),
  mimetype: types.maybe(types.string),
  rating: types.maybe(types.number),
  snippet: types.maybe(types.string),
  title: types.maybe(types.string),
  word_count: types.maybeNull(types.number),
})
