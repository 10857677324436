import { types, getEnv, flow, cast } from 'mobx-state-tree'
import { Environment } from '../environment'
import { QuizApi } from '../../services/api-objects/QuizApi'
import { Awaited } from '../../utility-types/Awaited'
import {
  GetFullQuiz,
  GetQuiz,
  GetQuizOverview,
  GetQuizQuestion,
  GetQuizQuestionKnowledgeGap,
  GetQuizUserStat,
  GetRelatedAssignmentsForQuiz,
  GetRelatedGroupsForQuiz,
} from '../response-models/quiz'
import { MasteryDistributionStat } from '../MasteryDistributionStat'
import { GetQuizAssociatedTopic } from '../response-models/topics'
import { GetQuizResource } from '../response-models/resource'
import { MedalStats } from '../MedalStats'
import { Quiz } from '../Quiz'

export const ManageQuizStoreModel = types
  .model('ManageQuizStore')
  .props({
    status: types.optional(types.enumeration(['idle', 'pending', 'done', 'error']), 'idle'),
    quizStats: types.maybeNull(GetQuizOverview),
    mastery: types.maybeNull(MasteryDistributionStat),
    knowledgeGaps: types.maybeNull(GetQuizQuestionKnowledgeGap),
    relatedTopics: types.maybeNull(types.array(GetQuizAssociatedTopic)),
    relatedGroups: types.maybeNull(types.array(GetRelatedGroupsForQuiz)),
    relatedAssignments: types.maybeNull(types.array(GetRelatedAssignmentsForQuiz)),
    questions: types.maybeNull(types.array(GetQuizQuestion)),
    users: types.maybeNull(types.array(GetQuizUserStat)),
    resources: types.maybeNull(types.array(GetQuizResource)),
    fullQuiz: types.maybeNull(GetFullQuiz),
    medalStats: types.array(MedalStats),
    onboardingQuiz: types.maybe(Quiz),
    selectedQuiz: types.frozen(),
    loadingQuiz: types.frozen(),
  })
  .actions((self) => ({
    setStatus(value?: 'idle' | 'pending' | 'done' | 'error') {
      self.status = value || 'idle'
    },
    setSelectedQuiz(quiz) {
      self.selectedQuiz = quiz
    },
    setLoadingQuiz(loading) {
      self.loadingQuiz = loading
    },
    setOnboardingQuiz(quiz) {
      self.onboardingQuiz = quiz
    },
    setMedalStats(stats: typeof self.medalStats) {
      self.medalStats = stats
    },
    setQuizStats(stats: typeof self.quizStats) {
      self.quizStats = stats
    },
    setMastery(mastery: typeof self.mastery) {
      self.mastery = mastery
    },
    setKnowledgeGaps(knowledgeGaps: typeof self.knowledgeGaps) {
      self.knowledgeGaps = knowledgeGaps
    },
    setRelatedTopics(topics: typeof self.relatedTopics) {
      self.relatedTopics = topics
    },
    setRelatedGroups(topics: typeof self.relatedGroups) {
      self.relatedGroups = topics
    },
    setRelatedAssignments(topics: typeof self.relatedAssignments) {
      self.relatedAssignments = topics
    },
    setQuestions(questions: typeof self.questions) {
      self.questions = questions
    },
    setUsers(questions: typeof self.users) {
      self.users = questions
    },
    setResources(resources: typeof self.resources) {
      self.resources = resources
    },
    setFullQuiz(quiz: typeof self.fullQuiz) {
      self.fullQuiz = quiz
    },
  }))
  .views((self) => ({
    get environment() {
      return getEnv(self) as Environment
    },
    get isLoading() {
      return self.status === 'pending'
    },
  }))
  .actions((self) => ({
    getQuizOverview: flow(function* (id: number, start: string, end: string) {
      self.setStatus('pending')
      self.setQuizStats(null)

      try {
        const result: Awaited<ReturnType<typeof QuizApi.getQuizOverview>> =
          yield QuizApi.getQuizOverview(id, start, end)

        if (result.ok && result.data) {
          self.setQuizStats(result.data)
          self.setStatus('done')
        } else {
          self.setStatus('error')
        }
      } catch {
        self.setStatus('error')
      }
    }),
    getKnowledgeGaps: flow(function* (id: number, start: string, end: string) {
      self.setStatus('pending')
      try {
        const result: Awaited<ReturnType<typeof QuizApi.getQuizQuestionKnowledgeGap>> =
          yield QuizApi.getQuizQuestionKnowledgeGap(id, start, end)

        if (result.ok && result.data) {
          self.setKnowledgeGaps(result.data)
          self.setStatus('done')
        } else {
          self.setStatus('error')
        }
      } catch {
        self.setStatus('error')
      }
    }),
    getRelatedTopicsForQuiz: flow(function* (id: number) {
      self.setStatus('pending')
      try {
        const result: Awaited<ReturnType<typeof QuizApi.getRelatedTopicsForQuiz>> =
          yield QuizApi.getRelatedTopicsForQuiz(id)

        if (result.ok && result.data) {
          self.setRelatedTopics(cast(result.data))
          self.setStatus('done')
        } else {
          self.setStatus('error')
        }
      } catch {
        self.setStatus('error')
      }
    }),
    updateQuizQuestions: flow(function* (quizID: number, payload: any) {
      self.setStatus('pending')
      try {
        const result = yield self.environment.api.addQuestionsToQuiz(quizID, payload)
        if (result.kind === 'ok') {
          self.setStatus('done')
        } else {
          self.setStatus('error')
        }
      } catch {
        self.setStatus('error')
      }
    }),
    updateQuiz: flow(function* (quiz_id: number, payload: any) {
      self.setStatus('pending')
      try {
        const result = yield self.environment.api.updateQuiz(quiz_id, payload)
        if (result.kind === 'ok') {
          self.setStatus('done')
        } else {
          self.setStatus('error')
        }
      } catch {
        self.setStatus('error')
      }
    }),
    createQuiz: flow(function* (quiz: any) {
      self.setStatus('pending')
      try {
        const result = yield self.environment.api.createQuiz(quiz)
        if (result.kind === 'ok') {
          self.setStatus('done')
          return result.data.quiz_id
        } else {
          self.setStatus('error')
        }
      } catch {
        self.setStatus('error')
      }
    }),
    checkDeleteQuizzes: flow(function* (ids: Array<number>) {
      self.setStatus('pending')
      try {
        const result: Awaited<ReturnType<typeof QuizApi.checkDeleteQuizzes>> =
          yield QuizApi.checkDeleteQuizzes(ids)

        if (result.ok && result.data) {
          self.setStatus('done')
          return result.data
        } else {
          self.setStatus('error')
        }
      } catch (err) {
        self.setStatus('error')
        return err
      }
    }),
    deleteQuizzes: flow(function* (ids: any) {
      self.setStatus('pending')
      let result
      try {
        result = yield self.environment.api.deleteQuizzes(ids)
        if (result.kind === 'ok') {
          self.setStatus('done')
        } else {
          self.setStatus('error')
        }
      } catch {
        self.setStatus('error')
      }
    }),
    addResourcesToQuiz: flow(function* (quiz_id: number, resources: any) {
      self.setStatus('pending')
      try {
        const result = yield self.environment.api.addResourcesToQuiz(quiz_id, resources)
        if (result.kind === 'ok') {
          self.setStatus('done')
        } else {
          self.setStatus('error')
        }
      } catch {
        self.setStatus('error')
      }
    }),
    removeResourcesFromQuiz: flow(function* (quiz_id: number, resources: any) {
      self.setStatus('pending')
      try {
        const result = yield self.environment.api.removeResourcesFromQuiz(quiz_id, resources)
        if (result.kind === 'ok') {
          self.setStatus('done')
        } else {
          self.setStatus('error')
        }
      } catch {
        self.setStatus('error')
      }
    }),
    getFullQuiz: flow(function* (id: any) {
      self.setStatus('pending')
      try {
        const result: Awaited<ReturnType<typeof QuizApi.getFullQuiz>> =
          yield QuizApi.getFullQuiz(id)

        if (result.ok && result.data) {
          self.setFullQuiz(result.data)
          self.setStatus('done')
        } else {
          self.setStatus('error')
        }
      } catch {
        self.setStatus('error')
      }
    }),
    removeQuestionsFromQuiz: flow(function* (quiz_id: number, questions: any) {
      self.setStatus('pending')
      try {
        const result = yield self.environment.api.removeQuestionsFromQuiz(quiz_id, questions)
        if (result.kind === 'ok') {
          self.setStatus('done')
        } else {
          self.setStatus('error')
        }
      } catch {
        self.setStatus('error')
      }
    }),
    getMedalStats: flow(function* (id: number) {
      self.setStatus('pending')
      try {
        const result: Awaited<ReturnType<typeof QuizApi.getMedalStats>> =
          yield QuizApi.getMedalStats(id)

        if (result.ok && result.data) {
          self.setStatus('done')
          const s = [] as any

          const inactive = result.data.find((stat) => stat.level === null)
          s.push(inactive)

          if (inactive && inactive.percent < 100) {
            const gold = result.data.find((stat) => stat.level === 'Gold')
            if (gold) {
              s.push(gold)
            } else {
              s.push({ level: 'Gold', order: 1, percent: 0, user_count: 0 })
            }

            const silver = result.data.find((stat) => stat.level === 'Silver')
            if (silver) {
              s.push(silver)
            } else {
              s.push({ level: 'Silver', order: 2, percent: 0, user_count: 0 })
            }

            const bronze = result.data.find((stat) => stat.level === 'Bronze')
            if (bronze) {
              s.push(bronze)
            } else {
              s.push({ level: 'Bronze', order: 3, percent: 0, user_count: 0 })
            }
          }

          self.setMedalStats(cast(s))
          return s
        } else {
          self.setStatus('error')
        }
      } catch {
        self.setStatus('error')
      }
    }),
    exportQuizzes: flow(function* (ids: number[]) {
      self.setStatus('pending')
      try {
        const result: Awaited<ReturnType<typeof QuizApi.exportQuizzes>> =
          yield QuizApi.exportQuizzes(ids)
        if (result.ok && result.data) {
          return result.data
        } else {
          self.setStatus('error')
        }
      } catch {
        self.setStatus('error')
      }
    }),
  }))

type ManageQuizStoreType = typeof ManageQuizStoreModel.Type
export interface ManageQuizStore extends ManageQuizStoreType {}
type ManageQuizStoreSnapshotType = typeof ManageQuizStoreModel.SnapshotType
export interface ManageQuizStoreSnapshot extends ManageQuizStoreSnapshotType {}
