import React, { useState } from 'react'
import { Dialog, DialogProps } from '../dialog/dialog'

interface ConfirmationDialogProps extends DialogProps {
  onCancel: () => void
  onConfirm: () => void
  dialogText: string
  confirmationMessage: string
  confirmBtnLabel?: string
  cancelBtnLabel?: string
  hideCancelButton?: boolean
}

export const ConfirmationDialog = (props: ConfirmationDialogProps) => {
  const {
    disableConfirm,
    danger,
    dialogText,
    onConfirm,
    onClose,
    onCancel,
    confirmationMessage,
    open,
    confirmBtnLabel,
    cancelBtnLabel,
    hideCancelButton,
    title,
    ...other
  } = props

  const [confirmed, setConfirmed] = useState(false)

  const handleConfirm = () => {
    if (onConfirm) {
      onConfirm()
      setConfirmed(true)
    }
  }

  return (
    <Dialog
      open={open}
      onConfirm={handleConfirm}
      maxWidth="xs"
      onClose={onClose}
      title={title}
      dialogText={confirmed ? confirmationMessage : dialogText}
      confirmBtnLabel={confirmBtnLabel}
      cancelBtnLabel={cancelBtnLabel}
      confirmed={confirmed}
      disableConfirm={disableConfirm}
      danger={danger}
      onCancel={hideCancelButton ? undefined : onCancel}
      {...other}
    />
  )
}
