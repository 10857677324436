import { Instance, types } from 'mobx-state-tree'
import { QuizReadModel } from '../quiz/QuizReadModel'
import { UserShortSchemaModel } from '../user/UserShortSchemaModel'

/**
 * AssignmentRead Model
 */
export const AssignmentReadModel = types.model('AssignmentRead').props({
  __typename: types.optional(types.literal('AssignmentRead'), 'AssignmentRead'),
  finish: types.maybeNull(types.string),
  id: types.identifierNumber,
  is_archived: types.boolean,
  is_required: types.boolean,
  max_attempts: types.integer,
  name: types.string,
  notify: types.boolean,
  quiz_list: types.array(
    types.union(
      types.null,
      types.late(() => QuizReadModel),
    ),
  ),
  quiz_ordering: types.maybeNull(types.array(types.maybeNull(types.integer))),
  start: types.maybeNull(types.string),
  users: types.union(
    types.null,
    types.array(
      types.union(
        types.null,
        types.late(() => UserShortSchemaModel),
      ),
    ),
  ),
})

/* The TypeScript type of an instance of AssignmentReadModel */
export interface AssignmentReadModelType extends Instance<typeof AssignmentReadModel.Type> {}
