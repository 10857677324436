import React, { useState, useEffect } from 'react'
import { InputAdornment, TextField } from '@mui/material'
import { Email } from '@mui/icons-material'

import { color } from '@trivie/ui-web/theme/color'
import { Dialog } from '@trivie/ui-web/components/dialogs/dialog'

export interface EmailReportProps {
  userEmail?: string | null
  open: boolean
  onSend: (email: string, report_id: any) => void
  onClose: () => void
  report: any
}

export function EmailReport(props: EmailReportProps) {
  const { open, report, userEmail, onSend, onClose } = props

  const [email, setEmail] = useState(userEmail)
  const [sent, setSent] = useState(false)

  useEffect(() => {
    if (userEmail && open) {
      setEmail(userEmail)
    }
  }, [userEmail, open])

  const handleSend = () => {
    if (email) {
      onSend(email, report)
      setSent(true)
    }
  }

  const handleClose = () => {
    onClose()
    setSent(false)
  }

  return (
    <Dialog
      confirmBtnLabel="Send"
      title="Email Report"
      maxWidth="xs"
      aria-labelledby="confirmation-dialog-title"
      open={open}
      dialogText={sent ? `${report.title} report sent to ${email}` : ''}
      confirmed={sent}
      onCancel={handleClose}
      onClose={handleClose}
      onConfirm={handleSend}
      disableConfirm={!email || !email.includes('@')}
    >
      <TextField
        variant="outlined"
        placeholder="Email Address"
        fullWidth
        value={email}
        data-qat="email_input"
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <Email htmlColor={color.palette.grey} />
            </InputAdornment>
          ),
        }}
        onChange={(event) => setEmail(event.target.value)}
      />
    </Dialog>
  )
}
