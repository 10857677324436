import React, { useEffect, useState } from 'react'

import Step from '@mui/material/Step'
import StepButton from '@mui/material/StepButton'
import Stepper from '@mui/material/Stepper'
import { ChevronLeftRounded } from '@mui/icons-material'
import { DateTime } from 'luxon'
import { observer } from 'mobx-react-lite'
import { Instance } from 'mobx-state-tree'

import { validateAndFormatNumber } from '@trivie/core/src/utils/phoneNumber'
import { InviteUserForm } from '@trivie/core/src/models/InviteUser'
import { InviteUserPayload } from '@trivie/core/src/models/InviteUserPayload'
import { useStores } from '@trivie/core'

import { Dialog } from '../dialogs/dialog'
import { Button } from '../button'
import { UserDetails } from './tabs/user-details-tab'
import { UserTagForm } from './tabs/user-tags-tab'
import { UserSchedule } from './tabs'

type UserInviteProps = {
  open: boolean
  close: () => void
  admin?: boolean
  onSave: () => void
  setLoading?: any
}

export const InviteUsers = observer((props: UserInviteProps) => {
  const { open, setLoading, close, admin, onSave } = props
  const { manageUserStore } = useStores()

  const { inviteUser, inviteAdmin } = manageUserStore
  const [activeStep, setActiveStep] = useState(0)
  const [form, setForm] = useState<Instance<typeof InviteUserForm> | null>(null)
  const steps = ['Recipients', 'Details', 'Schedule']

  useEffect(() => {
    setForm(
      InviteUserForm.create({
        start_date: DateTime.now().toFormat('yyyy-MM-dd'),
        start_time: DateTime.now().toLocaleString(DateTime.TIME_24_SIMPLE),
        first_name: '',
        last_name: '',
        email: '',
        phone_number: '',
        send_email: false,
        send_sms: false,
        tag_keys_values: [],
        employee_id: '',
        file: '',
        missingRequired: false,
        missingRequiredAdmin: false,
      }),
    )
  }, [])

  if (!form) {
    return null
  }

  const getStepContent = (step: number) => {
    switch (step) {
      case 0:
        return <UserDetails admin={admin} form={form} />
      case 1:
        return <UserTagForm form={form} />
      case 2:
        return <UserSchedule form={form} />
      default:
        return null
    }
  }

  const isLastStep = () => activeStep > steps.length - 1

  const handleNext = async () => {
    if (!form.phone_number && !form.email && !admin) {
      form.set('missingRequired', true)
    } else if (!form.email && admin) {
      form.set('missingRequiredAdmin', true)
    } else if (activeStep === 2) {
      const inviteForm: any = {
        first_name: form.first_name,
        last_name: form.last_name,
        email: form.email,
        send_email: form.send_email,
        send_sms: form.send_sms,
        send_email_datetime: form.datetimeUTC,
        employee_id: form.employee_id,
        tag_ids: form.tag_keys_values.map((t) => t.tag_id),
        tag_keys_values: form.tag_keys_values.map((t) => {
          return { key: t.key, value: t.value }
        }),
      }

      if (form.phone_number && validateAndFormatNumber(form.phone_number)) {
        inviteForm.phone_number = validateAndFormatNumber(form.phone_number)
      }

      if (form.email) {
        inviteForm.email = form.email
      }

      const invite = InviteUserPayload.create(inviteForm)

      if (admin) {
        setLoading(true)
        inviteAdmin(invite).then(() => {
          setTimeout(() => {
            onSave()
          }, 1000)
        })
      } else {
        inviteUser(invite).then(() => onSave())
      }
      close()
    } else {
      form.set('missingRequired', false)
      form.set('missingRequiredAdmin', false)
      const newActiveStep = !isLastStep() ? activeStep + 1 : activeStep
      setActiveStep(newActiveStep)
    }
  }

  const handleBack = () => setActiveStep((prevActiveStep) => prevActiveStep - 1)

  const stepIsValid = () => {
    if (activeStep === 0) {
      if (admin) {
        return !Object.keys(form.errors).length
      }
      return !Object.keys(form.errors).length
    } else if (activeStep === 1) {
      return !Object.keys(form.errors).length
    } else {
      return true
    }
  }

  const handleClose = () => {
    setActiveStep(0)
    close()
  }

  return (
    <Dialog
      maxWidth="sm"
      actions={
        <div style={{ flex: 1, display: 'flex', justifyContent: 'space-between' }}>
          <Button
            disabled={activeStep === 0}
            onClick={handleBack}
            text="Back"
            variant="text"
            startIcon={<ChevronLeftRounded />}
          />
          <Button
            onClick={handleNext}
            disabled={!stepIsValid()}
            text={activeStep === 2 ? 'Add User' : 'Next'}
          />
        </div>
      }
      onClose={handleClose}
      title={admin ? 'Invite Admin' : 'Invite User'}
      open={open}
    >
      <Stepper style={{ marginBottom: 20 }} nonLinear activeStep={activeStep}>
        {steps.map((label) => (
          <Step key={label}>
            <StepButton disableRipple disabled>
              {label}
            </StepButton>
          </Step>
        ))}
      </Stepper>
      <div style={{ overflow: 'auto', height: '100%', minHeight: 500 }}>
        {getStepContent(activeStep)}
      </div>
    </Dialog>
  )
})
