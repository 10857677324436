import { types } from 'mobx-state-tree'
import { ContentMetadata } from './UserQuiz'

export const TopicData = types.model('TopicData').props({
  topic_id: types.maybeNull(types.number),
  photo: types.maybeNull(types.string),
  title: types.string,
  quiz_count: types.number,
  quizzes_completed: types.number,
  pack_color: types.maybeNull(types.string),
})

export const AssignedTopic = types.model('AssignedTopic').props({
  assignment_id: types.number,
  topic: TopicData,
  due_date: types.maybeNull(types.string),
  end_date: types.maybeNull(types.string),
  knowledge_pct: types.maybeNull(types.number),
  locked_by_pre_test: types.boolean,
  certification_enabled: types.boolean,
  user_cert_level: types.maybeNull(types.string),
  metadata: ContentMetadata,
})

export const FlattenedTopic = types.model('FlattenedTopic').props({
  title: types.string,
  topic_id: types.maybeNull(types.number),
  assignment_id: types.number,
  topic: TopicData,
  knowledge_pct: types.maybeNull(types.number),
  locked_by_pre_test: types.boolean,
  certification_enabled: types.boolean,
  user_cert_level: types.maybeNull(types.string),
})
