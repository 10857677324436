import React from 'react'
import { Link } from 'react-router-dom'

import { color } from '../../../theme'
import { Avatar } from '../../avatar'
import { Text } from '../../text'
import { PersonRounded } from '@mui/icons-material'
import { getDisplayNumber } from '@trivie/core/src/utils/phoneNumber'

export function NameCell({
  params,
  url,
  disableLink,
}: {
  params: any
  url?: string
  disableLink?: boolean
}) {
  const { display_name, phone_number, photo_url, file_url, email, first_name, last_name } =
    params.row as any

  const name = display_name || `${first_name} ${last_name}`

  return (
    <div style={{ height: '100%', display: 'flex', alignItems: 'center' }}>
      <Link style={{ textDecoration: 'none !important' }} className="NameCell" to={url ?? ''}>
        {!name ? (
          <PersonRounded style={{ display: 'flex' }} />
        ) : (
          <Avatar name={name} url={photo_url || file_url} />
        )}
      </Link>
      <div style={{ overflow: 'hidden', lineHeight: 'normal', marginLeft: 16 }}>
        {disableLink ? (
          <Text variant="body1Emphasized" text={first_name && last_name ? name : email} />
        ) : (
          <Link style={{ overflow: 'hidden', display: 'flex' }} className="NameCell" to={url ?? ''}>
            <Text noWrap variant="body1Emphasized" text={name ? name : email} />
          </Link>
        )}
        {email && name && (
          <Text noWrap variant="body2" text={email} style={{ color: color.shade50 }} />
        )}
        {phone_number && !email && (
          <Text
            variant="body2"
            text={getDisplayNumber(phone_number)}
            style={{ color: color.shade50 }}
          />
        )}
      </div>
    </div>
  )
}
