import { Instance, types } from 'mobx-state-tree'

export const Branding = types.model('Branding').props({
  id: types.number,
  is_active: types.boolean,
  last_modified: types.string,
  web_font_url: types.maybeNull(types.string),
  native_font_url: types.maybeNull(types.string),
  font_family: types.string,
  accent_color: types.string,
  correct_answer: types.string,
  feedback_background: types.string,
  feedback_header: types.string,
  feedback_text: types.string,
  incorrect_answer: types.string,
  logo_url: types.maybeNull(types.string),
  quiz_background: types.string,
  review_background: types.string,
  text_color: types.string,
  review_background_text: types.maybe(types.string),
})
