import React from 'react'
import { Link } from 'react-router-dom'
import TableCell from '@mui/material/TableCell'
import { People } from '@mui/icons-material'

import { color } from '../../../theme'
import { Text } from '../../text'

export const GroupsShortCell = (props: any) => {
  const { row } = props
  return (
    <TableCell style={CELL} align="left">
      <div style={CONTAINER}>
        <Link style={LINK} to={`/people/groups/${row.group_id || row.id}/overview`}>
          <People htmlColor={color.shade70} style={{ marginRight: 8 }} />
          <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
            <Text text={row.label || row.group_name} variant="body1Medium" />
            {row.group_user_count && (
              <Text
                style={{ color: color.shade50, marginTop: 4 }}
                text={`${row.group_user_count} members`}
                variant="body1"
              />
            )}
          </div>
        </Link>
      </div>
    </TableCell>
  )
}

const CONTAINER: React.CSSProperties = {
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'space-between',
}
const CELL: React.CSSProperties = { borderBottom: 'none', padding: 0 }
const LINK: React.CSSProperties = {
  flexDirection: 'row',
  display: 'flex',
  textDecoration: 'none',
  color: color.black,
  alignItems: 'center',
}
