import React from 'react'
import { Skeleton, Stack } from '@mui/material'

import { Divider } from '../divider'
import { Text } from '../text'
import { Button } from '../button/button'

const _snakeCase = require('lodash/snakeCase')

interface HeaderProps {
  /**
   * Header title string - if empty/undefined show loader
   */
  title?: string
  /**
   * Show / hide loading skeleton
   * @default false
   */
  loading?: boolean
  /**
   * Show / hide bottom divider
   * @default false
   */
  divider?: boolean
  /**
   * Primary action button props
   */
  primaryAction?: any
  /**
   * Secondary action button props
   */
  secondaryAction?: any
}

export const Header = (props: HeaderProps) => {
  const { primaryAction, secondaryAction, divider = false, loading = false, title } = props
  return (
    <>
      <header>
        <Stack minHeight={36} direction="row" justifyContent="space-between" alignItems="center">
          {loading || !title ? (
            <Skeleton variant="rectangular" style={LOADER} height={29} width={250} />
          ) : (
            <Text component="h1" variant="headline" text={title} />
          )}
          {(primaryAction || secondaryAction) && (
            <div style={{ display: 'flex', flexDirection: 'row', gap: 12 }}>
              {secondaryAction && (
                <Button
                  sx={{
                    ':disabled': { background: 'rgba(0, 0, 0, 0.12) !important' },
                  }}
                  color={secondaryAction.gradient ? 'gradient' : 'white'}
                  {...secondaryAction}
                  data-qat={_snakeCase(secondaryAction?.text)}
                />
              )}
              {primaryAction && (
                <Button {...primaryAction} data-qat={_snakeCase(primaryAction?.text)} />
              )}
            </div>
          )}
        </Stack>
      </header>
      {divider && <Divider style={{ marginTop: 20, marginBottom: 20 }} />}
    </>
  )
}

const LOADER: React.CSSProperties = { borderRadius: 6 }
