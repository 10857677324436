import React, { useState } from 'react'

import { observer } from 'mobx-react-lite'
import { Instance } from 'mobx-state-tree'

import { Box, DialogActions, Stack, Tab, Tabs } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { Language, PermMedia, Publish, Search, TextFields } from '@mui/icons-material'

import { Quiz } from '@trivie/core/src/models/Quiz'

import { UploadResourceTab } from './tabs/upload-resource-tab'
import { WebResourceTab } from './tabs/web-resource-tab'
import { TextResourceTab } from './tabs/text-resource-tab'
import { ExistingResourcesTab } from './tabs/existing-resource-tab'
import { useStores } from '@trivie/core'
import { ResourceComponent } from '@trivie/ui-web/components/resource-component'
import { SearchResourceTab } from './tabs/search-resource-tab'
import { Button } from '@trivie/ui-web/components/button'

export const a11yProps = (index: number) => {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  }
}

export const useTabsStyles = makeStyles({
  fixed: {
    display: 'flex',
    justifyContent: 'flex-start',
  },
})

export const useTabStyles = makeStyles({
  // Dropdown container
  root: {
    '&.Mui-selected': {
      color: '#000000',
    },
    '& > svg': {
      marginRight: '4px !important',
    },
    position: 'relative',
    padding: '6px 8px',
    overflow: 'hidden',
    // letterSpacing: '0px !important',
    maxWidth: '264px',
    minWidth: '0px',
    boxSizing: 'border-box',
    minHeight: '48px',
    textAlign: 'center',
    flexShrink: 0,
    whiteSpace: 'normal',
    textTransform: 'none',
    justifyContent: 'center',
  },
})

interface ContentGenerationTabProps {
  children?: React.ReactNode
  index: number
  value: number
}

export const ContentGenerationTab = observer((props: ContentGenerationTabProps) => {
  const { children, value, index, ...other } = props

  return (
    <div role="tabpanel" hidden={value !== index} {...other}>
      {value === index && <>{children}</>}
    </div>
  )
})

interface ContentGenerationTabsProps {
  quiz: Instance<typeof Quiz> | null
  topic?: any
  standalone?: boolean
  onClose?: any
  onUpload?: any
  selectedTab?: any
  setSelectedTab?: any
}

export const ContentGenerationTabs = observer((props: ContentGenerationTabsProps) => {
  const { resourceStore } = useStores()
  const { getResourceDetails } = resourceStore
  const { onUpload, selectedTab, setSelectedTab, quiz, onClose, topic, standalone } = props
  const [value, setValue] = React.useState(selectedTab ? selectedTab : topic || quiz ? 0 : 1)
  const tabsClasses = useTabsStyles()
  const tabClasses = useTabStyles()

  const [selected, setSelected] = useState<any>(null)

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue)
    if (Boolean(setSelectedTab)) {
      setSelectedTab(newValue)
    }
    setSelected(null)
  }

  return (
    <>
      <Box
        sx={{
          width: '100%',
          height: value === 0 ? '729px' : '100%',
          paddingLeft: standalone ? 0 : '24px',
          paddingRight: standalone ? 0 : '24px',
          display: quiz?.mercuryResource && !standalone ? 'none' : 'block',
        }}
      >
        <Box
          style={{
            marginBottom: 24,
          }}
        >
          <Tabs
            style={{ overflowX: 'auto' }}
            selectionFollowsFocus
            variant="standard"
            indicatorColor="primary"
            classes={{ ...tabsClasses }}
            value={value}
            onChange={handleChange}
            aria-labelledby="a11y-tabs-automatic-label"
          >
            <Tab
              style={{ display: topic || quiz ? 'flex' : 'none' }}
              icon={<PermMedia style={{ fontSize: 16 }} />}
              iconPosition="start"
              disableRipple
              classes={tabClasses}
              label="Library"
              {...a11yProps(0)}
            />
            <Tab
              icon={<Search style={{ fontSize: 16 }} />}
              iconPosition="start"
              disableRipple
              classes={tabClasses}
              label="Search"
              {...a11yProps(1)}
            />
            <Tab
              icon={<Publish style={{ fontSize: 16 }} />}
              iconPosition="start"
              disableRipple
              classes={tabClasses}
              label="Upload"
              // style={{ marginRight: 8 }}
              {...a11yProps(2)}
            />
            <Tab
              icon={<Language style={{ fontSize: 16 }} />}
              iconPosition="start"
              disableRipple
              classes={tabClasses}
              label="Web"
              // style={{ marginRight: 8 }}
              {...a11yProps(3)}
            />
            <Tab
              icon={<TextFields style={{ fontSize: 16 }} />}
              iconPosition="start"
              disableRipple
              classes={tabClasses}
              label="Text"
              // style={{ marginRight: 8 }}
              {...a11yProps(4)}
            />
          </Tabs>
        </Box>
        <ContentGenerationTab value={value} index={0}>
          <ExistingResourcesTab
            onSelect={(s) => setSelected(s)}
            selected={selected}
            topic={topic}
            standalone={standalone}
            quiz={quiz}
          />
        </ContentGenerationTab>
        <ContentGenerationTab value={value} index={1}>
          <SearchResourceTab
            topic={topic}
            standalone={standalone}
            quiz={quiz}
            onSelect={(s) => {
              setSelected(s)
              if (Boolean(onUpload)) {
                onUpload(s)
              }
              onClose()
            }}
          />
        </ContentGenerationTab>
        <ContentGenerationTab value={value} index={2}>
          {selected && standalone ? (
            <ResourceComponent
              inline
              isSelected={false}
              onClick={(r) => {}}
              edit={topic || quiz}
              resource={selected}
              hideActions
            />
          ) : (
            <UploadResourceTab
              onUpload={(s) => {
                if (s) {
                  setSelected(s)
                  s.set('editing', true)
                }
              }}
              topic={topic}
              standalone={standalone}
              quiz={quiz}
            />
          )}
        </ContentGenerationTab>
        <ContentGenerationTab value={value} index={3}>
          {selected && standalone ? (
            <ResourceComponent
              inline
              isSelected={false}
              onClick={(r) => {}}
              edit={topic || quiz}
              resource={selected}
              hideActions
            />
          ) : (
            <WebResourceTab
              onSelect={(s) => {
                setSelected(s)
                s.set('editing', true)
              }}
              selected={selected}
              topic={topic}
              standalone={standalone}
              quiz={quiz}
            />
          )}
        </ContentGenerationTab>
        <ContentGenerationTab value={value} index={4}>
          <TextResourceTab
            onSelect={(s) => {
              setSelected(s)
              if (Boolean(onUpload)) {
                onUpload(s)
              }
              onClose()
            }}
            topic={topic}
            standalone={standalone}
            quiz={quiz}
          />
        </ContentGenerationTab>
      </Box>
      {value === 4 || value === 1
        ? null
        : standalone && (
            <Stack mt="20px" direction="row" justifyContent="flex-end">
              <Button
                disabled={!selected}
                onClick={() => {
                  if (selected) {
                    getResourceDetails(selected.resource_id).then((details) => {
                      selected.set(
                        'preview_url',
                        details?.converted_pdf_url || details?.filename_or_url,
                      )
                      if (topic) {
                        topic.addResource(selected)
                      } else if (quiz) {
                        quiz.addResource(selected)
                      }
                    })
                    if (Boolean(onUpload)) {
                      onUpload(selected)
                    }
                    onClose()
                  }
                }}
                text={selected || value === 0 || value === 3 ? 'Add Source' : 'Next'}
              />
            </Stack>
          )}
    </>
  )
})
