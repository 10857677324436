import { ApiResponse, ApisauceInstance } from 'apisauce'
import { Instance } from 'mobx-state-tree'
import { handleResponse } from '../handleResponse'
import { LeaderboardTagResponse } from '../../models/response-models'
import { LeaderboardByTagResponse } from '../../models/response-models/leaderboardByTag'

export const LeaderboardApi = {
  apisauce: {} as ApisauceInstance,

  /**
   * Leaderboard tag list
   */
  getTags: async (userId: number) => {
    const response: ApiResponse<Instance<typeof LeaderboardTagResponse>> =
      await LeaderboardApi.apisauce.get(`/users/${userId}/tag_categories/`)
    return handleResponse(response)
  },

  /**
   * Get user leaderboard
   */
  getLeaderboards: async (filter: string) => {
    const response: ApiResponse<any> = await LeaderboardApi.apisauce.get(
      `/leaderboard/filter/${filter}/`,
      {},
    )
    return handleResponse(response)
  },

  /**
   * Get user leaderboard rank
   */
  getLeaderboardRank: async (user_id: number, filter: string) => {
    const response: ApiResponse<any> = await LeaderboardApi.apisauce.get(
      `/leaderboard/user/${user_id}/${filter}/`,
      {},
    )
    return handleResponse(response)
  },

  /**
   * Get Leaderboard by tag
   */
  getLeaderboardByTag: async (tag_category: string, filter: string) => {
    const urlQuery = new URLSearchParams({
      tag_category,
    })

    const response: ApiResponse<Instance<typeof LeaderboardByTagResponse>> =
      await LeaderboardApi.apisauce.get(
        `/leaderboard/tag_category/filter/${filter}/?${urlQuery}`,
        {},
      )

    return handleResponse(response)
  },
}
