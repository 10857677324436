/* eslint-disable */

import { onSnapshot } from 'mobx-state-tree'
import { RootStoreModel } from './root-store'
import { Environment } from './environment'
import { flatten, mergeAll } from 'ramda'
import * as storage from '../services/storage'

// Default root-store setup config
const DEFAULT_CONFIG = {
  persist: false,
  storageKey: 'trivie',
}

/**
 * Setup the environment that all the models will be sharing.
 *
 * The environment includes other functions that will be picked from some
 * of the models that get created later. This is how we loosly couple things
 * like events between models.
 */
export async function createEnvironment(
  version: string,
  showMaintenance: (show: boolean) => void,
  showAlert?: (status: string, message: string, reset: boolean) => void,
  forceUpgrade?: (show: boolean) => void,
  config?: any,
) {
  const { insights, timezone } = config
  const env = new Environment()
  await env.setup(version, showMaintenance, timezone, showAlert, forceUpgrade, insights)
  return env
}

/**
 * Setup the root state / store
 */
export async function setupRootStore(
  configOverride: any,
  showMaintenance: (show: boolean) => void,
  showAlert?: (status: string, message: string, reset: boolean) => void,
  forceUpgrade?: (show: boolean) => void,
) {
  let rootStore: any
  let data: any

  const config = mergeAll(flatten([DEFAULT_CONFIG, configOverride]))
  const { persist, storageKey, version, flavor, timezone, insights } = config

  // Prepare environment
  const env = await createEnvironment(version, showMaintenance, showAlert, forceUpgrade, config)

  try {
    data = persist ? (await storage.load(storageKey)) || {} : {}
    rootStore = RootStoreModel.create({ ...data, flavor }, env)
  } catch (e) {
    rootStore = RootStoreModel.create({}, env)
  }

  // Track changes & save to storage
  onSnapshot(rootStore, (snapshot) => storage.save(storageKey, snapshot))

  return rootStore
}
