import React, { useEffect, useState } from 'react'
import { observer } from 'mobx-react-lite'
import { Instance } from 'mobx-state-tree'
import { InputAdornment, TextField, Checkbox, Box, Stack } from '@mui/material'
import { CheckCircle, RadioButtonUnchecked, Search } from '@mui/icons-material'

import { useStores } from '@trivie/core'
import { color } from '@trivie/ui-web/theme/color'
import { Text } from '@trivie/ui-web/components/text'

import { Dialog } from '@trivie/ui-web/components/dialogs/dialog'
import { GroupItem } from '../group-item'
import { UserItem } from '../user-item'
import { SurveyGroup, SurveyUser } from '@trivie/core/src/models/survey/SurveyUserGroup'

interface selectedGroupType extends Instance<typeof SurveyGroup> {
  checked?: boolean
}

interface selectedUserType extends Instance<typeof SurveyUser> {
  checked?: boolean
}

export const AssignmentUsersDialog = observer(
  (props: {
    open: boolean
    requestClose: () => void
    setSelectedAssignees: (items: {
      groups: Instance<typeof SurveyGroup>[]
      users: Instance<typeof SurveyUser>[]
    }) => void
  }) => {
    const { surveyStore } = useStores()
    const { open, setSelectedAssignees } = props
    const { selectedSurvey, getAvailableUsers, availableGroups, availableUsers } = surveyStore
    const [search, setSearch] = useState('')
    const [isLoading, setIsLoading] = useState(false)
    const [selectedGroups, setSelectedGroups] = useState<Instance<typeof SurveyGroup>[]>([])
    const [selectedUsers, setSelectedUsers] = useState<Instance<typeof SurveyUser>[]>([])
    const noOptions = !(selectedGroups.length || selectedUsers.length)
    useEffect(() => {
      if (open) {
        setIsLoading(true)
        getAvailableUsers().then(() => {
          resetSelectedUsers()
          resetSelectedGroups()
          setIsLoading(false)
        })
      }
    }, [open])

    const resetSelectedUsers = () => {
      setSelectedUsers(
        availableUsers?.map((user) => ({
          ...user,
          checked: selectedSurvey?.userIds.includes(user.user_id),
        })),
      )
    }

    const resetSelectedGroups = () => {
      setSelectedGroups(
        availableGroups?.map((group) => ({
          ...group,
          checked: selectedSurvey?.groupIds.includes(group.group_id),
        })),
      )
    }
    const toggleUsers = (id: number) => {
      setSelectedUsers(
        selectedUsers.map((user: selectedUserType) => ({
          ...user,
          checked: user.user_id === id ? !user.checked : user.checked,
        })),
      )
    }

    const toggleGroups = (id: number) => {
      setSelectedGroups(
        selectedGroups.map((group: selectedGroupType) => ({
          ...group,
          checked: group.group_id === id ? !group.checked : group.checked,
        })),
      )
    }

    const onClickAdd = () => {
      if (selectedSurvey && selectedSurvey.id) {
        const data = {
          groups: [
            ...selectedGroups.filter((gr: selectedGroupType) => gr.checked),
            ...selectedSurvey.groups,
          ],
          users: [
            ...selectedUsers.filter((usr: selectedUserType) => usr.checked),
            ...selectedSurvey.users,
          ],
        }
        setSelectedAssignees(data)
        props.requestClose()
      }
    }
    return (
      <Dialog
        maxWidth="sm"
        onCancel={props.requestClose}
        onClose={props.requestClose}
        open={open}
        title="Add Users"
        onConfirm={onClickAdd}
        disableConfirm={noOptions}
      >
        <TextField
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <Search htmlColor={color.palette.grey} />
              </InputAdornment>
            ),
          }}
          fullWidth
          variant="outlined"
          placeholder="Search"
          type="search"
          onChange={(e) => setSearch(e.target.value)}
        />
        <Stack mt={2}>
          {noOptions && !isLoading ? (
            <Text variant="headline" text={'No Users Found'} style={{ color: color.shade50 }} />
          ) : null}
          <Box maxHeight={500}>
            <Stack mb={2} useFlexGap gap={2}>
              {selectedGroups?.length ? (
                <Text variant="body2Emphasized" text={'GROUPS'} style={{ color: color.shade50 }} />
              ) : null}
              {selectedGroups
                ?.filter((grp) => (search ? grp.label.includes(search) : true))
                ?.map((group: selectedGroupType) => (
                  <Stack direction="row">
                    <Checkbox
                      onClick={() => toggleGroups(group.group_id)}
                      checked={group.checked}
                      checkedIcon={<CheckCircle />}
                      icon={<RadioButtonUnchecked />}
                      inputProps={{ 'aria-label': 'checkbox to select a group' }}
                      id={`assign-group-${group.group_id}`}
                    />
                    <GroupItem group={group} />
                  </Stack>
                ))}
            </Stack>
            <Stack useFlexGap gap={2}>
              {selectedUsers?.length ? (
                <Text variant="body2Emphasized" text={'ALL'} style={{ color: color.shade50 }} />
              ) : null}
              {selectedUsers
                .filter((usr) =>
                  search ? usr.display_name.includes(search) || usr.email?.includes(search) : true,
                )
                ?.map((user: selectedUserType) => (
                  <Stack direction="row">
                    <Checkbox
                      onClick={() => toggleUsers(user.user_id)}
                      checked={user.checked}
                      checkedIcon={<CheckCircle />}
                      icon={<RadioButtonUnchecked />}
                      inputProps={{ 'aria-label': 'checkbox to select a group' }}
                      id={`assign-users-${user.user_id}`}
                    />
                    <UserItem user={user} />
                  </Stack>
                ))}
            </Stack>
          </Box>
        </Stack>
      </Dialog>
    )
  },
)
