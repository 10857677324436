import React, { useState } from 'react'
import makeStyles from '@mui/styles/makeStyles'
import createStyles from '@mui/styles/createStyles'
import { Avatar as MaterialAvatar, IconButton } from '@mui/material'
// @ts-ignore
import { ReactComponent as UploadIcon } from '../../assets/icons/Upload.svg'
import { Text } from '../text'
import { color } from '../../theme'
import { Skeleton } from '@mui/material'

const useStyles = makeStyles(() =>
  createStyles({
    blue: {
      background: '#212746',
      border: '3px solid rgba(255, 255, 255, 0.3)',
    },
    editableLarge: {
      height: 90,
      width: 90,
    },
    letterLarge: {
      color: 'linear-gradient(180deg, rgba(253, 253, 253, 0.97) 12.5%, #FFDD64 100%)',
      background: 'linear-gradient(95.61deg, #E4EBFF 2.11%, #ECE9FF 97.32%)',
      height: 90,
      width: 90,
    },
  }),
)

const DigitAvatar = (props: any) => {
  const classes = useStyles()
  const { name, size, fontStyle, ...rest } = props
  return (
    <MaterialAvatar className={classes.blue} {...rest}>
      <Text
        variant="titleEmphasized"
        text={name}
        style={{
          backgroundImage: 'linear-gradient(180deg, rgba(253, 253, 253, 0.97) 12.5%, #FFDD64 100%)',
          '-webkit-background-clip': 'text',
          '-webkit-text-fill-color': 'transparent',
          ...fontStyle,
        }}
      />
    </MaterialAvatar>
  )
}

export const BadgeAvatar = (props: any) => {
  const classes = useStyles()
  const { url, name, size, fontStyle, onClick, hoverable, variant = 'circular' } = props
  const [isHover, setIsHover] = useState(false)

  const renderAvatar = () => {
    if (isHover) {
      return <UploadIcon style={{ height: size, width: size }} />
    }
    if (!url && !name) {
      return <Skeleton variant={variant} width={size} height={size} />
    } else if (url) {
      return <MaterialAvatar style={{ height: size, width: size }} src={url} variant={variant} />
    } else {
      return (
        <DigitAvatar
          name={name}
          alt={name}
          fontStyle={fontStyle}
          style={{ height: size, width: size }}
          className={classes.blue}
        />
      )
    }
  }
  return (
    <IconButton
      style={{ padding: 0 }}
      onClick={onClick}
      disabled={!Boolean(onClick)}
      onMouseEnter={() => hoverable && setIsHover(true)}
      onMouseLeave={() => hoverable && setIsHover(false)}
      size="large"
    >
      {renderAvatar()}
    </IconButton>
  )
}

BadgeAvatar.defaultProps = {
  hoverable: true,
}
