import React from 'react'
import { Link } from 'react-router-dom'
import { GridCellParams } from '@mui/x-data-grid-pro'

import { Image } from '../../image'
import { Text } from '../../text'

interface ImageCellProps {
  params: GridCellParams
  imageStyle?: React.CSSProperties
  defaultImage: any
}
export function ImageCell(props: ImageCellProps) {
  const { defaultImage, params, imageStyle } = props
  const { row } = params
  const { topic_title, path_topic_title, title, photo_url } = row as any

  return (
    <div style={{ height: '100%', display: 'flex', alignItems: 'center' }}>
      <Link style={LINK} to={`/content/topics/${params.id}/overview`}>
        <Image
          src={photo_url || defaultImage}
          style={{ height: 36, minWidth: 72, ...imageStyle }}
        />
        <Text variant="body1Emphasized" text={(topic_title || path_topic_title) ?? title} noWrap />
      </Link>
    </div>
  )
}

const LINK: React.CSSProperties = {
  flexDirection: 'row',
  display: 'flex',
  alignItems: 'center',
  overflow: 'hidden',
  gap: 20,
}
const DEFAULT_TOPIC: React.CSSProperties = {
  height: 36,
  minWidth: 72,
  borderRadius: 6,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
}
