import React from 'react'
import { MeterProps } from './meter.props'
import { color } from '../../theme'

/**
 * Meter
 */
export function Meter(props: MeterProps) {
  const { value, variant, style } = props
  let barStyle
  let unfilledStyle

  if (variant === 'green') {
    barStyle = {
      backgroundColor: color.palette.green,
    }
    unfilledStyle = {
      backgroundColor: color.shade20,
    }
  } else if (variant === 'blue') {
    barStyle = {
      backgroundColor: color.palette.blueLink,
    }
    unfilledStyle = {
      backgroundColor: color.shade20,
    }
  } else if (variant === 'red') {
    barStyle = {
      backgroundColor: color.palette.red,
    }
    unfilledStyle = {
      backgroundColor: color.shade20,
    }
  } else if (variant === 'strong') {
    barStyle = {
      background: color.palette.gradientGreenFilled,
    }
    unfilledStyle = {
      background: color.palette.gradientGreenUnfilled,
    }
  } else if (variant === 'weak') {
    barStyle = {
      background: color.palette.gradientRedFilled,
    }
    unfilledStyle = {
      background: color.palette.gradientRedUnfilled,
    }
  }

  return (
    <div
      style={{
        height: 12,
        borderRadius: 12,
        overflow: 'hidden',
        ...unfilledStyle,
        ...style,
      }}
    >
      <div
        style={{
          flex: 1,
          height: '100%',
          width: `${Math.round(value)}%`,
          ...barStyle,
        }}
      />
    </div>
  )
}
