import React from 'react'
import { Text } from '@trivie/ui-web/components/text'
import { color } from '@trivie/ui-web/theme'
import featureSet from './features.json'
import { Check } from '@mui/icons-material'

export const FeatureGrid = () => {
  return (
    <div style={CONTAINER}>
      <Text text="Compare Plans" style={HEADER} />
      <div style={{ flexDirection: 'row', paddingLeft: 10, paddingRight: 10, display: 'flex' }}>
        <div style={{ ...CELL, justifyContent: 'flex-start' }}>
          <Text text="Features" variant="headline" />
        </div>
        <div style={CELL}>
          <Text text="Free" variant="headline" />
        </div>
        <div style={CELL}>
          <Text style={{ color: '#885FFC' }} text="Standard" variant="headline" />
        </div>
        <div style={CELL}>
          <Text style={{ color: '#551BFA' }} text="Pro" variant="headline" />
        </div>
        <div style={{ ...CELL, borderRight: 'none' }}>
          <Text style={{ color: color.blue }} text="Enterprise" variant="headline" />
        </div>
      </div>
      {Object.keys(featureSet.features).map((key, index0) => {
        const features = featureSet.features[key]
        return features.map((feature, index) => (
          <div
            style={{
              ...ROW,
              paddingLeft: 10,
              paddingRight: 10,
              borderBottom:
                index0 === Object.keys(featureSet.features).length - 1
                  ? 'none'
                  : index === featureSet.features[key].length - 1
                    ? `1px solid #ABB5D8`
                    : 'none',
            }}
          >
            <Text variant="label" style={LABEL} text={feature.label} />
            {Object.keys(feature.plans).map((k, index2) => {
              const f = feature.plans[k]
              return (
                <div
                  style={{
                    ...FEATURE,
                    borderRight:
                      index2 !== Object.keys(feature.plans).length - 1
                        ? `1px solid #ABB5D8`
                        : 'none',
                  }}
                >
                  {feature.type === 'string' ? (
                    <Text style={{ fontSize: 15 }} variant="label" text={f} />
                  ) : feature.type === 'boolean' && f ? (
                    <Check htmlColor="#551BFA" />
                  ) : (
                    <Text style={{ fontSize: 15 }} variant="label" text="-" />
                  )}
                </div>
              )
            })}
          </div>
        ))
      })}
    </div>
  )
}

const CELL: React.CSSProperties = {
  flex: 1,
  display: 'flex',
  paddingTop: 12,
  paddingBottom: 12,
  borderBottom: `1px solid #ABB5D8`,
  borderRight: `1px solid #ABB5D8`,
  justifyContent: 'center',
  alignItems: 'center',
}
const CONTAINER: React.CSSProperties = {
  background: '#fff',
  borderRadius: 12,
  padding: 40,
  border: `2px solid #ABB5D8`,
}
const FEATURE: React.CSSProperties = {
  padding: 12,
  justifyContent: 'center',
  flex: 1,
  display: 'flex',
}
const LABEL: React.CSSProperties = {
  padding: 12,
  paddingRight: 24,
  flex: 1,
  fontSize: 15,
  borderRight: `1px solid #ABB5D8`,
}
const HEADER: React.CSSProperties = {
  textAlign: 'center',
  marginBottom: 52,
  fontSize: 36,
  fontWeight: 700,
}
const ROW: React.CSSProperties = {
  flexDirection: 'row',
  display: 'flex',
}
