import React from 'react'
import { Circle, ExpandMore } from '@mui/icons-material'
import { InputBase, MenuItem, Select, styled } from '@mui/material'

import { Dialog } from '@trivie/ui-web/components/dialogs/dialog'
import { Text } from '@trivie/ui-web/components/text'
import { color } from '@trivie/ui-web/theme'

const TrivieDropdown = styled(InputBase)(({ theme }) => ({
  '& .MuiInputBase-input': {
    width: '100%',
    borderRadius: 6,
    position: 'relative',
    backgroundColor: color.white,
    fontSize: 16,
    padding: '10px 26px 10px 12px',
    lineHeight: '22px',
    minHeight: '0px !important',
  },
  '& .MuiPopover-root': {
    height: 400,
  },
}))

export const QuizSettingsDialog = (props) => {
  const { open, onClose, onSubmit, onChange, value } = props

  return (
    <Dialog
      maxWidth="xs"
      onConfirm={() => {
        onSubmit()
        onClose()
      }}
      confirmBtnLabel="Done"
      onClose={onClose}
      title="Quiz Settings"
      open={open}
    >
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        <div
          style={{
            flexDirection: 'row',
            display: 'flex',
            marginBottom: 12,
            justifyContent: 'space-between',
          }}
        >
          <div style={{ flexDirection: 'column', marginBottom: 20 }}>
            <Text text="Quiz Status" variant="body2Emphasized" />
            <Text
              text="Set your quiz to Live in order to share it."
              variant="body2"
              style={{ color: color.shade50 }}
            />
          </div>
          <div style={{ position: 'relative' }}>
            <Circle
              style={{ zIndex: 999, fontSize: 6, position: 'absolute', top: 15.5, left: 12 }}
              htmlColor={value === 'Live' ? color.green : color.yellow}
            />
            <Select
              variant="standard"
              displayEmpty
              input={<TrivieDropdown />}
              MenuProps={{ PaperProps: { style: { borderRadius: 6, paddingTop: 0 } } }}
              IconComponent={ExpandMore}
              value={value}
              onChange={(e: any) => {
                setTimeout(() => {
                  // @ts-ignore
                  document?.activeElement?.blur()
                }, 0)
                onChange(e.target.value)
              }}
              style={STATUS_DROPDOWN}
            >
              <MenuItem value="Draft">
                <Text style={{ display: 'flex' }} variant="body2Emphasized" text="Draft" />
              </MenuItem>
              <MenuItem value="Live">
                <Text style={{ display: 'flex' }} variant="body2Emphasized" text="Live" />
              </MenuItem>
            </Select>
          </div>
        </div>
      </div>
    </Dialog>
  )
}

const STATUS_DROPDOWN: React.CSSProperties = {
  placeSelf: 'center',
  border: `1px solid ${color.shade10}`,
  borderRadius: 6,
  width: 90,
  paddingLeft: 10,
}
