import { types } from 'mobx-state-tree'

/**
 * UserShortSchema
 */
export const UserShortSchemaModel = types.model('UserShortSchema').props({
  __typename: types.optional(types.literal('UserShortSchema'), 'UserShortSchema'),
  email: types.string,
  first_name: types.string,
  id: types.integer,
  last_name: types.string,
  file_url: types.maybeNull(types.string),
})
