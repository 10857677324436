import React from 'react'

export function Und() {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M8.70615 14.71L11.2961 17.3C11.6861 17.69 12.3161 17.69 12.7061 17.3L15.2961 14.71C15.9261 14.08 15.4761 13 14.5861 13H9.40615C8.51615 13 8.07615 14.08 8.70615 14.71Z"
        fill="black"
        fillOpacity="0.1"
      />
      <path
        d="M8.70639 9.29244L11.2964 6.70244C11.6864 6.31244 12.3164 6.31244 12.7064 6.70244L15.2964 9.29244C15.9264 9.92244 15.4764 11.0024 14.5864 11.0024H9.40639C8.51639 11.0024 8.07639 9.92244 8.70639 9.29244Z"
        fill="black"
        fillOpacity="0.1"
      />
    </svg>
  )
}
