import { types, getEnv, Instance, getRoot, SnapshotOut, clone, flow, cast } from 'mobx-state-tree'
import { SurveyApi } from '../../services/api-objects/SurveyApi'
import { Environment } from '../environment'
import { Survey, SurveyQuestion } from '../Survey'
import { SurveyGroup, SurveyUser } from '../survey/SurveyUserGroup'
import { SurveyAssignPayload } from '../survey/SurveyAssignPayload'
import {
  PostSurveyAssignDetailsRequestType,
  AssignedSurvey,
  SurveySubmitRequestModelType,
} from '../base'
import { Awaited } from '../../utility-types'
import { GeneralSurveyPayload } from '../GeneralSurveyPayload'
import { ExtendSurveyPayload } from '../ExtendSurveyPayload'
import { SurveySchedulePayload } from '../survey/SurveySchedulePayload'
import { GeneralApiProblem } from '../../services/api'

import { FlowCacheModel, useFlowCacheMiddleware } from '../../middleware'

export interface IApiRejectProps {
  kind: string
  message: string
}

const SurveyListModel = types.model('SurveyList').props({
  total_survey_count: types.number,
  surveys: types.array(Survey),
})

const ACTIONS_TO_CACHE = {
  getAssignedSurveysForUser: { wait: 30 },
}

export const SurveyStoreModel = types
  .model('SurveyStore')
  .props({
    status: types.optional(types.enumeration(['idle', 'pending', 'done', 'error']), 'idle'),
    surveyErrorMessage: types.maybeNull(types.string),
    liveSurveys: types.maybe(SurveyListModel),
    scheduledSurveys: types.maybe(SurveyListModel),
    unscheduledSurveys: types.maybe(SurveyListModel),
    completedSurveys: types.maybe(SurveyListModel),
    selectedSurvey: types.maybeNull(Survey),
    surveyAssignDetails: types.frozen(),
    originalSurvey: types.maybeNull(Survey),
    startSurveyPlay: types.maybeNull(Survey),
    selectedSurveyId: types.maybe(types.number),
    assignedSurveys: types.array(AssignedSurvey),
    availableUsers: types.array(SurveyUser),
    availableGroups: types.array(SurveyGroup),
    flowCache: types.map(types.map(FlowCacheModel)),
  })
  .actions((self) => ({
    afterCreate() {
      useFlowCacheMiddleware({ store: self, cacheable: ACTIONS_TO_CACHE })
    },
    setStatus(value?: 'idle' | 'pending' | 'done' | 'error') {
      self.status = value || 'idle'
    },
    setLiveSurveys(data: typeof self.liveSurveys) {
      self.liveSurveys = data
    },
    setUnscheduledSurveys(data: typeof self.unscheduledSurveys) {
      self.unscheduledSurveys = cast(data)
    },
    setScheduledSurveys(data: typeof self.scheduledSurveys) {
      self.scheduledSurveys = data
    },
    setCompletedSurveys(data: typeof self.completedSurveys) {
      self.completedSurveys = data
    },
    setSurveyAssignDetails(data: any) {
      self.surveyAssignDetails = data
    },
    setSelectedSurvey(data: typeof self.selectedSurvey) {
      self.selectedSurvey = data
    },
    getSelectedSurvey() {
      return self.selectedSurvey
    },
    setOriginalSurvey(data: typeof self.selectedSurvey) {
      self.originalSurvey = data
    },
    setSelectedSurveyId(data: number) {
      self.selectedSurveyId = data
    },
    setAvailableUsers(data: typeof self.availableUsers) {
      self.availableUsers = data
    },
    setAvailableGroups(data: typeof self.availableGroups) {
      self.availableGroups = data
    },
    resetOriginalSurvey() {
      if (self.originalSurvey) {
        self.selectedSurvey = clone(self.originalSurvey)
      }
    },
    setAssignedSurveys(data: typeof self.assignedSurveys) {
      self.assignedSurveys = [] as any
      self.assignedSurveys = data
    },
    setStartSurveyPlay(data: typeof self.startSurveyPlay) {
      self.startSurveyPlay = data
    },
    setSurveyErrorMessage(data: typeof self.surveyErrorMessage) {
      self.surveyErrorMessage = data
    },
  }))
  .views((self) => ({
    get environment() {
      return getEnv(self) as Environment
    },
    get isLoading() {
      return self.status === 'pending'
    },
    get rootStore(): any {
      return getRoot(self)
    },
  }))
  .actions((self) => ({
    getSurveys: flow(function* (filter: 'live' | 'unscheduled' | 'scheduled' | 'completed') {
      self.setStatus('pending')
      try {
        const result: Awaited<ReturnType<typeof SurveyApi.getSurveys>> =
          yield SurveyApi.getSurveys(filter)
        if (result.ok) {
          switch (filter) {
            case 'live':
              self.setLiveSurveys(result.data)
              break
            case 'scheduled':
              self.setScheduledSurveys(result.data)
              break
            case 'unscheduled':
              self.setUnscheduledSurveys(result.data)
              break
            case 'completed':
              self.setCompletedSurveys(result.data)
              break
            default:
              break
          }
          self.setStatus('done')
        } else {
          self.setStatus('error')
        }
      } catch {
        self.setStatus('error')
      }
    }),
    getSurveyDetails: flow(function* (id: number) {
      self.setStatus('pending')
      try {
        const result: Awaited<ReturnType<typeof SurveyApi.getSurveys>> =
          yield SurveyApi.getSurvey(id)
        if (result.ok) {
          const survey = Survey.create(result.data)
          self.setSelectedSurvey(survey)
          self.setOriginalSurvey(survey)
          self.setSelectedSurveyId(result.data.id)
          self.setStatus('done')
        } else {
          self.setStatus('error')
        }
      } catch (err) {
        self.setStatus('error')
      }
      return self.selectedSurvey
    }),
    getSurveyStats: flow(function* (id: number) {
      self.setStatus('pending')
      try {
        const result: Awaited<ReturnType<typeof SurveyApi.getSurveyStats>> =
          yield SurveyApi.getSurveyStats(id)
        if (result.ok) {
          self.setSelectedSurvey({ ...self.selectedSurvey, ...result.data })
          self.setOriginalSurvey({ ...self.originalSurvey, ...result.data })
          self.setStatus('done')
        } else {
          self.setStatus('error')
        }
      } catch (err) {
        self.setStatus('error')
      }
    }),
    getSurveyQuestionStats: flow(function* (id: number) {
      self.setStatus('pending')
      try {
        const result: Awaited<ReturnType<typeof SurveyApi.getSurveyQuestionStats>> =
          yield SurveyApi.getSurveyQuestionStats(id)
        if (result.ok) {
          self.selectedSurvey?.questions?.forEach((question, index: number) => {
            const responseQuestion = result.data.questions.find(
              (q: Instance<typeof SurveyQuestion>) => q.id === question.id,
            )
            if (responseQuestion) {
              self.selectedSurvey?.setQuestion({ ...question, ...responseQuestion }, index)
            }
          })
          self.setSelectedSurvey(self.selectedSurvey)
          self.setOriginalSurvey(self.selectedSurvey)
          self.setStatus('done')
        } else {
          self.setStatus('error')
        }
      } catch (err) {
        self.setStatus('error')
      }
    }),
    duplicateSurvey: flow(function* (ids: Instance<typeof GeneralSurveyPayload>) {
      self.setStatus('pending')
      try {
        const result: Awaited<ReturnType<typeof SurveyApi.duplicateSurvey>> =
          yield SurveyApi.duplicateSurvey(ids)
        if (result.ok) {
          self.setStatus('done')
        } else {
          self.setStatus('error')
        }
      } catch (e) {
        if (e instanceof Error) {
          self.setSurveyErrorMessage(e?.message || 'Unexpected error ocurred')
        } else {
          const err = e as GeneralApiProblem
          if (err?.kind === 'rejected')
            self.setSurveyErrorMessage(err.message || 'Unexpected error ocurred')
        }
        self.setStatus('error')
      }
    }),
    deleteSurvey: flow(function* (ids: Instance<typeof GeneralSurveyPayload>) {
      self.setStatus('pending')
      try {
        const result: Awaited<ReturnType<typeof SurveyApi.deleteSurvey>> =
          yield SurveyApi.deleteSurvey(ids)
        if (result.ok) {
          self.setStatus('done')
        } else {
          self.setStatus('error')
        }
      } catch {
        self.setStatus('error')
      }
    }),
    endSurvey: flow(function* (ids: Instance<typeof GeneralSurveyPayload>) {
      self.setStatus('pending')
      try {
        const result: Awaited<ReturnType<typeof SurveyApi.endSurvey>> =
          yield SurveyApi.endSurvey(ids)
        if (result.ok) {
          self.setStatus('done')
        } else {
          self.setStatus('error')
        }
      } catch {
        self.setStatus('error')
      }
    }),
    startSurvey: flow(function* (ids: Instance<typeof GeneralSurveyPayload>) {
      self.setStatus('pending')
      try {
        const result: Awaited<ReturnType<typeof SurveyApi.startSurvey>> =
          yield SurveyApi.startSurvey(ids)
        if (result.ok) {
          self.setStatus('done')
        } else {
          self.setStatus('error')
        }
      } catch {
        self.setStatus('error')
      }
    }),
    nudgeSurvey: flow(function* (ids: Instance<typeof GeneralSurveyPayload>) {
      self.setStatus('pending')
      try {
        const result: Awaited<ReturnType<typeof SurveyApi.nudge>> = yield SurveyApi.nudge(ids)
        if (result.ok) {
          self.setStatus('done')
        } else {
          self.setStatus('error')
        }
      } catch {
        self.setStatus('error')
      }
    }),
    createSurvey: flow(function* (survey: Instance<typeof Survey>) {
      self.setStatus('pending')
      try {
        const result: Awaited<ReturnType<typeof SurveyApi.createSurvey>> =
          yield SurveyApi.createSurvey(survey)
        if (result.ok && result.data) {
          self.setSelectedSurveyId(result.data.id)
          self.setStatus('done')
        } else {
          self.setStatus('error')
        }
      } catch {
        self.setStatus('error')
      }
    }),
    getSurvey: flow(function* (survey_id: number) {
      self.setStatus('pending')
      try {
        const result: Awaited<ReturnType<typeof SurveyApi.getSurvey>> =
          yield SurveyApi.getSurvey(survey_id)
        if (result.ok) {
          self.setSelectedSurvey(result.data)
          self.setStatus('done')
          return result.data
        } else {
          self.setStatus('error')
        }
      } catch {
        self.setStatus('error')
      }
    }),
    extendSurvey: flow(function* (payload: Instance<typeof ExtendSurveyPayload>) {
      self.setStatus('pending')
      try {
        const result: Awaited<ReturnType<typeof SurveyApi.extendSurvey>> =
          yield SurveyApi.extendSurvey(payload)
        if (result.ok) {
          self.setStatus('done')
        } else {
          self.setStatus('error')
        }
      } catch {
        self.setStatus('error')
      }
    }),
    updateSurvey: flow(function* (survey: Instance<typeof Survey>) {
      self.setStatus('pending')
      try {
        const result: Awaited<ReturnType<typeof SurveyApi.updateSurvey>> =
          yield SurveyApi.updateSurvey(survey)
        if (result.ok) {
          self.setStatus('done')
        } else {
          self.setStatus('error')
        }
      } catch {
        self.setStatus('error')
      }
    }),
    updateSurveyAssignees: flow(function* (
      survey_id: number,
      data: Instance<typeof SurveyAssignPayload>,
    ) {
      self.setStatus('pending')
      try {
        const result: Awaited<ReturnType<typeof SurveyApi.updateSurveyAssignees>> =
          yield SurveyApi.updateSurveyAssignees(survey_id, data)
        if (result.ok) {
          return result.data
        } else {
          self.setStatus('error')
        }
      } catch {
        self.setStatus('error')
      }
    }),
    getAvailableUsers: flow(function* () {
      self.setStatus('pending')
      try {
        if (self.selectedSurveyId) {
          const result: Awaited<ReturnType<typeof SurveyApi.getAvailableUsers>> =
            yield SurveyApi.getAvailableUsers(self.selectedSurveyId)
          if (result.ok) {
            self.setAvailableGroups(result.data.groups)
            self.setAvailableUsers(result.data.users)
          } else {
            self.setStatus('error')
          }
        }
      } catch {
        self.setStatus('error')
      }
    }),
    getSurveyAssignDetails: flow(function* (survey_id: number) {
      self.setStatus('pending')
      try {
        const result: Awaited<ReturnType<typeof SurveyApi.getSurveyAssignDetails>> =
          yield SurveyApi.getSurveyAssignDetails(survey_id)
        if (result.ok) {
          self.setSurveyAssignDetails(result.data)
          self.setStatus('done')
          return result.data
        } else {
          self.setStatus('error')
        }
      } catch {
        self.setStatus('error')
      }
    }),
    postSurveyAssignDetails: flow(function* (
      survey_id: number,
      data: PostSurveyAssignDetailsRequestType,
    ) {
      self.setStatus('pending')
      try {
        const result: Awaited<ReturnType<typeof SurveyApi.postSurveyAssignDetails>> =
          yield SurveyApi.postSurveyAssignDetails(survey_id, data)
        if (result.ok) {
          self.setStatus('done')
          return result.data
        } else {
          self.setStatus('error')
        }
        // eslint-disable-next-line prettier/prettier
      } catch (e) {
        // @ts-ignore
        self.setSurveyErrorMessage(e.message)
        self.setStatus('error')
      }
    }),
    getAssignedSurveysForUser: flow(function* (user_Id: number) {
      self.setStatus('pending')
      try {
        const result: Awaited<ReturnType<typeof SurveyApi.getAssignedSurveysOfUser>> =
          yield SurveyApi.getAssignedSurveysOfUser(user_Id)

        if (result.ok) {
          self.setAssignedSurveys(cast(result.data))
          self.setStatus('done')
        } else {
          self.setStatus('error')
        }
      } catch (e) {
        self.setStatus('error')
      }
    }),
    startSelectedSurvey: flow(function* (survey_id: number, user_id: number) {
      self.setStatus('pending')
      try {
        const result: Awaited<ReturnType<typeof SurveyApi.postStartSurvey>> =
          yield SurveyApi.postStartSurvey(user_id, survey_id)
        if (result.ok) {
          self.setStartSurveyPlay({ ...result.data, id: result.data.survey_id })
          self.setStatus('done')
          return result.data
        } else {
          self.setStatus('error')
        }
      } catch (e) {
        console.log(`e`, e)
        self.setStatus('error')
      }
    }),
    updateSurveyDates: flow(function* (payload: Instance<typeof SurveySchedulePayload>) {
      self.setStatus('pending')
      try {
        const result: Awaited<ReturnType<typeof SurveyApi.updateSurveyDates>> =
          yield SurveyApi.updateSurveyDates(payload)

        if (result.ok) {
          self.setStatus('idle')
        } else {
          self.setStatus('error')
        }
      } catch (e) {
        console.log(`e`, e)
        self.setStatus('error')
      }
    }),
    postSubmitSurveyResponses: flow(function* (payload: SurveySubmitRequestModelType) {
      self.setStatus('pending')
      try {
        const result: Awaited<ReturnType<typeof SurveyApi.postSubmitSurveyResponses>> =
          yield SurveyApi.postSubmitSurveyResponses(payload)
        if (result.ok) {
          self.rootStore.invalidateGroup('surveys')
          self.setStatus('done')
        } else {
          self.setStatus('error')
        }
      } catch (e) {
        console.log(`e`, e)
        self.setStatus('error')
      }
    }),
  }))

type SurveyStoreType = Instance<typeof SurveyStoreModel>
export interface SurveyStore extends SurveyStoreType {}
type ManageQuizStoreSnapshotType = SnapshotOut<typeof SurveyStoreModel>
export interface ManageQuizStoreSnapshot extends ManageQuizStoreSnapshotType {}
