import React, { useEffect, useRef, useState } from 'react'
import { CircularProgress, Dialog, DialogContent, Divider, IconButton } from '@mui/material'
import { Header } from '@trivie/ui-web/components/header'
import { Text } from '@trivie/ui-web/components/text'
import { observer } from 'mobx-react-lite'
import { useStores } from '@trivie/core'
import plansData from './data.json'
import { Elements } from '@stripe/react-stripe-js'
import { loadStripe } from '@stripe/stripe-js'
import { color } from '@trivie/ui-web/theme'

import CheckoutForm from './CheckoutForm'
import './checkout.css'

// Make sure to call `loadStripe` outside of a component’s render to avoid
// recreating the `Stripe` object on every render.
const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PK!)

import { UserApi } from '@trivie/core/src/services/api-objects'
import { useLocation } from 'react-router-dom'
import { Check, Close } from '@mui/icons-material'
import { PlanCard, PlanCardProps } from './plan-card'
import { FeatureGrid } from './feature-grid'
import { Switch } from '@trivie/ui-web/components/switch/switch'

function useQuery() {
  const { search } = useLocation()

  return React.useMemo(() => new URLSearchParams(search), [search])
}

export const UpgradeScreen = observer((props: any) => {
  const { manageUserStore } = useStores()
  const { getSettings, settings } = manageUserStore

  const [open, setOpen] = useState(false)
  const [stripeOptions, setStripeOptions] = useState<any>({
    mode: 'subscription',
    currency: 'usd',
  })
  const [selectedPrice, setSelectedPrice] = useState<any>()

  const divPlansRef = useRef(null)

  const onClose = () => {
    setOpen(false)
  }

  const businessPlans = [
    {
      title: 'Free',
      subtitle: 'Forever',
      planColor: '#3254F4',
      monthlyPrice: 0,
      annualPrice: 0,
      includesItems: plansData.freePlan.includesItems,
      featureItems: plansData.freePlan.featureItems,
      onClick: () => {},
    },
    {
      title: 'Standard',
      subtitle: 'Individuals and small teams',
      planColor: '#885FFC',
      monthlyPrice: 49,
      annualPrice: 40,
      includesItems: plansData.standardPlan.includesItems,
      featureItems: plansData.standardPlan.featureItems,
      onClick: () => {
        setStripeOptions({
          ...stripeOptions,
          amount: annual ? billing.prices[2].amount : billing.prices[3].amount,
        })
        setSelectedPrice(annual ? billing.prices[2] : billing.prices[3])
        setOpen(true)
      },
    },
    {
      title: 'Pro',
      subtitle: 'For teams and growing businesses',
      planColor: '#551BFA',
      monthlyPrice: 99,
      annualPrice: 79,
      includesItems: plansData.proPlan.includesItems,
      featureItems: plansData.proPlan.featureItems,
      onClick: () => {
        setStripeOptions({
          ...stripeOptions,
          amount: annual ? billing.prices[0].amount : billing.prices[1].amount,
        })
        setSelectedPrice(annual ? billing.prices[0] : billing.prices[1])
        setOpen(true)
      },
      isPopular: true,
    },
    {
      title: 'Enterprise',
      subtitle: 'For large businesses',
      planColor: '#3354F4',
      includesItems: plansData.enterprisePlan.includesItems,
      featureItems: plansData.enterprisePlan.featureItems,
      onClick: () => {},
    },
  ] as PlanCardProps[]

  const query = useQuery()
  const final = query.get('redirect_status') === 'succeeded'

  useEffect(() => {
    if (final) {
      setOpen(true)
    }
    init()
  }, [])

  const [billing, setBilling] = useState<any>()
  const [currentPlan, setCurrentPlan] = useState<any>()
  const [annual, setAnnual] = useState<any>(false)
  const [loading, setLoading] = useState<any>(false)

  const init = async () => {
    setLoading(true)
    getSettings().then(() => {
      UserApi.getBilling().then((resp) => {
        const c = businessPlans.find((plan) => plan.title?.toLowerCase() === settings.plan_tier)

        if (resp.data.plan_tier === settings.plan_tier) {
          setTimeout(() => {
            getSettings().then(() => {
              const current = businessPlans.find(
                (plan) => plan.title?.toLowerCase() === settings.plan_tier,
              )
              setCurrentPlan(current)
            })
          }, 1200)
        }
        setLoading(false)
        setCurrentPlan(c)
        setBilling(resp.data)
        setOpen(false)
      })
    })
  }

  return loading ? null : (
    <div style={{ paddingBottom: 32 }}>
      {open && (
        <Dialog open={open}>
          <DialogContent style={{ ...CHECKOUT, minHeight: final ? 0 : 605 }}>
            {final ? (
              <div style={COMPLETED}>
                <Text
                  style={{ textAlign: 'center', color: color.shade70, marginBottom: 40 }}
                  variant="label"
                  text="We are working on upgrading your workspace."
                />
                <CircularProgress />
              </div>
            ) : (
              <>
                <div style={{ display: 'flex', flexDirection: 'column', marginBottom: 32 }}>
                  <Text
                    style={{ textAlign: 'center', marginBottom: 16 }}
                    variant="headline"
                    text={`Upgrade To ${selectedPrice?.name.includes('Pro') ? 'Pro' : 'Standard'}`}
                  />
                  <Text
                    style={{ textAlign: 'center', color: color.shade70 }}
                    variant="label"
                    text={`Instant access to all Trivie ${
                      selectedPrice?.name.includes('Pro') ? 'Pro' : 'Standard'
                    } features`}
                  />
                </div>
                <IconButton
                  style={{ position: 'absolute', right: 8, top: 8 }}
                  onClick={onClose}
                  size="large"
                >
                  <Close htmlColor={color.shade50} style={{ fontSize: 22 }} />
                </IconButton>
                {stripePromise && (
                  <Elements options={stripeOptions} stripe={stripePromise}>
                    <CheckoutForm customer_id={billing.customer_id} price={selectedPrice} />
                  </Elements>
                )}
              </>
            )}
          </DialogContent>
        </Dialog>
      )}
      <Header
        title="Upgrade"
        primaryAction={{
          text: 'Chat With Sales',
          onClick: () => {},
        }}
      />
      <Divider style={{ marginBottom: 32 }} />
      <Text text="Level up your learning" style={TAGLINE} />
      <div style={{ textAlign: 'center', marginBottom: 32 }}>
        <Text
          variant="body2Medium"
          text={
            settings.plan_tier === 'free'
              ? 'Start free today, no credit card required.'
              : settings.plan_tier === 'standard' || settings.plan_tier === 'pro'
                ? 'Upgrade your plan to scale up your learning.'
                : settings.plan_tier === 'enterprise'
                  ? 'Contact your salesperson to adjust your plan.'
                  : ''
          }
        />
      </div>
      <div style={ANNUAL_SWITCH}>
        <Text style={{ marginRight: 16 }} text="Monthly" variant="labelEmphasized" />
        <Switch
          color="success"
          checked={Boolean(annual)}
          onChange={(e) => setAnnual(e.target.checked)}
          size="small"
        />
        <Text style={{ marginLeft: 16 }} text="Annually" variant="labelEmphasized" />
      </div>
      <div ref={divPlansRef} style={PLANS}>
        {businessPlans?.map((item) => {
          return settings.plan_tier !== 'free' && item.title?.toLowerCase() === 'free' ? null : (
            <div style={{ position: 'relative' }}>
              {item.isPopular ? (
                <div style={{ ...POPULAR_TAG, backgroundColor: item.planColor }}>
                  <Text
                    variant="body1Emphasized"
                    style={{ color: color.white }}
                    text="Most Popular"
                  />
                </div>
              ) : null}
              <PlanCard
                current={settings.plan_tier?.toLowerCase() === item.title?.toLowerCase()}
                title={item.title!}
                subtitle={item.subtitle!}
                monthlyPrice={item.monthlyPrice}
                annualPrice={item.annualPrice}
                onClick={item.onClick}
                planColor={item.planColor}
                includesItems={item.includesItems}
                featureItems={item.featureItems}
                annual={annual}
                isPopular={item.isPopular}
              />
            </div>
          )
        })}
      </div>
      <FeatureGrid />
    </div>
  )
})

const POPULAR_TAG: React.CSSProperties = {
  display: 'flex',
  width: '180px',
  padding: '8px 12px',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  gap: '12px',
  top: -16,
  left: 70,
  position: 'absolute',
  zIndex: 999,
  borderRadius: 100,
}
const PLANS: React.CSSProperties = {
  position: 'relative',
  display: 'flex',
  justifyContent: 'center',
  gap: 40,
  marginBottom: 32,
}
const ANNUAL_SWITCH: React.CSSProperties = {
  flexDirection: 'row',
  marginBottom: 48,
  display: 'flex',
  justifyContent: 'center',
}
const TAGLINE: React.CSSProperties = {
  textAlign: 'center',
  lineHeight: '',
  marginBottom: 32,
  fontSize: 36,
  fontWeight: 700,
}
const CHECKOUT: React.CSSProperties = {
  padding: 20,
  width: 550,
  flex: 1,
  display: 'flex',
  flexDirection: 'column',
}
const COMPLETED: React.CSSProperties = {
  flexDirection: 'column',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
}
