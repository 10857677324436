import { types, getEnv, flow, getRoot, Instance } from 'mobx-state-tree'
import { Environment } from '../environment'
import { UserApi } from '../../services/api-objects/UserApi'
import { Awaited } from '../../utility-types'
import { PreQuiz, QuizResource } from '../UserQuiz'
import { QuizScoreboard, QuizScoreboardRecall } from '../QuizScoreboard'
import { UserAdaptive } from '../UserAdaptive'
import { FlowCacheModel, useFlowCacheMiddleware } from '../../middleware'
import { DateTime } from 'luxon'
import { GetLimitStatus } from '../response-models'
import { GameApi } from '../../services/api-objects'

const ACTIONS_TO_CACHE = {
  getAdaptive: { wait: 30 },
}
export const QuizStoreModel = types
  .model('QuizStore')
  .props({
    status: types.optional(
      types.enumeration(['idle', 'pending', 'pending-adaptive', 'done', 'error', 'ready']),
      'idle',
    ),
    quizInstance: types.frozen(),
    quizDetails: types.maybe(PreQuiz),
    result: types.frozen(),
    adaptive: types.maybe(UserAdaptive),
    scoreboardOfQuiz: types.maybe(QuizScoreboard),
    scoreboardRecallOfQuiz: types.maybe(QuizScoreboardRecall),
    loadingResults: types.maybe(types.boolean),
    loadingPostQuiz: types.maybe(types.boolean),
    history: types.array(types.frozen()),
    tutorialAnswers: types.frozen([]),
    showTutorial: types.frozen(false),
    completed: types.maybe(types.boolean),
    quizSearchTerm: types.maybe(types.string),
    flowCache: types.map(types.map(FlowCacheModel)),
    limitStatus: types.maybe(GetLimitStatus),
    answers: types.frozen(),
  })
  .actions((self) => ({
    afterCreate() {
      useFlowCacheMiddleware({ store: self, cacheable: ACTIONS_TO_CACHE })
    },
    addAnswer(answer) {
      self.answers = [...self.answers, answer]
    },
    resetAnswers() {
      self.answers = []
    },
    setStatus(value?: 'idle' | 'pending' | 'done' | 'error' | 'ready' | 'pending-adaptive') {
      self.status = value || 'idle'
    },
    setLoadingResults(loading: boolean) {
      self.loadingResults = loading
    },
    setLoadingPostQuiz(loading: boolean) {
      self.loadingPostQuiz = loading
    },
    setCompleted(status: boolean) {
      self.completed = status
    },
    setScoreboardOfQuiz(score: typeof self.scoreboardOfQuiz) {
      self.scoreboardOfQuiz = score
    },
    setScoreboardRecallOfQuiz(score: Instance<typeof QuizScoreboardRecall> | undefined) {
      self.scoreboardRecallOfQuiz = score
    },
    setShowTutorial(data: any) {
      self.showTutorial = data as any
    },
    setTutorialAnswer(answer: any) {
      self.tutorialAnswers = [...self.tutorialAnswers, answer] as any
    },
    clearTutorialAnswers() {
      self.tutorialAnswers = [] as any
    },
    setGame(data: any) {
      self.quizInstance = data as any
    },
    setQuizDetails(data: any) {
      self.quizDetails = data
    },
    setResult(data: any) {
      self.result = data as any
    },
    setQuizHistory(data: any) {
      self.history = data as any
    },
    setQuizSearchTerm(term: string) {
      self.quizSearchTerm = term
    },
    setLimitStatus(val: typeof self.limitStatus) {
      self.limitStatus = val
    },
  }))
  .views((self) => ({
    get environment() {
      return getEnv(self) as Environment
    },
    get isLoading() {
      return self.status === 'pending'
    },
    get isLoadingAdaptive() {
      return self.status === 'pending-adaptive'
    },
    get rootStore(): any {
      return getRoot(self)
    },
    get requisite(): any {
      return self.quizDetails?.resources?.filter((resource: Instance<typeof QuizResource>) => {
        return resource.is_required
      })[0] as Instance<typeof QuizResource>
    },
  }))
  .actions((self) => ({
    getScoreboardForQuiz: flow(function* (
      result_id: number,
      user_id: number,
      topic_id: number | null,
    ) {
      self.setStatus('pending')
      self.setScoreboardOfQuiz(undefined)
      try {
        const result: Awaited<ReturnType<typeof UserApi.getScoreboardForQuiz>> =
          yield UserApi.getScoreboardForQuiz(
            result_id.toString(),
            user_id.toString(),
            topic_id ? String(topic_id) : null,
          )
        if (result.ok && result.data) {
          self.setScoreboardOfQuiz(result.data)
          self.setStatus('done')
        } else {
          self.setStatus('error')
        }
      } catch (err) {
        self.setStatus('error')
        throw new Error('Error retrieving scoreboard.')
      }
    }),
    getScoreboardRecallForQuiz: flow(function* (
      result_id: number,
      user_id: number,
      topic_id: number | null,
    ) {
      self.setStatus('pending')
      // self.setScoreboardRecallOfQuiz(undefined)
      try {
        const result: Awaited<ReturnType<typeof UserApi.getScoreboardRecallForQuiz>> =
          yield UserApi.getScoreboardRecallForQuiz(
            result_id.toString(),
            user_id.toString(),
            topic_id ? String(topic_id) : null,
          )
        if (result.ok && result.data) {
          self.setScoreboardRecallOfQuiz(result.data)
          self.setStatus('done')
        } else {
          self.setStatus('error')
        }
      } catch (err) {
        self.setStatus('error')
        throw new Error('Error retrieving scoreboard recall.')
      }
    }),
    getQuizHistory: flow(function* (id: number) {
      self.setStatus('pending')
      try {
        const result = yield self.environment.api.getQuizHistory(id)
        if (result.kind === 'ok') {
          self.setQuizHistory(result.data)
          self.setStatus('done')
        } else {
          self.setStatus('error')
        }
      } catch {
        self.setStatus('error')
      }
    }),
    getQuizDetails: flow(function* (userID: number, metadata) {
      self.setStatus('pending')
      try {
        const result = yield self.environment.api.getQuizDetails(userID, metadata)
        if (result.kind === 'ok') {
          let formatted = { ...result.data }

          if (!result.data?.due_date && result.data?.end_date) {
            formatted = { ...result.data, due_date: result.data.end_date }
          } else if (result.data?.due_date && result.data?.end_date) {
            const due = DateTime.fromISO(result.data?.due_date)
            const end = DateTime.fromISO(result.data?.end_date)
            if (due > end) {
              formatted = { ...result.data, due_date: result.data?.end_date }
            }
          }
          self.setQuizDetails(formatted)
          self.setStatus('done')
          return formatted
        } else {
          self.setStatus('error')
        }
      } catch (e) {
        self.setStatus('error')
        return e
      }
    }),
    getPostQuiz: flow(function* (resultId: number, userId: number, topicId: any) {
      self.setStatus('pending')
      self.setResult(undefined)
      try {
        const result = yield self.environment.api.getPostQuiz(resultId, userId, topicId)
        if (result.kind === 'ok') {
          self.setResult(result.data)
          self.setStatus('ready')
          return result.data
        } else {
          self.setStatus('error')
          return { error: true }
        }
      } catch (e) {
        self.setStatus('error')
        throw new Error('Error retrieving quiz results.')
      }
    }),
    startAdaptive: flow(function* (topicID?: number) {
      self.setStatus('pending')
      try {
        const result = yield self.environment.api.startAdaptive(topicID)
        if (result.kind === 'ok') {
          self.setGame(result.data)
          self.setStatus('done')
          return result.data
        } else {
          self.setStatus('error')
        }
      } catch {
        self.setStatus('error')
      }
    }),
    getAdaptive: flow(function* (userId: number) {
      try {
        self.setStatus('pending-adaptive')
        const result = yield self.environment.api.getAdaptive(userId)
        if (result.kind === 'ok') {
          self.adaptive = { ...result.data, adaptive: true }
          self.setStatus('done')
        } else {
          self.setStatus('error')
        }
      } catch {
        self.setStatus('error')
      }
    }),
    startQuiz: flow(function* (quizId: number, metadata) {
      self.setStatus('pending')
      try {
        const result = yield self.environment.api.startQuiz(quizId, metadata)
        if (result.kind === 'ok') {
          self.setGame(result.data)
          self.setResult(result.data.result)
          self.setStatus('done')
          return result.data
        } else {
          self.setStatus('error')
        }
      } catch {
        self.setStatus('error')
      }
    }),
    startAssessment: flow(function* (quizId: number, metadata) {
      self.setStatus('pending')
      try {
        const result = yield self.environment.api.startAssessment(quizId, metadata)
        if (result.kind === 'ok') {
          self.setGame(result.data)
          self.setResult(result.data.result)
          self.setStatus('done')
          return result.data
        }
      } catch {
        self.setStatus('error')
      }
    }),
    exit: flow(function* (metadata, resultID) {
      self.setStatus('pending')
      self.setLoadingResults(true)
      try {
        const result: Awaited<ReturnType<typeof GameApi.submitAnswers>> =
          yield GameApi.submitAnswers({
            answers: self.answers,
            ...metadata,
            result_id: Number(resultID),
          })

        if (result.ok) {
          self.setStatus('done')
        } else {
          self.setStatus('error')
        }
        self.rootStore.invalidateGroup('content')
        self.setLoadingResults(false)
      } catch {
        self.setStatus('error')
      }
    }),
    submitAnswer: flow(function* (
      result_id: number,
      answer_time: number,
      question_id: number,
      choices: any[],
      metadata,
      completed?: boolean,
    ) {
      self.setStatus('pending')
      self.setLoadingResults(true)
      try {
        const payload = { answer_time, question_id, choices }

        self.addAnswer(payload)

        if (completed) {
          const result: Awaited<ReturnType<typeof GameApi.submitAnswers>> =
            yield GameApi.submitAnswers({
              answers: self.answers,
              ...metadata,
              result_id,
            })

          if (result.ok) {
            self.setStatus('done')
          } else {
            self.setStatus('error')
          }
          self.rootStore.invalidateGroup('content')
          self.setLoadingResults(false)
        }
      } catch {
        if (completed) {
          self.rootStore.invalidateGroup('content')
          self.setLoadingResults(false)
        }
        self.setStatus('error')
      }
    }),
    getLimitStatus: flow(function* () {
      try {
        const result = yield self.environment.api.getLimitStatus()
        if (result.kind === 'ok') {
          self.setLimitStatus(result.data)
          return result.data
        }
      } catch {
        self.setStatus('error')
      }
    }),
  }))

type QuizStoreType = typeof QuizStoreModel.Type
export interface QuizStore extends QuizStoreType {}
type QuizStoreSnapshotType = typeof QuizStoreModel.SnapshotType
export interface QuizStoreSnapshot extends QuizStoreSnapshotType {}
