import { observer } from 'mobx-react-lite'
import React, { useEffect } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { AppBar, Box } from '@mui/material'
import styled from '@emotion/styled'

import { QuestionForm } from '@trivie/ui-web/components/question-form'
import { useStores } from '@trivie/core'
import { QuestionHelper } from '@trivie/core/src/helpers/QuestionHelper'

import ContentCreationHeader from './content-creation-header'
import { QuestionCardFooter } from './components/question-card-footer'

export const CreateQuestionScreen: React.FunctionComponent = observer(() => {
  const { contentCreationStore } = useStores()
  const { setQuestion, question } = contentCreationStore
  const { questionId } = useParams<any>()
  const history = useHistory()

  const handleBack = () => {
    history.push(
      questionId?.includes('temp') ? '/content/questions' : `/content/questions/${questionId}`,
    )
  }

  useEffect(() => {
    if (!questionId?.includes('temp')) {
      QuestionHelper.loadQuestion(questionId).then((q) => {
        setQuestion(q)
      })
    } else {
      const newQuestion = QuestionHelper.createNewQuestion()
      setQuestion(newQuestion)
    }
  }, [])

  if (!question) {
    return null
  }

  return (
    <Box sx={ROOT}>
      <AppBar position="fixed" elevation={0} sx={HEADER}>
        <div style={{ height: 70 }}>
          <ContentCreationHeader onBack={handleBack} publishData={question} titleHeader={<div />} />
        </div>
      </AppBar>
      <Box component="div" sx={MAIN}>
        <DrawerHeader />
        <div style={CONTENT}>
          <div style={CARD_CONTAINER}>
            <div style={CARD}>
              <div style={QUESTION_FORM}>
                <QuestionForm question={question} />
              </div>
              <QuestionCardFooter
                onSelectionChange={() => {}}
                mercuryEnabled={false}
                question={question}
              />
            </div>
          </div>
        </div>
      </Box>
    </Box>
  )
})

const DrawerHeader = styled('div')(({ theme }: any) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  justifyContent: 'flex-end',
  minHeight: '70px !important',
}))

const ROOT: React.CSSProperties = {
  width: '100%',
  display: 'flex',
  height: '100%',
  position: 'absolute',
  top: '0px',
  left: '0px',
  background: '#F4F4F4',
}
const MAIN: React.CSSProperties = {
  flexGrow: 1,
  overflowY: 'hidden',
}
const HEADER: React.CSSProperties = {
  backgroundColor: '#fff',
  minHeight: '70px !important',
  borderRadius: 0,
  zIndex: 1300,
}
const CARD_CONTAINER: React.CSSProperties = {
  width: '100%',
  height: 'calc(100% - 60px)',
  display: 'flex',
  justifyContent: 'center',
}
const CONTENT: React.CSSProperties = {
  height: 'calc(100% - 70px)',
  display: 'flex',
  flexDirection: 'row',
}
const CARD: React.CSSProperties = {
  position: 'relative',
  width: '100%',
  display: 'flex',
  justifyContent: 'center',
}
const QUESTION_FORM: React.CSSProperties = {
  width: '100%',
  height: '100%',
  padding: 24,
  overflowY: 'auto',
  display: 'flex',
  justifyContent: 'center',
}
