import { types, getEnv, Instance, SnapshotOut, clone, flow, cast } from 'mobx-state-tree'
import { Environment } from '../environment'
import { Awaited } from '../../utility-types'
import { MessagesApi } from '../../services/api-objects/MessagesApi'
import { Message, ScheduledSentMessage } from '../Message'
import { MessagePayload } from '../MessagePayload'
import {
  GroupDetailRecipient,
  GroupRecipient,
  MessageRecipients,
  UserDetailRecipient,
  UserRecipient,
} from '../MessageRecipients'

export const MessageStoreModel = types
  .model('MessageStore')
  .props({
    status: types.optional(types.enumeration(['idle', 'pending', 'done', 'error']), 'idle'),
    allMessages: types.array(Message),
    scheduledMessages: types.array(Message),
    sentMessages: types.array(Message),
    recipients: types.maybe(MessageRecipients),
    selectedMessageID: types.maybe(types.number),
    selectedMessage: types.maybe(ScheduledSentMessage),
    editing: types.maybe(types.boolean),
    reviewing: types.maybe(types.boolean),
    currentPage: types.optional(types.number, 1),
    lastPage: types.optional(types.number, 1),
    nextPage: types.optional(types.maybeNull(types.number), 1),
    previousPage: types.optional(types.maybeNull(types.number), 1),
    total: types.optional(types.number, 0),
    triggerRefresh: types.frozen(),
  })
  .actions((self) => ({
    setStatus(value?: 'idle' | 'pending' | 'done' | 'error') {
      self.status = value || 'idle'
    },
    setTriggerRefresh(status: any) {
      self.triggerRefresh = status
    },
    setTotal(total: number) {
      self.total = total
    },
    setCurrentPage(page: typeof self.currentPage) {
      self.currentPage = page
    },
    setNextPage(page: typeof self.nextPage) {
      self.nextPage = page
    },
    setLastPage(page: typeof self.lastPage) {
      self.lastPage = page
    },
    setPreviousPage(page: typeof self.previousPage) {
      self.previousPage = page
    },
    appendAllMessages(data: typeof self.allMessages) {
      self.allMessages.push(...data)
    },
    appendSentMessages(data: typeof self.allMessages) {
      self.sentMessages.push(...data)
    },
    appendScheduledMessages(data: typeof self.allMessages) {
      self.scheduledMessages.push(...data)
    },
    setAllMessages(data: typeof self.allMessages) {
      self.allMessages = data
    },
    setScheduledMessages(data: typeof self.scheduledMessages) {
      self.scheduledMessages.replace(data)
    },
    setSentMessages(data: typeof self.sentMessages) {
      self.sentMessages = data
    },
    setRecipients(data: typeof self.recipients) {
      self.recipients = data
    },
    setSelectedMessage(message: typeof self.selectedMessage) {
      self.selectedMessage = message
    },
    setSelectedMessageID(id: typeof self.selectedMessageID) {
      self.selectedMessageID = id
    },
    setEditing(editing: boolean) {
      self.editing = editing
    },
    setReviewing(reviewing: boolean) {
      self.reviewing = reviewing
    },
  }))
  .views((self) => ({
    get environment() {
      return getEnv(self) as Environment
    },
    get isLoading() {
      return self.status === 'pending'
    },
  }))
  .actions((self) => ({
    getMessages: flow(function* (
      status: 'all' | 'scheduled' | 'sent',
      page: number,
      reset?: boolean,
    ) {
      self.setStatus('pending')
      try {
        const result: Awaited<ReturnType<typeof MessagesApi.getMessages>> =
          yield MessagesApi.getMessages(status, page)
        if (result.ok) {
          self.setCurrentPage(page)
          self.setTotal(result.data.total_count)
          self.setNextPage(result.data.next_page)
          self.setLastPage(result.data.last_page)
          switch (status) {
            case 'all':
              if (reset) {
                self.setAllMessages(cast(result.data.objects))
              } else {
                self.appendAllMessages(cast(result.data.objects))
              }
              break
            case 'scheduled':
              if (reset) {
                self.setScheduledMessages(cast(result.data.objects))
              } else {
                self.appendScheduledMessages(cast(result.data.objects))
              }
              break
            case 'sent':
              if (reset) {
                self.setSentMessages(cast(result.data.objects))
              } else {
                self.appendSentMessages(cast(result.data.objects))
              }
              break
            default:
              break
          }
          self.setStatus('done')
        } else {
          self.setStatus('error')
        }
      } catch {
        self.setStatus('error')
      }
    }),
    createMessage: flow(function* (
      message: Instance<typeof MessagePayload>,
      assignmentID?: number,
    ) {
      self.setStatus('pending')
      try {
        let result: Awaited<ReturnType<typeof MessagesApi.createMessage>>

        if (message.message_type === 'survey_nudge') {
          result = yield MessagesApi.surveyNudge(message)
        } else if (message.message_type === 'nudge' && assignmentID) {
          result = yield MessagesApi.nudge(message, assignmentID)
        } else if (message.message_type === 'contest') {
          result = yield MessagesApi.createContestMessage(message)
        } else {
          result = yield MessagesApi.createMessage(message)
        }

        if (result.ok) {
          self.setTriggerRefresh(true)
          self.setStatus('done')
        } else {
          self.setStatus('error')
        }
      } catch {
        self.setStatus('error')
      }
    }),
    editMessage: flow(function* (message: Instance<typeof MessagePayload>, id: number) {
      self.setStatus('pending')
      try {
        const result: Awaited<ReturnType<typeof MessagesApi.editMessage>> =
          yield MessagesApi.editMessage(message, id)
        if (result.ok) {
          self.setStatus('done')
        } else {
          self.setStatus('error')
        }
      } catch {
        self.setStatus('error')
      }
    }),
    getAvailableRecipients: flow(function* () {
      self.setStatus('pending')
      try {
        const result: Awaited<ReturnType<typeof MessagesApi.getAvailableRecipients>> =
          yield MessagesApi.getAvailableRecipients()
        if (result.ok && result.data) {
          self.setRecipients(result.data)
          self.setStatus('done')
          return result.data
        } else {
          self.setStatus('error')
        }
      } catch {
        self.setStatus('error')
      }
    }),
    getMessage: flow(function* (id: number) {
      self.setStatus('pending')
      try {
        const result: Awaited<ReturnType<typeof MessagesApi.getMessage>> =
          yield MessagesApi.getMessage(id)
        if (result.ok && result.data) {
          const users = [] as Array<Instance<typeof UserRecipient>>
          result.data.users.map((user: Instance<typeof UserDetailRecipient>) => {
            const match = self.recipients?.users.find((u) => u.user_id === user.user_id)
            if (match) {
              users.push({ ...match })
            }
          })

          const groups = [] as Array<Instance<typeof GroupRecipient>>
          result.data.groups.map((group: Instance<typeof GroupDetailRecipient>) => {
            const match = self.recipients?.groups.find((g) => g.group_id === group.group_id)
            if (match) {
              groups.push({ ...match })
            }
          })
          const selected = { ...result.data, users, groups }
          self.setSelectedMessage(cast(selected))
          self.setStatus('done')
          return selected
        } else {
          self.setStatus('error')
        }
      } catch {
        self.setStatus('error')
      }
    }),
    deleteMessage: flow(function* (id: number) {
      self.setStatus('pending')
      try {
        const result: Awaited<ReturnType<typeof MessagesApi.deleteMessage>> =
          yield MessagesApi.deleteMessage(id)
        if (result.ok && result.data) {
          self.setTriggerRefresh(true)
          self.setStatus('done')
        } else {
          self.setStatus('error')
        }
      } catch {
        self.setStatus('error')
      }
    }),
  }))

type MessageStoreType = Instance<typeof MessageStoreModel>
export interface MessageStore extends MessageStoreType {}
type MessageStoreSnapshotType = SnapshotOut<typeof MessageStoreModel>
export interface MessageStoreSnapshot extends MessageStoreSnapshotType {}
