import { PathAssignmentsTab } from './path-assignments-tab'
import { PathOverviewTab } from './path-overview-tab'
import { PathQuestionsTab } from './path-questions-tab'
import { PathQuizzesTab } from './path-quizzes-tab'
import { PathSourcesTab } from './path-sources-tab'
import { PathTopicsTab } from './path-topics-tab'
import { PathUsersTab } from './paths-users-tab'

export const pathTabs = [
  PathOverviewTab,
  PathAssignmentsTab,
  PathTopicsTab,
  PathQuizzesTab,
  PathQuestionsTab,
  PathSourcesTab,
  PathUsersTab,
]
