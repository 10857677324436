import { flow, getEnv, types } from 'mobx-state-tree'
import { DateTime } from 'luxon'

import { ContestApi } from '../../services/api-objects/ContestApi'
import { Environment } from '../environment'

export const ContestStoreModel = types
  .model('ContestStore')
  .props({
    status: types.optional(types.enumeration(['idle', 'pending', 'done', 'error']), 'idle'),
    showCreateWizard: types.maybe(types.boolean),
    editID: types.maybe(types.number),
    showEditWizard: types.maybe(types.boolean),
    triggerRefresh: types.frozen(),
    contestDetails: types.frozen(),
    leaderboard: types.frozen(),
    scoring: types.frozen(),
    contestsForUser: types.frozen(),
    selectedContest: types.frozen(),
    howToWin: types.frozen(),
    contestActive: types.frozen(),
    showTimeout: types.frozen(),
    currentPoints: types.frozen(),
    userRank: types.frozen(),
    lastUpdated: types.frozen(),
  })
  .actions((self) => ({
    setStatus(value?: 'idle' | 'pending' | 'done' | 'error') {
      self.status = value || 'idle'
    },
    setShowTimeout(data) {
      self.showTimeout = data
    },
    setCurrentPoints(data) {
      self.currentPoints = data
    },
    setUserRank(data) {
      self.userRank = data
    },
    setLastUpdated(data) {
      self.lastUpdated = data
    },
    setHowToWin(data) {
      self.howToWin = data
    },
    setContestActive(data) {
      self.contestActive = data
    },
    setSelectedContest(contest) {
      self.selectedContest = contest
    },
    setContestDetails(data: any) {
      self.contestDetails = data
    },
    setShowCreateWizard(status: boolean) {
      self.showCreateWizard = status
    },
    setShowEditWizard(status: boolean) {
      self.showEditWizard = status
    },
    setTriggerRefresh(trigger) {
      self.triggerRefresh = trigger as any
    },
    setEditID(id) {
      self.editID = id as any
    },
    setContestLeaderboard(data) {
      self.leaderboard = data as any
    },
    setContestScoring(data) {
      self.scoring = data as any
    },
    setContestsForUser(data) {
      self.contestsForUser = data as any
    },
  }))
  .views((self) => ({
    get environment() {
      return getEnv(self) as Environment
    },
    get isLoading() {
      return self.status === 'pending'
    },
  }))
  .actions((self) => ({
    createContest: flow(function* (contest: any) {
      self.setStatus('pending')
      try {
        const result: Awaited<ReturnType<typeof ContestApi.createContest>> =
          yield ContestApi.createContest(contest)
        if (result.ok) {
          self.setTriggerRefresh(true)
          self.setStatus('done')
        } else {
          self.setStatus('error')
        }
      } catch {
        self.setStatus('error')
      }
    }),
    getContestActive: flow(function* (content_id: number, content_type: string) {
      self.setStatus('pending')
      try {
        const result: Awaited<ReturnType<typeof ContestApi.getContestActive>> =
          yield ContestApi.getContestActive({ content_id, content_type })
        if (result.ok) {
          self.setSelectedContest(result.data.contest_id)
          self.setContestActive(result.data.contest_id)
          self.setStatus('done')
          return result.data
        } else {
          self.setStatus('error')
        }
      } catch {
        self.setStatus('error')
      }
    }),
    getContest: flow(function* (id: number) {
      self.setStatus('pending')
      try {
        const result: Awaited<ReturnType<typeof ContestApi.getContest>> =
          yield ContestApi.getContest(id)
        if (result.ok) {
          self.setSelectedContest(id)
          self.setContestDetails(result.data)
          self.setStatus('done')
          return result.data
        } else {
          self.setStatus('error')
        }
      } catch (e) {
        self.setStatus('error')
        return e
      }
    }),
    getHowToWin: flow(function* (id: number) {
      self.setStatus('pending')
      try {
        const result: Awaited<ReturnType<typeof ContestApi.getHowToWin>> =
          yield ContestApi.getHowToWin(id)
        if (result.ok) {
          const sortBy = [
            'quizzes',
            'reinforcements',
            'tests',
            'posts',
            'comments',
            'badges',
            'surveys',
          ]
          const scoring = [...result.data.scoring_overview]
          scoring.sort((a, b) => sortBy.indexOf(a.item) - sortBy.indexOf(b.item))
          self.setHowToWin({ ...result.data, scoring_overview: scoring })
          self.setStatus('done')
          return { ...result.data, scoring_overview: scoring }
        } else {
          self.setStatus('error')
        }
      } catch {
        self.setStatus('error')
      }
    }),
    getContestsForUser: flow(function* (id: number) {
      self.setStatus('pending')
      try {
        const result: Awaited<ReturnType<typeof ContestApi.getContestsForUser>> =
          yield ContestApi.getContestsForUser(id)
        if (result.ok) {
          const byDate = (a: any, b: any) => {
            const x = DateTime.fromISO(a.end_datetime)
            const y = DateTime.fromISO(b.end_datetime)
            if (x < y) {
              return -1
            }
            if (x === y) {
              return 0
            }
            if (x > y) {
              return 1
            }
            return 0
          }

          const sorted = result.data.sort(byDate)

          const now = DateTime.fromISO(DateTime.now().toISO())
          const formatted = [] as any

          sorted.map((contest) => {
            const x = DateTime.fromISO(contest.end_datetime)
            if (x > now) {
              formatted.push(contest)
            }
          })
          sorted.map((contest) => {
            const x = DateTime.fromISO(contest.end_datetime)
            if (x < now) {
              formatted.push(contest)
            }
          })
          self.setContestsForUser(formatted)
          self.setStatus('done')
          return formatted
        } else {
          self.setStatus('error')
        }
      } catch {
        self.setStatus('error')
      }
    }),
    getContestLeaderboard: flow(function* (id: number, useLimit = true) {
      self.setStatus('pending')
      try {
        const result: Awaited<ReturnType<typeof ContestApi.getContestLeaderboard>> =
          yield ContestApi.getContestLeaderboard(id, useLimit)
        if (result.ok) {
          self.setContestLeaderboard(result.data.leaderboard_ranks)
          self.setStatus('done')
          self.setCurrentPoints(result.data.current_user_points)
          self.setUserRank(result.data.current_user_rank)
          self.setLastUpdated(result.data.leaderboard_last_update)
          self.setShowTimeout(false)
          return result.data
        } else {
          self.setStatus('error')
          return result.data
        }
      } catch (e) {
        console.log(e)
        const err = e as any
        if (err.kind === 'wait' || err.kind === 'timeout') {
          self.setShowTimeout(true)
        }
        self.setStatus('error')
        return e
      }
    }),
    getContestScoring: flow(function* (id: number) {
      self.setStatus('pending')
      try {
        const result: Awaited<ReturnType<typeof ContestApi.getContestScoring>> =
          yield ContestApi.getContestScoring(id)
        if (result.ok) {
          self.setContestScoring(result.data)
          self.setStatus('done')
          return result.data
        } else {
          self.setStatus('error')
        }
      } catch {
        self.setStatus('error')
      }
    }),
    deleteContests: flow(function* (contests: Array<number>) {
      self.setStatus('pending')
      try {
        const result: Awaited<ReturnType<typeof ContestApi.deleteContests>> =
          yield ContestApi.deleteContests(contests)
        if (result.ok) {
          self.setStatus('done')
        } else {
          self.setStatus('error')
        }
      } catch {
        self.setStatus('error')
      }
    }),
    endContests: flow(function* (ids: Array<number>) {
      self.setStatus('pending')
      try {
        const result: Awaited<ReturnType<typeof ContestApi.endContests>> =
          yield ContestApi.endContests(ids)
        if (result.ok) {
          self.setStatus('done')
        } else {
          self.setStatus('error')
        }
      } catch {
        self.setStatus('error')
      }
    }),
    extendContests: flow(function* (ids: Array<number>, endDateTime: any) {
      self.setStatus('pending')
      try {
        const result: Awaited<ReturnType<typeof ContestApi.extendContests>> =
          yield ContestApi.extendContests(ids, endDateTime)
        if (result.ok) {
          self.setStatus('done')
        } else {
          self.setStatus('error')
        }
      } catch {
        self.setStatus('error')
      }
    }),
    startContestsNow: flow(function* (ids: Array<number>) {
      self.setStatus('pending')
      try {
        const result: Awaited<ReturnType<typeof ContestApi.startContestsNow>> =
          yield ContestApi.startContestsNow(ids)
        if (result.ok) {
          self.setStatus('done')
        } else {
          self.setStatus('error')
        }
      } catch {
        self.setStatus('error')
      }
    }),
  }))

type ContestStoreType = typeof ContestStoreModel.Type
export interface AssignmentStore extends ContestStoreType {}
type ContestStoreModelSnapshotType = typeof ContestStoreModel.SnapshotType
export interface ContestStoreSnapshot extends ContestStoreModelSnapshotType {}
