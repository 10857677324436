import React, { useState } from 'react'
import { observer } from 'mobx-react-lite'

import { useStores } from '@trivie/core'

import { FileUpload } from '../components/file-upload'

interface UploadResourceTabProps {
  quiz?: any
  onUpload?: any
  standalone?: any
  topic?: any
}

export const allowedFileTypes = [
  'application/pdf',
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  'application/msword',
  'application/vnd.ms-powerpoint',
  'application/vnd.openxmlformats-officedocument.presentationml.presentation',
  'text/plain',
  // 'audio/mpeg',
  // 'audio/mp4',
  // 'audio/mpeg4-generic',
  // 'audio/mpga',
  // 'audio/m4a',
  // 'audio/wav',
  // 'audio/webm',
  // 'video/mp4',
  // 'video/mpeg',
  // 'video/mpeg4-generic',
  // 'video/mpga',
  // 'video/m4a',
  // 'video/webm',
]

const allFileTypes = [
  'application/pdf',
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  'application/msword',
  'application/vnd.ms-powerpoint',
  'application/vnd.openxmlformats-officedocument.presentationml.presentation',
  'text/plain',
  'audio/mpeg',
  'audio/mp4',
  'audio/mpeg4-generic',
  'audio/mpga',
  'audio/m4a',
  'audio/wav',
  'audio/webm',
  'video/mp4',
  'video/mpeg',
  'video/mpeg4-generic',
  'video/mpga',
  'video/m4a',
  'video/webm',
  'image/png',
  'image/jpeg',
  'image/gif',
  'image/webp',
  'image/tiff',
  'image/bmp',
  'image/svg+xml',
]

export const UploadResourceTab = observer((props: UploadResourceTabProps) => {
  const { standalone, topic, quiz, onUpload } = props
  const { resourceStore } = useStores()
  const { addResource, getAvailableResources } = resourceStore

  const [file, setFile] = useState<File | null>(null)
  const [progress, setProgress] = useState<any>(undefined)
  const [error, setError] = useState(false)

  const handleFile = async (f: File | null) => {
    setFile(f)

    if (!f) {
      throw new Error(`Can't upload undefined file`)
    }

    setProgress({ loaded: 0, total: 100 })

    const result = await addResource((currentProgress: any) => setProgress(currentProgress), {
      file: f,
      title: f.name,
      file_size: f.size,
      mime_type: f.type,
    })

    if (!result) {
      setError(true)
      return
    } else {
      const resourcesAfterAdd = await getAvailableResources()
      if (resourcesAfterAdd) {
        const addedResource = resourcesAfterAdd.find((r) => r.resource_id === result.resource_id)
        if (standalone) {
          onUpload(addedResource)
        } else {
          quiz.setResourceDocument(undefined)
          if (addedResource) {
            quiz.setMercuryResource(addedResource)
            resourceStore?.getResourceDetails(addedResource.resource_id)
            quiz.addResource(addedResource)
            if (Boolean(onUpload)) {
              onUpload()
              setFile(null)
            }
          }
        }
      }
    }
  }

  return standalone || (!topic && !quiz) ? (
    <FileUpload
      accept={allFileTypes.join(';')}
      file={file}
      onChange={handleFile}
      style={{ paddingTop: 0, paddingBottom: 0, height: 86 }}
      progress={progress}
      showSize
      error={error ? 'File could not be uploaded' : ''}
    />
  ) : !quiz?.mercuryResource ? (
    <FileUpload
      accept={allowedFileTypes.join(';')}
      file={file}
      onChange={handleFile}
      style={{ paddingTop: 0, paddingBottom: 0, height: 86 }}
      progress={progress}
      showSize
      error={error ? 'File could not be uploaded' : ''}
    />
  ) : null
})
