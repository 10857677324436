import React, { useEffect, useState } from 'react'
import { Delete, Download, ListAltRounded } from '@mui/icons-material'
import { useStores } from '@trivie/core'
import { ConfirmDialog } from '@trivie/ui-web/components/confirm-dialog'
import { DataTable, TableHelper } from '@trivie/ui-web/components/data-table/data-table'
import { observer } from 'mobx-react-lite'
import { CircularProgress } from '@mui/material'
import { Toolbar } from '@trivie/ui-web/components/table-toolbar'
import { AssignmentCreateDialog } from '../screens/activities/assignments/create-assignment/assignment-create'

interface TopicTableProps {
  tableHelper: TableHelper
  openFilter?: boolean
  exportFilename?: string
}

export const TopicTable = observer((props: TopicTableProps) => {
  const { exportFilename, tableHelper, openFilter } = props

  const { manageUserStore, topicsStore, contentCreationStore } = useStores()
  const { deleteTopics, checkDeleteTopics, exportTopics } = topicsStore
  const { resetContentStore } = contentCreationStore
  const { gdpr, permissions } = manageUserStore

  const [selectedRows, setSelectedRows] = useState<any>([])
  const [loading, setLoading] = useState<boolean>(tableHelper.custom ? false : true)

  // Dialogs
  const [showConfirmDelete, setShowConfirmDelete] = useState(false)
  const [deleteCheck, setDeleteCheck] = useState<any>()
  const [showAssignWizard, setShowAssignWizard] = useState(false)

  const getActions = () => {
    switch (tableHelper.parent) {
      case 'assignments':
        return []
      case 'quizzes':
        return []
      case 'groups':
        return []
      case 'tags':
        return []
      case 'users':
        return []
      default:
        return [
          {
            label: 'Assign',
            icon: ListAltRounded,
            onClick: () => setShowAssignWizard(true),
            disabled: !permissions?.permissions.some((p) => p.code === 'can_create_assignment'),
          },
          {
            label: 'Export Content',
            icon: Download,
            disabled: !permissions?.permissions.some((p) => p.code === 'can_create_topic'),
            onClick: async () => {
              const { file_url } = (await exportTopics(selectedRows)) as any
              setSelectedRows([])
              window.open(file_url)
            },
          },
          {
            label: 'Delete',
            icon: Delete,
            onClick: () => {
              checkDeleteTopics(selectedRows).then((data: any) => {
                setDeleteCheck(data?.message)
                setShowConfirmDelete(true)
              })
            },
            disabled: !permissions?.permissions.some((p) => p.code === 'can_delete_topic'),
          },
        ]
    }
  }

  const [tableActions, setTableActions] = useState<any>(getActions())

  useEffect(() => {
    if (permissions) {
      setTableActions(getActions())
    }
  }, [permissions?.permissions])

  useEffect(() => {
    setTableActions(getActions())
  }, [selectedRows])

  useEffect(() => {
    load()
  }, [])

  const load = () => {
    if (!tableHelper.custom) {
      setLoading(true)
      tableHelper.load().then(() => setLoading(false))
    }
  }

  const save = async (columns: any, filter: any) => {
    setLoading(true)
    tableHelper.save(columns, filter).then(() => setLoading(false))
  }

  return loading ? (
    <div style={LOADER}>
      <CircularProgress />
    </div>
  ) : (
    <>
      <DataTable
        gdpr={gdpr}
        allowNewReport
        openFilter={openFilter}
        columnVisibilityModel={tableHelper.columnVisibilityModel}
        hideAddColumn
        tableHelper={tableHelper}
        filterModel={tableHelper.filterModel}
        sortModel={tableHelper.sortModel}
        searchPlaceholder="Search Topics"
        rows={tableHelper.rows}
        columns={tableHelper.columns}
        onSaveLayout={tableHelper?.saveLayout}
        onSaveFilters={save}
        toolbar={<Toolbar selected={selectedRows} actions={tableActions} />}
        exportFilename={
          exportFilename
            ? exportFilename
            : tableHelper.parent !== 'topics'
              ? `${tableHelper.parent}_topics`
              : 'topics'
        }
        rowSelectionModel={selectedRows}
        onRowSelectionModelChange={(param) => setSelectedRows(param)}
      />
      {showConfirmDelete && (
        <ConfirmDialog
          danger
          open={showConfirmDelete}
          title="Remove Topics"
          message={`${
            deleteCheck
              ? deleteCheck
              : `Are you sure you want to delete ${selectedRows.length} topic${
                  selectedRows.length > 1 ? 's' : ''
                }?`
          }`}
          confirmedMessage={`${selectedRows.length} topic${
            selectedRows.length > 1 ? 's have' : ' has'
          } been removed.`}
          contentStyle={deleteCheck ? { textAlign: 'left' } : {}}
          confirmButton="Confirm"
          onConfirm={() => deleteTopics(selectedRows)}
          handleCancel={() => setShowConfirmDelete(false)}
          onClose={() => {
            setSelectedRows([])
            load()
            setShowConfirmDelete(false)
          }}
        />
      )}
      {showAssignWizard && (
        <AssignmentCreateDialog
          fromTopics
          open={showAssignWizard}
          initialTopics={selectedRows}
          requestClose={() => {
            resetContentStore()
            setShowAssignWizard(false)
          }}
        />
      )}
    </>
  )
})

const LOADER: React.CSSProperties = {
  display: 'flex',
  flex: 1,
  justifyContent: 'center',
  flexDirection: 'column',
  alignItems: 'center',
  height: window.innerHeight / 1.5,
}
