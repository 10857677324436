import React, { useEffect, useState } from 'react'
import { DateTime } from 'luxon'
import { observer } from 'mobx-react-lite'

import { FormControl, MenuItem, Select } from '@mui/material'
import Step from '@mui/material/Step'
import StepButton from '@mui/material/StepButton'
import Stepper from '@mui/material/Stepper'
import { ChevronLeftRounded } from '@mui/icons-material'
import { Instance } from 'mobx-state-tree'

import { AssignmentHelper, useStores } from '@trivie/core'
import { Text } from '@trivie/ui-web/components/text'
import { Button } from '@trivie/ui-web/components/button'
import { Dialog } from '@trivie/ui-web/components/dialogs/dialog'
import { AssignmentApi } from '@trivie/core/src/services/api-objects/AssignmentApi'
import {
  AssignmentCreate,
  AssignmentEdit,
} from '@trivie/core/src/models/response-models/assignment'
import { color } from '@trivie/ui-web/theme/color'
import { AssignmentCreateForm } from '@trivie/core/src/models/AssignmentCreateForm'

import { AssignmentAssignees } from './assignment-assignees'
import { AssignmentSchedule } from './assignment-schedule'
import { AssignmentReview } from './assignment-review'
import { QuizApi } from '@trivie/core/src/services/api-objects'
import { AutocompleteGroupSelector } from '@trivie/ui-web/components/autocomplete-selector'
import { WorkflowApi } from '@trivie/core/src/services/api-objects/WorkflowApi'

type AssignmentCreateDialogProps = {
  open: boolean
  initialTopics?: Array<number>
  initialQuizzes?: Array<number>
  initialPaths?: Array<number>
  editingId?: number
  requestClose: () => void
  fromContentCreation?: boolean
  fromTopics?: boolean
}

export const AssignmentCreateDialog = observer((props: AssignmentCreateDialogProps) => {
  const { assignmentStore, manageUserStore, pathStore } = useStores()
  const { userProfile } = manageUserStore
  const {
    fromContentCreation,
    open,
    initialTopics,
    initialQuizzes,
    initialPaths,
    editingId,
    requestClose,
    fromTopics,
  } = props
  const { setDetails } = pathStore
  const [activeStep, setActiveStep] = useState(0)
  const [form, setForm] = useState<Instance<typeof AssignmentCreateForm> | null>(null)
  const steps = ['Content', 'Assignees', 'Schedule', 'Review']
  const [isLoading, setIsLoading] = useState(false)

  const [transformedTopics, setTransformedTopics] = useState<any>([])
  const [transformedQuizzes, setTransformedQuizzes] = useState<any>([])
  const [transformedPaths, setTransformedPaths] = useState<any>([])

  const [selectedAutocompleteOptions, setSelectedAutocompleteOptions] = useState<any>([])
  const [assignmentType, setAssignmentType] = useState<any>(
    initialPaths?.length ? 'Learning Paths' : initialQuizzes?.length ? 'Quizzes' : 'Topics',
  )

  const handleAddItem = (selected: any[], removing?) => {
    selected.map((item: any) => {
      const found = form?.content.find((c) => c.id === item.id && c.type === item.type)

      if (found) {
        found.set('checked', true)
      }
    })
    const toRemove = form?.content.find((c) => c.id === removing?.id && c.type === removing?.type)
    if (toRemove) {
      toRemove.set('checked', false)
    }
    setSelectedAutocompleteOptions(selected)
  }

  useEffect(() => {
    if (open) {
      setActiveStep(initialPaths?.length ? 1 : 0)

      if (editingId) {
        setIsLoading(true)
        Promise.all([
          AssignmentApi.getAssignmentEditData(editingId),
          AssignmentApi.getAssignmentContent(),
          QuizApi.getAllQuizzes(
            'quizzes',
            false,
            [{ field: 'quiz_title' }, { field: 'quiz_question_count' }],
            'list_quizzes',
          ),
          WorkflowApi.getAssignmentPathDetails(),
        ])
          .then(([assignmentEditData, assignmentContentResponse, quizzes, pathDetails]) => {
            if (
              assignmentEditData.ok &&
              assignmentEditData.data &&
              assignmentContentResponse.ok &&
              assignmentContentResponse.data &&
              quizzes.ok &&
              quizzes.data &&
              pathDetails.ok &&
              pathDetails.data
            ) {
              const loadedForm = AssignmentHelper.createAssignmentCreateForm({
                start_date: assignmentEditData.data.start_date
                  ? DateTime.fromISO(assignmentEditData.data.start_date).toFormat('yyyy-MM-dd')
                  : DateTime.local().toFormat('yyyy-MM-dd'),
                start_time: assignmentEditData.data.start_date
                  ? DateTime.fromISO(assignmentEditData.data.start_date).toLocaleString(
                      DateTime.TIME_24_SIMPLE,
                    )
                  : DateTime.local().toLocaleString(DateTime.TIME_24_SIMPLE),
                end_date: assignmentEditData.data.end_date
                  ? DateTime.fromISO(assignmentEditData.data.end_date).toFormat('yyyy-MM-dd')
                  : DateTime.local().plus({ months: 1 }).toFormat('yyyy-MM-dd'),
                endDateEnabled: Boolean(assignmentEditData.data.end_date),
                end_time: assignmentEditData.data.end_date
                  ? DateTime.fromISO(assignmentEditData.data.end_date).toLocaleString(
                      DateTime.TIME_24_SIMPLE,
                    )
                  : DateTime.local().toLocaleString(DateTime.TIME_24_SIMPLE),
                title: assignmentEditData.data.title ?? '',
                content: undefined,
                // paths: assignmentEditData.data.path
                groups:
                  assignmentEditData.data.group_ids?.map((g: any) => ({
                    group_id: g,
                    label: '',
                    subLabel: '',
                    checked: true,
                    subItems: null,
                    count: 0,
                  })) ?? [],
                users:
                  assignmentEditData.data.user_ids?.map((u: any) => ({
                    id: u,
                    label: '',
                    subLabel: '',
                    checked: true,
                    subItems: null,
                    count: 0,
                  })) ?? [],
                attempts: assignmentEditData.data.max_attempts ?? 5,
                attemptsEnabled: assignmentEditData.data.max_attempts !== 999999,
                daysToComplete: assignmentEditData.data.days_to_complete ?? 7,
                daysToCompleteEnabled: Boolean(assignmentEditData.data.days_to_complete),
                notify: assignmentEditData.data.notify ?? false,
                include_pre_tests: assignmentEditData.data.include_pre_tests ?? false,
                include_post_tests: assignmentEditData.data.include_post_tests ?? false,
              })
              const topicData = assignmentContentResponse.data.topics.map((tq) => {
                return {
                  id: tq.topic_id,
                  type: 'topics',
                  label: tq.topic_title,
                  subLabel: `${tq.quizzes?.length ?? 0} ${
                    tq.quizzes?.length === 1 ? 'Quiz' : 'Quizzes'
                  }`,
                  checked: Boolean(
                    [...(assignmentEditData.data?.topics || []), ...(initialTopics || [])]?.some(
                      (it) => it === tq.topic_id,
                    ),
                  ),
                  subItems: tq.quizzes.map((q) => ({
                    id: q.quiz_id,
                    type: 'quizzes',
                    label: q.quiz_title,
                    subLabel: `${q.question_count} ${
                      q.question_count === 1 ? 'Question' : 'Questions'
                    }`,
                    checked: false,
                  })),
                }
              })
              const quizData = quizzes.data.rows.map((quiz) => {
                const quiz_title = quiz.columns.find((column) => column.field === 'quiz_title')
                const quiz_question_count = quiz.columns.find(
                  (column) => column.field === 'quiz_question_count',
                )
                return {
                  id: quiz.id,
                  type: 'quizzes',
                  label: quiz_title.value,
                  subLabel: `${quiz_question_count.value} ${
                    quiz_question_count.value === 1 ? 'Question' : 'Questions'
                  }`,
                  checked: [
                    ...(assignmentEditData.data?.quizzes || []),
                    ...(initialQuizzes || []),
                  ]?.some((it) => it === quiz.id),
                }
              })
              const pathData = pathDetails.data?.paths.map((path, index) => {
                return {
                  id: path.path_id,
                  type: 'learning paths',
                  label: path.title,
                  subLabel: '',
                  subItems: [...path.topics, ...path.quizzes, ...path.resources].map(
                    (item, idx) => {
                      return {
                        parentId: path.path_id,
                        type: item?.question_count
                          ? 'quizzes'
                          : item?.file_name
                            ? 'resources'
                            : item?.quiz_count
                              ? 'topics'
                              : '',
                        id: idx,
                        label: item?.title,
                        subLabel: `${item?.quiz_count || item?.question_count || item?.file_name} ${
                          item?.quiz_count ? 'Quizzes' : item?.question_count ? 'Questions' : ''
                        }`,
                        checked: false,
                      }
                    },
                  ),
                  checked: Boolean(
                    [...(assignmentEditData?.data?.paths || []), ...(initialPaths || [])]?.some(
                      (it) => it === path.path_id,
                    ),
                  ),
                }
              })

              const content = [...topicData, ...quizData, ...pathData]
              pathStore.setPaths(pathData)

              loadedForm.set('content', content)
              setForm(loadedForm)

              const sel = [] as any

              pathData.map((p) => {
                if (p.checked) {
                  sel.push(p)
                }
              })
              setSelectedAutocompleteOptions(sel)
              topicData.map((t) => {
                if (t.checked) {
                  sel.push(t)
                }
              })
              setSelectedAutocompleteOptions(sel)
              quizData.map((q) => {
                if (q.checked) {
                  sel.push(q)
                }
              })
              setSelectedAutocompleteOptions(sel)

              if (assignmentEditData?.data?.topics?.length > 0) {
                setAssignmentType('Topics')
              } else if (assignmentEditData?.data?.quizzes?.length > 0) {
                setAssignmentType('Quizzes')
              } else if (assignmentEditData?.data?.paths?.length > 0) {
                setAssignmentType('Learning Paths')
              }
              setTransformedTopics(topicData)
              setTransformedQuizzes(quizData)
              setTransformedPaths(pathData)
            } else {
              throw new Error(`Failed to get assignment content`)
            }
          })
          .finally(() => {
            setIsLoading(false)
          })
      } else {
        const formDefaults = AssignmentHelper.createAssignmentCreateForm({})
        setIsLoading(true)
        // initial fetch of content so we can set up initial checked items for content

        Promise.all([
          AssignmentApi.getAssignmentContent(),
          QuizApi.getAllQuizzes(
            'quizzes',
            false,
            [{ field: 'quiz_title' }, { field: 'quiz_question_count' }],
            'list_quizzes',
          ),
          WorkflowApi.getAssignmentPathDetails(),
        ])
          .then(([assignmentContentResponse, quizzes, pathDetails]) => {
            setDetails(pathDetails)

            if (
              assignmentContentResponse.ok &&
              assignmentContentResponse.data &&
              quizzes.ok &&
              quizzes.data &&
              pathDetails.ok &&
              pathDetails.data
            ) {
              const topicData = assignmentContentResponse.data.topics.map((tq) => {
                const checked = Boolean(
                  [...(initialTopics || [])]?.some((it) => {
                    return it === tq.topic_id
                  }),
                )

                return {
                  id: tq.topic_id,
                  type: 'topics',
                  label: tq.topic_title,
                  subLabel: `${tq.quizzes?.length ?? 0} ${
                    tq.quizzes?.length === 1 ? 'Quiz' : 'Quizzes'
                  }`,
                  checked,
                  subItems: tq.quizzes.map((q) => ({
                    parentId: tq.topic_id,
                    type: 'quizzes',
                    id: q.quiz_id,
                    label: q.quiz_title,
                    subLabel: `${q.question_count} ${
                      q.question_count === 1 ? 'Question' : 'Questions'
                    }`,
                    checked: Boolean(initialTopics?.some((it) => it === tq.topic_id)),
                  })),
                }
              })

              const pathData = pathDetails.data?.paths.map((path, index) => {
                return {
                  id: path.path_id,
                  type: 'learning paths',
                  label: path.title,
                  subLabel: '',
                  subItems: [...path.topics, ...path.quizzes, ...path.resources].map(
                    (item, idx) => {
                      return {
                        parentId: path.path_id,
                        type: item?.question_count
                          ? 'quizzes'
                          : item?.file_name
                            ? 'resources'
                            : item?.quiz_count
                              ? 'topics'
                              : '',
                        id: idx,
                        label: item?.title,
                        subLabel: `${item?.quiz_count || item?.question_count || item?.file_name} ${
                          item?.quiz_count ? 'Quizzes' : item?.question_count ? 'Questions' : ''
                        }`,
                        checked: false,
                      }
                    },
                  ),
                  checked: Boolean([...(initialPaths || [])]?.some((p) => p === path.path_id)),
                }
              })

              pathStore.setPaths(pathData)

              const quizData = quizzes.data.rows.map((quiz) => {
                const quiz_title = quiz.columns.find((column) => column.field === 'quiz_title')
                const quiz_question_count = quiz.columns.find(
                  (column) => column.field === 'quiz_question_count',
                )
                return {
                  id: quiz.id,
                  type: 'quizzes',
                  label: quiz_title.value,
                  subLabel: `${quiz_question_count.value} ${
                    quiz_question_count.value === 1 ? 'Question' : 'Questions'
                  }`,
                  checked: Boolean([...(initialQuizzes || [])]?.some((it) => it === quiz.id)),
                }
              })

              const content = [...topicData, ...quizData, ...pathData]

              formDefaults.set('content', content)

              const sel = [] as any

              if (initialPaths?.length) {
                pathData.map((p) => {
                  if (p.checked) {
                    sel.push(p)
                  }
                })
                setSelectedAutocompleteOptions(sel)
              } else if (fromTopics) {
                topicData.map((t) => {
                  if (t.checked) {
                    sel.push(t)
                  }
                })
                setSelectedAutocompleteOptions(sel)
              } else if (initialQuizzes?.length) {
                quizData.map((q) => {
                  if (q.checked) {
                    sel.push(q)
                  }
                })
                setSelectedAutocompleteOptions(sel)
              }

              setTransformedTopics(topicData)
              setTransformedQuizzes(quizData)
              setTransformedPaths(pathData)

              setForm(formDefaults)
            } else {
              throw new Error(`Failed to get assignment content`)
            }
          })
          .finally(() => {
            setIsLoading(false)
          })
      }
    }
  }, [open])

  if (!form) {
    return null
  }

  let opts = [] as any

  if (assignmentType === 'Topics') {
    opts = transformedTopics
  } else if (assignmentType === 'Quizzes') {
    opts = transformedQuizzes
  } else if (assignmentType === 'Learning Paths') {
    opts = transformedPaths
  } else {
    opts = form.content
  }

  const getStepContent = (step: number) => {
    switch (step) {
      case 0:
        return (
          <>
            <div
              style={{
                flexDirection: 'row',
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                marginBottom: 32,
              }}
            >
              <div style={{ flexDirection: 'column', display: 'flex' }}>
                <Text text="Assignment Type" variant="body2Medium" />
                <Text
                  text="Assign topics or quizzes"
                  variant="body2"
                  style={{ color: color.shade50 }}
                />
              </div>
              <FormControl>
                <Select
                  disabled={Boolean(fromTopics || initialQuizzes?.length || initialPaths?.length)}
                  style={{ width: 207, height: 41 }}
                  value={assignmentType}
                  onChange={(e) => {
                    setSelectedAutocompleteOptions([])
                    form.content.map((c) => c.set('checked', false))
                    setAssignmentType(e.target.value)
                  }}
                >
                  <MenuItem value="Topics">Topics</MenuItem>
                  <MenuItem value="Quizzes">Quizzes</MenuItem>
                  {userProfile.workspace_settings.learning_paths_enabled && (
                    <MenuItem value="Learning Paths">Learning Paths</MenuItem>
                  )}
                </Select>
              </FormControl>
            </div>
            <AutocompleteGroupSelector
              open
              noOptionsText="No content."
              options={opts}
              defaultOptions={[]}
              listHeight="28vh"
              selectedOptions={selectedAutocompleteOptions}
              handleAddUserGroup={handleAddItem}
            />
          </>
        )
      case 1:
        return <AssignmentAssignees editingId={editingId} form={form} />
      case 2:
        return <AssignmentSchedule form={form} />
      case 3:
        return <AssignmentReview form={form} />

      default:
        return 'Unknown step'
    }
  }

  const isLastStep = () => {
    return activeStep > steps.length
  }

  const handleNext = async () => {
    if (activeStep + 1 === 2 && initialPaths?.length) {
      if (form.selectedPaths?.length) {
        form.set('title', `${form.selectedPaths[0].label} Assignment`)
      }
      setActiveStep(2)
    } else if (activeStep + 1 === 4) {
      const due_date = form.daysToCompleteEnabled
        ? DateTime.fromJSDate(new Date(`${form.start_date}T${form.start_time}:00`))
            .plus({ days: form.daysToComplete || 0 })
            .toUTC()
            .toISO()
        : null
      const end_date = DateTime.fromJSDate(new Date(`${form.end_date}T${form.end_time}:00`))
        .toUTC()
        .toISO()

      if (editingId) {
        const editPayload = {
          title: form.title,
          max_attempts: form.attemptsEnabled ? form.attempts || 5 : 999999,
          is_test: false,
          notify: form.notify,
          topics: form.selectedTopics.filter((f) => f.type === 'topics').map((t) => t.id),
          content_ordering: [],
          lessons: [],
          quizzes: form.selectedQuizzes.map((q) => q.id),
          group_ids: form.groups.map((g) => g.id),
          user_ids: form.users.map((u) => u.id),
          include_pre_tests: form.include_pre_tests,
          include_post_tests: form.include_post_tests,
          days_to_complete: form.daysToCompleteEnabled ? form.daysToComplete : null,
          start_date: DateTime.fromJSDate(new Date(`${form.start_date}T${form.start_time}:00`))
            .toUTC()
            .toISO(),
          end_date: form.endDateEnabled ? end_date : null,
          due_date,
          is_archived: false,
          is_deleted: false,
          paths: form.selectedPaths.map((path) => path.id),
        }
        AssignmentApi.patchAssignment(editingId, AssignmentEdit.create(editPayload))
      } else {
        assignmentStore.setTriggerRefresh(true)
        const payload = AssignmentCreate.create({
          title: form.title,
          max_attempts: form.attemptsEnabled ? form.attempts || 5 : 999999,
          is_test: false,
          notify: form.notify,
          topics: form.selectedTopics.filter((f) => f.type === 'topics').map((t) => t.id),
          content_ordering: [],
          lessons: [],
          quizzes: form.selectedQuizzes.map((q) => q.id),
          group_ids: form.groups.map((g) => g.id),
          user_ids: form.users.map((u) => u.id),
          include_pre_tests: form.include_pre_tests,
          include_post_tests: form.include_post_tests,
          days_to_complete: form.daysToCompleteEnabled ? form.daysToComplete : null,
          start_date: DateTime.fromJSDate(new Date(`${form.start_date}T${form.start_time}:00`))
            .toUTC()
            .toISO(),
          end_date: form.endDateEnabled ? end_date : null,
          due_date,
          paths: form.selectedPaths.map((path) => path.id),
        })
        AssignmentApi.createAssignment(payload)
      }

      setActiveStep(4)
    } else if (activeStep + 1 === 5) {
      setActiveStep(0)
      requestClose()
    } else {
      const newActiveStep = !isLastStep() ? activeStep + 1 : activeStep
      setActiveStep(newActiveStep)
    }
  }

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1)
  }

  const stepIsValid = () => {
    if (activeStep === 0) {
      return Boolean(
        form.selectedQuizzes.length || form.selectedTopics.length || form.selectedPaths.length,
      )
    } else if (activeStep === 1) {
      return Boolean(form.groups?.length > 0 || form.users?.length > 0)
    } else if (activeStep === 2) {
      return !Object.keys(form.errors).length
    } else {
      return true
    }
  }

  const getButtonText = () => {
    if (editingId && activeStep === 2) {
      return 'Review'
    } else if (editingId && activeStep === 3) {
      return 'Save'
    } else if (editingId) {
      return 'Next'
    } else if (activeStep === 3) {
      return 'Assign'
    } else {
      return 'Next'
    }
  }

  return (
    <div>
      <Dialog
        open={activeStep === 4}
        title={editingId ? 'Edit Assignment' : 'Create Assignment'}
        onConfirm={handleNext}
        dialogText={
          editingId
            ? 'Assignment Updated!'
            : `The assignment “${form.title}” was successfully created!`
        }
        onClose={handleNext}
        maxWidth="xs"
        confirmBtnLabel="Close"
      />
      <Dialog
        disableBackdropClick
        maxWidth="md"
        onClose={() => {
          setActiveStep(0)
          requestClose()
        }}
        title={editingId ? 'Edit Assignment' : 'Create Assignment'}
        open={open && activeStep !== 4}
        actions={
          <div
            style={{
              display: 'flex',
              flex: 1,
              justifyContent: activeStep === 0 ? 'flex-end' : 'space-between',
            }}
          >
            {activeStep > 0 && (
              <Button
                onClick={handleBack}
                startIcon={<ChevronLeftRounded />}
                text="Back"
                variant="text"
              />
            )}
            <Button onClick={handleNext} disabled={!stepIsValid()} text={getButtonText()} />
          </div>
        }
      >
        <Stepper
          style={{ paddingLeft: 0, paddingRight: 0, marginBottom: 32 }}
          nonLinear
          activeStep={activeStep}
        >
          {steps.map((label) => (
            <Step key={label}>
              <StepButton disableRipple disabled>
                {label}
              </StepButton>
            </Step>
          ))}
        </Stepper>
        <div style={{ display: 'flex', flexDirection: 'column', overflowY: 'hidden', height: 500 }}>
          {getStepContent(activeStep)}
        </div>
      </Dialog>
    </div>
  )
})
