import React from 'react'
import { CheckBoxOutlineBlankRounded } from '@mui/icons-material'
import { color } from '@trivie/ui-web/theme/color'
import { Text } from '@trivie/ui-web/components/text'
import { BaseQuestion } from './base-question'
import { Instance } from 'mobx-state-tree'
import { SurveyQuestion, SurveyQuestionChoice } from '@trivie/core/src/models/Survey'
import { roundNumber } from '@trivie/core/src/utils/roundNumber'
import { observer } from 'mobx-react-lite'

interface TrueFalseQuestionProps {
  question: Instance<typeof SurveyQuestion>
  disabled?: boolean
  showQuestionPct?: boolean
  handleDrag?: () => void
  handleDrop?: () => void
}
export const TrueFalseQuestion = observer((props: TrueFalseQuestionProps) => {
  const { question, disabled, showQuestionPct, handleDrag, handleDrop } = props
  const choiceTrue = question?.choices?.find(
    (c: Instance<typeof SurveyQuestionChoice>) => c.text === 'True',
  )
  const choiceFalse = question?.choices?.find(
    (c: Instance<typeof SurveyQuestionChoice>) => c.text === 'False',
  )

  return (
    <BaseQuestion
      handleDrag={handleDrag}
      handleDrop={handleDrop}
      question={question}
      disabled={disabled}
    >
      <div style={{ marginBottom: 20, paddingLeft: 20 }}>
        <div style={ANSWER_CONTAINER}>
          <div style={CHOICE_CONTAINER}>
            <CheckBoxOutlineBlankRounded
              style={{ color: 'rgba(218, 218, 218, 1)', marginRight: 16, fontSize: 16 }}
            />
            <Text
              style={{ fontSize: 14, color: color.palette.quizSummary }}
              text="True"
              variant="body2Medium"
            />
          </div>
          {showQuestionPct ? (
            <Text
              style={{ fontSize: 14, color: color.palette.quizSummary }}
              text={`${roundNumber(choiceTrue?.pct_selected ?? 0)}%`}
              variant="body2Medium"
            />
          ) : null}
        </div>
        <div style={ANSWER_CONTAINER}>
          <div style={CHOICE_CONTAINER}>
            <CheckBoxOutlineBlankRounded
              style={{ color: 'rgba(218, 218, 218, 1)', marginRight: 16, fontSize: 16 }}
            />
            <Text
              style={{ fontSize: 14, color: color.palette.quizSummary }}
              text="False"
              variant="body2Medium"
            />
          </div>
          {showQuestionPct ? (
            <Text
              style={{ fontSize: 14, color: color.palette.quizSummary }}
              text={choiceFalse?.pct_selected ? `${roundNumber(choiceFalse?.pct_selected)}%` : '0%'}
              variant="body2Medium"
            />
          ) : null}
        </div>
      </div>
    </BaseQuestion>
  )
})

const CHOICE_CONTAINER: React.CSSProperties = {
  display: 'flex',
  alignItems: 'center',
  marginBottom: 20,
}

const ANSWER_CONTAINER: React.CSSProperties = {
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
}
