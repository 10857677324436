import React, { useEffect, useRef, useState } from 'react'
import { observer } from 'mobx-react-lite'
import { LocalOfferOutlined } from '@mui/icons-material'
import { InputBase } from '@mui/material'
import { useStores } from '@trivie/core'
import { color } from '../../theme'
import { TagKey } from '../tag-key'
import { TagApi } from '@trivie/core/src/services/api-objects/TagApi'
export interface TagKeysSelectorProps {
  selectedTagKeys: any
  onChange: (tagKeys: any) => void
  disabled?: boolean
}

export const TagKeySelector = observer((props: TagKeysSelectorProps) => {
  const [showPopper, setShowPopper] = useState(false)
  const { selectedTagKeys = [], onChange, disabled } = props
  const { tagStore } = useStores()
  const { tagKeyFilter, setTagKeyFilter } = tagStore
  const selectorRef = useRef(null)
  const handleBlur = (e: any) => {
    if (!e.composedPath().some((el: any) => el === selectorRef.current)) {
      setShowPopper(false)
    }
  }

  const [tagKeys, setTagKeys] = useState<any[]>()

  useEffect(() => {
    const keys = [] as any
    TagApi.getAllTags('tags', false, [{ field: 'tag_key' }], 'list_tags').then((resp: any) => {
      resp.data.rows.map((tag) => {
        const tag_key = tag.columns.find((t) => t.field === 'tag_key')
        keys.push(tag_key.value)
      })
      setTagKeys([...new Set(keys)])
    })

    window.addEventListener('click', handleBlur)

    return () => {
      window.removeEventListener('click', handleBlur)
      setTagKeyFilter('')
    }
  }, [])

  const handleTagAdd = (tag: any) => {
    if (disabled) {
      return
    }
    setTagKeyFilter('')
    onChange([...selectedTagKeys, tag])
  }

  const handleTagRemove = (tagKey: any) => {
    if (disabled) {
      return
    }
    onChange(selectedTagKeys.filter((t: any) => t !== tagKey))
  }

  const handleTagsFilterChange = (e: any) => {
    if (disabled) {
      return
    }
    setTagKeyFilter(e.target.value)
  }

  const handleFocus = () => {
    setShowPopper(true)
  }

  return (
    <div style={{ position: 'relative' }} ref={selectorRef}>
      <div
        onFocus={handleFocus}
        style={{
          ...DROPDOWN,
          borderColor: showPopper ? color.palette.blueLink : color.palette.paperBorder,
        }}
      >
        <LocalOfferOutlined htmlColor={color.palette.grey} />
        {selectedTagKeys.map((tag: any) => {
          return (
            <TagKey
              tagKey={tag}
              onDelete={handleTagRemove}
              style={{ marginLeft: 12, marginBottom: 6, marginTop: 6 }}
            />
          )
        })}
        <InputBase
          disabled={Boolean(disabled)}
          value={tagKeyFilter}
          placeholder="Enter a Category"
          style={{ height: 29, marginLeft: 12, marginTop: 6, marginBottom: 6, flex: 1 }}
          onChange={handleTagsFilterChange}
        />
      </div>

      <div style={{ ...CONTENT, visibility: showPopper ? 'visible' : 'hidden' }}>
        <div style={{ display: 'flex', minWidth: 280, flexDirection: 'column' }}>
          {tagKeys
            ?.filter(
              (tagKey: string) =>
                !selectedTagKeys.includes(tagKey) &&
                tagKey?.toLowerCase().includes(tagKeyFilter?.toLowerCase()),
            )
            .map((tagKey: string) => (
              <div key={tagKey} style={TAG}>
                <div style={{ display: 'flex', alignItems: 'center', flex: 1, paddingLeft: 14 }}>
                  <LocalOfferOutlined htmlColor={color.palette.grey} />
                  <TagKey
                    tagKey={tagKey}
                    onAdd={handleTagAdd}
                    style={{ marginLeft: 12, marginBottom: 6, marginTop: 6 }}
                  />
                </div>
              </div>
            ))}
        </div>
      </div>
    </div>
  )
})

const DROPDOWN: React.CSSProperties = {
  display: 'flex',
  flexWrap: 'wrap',
  alignItems: 'center',
  borderStyle: 'solid',
  borderWidth: 1,
  borderRadius: 6,
  backgroundColor: color.palette.white,
  paddingLeft: 13,
  paddingRight: 13,
  flex: 1,
}
const CONTENT: React.CSSProperties = {
  width: '100%',
  position: 'absolute',
  display: 'flex',
  border: `1px solid ${color.palette.paperBorder}`,
  borderRadius: 16,
  marginTop: 2,
  backgroundColor: color.white,
  zIndex: 999,
}
const TAG: React.CSSProperties = {
  display: 'flex',
  alignItems: 'center',
  width: '100%',
  height: 45,
}
