import { Instance, SnapshotIn, cast, getParent, getSnapshot, types } from 'mobx-state-tree'
import { SourceSuggestion } from '../SourceSuggestion'

export const ChatAction = types
  .model('ChatAction')
  .props({
    visible: types.maybe(types.boolean),
    selected: types.maybe(types.boolean),
    label: types.string,
    labelOverride: types.maybe(types.boolean),
    active: types.maybe(types.boolean),
    type: types.enumeration(['toggle', 'button']),
  })
  .volatile(() => ({
    onPress: () => {},
  }))
  .actions((self) => ({
    setOnPress(f: any) {
      self.onPress = f
    },
    setActive(status: boolean) {
      self.active = status
    },
    select() {
      const actions = getParent(self, 1) as Instance<typeof ChatAction>[]
      self.selected = true
      if (actions && actions.length > 0) {
        actions.map((action) => action.hide())
      }
      self.onPress()
    },
    toggle() {
      const actions = getParent(self, 1) as Instance<typeof ChatAction>[]
      if (actions && actions.length > 0) {
        actions.map((action) => action.setActive(false))
      }
      self.active = true
      self.onPress()
    },
    hide() {
      self.visible = false
    },
  }))
export const ChatMessage = types
  .model('ChatMessage')
  .props({
    key: types.maybe(types.number),
    sender: types.enumeration(['trivie', 'user']),
    batch: types.maybe(types.boolean),
    text: types.maybe(types.string),
    actions: types.array(ChatAction),
    type: types.enumeration(['default', 'actions']),
    followsBatch: types.maybe(types.boolean),
    loading: types.maybe(types.boolean),
  })
  .volatile(() => ({
    // generic: null,
    component: () => {},
  }))
  .actions((self) => ({
    set<K extends keyof SnapshotIn<typeof self>, T extends SnapshotIn<typeof self>>(
      key: K,
      value: T[K],
    ) {
      // @ts-ignore
      self[key] = cast(value)
    },
    setComponent(c: any) {
      self.component = c
    },
    setToggles() {
      self.actions.map((action) => {
        if (action.active) {
          action.select()
        }
      })
    },
  }))
export const ChatStoreModel = types
  .model('ChatStore')
  .props({
    id: types.maybe(types.number),
    messages: types.array(ChatMessage),
    subject: types.maybe(types.string),
    sources: types.maybe(types.array(SourceSuggestion)),
  })
  .views((self) => ({
    get raw() {
      return getSnapshot(self.messages)
    },
  }))
  .actions((self) => ({
    pop() {
      const m = self.messages
      m.pop()
      self.messages = m
    },
    removeMessage(message) {
      self.messages = cast(self.messages.filter((m) => m.key !== message.key))
    },
    setSubject(subject: string) {
      self.subject = subject
    },
    clear() {
      self.messages = cast([])
      self.id = undefined
      self.subject = ''
      self.sources = undefined
    },
    set<K extends keyof SnapshotIn<typeof self>, T extends SnapshotIn<typeof self>>(
      key: K,
      value: T[K],
    ) {
      self[key] = cast(value)
    },
    sendMessage(sender?: any, text?: string, actions?: any, loading?: boolean, component?: any) {
      const message = { sender, text, actions, batch: false, followsBatch: false, loading }
      self.id = self.messages.length + 1

      if (self.messages[self.messages.length - 1]?.sender === sender) {
        self.messages[self.messages.length - 1].set('batch', true)
        message.followsBatch = true
      }

      let m

      if (actions?.length > 0) {
        self.id = self.messages.length + 1
        const actions = message.actions.map((action) => {
          const a = ChatAction.create(action)
          a.setOnPress(action.onPress)
          return a
        })
        m = ChatMessage.create({ ...message, key: self.id, type: 'actions', actions })
        m.setComponent(component)
        self.messages.push(m)
      } else {
        m = ChatMessage.create({ ...message, key: self.id, type: 'default' })
        m.setComponent(component)
        self.messages.push(m)
      }
      return m
    },
    setSources(sources) {
      self.sources = sources
    },
  }))
