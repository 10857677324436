import { Box, CircularProgress, InputAdornment, TextField, Typography } from '@mui/material'
import { observer } from 'mobx-react-lite'
import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import SentimentVeryDissatisfiedIcon from '@mui/icons-material/SentimentVeryDissatisfied'
import { Email } from '@mui/icons-material'

import { Dialog } from '@trivie/ui-web/components/dialogs/dialog'
import { Text } from '@trivie/ui-web/components/text'
import { Button } from '@trivie/ui-web/components/button'
import { color } from '@trivie/ui-web/theme/color'
import { AuthenticationApi } from '@trivie/core/src/services/api-objects'
import { ReactComponent as SignupGraphic } from './signup.svg'
import { useStores } from '@trivie/core'
import { OtpEntry } from '@trivie/ui-web/components/otp-entry/otp-entry'

export const SignupModal = observer((props: any) => {
  const { open, close, onSubmit } = props
  const { manageUserStore } = useStores()
  const { setOtpNotFound, setCreatingWorkspace, otpNotFound } = manageUserStore
  const [email, setEmail] = useState('')
  const [isLogin, setIsLogin] = useState(false)
  const [showOTC, setShowOTC] = useState(false)
  const [otp, setOtp] = useState('')
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    setCreatingWorkspace(true)
    setOtpNotFound(false)
  }, [])

  const loginOTC = () => {
    if (otp && email) {
      setLoading(true)
      if (!isLogin) {
        AuthenticationApi.createWorkspace({ otc: otp, email })
          .then((resp: any) => {
            setLoading(false)
            setCreatingWorkspace(false)
            manageUserStore.loadUser(resp.data.user_id)
            manageUserStore.getSettings()
            onSubmit()
            close()
          })
          .catch((e) => {
            console.log(e)
            setLoading(false)
            setOtpNotFound(false)
          })
      }
    }
  }

  const onChange = (value: string) => {
    setOtpNotFound(false)
    setOtp(value)
  }

  const resend = () => {
    AuthenticationApi.insightsSignup({ email })
  }

  return (
    <Dialog hideCloseIcon hideDivider title="" maxWidth="sm" open={open}>
      <Box display="flex" alignItems="center" flexDirection="column" justifyContent="center">
        <Text
          variant="headline"
          text={showOTC ? 'Enter Your Code' : isLogin ? 'Log In to Trivie' : 'Sign Up for Trivie'}
          style={{ marginBottom: 32, textAlign: 'center' }}
        />
        <div style={{ margin: 'auto' }}>
          <SignupGraphic width={200} height={132} />
        </div>
        {!loading && (
          <Text
            variant="titleMedium"
            component="div"
            textAlign="center"
            text={
              showOTC
                ? 'We just sent you a temporary sign up code. Please check your inbox and paste your sign-up code below.'
                : isLogin
                  ? "Enter your email address and we'll send you a temporary sign-in code"
                  : 'Ready to get started? Sign up for a free Trivie demo account today.'
            }
            style={TAGLINE}
          />
        )}
        {showOTC ? (
          <>
            {loading ? (
              <div
                style={{
                  marginTop: 40,
                  marginBottom: 40,
                  flex: 1,
                  display: 'flex',
                  justifyContent: 'center',
                }}
              >
                <CircularProgress />
              </div>
            ) : (
              <OtpEntry containerStyle={{ marginBottom: 32 }} onChange={onChange} />
            )}
            <Button
              disabled={otp.length !== 4 || loading}
              size="large"
              sx={{ marginBottom: '32px', alignSelf: 'center' }}
              onClick={loginOTC}
              fullWidth
              text="Create My Account"
            />
            {otpNotFound && (
              <div style={ERROR_STATE}>
                <SentimentVeryDissatisfiedIcon
                  htmlColor={color.red}
                  style={{ marginRight: 12, fontSize: 20 }}
                />
                <span style={{ fontWeight: '700', fontSize: 12, color: 'rgba(255, 0, 0, 0.8)' }}>
                  We don't recognize that code.&nbsp;
                  <Typography
                    variant="body2Medium"
                    style={{
                      fontSize: 12,
                      color: 'rgba(255, 0, 0, 0.8)',
                    }}
                  >
                    Please try again, select Resend Code below, or contact your administrator.
                  </Typography>
                </span>
              </div>
            )}
            <span
              style={{
                textAlign: 'center',
                fontSize: 14,
                color: color.shade70,
              }}
            >
              Haven't received the email? Check your spam folder,&nbsp;
              <span
                onClick={resend}
                style={{
                  fontSize: 14,
                  color: color.blue,
                  cursor: 'pointer',
                }}
              >
                send it again,&nbsp;
              </span>
              or email&nbsp;
              <span style={{ fontSize: 14 }}>
                <a href={`mailto:${process.env.REACT_APP_SUPPORT}`} style={{ color: color.blue }}>
                  {process.env.REACT_APP_SUPPORT}
                </a>
                &nbsp;to get in touch.
              </span>
            </span>
          </>
        ) : (
          <>
            <TextField
              fullWidth
              variant="outlined"
              InputProps={{
                startAdornment: (
                  <InputAdornment style={{ backgroundColor: '#fff' }} position="start">
                    <Email htmlColor={color.shade50} />
                  </InputAdornment>
                ),
              }}
              inputProps={{ inputMode: 'email' }}
              onChange={(e) => setEmail(e.target.value)}
              type="email"
              value={email}
              style={{ marginBottom: 32 }}
              size="small"
              placeholder="Email"
            />
            <Button
              disabled={!email || !email.includes('@')}
              size="large"
              fullWidth
              sx={{ marginBottom: '32px' }}
              onClick={() => {
                manageUserStore?.setEmail(email)
                AuthenticationApi.insightsSignup({ email }).then(() => {
                  setShowOTC(true)
                })
              }}
              text="Continue"
            />
            <div style={SEPARATOR}>
              <div style={{ height: 1, width: '100%', backgroundColor: color.shade20 }} />
              <Text
                variant="titleEmphasized"
                text="or"
                style={{ marginLeft: 10, marginRight: 10 }}
              />
              <div style={{ height: 1, width: '100%', backgroundColor: color.shade20 }} />
            </div>

            <Button
              size="large"
              fullWidth
              color="info"
              variant="outlined"
              sx={{ marginBottom: '32px' }}
              text="Continue with Google"
              onClick={() =>
                window.location.replace(
                  `${process.env.REACT_APP_SSO_GOOG_URL}&intent=${isLogin ? 'signin' : 'signup'}`,
                )
              }
              startIcon={
                <svg
                  width="23"
                  height="24"
                  viewBox="0 0 23 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M22.54 12.2615C22.54 11.446 22.4668 10.6619 22.3309 9.90918H11.5V14.3576H17.6891C17.4225 15.7951 16.6123 17.013 15.3943 17.8285V20.714H19.1109C21.2855 18.7119 22.54 15.7637 22.54 12.2615Z"
                    fill="#4285F4"
                  />
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M11.4999 23.4998C14.6049 23.4998 17.2081 22.47 19.1108 20.7137L15.3942 17.8282C14.3644 18.5182 13.0472 18.9259 11.4999 18.9259C8.50467 18.9259 5.96945 16.903 5.06513 14.1848H1.22308V17.1644C3.11536 20.9228 7.00445 23.4998 11.4999 23.4998Z"
                    fill="#34A853"
                  />
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M5.06523 14.185C4.83523 13.495 4.70455 12.7579 4.70455 12C4.70455 11.242 4.83523 10.505 5.06523 9.81499V6.83545H1.22318C0.444318 8.38795 0 10.1443 0 12C0 13.8557 0.444318 15.612 1.22318 17.1645L5.06523 14.185Z"
                    fill="#FBBC05"
                  />
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M11.4999 5.07386C13.1883 5.07386 14.7042 5.65409 15.896 6.79364L19.1944 3.49523C17.2029 1.63955 14.5997 0.5 11.4999 0.5C7.00445 0.5 3.11536 3.07705 1.22308 6.83545L5.06513 9.815C5.96945 7.09682 8.50468 5.07386 11.4999 5.07386Z"
                    fill="#EA4335"
                  />
                </svg>
              }
            />
            {isLogin && (
              <Text variant="titleMedium" style={{ textAlign: 'center', marginBottom: 32 }}>
                You can also&nbsp;
                <Link to="/login/sso" style={LOGIN_SSO}>
                  log in with SAML SSO.
                </Link>
              </Text>
            )}
            <Text
              variant="titleMedium"
              text={isLogin ? "Don't have an account yet?" : 'Already have an account?'}
              style={{ ...TAGLINE, marginBottom: 4, marginTop: 0, alignSelf: 'center' }}
            />
            <Text
              onClick={() => {
                setIsLogin(!isLogin)
                if (isLogin) {
                  setCreatingWorkspace(false)
                } else {
                  setCreatingWorkspace(true)
                }
              }}
              variant="titleMedium"
              text={
                isLogin ? 'Sign up for a free Trivie account today' : 'Sign in to your workspace'
              }
              style={{
                ...TAGLINE,
                marginBottom: 0,
                alignSelf: 'center',
                color: color.blue,
                marginTop: 0,
                cursor: 'pointer',
              }}
            />
          </>
        )}
      </Box>
    </Dialog>
  )
})

const TAGLINE: React.CSSProperties = { marginTop: 32, marginBottom: 32, color: color.shade70 }
const SEPARATOR: React.CSSProperties = {
  flexDirection: 'row',
  justifyContent: 'center',
  alignItems: 'center',
  display: 'flex',
  paddingRight: 40,
  paddingLeft: 40,
  marginBottom: 32,
}
const LOGIN_SSO: React.CSSProperties = {
  color: color.blue,
  borderBottom: `1px solid ${color.blue}`,
  textDecoration: 'none',
}
const ERROR_STATE: React.CSSProperties = {
  width: 349,
  height: 64,
  marginBottom: 25,
  borderRadius: 16,
  backgroundColor: 'rgba(249, 234, 234, 1)',
  paddingTop: 10,
  paddingBottom: 10,
  paddingRight: 20,
  display: 'flex',
  flexDirection: 'row',
  paddingLeft: 20,
  alignItems: 'center',
  alignSelf: 'center',
}
