import { types, getEnv, flow, getRoot, cast } from 'mobx-state-tree'
import { Environment } from '../environment'

// @ts-ignore
import { decode } from 'base-64'
import * as storage from '../../services/storage'
import { UserApi } from '../../services/api-objects/UserApi'
import { WorkspaceResponse } from '../WorkspaceResponse'
import { AuthenticationApi } from '../../services/api-objects/AuthenticationApi'
import { Awaited } from '../../utility-types'
import { UserProfile } from '../UserProfile'
import { UserGameStats } from '../UserGameStats'
import { UserAdaptive } from '../UserAdaptive'
import { UserLevelAndPoints } from '../UserLevelAndPoints'
import {
  MedalDetailResponse,
  UserCertificationDetail,
  UserCertificationMedals,
  UserMedalDetail,
} from '../UserCertificationMedal'
import { NotificationCheck } from '../NotificationCheck'
import { activityTransformer } from '../../utils'
import { FlowCacheModel, useFlowCacheMiddleware } from '../../middleware'
import { UpNextTileModel } from '../UpNextTile'
const _orderBy = require('lodash/sortBy')

const ACTIONS_TO_CACHE = {
  getBranding: { wait: 120 },
  // getUpNext: { wait: 120 },
}

export const UserStoreModel = types
  .model('UserStore')
  .props({
    status: types.optional(
      types.enumeration(['idle', 'pending', 'done', 'error', 'pendingRecall']),
      'idle',
    ),
    userLoaded: types.optional(types.enumeration(['idle', 'pending', 'done', 'error']), 'idle'),
    user: types.maybeNull(UserProfile),
    token: types.frozen(),
    notifications: types.frozen(),
    sharedQuiz: types.frozen(),
    splashNotifications: types.array(NotificationCheck),
    showingAlert: types.maybe(types.boolean),
    stats: types.maybeNull(UserGameStats),
    instance: types.frozen(),
    email: types.frozen(),
    permissions: types.frozen(),
    displayCobranding: types.frozen(),
    company_name: types.frozen(),
    logoPath: types.frozen(),
    rawUserActivity: types.frozen(), // has a view, see userActivity below, don't use directly
    accessCode: types.frozen(),
    showHourlyWarning: types.frozen(),
    ssoRedirect: types.maybe(types.string),
    ssoWorkspace: types.maybe(WorkspaceResponse),
    adaptive: types.maybeNull(UserAdaptive),
    redirect: types.frozen(),
    levelAndPoints: types.maybeNull(UserLevelAndPoints),
    userCertsMedals: types.maybeNull(UserCertificationMedals),
    certificationDetail: types.maybe(UserCertificationDetail),
    medalDetail: types.maybe(types.array(MedalDetailResponse)),
    userMedalDetail: types.maybe(UserMedalDetail),
    showBrandedSplash: types.maybe(types.boolean),
    phoneNumber: types.maybe(types.string),
    formattedPhoneNumber: types.maybe(types.string),
    otpNotFound: types.maybe(types.boolean),
    showAppRating: types.maybe(types.boolean),
    flowCache: types.map(types.map(FlowCacheModel)),
    onesignalID: types.frozen(),
    upNext: types.array(UpNextTileModel),
    loadingUpNext: types.maybe(types.boolean),
  })
  .actions((self) => ({
    afterCreate() {
      useFlowCacheMiddleware({ store: self, cacheable: ACTIONS_TO_CACHE })
    },
    setUpNext(upNext: typeof self.upNext) {
      self.upNext = [] as any
      self.upNext = cast(upNext)
    },
    setLoadingUpNext(loading) {
      self.loadingUpNext = loading
    },
    setSharedQuiz(quiz) {
      self.sharedQuiz = quiz
    },
    setStatus(value?: 'idle' | 'pending' | 'done' | 'error' | 'pendingRecall') {
      self.status = value || 'idle'
    },
    setShowAppRating(show: boolean) {
      self.showAppRating = show
    },
    setOtpNotFound(status: boolean) {
      self.otpNotFound = status
    },
    setPhoneNumber(number: string) {
      self.phoneNumber = number
    },
    setFormattedPhoneNumber(number: string) {
      self.formattedPhoneNumber = number
    },
    setMedalDetail(data: typeof self.medalDetail) {
      self.medalDetail = data
    },
    setUserMedalDetail(data: typeof self.userMedalDetail) {
      self.userMedalDetail = data
    },
    setCertificationDetail(data: typeof self.certificationDetail) {
      self.certificationDetail = data
    },
    setUserCertsMedals(data: typeof self.userCertsMedals) {
      self.userCertsMedals = data
    },
    setUserLoaded(value?: 'idle' | 'pending' | 'done' | 'error') {
      self.userLoaded = value || 'idle'
    },
    setRedirect(route: any) {
      self.redirect = route
    },
    setPermissions(permissions: any) {
      self.permissions = permissions as any
    },
    setEmail(email: string) {
      self.email = email
    },
    setWorkspace(workspace: any) {
      self.instance = workspace
    },
    setUser(user: typeof self.user) {
      self.user = user
    },
    setToken(token: any) {
      self.token = token
    },
    setNotifications(notifications: any) {
      self.notifications = notifications as any
    },
    setSplashNotifications(data: typeof self.splashNotifications) {
      self.splashNotifications = data
    },
    setShowingAlert(status: boolean) {
      self.showingAlert = status
    },
    setStats(stats: typeof self.stats) {
      self.stats = cast(stats)
    },
    setDisplayCobranding(displayCobranding: any) {
      self.displayCobranding = displayCobranding
    },
    setCompanyName(company_name: any) {
      self.company_name = company_name
    },
    setLogoPath(logoPath: any) {
      self.logoPath = logoPath
    },
    setAccessCode(code: any) {
      self.accessCode = code
    },
    setShowHourlyWarning(status: boolean) {
      self.showHourlyWarning = status
    },
    setSSORedirectURL(url: string | undefined) {
      self.ssoRedirect = url
    },
    setSSOWorkspace(workspace: typeof self.ssoWorkspace) {
      self.ssoWorkspace = workspace
    },
    setAdaptive(data: typeof self.adaptive) {
      self.adaptive = cast(data)
    },
    setUserLevelAndPoints(levelAndPoints: typeof self.levelAndPoints) {
      self.levelAndPoints = levelAndPoints
    },
    setShowBrandedSplash(show: boolean) {
      self.showBrandedSplash = show
    },
    setRawUserActivity(activity: typeof self.rawUserActivity) {
      self.rawUserActivity = activity
    },
    setOnesignal(id: any) {
      self.onesignalID = id
    },
  }))
  .views((self) => ({
    get freeTier() {
      return self.user?.workspace_settings?.plan_tier === 'free'
    },
    get standardTier() {
      return self.user?.workspace_settings?.plan_tier === 'standard'
    },
    get proTier() {
      return self.user?.workspace_settings?.plan_tier === 'pro'
    },
    get enterpriseTier() {
      return self.user?.workspace_settings?.plan_tier === 'enterprise'
    },
    get environment() {
      return getEnv(self) as Environment
    },
    get isLoading() {
      return self.status === 'pending' || self.status === 'pendingRecall'
    },
    get rootStore(): any {
      return getRoot(self) as any
    },
    get splashNotification() {
      return self.splashNotifications[0]
    },
    get userActivity() {
      const result = {}
      if (self.rawUserActivity) {
        try {
          return activityTransformer(self.rawUserActivity)
        } catch (error) {
          console.log(`activityTransformer error`, error)
        }
      }
      return result
    },
    get sortedUpNext() {
      /**
       * - Items which are due SOONER appear above items that are due LATER.
       * - Items due at the same time:
       *   - Learning Path “Next Steps” go above other items.
       *   - Then tests, then everything else.
       *   - Then we sort alphabetically.
       */
      return _orderBy(
        self.upNext,
        [
          (item) => new Date(item.due_date || new Date(8640000000000000)),
          (item) => (item.path ? 'a' : 'b'),
          (item) => (item.test ? 'a' : 'b'),
          (item) => item.object_title.toLowerCase(),
        ],
        ['asc', 'asc', 'asc', 'asc'],
      )
    },
  }))
  .actions((self) => ({
    getUpNext: flow(function* () {
      self.setLoadingUpNext(true)
      try {
        const result: Awaited<ReturnType<typeof UserApi.getUpNext>> = yield UserApi.getUpNext(
          self.user!.id,
        )
        if (result.ok && result.data) {
          self.setUpNext(result.data.objects)
          self.setLoadingUpNext(false)
          return result.data
        } else {
          self.setLoadingUpNext(false)
          self.setStatus('error')
        }
      } catch {
        self.setLoadingUpNext(false)
        self.setStatus('error')
        return false
      }
    }),
    getTalkPermission: function (code: string) {
      let permission = false
      if (self.permissions?.restrict_talk) {
        self.permissions?.permissions?.forEach((p: any) => {
          if (p.code === code) {
            permission = true
          }
        })
        return permission
      } else {
        return true
      }
    },
    getPermission: function (code: string) {
      let permission = false
      self.permissions?.permissions?.forEach((p: any) => {
        if (p.code === code) {
          permission = true
        }
      })
      return permission
    },
    subscriptionWebhook: flow(function* (payload: any, vendor: string) {
      self.setStatus('pending')
      try {
        const result = yield self.environment.api.subscriptionWebhook(payload, vendor)
        if (result.kind === 'ok') {
          self.setStatus('done')
        } else {
          self.setStatus('error')
        }
      } catch {
        self.setStatus('error')
      }
    }),
    whitelabelSignup: flow(function* (payload: any, appID: any) {
      self.setStatus('pending')
      try {
        const formData: any = new FormData()
        formData.append('email', payload.email)
        formData.append('first_name', payload.first_name)
        formData.append('last_name', payload.last_name)
        formData.append('app_id', payload.app_id)

        const result = yield self.environment.api.whitelabelSignup(formData, appID)
        if (result.kind === 'ok') {
          // take access code and token from response and log the user in
          self.setStatus('done')
          return result.data
        } else {
          self.setStatus('error')
        }
      } catch {
        self.setStatus('error')
      }
    }),
    handleTerms: flow(function* (decision, token, workspace_id) {
      self.setStatus('pending')
      try {
        const result: Awaited<ReturnType<typeof UserApi.handleTerms>> = yield UserApi.handleTerms({
          decision,
          token,
          workspace_id,
        })
        if (result.ok && result.data) {
          self.setStatus('done')
          return true
        } else {
          self.setStatus('error')
          return false
        }
      } catch {
        self.setStatus('error')
        return false
      }
    }),
    registerOnesignal: flow(function* (id: any) {
      self.setStatus('pending')
      try {
        self.setOnesignal(id)
        const result = yield self.environment.api.registerOnesignalID(id)
        if (result.kind === 'ok') {
          self.setStatus('done')
        } else {
          self.setStatus('error')
        }
      } catch {
        self.setStatus('error')
      }
    }),
    evaluateMagicLink: flow(function* (token: any, email?) {
      self.setStatus('pending')
      try {
        const result = yield self.environment.api.evaluateMagicLink({
          for_insights: false,
          token,
          ...(email && { email }),
        })
        if (result.kind === 'ok') {
          self.setWorkspace(result.data)
          self.setStatus('done')
          return result.data
        }
        self.setStatus('error')
      } catch {
        self.setStatus('error')
      }
    }),
    sendMagicLink: flow(function* (data: string, appID?: string, sms?: boolean) {
      self.setStatus('pending')
      if (!sms) {
        self.setEmail(data)
      }
      try {
        const result = yield self.environment.api.sendMagicLink(data, appID, sms)
        if (result.kind === 'ok') {
          self.setStatus('done')
          return result
        } else {
          self.setStatus('error')
        }
      } catch {
        self.setStatus('error')
      }
    }),
    evaluateOTC: flow(function* (code: string, email?: string, phone_number?: string) {
      self.setStatus('pending')
      try {
        const result = yield self.environment.api.evaluateOTC(code, email, phone_number)
        if (result.kind === 'ok') {
          self.setWorkspace(result.data)
          self.setStatus('done')
          self.setOtpNotFound(false)
          return result.data
        } else {
          self.setOtpNotFound(true)
        }
      } catch {
        self.setOtpNotFound(true)
        self.setStatus('error')
      }
    }),
    getGameStats: flow(function* (id) {
      self.setStatus('pending')
      try {
        const result: Awaited<ReturnType<typeof UserApi.getGameStats>> =
          yield UserApi.getGameStats(id)
        if (result.ok && result.data) {
          self.setStats(result.data)
          self.setStatus('done')
          return result.data
        } else {
          self.setStatus('error')
        }
      } catch {
        self.setStatus('error')
      }
    }),
    readNotification: flow(function* (id: number) {
      self.setStatus('pending')
      try {
        // @ts-ignore
        const result = yield self.environment.api.readNotification(self.user.id, id)
        if (result.kind === 'ok') {
          self.setStatus('done')
        } else {
          self.setStatus('error')
        }
      } catch {
        self.setStatus('error')
      }
    }),
    getPermissions: flow(function* (id: number) {
      try {
        const result = yield self.environment.api.getPermissions(id)
        if (result.kind === 'ok') {
          self.setPermissions(result.data)
        } else {
          self.setStatus('error')
        }
      } catch {
        self.setStatus('error')
      }
    }),

    getUserDataRequest: flow(function* () {
      self.setStatus('pending')
      try {
        const result = yield UserApi.getUserDataRequest()
        if (result.ok && result.data) {
          self.setStatus('done')
          return result.data!
        } else {
          self.setStatus('error')
        }
      } catch (err) {
        self.setStatus('error')
      }
    }),

    getUser: flow(function* (id?: number, t?: any) {
      self.setStatus('pending')
      self.setUserLoaded('pending')
      try {
        let result
        if (id) {
          result = yield self.environment.api.getUserProfile(id)
        }
        if (result.kind === 'ok') {
          self.setUser(result.data)
          self.setStatus('done')
          self.setUserLoaded('done')
          return result.data
        } else {
          self.setStatus('error')
        }
      } catch {
        self.setStatus('error')
      }
    }),
    getUserActivity: flow(function* (userId: number) {
      self.setStatus('pending')
      try {
        const result: Awaited<ReturnType<typeof UserApi.getUserActivity>> =
          yield UserApi.getUserActivity(userId)
        if (result.ok) {
          self.setRawUserActivity(result.data!)
          self.setStatus('done')
        } else {
          self.setStatus('error')
        }
      } catch {
        self.setStatus('error')
      }
    }),
    updateUser: flow(function* (userUpdate: any) {
      self.setStatus('pending')

      if (!self.user) {
        self.setStatus('error')
        return
      }

      self.setUser({ ...self.user, ...userUpdate })
      try {
        const result = yield self.environment.api.updateUser(self.user.id, {
          ...self.user,
          ...userUpdate,
        })
        if (result.kind === 'ok') {
          //**if BE not able to provide user_level&workspace_settings, we should keep exist context*/
          if (result.data) {
            let resultData = result.data
            const { user_level, workspace_settings } = self.user
            if (!resultData.workspace_settings) {
              resultData = { ...resultData, workspace_settings }
            }
            if (!resultData.user_level) {
              resultData = { ...resultData, user_level }
            }
            self.setUser(resultData)
          }
          self.setStatus('done')
        } else {
          self.setStatus('error')
          return 'test'
        }
      } catch (e) {
        self.setStatus('error')
        return e
      }
    }),
    setUserCommSettings(settings: any) {
      if (!self.user) {
        self.setStatus('error')
        return
      }

      this.updateUser({
        ...self.user,
        ...settings,
        user_comm_settings: { ...self.user.user_comm_settings, ...settings },
      })
    },
    getBranding: flow(function* (id: number) {
      self.setStatus('pending')
      const defaultBranding = {
        id: 0,
        is_active: true,
        last_modified: '',
        web_font_url:
          'https://fonts.gstatic.com/s/inter/v7/UcCO3FwrK3iLTeHuS_fvQtMwCp50KnMw2boKoduKmMEVuLyfAZ9hjp-Ek-_EeA.woff',
        font_family: 'Inter',
        accent_color: '#1B59F8',
        native_font_url: '',
        correct_answer: '#00CB76',
        feedback_background: '#FFFFFF',
        feedback_header: '#551BFA',
        feedback_text: '#000000',
        incorrect_answer: '#ED3939',
        logo_url: '',
        quiz_background: '#151E23',
        review_background: '#1D252B',
        text_color: '#ffffff',
        review_background_text: '#ffffff',
      }
      const { uiStore } = self.rootStore
      try {
        const result: Awaited<ReturnType<typeof UserApi.getUserProfile>> =
          yield UserApi.getBranding(id)
        if (result.ok) {
          uiStore.setBranding(result.data)
          self.setStatus('done')
        } else {
          uiStore.setBranding(defaultBranding)
          self.setStatus('error')
        }
      } catch {
        uiStore.setBranding(defaultBranding)
        self.setStatus('error')
      }
    }),
    login: flow(function* (user, access_code) {
      self.setStatus('pending')
      try {
        const result = yield self.environment.api.login(user, access_code)
        if (result.kind === 'ok') {
          storage.saveString('token', result.data.access_token)
          self.setToken(result.data.access_token)
          self.setStatus('done')
          return result.data.user_id
        } else {
          self.setStatus('error')
        }
      } catch {
        self.setStatus('error')
      }
    }),
    getNotifications: flow(function* (id: number) {
      self.setStatus('pending')
      try {
        const apiToken = self.environment.api.token
        let decoded
        if (apiToken) {
          decoded = decode(apiToken!.split('.')[1])
        }
        const tokenData: any = apiToken ? JSON.parse(decoded).user_id : (null as any)
        const result = yield self.environment.api.getNotifications(tokenData ? tokenData : id)
        if (result.kind === 'ok') {
          self.setNotifications(result.data)

          self.setStatus('done')
        } else {
          self.setStatus('error')
        }
      } catch {
        self.setStatus('error')
      }
    }),
    checkNotifications: flow(function* (
      user_id: number,
      notification_id?: number,
      include_viewed?: boolean,
    ) {
      try {
        const result = yield self.environment.api.checkNotifications(
          user_id,
          notification_id,
          include_viewed,
        )
        if (result.kind === 'ok') {
          self.setSplashNotifications(cast(result.data))
        } else {
          self.setStatus('error')
        }
      } catch {
        self.setStatus('error')
      }
    }),
    getAccessCodeDetails: flow(function* (access_code: string) {
      self.setStatus('pending')
      try {
        const result: Awaited<ReturnType<typeof AuthenticationApi.getAccessCodeDetails>> =
          yield AuthenticationApi.getAccessCodeDetails(access_code, false)
        if (result.ok && result.data) {
          self.setSSORedirectURL(result.data.redirect_url)
          self.setStatus('done')
          return result.data.redirect_url
        } else {
          self.setStatus('error')
        }
      } catch {
        self.setStatus('error')
      }
    }),
    loginWorkspace: flow(function* (token: string, access_code: string, navigate?: () => void) {
      self.setStatus('pending')
      try {
        const result = yield self.environment.api.loginWorkspace(token, access_code)
        const rootStore = getRoot(self) as any
        if (result.kind === 'ok') {
          // self.setSSORedirectURL('')
          self.setAccessCode(access_code)
          ;(self as any).workspaceVerify()
          self.setShowHourlyWarning(true)
          storage.saveString('token', result.data.access_token)
          ;(self as any).getUser(result.data.user_id, result.data.access_token).then(() => {
            self.setToken(result.data.access_token)
            if (self.enterpriseTier) {
              rootStore?.libraryStore?.getTopicsForUser(result.data.user_id)
              rootStore?.pathStore?.getPathsForUser(result.data.user_id)
            }
            rootStore?.libraryStore?.getResourcesForUser(result.data.user_id)
            rootStore?.libraryStore?.getQuizzesForUser(result.data.user_id)
            ;(self as any).getNotifications(result.data.user_id)
            if (navigate) {
              navigate()
            }
          })
          self.setStatus('done')
          return result.data
        } else {
          self.setStatus('error')
        }
      } catch {
        self.setStatus('error')
      }
    }),
    loginShared: flow(function* (
      code: string,
      access_code: string,
      quiz_id: number,
      email?: string,
    ) {
      self.setStatus('pending')
      try {
        const result: Awaited<ReturnType<typeof AuthenticationApi.loginShared>> =
          yield AuthenticationApi.loginShared(code, access_code, quiz_id, email)

        if (result.ok && result.data) {
          // self.setSSORedirectURL('')
          self.setAccessCode(access_code)
          ;(self as any).getUser(result.data.user_id).then(() => {
            self.setToken(result.data.token)
            storage.saveString('token', result.data.token)
          })
          self.setStatus('done')
          return result.data
        } else {
          self.setStatus('error')
        }
      } catch {
        self.setStatus('error')
      }
    }),
    urlVerify: flow(function* (url: string) {
      self.setStatus('pending')

      try {
        const result = yield self.environment.api.workspaceVerify('', url)
        if (result.kind === 'ok') {
          self.setCompanyName(result.data.company_name)
          self.setDisplayCobranding(result.data.display_cobranding)
          self.setLogoPath(result.data.logo_path)
          if (result.data.display_cobranding && result.data.logo_path) {
            self.setShowBrandedSplash(true)
          }
          self.setStatus('done')
          return result.data
        } else {
          self.setStatus('error')
        }
      } catch {
        self.setStatus('error')
      }
    }),
    workspaceVerify: flow(function* (accessCode?: string) {
      self.setStatus('pending')

      if (!self.accessCode && !accessCode) {
        return
      }

      try {
        const result = yield self.environment.api.workspaceVerify(accessCode ?? self.accessCode)
        if (result.kind === 'ok') {
          self.setCompanyName(result.data.company_name)
          self.setDisplayCobranding(result.data.display_cobranding)
          self.setLogoPath(result.data.logo_path)
          if (result.data.display_cobranding && result.data.logo_path) {
            self.setShowBrandedSplash(true)
          }
          self.setStatus('done')
          return result.data
        } else {
          self.setStatus('error')
        }
      } catch {
        self.setStatus('error')
      }
    }),
    uploadProfilePhoto: flow(function* (photo, id) {
      self.setStatus('pending')
      self.setUserLoaded('pending')
      try {
        const result = yield self.environment.api.uploadProfilePhoto(photo, id)
        ;(self as any).getUser(id)
        if (result.kind === 'ok') {
          self.setStatus('done')
        } else {
          self.setStatus('error')
        }
      } catch {
        self.setStatus('error')
      }
    }),
    reportProblem: flow(function* (text: string, subject: string) {
      self.setStatus('pending')
      try {
        const result = yield self.environment.api.reportProblem(text, subject)
        if (result.kind === 'ok') {
          self.setStatus('done')
        } else {
          self.setStatus('error')
        }
      } catch {
        self.setStatus('error')
      }
    }),
    logout(persist = false) {
      if (persist && self.ssoRedirect) {
        self.setToken('')
        self.setUserLevelAndPoints(null)
        self.setUser(null)
        self.environment.api.removeAuthHeaders()
      } else {
        self.setToken('')
        self.setUserLevelAndPoints(null)
        self.setUser(null)
        storage.load('redirecting-sso').then((data) => {
          // clear storage, but retain whether we are redirecting
          // back from sso.
          storage.clear()
          if (data) {
            storage.save('redirecting-sso', true)
          }
          self.environment.api.removeAuthHeaders()
        })
      }
    },
    setLanguage(language: string) {
      if (!self.user) {
        self.setStatus('error')
        return
      }
      self.setUser({
        ...self.user,
        language,
      })
      this.updateUser({ language })
    },
    getUserLevelAndPoints: flow(function* (user_id, startingTime: string, endingTime: string) {
      self.setStatus('pending')
      try {
        const result: Awaited<ReturnType<typeof UserApi.getUserLevelAndPoints>> =
          yield UserApi.getUserLevelAndPoints(user_id, startingTime, endingTime)

        if (result.ok && result.data) {
          self.setUserLevelAndPoints(result.data)
          self.setStatus('done')
          return result.data
        } else {
          self.setStatus('error')
        }
      } catch (e) {
        self.setStatus('error')
      }
    }),
    getCertsMedalsForUser: flow(function* (id: number) {
      self.setStatus('pending')
      try {
        const result: Awaited<ReturnType<typeof UserApi.getCertsMedalsForUser>> =
          yield UserApi.getCertsMedalsForUser(id)

        if (result.ok && result.data) {
          self.setUserCertsMedals(result.data)
          self.setStatus('done')
          return result.data
        } else {
          self.setStatus('error')
        }
      } catch (e) {
        self.setStatus('error')
      }
    }),
    getUserMedalDetail: flow(function* (user_id: number, user_medal_id: number) {
      self.setStatus('pending')
      try {
        const result: Awaited<ReturnType<typeof UserApi.getUserMedalDetail>> =
          yield UserApi.getUserMedalDetail(user_id, user_medal_id)

        if (result.ok && result.data) {
          self.setUserMedalDetail(result.data)
          self.setStatus('done')
          return result.data
        } else {
          self.setStatus('error')
        }
      } catch (e) {
        self.setStatus('error')
      }
    }),
    getMedalDetail: flow(function* () {
      self.setStatus('pending')
      try {
        const result: Awaited<ReturnType<typeof UserApi.getMedalDetail>> =
          yield UserApi.getMedalDetail()

        if (result.ok && result.data) {
          self.setMedalDetail(cast(result.data))
          self.setStatus('done')
          return result.data
        } else {
          self.setStatus('error')
        }
      } catch (e) {
        self.setStatus('error')
      }
    }),
    getCertificationDetail: flow(function* (
      user_id: number,
      certification_id: number,
      ignoreStatus = false,
    ) {
      if (!ignoreStatus) self.setStatus('pending')
      try {
        const result: Awaited<ReturnType<typeof UserApi.getCertificationDetail>> =
          yield UserApi.getCertificationDetail(user_id, certification_id)

        if (result.ok && result.data) {
          self.setCertificationDetail(result.data)
          self.setStatus('done')
          return result.data
        } else {
          self.setStatus('error')
        }
      } catch (e) {
        self.setStatus('error')
      }
    }),
    getAppRating: flow(function* (user_id: number) {
      self.setStatus('pending')
      try {
        const result: Awaited<ReturnType<typeof UserApi.getAppRating>> =
          yield UserApi.getAppRating(user_id)

        if (result.ok && result.data) {
          self.setShowAppRating(result.data.ready_for_feedback)
          self.setStatus('done')
          return result.data.ready_for_feedback
        } else {
          self.setStatus('error')
        }
      } catch (e) {
        self.setStatus('error')
      }
    }),
    gdprConsent: flow(function* (user_id: number, payload: any) {
      self.setStatus('pending')
      try {
        const result: Awaited<ReturnType<typeof UserApi.gdprConsent>> = yield UserApi.gdprConsent(
          user_id,
          payload,
        )
        if (result.ok && result.data) {
          self.setStatus('done')
        } else {
          self.setStatus('error')
        }
      } catch (e) {
        self.setStatus('error')
      }
    }),
    saveAppRating: flow(function* (user_id: number, rating: number | null, dismiss?: boolean) {
      self.setStatus('pending')
      try {
        const result: Awaited<ReturnType<typeof UserApi.saveAppRating>> =
          yield UserApi.saveAppRating(user_id, rating, dismiss)

        if (result.ok && result.data) {
          self.setStatus('done')
          return result.data
        } else {
          self.setStatus('error')
        }
      } catch (e) {
        self.setStatus('error')
      }
    }),
  }))

type UserStoreType = typeof UserStoreModel.Type
export interface UserStore extends UserStoreType {}
type UserStoreSnapshotType = typeof UserStoreModel.SnapshotType
export interface UserStoreSnapshot extends UserStoreSnapshotType {}
