import React, { useEffect, useState } from 'react'
import { observer } from 'mobx-react-lite'
import { useRouteMatch } from 'react-router-dom'

import { useStores } from '@trivie/core'
import { TableHelper } from '../../../../tables'
import { AssignmentTable } from '../../../../tables/assignment-table'

const Assignments: React.FunctionComponent = observer(() => {
  const match = useRouteMatch<any>('/people/users/:id/assignments')
  const { assignmentStore, manageUserStore } = useStores()
  const { selectedUserId, setSelectedUserId, getSelectedUserProfile } = manageUserStore

  const [tableHelper] = useState(
    new TableHelper(
      'list_user_assignments',
      'assignments',
      'users',
      Number(match?.params.id),
      'active',
    ),
  )

  useEffect(() => {
    if (!selectedUserId) {
      setSelectedUserId(Number(match?.params.id))
    }
    getSelectedUserProfile()
  }, [])

  useEffect(() => {
    if (assignmentStore.triggerRefresh) {
      tableHelper.load().then(() => assignmentStore.setTriggerRefresh(false))
    }
  }, [assignmentStore.triggerRefresh])

  return (
    <AssignmentTable
      forceLoading={assignmentStore.triggerRefresh}
      filter="active"
      tableHelper={tableHelper}
    />
  )
})

export const UsersAssignmentsTab = (props: any) => {
  const { id } = props
  return {
    name: 'Assignments',
    path: `/people/users/${id}/assignments`,
    component: () => <Assignments />,
  }
}
