import React from 'react'
import { observer } from 'mobx-react-lite'
import { prop, sortBy } from 'ramda'
import XLSX from 'xlsx'

import makeStyles from '@mui/styles/makeStyles'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TablePagination from '@mui/material/TablePagination'
import TableRow from '@mui/material/TableRow'
import { DownloadRounded, KeyboardArrowDown } from '@mui/icons-material'

import { Button } from '../button'

import { LeaderboardRowItem } from './leaderboard-row-item'
import { Panel } from '../panel/panel'
import { TableFooter } from '@mui/material'

const useStyles = makeStyles({
  root: {},
  container: {
    display: 'flex',
    width: '100%',
    height: '100%',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
  cell: {
    borderBottom: 'none',
    padding: 0,
  },
  pagination: {
    '& .MuiTablePagination-toolbar': {
      minHeight: 0,
    },
    '& .MuiSelect-selectMenu': {
      minHeight: 0,
    },
    '& .MuiSelect-icon': {
      fontSize: 16,
      color: 'rgba(0, 0, 0, 0.3)',
      top: 'none',
      right: 8,
    },
    '& .MuiSelect-select:focus': {
      backgroundColor: 'none',
    },
  },
})

export const Leaderboard = observer((props: any) => {
  const tableClasses = useStyles()
  const [page, setPage] = React.useState(0)
  const [rowsPerPage, setRowsPerPage] = React.useState(10)

  const { hideExport, showMore, type, data } = props

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(+event.target.value)
    setPage(0)
  }

  const sortByRank = sortBy(prop('rank'))

  const exportToCsv = () => {
    const wb = XLSX.utils.book_new()
    const ws = XLSX.utils.json_to_sheet(
      data.map((user) => {
        const { last_name, first_name, email, points, rank } = user
        const headers = { last_name, first_name, email, score: points, rank }
        if (Object.hasOwn(user, 'employee_id')) {
          headers['employee_id'] = user.employee_id
        }
        return headers
      }),
    )
    XLSX.utils.book_append_sheet(wb, ws, 'Leaderboard')
    XLSX.writeFile(wb, 'leaderboard.csv')
  }

  const rowsPerPageOptions = [1, 5, 10]
  if (data?.length > 20) rowsPerPageOptions.push(20)
  if (data?.length > 40) rowsPerPageOptions.push(40)
  return (
    <Panel
      sx={{ flex: 1 }}
      title={
        type === 'contest'
          ? 'Leaderboard'
          : type === 'user'
            ? 'User Leaderboard'
            : 'Group Leaderboard'
      }
      action={
        hideExport ? null : (
          <Button
            variant="outlined"
            size="small"
            color="info"
            startIcon={<DownloadRounded />}
            onClick={exportToCsv}
            text="Export"
          />
        )
      }
    >
      <TableContainer className={tableClasses.container}>
        <Table stickyHeader aria-label="sticky table">
          <TableBody>
            {data
              ? sortByRank(data)
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row: any) => {
                    return (
                      <TableRow style={{ height: 66 }} tabIndex={-1}>
                        <TableCell className={tableClasses.cell} align="left">
                          {type === 'user' || type === 'contest' ? (
                            <LeaderboardRowItem
                              // @ts-ignore
                              user={row}
                            />
                          ) : (
                            <LeaderboardRowItem
                              // @ts-ignore
                              group={row}
                            />
                          )}
                        </TableCell>
                      </TableRow>
                    )
                  })
              : null}
          </TableBody>
        </Table>
        {showMore ? (
          <TableFooter sx={{ justifyContent: 'center', display: 'flex' }}>
            <Button onClick={showMore} variant="text" text="View Full Leaderboard" />
          </TableFooter>
        ) : (
          <TablePagination
            className={tableClasses.pagination}
            labelDisplayedRows={({ from, to, count }) => `Showing: ${from}–${to} of ${count}`}
            rowsPerPageOptions={rowsPerPageOptions}
            component="div"
            SelectProps={{ IconComponent: KeyboardArrowDown }}
            count={data ? data.length : 0}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        )}
      </TableContainer>
    </Panel>
  )
})
