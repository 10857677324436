import React, { useState } from 'react'
import { observer } from 'mobx-react-lite'

import { useStores } from '@trivie/core'

import { TableHelper } from '../../../../tables'

import { SurveyTable } from '../../../../tables/survey-table'

const LiveSurveys: React.FunctionComponent = observer(() => {
  const { surveyStore } = useStores()

  const [loading, setLoading] = useState(true) as any

  const [tableHelper] = useState(
    new TableHelper('list_live_surveys', 'surveys', 'surveys', undefined, 'live'),
  )

  return <SurveyTable filter="live" tableHelper={tableHelper} />
})

export const LiveSurveysTab = () => {
  return {
    name: 'Live',
    path: '/activities/surveys/live',
    component: () => <LiveSurveys />,
  }
}
