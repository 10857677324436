export const breadcrumbFilter = (breadcrumbs: any) => {
  let filteredBreadcrumb = []
  filteredBreadcrumb = breadcrumbs.filter(({ breadcrumb }: any) => {
    return Number.isNaN(Number((breadcrumb as any).props.children))
  })
  // @ts-ignore
  if (filteredBreadcrumb[1]?.breadcrumb?.props?.children === 'Assignments') {
    // @ts-ignore
    filteredBreadcrumb[1].key = filteredBreadcrumb[2].key
    if (filteredBreadcrumb[3]) {
      filteredBreadcrumb = filteredBreadcrumb.filter((bc: any, index: number) => index !== 2)
    }
  }
  return filteredBreadcrumb
}
