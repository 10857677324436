import { types } from 'mobx-state-tree'

export const PendingUser = types.model('UserProfile').props({
  user_id: types.number,
  display_name: types.string,
  email: types.maybeNull(types.string),
  phone_number: types.maybeNull(types.string),
  invited_by: types.string,
  date_invited: types.maybeNull(types.string),
})
