import React, { useState } from 'react'
import {
  BoltRounded,
  Check,
  Circle,
  Close,
  DescriptionRounded,
  GroupsTwoTone,
  MenuBook,
  PeopleRounded,
  PersonRounded,
  Settings,
  Topic,
} from '@mui/icons-material'
import { Collapse, Paper } from '@mui/material'
import { Text } from '@trivie/ui-web/components/text'
import { color } from '@trivie/ui-web/theme'
import { observer } from 'mobx-react-lite'
import { DateTime } from 'luxon'

import { roundNumber, useStores } from '@trivie/core'
import { ExpandMore } from '@trivie/ui-web/components/expand-button/expand-button'

export const AssignmentDetailPanel = observer((props: any) => {
  const { manageUserStore } = useStores()
  const { userProfile } = manageUserStore
  const { count, scheduled, completed, assignment, content, assignees, style } = props

  const [expanded, setExpanded] = useState(true)

  const getEndDate = () => {
    return DateTime.fromISO(assignment?.end_date).toFormat('MMMM d, yyyy')
  }

  const getStartDate = () => {
    return DateTime.fromISO(assignment?.start_date).toLocaleString(DateTime.DATETIME_FULL)
  }

  const getDaysLeft = () => {
    const diff = DateTime.now().diff(DateTime.fromISO(assignment?.end_date), 'days').toObject()
    const { days } = diff as any
    if (-1 * days < 0) {
      return 0
    } else {
      return roundNumber(-1 * days, 0)
    }
  }

  const getContentSubtitle = () => {
    let label = ''
    let pathCount = 0
    let topicCount = 0
    let sourceCount = 0
    let quizCount = 0

    if (content?.paths?.length) {
      content?.paths.map((path) => {
        pathCount++
        path?.nodes.map((node) => {
          if (node.object_type === 'Quiz') {
            quizCount += 1
          } else if (node.object_type === 'Topic') {
            topicCount += 1
          } else if (node.object_type === 'Resource') {
            sourceCount += 1
          }
        })
      })
      if (!userProfile.workspace_settings.learning_paths_enabled) {
        label = `${topicCount} Topic${topicCount === 1 ? '' : 's'}, ${quizCount} Quiz${
          quizCount === 1 ? '' : 'zes'
        }`
      } else {
        label = `${pathCount} Path${pathCount === 1 ? '' : 's'}: ${topicCount} Topic${
          topicCount === 1 ? '' : 's'
        }, ${quizCount} Quiz${quizCount === 1 ? '' : 'zes'}, ${sourceCount} Source${
          sourceCount === 1 ? '' : 's'
        }`
      }
    } else if (content?.topics?.length > 0) {
      label = `${content?.topics?.length} Topic${content?.topics?.length === 1 ? '' : 's'}`
    } else if (content?.quizzes?.length > 0) {
      label = `${content?.quizzes?.length} Quiz${content?.quizzes?.length === 1 ? '' : 'zes'}`
    }
    return label
  }

  return (
    <Paper sx={{ p: '20px' }} variant="outlined" style={style}>
      <div onClick={() => setExpanded(!expanded)} style={ROOT}>
        <div style={TITLE_ROW}>
          <Text variant="titleEmphasized" text={assignment?.title} />
          <ExpandMore
            expand={expanded}
            onClick={() => setExpanded(!expanded)}
            style={{ left: 14 }}
          />
        </div>
        <div style={DATE_ROW}>
          <Text
            style={{ color: color.shade50, marginRight: 40 }}
            variant="body2Medium"
            text={`${
              completed ? 'Started' : scheduled ? 'Starts' : 'Started'
            } on ${getStartDate()}`}
          />
          {assignment?.end_date && getDaysLeft() <= 30 ? (
            <>
              <Circle
                htmlColor={
                  getDaysLeft() <= 30 && getDaysLeft() > 7
                    ? color.yellow
                    : getDaysLeft() <= 7
                      ? color.red
                      : color.transparent
                }
                style={{ height: 8, width: 8, marginRight: 8 }}
              />
              <Text
                style={{ color: color.shade50, marginRight: 40 }}
                variant="body2Emphasized"
                text={`${
                  getDaysLeft() === 0
                    ? 'Ended'
                    : `${getDaysLeft()} ${getDaysLeft() === 1 ? 'Day' : 'Days'} Left`
                }`}
              />
            </>
          ) : null}
        </div>
        <div style={{ display: 'flex', flexDirection: 'row' }}>
          <div
            style={{
              ...INFO_ROW,
              borderBottom: expanded ? '1px solid #EFF0F6' : 'none',
            }}
          >
            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
              <div style={INFO_ICON}>
                <Settings htmlColor={color.shade70} style={ICON} />
              </div>
              <Text text="Settings" variant="body1Emphasized" />
            </div>
          </div>
          <div
            style={{
              ...INFO_ROW,
              borderBottom: expanded ? '1px solid #EFF0F6' : 'none',
            }}
          >
            <div
              style={{
                flexDirection: 'row',
                display: 'flex',
                alignItems: 'center',
                paddingRight: 12,
              }}
            >
              <div style={INFO_ICON}>
                <MenuBook htmlColor={color.shade70} style={ICON} />
              </div>
              <Text text="Content" variant="body1Emphasized" />
            </div>
            <Text text={getContentSubtitle()} variant="body2Medium" />
          </div>
          <div
            style={{
              ...INFO_ROW,
              borderBottom: expanded ? '1px solid #EFF0F6' : 'none',
            }}
          >
            <div style={{ flexDirection: 'row', display: 'flex', alignItems: 'center' }}>
              <div style={INFO_ICON}>
                <GroupsTwoTone htmlColor={color.shade70} style={ICON} />
              </div>
              <Text text="Assignees" variant="body1Emphasized" />
            </div>
            {count !== undefined && (
              <Text
                text={count === 1 ? '1 User' : `${count} Total Users`}
                variant="body2Medium"
                style={{ color: color.shade70 }}
              />
            )}
          </div>
        </div>
      </div>
      <Collapse in={expanded} timeout="auto" unmountOnExit>
        <div style={COLLAPSED}>
          <div style={COLUMN}>
            {assignment?.end_date ? (
              <div style={ROW}>
                <Text style={{ color: color.shade70 }} text="End Date" variant="body1Emphasized" />
                <Text
                  style={{ color: color.shade70 }}
                  text={getEndDate()}
                  variant="body2Emphasized"
                />
              </div>
            ) : null}
            {assignment?.days_to_complete ? (
              <div style={ROW}>
                <Text
                  style={{ color: color.shade70 }}
                  text="Days To Complete"
                  variant="body1Emphasized"
                />
                <Text
                  style={{ color: color.shade70 }}
                  text={`${assignment?.days_to_complete} ${
                    assignment?.days_to_complete === 1 ? 'Day' : 'Days'
                  }`}
                  variant="body2Emphasized"
                />
              </div>
            ) : null}
            <div style={ROW}>
              <Text
                style={{ color: color.shade70 }}
                text="Notify Learners"
                variant="body1Emphasized"
              />
              {assignment?.notify ? (
                <Check style={{ fontSize: 16 }} />
              ) : (
                <Close style={{ fontSize: 16 }} />
              )}
            </div>
            <div style={ROW}>
              <Text
                style={{ color: color.shade70 }}
                text="Limit Quiz Attempt"
                variant="body1Emphasized"
              />
              <Text
                style={{ color: color.shade70 }}
                text={
                  assignment?.max_attempts === 999999
                    ? 'Unlimited'
                    : `${assignment?.max_attempts} Attempts`
                }
                variant="body2Emphasized"
              />
            </div>
            <div style={ROW}>
              <Text style={{ color: color.shade70 }} text="Pre-Test" variant="body1Emphasized" />
              {assignment?.includes_pre_test ? (
                <Check style={{ fontSize: 16 }} />
              ) : (
                <Close style={{ fontSize: 16 }} />
              )}
            </div>
            <div style={ROW}>
              <Text style={{ color: color.shade70 }} text="Post-Test" variant="body1Emphasized" />
              {assignment?.includes_post_test ? (
                <Check style={{ fontSize: 16 }} />
              ) : (
                <Close style={{ fontSize: 16 }} />
              )}
            </div>
          </div>
          <div style={COLUMN}>
            {content?.paths?.map((path) => {
              return (
                <>
                  <div style={CONTENT_ROW}>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      style={{ marginRight: 8 }}
                    >
                      <path
                        d="M19.15 21C18.4667 21 17.8708 20.7958 17.3625 20.3875C16.8542 19.9792 16.5333 19.4833 16.4 18.9H11.075C9.975 18.9 9.0625 18.5375 8.3375 17.8125C7.6125 17.0875 7.25 16.175 7.25 15.075C7.25 13.975 7.6125 13.0625 8.3375 12.3375C9.0625 11.6125 9.975 11.25 11.075 11.25H13C13.6833 11.25 14.2417 11.0333 14.675 10.6C15.1083 10.1667 15.325 9.60833 15.325 8.925C15.325 8.24167 15.1083 7.68333 14.675 7.25C14.2417 6.81667 13.6833 6.6 13 6.6H7.6C7.45 7.18333 7.125 7.67917 6.625 8.0875C6.125 8.49583 5.53333 8.7 4.85 8.7C4.05 8.7 3.375 8.425 2.825 7.875C2.275 7.325 2 6.65 2 5.85C2 5.05 2.275 4.375 2.825 3.825C3.375 3.275 4.05 3 4.85 3C5.53333 3 6.125 3.20417 6.625 3.6125C7.125 4.02083 7.45 4.51667 7.6 5.1H13C14.1 5.1 15.0125 5.4625 15.7375 6.1875C16.4625 6.9125 16.825 7.825 16.825 8.925C16.825 10.025 16.4625 10.9375 15.7375 11.6625C15.0125 12.3875 14.1 12.75 13 12.75H11.075C10.3917 12.75 9.83333 12.9667 9.4 13.4C8.96667 13.8333 8.75 14.3917 8.75 15.075C8.75 15.7583 8.96667 16.3167 9.4 16.75C9.83333 17.1833 10.3917 17.4 11.075 17.4H16.4C16.55 16.8167 16.875 16.3208 17.375 15.9125C17.875 15.5042 18.4667 15.3 19.15 15.3C19.95 15.3 20.625 15.575 21.175 16.125C21.725 16.675 22 17.35 22 18.15C22 18.95 21.725 19.625 21.175 20.175C20.625 20.725 19.95 21 19.15 21ZM4.85 7.2C5.23333 7.2 5.55417 7.07083 5.8125 6.8125C6.07083 6.55417 6.2 6.23333 6.2 5.85C6.2 5.46667 6.07083 5.14583 5.8125 4.8875C5.55417 4.62917 5.23333 4.5 4.85 4.5C4.46667 4.5 4.14583 4.62917 3.8875 4.8875C3.62917 5.14583 3.5 5.46667 3.5 5.85C3.5 6.23333 3.62917 6.55417 3.8875 6.8125C4.14583 7.07083 4.46667 7.2 4.85 7.2Z"
                        fill="#212121"
                      />
                    </svg>
                    <Text text={path.title} variant="body1" />
                  </div>
                  {path.nodes.map((node) => {
                    return (
                      <div style={{ ...CONTENT_ROW, paddingLeft: 20 }}>
                        {node.object_type === 'Topic' ? (
                          <Topic htmlColor={color.shade70} style={{ marginRight: 8 }} />
                        ) : node.object_type === 'Quiz' ? (
                          <BoltRounded htmlColor={color.shade70} style={{ marginRight: 8 }} />
                        ) : (
                          <DescriptionRounded
                            htmlColor={color.shade70}
                            style={{ marginRight: 8 }}
                          />
                        )}
                        <Text text={node.object_name} variant="body1" />
                      </div>
                    )
                  })}
                </>
              )
            })}
            {content?.topics?.map((topic) => {
              return (
                <div style={CONTENT_ROW}>
                  <Topic htmlColor={color.shade70} style={{ marginRight: 8 }} />
                  <Text text={topic} variant="body1" />
                </div>
              )
            })}
            {content?.quizzes?.map((quiz) => {
              return (
                <div style={CONTENT_ROW}>
                  <BoltRounded htmlColor={color.shade70} style={{ marginRight: 8 }} />
                  <Text text={quiz} variant="body1" />
                </div>
              )
            })}
          </div>
          <div style={COLUMN}>
            {assignees.groups.map((group) => {
              return (
                <div style={CONTENT_ROW}>
                  <PeopleRounded htmlColor={color.shade70} style={{ marginRight: 8 }} />
                  <Text text={group.group_name} variant="body1" style={{ marginRight: 20 }} />
                  <Text text={group.user_count} variant="body1" style={{ color: color.shade50 }} />
                </div>
              )
            })}
            {assignees.users.map((user) => {
              return (
                <div style={CONTENT_ROW}>
                  <PersonRounded htmlColor={color.shade70} style={{ marginRight: 8 }} />
                  <Text text={user} variant="body1" />
                </div>
              )
            })}
          </div>
        </div>
      </Collapse>
    </Paper>
  )
})

const ROOT: React.CSSProperties = {
  flexDirection: 'column',
  display: 'flex',
  cursor: 'pointer',
}
const TITLE_ROW: React.CSSProperties = {
  flexDirection: 'row',
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  paddingRight: 8,
}
const COLLAPSED: React.CSSProperties = {
  height: 232,
  borderBottomLeftRadius: 20,
  borderBottomRightRadius: 20,
  background: '#fff',
  display: 'flex',
  flexDirection: 'row',
}
const INFO_ROW: React.CSSProperties = {
  flex: 1,
  paddingBottom: 8,
  marginRight: 40,
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'space-between',
}
const INFO_ICON: React.CSSProperties = {
  marginRight: 4,
  height: 24,
  width: 24,
  borderRadius: 100,
  backgroundColor: '#F4F4F4',
}
const DATE_ROW: React.CSSProperties = {
  flexDirection: 'row',
  display: 'flex',
  alignItems: 'center',
  marginBottom: 24,
}
const ICON: React.CSSProperties = { width: 24, height: 24, padding: 4, fontSize: 16 }
const ROW: React.CSSProperties = {
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  marginBottom: 16,
  alignItems: 'center',
}
const COLUMN: React.CSSProperties = {
  flex: 1,
  flexDirection: 'column',
  display: 'flex',
  overflowY: 'auto',
  paddingRight: 20,
  marginRight: 20,
  paddingTop: 16,
}
const CONTENT_ROW: React.CSSProperties = {
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  marginBottom: 12,
}
