import React, { useEffect, useState } from 'react'
import { observer } from 'mobx-react-lite'
import { ConfirmDialog } from '@trivie/ui-web/components/confirm-dialog'
import { Button } from '@trivie/ui-web/components/button'
import { CloseRounded } from '@mui/icons-material'
import { useStores } from '@trivie/core'
import { TableHelper } from '../../../../tables'
import { useRouteMatch } from 'react-router-dom'
import { DateTime } from 'luxon'
import { UserTable } from '../../../../tables/user-table'
import { ShortList } from '@trivie/ui-web/components/short-list'
import { Stack } from '@mui/material'

const People: React.FunctionComponent = observer(() => {
  const match = useRouteMatch<any>('/people/groups/:id/people')
  const groupID = Number(match?.params?.id)

  const { groupStore, manageUserStore } = useStores()
  const { permissions } = manageUserStore
  const {
    getGroupTags,
    addedGroupUsers,
    getAddedGroupUsers,
    excludedGroupUsers,
    getExcludedGroupUsers,
    removeAddedUsers,
    restoreExcludedUsers,
    selectedGroupId,
    setSelectedGroupId,
    refreshOverviewData,
  } = groupStore

  const [showRemoveAdditionalUsers, setShowRemoveAdditionalUsers] = useState(false)
  const [showRestoreExcludedUsers, setShowRestoreExcludedUsers] = useState(false)
  const [tableHelper] = useState(new TableHelper('list_group_users', 'users', 'groups', groupID))

  useEffect(() => {
    if (!selectedGroupId) {
      setSelectedGroupId(Number(match?.params.id))
      refreshOverviewData(
        DateTime.local(1971).startOf('day').toISO(),
        DateTime.local().endOf('day').toISO(),
      )
    }
    getGroupTags()
    getAddedGroupUsers()
    getExcludedGroupUsers()
  }, [])

  const handleCloseRemove = () => {
    setShowRemoveAdditionalUsers(false)
  }

  const handleCloseExclude = () => {
    setShowRestoreExcludedUsers(false)
  }

  const [loading, setLoading] = useState(false)

  return (
    <>
      <UserTable forceLoading={loading} tableHelper={tableHelper} />
      <Stack mt={2} useFlexGap gap={2} direction="row">
        <ShortList
          rowAction={
            permissions?.permissions.some((p) => p.code === 'can_edit_group')
              ? (item: any) => {
                  setLoading(true)
                  removeAddedUsers([item]).then(() => {
                    tableHelper.load().then(() => setLoading(false))
                  })
                }
              : null
          }
          action={
            <Button
              disabled={
                !permissions?.permissions.some((p) => p.code === 'can_edit_group') ||
                addedGroupUsers?.length === 0
              }
              onClick={() => setShowRemoveAdditionalUsers(true)}
              color="info"
              variant="outlined"
              startIcon={<CloseRounded />}
              text="Remove All"
            />
          }
          style={{ marginRight: 10 }}
          data={addedGroupUsers}
          type="Additional Users"
        />
        <ShortList
          rowAction={
            permissions?.permissions.some((p) => p.code === 'can_edit_group')
              ? (item: any) => {
                  setLoading(true)
                  restoreExcludedUsers([item]).then(() => {
                    tableHelper.load().then(() => setLoading(false))
                  })
                }
              : null
          }
          action={
            <Button
              disabled={
                !permissions?.permissions.some((p) => p.code === 'can_edit_group') ||
                addedGroupUsers?.length === 0
              }
              color="info"
              variant="outlined"
              onClick={() => setShowRestoreExcludedUsers(true)}
              startIcon={<CloseRounded />}
              text="Restore All"
            />
          }
          data={excludedGroupUsers}
          type="Excluded Users"
        />
      </Stack>
      {showRemoveAdditionalUsers && (
        <ConfirmDialog
          danger
          open={showRemoveAdditionalUsers}
          title="Remove Additional Users"
          message="Are you sure you want to remove additional users?"
          confirmedMessage="Additional users have been removed from the system."
          confirmButton="Confirm"
          onConfirm={() => {
            setLoading(true)
            removeAddedUsers(addedGroupUsers?.map((u: any) => u.id)).then(() => {
              tableHelper.load().then(() => setLoading(false))
            })
          }}
          onClose={handleCloseRemove}
          handleCancel={handleCloseRemove}
        />
      )}
      {showRestoreExcludedUsers && (
        <ConfirmDialog
          danger
          open={showRestoreExcludedUsers}
          title="Restore Excluded Users"
          message="Are you sure you want to restore excluded users?"
          confirmedMessage="Excluded users have been restored to the system."
          confirmButton="Confirm"
          onConfirm={() => {
            setLoading(true)
            restoreExcludedUsers(excludedGroupUsers?.map((u: any) => u.id)).then(() =>
              tableHelper.load().then(() => setLoading(false)),
            )
          }}
          onClose={handleCloseExclude}
          handleCancel={handleCloseExclude}
        />
      )}
    </>
  )
})

export const GroupsPeopleTab = (props: any) => {
  const { id } = props
  return {
    name: 'People',
    path: `/people/groups/${id}/people`,
    component: () => <People />,
  }
}
