import React from 'react'
import { Instance } from 'mobx-state-tree'
import { FormControl, InputBase, MenuItem, Select, styled } from '@mui/material'
import { observer } from 'mobx-react-lite'

import { ContestCreateForm } from '@trivie/core/src/models/ContestCreateForm'
import { Text } from '@trivie/ui-web/components/text'
import { color } from '@trivie/ui-web/theme'
import { Check } from '@mui/icons-material'
import { Switch } from '@trivie/ui-web/components/switch/switch'

interface ContestScoringProps {
  form:
    | Instance<typeof ContestCreateForm>
    | { scoring_method: string; custom_scoring: any; set: () => void }
  viewOnly?: boolean | undefined
}

const TrivieDropdown = styled(InputBase)(({ theme }) => ({
  '& .MuiInputBase-input': {
    width: '100%',
    borderRadius: 6,
    position: 'relative',
    backgroundColor: color.white,
    border: '1px solid #ced4da',
    fontSize: 16,
    padding: '10px 26px 10px 12px',
  },
  '& .MuiPopover-root': {
    height: 400,
  },
}))

export const ContestScoring = observer((props: ContestScoringProps) => {
  const { form, viewOnly } = props

  const handleChangeScoring = (e: { target: { value: string } }) =>
    form.set('scoring_method', e.target.value)

  return (
    <div>
      <Text text="Contest Scoring Type" variant="body1Medium" />
      <FormControl style={{ marginBottom: 12, marginTop: 12, width: '100%' }} variant="standard">
        <Select
          disabled={viewOnly}
          MenuProps={{ PaperProps: { style: { width: 250, borderRadius: 6, paddingTop: 0 } } }}
          value={form.scoring_method}
          onChange={handleChangeScoring}
          input={<TrivieDropdown />}
          renderValue={(val) => (val === 'standard' ? 'Standard' : 'Custom Scoring')}
        >
          <MenuItem value="standard">
            <div style={MENU_ITEM_CONTAINER}>
              <div style={{ flexDirection: 'column', justifyContent: 'center' }}>
                <Text text="Standard" variant="body1Medium" style={{ marginBottom: 8 }} />
                <Text
                  text="Users earn the same points for the contest that they earn elsewhere in the app"
                  variant="body2"
                />
              </div>
              {form.scoring_method === 'standard' && (
                <Check htmlColor={color.blue} style={{ fontSize: 20 }} />
              )}
            </div>
          </MenuItem>
          <MenuItem value="custom">
            <div style={MENU_ITEM_CONTAINER}>
              <div style={{ flexDirection: 'column', justifyContent: 'center' }}>
                <Text text="Custom Scoring" variant="body1Medium" style={{ marginBottom: 8 }} />
                <Text
                  text="Users earn points based on the custom-defined scoring below"
                  variant="body2"
                />
              </div>
              {form.scoring_method === 'custom' && (
                <Check htmlColor={color.blue} style={{ fontSize: 20 }} />
              )}
            </div>
          </MenuItem>
        </Select>
      </FormControl>
      <Text
        style={{ marginBottom: 20 }}
        text={
          form.scoring_method === 'custom'
            ? 'Users earn points based on the custom-defined scoring below'
            : 'Users earn the same points for the contest that they earn elsewhere in the app.'
        }
        variant="body2"
      />
      {form.scoring_method === 'custom' && (
        <>
          <div style={SWITCH_ROW}>
            <div style={SWITCH_TEXT}>
              <Text style={{ marginBottom: 8 }} variant="body1Medium" text="Quiz Answers" />
              <Text
                variant="body2Medium"
                text="Award points for correct answers on regular quizzes."
                style={{ color: color.shade50, marginRight: 60 }}
              />
            </div>
            <Switch
              disabled={viewOnly}
              color="success"
              checked={form.custom_scoring.answers}
              onChange={(e) =>
                form.set('custom_scoring', { ...form.custom_scoring, answers: e.target.checked })
              }
            />
          </div>
          <div style={SWITCH_ROW}>
            <div style={SWITCH_TEXT}>
              <Text
                style={{ marginBottom: 8 }}
                variant="body1Medium"
                text="Reinforcement Answers"
              />
              <Text
                variant="body2Medium"
                text="Award points for correct answers on reinforcement quizzes."
                style={{ color: color.shade50, marginRight: 60 }}
              />
            </div>
            <Switch
              disabled={viewOnly}
              color="success"
              checked={form.custom_scoring.reinforcements}
              onChange={(e) =>
                form.set('custom_scoring', {
                  ...form.custom_scoring,
                  reinforcements: e.target.checked,
                })
              }
            />
          </div>
          <div style={SWITCH_ROW}>
            <div style={SWITCH_TEXT}>
              <Text style={{ marginBottom: 8 }} variant="body1Medium" text="Tests" />
              <Text
                variant="body2Medium"
                text="Award points for correct answers on pre- and post-tests."
                style={{ color: color.shade50, marginRight: 60 }}
              />
            </div>
            <Switch
              disabled={viewOnly}
              color="success"
              checked={form.custom_scoring.tests}
              onChange={(e) =>
                form.set('custom_scoring', { ...form.custom_scoring, tests: e.target.checked })
              }
            />
          </div>
          <div style={SWITCH_ROW}>
            <div style={SWITCH_TEXT}>
              <Text style={{ marginBottom: 8 }} variant="body1Medium" text="Posts" />
              <Text
                variant="body2Medium"
                text="Award points when the user creates a new post on the Feed."
                style={{ color: color.shade50, marginRight: 60 }}
              />
            </div>
            <Switch
              disabled={viewOnly}
              color="success"
              checked={form.custom_scoring.posts}
              onChange={(e) =>
                form.set('custom_scoring', { ...form.custom_scoring, posts: e.target.checked })
              }
            />
          </div>
          <div style={SWITCH_ROW}>
            <div style={SWITCH_TEXT}>
              <Text style={{ marginBottom: 8 }} variant="body1Medium" text="Replies" />
              <Text
                variant="body2Medium"
                text="Award points when a user replies to a post on the Feed."
                style={{ color: color.shade50, marginRight: 60 }}
              />
            </div>
            <Switch
              disabled={viewOnly}
              color="success"
              checked={form.custom_scoring.comments}
              onChange={(e) =>
                form.set('custom_scoring', { ...form.custom_scoring, comments: e.target.checked })
              }
            />
          </div>
          {/* <div style={SWITCH_ROW}>
            <div style={SWITCH_TEXT}>
              <Text style={{ marginBottom: 8 }} variant="body1Medium" text="Badges" />
              <Text
                variant="body2Medium"
                text="Award points when a user earns a new badge."
                style={{ color: color.shade50, marginRight: 60 }}
              />
            </div>
            <Switch
              disabled={viewOnly}
              color="success"
              checked={form.custom_scoring.badges}
              onChange={e =>
                form.set('custom_scoring', { ...form.custom_scoring, badges: e.target.checked })
              }
            />
          </div> */}
        </>
      )}
    </div>
  )
})

const SWITCH_ROW: React.CSSProperties = {
  display: 'flex',
  marginTop: 0,
  marginBottom: 22,
  alignItems: 'center',
  justifyContent: 'space-between',
}
const SWITCH_TEXT: React.CSSProperties = { flex: 1, flexDirection: 'column', display: 'flex' }
const MENU_ITEM_CONTAINER: React.CSSProperties = {
  flex: 1,
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'center',
}
