import React from 'react'
import { observer } from 'mobx-react-lite'
import { Stack } from '@mui/material'
import { NoteAdd } from '@mui/icons-material'
import { Button } from '@trivie/ui-web/components/button'
import { useStores } from '@trivie/core'
import { Text } from '@trivie/ui-web/components/text'
import { color } from '@trivie/ui-web/theme'

import { ReactComponent as NullState } from '../null-states/null-resource-settings.svg'
import { ResourceComponent } from '@trivie/ui-web/components/resource-component'
import { Panel } from '@trivie/ui-web/components/panel/panel'

export const ResourceSettings = observer((props: any) => {
  const { contentCreationStore } = useStores()
  const { selectedTopic } = contentCreationStore

  const { openResourceDialog } = props

  if (!selectedTopic) {
    return null
  }

  const badgeCount =
    (selectedTopic.resources?.filter((r) => {
      return selectedTopic?.quizResources?.find((qr) => qr.resource_id === r.resource_id)
        ? false
        : true
    }).length ?? 0) + selectedTopic?.quizResources?.length

  const bubbleUpResources = selectedTopic.resources?.filter((r) => {
    return selectedTopic?.quizResources?.find((qr) => qr.resource_id === r.resource_id)
      ? false
      : true
  })

  return (
    <Panel
      title="Sources"
      badge={badgeCount}
      action={
        <Button
          size="small"
          color="info"
          onClick={openResourceDialog}
          variant="outlined"
          startIcon={<NoteAdd />}
          text="Add Source"
        />
      }
      divider
    >
      {!selectedTopic?.resources?.length && !selectedTopic?.quizResources?.length ? (
        <NullSources attachSource={openResourceDialog} />
      ) : (
        <Stack gap={4} useFlexGap>
          {bubbleUpResources?.length ? (
            <Stack useFlexGap gap={2}>
              <Stack gap={1}>
                <Text text="Topic Sources" variant="body1Emphasized" />
                <Text
                  style={{ color: color.palette.grey }}
                  text="These sources are only associated with the topic. You can attach a source to a quiz by dragging and dropping the source onto a quiz."
                  variant="body2"
                />
              </Stack>
              {bubbleUpResources?.map((resource) => {
                return (
                  <ResourceComponent
                    key={`${resource.resource_id}-t`}
                    inline
                    edit={resource.editing}
                    resource={resource}
                    isSelected={false}
                    onClick={(r) => {}}
                    onRemove={() => selectedTopic?.removeResource(resource)}
                  />
                )
              })}
            </Stack>
          ) : null}
          {selectedTopic?.quizResources?.length ? (
            <Stack useFlexGap gap={2}>
              <Stack useFlexGap gap={1}>
                <Text text="Quiz Sources" variant="body1Emphasized" />
                <Text
                  style={{ color: color.palette.grey }}
                  text="These sources are associated with one or more quizzes in the topic. Learners can view these sources on the topic or the quiz page."
                  variant="body2"
                />
              </Stack>
              {selectedTopic.quizzes?.map((quiz) => {
                return quiz.resources?.map((resource) => {
                  return (
                    <ResourceComponent
                      key={`${resource.resource_id}-q`}
                      inline
                      edit={resource.editing}
                      resource={resource}
                      isSelected={false}
                      onClick={(r) => {}}
                      onRemove={() => quiz?.removeResource(resource)}
                    />
                  )
                })
              })}
            </Stack>
          ) : null}
        </Stack>
      )}
    </Panel>
  )
})

const NullSources = (props: any) => {
  const { attachSource } = props

  return (
    <>
      <NullState style={{ alignSelf: 'center' }} />
      <span style={{ textAlign: 'center' }}>
        <span
          onClick={attachSource}
          style={{
            textAlign: 'center',
            alignSelf: 'center',
            color: color.shade50,
            marginTop: 16,
            marginBottom: 16,
            textDecoration: 'underline',
            cursor: 'pointer',
            fontWeight: 500,
            fontSize: 14,
            lineHeight: '16.94px',
          }}
        >
          Attach a source
        </span>
        <span
          style={{
            textAlign: 'center',
            alignSelf: 'center',
            color: color.shade50,
            marginTop: 16,
            marginBottom: 16,
            fontWeight: 500,
            fontSize: 14,
            lineHeight: '16.94px',
          }}
        >
          &nbsp;to your topic to help your learners prepare for their quizzes.
        </span>
      </span>
    </>
  )
}
