import { mergeAttributes } from '@tiptap/core'

import TableRow from '@tiptap/extension-table-cell'

export const TTableRow = TableRow.extend({
  name: 'tableRow',
  addAttributes() {
    return {
      ...this.parent?.(),
      generated_question_ids: {
        default: [],
      },
      node_id: {
        default: 0,
      },
    }
  },
  renderHTML({ node, HTMLAttributes }) {
    return [`tr`, mergeAttributes(this.options.HTMLAttributes, HTMLAttributes), 0]
  },
})
