import React, { useState } from 'react'
import { observer } from 'mobx-react-lite'
import { Stack } from '@mui/material'
import { Add, Inventory2Outlined } from '@mui/icons-material'

import { useStores } from '@trivie/core'
import { QuizHelper } from '@trivie/core/src/helpers/QuizHelper'
import { Button } from '@trivie/ui-web/components/button'

import { QuizList } from './quiz-list'
import { AddQuizDialog } from './add-quiz-dialog'
import { Panel } from '@trivie/ui-web/components/panel/panel'

export const QuizSettings: React.FunctionComponent = observer(() => {
  const { contentCreationStore } = useStores()
  const { selectedTopic } = contentCreationStore

  const [showAddQuiz, setShowAddQuiz] = useState(false)

  if (!selectedTopic) {
    return null
  }

  const addQuiz = (q) => {
    QuizHelper.loadQuiz(q.id).then((data) => {
      if (selectedTopic.quizzes) {
        selectedTopic.set('quizzes', [...selectedTopic.quizzes, data])
      } else {
        selectedTopic.set('quizzes', [data])
      }
    })
  }

  return (
    <Panel title="Quizzes" badge={selectedTopic?.quizzes?.length} divider>
      <QuizList />
      <Stack
        direction="row"
        justifyContent={
          selectedTopic.quizzes && selectedTopic.quizzes?.length > 0 ? 'flex-end' : 'center'
        }
        gap={2}
        useFlexGap
      >
        <Button
          startIcon={<Inventory2Outlined />}
          variant="outlined"
          onClick={() => setShowAddQuiz(true)}
          text="Add Quiz From Library"
        />
        <Button
          color="secondary"
          variant="contained"
          startIcon={<Add />}
          text="Create New Quiz"
          onClick={selectedTopic?.addNewQuiz}
        />
      </Stack>
      {showAddQuiz && (
        <AddQuizDialog open={showAddQuiz} close={() => setShowAddQuiz(false)} onSelect={addQuiz} />
      )}
    </Panel>
  )
})
