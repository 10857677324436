import React, { useState } from 'react'

import { observer } from 'mobx-react-lite'

import { Dialog } from '../dialogs/dialog'
import { Button } from '../button'
import { Text } from '../text'
import { CreateMessageForm } from '@trivie/core/src/models/CreateMessage'
import { DateTime } from 'luxon'
import { MessageForm } from '../message-form'
import { useStores } from '@trivie/core'
import { MessagePayload } from '@trivie/core/src/models/MessagePayload'

export interface MessageDialogProps {
  open: boolean
  userCount?: number
  onClose: () => void
  entity?: string
  ids: number[]
  messageType?: 'nudge' | 'generic' | 'survey_nudge' | 'contest'
}

export const MessageDialog = observer((props: MessageDialogProps) => {
  const { entity = 'user', messageType, ids, open, userCount, onClose } = props

  const { messageStore } = useStores()
  const { createMessage } = messageStore

  const [messageText, setMessageText] = useState('')

  const [isSent, setIsSent] = useState(false)

  const [form, setForm] = useState(
    CreateMessageForm.create({
      start_date: DateTime.local().toISODate(),
      start_time: DateTime.local().toLocaleString(DateTime.TIME_24_SIMPLE),
      subject:
        messageType === 'nudge'
          ? 'Friendly Reminder: Your Assignment is Due Soon'
          : messageType === 'survey_nudge'
            ? 'Quick Reminder: Your Feedback is Valued'
            : '',
      message:
        messageType === 'nudge'
          ? 'This is a gentle reminder about your assignment due soon. Please ensure they are completed promptly. We are always available to help with any concerns or questions you might have.'
          : messageType === 'survey_nudge'
            ? "Remember to share your thoughts in our recent survey. It won't take long and your input is invaluable."
            : '',
      users:
        messageType !== 'generic' && messageType !== 'contest'
          ? []
          : entity === 'user'
            ? ids.map((id) => {
                return { type: 'Users', name: '', number_users: undefined, id }
              })
            : [],
      groups:
        messageType !== 'generic' && messageType !== 'contest'
          ? []
          : entity === 'group'
            ? ids.map((id) => {
                return { type: 'Groups', name: '', number_users: undefined, id }
              })
            : [],
      push: false,
      email: false,
      schedule: false,
      message_type: messageType ?? 'generic',
    }),
  )

  const handleSend = () => {
    const p = {
      subject: form.subject,
      message_text: form.message,
      send_email:
        form.message_type === 'survey_nudge' || form.message_type === 'nudge' ? true : form.email,
      send_push:
        form.message_type === 'survey_nudge' || form.message_type === 'nudge' ? true : form.push,
      send_datetime: form.datetimeUTC,
      user_ids: form.message_type === 'generic' && entity === 'user' ? ids : [],
      message_type: form.message_type,
    }
    if (messageType === 'contest' || entity === 'group') {
      // @ts-ignore
      p['audience'] = {
        audience: ids.map((id) => ({
          object_type: entity === 'group' ? 'Group' : 'Contest',
          object_id: Number(id),
        })),
      }
    }
    const payload = MessagePayload.create(p)
    if (messageType === 'survey_nudge') {
      ids.forEach((id) => {
        payload.setSurveyId(id)
        createMessage(payload, id).then(() => {
          setIsSent(true)
          setMessageText(`Your message will be sent on ${form.displayDate} at ${form.displayTime}`)
        })
      })
    } else if (messageType === 'nudge') {
      ids.forEach((id) => {
        createMessage(payload, id).then(() => {
          setIsSent(true)
          setMessageText(`Your message will be sent on ${form.displayDate} at ${form.displayTime}`)
        })
      })
    } else {
      createMessage(payload).then(() => {
        setIsSent(true)
        setMessageText(
          `Your message will be sent to ${userCount} ${entity}${userCount !== 1 ? 's' : ''} on ${
            form.displayDate
          } at ${form.displayTime}`,
        )
      })
    }
  }

  return (
    <Dialog
      maxWidth={isSent ? 'xs' : 'sm'}
      title={`Send ${
        messageType === 'nudge' || messageType === 'survey_nudge' ? 'Nudge' : 'Message'
      }`}
      onClose={onClose}
      open={open}
      actions={
        isSent ? (
          <Button text="Close" onClick={onClose} />
        ) : (
          <div style={FOOTER}>
            {userCount ? (
              <Text
                variant="body1Medium"
                text={`${userCount} selected ${entity}${userCount > 1 ? 's' : ''}`}
              />
            ) : (
              <div />
            )}
            <div style={{ display: 'flex', gap: 12 }}>
              <Button color="info" text="Cancel" variant="outlined" onClick={onClose} />
              <Button
                disabled={!!Object.keys(form.errors).length}
                onClick={handleSend}
                text="Send"
              />
            </div>
          </div>
        )
      }
    >
      {isSent ? (
        <Text
          variant="body1Medium"
          text={messageText}
          component="div"
          textAlign="center"
          style={{ textAlign: 'center' }}
        />
      ) : (
        <>
          {(messageType === 'nudge' || messageType === 'survey_nudge') && (
            <Text
              color="text.secondary"
              variant="body1"
              text="Nudges are sent to learners with the assignment who have not completed it yet."
            />
          )}
          <MessageForm form={form} />
        </>
      )}
    </Dialog>
  )
})

const FOOTER: React.CSSProperties = {
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  width: '100%',
}
