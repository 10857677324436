import { ApiResponse } from 'apisauce'
import { getGeneralApiProblem } from './api/api-problem'

export const handleResponse = <T>(response: ApiResponse<T>) => {
  // simulate error codes
  // const successChance = Math.random()
  // if (testFailure && successChance < 0) {
  //   return Promise.reject({ kind: 'wait', message: 'Test' })
  // } else

  if (response.ok) {
    return response
  } else {
    return Promise.reject(getGeneralApiProblem(response) ?? null)
  }
}
