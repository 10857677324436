import React, { useRef } from 'react'
import CameraAltIcon from '@mui/icons-material/CameraAlt'

import { Button } from '@trivie/ui-web/components/button'
import { color } from '@trivie/ui-web/theme'
import { Text } from '@trivie/ui-web/components/text'
import { BadgeAvatar } from '@trivie/ui-web/components/badge-avatar'

export interface FileInputProps {
  style?: React.CSSProperties
  circularCrop?: boolean
  imgSrc?: string
  mainText?: string
  helperText?: string
  onChange: (e: any) => void
  value?: any
  accept: string
  progress?: any
  showSize?: boolean
  contentStyle?: React.CSSProperties
  onCancel?: () => void
  error?: string | null
}

export const FileInput = React.forwardRef((props: FileInputProps, ref: any) => {
  const { style, onChange, helperText, imgSrc, accept, error, circularCrop = true } = props

  const handleFile = (files: FileList | null) => {
    onChange(files ? files[0] : null)
  }
  const fileInputRef = useRef<null | HTMLInputElement>(null)
  const changeImageRef = useRef<null | HTMLInputElement>(null)
  const hasFile = !!imgSrc

  return (
    <label style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
      {hasFile ? (
        <>
          <input
            ref={changeImageRef}
            type="file"
            style={{ ...ACCESSIBLE_HIDDEN_STYLE, borderRadius: circularCrop ? '50%' : 0 }}
            onChange={(e) => handleFile(e.target.files || null)}
            accept={accept}
          />
          <BadgeAvatar
            size={180}
            name="Badge"
            url={imgSrc}
            hoverable={false}
            variant={circularCrop != undefined && circularCrop ? 'circular' : 'square'}
          />
          <Button
            variant="text"
            color="info"
            onClick={() => changeImageRef?.current?.click()}
            text="Change Image"
            sx={{ textDecoration: 'underline', marginTop: '12px', marginBottom: '12px' }}
          />
          {error && (
            <Text variant="body2Medium" text={error} style={{ color: color.palette.red }} />
          )}
        </>
      ) : (
        <>
          <label
            style={{ ...BASE, ...style }}
            onDrop={(e) => handleFile(e.dataTransfer.files)}
            ref={ref}
          >
            <input
              ref={fileInputRef}
              type="file"
              style={{ ...ACCESSIBLE_HIDDEN_STYLE, borderRadius: circularCrop ? '50%' : 0 }}
              onChange={(e) => handleFile(e.target.files || null)}
              accept={accept}
            />
          </label>
          <Button
            onClick={() => fileInputRef?.current?.click()}
            startIcon={<CameraAltIcon />}
            text="Upload Image"
            variant="text"
            sx={{ marginTop: '12px', marginBottom: '12px' }}
          />
          <Text variant="body2Medium" text="or drag and drop" style={{ color: color.shade70 }} />
          {!error && helperText && (
            <Text variant="body2Medium" text={helperText} style={{ color: color.shade70 }} />
          )}
          {error && (
            <Text variant="body2Medium" text={error} style={{ color: color.palette.red }} />
          )}
        </>
      )}
    </label>
  )
})

const BASE: React.CSSProperties = {
  display: 'flex',
  backgroundColor: 'rgba(240, 242, 245, 0.5)',
  borderStyle: 'dashed',
  borderWidth: 1,
  borderColor: color.shade30,
  borderRadius: '50%',
  cursor: 'pointer',
  height: 184,
  width: 184,
  margin: 'auto',
}

const ACCESSIBLE_HIDDEN_STYLE: React.CSSProperties = {
  clip: 'rect(1px, 1px, 1px, 1px)',
  clipPath: 'inset(50%)',
  height: 1,
  width: 1,
  margin: -1,
  overflow: 'hidden',
  padding: 0,
  position: 'absolute',
}
