import { types } from 'mobx-state-tree'
import { TagBaseModel } from '../tag/TagBaseModel'
import { UserShortSchemaModel } from './UserShortSchemaModel'

/**
 * TagUserRead
 */
export const TagUserReadModel = types.model('TagUserRead').props({
  __typename: types.optional(types.literal('TagUserRead'), 'TagUserRead'),
  id: types.integer,
  tagId: types.integer,
  tags: types.array(
    types.union(
      types.null,
      types.late(() => TagBaseModel),
    ),
  ),
  userId: types.integer,
  users: types.array(
    types.union(
      types.null,
      types.late(() => UserShortSchemaModel),
    ),
  ),
})
