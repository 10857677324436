import { useStores } from '@trivie/core'
import { observer } from 'mobx-react-lite'
import React, { useEffect } from 'react'
import { useHistory } from 'react-router-dom'

export const Logout: React.FunctionComponent = observer(() => {
  const rootStore = useStores()
  const { manageUserStore } = rootStore
  const { clearAuthentication } = manageUserStore

  const history = useHistory()

  useEffect(() => {
    clearAuthentication()
    rootStore.reset()
    setTimeout(() => {
      history.replace('/login')
    }, 400)
  }, [])

  return null
})
