import React from 'react'
import { Instance } from 'mobx-state-tree'
import { DateTime } from 'luxon'
import { color } from '../../theme'
import { Avatar } from '../avatar'
import { Text } from '../text'
import { BadgeUserReadModel } from '@trivie/core/src/models/base/badge'
import { RevokeBadge } from './revoke-badge'

export function BadgeUserItem({
  user,
  onRevokeClick,
}: {
  user: Instance<typeof BadgeUserReadModel>
  onRevokeClick: () => void
}) {
  const { display_name, profile_url, datetime_awarded, awarded_by } = user
  const dt = DateTime.fromISO(datetime_awarded)
  return (
    <div style={{ display: 'flex', alignItems: 'center', marginTop: 20, flex: 1 }}>
      <Avatar name={display_name} url={profile_url} />
      <div style={{ marginLeft: 16, display: 'flex', flexDirection: 'column', flex: 1 }}>
        <Text
          style={{
            textAlign: 'left',
            overflowWrap: 'break-word',
          }}
          variant="body1Emphasized"
          text={display_name ?? ''}
        />
        <Text
          variant="body2"
          text={`Awarded by ${awarded_by} on ${dt.toLocaleString(DateTime.DATE_MED)}`}
          style={{
            color: color.shade50,
            overflowWrap: 'break-word',
          }}
        />
      </div>
      <RevokeBadge onRevokeClick={onRevokeClick} />
    </div>
  )
}
