export const scrollToCitation = (generated_id: number) => {
  if (generated_id) {
    document.querySelectorAll('.SelectedFact').forEach((e) => e.classList.remove('SelectedFact'))
    document.querySelectorAll('[node_id]').forEach((node) => {
      const gqids = node.getAttribute('generated_question_ids')
      const ids = gqids?.split(',').map((id) => Number(id)) ?? []
      if (ids?.includes(generated_id)) {
        if (node.tagName === 'TR') {
          node.classList.add('SelectedFact')
          node.scrollIntoView({
            behavior: 'smooth',
            block: 'start',
            inline: 'start',
          })
        } else if (node.classList.contains('list-item-content')) {
          node.querySelector('.paragraph-node-content>span')?.classList.add('SelectedFact')
          node.scrollIntoView({
            behavior: 'smooth',
            block: 'center',
            inline: 'center',
          })
        } else {
          node.children[0].classList.add('SelectedFact')
          node.scrollIntoView({
            behavior: 'smooth',
            block: 'center',
            inline: 'center',
          })
        }
      }
    })
  }
}
