import { UserApi } from '../services/api-objects'

let rootStore

export class UserHelper {
  static setRootStore = (store) => {
    rootStore = store
  }

  static loadUser = async (userId: string | number) => {
    const [userProfileResponse, userPermissionsResponse] = await Promise.all([
      UserApi.getUserProfile(userId),
      UserApi.getPermissions(userId),
    ])

    if (
      !userProfileResponse.ok ||
      !userPermissionsResponse.ok ||
      !userProfileResponse.data ||
      !userPermissionsResponse.data
    ) {
      throw new Error(`Failed to load user`)
    } else {
      return {
        profile: userProfileResponse.data,
        permissions: userPermissionsResponse.data,
      }
    }
  }
}
