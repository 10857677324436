import React, { useState } from 'react'
import { observer } from 'mobx-react-lite'
import { Route, Switch } from 'react-router-dom'
import { Mail, Publish } from '@mui/icons-material'

import { Header } from '@trivie/ui-web/components/header'
import { InviteUsers } from '@trivie/ui-web/components/invite-users'
import { useStores } from '@trivie/core'

import { TableHelper } from '../../../tables'
import { ImportUsers } from './import-users'
import { UsersDetailsScreen } from './users-details-screen'
import { UserTable } from '../../../tables/user-table'
import { UserOverviewScreen } from './user-overview-screen'
import { UserApi } from '@trivie/core/src/services/api-objects'

export const UsersScreen: React.FunctionComponent = observer(() => {
  const { manageUserStore } = useStores()
  const { enterpriseTier, permissions } = manageUserStore
  const [showInviteUsers, setShowInviteUsers] = useState(false)
  const [showImportUsers, setShowImportUsers] = useState(false)

  const handleOpenImport = () => setShowImportUsers(true)
  const handleOpenInvite = () => setShowInviteUsers(true)

  const [tableHelper] = useState(new TableHelper('list_users', 'users', 'users'))
  const [loading, setLoading] = useState(false)

  const columns = !enterpriseTier
    ? [
        { field: 'user_quizzes_available' },
        { field: 'user_total_attempts' },
        { field: 'user_pct_correct' },
        { field: 'user_last_active' },
        { field: 'identity' },
        { field: 'first_name' },
        { field: 'last_name' },
        { field: 'email' },
        { field: 'phone_number' },
      ]
    : []

  const primary = enterpriseTier && {
    disabled: !permissions?.permissions.some((p) => p.code === 'can_create_user'),
    startIcon: <Publish />,
    onClick: handleOpenImport,
    text: 'Import Users',
  }

  const secondary = enterpriseTier && {
    disabled: !permissions?.permissions.some((p) => p.code === 'can_create_user'),
    startIcon: <Mail />,
    onClick: handleOpenInvite,
    text: 'Invite Users',
  }

  return (
    <Switch>
      <Route exact path="/people/users">
        <>
          <Header divider title="People" primaryAction={primary} secondaryAction={secondary} />
          <UserTable columns={columns} forceLoading={loading} tableHelper={tableHelper} />
          {showInviteUsers && (
            <InviteUsers
              onSave={() => {
                setLoading(true)
                tableHelper.load().then(() => setLoading(false))
              }}
              open={showInviteUsers}
              close={() => setShowInviteUsers(false)}
            />
          )}
          {showImportUsers && (
            <ImportUsers
              open={showImportUsers}
              onClose={() => setShowImportUsers(false)}
              onImport={(importData: any) => {
                setLoading(true)
                setShowImportUsers(false)
                UserApi.bulkImportUsers(importData).then((resp) => {
                  tableHelper.load().then(() => setLoading(false))
                })
              }}
            />
          )}
        </>
      </Route>
      <Route path="/people/users/:id/:tab">
        {!enterpriseTier ? <UserOverviewScreen /> : <UsersDetailsScreen />}
      </Route>
    </Switch>
  )
})
