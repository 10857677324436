import React from 'react'
import { TrackChanges } from '@mui/icons-material'
import { color } from '../../theme'

export interface CirclePercentageProps {
  value: number
  variant?: 'green' | 'yellow' | 'red'
  style?: React.CSSProperties
}

export function CirclePercentage(props: CirclePercentageProps) {
  const { value, variant, style } = props

  let masteryColor = color.palette.green

  if (variant === 'yellow') {
    masteryColor = color.palette.yellow
  } else if (variant === 'red') {
    masteryColor = color.palette.red
  }

  return (
    <div style={{ height: '70px', width: '70px', ...style }}>
      <svg height="70px" width="70px" viewBox="0 0 36 36">
        <path
          fill="none"
          stroke={masteryColor}
          opacity={0.1}
          strokeWidth="3"
          d="M18 2.0845
            a 15.9155 15.9155 0 0 1 0 31.831
            a 15.9155 15.9155 0 0 1 0 -31.831"
        />

        <path
          stroke={masteryColor}
          fill="none"
          strokeWidth="2"
          strokeLinecap="round"
          strokeDasharray={`${value}, 100`}
          d="M18 2.0845
            a 15.9155 15.9155 0 0 1 0 31.831
            a 15.9155 15.9155 0 0 1 0 -31.831"
        />
        <g transform="translate(13,13)">
          <TrackChanges height="10px" width="10px" htmlColor={masteryColor} />
        </g>
      </svg>
    </div>
  )
}
