import { types } from 'mobx-state-tree'
import { UserRole } from './response-models'
import { StatisticalHistory } from './StatisticalHistory'

export const UserOverviewStats = types.model('UserOverviewStats').props({
  user_id: types.number,
  display_name: types.string,
  profile_image_url: types.maybeNull(types.string),
  exclude_from_leaderboard: types.boolean,
  last_activity: types.maybeNull(types.string),
  starting_knowledge: types.number,
  current_knowledge: types.number,
  knowledge_gain: types.number,
  stats_history: types.array(StatisticalHistory),
  roles: types.frozen(),
})
