import React from 'react'

import { Text } from '@trivie/ui-web/components/text'
import {
  CheckRounded,
  TopicRounded,
  BoltRounded,
  Person,
  Groups,
  Description,
} from '@mui/icons-material'
import { color } from '@trivie/ui-web/theme'
import { DateTime } from 'luxon'
import { Instance } from 'mobx-state-tree'
import { AssignmentCreateForm } from '@trivie/core/src/models/AssignmentCreateForm'
import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import { observer } from 'mobx-react-lite'
import { useStores } from '@trivie/core'

type AssignmentReviewProps = {
  form: Instance<typeof AssignmentCreateForm>
}

export const AssignmentReview = observer((props: AssignmentReviewProps) => {
  const { pathStore } = useStores()
  const { details } = pathStore

  const { form } = props
  let prePostText = ''

  if (form.include_pre_tests && form.include_post_tests) {
    prePostText = 'Add Pre-Test and Post Test'
  } else if (form.include_pre_tests) {
    prePostText = 'Add Pre-Test'
  } else if (form.include_post_tests) {
    prePostText = 'Add Post Test'
  }

  const renderContent = () => {
    if (form.selectedPaths?.length) {
      const pathData = form.selectedPaths.map((path, index) => {
        return (
          <>
            <div style={ROW_ASSIGNEES}>
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M19.15 21C18.4667 21 17.8708 20.7958 17.3625 20.3875C16.8542 19.9792 16.5333 19.4833 16.4 18.9H11.075C9.975 18.9 9.0625 18.5375 8.3375 17.8125C7.6125 17.0875 7.25 16.175 7.25 15.075C7.25 13.975 7.6125 13.0625 8.3375 12.3375C9.0625 11.6125 9.975 11.25 11.075 11.25H13C13.6833 11.25 14.2417 11.0333 14.675 10.6C15.1083 10.1667 15.325 9.60833 15.325 8.925C15.325 8.24167 15.1083 7.68333 14.675 7.25C14.2417 6.81667 13.6833 6.6 13 6.6H7.6C7.45 7.18333 7.125 7.67917 6.625 8.0875C6.125 8.49583 5.53333 8.7 4.85 8.7C4.05 8.7 3.375 8.425 2.825 7.875C2.275 7.325 2 6.65 2 5.85C2 5.05 2.275 4.375 2.825 3.825C3.375 3.275 4.05 3 4.85 3C5.53333 3 6.125 3.20417 6.625 3.6125C7.125 4.02083 7.45 4.51667 7.6 5.1H13C14.1 5.1 15.0125 5.4625 15.7375 6.1875C16.4625 6.9125 16.825 7.825 16.825 8.925C16.825 10.025 16.4625 10.9375 15.7375 11.6625C15.0125 12.3875 14.1 12.75 13 12.75H11.075C10.3917 12.75 9.83333 12.9667 9.4 13.4C8.96667 13.8333 8.75 14.3917 8.75 15.075C8.75 15.7583 8.96667 16.3167 9.4 16.75C9.83333 17.1833 10.3917 17.4 11.075 17.4H16.4C16.55 16.8167 16.875 16.3208 17.375 15.9125C17.875 15.5042 18.4667 15.3 19.15 15.3C19.95 15.3 20.625 15.575 21.175 16.125C21.725 16.675 22 17.35 22 18.15C22 18.95 21.725 19.625 21.175 20.175C20.625 20.725 19.95 21 19.15 21ZM4.85 7.2C5.23333 7.2 5.55417 7.07083 5.8125 6.8125C6.07083 6.55417 6.2 6.23333 6.2 5.85C6.2 5.46667 6.07083 5.14583 5.8125 4.8875C5.55417 4.62917 5.23333 4.5 4.85 4.5C4.46667 4.5 4.14583 4.62917 3.8875 4.8875C3.62917 5.14583 3.5 5.46667 3.5 5.85C3.5 6.23333 3.62917 6.55417 3.8875 6.8125C4.14583 7.07083 4.46667 7.2 4.85 7.2Z"
                  fill="#212121"
                />
              </svg>
              <Text variant="body1Medium" text={path.label} style={{ marginLeft: 10 }} />
            </div>
            {path.subItems?.map((si) => {
              if (si.type === 'topics') {
                return (
                  <div style={{ ...ROW_ASSIGNEES, paddingLeft: 20 }}>
                    <TopicRounded style={{ marginRight: 8 }} htmlColor={color.shade70} />
                    <Text variant="body1Medium" text={si.label} style={{ marginLeft: 10 }} />
                  </div>
                )
              } else if (si.type === 'quizzes') {
                return (
                  <div style={{ ...ROW_ASSIGNEES, paddingLeft: 20 }}>
                    <BoltRounded style={{ marginRight: 8 }} htmlColor={color.shade70} />
                    <Text variant="body1Medium" text={si.label} style={{ marginLeft: 10 }} />
                  </div>
                )
              } else if (si.type === 'resources') {
                return (
                  <div style={{ ...ROW_ASSIGNEES, paddingLeft: 20 }}>
                    <Description style={{ marginRight: 8 }} htmlColor={color.shade70} />
                    <Text variant="body1Medium" text={si.label} style={{ marginLeft: 10 }} />
                  </div>
                )
              }
            })}
          </>
        )
      })
      return (
        <>
          <div style={CONTENT_VALUES}>
            <Text
              variant="body1"
              text="Content"
              style={{ marginRight: 10, color: color.shade70 }}
            />
            <Text
              variant="body2"
              text={`${form.pathContent.paths} Path${form.pathContent.paths === 1 ? '' : 's'}: ${
                form.pathContent.topics
              } Topic${form.pathContent.topics === 1 ? '' : 's'}, ${form.pathContent.quizzes} Quiz${
                form.pathContent.quizzes === 1 ? '' : 'zes'
              }, ${form.pathContent.sources} Source${form.pathContent.sources === 1 ? '' : 's'}`}
              style={{ color: color.shade70 }}
            />
          </div>
          {pathData}
        </>
      )
    } else if (form.selectedTopics?.length) {
      return (
        <>
          <div style={CONTENT_VALUES}>
            <Text
              variant="body1"
              text="Content"
              style={{ marginRight: 10, color: color.shade70 }}
            />
            <Text
              variant="body2"
              text={`${form.topicContent.topics} Topic${
                form.topicContent.topics === 1 ? '' : 's'
              }: ${form.topicContent.quizzes} Quiz${form.topicContent.quizzes === 1 ? '' : 'zes'}`}
              style={{ color: color.shade70 }}
            />
          </div>
          {form.selectedTopics.map((topic) => (
            <>
              <div style={ROW_ASSIGNEES} key={topic.id}>
                <TopicRounded style={{ marginRight: 8 }} htmlColor={color.shade70} />
                <Text variant="body1Medium" text={topic.label} style={{ marginLeft: 10 }} />
              </div>
              {topic.subItems?.map((si) => (
                <div style={{ ...ROW_ASSIGNEES, paddingLeft: 20 }}>
                  <BoltRounded style={{ marginRight: 8 }} htmlColor={color.shade70} />
                  <Text variant="body1Medium" text={si.label} style={{ marginLeft: 10 }} />
                </div>
              ))}
            </>
          ))}
        </>
      )
    } else if (form.selectedQuizzes?.length) {
      return (
        <>
          <div style={CONTENT_VALUES}>
            <Text
              variant="body1"
              text="Content"
              style={{ marginRight: 10, color: color.shade70 }}
            />
            <Text
              variant="body2"
              text={`${form.selectedQuizzes?.length} Quiz${
                form.pathContent.quizzes === 1 ? '' : 'zes'
              }`}
              style={{ color: color.shade70 }}
            />
          </div>
          {form.selectedQuizzes.map((quiz) => (
            <div style={ROW_ASSIGNEES} key={quiz.id}>
              <BoltRounded style={{ marginRight: 8 }} htmlColor={color.shade70} />
              <Text variant="body1Medium" text={quiz.label} style={{ marginLeft: 10 }} />
            </div>
          ))}
        </>
      )
    }
  }

  const renderAssignees = () => {
    const _users = form.users
    const _groups = form.groups
    const fsBuffer = _users.slice(0, 3).map((t) => {
      return (
        <div style={ROW_ASSIGNEES} key={t.id}>
          <Person htmlColor={color.shade70} />
          <Text variant="body1Medium" text={t.label} style={{ marginLeft: 10 }} />
        </div>
      )
    })
    if (_users.length >= 4) {
      if (_users.length === 4) {
        fsBuffer[3] = (
          <div style={ROW_ASSIGNEES} key={_users[3].id}>
            <Person htmlColor={color.shade70} />
            <Text variant="body1Medium" text={_users[3].label} style={{ marginLeft: 10 }} />
          </div>
        )
      } else {
        fsBuffer[3] = (
          <div style={ROW_ASSIGNEES} key={_users[4].id}>
            <Person htmlColor={color.shade70} />
            <Text
              variant="body1Medium"
              text={`+${_users.length - 3} Users`}
              style={{ marginLeft: 10 }}
            />
          </div>
        )
      }

      if (_groups.length > 1) {
        fsBuffer[4] = (
          <div style={ROW_ASSIGNEES} key={_groups[0].id}>
            <Groups htmlColor={color.shade70} />
            <Text
              variant="body1Medium"
              text={`+${_groups.length} Groups`}
              style={{ marginLeft: 10 }}
            />
          </div>
        )
      } else if (_groups.length === 1) {
        fsBuffer[4] = (
          <div style={ROW_ASSIGNEES} key={_groups[0].id}>
            <Groups htmlColor={color.shade70} />
            <Text variant="body1Medium" text={_groups[0].label} style={{ marginLeft: 10 }} />
          </div>
        )
      }
      return fsBuffer.slice(0, 5)
    } else {
      const groupsC = _groups.map((q) => {
        return (
          <div style={ROW_ASSIGNEES} key={q.id}>
            <Groups htmlColor={color.shade70} />
            <Text variant="body1Medium" text={q.label} style={{ marginLeft: 10 }} />
          </div>
        )
      })
      const result = [...fsBuffer, ...groupsC]

      if (result.length > 5) {
        result[4] = (
          <div style={ROW_ASSIGNEES} key={_groups[0].id}>
            <Groups htmlColor={color.shade70} />
            <Text
              variant="body1Medium"
              text={`+${groupsC.length - (4 - fsBuffer.length)} Groups`}
              style={{ marginLeft: 10 }}
            />
          </div>
        )
      }
      return result.slice(0, 5)
    }
  }

  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'space-between',
        flexDirection: 'column',
        maxHeight: 500,
        overflowY: 'auto',
      }}
    >
      <Text variant="titleEmphasized" text={form.title} style={{ marginBottom: 25 }} />

      <div style={ROW}>
        <CheckRounded htmlColor={color.palette.green} />
        <Text
          variant="body1"
          text={`Starts ${DateTime.fromISO(`${form.start_date}T${form.start_time}`).toLocaleString(
            DateTime.DATETIME_FULL,
          )}`}
          style={{ marginLeft: 10 }}
        />
      </div>

      {form.endDateEnabled && (
        <div style={ROW}>
          <CheckRounded htmlColor={color.palette.green} />
          <Text
            variant="body1"
            text={`Ends ${DateTime.fromISO(`${form.end_date}T${form.end_time}`).toLocaleString(
              DateTime.DATETIME_FULL,
            )}`}
            style={{ marginLeft: 10 }}
          />
        </div>
      )}

      <div style={ROW}>
        <CheckRounded htmlColor={color.palette.green} />
        <Text
          variant="body1"
          text={`${!form.attemptsEnabled ? 'Unlimited' : form.attempts} ${
            form.attempts === 1 ? 'Attempt' : 'Attempts'
          }`}
          style={{ marginLeft: 10 }}
        />
      </div>

      {prePostText && (
        <div style={ROW}>
          <CheckRounded htmlColor={color.palette.green} />
          <Text variant="body1" text={prePostText} style={{ marginLeft: 10 }} />
        </div>
      )}

      {form.daysToCompleteEnabled && (
        <div style={ROW}>
          <CheckRounded htmlColor={color.palette.green} />
          <Text
            variant="body1"
            text={`${form.daysToComplete} ${
              form.daysToComplete === 1 ? 'Day To Complete' : 'Days To Complete'
            }`}
            style={{ marginLeft: 10 }}
          />
        </div>
      )}

      <Box>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <div style={{ paddingLeft: 16 }}>{renderContent()}</div>
          </Grid>
          <Grid item xs={6}>
            <Text
              variant="body1"
              text={`Assignees (${form.groups?.length + form.users?.length})`}
              style={{ color: color.shade70, marginBottom: 12 }}
            />
            {renderAssignees()}
          </Grid>
        </Grid>
      </Box>
    </div>
  )
})

const ROW: React.CSSProperties = {
  display: 'flex',
  alignItems: 'center',
  marginBottom: 21,
  paddingLeft: 7,
}
const ROW_ASSIGNEES: React.CSSProperties = {
  display: 'flex',
  alignItems: 'center',
  marginBottom: 12,
}
const CONTENT_VALUES: React.CSSProperties = {
  marginBottom: 12,
  flexDirection: 'row',
  display: 'flex',
  alignItems: 'center',
}
