import { FormControl } from '@mui/material'
import { color } from '@trivie/ui-web/theme'
import { observer } from 'mobx-react-lite'
import React, { useState } from 'react'
import { DateTime } from 'luxon'
import { TextField } from '@trivie/ui-web/components/text-field'
import { Text } from '@trivie/ui-web/components/text'
import { Instance } from 'mobx-state-tree'
import { AssignmentCreateForm } from '@trivie/core/src/models/AssignmentCreateForm'
import { Switch } from '@trivie/ui-web/components/switch/switch'

type AssignmentScheduleProps = {
  form: Instance<typeof AssignmentCreateForm>
}

export const AssignmentSchedule = observer((props: AssignmentScheduleProps) => {
  const { form } = props

  const [touched, setTouched] = useState({
    title: false,
    start_date: false,
    start_time: false,
    end_date: false,
    end_time: false,
    attempts: false,
    include_pre_tests: false,
    include_post_tests: false,
    notify: false,
    daysToComplete: false,
  })

  let startTimeMax = '23:59'
  if (
    DateTime.fromISO(form.start_date).toFormat('yyyy-MM-dd') ===
    DateTime.fromISO(form.end_date).toFormat('yyyy-MM-dd')
  ) {
    startTimeMax = DateTime.fromISO(`${form.end_date}T${form.end_time}:00`).toFormat('HH:mm')
  }
  let endTimeMin = '00:00'
  if (
    DateTime.fromISO(form.start_date).toFormat('yyyy-MM-dd') ===
    DateTime.fromISO(form.end_date).toFormat('yyyy-MM-dd')
  ) {
    endTimeMin = DateTime.fromISO(`${form.start_date}T${form.start_time}:00`).toFormat('HH:mm')
  }

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        overflowY: 'auto',
        overflowX: 'hidden',
      }}
    >
      <form style={{ paddingRight: 20 }}>
        <div style={{ flexDirection: 'row', marginBottom: 24 }}>
          <Text variant="body2" text="Name" style={{ marginBottom: 7, color: color.shade70 }} />
          <TextField
            error={touched.title && !!form.errors.title}
            helperText={touched.title && form.errors.title?.message}
            style={{ width: '100%' }}
            // size="small"
            placeholder="Assignment Name"
            onChange={(e) => {
              setTouched({ ...touched, title: true })
              form.set('title', e.target.value)
            }}
            onBlur={() => {
              setTouched({ ...touched, title: true })
            }}
            value={form.title}
          />
          <Text
            component="div"
            style={{
              justifyContent: 'flex-end',
              display: 'flex',
              color: touched.title && form.title?.length > 64 ? '#f44336' : color.shade50,
            }}
            variant="body1"
            text={(form.title?.length ?? 0) + ' / 64'}
          />
        </div>

        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            marginBottom: 15,
          }}
        >
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              marginBottom: 32,
              flex: 1,
              marginRight: 14,
            }}
          >
            <Text variant="body2" text="Start" style={{ marginBottom: 7, color: color.shade70 }} />

            <TextField
              error={!!form.errors.start_date}
              helperText={form.errors.start_date?.message}
              variant="outlined"
              {...{
                inputProps: {
                  min: DateTime.local().toFormat('yyyy-MM-dd'),
                  max: DateTime.local().plus({ years: 1 }).toFormat('yyyy-MM-dd'),
                },
              }}
              size="small"
              style={{
                marginRight: 8,
                marginBottom: 10,
                display: 'flex',
                width: '100%',
              }}
              type="date"
              onChange={(e) => {
                if (!e.target.value) {
                  return
                }

                setTouched({ ...touched, start_date: true })
                form.set('start_date', e.target.value)
              }}
              onBlur={() => {
                setTouched({ ...touched, start_date: true })
              }}
              value={form.start_date}
            />
            <TextField
              error={!!form.errors.start_time}
              type="time"
              size="small"
              variant="outlined"
              style={{ width: '100%' }}
              {...{
                inputProps: {
                  max: startTimeMax,
                },
              }}
              InputLabelProps={{
                shrink: true,
              }}
              onChange={(e) => {
                setTouched({ ...touched, start_time: true })
                form.set('start_time', e.target.value)
              }}
              onBlur={() => {
                setTouched({ ...touched, start_time: true })
              }}
              value={form.start_time}
            />
            <Text
              style={{ padding: 8, paddingLeft: 0, color: color.shade50 }}
              variant="body1"
              text="Learners will receive access to this content at this time."
            />
          </div>

          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              marginBottom: 32,
              flex: 1,
            }}
          >
            <div
              style={{ display: 'flex', alignItems: 'center', flexWrap: 'nowrap', marginBottom: 7 }}
            >
              <Text variant="body2" text="End" style={{ marginRight: 7, color: color.shade70 }} />
              <Switch
                color="success"
                checked={form.endDateEnabled}
                onChange={(e) => form.set('endDateEnabled', e.target.checked)}
                size="small"
              />
            </div>

            <TextField
              error={touched.end_date && !!form.errors.end_date}
              helperText={touched.end_date && form.errors.end_date?.message}
              variant="outlined"
              disabled={!form.endDateEnabled}
              {...{
                inputProps: {
                  min: DateTime.fromISO(form.start_date).toFormat('yyyy-MM-dd'),
                },
              }}
              InputLabelProps={{
                shrink: false,
              }}
              size="small"
              style={{ marginRight: 8, marginBottom: 10, display: 'flex', width: '100%' }}
              type="date"
              onChange={(e) => {
                setTouched({ ...touched, end_date: true })

                if (!e.target.value) {
                  return
                }

                form.set('end_date', e.target.value)
              }}
              onBlur={() => {
                setTouched({ ...touched, end_date: true })
              }}
              value={form.end_date}
            />
            <TextField
              error={!!form.errors.end_time}
              type="time"
              size="small"
              variant="outlined"
              disabled={!form.endDateEnabled}
              style={{ width: '100%' }}
              {...{
                inputProps: {
                  min: endTimeMin,
                },
              }}
              InputLabelProps={{
                shrink: true,
              }}
              onChange={(e) => {
                setTouched({ ...touched, end_time: true })
                form.set('end_time', e.target.value)
              }}
              onBlur={() => {
                setTouched({ ...touched, end_time: true })
              }}
              value={form.end_time}
            />
            <Text
              style={{ padding: 8, paddingLeft: 0, paddingRight: 0, color: color.shade50 }}
              variant="body1"
              text={
                form.endDateEnabled
                  ? 'Learners lose access to this content at this time'
                  : 'Learners will have access to this content indefinitely.'
              }
            />
          </div>
        </div>

        <div
          style={{
            flexDirection: 'row',
            marginTop: -20,
          }}
        >
          <FormControl variant="standard" style={{ width: '100%' }}>
            <div style={SWITCH_ROW}>
              <div style={SWITCH_TEXT}>
                <Text style={{ marginBottom: 8 }} variant="body1Medium" text="Days To Complete" />
                <Text
                  variant="body2Medium"
                  text={
                    form.errors?.daysToCompleteEnabled?.message ??
                    'Assignment is due this many days after the learner receives it'
                  }
                  style={{
                    color: !!form.errors?.daysToCompleteEnabled?.message
                      ? color.red
                      : color.shade50,
                    marginRight: 60,
                  }}
                />
              </div>
              <div style={{ display: 'flex', flexWrap: 'nowrap' }}>
                <TextField
                  error={touched.daysToComplete && !!form.errors.daysToComplete}
                  helperText={touched.daysToComplete && form.errors.daysToComplete?.message}
                  size="small"
                  variant="outlined"
                  type="number"
                  disabled={!form.daysToCompleteEnabled}
                  onChange={(e) => {
                    if (!e.target.value) {
                      form.set('daysToComplete', null)
                    } else {
                      form.set('daysToComplete', parseInt(e.target.value))
                    }
                  }}
                  style={{ width: 60, marginRight: 22 }}
                  value={form.daysToComplete}
                />
              </div>
              <Switch
                color="success"
                checked={form.daysToCompleteEnabled}
                onChange={(e) => form.set('daysToCompleteEnabled', e.target.checked)}
              />
            </div>
            <div style={SWITCH_ROW}>
              <div style={SWITCH_TEXT}>
                <Text
                  style={{ marginBottom: 8 }}
                  variant="body1Medium"
                  text="Limit Quiz Attempts"
                />
                <Text
                  variant="body2Medium"
                  text="Limit learners to this many attempts at each quiz"
                  style={{ color: color.shade50, marginRight: 60 }}
                />
              </div>
              {form.attemptsEnabled ? (
                <TextField
                  error={touched.attempts && !!form.errors.attempts}
                  helperText={touched.attempts && form.errors.attempts?.message}
                  size="small"
                  variant="outlined"
                  type="number"
                  inputProps={{ maxLength: 4 }}
                  disabled={!form.attemptsEnabled}
                  onChange={(e) => {
                    e.target.value = Math.max(0, parseInt(e.target.value)).toString().slice(0, 4)
                    if (!e.target.value) {
                      form.set('attempts', null)
                    } else {
                      form.set('attempts', parseInt(e.target.value))
                    }
                  }}
                  style={{ width: 60, marginRight: 22 }}
                  value={form.attempts}
                />
              ) : (
                <div style={UNLIMITED_ATTEMPTS}>
                  <Text style={{ color: color.shade50 }} variant="body1" text="Unlimited" />
                </div>
              )}
              <Switch
                color="success"
                checked={form.attemptsEnabled}
                onChange={(e) => form.set('attemptsEnabled', e.target.checked)}
              />
            </div>
            <div style={SWITCH_ROW}>
              <div style={SWITCH_TEXT}>
                <Text style={{ marginBottom: 8 }} variant="body1Medium" text="Notify Learners" />
                <Text
                  variant="body2Medium"
                  text="Send learners a notification when they receive the assignment"
                  style={{ color: color.shade50, marginRight: 60 }}
                />
              </div>
              <Switch
                color="success"
                checked={form.notify}
                onChange={(e) => form.set('notify', e.target.checked)}
              />
            </div>
            {form.selectedTopics?.length ? (
              <div style={SWITCH_ROW}>
                <div style={SWITCH_TEXT}>
                  <Text style={{ marginBottom: 8 }} variant="body1Medium" text="Add Pre-Test" />
                  <Text
                    variant="body2Medium"
                    text="Test topic knowledge at the start of an assignment"
                    style={{ color: color.shade50, marginRight: 60 }}
                  />
                </div>
                <Switch
                  color="success"
                  checked={form.include_pre_tests}
                  onChange={(e) => form.set('include_pre_tests', e.target.checked)}
                />
              </div>
            ) : null}
            {form.selectedTopics?.length ? (
              <div style={SWITCH_ROW}>
                <div style={SWITCH_TEXT}>
                  <Text style={{ marginBottom: 8 }} variant="body1Medium" text="Add Post-Test" />
                  <Text
                    variant="body2Medium"
                    text="Test topic knowledge at the end of an assignment "
                    style={{ color: color.shade50, marginRight: 60 }}
                  />
                </div>
                <Switch
                  color="success"
                  checked={form.include_post_tests}
                  onChange={(e) => form.set('include_post_tests', e.target.checked)}
                />
              </div>
            ) : null}
          </FormControl>
        </div>
      </form>
    </div>
  )
})

const SWITCH_ROW: React.CSSProperties = {
  display: 'flex',
  marginTop: 0,
  marginBottom: 22,
  alignItems: 'center',
  justifyContent: 'space-between',
}

const SWITCH_TEXT: React.CSSProperties = {
  flex: 1,
  display: 'flex',
  flexDirection: 'column',
}
const UNLIMITED_ATTEMPTS: React.CSSProperties = {
  height: 30,
  border: `1px solid ${color.shade20}`,
  padding: 16,
  marginRight: 22,
  borderRadius: 6,
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  cursor: 'default',
}
