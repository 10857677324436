import React from 'react'
import { IconButton, Tooltip as MuiTooltip } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { Text } from '../text'
import { observer } from 'mobx-react-lite'
import { Close } from '@mui/icons-material'
import { color } from '../../theme'

export const OnboardingTooltip = observer((props: any) => {
  const {
    open,
    containerStyles,
    children,
    title,
    titleStyle,
    text,
    textStyle,
    placement,
    onSkip,
    style,
    onNext,
    subtext,
    final,
  } = props

  const useStyles = makeStyles(() => {
    const s = {
      tooltip: {
        top: 8,
        borderRadius: 8,
        backgroundColor: '#9D14FA',
        ...containerStyles,
      },
      arrow: {
        fontSize: 24,
        color: '#9D14FA',
      },
    } as any
    if (placement === 'right-start') {
      s.arrow = { ...s.arrow, top: '-80px !important' }
    }
    return s
  })

  const classes = useStyles()

  return (
    <MuiTooltip
      classes={{
        // @ts-ignore
        tooltip: classes.tooltip,
        // @ts-ignore
        arrow: classes.arrow,
      }}
      style={{ padding: 0, ...style }}
      open={open}
      placement={placement ?? 'bottom'}
      PopperProps={{ open }}
      title={
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            width: 318,
            paddingRight: 24,
            paddingTop: 8,
            paddingLeft: 24,
            paddingBottom: 16,
            borderRadius: 8,
          }}
        >
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              flex: 1,
              alignItems: 'center',
              paddingRight: 9,
            }}
          >
            {title && (
              <Text
                variant="labelEmphasized"
                text={title}
                style={{ color: color.white, paddingTop: 10, paddingBottom: 10, ...titleStyle }}
              />
            )}
          </div>
          <Text
            variant="body1"
            text={text}
            style={{
              marginBottom: 8,
              color: color.white,
              ...textStyle,
            }}
          />
          {subtext && (
            <Text
              variant="body1"
              text={subtext}
              style={{
                marginBottom: 8,
                marginTop: 8,
                color: color.white,
                ...textStyle,
              }}
            />
          )}
          <div style={{ flexDirection: 'row', display: 'flex', justifyContent: 'space-between' }}>
            <Text
              variant="body1"
              text="Skip Tutorial"
              onClick={() => {
                onSkip()
                onNext()
              }}
              style={{ cursor: 'pointer', color: color.white, textDecoration: 'underline' }}
            />
            <Text
              variant="body1"
              text={final ? 'Done' : 'Next'}
              onClick={onNext}
              style={{ cursor: 'pointer', color: color.white, textDecoration: 'underline' }}
            />
          </div>
        </div>
      }
      arrow
    >
      {children}
    </MuiTooltip>
  )
})
