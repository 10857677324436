import styled from '@emotion/styled'
import { animated } from '@react-spring/web'

export const Container = styled.div`
  overflow-y: scroll;
  width: 100%;
  z-index: 1000;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
`

export const Message = styled(animated.div)`
  box-sizing: border-box;
  position: relative;
  overflow: hidden;
`

export const Content = styled.div`
  color: white;
  text-align: right;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  padding: 0px 6px;
  font-size: 1em;
  height: auto;
  border-radius: 10px;
`

export const ChatBubble = styled.p`
  margin: 0;
  border-radius: 10px;
  padding: 12px;
  position: relative;
  word-wrap: break-word;
  text-align: start;
  max-width: 604px;
`

export const Row = styled.div`
  flex-direction: row;
  align-items: center;
  display: flex;
`

export const ChatAvatar = styled.div`
  height: 24px;
  width: 24px;
  margin-right: 10px;
  align-self: flex-end;
  overflow: hidden;
`

export const Loading = styled.div`
  display: flex;
  gap: 3px;
`

export const Circle = styled(animated.div)`
  background-color: #ffffff95;
  position: relative;
  height: 10px;
  width: 10px;
  transform: translateY(-5px);
`
