import { Instance, types } from 'mobx-state-tree'

const BadgeCategory = types.model('BadgeCategory').props({
  id: types.number,
  label: types.string,
})

/**
 * BadgeRead Model
 */
export const BadgeReadModel = types.model('BadgeRead').props({
  id: types.maybe(types.number),
  icon_url: types.maybeNull(types.string),
  label: types.string,
  description: types.maybeNull(types.string),
  datetime_created: types.string,
  created_by: types.maybeNull(types.string),
  awarded_count: types.maybeNull(types.number),
  required_points: types.maybeNull(types.number),
  is_custom: types.maybeNull(types.boolean),
  is_disabled: types.maybeNull(types.boolean),
  convertedDate: types.maybeNull(types.string),
  badge_category: BadgeCategory,
})

/**
 * BadgeUserRead Model
 */
export const BadgeUserReadModel = types.model('BadgeUserRead').props({
  user_id: types.number,
  datetime_awarded: types.string,
  awarded_by: types.string,
  display_name: types.maybeNull(types.string),
  profile_url: types.maybeNull(types.string),
})

/* The TypeScript type of an instance of BadgeReadModel */
export interface BadgeReadModelType extends Instance<typeof BadgeReadModel.Type> {}
export interface BadgeUserReadModelType extends Instance<typeof BadgeUserReadModel.Type> {}

export interface BagdeUsersReadModel {
  users: BadgeUserReadModelType[]
}

export interface BadgeCreateModelType {
  icon_url: string
  label: string
  description: string
  file?: File
}
