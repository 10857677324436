import { types } from 'mobx-state-tree'

export const Badge = types.model('Badge').props({
  badge_id: types.number,
  badge_category: types.frozen(),
  label: types.maybeNull(types.string),
  awarded_at: types.maybe(types.maybeNull(types.string)),
  created_at: types.maybe(types.maybeNull(types.string)),
  icon_url: types.maybeNull(types.string),
  description: types.string,
})
