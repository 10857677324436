import React, { useEffect, useState } from 'react'
import { observer } from 'mobx-react-lite'
import { CircularProgress } from '@mui/material'
import {
  CalendarTodayRounded,
  CloseRounded,
  Delete,
  EditRounded,
  NotificationsRounded,
  PlayCircleOutline,
} from '@mui/icons-material'
import { DateTime } from 'luxon'

import { useStores } from '@trivie/core'
import { ConfirmDialog } from '@trivie/ui-web/components/confirm-dialog'
import { DataTable, TableHelper } from '@trivie/ui-web/components/data-table/data-table'
import { Toolbar } from '@trivie/ui-web/components/table-toolbar'
import { ExtendAssignment } from '@trivie/ui-web/components/extend-dialog'
import { Text } from '@trivie/ui-web/components/text'
import { MessageDialog } from '@trivie/ui-web/components/message-dialog'

import { ReactComponent as NullState } from './contest-table-null.svg'

interface ContestTableProps {
  tableHelper: TableHelper
  filter: 'live' | 'scheduled' | 'completed'
  forceLoading?: boolean
}

export const ContestTable = observer((props: ContestTableProps) => {
  const { tableHelper, filter, forceLoading } = props

  const { manageUserStore, contestStore } = useStores()
  const {
    setShowEditWizard,
    setEditID,
    endContests,
    extendContests,
    startContestsNow,
    deleteContests,
  } = contestStore
  const { gdpr, permissions } = manageUserStore

  useEffect(() => {
    if (typeof forceLoading === 'boolean') {
      setLoading(Boolean(forceLoading))
    }
  }, [forceLoading])

  const [selectedRows, setSelectedRows] = useState<any>([])
  const [loading, setLoading] = useState<boolean>(true)

  // Dialogs
  const [showConfirmDelete, setShowConfirmDelete] = useState(false)
  const [showExtendContest, setShowExtendContest] = useState(false)
  const [showSendMessage, setShowSendMessage] = useState(false)
  const [showConfirmEnd, setShowConfirmEnd] = useState(false)
  const [showConfirmStartNow, setShowConfirmStartNow] = useState(false)

  const getActions = () => {
    switch (tableHelper.parent) {
      case 'contests':
        if (filter === 'live') {
          return [
            {
              label: 'End',
              icon: CloseRounded,
              onClick: () => setShowConfirmEnd(true),
              disabled:
                !permissions?.permissions.some((p) => p.code === 'can_edit_contest') ||
                selectedRows.length > 1,
            },
            {
              label: 'Extend',
              icon: CalendarTodayRounded,
              onClick: () => setShowExtendContest(true),
              disabled:
                !permissions?.permissions.some((p) => p.code === 'can_edit_contest') ||
                selectedRows.length > 1,
            },
            {
              label: 'Message',
              icon: NotificationsRounded,
              onClick: () => setShowSendMessage(true),
              disabled: !permissions?.permissions.some((p) => p.code === 'can_message_user'),
            },
            {
              label: 'Delete',
              icon: Delete,
              onClick: () => setShowConfirmDelete(true),
              disabled: !permissions?.permissions.some((p) => p.code === 'can_delete_contest'),
            },
          ]
        } else if (filter === 'scheduled') {
          return [
            {
              label: 'Edit',
              icon: EditRounded,
              onClick: () => {
                setEditID(selectedRows[0])
                setShowEditWizard(true)
                setSelectedRows([])
              },
              disabled:
                !permissions?.permissions.some((p) => p.code === 'can_edit_contest') ||
                selectedRows.length > 1,
            },

            {
              label: 'Start Now',
              icon: PlayCircleOutline,
              onClick: () => setShowConfirmStartNow(true),
              disabled: !permissions?.permissions.some((p) => p.code === 'can_edit_contest'),
            },
            {
              label: 'Message',
              icon: NotificationsRounded,
              onClick: () => setShowSendMessage(true),
              disabled: !permissions?.permissions.some((p) => p.code === 'can_message_user'),
            },
            {
              label: 'Delete',
              icon: Delete,
              onClick: () => setShowConfirmDelete(true),
              disabled: !permissions?.permissions.some((p) => p.code === 'can_delete_contest'),
            },
          ]
        } else {
          return [
            {
              label: 'Message',
              icon: NotificationsRounded,
              onClick: () => setShowSendMessage(true),
              disabled:
                selectedRows.length > 1 ||
                !permissions?.permissions.some((p) => p.code === 'can_message_user'),
            },
            {
              label: 'Delete',
              icon: Delete,
              onClick: () => setShowConfirmDelete(true),
              disabled: !permissions?.permissions.some((p) => p.code === 'can_delete_contest'),
            },
          ]
        }
      default:
        return []
    }
  }

  const [tableActions, setTableActions] = useState<any>(getActions())

  useEffect(() => {
    if (permissions) {
      setTableActions(getActions())
    }
  }, [permissions?.permissions])

  useEffect(() => {
    load()
  }, [])

  const load = () => {
    setLoading(true)
    tableHelper.load().then(() => setLoading(false))
  }

  const save = async (columns: any, assignmentFilter: any) => {
    setLoading(true)
    tableHelper.save(columns, assignmentFilter).then(() => setLoading(false))
  }

  const onExtend = (endDateTime: string) => {
    extendContests(selectedRows, endDateTime).then(() => load())
    setShowExtendContest(false)
    setSelectedRows([])
  }

  const [initialEndDate, setInitialEndDate] = useState<DateTime | null>(null)

  useEffect(() => {
    if (selectedRows.length > 0) {
      const selectedContest = Math.max(
        ...(tableHelper.rows
          .filter((row: any) => selectedRows.includes(row.id))
          .map((contest: any) => DateTime.fromISO(contest.contest_end_date)) as any),
      )
      const date = DateTime.fromMillis(selectedContest, { zone: 'utc' })

      setInitialEndDate(date)
    } else {
      setInitialEndDate(null)
    }
    setTableActions(getActions())
  }, [selectedRows])

  const [nullState, setNullState] = useState(false)
  useEffect(() => {
    if (tableHelper.rows.length === 0) {
      setNullState(true)
    } else {
      setNullState(false)
    }
  }, [tableHelper.rows])

  return loading ? (
    <div style={LOADER}>
      <CircularProgress />
    </div>
  ) : nullState ? (
    <>
      <Text
        style={{ marginBottom: 20 }}
        variant="labelEmphasized"
        text={`You don't have any ${filter} contests right now. `}
      />
      <Text
        style={{ marginBottom: 60 }}
        variant="labelEmphasized"
        text={
          filter === 'scheduled'
            ? 'Motivate your learners by creating a new Contest.'
            : 'Motivate your learners by creating a new Contest. Or start one of your Scheduled Contests now to keep people engaged!'
        }
      />
      <div style={{ justifyContent: 'center', flexDirection: 'row', display: 'flex' }}>
        <NullState height={400} width={616} />
      </div>
    </>
  ) : (
    <>
      <DataTable
        columnVisibilityModel={tableHelper.columnVisibilityModel}
        hideAddColumn
        tableHelper={tableHelper}
        filterModel={tableHelper.filterModel}
        sortModel={tableHelper.sortModel}
        searchPlaceholder="Search Assignments"
        rows={tableHelper.rows}
        columns={tableHelper.columns}
        onSaveFilters={save}
        onSaveLayout={tableHelper?.saveLayout}
        toolbar={<Toolbar selected={selectedRows} actions={tableActions} />}
        exportFilename="contests"
        gdpr={gdpr}
        rowSelectionModel={selectedRows}
        onRowSelectionModelChange={(param) => setSelectedRows(param)}
      />
      {showExtendContest && (
        <ExtendAssignment
          contest
          open={showExtendContest}
          initialEndDate={initialEndDate}
          onClose={() => {
            setSelectedRows([])
            load()
            setShowExtendContest(false)
          }}
          onExtend={onExtend}
        />
      )}
      {showSendMessage && (
        <MessageDialog
          ids={selectedRows}
          open={showSendMessage}
          userCount={(selectedRows ?? []).length}
          messageType="contest"
          onClose={() => {
            setSelectedRows([])
            setShowSendMessage(false)
          }}
        />
      )}
      {showConfirmEnd && (
        <ConfirmDialog
          danger
          open={showConfirmEnd}
          title="End Contests"
          message={`Are you sure you want to end ${selectedRows.length} contest${
            selectedRows.length > 1 ? 's' : ''
          } early?`}
          confirmedMessage={`${selectedRows.length} contest${
            selectedRows.length > 1 ? 's have' : ' has'
          } been ended.`}
          confirmButton="Confirm"
          onConfirm={() => endContests(selectedRows)}
          onClose={() => {
            setSelectedRows([])
            load()
            setShowConfirmEnd(false)
          }}
          handleCancel={() => setShowConfirmEnd(false)}
        />
      )}
      {showConfirmDelete && (
        <ConfirmDialog
          danger
          open={showConfirmDelete}
          title="Delete Contests"
          message={`Are you sure you want to delete ${selectedRows.length} contest${
            selectedRows.length > 1 ? 's' : ''
          }? This action is permanent.`}
          confirmedMessage={`${selectedRows.length} contest${
            selectedRows.length > 1 ? 's have' : ' has'
          } been deleted.`}
          confirmButton="Confirm"
          onConfirm={() => deleteContests(selectedRows)}
          onClose={() => {
            setSelectedRows([])
            load()
            setShowConfirmDelete(false)
          }}
          handleCancel={() => setShowConfirmDelete(false)}
        />
      )}
      {showConfirmStartNow && (
        <ConfirmDialog
          open={showConfirmStartNow}
          title="Confirm Start Contests"
          message={`Are you sure you want to start ${selectedRows.length} contest${
            selectedRows.length > 1 ? 's now?' : ' now?'
          }`}
          confirmedMessage={`${selectedRows.length} contest${
            selectedRows.length > 1 ? 's have' : ' has'
          } started.`}
          confirmButton="Confirm"
          onConfirm={() => startContestsNow(selectedRows)}
          onClose={() => {
            setSelectedRows([])
            load()
            setShowConfirmStartNow(false)
          }}
          handleCancel={() => setShowConfirmStartNow(false)}
        />
      )}
    </>
  )
})

const LOADER: React.CSSProperties = {
  display: 'flex',
  flex: 1,
  justifyContent: 'center',
  flexDirection: 'column',
  alignItems: 'center',
  height: window.innerHeight / 1.5,
}
