import { BugReport } from '@mui/icons-material'
import { useStores } from '@trivie/core'
import { observer } from 'mobx-react-lite'
import React, { useState } from 'react'
import { color } from '../../theme'
import { FlytrapDialog } from '../flytrap-dialog/flytrap-dialog'

export const Flytrap = observer(() => {
  const [showFlytrapDialog, setShowFlytrapDialog] = useState(false)
  const { manageUserStore } = useStores()
  const { reportProblem } = manageUserStore

  const handleFlytrapSubmit = (message: string) => {
    setShowFlytrapDialog(false)
    reportProblem(message)
  }

  return (
    <div
      tabIndex={0}
      onKeyDown={(e) => {
        if (e.key === 'Enter') {
          setShowFlytrapDialog(true)
        }
      }}
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        position: 'fixed',
        bottom: 0,
        left: 60,
        borderTopLeftRadius: 8,
        borderTopRightRadius: 8,
        height: 40,
        width: 40,
        cursor: 'pointer',
        backgroundColor: color.palette.titanWhite,
      }}
      onClick={() => setShowFlytrapDialog(true)}
    >
      <BugReport />

      {showFlytrapDialog && (
        <FlytrapDialog
          open={showFlytrapDialog}
          onSubmit={handleFlytrapSubmit}
          onClose={() => setShowFlytrapDialog(false)}
        />
      )}
    </div>
  )
})
