import { observer } from 'mobx-react-lite'
import { includes } from 'ramda'
import React, { useEffect, useState } from 'react'

import { Box, InputAdornment, Paper, TextField } from '@mui/material'
import { People } from '@mui/icons-material'

import { useStores } from '@trivie/core'
import { Dialog } from '@trivie/ui-web/components/dialogs/dialog/dialog'
import { color } from '@trivie/ui-web/theme/color'
import { Text } from '@trivie/ui-web/components/text'
import { NameCell } from '@trivie/ui-web/components/table-cells/name-cell'

export const AddUsersToAssignment = observer((props: any) => {
  const { open, onClose, assignmentIDs } = props
  const { assignmentStore, manageUserStore } = useStores()
  const { availableUsers, getAvailableUsers } = manageUserStore
  const { addUsersToAssignment } = assignmentStore
  const [selectedUsers, setSelectedUsers] = useState<any>([])
  const [usersFilter, setUsersFilter] = useState('')

  useEffect(() => {
    getAvailableUsers(false, [], 'list_users')
  }, [])

  const onChange = (e: any) => {
    setUsersFilter(e.target.value)
  }

  const select = (user: any) => {
    if (!includes(user, selectedUsers)) {
      setSelectedUsers([...selectedUsers, user])
    }
  }

  const addToAssignment = () => {
    const selected = [] as any
    selectedUsers.forEach((user: any) => {
      selected.push(user.id)
    })
    for (let i = 0; i < assignmentIDs.length; i++) {
      addUsersToAssignment(selected, assignmentIDs[i])
    }
    onClose()
  }

  const cancel = () => {
    setSelectedUsers([])
    onClose()
  }

  return (
    <Dialog
      onClose={onClose}
      title="Add Users to Assignment"
      aria-labelledby="add users to assignment"
      open={open}
      onConfirm={addToAssignment}
      onCancel={cancel}
      confirmBtnLabel="Add Users"
      disableConfirm={!selectedUsers.length}
      maxWidth="sm"
    >
      <Box height={600}>
        <Text mb={1} variant="body1" text="Search" style={{ color: color.shade70 }} />
        <TextField
          fullWidth
          data-qat="search"
          id="search"
          size="small"
          variant="outlined"
          value={usersFilter}
          placeholder="Search name or emails"
          style={{ width: '100%' }}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <People style={{ fontSize: 16 }} htmlColor={color.palette.grey} />
              </InputAdornment>
            ),
          }}
          onChange={onChange}
        />
        {!usersFilter && selectedUsers.length === 0 && (
          <Text
            mt={1}
            variant="body2"
            text="Search for users to manually assign this content to them."
            style={{ color: color.shade50, marginBottom: 32 }}
          />
        )}
        {usersFilter && (
          <Paper
            variant="outlined"
            style={{
              marginTop: 5,
              paddingTop: 12,
              paddingBottom: 12,
              paddingLeft: 16,
              paddingRight: 16,
              borderRadius: 6,
            }}
          >
            {(availableUsers ?? [])
              .filter((user: any) => {
                const u = { ...user, display_name: `${user.first_name} ${user.last_name}` }
                return (
                  u.display_name?.toLowerCase().includes(usersFilter?.toLowerCase()) ||
                  u.email?.toLowerCase().includes(usersFilter?.toLowerCase())
                )
              })
              .map((user: any, index: any, data: any) => {
                return (
                  <div
                    onClick={() => {
                      select(user)
                      setUsersFilter('')
                    }}
                    data-qat={`available_user_${index}`}
                    style={{
                      cursor: 'pointer',
                      width: '100%',
                      padding: 0,
                      display: 'flex',
                      flexDirection: 'row',
                      textAlign: 'left',
                      alignItems: 'center',
                      textTransform: 'none',
                      justifyContent: 'flex-start',
                      marginBottom: index === data.length - 1 ? 0 : 24,
                    }}
                  >
                    <NameCell disableLink params={{ row: user }} />
                  </div>
                )
              })}
          </Paper>
        )}
        {selectedUsers.length > 0 && !usersFilter && (
          <Text
            variant="body1"
            mt={1}
            text={`Selected Users (${selectedUsers.length})`}
            style={{ color: color.shade70, marginBottom: 24 }}
          />
        )}
        {!usersFilter && selectedUsers.length > 0 && (
          <div style={{ maxHeight: 400, overflowY: 'scroll' }}>
            {selectedUsers
              .filter((user: any) => {
                const u = { ...user, display_name: `${user.first_name} ${user.last_name}` }
                return u.display_name.toLowerCase().includes(usersFilter.toLowerCase())
              })
              .map((user: any, index: number) => {
                return (
                  <div
                    data-qat={`selected_user_${index}`}
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      alignItems: 'center',
                      marginBottom: 24,
                    }}
                  >
                    <NameCell disableLink params={{ row: user }} />
                  </div>
                )
              })}
          </div>
        )}
      </Box>
    </Dialog>
  )
})
