import { types } from 'mobx-state-tree'

export const UserAssignmentStat = types.model('UserAssignmentStat').props({
  assignment_id: types.number,
  title: types.string,
  is_active: types.boolean,
  topic_count: types.number,
  quiz_count: types.number,
  pct_complete: types.maybeNull(types.number),
  knowledge_gain: types.number,
  start_date: types.maybeNull(types.string),
  end_date: types.maybeNull(types.string),
})
