import React, { useState } from 'react'
import { observer } from 'mobx-react-lite'
import { useRouteMatch } from 'react-router-dom'

import { TableHelper } from '../../../../tables'
import { QuestionTable } from '../../../../tables/question-table'

const Questions: React.FunctionComponent = observer(() => {
  const match = useRouteMatch<any>('/people/tags/:id/questions')
  const tagID = Number(match?.params.id)

  const [tableHelper] = useState(new TableHelper('list_tag_questions', 'questions', 'tags', tagID))

  return <QuestionTable tableHelper={tableHelper} />
})

export const TagsQuestionsTab = (props: any) => {
  const { id } = props
  return {
    name: 'Questions',
    path: `/people/tags/${id}/questions`,
    component: () => <Questions />,
  }
}
