import { TopicsUsersTab } from './topics-users-tab'
import { TopicsResourcesTab } from './topics-resources-tab'
import { TopicsQuizzesTab } from './topics-quizzes-tab'
import { TopicsAssignmentsTab } from './topics-assignments-tab'
import { TopicsOverviewTab } from './topics-overview-tab'
import { TopicsGroupsTab } from './topics-groups-tab'

export const topicsTabs = [
  TopicsOverviewTab,
  TopicsAssignmentsTab,
  TopicsQuizzesTab,
  TopicsResourcesTab,
  TopicsUsersTab,
  TopicsGroupsTab,
]
