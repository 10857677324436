import { TableApi } from '@trivie/core/src/services/api-objects'
import { initColumns } from '@trivie/ui-web/components/data-table/configure'
import { TableCategory, TableHelper } from '@trivie/ui-web/components/data-table/data-table'

export class CustomTableHelper extends TableHelper {
  name?: string
  description?: string
  baseViewID?: number
  baseViewName?: string
  customReload?: any
  createNew?: any

  constructor(
    viewName: string,
    type: string,
    parent: TableCategory,
    id?: number | undefined,
    filter?: string,
    columns?: any,
    name?: string,
    description?: string,
    baseViewID?: number,
    baseViewName?: string,
    customReload?: any,
    createNew?: any,
  ) {
    super(viewName, type, parent, id, filter, columns)
    this.name = name
    this.description = description
    this.baseViewID = baseViewID
    this.baseViewName = baseViewName
    this.customReload = customReload
    this.createNew = createNew
    this.custom = true
  }

  saveAsNewReport = async (columns: any, filter: any) => {
    this.createNew(columns, filter)
  }

  load = async (columns = [] as any, rows = [] as any, id?: number) => {
    try {
      // Configure Options
      const options = await TableApi.getViewOptions(this.viewName)
      this.viewID = id

      // Merge provided columns with metadata available from the options response
      this.options = options.data.meta
        .filter((o) => !o.is_custom || columns.filter((col) => col.field === o.field).length > 0)
        .map((o) => ({ ...o, ...columns.find((column) => column.field === o.field) }))

      // Configure Columns
      const formatted = initColumns(this.options, columns, this.filter)
      this.columnVisibilityModel = formatted.columnVisibilityModel
      this.columns = formatted.columns

      // Configure Sorting Model
      this.initSorting(this.columns)

      // Configure Filters
      this.filterModel = this.initFilters(this.columns, this.logicOperator)

      // Configure Rows
      this.rows = this.initRows(rows, columns)
    } catch (e) {
      console.warn('Table failed to initialize data', e)
    }
  }

  save = async (columns) => {
    const arr = [...columns] as any

    // Always include identity/name columns for user tables
    if (this.type === 'users') {
      arr.push(
        {
          field: 'first_name',
          filter: null,
          is_gdpr: false,
          sort: null,
          width: null,
          column_alias: null,
        },
        {
          field: 'last_name',
          filter: null,
          is_gdpr: false,
          sort: null,
          width: null,
          column_alias: null,
        },
        {
          field: 'email',
          filter: null,
          is_gdpr: false,
          sort: null,
          width: null,
          column_alias: null,
        },
        {
          field: 'phone_number',
          filter: null,
          is_gdpr: false,
          sort: null,
          width: null,
          column_alias: null,
        },
      )
    }

    try {
      return await TableApi.saveCustomDataView(
        {
          view_name: this.name,
          view_description: this.description,
          method: this.logicOperator,
          columns: arr,
        },
        this.viewID,
      ).then(async () => {
        return await this.customReload()
      })
    } catch (e) {
      return false
    }
  }
}
