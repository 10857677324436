import React, { useEffect, useState } from 'react'
import { observer } from 'mobx-react-lite'
import { Box, IconButton } from '@mui/material'
import { Close } from '@mui/icons-material'

import { Dialog } from '../dialogs/dialog'
import { color } from '../../theme'
import { Text } from '../text'
import { Avatar } from '../avatar'
import { useStores } from '@trivie/core'
import { UserSelector } from '../user-selector/user-selector'

export interface TagUsersProps {
  tagIds: any
  open: boolean
  onClose: () => void
}

export const TagUsers = observer((props: TagUsersProps) => {
  const { open, onClose, tagIds } = props
  const { tagStore } = useStores()
  const { addUsersToTags, getTaggableUsers, setTaggableUsers, taggableUsers } = tagStore
  const [selectedUsers, setSelectedUsers] = useState<any[]>([])

  const handleSubmit = () => {
    addUsersToTags(
      selectedUsers.map((u) => u.id),
      tagIds,
    )
    onClose()
  }

  const handleChange = (user: any) => {
    setSelectedUsers([...selectedUsers, user])
  }

  const removeSelectedUser = (user: any) => {
    setSelectedUsers(selectedUsers.filter((u) => u.user_id !== user.user_id))
  }

  useEffect(() => {
    return () => {
      setTaggableUsers(undefined)
    }
  }, [])

  useEffect(() => {
    setTaggableUsers(taggableUsers)
  }, [taggableUsers])

  return (
    <Dialog
      maxWidth="sm"
      onConfirm={handleSubmit}
      onClose={onClose}
      title="Tag Users"
      aria-labelledby="tag users dialog"
      open={open}
      confirmBtnLabel="Tag Users"
      disableConfirm={!selectedUsers.length}
    >
      <Box height={600}>
        <Text variant="body1" text="Search" style={{ color: color.shade70, marginBottom: 12 }} />
        <UserSelector
          onSearch={(filter) => getTaggableUsers(filter, tagIds)}
          users={taggableUsers}
          selectedUsers={selectedUsers}
          onChange={(user: any) => handleChange(user)}
        />
        <Text
          variant="body2"
          text="Search for users to tag"
          style={{ color: color.shade50, marginTop: 4, marginBottom: 32 }}
        />
        {selectedUsers.length ? (
          <Text
            variant="body1"
            text={`Selected Users (${selectedUsers.length})`}
            style={{ color: color.shade70, marginBottom: 21 }}
          />
        ) : null}
        {selectedUsers.map((user) => (
          <div style={{ display: 'flex', paddingTop: 4, paddingBottom: 4, alignItems: 'center' }}>
            <Avatar name={`${user.first_name} ${user.last_name}`} url={user.photo_url} />
            <div style={{ display: 'flex', flexDirection: 'column', marginLeft: 8 }}>
              <Text variant="body1Emphasized" text={`${user.first_name} ${user.last_name}`} />
              <Text variant="body2Emphasized" text={user.email} style={{ color: color.shade50 }} />
            </div>

            <IconButton
              style={{ alignSelf: 'flex-end' }}
              onClick={() => removeSelectedUser(user)}
              size="large"
            >
              <Close />
            </IconButton>
          </div>
        ))}
      </Box>
    </Dialog>
  )
})
