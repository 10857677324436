import { types, Instance, flow, cast } from 'mobx-state-tree'
import { DateTime } from 'luxon'
import { sortWith, descend, ascend, prop } from 'ramda'
import { BadgeApi } from '../../services/api-objects/BadgeApi'
import { Awaited } from '../../utility-types'
import {
  BadgeReadModel,
  BadgeReadModelType,
  BadgeUserReadModel,
  BadgeCreateModelType,
} from '../../models/base/badge'
import { createFileFromBlob } from '../../utils'
import { SurveyGroup, SurveyUser } from '../survey/SurveyUserGroup'

export const BadgesStoreModel = types
  .model('BadgesStore')
  .props({
    status: types.optional(types.enumeration(['idle', 'pending', 'done', 'error']), 'idle'),
    allBadges: types.maybe(types.array(BadgeReadModel)),
    customBadges: types.maybe(types.array(BadgeReadModel)),
    defaultBadges: types.maybe(types.array(BadgeReadModel)),
    availableUsers: types.array(SurveyUser),
    availableGroups: types.array(SurveyGroup),
    badgeUsers: types.array(BadgeUserReadModel),
    triggerRefresh: types.frozen(),
  })
  .actions((self) => ({
    setStatus(value?: 'idle' | 'pending' | 'done' | 'error') {
      self.status = value || 'idle'
    },
    setAllBadges(data: typeof self.allBadges) {
      self.allBadges = data
    },
    setCustomBadges(data: typeof self.customBadges) {
      self.customBadges = data
    },
    setDefaultBadges(data: typeof self.defaultBadges) {
      self.defaultBadges = data
    },
    setAvailableUsers(data: typeof self.availableUsers) {
      self.availableUsers = data
    },
    setAvailableGroups(data: typeof self.availableGroups) {
      self.availableGroups = data
    },
    setBadgeUsers(data: typeof self.badgeUsers) {
      self.badgeUsers = data
    },
    setTriggerRefresh(status: any) {
      self.triggerRefresh = status
    },
  }))
  .views((self) => ({
    get isLoading() {
      return self.status === 'pending'
    },
  }))
  .actions((self) => ({
    createBadge: flow(function* (payload: BadgeCreateModelType) {
      self.setStatus('pending')
      try {
        if (payload.icon_url) {
          const file = yield createFileFromBlob(payload.icon_url, `badge_${payload.label}`)
          const result: Awaited<ReturnType<typeof BadgeApi.createBadge>> =
            yield BadgeApi.createBadge({ ...payload, file })
          if (result?.ok) {
            self.setTriggerRefresh(true)
            self.setStatus('done')
          } else {
            self.setStatus('error')
          }
        }
      } catch {
        self.setStatus('error')
      }
    }),
    editBadge: flow(function* (badge_id: number, payload: BadgeCreateModelType) {
      self.setStatus('pending')
      try {
        if (payload.icon_url) {
          let file
          if (payload.icon_url.includes('blob:')) {
            file = yield createFileFromBlob(payload.icon_url, `badge_${payload.label}`)
          }
          const result: Awaited<ReturnType<typeof BadgeApi.editBadge>> = yield BadgeApi.editBadge(
            badge_id,
            { ...payload, file },
          )
          if (result.ok) {
            self.setStatus('done')
          } else {
            self.setStatus('error')
          }
        }
      } catch {
        self.setStatus('error')
      }
    }),
    deleteBadge: flow(function* (badge_id: number) {
      self.setStatus('pending')
      try {
        const result: Awaited<ReturnType<typeof BadgeApi.deleteBadge>> =
          yield BadgeApi.deleteBadge(badge_id)
        if (result.ok) {
          self.setStatus('done')
        } else {
          self.setStatus('error')
        }
      } catch {
        self.setStatus('error')
      }
    }),
    toggleBadgeDisable: flow(function* (badge_id: number) {
      self.setStatus('pending')
      try {
        const result: Awaited<ReturnType<typeof BadgeApi.toggleBadgeDisable>> =
          yield BadgeApi.toggleBadgeDisable(badge_id)
        if (result.ok) {
          self.setStatus('done')
        } else {
          self.setStatus('error')
        }
      } catch {
        self.setStatus('error')
      }
    }),
    getAvailableUsersForBadge: flow(function* (badge_id: number) {
      self.setStatus('pending')
      try {
        const result: Awaited<ReturnType<typeof BadgeApi.getAvailableUsersForBadge>> =
          yield BadgeApi.getAvailableUsersForBadge(badge_id)
        if (result.ok && result.data) {
          self.setAvailableGroups(cast(result.data.groups))
          self.setAvailableUsers(cast(result.data.users))
          self.setStatus('done')
        } else {
          self.setStatus('error')
        }
      } catch {
        self.setStatus('error')
      }
    }),
    awardBadge: flow(function* (payload: {
      badge_id: number
      user_ids: number[]
      group_ids: number[]
    }) {
      self.setStatus('pending')
      try {
        const result: Awaited<ReturnType<typeof BadgeApi.awardBadge>> =
          yield BadgeApi.awardBadge(payload)
        if (result.ok) {
          self.setStatus('done')
        } else {
          self.setStatus('error')
        }
      } catch {
        self.setStatus('error')
      }
    }),
    revokeBadgeFromUser: flow(function* (payload: { badge_id: number; user_id: number }) {
      self.setStatus('pending')
      try {
        const result: Awaited<ReturnType<typeof BadgeApi.revokeBadge>> =
          yield BadgeApi.revokeBadge(payload)
        if (result.ok) {
          ;(self as Instance<typeof BadgesStoreModel>).getBadgeUsers(payload.badge_id)
          self.setTriggerRefresh(true)
          self.setStatus('done')
        } else {
          self.setStatus('error')
        }
      } catch {
        self.setStatus('error')
      }
    }),
    getBadgeUsers: flow(function* (badge_id: number) {
      self.setStatus('pending')
      try {
        const result: Awaited<ReturnType<typeof BadgeApi.badgeUsers>> =
          yield BadgeApi.badgeUsers(badge_id)
        if (result.ok && result.data) {
          self.setBadgeUsers(cast(result.data.users))
          self.setStatus('done')
        } else {
          self.setStatus('error')
        }
      } catch {
        self.setStatus('error')
      }
    }),
  }))

type BadgesStoreType = Instance<typeof BadgeReadModel>
export interface BadgesStore extends BadgesStoreType {}
