import { Instance, types } from 'mobx-state-tree'
import { QuizReadModel } from '../quiz'
import { ResourceReadModel } from '../resource'
// import { PostReadModel } from '../talk/PostReadModel'

/**
 * LessonRead Model
 */
const LessonReadModel = types.model('LessonRead').props({
  __typename: types.optional(types.literal('LessonRead'), 'LessonRead'),
  description: types.string,
  topicId: types.identifierNumber,
  isLocked: types.boolean,
  isArchived: types.boolean,
  isDeleted: types.boolean,
  title: types.string,
  contentOrdering: types.frozen(),
  estimatedTime: types.number,
  resources: types.maybe(types.array(types.maybe(types.late(() => ResourceReadModel)))),
  quizzes: types.maybe(types.array(types.maybe(types.late(() => QuizReadModel)))),
})

/* The TypeScript type of an instance of LessonReadModel */
export interface LessonReadModelType extends Instance<typeof LessonReadModel> {}

export const TopicReadBaseModel = types.model('TopicReadBase').props({
  __typename: types.optional(types.literal('TopicReadBase'), 'TopicReadBase'),
  description: types.string,
  topic_id: types.maybeNull(types.number),
  is_archived: types.boolean,
  is_deleted: types.boolean,
  is_locked: types.boolean,
  title: types.string,
  posts: types.frozen(),
  photo: types.maybeNull(types.string),
  lessons: types.frozen(),
  defaultColor: types.string,
})

/* The TypeScript type of an instance of TopicReadModel */
export interface TopicReadModelBaseType extends Instance<typeof TopicReadBaseModel> {}

/**
 * TopicRead Model
 */
export const TopicReadModel = types.model('TopicRead').props({
  __typename: types.optional(types.literal('TopicRead'), 'TopicRead'),
  assignment_id: types.maybeNull(types.number),
  knowledge_pct: types.maybeNull(types.number),
  topic: TopicReadBaseModel,
})

/* The TypeScript type of an instance of TopicReadModel */
export interface TopicReadModelType extends Instance<typeof TopicReadModel> {}
