import { Instance, types } from 'mobx-state-tree'

/**
 * ResourceRead Model
 */
export const ResourceReadModel = types.model('ResourceRead').props({
  __typename: types.optional(types.literal('ResourceRead'), 'ResourceRead'),
  alt_text: types.maybeNull(types.string),
  description: types.maybeNull(types.string),
  extension: types.string,
  is_locked: types.boolean,
  is_deleted: types.boolean,
  is_archived: types.boolean,
  id: types.identifierNumber,
  object_id: types.maybeNull(types.integer),
  object_type: types.maybeNull(types.string),
  original_filename: types.maybeNull(types.string),
  is_recommended: types.boolean,
  is_required: types.boolean,
  title: types.string,
  url: types.maybeNull(types.string),
  thumbnail_url: types.string,
  file_url: types.string,
})

/* The TypeScript type of an instance of ResourceReadModel */
export interface ResourceReadModelType extends Instance<typeof ResourceReadModel> {}
