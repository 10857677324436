import React from 'react'
import { Link } from 'react-router-dom'
import TableCell from '@mui/material/TableCell'
import { IconButton } from '@mui/material'
import { AddRounded, CloseRounded, LocalOffer, PlusOneRounded } from '@mui/icons-material'

import { color } from '../../../theme'
import { FullTag } from '../../full-tag'

export const TagShortCell = (props: any) => {
  const { row, action, remove } = props
  return (
    <TableCell style={CELL} align="left">
      <div style={CONTAINER}>
        <Link style={LINK} to={`/people/tags/${row.id}/overview`}>
          <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
            <LocalOffer htmlColor={color.shade70} />
            <FullTag
              showPointer
              tag={row}
              style={{
                height: 29,
                backgroundColor: 'transparent',
              }}
            />
          </div>
        </Link>
        {Boolean(action) && (
          <IconButton onClick={() => action(row.id)}>
            {remove ? <CloseRounded /> : <AddRounded />}
          </IconButton>
        )}
      </div>
    </TableCell>
  )
}

const CELL: React.CSSProperties = { borderBottom: 'none', padding: 0 }
const CONTAINER: React.CSSProperties = {
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'space-between',
}
const LINK: React.CSSProperties = {
  flexDirection: 'row',
  display: 'flex',
  textDecoration: 'none',
  color: color.black,
  alignItems: 'center',
}
