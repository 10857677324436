/* eslint-disable */

import { Instance, types } from 'mobx-state-tree'
import { ChoiceReadModel } from '../question'
import { SurveyQuestion } from '../../Survey'
/**
 * AssignmentUserRead Model
 */

export const StartSurveyResponseModel = types.model('StartSurveyResponseModel').props({
  survey_id: types.number,
  user_id: types.number,
  title: types.string,
  responded: types.boolean,
  questions: types.array(SurveyQuestion),
})

export const SurveyResponseModel = types.model('SurveyResponseModel').props({
  question_id: types.number,
  question_type: types.string,
  range_value: types.maybeNull(types.number),
  text_value: types.maybeNull(types.string),
  choice_ids: types.array(types.number),
})

export const SurveySubmitRequestModel = types.model('SurveySubmitRequestModel').props({
  survey_id: types.number,
  user_id: types.number,
  is_anonymous: types.boolean,
  responses: types.array(SurveyResponseModel),
})

export interface StartSurveyResponseModelType
  extends Instance<typeof StartSurveyResponseModel.Type> {}

export interface SurveyResponseModelType extends Instance<typeof SurveyResponseModel.Type> {}
export interface SurveySubmitRequestModelType
  extends Instance<typeof SurveySubmitRequestModel.Type> {}
