import { ApiResponse, ApisauceInstance } from 'apisauce'
import { handleResponse } from '../handleResponse'
import { Instance } from 'mobx-state-tree'
import { MessagesResponse, ScheduledSentMessage } from '../../models/Message'
import { MessagePayload } from '../../models/MessagePayload'
import { MessageRecipients } from '../../models/MessageRecipients'

export const MessagesApi = {
  apisauce: {} as ApisauceInstance,

  /**
   * List all messages
   */
  getAllMessages: async (
    type: 'users' | 'topics' | 'quizzes' | 'groups' | 'tags',
    meta?: boolean,
    columns?: any,
    view_name?: string,
    id?: number,
    filter?: 'custom' | 'trivie',
  ) => {
    const payload = { view_name }
    if (columns && columns.length > 0) {
      payload['columns'] = columns
    }

    const response: ApiResponse<any> = await MessagesApi.apisauce.post(
      `/messages/?status=${filter}&meta=${meta}&asynchronous=${false}`,
      payload,
    )

    return handleResponse(response)
  },

  /**
   * List Messages
   */
  async getMessages(status: 'all' | 'scheduled' | 'sent', page: number): Promise<any> {
    const response: ApiResponse<Instance<typeof MessagesResponse>> = await MessagesApi.apisauce.get(
      `/messages/?status=${status}&start_page=${page}`,
    )
    return handleResponse(response)
  },

  /**
   * Get Message
   */
  async getMessage(id: number): Promise<any> {
    const response: ApiResponse<Instance<typeof ScheduledSentMessage>> =
      await MessagesApi.apisauce.get(`/messages/${id}/`)
    return handleResponse(response)
  },

  /**
   * Get Available Recipients
   */
  async getAvailableRecipients(): Promise<any> {
    const response: ApiResponse<Instance<typeof MessageRecipients>> =
      await MessagesApi.apisauce.get(`/messages/available_recipients/`)
    return handleResponse(response)
  },

  /**
   * Create/Send Message
   */
  async createMessage(message: Instance<typeof MessagePayload>): Promise<any> {
    const response: ApiResponse<Instance<typeof MessagePayload>> = await MessagesApi.apisauce.post(
      `/users/message/`,
      message,
    )
    return handleResponse(response)
  },

  /**
   * Create/Send Contest Message
   */
  async createContestMessage(message: Instance<typeof MessagePayload>): Promise<any> {
    const response: ApiResponse<Instance<typeof MessagePayload>> = await MessagesApi.apisauce.post(
      `/contests/message/`,
      message,
    )
    return handleResponse(response)
  },

  /**
   * Create/Send Assignment Nudge
   */
  async nudge(message: Instance<typeof MessagePayload>, assignment_id: number): Promise<any> {
    const response: ApiResponse<Instance<typeof MessagePayload>> = await MessagesApi.apisauce.post(
      `/assignments/${assignment_id}/nudge/`,
      message,
    )
    return handleResponse(response)
  },

  /**
   * Create/Send Survey Nudge
   */
  async surveyNudge(message: Instance<typeof MessagePayload>): Promise<any> {
    const response: ApiResponse<Instance<typeof MessagePayload>> = await MessagesApi.apisauce.post(
      `/surveys/nudge/`,
      message,
    )
    return handleResponse(response)
  },

  /**
   * Edit Message
   */
  async editMessage(message: Instance<typeof MessagePayload>, message_id: number): Promise<any> {
    const response: ApiResponse<Instance<typeof MessagePayload>> = await MessagesApi.apisauce.patch(
      `/messages/${message_id}/`,
      { ...message, message_id },
    )
    return handleResponse(response)
  },

  /**
   * Get Message
   */
  async deleteMessage(id: number): Promise<any> {
    const response: ApiResponse<object> = await MessagesApi.apisauce.delete(`/messages/${id}/`)
    return handleResponse(response)
  },

  async markAllAsRead(id: number): Promise<any> {
    const response: ApiResponse<any> = await MessagesApi.apisauce.post(
      `/users/${id}/notifications/mark_all_as_read/`,
      {},
    )
    return handleResponse(response)
  },
}
