export const matchCitations = (gqids, questions) => {
  const citations = [] as any
  if (gqids?.length) {
    gqids.map((gqid) => {
      if (gqid) {
        let matchingGqid
        questions.map((question) => {
          if (question.generated_question_id === gqid) {
            matchingGqid = question
          }
        })
        if (gqid !== null && matchingGqid) {
          citations.push({ level: questions.indexOf(matchingGqid) + 1, gqid })
        }
      }
    })
  }
  return citations
}
