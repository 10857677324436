import { types } from 'mobx-state-tree'
import { UserShortSchemaModel } from '../user/UserShortSchemaModel'

/**
 * SegmentRead Model
 */
export const SegmentReadModel = types.model('SegmentRead').props({
  __typename: types.optional(types.literal('SegmentRead'), 'SegmentRead'),
  addedUsers: types.union(
    types.null,
    types.array(
      types.union(
        types.null,
        types.late(() => UserShortSchemaModel),
      ),
    ),
  ),
  baseUsers: types.union(
    types.null,
    types.array(
      types.union(
        types.null,
        types.late(() => UserShortSchemaModel),
      ),
    ),
  ),
  id: types.integer,
  isEditable: types.boolean,
  isVisible: types.boolean,
  label: types.string,
  removedUsers: types.union(
    types.null,
    types.array(
      types.union(
        types.null,
        types.late(() => UserShortSchemaModel),
      ),
    ),
  ),
})
