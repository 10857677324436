import React, { useEffect, useState } from 'react'

import { observer } from 'mobx-react-lite'
import { DateTime } from 'luxon'
import { Instance } from 'mobx-state-tree'

import { Dialog } from '@trivie/ui-web/components/dialogs/dialog'
import { UserSchedule } from '@trivie/ui-web/components/invite-users/tabs/user-schedule-tab'
import { InviteUserForm } from '@trivie/core/src/models/InviteUser'
import { color } from '@trivie/ui-web/theme'
import { Text } from '@trivie/ui-web/components/text'
import { FileInput } from '@trivie/ui-web/components/file-input'
import { Button } from '@trivie/ui-web/components/button'

import XLSX from 'xlsx'

// @ts-ignore
import { ReactComponent as ExcelFile } from '@trivie/ui-web/assets/icons/Excel.svg'

export interface ImportUsersProps {
  open: boolean
  onClose: () => void
  onImport: (users: any) => void
}

const downloadImportTemplate = () => {
  const a = document.createElement('a')
  a.download = 'trivie_people_template'
  a.href = '/trivie_people_template.xlsx'
  document.body.appendChild(a)
  a.click()
  document.body.removeChild(a)
}

export const ImportUsers = observer((props: ImportUsersProps) => {
  const { open, onClose, onImport } = props

  const [form, setForm] = useState<Instance<typeof InviteUserForm> | null>(null)

  const [userErrors, setUserErrors] = useState({ phone: '' })
  const [userCount, setUserCount] = useState()

  useEffect(() => {
    setForm(
      InviteUserForm.create({
        start_date: DateTime.now().toFormat('yyyy-MM-dd'),
        start_time: DateTime.now().toLocaleString(DateTime.TIME_24_SIMPLE),
        first_name: '',
        last_name: '',
        email: '',
        phone_number: '',
        send_email: false,
        send_sms: false,
        tag_keys_values: [],
        employee_id: '',
        missingRequired: false,
        missingRequiredAdmin: false,
      }),
    )
  }, [])

  if (!form) {
    return null
  }

  const readXlsx = (file: any) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader()

      reader.onabort = () => console.log('file reading was aborted')
      reader.onerror = () => reject()
      reader.onloadend = () => {
        const workbook = XLSX.read(reader.result, { type: 'array' })
        const sheetNames = workbook.SheetNames
        const worksheet = workbook.Sheets[sheetNames[0]]
        const jsonOutput = XLSX.utils.sheet_to_json(worksheet) as any

        resolve(jsonOutput)
      }

      reader.readAsArrayBuffer(file)
    })
  }

  const readCsv = async (file: any) => {
    const parsed = await file.text()
    return parsed
      .split('\n')
      .slice(1)
      .reduce((acc: any, row: string) => {
        if (row) {
          const values = row.split(',')
          const user: any = {
            email: values[0],
            first_name: values[1],
            last_name: values[2],
            employee_id: String(values[3]).trim(),
            phone_number: '',
          }
          acc.push(user)
        }
        return acc
      }, [])
  }

  const handleImport = async (file: any) => {
    const payload: any = {
      upload_file: file,
      send_sms_invite: form.send_sms,
      send_email_invite: form.send_email,
      send_datetime: form?.datetimeUTC ?? DateTime.now().toUTC().toISO(),
    }
    if (form.send_email || form.send_sms) {
      payload.send_datetime = form?.datetimeUTC
    }
    onImport(payload)
  }

  const handleFile = async (file: any) => {
    if (!file) {
      setUserErrors({ phone: '' })
    }
    form.set('file', file)
    if (file) {
      const isXlsx =
        file.type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
      const users = isXlsx ? await readXlsx(file) : await readCsv(file)
      setUserCount(users?.length)
    }
  }

  return (
    <Dialog
      open={open}
      title="Import Users"
      onClose={onClose}
      maxWidth="sm"
      actions={
        <>
          {userErrors.phone ? (
            <Button
              disabled={!form.file}
              style={{ width: '100%', height: 69, backgroundColor: 'rgba(0,0,0,.04)' }}
              variant="contained"
              disableElevation
              onClick={() => handleImport(form.file)}
            >
              <div style={{ flexDirection: 'column' }}>
                <Text
                  text="Import Anyway"
                  style={{ color: color.red, fontWeight: '600', fontSize: 14 }}
                />
                <Text
                  variant="body2"
                  text="Users without a phone number will not receive an invitation via SMS."
                  style={{ color: color.red }}
                />
              </div>
            </Button>
          ) : (
            <Button
              text={
                form.file && userCount
                  ? `Import ${userCount} User${userCount === 1 ? '' : 's'}`
                  : 'Import Users'
              }
              disabled={!form.file || (form.file && userCount === 0)}
              variant="contained"
              fullWidth
              color="primary"
              disableElevation
              data-qat="import"
              onClick={() => handleImport(form.file)}
            />
          )}
        </>
      }
    >
      <FileInput
        accept="text/csv,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,application/vnd.ms-excel"
        file={form.file}
        helperText="Supports Excel or CSV"
        error={userErrors.phone}
        style={{ marginBottom: 20 }}
        onChange={handleFile}
      />
      {form.file && userCount === 0 ? (
        <Text
          variant="body2"
          style={{ color: color.red }}
          text="The file appears to be empty. Please check the import template and try again."
        />
      ) : null}
      <UserSchedule form={form} />
      <Text
        component="div"
        variant="body2Emphasized"
        text="DOWNLOAD TEMPLATE"
        style={{ color: color.palette.grey, marginBottom: 16 }}
      />
      <div
        data-qat="download_template"
        onClick={() => downloadImportTemplate()}
        style={DL_TEMPLATE}
      >
        <ExcelFile />
        <Text variant="body1Medium" text="Excel Template" />
      </div>
    </Dialog>
  )
})

const DL_TEMPLATE: React.CSSProperties = {
  textDecoration: 'none',
  color: color.black,
  display: 'flex',
  backgroundColor: color.palette.athensGrey,
  borderRadius: 10,
  height: 56,
  alignItems: 'center',
  cursor: 'pointer',
  padding: 20,
  flexDirection: 'row',
  gap: 12,
  position: 'relative',
  width: 176,
}
