import React from 'react'
import { observer } from 'mobx-react-lite'
import { ActionTooltip } from '@trivie/ui-web/components/table-toolbar'

export const InsertStepButton = observer((props: any) => {
  const { workflow, disabled, readonly, node, step, index, handleAddStep } = props
  return (
    <svg
      onClick={(e) => {
        if (
          disabled ||
          workflow?.stub ||
          (workflow?.selectedStep && workflow?.selectedStep?.reference_id !== step?.reference_id)
        ) {
          return
        }
        handleAddStep()
        if (!readonly) {
          workflow.insertStepAfter(node, step, index)
        }
      }}
      className="InsertStep"
      style={{
        display: readonly ? 'none' : 'flex',
        cursor:
          (workflow?.selectedStep && workflow?.selectedStep?.reference_id !== step?.reference_id) ||
          disabled ||
          workflow?.stub
            ? 'default'
            : 'pointer',
        alignItems: 'center',
        opacity:
          disabled ||
          workflow?.stub ||
          (workflow?.selectedStep && workflow?.selectedStep?.reference_id !== step?.reference_id)
            ? 0.2
            : 1,
        margin: '0 auto',
        width: 100,
      }}
      width="24"
      height="48"
      viewBox="0 0 24 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <line x1="12.5" y1="4" x2="12.5" y2="12" stroke="black" strokeOpacity="0.7" />
      <path
        d="M12 20.6666C11.6333 20.6666 11.3333 20.9666 11.3333 21.3333V23.3333H9.33334C8.96668 23.3333 8.66668 23.6333 8.66668 24C8.66668 24.3666 8.96668 24.6666 9.33334 24.6666H11.3333V26.6666C11.3333 27.0333 11.6333 27.3333 12 27.3333C12.3667 27.3333 12.6667 27.0333 12.6667 26.6666V24.6666H14.6667C15.0333 24.6666 15.3333 24.3666 15.3333 24C15.3333 23.6333 15.0333 23.3333 14.6667 23.3333H12.6667V21.3333C12.6667 20.9666 12.3667 20.6666 12 20.6666ZM12 17.3333C8.32001 17.3333 5.33334 20.32 5.33334 24C5.33334 27.68 8.32001 30.6666 12 30.6666C15.68 30.6666 18.6667 27.68 18.6667 24C18.6667 20.32 15.68 17.3333 12 17.3333ZM12 29.3333C9.06001 29.3333 6.66668 26.94 6.66668 24C6.66668 21.06 9.06001 18.6666 12 18.6666C14.94 18.6666 17.3333 21.06 17.3333 24C17.3333 26.94 14.94 29.3333 12 29.3333Z"
        fill="black"
        fillOpacity="0.7"
      />
      <path
        d="M11.6464 44.3536C11.8417 44.5488 12.1583 44.5488 12.3536 44.3536L15.5355 41.1716C15.7308 40.9763 15.7308 40.6597 15.5355 40.4645C15.3403 40.2692 15.0237 40.2692 14.8284 40.4645L12 43.2929L9.17157 40.4645C8.97631 40.2692 8.65973 40.2692 8.46447 40.4645C8.2692 40.6597 8.2692 40.9763 8.46447 41.1716L11.6464 44.3536ZM11.5 36V44H12.5V36H11.5Z"
        fill="black"
        fillOpacity="0.7"
      />
    </svg>
  )
})
