/* eslint-disable */

import { Api } from '../services/api'

/**
 * The environment is a place where services and shared dependencies between
 * models live.  They are made available to every model via dependency injection.
 */
export class Environment {
  constructor() {
    this.api = new Api()
  }

  async setup(
    version: string,
    showMaintenance: (show: boolean) => void,
    timezone: string,
    showAlert?: (status: string, message: string, reset: boolean) => void,
    forceUpgrade?: () => void,
    insights?: boolean,
  ) {
    await this.api.setup(version, showMaintenance, timezone, showAlert, () => {}, insights)
  }

  /**
   * Our api.
   */
  api: Api
}
