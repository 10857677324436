import { ListSelectorItem } from '../models/ListSelectorItem'
import { SnapshotIn, types, cast } from 'mobx-state-tree'
import { DateTime } from 'luxon'

const _keys = require('lodash/keys')
const _pickBy = require('lodash/pickBy')

export const ContestCreateForm = types
  .model('ContestCreateForm')
  .props({
    id: types.maybe(types.number),
    start_datetime: types.maybe(types.string),
    end_datetime: types.maybe(types.string),
    start_date: types.string,
    start_time: types.string,
    end_date: types.string,
    end_time: types.string,
    title: types.string,
    description: types.string,
    quiz_ids: types.frozen(),
    topic_ids: types.frozen(),
    group_ids: types.frozen(),
    user_ids: types.frozen(),
    content: types.array(ListSelectorItem),
    topics: types.frozen(),
    quizzes: types.frozen(),
    max_leaderboard_length_enabled: types.boolean,
    max_leaderboard_length: types.maybeNull(types.number),
    notify_users: types.boolean,
    scoring_method: types.string,
    content_method: types.string,
    custom_scoring: types.model({
      answers: types.boolean,
      badges: types.boolean,
      comments: types.boolean,
      posts: types.boolean,
      reinforcements: types.boolean,
      surveys: types.boolean,
      tests: types.boolean,
    }),
  })
  .views((self) => {
    return {
      get emptyScoring() {
        if (self.scoring_method === 'custom') {
          return Boolean(_keys(_pickBy(self.custom_scoring))?.length === 0)
        }
        return false
      },
      get selectedTopics() {
        return self.content
          .filter((t) => {
            return t.type === 'topics' && t.checked
          })
          .map((t) => t.id)
      },
      get selectedQuizzes() {
        return self.content
          .filter((q) => {
            return q.type === 'quizzes' && q.checked
          })
          .map((q) => q.id)
      },
      get payload() {
        const p = {
          title: self.title,
          description: self.description,
          start_datetime: DateTime.fromJSDate(new Date(`${self.start_date}T${self.start_time}:00`))
            .toUTC()
            .toISO(),
          end_datetime: DateTime.fromJSDate(new Date(`${self.end_date}T${self.end_time}:00`))
            .toUTC()
            .toISO(),
          group_ids: self.group_ids.map((g) => g.id || g.group_id),
          user_ids: self.user_ids.map((u) => u.id || u.user_id),
          max_leaderboard_length: self.max_leaderboard_length_enabled
            ? self.max_leaderboard_length
            : null,
          notify_users: self.notify_users,
          quiz_ids: this.selectedQuizzes,
          topic_ids: this.selectedTopics,
          scoring_method: self.scoring_method,
          custom_scoring: self.custom_scoring,
        }
        if (self.id) {
          p['id'] = self.id
        }
        return p
      },
      get errors() {
        const errors: any = {}
        if (!self.title) {
          errors.title = {
            type: 'required',
            message: 'Name is required',
          }
        }

        if (self.title?.length >= 40) {
          errors.title = {
            type: 'required',
            message: 'Maximum of 40 characters',
          }
        }

        if (!self.description) {
          errors.description = {
            type: 'required',
            message: 'Description is required',
          }
        }

        if (self.description?.length >= 280) {
          errors.description = {
            type: 'required',
            message: 'Maximum of 280 characters',
          }
        }

        if (!self.start_date) {
          errors.start_date = {
            type: 'required',
            message: 'Start Date is required',
          }
        }

        if (!self.start_time) {
          errors.start_time = {
            type: 'required',
            message: 'Start Time is required',
          }
        }

        if (!self.end_date) {
          errors.end_date = {
            type: 'required',
            message: 'End Date is required',
          }
        }

        if (!self.end_time) {
          errors.end_time = {
            type: 'required',
            message: 'End Time is required',
          }
        }

        // no need to validate beyond this since we're missing required fields
        if (Object.keys(errors).length) {
          return errors
        }

        const startDateTime = DateTime.fromISO(`${self.start_date}T${self.start_time}:00`)
        const endDateTime = DateTime.fromISO(`${self.end_date}T${self.end_time}:00`)

        if (!startDateTime.isValid) {
          return {
            start_date: {
              type: 'invalid',
              message: 'Date is Invalid',
            },
          }
        }

        if (!endDateTime.isValid) {
          return {
            end_date: {
              type: 'invalid',
              message: 'Date is Invalid',
            },
          }
        }

        if (endDateTime < startDateTime) {
          errors.start_date = {
            type: 'startTimeAfterEndTime',
            message: 'Must start before End Date and Time',
          }
          errors.start_time = {
            type: 'startTimeAfterEndTime',
          }
        }

        return errors
      },
    }
  })
  .actions((self) => {
    return {
      set<K extends keyof SnapshotIn<typeof self>, T extends SnapshotIn<typeof self>>(
        key: K,
        value: T[K],
      ) {
        // @ts-ignore
        self[key] = cast(value)
      },
    }
  })
