import { Instance, cast, destroy, types } from 'mobx-state-tree'
import { isCondition, isAction } from '../../type-guards/isAction'
import { Condition } from './Condition'
import { Action } from './Action'
import { Node } from './Node'

const _takeWhile = require('lodash/takeWhile')
const _flattenDeep = require('lodash/flattenDeep')

export const Workflow = types
  .model('Workflow')
  .props({
    actions: types.array(Action),
    conditions: types.array(Condition),
    nodes: types.array(Node),
    name: types.string,
    version: types.number,
    editing: types.maybe(types.string),
    selectedAction: types.maybe(types.string),
    selectedCondition: types.maybe(types.string),
    inserting: types.maybe(types.boolean),
  })
  .views((self) => ({
    get selectedStep() {
      if (self.selectedAction) {
        return self.actions.find((a) => a.reference_id === self.selectedAction)
      } else if (self.selectedCondition) {
        return self.conditions.find((c) => c.reference_id === self.selectedCondition)
      } else {
        return undefined
      }
    },
    get upNext() {
      let next
      self.nodes.map((node) => {
        node.steps.map((step) => {
          if (!next && !node.is_completed) {
            next = { node, step }
          }
        })
      })
      return next
    },
    get completed() {
      let complete = true
      self.nodes.map((n) => {
        if (!n.is_completed) {
          complete = false
        }
      })
      return complete
    },
    get stub() {
      let stub = self.actions.find((a) => a.stub) as any
      if (!stub) {
        stub = self.conditions.find((c) => c.stub)
      }
      return stub
    },
    get valid() {
      return self.nodes.length >= 1 && !this.stub
    },
    get currentNode() {
      return self.nodes[self.nodes.length - 1]
    },
    get stepCount() {
      let count = 0
      self.nodes.map((node) => (count += node?.steps.length))
      return count
    },
    get flat() {
      return _flattenDeep(self.nodes.map((node) => node.steps))
    },
    get rootHasActions() {
      if (this.flat?.length && isCondition(this.flat[0])) {
        const n = self?.nodes.find((n) =>
          n.conditions.find((c) => c.criteria.includes(this.flat[0].reference_id)),
        )
        if (n && n?.actions?.length > 0) {
          return true
        }
      }
      return false
    },
  }))
  .actions((self) => ({
    reorder(updated) {
      if (updated?.length === 0) {
        return
      }
      let order = 0
      updated?.map((step, idx) => {
        if (isCondition(step)) {
          order += 1
          const matchingNode = self.nodes.find((n) =>
            n.conditions.find((c) => c.criteria.includes(step.reference_id)),
          ) as any
          // take from reordered array while item is an action
          // add actions to new matching node arr / update ordering
          const actions = _takeWhile(updated.slice(idx + 1), (o) => isAction(o))
          matchingNode.setActions(actions.map((a) => a.reference_id))
          matchingNode.setOrder(order)
        }
      })
      self.nodes.replace(self.nodes.sort((a, b) => a.order - b.order))
    },
    makeReferencesUnique() {
      // Update node ids
      self.nodes.map((node) => {
        const new_node_id = crypto.randomUUID()
        node.setNodeId(new_node_id)
      })
      // Update actions with new reference ids
      self.actions.map((action) => {
        const { reference_id } = action
        const new_reference_id = crypto.randomUUID()
        action.setReferenceId(new_reference_id)
        //  since we are duplicating here, just remove join actions from workflow - will be handled on publish
        if (action.action_type === 'join') {
          destroy(action)
        } else {
          self.nodes.map((node) => {
            // Otherwise find nodes where old action id is referenced and change to use the new reference_id
            const idx = node.actions.indexOf(reference_id)
            const newActions = [...node.actions]
            if (idx >= 0) {
              newActions[idx] = new_reference_id
            }
            node.setActions(cast(newActions))
          })
        }
      })
      // Update condition reference ids
      self.conditions.map((condition) => {
        const { reference_id } = condition
        const new_reference_id = crypto.randomUUID()
        self.nodes.map((node) => {
          node.conditions.map((node_condition) => {
            const idx = node_condition.criteria.indexOf(reference_id)
            const criteria = [...node_condition.criteria]
            if (idx >= 0) {
              criteria[idx] = new_reference_id
            }
            node_condition.setCriteria(cast([...criteria]))
          })
        })
        condition.setReferenceId(new_reference_id)
      })
    },
    setVersion(version: number) {
      self.version = version
    },
    setInserting(inserting: boolean) {
      self.inserting = inserting
    },
    insertStepAfterNode(node, index) {
      // create action stub
      const reference = crypto.randomUUID()
      const newAction = Action.create({
        stub: true,
        action_type: 'stub',
        description: '',
        force_evaluate: false,
        label: '',
        reference_id: reference,
        user_visible: false,
        conditions: [],
        tag_ids: [],
        recipients: [],
      })

      // don't shift join action to newly inserted node
      const nodeActions = [] as string[]
      node.actions?.map((id) => {
        const found = self.actions.find((action) => action.reference_id === id)
        if (found && found.action_type !== 'join') {
          nodeActions.push(id)
        }
      })

      const references = [reference, ...nodeActions]

      const actions = [...self.actions]
      actions.splice(index, 0, newAction)

      this.setActions(actions)
      node?.setActions(references)
    },
    insertStepAfterAction(node, step, index) {
      const reference = crypto.randomUUID()
      const newAction = Action.create({
        stub: true,
        action_type: '',
        description: '',
        force_evaluate: false,
        label: '',
        reference_id: reference,
        user_visible: false,
        conditions: [],
        tag_ids: [],
        recipients: [],
      })

      const references = [...node.actions]
      references.splice(references.indexOf(step.reference_id) + 1, 0, reference)

      const actions = [...self.actions]
      actions.splice(index, 0, newAction)

      this.setActions(actions)
      node?.setActions(references)
    },
    insertStepAfter(node, step, index) {
      if (isAction(step)) {
        this.insertStepAfterAction(node, step, index)
      } else {
        this.insertStepAfterNode(node, index)
      }
    },
    selectStep(step: undefined | Instance<typeof Action> | Instance<typeof Condition>) {
      if (isAction(step)) {
        self.selectedAction = step.reference_id
        self.editing = step?.action_type
      } else if (isCondition(step)) {
        self.selectedCondition = step.reference_id
        self.editing = step?.condition_name
      } else {
        self.selectedAction = undefined
        self.selectedCondition = undefined
        self.editing = ''
      }
    },
    setEditing(type: string) {
      self.editing = type
    },
    setName(name: string) {
      self.name = name
    },
    handleJoinActions() {
      self.nodes.map((node, index) => {
        node.setStartNode(false)
        node.setTerminalNode(false)

        // set start node
        if (index === 0) {
          node.setStartNode(true)
        }

        const next = self.nodes[index + 1]
        if (next) {
          // ensure the node includes its own join action
          if (node.joinAction && !node.actions.includes(node.joinAction.reference_id)) {
            node.setActions(cast([...node.actions, node.joinAction.reference_id]))
          }

          // check if node has a join action and that it links to the next node
          if (node.joinAction && node.joinAction.to_id !== next.node_id) {
            node.joinAction.setToID(next.node_id)
          } else if (!node.joinAction) {
            const reference_id = crypto.randomUUID()
            node.addAction(reference_id)
            this.addAction(
              cast({
                user_visible: false,
                action_type: 'join',
                description: 'Unlock the next node in the Path',
                force_evaluate: true,
                label: '',
                conditions: [],
                reference_id,
                from_id: node.node_id,
                to_id: next.node_id,
              }),
            )
          }
        } else {
          // no next node found (set terminal), destroy any join action found
          node.setTerminalNode(true)
          if (node.joinAction) {
            this.removeAction(node.joinAction)
          }
        }
      })
      // clean up actions
      self.actions.map((action) => {
        let included = false
        self.nodes.map((node) => {
          // mark action as included if found in a node's actions list
          if (node.actions.includes(action.reference_id)) {
            included = true
          }
        })
        if (!included) {
          // action found without node referencing it, destroy it
          destroy(action)
        }
      })
      self.actions
        .filter((a) => a.action_type === 'join')
        .map((joinAction) => {
          const node_ids = self.nodes.map((n) => n.node_id)
          if (joinAction.from_id && !node_ids.includes(joinAction.from_id)) {
            // join action found pointing from non-existent node - destroy it
            destroy(joinAction)
          }
        })
    },
    addNode(node: Instance<typeof Node>) {
      self.nodes = cast([...self.nodes, node])
      return node
    },
    setNodes(nodes: Array<Instance<typeof Node>>) {
      self.nodes.replace(nodes)
    },
    addCondition(condition: Instance<typeof Condition>) {
      const c = Condition.create(condition)
      self.conditions = cast([...self.conditions, c])
      return c.reference_id ?? ''
    },
    addAction(action: Instance<typeof Action>) {
      self.actions = cast([...self.actions, Action.create(action)])
    },
    setActions(actions: Array<Instance<typeof Action>>) {
      self.actions.replace(actions)
    },
    removeAction(action) {
      self.nodes.map((node) =>
        node.setActions(cast(node.actions.filter((id) => id !== action?.reference_id))),
      )
      destroy(action)
    },
    removeCondition(condition) {
      self.nodes.map((node) =>
        node.setConditions(cast(node.conditions.filter((id) => id !== condition?.reference_id))),
      )
      destroy(condition)
    },
    removeNode(node) {
      // ensure any referenced actions / condtions are removed at the workflow level
      node.conditions.map((condition) => {
        condition.criteria.map((criteria) => {
          const found = self.conditions.find((c) => c.reference_id === criteria)
          if (found) {
            destroy(found)
          }
        })
      })
      // if fallback node - move all actions (if applicable - certs) to the previous node/condition
      // assuming one node has one top level condition
      if (node.order > 1) {
        const fallback = self.nodes.find((n) => n.order === node.order - 1)
        const joinActions = self.actions.map((a) =>
          a.action_type === 'join' ? a.reference_id : '',
        )
        fallback?.actions.push(
          ...node.actions.filter((nodeAction) => !joinActions.includes(nodeAction)),
        )
      } else {
        node.actions.map((id) => {
          // for each action id find in workflow actions array
          const found = self.actions.find((a) => a.reference_id === id)
          // for each condition for the action found remove from workflow conditions
          found?.conditions?.map((condition) => {
            condition.criteria.map((c) => {
              const found = self.conditions.find((condition) => condition.reference_id === c)
              if (found) {
                destroy(found)
              }
            })
          })
          // remove root join action
          const join = self.actions.find(
            (action) => action.action_type === 'join' && action.from_id === node.reference_id,
          )
          if (join) {
            this.removeAction(join)
          }
          // after removing all of the action's condtions from workflow
          // remove the action itself from the workflow
          if (found) {
            destroy(found)
          }
        })
      }
      // after all conditions / actions are handled destroy the node
      destroy(node)
      // re-adjust node order values
      self.nodes.map((node, index) => node.setOrder(index + 1))
    },
    updateTopicIDs(id: number) {
      // after create topic publish request
      // find all object_type "Topic" nodes/conditions and update their object_ids
      self.nodes.map((node) => {
        if (node.object_type === 'Topic') {
          node.setObjectID(id)
        }
      })
      self.conditions.map((condition) => {
        if (condition.object_type === 'Topic') {
          condition.setObjectID(id)
        }
      })
    },
    handleStub(newNode) {
      // find parent node of stub
      const parentNode = self.nodes.find((n) => n.actions.includes(self.stub.reference_id))
      let i
      if (parentNode) {
        parentNode.actions.map((a, index) => {
          if (a === self.stub.reference_id) {
            i = index
          }
        })

        // update parent nodes actions and
        // move all actions > index of stub action into the new condition
        const actions = [...parentNode.actions].filter((action) => {
          const stub = self.actions.find((action) => action.stub)
          if (stub?.reference_id !== action) {
            return action
          }
        })
        parentNode.setActions(cast(actions.slice(0, i)))
        newNode.setActions(cast(actions.slice(i, actions.length)))

        // reorder the node / condition to come after the parent node of the stub
        const temp = self.nodes?.slice()
        if (temp) {
          temp.splice(
            self.nodes.indexOf(parentNode) + 1,
            0,
            temp.splice(self.nodes.indexOf(newNode), 1)[0],
          )
          this.setNodes(temp)
          // update order value for all nodes
          self.nodes.map((node, index) => {
            node.setOrder(index + 1)
          })
        }
      }
      this.removeAction(self.stub)
    },
    addToGroup(group, user_visible: boolean, description: string) {
      if (self.stub) {
        self.stub.setLabel('Add User to Group')
        self.stub.setDescription(description)
        self.stub.setActionType('add_to_group')
        self.stub.setForceEvaluate(true)
        self.stub.setGroup(group)
        self.stub.setObjectID(group.id)
        self.stub.setUserVisible(user_visible)
        self.stub.setStub(false)
      } else {
        const reference_id = crypto.randomUUID()
        this.addAction(
          Action.create({
            user_visible,
            description,
            action_type: 'add_to_group',
            force_evaluate: true,
            label: 'Add User to Group',
            reference_id,
            object_id: group.id,
            conditions: [],
            group,
          }),
        )
        self.nodes[self.nodes.length - 1].addAction(reference_id)
      }
    },
    addToAssignment(assignment, user_visible: boolean, description: string) {
      if (self.stub) {
        self.stub.setLabel('Add User to Assignment')
        self.stub.setDescription(description)
        self.stub.setActionType('add_to_assignment')
        self.stub.setForceEvaluate(true)
        self.stub.setAssignment(assignment)
        self.stub.setObjectID(assignment.id)
        self.stub.setUserVisible(user_visible)
        self.stub.setStub(false)
      } else {
        const reference_id = crypto.randomUUID()
        this.addAction(
          Action.create({
            user_visible,
            description,
            action_type: 'add_to_assignment',
            force_evaluate: true,
            label: 'Add User to Assignment',
            reference_id,
            object_id: assignment.id,
            conditions: [],
            assignment,
          }),
        )
        self.nodes[self.nodes.length - 1].addAction(reference_id)
      }
    },
    untagUser(tags, user_visible: boolean, description: string) {
      if (self.stub) {
        self.stub.setLabel('Untag User')
        self.stub.setDescription(description)
        self.stub.setActionType('untag_user')
        self.stub.setForceEvaluate(true)
        self.stub.setTags(tags)
        self.stub.setTagIDs(tags.map((tag) => tag.id))
        self.stub.setUserVisible(user_visible)
        self.stub.setStub(false)
      } else {
        const reference_id = crypto.randomUUID()
        this.addAction(
          Action.create({
            action_type: 'untag_user',
            force_evaluate: true,
            user_visible,
            description,
            label: 'Untag User',
            reference_id,
            tag_ids: tags.map((tag) => tag.id),
            conditions: [],
            tags,
          }),
        )
        self.nodes[self.nodes.length - 1].addAction(reference_id)
      }
    },
    awardPoints(points: number) {
      const reference_id = crypto.randomUUID()
      this.addAction(
        Action.create({
          action_type: 'award_points',
          description: '',
          user_visible: false,
          force_evaluate: true,
          label: 'Tag User',
          reference_id,
          points,
          conditions: [],
        }),
      )
      self.nodes[self.nodes.length - 1].addAction(reference_id)
    },

    delay(delay: number, user_visible: boolean, description: string, fromAssignment: boolean) {
      // check if stub avail
      if (self.stub) {
        self.stub.setLabel('Delay')
        self.stub.setDescription(description)
        self.stub.setActionType(fromAssignment ? 'delay_from' : 'delay')
        self.stub.setForceEvaluate(true)
        self.stub.setDelay(delay)
        self.stub.setUserVisible(user_visible)
        self.stub.setStub(false)
      } else {
        const reference_id = crypto.randomUUID()
        this.addAction(
          Action.create({
            action_type: fromAssignment ? 'delay_from' : 'delay',
            description,
            user_visible,
            force_evaluate: true,
            label: 'Delay',
            reference_id,
            minutes: delay,
            conditions: [],
          }),
        )
        self.nodes[self.nodes.length - 1].addAction(reference_id)
      }
    },
    waitUntil(datetime: string, user_visible: boolean, description: string) {
      if (self.stub) {
        self.stub.setLabel('Wait Until')
        self.stub.setDescription(description)
        self.stub.setActionType('wait_until')
        self.stub.setForceEvaluate(true)
        self.stub.setWaitUntil(datetime)
        self.stub.setUserVisible(user_visible)
        self.stub.setStub(false)
      } else {
        const reference_id = crypto.randomUUID()
        this.addAction(
          Action.create({
            action_type: 'wait_until',
            description,
            user_visible,
            force_evaluate: true,
            label: 'Wait Until',
            reference_id,
            wait_until_datetime: datetime,
            conditions: [],
          }),
        )
        self.nodes[self.nodes.length - 1].addAction(reference_id)
      }
    },
    tagUser(tags, user_visible: boolean, description: string) {
      if (self.stub) {
        self.stub.setLabel('Tag User')
        self.stub.setDescription(description)
        self.stub.setActionType('tag_user')
        self.stub.setForceEvaluate(true)
        self.stub.setTags(tags)
        self.stub.setTagIDs(tags.map((tag) => tag.id))
        self.stub.setUserVisible(user_visible)
        self.stub.setStub(false)
      } else {
        const reference_id = crypto.randomUUID()
        this.addAction(
          Action.create({
            action_type: 'tag_user',
            force_evaluate: true,
            user_visible,
            description,
            label: 'Tag User',
            reference_id,
            tag_ids: tags.map((tag) => tag.id),
            conditions: [],
            tags,
          }),
        )
        self.nodes[self.nodes.length - 1].addAction(reference_id)
      }
    },
    sendMessage(
      description: string,
      recipients: number[],
      include_user: boolean,
      subject: string,
      body: string,
      template_name?: string,
    ) {
      const reference_id = crypto.randomUUID()
      this.addAction(
        Action.create({
          action_type: 'send_message',
          description,
          force_evaluate: false,
          label: 'Send Message to User',
          reference_id,
          recipients,
          include_user,
          subject,
          body,
          template_name: template_name ?? null,
          user_visible: false,
          conditions: [],
        }),
      )
      self.nodes[self.nodes.length - 1].addAction(reference_id)
    },
    viewSource(source) {
      const criteria_id = this.addCondition(
        Condition.create({
          verb: 'view',
          label: 'View Source',
          value: null,
          operator: null,
          object_id: source.id || source?.resource_id,
          object_type: 'Resource',
          reference_id: crypto.randomUUID(),
          condition_name: 'view_source',
          source,
        }),
      )
      const newNode = Node.create({
        label: 'View Source',
        actions: [],
        required: true,
        object_id: source.id || source?.resource_id,
        order: self.nodes.length ? self.nodes.length + 1 : 1,
        conditions: [{ name: 'all', criteria: [criteria_id] }],
        object_type: 'Resource',
        node_id: crypto.randomUUID(),
      })
      this.addNode(newNode)

      if (self.stub) {
        this.handleStub(newNode)
      }
    },
    scoreOverQuiz(quiz, threshold) {
      const criteria_id = this.addCondition(
        Condition.create({
          verb: 'score',
          label: `Score ${threshold}%+ on Quiz`,
          value: threshold,
          operator: threshold < 100 ? 'gte' : 'eq',
          object_type: 'Quiz',
          condition_name: `score_${threshold}_on_all_quizzes`,
          reference_id: crypto.randomUUID(),
          object_id: quiz.id,
          quiz,
        }),
      )
      const newNode = Node.create({
        label: `Score % + on Quiz`,
        node_id: crypto.randomUUID(),
        object_id: quiz.id,
        required: true,
        order: self.nodes.length ? self.nodes.length + 1 : 1,
        object_type: 'Quiz',
        conditions: [{ name: 'all', criteria: [criteria_id] }],
        actions: [],
      })
      this.addNode(newNode)

      if (self.stub) {
        this.handleStub(newNode)
      }
    },
    scoreOverTopic(topic, threshold) {
      const criteria_id = this.addCondition(
        Condition.create({
          verb: 'score',
          label: `Score ${threshold}%+ on Topic`,
          value: threshold,
          operator: threshold < 100 ? 'gte' : 'eq',
          object_type: 'Topic',
          condition_name: `score_${threshold}_on_all_quizzes`,
          reference_id: crypto.randomUUID(),
          object_id: topic.id,
          topic,
        }),
      )
      const newNode = Node.create({
        label: `Score X%+ on Topic`,
        node_id: crypto.randomUUID(),
        object_id: topic.id,
        required: true,
        order: self.nodes.length ? self.nodes.length + 1 : 1,
        object_type: 'Topic',
        conditions: [{ name: 'all', criteria: [criteria_id] }],
        actions: [],
      })
      this.addNode(newNode)

      if (self.stub) {
        this.handleStub(newNode)
      }
    },
    completeTopic(topic) {
      const criteria_id = crypto.randomUUID()
      this.addCondition(
        Condition.create({
          verb: 'complete',
          label: 'Complete Topic',
          value: null,
          operator: null,
          object_type: 'Topic',
          condition_name: 'complete_topic',
          reference_id: criteria_id,
          object_id: topic.id,
          topic,
        }),
      )
      const newNode = Node.create({
        label: 'Complete Topic',
        node_id: crypto.randomUUID(),
        object_id: topic.id,
        required: true,
        order: self.nodes.length ? self.nodes.length + 1 : 1,
        object_type: 'Topic',
        conditions: [{ name: 'all', criteria: [criteria_id] }],
        actions: [],
      })
      this.addNode(newNode)

      if (self.stub) {
        this.handleStub(newNode)
      }
    },
    completeQuiz(quiz: any) {
      const criteria_id = this.addCondition(
        Condition.create({
          verb: 'complete',
          label: 'Complete Quiz',
          value: null,
          operator: null,
          object_type: 'Quiz',
          condition_name: 'complete_quiz',
          reference_id: crypto.randomUUID(),
          object_id: quiz.id,
          quiz,
        }),
      )
      const newNode = Node.create({
        label: 'Complete Quiz',
        node_id: crypto.randomUUID(),
        object_id: quiz.id,
        required: true,
        order: self.nodes.length ? self.nodes.length + 1 : 1,
        object_type: 'Quiz',
        conditions: [{ name: 'all', criteria: [criteria_id] }],
        actions: [],
      })
      this.addNode(newNode)

      if (self.stub) {
        this.handleStub(newNode)
      }
    },
    awardBadge(badge: any, user_visible: boolean, description: string, topic_id?: number) {
      // Ensure at least one complete topic node exists

      if (self.nodes.length === 0) {
        const criteria_id = this.addCondition(
          Condition.create({
            verb: 'complete',
            label: 'Complete Topic',
            value: null,
            operator: null,
            object_type: 'Topic',
            condition_name: 'complete_topic',
            reference_id: crypto.randomUUID(),
            object_id: topic_id,
          }),
        )
        this.addNode(
          Node.create({
            label: 'Complete Topic',
            node_id: crypto.randomUUID(),
            object_id: topic_id,
            required: true,
            order: self.nodes.length ? self.nodes.length + 1 : 1,
            object_type: 'Topic',
            conditions: [{ name: 'all', criteria: [criteria_id] }],
            actions: [],
          }),
        )
      }

      if (self.stub) {
        self.stub.setLabel('Award Badge')
        self.stub.setDescription(description)
        self.stub.setActionType('award_badge')
        self.stub.setForceEvaluate(false)
        self.stub.setObjectID(badge.id)
        self.stub.setBadge(badge)
        self.stub.setUserVisible(user_visible)
        self.stub.setStub(false)
      } else {
        // Add an action referencing the complete topic condition reference ids as criteria
        const action_id = crypto.randomUUID()
        this.addAction(
          Action.create({
            action_type: 'award_badge',
            description,
            force_evaluate: false,
            label: 'Award Badge',
            reference_id: action_id,
            object_id: badge.id,
            user_visible,
            conditions: [],
            badge,
          }),
        )
        self.nodes[self.nodes.length - 1].addAction(action_id)
      }
    },
    updateCertification(
      step: any,
      title: string,
      user_visible: boolean,
      description: string,
      certification?: any,
      topic_id?: number,
    ) {
      step.setDescription(description)
      step.setLabel(title)
      step.setUserVisible(user_visible)

      if (certification) {
        step.setObjectID(certification.certification_id)
        step.conditions.map((condition) => {
          condition.criteria.map((criteria, index) => {
            const found = self.conditions.find((c) => c.reference_id === criteria)
            if (found) {
              found.updateLevelId(
                certification.levels.find((level) => level.order === index + 1).level_id,
              )
            }
          })
        })
      }
    },
    awardCertification(
      title: string,
      certification: any,
      user_visible: boolean,
      description: string,
      topic_id?: number,
    ) {
      // ensure at least one node exists to attach the certification
      let node: any = self.nodes[self.nodes.length - 1]
      if (self.nodes.length === 0) {
        // Automatically add base topic completion condition to trigger cert action
        const criteria_id = crypto.randomUUID()
        this.addCondition(
          Condition.create({
            verb: 'complete',
            label: 'Complete Topic',
            value: null,
            operator: null,
            object_type: 'Topic',
            object_id: topic_id, // defaults to undefined for new topics
            condition_name: 'complete_topic',
            reference_id: criteria_id,
            // certification_level_id: certification.certification_id,
          }),
        )
        // Add node referencing complete topic condition + award cert action and tick order
        node = Node.create({
          label: 'Complete Topic',
          node_id: crypto.randomUUID(),
          required: true,
          order: self.nodes.length ? self.nodes.length + 1 : 1,
          object_type: 'Topic',
          object_id: topic_id,
          conditions: [{ name: 'all', criteria: [criteria_id] }],
          actions: [],
        })
        this.addNode(node)
      }

      // Create the three conditions for default award certification action
      // for now gold/silver/bronze
      const gold = crypto.randomUUID()
      const silver = crypto.randomUUID()
      const bronze = crypto.randomUUID()

      this.addCondition(
        Condition.create({
          verb: 'score',
          label: 'Score 100% on All Quizzes',
          value: 100,
          operator: 'eq',
          object_type: 'Topic',
          object_id: topic_id,
          condition_name: 'score_100_on_all_quizzes',
          certification_level_id: certification.levels.find((level) => level.order === 1).level_id,
          reference_id: gold,
        }),
      )

      this.addCondition(
        Condition.create({
          verb: 'score',
          label: 'Score 90% + on All Quizzes',
          value: 90,
          operator: 'gte',
          object_type: 'Topic',
          object_id: topic_id,
          reference_id: silver,
          condition_name: 'score_90_on_all_quizzes',
          certification_level_id: certification.levels.find((level) => level.order === 2).level_id,
        }),
      )

      this.addCondition(
        Condition.create({
          verb: 'score',
          label: 'Score 80% + on All Quizzes',
          value: 80,
          operator: 'gte',
          object_type: 'Topic',
          object_id: topic_id,
          condition_name: 'score_80_on_all_quizzes',
          certification_level_id: certification.levels.find((level) => level.order === 3).level_id,
          reference_id: bronze,
        }),
      )

      // Add an action referencing the previous condition reference ids as criteria
      const action_id = crypto.randomUUID()
      this.addAction(
        Action.create({
          action_type: 'award_certification',
          force_evaluate: true,
          label: title,
          reference_id: action_id,
          user_visible,
          description,
          object_id: certification.certification_id,
          conditions: [
            {
              name: 'any',
              // Must descend gold -> bronze - implicit ordering for backend
              criteria: [gold, silver, bronze],
            },
          ],
        }),
      )
      node.addAction(action_id)
    },
  }))
