import { types } from 'mobx-state-tree'
import { WebhooksBaseModel } from '../webhooks'

export const SettingsBaseModel = types.model('SettingsModel').props({
  __typename: types.optional(types.literal('Settings'), 'Settings'),
  company_name: types.string,
  push_enabled: types.boolean,
  email_enabled: types.boolean,
  feed_enabled: types.boolean,
  assignment_completed_enabled: types.boolean,
  adaptive_push_enabled: types.boolean,
  adaptive_email_enabled: types.boolean,
  challenges_enabled: types.boolean,
  cooldown_required: types.boolean,
  hourly_warning: types.string,
  hourly_override: types.string,
  music_default: types.boolean,
  points_learn_standard: types.number,
  points_reinforce_standard: types.number,
  points_test_standard: types.number,
  points_post: types.number,
  points_reply: types.number,
  points_badge: types.number,
  points_challenge: types.number,
  sounds_default: types.boolean,
  allow_change_email: types.boolean,
  allow_change_name: types.boolean,
  webhooks: types.maybeNull(WebhooksBaseModel),
})
