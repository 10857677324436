import { SnapshotIn, types, cast } from 'mobx-state-tree'
import { DateTime } from 'luxon'

const SelectedUserGroup = types.model('SelectedUserGroup').props({
  type: types.string,
  label: types.string,
  id: types.number,
  number_users: types.maybe(types.number),
})
export const CreateMessageForm = types
  .model('CreateMessageForm')
  .props({
    start_date: types.string,
    start_time: types.string,
    subject: types.string,
    message: types.string,
    users: types.frozen(),
    groups: types.frozen(),
    push: types.boolean,
    email: types.boolean,
    schedule: types.boolean,
    message_type: types.enumeration(['generic', 'nudge', 'contest', 'survey_nudge']),
  })
  .views((self) => {
    return {
      get datetimeUTC() {
        return DateTime.fromJSDate(new Date(`${self.start_date} ${self.start_time}`))
          .toUTC()
          .toISO()
      },
      get displayTime() {
        return DateTime.fromJSDate(
          new Date(`${self.start_date} ${self.start_time}`),
        ).toLocaleString(DateTime.TIME_SIMPLE)
      },
      get displayDate() {
        return DateTime.fromJSDate(new Date(`${self.start_date} ${self.start_time}`)).toFormat(
          'MMM d, yyyy',
        )
      },
      get errors() {
        const errors: any = {}
        if (!self.subject) {
          errors.subject = {
            type: 'required',
            message: 'Subject is required',
          }
        }

        if (
          self.push &&
          self.subject?.length > 50 &&
          self.message_type !== 'survey_nudge' &&
          self.message_type !== 'nudge'
        ) {
          errors.subject = {
            type: 'required',
            message: 'Maximum of 50 characters',
          }
        }

        if (!self.message) {
          errors.message = {
            type: 'required',
            message: 'Message body is required',
          }
        }

        if (
          self.push &&
          self.message?.length > 150 &&
          self.message_type !== 'survey_nudge' &&
          self.message_type !== 'nudge'
        ) {
          errors.message = {
            type: 'required',
            message: 'Maximum of 150 characters',
          }
        }

        if (!self.start_date) {
          errors.start_date = {
            type: 'required',
            message: 'Start Date is required',
          }
        }

        if (!self.start_time) {
          errors.start_time = {
            type: 'required',
            message: 'Start Time is required',
          }
        }

        if (
          !self.push &&
          !self.email &&
          self.message_type !== 'survey_nudge' &&
          self.message_type !== 'nudge'
        ) {
          errors.push = {
            type: 'required',
            message: 'Notification type is required',
          }
        }

        if (
          self.users.length === 0 &&
          self.groups.length === 0 &&
          self.message_type === 'generic'
        ) {
          errors.push = {
            type: 'required',
            message: 'At least one user or group is required',
          }
        }

        // no need to validate beyond this since we're missing required fields
        if (Object.keys(errors).length) {
          return errors
        }

        const startDateTime = DateTime.fromISO(`${self.start_date}T${self.start_time}:00`)

        if (!startDateTime.isValid) {
          return {
            start_date: {
              type: 'invalid',
              message: 'Date is Invalid',
            },
          }
        }

        return errors
      },
    }
  })
  .actions((self) => {
    return {
      set<K extends keyof SnapshotIn<typeof self>, T extends SnapshotIn<typeof self>>(
        key: K,
        value: T[K],
      ) {
        // @ts-ignore
        self[key] = cast(value)
      },
    }
  })
