import React, { useEffect, useState } from 'react'
import { observer } from 'mobx-react-lite'
import { DateTime } from 'luxon'
import { useRouteMatch } from 'react-router-dom'

import { useStores } from '@trivie/core'
import { TableHelper } from '../../../../tables'
import { ResourceTable } from '../../../../tables/resource-table'

const Resources = observer(() => {
  const match = useRouteMatch<any>('/activities/assignments/:tab/:id/sources')
  const assignmentID = Number(match?.params?.id)
  const tab = String(match?.params?.id)

  const { assignmentStore } = useStores()
  const { assignmentOverviewStats, getAssignmentDetails } = assignmentStore

  useEffect(() => {
    if (!assignmentOverviewStats) {
      const endTime = DateTime.now()
      const startTime = endTime.plus({ days: -7 }).toISO().toString()
      getAssignmentDetails(assignmentID, startTime, endTime.plus({ days: -7 }).toISO())
    }
  }, [])

  const [tableHelper] = useState(
    new TableHelper('list_assignment_resources', 'resources', 'assignments', assignmentID, tab),
  )

  return <ResourceTable tableHelper={tableHelper} />
})

export const AssignmentsResourcesTab = (props: any) => {
  const { id, mainTab } = props
  return {
    name: 'Sources',
    path: `/activities/assignments/${mainTab}/${id}/sources`,
    component: () => <Resources />,
  }
}
