import React, { useState } from 'react'
import { observer } from 'mobx-react-lite'
import { Route, Switch } from 'react-router-dom'
import { Add } from '@mui/icons-material'

import { Header } from '@trivie/ui-web/components/header'
import { CreateGroup } from '@trivie/ui-web/components/create-group'
import { useStores } from '@trivie/core'

import { TableHelper } from '../../../tables'
import { GroupsDetailsScreen } from './groups-details-screen'
import { GroupTable } from '../../../tables/group-table'

export const GroupsScreen: React.FunctionComponent = observer(() => {
  const { groupStore, manageUserStore } = useStores()
  const { setGroups } = groupStore
  const { permissions } = manageUserStore
  const [showCreateGroup, setShowCreateGroup] = useState(false)
  const [forceLoading, setForceLoading] = useState(false)
  const [tableHelper] = useState(new TableHelper('list_groups', 'groups', 'groups'))

  const reload = () => {
    setForceLoading(true)
    tableHelper.load().then(() => setForceLoading(false))
  }

  const closeCreateGroup = () => {
    setGroups([])
    tableHelper.load()
    setShowCreateGroup(false)
  }

  const primary = {
    disabled: !permissions?.permissions.some((p) => p.code === 'can_create_group'),
    startIcon: <Add />,
    onClick: () => setShowCreateGroup(true),
    text: 'Create Group',
  }

  return (
    <Switch>
      <Route exact path="/people/groups">
        <Header divider title="Groups" primaryAction={primary} />
        <GroupTable forceLoading={forceLoading} tableHelper={tableHelper} />
        {showCreateGroup && (
          <CreateGroup reload={reload} open={showCreateGroup} onClose={closeCreateGroup} />
        )}
      </Route>
      <Route path="/people/groups/:id">
        <GroupsDetailsScreen />
      </Route>
    </Switch>
  )
})
