import React, { useState } from 'react'
import { AutoFixHigh, Delete, DragIndicator } from '@mui/icons-material'
import {
  Checkbox,
  Collapse,
  IconButton,
  InputAdornment,
  LinearProgress,
  Radio,
  TextField,
} from '@mui/material'
import { getNodeId } from 'mobx-state-tree'
import { color } from '../../theme/color'
import { Text } from '../text'
import { observer } from 'mobx-react-lite'

interface MultipleResponseChoiceProps {
  question: any
  choice: any
  grabbing: boolean
  handleDragOver: any
  handleDrag: any
  setGrabbing: any
  handleDrop: any
  dropChoiceID: any
  setHovered: any
  setHoveredID: any
  setDropChoiceID: any
  hoveredID: any
  mercuryEnabled: any
  hovered: any
  index: any
  rewrite: any
}

export const ChoiceField = observer((props: MultipleResponseChoiceProps) => {
  const {
    choice,
    question,
    handleDragOver,
    handleDrag,
    setGrabbing,
    handleDrop,
    grabbing,
    setHovered,
    setHoveredID,
    setDropChoiceID,
    hoveredID,
    dropChoiceID,
    mercuryEnabled,
    hovered,
    rewrite,
    index,
  } = props

  const [modified, setModified] = useState(false)

  return (
    <>
      <div
        draggable={Boolean(question?.keep_answer_order) && grabbing}
        onDragOver={() => handleDragOver(choice)}
        onDrag={() => handleDrag(choice)}
        onDragEnd={(e) => setGrabbing(false)}
        onDrop={() => handleDrop(choice)}
        style={{
          flexDirection: 'row',
          display: 'flex',
          width: '100%',
          backgroundColor:
            dropChoiceID === getNodeId(choice) && grabbing ? color.shade10 : color.white,
        }}
      >
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            flexDirection: 'column',
            width: '100%',
            position: 'relative',
          }}
        >
          <>
            <div style={{ position: 'relative' }}>
              <TextField
                variant="standard"
                value={choice.text ?? ''}
                fullWidth
                multiline
                sx={{
                  '& .MuiInputBase-root': {
                    textDecoration: choice.loading ? 'line-through' : 'none',
                    fontSize: 14,
                    fontFamily: 'Inter',
                    fontWeight: 500,
                    paddingRight: '80px',
                    lineHeight: '16.94px',
                    paddingTop: '8px',
                    paddingBottom: '8px',
                    pointerEvents: choice.loading || choice.generating ? 'none' : 'auto',
                  },
                  '& .MuiInput-underline:after': {
                    borderBottomColor: choice?.generating
                      ? color.lightPurple
                      : choice.touched && choice.formErrors.text
                        ? color.red
                        : choice.formWarnings.text
                          ? color.yellow
                          : color.blue,
                  },
                  '& .MuiInput-underline:before': {
                    borderBottomColor: choice?.generating
                      ? color.lightPurple
                      : choice.touched && choice.formErrors.text && !question?.generatingAll
                        ? color.red
                        : choice.formWarnings.text && !question?.generatingAll
                          ? color.yellow
                          : color.shade10,
                  },
                }}
                style={{ fontSize: 16, fontWeight: 600 }}
                onMouseEnter={() => setHovered(choice)}
                onMouseLeave={() => setHovered(null)}
                InputProps={{
                  id: `typewriter-${getNodeId(choice)}`,
                  spellCheck: choice?.generating ? false : true,
                  startAdornment: (
                    <>
                      {!question?.shuffle && (
                        <DragIndicator
                          onMouseLeave={() => setHoveredID(null)}
                          onMouseEnter={() => setHoveredID(getNodeId(choice))}
                          onMouseDown={() => setGrabbing(true)}
                          onMouseUp={() => {
                            setGrabbing(false)
                            setDropChoiceID(null)
                          }}
                          style={{
                            ...HANDLE,
                            color: hoveredID === getNodeId(choice) ? color.shade70 : color.shade10,
                            cursor: grabbing ? 'grabbing' : 'grab',
                          }}
                        />
                      )}
                      {question?.question_type === 'Multiple Response' ? (
                        <Checkbox
                          checked={choice.correct}
                          onChange={(e) => {
                            question?.setCorrectChoice(choice, e.target.checked)
                          }}
                          inputProps={{ 'aria-label': 'choice' }}
                          style={{
                            placeSelf: 'center',
                            marginRight: 25,
                            marginLeft: question?.shuffle ? 20 : 0,
                            color: choice.correct ? color.shade70 : color.shade30,
                          }}
                        />
                      ) : (
                        <Radio
                          checked={choice.correct}
                          onChange={() => {
                            question?.setCorrectChoice(choice)
                          }}
                          color="default"
                          inputProps={{ 'aria-label': 'choice' }}
                          style={{
                            marginRight: 25,
                            marginLeft: question?.shuffle ? 20 : 0,
                            placeSelf: 'center',
                            color:
                              !question?.generatingAll &&
                              question?.question_type === 'Multiple Choice' &&
                              question?.choices &&
                              question?.choices.filter((c) => c.correct).length === 0
                                ? color.red
                                : choice.correct
                                  ? color.palette.black
                                  : color.shade30,
                          }}
                        />
                      )}
                    </>
                  ),
                  endAdornment: (
                    <InputAdornment position="end" style={{ position: 'absolute', right: 0 }}>
                      <div
                        style={{
                          flexDirection: 'row',
                          cursor: choice.loading ? 'default' : 'pointer',
                        }}
                      >
                        {!choice.loading && question?.choices.length > 2 && hovered === choice && (
                          <IconButton
                            sx={{
                              '&:hover': {
                                svg: {
                                  color: color.shade50,
                                  transition: 'color .5s',
                                },
                              },
                            }}
                            style={{ marginRight: 2 }}
                            onClick={() => {
                              question?.removeChoice(choice)
                            }}
                          >
                            <Delete style={{ fontSize: 18 }} htmlColor={color.shade30} />
                          </IconButton>
                        )}
                        {mercuryEnabled &&
                          hovered === choice &&
                          question?.generated_question_id &&
                          question?.question_type !== 'True or False' &&
                          !choice.loading &&
                          (!choice.correct || !choice.text) &&
                          question?.factoid?.text.length > 0 &&
                          question?.text?.length > 0 && (
                            <IconButton
                              disabled={choice.loading}
                              sx={{
                                '&:hover': {
                                  svg: {
                                    color: color.lightPurple,
                                    transition: 'color .5s',
                                  },
                                },
                              }}
                              onClick={() => {
                                rewrite(choice)
                              }}
                            >
                              <AutoFixHigh style={{ fontSize: 18 }} htmlColor={color.shade30} />
                            </IconButton>
                          )}
                      </div>
                    </InputAdornment>
                  ),
                }}
                placeholder={`Choice ${index + 1}`}
                onKeyDown={(e) => {
                  if (
                    question?.choices.length > 2 &&
                    (choice.text === '' || choice.text === null) &&
                    e.key === 'Backspace'
                  ) {
                    question?.removeChoice(choice)
                  }
                }}
                onChange={(e) => {
                  if (e.target.value !== choice.text) {
                    setModified(true)
                  }
                  choice.setText(String(e.target.value))
                }}
                onBlur={(e) => {
                  if (modified) {
                    choice.setTouched(true)
                  }
                }}
              />
              {choice.text && choice.text.length >= 80 && (
                <Text
                  variant="body2"
                  text={`${choice.text.length ?? 0}/100`}
                  style={{
                    fontWeight: 700,
                    position: 'absolute',
                    bottom: 4,
                    right: 4,
                    color: choice.text?.length > 100 ? color.palette.yellow : color.shade30,
                  }}
                />
              )}
            </div>
            {choice.loading && (
              <LinearProgress
                sx={{
                  top: '-1px',
                  '&.MuiLinearProgress-root': {
                    backgroundColor: `${color.purple}20`,
                    height: '2px',
                  },
                  '&.MuiLinearProgress-root > .MuiLinearProgress-bar': {
                    background: `linear-gradient(40deg, #FF00FF -17.26%, #9116F9 49.66%, #3199FF 99.55%) !important`,
                  },
                }}
              />
            )}

            <Collapse in={question?.advanced} timeout="auto" unmountOnExit>
              <div style={{ position: 'relative' }}>
                <TextField
                  multiline
                  onChange={(e: any) => {
                    if (e.target.value !== choice.factoid?.text) {
                      setModified(true)
                    }
                    choice?.set('factoid', {
                      factoid_id: choice?.factoid?.factoid_id ?? null,
                      text: String(e.target.value),
                      resource_id: null,
                      modified: true,
                    })
                  }}
                  onBlur={(e) => {
                    if (modified) {
                      choice.setTouched(true)
                    }
                  }}
                  value={choice?.factoid?.text ?? ''}
                  fullWidth
                  sx={{
                    transition: 'all 0.2s 0.1s ease',
                    '& .MuiInputBase-root': {
                      paddingTop: '12px',
                      paddingBottom: '12px',
                      paddingLeft: '87px',
                      paddingRight: '80px',
                      color: 'rgba(0,0,0.7)',
                      fontSize: 12,
                    },
                    '& .Mui-focused': {
                      backgroundColor: `${choice?.factoid?.text.length >= 100 ? color.yellow : color.blue}10 !important`,
                    },
                    '& .MuiFilledInput-underline:after': {
                      borderBottomColor: choice?.formWarnings.feedback ? color.yellow : color.blue,
                    },
                    '& .MuiFilledInput-underline:before': {
                      borderBottomColor: choice?.formWarnings.feedback
                        ? color.yellow
                        : 'rgba(0,0,0,0.02)',
                    },
                  }}
                  variant="filled"
                  placeholder={
                    question?.question_type === 'Multiple Response'
                      ? 'Enter feedback for this answer.'
                      : 'Enter text to show the user if they choose this answer.'
                  }
                />
                {choice?.factoid?.text?.length >= 80 && (
                  <Text
                    variant="body2"
                    text={`${choice?.factoid?.text?.length ?? 0}/100`}
                    style={{
                      fontWeight: 700,
                      position: 'absolute',
                      bottom: 4,
                      right: 4,
                      color:
                        choice?.factoid?.text?.length > 100 ? color.palette.yellow : color.shade30,
                    }}
                  />
                )}
              </div>
            </Collapse>
          </>
        </div>
      </div>
    </>
  )
})

const HANDLE: React.CSSProperties = {
  color: color.shade10,
  alignSelf: 'center',
  fontSize: 20,
}
