import React, { useEffect, useState } from 'react'
import { Close, Delete, LocalOfferRounded } from '@mui/icons-material'
import { observer } from 'mobx-react-lite'
import { CircularProgress } from '@mui/material'

import { useStores } from '@trivie/core'
import { ConfirmDialog } from '@trivie/ui-web/components/confirm-dialog'
import { DataTable, TableHelper } from '@trivie/ui-web/components/data-table/data-table'
import { Toolbar } from '@trivie/ui-web/components/table-toolbar'
import { QuizApi } from '@trivie/core/src/services/api-objects/QuizApi'
import { AddQuizQuestionsModal } from '@trivie/ui-web/components/add-quiz-questions-modal'

interface QuestionTableProps {
  tableHelper: TableHelper
  openFilter?: boolean
  exportFilename?: string
}

export const QuestionTable = observer((props: QuestionTableProps) => {
  const { exportFilename, tableHelper, openFilter } = props

  const { manageUserStore, manageQuizStore, questionStore } = useStores()
  const { removeQuestionsFromQuiz } = manageQuizStore
  const { deleteQuestions } = questionStore
  const { gdpr, permissions } = manageUserStore

  const [selectedRows, setSelectedRows] = useState<any>([])
  const [loading, setLoading] = useState<boolean>(tableHelper.custom ? false : true)

  // Dialogs
  const [showConfirmDelete, setShowConfirmDelete] = useState(false)
  const [showQuizAdd, setShowQuizAdd] = useState(false)
  const [quizzes, setQuizzes] = useState([])

  const [showConfirmRemoveFromQuiz, setShowConfirmRemoveFromQuiz] = useState(false)

  const getActions = () => {
    switch (tableHelper.parent) {
      case 'assignments':
        return []
      case 'groups':
        return []
      case 'tags':
        return []
      case 'quizzes':
        return [
          {
            label: 'Remove From Quiz',
            icon: Close,
            onClick: () => setShowConfirmRemoveFromQuiz(true),
            disabled: !permissions?.permissions.some((p) => p.code === 'can_delete_question'),
          },
        ]
      case 'topics':
        return []
      default:
        return [
          {
            label: 'Add To Quiz',
            icon: LocalOfferRounded,
            onClick: () => {
              QuizApi.getAllQuizzes('quizzes', false, [], 'list_quizzes').then((resp) => {
                if (resp.ok) {
                  const q = resp.data.rows.map((quiz) => {
                    const title = quiz.columns.find((column) => column.field === 'quiz_title')
                    return { id: quiz.id, quiz_title: title.value }
                  })
                  setQuizzes(q)
                }
              })
              setShowQuizAdd(true)
            },
            disabled: !permissions?.permissions.some((p) => p.code === 'can_edit_quiz'),
          },
          {
            label: 'Delete',
            icon: Delete,
            onClick: () => setShowConfirmDelete(true),
            disabled: !permissions?.permissions.some((p) => p.code === 'can_delete_question'),
          },
        ]
    }
  }

  const [tableActions, setTableActions] = useState<any>(getActions())

  useEffect(() => {
    if (permissions) {
      setTableActions(getActions())
    }
  }, [permissions?.permissions])

  const [init] = useState(false)
  useEffect(() => {
    if (!init) {
      load()
    }
  }, [])

  const load = () => {
    if (!tableHelper.custom) {
      setLoading(true)
      tableHelper.load().then(() => setLoading(false))
    }
  }

  const save = async (columns: any, filter: any) => {
    setLoading(true)
    tableHelper.save(columns, filter).then(() => setLoading(false))
  }

  return loading ? (
    <div style={LOADER}>
      <CircularProgress />
    </div>
  ) : (
    <>
      <DataTable
        gdpr={gdpr}
        allowNewReport
        openFilter={openFilter}
        columnVisibilityModel={tableHelper.columnVisibilityModel}
        hideAddColumn
        tableHelper={tableHelper}
        filterModel={tableHelper.filterModel}
        sortModel={tableHelper.sortModel}
        searchPlaceholder="Search Questions"
        rows={tableHelper.rows}
        columns={tableHelper.columns}
        onSaveLayout={tableHelper?.saveLayout}
        onSaveFilters={save}
        toolbar={<Toolbar selected={selectedRows} actions={tableActions} />}
        exportFilename={
          exportFilename
            ? exportFilename
            : tableHelper.parent !== 'quizzes'
              ? `${tableHelper.parent}_quizzes`
              : 'quizzes'
        }
        rowSelectionModel={selectedRows}
        onRowSelectionModelChange={(param) => setSelectedRows(param)}
      />
      {showConfirmDelete && (
        <ConfirmDialog
          danger
          open={showConfirmDelete}
          title="Remove Quizzes"
          message={`Are you sure you want to delete ${selectedRows.length} question${
            selectedRows.length > 1 ? 's' : ''
          }?`}
          confirmedMessage={`${selectedRows.length} question${
            selectedRows.length > 1 ? 's have' : ' has'
          } been removed.`}
          confirmButton="Confirm"
          onConfirm={() => deleteQuestions(selectedRows)}
          onClose={() => {
            setSelectedRows([])
            load()
            setShowConfirmDelete(false)
          }}
          handleCancel={() => setShowConfirmDelete(false)}
        />
      )}
      {showConfirmRemoveFromQuiz && (
        <ConfirmDialog
          danger
          open={showConfirmRemoveFromQuiz}
          title="Remove Questions"
          message={`Are you sure you want to remove ${selectedRows.length} question${
            selectedRows.length > 1 ? 's' : ''
          }?`}
          confirmedMessage={`${selectedRows.length} question${
            selectedRows.length > 1 ? 's have' : ' has'
          } been removed.`}
          confirmButton="Confirm"
          onConfirm={() => removeQuestionsFromQuiz(tableHelper.id!, selectedRows)}
          onClose={() => {
            setSelectedRows([])
            load()
            setShowConfirmRemoveFromQuiz(false)
          }}
          handleCancel={() => setShowConfirmRemoveFromQuiz(false)}
        />
      )}
      {showQuizAdd && (
        <AddQuizQuestionsModal
          open={showQuizAdd}
          quizzes={quizzes}
          questionIds={selectedRows}
          onClose={() => {
            setSelectedRows([])
            load()
            setShowQuizAdd(false)
          }}
        />
      )}
    </>
  )
})

const LOADER: React.CSSProperties = {
  display: 'flex',
  flex: 1,
  justifyContent: 'center',
  flexDirection: 'column',
  alignItems: 'center',
  height: window.innerHeight / 1.5,
}
