import React, { useEffect, useState } from 'react'
import {
  CheckRounded,
  CloseRounded,
  Delete,
  EditRounded,
  PersonAddRounded,
} from '@mui/icons-material'
import { observer } from 'mobx-react-lite'
import { CircularProgress } from '@mui/material'

import { Toolbar } from '@trivie/ui-web/components/table-toolbar'
import { useStores } from '@trivie/core'
import { ConfirmDialog } from '@trivie/ui-web/components/confirm-dialog'
import { DataTable, TableHelper } from '@trivie/ui-web/components/data-table/data-table'

import { AwardBadgeDialog } from '../screens/activities/badges/award-badge-dialog'
import { CreateEditBadge } from '../screens/activities/badges/create-edit-badge'

interface BadgeTableProps {
  tableHelper: TableHelper
  forceLoading?: boolean
}

export const BadgeTable = observer((props: BadgeTableProps) => {
  const { tableHelper, forceLoading } = props

  const { manageUserStore, badgesStore } = useStores()
  const { gdpr, permissions } = manageUserStore
  const { editBadge, deleteBadge, toggleBadgeDisable } = badgesStore

  const [selectedRows, setSelectedRows] = useState<any>([])
  const [loading, setLoading] = useState<boolean>(true)
  const [selectedBadge, setSelectedBadge] = useState<any>(null)

  // Dialogs
  const [showConfirmDelete, setShowConfirmDelete] = useState(false)
  const [showAward, setShowAward] = useState(false)
  const [showEdit, setShowEdit] = useState(false)
  const [showEnableDisable, setShowEnableDisable] = useState(false)

  // Badge enable / disable helpers
  const getEnabledDisabledIcon = () => {
    const selected = tableHelper.rows.find((row: any) => selectedRows.includes(row.id)) as any
    if (selectedRows.length === 0 || (selected && selected.badge_status === 'Disabled')) {
      return CheckRounded
    }
    return CloseRounded
  }

  const getEnabledDisabled = () => {
    const selected = tableHelper.rows.find((row: any) => selectedRows.includes(row.id)) as any
    if (selectedRows.length === 0 || (selected && selected.badge_status === 'Disabled')) {
      return 'Enable'
    }
    return 'Disable'
  }

  // Table actions
  const getActions = () => {
    switch (tableHelper.filter) {
      case 'custom':
        return [
          {
            label: 'Award',
            icon: PersonAddRounded,
            onClick: () => setShowAward(true),
            disabled:
              selectedRows.length !== 1 ||
              !permissions?.permissions.some((p) => p.code === 'can_create_badge'),
          },
          {
            label: 'Edit',
            icon: EditRounded,
            onClick: () => setShowEdit(true),
            disabled:
              selectedRows.length !== 1 ||
              !permissions?.permissions.some((p) => p.code === 'can_edit_badge'),
          },
          {
            label: getEnabledDisabled(),
            icon: getEnabledDisabledIcon(),
            onClick: () => setShowEnableDisable(true),
            disabled:
              selectedRows.length !== 1 ||
              !permissions?.permissions.some((p) => p.code === 'can_create_badge'),
          },
          {
            label: 'Delete',
            icon: Delete,
            onClick: () => setShowConfirmDelete(true),
            disabled: !permissions?.permissions.some((p) => p.code === 'can_edit_badge'),
          },
        ]
    }
    return [
      {
        label: getEnabledDisabled(),
        icon: getEnabledDisabledIcon(),
        onClick: () => setShowEnableDisable(true),
        disabled:
          selectedRows.length !== 1 ||
          !permissions?.permissions.some((p) => p.code === 'can_edit_badge'),
      },
    ]
  }

  const [tableActions, setTableActions] = useState<any>(getActions())

  useEffect(() => {
    load()
  }, [])
  useEffect(() => {
    if (forceLoading) load()
  }, [forceLoading])

  useEffect(() => {
    if (permissions) {
      setTableActions(getActions())
    }
  }, [permissions?.permissions, selectedRows])

  const load = () => {
    setLoading(true)
    tableHelper.load().then(() => setLoading(false))
  }

  const save = async (columns: any, filter: any) => {
    setLoading(true)
    tableHelper.save(columns, filter).then(() => setLoading(false))
  }

  const handleSaveBadge = (badge: any) => {
    if (selectedBadge?.id) {
      editBadge(selectedBadge.id, badge).then(() => {
        setSelectedRows([])
        load()
        setShowEdit(false)
      })
    }
  }

  return loading ? (
    <div style={LOADER}>
      <CircularProgress />
    </div>
  ) : (
    <>
      <DataTable
        gdpr={gdpr}
        columnVisibilityModel={tableHelper.columnVisibilityModel}
        hideAddColumn
        tableHelper={tableHelper}
        sortModel={tableHelper.sortModel}
        filterModel={tableHelper.filterModel}
        searchPlaceholder="Search Badges"
        rows={tableHelper.rows}
        columns={tableHelper.columns}
        onSaveFilters={save}
        onSaveLayout={tableHelper?.saveLayout}
        toolbar={<Toolbar selected={selectedRows} actions={tableActions} />}
        exportFilename={tableHelper.parent !== 'badges' ? `${tableHelper.parent}_badges` : 'topics'}
        rowSelectionModel={selectedRows}
        onRowSelectionModelChange={(param) => {
          setSelectedRows(param)
          if (param.length === 1) {
            const selected = tableHelper.rows.find((row: any) => param.includes(row.id))
            if (selected) {
              setSelectedBadge(selected)
            }
          }
        }}
      />
      {showAward && (
        <AwardBadgeDialog
          open={showAward}
          badge={selectedBadge}
          requestClose={() => setShowAward(false)}
          onConfirm={() => {
            setSelectedRows([])
            load()
            setShowAward(false)
          }}
        />
      )}
      {showConfirmDelete && (
        <ConfirmDialog
          danger
          open={showConfirmDelete}
          title="Delete Badges"
          message={`Are you sure you want to delete ${selectedRows.length} badge${
            selectedRows.length > 1 ? 's' : ''
          }`}
          confirmedMessage={`${selectedRows.length} badge${
            selectedRows.length > 1 ? 's have' : ' has'
          } been removed.`}
          confirmButton="Confirm"
          onConfirm={() =>
            selectedRows.map((badge) => {
              deleteBadge(badge)
            })
          }
          onClose={() => {
            setSelectedRows([])
            load()
            setShowConfirmDelete(false)
          }}
          handleCancel={() => setShowConfirmDelete(false)}
        />
      )}
      {showEnableDisable && (
        <ConfirmDialog
          open={showEnableDisable}
          danger
          title={selectedBadge?.badge_status === 'Disabled' ? 'Enable Badge' : 'Disable Badge'}
          message={
            selectedBadge?.badge_status === 'Disabled'
              ? `Are you sure you want to enable the "${selectedBadge?.badge_title}" badge?`
              : `Are you sure you want to disable the "${selectedBadge?.badge_title}" badge? This badge will be hidden from users.`
          }
          confirmedMessage={`${selectedBadge?.badge_title} was ${
            selectedBadge?.badge_status === 'Disabled' ? 'enabled.' : 'disabled.'
          }`}
          confirmButton="Confirm"
          onConfirm={() => {
            setSelectedRows([])
            if (selectedBadge?.id) {
              toggleBadgeDisable(selectedBadge.id).then(() => load())
            }
            setShowEnableDisable(false)
          }}
          onClose={() => setShowEnableDisable(false)}
          handleCancel={() => setShowEnableDisable(false)}
        />
      )}
      {showEdit && (
        <CreateEditBadge
          open={showEdit}
          badge={selectedBadge}
          onClose={() => setShowEdit(false)}
          onSaveBadge={handleSaveBadge}
        />
      )}
    </>
  )
})

const LOADER: React.CSSProperties = {
  display: 'flex',
  flex: 1,
  justifyContent: 'center',
  flexDirection: 'column',
  alignItems: 'center',
  height: window.innerHeight / 1.5,
}
