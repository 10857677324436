import React, { useState } from 'react'
import { observer } from 'mobx-react-lite'
import { useRouteMatch } from 'react-router-dom'

import { TableHelper } from '../../../../tables'
import { QuestionTable } from '../../../../tables/question-table'

const Questions: React.FunctionComponent = observer(() => {
  const match = useRouteMatch<any>('/people/groups/:id/questions')
  const groupID = Number(match?.params?.id)

  const [tableHelper] = useState(
    new TableHelper('list_group_questions', 'questions', 'groups', groupID),
  )

  return <QuestionTable tableHelper={tableHelper} />
})

export const GroupsQuestionsTab = (props: any) => {
  const { id } = props
  return {
    name: 'Questions',
    path: `/people/groups/${id}/questions`,
    component: () => <Questions />,
  }
}
