import { IconButton } from '@mui/material'
import { Check, ImageNotSupported } from '@mui/icons-material'
import React, { useRef, useState } from 'react'
import { AltTextDialog } from '../alt-text-dialog'
import { FileInput, ImageFileInput } from '../file-input'
import { Image } from '../image'
import { Text } from '../text'
import { color } from '../../theme'

export const ImageSelector = React.forwardRef((props: any, ref) => {
  const {
    imageSrc,
    compact,
    style,
    file,
    altText,
    onChange,
    onAltTextChange,
    onUnsplashClick,
    imageStyle,
    pickerStyle,
    imageContainerStyle,
    fullWidth,
    mini,
  } = props
  const [showAltText, setShowAltText] = useState(false)
  const [error, setError] = useState<string | null>(null)
  const replacePhotoRef = useRef(null)

  const handleImage = (f: File | undefined) => {
    const max10MB = 10 * 1000 * 1000
    if (f) {
      const splittedName = f?.name.split('.')
      if (f.size > max10MB) {
        setError('Maximum file size exceeded. Please try again with a smaller file (10MB).')
        return
      } else if (
        !['jpeg', 'png', 'gif', 'jpg'].includes(
          splittedName[splittedName.length - 1].toLocaleLowerCase(),
        )
      ) {
        setError('This file must be a .png, .gif, or .jpg. Please try again.')
        return
      } else {
        onChange(f)
        setError(null)
      }
    } else {
      onChange(f)
      setError(null)
    }
  }

  return (
    <div ref={ref} style={style}>
      {!imageSrc ? (
        onUnsplashClick ? (
          <ImageFileInput
            mini={mini}
            accept="image/png;image/jpeg;image/gif"
            file={file}
            helperText="JPG, PNG, GIF - max size 10 MB"
            onChange={handleImage}
            error={error}
            onBrowseClick={onUnsplashClick}
            style={{
              width: fullWidth ? '100%' : 400,
              marginBottom: mini ? 0 : 32,
              ...pickerStyle,
            }}
          />
        ) : (
          <FileInput
            accept="image/png;image/jpeg;image/gif"
            file={file}
            helperText="JPG, PNG, GIF - max size 10 MB"
            onChange={handleImage}
            error={error}
            style={{ width: fullWidth ? '100%' : compact ? 350 : 400 }}
          />
        )
      ) : (
        <>
          <div
            style={{
              position: 'relative',
              display: 'flex',
              marginBottom: 32,
              ...imageContainerStyle,
            }}
          >
            <img
              src={imageSrc}
              style={{
                borderRadius: '6px',
                objectFit: 'cover',
                width: '100%',
                height: compact ? 150 : 200,
                ...imageStyle,
              }}
            />
            <IconButton
              sx={{
                height: '36px',
                width: '36px',
                border: `1px solid ${color.shade20}`,
                '&:hover': {
                  backgroundColor: 'rgb(245,245,245) !important',
                },
              }}
              onClick={() => handleImage(undefined)}
              style={{ position: 'absolute', right: 8, top: 8, background: color.white }}
            >
              <ImageNotSupported htmlColor={color.shade70} />
            </IconButton>
            {Boolean(onAltTextChange) && (
              <IconButton
                onClick={() => setShowAltText(true)}
                style={{
                  position: 'absolute',
                  right: 56,
                  height: 36,
                  top: 8,
                  borderRadius: 100,
                  background: '#fff',
                  border: `1px solid ${color.shade20}`,
                }}
                sx={{
                  '&:hover': {
                    backgroundColor: 'rgb(245,245,245) !important',
                  },
                }}
              >
                <Text variant="labelEmphasized" text="ALT" style={{ color: color.shade70 }} />
                {altText && <Check htmlColor={color.green} style={{ marginLeft: 8 }} />}
              </IconButton>
            )}
          </div>
        </>
      )}

      <AltTextDialog
        open={showAltText}
        imgSrc={imageSrc}
        altText={altText ?? ''}
        onConfirm={(text: string) => {
          onAltTextChange(text)
        }}
        onClose={() => setShowAltText(false)}
      />
    </div>
  )
})
