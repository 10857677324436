import { types } from 'mobx-state-tree'
import { UserShortSchemaModel } from './UserShortSchemaModel'

/**
 * WhoIFollow Model
 */
export const WhoIFollowModel = types.model('WhoIFollow').props({
  __typename: types.optional(types.literal('WhoIFollow'), 'WhoIFollow'),
  followedUser: types.late(() => UserShortSchemaModel),
  followedUserId: types.maybeNull(types.integer),
  followingUserId: types.maybeNull(types.integer),
})
