import { cast, getParent, getParentOfType, SnapshotIn, types } from 'mobx-state-tree'
import { Question } from './Question'
import { Factoid } from './Factoid'

export const questionChoiceErrors = {
  invalidTFChoice: {
    key: 'invalidTFChoice',
    title: '',
    text: '',
    problem: 'answer choice text not valid for True or False question type.',
  },
  invalidQuestionType: {
    key: 'invalidQuestionType',
    title: '',
    text: '',
    problem: 'unable to validate choices provided due to invalid Question type.',
  },
  choiceLength: {
    key: 'choiceLength',
    title: 'Choice Length',
    text: 'Choice text cannot be blank.',
    problem: 'answer choice text length not within allowed parameters (1-100). length: 0',
  },
  mc24Choices: {
    key: 'mc24Choices',
    title: 'Choices',
    text: 'Multiple Choice question must have between 2 to 4 valid choices',
    problem: 'Multiple Choice question must have between 2 to 4 valid choices',
  },
}

export const questionChoiceWarnings = {
  choiceLength: {
    key: 'choiceLength',
    title: 'Choice Length',
    text: 'Warning: this answer is over the recommended character limit. It may not display correctly on certain devices.',
    problem: 'answer choice text length not within allowed parameters (1-100). length:',
  },
}

export const QuestionChoice = types
  .model('QuestionChoice')
  .props({
    choice_id: types.maybeNull(types.number),
    text: types.maybeNull(types.string),
    correct: types.boolean,
    errors: types.maybeNull(types.array(types.string)),
    errorsCorrected: types.maybeNull(types.array(types.string)),
    warnings: types.maybeNull(types.array(types.string)),
    acceptedWarnings: types.maybeNull(types.array(types.string)),
    order: types.maybeNull(types.number),
    touched: types.maybe(types.boolean),
    generating: types.maybe(types.boolean),
    loading: types.maybe(types.boolean),
    factoid: types.maybeNull(Factoid),
  })
  .actions((self) => ({
    set<K extends keyof SnapshotIn<typeof self>, T extends SnapshotIn<typeof self>>(
      key: K,
      value: T[K],
    ) {
      self[key] = cast(value)
    },
    reset() {
      self.text = ''
      self.touched = false
    },
    setTouched(touched: boolean) {
      const question = getParentOfType(self, Question) as any
      if (question && touched) {
        question.setTouched(touched)
        question.setApproved(false)
      }
      self.touched = touched
    },
    setText(text: string | null) {
      self.text = text
    },
    addToCorrectedErrors(correctedErrorsToAdd: Array<keyof typeof questionChoiceErrors>) {
      if (self.errorsCorrected) {
        self.errorsCorrected.push(...correctedErrorsToAdd)
      } else {
        self.errorsCorrected = cast(correctedErrorsToAdd)
      }
    },
    addToAcceptedWarnings(acceptedWarningsToAdd: Array<keyof typeof questionChoiceWarnings>) {
      if (self.acceptedWarnings) {
        self.acceptedWarnings.push(...acceptedWarningsToAdd)
      } else {
        self.acceptedWarnings = cast(acceptedWarningsToAdd)
      }
    },
    addError(error) {
      self.errors?.push(error)
    },
    removeError(error) {
      self.errors?.replace(self.errors.filter((e) => e === error))
    },
  }))
  .views((self) => ({
    get formWarnings() {
      const warnings: any = {}
      if (self.text && self.text.length > 100) {
        warnings.text = {
          type: 'warning',
          message:
            'Choice text is over the recommended character limit. It may not display correctly on certain devices.',
        }
      }

      if (self.factoid && self.factoid?.text && self.factoid.text.length > 100) {
        warnings.feedback = {
          type: 'warning',
          message: `Choice feedback is longer than 100 characters and may not display correctly on some devices.`,
        }
      }
      return warnings
    },
    get formErrors() {
      const errors: any = {}
      const question = getParent(self, 2) as any
      const count = question.choices.filter((choice) => choice?.text).length
      if (!self.text) {
        errors.text = {
          type: 'error',
          message: 'Choice text cannot be blank.',
        }
      }
      return errors
    },
    get hasErrors() {
      // if no errors
      if (!self?.errors?.length) {
        return false
      }

      // if there are errors but no corrected errors
      if (!self.errorsCorrected?.length) {
        return true
      }

      const mappedErrors = self.errors.map((e) => {
        const error = Object.values(questionChoiceErrors).find((qce) => e.includes(qce.problem))
        if (!error) {
          throw new Error(`Could not map error ${e}`)
        } else {
          return error
        }
      })

      // if any errors have not been corrected
      return mappedErrors.some((me) => !self.errorsCorrected!.includes(me.key))
    },
    get hasWarnings() {
      if (!self.warnings?.length) {
        return false
      }

      if (!self.acceptedWarnings?.length) {
        return true
      }

      const mappedWarnings = self.warnings.map((w) => {
        const warning = Object.values(questionChoiceWarnings).find((qcw) => w.includes(qcw.problem))
        if (!warning) {
          throw new Error(`Could not map warning ${w}`)
        } else {
          return warning
        }
      })

      return mappedWarnings.some((mw) => !self.acceptedWarnings!.includes(mw.key))
    },
    hasError(errorKey: keyof typeof questionChoiceErrors) {
      // has errors
      if (!self.errors?.length) {
        return false
      }

      // does not have this error
      if (!self.errors.some((e) => e.includes(questionChoiceErrors[errorKey].problem))) {
        return false
      }

      // this error has been corrected
      if (self.errorsCorrected?.includes(errorKey)) {
        return false
      }

      // has errors, has this error, and the error hasn't been corrected
      return true
    },
    hasWarning(warningKey: keyof typeof questionChoiceWarnings) {
      if (!self.warnings?.length) {
        return false
      }

      // does not have this error
      if (!self.warnings.some((w) => w.includes(questionChoiceWarnings[warningKey].problem))) {
        return false
      }

      // this error has been corrected
      if (self.acceptedWarnings?.includes(warningKey)) {
        return false
      }

      // has errors, has this error, and the error hasn't been corrected
      return true
    },
    getWarning(warningKey: keyof typeof questionChoiceWarnings) {
      return questionChoiceWarnings[warningKey]
    },
    getError(errorKey: keyof typeof questionChoiceErrors) {
      return questionChoiceErrors[errorKey]
    },
  }))
