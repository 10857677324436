import React from 'react'
import { Badge, Paper, PaperProps, Stack } from '@mui/material'
import { Text } from '../text'
import { Divider } from '../divider'

export interface PanelProps extends PaperProps {
  divider?: boolean
  title?: string
  action?: any
  badge?: any
  fill?: boolean
  fullWidth?: boolean
}
export const Panel = (props: PanelProps) => {
  const { fullWidth, variant, badge, sx, fill, title, divider, children, action, ...rest } = props
  return (
    <Paper
      sx={{ p: 3, width: fullWidth ? '100%' : 'auto', height: fill ? '100%' : 'auto', ...sx }}
      variant={variant || 'outlined'}
      {...rest}
    >
      <Stack height="100%" useFlexGap gap={2}>
        {title || badge || action ? (
          <Stack direction="row" alignItems="center" justifyContent="space-between">
            {title || badge ? (
              <Stack direction="row">
                {title ? <Text variant="titleEmphasized" text={title} /> : null}
                {badge ? (
                  <Badge
                    showZero
                    badgeContent={badge}
                    sx={{
                      '.MuiBadge-badge': {
                        backgroundColor: 'rgb(243,243,243)',
                        color: 'text.secondary',
                        top: 10,
                        left: 0,
                        border: `1px solid rgb(229,229,229)`,
                      },
                    }}
                  />
                ) : null}
              </Stack>
            ) : null}
            {action}
          </Stack>
        ) : null}
        {divider ? <Divider /> : null}
        {children}
      </Stack>
    </Paper>
  )
}
