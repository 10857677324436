import { types } from 'mobx-state-tree'
import { StatisticHistory } from './StatisticHistory'

export const UserGeneralStats = types.model('UserGeneralStats').props({
  user_id: types.number,
  questions_answered: types.number,
  quizzes_completed: types.number,
  mastery: types.maybeNull(types.number),
  stats_history: types.array(StatisticHistory),
})
