import { types, getEnv, flow, getRoot, Instance } from 'mobx-state-tree'
import { Environment } from '../environment'
import { getStrongestWeakest, transformHourlyActivity } from '../../utils'
import { GroupApi } from '../../services/api-objects/GroupApi'
import { MemberCountPayload, NewGroupPayload } from '../NewGroup'
import { Awaited } from '../../utility-types/Awaited'

// TODO need to clear stats on screen exits
export const GroupStoreModel = types
  .model('GroupStore')
  .props({
    status: types.optional(types.enumeration(['idle', 'pending', 'done', 'error']), 'idle'),
    selectedGroupId: types.frozen(),
    selectedGroup: types.frozen(),
    groups: types.frozen(),
    tags: types.frozen(),
    tagsMethod: types.frozen(),
    groupSessionStats: types.frozen(),
    hourlyActivity: types.frozen(),
    groupsFilter: types.maybe(types.string),
    groupDetail: types.maybe(types.frozen()),
    accomplishments: types.frozen(),
    assignmentStats: types.frozen(),
    knowledgeGain: types.frozen(),
    knowledgeGap: types.frozen(),
    questionStats: types.frozen(),
    topicStats: types.frozen(),
    userStats: types.frozen(),
    addedGroupUsers: types.frozen(),
    excludedGroupUsers: types.frozen(),
    addableUsers: types.frozen(),
    topicsFilter: types.maybe(types.string),
    questionsFilter: types.maybe(types.string),
    peopleFilter: types.maybe(types.string),
    memberCount: types.frozen(),
    mastery: types.frozen(),
  })
  .actions((self) => ({
    setStatus(value?: 'idle' | 'pending' | 'done' | 'error') {
      self.status = value || 'idle'
    },
    setTags(tags: any) {
      self.tags = tags
    },
    setTagsMethod(tagsMethod: any) {
      self.tagsMethod = tagsMethod
    },
    setGroups(groups: any) {
      self.groups = groups
    },
    setGroupSessionStats(groupSessionStats: any) {
      self.groupSessionStats = groupSessionStats
    },
    setHourlyActivity(hourlyActivity: any) {
      self.hourlyActivity = hourlyActivity
    },
    setGroupsFilter(filter: string | undefined) {
      self.groupsFilter = filter
    },
    setSelectedGroupId(groupId: any) {
      self.selectedGroupId = groupId
    },
    setSelectedGroup(group: any) {
      self.selectedGroup = group
    },
    setGroupDetail(detail: any) {
      self.groupDetail = detail
    },
    setAddableUsers(users: any) {
      self.addableUsers = users
    },
    setExcludedGroupUsers(users: any) {
      self.excludedGroupUsers = users
    },
    setAddedGroupUsers(users: any) {
      self.addedGroupUsers = users
    },
    setUserStats(users: any) {
      self.userStats = users
    },
    setGroupTopicStats(topicStats: any) {
      self.topicStats = topicStats
    },
    setGroupQuestionStats(questionStats: any) {
      self.questionStats = questionStats
    },
    setGroupKnowledgeGap(knowledgeGap: any) {
      self.knowledgeGap = knowledgeGap
    },
    setGroupKnowledgeGain(knowledgeGain: any) {
      self.knowledgeGain = knowledgeGain
    },
    setGroupAssignmentStats(assignmentStats: any) {
      self.assignmentStats = assignmentStats
    },
    setGroupAccomplishments(accomplishments: any) {
      self.accomplishments = accomplishments
    },
    setTopicsFilter(filter: any) {
      self.topicsFilter = filter
    },
    setQuestionsFilter(filter: any) {
      self.questionsFilter = filter
    },
    setPeopleFilter(filter: any) {
      self.peopleFilter = filter
    },
    setMemberCount(count: number) {
      self.memberCount = count
    },
    setGroupMastery(mastery: any) {
      self.mastery = mastery
    },
  }))
  .views((self) => ({
    get environment() {
      return getEnv(self) as Environment
    },
    get isLoading() {
      return self.status === 'pending'
    },
    get rootStore(): any {
      return getRoot(self) as any
    },
    get filteredGroups() {
      if (!self.groupsFilter) {
        return self.groups
      } else {
        return self.groups.filter((g: any) => {
          return g.label.toLocaleLowerCase().includes(self.groupsFilter!.toLocaleLowerCase())
        })
      }
    },
  }))
  .actions((self) => ({
    getGroupTags: flow(function* () {
      self.setStatus('pending')
      if (!self.selectedGroupId) {
        self.setStatus('error')
        return
      }

      try {
        const result = yield self.environment.api.getGroupTags(self.selectedGroupId)
        if (result.kind === 'ok') {
          self.setTags(result.data?.tags ?? [])
          self.setTagsMethod(result.data?.method ?? '')
          self.setStatus('done')
          return result.data
        } else {
          self.setStatus('error')
        }
      } catch {
        self.setStatus('error')
      }
    }),
    getGroupSessionStats: flow(function* (startingTime: string, endingTime: string) {
      self.setStatus('pending')
      self.setGroupSessionStats(undefined)
      if (!self.selectedGroupId) {
        self.setStatus('error')
        return
      }

      try {
        const result = yield self.environment.api.getGroupSessionStats(
          self.selectedGroupId,
          startingTime,
          endingTime,
        )
        if (result.kind === 'ok') {
          const sessionMinutes = Math.ceil(result.data.avg_session_length / 60)
          const userSessionMinutes = Math.ceil(result.data.avg_time_per_user / 60)
          const final = {
            avg_session_length: sessionMinutes,
            avg_time_per_user: userSessionMinutes,
            stats_history: result.data.stats_history,
          }
          self.setGroupSessionStats(final)
          self.setStatus('done')
        } else {
          self.setStatus('error')
        }
      } catch {
        self.setStatus('error')
      }
    }),
    getGroupHourlyActivity: flow(function* (startingTime: string, endingTime: string) {
      self.setStatus('pending')
      if (!self.selectedGroupId) {
        self.setStatus('error')
        return
      }

      try {
        const result = yield self.environment.api.getGroupHourlyActivity(
          self.selectedGroupId,
          startingTime,
          endingTime,
        )
        if (result.kind === 'ok') {
          self.setHourlyActivity(transformHourlyActivity(result.data))
          self.setStatus('done')
        } else {
          self.setStatus('error')
        }
      } catch {
        self.setStatus('error')
      }
    }),
    deleteUsers: flow(function* (iDs: []) {
      self.setStatus('pending')

      try {
        const result = yield self.environment.api.deleteUsers(iDs)
        if (result.kind === 'ok') {
          self.setStatus('done')
        } else {
          self.setStatus('error')
        }
      } catch {
        self.setStatus('error')
      }
    }),
    createGroup: flow(function* (group: Instance<typeof NewGroupPayload>) {
      self.setStatus('pending')
      try {
        const result: Awaited<ReturnType<typeof GroupApi.createGroup>> =
          yield GroupApi.createGroup(group)
        if (result.ok) {
          self.setStatus('done')
        } else {
          self.setStatus('error')
        }
      } catch (err) {
        self.setStatus('error')
      }
    }),
    getRulesets: flow(function* (id: number) {
      self.setStatus('pending')
      try {
        const result: Awaited<ReturnType<typeof GroupApi.getRulesets>> =
          yield GroupApi.getRulesets(id)
        if (result.ok) {
          self.setStatus('done')
          return result.data
        } else {
          self.setStatus('error')
        }
      } catch (err) {
        self.setStatus('error')
      }
    }),
    editGroup: flow(function* (group: any, id: number) {
      self.setStatus('pending')
      try {
        const result: Awaited<ReturnType<typeof GroupApi.editGroup>> = yield GroupApi.editGroup(
          group,
          id,
        )
        if (result.ok) {
          self.setStatus('done')
        } else {
          self.setStatus('error')
        }
      } catch (err) {
        self.setStatus('error')
      }
    }),
    getGroupDetail: flow(function* (startTime: string, endTime: string) {
      self.setStatus('pending')
      self.setGroupDetail(undefined)
      if (!self.selectedGroupId) {
        self.setStatus('error')
        return
      }

      try {
        const result = yield self.environment.api.getGroupDetail(
          self.selectedGroupId,
          startTime,
          endTime,
        )
        if (result.kind === 'ok') {
          self.setGroupDetail(result.data)
          self.setStatus('done')
        } else {
          self.setStatus('error')
        }
      } catch {
        self.setStatus('error')
      }
    }),
    getGroupAccomplishments: flow(function* (startTime: string, endTime: string) {
      self.setStatus('pending')
      self.setGroupAccomplishments(undefined)

      if (!self.selectedGroupId) {
        self.setStatus('error')
        return
      }

      try {
        const result = yield self.environment.api.getGroupAccomplishments(
          self.selectedGroupId,
          startTime,
          endTime,
        )
        if (result.kind === 'ok') {
          self.setGroupAccomplishments(result.data)
          self.setStatus('done')
        } else {
          self.setStatus('error')
        }
      } catch {
        self.setStatus('error')
      }
    }),
    getGroupKnowledgeGain: flow(function* (startTime: string, endTime: string) {
      self.setStatus('pending')
      self.setGroupKnowledgeGain(undefined)

      if (!self.selectedGroupId) {
        self.setStatus('error')
        return
      }

      try {
        const result = yield self.environment.api.getGroupKnowledgeGain(
          self.selectedGroupId,
          startTime,
          endTime,
        )
        if (result.kind === 'ok') {
          self.setGroupKnowledgeGain(result.data)
          self.setStatus('done')
        } else {
          self.setStatus('error')
        }
      } catch {
        self.setStatus('error')
      }
    }),
    getGroupKnowledgeGap: flow(function* (startTime: string, endTime: string) {
      self.setStatus('pending')

      if (!self.selectedGroupId) {
        self.setStatus('error')
        return
      }

      try {
        const resultTopics = yield self.environment.api.getGroupTopicGaps(
          self.selectedGroupId,
          startTime,
          endTime,
        )
        const resultQuestions = yield self.environment.api.getGroupQuestionGaps(
          self.selectedGroupId,
          startTime,
          endTime,
        )
        const strongestWeakestTopics = getStrongestWeakest(resultTopics?.data?.topics)
        const strongestWeakestQuestions = getStrongestWeakest(resultQuestions?.data?.questions)
        const data = {
          group_id: self.selectedGroupId,
          strongest_questions: strongestWeakestQuestions?.strongest,
          strongest_topics: strongestWeakestTopics?.strongest,
          weakest_questions: strongestWeakestQuestions?.weakest,
          weakest_topics: strongestWeakestTopics?.weakest,
        }
        if (resultTopics.kind === 'ok' && resultQuestions.kind === 'ok') {
          self.setGroupKnowledgeGap(data)
          self.setStatus('done')
        } else {
          self.setStatus('error')
        }
      } catch {
        self.setStatus('error')
      }
    }),
    getGroupMastery: flow(function* (startTime: string, endTime: string) {
      self.setStatus('pending')
      self.setGroupMastery(undefined)

      if (!self.selectedGroupId) {
        self.setStatus('error')
        return
      }

      try {
        const result = yield self.environment.api.getGroupMastery(
          self.selectedGroupId,
          startTime,
          endTime,
        )
        if (result.kind === 'ok') {
          self.setGroupMastery(result.data)
          self.setStatus('done')
        } else {
          self.setStatus('error')
        }
      } catch {
        self.setStatus('error')
      }
    }),
    getAddedGroupUsers: flow(function* () {
      self.setStatus('pending')

      if (!self.selectedGroupId) {
        self.setStatus('error')
        return
      }

      try {
        const result = yield self.environment.api.getAddedGroupUsers(self.selectedGroupId)
        if (result.kind === 'ok') {
          self.setAddedGroupUsers(result.data)
          self.setStatus('done')
          return result.data
        } else {
          self.setStatus('error')
        }
      } catch {
        self.setStatus('error')
      }
    }),
    getExcludedGroupUsers: flow(function* () {
      self.setStatus('pending')

      if (!self.selectedGroupId) {
        self.setStatus('error')
        return
      }

      try {
        const result = yield self.environment.api.getExcludedGroupUsers(self.selectedGroupId)
        if (result.kind === 'ok') {
          self.setExcludedGroupUsers(result.data)
          self.setStatus('done')
        } else {
          self.setStatus('error')
        }
      } catch {
        self.setStatus('error')
      }
    }),
    removeAddedUsers: flow(function* (userIds: any = []) {
      self.setStatus('pending')

      if (!self.selectedGroupId) {
        self.setStatus('error')
        return
      }

      try {
        const result = yield self.environment.api.clearGroupAddedUsers(
          self.selectedGroupId,
          userIds,
        )
        if (result.kind === 'ok') {
          ;(self as any).getAddedGroupUsers()
          self.setStatus('done')
        } else {
          self.setStatus('error')
        }
      } catch {
        self.setStatus('error')
      }
    }),
    restoreExcludedUsers: flow(function* (userIds: any = []) {
      self.setStatus('pending')

      if (!self.selectedGroupId) {
        self.setStatus('error')
        return
      }

      try {
        const result = yield self.environment.api.restoreGroupExcludedUsers(
          self.selectedGroupId,
          userIds,
        )
        if (result.kind === 'ok') {
          ;(self as any).getExcludedGroupUsers()
          self.setStatus('done')
        } else {
          self.setStatus('error')
        }
      } catch {
        self.setStatus('error')
      }
    }),
    addUsers: flow(function* (userIdsToAdd: { user_ids: any[] }) {
      self.setStatus('pending')

      if (!self.selectedGroupId) {
        self.setStatus('error')
        return
      }

      try {
        const result = yield self.environment.api.addUsersToGroup(
          self.selectedGroupId,
          userIdsToAdd,
        )
        if (result.kind === 'ok') {
          ;(self as any).getExcludedGroupUsers()
          ;(self as any).getAddedGroupUsers()
          self.setStatus('done')
        } else {
          self.setStatus('error')
        }
      } catch {
        self.setStatus('error')
      }
    }),
    excludeUsers: flow(function* (userIdsToExclude: { user_ids: [number] }, groupId?: number) {
      self.setStatus('pending')

      if (!self.selectedGroupId && !groupId) {
        self.setStatus('error')
        return
      }

      try {
        const result = yield self.environment.api.excludeUsersFromGroup(
          groupId ?? self.selectedGroupId!,
          userIdsToExclude,
        )
        if (result.kind === 'ok') {
          ;(self as any).getExcludedGroupUsers()
          self.setStatus('done')
        } else {
          self.setStatus('error')
        }
      } catch {
        self.setStatus('error')
      }
    }),
    refreshOverviewData: flow(function* (start: string, end: string) {
      ;(self as any).getGroupDetail(start, end)
      ;(self as any).getGroupAccomplishments(start, end)
      ;(self as any).getGroupSessionStats(start, end)
      ;(self as any).getGroupHourlyActivity(start, end)
      ;(self as any).getGroupKnowledgeGain(start, end)
      ;(self as any).getGroupKnowledgeGap(start, end)
      ;(self as any).getGroupMastery(start, end)
    }),
    getMemberCount: flow(function* (payload: Instance<typeof MemberCountPayload>) {
      self.setStatus('pending')
      try {
        const result: Awaited<ReturnType<typeof GroupApi.getMemberCount>> =
          yield GroupApi.getMemberCount(payload)
        if (result.ok && result.data) {
          self.setStatus('done')
          self.setMemberCount(result.data.count)
        } else {
          self.setStatus('error')
        }
      } catch (err) {
        self.setStatus('error')
      }
    }),
    deleteGroups: flow(function* (iDs: number[]) {
      self.setStatus('pending')

      try {
        const result = yield self.environment.api.deleteGroups({
          group_ids: iDs,
        })
        if (result.kind === 'ok') {
          self.setStatus('done')
        } else {
          self.setStatus('error')
        }
      } catch {
        self.setStatus('error')
      }
    }),
  }))

type GroupStoreType = typeof GroupStoreModel.Type
export interface GroupStore extends GroupStoreType {}
type GroupStoreSnapshotType = typeof GroupStoreModel.SnapshotType
export interface GroupStoreSnapshot extends GroupStoreSnapshotType {}
