import { elapsedTime } from './elapsedTime'
import { DateTime } from 'luxon'
import { RawUserActivity } from '../models/RawUserActivity'
import { Instance } from 'mobx-state-tree'
import { TextMapValueModel } from '../models/base/activity/activity'

export const eventStrings = {
  Assignment: {
    extended: 'extended the {{title}} assignment',
    created: 'created the {{title}} assignment',
    deleted: 'deleted the {{title}} assignment',
    edited: 'edited the {{title}} assignment',
    nudged: 'nudged users to complete the {{title}} assignment',
  },
  Badge: {
    created: 'created a badge {{name}}',
    edited: 'edited the {{name}} badge',
    deleted: 'deleted the {{name}} badge',
    enabled: 'enabled the {{label}} badge',
    disabled: 'disabled the {{label}} badge',
  },
  Contest: {
    created: 'created a new contest {{name}}',
    started: 'started the {{name}} contest ',
    extended: 'started the {{name}} contest ',
    ended: 'ended the {{name}} contest',
    edited: 'edited the {{name}} contest',
    deleted: 'deleted the {{name}} contest',
    photo: 'changed the photo for the {{name}} contest',
  },
  Course: {
    created: 'created the {{title}} course',
    deleted: 'deleted the {{title}} course',
    edited: 'edited the {{title}} course',
    photo: 'changed the photo for the {{title}} course',
  },
  Generic: {
    bulk_load_user_levels: 'created UserLevel objects in bulk',
    bulk_create_resources: 'created Resource objects in bulk',
    edit_user_levels: 'updated UserLevel {{label}}',
  },
  Group: {
    created: 'created a new group {{label}}',
    deleted: 'deleted group {{label}}',
    messaged: 'sent a message to the {{label}} group',
    edited: 'edited the {{label}} segment',
    welcomed: 'sent a welcome email to the {{label}} segment',
  },
  Post: {
    created: 'created the {{title}} post',
    deleted: 'deleted the {{title}} post',
    edited: 'edited the {{title}} post',
  },
  Question: {
    created: 'created a question with text {{text}}',
    deleted: 'created a question with text {{text}}',
    edited: 'created a question with text {{text}}',
    resource: 'changed the resources for the question {{text}}',
    image: 'changed the image for the question {{text}}',
  },
  Quiz: {
    created: 'created the {{title}} quiz',
    deleted: 'deleted the {{title}} quiz',
    edited: 'edited the {{title}} quiz',
    question: 'changed the questions for the {{title}} quiz',
    resource: 'changed the resources for the {{title}} quiz',
    order: 'changed the question order for the {{title}} quiz',
    removed: 'removed the {{title}} quiz',
    photo: 'set a photo for the {{title}} quiz',
  },
  Resource: {
    created: 'created the resource {{title}}',
    deleted: 'deleted the resource {{title}}',
    edited: 'edited the resource {{title}}',
  },
  Settings: {
    updated_settings: 'updated settings',
    exported: 'exported data',
  },
  Message: {
    scheduled: 'has scheduled a message to {{recipient_count}} users.',
    sent: 'has sent a message to {{recipient_count}} users.',
  },
  Survey: {
    started: 'started the {{title}} survey',
    created: 'created the {{title}} survey',
    deleted: 'deleted the {{title}} survey',
    extended: 'extended the {{title}} survey',
    ended_early: '{{title}} survey ended early',
    messaged: 'messaged the {{title}} survey',
    edited: 'edited the {{title}} survey',
    updated: 'updated the {{title}} survey',
  },
  Tag: {
    created: 'created a new tag {{label}}',
    deleted: 'deleted the {{label}} tag',
    edited: 'edited the {{label}} tag',
  },
  Topic: {
    created: 'created the topic {{title}}',
    deleted: 'deleted the topic {{title}}',
    removed: 'removed the topic {{title}}',
    photo: 'set a photo for the topic {{title}}',
    edited: 'edited the topic {{title}}',
  },
  User: {
    rejected_terms: '{{name}} rejected the hourly warning terms',
    consented: '{{edited_user_name}} consented to receive SMS messages from Trivie',
    created: 'created a new user {{edited_user_name}}',
    deleted: 'deleted the user {{edited_user_name}}',
    edited: 'edited the user {{edited_user_name}}',
    messaged: 'sent a message to user {{edited_user_name}}',
    welcomed: 'sent a welcome email to user {{edited_user_name}}',
    password: 'sent a password reset email to user {{edited_user_name}}',
    invited: 'invited user {{edited_user_name}} to Trivie',
    set_roles: 'edited user role as {{role_names}}',
  },
  UserBadge: {
    created: 'created a badge {{name}}',
    edited: 'edited the {{name}} badge',
    deleted: 'deleted the {{name}} badge',
    revoked: 'revoked the {{name}} badge',
  },
  UserLevel: {
    created: 'created UserLevel {{label}}',
    deleted: 'deleted UserLevel {{label}}',
    icon: 'updated icon for UserLevel {{label}}',
    updated: 'updated UserLevel {{label}}',
  },
  Workflow: {
    created: 'created the {{workflow_name}} workflow',
    deleted: 'deleted the {{workflow_name}} workflow',
    edited: 'edited the {{workflow_name}} workflow',
  },
  Path: {
    created: 'created the {{path_name}} path',
    deleted: 'deleted the {{path_name}} path',
    edited: 'edited the {{path_name}} path',
    photo: 'set a photo for the {{path_name}} path',
  },
}

const getLink = (type: string, id: number | null, mainTab = 'scheduled') => {
  if (type === 'deleted') {
    return null
  }

  switch (type) {
    case 'Assignment':
      return `/activities/assignments/${mainTab}/${id}/overview`
    case 'Message':
      return `/activities/messages/all/?message_id=${id}`
    case 'Contest':
      return null
    case 'Course':
      return `/content/topics/${id}/overview`
    case 'Generic':
      return null
    case 'Group':
      return `/people/groups/${id}/overview`
    case 'Post':
      return null
    case 'Question':
      return `/content/questions/${id}`
    case 'Quiz':
      return `/content/quizzes/${id}/overview`
    case 'Resource':
      return `/content/resources/${id}`
    case 'Settings':
      return null
    case 'Tag':
      return `/people/tags/${id}/overview`
    case 'Topic':
      return `/content/topics/${id}/overview`
    case 'Path':
      return `/content/paths/${id}/overview`
    case 'User':
      return id ? `/people/users/${id}/overview` : `/people/users`
    case 'UserLevel':
      return null
  }
}

export function activityTransformer(activities: Array<Instance<typeof RawUserActivity>>) {
  const today = DateTime.local().startOf('day')
  const yesterday = DateTime.local().plus({ day: -1 }).startOf('day')

  return activities
    .sort((a, b) => {
      return b.id - a.id
    })
    .map((activity) => {
      const text = (eventStrings as any)[activity.object_type][activity.action]
      if (!text) {
        console.warn('no text for object/action', activity.object_type, activity.action)
      }
      const variable = text?.includes('{{') ? text?.split('{{')[1].split('}}')[0] : null
      const value = variable ? activity.text_map_values[variable as keyof TextMapValueModel] : null

      const event = DateTime.fromISO(activity.datetime_occurred)

      const a = {
        id: activity.id,
        name:
          activity.object_type === 'Message' ||
          activity.object_type === 'Path' ||
          activity.object_type === 'Workflow'
            ? activity.text_map_values.admin
            : activity.text_map_values.user_full_name || activity.text_map_values.user,
        photo: activity.source_user_photo_url || activity.category.full_url,
        datetime_occurred: activity.datetime_occurred,
        marginFix:
          (activity.object_type === 'User' && activity.action === 'consented') ||
          activity.action === 'rejected_terms',
        link: {
          name:
            activity.object_type === 'User' && activity.action === 'consented'
              ? ''
              : activity.object_type === 'Message' ||
                  activity.object_type === 'Path' ||
                  activity.object_type === 'Workflow'
                ? activity.text_map_values.admin
                : activity.text_map_values.user_full_name || activity.text_map_values.user,
          firstPart: text?.includes('{{') ? text.split('{{')[0] : text,
          link: getLink(activity.object_type, activity.object_id),
          fullLink:
            activity.object_type === 'Message'
              ? getLink(activity.object_type, activity.object_id)
              : '',
          linkLabel: value,
          lastPart: text?.includes('}}') ? text.split('}}')[1] : null,
        },
        ...(event > today
          ? { elapsedTime: elapsedTime(activity.datetime_occurred, 'long') }
          : null),
      }

      return a
    })
    .reduce((acc: { [key: string]: Array<typeof activity> }, activity) => {
      const event = DateTime.fromISO(activity.datetime_occurred)

      let key = 'Today'

      if (event < yesterday) {
        key = event.toFormat('EEEE, MMMM dd').toLocaleUpperCase()
      } else if (event < today) {
        key = 'Yesterday'
      }

      if (!acc) {
        acc = {
          [key]: [activity],
        }
      } else if (!acc[key]) {
        acc[key] = [activity]
      } else {
        acc[key].push(activity)
      }

      return acc
    }, {})
}
