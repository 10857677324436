import React from 'react'
import { Link } from 'react-router-dom'
import TableCell from '@mui/material/TableCell'
import { TopicRounded } from '@mui/icons-material'

import { color } from '../../../theme'
import { Text } from '../../text'

export const TopicsShortCell = (props: any) => {
  const { row, deleteButton: DeleteButton } = props

  return (
    <TableCell style={{ borderBottom: 'none', padding: 0 }} align="left">
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
      >
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <Link
            style={{
              flexDirection: 'row',
              display: 'flex',
              textDecoration: 'none',
              alignItems: 'center',
              color: color.black,
            }}
            to={
              row.topic_id === null ? `/content/topics` : `/content/topics/${row.topic_id}/overview`
            }
          >
            <TopicRounded style={{ marginRight: 8 }} htmlColor={color.shade70} />
            <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
              <Text text={row.title || row.topic_title} variant="body1Medium" />
              {row.quiz_count && (
                <Text
                  style={{ color: color.shade50, marginTop: 4 }}
                  text={`${row.quiz_count} quizzes`}
                  variant="body1"
                />
              )}
            </div>
          </Link>
        </div>
        {DeleteButton && <DeleteButton {...row} />}
      </div>
    </TableCell>
  )
}
