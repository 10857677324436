import React, { useEffect, useState } from 'react'
import { useHistory, useParams, useRouteMatch } from 'react-router-dom'

import { Grid } from '@mui/material'
import { observer } from 'mobx-react-lite'
import { Instance } from 'mobx-state-tree'
import { Add } from '@mui/icons-material'

import { Topic, TopicHelper, useStores } from '@trivie/core'
import { Button } from '@trivie/ui-web/components/button'
import { Workflow } from '@trivie/core/src/models/workflow/Workflow'
import { color } from '@trivie/ui-web/theme'

import { ResourceSettings } from './components'
import { QuizSettings } from './components/quiz-settings'
import { TopicSettings } from './components/topic-settings'
import ContentCreationHeader from './content-creation-header'
import ContentCreationHeaderTitle from './content-creation-header-title'
import { ResourceUploadDialog } from './components/resource-upload-dialog'
import { StepPanel } from './components/step-drawer'
import { StepsList } from './components/steps-list'
import { ContentImportSidebar } from './content-import-sidebar'
import { BadgeApi } from '@trivie/core/src/services/api-objects'

export const CreateTopicScreen: React.FunctionComponent = observer(() => {
  const { contentCreationStore, pathStore } = useStores()
  const { workflow, loadWorkflow, path } = pathStore
  const { topics, selectedTopic, setTopics } = contentCreationStore
  const [showResourceDialog, setShowResourceDialog] = useState(false)

  const [showStepPanel, setShowStepPanel] = useState(false)

  const importing = useRouteMatch<any>('/content/creation/import')
  const history = useHistory()
  const { topicId } = useParams<any>()

  const handlePublish = async () => {
    history.push('/content/topics')
  }

  useEffect(() => {
    if (topicId && !selectedTopic && !topics?.length) {
      TopicHelper.loadTopic(Number(topicId))
        .then((topic: Instance<typeof Topic>) => {
          if (topic.workflow_id) {
            loadWorkflow(topic.workflow_id).then((wf) => {
              BadgeApi.getAllBadges(
                'users',
                false,
                undefined,
                'list_custom_badges',
                undefined,
                'custom',
              ).then((res) => {
                const bList = [] as any
                res.data?.rows.map((badge) => {
                  const badge_title = badge.columns.find((c) => c.field === 'badge_title')
                  const photo_url = badge.columns.find((c) => c.field === 'photo_url')
                  bList.push({ label: badge_title.value, photo_url: photo_url.value, id: badge.id })
                })
                wf?.actions.map((action) => {
                  if (action.action_type === 'award_badge') {
                    const found = bList.find((b) => b.id === action.object_id)
                    action.badge = found
                  }
                })
                pathStore.setWorkflow(wf)
              })
            })
          }
          setTopics([topic])
        })
        .catch((e) => console.warn(e))
    }
    if (!selectedTopic) {
      setTopics([TopicHelper.createNewTopic()])
    }
  }, [])

  useEffect(() => {
    if (!importing && workflow) {
      workflow.setName(selectedTopic?.title + ' Workflow')
    }
  }, [selectedTopic?.title])

  const handleAddStep = () => {
    setShowStepPanel(true)
    if (importing) {
      selectedTopic?.workflow?.setEditing('')
      selectedTopic?.workflow?.selectStep(undefined)
    } else {
      if (!workflow) {
        pathStore.setWorkflow(
          Workflow.create({
            version: 1.1,
            name: selectedTopic?.title + ' Workflow',
          }),
        )
      }
      if (workflow?.editing) {
        workflow?.setEditing('')
        workflow?.selectStep(undefined)
      }
    }
  }

  if (!selectedTopic) {
    return null
  }

  return (
    <div style={ROOT}>
      <ContentCreationHeader
        onBack={handlePublish}
        showUnsavedPopup
        publishData={selectedTopic}
        titleHeader={
          <ContentCreationHeaderTitle
            titles={[
              {
                title: selectedTopic.title ? selectedTopic.title : 'Untitled Topic',
                isPlaceholder: !selectedTopic.title,
              },
            ]}
          />
        }
      />
      {importing && topics ? (
        <div style={CONTENT}>
          {topics && topics?.length > 1 && <ContentImportSidebar />}
          <div style={CONTAINER}>
            <Grid container spacing={2} style={{ maxWidth: 1400 }}>
              <Grid item container xs={12} sm={importing ? 12 : 5} md={5} rowGap={3}>
                <Grid item xs={12}>
                  <TopicSettings />
                </Grid>
                <Grid item xs={12}>
                  <ResourceSettings openResourceDialog={() => setShowResourceDialog(true)} />
                </Grid>
              </Grid>
              <Grid item xs={12} sm={importing ? 12 : 7} md={7}>
                <Grid item xs={12} style={{ marginBottom: 24 }}>
                  <QuizSettings />
                </Grid>
                <Grid item xs={12}>
                  {workflow && workflow.nodes.length > 0 ? (
                    <StepsList
                      workflow={workflow}
                      path={path}
                      showStepPanel={showStepPanel}
                      topic={selectedTopic}
                      handleAddStep={handleAddStep}
                    />
                  ) : (
                    <Button
                      sx={{
                        backgroundColor: color.white,
                        display: 'flex',
                        margin: '0 auto !important',
                      }}
                      variant="outlined"
                      startIcon={<Add />}
                      onClick={handleAddStep}
                      text="Add Award After Topic Completion"
                    />
                  )}
                </Grid>
              </Grid>
            </Grid>
          </div>
          <StepPanel
            importing
            topic={selectedTopic}
            workflow={workflow}
            open={showStepPanel && workflow}
            onOpen={() => setShowStepPanel(true)}
            onClose={() => setShowStepPanel(false)}
          />
        </div>
      ) : (
        <div style={CONTENT}>
          <div id="PathContainer" style={CONTAINER}>
            <Grid container spacing={2} style={{ maxWidth: 1400 }}>
              <Grid item xs={12} md={showStepPanel ? 12 : 5} lg={4} sm={12}>
                <Grid item xs={12} style={{ marginBottom: 16 }}>
                  <TopicSettings />
                </Grid>
                <Grid item xs={12}>
                  <ResourceSettings openResourceDialog={() => setShowResourceDialog(true)} />
                </Grid>
              </Grid>
              <Grid item xs={12} md={showStepPanel ? 12 : 7} lg={8} sm={12}>
                <Grid item xs={12} style={{ marginBottom: 16 }}>
                  <QuizSettings />
                </Grid>
                <Grid item xs={12}>
                  {workflow && workflow.nodes.length > 0 ? (
                    <StepsList
                      workflow={workflow}
                      path={path}
                      showStepPanel={showStepPanel}
                      topic={selectedTopic}
                      handleAddStep={handleAddStep}
                    />
                  ) : (
                    <Button
                      sx={{
                        backgroundColor: color.white,
                        display: 'flex',
                        margin: '0 auto !important',
                      }}
                      variant="outlined"
                      startIcon={<Add />}
                      onClick={handleAddStep}
                      text="Add Award After Topic Completion"
                    />
                  )}
                </Grid>
              </Grid>
            </Grid>
          </div>
          <StepPanel
            topic={selectedTopic}
            workflow={workflow}
            open={showStepPanel && workflow}
            onOpen={() => setShowStepPanel(true)}
            onClose={() => setShowStepPanel(false)}
          />
        </div>
      )}
      {showResourceDialog && (
        <ResourceUploadDialog
          topic={selectedTopic}
          close={() => setShowResourceDialog(false)}
          open={showResourceDialog}
        />
      )}
    </div>
  )
})

const CONTAINER: React.CSSProperties = {
  padding: 40,
  height: 'calc(100% - 70px)',
  display: 'flex',
  justifyContent: 'center',
  overflowY: 'auto',
  width: '100%',
}
const ROOT: React.CSSProperties = {
  position: 'absolute',
  top: 0,
  left: 0,
  bottom: 0,
  right: 0,
  background: 'rgb(244, 244, 244)',
  flexGrow: 1,
  display: 'flex',
  flexDirection: 'column',
}
const CONTENT: React.CSSProperties = { display: 'flex', flexDirection: 'row', height: '100%' }
