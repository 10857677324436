import { types, getEnv, flow, cast } from 'mobx-state-tree'

import { Environment } from '../environment'
import { UserApi } from '../../services/api-objects/UserApi'
import { AssignedTopic } from '../AssignedTopic'
import { UserResourceResponse } from '../response-models/user'
import { TopicApi } from '../../services/api-objects/TopicApi'
import { UserTopicDetails } from '../UserTopicDetails'
import { AssignedQuiz } from '../UserQuiz'
import { Assessment } from '../Assessment'
import { DateTime } from 'luxon'

const _groupBy = require('lodash/groupBy')

import { FlowCacheModel, useFlowCacheMiddleware } from '../../middleware'

// Add flow actions to be cached / blocked and
// how much time in seconds we wait before continuing
const ACTIONS_TO_CACHE = {
  getQuizzesForUser: { wait: 30 },
  getTopicsForUser: { wait: 30 },
  getResourcesForUser: { wait: 30 },
}

export const LibraryStoreModel = types
  .model('LibraryStore')
  .props({
    status: types.optional(
      types.enumeration(['idle', 'pending-assessments', 'pending', 'done', 'error']),
      'idle',
    ),
    quizLoadStatus: types.optional(types.enumeration(['idle', 'pending', 'done', 'error']), 'idle'),
    topicLoadStatus: types.optional(
      types.enumeration(['idle', 'pending', 'done', 'error']),
      'idle',
    ),
    resourceLoadStatus: types.optional(
      types.enumeration(['idle', 'pending', 'done', 'error']),
      'idle',
    ),
    topics: types.array(AssignedTopic),
    quizzes: types.array(AssignedQuiz),
    allResources: types.array(UserResourceResponse),
    selectedTopic: types.maybe(types.maybeNull(UserTopicDetails)),
    topicSearchTerm: types.maybe(types.string),
    assessments: types.array(Assessment),
    contentPacks: types.frozen(),
    packDetails: types.frozen(),
    packSearchTerm: types.frozen(),
    subscribedAll: types.frozen(),
    allAccessExpiry: types.frozen(),
    flowCache: types.map(types.map(FlowCacheModel)),
    quizSearchTerm: types.maybe(types.string),
  })
  .actions((self) => ({
    afterCreate() {
      useFlowCacheMiddleware({ store: self, cacheable: ACTIONS_TO_CACHE })
    },
    setStatus(value?: 'idle' | 'pending' | 'pending-assessments' | 'done' | 'error') {
      self.status = value || 'idle'
    },
    setQuizLoadStatus(value?: 'idle' | 'pending' | 'done' | 'error') {
      self.quizLoadStatus = value || 'idle'
    },
    setResourceLoadStatus(value?: 'idle' | 'pending' | 'done' | 'error') {
      self.resourceLoadStatus = value || 'idle'
    },
    setTopicLoadStatus(value?: 'idle' | 'pending' | 'done' | 'error') {
      self.topicLoadStatus = value || 'idle'
    },
    setAllAccessExpiry(expiration: typeof self.allAccessExpiry) {
      self.allAccessExpiry = expiration
    },
    setSubscribedAll(subscribed: any) {
      self.subscribedAll = subscribed
    },
    setAssessments(assessments: typeof self.assessments) {
      self.assessments.replace(assessments)
    },
    setPackSearchTerm(term: string) {
      self.packSearchTerm = term
    },
    setPackDetails(details: typeof self.packDetails) {
      self.packDetails = details
    },
    setTopics(topics: typeof self.topics) {
      self.topics = cast(topics)
    },
    setQuizzes(quizzes: typeof self.quizzes) {
      self.quizzes = quizzes
    },
    clearTopic() {
      self.selectedTopic = null
    },
    selectTopic(topic: typeof self.selectedTopic) {
      self.selectedTopic = cast(topic)
    },
    setTopicSearchTerm(searchTerm: string) {
      self.topicSearchTerm = searchTerm
    },
    setContentPacks(packs: typeof self.contentPacks) {
      self.contentPacks = packs
    },
    setAllResources(resources: typeof self.allResources) {
      self.allResources = resources
    },
    setQuizSearchTerm(term: string) {
      self.quizSearchTerm = term
    },
  }))
  .views((self) => ({
    get environment() {
      return getEnv(self) as Environment
    },
    get uniqueQuizzes() {
      return Object.values(_groupBy(self.quizzes, 'quiz_id')).map((group: any) =>
        group.reduce((acc, quiz) =>
          acc?.metdata?.content_user_id > quiz?.metdata?.content_user_id ? quiz : acc,
        ),
      )
    },
    get isLoadingQuizzes() {
      return self.quizLoadStatus === 'pending'
    },
    get isLoadingResources() {
      return self.resourceLoadStatus === 'pending'
    },
    get isLoadingTopics() {
      return self.topicLoadStatus === 'pending'
    },
    get isLoading() {
      return self.status === 'pending'
    },
    get isLoadingAssessments() {
      return self.status === 'pending-assessments'
    },
    get flattenedTopics() {
      const flattened = [] as any
      self.topics.forEach((topic: any) => {
        const temp = { ...topic, ...topic.topic }
        delete temp.topic
        flattened.push(temp)
      })
      return flattened.filter((topic: any) => topic.topic_id !== null)
    },
    get filteredPacks() {
      const searchTerm = self.packSearchTerm
      if (!searchTerm) {
        return self.contentPacks
      }
      const filtered = self.contentPacks.filter((pack: any) =>
        pack.title.toLocaleLowerCase().includes(searchTerm.toLocaleLowerCase()),
      )
      return filtered ?? []
    },
    get filteredQuizzes() {
      const searchTerm = self.quizSearchTerm
      if (!searchTerm) {
        return self.quizzes
      }
      return self.quizzes.filter((assignedQuiz) =>
        assignedQuiz.title.toLocaleLowerCase().includes(searchTerm.toLocaleLowerCase()),
      ) as typeof self.quizzes
    },
    get filteredTopics() {
      const searchTerm = self.topicSearchTerm
      if (!searchTerm) {
        return self.topics
      }
      return self.topics.filter((assignedTopic) =>
        assignedTopic.topic.title.toLocaleLowerCase().includes(searchTerm.toLocaleLowerCase()),
      ) as typeof self.topics
    },
    get topicsSansGeneral() {
      return self.topics
        .slice()
        .filter((t) => t.topic.topic_id !== null)
        .sort((a, b) => {
          const compareLocked = Number(a.locked_by_pre_test) - Number(b.locked_by_pre_test)
          const compareCompletion = a.topic.quizzes_completed - b.topic.quizzes_completed
          const compareKnowledgePct = Number(a.knowledge_pct) - Number(b.knowledge_pct)

          // Note: Sorting Priority governened by order of return statement
          return compareLocked || compareKnowledgePct || compareCompletion
        })
        .sort((a, b) => {
          if (a.topic.quizzes_completed === b.topic.quizzes_completed) {
            return a.topic.title?.localeCompare(b.topic.title)
          }
          return a.topic.quizzes_completed > b.topic.quizzes_completed
            ? -1
            : a.topic.quizzes_completed === b.topic.quizzes_completed
              ? 0
              : 1
        })
    },
  }))
  .actions((self) => ({
    getTopicsForUser: flow(function* (id: number) {
      self.setTopicLoadStatus('pending')
      try {
        const result: Awaited<ReturnType<typeof UserApi.getUserTopics>> =
          yield UserApi.getUserTopics(id)
        if (result.ok && result.data) {
          const generalTopic = {
            locked_by_pre_test: false,
            knowledge_pct: 0,
            due_date: null,
            end_date: null,
            assignment_id: 0,
            topic: {
              topic_id: null,
              photo: null,
              title: 'General',
              quiz_count: 0,
              quizzes_completed: 0,
              pack_color: '',
            },
            certification_enabled: false,
            user_cert_level: null,
            metadata: {
              assignment_id: 0,
              content_user_id: 0,
              path_id: null,
              quiz_id: null,
              topic_id: null,
            },
          }
          const formatted = result.data.map((topic) => {
            if (topic.due_date && topic.end_date) {
              if (!topic.due_date && topic.end_date) {
                return { ...topic, due_date: topic.end_date }
              }
              const due = DateTime.fromISO(topic.due_date)
              const end = DateTime.fromISO(topic.end_date)
              if (due > end) {
                return { ...topic, due_date: topic.end_date }
              } else {
                return topic
              }
            }
            return topic
          })
          self.setTopics(cast([generalTopic, ...formatted]))
          self.setTopicLoadStatus('done')
          return [generalTopic, ...formatted]
        } else {
          self.setTopicLoadStatus('error')
        }
      } catch {
        self.setTopicLoadStatus('error')
      }
    }),
    getQuizzesForUser: flow(function* (id: number) {
      self.setQuizLoadStatus('pending')
      try {
        const result: Awaited<ReturnType<typeof UserApi.getUserQuizzes>> =
          yield UserApi.getUserQuizzes(id)
        if (result.ok && result.data) {
          const formatted = result.data.map((quiz) => {
            let unlocks_after: any
            if (quiz.quiz_locked) {
              const found = result.data?.find((q) => q.quiz_id === quiz.prerequisite_quiz_id)
              unlocks_after = found?.title
            }

            if (!quiz.due_date && quiz.end_date) {
              return { ...quiz, unlocks_after, due_date: quiz.end_date }
            }
            if (quiz.due_date && quiz.end_date) {
              const due = DateTime.fromISO(quiz.due_date)
              const end = DateTime.fromISO(quiz.end_date)
              if (due > end) {
                return { ...quiz, unlocks_after, due_date: quiz.end_date }
              } else {
                return { ...quiz, unlocks_after }
              }
            }
            return { ...quiz, unlocks_after }
          })

          self.setQuizzes(
            cast(
              formatted.map((q) => {
                return {
                  ...q,
                  adaptive: false,
                }
              }),
            ),
          )
          self.setQuizLoadStatus('done')
          return formatted
        } else {
          self.setQuizLoadStatus('error')
        }
      } catch (e) {
        self.setQuizLoadStatus('error')
      }
    }),
    getResourcesForUser: flow(function* (id: number) {
      self.setResourceLoadStatus('pending')
      try {
        const result: Awaited<ReturnType<typeof UserApi.getUserResources>> =
          yield UserApi.getUserResources(id)

        if (result.ok && result.data) {
          self.setAllResources(cast(result.data))
          self.setResourceLoadStatus('done')
          return result.data
        } else {
          self.setStatus('error')
        }
      } catch {
        self.setStatus('error')
      }
    }),
    getTopicDetail: flow(function* (user_id: number, metadata) {
      self.setStatus('pending')
      try {
        const result: Awaited<ReturnType<typeof TopicApi.getTopicDetailForUser>> =
          yield TopicApi.getTopicDetailForUser(user_id, metadata)

        if (result.ok && result.data) {
          let formatted = { ...result.data, topic_id: Number(metadata.topic_id) }
          if (!result.data?.due_date && result.data?.end_date) {
            formatted = {
              ...result.data,
              topic_id: Number(metadata.topic_id),
              due_date: result.data?.end_date,
            }
          } else if (result.data?.due_date && result.data?.end_date) {
            const due = DateTime.fromISO(result.data?.due_date)
            const end = DateTime.fromISO(result.data?.end_date)
            if (due > end) {
              formatted = {
                ...result.data,
                due_date: result.data?.end_date,
                topic_id: Number(metadata.topic_id),
              }
            }
          } else {
            formatted = { ...result.data, topic_id: Number(metadata.topic_id) }
          }

          self.selectTopic({ ...formatted, topic_id: metadata.topic_id })
          self.selectedTopic?.enrichData()

          const q = [] as any

          if (self.selectedTopic?.content_order && self.selectedTopic.keep_item_order) {
            self.selectedTopic?.content_order?.map((o, index) => {
              const found = self.selectedTopic?.quizzes.find(
                (quiz) => quiz.quiz_id === o.object_id,
              ) as any

              if (!found) {
                return
              }

              if (index > 0) {
                const found2 = self.selectedTopic?.quizzes.find(
                  (quiz) =>
                    quiz.quiz_id ===
                    (self.selectedTopic?.content_order &&
                      self.selectedTopic?.content_order[index - 1].object_id),
                ) as any

                if (found2.completed_attempts === 0) {
                  found.set('unlocks_after', found2.title)
                }
              }
              q.push(found)
            })

            self.selectTopic({ ...formatted, quizzes: q, topic_id: metadata.topic_id })
            self.selectedTopic?.enrichData()
          } else {
            self.selectTopic({ ...formatted, topic_id: metadata.topic_id })
            self.selectedTopic?.enrichData()
          }

          self.setStatus('done')
          return formatted
        } else {
          self.setStatus('error')
        }
      } catch (e) {
        self.setStatus('error')
        return e
      }
    }),
    getAssessments: flow(function* (user_id: number) {
      self.setStatus('pending-assessments')
      try {
        const result: Awaited<ReturnType<typeof TopicApi.getAssessments>> =
          yield TopicApi.getAssessments(user_id)

        if (result.ok && result.data) {
          self.setAssessments(cast(result.data))
          self.setStatus('done')
          return result.data
        } else {
          self.setStatus('error')
        }
      } catch {
        self.setStatus('error')
      }
    }),
    getContentPackDetails: flow(function* (
      user_id: number,
      pack_id: any,
      vendor?: string,
      all?: any,
    ) {
      !all && self.setStatus('pending')
      try {
        const result = yield self.environment.api.getContentPackDetails(user_id, pack_id, vendor)
        if (result.kind === 'ok') {
          if (pack_id === 3) {
            self.setAllAccessExpiry(result.data.expiration_date)
          }
          self.setStatus('done')
          return result.data
        } else {
          self.setStatus('error')
        }
      } catch {
        self.setStatus('error')
      }
    }),
    getAHAContentPacksForUser: flow(function* (id: number, vendor?: string) {
      self.setStatus('pending')
      try {
        const result = yield self.environment.api.getAHAContentPacksForUser(id, vendor)
        if (result.kind === 'ok') {
          const allAccess = result.data.find((pack: any) => pack.sku === 'com.trivie.aha.all')
          self.setSubscribedAll(allAccess.is_subscribed)
          self.setContentPacks(result.data)
          self.setStatus('done')
        } else {
          self.setStatus('error')
        }
      } catch {
        self.setStatus('error')
      }
    }),
  }))
