import { types } from 'mobx-state-tree'

export const Assessment = types.model('Assessment').props({
  assessment_quiz_id: types.number,
  assessment_title: types.string,
  assessment_type: types.string,
  assignment_due_date: types.maybe(types.maybeNull(types.string)),
  assignment_end_date: types.maybeNull(types.string),
  assignment_id: types.number,
  topic_id: types.number,
  topic_image_url: types.maybe(types.string),
})
