import React, { useEffect, useState } from 'react'
import { DateTime } from 'luxon'
import { observer } from 'mobx-react-lite'
import { TextField } from '@mui/material'
import { useStores } from '@trivie/core'
import { Dialog } from '@trivie/ui-web/components/dialogs/dialog'
import { Text } from '@trivie/ui-web/components/text'

const Details = observer((props: any) => {
  const { dates, setDates } = props
  const [touched, setTouched] = useState({
    start_date: false,
    start_time: false,
    end_date: false,
    end_time: false,
  })

  const validate = (data: any) => {
    const errors: any = {}

    if (!data.start_date) {
      errors.start_date = {
        type: 'required',
        message: 'Start Date is required',
      }
    }

    if (!data.start_time) {
      errors.start_time = {
        type: 'required',
        message: 'Start Time is required',
      }
    }

    if (!data.end_date) {
      errors.end_date = {
        type: 'required',
        message: 'End Date is required',
      }
    }

    if (!data.end_time) {
      errors.end_time = {
        type: 'required',
        message: 'End Time is required',
      }
    }

    // no need to validate beyond this since we're missing required fields
    if (Object.keys(errors).length) {
      return errors
    }

    const startDateTime = DateTime.fromISO(`${data.start_date}T${data.start_time}:00`)
    const endDateTime = DateTime.fromISO(`${data.end_date}T${data.end_time}:00`)

    if (!startDateTime.isValid) {
      return {
        start_date: {
          type: 'invalid',
          message: 'Date is Invalid',
        },
      }
    }

    if (!endDateTime.isValid) {
      return {
        end_date: {
          type: 'invalid',
          message: 'Date is Invalid',
        },
      }
    }

    let validationError: any

    if (endDateTime < startDateTime) {
      validationError = 'startTimeAfterEndTime'
    }

    if (validationError) {
      errors.start_date = {
        type: 'startTimeAfterEndTime',
        message: 'Must start before End Date and Time',
      }
      errors.start_time = {
        type: 'startTimeAfterEndTime',
      }
    }

    return errors
  }

  let startTimeMax = '23:59'
  if (
    DateTime.fromISO(dates.start_date).toFormat('yyyy-MM-dd') ===
    DateTime.fromISO(dates.end_date).toFormat('yyyy-MM-dd')
  ) {
    startTimeMax = DateTime.fromISO(`${dates.end_date}T${dates.end_time}:00`).toFormat('HH:mm')
  }
  let endTimeMin = '00:00'
  if (
    DateTime.fromISO(dates.start_date).toFormat('yyyy-MM-dd') ===
    DateTime.fromISO(dates.end_date).toFormat('yyyy-MM-dd')
  ) {
    endTimeMin = DateTime.fromISO(`${dates.start_date}T${dates.start_time}:00`).toFormat('HH:mm')
  }

  const errors = validate(dates)

  return (
    <div
      style={{
        width: '100%',
        display: 'flex',
        justifyContent: 'space-between',
        flexDirection: 'column',
      }}
    >
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'flex-start',
          width: '100%',
        }}
      >
        <form
          style={{
            justifyContent: 'space-evenly',
            width: '100%',
          }}
        >
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              width: '100%',
            }}
          >
            <TextField
              error={!!errors.start_date}
              helperText={errors.start_date?.message}
              variant="outlined"
              label="Start Date"
              {...{
                inputProps: {
                  min: DateTime.local().toFormat('yyyy-MM-dd'),
                  max: DateTime.fromISO(dates.end_date).toFormat('yyyy-MM-dd'),
                },
              }}
              size="small"
              style={{
                width: '100%',
                marginRight: 8,
                marginBottom: 32,
                display: 'flex',
              }}
              type="date"
              onChange={(e) => {
                if (!e.target.value) {
                  return
                }

                setDates({
                  ...dates,
                  start_date: e.target.value,
                })
                setTouched({ ...touched, start_date: true })
              }}
              onBlur={() => {
                setTouched({ ...touched, start_date: true })
              }}
              value={dates.start_date}
            />
            <TextField
              error={!!errors.start_time}
              type="time"
              size="small"
              variant="outlined"
              {...{
                inputProps: {
                  max: startTimeMax,
                },
              }}
              style={{ width: '100%', marginLeft: 8 }}
              onChange={(e) => {
                setDates({
                  ...dates,
                  start_time: e.target.value,
                })
                setTouched({ ...touched, start_time: true })
              }}
              onBlur={() => {
                setTouched({ ...touched, start_time: true })
              }}
              value={dates.start_time}
            />
            <Text
              style={{ padding: 8, paddingRight: 0 }}
              variant="body1"
              text={Intl.DateTimeFormat().resolvedOptions().timeZone}
            />
          </div>

          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              width: '100%',
            }}
          >
            <TextField
              error={touched.end_date && !!errors.end_date}
              helperText={touched.end_date && errors.end_date?.message}
              variant="outlined"
              label="End Date"
              {...{
                inputProps: {
                  min: DateTime.fromISO(dates.start_date).toFormat('yyyy-MM-dd'),
                },
              }}
              size="small"
              style={{ width: '100%', marginRight: 8 }}
              type="date"
              onChange={(e) => {
                setTouched({ ...touched, end_date: true })

                if (!e.target.value) {
                  return
                }

                setDates({
                  ...dates,
                  end_date: e.target.value,
                })
              }}
              onBlur={() => {
                setTouched({ ...touched, end_date: true })
              }}
              value={dates.end_date}
            />

            <TextField
              error={!!errors.end_time}
              type="time"
              size="small"
              variant="outlined"
              {...{
                inputProps: {
                  min: endTimeMin,
                },
              }}
              style={{ width: '100%', marginLeft: 8 }}
              onChange={(e) => {
                setDates({
                  ...dates,
                  end_time: e.target.value,
                })

                setTouched({ ...touched, end_time: true })
              }}
              onBlur={() => {
                setTouched({ ...touched, end_time: true })
              }}
              value={dates.end_time}
            />
            <Text
              style={{ padding: 8, paddingRight: 0 }}
              variant="body1"
              text={Intl.DateTimeFormat().resolvedOptions().timeZone}
            />
          </div>
        </form>
      </div>
    </div>
  )
})

export const SurveySchedule = observer((props: { open: boolean; requestClose: any }) => {
  const { surveyStore } = useStores()
  const { selectedSurvey, updateSurveyDates, getSurvey } = surveyStore
  const { open } = props
  const [dates, setDates] = useState<any>({})

  useEffect(() => {
    if (open) {
      setDates({
        start_date: selectedSurvey?.start_datetime
          ? DateTime.fromISO(selectedSurvey?.start_datetime).toFormat('yyyy-MM-dd')
          : DateTime.local().toFormat('yyyy-MM-dd'),
        start_time: selectedSurvey?.start_datetime
          ? DateTime.fromISO(selectedSurvey?.start_datetime).toLocaleString(DateTime.TIME_24_SIMPLE)
          : DateTime.now().toLocaleString(DateTime.TIME_24_SIMPLE),
        end_date: selectedSurvey?.end_datetime
          ? DateTime.fromISO(selectedSurvey?.end_datetime).toFormat('yyyy-MM-dd')
          : DateTime.local().plus({ days: 30 }).toFormat('yyyy-MM-dd'),
        end_time: selectedSurvey?.end_datetime
          ? DateTime.fromISO(selectedSurvey?.end_datetime).toLocaleString(DateTime.TIME_24_SIMPLE)
          : '23:59',
      })
    }
  }, [open])

  const handleUpdateDates = () => {
    if (selectedSurvey && selectedSurvey.id) {
      const data = {
        id: selectedSurvey.id,
        title: selectedSurvey.title,
        start_datetime: DateTime.fromFormat(
          `${dates.start_date} ${dates.start_time}`,
          'yyyy-MM-dd hh:mm',
        )
          .toUTC()
          .toISO(),
        end_datetime: DateTime.fromFormat(`${dates.end_date} ${dates.end_time}`, 'yyyy-MM-dd hh:mm')
          .toUTC()
          .toISO(),
      }
      updateSurveyDates(data).then(() => {
        if (selectedSurvey.id) {
          getSurvey(selectedSurvey.id)
        }
        props.requestClose()
      })
    }
  }

  return (
    <Dialog
      maxWidth="sm"
      onCancel={props.requestClose}
      onClose={props.requestClose}
      title="Edit Scheduled Dates"
      open={open}
      confirmBtnLabel="Save"
      onConfirm={handleUpdateDates}
    >
      <Details dates={dates} setDates={setDates} />
    </Dialog>
  )
})
