import { types } from 'mobx-state-tree'
import { QuestionPctCorrectSummary } from '../QuestionPctCorrectSummary'
import { StatisticalHistory } from '../StatisticalHistory'
import { QuestionType } from '../QuestionType'
import { GetResourceRead } from './resource'

export const GetQuizPctCorrectSummary = types.model('GetQuizPctCorrectSummary').props({
  quiz_id: types.number,
  quiz_title: types.string,
  pct_correct: types.number,
})

export const GetQuizQuestion = types.model('GetQuizQuestion').props({
  question_id: types.number,
  text: types.string,
  total_answers: types.number,
  knowledge_gain: types.number,
  pct_correct: types.number,
  avg_time: types.number,
})

export const GetQuiz = types.model('GetQuiz').props({
  quiz_id: types.number,
  title: types.string,
  number_of_questions: types.number,
  pct_correct: types.maybeNull(types.number),
  knowledge_gain: types.maybeNull(types.number),
  last_completed: types.maybeNull(types.string),
})

export const GetQuizOverview = types.model('GetQuizOverview').props({
  quiz_id: types.number,
  title: types.string,
  starting_knowledge: types.number,
  current_knowledge: types.number,
  knowledge_gain: types.number,
  stats_history: types.array(StatisticalHistory),
})

export const GetQuizQuestionKnowledgeGap = types.model('GetQuizQuestionKnowledgeGap').props({
  quiz_id: types.number,
  questions: types.maybeNull(types.array(QuestionPctCorrectSummary)),
})

export const GetRelatedGroupsForQuiz = types.model('GetRelatedGroupsForQuiz').props({
  group_id: types.number,
  label: types.string,
  number_users: types.number,
})

export const GetRelatedAssignmentsForQuiz = types.model('GetRelatedAssignmentsForQuiz').props({
  assignment_id: types.number,
  title: types.string,
  number_topics: types.number,
})

export const GetQuestionsForQuiz = types.model('GetQuestionsForQuiz').props({
  quiz_question_ids: types.array(types.number),
})

export const GetQuizUserStat = types.model('GetQuizUserStat').props({
  user_id: types.number,
  display_name: types.string,
  medal_name: types.maybeNull(types.string),
  recall: types.maybeNull(types.number),
  email: types.string,
  mastery: types.maybeNull(types.number),
  total_answers: types.maybeNull(types.number),
  pct_correct: types.maybeNull(types.number),
  total_points: types.maybeNull(types.number),
  rank: types.maybeNull(types.number),
  tag_count: types.maybeNull(types.number),
  last_active: types.maybeNull(types.string),
})

const FactoidFull = types.model('FactoidFull').props({
  id: types.number,
  text: types.maybeNull(types.string),
  resource: types.frozen(), // we don't have factoid resources so I'm just leaving this as frozen for now
})

const ChoiceFull = types.model('ChoiceFull').props({
  id: types.number,
  text: types.string,
  correct: types.boolean,
  order: types.maybeNull(types.number),
  is_deleted: types.boolean,
})

const QuestionFull = types.model('QuestionFull').props({
  id: types.number,
  adaptive_eligible: types.boolean,
  difficulty: types.number,
  is_archived: types.boolean,
  is_deleted: types.boolean,
  is_locked: types.boolean,
  last_modified: types.string,
  text: types.string,
  image: types.maybeNull(types.string),
  alt_text: types.maybeNull(types.string),
  question_type: QuestionType,
  choices: types.array(ChoiceFull),
  factoid: types.maybeNull(FactoidFull),
})

export const GetFullQuiz = types.model('GetFullQuiz').props({
  id: types.number,
  description: types.maybeNull(types.string),
  is_archived: types.boolean,
  is_challenge: types.boolean,
  is_deleted: types.boolean,
  is_locked: types.boolean,
  is_reinforcement: types.boolean,
  is_test: types.boolean,
  is_lesson_quiz: types.boolean,
  title: types.string,
  item_ordering: types.maybeNull(types.array(types.number)),
  questions: types.array(QuestionFull),
})

export const GetLimitStatus = types.model('GetLimitStatus').props({
  active_quizzes_limit_reached: types.maybeNull(types.boolean),
  respondent_limit_reached: types.maybeNull(types.boolean),
  source_upload_limit_reached: types.maybeNull(types.boolean),
  owner_display_name: types.string,
  owner_email: types.string,
})
