import React, { useState } from 'react'
import { InviteUserForm } from '@trivie/core/src/models/InviteUser'
import { DateTime } from 'luxon'
import { Instance } from 'mobx-state-tree'
import { observer } from 'mobx-react-lite'
import { color } from '../../../theme/color'
import { Text } from '../../text'
import { TextField } from '../../text-field'
import { Switch } from '../../switch/switch'

interface UserScheduleProps {
  form: Instance<typeof InviteUserForm>
}

export const UserSchedule = observer((props: UserScheduleProps) => {
  const { form } = props

  const [touched, setTouched] = useState({
    start_date: false,
    start_time: false,
    send_sms: false,
    send_email: false,
  })

  return (
    <>
      <div style={{ marginBottom: 32, display: 'flex', justifyContent: 'space-between' }}>
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <Text variant="body1Medium" text="Send Email Invite" style={{ marginBottom: 8 }} />
          <Text
            variant="body2Medium"
            text="Send an invite email at a specific date and time."
            style={{ color: color.shade50 }}
          />
        </div>
        <Switch
          color="success"
          onChange={(e) => form.set('send_email', e.target.checked)}
          checked={form.send_email}
        />
      </div>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          marginBottom: form.send_sms ? 4 : 32,
        }}
      >
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <Text variant="body1Medium" text="Send SMS Invite" style={{ marginBottom: 8 }} />
          <Text
            variant="body2Medium"
            text="Send an invite text message at a specific date and time."
            style={{ color: color.shade50 }}
          />
        </div>
        <Switch
          color="success"
          onChange={(e) => form.set('send_sms', e.target.checked)}
          checked={form.send_sms}
        />
      </div>
      {form.send_sms && (
        <Text
          variant="body2"
          text="By selecting this option, you affirm that your users have consented to receive SMS messages."
          style={{ color: '#FF7324', marginBottom: 32 }}
        />
      )}
      {(form.send_email || form.send_sms) && (
        <div style={{ marginBottom: 32 }}>
          <Text variant="body1" text="Send On" style={{ marginBottom: 12 }} />
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <TextField
              error={!!form.errors.start_date}
              helperText={form.errors.start_date?.message}
              variant="outlined"
              {...{ inputProps: { min: DateTime.local().toFormat('yyyy-MM-dd') } }}
              size="small"
              style={{ marginRight: 8, display: 'flex', width: '100%' }}
              type="date"
              onChange={(e) => {
                if (!e.target.value) {
                  return
                }
                setTouched({ ...touched, start_date: true })
                form.set('start_date', e.target.value)
              }}
              onBlur={() => setTouched({ ...touched, start_date: true })}
              value={form.start_date}
            />
            <TextField
              error={!!form.errors.start_time}
              type="time"
              size="small"
              variant="outlined"
              style={{ width: '100%' }}
              InputLabelProps={{ shrink: true }}
              onChange={(e) => {
                if (!e.target.value) {
                  return
                }
                setTouched({ ...touched, start_time: true })
                form.set('start_time', e.target.value)
              }}
              onBlur={() => setTouched({ ...touched, start_time: true })}
              value={form.start_time}
            />
            <Text
              style={{ padding: 8, paddingRight: 0, color: color.shade50 }}
              variant="body1"
              text={Intl.DateTimeFormat().resolvedOptions().timeZone}
            />
          </div>
        </div>
      )}
    </>
  )
})
