import { ApiResponse } from 'apisauce'
import { cast, Instance } from 'mobx-state-tree'

import { ContentCreationApi } from '../services/api-objects/ContentCreationApi'
import { QuestionApi } from '../services/api-objects/QuestionApi'
import { isQuestion } from '../type-guards/isQuestion'
import { Question, questionErrors } from '../models/Question'
import { ContentCreation } from '../models/ContentCreation'
import { QuestionContentCreatePayload } from '../models/QuestionContentCreatePayload'
import { QuestionChoiceHelper } from './QuestionChoiceHelper'
import { getChoicesByQuestionType } from '../utils/getChoicesByQuestionType'
import { QuestionType } from '../models/QuestionType'

let rootStore
export class QuestionHelper {
  static setRootStore = (store: any) => {
    rootStore = store
  }

  static createOrModifyQuestions = async (questions: Array<any>) => {
    if (!questions.length) {
      return
    }

    const massagedQuestions = [...questions]

    await Promise.all(
      massagedQuestions.map(async (question: any) => {
        return QuestionHelper.createOrModifyQuestion(question)
      }),
    )
  }

  static createOrModifyQuestion = async (question: any) => {
    const validationResult = QuestionHelper.validateQuestion(question)
    if (!validationResult.isValid) {
      return validationResult
    }

    const questionId = question.id ?? question.question_id

    if (!question.factoid?.text && !question.factoid?.resource_id) {
      delete question.factoid
    } else if (!question.factoid.resource_id) {
      // assume we have text since there's no way to set resource_id right now
      question.factoid.resource_id = null
    }

    const isNewQuestion = typeof questionId === 'string' && questionId.includes('temp')
    if (isNewQuestion) {
      question.id = await rootStore!.questionStore.createQuestion(question)
    } else {
      await rootStore!.questionStore.updateQuestion(question)
    }

    if (question.image && typeof question.image !== 'string') {
      await rootStore!.questionStore.changePhotoForQuestion(
        question.id ?? question.question_id,
        question.image,
      )
      await rootStore!.questionStore.setAltText(
        question.id ?? question.question_id,
        question.alt_text,
      )
    } else if (!question.image) {
      await rootStore!.questionStore.removePhotoFromQuestion(question.id ?? question.question_id)
    }

    return true
  }

  static validateQuestions = (questions: any) => {
    return questions.map((q: any) => {
      return QuestionHelper.validateQuestion(q)
    })
  }

  static validateQuestion = (question: any) => {
    const errors: Array<string> = []

    if (!question.text) {
      errors.push('no text')
    }

    const hasChoices =
      question.choices?.length &&
      question.choices.every((c: any) => {
        return !!c.text
      })

    if (!hasChoices) {
      errors.push('no valid choices')
    }

    return {
      isValid: errors.length === 0,
      errors,
    }
  }

  static loadQuestion = async (id: number) => {
    const questionResponse = await QuestionApi.getQuestion(id)

    if (!questionResponse.ok || !questionResponse.data) {
      throw new Error('Failed to loadQuestion')
    }

    return Question.create({
      advanced: Boolean(questionResponse.data.answers_summary?.some((choice) => choice.factoid)),
      adaptive_eligible: questionResponse.data.adaptive_eligible,
      order: questionResponse.data.order,
      difficulty: questionResponse.data.difficulty ?? 0,
      factoid: questionResponse.data.factoid ?? null,
      is_locked: false,
      question_id: questionResponse.data.question_id,
      question_type: questionResponse.data.question_type,
      choices: questionResponse.data.answers_summary,
      text: questionResponse.data.text,
      alt_text: questionResponse.data.alt_text ?? '',
      image_src: questionResponse.data.image_url ?? '',
      keep_answer_order: questionResponse.data.keep_answer_order ?? false,
      approved: true,
      external_id: questionResponse.data.external_id ?? '',
    })
  }

  /**
   * Creates a new question with defaults.
   *
   * @param questionType Optional question type to create
   */
  static createNewQuestion = (questionType?: Instance<typeof QuestionType>, init?: any) => {
    return Question.create({
      advanced: false,
      order: 0,
      adaptive_eligible: true,
      difficulty: 0,
      factoid: null,
      is_locked: false,
      question_id: null,
      question_type: questionType ?? 'Multiple Choice',
      choices: getChoicesByQuestionType(questionType ?? 'Multiple Choice'),
      text: '',
      alt_text: '',
      image_src: null,
      keep_answer_order: false,
      fact_id: null,
      document_id: null,
      generated_question_id: null,
      touched: false,
      ...init,
    })
  }

  static objectsToQuestions(questionObjects: Array<any>): Array<Instance<typeof Question>> {
    if (!Array.isArray(questionObjects)) {
      console.warn('QuestionHelper.objectsToQuestions was passed a non array arg', questionObjects)
      return [] as Array<Instance<typeof Question>>
    }
    if (!questionObjects || questionObjects.length === 0) {
      console.warn(
        'QuestionHelper.objectsToQuestions was passed a null or 0 length array',
        questionObjects,
      )
      return [] as Array<Instance<typeof Question>>
    }
    return questionObjects.map((q: any) => {
      if (isQuestion(q)) {
        return q
      } else {
        return Question.create({
          // @ts-ignore
          ...q,
          approved: true,
          touched: true,
        })
      }
    })
  }

  static validate = async (question: Instance<typeof Question>, hardValidate = false) => {
    const validateResponse: ApiResponse<Instance<typeof ContentCreation>> =
      await ContentCreationApi.validateContent({ question: [question.toPayload()] }, hardValidate)
    if (validateResponse.ok && validateResponse.data?.question) {
      QuestionHelper.collectErrors(validateResponse.data.question[0], question)
      QuestionHelper.collectWarnings(validateResponse.data.question[0], question)
    }
  }

  static publishImage = async (q: Instance<typeof Question>) => {
    if (q.question_id && q.imageFile) {
      const response = await QuestionApi.publishImage(q.question_id, q.imageFile)
      return response.ok
    } else if (q.image) {
      // do nothing
    } else if (q.question_id && q.imageChanged) {
      const response = await QuestionApi.removeImage(q.question_id)
      return response.ok
    }
  }

  static removeImage = async (id: number) => {
    const response = await QuestionApi.removeImage(id)

    return response.ok
  }

  static collectErrors = (
    responseQuestion: Instance<typeof QuestionContentCreatePayload>,
    question: Instance<typeof Question>,
  ) => {
    if (responseQuestion.question_id) {
      question.set('question_id', responseQuestion.question_id)
    }

    question.set('errorsCorrected', [])

    let hasErrors = false
    if (
      responseQuestion.errors?.length &&
      !responseQuestion.errors.every((e) => e.includes('an active assignment')) &&
      !responseQuestion.errors.every((e) => e.includes('is not between 1 and 1000 characters'))
    ) {
      hasErrors = true
      question.set('errors', responseQuestion.errors)
    } else {
      question.set('errors', [])
    }

    if (question.choices?.length && responseQuestion.choices?.length) {
      responseQuestion.choices.forEach((c, i) => {
        const indexedChoice = question.choices![i]
        if (indexedChoice) {
          hasErrors = QuestionChoiceHelper.collectErrors(cast(c), indexedChoice) || hasErrors
        }
      })
    }

    return hasErrors
  }

  static collectWarnings = (
    responseQuestion: Instance<typeof QuestionContentCreatePayload>,
    question: Instance<typeof Question>,
  ) => {
    let hasWarnings = false

    if (question.choices?.length && responseQuestion.choices?.length) {
      responseQuestion.choices.forEach((c, i) => {
        const indexedChoice = question.choices![i]
        if (indexedChoice) {
          hasWarnings = QuestionChoiceHelper.collectWarnings(cast(c), indexedChoice) || hasWarnings
        }
      })
    }

    return hasWarnings
  }

  static hasUncorrectedErrors = (
    question: Instance<typeof Question>,
    correctedErrorKeys: Array<string>,
  ) => {
    if (!question.errors?.length) {
      return false
    }

    const questionErrorKeys = Object.keys(questionErrors)
    const correctedQuestionErrorKeys = correctedErrorKeys.filter((k) =>
      questionErrorKeys.includes(k),
    ) as Array<keyof typeof questionErrors>

    // map error keys to problem texts
    const correctedErrorProblems = correctedQuestionErrorKeys.map((k) => questionErrors[k].problem)

    // check if any errors have not been corrected
    return question.errors.some((e) => {
      // if no corrected problems match the error then this error has not been corrected
      return !correctedErrorProblems.some((cep) => e.includes(cep))
    })
  }

  static isSafeToForcePublish = (question: Instance<typeof Question>) => {
    if (!question.errors?.length) {
      return true
    }
    if (question.hasError('duplicateCreateQuestion')) {
      return true
    }

    if (question.errors.length === 1 && question.hasError('activeAssignment')) {
      return true
    } else {
      return false
    }
  }
}
