import React, { useEffect, useState } from 'react'
import Box from '@mui/material/Box'
import { observer } from 'mobx-react-lite'

import { Button } from '../button'
import { color } from '../../theme'

interface ToggleProps {
  leftText: string
  rightText: string
  onLeftClick: () => void
  onRightClick: () => void
  defaultSelection?: string
}

export const Toggle = observer((props: ToggleProps) => {
  const { leftText, defaultSelection = leftText, rightText, onLeftClick, onRightClick } = props
  const [selected, setSelected] = React.useState<string>(defaultSelection)

  const [init, setInit] = useState(false)

  let leftWidth = document.getElementById('ToggleLeft')?.getBoundingClientRect().width || 0
  let rightWidth = document.getElementById('ToggleRight')?.getBoundingClientRect().width || 0

  useEffect(() => {
    if ((!init && document.getElementById('ToggleLeft'), document.getElementById('ToggleRight'))) {
      leftWidth = document.getElementById('ToggleLeft')?.getBoundingClientRect().width || 0
      rightWidth = document.getElementById('ToggleRight')?.getBoundingClientRect().width || 0
    }
    setInit(true)
  }, [document.getElementById('ToggleLeft'), document.getElementById('ToggleRight')])

  const onClick = () => {
    if (selected === leftText) {
      setSelected(rightText)
      onRightClick()
    } else {
      setSelected(leftText)
      onLeftClick()
    }
  }

  const leftStyle = {
    ...TOGGLE_BTN,
    paddingRight: '0px',
    paddingLeft: '8px',
    opacity: selected === leftText ? 0.8 : 1,
    color: selected === leftText ? color.blue : color.shade50,
  }

  const rightStyle = {
    ...TOGGLE_BTN,
    paddingRight: '8px',
    paddingLeft: '4px',
    color: selected === rightText ? color.blue : color.shade50,
    opacity: selected === rightText ? 0.8 : 1,
  }

  return (
    <Box onClick={onClick} sx={CONTAINER}>
      <Box
        style={{
          ...INDICATOR,
          transform: `translateX(${selected === leftText ? 0 : `${leftWidth + 4}px`})`,
          width: selected === leftText ? leftWidth : rightWidth - 4,
        }}
      />
      <Button
        id="ToggleLeft"
        disableRipple
        variant="text"
        sx={leftStyle}
        text={leftText}
      />
      <Button
        id="ToggleRight"
        disableRipple
        variant="text"
        sx={rightStyle}
        text={rightText}
      />
    </Box>
  )
})

const CONTAINER = {
  cursor: 'pointer',
  borderRadius: '10px',
  height: '32px',
  position: 'relative',
  background: '#fff',
  display: 'flex',
  gap: '8px',
  border: `1px solid ${color.shade10}`,
}
const INDICATOR: React.CSSProperties = {
  height: 24,
  borderRadius: 6,
  top: 3,
  left: 4,
  background: color.blue,
  opacity: 0.1,
  position: 'absolute',
  transition: 'all 0.22s ease',
}
const TOGGLE_BTN = {
  ':hover': {
    opacity: 0.6,
    backgroundColor: color.transparent,
  },
  minWidth: 0,
  height: '24px',
  borderRadius: '6px',
  top: '3px',
  padding: 0,
  transition: 'all 0.2s 0.1s ease',
}
