import { cast, flow, getEnv, Instance, types } from 'mobx-state-tree'

import { Environment } from '../environment'
import { AssignmentApi } from '../../services/api-objects/AssignmentApi'
import { AssignmentCreate, AssignmentResource } from '../response-models/assignment'

export const AssignmentStoreModel = types
  .model('AssignmentStore')
  .props({
    status: types.optional(types.enumeration(['idle', 'pending', 'done', 'error']), 'idle'),
    liveAssignments: types.optional(types.frozen(), []),
    scheduledAssignments: types.optional(types.frozen(), []),
    completedAssignments: types.optional(types.frozen(), []),
    selectedAssignment: types.frozen(),
    assignmentOverviewStats: types.frozen(),
    mastery: types.frozen(),
    knowledgeGain: types.frozen(),
    topics: types.frozen(),
    questions: types.frozen(),
    users: types.frozen(),
    selectedTopics: types.frozen(),
    resources: types.maybeNull(types.array(AssignmentResource)),
    addedUsers: types.frozen(),
    excludedUsers: types.frozen(),
    groups: types.frozen([]),
    checkedDisplay: types.frozen([]),
    checked: types.optional(types.frozen(), {}),
    checkedGroups: types.optional(types.frozen(), {}),
    conflict: types.frozen(),
    exportableReports: types.frozen(),
    triggerRefresh: types.frozen(),
  })
  .actions((self) => ({
    setStatus(value?: 'idle' | 'pending' | 'done' | 'error') {
      self.status = value || 'idle'
    },
    setTriggerRefresh(trigger) {
      self.triggerRefresh = trigger as any
    },
    setConflict(conflict: typeof self.conflict) {
      self.conflict = conflict
    },
    setChecked(topic_id: any, values: any) {
      self.checked = {
        ...self.checked,
        [topic_id]: values ?? [],
      }
    },
    setCheckedGroups(group_id: any, values: any[]) {
      self.checkedGroups = {
        ...self.checkedGroups,
        [group_id]: values ?? [],
      }
    },
    resetChecked() {
      self.checked = {}
      self.checkedGroups = {}
    },
    setResources(resources: typeof self.resources) {
      self.resources = resources
    },
    setLiveAssignments(assignments: typeof self.liveAssignments) {
      self.liveAssignments = assignments
    },
    setScheduledAssignments(assignments: typeof self.scheduledAssignments) {
      self.scheduledAssignments = assignments
    },
    setCompletedAssignments(assignments: typeof self.completedAssignments) {
      self.completedAssignments = assignments
    },
    setAssignment(id: typeof self.selectedAssignment) {
      self.selectedAssignment = id
    },
    setKnowledgeGain(knowledgeGain: typeof self.knowledgeGain) {
      self.knowledgeGain = knowledgeGain
    },
    setMastery(mastery: typeof self.mastery) {
      self.mastery = mastery
    },
    setTopics(topics: typeof self.topics) {
      self.topics = topics
    },
    setUsers(users: typeof self.users) {
      self.users = users
    },
    setQuestions(questions: typeof self.questions) {
      self.questions = questions
    },
    setOverview(assignmentOverviewStats: typeof self.assignmentOverviewStats) {
      self.assignmentOverviewStats = assignmentOverviewStats
    },
    setSelectedTopics(selectedTopics: typeof self.selectedTopics) {
      self.selectedTopics = selectedTopics
    },
    setAddedUsers(addedUsers: typeof self.addedUsers) {
      self.addedUsers = addedUsers
    },
    setExcludedUsers(excludedUsers: typeof self.excludedUsers) {
      self.excludedUsers = excludedUsers
    },
    setGroups(groups: typeof self.groups) {
      self.groups = groups
    },
    setExportableReports(exportableReports: typeof self.exportableReports) {
      self.exportableReports = exportableReports
    },
  }))
  .views((self) => ({
    get environment() {
      return getEnv(self) as Environment
    },
    get isLoading() {
      return self.status === 'pending'
    },
  }))
  .actions((self) => ({
    createAssignment: flow(function* (assignment: Instance<typeof AssignmentCreate>) {
      self.setStatus('pending')
      try {
        const result: Awaited<ReturnType<typeof AssignmentApi.createAssignment>> =
          yield AssignmentApi.createAssignment(assignment)
        if (result.ok) {
          self.setTriggerRefresh(true)
          self.setStatus('done')
        } else {
          self.setStatus('error')
        }
      } catch {
        self.setStatus('error')
      }
    }),
    deleteAssignments: flow(function* (assignments: Array<number>) {
      self.setStatus('pending')
      try {
        const result: Awaited<ReturnType<typeof AssignmentApi.deleteAssignments>> =
          yield AssignmentApi.deleteAssignments(assignments)
        if (result.ok) {
          self.setStatus('done')
        } else {
          self.setStatus('error')
        }
      } catch {
        self.setStatus('error')
      }
    }),
    endAssignment: flow(function* (id: number) {
      self.setStatus('pending')
      try {
        const result: Awaited<ReturnType<typeof AssignmentApi.endAssignment>> =
          yield AssignmentApi.endAssignment(id)
        if (result.ok) {
          self.setStatus('done')
        } else {
          self.setStatus('error')
        }
      } catch {
        self.setStatus('error')
      }
    }),
    addUsersToAssignment: flow(function* (userIds: Array<number>, assignmentId: number) {
      self.setStatus('pending')
      try {
        const result: Awaited<ReturnType<typeof AssignmentApi.addUsersToAssignment>> =
          yield AssignmentApi.addUsersToAssignment(userIds, assignmentId)
        if (result.ok) {
          self.setStatus('done')
        } else {
          self.setStatus('error')
        }
      } catch {
        self.setStatus('error')
      }
    }),
    nudge: flow(function* (id: number, message: string) {
      self.setStatus('pending')
      try {
        const result: Awaited<ReturnType<typeof AssignmentApi.nudge>> = yield AssignmentApi.nudge(
          id,
          message,
        )
        if (result.ok) {
          self.setStatus('done')
        } else {
          self.setStatus('error')
        }
      } catch {
        self.setStatus('error')
      }
    }),
    getResources: flow(function* (id: number) {
      self.setStatus('pending')
      try {
        const result: Awaited<ReturnType<typeof AssignmentApi.getResourcesForAssignment>> =
          yield AssignmentApi.getResourcesForAssignment(id)
        if (result.ok && result.data) {
          self.setResources(cast(result.data))
          self.setStatus('done')
        } else {
          self.setStatus('error')
        }
      } catch {
        self.setStatus('error')
      }
    }),
    getAssignmentDetails: flow(function* (id: number, start: string, end: string) {
      self.setStatus('pending')
      try {
        const result = yield self.environment.api.getAssignmentByID(id, start, end)
        if (result.kind === 'ok') {
          self.setOverview(result.data)
          self.setStatus('done')
        } else {
          self.setStatus('error')
        }
      } catch {
        self.setStatus('error')
      }
    }),
    getMastery: flow(function* (id: number, start: string, end: string) {
      self.setStatus('pending')
      try {
        const result = yield self.environment.api.getAssignmentMastery(id, start, end)
        if (result.kind === 'ok') {
          self.setMastery(result.data.mastery.toFixed(1))
          self.setStatus('done')
        } else {
          self.setStatus('error')
        }
      } catch {
        self.setStatus('error')
      }
    }),
    getAddedUsers: flow(function* (id: number) {
      self.setStatus('pending')
      try {
        const result = yield self.environment.api.getAddedUsersForAssignment(id)
        if (result.kind === 'ok') {
          self.setAddedUsers(result.data)
          self.setStatus('done')
        } else {
          self.setStatus('error')
        }
      } catch {
        self.setStatus('error')
      }
    }),
    getExcludedUsers: flow(function* (id: number) {
      self.setStatus('pending')
      try {
        const result = yield self.environment.api.getExcludedUsersForAssignment(id)
        if (result.kind === 'ok') {
          self.setExcludedUsers(result.data)
          self.setStatus('done')
        } else {
          self.setStatus('error')
        }
      } catch {
        self.setStatus('error')
      }
    }),
    excludeUsers: flow(function* (assignmentID: number, userIdsToExclude: any) {
      self.setStatus('pending')
      try {
        const result = yield self.environment.api.excludeUsersFromAssignment(
          assignmentID,
          userIdsToExclude,
        )
        if (result.kind === 'ok') {
          ;(self as any).getExcludedUsers(assignmentID)
          self.setStatus('done')
        } else {
          self.setStatus('error')
        }
      } catch {
        self.setStatus('error')
      }
    }),
    restoreExcludedUsers: flow(function* (assignmentID: number, userIds: any = []) {
      self.setStatus('pending')
      try {
        const result = yield self.environment.api.restoreAssignmentExcludedUsers(
          assignmentID,
          userIds,
        )
        if (result.kind === 'ok') {
          ;(self as any).getExcludedUsers(assignmentID)
          self.setStatus('done')
        } else {
          self.setStatus('error')
        }
      } catch {
        self.setStatus('error')
      }
    }),
    removeAddedUsers: flow(function* (assignmentID: number, userIds: any = []) {
      self.setStatus('pending')

      try {
        const result = yield self.environment.api.clearAssignmentAddedUsers(assignmentID, userIds)
        if (result.kind === 'ok') {
          ;(self as any).getAddedUsers(assignmentID)
          self.setStatus('done')
        } else {
          self.setStatus('error')
        }
      } catch {
        self.setStatus('error')
      }
    }),
    extendAssignment: flow(function* (assignmentID: any, endDateTime: any) {
      self.setStatus('pending')
      try {
        const result = yield self.environment.api.extendAssignment(assignmentID, endDateTime)
        if (result.kind === 'ok') {
          self.setStatus('done')
        } else {
          self.setStatus('error')
        }
      } catch {
        self.setStatus('error')
      }
    }),
    extendAssignments: flow(function* (assignmentIDs: any, endDateTime: any) {
      assignmentIDs.forEach((a: any) => (self as any).extendAssignment(a, endDateTime))
    }),
    startAssignmentsNow: flow(function* (assignmentIDs: Array<number>) {
      self.setStatus('pending')
      try {
        const result: Awaited<ReturnType<typeof AssignmentApi.startAssignmentsNow>> =
          yield AssignmentApi.startAssignmentsNow(assignmentIDs)
        if (result.ok) {
          self.setStatus('done')
        } else {
          self.setStatus('error')
        }
      } catch {
        self.setStatus('error')
      }
    }),
    getExportableReports: flow(function* () {
      self.setStatus('pending')

      try {
        const response = yield AssignmentApi.getExportableReports()
        if (response.ok && response.data) {
          response.data['base_reports'].sort((a: any, b: any): any =>
            a.title.toLowerCase().localeCompare(b.title.toLowerCase()),
          )
          response.data['custom_reports'].sort((a: any, b: any): any =>
            a.title.toLowerCase().localeCompare(b.title.toLowerCase()),
          )
          self.setExportableReports([
            ...response.data['custom_reports'],
            ...response.data['base_reports'],
          ])
          self.setStatus('done')
        } else {
          self.setStatus('error')
        }
      } catch {
        self.setStatus('error')
      }
    }),
  }))

type AssignmentStoreType = typeof AssignmentStoreModel.Type
export interface AssignmentStore extends AssignmentStoreType {}
type AssignmentStoreSnapshotType = typeof AssignmentStoreModel.SnapshotType
export interface AssignmentStoreSnapshot extends AssignmentStoreSnapshotType {}
