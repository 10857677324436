import React from 'react'
import { Error, Check, Info, Warning, AutorenewRounded } from '@mui/icons-material'
import { observer } from 'mobx-react-lite'

import { color } from '../../../theme/color'
import { Text } from '../../text'
import { Collapse } from '@mui/material'
import { ExpandMore } from '../../expand-button/expand-button'

interface StatusBarProps {
  type: 'info' | 'warning' | 'error' | 'success' | 'generating'
  message: string
  style?: React.CSSProperties
  expandable?: boolean
  children?: any
  forceClose?: any
  handleExpandClick?: any
  expanded?: boolean
}

const StatusBarIcon = (props: any) => {
  const { status } = props
  switch (status) {
    case 'generating':
      return (
        <AutorenewRounded
          style={{ height: 14, width: 14, marginRight: 12 }}
          sx={{
            animation: 'spin 3s linear infinite',
            '@keyframes spin': {
              '0%': {
                transform: 'rotate(-360deg)',
              },
              '100%': {
                transform: 'rotate(0deg)',
              },
            },
          }}
          htmlColor="#194FF0"
        />
      )
    case 'info':
      return <Info style={{ fontSize: 14, marginRight: 12 }} htmlColor="#194FF0" />
    case 'warning':
      return <Warning style={{ fontSize: 14, marginRight: 12 }} htmlColor="#FFB800" />
    case 'success':
      return <Check style={{ fontSize: 14, marginRight: 12 }} htmlColor="#33BF57" />
    default:
      return <Error style={{ fontSize: 14, marginRight: 12 }} htmlColor="#FF3E13" />
  }
}
export const StatusBar = observer((props: StatusBarProps) => {
  const { expanded, forceClose, handleExpandClick, expandable, message, type, style, children } =
    props

  const background =
    type === 'info' || type === 'generating'
      ? 'linear-gradient(0deg, rgba(25, 79, 240, 0.1), rgba(25, 79, 240, 0.1))'
      : type === 'warning'
        ? 'linear-gradient(0deg, rgba(255, 184, 0, 0.1), rgba(255, 184, 0, 0.1))'
        : type === 'success'
          ? 'linear-gradient(0deg, rgba(51, 191, 87, 0.1), rgba(51, 191, 87, 0.1))'
          : '#fcebe7'

  return (
    <>
      <div id="QuestionStatus" style={{ ...ROOT, ...style }}>
        <div
          style={{
            backgroundColor: '#fff',
            borderRadius: 10,
            borderBottomLeftRadius: expanded && expandable ? 0 : 10,
            borderBottomRightRadius: expanded && expandable ? 0 : 10,
          }}
        >
          <div
            style={{
              ...CONTAINER,
              background,
              borderBottomLeftRadius: expanded && expandable ? 0 : 10,
              borderBottomRightRadius: expanded && expandable ? 0 : 10,
            }}
          >
            <StatusBarIcon status={type} />
            <Text style={{ color: color.shade80 }} variant="body2Medium" text={message} />
            {expandable ? (
              <ExpandMore expand={Boolean(expanded)} onClick={handleExpandClick} />
            ) : (
              <div style={{ flex: 1 }} />
            )}
          </div>
        </div>
        {!forceClose && (
          <Collapse in={expanded} timeout="auto" unmountOnExit>
            <div
              style={{
                ...CONTENT,
                border:
                  children[0]?.length === 0 && children[1]?.length === 0
                    ? 'none'
                    : `1px solid ${color.shade5}`,
                borderTopWidth: 0,
              }}
            >
              {children}
            </div>
          </Collapse>
        )}
      </div>
    </>
  )
})

const ROOT: React.CSSProperties = { flexDirection: 'column', display: 'flex' }
const CONTAINER: React.CSSProperties = {
  alignItems: 'center',
  padding: 12,
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'row',
  borderRadius: 10,
  border: `1px inset ${color.shade5}`,
  width: '100%',
  height: 48,
}
const CONTENT: React.CSSProperties = {
  borderBottomLeftRadius: 10,
  borderBottomRightRadius: 10,
  backgroundColor: color.white,
}
