import React, { useEffect, useState } from 'react'
import { observer } from 'mobx-react-lite'
import { Box, CircularProgress } from '@mui/material'

import { ISelectGroupOptions, useStores } from '@trivie/core'
import { UserApi } from '@trivie/core/src/services/api-objects/UserApi'
import { Dialog } from '../dialogs/dialog'
import { UserGroupSelector } from '../user-group-selector'

export interface AddUsersProps {
  open: boolean
  onClose: () => void
}

export const AddUsersToGroup = observer((props: AddUsersProps) => {
  const { open, onClose } = props
  const { groupStore } = useStores()
  const { addUsers, getAddedGroupUsers, addedGroupUsers } = groupStore
  const [allUsers, setAllUsers] = useState<ISelectGroupOptions[]>([])
  const [selectedUsers, setSelectedUsers] = useState<ISelectGroupOptions[]>([])
  const [isLoading, setIsLoading] = useState(true)

  const handleSubmit = () => {
    addUsers({ user_ids: selectedUsers.map((u) => u.id) })
    onClose()
  }

  const handleChange = (users: ISelectGroupOptions[]) => setSelectedUsers(users)

  useEffect(() => {
    const opts: ISelectGroupOptions[] = []
    UserApi.getUsers(
      'users',
      false,
      [{ field: 'first_name' }, { field: 'last_name' }, { field: 'email' }, { field: 'photo_url' }],
      'list_users',
    )
      .then((resp) => {
        if (resp.ok && resp.data) {
          resp.data.rows.map((user) => {
            const first_name = user.columns.find((column) => column.field === 'first_name')
            const last_name = user.columns.find((column) => column.field === 'last_name')
            const email = user.columns.find((column) => column.field === 'email')
            const photo_url = user.columns.find((column) => column.field === 'photo_url')
            opts.push({
              type: 'Users',
              label: `${first_name?.value} ${last_name?.value}`,
              subLabel: email?.value,
              number_users: undefined,
              photo_url: photo_url.value,
              id: user.id,
            })
          })
        }
      })
      .finally(() => {
        getAddedGroupUsers().then((resp) => {
          setAllUsers(opts.filter((f) => !addedGroupUsers.map((m) => m.id).includes(f.id)))
          setIsLoading(false)
        })
      })
  }, [])

  return (
    <Dialog
      onCancel={() => {
        setSelectedUsers([])
        onClose()
      }}
      disableConfirm={!selectedUsers.length}
      onConfirm={handleSubmit}
      maxWidth="sm"
      onClose={onClose}
      title="Add Users to Group"
      confirmBtnLabel="Add Users"
      aria-labelledby="add users to group"
      open={open}
    >
      <Box display="flex" justifyContent={isLoading ? 'center' : 'flex-start'} height={516}>
        {isLoading ? (
          <CircularProgress sx={{ alignSelf: 'center' }} />
        ) : (
          <div style={CONTAINER}>
            <UserGroupSelector
              open
              showAvatar
              options={allUsers}
              total={allUsers?.length}
              selectedOptions={selectedUsers}
              handleAddUserGroup={(user: ISelectGroupOptions[]) => handleChange(user)}
            />
          </div>
        )}
      </Box>
    </Dialog>
  )
})

const CONTAINER: React.CSSProperties = {
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-start',
  width: '100%',
  overflow: 'hidden',
}
