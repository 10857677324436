import { types } from 'mobx-state-tree'

/**
 * ReasonRead Model
 */
export const ReasonReadModel = types.model('ReasonRead').props({
  __typename: types.optional(types.literal('ReasonRead'), 'ReasonRead'),
  description: types.string,
  id: types.integer,
  isDeleted: types.maybeNull(types.boolean),
  text: types.string,
})
