import { useRef, useEffect } from 'react'

export function useInterval(callback: any, delay: any) {
  const savedCallback = useRef()

  // Remember the latest callback.
  useEffect(() => {
    savedCallback.current = callback
  }, [callback])

  // Set up the interval.
  useEffect(() => {
    const id = setInterval(() => {
      ;(savedCallback as any).current()
    }, delay)
    return () => clearInterval(id)
  }, [delay])
}
