import React from 'react'
import { Dialog, IconButton } from '@mui/material'
import { CheckCircle, Close } from '@mui/icons-material'

import { Button } from '../button'
import { Text } from '../text'
import { color } from '../../theme'
import { observer } from 'mobx-react-lite'
import { useStores } from '@trivie/core'

export interface SuccessfulImportProps {
  open: boolean
  onClose: () => void
  onAssign: () => void
  onGo: () => void
}

export const SuccessfulImport = observer((props: SuccessfulImportProps) => {
  const { open, onClose, onAssign, onGo } = props
  const { contentCreationStore } = useStores()
  const { topics, quiz, question } = contentCreationStore

  return (
    <Dialog aria-labelledby="successful-import-dialog" open={open}>
      <div style={{ height: 766, display: 'flex', flexDirection: 'column', paddingBottom: 66 }}>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            paddingLeft: 20,
            paddingTop: 10,
            paddingRight: 20,
            paddingBottom: 10,
            width: 719,
          }}
        >
          <div />
          <IconButton onClick={onClose} size="large">
            <Close htmlColor={color.shade50} style={{ fontSize: 22 }} />
          </IconButton>
        </div>

        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            flex: 1,
            justifyContent: 'center',
          }}
        >
          <CheckCircle htmlColor={color.palette.green} style={{ fontSize: 34 }} />
          <Text
            variant="headline"
            text="It's Published!"
            style={{ marginTop: 30, marginBottom: 26 }}
          />
          <Text
            variant="body1"
            text={`Your content has been successfully published. You can now ${
              topics || (!quiz && !question) ? 'make assignments or' : ''
            } edit your ${
              topics && topics?.length > 0
                ? 'topic'
                : quiz
                  ? 'quiz'
                  : question
                    ? 'question'
                    : 'content'
            } at a later point.`}
            style={{ width: 291, marginBottom: 25, textAlign: 'center' }}
          />
          {!quiz && !question && (
            <Button
              onClick={onAssign}
              sx={{ marginBottom: '14px' }}
              text="Assign your new content"
            />
          )}
          <Button
            color="info"
            variant="outlined"
            onClick={onGo}
            text={`Go to your ${
              topics && topics?.length > 0
                ? 'topics'
                : quiz
                  ? 'quizzes'
                  : question
                    ? 'questions'
                    : 'topics'
            }`}
          />
        </div>
      </div>
    </Dialog>
  )
})
