import React, { useState, useEffect } from 'react'
import { observer } from 'mobx-react-lite'
import { useStores, validateAndFormatNumber } from '@trivie/core'
import { Stack } from '@mui/material'
import { Instance } from 'mobx-state-tree'

import { UserProfile } from '@trivie/core/src/models/UserProfile'
import { EditUserForm } from '@trivie/core/src/models/EditUser'

import { Button } from '../button'
import { Dialog } from '../dialogs/dialog'
import { EditUserDialog } from './edit-user-form'

export interface EditUserProps {
  open: boolean
  profile?: Instance<typeof UserProfile> | null
  onClose: () => void
  onUpdate?: () => void
  onDelete: () => void
}

export const EditUser = observer((props: EditUserProps) => {
  const { open, onClose, onUpdate = () => ({}), onDelete = () => ({}) } = props
  const { manageUserStore } = useStores()
  const { selectedUserProfile, updateSelectedUser } = manageUserStore

  const getPayload = () => {
    const payload = {
      first_name: form?.first_name,
      last_name: form?.last_name,
      email: form?.email,
    }
    if (form?.phone_number) {
      payload['phone_number'] = validateAndFormatNumber(form?.phone_number)
    } else {
      payload['phone_number'] = null
    }
    if (form?.email === '') {
      payload.email = null
    }
    return payload
  }

  const onSubmit = () => {
    getPayload()
    updateSelectedUser(
      getPayload(),
      () => {
        onUpdate()
      },
      (err: any) => {
        if (err?.kind === 'conflict' && err?.message) {
          if (err?.message.includes('email')) {
            form!.set('duplicateEmail', true)
            form!.set('duplicatePhone', false)
          } else if (err?.message.includes('phone')) {
            form!.set('duplicatePhone', true)
            form!.set('duplicateEmail', false)
          }
        }
      },
      selectedUserProfile?.id,
    )
  }

  const handleClose = () => {
    onClose()
  }

  const [form, setForm] = useState<Instance<typeof EditUserForm> | null>(null)
  useEffect(() => {
    setForm(
      EditUserForm.create({
        first_name: selectedUserProfile?.first_name ?? '',
        last_name: selectedUserProfile?.last_name ?? '',
        email: selectedUserProfile?.email ?? '',
        phone_number: selectedUserProfile?.phone_number ?? '',
        touched: false,
        duplicateEmail: false,
        duplicatePhone: false,
      }),
    )
  }, [selectedUserProfile])

  if (!form) {
    return null
  }

  return (
    <Dialog
      maxWidth="sm"
      onClose={handleClose}
      disableConfirm={Boolean(!form.touched || Object.keys(form.errors).length)}
      title="Edit User"
      open={open}
      actions={
        <Stack direction="row" flex={1} justifyContent={'space-between'}>
          <Button variant="text" onClick={onDelete} color="error" text="Delete User" />
          <Stack flex={1} direction="row" useFlexGap gap={'12px'} justifyContent="flex-end">
            <Button variant="outlined" color="info" onClick={onClose} text="Cancel" />
            <Button
              disabled={Boolean(!form.touched || Object.keys(form.errors).length)}
              onClick={onSubmit}
              text="Save Changes"
            />
          </Stack>
        </Stack>
      }
    >
      <EditUserDialog form={form} />
    </Dialog>
  )
})
