import { types } from 'mobx-state-tree'

export const UserCommSettings = types.model('UserCommSettings').props({
  onesignal_id: types.maybeNull(types.string),
  web_push_id: types.maybeNull(types.string),
  push_enabled: types.maybeNull(types.boolean),
  push_challenge_received: types.maybeNull(types.string),
  push_challenge_completed: types.maybeNull(types.string),
  push_notify_likes: types.maybeNull(types.boolean),
  push_notify_quizzes: types.maybeNull(types.string),
  push_notify_tests: types.maybeNull(types.string),
  push_notifications_allowed: types.maybeNull(types.string),
  push_adaptive_allowed: types.maybeNull(types.boolean),
  assignments_email_enabled: types.maybeNull(types.boolean),
  feed_email_enabled: types.maybeNull(types.boolean),
  leaderboard_email_enabled: types.maybeNull(types.boolean),
  reinforcement_email_enabled: types.maybeNull(types.boolean),
  survey_email_enabled: types.maybeNull(types.boolean),
})
