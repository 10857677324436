import { Container, createStyles, useMediaQuery, useTheme } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { useStores } from '@trivie/core'
import { color } from '@trivie/ui-web/theme/color'
import { observer } from 'mobx-react-lite'
import React, { useEffect, useState } from 'react'
import { Link, Redirect, Route, Switch, useHistory, useLocation } from 'react-router-dom'
import useBreadcrumbs from 'use-react-router-breadcrumbs'
import { CreateTopicScreen, CreateQuestionScreen } from '../screens/content/content-creation'
import { CreateQuizScreen } from '../screens/content/content-creation/create-quiz-screen'
import { CreateSurveyScreen } from '../screens/activities/surveys/survey-creation/create-survey-screen'
import { breadcrumbFilter } from '../utils'
import { ReactComponent as NotFound } from './404.svg'
import { ProfileDropdown } from './profile-dropdown'
import { routes, supportRoutes } from './routes'
import { Sidebar } from './sidebar'
import { Text } from '@trivie/ui-web/components/text'
import { Button } from '@trivie/ui-web/components/button'
import {
  SettingsRounded,
  EmojiObjectsRounded,
  People,
  BoltRounded,
  MenuBookRounded,
} from '@mui/icons-material'
import { Logo } from '../screens/login/logo'
import { ReactComponent as Mark } from './mark.svg'
import { LibraryScreen } from '../screens/content/quizzes/library-screen'
import { ResourcesScreen } from '../screens/content/resources'
import { UsersScreen } from '../screens/people/users'
import { UpgradeScreen } from '../screens/upgrade'
import { Settings as SettingsScreen } from '../screens/settings/workspace'
import { UserOverviewScreen } from '../screens/people/users/user-overview-screen'
import { ResourcesDetailsLiteScreen } from '../screens/content/resources/resources-details-lite-screen'
import { CreatePathScreen } from '../screens/content/learning-paths/create-path-screen'
import { QuizzesDetailsScreen } from '../screens'

const NotFoundPage = observer(() => {
  const history = useHistory()
  return (
    <div style={NOT_FOUND_CONTAINER}>
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          width: '100%',
          justifyContent: 'center',
        }}
      >
        <NotFound />
        <div
          style={{
            alignSelf: 'center',
            marginLeft: 40,
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <Text
            style={{ fontSize: 80, marginBottom: 20, lineHeight: '80px' }}
            variant="headline"
            text="404"
          />
          <Text
            variant="titleMedium"
            style={{ width: 255, color: color.shade50, marginBottom: 20 }}
            text="Sorry, the page you’re looking for cannot be found."
          />
          <Button text="Back" onClick={() => history.replace('/content/quizzes')} />
        </div>
      </div>
    </div>
  )
})

const Breadcrumbs = () => {
  // @ts-ignore
  const breadcrumbs = useBreadcrumbs(routes, {
    excludePaths: ['/', '/no-breadcrumb/for-this-route'],
  })
  const filteredBreadcrumbs = breadcrumbFilter(breadcrumbs)
  return (
    <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginBottom: 14 }}>
      {filteredBreadcrumbs.map(({ breadcrumb, key }: any, index: number, data: any) => {
        return (
          <React.Fragment key={index}>
            <Link style={{ textDecoration: 'none' }} to={key}>
              <Text
                key={index}
                variant="body1Medium"
                style={{
                  color: index < filteredBreadcrumbs.length - 1 ? color.shade50 : color.black,
                }}
              >
                {breadcrumb as any}
              </Text>
            </Link>
            {index !== data.length - 1 && (
              <Text style={{ color: '#9098A3' }} text="&nbsp;/&nbsp;" />
            )}
          </React.Fragment>
        )
      })}
    </div>
  )
}

const useStyles = makeStyles(() =>
  createStyles({
    navItem: {
      '&:hover': {
        backgroundColor: color.palette.paperBorder,
        borderRadius: 10,
      },
      height: 50,
      width: 210,
      paddingRight: 20,
      cursor: 'pointer',
    },
  }),
)

const lroutes = [
  {
    name: 'quizzes',
    label: 'Quizzes',
    path: '/content/quizzes',
    exact: true,
    main: () => <LibraryScreen />,
    icon: (active?: boolean) => (
      <BoltRounded
        style={{
          marginRight: 16,
          color: active ? '#1B59F8' : 'rgba(0, 0, 0, .7)',
        }}
      />
    ),
  },
  {
    name: 'sources',
    label: 'Sources',
    path: '/content/sources',
    exact: true,
    main: () => <ResourcesScreen />,
    icon: (active?: boolean) => (
      <MenuBookRounded
        style={{
          marginRight: 16,
          color: active ? '#1B59F8' : 'rgba(0, 0, 0, .7)',
        }}
      />
    ),
  },
  {
    name: 'people',
    label: 'People',
    path: '/people/users',
    exact: true,
    main: () => <UsersScreen />,
    icon: (active?: boolean) => (
      <People
        style={{
          marginRight: 16,
          color: active ? '#1B59F8' : 'rgba(0, 0, 0, .7)',
        }}
      />
    ),
  },
  {
    name: 'upgrade',
    label: 'Upgrade',
    path: '/upgrade',
    exact: true,
    main: () => <UpgradeScreen />,
    icon: (active?: boolean) => (
      <EmojiObjectsRounded
        style={{
          marginRight: 16,
          color: active ? '#1B59F8' : 'rgba(0, 0, 0, .7)',
        }}
      />
    ),
  },
  {
    name: 'settings',
    label: 'Settings',
    path: '/settings',
    exact: true,
    main: () => <SettingsScreen />,
    icon: (active?: boolean) => (
      <SettingsRounded
        style={{
          marginRight: 16,
          color: active ? '#1B59F8' : 'rgba(0, 0, 0, .7)',
        }}
      />
    ),
  },
]

const LiteRoutes = observer(() => {
  const { manageUserStore, uiStore } = useStores()
  const { quantum } = uiStore
  const [active, setActive] = useState<any>(undefined)

  const [init, setInit] = useState(manageUserStore?.initializing)

  useEffect(() => {
    lroutes.forEach((route: any) => {
      if (location.pathname.includes(route.path)) {
        setActive(route.path)
      }
    })
  }, [location.pathname])

  const classes = useStyles() as any

  const history = useHistory()

  const theme = useTheme()
  const compact = useMediaQuery(theme.breakpoints.down('sm'))
  const tok = localStorage.getItem('token')

  return (
    <Route
      render={() => {
        if (tok) {
          return (
            <>
              <nav
                style={{
                  width: compact ? 80 : 230,
                  maxHeight: '100%',
                  minWidth: compact ? 80 : 230,
                  borderRight: '1px solid #EFF0F6',
                  backgroundColor: '#FFFFFF',
                  overflowY: 'auto',
                }}
              >
                <ul style={{ listStyleType: 'none', padding: 0, margin: 0, paddingBottom: 30 }}>
                  <div
                    key={0}
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                      flexDirection: 'row',
                      alignItems: 'center',
                      height: 70,
                    }}
                  >
                    {quantum ? (
                      <Logo quantum />
                    ) : compact ? (
                      <Mark style={{ marginRight: 4 }} />
                    ) : (
                      <Logo style={{ marginRight: 48 }} />
                    )}
                  </div>
                  <div style={{ paddingTop: 24 }}>
                    {lroutes.map((route: any, index: number) => {
                      const isOpen = active === route.path
                      return (
                        route && (
                          <li className={classes.navItem} key={`ROUTE_${index}`}>
                            <div
                              onKeyDown={(e) => {
                                if (e.key === 'Enter') {
                                  history.push(route.path)
                                }
                              }}
                              onClick={() => {
                                setActive(route.path)
                                history.push(route.path)
                              }}
                              style={{
                                ...(isOpen ? ACTIVE_LINK_TOP : LINK),
                                color: isOpen ? color.palette.blueLink : color.shade70,
                              }}
                              role="link"
                              tabIndex={0}
                            >
                              <div style={{ display: 'flex', alignItems: 'center' }}>
                                <div
                                  style={{
                                    ...LEFT_BORDER,
                                    backgroundColor: isOpen ? color.palette.blueLink : 'initial',
                                  }}
                                />
                                {route.icon(isOpen)}
                                {!compact && (
                                  <Text
                                    variant="body1Medium"
                                    text={route.label}
                                    style={{
                                      fontWeight: isOpen ? '600' : '500',
                                      minWidth: 77,
                                      marginLeft: 8,
                                      color: isOpen ? color.palette.blueLink : color.shade70,
                                    }}
                                  />
                                )}
                              </div>
                            </div>
                          </li>
                        )
                      )
                    })}
                  </div>
                </ul>
              </nav>
              <div style={CONTAINER}>
                <div style={HEADER_BAR}>
                  <ProfileDropdown />
                </div>
                <main
                  className="rootContainer"
                  style={{
                    overflowY: 'auto',
                    overflowX: 'hidden',
                    height: '100%',
                    minWidth: 0,
                  }}
                >
                  <div style={MAIN}>
                    <Switch>
                      {lroutes.map((r) => {
                        return (
                          <Route path={r.path} exact={r.exact}>
                            <r.main />
                          </Route>
                        )
                      })}
                      <Route exact path="/content/creation/topic">
                        <CreateTopicScreen />
                      </Route>
                      <Route exact path="/content/creation/quiz/:quizId/edit">
                        <CreateQuizScreen />
                      </Route>
                      <Route path="/content/quizzes/:id/:tab">
                        <QuizzesDetailsScreen />
                      </Route>
                      <Route path="/people/users/:id/:tab">
                        <UserOverviewScreen />
                      </Route>
                      <Route path="/content/sources/:id">
                        <ResourcesDetailsLiteScreen />
                      </Route>
                      <Route exact path="/">
                        <Redirect to={{ pathname: '/content/quizzes' }} />
                      </Route>
                      <Route path="*">
                        <NotFoundPage />
                      </Route>
                    </Switch>
                  </div>
                </main>
              </div>
            </>
          )
        }
        return <Redirect to="/login" />
      }}
    />
  )
})

export const PrivateRoutes = observer(() => {
  const { manageUserStore } = useStores()
  const { userProfile, permissions, enterpriseTier } = manageUserStore

  const tok = localStorage.getItem('token')

  return !enterpriseTier ? (
    <LiteRoutes />
  ) : (
    <Route
      render={() => {
        if (tok) {
          return (
            <>
              <Sidebar />
              <div style={CONTAINER}>
                <div style={HEADER_BAR}>
                  <ProfileDropdown />
                </div>
                <main
                  className="rootContainer"
                  style={{ overflowY: 'scroll', overflowX: 'hidden', margin: 0 }}
                >
                  <Container
                    disableGutters
                    sx={{ maxWidth: '1440px !important', padding: '40px', margin: 0 }}
                  >
                    <Switch>
                      {routes.map((route: any, index: number) => {
                        return route.routes.map((subroute: any) => {
                          if (
                            !userProfile.workspace_settings.learning_paths_enabled &&
                            subroute?.name === 'paths'
                          ) {
                            return null
                          }
                          return (
                            <Route
                              key={index}
                              path={subroute.path}
                              exact={subroute.exact}
                              render={() => (
                                <>
                                  {!subroute.hideBreadcrumbs && <Breadcrumbs />}
                                  <subroute.main />
                                </>
                              )}
                            />
                          )
                        })
                      })}
                      <Route exact path="/content/path/:id/edit">
                        <CreatePathScreen edit />
                      </Route>
                      <Route exact path="/content/creation/path">
                        <CreatePathScreen />
                      </Route>
                      <Route exact path="/content/creation/import">
                        <CreateTopicScreen />
                      </Route>
                      <Route exact path="/content/creation/topic">
                        <CreateTopicScreen />
                      </Route>
                      <Route exact path="/content/creation/topic/:topicId/edit">
                        <CreateTopicScreen />
                      </Route>
                      <Route exact path="/content/creation/quiz/:quizId/edit">
                        <CreateQuizScreen />
                      </Route>
                      <Route path="/content/question/:questionId/edit">
                        <CreateQuestionScreen />
                      </Route>
                      <Route path="/content/question/:questionId/new">
                        <CreateQuestionScreen />
                      </Route>
                      <Route exact path="/content/survey/create">
                        <CreateSurveyScreen />
                      </Route>
                      <Route exact path="/">
                        <Redirect to={{ pathname: '/content/quizzes' }} />
                      </Route>
                      {supportRoutes.map((route: any, index: number) => {
                        if (
                          !permissions?.permissions.some((p) => p.code === 'can_create_quiz') &&
                          route.name === 'support'
                        ) {
                          return null
                        }
                        if (route.routes) {
                          return route.routes.map((subroute: any) => {
                            return (
                              <Route
                                key={index}
                                path={subroute.path}
                                exact={subroute.exact}
                                render={() => (
                                  <>
                                    <Breadcrumbs />
                                    <subroute.main />
                                  </>
                                )}
                              />
                            )
                          })
                        }
                        return (
                          <Route
                            key={index}
                            path={route.path}
                            exact={route.exact}
                            render={() => <route.main />}
                          />
                        )
                      })}
                      <Route exact path="/insights/reports">
                        <Redirect to={{ pathname: '/insights/reports/custom' }} />
                      </Route>
                      <Route exact path="/insights">
                        <Redirect to={{ pathname: '/insights/knowledge' }} />
                      </Route>
                      <Route exact path="/activities">
                        <Redirect to={{ pathname: '/activities/assignments/live' }} />
                      </Route>
                      <Route exact path="/activities/surveys">
                        <Redirect to={{ pathname: '/activities/surveys/live' }} />
                      </Route>
                      <Route exact path="/activities/contests">
                        <Redirect to={{ pathname: '/activities/contests/live' }} />
                      </Route>
                      <Route exact path="/people">
                        <Redirect to={{ pathname: '/people/users' }} />
                      </Route>
                      <Route exact path="/people/users">
                        <Redirect to={{ pathname: '/people/users' }} />
                      </Route>
                      <Route exact path="/content">
                        <Redirect to={{ pathname: '/content/topics' }} />
                      </Route>
                      <Route exact path="/reports">
                        <Redirect to={{ pathname: '/insights/knowledge' }} />
                      </Route>
                      <Route exact path="/activities/badges">
                        <Redirect to={{ pathname: '/activities/badges/custom' }} />
                      </Route>
                      <Route path="*">
                        <NotFoundPage />
                      </Route>
                    </Switch>
                  </Container>
                </main>
              </div>
            </>
          )
        }
        return <Redirect to="/login" />
      }}
    />
  )
})

const ACTIVE_LINK_TOP: React.CSSProperties = {
  textDecoration: 'none',
  alignSelf: 'center',
  alignItems: 'center',
  display: 'flex',
  width: '100%',
  color: color.blue,
  fontSize: 14,
  fontWeight: 600,
  height: '100%',
}

const LEFT_BORDER: React.CSSProperties = {
  backgroundColor: color.palette.blueLink,
  width: 4,
  height: 32,
  borderTopRightRadius: 100,
  borderBottomRightRadius: 100,
  marginRight: 16,
}

const LINK: React.CSSProperties = {
  height: 50,
  textDecoration: 'none',
  alignSelf: 'center',
  display: 'flex',
  color: 'rgba(0,0,0,0.7)',
  width: '100%',
  alignItems: 'center',
  fontWeight: 500,
  letterSpacing: '-0.154',
  marginBottom: 8,
}

const CONTAINER: any = {
  flex: 1,
  display: 'flex',
  flexDirection: 'column',
  minWidth: 0,
}
const MAIN: React.CSSProperties = {
  // flex: 1,
  paddingLeft: 40,
  paddingRight: 40,
  paddingBottom: 40,
  paddingTop: 40,
  maxWidth: 1440,
  minHeight: '100%',
  minWidth: 0,
  // flexGrow: 1,
}

const HEADER_BAR = {
  display: 'flex',
  backgroundColor: '#FFFFFF',
  borderBottomWidth: 1,
  minHeight: 70,
  justifyContent: 'flex-start',
  alignItems: 'center',
  boxShadow: '0px -1px 0px 0px #E2E2EA inset',
}

const NOT_FOUND_CONTAINER: React.CSSProperties = {
  width: '100%',
  height: '100%',
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  marginTop: 100,
  alignSelf: 'center',
}
