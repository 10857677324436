import { AssignmentsResourcesTab } from './assignments-resources-tab'
import { AssignmentsQuestionsTab } from './assignments-questions-tab'
import { AssignmentsTopicsTab } from './assignments-topics-tab'
import { AssignmentsQuizzesTab } from './assignments-quizzes-tab'
import { AssignmentsOverviewTab } from './assignments-overview-tab'
import { AssignmentsReportsTab } from './assignments-reports-tab'
import { AssignmentsUsersTab } from './assignments-users-tab'
import { AssignmentsGroupsTab } from './assignments-groups-tab'

export const assignmentTabs = [
  AssignmentsOverviewTab,
  AssignmentsTopicsTab,
  AssignmentsQuizzesTab,
  AssignmentsQuestionsTab,
  AssignmentsResourcesTab,
  AssignmentsUsersTab,
  AssignmentsGroupsTab,
  AssignmentsReportsTab,
]
