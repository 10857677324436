import React from 'react'
import { observer } from 'mobx-react-lite'
import { Stack } from '@mui/material'
import { Add } from '@mui/icons-material'

import { Text } from '@trivie/ui-web/components/text'
import { Button } from '@trivie/ui-web/components/button'
import { color } from '@trivie/ui-web/theme'

import { ReactComponent as NullState } from '../null-states/null-quiz-settings.svg'
import { Panel } from '@trivie/ui-web/components/panel/panel'
import { DraggableList } from '../../learning-paths/components/draggable-list'

export const StepsList = observer((props: any) => {
  const { edit, showStepPanel, topic, readonly, handleAddStep, workflow, path } = props

  return (
    <Panel title={path ? 'Steps' : 'Awards'} divider badge={path?.workflow_json?.stepCount}>
      <Stack position="relative">
        {workflow?.flat?.length ? (
          <DraggableList
            editing={edit}
            topic={Boolean(topic)}
            workflow={workflow}
            readonly={readonly}
            handleAddStep={handleAddStep}
          />
        ) : (
          <>
            <NullState style={{ alignSelf: 'center' }} />
            <Text
              align="center"
              alignSelf="center"
              color={color.shade50}
              my={2}
              text="What does a learner do on this learning path?"
            />
          </>
        )}
        {!readonly && (
          <Button
            id="add_step_btn"
            sx={{ alignSelf: 'center' }}
            variant="outlined"
            disabled={showStepPanel || workflow?.stub}
            startIcon={<Add />}
            onClick={handleAddStep}
            text={path ? 'Add Step' : 'Add Award'}
          />
        )}
      </Stack>
    </Panel>
  )
})
