import React from 'react'
import CloseIcon from '@mui/icons-material/Close'
import {
  Dialog as MuiDialog,
  DialogContent as MuiDialogContent,
  DialogTitle as MuiDialogTitle,
  DialogTitleProps as MuiDialogTitleProps,
  DialogProps as MuiDialogProps,
  DialogActions as MuiDialogActions,
  DialogActionsProps as MuiDialogActionsProps,
  DialogContentText,
  Breakpoint,
} from '@mui/material'
import IconButton from '@mui/material/IconButton'

import { Text } from '../../text'
import { Divider } from '../../divider'
import { Button } from '../../button'

export interface DialogTitleProps extends MuiDialogTitleProps {
  title: string
  onClose?: () => void
  open?: boolean
  hideCloseIcon?: boolean
}

export const DialogTitle = (props: DialogTitleProps) => {
  const { title, hideCloseIcon, onClose, ...other } = props
  return (
    <MuiDialogTitle {...other}>
      <Text variant="body1Emphasized" text={title} />
      {!hideCloseIcon ? (
        <IconButton
          data-qat="dialog_close"
          sx={{ position: 'absolute', right: 12, top: 12 }}
          aria-label="close"
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  )
}

export interface DialogActionsProps extends MuiDialogActionsProps {
  children?: any
}

export const DialogActions = (props: DialogActionsProps) => {
  const { children, ...rest } = props
  return <MuiDialogActions {...rest}>{children}</MuiDialogActions>
}

interface ConfirmDialogActionsProps extends DialogActionsProps {
  onCancel?: () => void
  onClose?: () => void
  onConfirm?: () => void
  open?: boolean
  confirmBtnLabel?: string
  cancelBtnLabel?: string
  danger?: boolean
  disableConfirm?: boolean
  confirmed?: boolean
}

export const ConfirmDialogActions = (props: ConfirmDialogActionsProps) => {
  const {
    confirmed,
    disableConfirm,
    onCancel,
    onConfirm,
    onClose,
    confirmBtnLabel,
    cancelBtnLabel,
    danger,
  } = props
  return (
    <DialogActions>
      {!confirmed && onCancel && (
        <Button
          data-qat="dialog_footer_cancel"
          onClick={onCancel}
          variant="outlined"
          text={cancelBtnLabel ?? 'Cancel'}
          color="info"
        />
      )}
      <Button
        data-qat="dialog_footer_confirm"
        onClick={confirmed ? onClose : onConfirm}
        disabled={!confirmed && disableConfirm}
        text={confirmed ? 'Close' : confirmBtnLabel ?? 'Confirm'}
        color={confirmed ? 'primary' : danger ? 'error' : 'primary'}
      />
    </DialogActions>
  )
}

export interface DialogProps extends MuiDialogProps {
  title: string
  onClose?: (e?) => void
  onCancel?: () => void
  onConfirm?: () => void
  disableConfirm?: boolean
  cancelLabel?: string
  children?: any
  actions?: any
  dialogText?: string
  danger?: boolean
  hideCloseIcon?: boolean
  maxWidth?: 'xs' | 'sm' | 'md' | 'lg' | 'xl' | false | Breakpoint
  align?: 'right' | 'left' | 'center' | 'inherit' | 'justify' | undefined
  confirmBtnLabel?: string
  cancelBtnLabel?: string
  confirmed?: boolean
  dialogActionsProps?: DialogActionsProps
  hideDivider?: boolean
  disableBackdropClick?: boolean
}

export const Dialog = (props: DialogProps) => {
  const {
    title,
    danger,
    open,
    onConfirm,
    onCancel,
    onClose,
    fullWidth = true,
    dialogText,
    children,
    maxWidth,
    disableConfirm,
    actions,
    hideCloseIcon,
    align,
    confirmBtnLabel,
    cancelBtnLabel,
    confirmed,
    sx,
    dialogActionsProps,
    hideDivider,
    disableBackdropClick,
    ...other
  } = props

  const handleClose = (event: any, reason: any) => {
    if (reason && reason === 'backdropClick' && disableBackdropClick) {
      return
    }
    if (onClose) {
      onClose()
    }
  }

  return open ? (
    <MuiDialog
      key={title}
      aria-labelledby={`modal-${title}`}
      open
      fullWidth={fullWidth}
      onClose={handleClose}
      maxWidth={maxWidth || false}
      sx={sx}
      onPointerDown={(e) => {
        // Prevent propagating pointer down events behind dialog
        e.stopPropagation()
      }}
      {...other}
    >
      <DialogTitle title={title} hideCloseIcon={hideCloseIcon} onClose={onClose} />
      {!hideDivider && <Divider />}
      <MuiDialogContent>
        {dialogText ? (
          <DialogContentText color="text.primary" align={align ?? 'center'} variant="body1Medium">
            {dialogText}
          </DialogContentText>
        ) : (
          children ?? null
        )}
      </MuiDialogContent>
      {actions ? (
        <DialogActions {...dialogActionsProps}>{actions}</DialogActions>
      ) : Boolean(onConfirm) ? (
        <ConfirmDialogActions
          onClose={onClose}
          confirmBtnLabel={confirmBtnLabel}
          cancelBtnLabel={cancelBtnLabel}
          confirmed={confirmed}
          disableConfirm={disableConfirm}
          danger={danger}
          onConfirm={onConfirm}
          onCancel={onCancel}
        />
      ) : null}
    </MuiDialog>
  ) : null
}
