/* eslint-disable */

import { Instance, types } from 'mobx-state-tree'
import { SurveyAssigneesGroupModel } from './SurveyAssigneesGroupModel'
import { SurveyAssigneesUserModel } from './SurveyAssigneesUserModel'

/**
 * AssignmentUserRead Model
 */

export const GetSurveyAssignDetailsResponseModel = types
  .model('GetSurveyAssignDetailsResponse')
  .props({
    id: types.number,
    title: types.string,
    is_anonymous: types.boolean,
    groups: types.array(types.late(() => SurveyAssigneesGroupModel)),
    users: types.array(types.late(() => SurveyAssigneesUserModel)),
    start_datetime: types.maybeNull(types.string),
    end_datetime: types.maybeNull(types.string),
    notify: types.boolean,
  })

export const PostSurveyAssignDetailsRequestModel = types
  .model('PostSurveyAssignDetailsRequest')
  .props({
    id: types.number,
    title: types.string,
    is_anonymous: types.boolean,
    groups: types.array(types.number),
    users: types.array(types.number),
    start_datetime: types.string,
    end_datetime: types.string,
    notify: types.boolean,
  })

export const PostSurveyAssignDetailsResponseModel = types
  .model('PostSurveyAssignDetailsResponse')
  .props({
    id: types.number,
    assignee_text: types.string,
    end_datetime: types.string,
    start_datetime: types.string,
    title: types.string,
  })

export interface GetSurveyAssignDetailsResponseType
  extends Instance<typeof GetSurveyAssignDetailsResponseModel.Type> {}
export interface PostSurveyAssignDetailsRequestType
  extends Instance<typeof PostSurveyAssignDetailsRequestModel.Type> {}

export interface PostSurveyAssignDetailsResponseType
  extends Instance<typeof PostSurveyAssignDetailsResponseModel.Type> {}
