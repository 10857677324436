import { AuthenticationApi } from '../services/api-objects/AuthenticationApi'
import * as storage from '../services/storage'

let token: string | null = null
let accessCode: string | null = null
let userId: string | null = null

export class AuthenticationHelper {
  static get userId() {
    return userId
  }

  static get token() {
    return token
  }

  static get accessCode() {
    return accessCode
  }

  static login = async (username: string, password: string, accessCode: string) => {
    const response = await AuthenticationApi.loginInsights(username, password, accessCode)

    if (response.ok && response.data) {
      storage.save('token', response.data.access_token)
    } else {
      storage.remove('access_code')
      storage.remove('token')
    }

    return response
  }

  static loginWorkspace = async (token: string, access_code: string) => {
    const response = await AuthenticationApi.loginWorkspace(token, access_code)

    if (response.ok && response.data) {
      await storage.save('user_id', response.data.user_id)
      await storage.saveString('access_code', access_code)
    }

    return response
  }

  static clearAuthentication = async () => {
    await storage.remove('user_id')
    await storage.remove('token')
    await storage.remove('access_code')
    await storage.remove('trivie')
    await storage.remove('workspaces')
    token = null
    accessCode = null
    userId = null
    AuthenticationApi.clearAuthentication()
  }

  static setupApi = async () => {
    const storedToken = await storage.loadString('token')
    const storedAccessCode = await storage.loadString('access_code')
    const storedUserId = await storage.loadString('user_id')

    if (!storedAccessCode || !storedToken || !storedUserId) {
      return
    }

    token = storedToken
    accessCode = storedAccessCode
    userId = storedUserId

    AuthenticationApi.setAccessCode(storedAccessCode)
    AuthenticationApi.setToken(token)
  }
}
