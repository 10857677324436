import { observer } from 'mobx-react-lite'
import React, { useRef, useEffect, useState } from 'react'
import ChevronLeft from '@mui/icons-material/ChevronLeft'
import { FileInput } from '@trivie/ui-web/components/file-input'
import { ImageCropper } from '@trivie/ui-web/components/image-cropper'
import { Button } from '@trivie/ui-web/components/button'
import { UserLevelModelType } from '@trivie/core'
import { Dialog } from '@trivie/ui-web/components/dialogs/dialog'

interface ImportBadgeDialogProps {
  onClose: () => void
  open: boolean
  level: UserLevelModelType | null
}

export const ImportBadgeDialog = observer((props: ImportBadgeDialogProps) => {
  const { onClose, open, level } = props
  const [file, setFile] = useState<File | null>(null)
  const [error, setError] = useState<null | string>(null)
  const cropperRef = useRef<any>(null)

  const [imgSrc, setImgSrc] = useState('')
  const [step, setStep] = useState(0)

  const [circularCrop, setCircularCrop] = useState(true)

  useEffect(() => {
    if (!open) {
      setFile(null)
      setImgSrc('')
      setStep(0)
    }
  }, [open])

  const handleFile = async (f: File | null) => {
    setFile(f)
    const max5MB = 5 * 1000 * 1000
    if (!f) {
      setImgSrc('')
      return
    } else {
      const splittedName = f?.name.split('.')
      if (f.size > max5MB) {
        setError('Maximum file size exceeded. Please try again with a smaller file (5MB).')
        return
      } else if (
        !['jpeg', 'png', 'gif', 'jpg'].includes(
          splittedName[splittedName.length - 1].toLocaleLowerCase(),
        )
      ) {
        setError('This file must be a .png, .gif, or .jpg. Please try again.')
        return
      } else {
        setError(null)
      }
    }
    const reader = new FileReader()
    reader.addEventListener('load', () => {
      setImgSrc(reader.result?.toString() || '')
      setStep(1)
    })
    reader.readAsDataURL(f)
  }

  return (
    <Dialog
      maxWidth="sm"
      title="Add Badge"
      onClose={onClose}
      aria-labelledby="user-level-badge-title"
      open={open}
      actions={
        <>
          {step === 1 ? (
            <div
              style={{
                flex: 1,
                display: 'flex',
                justifyContent: 'space-between',
                flexDirection: 'row',
              }}
            >
              <Button
                variant="text"
                color="info"
                onClick={() => setStep(0)}
                text="Back"
                startIcon={<ChevronLeft />}
              />
              <Button
                onClick={async () => {
                  const { blobUrl } = (await cropperRef?.current?.onCrop()) ?? {}
                  level?.setIcon(blobUrl)
                  onClose()
                }}
                text="Save"
              />
            </div>
          ) : (
            <div
              style={{ gap: 12, display: 'flex', flexDirection: 'row', justifyContent: 'flex-end' }}
            >
              <Button text="Cancel" color="info" variant="outlined" onClick={onClose} />
              <Button disabled={!file} onClick={() => setStep(1)} text="Next" />
            </div>
          )}
        </>
      }
    >
      <div style={{ marginBottom: 20 }}>
        {step === 1 ? (
          file ? (
            <ImageCropper
              ref={cropperRef}
              imgSrc={imgSrc}
              file={file}
              circularCrop={circularCrop}
              setCircularCrop={setCircularCrop}
            />
          ) : null
        ) : (
          <FileInput
            accept="image/png;image/jpeg;image/gif"
            file={file}
            mainText="or drag a file right here"
            helperText="Max file size 5MB. Accepts .png, .gif, and .jpg"
            onChange={handleFile}
            error={error}
          />
        )}
      </div>
    </Dialog>
  )
})
