import React, { useState } from 'react'
import { Text } from '../text'
import { color } from '../../theme/color'
import { Help } from '@mui/icons-material'
import { observer } from 'mobx-react-lite'
import Tooltip, { tooltipClasses, TooltipProps } from '@mui/material/Tooltip'
import styled from '@emotion/styled'
import { IconButton } from '@mui/material'

export const DescriptionTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} arrow classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: '#162142',
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: '#162142',
    fontSize: 14,
    padding: 16,
    fontFamily: 'Inter',
    maxWidth: 250,
    top: '0px !important',
  },
}))

export const Stat = observer((props: any) => {
  const { label, tooltip, value } = props
  const [showTooltip, setShowTooltip] = useState(false)
  return (
    <div style={CONTAINER}>
      <div style={{ flexDirection: 'row', display: 'flex', alignItems: 'center' }}>
        <Text
          text={label}
          variant="titleEmphasized"
          style={{ color: color.shade70, marginRight: 4 }}
        />
        <DescriptionTooltip open={showTooltip} placement="top" title={tooltip ?? ''}>
          <IconButton
            onMouseLeave={() => setShowTooltip(false)}
            onMouseEnter={() => setShowTooltip(true)}
          >
            <Help fontSize="small" htmlColor={color.shade30} />
          </IconButton>
        </DescriptionTooltip>
      </div>
      <div style={{ display: 'flex' }}>
        <Text text={value} variant="headline" />
      </div>
    </div>
  )
})

const CONTAINER: React.CSSProperties = {
  flexDirection: 'column',
  display: 'flex',
  height: '100%',
  justifyContent: 'space-between',
}
