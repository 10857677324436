import React, { useEffect, useState } from 'react'
import { Add, AddRounded, Close, FilterList } from '@mui/icons-material'
import { Autocomplete, IconButton, TextField } from '@mui/material'

import { Dialog } from '../dialogs/dialog'
import { Button } from '../button'
import { Text } from '../text'
import { color } from '../../theme'
import { FilterItem } from './filter-item'
import { CustomColumn } from './custom-column'
import { observer } from 'mobx-react-lite'
import { useStores } from '@trivie/core'
import { CreateReportDialog } from '../create-report/create-report-dialog'
import { TableApi } from '@trivie/core/src/services/api-objects'
import { useHistory } from 'react-router-dom'
import { Switch } from '../switch/switch'
import List from '@mui/material/List'

interface CustomFilterPanelProps {
  apiRef: any
  tagCategories: any
  onSave: (columns: any[], filter: any) => void
  hideAddColumn?: boolean
  customReport?: boolean
  custom?: boolean
  tableHelper?: any
  allowNewReport?: boolean
}
export const CustomFilterPanel = observer((props: CustomFilterPanelProps) => {
  const {
    apiRef,
    custom,
    hideAddColumn,
    onSave,
    tagCategories = [],
    tableHelper,
    allowNewReport,
  } = props

  const { state, exportState, restoreState, setColumnVisibilityModel, hideFilterPanel } =
    apiRef?.current

  const { reportStore } = useStores()
  const { setOpenFilter, openFilter } = reportStore
  const [filter, setFilter] = useState(state.filter)

  const [showCustomColumn, setShowCustomColumn] = useState(false)
  const [logicOperator, setLogicOperator] = useState<any>(state.filter.filterModel.logicOperator)
  const [unfilteredButtons, setUnfilteredButtons] = useState<any>({})
  const [columns, setColumns] = useState<any>(state.columns.lookup)

  const history = useHistory()

  const [initialState] = useState<any>({
    ...exportState(),
    columns: {
      ...exportState().columns,
      columnVisibilityModel: state.columns.columnVisibilityModel,
    },
  })

  const [visibilityModel, setVisibilityModel] = useState(state.columns.columnVisibilityModel)
  const [filteredButtons, setFilteredButtons] = useState({})

  useEffect(() => {
    const f = {}
    let updateApplyDisabledFlag = false
    filter.filterModel.items?.map((c: any) => {
      f[c.field] = true
      setFilteredButtons(f)
      if (c.operator !== 'isEmpty' && c.operator?.label !== 'never' && (!c.value || !c.operator)) {
        updateApplyDisabledFlag = true
      }
    })
    setApplyDisabled(updateApplyDisabledFlag)
  }, [filter])

  const close = () => {
    restoreState(initialState)
    setOpenFilter(false)
    hideFilterPanel()
  }

  const reset = () => {
    // reset to workspace default
    // resotre initial state, find original column visibility model, reset filter
    restoreState(initialState)
    const workspaceDefaultVisibility = {}
    Object.values(state.columns.lookup)
      .filter((column: any) => column.field !== '__check__')
      .map((column: any) => {
        updateColumnVisibility(column.header_name, column.is_default)
        workspaceDefaultVisibility[column.field] = column.is_default
      })

    // reset widths, sorting, filtering, and column order to workspace defaults
    // apply to our custom state
    // apiRef.current.setColumnVisibilityModel(workspaceDefaultVisibility)
    setVisibilityModel(workspaceDefaultVisibility)

    // reset filter icons on column rows
    setFilteredButtons({})

    // reset filter on our state
    setFilter({ filterModel: { items: [] } })
  }

  const addFilter = (column?: string) => {
    let newCol = ''

    // find first available unfiltered column
    Object.keys(columns)
      .reverse()
      .map((col) => {
        if (col !== '__check__' && !filteredButtons[col]) {
          newCol = col
        }
      })

    setFilter({
      ...filter,
      filterModel: {
        ...filter.filterModel,
        items: [
          ...filter.filterModel.items,
          {
            id: filter.filterModel.items.length * 2,
            field: column ? column : newCol,
            column: columns[column ?? newCol],
            operator: '',
            value: '',
          },
        ],
      },
    })
  }

  const [removed, setRemoved] = useState<any>({})
  const removeFilter = (column: any) => {
    // lookup by column name
    // remove filter from filter model list
    // update our custom filter state
    const { items } = filter.filterModel

    const r = { ...removed }
    r[column] = true
    setRemoved(r)

    items.map((item, index) => {
      if (item.field === column) {
        const filtered = { ...filteredButtons }
        filtered[column] = false
        setFilteredButtons(filtered)
      }
    })

    const filterItems = items.filter((item) => {
      return item.field !== column
    })

    setFilter({
      ...filter,
      filterModel: {
        ...filter.filterModel,
        items: filterItems,
      },
    })
  }

  const [showDelete, setShowDelete] = useState<any>('')
  const showUnfilteredbutton = (column: any) => {
    const updated = {}
    if (!filteredButtons[column]) {
      updated[column] = true
      setUnfilteredButtons(updated)
    } else if (filteredButtons[column]) {
      updated[column] = false
      setUnfilteredButtons(updated)
    }
    if (columns[column]?.is_custom) {
      setShowDelete(column)
    } else {
      setShowDelete('')
    }
  }

  const hideUnfilteredButton = (column: any) => {
    const updated = {}
    if (!filteredButtons[column]) {
      updated[column] = false
      setUnfilteredButtons(updated)
    } else {
      updated[column] = false
      setUnfilteredButtons(updated)
    }
  }

  const handleFilteredButton = (column: any) => {
    // check if column is already filtered on
    // if not add a new filter row defaulting to column name
    // hide custom column panel
    setShowCustomColumn(false)
    hideUnfilteredButton(column)
    addFilter(column.field)
    updateColumnVisibility(column.field, true)
  }

  const handleLogicOperatorUpdate = (logicOperator: any) => {
    // update filter model with new link operator
    setFilter({
      ...filter,
      filterModel: { ...filter.filterModel, logicOperator },
    })
  }

  const updateColumnVisibility = (column: any, enabled: boolean) => {
    // update visibility model
    const updated = { ...visibilityModel }
    updated[column] = enabled
    setVisibilityModel(updated)

    // remove filter?
    if (!enabled) {
      removeFilter(column)
    }
  }

  const handleColumnFilterUpdate = (newColumn: any, oldColumn: any) => {
    // lookup and update filter column value for given filter (by id?)
    // update visibility model and filter button state

    updateColumnVisibility(newColumn.value.field, true)

    const r = { ...removed }
    r[newColumn.value.field] = false
    setRemoved(r)

    const { items } = filter.filterModel
    let updatedFilter = {}
    let updatedFilterIndex = 0

    let found

    items.map((item, index) => {
      if (item.field === oldColumn.value.field) {
        updatedFilterIndex = index
        updatedFilter = {
          column: newColumn,
          field: newColumn.value.field,
          operator: '',
          value: '',
        }

        const filtered = { ...filteredButtons }

        filtered[oldColumn.value.field] = false
        setFilteredButtons(filtered)

        found = true
      }
    })

    const updatedItems = [...items]

    updatedItems[updatedFilterIndex] = updatedFilter

    setFilter({
      ...filter,
      filterModel: {
        ...filter.filterModel,
        items: updatedItems,
      },
    })
  }

  const handleOperatorFilterUpdate = (operator: any, column: any) => {
    // lookup and update filter item operator for given filter

    const { items } = filter.filterModel
    let updatedFilter = {}
    let updatedFilterIndex = 0

    items.map((item, index) => {
      if (item.field === column.value.field) {
        updatedFilterIndex = index
        updatedFilter = {
          ...item,
          operator: operator,
          column,
        }
      }
    })

    const updatedItems = [...items]
    updatedItems[updatedFilterIndex] = updatedFilter

    setFilter({
      ...filter,
      filterModel: {
        ...filter.filterModel,
        items: updatedItems,
      },
    })
  }

  const handleValueFilterUpdate = (value: any, column: any) => {
    // lookup and update filter item value for given filter

    const { items } = filter.filterModel
    let updatedFilter = {}
    let updatedFilterIndex = 0

    items.map((item, index) => {
      if (item.field === column?.value?.field ?? column) {
        updatedFilterIndex = index
        updatedFilter = {
          ...item,
          value: value.value ? value.value : value,
          column,
        }
      }
    })

    const updatedItems = [...items]
    updatedItems[updatedFilterIndex] = updatedFilter

    setFilter({
      ...filter,
      filterModel: {
        ...filter.filterModel,
        items: updatedItems,
      },
    })
  }

  const addCustomColumn = (column: any) => {
    // create column alias and add to columns list
    // update column visibility to show new column

    updateColumnVisibility(column.field, true)
    const c = { ...columns }
    c[column.field] = column
    setColumns(c)

    let updateApplyDisabledFlag = false
    filter.filterModel.items?.map((c: any) => {
      if (!c.value || !c.operator) {
        updateApplyDisabledFlag = true
      }
    })
    setApplyDisabled(updateApplyDisabledFlag)
  }

  const getDateOperator = (filter: any) => {
    switch (filter) {
      case 'isEmpty':
        return 'never'
      case 'never':
        return 'eq'
      case 'is':
        return 'eq'
      case 'not':
        return 'ne'
      case 'after':
        return 'gt'
      case 'onOrAfter':
        return 'gte'
      case 'before':
        return 'lt'
      case 'onOrBefore':
        return 'lte'
      default:
        return filter
    }
  }

  const getStringOperatorValue = (filter: any) => {
    switch (filter) {
      case 'contains':
        return 'like'
      case 'equals':
        return 'eq'
      case 'does not contain':
        return 'not_like'
      case 'does not equal':
        return 'ne'
      default:
        return filter
    }
  }

  const getBooleanOperatorValue = (filter: any) => {
    switch (filter) {
      case 'is':
        return 'eq'
    }
  }

  const getNumericOperators = (filter: any) => {
    switch (filter) {
      case '=':
        return 'eq'
      case '!=':
        return 'ne'
      case '>':
        return 'gt'
      case '<':
        return 'lt'
      case '>=':
        return 'gte'
      case '<=':
        return 'lte'
      default:
        return filter
    }
  }

  const [tempCols, setTempCols] = useState([])
  const apply = (newReport?: boolean, nonCustom?: boolean) => {
    // ensure visibility model is up to date with our components state
    // create column alias for custom (and potential defaults depending on mui)
    // save new view and hide the panel

    setColumnVisibilityModel(visibilityModel)

    const filtersToApply = {
      logicOperator,
      items: [] as any,
    }

    filter.filterModel.items.map((item) => {
      const c = Object.values(columns).find((c: any) => c?.field === item.field) as any
      filtersToApply.items.push({
        verb: item.operator?.value ? item.operator?.value : item.operator,
        value: c?.data_type === 'percent' ? item.value / 100 : item.value,
        column: item.column,
      })
    })

    const orderedColumns = [...state.columns.orderedFields]
    const payload = Object.values(columns)
      .filter((col: any) => visibilityModel[col.field] && col.field !== '__check__')
      .map((c: any) => {
        let filterPayload

        // check column for existing filter
        filtersToApply.items.map((i) => {
          let v

          // if no existing create from filter array
          if (
            i.column && i.column?.value?.field
              ? i.column?.value?.field === c.field
              : i.column?.field === c.field
          ) {
            if (i.column.value.type === 'boolean') {
              v = getBooleanOperatorValue(i.verb)
            } else if (i.column.value.type === 'date') {
              v = getDateOperator(i.verb)
            } else if (c.type === 'string' || c.header_name === 'Name' || c.type === 'array') {
              v = getStringOperatorValue(i.verb)
            } else {
              v = getNumericOperators(i.verb)
            }
            filterPayload = { verb: v ?? i.verb, value: i.verb === 'never' ? null : i.value }
          } else {
            if (c.filter) {
              if (c.type === 'boolean') {
                v = getBooleanOperatorValue(i.verb)
              } else if (c.type === 'date') {
                v = getDateOperator(c.filter.verb)
              } else if (c.type === 'string' || c.header_name === 'Name' || c.type === 'array') {
                v = getStringOperatorValue(c.filter.verb)
              } else {
                v = getNumericOperators(c.filter.verb)
              }
              // no column - exists & not removed
              if (!removed[c.field]) {
                filterPayload = { verb: v, value: c.filter.value }
              }
            }
          }
        })
        let f = ''
        if (c.column_alias) {
          f = c.field.split(':')[0]
          if (!orderedColumns.includes(c.field)) {
            orderedColumns.push(c.field)
          }
        }
        return {
          filter: filterPayload,
          field: f ? f : c.field,
          params: c.params,
          column_alias: c.column_alias ?? null,
          width: c.width,
        }
      })

    // keep sorting / ordering on filter apply
    const columnPayload = [] as any
    const { sorting } = state

    orderedColumns.slice(1).map((column) => {
      const sortModel = sorting.sortModel.find(
        (c) => c.field === column || c.column_alias === column,
      )
      const payloadC = payload.find((c) => c.field === column || c.column_alias === column) as any
      if (payloadC) {
        const { width, column_alias, field, filter, params } = payloadC
        if (visibilityModel[column]) {
          columnPayload.push({
            column_alias,
            filter,
            field,
            params: params ?? {},
            width,
            sort: sortModel?.sort ?? null,
          })
        }
      }
    })

    if (newReport && nonCustom) {
      setOpenFilter(false)
      setTempCols(columnPayload ?? payload)
      setShowCreateNewReport(true)
    } else if (newReport) {
      setOpenFilter(false)
      hideFilterPanel()
      tableHelper.saveAsNewReport(columnPayload ?? payload, filtersToApply)
    } else {
      onSave(columnPayload ?? payload, filtersToApply)
      setOpenFilter(false)
      hideFilterPanel()
    }
  }

  const [applyDisabled, setApplyDisabled] = useState(false)

  const removeColumn = (col: string) => {
    const updatedItems = [] as any
    filter.filterModel.items.map((item) => {
      if (item.field !== col) {
        updatedItems.push(item)
      }
    })
    setFilter({
      ...filter,
      filterModel: {
        ...filter.filterModel,
        items: updatedItems,
      },
    })

    const updated = columns
    delete columns[col]
    setColumns(updated)
  }

  const [showCreateNewReport, setShowCreateNewReport] = useState(false)

  return showCreateNewReport ? (
    <CreateReportDialog
      nonCustom
      duplicate={{
        columns: columns,
      }}
      baseViews={reportStore.baseViews}
      tableHelper={tableHelper}
      open={showCreateNewReport}
      onClose={() => {
        setShowCreateNewReport(false)
      }}
      onCreate={(payload, base) => {
        const c = [...tempCols] as any
        if (base === 'users') {
          c.push(
            ...[
              {
                field: 'email',
                filter: null,
                is_gdpr: false,
                sort: null,
                width: null,
                column_alias: null,
              },
              {
                field: 'phone_number',
                filter: null,
                is_gdpr: false,
                sort: null,
                width: null,
                column_alias: null,
              },
              {
                field: 'first_name',
                filter: null,
                is_gdpr: false,
                sort: null,
                width: null,
                column_alias: null,
              },
              {
                field: 'last_name',
                filter: null,
                is_gdpr: false,
                sort: null,
                width: null,
                column_alias: null,
              },
            ],
          )
        }
        const p = {
          view_name: payload.view_name,
          view_description: payload.view_description,
          method: payload.method,
          columns: c,
          base_view_id: payload.base_view_id,
          is_report: true,
        }
        TableApi.saveCustomReport(p).then((resp) => {
          setShowCreateNewReport(false)
          hideFilterPanel()
          history.replace(`/insights/reports/custom/${resp.data.id}?open=true`)
        })
      }}
    />
  ) : (
    <Dialog
      maxWidth="md"
      sx={{
        '& .MuiDialogContent-root': {
          overflow: 'hidden !important',
          display: 'flex',
          maxHeight: '600px !important',
        },
      }}
      open={state.preferencePanel.open || openFilter}
      title="Table Settings"
      onClose={close}
      actions={
        <div
          style={{
            width: '100%',
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
          }}
        >
          <Button text="Reset" color="error" variant="outlined" onClick={reset} />
          <div style={{ flexDirection: 'row', display: 'flex', gap: 12 }}>
            {custom && (
              <Button
                text="Save As New Report"
                color="primary"
                variant="outlined"
                onClick={() => apply(true)}
              />
            )}
            {!custom && allowNewReport && (
              <Button
                text="Save As New Report"
                color="primary"
                variant="outlined"
                onClick={() => {
                  apply(true, true)
                }}
              />
            )}
            {custom && (
              <Button disabled={applyDisabled} text="Update Report" onClick={() => apply(false)} />
            )}
            {!custom && (
              <Button disabled={applyDisabled} text="Apply" onClick={() => apply(false)} />
            )}
          </div>
        </div>
      }
    >
      <div style={CONTAINER}>
        <div style={COLUMN_PANEL}>
          <Text style={{ ...TITLE, marginBottom: 0 }} variant="titleEmphasized" text="Columns" />
          <List sx={COLUMN_LIST} subheader={<li />}>
            {Object.values(columns)
              .filter(
                (c: any) =>
                  c.field !== '__check__' &&
                  c.field !== 'first_name' &&
                  c.field !== 'last_name' &&
                  c.field !== 'email' &&
                  c.field !== 'phone_number',
              )
              .map((column: any, index) => {
                return column.is_custom && !column.column_alias ? null : (
                  <div
                    key={column.field}
                    style={SWITCH_ROW}
                    onMouseEnter={() => showUnfilteredbutton(column.field)}
                    onMouseLeave={() => hideUnfilteredButton(column.field)}
                  >
                    <div style={{ display: 'flex', alignItems: 'center', flex: 1 }}>
                      <Switch
                        disabled={
                          column.field === 'identity' ||
                          column.field === 'assignment_group_assigned_directly'
                        }
                        size="small"
                        checked={visibilityModel[column.field]}
                        onChange={(e) => updateColumnVisibility(column.field, e.target.checked)}
                      />
                      <Text
                        style={SWITCH_LABEL}
                        variant="body1Medium"
                        text={
                          column?.is_custom && column?.params
                            ? `${column?.header_name}: ${Object.values(column?.params)[0]}`
                            : `${column?.header_name}`
                        }
                      />
                    </div>
                    {column.data_type !== 'array' && (
                      <>
                        {filteredButtons[column.field] && (
                          <IconButton
                            onClick={() => setShowCustomColumn(false)}
                            style={{ marginRight: 4 }}
                            size="small"
                          >
                            <FilterList fontSize="small" htmlColor={color.blue} />
                          </IconButton>
                        )}
                      </>
                    )}
                    {unfilteredButtons[column.field] &&
                      !filteredButtons[column.field] &&
                      column.data_type !== 'array' && (
                        <IconButton
                          onClick={() => handleFilteredButton(column)}
                          style={{ marginRight: 4 }}
                          size="small"
                        >
                          <FilterList fontSize="small" htmlColor={color.shade50} />
                        </IconButton>
                      )}
                    {column?.is_custom && column.field === showDelete && (
                      <IconButton
                        onClick={() => removeColumn(column.field)}
                        style={{ marginRight: 4 }}
                        size="small"
                      >
                        <Close fontSize="small" htmlColor={color.shade50} />
                      </IconButton>
                    )}
                  </div>
                )
              })}

            <div style={GRADIENT} />
          </List>
          {!hideAddColumn && (
            <Button
              round
              size="small"
              sx={{ width: 230, zIndex: 999 }}
              endIcon={<Add />}
              text="Add Advanced Column"
              onClick={() => setShowCustomColumn(true)}
            />
          )}
        </div>
        <div style={FILTER_CONTAINER}>
          <Text
            style={TITLE}
            variant="titleEmphasized"
            text={showCustomColumn ? 'Add Advanced Column' : 'Filters'}
          />

          <div style={FILTERS}>
            {showCustomColumn ? (
              <CustomColumn
                setShowCustomColumn={setShowCustomColumn}
                columns={columns}
                addCustomColumn={addCustomColumn}
                tagCategories={tagCategories ?? []}
              />
            ) : (
              filter.filterModel.items &&
              filter.filterModel.items.map((item, index) => {
                return (
                  <div key={`${item.field}`}>
                    {index > 0 && (
                      <Autocomplete
                        style={{ marginBottom: 20 }}
                        onChange={(event: any, value: any) => {
                          setLogicOperator(value.toLowerCase())
                          handleLogicOperatorUpdate(value.toLowerCase())
                        }}
                        disablePortal
                        size="small"
                        disabled={index > 1}
                        disableClearable
                        sx={{ width: 109 }}
                        value={logicOperator?.charAt(0)?.toUpperCase() + logicOperator?.slice(1)}
                        options={['And', 'Or']}
                        renderInput={(params) => (
                          <TextField variant="standard" {...params} label="Operator" />
                        )}
                      />
                    )}
                    <FilterItem
                      handleColumnFilterUpdate={handleColumnFilterUpdate}
                      handleOperatorFilterUpdate={handleOperatorFilterUpdate}
                      handleValueFilterUpdate={handleValueFilterUpdate}
                      columns={columns}
                      filter={item}
                      filterList={filter.filterModel.items}
                      removeFilter={removeFilter}
                    />
                  </div>
                )
              })
            )}
          </div>
          {!showCustomColumn && (
            <Button
              size="small"
              sx={{ width: '122px' }}
              round
              endIcon={<AddRounded />}
              onClick={() => addFilter()}
              text="Add Filter"
            />
          )}
        </div>
      </div>
    </Dialog>
  )
})

const FILTER_CONTAINER: React.CSSProperties = {
  width: '50%',
  flexDirection: 'column',
  display: 'flex',
  paddingLeft: 20,
  overflow: 'auto',
  maxHeight: 520,
}
const CONTAINER: React.CSSProperties = {
  flexDirection: 'row',
  display: 'flex',
  flex: 1,
  overflow: 'hidden',
}
const SWITCH_ROW: React.CSSProperties = {
  flexDirection: 'row',
  display: 'flex',
  height: 17,
  alignItems: 'center',
  marginBottom: 8,
  justifyContent: 'space-between',
}
const SWITCH_LABEL: React.CSSProperties = {
  position: 'relative',
  alignSelf: 'center',
  color: '#70768C',
  paddingLeft: 12,
}
const COLUMN_PANEL: React.CSSProperties = {
  flexDirection: 'column',
  flex: 1,
  display: 'flex',
  borderRight: '1px solid rgba(0,0,0,.1)',
  position: 'relative',
  overflow: 'hidden',
}
const TITLE: React.CSSProperties = { alignSelf: 'center', color: '#70768C', marginBottom: 20 }
const COLUMNS: React.CSSProperties = {
  flexDirection: 'column',
  overflow: 'auto',
}
const FILTERS: React.CSSProperties = { ...COLUMNS, paddingTop: 0, paddingRight: 20 }
const GRADIENT: React.CSSProperties = {
  width: `calc(100% - 12px)`,
  height: 48,
  position: 'sticky',
  bottom: -8,
  left: 0,
  background: `linear-gradient(180deg, rgba(255,255,255,0) 0%, rgba(255,255,255,1) 100%)`,
}
const COLUMN_LIST = {
  width: '100%',
  position: 'relative',
  overflow: 'auto',
  '& ul': { padding: 0 },
  paddingTop: '20px',
}
