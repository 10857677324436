import React, { useState } from 'react'
import { observer } from 'mobx-react-lite'
import { Grid, Stack } from '@mui/material'

import { useStores } from '@trivie/core'
import { DateFilter } from '@trivie/ui-web/components/date-filter'
import { Header } from '@trivie/ui-web/components/header'
import { Insight } from '@trivie/ui-web/components/insight'
import { Mastery } from '@trivie/ui-web/components/mastery'
import { Text } from '@trivie/ui-web/components/text'
import { GenerateReport } from '@trivie/ui-web/components/generate-report'
import { StrongestWeakest } from '@trivie/ui-web/components/strongest-weakest'
import { Panel } from '@trivie/ui-web/components/panel/panel'
import { LineGraph } from '@trivie/ui-web/components/line-graph'

export const KnowledgeScreen: React.FunctionComponent = observer(() => {
  const { reportStore } = useStores()
  const { refreshKnowledgeData, knowledgeBaseStats, knowledgeMastery, topicGaps, questionGaps } =
    reportStore

  const [timespan, setTimespan] = useState('All Time')

  const onDateChange = (start, end, label) => {
    setTimespan(label as any)
    refreshKnowledgeData(start, end)
  }

  return (
    <>
      <Header divider title="Knowledge" />
      <Stack mb={2} direction="row" justifyContent="space-between" alignItems="center">
        <DateFilter onDateChange={onDateChange} />
        <GenerateReport filename="knowledge" />
      </Stack>
      <Grid container spacing={2}>
        <Grid item md={8} sm={12} xs={12}>
          <Panel title="How much are people learning?">
            <Stack gap={2} direction="row" flexWrap="wrap">
              <Insight
                noAveraging
                unit="%"
                timespan={timespan}
                data={knowledgeBaseStats?.stats_history?.[1].data_points}
                loading={!knowledgeBaseStats}
                title="Baseline Knowledge"
                value={`${knowledgeBaseStats?.starting_knowledge.toFixed(1) || '0.0'}%`}
              />
              <Insight
                noAveraging
                unit="%"
                timespan={timespan}
                data={knowledgeBaseStats?.stats_history?.[0].data_points}
                loading={!knowledgeBaseStats}
                title="Current Knowledge"
                value={`${knowledgeBaseStats?.current_knowledge.toFixed(1) || '0.0'}%`}
              />
              <Insight
                noAveraging
                unit="%"
                timespan={timespan}
                data={knowledgeBaseStats?.stats_history?.[2].data_points}
                loading={!knowledgeBaseStats}
                title="Knowledge Gain"
                value={`${knowledgeBaseStats?.knowledge_gain.toFixed(1) || '0.0'}%`}
              />
            </Stack>
            <Text
              variant="body2Medium"
              color="text.secondary"
              text="We measure each person's baseline knowledge by their first answer to each question. As they answer more questions, their current knowledge rises, resulting in knowledge gain."
            />
          </Panel>
        </Grid>
        <Grid item md={4} sm={12} xs={12}>
          <Panel fill title="Overall Content Mastery">
            <Stack height="100%" useFlexGap gap={4} justifyContent="center">
              <Text
                style={{ fontSize: 48 }}
                variant="headline"
                text={
                  knowledgeMastery
                    ? `${Math.round(knowledgeMastery?.overall_mastery * 100) / 4}%`
                    : '0%'
                }
                component="div"
              />
              <LineGraph
                height={44}
                width="auto"
                format={
                  timespan === 'Today' || timespan === 'Yesterday' ? 'yyyy-MM-dd:hh' : 'yyyy-MM-dd'
                }
                data={knowledgeMastery?.stats_history?.[0].data_points}
              />
            </Stack>
          </Panel>
        </Grid>
        {topicGaps?.weakest?.length || questionGaps?.weakest?.length ? (
          <Grid item md={6} sm={12} xs={12}>
            <StrongestWeakest
              style={{ height: '100%' }}
              type="Weakest"
              variant="medium"
              topics={topicGaps?.weakest}
              questions={questionGaps?.weakest}
            />
          </Grid>
        ) : null}
        {topicGaps?.strongest?.length || questionGaps?.strongest?.length ? (
          <Grid item md={6} sm={12} xs={12}>
            <StrongestWeakest
              style={{ height: '100%' }}
              type="Strongest"
              variant="medium"
              topics={topicGaps?.strongest}
              questions={questionGaps?.strongest}
            />
          </Grid>
        ) : null}
        <Grid item sm={12} xs={12}>
          <Panel title="Mastery Distribution">
            <Stack gap={3} direction="row" flexWrap="wrap">
              <Mastery
                variant="green"
                percentage={knowledgeMastery?.most}
                message={`${knowledgeMastery?.most_user_count} ${
                  knowledgeMastery?.most_user_count > 1 || knowledgeMastery?.most_user_count === 0
                    ? 'people have'
                    : 'person has'
                } mastered most of their questions.`}
              />
              <Mastery
                variant="yellow"
                percentage={knowledgeMastery?.some}
                message={`${knowledgeMastery?.some_user_count} ${
                  knowledgeMastery?.some_user_count > 1 || knowledgeMastery?.some_user_count === 0
                    ? 'people have'
                    : 'person has'
                } mastered some of their questions.`}
              />
              <Mastery
                variant="red"
                percentage={knowledgeMastery?.few}
                message={`${knowledgeMastery?.few_user_count} ${
                  knowledgeMastery?.few_user_count > 1 || knowledgeMastery?.few_user_count === 0
                    ? 'people have'
                    : 'person has'
                } mastered few of their questions.`}
              />
            </Stack>
          </Panel>
        </Grid>
      </Grid>
    </>
  )
})
