import { ApiResponse, ApisauceInstance } from 'apisauce'
import { handleResponse } from '../handleResponse'
import { Instance } from 'mobx-state-tree'

export const TaskApi = {
  apisauce: {} as ApisauceInstance,

  /**
   * Save async task
   */
  saveTasks: async (tasks: [{ object_id: number; object_type: 'CustomDataView' }]) => {
    const response: ApiResponse<any> = await TaskApi.apisauce.post(`/tasks/`, {
      key_objects: tasks,
    })
    return handleResponse(response)
  },

  /**
   * Get async task result by id
   */
  getResults: async (id: string) => {
    const response: ApiResponse<any> = await TaskApi.apisauce.get(`/tasks/${id}/results`)

    return handleResponse(response)
  },
}
