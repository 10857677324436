import React, { useState } from 'react'
import { observer } from 'mobx-react-lite'
import { Divider, Stack } from '@mui/material'
import { useRouteMatch } from 'react-router-dom'

import { DateFilter } from '@trivie/ui-web/components/date-filter'
import { Insight } from '@trivie/ui-web/components/insight'
import { Mastery } from '@trivie/ui-web/components/mastery'
import { Punchcard } from '@trivie/ui-web/components/punchcard'
import { Text } from '@trivie/ui-web/components/text'
import { GenerateReport } from '@trivie/ui-web/components/generate-report'
import { StrongestWeakest } from '@trivie/ui-web/components/strongest-weakest'
import { useStores } from '@trivie/core'
import { Panel } from '@trivie/ui-web/components/panel/panel'

const Overview: React.FunctionComponent = observer(() => {
  const { tagStore } = useStores()
  const {
    refreshOverviewData,
    overviewStats,
    accomplishingStats,
    hourlyActivity,
    knowledgeGainStats,
    knowledgeGapStats,
    setSelectedTagId,
    mastery,
  } = tagStore

  const match = useRouteMatch<any>('/people/tags/:id/overview')

  const [timespan, setTimespan] = useState('All Time')

  const onDateChange = (start, end, label) => {
    setTimespan(label as any)
    setSelectedTagId(Number(match?.params.id))
    refreshOverviewData(start, end)
  }

  return (
    <>
      <Stack mb={2} direction="row" justifyContent="space-between" alignItems="center">
        <DateFilter onDateChange={onDateChange} />
        <GenerateReport filename="tag-detail" />
      </Stack>
      <Stack useFlexGap gap={2}>
        <Panel title="How much are people with this tag learning?">
          <Stack gap={2} direction="row" flexWrap="wrap">
            <Insight
              timespan={timespan}
              data={knowledgeGainStats?.stats_history[2].data_points}
              title="Baseline Knowledge"
              unit="%"
              value={`${knowledgeGainStats?.starting_knowledge?.toFixed(1) ?? '0'}%`}
            />
            <Insight
              timespan={timespan}
              data={knowledgeGainStats?.stats_history[1].data_points}
              title="Current Knowledge"
              unit="%"
              value={`${knowledgeGainStats?.current_knowledge?.toFixed(1) ?? '0'}%`}
            />
            <Insight
              data={knowledgeGainStats?.stats_history[0].data_points}
              title="Knowledge Gain"
              unit="%"
              value={`${knowledgeGainStats?.knowledge_gain?.toFixed(1) ?? '0'}%`}
            />
          </Stack>
          <Text
            variant="body2Medium"
            color="text.secondary"
            text="We measure each person's baseline knowledge by their first answer to each question. As they answer more questions, their current knowledge rises, resulting in knowledge gain."
          />
        </Panel>
        <Stack useFlexGap gap={2} flexWrap="wrap" direction="row">
          <Panel sx={{ flex: 1 }}>
            <Insight
              timespan={timespan}
              data={overviewStats?.stats_history[0].data_points}
              title="Active Users"
              outlined
              unit=" Users"
              value={overviewStats?.active_users ?? '0'}
              style={{ marginRight: 20 }}
            />
          </Panel>
          <Panel sx={{ flex: 1 }}>
            <Insight
              timespan={timespan}
              data={overviewStats?.stats_history[1].data_points}
              title="Average Mastery"
              outlined
              value={`${overviewStats?.mastery?.toFixed(1) ?? 0}`}
              unit=""
              ofVal="/ 4"
              style={{ marginRight: 20 }}
            />
          </Panel>
          <Panel sx={{ flex: 1 }}>
            <Insight
              timespan={timespan}
              data={overviewStats?.stats_history[2].data_points}
              title="Correct Answer %"
              unit="%"
              value={`${overviewStats?.pct_correct?.toFixed(1) ?? 0} %`}
              outlined
            />
          </Panel>
        </Stack>
        <Stack useFlexGap gap={2} flexWrap="wrap" direction="row">
          <Panel style={{ flex: 1 }} title="What are people with this tag accomplishing?">
            <Insight
              sx={{ display: 'flex' }}
              timespan={timespan}
              unit=" Questions"
              data={accomplishingStats?.stats_history[0].data_points}
              title="Questions Answered"
              value={accomplishingStats?.questions_answered ?? '0'}
            />
            <Divider />
            <Insight
              sx={{ display: 'flex' }}
              timespan={timespan}
              unit=" Quizzes"
              data={accomplishingStats?.stats_history[1].data_points}
              title="Quizzes Completed"
              value={accomplishingStats?.quizzes_completed ?? '0'}
            />
            <Divider />
            <Insight
              sx={{ display: 'flex' }}
              timespan={timespan}
              unit=" Sources"
              data={accomplishingStats?.stats_history[2].data_points}
              title="Sources Viewed"
              value={accomplishingStats?.resources_viewed ?? '0'}
            />
          </Panel>
          <Panel style={{ flex: 2 }} divider title="When are people with this tag learning most?">
            <Punchcard values={hourlyActivity} />
          </Panel>
        </Stack>
        <Panel title="Mastery Distribution">
          <Stack gap={3} direction="row" flexWrap="wrap">
            <Mastery
              variant="green"
              percentage={mastery?.most}
              message={`${mastery?.most_user_count} people with this tag have mastered most of their questions.`}
            />
            <Mastery
              variant="yellow"
              percentage={mastery?.some}
              message={`${mastery?.some_user_count} people with this tag have mastered some of their questions.`}
            />
            <Mastery
              variant="red"
              percentage={mastery?.few}
              message={`${mastery?.few_user_count} people with this tag have mastered few of their questions.`}
            />
          </Stack>
        </Panel>
        <Stack flexWrap="wrap" direction="row" useFlexGap gap={2}>
          {knowledgeGapStats?.weakest_topics?.length ||
          knowledgeGapStats?.weakest_questions?.length ? (
            <StrongestWeakest
              type="Weakest"
              variant="medium"
              topics={knowledgeGapStats?.weakest_topics}
              questions={knowledgeGapStats?.weakest_questions}
              style={{ flex: 1 }}
            />
          ) : null}
          {knowledgeGapStats?.strongest_topics?.length ||
          knowledgeGapStats?.strongest_questions?.length ? (
            <StrongestWeakest
              type="Strongest"
              variant="medium"
              topics={knowledgeGapStats?.strongest_topics}
              questions={knowledgeGapStats?.strongest_questions}
              style={{ flex: 1 }}
            />
          ) : null}
        </Stack>
      </Stack>
    </>
  )
})

export const TagsOverviewTab = (props: any) => {
  const { id } = props
  return {
    name: 'Overview',
    path: `/people/tags/${id}/overview`,
    component: () => <Overview />,
  }
}
