import React from 'react'
import { observer } from 'mobx-react-lite'
import { Stack } from '@mui/material'
import { Instance } from 'mobx-state-tree'

import { TopicPctCorrectSummary } from '@trivie/core/src/models/TopicPctCorrectSummary'
import { QuestionPctCorrectSummary } from '@trivie/core/src/models/QuestionPctCorrectSummary'
import { QuizPctCorrectSummary } from '@trivie/core/src/models/QuizPctCorrectSummary'

import { Text } from '../text'
import { TopicProgress } from '../topic-progress'
import { QuestionStat } from '../question-stat'
import { QuizProgress } from '../quiz-progress/quiz-progress'
import { Paywall } from '../paywall/paywall'
import { Panel } from '../panel/panel'

interface StrongestWeakestProps {
  topics?: Array<Instance<typeof TopicPctCorrectSummary>>
  quizzes?: Array<Instance<typeof QuizPctCorrectSummary>>
  questions?: Array<Instance<typeof QuestionPctCorrectSummary>>
  type: 'Strongest' | 'Weakest'
  variant: 'small' | 'medium'
  freeTier?: boolean
  style?: React.CSSProperties
}

export const StrongestWeakest = observer((props: StrongestWeakestProps) => {
  const { style, topics, freeTier, quizzes, questions, type, variant } = props
  return (
    <>
      <Panel style={style} title={topics?.length ? `${type} Topics` : ''}>
        {freeTier && <div style={OVERLAY} />}
        {topics?.length || quizzes?.length ? (
          <Stack useFlexGap gap={3} mb={questions?.length ? 2 : 0}>
            {topics?.map((t) => (
              <TopicProgress
                key={String(t?.topic_id)}
                gradient={type === 'Strongest' ? 'green' : 'red'}
                small={variant === 'small'}
                topic={t}
              />
            ))}
            {quizzes?.length ? (
              <Text component="div" variant="titleEmphasized" text={`${type} Quizzes`} />
            ) : null}
            {quizzes?.map((q) => (
              <QuizProgress gradient={type === 'Strongest' ? 'green' : 'red'} quiz={q} />
            ))}
          </Stack>
        ) : null}
        {questions?.length ? (
          <Stack useFlexGap gap={2}>
            <Text
              component="div"
              variant="titleEmphasized"
              text={freeTier ? 'Knowledge Gaps' : `${type} Questions`}
            />
            {questions?.map((q) => (
              <QuestionStat
                key={String(q?.question_id)}
                small={variant === 'small'}
                strongest={type === 'Strongest'}
                question={q}
              />
            ))}
          </Stack>
        ) : null}
        {freeTier && <Paywall plan="Standard" tagline="to identify more knowledge gaps" />}
      </Panel>
    </>
  )
})

const OVERLAY: React.CSSProperties = {
  position: 'absolute',
  bottom: 0,
  height: '100%',
  width: '100%',
  left: 0,
  borderBottomRightRadius: 20,
  borderBottomLeftRadius: 20,
  background: 'linear-gradient(180deg, rgba(217, 217, 217, 0.00) 0%, #F4F4F4 64.19%)',
}
