import React from 'react'
import { Link } from 'react-router-dom'
import { Instance } from 'mobx-state-tree'
import { Stack } from '@mui/material'

import { TopicPctCorrectSummary } from '@trivie/core/src/models/TopicPctCorrectSummary'
import { Meter } from '../meter'
import { Image } from '../image'
import { Text } from '../text'
import defaultCover from './default-topic-image.png'

export interface TopicProgressProps {
  topic: Instance<typeof TopicPctCorrectSummary>
  small?: boolean
  gradient: 'red' | 'green'
}

export function TopicProgress(props: TopicProgressProps) {
  const { topic, small, gradient } = props

  return (
    <Stack flex={1} gap={2} direction="row" key={`${topic.topic_id}`}>
      <Link style={{ textDecoration: 'none' }} to={`/content/topics/${topic.topic_id}/overview`}>
        <Image src={topic.image_url || defaultCover} style={{ minWidth: 72 }} />
      </Link>
      <Stack flex={1} direction="column" useFlexGap>
        <Link to={`/content/topics/${topic.topic_id}/overview`} style={{ textDecoration: 'none' }}>
          <Text variant="body1Emphasized" text={topic.title ?? ''} />
        </Link>
        <Stack useFlexGap gap={2} direction="row" flex={1} alignItems="center">
          <Meter
            variant={gradient === 'red' ? 'weak' : 'strong'}
            value={topic.pct_correct || 0}
            style={{ height: '6px', flex: 1 }}
          />
          <Text
            color="text.secondary"
            variant="body1Emphasized"
            text={`${topic.pct_correct || 0}% ${small ? '' : 'Correct'}`}
          />
        </Stack>
      </Stack>
    </Stack>
  )
}
