import { types } from 'mobx-state-tree'
import { QuestionsForQuizStartModel } from '../question/QuestionsForQuizStartModel'
import { QuizForStartModel } from './QuizForStartModel'
import { ResourceReadModel } from '../resource/ResourceReadModel'
import { ResultForQuizStartModel } from './ResultForQuizStartModel'

/**
 * QuizStartResponse Model
 */
export const QuizStartResponseModel = types.model('QuizStartResponse').props({
  __typename: types.optional(types.literal('QuizStartResponse'), 'QuizStartResponse'),
  assessment: types.boolean,
  questions: types.array(
    types.union(
      types.null,
      types.late(() => QuestionsForQuizStartModel),
    ),
  ),
  quiz: types.late(() => QuizForStartModel),
  resources: types.union(
    types.null,
    types.array(
      types.union(
        types.null,
        types.late(() => ResourceReadModel),
      ),
    ),
  ),
  result: types.late(() => ResultForQuizStartModel),
})
