import React, { useEffect, useState } from 'react'
import {
  Add,
  CheckBox,
  Error,
  RadioButtonChecked,
  ThumbsUpDown,
  Warning,
} from '@mui/icons-material'
import { Collapse, LinearProgress, MenuItem, Select, Stack, TextField } from '@mui/material'
import { observer } from 'mobx-react-lite'
import { Instance, getNodeId, getParentOfType } from 'mobx-state-tree'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'

import { Question, isQuestionType, useStores } from '@trivie/core'
import { QuestionChoice } from '@trivie/core/src/models/QuestionChoice'
import { Quiz } from '@trivie/core/src/models/Quiz'
import { OnboardingTooltip } from '../tooltip/onboarding-tooltip'
import { Button } from '../button'

import { color } from '../../theme/color'
import { FileInput } from '../file-input'
import { ImageSelector } from '../image-selector/image-selector'
import { Text } from '../text'
import { ChoiceField } from './choice-field'
import { StatusBar } from './status-bar/status-bar'
import { Switch } from '../switch/switch'
import { Toggle } from '../toggle/toggle'
import { ExpandMore } from '../expand-button/expand-button'

export type QuestionFormProps = {
  question: Instance<typeof Question> | null
  onSelectionChange?: any
  edit?: boolean
}

export const QuestionForm = observer((props: QuestionFormProps) => {
  const { question } = props
  const { manageUserStore, contentCreationStore } = useStores()
  const { enterpriseTier, userProfile } = manageUserStore

  const [draggingChoiceID, setDraggingChoiceID] = useState<null | number>(null)
  const [dropChoiceID, setDropChoiceID] = useState<null | number>(null)
  const [hoveredID, setHoveredID] = useState<null | number>(null)
  const [grabbing, setGrabbing] = useState(false)
  const [showSettings, setShowSettings] = useState(
    localStorage.getItem('showQuestionSettings') === 'true',
  )
  const [hovered, setHovered] = useState<Instance<typeof QuestionChoice> | null>(null)

  let parentQuiz
  try {
    const p = getParentOfType(question, Quiz)
    if (p) {
      parentQuiz = p
    }
  } catch (e) {
    console.warn('no parent quiz')
  }

  useEffect(() => {
    if (parentQuiz?.generationCount === 0) {
      parentQuiz.setGeneratingQuestions(false)
    }
  }, [parentQuiz?.generationCount])

  const handleDrag = (choice: Instance<typeof QuestionChoice>) =>
    setDraggingChoiceID(getNodeId(choice))

  const handleDragOver = (choice: Instance<typeof QuestionChoice>) =>
    setDropChoiceID(getNodeId(choice))

  const handleDrop = (choice: Instance<typeof QuestionChoice>) => {
    setDropChoiceID(null)
    setGrabbing(false)

    const dragChoice = question?.choices?.find((c) => getNodeId(c) === draggingChoiceID)
    const dropChoice = question?.choices?.find((c) => getNodeId(c) === getNodeId(choice))

    if (dragChoice && dropChoice) {
      question?.reorderChoices(
        question?.choices?.indexOf(dragChoice) ?? 0,
        question?.choices?.indexOf(dropChoice) ?? 0,
      )
    }
  }

  const handleSettings = () => {
    if (localStorage.getItem('showQuestionSettings') === 'true') {
      localStorage.removeItem('showQuestionSettings')
      setShowSettings(false)
    } else {
      localStorage.setItem('showQuestionSettings', 'true')
      setShowSettings(true)
      setTimeout(() => {
        document.getElementById('QuestionSettings')?.scrollIntoView({ behavior: 'smooth' })
      }, 400)
    }
  }

  const getStatusMessage = () => {
    if (question?.allErrors && question?.allErrors.length > 0) {
      if (question?.allWarnings.length > 0) {
        return `${question?.allErrors.length} error${
          question?.allErrors.length > 1 ? 's' : ''
        } and ${question?.allWarnings.length} warning${question?.allWarnings.length > 1 ? 's' : ''}`
      } else if (question?.allWarnings.length === 0) {
        return `${question?.allErrors.length} error${question?.allErrors.length > 1 ? 's' : ''}`
      }
    } else if (question?.allWarnings && question?.allWarnings.length > 0 && !question?.approved) {
      return `${question?.allWarnings.length} warning${question?.allWarnings.length > 1 ? 's' : ''}`
    } else if (question?.allWarnings.length && question?.approved) {
      return `Approved with ${question?.allWarnings.length} warning${
        question?.allWarnings.length > 1 ? 's' : ''
      }`
    } else if (question?.approved) {
      return `Approved`
    } else {
      return `Ready for review`
    }
    return 'No errors or warnings'
  }

  const rewriteAnswer = async (choice: Instance<typeof QuestionChoice>) => {
    choice.set('loading', true)

    const choices = await question?.generateChoices(1, true, [choice.text])
    if (choices?.length) {
      // Note: may still need to increase count and double check for matching choice text
      // const distractor = distractors.filter((d) => d.text !== choice.text)[0]
      choice.set('text', choices[0].text)
    }

    choice.set('loading', false)
  }

  const mercuryEnabled = userProfile?.workspace_settings?.mercury_enabled

  const changeQuestionType = (e: any) => {
    const qType = String(e.target.value)
    if (qType === 'True or False') {
      question?.setShuffleOrder(true)
    }
    if (isQuestionType(qType)) {
      question?.setQuestionType(qType)
    }
  }

  if (!question) {
    return null
  }

  return (
    <div style={{ minWidth: 400, maxWidth: 600, width: '100%' }}>
      {(question?.touched || question?.generatingAll) && (
        <StatusBar
          expanded={Boolean(question?.showStatus)}
          handleExpandClick={() => question?.set('showStatus', !question?.showStatus)}
          expandable={
            !parentQuiz?.generatingQuestions &&
            !question?.generatingAll &&
            Boolean(
              ((question?.allErrors && question?.allErrors.length) ?? 0) +
                ((question?.allWarnings && question?.allWarnings.length) ?? 0) >
                0,
            )
          }
          message={mercuryEnabled && question?.generatingAll ? 'Generating...' : getStatusMessage()}
          style={{ marginBottom: 12, alignSelf: 'center', width: '100%' }}
          forceClose={question?.generatingAll}
          type={
            mercuryEnabled && question?.generatingAll
              ? 'generating'
              : question?.approved
                ? 'success'
                : question?.allErrors.length
                  ? 'error'
                  : question?.allWarnings.length || !question?.approved
                    ? 'warning'
                    : 'info'
          }
        >
          {question?.allErrors.map((e: any) => {
            return (
              <div style={ISSUE}>
                <Error style={{ fontSize: 14, marginRight: 12 }} htmlColor={color.red} />
                <Text style={{ color: color.shade80 }} variant="body2Medium" text={e.message} />
              </div>
            )
          })}
          {question?.allWarnings.map((e: any) => {
            return (
              <div style={ISSUE}>
                <Warning style={{ fontSize: 14, marginRight: 12 }} htmlColor={color.yellow} />
                <Text style={{ color: color.shade80 }} variant="body2Medium" text={e.message} />
              </div>
            )
          })}
        </StatusBar>
      )}
      <div
        id="QuestionForm"
        style={{
          ...QUESTION_FORM,
          minHeight: `calc(100% ${
            question?.touched || question?.generatingAll ? '- 53px' : '+ 7px'
          })`,
        }}
      >
        <div style={{ display: 'flex', marginBottom: '16px', gap: 10, flexDirection: 'row' }}>
          <Select
            variant="standard"
            displayEmpty
            IconComponent={ExpandMoreIcon}
            value={question?.question_type}
            onChange={changeQuestionType}
            style={{ width: '100%' }}
            sx={SELECT}
          >
            <MenuItem value="Multiple Choice">
              <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                <RadioButtonChecked
                  sx={{ marginRight: '12px' }}
                  htmlColor={color.shade70}
                  fontSize="small"
                />
                <Text
                  style={{ color: color.shade70 }}
                  variant="body2Emphasized"
                  text="Multiple Choice"
                />
              </div>
            </MenuItem>
            <MenuItem value="True or False">
              <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                <ThumbsUpDown
                  sx={{ marginRight: '12px' }}
                  htmlColor={color.shade70}
                  fontSize="small"
                />
                <Text
                  style={{ color: color.shade70 }}
                  variant="body2Emphasized"
                  text="True / False"
                />
              </div>
            </MenuItem>
            <MenuItem value="Multiple Response">
              <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                <CheckBox sx={{ marginRight: '12px' }} htmlColor={color.shade70} fontSize="small" />
                <Text style={{ color: color.shade70 }} variant="body2Emphasized" text="Checkbox" />
              </div>
            </MenuItem>
          </Select>
          <Toggle
            leftText="Basic"
            rightText="Advanced"
            onLeftClick={() => question?.set('advanced', false)}
            onRightClick={() => question?.set('advanced', true)}
            defaultSelection={question?.advanced ? 'Advanced' : 'Basic'}
          />
        </div>
        {question?.image ? (
          <ImageSelector
            imageSrc={question?.image}
            altText={question?.alt_text ?? ''}
            imageStyle={{ minWidth: '100%', height: '100%', objectFit: 'contain', maxHeight: 400 }}
            onAltTextChange={(text: string) => {
              question?.set('approved', false)
              question?.set('alt_text', text)
            }}
            onChange={(f: File | null) => {
              if (!f) {
                question?.set('alt_text', '')
              }
              question?.setImage(f)
            }}
          />
        ) : (
          <FileInput
            creatingQuestion
            style={{
              marginBottom: 24,
              height: 48,
              padding: '8px 12px 8px 12px',
              backgroundColor: '#fff',
            }}
            accept="image/png;image/jpeg;image/gif"
            mainText="or drag an image here."
            helperText="JPG, PNG, or GIF - max size 5 MB"
            onChange={(f) => question?.setImage(f)}
            onClose={(e: any) => {
              e.preventDefault()
              e.stopPropagation()
            }}
          />
        )}
        <div style={{ marginBottom: 16, position: 'relative' }}>
          <TextField
            sx={{
              '& .MuiInputBase-root': {
                textDecoration: question?.rewriting ? 'line-through' : 'none',
                ...(question?.text.length >= 200
                  ? {
                      fontWeight: 600,
                      fontFamily: 'Inter',
                      fontSize: 14,
                      lineHeight: '18px',
                    }
                  : {
                      fontSize: 17,
                      fontFamily: 'Inter',
                      fontWeight: 600,
                      lineHeight: '22px',
                    }),
                paddingBottom: '12px',
                // marginBottom: '16px',
              },
              '& .MuiInput-underline:after': {
                borderBottomColor: question?.generating
                  ? color.lightPurple
                  : question?.text?.length === 0 && question?.touched
                    ? color.red
                    : question?.text && question?.text.length > 1000
                      ? color.yellow
                      : color.blue,
              },
              '& .MuiInput-underline:before': {
                borderBottomColor:
                  question?.generating && !question?.rewriting
                    ? color.lightPurple
                    : question?.text?.length === 0 && question?.touched
                      ? color.red
                      : question?.text && question?.text.length > 1000
                        ? color.yellow
                        : color.shade5,
              },
            }}
            style={{ width: '100%' }}
            value={question?.text}
            multiline
            variant="standard"
            placeholder="Enter question text"
            inputProps={{ 'aria-label': 'Enter question text here' }}
            onChange={(e) => {
              question?.set('text', e.target.value)
            }}
            onBlur={(e) => {
              question?.set('approved', false)
              question?.set('touched', true)
            }}
          />
          {question?.text && question?.text.length >= 800 && (
            <Text
              variant="body2"
              text={`${question?.text?.length ?? 0}/1000`}
              style={{
                fontWeight: 700,
                position: 'absolute',
                bottom: 4,
                right: 4,
                color: question?.text?.length > 1000 ? color.palette.yellow : color.shade30,
              }}
            />
          )}
          {question.rewriting && (
            <LinearProgress
              sx={{
                top: '-1px',
                '&.MuiLinearProgress-root': {
                  backgroundColor: `${color.purple}20`,
                  height: '2px',
                },
                '&.MuiLinearProgress-root > .MuiLinearProgress-bar': {
                  background: `linear-gradient(40deg, #FF00FF -17.26%, #9116F9 49.66%, #3199FF 99.55%) !important`,
                },
              }}
            />
          )}
        </div>
        <div style={{ marginBottom: 28 }}>
          {(question?.question_type === 'Multiple Choice' ||
            question?.question_type === 'True or False') && (
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              {question?.choices.map((choice, index) => {
                return (
                  <ChoiceField
                    key={`choice-${index}`}
                    choice={choice}
                    question={question}
                    handleDragOver={handleDragOver}
                    handleDrag={handleDrag}
                    setGrabbing={setGrabbing}
                    handleDrop={handleDrop}
                    grabbing={grabbing}
                    setHovered={setHovered}
                    setHoveredID={setHoveredID}
                    setDropChoiceID={setDropChoiceID}
                    hoveredID={hoveredID}
                    dropChoiceID={dropChoiceID}
                    mercuryEnabled={mercuryEnabled}
                    hovered={hovered}
                    rewrite={rewriteAnswer}
                    index={index}
                  />
                )
              })}
            </div>
          )}
          {question?.question_type === 'Multiple Response'
            ? question?.choices.map((choice, index) => (
                <ChoiceField
                  key={`choice-${index}`}
                  choice={choice}
                  question={question}
                  handleDragOver={handleDragOver}
                  handleDrag={handleDrag}
                  setGrabbing={setGrabbing}
                  handleDrop={handleDrop}
                  grabbing={grabbing}
                  setHovered={setHovered}
                  setHoveredID={setHoveredID}
                  setDropChoiceID={setDropChoiceID}
                  hoveredID={hoveredID}
                  dropChoiceID={dropChoiceID}
                  mercuryEnabled={mercuryEnabled}
                  hovered={hovered}
                  rewrite={rewriteAnswer}
                  index={index}
                />
              ))
            : null}
          {question?.question_type !== 'True or False' &&
            question?.choices &&
            question?.choices.length <= 3 && (
              <Button
                variant="outlined"
                color="info"
                startIcon={<Add />}
                onClick={() => {
                  question?.addNewChoice()
                }}
                sx={{ marginTop: '12px', marginLeft: '19px' }}
                text="Add Choice"
              />
            )}
        </div>
        <div style={{ marginBottom: 16 }}>
          <Text
            component="div"
            variant="body2Emphasized"
            text="Feedback"
            style={{ marginBottom: 8, color: color.shade50 }}
          />
          <div style={{ position: 'relative' }}>
            <TextField
              disabled={question?.factoid?.generating}
              sx={{
                '& .MuiInputBase-root': {
                  fontFamily: 'Inter',
                  paddingBottom: '12px',
                  fontSize: 12,
                  fontWeight: 400,
                  color: color.shade70,
                  textDecoration: question?.factoid?.generating ? 'line-through' : 'none',
                },
                '& .MuiInput-underline:after': {
                  borderBottomColor: question?.factoid?.generating
                    ? color.lightPurple
                    : question?.formWarnings.feedback
                      ? color.yellow
                      : color.blue,
                },
                '& .MuiInput-underline:before': {
                  borderBottomColor: question?.factoid?.generating
                    ? color.lightPurple
                    : question?.formWarnings.feedback
                      ? color.yellow
                      : color.shade5,
                },
              }}
              InputProps={{
                spellCheck: !question?.generatingAll,
                id: `typewriter-${getNodeId(question)}-factoid`,
              }}
              value={question?.factoid?.text ?? ''}
              onChange={(e) => {
                question?.set('approved', false)
                question?.set('factoid', {
                  factoid_id: question?.factoid?.factoid_id ?? null,
                  text: String(e.target.value),
                  resource_id: null,
                  modified: true,
                })
              }}
              multiline
              fullWidth
              variant="standard"
              placeholder={
                question?.advanced
                  ? 'Enter text to show the user after they answer the question if no choice-level feedback is available.'
                  : 'Enter text to show the user after they answer the question.'
              }
            />
            {question?.factoid?.text && question?.factoid.text.length >= 800 && (
              <Text
                variant="body2"
                text={`${question?.factoid?.text.length ?? 0}/1000`}
                style={{
                  fontWeight: 700,
                  position: 'absolute',
                  bottom: 0,
                  right: 0,
                  color:
                    question?.factoid?.text.length > 1000 ? color.palette.yellow : color.shade30,
                }}
              />
            )}
            {question?.factoid?.generating && (
              <LinearProgress
                sx={{
                  top: '-1px',
                  '&.MuiLinearProgress-root': {
                    backgroundColor: `${color.purple}20`,
                    height: '2px',
                  },
                  '&.MuiLinearProgress-root > .MuiLinearProgress-bar': {
                    background: `linear-gradient(40deg, #FF00FF -17.26%, #9116F9 49.66%, #3199FF 99.55%) !important`,
                  },
                }}
              />
            )}
          </div>
        </div>
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'center',
            marginBottom: 12,
          }}
        >
          <Text variant="body2Emphasized" text="Settings" style={{ color: color.shade50 }} />
          <ExpandMore expand={showSettings} onClick={handleSettings} />
        </div>
        <Collapse in={showSettings} timeout="auto">
          <div style={{ flexDirection: 'column', display: 'flex' }}>
            {enterpriseTier && (
              <div style={SWITCH_ROW}>
                <div style={{ flexDirection: 'column', gap: 4, display: 'flex' }}>
                  <Text
                    style={{ color: color.shade70 }}
                    variant="body2Emphasized"
                    text="Reinforcement Eligible"
                  />
                  <Text
                    style={{ color: color.shade50 }}
                    variant="body2"
                    text="Include this question in reinforcement quizzes."
                  />
                </div>
                <Switch
                  color="success"
                  checked={Boolean(question?.adaptive_eligible)}
                  onChange={(e) => {
                    question?.set('approved', false)
                    question?.set('adaptive_eligible', e.target.checked)
                  }}
                  size="small"
                />
              </div>
            )}
            {question?.question_type !== 'True or False' && (
              <div id="QuestionSettings" style={{ ...SWITCH_ROW, marginBottom: 20 }}>
                <div style={{ flexDirection: 'column', gap: 4, display: 'flex' }}>
                  <Text
                    style={{ color: color.shade70 }}
                    variant="body2Emphasized"
                    text="Shuffle Answer Order"
                  />
                  <Text
                    style={{ color: color.shade50 }}
                    variant="body2"
                    text="Randomize the order of this question's answers."
                  />
                </div>
                <Switch
                  color="success"
                  checked={Boolean(question?.shuffle)}
                  onChange={(e) => question?.setShuffleOrder(e.target.checked)}
                  size="small"
                />
              </div>
            )}
            <Stack direction="row" justifyContent="space-between" alignItems="center">
              <Stack useFlexGap gap={0.5}>
                <Text variant="body2Emphasized" text="External ID" />
                <Text
                  style={{ color: color.shade50 }}
                  variant="body2"
                  text="Refer to this question outside of Trivie."
                />
              </Stack>
              <TextField
                variant="standard"
                sx={{ minWidth: 160 }}
                placeholder="External ID"
                value={question?.external_id || ''}
                onChange={(e) => {
                  if (e.target.value.length <= 26) {
                    question?.set('external_id', e.target.value)
                  }
                }}
              />
            </Stack>
          </div>
        </Collapse>
      </div>
    </div>
  )
})

const QUESTION_FORM: React.CSSProperties = {
  background: '#fff',
  borderRadius: 12,
  border: `1px solid #EFF0F6`,
  padding: 20,
}
const SWITCH_ROW: React.CSSProperties = {
  display: 'flex',
  marginTop: 0,
  marginBottom: 16,
  alignItems: 'center',
  justifyContent: 'space-between',
}
const ISSUE: React.CSSProperties = {
  flexDirection: 'row',
  display: 'flex',
  padding: 12,
  paddingLeft: 24,
}
const SELECT: any = {
  '.MuiSelect-select': {
    '&:focus': { backgroundColor: '#fff' },
    paddingBottom: '8px',
  },
  '&:before': {
    borderBottom: '1px solid rgba(0, 0, 0, 0.05)',
  },
  flexDirection: 'row',
  display: 'flex',
  alignItems: 'center',
  width: '100%',
}
