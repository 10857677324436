import { ListSelectorItem } from '../models/ListSelectorItem'
import { SnapshotIn, types, cast } from 'mobx-state-tree'
import { DateTime } from 'luxon'

export const AssignmentCreateForm = types
  .model('AssignmentCreateForm')
  .props({
    start_date: types.string,
    start_time: types.string,
    end_date: types.string,
    endDateEnabled: types.boolean,
    end_time: types.string,
    title: types.string,
    content: types.array(ListSelectorItem),
    groups: types.frozen(),
    users: types.frozen(),
    paths: types.frozen(),
    attempts: types.maybeNull(types.number),
    attemptsEnabled: types.boolean,
    daysToComplete: types.maybeNull(types.number),
    daysToCompleteEnabled: types.boolean,
    notify: types.boolean,
    include_pre_tests: types.boolean,
    include_post_tests: types.boolean,
  })
  .views((self) => {
    return {
      get selectedTopics() {
        return self.content.filter((t) => {
          return t.checked && t.type === 'topics'
        })
      },
      get selectedPaths() {
        return self.content.filter((t) => {
          return t.checked && t.type === 'learning paths'
        })
      },
      get pathContent() {
        let paths = 0,
          topics = 0,
          sources = 0,
          quizzes = 0
        this.selectedPaths.map((path) => {
          paths++
          path.subItems?.map((si) => {
            switch (si.type) {
              case 'topics':
                topics++
                break
              case 'quizzes':
                quizzes++
                break
              case 'resources':
                sources++
            }
          })
        })
        return { paths, topics, quizzes, sources }
      },
      get topicContent() {
        let topics = 0,
          quizzes = 0
        this.selectedTopics.map((topic) => {
          topics++
          quizzes += topic.subItems?.length ?? 0
        })
        return { topics, quizzes }
      },
      get selectedQuizzes() {
        return self.content.filter((t) => {
          return t.checked && t.type === 'quizzes'
        })
      },

      get selectedGroups() {
        return self.groups.filter((g) => g.checked)
      },

      get errors() {
        const errors: any = {}
        if (!self.title) {
          errors.title = {
            type: 'required',
            message: 'Name is required',
          }
        }

        if (self.title?.length >= 65) {
          errors.title = {
            type: 'required',
            message: 'Maximum of 64 characters',
          }
        }
        if (!self.start_date) {
          errors.start_date = {
            type: 'required',
            message: 'Start Date is required',
          }
        } else if (self.start_date < DateTime.local().toFormat('yyyy-MM-dd')) {
          errors.start_date = {
            type: 'required',
            message: `Start date shouldn't be in the past`,
          }
        }

        if (!self.start_time) {
          errors.start_time = {
            type: 'required',
            message: 'Start Time is required',
          }
        }

        if (!self.end_date) {
          errors.end_date = {
            type: 'required',
            message: 'End Date is required',
          }
        }

        if (!self.end_time) {
          errors.end_time = {
            type: 'required',
            message: 'End Time is required',
          }
        }

        if (!self.daysToComplete) {
          errors.daysToComplete = {
            type: 'required',
            message: 'Days To Complete is required',
          }
        }

        if (self.daysToCompleteEnabled && self.daysToComplete && self.endDateEnabled) {
          const date1 = DateTime.fromISO(self.start_date)
          const date2 = DateTime.fromISO(self.end_date)
          const diff = date2.diff(date1, ['days']).days
          if (diff < self.daysToComplete) {
            errors.daysToCompleteEnabled = {
              type: 'invalid',
              message:
                'Days to complete cannot exceed the number of days between the start date and the end date',
            }
          }
        }

        if (!self.attempts) {
          errors.attempts = {
            type: 'required',
            message: 'Limitation to attempt at quiz is required',
          }
        }

        // no need to validate beyond this since we're missing required fields
        if (Object.keys(errors).length) {
          return errors
        }

        const startDateTime = DateTime.fromISO(`${self.start_date}T${self.start_time}:00`)
        const endDateTime = DateTime.fromISO(`${self.end_date}T${self.end_time}:00`)

        if (!startDateTime.isValid) {
          return {
            start_date: {
              type: 'invalid',
              message: 'Date is Invalid',
            },
          }
        }

        if (!endDateTime.isValid) {
          return {
            end_date: {
              type: 'invalid',
              message: 'Date is Invalid',
            },
          }
        }

        if (endDateTime < startDateTime && self.endDateEnabled) {
          errors.start_date = {
            type: 'startTimeAfterEndTime',
            message: 'Must start before End Date and Time',
          }
          errors.start_time = {
            type: 'startTimeAfterEndTime',
          }
        }

        return errors
      },
    }
  })
  .actions((self) => {
    return {
      set<K extends keyof SnapshotIn<typeof self>, T extends SnapshotIn<typeof self>>(
        key: K,
        value: T[K],
      ) {
        self[key] = cast(value)
      },
    }
  })
