import React, { useEffect, useState } from 'react'
import { DateTime } from 'luxon'
import { observer } from 'mobx-react-lite'
import { useRouteMatch } from 'react-router-dom'
import { Grid, IconButton, Skeleton, Divider, Tab, Tabs, TextField } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import {
  Download,
  Edit,
  Launch,
  KeyboardArrowUp,
  KeyboardArrowDown,
  Close,
  Search,
} from '@mui/icons-material'
import { useEditor, EditorContent } from '@tiptap/react'
import StarterKit from '@tiptap/starter-kit'

import { roundNumber, GetBackgroundColorOfMimeType, useStores } from '@trivie/core'
import { color } from '@trivie/ui-web/theme'
import { Text } from '@trivie/ui-web/components/text'
import { ConfirmDialog } from '@trivie/ui-web/components/confirm-dialog'
import { MimeTypeIcon } from '@trivie/ui-web/components/mime-type-icon'
import { HeaderPanel } from '@trivie/ui-web/components/header-panel'
import { ShortList } from '@trivie/ui-web/components/short-list'
import { ResourceApi } from '@trivie/core/src/services/api-objects'
import { Sentence } from '../content-generation/quiz-generation/components/nodes/paragraph/sentence'
import { Insight } from '@trivie/ui-web/components/insight'

const Mark = require('mark.js')

export const a11yProps = (index: number) => {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  }
}

export const useTabsStyles = makeStyles({
  fixed: {
    display: 'flex',
    justifyContent: 'flex-start',
  },
})

export const useTabStyles = makeStyles({
  // Dropdown container
  root: {
    '&.Mui-selected': {
      color: '#000000',
    },
    position: 'relative',
    padding: '6px 12px',
    overflow: 'hidden',
    maxWidth: '264px',
    minWidth: '0px',
    boxSizing: 'border-box',
    minHeight: '48px',
    textAlign: 'center',
    flexShrink: 0,
    whiteSpace: 'normal',
    textTransform: 'none',
    justifyContent: 'center',
  },
})

interface ResourceTabProps {
  children?: React.ReactNode
  index: number
  value: number
}
export const ResourceTab = observer((props: ResourceTabProps) => {
  const { children, value, index, ...other } = props

  return (
    <div role="tabpanel" hidden={value !== index} {...other}>
      {value === index && <>{children}</>}
    </div>
  )
})

export const ResourcesDetailsLiteScreen = observer(() => {
  const { manageResourceStore, manageUserStore } = useStores()

  const { permissions } = manageUserStore
  const { getRelatedQuizzes, relatedQuizzes } = manageResourceStore
  const [showEdit, setShowEdit] = useState(false)
  const [isLoadingQuiz, setIsLoadingQuiz] = useState(true)
  const [selectedResource, setSelectedResource] = useState<any>()
  const [resourceDocument, setResourceDocument] = React.useState<any>()
  const [searchText, setSearchText] = useState<string | null>()

  const tabsClasses = useTabsStyles()
  const tabClasses = useTabStyles()

  const [value, setValue] = React.useState(0)
  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue)
  }

  const { params } = useRouteMatch()
  // @ts-ignore
  const { id } = params

  const editor = useEditor({
    editable: false,
    editorProps: {
      attributes: {
        class: 'resource-details',
      },
    },
    extensions: [
      StarterKit.configure({ heading: { levels: [1, 2, 3] } }),
      Sentence.configure({ quiz: {}, selectQuestion: (q) => {} }),
    ],
    content: document ?? {},
  })

  const [loadingSummary, setLoadingSummary] = useState(true)
  const [loadingKeywords, setLoadingKeywords] = useState(true)
  const [summary, setSummary] = useState('')
  const [keywords, setKeywords] = useState([])

  useEffect(() => {
    getResourceDetails(id)
    getRelatedQuizzes(id)
    getKeywords(id)
    getSummary(id)
    ResourceApi.getResourceDocument(id)
      .then((resp) => {
        const doc = resp.data
        if (doc) {
          doc.nodes.content.unshift({
            type: 'heading',
            content: [
              {
                text: doc.title,
                type: 'text',
              },
            ],
            attrs: {
              level: 1,
            },
          })
          setResourceDocument(doc?.nodes)
        }
      })
      .catch((e) => setResourceDocument(null))
  }, [])

  const getKeywords = (sourceID) => {
    setLoadingKeywords(true)
    ResourceApi.getKeywords(sourceID).then((resp: any) => {
      setLoadingKeywords(false)
      setKeywords(resp.data.keywords)
    })
  }
  const getSummary = (sourceID) => {
    setLoadingSummary(true)
    ResourceApi.getSummary(sourceID).then((resp: any) => {
      setLoadingSummary(false)
      setSummary(resp.data.summary)
    })
  }

  useEffect(() => {
    if (resourceDocument && editor) {
      setTimeout(() => {
        editor?.commands.setContent({ type: 'doc', content: resourceDocument.content })
      }, 0)
    }
  }, [resourceDocument])

  const getResourceDetails = async (sourceID) => {
    setSelectedResource(await ResourceApi.getAllResourcesOrSingleById(Number(sourceID)))
    setIsLoadingQuiz(false)
  }

  const preview = (url: string) => {
    window.open(url, '_blank', 'noopener')
  }

  const download = (url: string) => {
    fetch(url).then(function (t) {
      return t.blob().then((b) => {
        const a = document.createElement('a')
        a.href = URL.createObjectURL(b)
        a.setAttribute('download', selectedResource?.title)
        a.click()
      })
    })
  }

  const headerActions = [
    {
      hide: selectedResource?.mime_type === 'url',
      label: 'Download',
      onClick: () => download(selectedResource?.download_url),
      icon: <Download htmlColor={color.shade70} />,
    },
    {
      label: 'Preview',
      onClick: () =>
        preview(
          selectedResource?.mime_type === 'url'
            ? selectedResource?.filename_or_url
            : selectedResource?.pdf_url,
        ),
      icon: <Launch htmlColor={color.shade70} />,
    },
    {
      primary: true,
      disabled: !permissions?.permissions.some((p) => p.code === 'can_edit_quiz'),
      label: 'Edit',
      onClick: () => setShowEdit(true),
      icon: <Edit htmlColor={color.white} />,
    },
  ]
  const [marks, setMarks] = useState<any>()

  const markInstance = new Mark(document.querySelector('.ProseMirror'))
  const search = (keyword) =>
    markInstance.unmark({
      done: function () {
        markInstance.mark(keyword.trim(), {
          separateWordSearch: false,
          accuracy: 'complementary',
          diacritics: true,
          debug: false,
          done: () => {
            setMarks(document.querySelectorAll('mark'))
          },
        })
      },
    })

  const [selectedMark, setSelectedMark] = useState()

  const page = (next: boolean) => {
    if (marks && marks.length > 0) {
      const idx = marks?.length === 1 ? 0 : next ? 1 : -1
      const selected = marks[Array.prototype.slice.call(marks).indexOf(selectedMark) + idx]
      marks.forEach((mark) => mark.setAttribute('class', ''))
      if (selected) {
        selected.setAttribute('class', 'SelectedKeyword')
        selected.scrollIntoView({ behavior: 'smooth', inline: 'center', block: 'center' })
        setSelectedMark(selected)
      }
    }
  }

  const onSearchChange = (e) => {
    setSearchText(e.target.value)
    search(e.target.value)
  }

  return (
    <>
      <HeaderPanel
        containerStyle={{
          background: GetBackgroundColorOfMimeType(selectedResource?.extension),
          justifyContent: 'center',
          height: 160,
          paddingLeft: 30,
          paddingRight: 30,
        }}
        loading={!selectedResource}
        actions={headerActions}
        footer={
          <div style={{ display: 'flex', gap: 24 }}>
            {selectedResource?.mime_type && (
              <div style={{ alignSelf: 'center' }}>
                <MimeTypeIcon
                  mimeType={selectedResource?.mime_type}
                  filename={selectedResource?.mime_type}
                  width={40}
                  height={40}
                  white
                />
              </div>
            )}
            <div style={{ flexDirection: 'column', display: 'flex' }}>
              <Text style={TITLE_TEXT} variant="headline" text={selectedResource?.title} />
              <Text
                style={SUBTITLE_TEXT}
                variant="body1Medium"
                text={selectedResource?.filename_or_url}
              />
              <Text
                style={SUBTITLE_TEXT}
                variant="body1Medium"
                text={`Uploaded ${DateTime.fromISO(selectedResource?.created_at).toFormat(
                  'MMM d, yyyy',
                )}`}
              />
            </div>
          </div>
        }
      />
      <Tabs
        selectionFollowsFocus
        variant="standard"
        indicatorColor="primary"
        classes={{ ...tabsClasses }}
        value={value}
        onChange={handleChange}
        aria-labelledby="a11y-tabs-automatic-label"
      >
        <Tab
          disableRipple
          classes={tabClasses}
          label="Overview"
          style={{ marginRight: 8 }}
          {...a11yProps(0)}
        />
        {resourceDocument && (
          <Tab disableRipple classes={tabClasses} label="Document" {...a11yProps(1)} />
        )}
      </Tabs>
      <Divider style={{ marginBottom: 32 }} />
      <ResourceTab value={value} index={0}>
        <Text text="Statistics" variant="labelEmphasized" style={{ marginBottom: 16 }} />
        {isLoadingQuiz ? (
          <Skeleton height={119} style={{ borderRadius: 20 }} variant="rectangular" />
        ) : (
          selectedResource && (
            <div style={{ display: 'flex', flexDirection: 'row', marginBottom: 20 }}>
              <Grid container spacing={2}>
                <Grid item md={3} xs={6}>
                  <Insight
                    title="Word Count"
                    tooltip="Number of words in this source"
                    outlined
                    value={`${selectedResource?.word_count}`}
                    valueFontSize="24px"
                  />
                </Grid>
                <Grid item md={3} xs={6}>
                  <Insight
                    title="Estimated Reading Time"
                    tooltip="Estimate of how long it will take to read this source"
                    outlined
                    value={`${roundNumber(selectedResource?.word_count / 225)} min`}
                    valueFontSize="24px"
                  />
                </Grid>
                <Grid item md={3} xs={6}>
                  <Insight
                    title="Unique Viewers"
                    tooltip="Number of users who have viewed this source"
                    outlined
                    value={`${selectedResource.unique_views}`}
                    valueFontSize="24px"
                  />
                </Grid>
                <Grid item md={3} xs={6}>
                  <Insight
                    title="Views"
                    tooltip="Total number of times this source has been viewed"
                    outlined
                    value={`${selectedResource?.view_count}`}
                    valueFontSize="24px"
                  />
                </Grid>
              </Grid>
            </div>
          )
        )}
        <Grid container spacing={2} style={{ paddingBottom: 32 }}>
          <Grid item xs={12}>
            <Text text="Information" variant="labelEmphasized" />
          </Grid>
          <Grid item xs={12} sm={12} md={6}>
            {loadingSummary ? (
              <Skeleton height={350} style={{ borderRadius: 20 }} variant="rectangular" />
            ) : (
              <div style={{ ...PANEL, height: 350, flexDirection: 'column' }}>
                <Text text="Summary" variant="titleMedium" style={{ marginBottom: 24 }} />
                <div style={{ display: 'flex', flexDirection: 'column', overflowY: 'auto' }}>
                  <Text variant="body1Medium" text={summary} />
                </div>
              </div>
            )}
          </Grid>
          <Grid item xs={12} sm={12} md={6}>
            {!selectedResource ? (
              <Skeleton height={350} style={{ borderRadius: 20 }} variant="rectangular" />
            ) : (
              <ShortList
                hideIcon
                style={{ height: 350 }}
                containerStyle={{ minHeight: 350 }}
                data={relatedQuizzes}
                titleVariant="titleMedium"
                type="Quizzes"
                // action={}
                deleteButton={DeleteQuizDialog}
                itemsPerPage={3}
              />
            )}
          </Grid>
          <Grid item xs={12}>
            {loadingSummary ? (
              <Skeleton height={119} style={{ borderRadius: 20 }} variant="rectangular" />
            ) : (
              <Grid container item style={{ ...PANEL, flexDirection: 'column' }} rowGap={2}>
                <Text
                  text="Keywords"
                  variant="titleEmphasized"
                  style={{ color: color.shade70, marginRight: 4 }}
                />
                <div style={{ display: 'flex', gap: 10, flexWrap: 'wrap' }}>
                  {keywords?.slice(0, 20).map((m: any) => (
                    <div style={KEYWORD}>
                      <Text
                        text={m.text}
                        variant="body1Medium"
                        style={{ color: color.shade70, marginRight: 4 }}
                      />
                      {/* <Text
                        text={m.count.toString()}
                        variant="body1Medium"
                        style={{ color: color.shade70, marginRight: 4, opacity: 0.5 }}
                      /> */}
                    </div>
                  ))}
                </div>
              </Grid>
            )}
          </Grid>
        </Grid>
      </ResourceTab>
      <ResourceTab value={value} index={1}>
        <div style={{ flexDirection: 'column', display: 'flex' }}>
          <div style={{ marginBottom: 24, paddingRight: 32, paddingLeft: 32 }}>
            <TextField
              id="search-field"
              type="text"
              name="keyword"
              variant="standard"
              onKeyDown={(e) => {
                if (e.key === 'Enter') {
                  page(true)
                }
              }}
              fullWidth
              InputProps={{
                style: { paddingRight: 90 },
                startAdornment: (
                  <Search style={{ marginRight: 12, fontSize: 22 }} htmlColor={color.shade70} />
                ),
                endAdornment: (
                  <div style={INPUT_SEARCH}>
                    <Text
                      style={{ marginRight: 8 }}
                      text={`${
                        !marks || marks?.length === 0
                          ? 0
                          : marks?.length === 1
                            ? 1
                            : Array.prototype.slice.call(marks).indexOf(selectedMark) + 1
                      } of ${marks?.length ?? 0}`}
                      variant="body1"
                    />
                    <div style={{ flexDirection: 'column', display: 'flex' }}>
                      <IconButton sx={{ top: 2, padding: 0 }} onClick={() => page(false)}>
                        <KeyboardArrowUp style={{ fontSize: 14 }} htmlColor={color.shade70} />
                      </IconButton>
                      <IconButton sx={{ bottom: 2, padding: 0 }} onClick={() => page(true)}>
                        <KeyboardArrowDown style={{ fontSize: 14 }} htmlColor={color.shade70} />
                      </IconButton>
                    </div>
                  </div>
                ),
              }}
              style={{ fontSize: 16, fontWeight: 600 }}
              placeholder="Search"
              onChange={onSearchChange}
            />
          </div>
          <EditorContent editor={editor} />
        </div>
      </ResourceTab>
    </>
  )
})

const DeleteQuizDialog = (props: any) => {
  const { manageResourceStore, manageQuizStore, manageUserStore } = useStores()
  const { getRelatedQuizzes } = manageResourceStore
  const { removeResourcesFromQuiz } = manageQuizStore
  const { permissions } = manageUserStore
  const { params } = useRouteMatch()
  //@ts-ignore
  const { id } = params

  const [showConfirmDialog, setShowConfirmDialog] = useState(false)

  const { title, quiz_id } = props

  const confirmDelete = () => {
    removeResourcesFromQuiz(quiz_id, [id]).then(() => {
      getRelatedQuizzes(id)
    })
  }

  return (
    <div style={{ paddingRight: 20 }}>
      <IconButton
        disabled={!permissions?.permissions.some((p) => p.code === 'can_edit_quiz')}
        onClick={() => setShowConfirmDialog(!showConfirmDialog)}
        size="small"
      >
        <Close htmlColor={color.palette.grey} style={{ fontSize: 18 }} />
      </IconButton>
      <ConfirmDialog
        danger
        open={showConfirmDialog}
        title="Remove From Quiz"
        message={`Are you sure want to remove this source from ${title}? The source will still be available in your content library`}
        confirmButton="Confirm"
        onConfirm={() => confirmDelete()}
        onClose={() => setShowConfirmDialog(false)}
        handleCancel={() => setShowConfirmDialog(false)}
      />
    </div>
  )
}

const PANEL: React.CSSProperties = {
  minHeight: 119,
  padding: 24,
  borderRadius: 20,
  background: '#fff',
  border: `1px solid #EFF0F6`,
  display: 'flex',
}
const TITLE_TEXT: React.CSSProperties = {
  color: color.white,
  display: 'block',
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  maxWidth: '50vw',
}
const SUBTITLE_TEXT: React.CSSProperties = {
  color: 'rgba(255, 255, 255, 0.7)',
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  maxWidth: '50vw',
}
const KEYWORD: React.CSSProperties = {
  padding: 10,
  borderRadius: 8,
  border: '1px solid rgba(0, 0, 0, 0.10)',
  background: 'rgba(27, 89, 248, 0.05)',
  display: 'flex',
}
const INPUT_SEARCH: React.CSSProperties = {
  alignItems: 'center',
  flexDirection: 'row',
  display: 'flex',
  position: 'absolute',
  right: 0,
}
