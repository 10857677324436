import { Instance, cast, destroy, flow, getEnv, getRoot, getSnapshot, types } from 'mobx-state-tree'
import { Workflow } from '../workflow/Workflow'
import { Environment } from '../environment'
import { WorkflowApi } from '../../services/api-objects/WorkflowApi'
import { AssignmentApi } from '../../services/api-objects/AssignmentApi'
import {
  BadgeApi,
  GroupApi,
  QuizApi,
  ResourceApi,
  TagApi,
  TopicApi,
} from '../../services/api-objects'
import { FlowCacheModel, useFlowCacheMiddleware } from '../../middleware'
import { DateTime } from 'luxon'
import { Path, UserPath } from '../workflow/Path'

// Add flow actions to be cached / blocked and
// how much time in seconds we wait before continuing
const ACTIONS_TO_CACHE = {
  getPathsForUser: { wait: 30 },
}

export const PathStoreModel = types
  .model('PathStore')
  .props({
    status: types.optional(types.enumeration(['idle', 'pending', 'done', 'error']), 'idle'),
    wf: types.maybe(Workflow),
    path: types.maybe(Path),
    loading: types.maybe(types.boolean),
    details: types.maybe(types.frozen()),
    paths: types.array(types.frozen()),
    userPaths: types.array(UserPath),
    selectedPath: types.maybe(Path),
    topicGaps: types.maybe(types.frozen()),
    quizGaps: types.maybe(types.frozen()),
    questionGaps: types.maybe(types.frozen()),
    flowCache: types.map(types.map(FlowCacheModel)),
  })
  .actions((self) => ({
    afterCreate() {
      useFlowCacheMiddleware({ store: self, cacheable: ACTIONS_TO_CACHE })
    },
    createWorkflow() {
      self.wf = Workflow.create({
        version: 1.1,
        name: 'New Workflow',
      })
    },
    setTopicGaps(topicGaps: Instance<typeof self.topicGaps>) {
      self.topicGaps = topicGaps
    },
    setQuizGaps(quizGaps: Instance<typeof self.quizGaps>) {
      self.quizGaps = quizGaps
    },
    setQuestionGaps(questionGaps: Instance<typeof self.questionGaps>) {
      self.questionGaps = questionGaps
    },
    setWorkflow(workflow: Instance<typeof self.wf>) {
      self.wf = workflow
    },
    setPath(path: Instance<typeof self.path>) {
      self.path = path
    },
    setPaths(paths) {
      self.paths = paths
    },
    setDetails(details: any) {
      self.details = details
    },
    setUserPaths(paths) {
      self.userPaths = paths
    },
    selectPath(path) {
      self.selectedPath = Path.create({
        ...path,
        workflow_json: path.user_workflow_criteria,
      })
    },
    clearPath() {
      self.selectedPath = undefined
    },
    createPath() {
      const wf = Workflow.create({
        name: 'New Workflow',
        version: 1.1,
      }) as any
      self.path = Path.create({
        name: '',
        description: '',
        workflow_json: wf,
      })
    },
    deleteWorkflow() {
      destroy(self.wf)
    },
    deletePath() {
      destroy(self.path)
    },
    setLoading(loading: boolean) {
      self.loading = loading
    },
  }))
  .views((self) => ({
    get rootStore(): any {
      return getRoot(self) as any
    },
    get workflow() {
      // helper computed value to return a path's workflow
      // vs a topic level workflow if there is no path set
      const { contentCreationStore } = this.rootStore
      const { selectedTopic, importingFilename } = contentCreationStore
      if (importingFilename) {
        return selectedTopic.workflow
      } else if (self.path) {
        return self.path.workflow_json
      } else if (self.selectedPath) {
        return self.selectedPath.workflow_json
      } else {
        return self.wf
      }
    },
    get environment() {
      return getEnv(self) as Environment
    },
    get isLoading() {
      return self.status === 'pending'
    },
  }))
  .actions((self) => ({
    publishPath: flow(function* () {
      self.setLoading(true)

      self.path?.workflow_json.handleJoinActions()

      try {
        const result: Awaited<ReturnType<typeof WorkflowApi.publishPath>> =
          yield WorkflowApi.publishPath(self.path)
        if (result.ok && result.data) {
          self.setLoading(false)
          const { path_id } = result.data
          self.path?.setPathID(path_id)
          if (self.path?.imageFile) {
            WorkflowApi.updatePathPhoto(path_id, self.path.imageFile)
          }
          return result.data
        }
      } catch (error) {
        self.setLoading(false)
        return false
      }
    }),
    updatePath: flow(function* () {
      self.setLoading(true)

      self.path?.workflow_json.handleJoinActions()

      try {
        const result: Awaited<ReturnType<typeof WorkflowApi.updatePath>> =
          yield WorkflowApi.updatePath(self.path)
        if (result.ok && result.data) {
          self.setLoading(false)
          if (self.path?.imageFile) {
            WorkflowApi.updatePathPhoto(self.path.path_id!, self.path.imageFile)
          }
          return result.data
        }
      } catch (error) {
        self.setLoading(false)
        return false
      }
    }),
    getPathsForUser: flow(function* (user_id: number) {
      try {
        self.setLoading(true)

        const result: Awaited<ReturnType<typeof WorkflowApi.getPathsForUser>> =
          yield WorkflowApi.getPathsForUser(user_id)

        if (result.ok && result.data) {
          self.setLoading(false)

          // incomplete
          const incomplete = [...result.data]
            .filter((path: any) => path.nodes_completed < path.node_count)
            .sort((a, b) => a.name.localeCompare(b.name))
            .sort((a: any, b: any) => {
              const dateA = DateTime.fromISO(a.end_datetime)
              const dateB = DateTime.fromISO(b.end_datetime)
              if (dateA >= dateB || a.end_datetime === null) {
                return 1
              } else {
                return -1
              }
            })

          // complete
          const complete = [...result.data]
            .filter((path: any) => path.nodes_completed >= path.node_count)
            .sort((a, b) => a.name.localeCompare(b.name))

          const sorted = [...incomplete, ...complete]

          self.setUserPaths(cast(sorted))
          return result.data.sort((a, b) => a.name.localeCompare(b.name))
        }
      } catch (error) {
        self.setLoading(false)
        return false
      }
    }),
    getPathForUser: flow(function* (user_id: number, params: any) {
      try {
        self.setLoading(true)

        const result: Awaited<ReturnType<typeof WorkflowApi.getPathForUser>> =
          yield WorkflowApi.getPathForUser(user_id, params)

        if (result.ok && result.data) {
          self.selectPath(result.data)
          self.setLoading(false)
          ;(self as any).updatePathObjects(true)
          return result.data
        }
      } catch (error) {
        self.setLoading(false)
        return false
      }
    }),
    saveWorkflow: flow(function* (object_id, object_type) {
      try {
        const result: Awaited<ReturnType<typeof WorkflowApi.createWorkflow>> =
          yield WorkflowApi.createWorkflow(self.workflow, object_id, object_type)
        if (result.ok && result.data) {
          return result.data
        }
      } catch (error) {
        return false
      }
    }),
    editWorkflow: flow(function* (workflow_id) {
      try {
        const result: Awaited<ReturnType<typeof WorkflowApi.editWorkflow>> =
          yield WorkflowApi.editWorkflow(self.workflow, workflow_id)
        if (result.ok && result.data) {
          return result.data
        }
      } catch (error) {
        return false
      }
    }),
    getDetails: flow(function* () {
      try {
        const result: Awaited<ReturnType<typeof WorkflowApi.getAssignmentPathDetails>> =
          yield WorkflowApi.getAssignmentPathDetails()
        if (result.ok && result.data) {
          self.setDetails(result.data)
          return result.data
        }
      } catch (error) {
        return false
      }
    }),
    getTopicGaps: flow(function* (path_id: number) {
      try {
        const result: Awaited<ReturnType<typeof WorkflowApi.getTopicGaps>> =
          yield WorkflowApi.getTopicGaps(path_id)

        if (result.ok && result.data) {
          self.setTopicGaps(
            result.data.topics.map((t) => ({ ...t, pct_correct: t.pct_correct * 100 })),
          )
          return result.data
        }
      } catch (error) {
        self.setLoading(false)
        return false
      }
    }),
    getQuizGaps: flow(function* (path_id: number) {
      try {
        const result: Awaited<ReturnType<typeof WorkflowApi.getQuizGaps>> =
          yield WorkflowApi.getQuizGaps(path_id)

        if (result.ok && result.data) {
          self.setQuizGaps(
            result.data.quizzes.map((q) => ({ ...q, pct_correct: q.pct_correct * 100 })),
          )
          return result.data
        }
      } catch (error) {
        self.setLoading(false)
        return false
      }
    }),
    getQuestionGaps: flow(function* (path_id: number) {
      try {
        const result: Awaited<ReturnType<typeof WorkflowApi.getTopicGaps>> =
          yield WorkflowApi.getQuestionGaps(path_id)

        if (result.ok && result.data) {
          self.setQuestionGaps(
            result.data.questions.map((q) => ({ ...q, pct_correct: q.pct_correct * 100 })),
          )
          return result.data
        }
      } catch (error) {
        self.setLoading(false)
        return false
      }
    }),
    getPath: flow(function* (id: number, duplicate?: boolean) {
      try {
        self.setPath(undefined)
        self.setLoading(true)
        const result: Awaited<ReturnType<typeof WorkflowApi.getPath>> =
          yield WorkflowApi.getPath(id)
        if (result.ok && result.data) {
          self.setPath(result.data)
          if (duplicate) {
            self.path?.setName(`${self.path?.name} (1)`)
            self.path?.workflow_json.makeReferencesUnique()
          } else {
            self.path?.setPathID(id)
          }
          ;(self as any).updatePathObjects()
          self.setLoading(false)
          return result.data
        }
      } catch (error) {
        self.setLoading(false)

        return false
      }
    }),
    updatePathObjects: flow(function* (learner?: boolean) {
      let hasBadge, hasResource, hasTopic, hasQuiz, hasAssignment, hasGroup, hasTag
      self.workflow?.conditions.map((condition) => {
        if (condition.object_type === 'Resource') {
          hasResource = true
        } else if (condition.object_type === 'Topic') {
          hasTopic = true
        } else if (condition.object_type === 'Quiz') {
          hasQuiz = true
        }
      })
      self.workflow?.actions.map((action) => {
        if (action.action_type === 'tag_user' || action.action_type === 'untag_user') {
          hasTag = true
        } else if (action.action_type === 'add_to_group') {
          hasGroup = true
        } else if (action.action_type === 'add_to_assignment') {
          hasAssignment = true
        } else if (action.action_type === 'award_badge') {
          hasBadge = true
        }
      })

      if (hasGroup && !learner) {
        GroupApi.getAllGroups('groups', false, [{ field: 'group_name' }], 'list_groups').then(
          (resp) => {
            const formatted = resp.data.rows.map((g) => {
              return {
                id: g.id,
                group_name: g.columns.find((column) => column.field === 'group_name').value,
              }
            })
            self.workflow?.actions.map((action) => {
              if (action.action_type === 'add_to_group') {
                const found = formatted.find((a) => a.id === action.object_id)
                action.setGroup(found)
              }
            })
          },
        )
      }
      if (hasTag && !learner) {
        TagApi.getAllTags(
          'tags',
          false,
          [{ field: 'tag_key' }, { field: 'tag_value' }],
          'list_tags',
        ).then((resp) => {
          if (resp.ok) {
            // @ts-ignore
            const available = resp.data.rows.map((tag) => {
              const key = tag.columns.find((column) => column.field === 'tag_key')
              const value = tag.columns.find((column) => column.field === 'tag_value')
              return { id: tag.id, key: key.value, value: value.value }
            })
            const formatted = {}
            available.map((tag) => {
              formatted[tag.key] = { values: [] }
            })
            available.map((tag) => {
              formatted[tag.key].values.push(tag)
            })

            self.workflow?.actions?.map((action) => {
              if (action.action_type === 'tag_user' || action.action_type === 'untag_user') {
                action.setTags(
                  action?.tag_ids?.map((tag_id) => {
                    return available.find((t) => tag_id === t.id)
                  }),
                )
              }
            })
          }
        })
      }
      if (hasAssignment && !learner) {
        AssignmentApi.getAllAssignments(
          'assignments',
          false,
          [{ field: 'assignment_title' }],
          'list_active_assignments',
          undefined,
          'active',
        ).then((resp) => {
          AssignmentApi.getAllAssignments(
            'assignments',
            false,
            [{ field: 'assignment_title' }],
            'list_active_assignments',
            undefined,
            'scheduled',
          ).then((resp2) => {
            const formatted = [
              ...resp.data.rows.map((s) => {
                return {
                  id: s.id,
                  assignment_title: s.columns.find((column) => column.field === 'assignment_title')
                    .value,
                }
              }),
              ...resp2.data.rows.map((s) => {
                return {
                  id: s.id,
                  assignment_title: s.columns.find((column) => column.field === 'assignment_title')
                    .value,
                }
              }),
            ]
            self.workflow?.actions.map((action) => {
              if (action.action_type === 'add_to_assignment') {
                const found = formatted.find((a) => a.id === action.object_id)
                action.setAssignment(found)
              }
            })
          })
        })
      }
      if (hasQuiz) {
        let formatted
        if (learner) {
          const { getQuizzesForUser } = self.rootStore.libraryStore
          const { user } = self.rootStore.userStore
          getQuizzesForUser(user.id).then((quizzes) => {
            formatted = quizzes.map((quiz) => {
              return {
                id: quiz.quiz_id,
                quiz_title: quiz.title,
                photo_url: quiz.photo_url,
              }
            })
            self.workflow?.conditions.map((condition) => {
              if (condition.object_type === 'Quiz') {
                const found = formatted.find((q) => q.id === condition.object_id)
                condition.setQuiz(found)
              }
            })
          })
        } else {
          QuizApi.getAllQuizzes('quizzes', false, [{ field: 'quiz_title' }], 'list_quizzes').then(
            (resp) => {
              const formatted = resp.data.rows.map((q) => {
                return {
                  id: q.id,
                  quiz_title: q.columns.find((column) => column.field === 'quiz_title').value,
                  photo_url: q.columns.find((column) => column.field === 'photo_url').value,
                }
              })
              self.workflow?.conditions.map((condition) => {
                if (condition.object_type === 'Quiz') {
                  const found = formatted.find((q) => q.id === condition.object_id)
                  condition.setQuiz(found)
                }
              })
            },
          )
        }
      }
      if (hasBadge) {
        self.workflow?.actions.map((action) => {
          if (action.action_type === 'award_badge') {
            BadgeApi.getBadge(action.object_id).then((res) => {
              action.setBadge(res.data)
            })
          }
        })
      }
      if (hasTopic) {
        let formatted
        if (learner) {
          const { getTopicsForUser } = self.rootStore.libraryStore
          const { user } = self.rootStore.userStore
          getTopicsForUser(user.id).then((topics) => {
            formatted = topics.map((topic) => {
              return {
                id: topic.topic.topic_id,
                topic_title: topic.topic.title,
                photo_url: topic.topic.photo,
              }
            })
            self.workflow?.conditions.map((condition) => {
              if (condition.object_type === 'Topic') {
                const found = formatted.find((t) => t.id === condition.object_id)
                condition.setTopic(found)
              }
            })
          })
        } else {
          TopicApi.getAllTopics('topics', false, [{ field: 'topic_title' }], 'list_topics').then(
            (res) => {
              formatted = res.data.rows.map((topic) => ({
                id: topic.id,
                topic_title: topic.columns.find((column) => column.field === 'topic_title').value,
                photo_url: topic.columns.find((column) => column.field === 'photo_url').value,
              }))
              self.workflow?.conditions.map((condition) => {
                if (condition.object_type === 'Topic') {
                  const found = formatted.find((t) => t.id === condition.object_id)
                  condition.setTopic(found)
                }
              })
            },
          )
        }
      }
      if (hasResource) {
        let formatted = [] as any
        if (learner) {
          const { getResourcesForUser } = self.rootStore.libraryStore
          const { user } = self.rootStore.userStore
          getResourcesForUser(user.id).then((resources) => {
            formatted = resources.map((resource) => {
              return {
                id: resource.resource_id,
                resource_title: resource.title,
                resource_file_type: resource.extension,
              }
            })
            self.workflow?.conditions.map((condition) => {
              if (condition.object_type === 'Resource') {
                const found = formatted.find((r) => r.id === condition.object_id)
                condition.setSource(found)
              }
            })
          })
        } else {
          ResourceApi.getAllResources(
            'resources',
            false,
            [{ field: 'resource_title' }, { field: 'resource_file_type' }],
            'list_resources',
          ).then((resp) => {
            formatted = resp.data.rows.map((s) => {
              return {
                id: s.id,
                resource_title: s.columns.find((column) => column.field === 'resource_title').value,
                resource_file_type: s.columns.find(
                  (column) => column.field === 'resource_file_type',
                ).value,
              }
            })
            self.workflow?.conditions.map((condition) => {
              if (condition.object_type === 'Resource') {
                const found = formatted.find((r) => r.id === condition.object_id)
                condition.setSource(found)
              }
            })
          })
        }
      }
    }),
    deletePaths: flow(function* (ids: number[]) {
      try {
        const result: Awaited<ReturnType<typeof WorkflowApi.deletePaths>> =
          yield WorkflowApi.deletePaths(ids)
        if (result.ok && result.data) {
          return result.data
        }
      } catch (error) {
        return false
      }
    }),
    checkDeletePaths: flow(function* (ids: number[]) {
      try {
        const result: Awaited<ReturnType<typeof WorkflowApi.checkDeletePaths>> =
          yield WorkflowApi.checkDeletePaths(ids)
        if (result.ok && result.data) {
          return result.data
        }
      } catch (error) {
        return error
      }
    }),
    loadWorkflow: flow(function* (id: number) {
      try {
        const result: Awaited<ReturnType<typeof WorkflowApi.getWorkflow>> =
          yield WorkflowApi.getWorkflow(id)
        if (result.ok && result.data) {
          // self.setWorkflow(Workflow.create(result.data))
          return result.data
        }
      } catch (error) {
        return false
      }
    }),
  }))
