import React from 'react'
import { observer } from 'mobx-react-lite'
import { color } from '@trivie/ui-web/theme'
import { Text } from '@trivie/ui-web/components/text'
import { ReactComponent as SuccessIcon } from '@trivie/ui-web/assets/icons/SuccessCheck.svg'
import { ReactComponent as ErrorIcon } from '@trivie/ui-web/assets/icons/Error.svg'

export interface ValidationResultProps {
  hasError?: boolean
  message: string
}

export const ValidationResult = observer((props: ValidationResultProps) => {
  const { hasError = false, message } = props

  return (
    <div
      style={{
        backgroundColor: hasError ? 'rgba(255, 62, 19, 0.1)' : '#D8F4EB',
        borderRadius: 8,
        padding: 6,
        marginRight: 6,
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
      }}
    >
      {hasError ? <ErrorIcon /> : <SuccessIcon />}
      <Text
        style={{ color: hasError ? color.red : color.lightGreen, marginLeft: 4 }}
        variant="body2"
        text={message}
      />
    </div>
  )
})
