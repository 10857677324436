import React, { useEffect, useState } from 'react'

import {
  CalendarTodayRounded,
  CloseRounded,
  Delete,
  EditRounded,
  FileCopyRounded,
  NotificationsRounded,
  PersonRounded,
  PlayCircleOutline,
} from '@mui/icons-material'
import { observer } from 'mobx-react-lite'
import { CircularProgress } from '@mui/material'

import { useStores } from '@trivie/core'
import { DataTable, TableHelper } from '@trivie/ui-web/components/data-table/data-table'
import { Toolbar } from '@trivie/ui-web/components/table-toolbar'
import { ExtendSurvey } from '@trivie/ui-web/components/extend-dialog'
import { MessageDialog } from '@trivie/ui-web/components/message-dialog'
import { useHistory } from 'react-router-dom'
import { AssignSurveyDialog } from '../screens/activities/surveys/assign-survey'

import { useConfirm } from '@trivie/ui-web/components/dialogs/confirmation-dialog'

interface SurveyTableProps {
  tableHelper: TableHelper
  filter: string
}

export const SurveyTable = observer((props: SurveyTableProps) => {
  const { tableHelper, filter } = props

  const confirm = useConfirm()

  const { manageUserStore, surveyStore } = useStores()
  const {
    endSurvey,
    setSelectedSurveyId,
    startSurvey,
    duplicateSurvey,
    deleteSurvey,
    extendSurvey,
  } = surveyStore
  const { gdpr, permissions } = manageUserStore

  const [selectedRows, setSelectedRows] = useState<any>([])
  const [loading, setLoading] = useState<boolean>(true)

  // Dialogs
  const [showExtendSurvey, setShowExtendSurvey] = useState(false)
  const [showSendMessage, setShowSendMessage] = useState(false)
  const [showSurveyAssign, setShowSurveyAssign] = useState(false)

  const history = useHistory()

  const getActions = () => {
    switch (filter) {
      case 'live':
        return [
          {
            label: 'Nudge',
            icon: NotificationsRounded,
            onClick: () => setShowSendMessage(true),
            disabled: !permissions?.permissions.some((p) => p.code === 'can_message_user'),
          },
          {
            label: 'Duplicate',
            icon: FileCopyRounded,
            onClick: () => confirmHandler('Duplicate'),
            disabled:
              selectedRows.length > 1 ||
              !permissions?.permissions.some((p) => p.code === 'can_edit_survey'),
          },
          {
            label: 'End',
            icon: CloseRounded,
            onClick: () => confirmHandler('End'),
            disabled: !permissions?.permissions.some((p) => p.code === 'can_edit_survey'),
          },
          {
            label: 'Extend',
            icon: CalendarTodayRounded,
            onClick: () => setShowExtendSurvey(true),
            disabled: !permissions?.permissions.some((p) => p.code === 'can_edit_survey'),
          },
          {
            label: 'Delete',
            icon: Delete,
            onClick: () => confirmHandler('Delete'),
            disabled: !permissions?.permissions.some((p) => p.code === 'can_edit_survey'),
          },
        ]
      case 'scheduled':
        return [
          {
            label: 'Start Now',
            icon: PlayCircleOutline,
            onClick: () => confirmHandler('Start'),
            disabled: !permissions?.permissions.some((p) => p.code === 'can_edit_survey'),
          },
          {
            label: 'Edit',
            icon: EditRounded,
            onClick: () =>
              history.push(`/activities/surveys/scheduled/${selectedRows[0]}/overview`),
            disabled: !permissions?.permissions.some((p) => p.code === 'can_edit_survey'),
          },
          {
            label: 'Duplicate',
            icon: FileCopyRounded,
            onClick: () => confirmHandler('Duplicate'),
            disabled:
              selectedRows.length > 1 ||
              !permissions?.permissions.some((p) => p.code === 'can_edit_survey'),
          },
          {
            label: 'Delete',
            icon: Delete,
            onClick: () => confirmHandler('Delete'),
            disabled: !permissions?.permissions.some((p) => p.code === 'can_edit_survey'),
          },
        ]
      case 'unscheduled':
        return [
          {
            label: 'Assign',
            icon: PersonRounded,
            onClick: () => {
              setSelectedSurveyId(selectedRows[0])
              setShowSurveyAssign(true)
            },
            disabled:
              selectedRows.length > 1 ||
              !permissions?.permissions.some((p) => p.code === 'can_edit_survey'),
          },
          {
            label: 'Edit',
            icon: EditRounded,
            onClick: () =>
              history.push(`/activities/surveys/scheduled/${selectedRows[0]}/overview`),
            disabled: !permissions?.permissions.some((p) => p.code === 'can_edit_survey'),
          },
          {
            label: 'Delete',
            icon: Delete,
            onClick: () => confirmHandler('Delete'),
            disabled: !permissions?.permissions.some((p) => p.code === 'can_edit_survey'),
          },
        ]
      case 'completed':
        return [
          {
            label: 'Duplicate',
            icon: FileCopyRounded,
            onClick: () => confirmHandler('Duplicate'),
            disabled:
              selectedRows.length > 1 ||
              !permissions?.permissions.some((p) => p.code === 'can_edit_survey'),
          },
          {
            label: 'Delete',
            icon: Delete,
            onClick: () => confirmHandler('Delete'),
            disabled: !permissions?.permissions.some((p) => p.code === 'can_edit_survey'),
          },
        ]
      default:
        return []
    }
  }

  const [tableActions, setTableActions] = useState<any>(getActions())

  useEffect(() => {
    if (permissions) {
      setTableActions(getActions())
    }
  }, [permissions?.permissions, selectedRows])

  useEffect(() => {
    load()
  }, [])

  const load = () => {
    setLoading(true)
    tableHelper.load().then(() => setLoading(false))
  }

  const save = async (columns: any, surveyFilter: any) => {
    setLoading(true)
    tableHelper.save(columns, surveyFilter).then(() => setLoading(false))
  }

  const confirmHandler = (confirmType: 'Start' | 'End' | 'Delete' | 'Duplicate') => {
    confirm({
      danger: confirmType === 'Delete',
      title: `${confirmType} Surveys`,
      confirmationMessage: `${selectedRows.length} survey${
        selectedRows.length > 1 ? 's have' : ' has'
      } been ${confirmType === 'Delete' ? 'deleted' : confirmType === 'Duplicate' ? 'duplicated' : `${confirmType.toLowerCase()}ed`}.`,
      dialogText: `Are you sure you want to ${confirmType.toLowerCase()} ${selectedRows.length} survey${
        selectedRows.length > 1 ? 's' : ''
      }${confirmType === 'Start' ? ' now' : ''}?`,
    })
      .then(() => {
        switch (confirmType) {
          case 'Start':
            startSurvey({ survey_ids: selectedRows }).then(() => load())
            break
          case 'End':
            endSurvey({ survey_ids: selectedRows }).then(() => load())
            break
          case 'Delete':
            deleteSurvey({ survey_ids: selectedRows }).then(() => load())
            break
          case 'Duplicate':
            duplicateSurvey({ survey_ids: selectedRows }).then(() => load())
            break
        }
        setSelectedRows([])
      })
      .catch(() => {})
  }

  return loading ? (
    <div style={LOADER}>
      <CircularProgress />
    </div>
  ) : (
    <>
      <DataTable
        gdpr={gdpr}
        columnVisibilityModel={tableHelper.columnVisibilityModel}
        hideAddColumn
        tableHelper={tableHelper}
        filterModel={tableHelper.filterModel}
        sortModel={tableHelper.sortModel}
        searchPlaceholder="Search Surveys"
        rows={tableHelper.rows}
        columns={tableHelper.columns}
        onSaveLayout={tableHelper?.saveLayout}
        onSaveFilters={save}
        toolbar={<Toolbar selected={selectedRows} actions={tableActions} />}
        exportFilename={
          tableHelper.parent !== 'surveys' ? `${tableHelper.parent}_surveys` : 'surveys'
        }
        rowSelectionModel={selectedRows}
        onRowSelectionModelChange={(param) => setSelectedRows(param)}
      />
      {showExtendSurvey && (
        <ExtendSurvey
          open={showExtendSurvey}
          // @ts-ignore
          end={tableHelper.rows.find((s: any) => s.id === selectedRows[0])?.survey_end_date}
          onClose={() => {
            setShowExtendSurvey(false)
            setSelectedRows([])
            load()
          }}
          onExtend={(end: string) => extendSurvey({ survey_ids: selectedRows, end_datetime: end })}
        />
      )}
      {showSendMessage && (
        <MessageDialog
          ids={selectedRows}
          open={showSendMessage}
          onClose={() => {
            setSelectedRows([])
            load()
            setShowSendMessage(false)
          }}
          messageType="survey_nudge"
        />
      )}
      {showSurveyAssign && (
        <AssignSurveyDialog
          open={showSurveyAssign}
          requestClose={() => {
            setShowSurveyAssign(false)
            setSelectedRows([])
            load()
          }}
        />
      )}
    </>
  )
})

const LOADER: React.CSSProperties = {
  display: 'flex',
  flex: 1,
  justifyContent: 'center',
  flexDirection: 'column',
  alignItems: 'center',
  height: window.innerHeight / 1.5,
}
