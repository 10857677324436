import React, { useEffect, useState } from 'react'
import { DateTime } from 'luxon'
import { observer } from 'mobx-react-lite'
import { useHistory, useRouteMatch } from 'react-router-dom'
import { IconButton, Paper, Stack } from '@mui/material'
import { Button } from '@trivie/ui-web/components/button/button'
import {
  GetAppRounded,
  ScreenShareRounded,
  Add,
  Close,
  AutoFixHigh,
  AutoFixHighOutlined,
} from '@mui/icons-material'
import { useStores, GetBackgroundColorOfMimeType, QuizHelper, QuestionHelper } from '@trivie/core'
import { color } from '@trivie/ui-web/theme'
import { Header } from '@trivie/ui-web/components/header'
import { ShortList } from '@trivie/ui-web/components/short-list'
import { Text } from '@trivie/ui-web/components/text'
import { ConfirmDialog } from '@trivie/ui-web/components/confirm-dialog'
import { GenerateReport } from '@trivie/ui-web/components/generate-report'
import { AddToTopicsDialog } from './add-to-topics-dialog'
import { AddToQuizDialog } from './add-to-quiz-dialog'
import { Create } from '@mui/icons-material'
import { EditResource } from '@trivie/ui-web/components/edit-resource'
import { ResourceIcon } from '@trivie/ui-web/components/resource-icon'
import { Resource } from '@trivie/core/src/models/Resource'
import { ResourceApi } from '@trivie/core/src/services/api-objects'

export const ResourcesDetailsScreen = observer(() => {
  const { manageResourceStore, resourceStore, contentCreationStore, manageUserStore } = useStores()
  const { resetContentStore, setQuiz } = contentCreationStore
  const {
    getResourceDetails,
    getRelatedTopics,
    getRelatedQuizzes,
    relatedTopics,
    relatedQuizzes,
    resourceDetails,
    setResourceDetails,
  } = manageResourceStore
  const { availableResources, getAvailableResources } = resourceStore
  const { permissions, userProfile } = manageUserStore

  const [showEdit, setShowEdit] = useState(false)
  const [showAddToTopicsDialog, setShowAddToTopicsDialog] = useState(false)
  const [showAddToQuizDialog, setShowAddToQuizDialog] = useState(false)
  const [formattedResource, setFormattedResource] = useState<any>()

  const history = useHistory()
  const { params } = useRouteMatch()
  // @ts-ignore
  const { id } = params

  useEffect(() => {
    ResourceApi.getAllResources(
      'resources',
      false,
      [{ field: 'resource_filename_url' }],
      'list_resources',
    ).then((resp) => {
      const res = resp.data.rows.map((s) => {
        return {
          id: s.id,
          resource_filename_url: s.columns.find(
            (column) => column.field === 'resource_filename_url',
          ).value,
        }
      })
      setFormattedResource(res.find((r) => r.id === Number(id)))
    })

    setResourceDetails({})
    getResourceDetails(id)
    getRelatedTopics(id)
    getRelatedQuizzes(id)
  }, [])

  const getResource = () => {
    return Resource.create({
      ...resourceDetails,
      filename_or_url: formattedResource?.resource_filename_url,
      identityBreaker: Math.random() * 9999999999,
    })
  }

  const renderUploadDate = () => {
    const dt = DateTime.fromISO(resourceDetails?.created_at)
    return dt.toLocaleString(DateTime.DATE_MED) || ''
  }

  const preview = (url: string) => {
    window.open(url, '_blank', 'noopener')
  }

  const download = (url: string) => {
    fetch(url).then(function (t) {
      return t.blob().then((b) => {
        const a = document.createElement('a')
        a.href = URL.createObjectURL(b)
        a.setAttribute('download', resourceDetails.title)
        a.click()
      })
    })
  }

  const addToTopicsButton = () => {
    return (
      <Button
        color="info"
        variant="outlined"
        size="small"
        disabled={!permissions?.permissions.some((p) => p.code === 'can_edit_topic')}
        text="Add to Topic"
        onClick={() => setShowAddToTopicsDialog(true)}
        startIcon={<Add />}
      />
    )
  }

  const addToQuizButton = () => {
    return (
      <Button
        color="info"
        disabled={!permissions?.permissions.some((p) => p.code === 'can_edit_quiz')}
        size="small"
        onClick={() => setShowAddToQuizDialog(true)}
        startIcon={<Add />}
        variant="outlined"
        text="Add to Quiz"
      />
    )
  }

  const generateQuiz = async () => {
    resetContentStore()
    const quiz = QuizHelper.createNewQuiz({
      showQuizPanel: true,
      showResourcePanel: true,
      generatingQuestions: true,
      stopGenerating: false,
      showSettings: false,
      settings: {
        questionCount: 10,
        audience: '',
        voice: '',
      },
    })
    quiz.addQuestions([QuestionHelper.createNewQuestion()])
    const resource = availableResources?.find((r) => r.resource_id === Number(id))
    if (resource && resource.processing_status !== 'processed') {
      ResourceApi.processResource(Number(id)).then(async () => {
        const available = await getAvailableResources()
        const processedResource = available?.find((r: any) => r.resource_id === Number(id))
        if (processedResource) {
          quiz.setMercuryResource(processedResource)
          quiz.addResource(processedResource)
        }
        quiz?.set('title', `${resource.title} Quiz`)
        quiz?.set('showSettings', true)
        setQuiz(quiz)
        history.push('/content/creation/quiz/new/edit/?generate=true&create=true')
      })
    } else if (resource && resource.processing_status === 'processed') {
      quiz.setMercuryResource(resource)
      quiz.addResource(resource)
      quiz?.set('title', `${resource.title} Quiz`)
      quiz?.set('showSettings', true)
      setQuiz(quiz)
      history.push('/content/creation/quiz/new/edit/?generate=true&create=true')
    }
  }

  const primary = {
    disabled: !permissions?.permissions.some((p) => p.code === 'can_edit_resource'),
    startIcon: <Create />,
    onClick: () => setShowEdit(true),
    text: 'Edit Source',
  }

  return (
    <>
      <Header
        divider
        title={`${resourceDetails?.title ?? ''}`}
        primaryAction={primary}
        secondaryAction={{
          sx: { '&:hover': { opacity: 0.8 } },
          color: 'gradient',
          disabled:
            !userProfile?.workspace_settings?.mercury_enabled ||
            !permissions?.permissions.some((p) => p.code === 'can_create_quiz'),
          startIcon: <AutoFixHighOutlined />,
          onClick: generateQuiz,
          text: 'Generate Quiz',
        }}
      />
      <Paper
        variant="outlined"
        style={{
          display: 'flex',
          height: 72,
          paddingLeft: 20,
          paddingRight: 20,
          flexDirection: 'row',
          justifyContent: 'space-between',
          marginBottom: 20,
          alignItems: 'center',
        }}
      >
        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              flexDirection: 'row',
              backgroundColor: GetBackgroundColorOfMimeType(resourceDetails?.mime_type),
              padding: 10,
              margin: 5,
              borderRadius: 10,
            }}
          >
            <ResourceIcon extension={resourceDetails?.mime_type} white={true} />
          </div>
          <div style={{ marginLeft: 12, flexDirection: 'column', display: 'flex' }}>
            <Text
              variant="body1Emphasized"
              style={{ marginBottom: 4 }}
              text={resourceDetails?.title}
            />
            <Text
              variant="body2Medium"
              style={{ color: color.shade70 }}
              text={`Uploaded ${renderUploadDate()} `}
            />
          </div>
        </div>

        <div style={{ flexDirection: 'row', display: 'flex', gap: 12 }}>
          <Button
            variant="outlined"
            color="info"
            onClick={() =>
              preview(
                resourceDetails?.converted_pdf_url
                  ? resourceDetails?.converted_pdf_url
                  : resourceDetails?.filename_or_url,
              )
            }
            startIcon={<ScreenShareRounded />}
            text="Preview"
          />
          <IconButton
            onClick={() => {
              download(resourceDetails.filename_or_url)
            }}
            size="small"
          >
            <GetAppRounded htmlColor={color.shade70} />
          </IconButton>
        </div>
      </Paper>
      <div
        style={{
          marginBottom: 16,
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'flex-end',
        }}
      >
        <GenerateReport filename={`resource-${id}`} />
      </div>
      <Stack useFlexGap gap={2} direction="row">
        <ShortList
          style={{ marginRight: 10 }}
          data={relatedTopics}
          type="Topics"
          action={addToTopicsButton()}
          deleteButton={DeleteTopicDialog}
        />
        <ShortList
          style={{ marginLeft: 10 }}
          data={relatedQuizzes}
          type="Quizzes"
          action={addToQuizButton()}
          deleteButton={DeleteQuizDialog}
        />
      </Stack>
      <AddToTopicsDialog
        onClose={() => {
          setShowAddToTopicsDialog(false)
        }}
        open={showAddToTopicsDialog}
      />
      <AddToQuizDialog
        onClose={() => {
          setShowAddToQuizDialog(false)
        }}
        open={showAddToQuizDialog}
      />
      {showEdit && (
        <EditResource
          open={showEdit}
          standalone
          resource={getResource()}
          onClose={() => setShowEdit(false)}
          onUpdate={(deleted) => {
            setShowEdit(false)
            getResourceDetails(id)
            if (deleted) {
              history.goBack()
            }
          }}
        />
      )}
    </>
  )
})

const DeleteTopicDialog = (props: any) => {
  const { manageResourceStore, topicsStore, manageUserStore } = useStores()
  const { removeResourcesFromTopic } = topicsStore
  const { getRelatedTopics } = manageResourceStore
  const { permissions } = manageUserStore
  const { params } = useRouteMatch()
  //@ts-ignore
  const { id } = params

  const [showConfirmDialog, setShowConfirmDialog] = useState(false)

  const { title, topic_id } = props

  const confirmDelete = () => {
    removeResourcesFromTopic(topic_id, [id]).then(() => {
      getRelatedTopics(id)
    })
  }

  return (
    <div style={{ paddingRight: 20 }}>
      <IconButton
        disabled={!permissions?.permissions.some((p) => p.code === 'can_edit_topic')}
        onClick={() => setShowConfirmDialog(!showConfirmDialog)}
        size="small"
      >
        <Close htmlColor={color.palette.grey} style={{ fontSize: 18 }} />
      </IconButton>
      <ConfirmDialog
        danger
        open={showConfirmDialog}
        title="Remove Topic"
        message={`Are you sure want to remove this source from ${title}? The source will still be available in your content library`}
        confirmButton="Confirm"
        onConfirm={() => confirmDelete()}
        onClose={() => setShowConfirmDialog(false)}
        handleCancel={() => setShowConfirmDialog(false)}
      />
    </div>
  )
}

const DeleteQuizDialog = (props: any) => {
  const { manageResourceStore, manageQuizStore, manageUserStore } = useStores()
  const { getRelatedQuizzes } = manageResourceStore
  const { removeResourcesFromQuiz } = manageQuizStore
  const { permissions } = manageUserStore
  const { params } = useRouteMatch()
  //@ts-ignore
  const { id } = params

  const [showConfirmDialog, setShowConfirmDialog] = useState(false)

  const { title, quiz_id } = props

  const confirmDelete = () => {
    removeResourcesFromQuiz(quiz_id, [id]).then(() => {
      getRelatedQuizzes(id)
    })
  }

  return (
    <div style={{ paddingRight: 20 }}>
      <IconButton
        disabled={!permissions?.permissions.some((p) => p.code === 'can_edit_quiz')}
        onClick={() => setShowConfirmDialog(!showConfirmDialog)}
        size="small"
      >
        <Close htmlColor={color.palette.grey} style={{ fontSize: 18 }} />
      </IconButton>
      <ConfirmDialog
        danger
        open={showConfirmDialog}
        title="Remove Source"
        message={`Are you sure want to remove this source from ${title}? The source will still be available in your content library`}
        confirmButton="Confirm"
        onConfirm={() => confirmDelete()}
        onClose={() => setShowConfirmDialog(false)}
        handleCancel={() => setShowConfirmDialog(false)}
      />
    </div>
  )
}
