import React, { useRef } from 'react'
import { Delete, Edit, MoreVert, OpenInNew } from '@mui/icons-material'
import { IconButton, ListItemIcon, Menu, MenuItem, TextField } from '@mui/material'
import { ResourceHelper, GetBackgroundColorOfMimeType, useStores } from '@trivie/core'
import { Resource } from '@trivie/core/src/models/Resource'
import { Switch } from '../switch/switch'
import { observer } from 'mobx-react-lite'
import { Instance } from 'mobx-state-tree'
import { color } from '../../theme'
import { ResourceIcon } from '../resource-icon'
import { Text } from '../text'

interface ItemInterface {
  resource: Instance<typeof Resource>
  hoverable?: boolean
  isSelected?: boolean
  hovered?: boolean
  onDrag?: (resource: Instance<typeof Resource>) => void
  onToggleResource?: () => void
  onRemove?: () => void
  style?: React.CSSProperties
  handleDragEnd?: (resource: Instance<typeof Resource>) => void
  showButtons?: boolean
  onClick?: any
  subtitle?: string
  onRequire?: any
  edit?: any
  inline?: boolean
  hideActions?: boolean
}

export const ResourceComponent = observer(({ ...props }: ItemInterface) => {
  const {
    showButtons,
    resource,
    isSelected,
    hoverable,
    style,
    subtitle,
    onClick,
    onRequire,
    onRemove,
    inline,
    hideActions = false,
  } = props

  const { resourceStore } = useStores()
  const { updateAvailableResources } = resourceStore

  const handleTitleEdit = async (e: any) => {
    if ((e.type === 'keyup' && e.code !== 'Enter') || !e.target.value) {
      return
    }

    resource.set('title', e.target.value)
    if (inline) {
      resource.set('editing', false)
    }

    await ResourceHelper.updateResource(resource)
    updateAvailableResources(resource)
  }

  const getExtension = (resource) => {
    if (resource?.extension) {
      return resource.extension
    } else if (resource?.url) {
      return 'url'
    } else {
      return resource.mime_type
    }
  }

  let details = ''
  try {
    details =
      resource?.extension || resource?.mime_type === 'txt'
        ? 'Text Source'
        : resource?.mime_type === 'url'
          ? new URL(resource?.filename_or_url).hostname
          : resource?.filename_or_url ?? ''
  } catch {
    if (resource?.mime_type === 'url') {
      details = resource?.filename_or_url
    }
  }

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => setAnchorEl(null)

  const titleRef = useRef<any>(null)

  return (
    <div style={{ flex: 1, flexDirection: 'row', alignItems: 'center', display: 'flex' }}>
      <div
        onClick={Boolean(onClick) ? () => onClick(resource) : () => {}}
        style={{
          backgroundColor: isSelected
            ? '#F2F7FF'
            : hoverable || showButtons
              ? color.palette.selectionSidebar
              : color.palette.white,
          width: '100%',
          border: isSelected ? `1px solid #1B59F8` : `1px solid ${color.shade50}`,
          borderColor: isSelected ? '#1B59F8' : '#E6E6E6',
          paddingLeft: 12,
          paddingRight: 4,
          borderRadius: 10,
          ...style,
        }}
      >
        <div
          style={{
            flex: 1,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
          }}
        >
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              flex: 1,
              paddingRight: !showButtons ? 12 : 0,
              overflow: 'hidden',
            }}
          >
            <div
              style={{
                display: 'flex',
                width: '100%',
                alignItems: 'center',
                paddingTop: 18,
                overflow: 'hidden',
                paddingBottom: 18,
              }}
            >
              <div
                style={{
                  alignItems: 'center',
                  flexDirection: 'row',
                  padding: 6,
                  borderRadius: 10,
                  display: 'flex',
                  backgroundColor: GetBackgroundColorOfMimeType(getExtension(resource)),
                }}
              >
                <ResourceIcon extension={getExtension(resource)} white={true} />
              </div>

              <div style={{ overflow: 'hidden', marginLeft: 19, width: '100%' }}>
                {resource.editing ? (
                  <TextField
                    variant="standard"
                    inputRef={titleRef}
                    // autoFocus
                    value={resource.title}
                    onChange={(e) => resource.set('title', e.target.value)}
                    onBlur={handleTitleEdit}
                    onKeyUp={handleTitleEdit}
                    style={{ width: '100%' }}
                  />
                ) : (
                  <Text
                    component="div"
                    text={resource.title}
                    // noWrap
                    variant="body1Medium"
                    style={{ marginBottom: 4, textOverflow: 'ellipsis', overflow: 'hidden' }}
                  />
                )}
                <Text
                  variant="body2"
                  component="div"
                  // noWrap
                  text={`${details} ${subtitle ? `• ${subtitle}` : ''}`}
                  style={{
                    color: color.palette.grey,
                    textOverflow: 'ellipsis',
                    overflow: 'hidden',
                  }}
                />
              </div>
            </div>
          </div>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          ></div>
          <div style={{ paddingRight: 12 }}>
            {Boolean(onRequire) && (
              <Switch
                color="success"
                checked={resource.is_required}
                onChange={(e) => onRequire(e.target.checked)}
                size="small"
              />
            )}
          </div>
        </div>
      </div>
      {inline && !hideActions && (
        <IconButton
          onClick={handleClick}
          sx={{
            width: '38px',
            height: '38px',
            alignSelf: 'center',
            marginLeft: '12px',
          }}
        >
          <MoreVert style={{ color: color.shade70 }} />
        </IconButton>
      )}
      <Menu
        open={open}
        onClose={handleClose}
        anchorEl={anchorEl}
        sx={{
          '& .MuiPaper-root': {
            borderRadius: '6px !important',
            border: `1px solid #EFF0F6`,
            padding: 0,
            boxShadow: 'none',
          },
        }}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
        transformOrigin={{ vertical: 'bottom', horizontal: 'left' }}
      >
        <MenuItem
          sx={{ height: 32 }}
          onClick={() => {
            resource.set('editing', true)
            handleClose()
            setTimeout(() => {
              titleRef?.current?.focus()
            }, 10)
          }}
        >
          <ListItemIcon>
            <Edit htmlColor={color.shade70} fontSize="small" />
          </ListItemIcon>
          <Text style={{ color: color.shade70 }} variant="body2Medium" text="Edit Source" />
        </MenuItem>
        <MenuItem
          sx={{ height: 32 }}
          onClick={() => {
            resourceStore?.getResourceDetails(resource.resource_id).then((data) => {
              if (data?.filename_or_url) {
                window.open(data.filename_or_url, '_blank', 'noopener')
              }
            })
          }}
        >
          <ListItemIcon>
            <OpenInNew htmlColor={color.shade70} fontSize="small" />
          </ListItemIcon>
          <Text style={{ color: color.shade70 }} variant="body2Medium" text="Preview Source" />
        </MenuItem>
        <MenuItem
          sx={{ height: 32 }}
          onClick={() => {
            handleClose()
            // @ts-ignore
            onRemove()
          }}
        >
          <ListItemIcon>
            <Delete htmlColor={color.shade70} fontSize="small" />
          </ListItemIcon>
          <Text style={{ color: color.shade70 }} variant="body2Medium" text="Remove" />
        </MenuItem>
      </Menu>
    </div>
  )
})
