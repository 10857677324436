import { types } from 'mobx-state-tree'
import { QuestionPctCorrectSummary } from '../QuestionPctCorrectSummary'

const AssessmentStats = types.model('AssessmentStats').props({
  assessment_id: types.number,
  quiz_id: types.number,
  quiz_title: types.string,
  count_users: types.number,
  count_users_completed: types.number,
  avg_score: types.number,
})

export const GetAssessments = types.model('GetAssessments').props({
  assignment_id: types.number,
  pre_test: types.maybeNull(AssessmentStats),
  post_test: types.maybeNull(AssessmentStats),
})

export const GetAssignmentQuestionGaps = types.model('GetAssignmentQuestionGaps').props({
  assignment_id: types.number,
  questions: types.array(QuestionPctCorrectSummary),
})

const TopicAssignmentStat = types.model('TopicAssignmentStat').props({
  topic_count: types.number,
  quiz_count: types.number,
  pct_complete: types.maybeNull(types.number),
  knowledge_gain: types.number,
})

export const GetTopicAssignment = types.model('GetTopicAssignment').props({
  assignment_id: types.number,
  title: types.string,
  start_date: types.string,
  end_date: types.maybeNull(types.string),
  is_active: types.boolean,
  stats: types.maybeNull(TopicAssignmentStat),
})

export const AssignmentContentOrder = types.model('AssignmentContentOrder').props({
  object_type: types.string,
  object_id: types.number,
})

export const AssignmentBase = types.model('AssignmentBase').props({
  title: types.string,
  max_attempts: types.number,
  start_date: types.maybeNull(types.string),
  end_date: types.maybeNull(types.string),
  due_date: types.maybeNull(types.string),
  days_to_complete: types.maybeNull(types.number),
})

export const AssignmentCreate = AssignmentBase.named('AssignmentCreate').props({
  is_test: types.boolean,
  notify: types.maybeNull(types.boolean),
  topics: types.maybeNull(types.array(types.number)),
  content_ordering: types.maybeNull(types.array(AssignmentContentOrder)),
  lessons: types.maybeNull(types.array(types.number)),
  quizzes: types.maybeNull(types.array(types.number)),
  group_ids: types.maybeNull(types.array(types.number)),
  user_ids: types.maybeNull(types.array(types.number)),
  include_pre_tests: types.maybeNull(types.boolean),
  include_post_tests: types.maybeNull(types.boolean),
  paths: types.maybe(types.frozen()),
})

export const AssignmentEdit = AssignmentBase.named('AssignmentEdit').props({
  topics: types.maybeNull(types.array(types.number)),
  lessons: types.maybeNull(types.array(types.number)),
  quizzes: types.maybeNull(types.array(types.number)),
  paths: types.maybeNull(types.array(types.number)),
  is_test: types.boolean,
  notify: types.boolean,
  group_ids: types.array(types.number),
  user_ids: types.array(types.number),
  is_archived: types.boolean,
  is_deleted: types.boolean,
  content_ordering: types.maybeNull(types.array(types.number)),
  include_pre_tests: types.maybeNull(types.boolean),
  include_post_tests: types.maybeNull(types.boolean),
})

export const AssignmentResource = types.model('AssignmentResource').props({
  resource_id: types.number,
  title: types.string,
  filename_url: types.string,
  created_at: types.string,
})

const AssignableQuizzes = types.model('AssignableQuizzes').props({
  quiz_id: types.number,
  quiz_title: types.string,
  question_count: types.number,
})

const AssignableTopicsQuizzes = types.model('AssignableTopicsQuizzes').props({
  topic_id: types.number,
  topic_title: types.string,
  quizzes: types.array(AssignableQuizzes),
})

export const AssignmentContentResponse = types.model('AssignmentContentResponse').props({
  topics: types.array(AssignableTopicsQuizzes),
  quizzes: types.array(AssignableQuizzes),
})
