import { types } from 'mobx-state-tree'

export const UserPointsSummary = types.model('UserPointsSummary').props({
  total_points: types.maybeNull(types.number),
  answer_points: types.maybeNull(types.number),
  post_points: types.maybeNull(types.number),
  reply_points: types.maybeNull(types.number),
  period_total_points: types.maybeNull(types.number),
  period_answer_points: types.maybeNull(types.number),
  period_post_points: types.maybeNull(types.number),
  period_reply_points: types.maybeNull(types.number),
})
