import { types } from 'mobx-state-tree'

/**
 * ResultForQuizStart Model
 */
export const ResultForQuizStartModel = types.model('ResultForQuizStart').props({
  __typename: types.optional(types.literal('ResultForQuizStart'), 'ResultForQuizStart'),
  answered: types.maybeNull(types.integer),
  correctAnswers: types.maybeNull(types.integer),
  id: types.integer,
})
