import React from 'react'
import { observer } from 'mobx-react-lite'

import { CustomReportTable } from '../../../../tables/custom-report-table'

const CustomReports: React.FunctionComponent = observer(() => {
  return <CustomReportTable filter="custom" />
})

export const CustomReportsTab = () => {
  return {
    name: 'My Reports',
    path: `/insights/reports/custom`,
    component: () => <CustomReports />,
  }
}
