import React, { useState } from 'react'
import { observer } from 'mobx-react-lite'

import { TableHelper } from '../../../../tables'
import { ResourceTable } from '../../../../tables/resource-table'

const Resources = observer((props: { quizID: any }) => {
  const { quizID } = props

  const [tableHelper] = useState(
    new TableHelper('list_quiz_resources', 'resources', 'quizzes', quizID),
  )

  return <ResourceTable tableHelper={tableHelper} />
})

export const QuizzesResourcesTab = (props: any) => {
  const { id } = props
  return {
    name: 'Sources',
    path: `/content/quizzes/${id}/sources`,
    component: () => <Resources quizID={id} />,
  }
}
