import React from 'react'
import { ArrowForwardIos } from '@mui/icons-material'
import { color } from '@trivie/ui-web/theme/color'
import { Text } from '@trivie/ui-web/components/text'
import { observer } from 'mobx-react-lite'

const ContentCreationHeaderTitle = observer((props: any) => {
  const { titles } = props

  return (
    <>
      {titles.map((ele: any, indx: number) => (
        <>
          <Text
            style={{
              marginLeft: 10,
              marginRight: 15,
              ...(ele.isPlaceholder ? { color: color.trivieGrey } : {}),
            }}
            variant="labelEmphasized"
            text={ele.title ? ele.title : ''}
          />
          {titles.length - 1 !== indx ? (
            <ArrowForwardIos
              style={{ height: 18, width: 18 }}
              fontSize="small"
              htmlColor={color.black}
            />
          ) : null}
        </>
      ))}
    </>
  )
})

export default ContentCreationHeaderTitle
