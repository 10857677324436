import { types, cast, getEnv, flow, getRoot } from 'mobx-state-tree'
import { Environment } from '../environment'
import { UserApi } from '../../services/api-objects/UserApi'
import { Awaited } from '../../utility-types'

export const BadgeStoreModel = types
  .model('BadgeStore')
  .props({
    status: types.optional(
      types.enumeration(['idle', 'pending', 'done', 'error', 'pendingRecall']),
      'idle',
    ),
    badges: types.optional(types.frozen(), []),
    allBadges: types.frozen(),
    selectedBadge: types.frozen(),
  })
  .actions((self) => ({
    setStatus(value?: 'idle' | 'pending' | 'done' | 'error' | 'pendingRecall') {
      self.status = value || 'idle'
    },
    setBadges(badges: typeof self.badges) {
      self.badges = badges
    },
    setAllBadges(badges: typeof self.allBadges) {
      self.allBadges = badges
    },
    selectBadge(badge: typeof self.selectedBadge) {
      self.selectedBadge = cast(badge)
    },
  }))
  .views((self) => ({
    get environment() {
      return getEnv(self) as Environment
    },
    get rootStore(): any {
      return getRoot(self) as any
    },
  }))
  .actions((self) => ({
    getBadgeDetail: flow(function* (id: number) {
      self.setStatus('pending')
      try {
        const result: Awaited<ReturnType<typeof UserApi.getBadgeDetail>> =
          yield UserApi.getBadgeDetail(id)
        if (result.ok && result.data) {
          self.selectBadge(result.data)
          self.setStatus('done')
        } else {
          self.setStatus('error')
        }
      } catch {
        self.setStatus('error')
      }
    }),
    getBadgesForUser: flow(function* (id: number) {
      self.setStatus('pending')
      try {
        const result: Awaited<ReturnType<typeof UserApi.getBadgesForUser>> =
          yield UserApi.getBadgesForUser(id)
        if (result.ok && result.data) {
          self.setBadges(cast(result.data))
          self.setStatus('done')
        } else {
          self.setStatus('error')
        }
      } catch (e) {
        self.setStatus('error')
      }
    }),
  }))
