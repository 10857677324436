import React, { useEffect, useRef, useState } from 'react'
import { AppBar, Box, CircularProgress, IconButton, TextField, useMediaQuery } from '@mui/material'
import MuiDrawer from '@mui/material/Drawer'
import { observer } from 'mobx-react-lite'
import { useHistory, useLocation, useParams } from 'react-router-dom'
import { AnimatedProps, animated, useSpringRef, useTransition } from '@react-spring/web'
import styled from '@emotion/styled'
import { ChevronLeft, ChevronRight } from '@mui/icons-material'
import { Instance } from 'mobx-state-tree'

import { ResourceHelper, useStores } from '@trivie/core'
import { QuestionHelper } from '@trivie/core/src/helpers/QuestionHelper'
import { QuizHelper } from '@trivie/core/src/helpers/QuizHelper'
import { QuestionForm } from '@trivie/ui-web/components/question-form'
import { Text } from '@trivie/ui-web/components/text'
import { Button } from '@trivie/ui-web/components/button'

import { QuestionCardFooter } from './components/question-card-footer'
import { ContentGenerationSettings } from '../content-generation/content-generation-settings'
import { ContentQuizSidebar } from './content-quiz-sidebar'
import { ResourcePanel } from '../content-generation/quiz-generation/resource-panel'
import { Quiz } from '@trivie/core/src/models/Quiz'
import { color } from '@trivie/ui-web/theme'
import ContentCreationHeader, { PublishDialog } from './content-creation-header'

import { AssignmentCreateDialog } from '../../activities/assignments/create-assignment/assignment-create'
import { ImageSelector } from '@trivie/ui-web/components/image-selector'
import UnsplashImageDialog from './unsplash-image-dialog'
import { scrollToCitation } from '@trivie/core/src/utils/scrollToCitation'
import { QuizApi } from '@trivie/core/src/services/api-objects'

const usePrevious = (value) => {
  const ref = useRef()
  useEffect(() => {
    ref.current = value
  })
  return ref.current
}

const useQuery = () => {
  const { search } = useLocation()

  return React.useMemo(() => new URLSearchParams(search), [search])
}

export const CreateQuizScreen: React.FunctionComponent = observer(() => {
  const { contentCreationStore, resourceStore, manageUserStore } = useStores()
  const { getAvailableResources } = resourceStore
  const { userProfile } = manageUserStore
  const { quiz, setQuiz, selectedTopic } = contentCreationStore

  const [q, setq] = useState<Instance<typeof Quiz> | null | undefined>(quiz)
  const [selectedTab, setSelectedTab] = useState<any>(null)

  const mercuryEnabled = userProfile?.workspace_settings?.mercury_enabled
  const selectedQuestion = q?.selectedQuestion

  const quizDrawerWidth = 300
  const resourceDrawerWidth = `calc(50vw - ${q?.showQuizPanel ? 150 : 20}px)`

  const [showPublishDialog, setShowPublishDialog] = useState(false)
  const [showWizard, setShowWizard] = useState(false)

  const { quizId } = useParams<any>()
  const history = useHistory()

  const handlePublish = () => {
    if (query.get('topic')) {
      history.goBack()
    } else {
      setShowPublishDialog(true)
    }
  }

  const onSelectionChange = (sel) => {
    if (sel) {
      q?.selectQuestion(sel)
    }
  }

  const query = useQuery()

  const editing = quizId !== 'new' && !query.get('topic')

  const onboarding = query.get('onboarding') === 'true'
  const fromCreate = query.get('create') === 'true'

  const handleBack = () => {
    if (onboarding) {
      history.replace('/content/quizzes')
    } else if (query.get('topic') || quizId === 'new') {
      history.goBack()
    } else {
      history.push(`/content/quizzes/${quizId}/overview`)
    }
  }

  const [existingQuizNames, setExistingQuizNames] = useState<any>([])

  const loadExistingQuizzes = () =>
    QuizApi.getAllQuizzes('quizzes', false, [{ field: 'quiz_title' }], 'list_quizzes').then(
      (res: any) =>
        setExistingQuizNames(
          res.data.rows.map((row) =>
            row.columns
              .find((c) => c.field === 'quiz_title')
              ?.value?.replace(/\s+/g, '')
              ?.toLowerCase(),
          ),
        ),
    )

  useEffect(() => {
    if (quiz) {
      loadExistingQuizzes()
      return
    } else if (editing) {
      QuizApi.getAllQuizzes('quizzes', false, [{ field: 'quiz_title' }], 'list_quizzes').then(
        (res: any) => {
          setExistingQuizNames(
            res.data.rows.map((row) => {
              const name = row.columns
                .find((c) => c.field === 'quiz_title')
                ?.value?.replace(/\s+/g, '')
                ?.toLowerCase()
              if (row.id === selectedTopic?.selectedQuiz?.quiz_id) {
                selectedTopic?.selectedQuiz?.set('original_name', name)
              }
              return name
            }),
          )
        },
      )
      QuizHelper.loadQuiz(quizId, { showQuizPanel: !max1280, showResourcePanel: !mobile })
        .then((loadedQuiz) => {
          setQuiz(loadedQuiz)
          setq(loadedQuiz)
        })
        .catch((e) => console.warn(e))
    } else if (selectedTopic?.selectedQuiz) {
      loadExistingQuizzes()
      setq(selectedTopic?.selectedQuiz)
    } else {
      loadExistingQuizzes()
      const newQuiz = QuizHelper.createNewQuiz({
        showQuizPanel: !max1280,
        showResourcePanel: !mobile,
      })
      newQuiz?.set('questions', [QuestionHelper.createNewQuestion()])
      newQuiz?.selectQuestion(newQuiz.questions![0])
      setq(newQuiz)
      setQuiz(newQuiz)
    }
  }, [quizId])

  useEffect(() => {
    if (
      existingQuizNames &&
      existingQuizNames.includes(q?.title?.replace(/\s+/g, '')?.toLowerCase() as never) &&
      q?.title?.replace(/\s+/g, '')?.toLowerCase() !==
        q?.original_name?.replace(/\s+/g, '')?.toLowerCase()
    ) {
      q?.set('duplicate_name', true)
    } else {
      q?.set('duplicate_name', false)
    }
  }, [existingQuizNames?.length, q?.title])

  const prev = usePrevious(q?.questions?.indexOf(q?.selectedQuestion)) ?? 0
  const slideRef = useSpringRef()
  const transitions: any = useTransition(q?.questions?.indexOf(q?.selectedQuestion), {
    ref: slideRef,
    // config: { mass: 1, tension: 170, friction: 26 },
    keys: null,
    from: (item) => {
      const current = Number(q?.questions?.indexOf(q?.selectedQuestion))
      return {
        opacity: 0,
        transform: `translate3d(${current >= prev ? '100%' : '-100%'},0,0)`,
      }
    },
    enter: { opacity: 1, transform: 'translate3d(0%,0,0)' },
    leave: (item) => {
      const current = Number(q?.questions?.indexOf(q?.selectedQuestion))
      return q?.questions![item!]?.removing
        ? {
            opacity: 0,
            transform: `translate3d('-40%',1000px,0)`,
          }
        : {
            opacity: 0,
            transform: `translate3d(${current >= prev ? '-100%' : '100%'},0,0)`,
          }
    },
  })

  const handleCitations = async () => {
    // Selected question has resource id and doc id
    if (q?.selectedQuestion?.resource_id && q?.selectedQuestion?.document_id) {
      const attachedResource = q?.resources?.find(
        (r) => r.resource_id === q?.selectedQuestion?.resource_id,
      )
      const loadedResource = q?.loadedResources?.length
        ? q?.loadedResources?.find((r) => r.resource_id === q?.selectedQuestion?.resource_id)
        : null
      // Is resource already attached to quiz?
      if (attachedResource) {
        // Attach as selected mercury resource - triggers document polling if necessary
        q?.setMercuryResource(attachedResource)
      } else if (loadedResource) {
        // Check if this resource has already been loaded and attach
        q?.setMercuryResource(loadedResource)
      } else {
        // Grab the unattached resource details,
        const availableResources = await getAvailableResources()
        const available = ResourceHelper.fetchedResourcesToResources(availableResources)
        const resource = available?.find(
          (r: any) => r.resource_id === q?.selectedQuestion?.resource_id,
        )

        if (resource) {
          // add to loaded resources to avoid querying again
          if (q?.loadedResources?.length) {
            q?.set('loadedResources', [...q.loadedResources, resource])
          } else {
            q?.set('loadedResources', [resource])
          }
          // Attach as selected mercury resource - triggers document polling if necessary
          q?.setMercuryResource(resource)
        }
      }
    }
  }

  useEffect(() => {
    if (q?.selectedQuestion) {
      slideRef.start()
      handleCitations()
      setTimeout(() => {
        scrollToCitation(q?.selectedQuestion?.generated_question_id)
      }, 600)
    } else if (q?.questions && q?.questions.length) {
      q?.selectQuestion(q?.questions[0])
      handleCitations()
      setTimeout(() => {
        scrollToCitation(q?.selectedQuestion?.generated_question_id)
      }, 600)
    }
  }, [q, q?.selectedQuestion])

  const mobile = useMediaQuery('(max-width:900px)')
  const max1280 = useMediaQuery('(max-width:1280px)')

  const userToggleQuizPanel = () => {
    if (q?.showQuizPanel) {
      q?.set('userClosedQuizPanel', true)
      q?.set('showQuizPanel', false)

      // if closing quiz panel and under 1280 and user has not manually closed resource panel, open resource panel
      if (max1280 && !mobile && !q?.userClosedResourcePanel) {
        q?.set('showResourcePanel', true)
      }
    } else {
      q?.set('userClosedQuizPanel', false)
      q?.set('showQuizPanel', true)
      // if opening quiz panel manually and under 1280, hide resource panel
      if (max1280 && !mobile && q?.showResourcePanel) {
        q?.set('showResourcePanel', false)
      }
    }
  }

  const userToggleResourcePanel = () => {
    if (q?.showResourcePanel) {
      q?.set('userClosedResourcePanel', true)
      q?.set('showResourcePanel', false)

      // if closing resource panel and under 1280 and user has not manually closed quiz panel, open quiz panel
      if (max1280 && !mobile && !q?.userClosedQuizPanel) {
        q?.set('showQuizPanel', true)
      }
    } else {
      q?.set('userClosedResourcePanel', false)
      q?.set('showResourcePanel', true)
      // if opening resource panel manually and under 1280, hide quiz panel
      if (max1280 && !mobile && q?.showQuizPanel) {
        q?.set('showQuizPanel', false)
      }
    }
  }

  useEffect(() => {
    if (mobile) {
      setTimeout(() => {
        scrollToCitation(selectedQuestion?.generated_question_id)
      }, 1000)
    }
  }, [mobile])

  useEffect(() => {
    // if under 1280 and showing both panels hide quiz panel
    if (max1280 && q?.showQuizPanel && q?.showResourcePanel) {
      q?.set('showQuizPanel', false)
    }

    if (!max1280 && !q?.userClosedQuizPanel) {
      // if over 1280 and user has not manually closed the quiz panel, show
      q?.set('showQuizPanel', true)
    }

    if (!max1280 && !q?.userClosedResourcePanel) {
      // if over 1280 and user has not manually closed the resource panel, show
      q?.set('showResourcePanel', true)
    }
  }, [max1280])

  if (!selectedQuestion) {
    return null
  }

  const quizPanel = (
    <ContentQuizSidebar
      quiz={q}
      mercuryEnabled={mercuryEnabled}
      onSelectionChange={onSelectionChange}
      selectedQuestion={selectedQuestion}
    />
  )

  const cards: ((props: AnimatedProps<{ style: React.CSSProperties }>) => React.ReactElement)[] =
    q.questions?.map((qu, index) => {
      return ({ style }) => {
        return (
          <animated.div id="Question" style={{ ...style, ...ANIMATED_CARD }}>
            {qu?.final ? (
              <FinalCard handlePublish={handlePublish} quiz={q} />
            ) : (
              <QuestionForm onSelectionChange={onSelectionChange} question={qu} />
            )}
          </animated.div>
        )
      }
    }) ?? []

  return (
    <Box sx={ROOT}>
      <AppBar position="fixed" elevation={0} sx={HEADER}>
        <div style={{ height: 70 }}>
          <ContentCreationHeader
            onBack={handleBack}
            save={Boolean(query.get('topic'))}
            showUnsavedPopup={
              query.get('topic')
                ? false
                : fromCreate
                  ? true
                  : quizId === 'new'
                    ? false
                    : !(q?.approved || false)
            }
            edit={fromCreate ? false : quizId !== 'new'}
            publishData={q}
            titleHeader={
              <TextField
                variant="standard"
                sx={{
                  '& .MuiInputBase-root': {
                    fontSize: 18,
                    fontWeight: 600,
                  },
                  '& .MuiInput-underline:after': {
                    borderBottomColor:
                      q?.duplicate_name || q?.title?.length === 0 || q?.title === null
                        ? color.red
                        : q?.title && q?.title.length > 1000
                          ? color.yellow
                          : color.blue,
                  },
                  '& .MuiInput-underline:before': {
                    borderBottomColor:
                      q?.duplicate_name || q?.title?.length === 0 || q?.title === null
                        ? color.red
                        : q?.title && q?.title.length > 1000
                          ? color.yellow
                          : color.shade5,
                  },
                }}
                inputProps={{ id: 'QuizName' }}
                placeholder="Quiz Title"
                error={Boolean(q?.duplicate_name)}
                helperText={q?.duplicate_name ? 'This name is already in use.' : ''}
                style={{
                  width: `${q?.title?.length ? q?.title?.length / 1.3 : 20}ch`,
                  minWidth: 339,
                  maxWidth: window.innerWidth - 450,
                }}
                value={q.title ?? ''}
                onChange={(e) => q?.set('title', e.target.value)}
              />
            }
          />
        </div>
      </AppBar>
      {showPublishDialog && (
        <PublishDialog
          open={showPublishDialog}
          quiz={q}
          setShowWizard={setShowWizard}
          onClose={() => {
            setShowPublishDialog(false)
          }}
          onConfirm={() => {
            setShowPublishDialog(false)
          }}
        />
      )}
      {showWizard && (
        <AssignmentCreateDialog
          fromContentCreation
          open={showWizard}
          requestClose={() => {
            setShowWizard(false)
            handleBack()
          }}
        />
      )}
      {mobile && (
        <MuiDrawer
          sx={{ ...MOBILE_QUIZ_DRAWER }}
          anchor="left"
          variant="temporary"
          open={q?.showQuizPanel}
          onClose={(ev, reason) => q?.set('showQuizPanel', false)}
        >
          <DrawerHeader />
          <IconButton style={QUIZ_PANEL_CLOSE} onClick={userToggleQuizPanel}>
            {q?.showQuizPanel ? <ChevronLeft /> : <ChevronRight />}
          </IconButton>
          {q?.showQuizPanel && quizPanel}
        </MuiDrawer>
      )}
      {!mobile && (
        <QuizDrawer
          sx={{ ...FULL_QUIZ_DRAWER, width: quizDrawerWidth }}
          variant="permanent"
          anchor="left"
          open={q?.showQuizPanel}
        >
          <DrawerHeader />
          <IconButton style={FULL_QUIZ_DRAWER_CLOSE} onClick={userToggleQuizPanel}>
            {q?.showQuizPanel ? <ChevronLeft /> : <ChevronRight />}
          </IconButton>
          {q?.showQuizPanel && quizPanel}
        </QuizDrawer>
      )}
      <Box component="div" sx={MAIN}>
        <DrawerHeader />
        <div style={CONTENT}>
          {mobile && (
            <div style={QUIZ_PANEL_COLLAPSED}>
              <IconButton style={{ alignSelf: 'flex-end', top: -6 }} onClick={userToggleQuizPanel}>
                <ChevronRight />
              </IconButton>
            </div>
          )}
          <div style={CARD_CONTAINER}>
            {q?.showSettings ? (
              <ContentGenerationSettings
                quiz={q}
                onGenerate={() => q.set('showSettings', false)}
                onCancel={() => q.set('showSettings', false)}
              />
            ) : (
              <>
                <div style={CARD}>
                  {Boolean(transitions) &&
                    transitions((style, i) => {
                      const Card = cards[i]
                      return Card ? <Card style={style} /> : null
                    })}
                  {!selectedQuestion?.final && (
                    <QuestionCardFooter
                      creatingTopic={query.get('topic')}
                      editing={editing}
                      onSelectionChange={onSelectionChange}
                      mercuryEnabled={mercuryEnabled}
                      question={selectedQuestion}
                    />
                  )}
                </div>
              </>
            )}
          </div>
          {mobile && (
            <div style={RESOURCE_PANEL_COLLAPSED}>
              <IconButton
                style={{ alignSelf: 'flex-start', top: -6 }}
                onClick={userToggleResourcePanel}
              >
                <ChevronLeft />
              </IconButton>
            </div>
          )}
        </div>
      </Box>
      {mobile && (
        <MuiDrawer
          sx={MOBILE_RESOURCE_DRAWER}
          keepMounted
          variant="temporary"
          anchor="right"
          open={q?.showResourcePanel}
          onClose={(ev, reason) => q?.set('showResourcePanel', false)}
        >
          <DrawerHeader />
          <IconButton style={{ ...RESOURCE_PANEL_CLOSE }} onClick={userToggleResourcePanel}>
            {q?.showResourcePanel ? <ChevronRight /> : <ChevronLeft />}
          </IconButton>
          <ResourcePanel
            quiz={q}
            selectedTab={selectedTab}
            setSelectedTab={(t) => setSelectedTab(t)}
            style={{
              display: !mobile && !q?.showResourcePanel ? 'none' : 'flex',
              width: mobile ? 500 : '100%',
            }}
          />
        </MuiDrawer>
      )}
      {!mobile && (
        <ResourceDrawer
          keepMounted
          sx={{ ...FULL_RESOURCE_DRAWER, width: resourceDrawerWidth }}
          variant="permanent"
          anchor="right"
          open={q?.showResourcePanel}
        >
          <DrawerHeader />
          <IconButton style={FULL_RES_DRAWER_CLOSE} onClick={userToggleResourcePanel}>
            {q?.showResourcePanel ? <ChevronRight /> : <ChevronLeft />}
          </IconButton>
          <ResourcePanel
            quiz={q}
            selectedTab={selectedTab}
            setSelectedTab={(t) => setSelectedTab(t)}
            style={{
              display: !mobile && !q?.showResourcePanel ? 'none' : 'flex',
              width: mobile ? 500 : '100%',
            }}
          />
        </ResourceDrawer>
      )}
    </Box>
  )
})

const FinalCard = observer((props: any) => {
  const { handlePublish, quiz } = props

  const [showUnsplashDialog, setShowUnsplashDialog] = useState(false)
  const [title, setTitle] = useState(quiz?.title)

  return (
    <div
      style={{
        ...FINAL_CARD,
        alignItems: quiz?.generatingSummary ? 'center' : 'flex-start',
        justifyContent: quiz?.generatingSummary ? 'center' : 'space-between',
      }}
    >
      {quiz?.generatingSummary ? (
        <CircularProgress />
      ) : (
        <>
          <div style={{ marginBottom: 20 }}>
            <Text variant="headline" text="Ready to Publish?" />
          </div>
          <div
            style={{
              marginBottom: 24,
              display: 'flex',
              flexDirection: 'column',
              flex: 1,
              justifyContent: 'space-evenly',
            }}
          >
            <Text
              style={{ marginBottom: 12 }}
              variant="body1"
              text="You've reviewed and approved all questions in this quiz."
            />
            {quiz?.generatedTitle && quiz?.generatedImage ? (
              <Text
                style={{ marginBottom: 12 }}
                variant="body1"
                text={`We've selected a cover photo and generated a title and summary for your quiz.`}
              />
            ) : quiz?.generatedTitle && !quiz?.generatedImage ? (
              <Text
                style={{ marginBottom: 12 }}
                variant="body1"
                text={`We've generated a title and summary for your quiz.`}
              />
            ) : !quiz?.generatedTitle && quiz?.generatedImage ? (
              <Text
                style={{ marginBottom: 12 }}
                variant="body1"
                text={`We've selected a cover photo for your quiz.`}
              />
            ) : null}
            <Text
              variant="body1"
              text="Make any updates you'd like and your quiz is ready to publish!"
            />
          </div>
          <div style={{ flexDirection: 'column', display: 'flex', width: '100%' }}>
            <Text
              text="Quiz Photo"
              variant="body2"
              style={{ marginBottom: 6, color: color.shade60 }}
            />
            <div style={{ marginBottom: 24 }}>
              <>
                {showUnsplashDialog && (
                  <UnsplashImageDialog
                    onChangeImage={(f: File | null) => {
                      quiz?.setImage(f)
                    }}
                    handleImageDialogClose={() => setShowUnsplashDialog(false)}
                    open={showUnsplashDialog}
                  />
                )}
                <ImageSelector
                  fullWidth
                  compact
                  imageContainerStyle={{ marginBottom: 0 }}
                  style={{ width: '100%' }}
                  pickerStyle={{ marginBottom: 0, height: 150 }}
                  imageStyle={{ width: '100%', maxWidth: '100%' }}
                  onUnsplashClick={() => setShowUnsplashDialog(true)}
                  imageSrc={quiz?.image}
                  file={quiz?.imageFile}
                  onChange={(f: File | null) => {
                    quiz?.setImage(f ?? null)
                  }}
                />
              </>
            </div>
            <Text
              text="Quiz Title"
              variant="body2"
              style={{ marginBottom: 6, color: color.shade60 }}
            />
            <TextField
              type="text"
              value={title}
              variant="standard"
              onBlur={(e) => quiz?.set('title', title)}
              fullWidth
              style={{ marginBottom: 24 }}
              onChange={(e) => setTitle(e.target.value)}
            />
            <Text
              text="Quiz Summary"
              variant="body2"
              style={{ marginBottom: 6, color: color.shade60 }}
            />
            <TextField
              type="text"
              value={quiz?.description}
              variant="standard"
              fullWidth
              style={{ marginBottom: 24 }}
              multiline
              onChange={(e) => quiz?.set('description', e.target.value)}
            />
            <Button
              disabled={Boolean(quiz?.allErrors && quiz?.allErrors.length > 0)}
              fullWidth
              size="large"
              text="Publish"
              onClick={handlePublish}
            />
          </div>
        </>
      )}
    </div>
  )
})

const openedQuizMixin = (theme: any, width: number): any => ({
  width,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
})

const closedQuizMixin = (theme: any): any => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up('sm')]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
})

const QuizDrawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== 'open',
})((props) => {
  const { sx, theme, open } = props
  // @ts-ignore
  const { width } = sx
  return {
    flexShrink: 0,
    whiteSpace: 'nowrap',
    boxSizing: 'border-box',
    ...(open && {
      ...openedQuizMixin(theme, width),
      '& .MuiDrawer-paper': openedQuizMixin(theme, width),
    }),
    ...(!open && {
      ...closedQuizMixin(theme),
      '& .MuiDrawer-paper': closedQuizMixin(theme),
    }),
  }
})

const openedResourceMixin = (theme: any, width: number): any => ({
  width: width,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
})

const closedResourceMixin = (theme: any): any => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up('sm')]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
})
const ResourceDrawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== 'open',
})((props) => {
  const { sx, theme, open } = props
  // @ts-ignore
  const { width } = sx

  return {
    width,
    flexShrink: 0,
    whiteSpace: 'nowrap',
    boxSizing: 'border-box',
    right: 0,
    position: 'relative',
    ...(open && {
      ...openedResourceMixin(theme, width),
      '& .MuiDrawer-paper': openedResourceMixin(theme, width),
    }),
    ...(!open && {
      ...closedResourceMixin(theme),
      '& .MuiDrawer-paper': closedResourceMixin(theme),
    }),
  }
})

const DrawerHeader = styled('div')(({ theme }: any) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  justifyContent: 'flex-end',
  minHeight: '70px !important',
}))

const ROOT: React.CSSProperties = {
  width: '100%',
  display: 'flex',
  height: '100%',
  position: 'absolute',
  top: '0px',
  left: '0px',
  background: '#F4F4F4',
}
const MAIN: React.CSSProperties = {
  flexGrow: 1,
  overflowY: 'hidden',
}
const HEADER: React.CSSProperties = {
  backgroundColor: '#fff',
  minHeight: '70px !important',
  borderRadius: 0,
  zIndex: 1300,
}
const ANIMATED_CARD: React.CSSProperties = {
  width: '100%',
  position: 'absolute',
  height: '100%',
  padding: 24,
  overflowY: 'auto',
  display: 'flex',
  justifyContent: 'center',
}
const FULL_QUIZ_DRAWER = {
  backgroundColor: 'rgba(248, 250, 253)',
  '& .MuiDrawer-paper': {
    boxSizing: 'border-box',
    borderRadius: '0px !important',
    backgroundColor: 'rgba(248, 250, 253)',
  },
}
const FULL_RESOURCE_DRAWER = {
  backgroundColor: 'rgba(248, 250, 253)',
  '& .MuiDrawer-paper': {
    boxSizing: 'border-box',
    borderRadius: '0px !important',
    backgroundColor: 'rgba(248, 250, 253)',
    width: 500,
  },
}
const MOBILE_QUIZ_DRAWER = {
  '& .MuiDrawer-paper': {
    boxSizing: 'border-box',
    borderRadius: '0px !important',
    backgroundColor: 'rgba(248, 250, 253)',
    width: 300,
  },
  position: 'relative',
}
const MOBILE_RESOURCE_DRAWER = {
  '& .MuiDrawer-paper': {
    boxSizing: 'border-box',
    borderRadius: '0px !important',
    backgroundColor: 'rgba(248, 250, 253)',
  },
  position: 'relative',
}
const QUIZ_PANEL_CLOSE: React.CSSProperties = {
  right: 13,
  alignSelf: 'flex-end',
  position: 'absolute',
  top: 80,
}
const RESOURCE_PANEL_CLOSE: React.CSSProperties = {
  left: 17,
  alignSelf: 'flex-end',
  position: 'absolute',
  top: 80,
  zIndex: 9999,
}
const FULL_QUIZ_DRAWER_CLOSE: React.CSSProperties = { ...QUIZ_PANEL_CLOSE, right: 12 }
const FULL_RES_DRAWER_CLOSE: React.CSSProperties = { ...RESOURCE_PANEL_CLOSE, left: 12 }
const COLLAPSED: React.CSSProperties = {
  padding: 8,
  paddingTop: 16,
  height: '100%',
  backgroundColor: 'rgba(248, 250, 253)',
  width: 70,
}
const QUIZ_PANEL_COLLAPSED: React.CSSProperties = {
  ...COLLAPSED,
  borderRight: '1px solid rgba(0, 0, 0, 0.12)',
}
const RESOURCE_PANEL_COLLAPSED: React.CSSProperties = {
  ...COLLAPSED,
  borderLeft: '1px solid rgba(0, 0, 0, 0.12)',
}
const CARD_CONTAINER: React.CSSProperties = {
  width: '100%',
  height: 'calc(100% - 60px)',
  display: 'flex',
  justifyContent: 'center',
}
const CONTENT: React.CSSProperties = {
  height: 'calc(100% - 70px)',
  display: 'flex',
  flexDirection: 'row',
  overflow: 'hidden',
}
const FINAL_CARD: React.CSSProperties = {
  background: '#fff',
  borderRadius: 12,
  border: `1px solid #EFF0F6`,
  padding: 32,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  maxWidth: 536,
  width: '100%',
  minHeight: 600,
  alignSelf: 'center',
}
const CARD: React.CSSProperties = {
  position: 'relative',
  width: '100%',
  display: 'flex',
  justifyContent: 'center',
}
