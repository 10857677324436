import { Instance, types } from 'mobx-state-tree'

export const APIKey = types.model('APIKey').props({
  access_code: types.maybe(types.string),
  created_by: types.maybe(types.string),
  created_on: types.maybe(types.string),
  expiration: types.maybe(types.string),
  key: types.maybe(types.string),
  key_name: types.maybe(types.string),
})

export const APIKeys = types.model('APIKeys').props({
  keys: types.maybe(types.array(APIKey)),
})

export type APIKeyType = Instance<typeof APIKey>
export type APIKeysType = Instance<typeof APIKeys>
