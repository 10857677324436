import React, { useState } from 'react'
import { observer } from 'mobx-react-lite'

import { TableHelper } from '../../../../tables'
import { SurveyTable } from '../../../../tables/survey-table'

const UnscheduledSurveys: React.FunctionComponent = observer(() => {
  const [tableHelper] = useState(
    new TableHelper('list_unscheduled_surveys', 'surveys', 'surveys', undefined, 'unscheduled'),
  )

  return <SurveyTable filter="unscheduled" tableHelper={tableHelper} />
})

export const UnscheduledSurveysTab = () => {
  return {
    name: 'Unscheduled',
    path: `/activities/surveys/unscheduled`,
    component: () => <UnscheduledSurveys />,
  }
}
