import { observer } from 'mobx-react-lite'
import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { DateTime } from 'luxon'
import { Box, CircularProgress, Stack } from '@mui/material'

import {
  DescriptionRounded,
  EditRounded,
  LeaderboardRounded,
  PeopleRounded,
  PersonAddRounded,
  TodayRounded,
} from '@mui/icons-material'

import { Dialog } from '@trivie/ui-web/components/dialogs/dialog'
import { intersection, useStores } from '@trivie/core'
import { UserApi } from '@trivie/core/src/services/api-objects/UserApi'
import { GroupApi } from '@trivie/core/src/services/api-objects/GroupApi'
import { Header } from '@trivie/ui-web/components/header'
import { Text } from '@trivie/ui-web/components/text'
import { Leaderboard } from '@trivie/ui-web/components/leaderboard'
import { color } from '@trivie/ui-web/theme'
import { ShortList } from '@trivie/ui-web/components/short-list'
import { TopicApi } from '@trivie/core/src/services/api-objects/TopicApi'
import { QuizApi } from '@trivie/core/src/services/api-objects/QuizApi'
import { ContestScoring } from './create-contest'
import { Panel } from '@trivie/ui-web/components/panel/panel'

export const ContestDetailsScreen: React.FunctionComponent = observer(() => {
  const { id, mainTab } = useParams() as any
  const { contestStore, manageUserStore } = useStores()
  const {
    setEditID,
    getContestLeaderboard,
    getContest,
    contestDetails,
    leaderboard,
    triggerRefresh,
    setTriggerRefresh,
    setShowEditWizard,
  } = contestStore
  const { permissions } = manageUserStore

  const [loading, setLoading] = useState(true)
  const [competitors, setCompetitors] = useState<any>([])
  const [competitorCount, setCompetitorCount] = useState(0)
  const [content, setContent] = useState<any>([])
  const [showScoringDialog, setShowScoringDialog] = useState(false)

  const getUserCompetitors = (users, details) => {
    return intersection(
      users.data.rows,
      details?.user_ids,
      (user1: any, user2: any) => user1.id === user2,
    ).map((user) => {
      const first_name = user.columns.find((column) => column.field === 'first_name')
      const last_name = user.columns.find((column) => column.field === 'last_name')
      const photo_url = user.columns.find((column) => column.field === 'photo_url')
      return {
        id: user.id,
        type: 'user',
        first_name: first_name.value,
        last_name: last_name.value,
        photo_url: photo_url.value,
      }
    })
  }

  const getGroupCompetitors = (groups, details) => {
    return intersection(
      groups.data.rows,
      details?.group_ids,
      (group1: any, group2: any) => group1.id === group2,
    ).map((group) => {
      const group_name = group.columns.find((column) => column.field === 'group_name')
      const group_user_count = group.columns.find((column) => column.field === 'group_user_count')
      return {
        id: group.id,
        type: 'group',
        group_name: group_name.value,
        group_user_count: group_user_count.value,
      }
    })
  }

  const getTopicContent = (topics, details) => {
    if (details?.topic_ids.length === 0 && details?.quiz_ids.length === 0) {
      return [
        {
          topic_id: null,
          type: 'topic',
          topic_title: 'All Content',
        },
      ]
    }
    return intersection(
      topics.data.rows,
      details?.topic_ids,
      (topic1: any, topic2: any) => topic1.id === topic2,
    ).map((topic) => {
      const topic_title = topic.columns.find((column) => column.field === 'topic_title')
      const quiz_count = topic.columns.find((column) => column.field === 'quiz_count')
      return {
        topic_id: topic.id,
        type: 'topic',
        topic_title: topic_title.value,
        quiz_count: quiz_count.value,
      }
    })
  }

  const getQuizContent = (quizzes, details) => {
    return intersection(
      quizzes.data.rows,
      details?.quiz_ids,
      (quiz1: any, quiz2: any) => quiz1.id === quiz2,
    ).map((quiz) => {
      const quiz_title = quiz.columns.find((column) => column.field === 'quiz_title')
      return { id: quiz.id, type: 'quiz', quiz_title: quiz_title.value }
    })
  }

  const getDetails = async () => {
    return new Promise((resolve) => {
      // Get contest details
      getContest(Number(id)).then(async (data) => {
        // Get all users, groups, topics, quizzes
        const [users, groups, topics, quizzes] = await Promise.all([
          UserApi.getUsers(
            'users',
            false,
            [{ field: 'first_name' }, { field: 'last_name' }, { field: 'photo_url' }],
            'list_users',
          ),
          GroupApi.getAllGroups(
            'groups',
            false,
            [{ field: 'group_name' }, { field: 'group_user_count' }],
            'list_groups',
          ),
          TopicApi.getAllTopics(
            'topics',
            false,
            [{ field: 'topic_title' }, { field: 'quiz_count' }],
            'list_topics',
          ),
          QuizApi.getAllQuizzes('quizzes', false, [{ field: 'quiz_title' }], 'list_quizzes'),
        ])

        const userCompetitors = getUserCompetitors(users, data)
        const groupCompetitors = getGroupCompetitors(groups, data)

        resolve({
          details: data,
          topics: getTopicContent(topics, data),
          quizzes: getQuizContent(quizzes, data),
          users: userCompetitors,
          groups: groupCompetitors,
          count:
            userCompetitors.length +
            groupCompetitors.reduce((acc: number, curr: any) => acc + curr.group_user_count, 0),
        })
      })
    })
  }

  const [initialized, setInitialized] = useState(false)

  const load = () => {
    if (mainTab !== 'scheduled') {
      getContestLeaderboard(Number(id), false)
    }
    getDetails().then((details: any) => {
      setCompetitors([...details.groups, ...details.users])
      setContent([...details.topics, ...details.quizzes])
      setCompetitorCount(details.count)
      setLoading(false)
      setTriggerRefresh(false)
      setInitialized(true)
    })
  }

  useEffect(() => {
    if (Boolean(triggerRefresh) || !initialized) {
      load()
    }
  }, [triggerRefresh])

  const edit = () => {
    setEditID(Number(id))
    setShowEditWizard(true)
  }

  const primary = mainTab === 'scheduled' && {
    disabled: !permissions?.permissions.some((p) => p.code === 'can_edit_contest'),
    startIcon: <EditRounded />,
    onClick: edit,
    text: 'Edit Contest',
  }

  return loading || triggerRefresh ? (
    <div style={LOADER}>
      <CircularProgress />
    </div>
  ) : (
    <>
      <Header divider title={contestDetails?.title ?? ''} primaryAction={primary} />
      {mainTab === 'scheduled' ? (
        <Stack direction="row" useFlexGap gap={2}>
          <Stack flex={1} useFlexGap gap={2}>
            <ContestDetailsPanel
              setShowScoringDialog={setShowScoringDialog}
              details={contestDetails}
              competitorCount={competitorCount}
            />
            <ShortList data={content} type="Content" />
          </Stack>
          <Stack useFlexGap gap={2}>
            <ShortList data={competitors} type="Competitors" />
          </Stack>
        </Stack>
      ) : (
        <Stack direction="row" useFlexGap gap={2}>
          <Box flex={1}>
            <Leaderboard type="contest" data={leaderboard} />
          </Box>
          <Stack useFlexGap gap={2} flex={1}>
            <ContestDetailsPanel
              setShowScoringDialog={setShowScoringDialog}
              details={contestDetails}
              competitorCount={competitorCount}
            />
            <Stack useFlexGap gap={2}>
              <ShortList data={competitors} type="Competitors" />
              <ShortList data={content} type="Content" />
            </Stack>
          </Stack>
        </Stack>
      )}
      {showScoringDialog && (
        <Dialog
          confirmBtnLabel="Close"
          maxWidth="sm"
          onClose={() => setShowScoringDialog(false)}
          onConfirm={() => setShowScoringDialog(false)}
          title="Contest Scoring"
          open={showScoringDialog}
        >
          <Box>
            <ContestScoring
              viewOnly
              form={{
                scoring_method: 'custom',
                set: () => {},
                custom_scoring: contestDetails?.custom_scoring,
              }}
            />
          </Box>
        </Dialog>
      )}
    </>
  )
})

const ContestDetailsPanel = observer((props: any) => {
  const { details, competitorCount, setShowScoringDialog } = props

  const formatDate = (date: string) => {
    const formatted = `${DateTime.fromISO(date).toLocaleString(
      DateTime.DATE_SHORT,
    )} at ${DateTime.fromISO(date).toLocaleString(DateTime.TIME_SIMPLE)}`
    return formatted
  }
  return (
    <Panel title="Details" divider>
      <Stack alignItems="center" useFlexGap gap={2} direction="row">
        <PeopleRounded htmlColor={color.shade70} />
        <Stack direction="column">
          <Text variant="body1Medium" style={{ marginBottom: 4 }} text="Total Competitors" />
          <Text style={{ color: color.shade50 }} variant="body1" text={String(competitorCount)} />
        </Stack>
      </Stack>
      <Stack alignItems="center" useFlexGap gap={2} direction="row">
        <DescriptionRounded htmlColor={color.shade70} />
        <Stack direction="column">
          <Text variant="body1Medium" text="Description" />
          <Text style={{ color: color.shade50 }} variant="body1" text={details?.description} />
        </Stack>
      </Stack>
      <Stack alignItems="center" useFlexGap gap={2} direction="row">
        <TodayRounded htmlColor={color.shade70} />
        <Stack direction="column">
          <Text variant="body1Medium" text="Start Date" />
          <Text
            style={{ color: color.shade50 }}
            variant="body1"
            text={formatDate(details?.start_datetime)}
          />
        </Stack>
      </Stack>
      <Stack alignItems="center" useFlexGap gap={2} direction="row">
        <TodayRounded htmlColor={color.shade70} />
        <Stack direction="column">
          <Text variant="body1Medium" text="End Date" />
          <Text
            style={{ color: color.shade50 }}
            variant="body1"
            text={formatDate(details?.end_datetime)}
          />
        </Stack>
      </Stack>
      <Stack alignItems="center" useFlexGap gap={2} direction="row">
        <PersonAddRounded htmlColor={color.shade70} />
        <Stack direction="column">
          <Text variant="body1Medium" text="Created By" />
          <Text style={{ color: color.shade50 }} variant="body1" text={details?.created_by} />
        </Stack>
      </Stack>
      <Stack alignItems="center" useFlexGap gap={2} direction="row">
        <LeaderboardRounded htmlColor={color.shade70} />
        <Stack direction="column">
          <Text variant="body1Medium" text="Scoring Type" />
          <Text
            onClick={() => {
              if (details?.scoring_method === 'custom') {
                setShowScoringDialog(true)
              }
            }}
            style={{
              cursor: details?.scoring_method === 'custom' ? 'pointer' : 'default',
              color: details?.scoring_method === 'custom' ? color.blue : color.shade50,
              textDecoration: details?.scoring_method === 'custom' ? 'underline' : 'none',
              textTransform: 'capitalize',
            }}
            variant="body1"
            text={details?.scoring_method}
          />
        </Stack>
      </Stack>
    </Panel>
  )
})

const LOADER: React.CSSProperties = {
  height: window.innerHeight - 150,
  flexDirection: 'column',
  flex: 1,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
}
