import React from 'react'

import {
  BoltRounded,
  ChevronRightRounded,
  EmojiObjectsRounded,
  GroupRounded,
  ListAltRounded,
  Settings,
  TrendingUpRounded,
} from '@mui/icons-material'

import { color } from '@trivie/ui-web/theme'
import {
  ActivityScreen,
  AssignmentsScreen,
  EngagementScreen,
  GettingStarted,
  GroupsScreen,
  KnowledgeScreen,
  LeaderboardsScreen,
  QuizzesScreen,
  TagsScreen,
  TopicsScreen,
  UsersScreen,
} from '../screens'
import { QuestionsScreen } from '../screens/content/questions/questions-screen'
import { ResourcesScreen } from '../screens/content/resources'
import { Settings as SettingsScreen } from '../screens/settings'
import { SurveysScreen } from '../screens/activities/surveys'
import { BadgesScreen } from '../screens/activities/badges'
import { MessagesScreen } from '../screens/activities/messages/messages-screen'
import { ContestsScreen } from '../screens/activities/contests/contests-screen'
import { ReportsScreen } from '../screens/insights/reports/reports-screen'
import { PathsScreen } from '../screens/content/learning-paths/learning-paths-screen'

export const routes = [
  {
    name: 'insights',
    label: 'Insights',
    path: '/insights',
    icon: (active?: boolean, disabled?: boolean) => (
      <TrendingUpRounded
        style={{
          marginRight: 16,
          color: active ? '#1B59F8' : disabled ? color.palette.alto : 'rgba(0, 0, 0, .7)',
        }}
      />
    ),
    routes: [
      {
        name: 'knowledge',
        label: 'Knowledge',
        path: '/insights/knowledge',
        exact: true,
        main: () => <KnowledgeScreen />,
      },
      {
        name: 'engagement',
        label: 'Engagement',
        path: '/insights/engagement',
        exact: true,
        main: () => <EngagementScreen />,
      },
      {
        name: 'reports',
        label: 'Reports',
        link: '/insights/reports/custom',
        path: '/insights/reports/:mainTab',
        prefix: '/insights/reports',
        main: () => <ReportsScreen />,
      },
      {
        name: 'leaderboards',
        label: 'Leaderboards',
        path: '/insights/leaderboards',
        exact: true,
        main: () => <LeaderboardsScreen />,
      },
      {
        name: 'activity',
        label: 'Activity Log',
        path: '/insights/activity',
        exact: true,
        main: () => <ActivityScreen />,
      },
    ],
  },
  {
    name: 'people',
    label: 'People',
    path: '/people',
    icon: (active?: boolean, disabled?: boolean) => (
      <GroupRounded
        style={{
          marginRight: 16,
          color: active ? '#1B59F8' : disabled ? color.palette.alto : 'rgba(0, 0, 0, .7)',
        }}
      />
    ),
    rightIcon: (active?: boolean, disabled?: boolean) => (
      <ChevronRightRounded
        style={{
          marginRight: 16,
          color: active ? '#1B59F8' : disabled ? color.palette.alto : 'rgba(0, 0, 0, .7)',
        }}
      />
    ),
    routes: [
      {
        name: 'users',
        label: 'Users',
        link: '/people/users',
        path: '/people/users',
        prefix: '/people/users',
        main: () => <UsersScreen />,
      },
      {
        name: 'groups',
        label: 'Groups',
        path: '/people/groups',
        main: () => <GroupsScreen />,
      },
      {
        name: 'tags',
        label: 'Tags',
        path: '/people/tags',
        main: () => <TagsScreen />,
      },
    ],
  },
  {
    name: 'content',
    label: 'Content',
    path: '/content',
    icon: (active?: boolean, disabled?: boolean) => (
      <BoltRounded
        style={{
          marginRight: 16,
          color: active ? '#1B59F8' : disabled ? color.palette.alto : 'rgba(0, 0, 0, .7)',
        }}
      />
    ),
    rightIcon: (active?: boolean, disabled?: boolean) => (
      <ChevronRightRounded
        style={{
          marginRight: 16,
          color: active ? '#1B59F8' : disabled ? color.palette.alto : 'rgba(0, 0, 0, .7)',
        }}
      />
    ),
    routes: [
      {
        name: 'paths',
        label: 'Paths',
        path: '/content/paths',
        main: () => <PathsScreen />,
        render: (props: any) => {
          const { children } = props
          return <>{children}</>
        },
      },
      {
        name: 'topics',
        label: 'Topics',
        path: '/content/topics',
        main: () => <TopicsScreen />,
        render: (props: any) => {
          const { children } = props
          return <>{children}</>
        },
      },
      {
        name: 'quizzes',
        label: 'Quizzes',
        path: '/content/quizzes',
        main: () => <QuizzesScreen />,
      },
      {
        name: 'questions',
        label: 'Questions',
        path: '/content/questions',
        main: () => <QuestionsScreen />,
      },
      {
        name: 'sourcecs',
        label: 'Sources',
        path: '/content/sources',
        main: () => <ResourcesScreen />,
      },
    ],
  },
  {
    name: 'activities',
    label: 'Activities',
    path: '/activities',
    icon: (active?: boolean, disabled?: boolean) => (
      <ListAltRounded
        style={{
          marginRight: 16,
          color: active ? '#1B59F8' : disabled ? color.palette.alto : 'rgba(0, 0, 0, .7)',
        }}
      />
    ),
    rightIcon: (active?: boolean, disabled?: boolean) => (
      <ChevronRightRounded
        style={{
          marginRight: 16,
          color: active ? '#1B59F8' : disabled ? color.palette.alto : 'rgba(0, 0, 0, .7)',
        }}
      />
    ),
    routes: [
      {
        name: 'assignments',
        label: 'Assignments',
        link: '/activities/assignments/live',
        path: '/activities/assignments/:mainTab',
        prefix: '/activities/assignments',
        main: () => <AssignmentsScreen />,
      },
      {
        name: 'surveys',
        label: 'Surveys',
        link: '/activities/surveys/live',
        path: '/activities/surveys/:mainTab',
        prefix: '/activities/surveys',
        main: () => <SurveysScreen />,
      },
      {
        name: 'badges',
        label: 'Badges',
        link: '/activities/badges/custom',
        path: '/activities/badges/:mainTab',
        prefix: '/activities/badges',
        main: () => <BadgesScreen />,
      },

      {
        name: 'messages',
        label: 'Messages',
        link: '/activities/messages/all',
        path: '/activities/messages/:mainTab',
        prefix: '/activities/messages',
        main: () => <MessagesScreen />,
      },
      {
        name: 'contests',
        label: 'Contests',
        link: '/activities/contests/live',
        path: '/activities/contests/:mainTab',
        prefix: '/activities/contests',
        main: () => <ContestsScreen />,
      },
    ],
  },
]

export const supportRoutes = [
  {
    name: 'support',
    label: 'Get Started',
    path: '/getting-started',
    icon: (active?: boolean, disabled?: boolean) => (
      <EmojiObjectsRounded
        style={{
          marginRight: 16,
          color: active ? '#1B59F8' : disabled ? color.palette.alto : 'rgba(0, 0, 0, 0.7)',
        }}
      />
    ),
    exact: true,
    main: () => <GettingStarted />,
  },
  {
    name: 'settings',
    label: 'Settings',
    path: '/settings',
    icon: (active?: boolean, disabled?: boolean) => (
      <Settings
        style={{
          marginRight: 16,
          color: active ? '#1B59F8' : disabled ? color.palette.alto : 'rgba(0, 0, 0, 0.7)',
        }}
      />
    ),
    exact: true,
    main: () => <SettingsScreen />,
  },
]
