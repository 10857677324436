import React, { useCallback, useEffect, useState } from 'react'
import { Box, Checkbox, Stack, Step, StepButton, Stepper } from '@mui/material'
import { ArrowBack } from '@mui/icons-material'
import { useStores } from '@trivie/core'
import { observer } from 'mobx-react-lite'
import { IAutocompleteGroupOptions } from '@trivie/core'
import { AutocompleteGroupSelector } from '../autocomplete-selector'

import { Dialog } from '../dialogs/dialog'
import { Button } from '../button'
import { Text } from '../text'
import { color } from '../../theme'
import { EmailSelect } from '../email-select'

export interface EmailDialogProps {
  open: boolean
  title: string
  confirmedMessage?: string
  initialSelections?: number[]
  onConfirm: (emails: string[]) => void
  onClose: () => void
  handleCancel?: () => void
  userEmail: string
  getEmailConfirmationMessage: any
}

export const EmailDialog = observer((props: EmailDialogProps) => {
  const {
    open,
    userEmail,
    getEmailConfirmationMessage,
    title,
    onConfirm,
    initialSelections = [],
    onClose,
  } = props
  const { reportStore } = useStores()
  const { customReports } = reportStore

  const [selectedEmails, setSelectedEmails] = useState([])
  const [sendToMyself, setSendToMyself] = useState(false)

  const [autocompleteOptions, setAutocompleteOptions] = useState<IAutocompleteGroupOptions[]>([])
  const [selectedAutocompleteOptions, setSelectedAutocompleteOptions] = useState<
    IAutocompleteGroupOptions[]
  >([])

  const [activeStep, setActiveStep] = useState(0)
  const steps = ['Reports', 'Recipients']

  const [confirmationMessage, setConfirmationMessage] = useState<string>('')
  const [hasErrors, setHasErrors] = useState(false)

  const transfromData = (targetData: any) => {
    const transformedData: IAutocompleteGroupOptions[] = []
    targetData.forEach((element) => {
      const item: IAutocompleteGroupOptions = {
        type: 'Email Reports',
        id: element.id,
        label: element.view_name,
      }
      transformedData.push(item)
    })
    setAutocompleteOptions(transformedData)
    setSelectedAutocompleteOptions(transformedData.filter((f) => initialSelections.includes(f.id)))
  }

  useEffect(() => {
    transfromData(customReports.slice())
  }, [])

  const handleConfirm = () => {
    setActiveStep(-1) //go to defaultState of Switch
    if (sendToMyself) {
      onConfirm([...selectedEmails, userEmail])
    } else {
      onConfirm(selectedEmails)
    }
  }

  const handleClose = () => {
    onClose()
    setTimeout(() => {
      setActiveStep(0)
    }, 200)
  }

  useEffect(() => {
    if (!open) {
      setActiveStep(0)
    }
  }, [open])

  const onChange = (emails) => {
    setSelectedEmails(emails)
    setConfirmationMessage(getEmailConfirmationMessage(emails, sendToMyself))
  }

  const onChangeSelect = (val) => {
    setSendToMyself(val)
    setConfirmationMessage(getEmailConfirmationMessage(selectedEmails, Boolean(val)))
  }

  useEffect(() => {
    let errors = false
    selectedEmails.map((email: string) => {
      if (!email.includes('@')) {
        errors = true
      }
    })
    setHasErrors(errors)
  }, [selectedEmails.length])

  const handleAddUserGroup = (selected: IAutocompleteGroupOptions[]) => {
    setSelectedAutocompleteOptions(selected)
  }

  const [searchBoxHeight, setSearchBoxHeight] = useState(0)
  const elementRef = useCallback((node) => {
    if (!node) return
    const resizeObserver = new ResizeObserver((entries) => {
      // Do what you want to do when the size of the element changes
      const _height = entries[0].contentRect.height || 0
      setSearchBoxHeight(_height)
    })
    resizeObserver.observe(node)
  }, [])
  const step1 = () => {
    return (
      <div ref={elementRef}>
        <AutocompleteGroupSelector
          open
          noOptionsText="No matching reports."
          options={autocompleteOptions}
          defaultOptions={[]}
          listHeight="32vh"
          selectedOptions={selectedAutocompleteOptions}
          handleAddUserGroup={handleAddUserGroup}
        />
      </div>
    )
  }

  const step2 = () => {
    return (
      <>
        <EmailSelect selected={selectedEmails} onChange={onChange} />
        <Stack alignItems="center" mt={2} useFlexGap gap={1} direction="row">
          <Checkbox
            color="success"
            onChange={(e) => onChangeSelect(e.target.checked)}
            checked={sendToMyself}
          />
          <Text text="Send a copy to myself" variant="body1Medium" />
          <Text text={userEmail} variant="body1Medium" style={{ color: color.shade50 }} />
        </Stack>
      </>
    )
  }

  const getStepContent = () => {
    switch (activeStep) {
      case 0:
        return step1()
      case 1:
        return step2()
      default:
        return (
          <Text
            component="div"
            variant="body1Medium"
            textAlign="center"
            text={confirmationMessage}
            style={{ textAlign: 'center' }}
          />
        )
    }
  }

  const getStepActions = () => {
    switch (activeStep) {
      case 0:
        return (
          <>
            <Button text="Cancel" variant="outlined" color="info" onClick={handleClose} />
            <Button
              disabled={selectedAutocompleteOptions.length === 0}
              onClick={() => setActiveStep(1)}
              text="Next"
            />
          </>
        )
      case 1:
        return (
          <>
            <div
              style={{
                display: 'flex',
                flex: 1,
                justifyContent: 'space-between',
              }}
            >
              <Button
                onClick={() => setActiveStep(0)}
                text="Back"
                variant="text"
                startIcon={<ArrowBack />}
              />
              <div>
                <Button
                  style={{ marginRight: 12 }}
                  variant="outlined"
                  color="info"
                  onClick={handleClose}
                  text="Cancel"
                />
                <Button
                  disabled={(!sendToMyself && selectedEmails.length === 0) || hasErrors}
                  onClick={handleConfirm}
                  text="Send"
                />
              </div>
            </div>
          </>
        )
      default:
        return <Button onClick={handleClose} text="Close" />
    }
  }

  return (
    <Dialog
      actions={getStepActions()}
      onClose={handleClose}
      open={open}
      title={title}
      maxWidth={activeStep < 0 ? 'xs' : 'sm'}
    >
      {activeStep >= 0 && (
        <Stepper sx={{ mb: 3 }} nonLinear activeStep={activeStep}>
          {steps.map((label) => (
            <Step key={label}>
              <StepButton disableRipple disabled>
                {label}
              </StepButton>
            </Step>
          ))}
        </Stepper>
      )}
      <Box height={activeStep === 0 ? 500 : 'auto'}>{getStepContent()}</Box>
    </Dialog>
  )
})
