import React, { useEffect, useState } from 'react'
import {
  AutorenewRounded,
  CloseRounded,
  DownloadRounded,
  EmailRounded,
  GroupsOutlined,
  LocalOfferRounded,
  PeopleRounded,
  QuestionMarkRounded,
  TopicRounded,
  WarningRounded,
} from '@mui/icons-material'
import { Button, CircularProgress } from '@mui/material'
import { observer } from 'mobx-react-lite'
import { Link } from 'react-router-dom'
import { DateTime } from 'luxon'
import { Instance } from 'mobx-state-tree'
import XLSX from 'xlsx'

import { useStores } from '@trivie/core'
import { color } from '@trivie/ui-web/theme'
import { ReactComponent as NullState } from './custom-report-table-null.svg'

import { CustomReportModel } from '@trivie/core/src/models/stores/report-store'
import { ConfirmDialog } from '@trivie/ui-web/components/confirm-dialog'
import { DataTable } from '@trivie/ui-web/components/data-table/data-table'
import { EmailDialog } from '@trivie/ui-web/components/email-dialog'
import { ActionTooltip, Toolbar } from '@trivie/ui-web/components/table-toolbar'
import { Text } from '@trivie/ui-web/components/text'

interface CustomReportTableProps {
  filter: 'custom' | 'trivie'
  forceLoading?: boolean
}

export const CustomReportTable = observer((props: CustomReportTableProps) => {
  const { reportStore, taskStore, manageUserStore } = useStores()
  const { gdpr, userProfile } = manageUserStore
  const {
    getCustomReports,
    emailCustomReports,
    deleteCustomReports,
    setShowCreateReport,
    customReports,
  } = reportStore

  const { filter, forceLoading } = props
  const { saveTask, taskManager } = taskStore

  useEffect(() => {
    if (typeof forceLoading === 'boolean') {
      setLoading(Boolean(forceLoading))
    }
  }, [forceLoading])

  const [selectedRows, setSelectedRows] = useState<any>([])
  const [loading, setLoading] = useState<boolean>(true)

  // Dialogs
  const [showConfirmRemove, setShowConfirmRemove] = useState(false)
  const [showEmail, setShowEmail] = useState(false)

  const getActions = () => {
    return [
      {
        label: 'Email',
        icon: EmailRounded,
        onClick: () => setShowEmail(true),
        disabled: false,
      },
      {
        label: 'Download',
        icon: DownloadRounded,
        onClick: () => downloadMultiple(selectedRows),
        disabled: false,
      },
      {
        label: 'Remove',
        icon: CloseRounded,
        onClick: () => setShowConfirmRemove(true),
        disabled: false,
      },
    ]
  }

  const [tableActions, setTableActions] = useState<any>(getActions())

  useEffect(() => {
    setTableActions(getActions())
  }, [selectedRows])

  useEffect(() => {
    reportStore.getBaseViews()
    getCustomReports().then(() => {
      setLoading(false)
      customReports.map((report) => {
        // returning to custom reports tab
        const { task } = report
        const key = `${report.task?.object_type}:${report.task?.object_id}`
        const activeTask = taskManager.get(key)

        if (task?.started) {
          // console.log('poll')
          refresh(report)
        }
        // // if the task has started and there is a taskManager entry
        // // replace the report row's task model with one from the taskManager
        // if (task?.status === 'started' && activeTask) {
        //   console.log('replacing task', activeTask)
        //   console.log(getRoot(activeTask))
        //   // task.replace(activeTask)
        //   report.setTask(activeTask)
        // } else if (task?.status === 'started' && !activeTask) {
        //   // if the task has started and there is no taskManager entry
        //   // add to taskManager and start downloading (refreshing? autodownload?)
        //   taskStore.setTask(key, { ...task })
        //   refresh(report)
        // }
      })
    })
  }, [])

  // Fetch report rows from s3 url
  const getReportData = async (url: string) => {
    const res = await fetch(url)
    return await res.json()
  }

  const exportData = async (rows, report) => {
    // Get columns from report row
    const { columns } = report
    const formatted = rows.map((row) => {
      const exportable = {}
      columns.map((col) => {
        if (col.field !== 'identity') {
          exportable[col.field] = row.columns.find((c) => c.field === col.field).value
        }
      })
      return exportable
    })

    // export to csv
    const worksheet = XLSX.utils.json_to_sheet(formatted, {
      header: columns.filter((c) => c.field !== 'identity').map((c) => c.field),
    })
    const workbook = XLSX.utils.book_new()
    XLSX.utils.book_append_sheet(workbook, worksheet, 'SheetJS')
    XLSX.writeFile(workbook, `${report.view_name.toLowerCase().split(' ').join('_')}.csv`)
  }

  const abortTasks = () => {
    customReports.map((report: Instance<typeof CustomReportModel>) => {
      if (report && report.task?.started) {
        report?.task?.setAbort(true)
      }
    })
  }

  // Download single report row
  const download = async (report: Instance<typeof CustomReportModel>, write?: boolean) => {
    setSelectedRows([])
    if (report.task && report.task.completed && !report.task.stale && write) {
      // fetched recently - find results and download
      const existingReport = (await report.task.getResults(undefined, true)) as any
      const { url } = existingReport.data
      exportData(await getReportData(url), report)
    } else {
      // if not fetched in last 5 minutes, post to tasks endpoint and replace existing row
      const newTask = await saveTask([{ object_id: report.id, object_type: 'CustomDataView' }])
      if (newTask) {
        report.setTask(newTask.data[0])
      }

      // poll for results
      const result = await report.task?.poll(report.task.getResults)
      if (result) {
        // save file
        if (write) {
          exportData(await getReportData(result?.data?.url), report)
        }
      } else {
        console.log('no result')
      }
    }
  }

  const downloadMultiple = async (selected) => {
    const selectedReports = customReports.filter((r) => selected.includes(r.id))
    selectedReports.map((report: Instance<typeof CustomReportModel>) => {
      if (!report.task?.started) {
        download(report, true)
      }
      setSelectedRows([])
    })
  }

  const refresh = (report) => download(report, false)

  const handleSingleEmail = (id) => {
    setSelectedRows([id])
    setShowEmail(true)
  }

  const handleSingleRemove = (id) => {
    setSelectedRows([id])
    setShowConfirmRemove(true)
  }

  const columns = [
    {
      field: 'view_name',
      headerName: 'NAME',
      flex: 1,
      renderCell: (params: any) => <ReportCell params={params} refresh={refresh} />,
    },
    {
      field: '',
      width: 146,
      renderCell: (params: any) => (
        <ActionCell
          handleSingleEmail={handleSingleEmail}
          handleSingleRemove={handleSingleRemove}
          params={params}
          download={download}
        />
      ),
    },
  ]

  const getEmailConfirmationMessage = (emails, sendToMyself) => {
    const multipleReports = selectedRows.length > 1
    const report = customReports.find((r) => r.id === selectedRows[0])

    let count = emails?.length
    if (sendToMyself) {
      count++
    }

    if (multipleReports) {
      return `${selectedRows.length} reports have been sent out to ${count} ${
        count === 1 ? 'person' : 'people'
      }`
    }
    return `${report?.view_name} has been emailed to ${count} ${count === 1 ? 'person' : 'people'}`
  }

  return loading ? (
    <div style={LOADER}>
      <CircularProgress />
    </div>
  ) : customReports?.slice().length === 0 && filter === 'custom' ? (
    <>
      <Text
        style={{ marginBottom: 20 }}
        variant="labelEmphasized"
        text={`You haven't created any custom reports yet.`}
      />
      <Text style={{ marginBottom: 60 }} variant="labelEmphasized">
        &nbsp;Prepare and present your data your way by creating a&nbsp;
        <a
          style={{ color: color.blueLink, cursor: 'pointer' }}
          onClick={() => setShowCreateReport(true)}
        >
          new Custom Report
        </a>
        , or by saving one of your Advanced table layouts as a custom report
      </Text>
      <div style={{ justifyContent: 'center', flexDirection: 'row', display: 'flex' }}>
        <NullState height={400} width={616} />
      </div>
    </>
  ) : (
    <>
      <DataTable
        allowNewReport
        gdpr={gdpr}
        hideActions
        hideAddColumn={true}
        searchPlaceholder="Search Reports"
        rows={customReports}
        hideHeader
        columns={columns}
        toolbar={<Toolbar selected={selectedRows} actions={tableActions} />}
        rowSelectionModel={selectedRows}
        onRowSelectionModelChange={(param) => setSelectedRows(param)}
      />
      {showEmail && (
        <EmailDialog
          initialSelections={selectedRows}
          userEmail={userProfile?.email ?? ''}
          getEmailConfirmationMessage={getEmailConfirmationMessage}
          open={showEmail}
          title="Email Report"
          onConfirm={(emails) => emailCustomReports(selectedRows, emails)}
          onClose={() => {
            setSelectedRows([])
            setShowEmail(false)
          }}
        />
      )}
      {showConfirmRemove && (
        <ConfirmDialog
          open={showConfirmRemove}
          title="Remove Reports"
          message={`Are you sure you want to remove ${selectedRows.length > 1 ? 'these' : 'this'} ${
            selectedRows.length > 1 ? selectedRows.length : ''
          } report${selectedRows.length > 1 ? 's' : ''}? This action cannot be undone!`}
          confirmedMessage={`${selectedRows.length} report${
            selectedRows.length > 1 ? 's have' : ' has'
          } been removed from My Reports.`}
          confirmButton="Confirm"
          onConfirm={() => deleteCustomReports(selectedRows)}
          onClose={() => {
            setLoading(true)
            setSelectedRows([])
            getCustomReports().then(() => setLoading(false))
            setShowConfirmRemove(false)
          }}
          handleCancel={() => setShowConfirmRemove(false)}
        />
      )}
    </>
  )
})

const ReportCell = observer((props: any) => {
  const { params, refresh } = props

  const { reportStore } = useStores()
  const { customReports } = reportStore

  const report = customReports.find((r) => r.id === params.row.id)

  const formatDateTime = (date) =>
    `${DateTime.fromISO(date).toFormat('MM/dd/yyyy')} at ${DateTime.fromISO(date).toLocaleString(
      DateTime.TIME_WITH_SHORT_OFFSET,
    )}`

  const getIcon = (template: string) => {
    switch (template) {
      case 'base_users':
        return <PeopleRounded style={{ marginRight: 20 }} />
      case 'base_questions':
        return <QuestionMarkRounded style={{ marginRight: 20 }} />
      case 'base_quizzes':
        return (
          <svg
            style={{ marginRight: 20 }}
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g clipPath="url(#clip0_1134_59228)">
              <path
                d="M11.0475 20.2647V14.2085H7.42968C6.92645 14.2085 6.58643 13.6234 6.83124 13.1406L11.8363 2.65189C12.1491 1.96435 13.1148 2.21304 13.1148 2.98835V9.11774H16.5694C17.0726 9.11774 17.399 9.68826 17.1814 10.171L12.3396 20.5866C12.0131 21.2887 11.0475 21.0401 11.0475 20.2647Z"
                fill="black"
              />
            </g>
            <defs>
              <clipPath id="clip0_1134_59228">
                <rect width="24" height="24" fill="white" />
              </clipPath>
            </defs>
          </svg>
        )

      case 'base_tags':
        return <LocalOfferRounded style={{ marginRight: 20 }} />
      case 'base_groups':
        return <GroupsOutlined style={{ marginRight: 20 }} />
      case 'base_topics':
        return <TopicRounded style={{ marginRight: 20 }} />
      default:
        return 'users'
    }
  }

  if (!report) {
    return null
  }

  return (
    <div
      style={{
        flexDirection: 'row',
        lineHeight: 'normal',
        height: '100%',
        display: 'flex',
        alignItems: 'center',
      }}
    >
      <Link style={{ paddingTop: 4 }} to={`/insights/reports/custom/${params.row.id}`}>
        {getIcon(report.base_view_name)}
      </Link>
      <div style={{ flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
        <div style={TITLE_CONTAINER}>
          <Link to={`/insights/reports/custom/${params.row.id}`}>
            <Text variant="body1Emphasized" text={params.row.view_name} />
          </Link>
          {report.task ? (
            <>
              <Text variant="body2" style={{ marginLeft: 10, marginRight: 10 }} text="•" />
              <Text
                variant="body2"
                style={{ color: color.shade50, fontStyle: 'italic', marginRight: 4 }}
                text={
                  report.task.loading
                    ? 'Preparing report for download...'
                    : report.task.status === 'completed' || report.task.date_completed
                      ? `Report prepared ${formatDateTime(report.task.date_completed)}.`
                      : 'Report failed to download.'
                }
              />
              {!report.task.loading && (
                <Text
                  onClick={() => refresh(report)}
                  variant="body2"
                  style={{
                    cursor: 'pointer',
                    color: color.blue,
                    textDecoration: 'underline',
                    fontStyle: 'italic',
                  }}
                  text="Refresh"
                />
              )}
            </>
          ) : null}
        </div>
        <Link to={`/insights/reports/custom/${params.row.id}`}>
          <Text variant="body2" style={{ color: color.shade50 }} text={report.view_description} />
        </Link>
      </div>
    </div>
  )
})

const ActionCell = observer((props: any) => {
  const { params, download, handleSingleRemove, handleSingleEmail } = props

  const { reportStore } = useStores()
  const { customReports } = reportStore

  const report = customReports.find((r) => r.id === params.row.id)

  return (
    <div style={ACTIONS}>
      <ActionTooltip title="Email Report" placement="top">
        <Button
          disableRipple
          style={{ minWidth: 36, color: color.shade70, alignSelf: 'center' }}
          onClick={() => handleSingleEmail(params.row.id)}
          startIcon={
            <EmailRounded style={{ position: 'relative', left: 4, height: 14, width: 14 }} />
          }
        />
      </ActionTooltip>
      <div style={DIVIDER} />
      <ActionTooltip
        title={report?.task?.failed ? 'Download Failed' : 'Download Report'}
        placement="top"
      >
        <Button
          disableRipple
          style={{ minWidth: 36, color: color.shade70, alignSelf: 'center' }}
          onClick={report?.task?.loading ? () => {} : () => download(params.row, true)}
          startIcon={
            report?.task?.loading ? (
              <AutorenewRounded
                style={{ position: 'relative', left: 4, height: 14, width: 14 }}
                sx={{
                  animation: 'spin 2s linear infinite',
                  '@keyframes spin': {
                    '0%': {
                      transform: 'rotate(-360deg)',
                    },
                    '100%': {
                      transform: 'rotate(0deg)',
                    },
                  },
                }}
                htmlColor={color.shade70}
              />
            ) : report?.task?.failed ? (
              <WarningRounded
                style={{ position: 'relative', left: 4, height: 14, width: 14 }}
                htmlColor={color.yellow}
              />
            ) : (
              <DownloadRounded
                htmlColor={
                  !report?.task?.stale && report?.task?.stale !== undefined
                    ? color.blue
                    : color.shade70
                }
                style={{ position: 'relative', left: 4, height: 14, width: 14 }}
              />
            )
          }
        />
      </ActionTooltip>
      <div style={DIVIDER} />
      <ActionTooltip title="Remove Report" placement="top">
        <Button
          disableRipple
          style={{ minWidth: 36, color: color.shade70, alignSelf: 'center' }}
          onClick={() => handleSingleRemove(params.row.id)}
          startIcon={
            <CloseRounded style={{ position: 'relative', left: 4, height: 14, width: 14 }} />
          }
        />
      </ActionTooltip>
    </div>
  )
})

const LOADER: React.CSSProperties = {
  display: 'flex',
  flex: 1,
  justifyContent: 'center',
  flexDirection: 'column',
  alignItems: 'center',
  height: window.innerHeight / 1.5,
}
const ACTIONS: React.CSSProperties = {
  height: 32,
  border: '1px solid #EFF0F6',
  borderRadius: 6,
  flexDirection: 'row',
  justifyContent: 'space-evenly',
  display: 'flex',
  top: 18,
  position: 'relative',
}
const DIVIDER: React.CSSProperties = {
  alignSelf: 'center',
  height: 11,
  width: 1,
  backgroundColor: '#D9D9D9',
}
const TITLE_CONTAINER: React.CSSProperties = {
  flexDirection: 'row',
  display: 'flex',
  alignItems: 'center',
}
