import React from 'react'
import { Add, ChevronRight, Close } from '@mui/icons-material'
import { Text } from '../text'
import { color } from '../../theme'
import { IconButton } from '@mui/material'

export interface FullTagProps {
  tag: { key: string; value: string; id: number }
  onDelete?: (tag_id: number) => void
  onAdd?: (tag: { key: string; value: string; id: number }) => void
  style?: React.CSSProperties
  showPointer?: boolean
}

export function FullTag(props: FullTagProps) {
  const { tag, style, onAdd, onDelete, showPointer } = props

  const isDelete = Boolean(onDelete)
  const isAdd = Boolean(onAdd)

  if (!tag) {
    return null
  }

  return (
    <div
      style={{ ...CONTAINER, ...style }}
      key={`tag-${tag.id}`}
      onClick={() => {
        if (onAdd) {
          onAdd(tag)
        }
      }}
    >
      {isAdd ? null : (
        <>
          <Text variant="body1" text={tag.key} style={{ whiteSpace: 'nowrap' }} />
          <ChevronRight fontSize="small" />
        </>
      )}
      <Text
        variant="body1"
        text={tag.value}
        style={{
          whiteSpace: 'nowrap',
          marginRight: isAdd || isDelete ? 8 : 0,
        }}
      />
      {isAdd ? (
        <IconButton size="small">
          <Add htmlColor={color.palette.grey} />
        </IconButton>
      ) : null}
      {isDelete ? (
        <IconButton size="small" onClick={() => onDelete!(tag.id)}>
          <Close htmlColor={color.palette.grey} />
        </IconButton>
      ) : null}
    </div>
  )
}

const CONTAINER: React.CSSProperties = {
  display: 'flex',
  // height: 29,
  width: 'min-content',
  alignItems: 'center',
  backgroundColor: color.palette.selectedTag,
  borderRadius: '6px',
  padding: '6px 8px',
}
