import React, { useState } from 'react'
import { observer } from 'mobx-react-lite'
import { Route, Switch, useHistory } from 'react-router-dom'
import { Add } from '@mui/icons-material'

import { useStores } from '@trivie/core/src/models/root-store'
import { Header } from '@trivie/ui-web/components/header'
import { QuestionDetailsScreen } from './questions-details-screen'
import { QuestionTable } from '../../../tables/question-table'
import { TableHelper } from '../../../tables'

export const QuestionsScreen: React.FunctionComponent = observer(() => {
  const history = useHistory()
  const { manageUserStore, contentCreationStore } = useStores()
  const { resetContentStore } = contentCreationStore
  const { permissions } = manageUserStore
  const [tableHelper] = useState(new TableHelper('list_questions', 'questions', 'questions'))

  const primary = {
    disabled: !permissions?.permissions.some((p) => p.code === 'can_create_question'),
    startIcon: <Add />,
    onClick: () => {
      resetContentStore()
      history.push(`/content/question/${Math.floor(Math.random() * 999999)}tempId/new`)
    },
    text: 'Create Question',
  }

  return (
    <Switch>
      <Route exact path="/content/questions">
        <Header divider title="Questions" primaryAction={primary} />
        <QuestionTable tableHelper={tableHelper} />
      </Route>
      <Route path="/content/questions/:id">
        <QuestionDetailsScreen />
      </Route>
    </Switch>
  )
})
