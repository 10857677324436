import React, { useEffect, useState } from 'react'
import { observer } from 'mobx-react-lite'
import { Box, Stack } from '@mui/material'

import { useStores, ISelectGroupOptions } from '@trivie/core'
import { Dialog } from '@trivie/ui-web/components/dialogs/dialog'
import { Text } from '@trivie/ui-web/components/text'
import { BadgeAvatar } from '@trivie/ui-web/components/badge-avatar'
import { UserGroupSelector } from '@trivie/ui-web/components/user-group-selector'

export const AwardBadgeDialog = observer(
  (props: { onConfirm?: any; open: boolean; requestClose: () => void; badge: any | null }) => {
    const { badgesStore } = useStores()
    const { onConfirm, open, badge } = props
    const { getAvailableUsersForBadge, availableGroups, availableUsers, awardBadge } = badgesStore

    const [assigneeOptions, setAssigneeOptions] = useState<ISelectGroupOptions[]>([])
    const [selectedOptions, setSelectedOptions] = useState<ISelectGroupOptions[]>([])

    useEffect(() => {
      if (open) {
        if (badge?.id) {
          getAvailableUsersForBadge(badge.id).then(() => {
            const opts = [] as any
            availableGroups.map((item) => {
              opts.push({
                type: 'Groups',
                label: item.label,
                number_users: item.number_users,
                id: item.group_id,
              })
            })
            availableUsers.map((item) => {
              opts.push({
                type: 'Users',
                label: item.display_name,
                subLabel: item.email,
                number_users: undefined,
                photo_url: item.photo_url,
                id: item.user_id,
              })
            })
            setAssigneeOptions(opts)
          })
        }
      }
    }, [open])

    const onClickAdd = () => {
      if (badge?.id) {
        const data = {
          badge_id: badge.id,
          group_ids: [
            ...selectedOptions
              .filter((f) => f.type == 'Groups')
              .map((gr: ISelectGroupOptions) => gr.id),
          ],
          user_ids: [
            ...selectedOptions
              .filter((f) => f.type == 'Users')
              .map((usr: ISelectGroupOptions) => usr.id),
          ],
        }
        awardBadge(data).then(() => {
          onConfirm()
        })
      }
    }

    const handleAddUserGroup = (selected: ISelectGroupOptions[]) => {
      setSelectedOptions(selected)
    }

    return (
      <Dialog
        disableConfirm={selectedOptions?.length === 0}
        confirmBtnLabel="Award"
        onConfirm={onClickAdd}
        onCancel={props.requestClose}
        onClose={props.requestClose}
        title="Award Badge"
        open={open}
        maxWidth="md"
      >
        <Stack mb={2} useFlexGap gap={2} direction="row">
          <BadgeAvatar
            size={40}
            name={badge?.badge_title}
            url={badge?.photo_url}
            hoverable={false}
          />
          <Stack>
            <Text variant="body1Emphasized" text={badge?.badge_title ?? ''} />
            <Text variant="body2Medium" color="text.secondary" text={badge?.badge_criteria ?? ''} />
          </Stack>
        </Stack>
        <Box height={500}>
          <UserGroupSelector
            open
            options={assigneeOptions}
            total={3}
            defaultOptions={[]}
            selectedOptions={selectedOptions}
            handleAddUserGroup={handleAddUserGroup}
          />
        </Box>
      </Dialog>
    )
  },
)
