import { observer } from 'mobx-react-lite'
import React, { useEffect, useMemo, useState } from 'react'
import { NavLink, useHistory, useLocation } from 'react-router-dom'
import { BuildRounded, ExpandLessRounded, ExpandMoreRounded } from '@mui/icons-material'
import { IconButton, Collapse } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import createStyles from '@mui/styles/createStyles'
import { CircularProgress } from '@mui/material'

import { useStores } from '@trivie/core'
import { color } from '@trivie/ui-web/theme/color'
import { Text } from '@trivie/ui-web/components/text'
import { Flytrap } from '@trivie/ui-web/components/flytrap'
import { AppInfoDialog } from '@trivie/ui-web/components/app-info-dialog'

const _snakeCase = require('lodash/snakeCase')

import { routes, supportRoutes } from './routes'
import './sidebar.css'

import { Logo } from '../screens/login/logo'

const useStyles = makeStyles(() =>
  createStyles({
    navItem: {
      '&:hover': {
        backgroundColor: color.palette.paperBorder,
      },
      height: 50,
      width: '100%',
      cursor: 'pointer',
    },
  }),
)

const LINK: React.CSSProperties = {
  color: 'rgba(0, 0, 0, 0.87)',
  height: 50,
  textDecoration: 'none',
  alignSelf: 'center',
  display: 'flex',
  width: '100%',
  alignItems: 'center',
  fontWeight: 500,
  letterSpacing: '-0.154',
  marginBottom: 8,
}
const LINK_NESTED: React.CSSProperties = {
  ...LINK,
  paddingLeft: 60,
  marginBottom: 0,
  color: color.shade70,
}
const ACTIVE_LINK_TOP: React.CSSProperties = {
  textDecoration: 'none',
  alignSelf: 'center',
  alignItems: 'center',
  display: 'flex',
  width: '100%',
  color: color.blue,
  fontSize: 14,
  fontWeight: 600,
  height: '100%',
}
const ACTIVE_LINK_NESTED: React.CSSProperties = {
  textDecoration: 'none',
  alignSelf: 'center',
  color: '#000',
  fontWeight: 500,
  fontSize: 14,
  backgroundColor: '#EFF0F6',
}
const LEFT_BORDER: React.CSSProperties = {
  backgroundColor: color.palette.blueLink,
  width: 4,
  height: 32,
  borderTopRightRadius: 100,
  borderBottomRightRadius: 100,
  marginRight: 16,
}

export const Sidebar = observer(() => {
  const [openedDrawer, setOpenedDrawer] = useState<any>(undefined)
  const [showAppInfo, setShowAppInfo] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const location = useLocation()
  const history = useHistory()
  const classes = useStyles()

  const { manageUserStore, uiStore } = useStores()
  const { quantum } = uiStore
  const { environment, permissions, userProfile, getSettings, settings, status } = manageUserStore

  const gdpr_enabled = userProfile?.workspace_settings?.gdpr_enabled

  useEffect(() => {
    setIsLoading(true)
    getSettings().then(() => {
      setIsLoading(false)
    })
  }, [])

  useEffect(() => {
    routes.forEach((route) => {
      if (location.pathname.includes(route.path)) {
        setOpenedDrawer(route.path)
      }
    })

    supportRoutes.forEach((route) => {
      if (location.pathname.includes(route.path)) {
        setOpenedDrawer(route.path)
      }
    })
  }, [location])

  const renderSidebarLogo = useMemo(() => {
    if (isLoading || status === 'pending') {
      return (
        <div
          style={{
            width: '100%',
            height: '100%',
            padding: 12,
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
          }}
        >
          <CircularProgress />
        </div>
      )
    }
    if (settings?.whitelabel_logo_url) {
      return (
        <img
          width="100%"
          height="100%"
          src={settings?.whitelabel_logo_url}
          style={{
            padding: 12,
            objectFit: 'contain',
            rotate: '-13deg',
          }}
        />
      )
    }

    return quantum ? (
      <Logo
        quantum
        style={{
          marginBottom: 20,
          marginLeft: 13,
          marginRight: 3,
          marginTop: 23,
        }}
      />
    ) : (
      <Logo
        style={{
          marginBottom: 20,
          marginLeft: 20,
          marginRight: 3,
          marginTop: 23,
        }}
        width={125.62}
        height={32}
      />
    )
  }, [isLoading])

  return (
    <nav
      style={{
        width: 230,
        minWidth: 230,
        borderRight: '1px solid #EFF0F6',
        backgroundColor: '#FFFFFF',
        overflowY: 'auto',
      }}
    >
      <ul style={{ listStyleType: 'none', padding: 0, margin: 0, paddingBottom: 30 }}>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            height: 70,
          }}
        >
          {renderSidebarLogo}

          {/* {!quantum &&
            (environment.api.config.url.includes('test') ||
              environment.api.config.url.includes('dev')) && (
              <IconButton
                onClick={() => setShowAppInfo(true)}
                style={{ zIndex: 1298, top: 4 }}
                size="large"
              >
                <BuildRounded
                  fontSize="small"
                  htmlColor={color.palette.grey}
                  style={{ transform: 'scaleX(-1)' }}
                />
              </IconButton>
            )} */}
        </div>

        {routes.map((topLevelRoute: any, index: number) => {
          const isOpen = openedDrawer === topLevelRoute.path

          return (
            <ul
              key={`ROUTE_LIST_${index}`}
              style={{
                listStyleType: 'none',
                paddingLeft: 0,
                margin: 0,
                marginBottom: 8,
              }}
            >
              <li className={classes.navItem} key={`ROUTE_${index}`}>
                <div
                  onKeyPress={(e) => {
                    const event = new MouseEvent('click')
                    e.target.dispatchEvent(event)
                  }}
                  onClick={() => {
                    if (isOpen) {
                      setOpenedDrawer(undefined)
                    } else {
                      setOpenedDrawer(topLevelRoute.path)
                    }
                  }}
                  data-qat={`sidenav_${_snakeCase(topLevelRoute.label)}`}
                  style={{
                    ...(isOpen ? ACTIVE_LINK_TOP : LINK),
                    color: isOpen ? color.palette.blueLink : color.shade70,
                  }}
                  role="link"
                  tabIndex={0}
                >
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <div
                      style={{
                        ...LEFT_BORDER,
                        backgroundColor: isOpen ? color.palette.blueLink : 'initial',
                      }}
                    />

                    {topLevelRoute.icon(isOpen)}
                    <Text
                      variant="body1Medium"
                      text={topLevelRoute.label}
                      style={{
                        color: isOpen ? color.palette.blueLink : color.shade70,
                        userSelect: 'none',
                      }}
                    />
                  </div>

                  <div
                    style={{
                      width: '100%',
                      display: 'flex',
                      justifyContent: 'flex-end',
                      paddingRight: 8,
                    }}
                  >
                    {isOpen ? (
                      <ExpandLessRounded
                        style={{
                          color: color.shade20,
                        }}
                      />
                    ) : (
                      <ExpandMoreRounded
                        style={{
                          color: color.shade20,
                        }}
                      />
                    )}
                  </div>
                </div>
              </li>
              <Collapse in={isOpen} timeout="auto" unmountOnExit>
                {topLevelRoute.routes.map((nestedRoute: any, subIndex: number) => {
                  if (
                    !userProfile.workspace_settings.learning_paths_enabled &&
                    nestedRoute?.name === 'paths'
                  ) {
                    return null
                  }
                  const disabled = Boolean(
                    (gdpr_enabled && nestedRoute.name === 'contests') ||
                      (nestedRoute.name === 'activity' &&
                        !permissions?.roles.some((r) =>
                          ['Moderator', 'Administrator', 'Owner'].includes(r.name),
                        )) ||
                      (nestedRoute.name === 'messages' &&
                        !permissions?.permissions.some((p) => p.code === 'can_message_user')),
                  )

                  if (disabled) {
                    return null
                  }

                  return (
                    <li
                      className={classes.navItem}
                      style={{
                        padding: 0,
                        userSelect: 'none',
                      }}
                      key={`ROUTE_NESTED_${subIndex}`}
                    >
                      {nestedRoute.render ? (
                        <nestedRoute.render>
                          <NavLink
                            data-qat={`sidenav_${_snakeCase(nestedRoute.label)}`}
                            activeStyle={ACTIVE_LINK_NESTED}
                            style={{ ...LINK_NESTED }}
                            to={nestedRoute.link ?? nestedRoute.path}
                          >
                            {nestedRoute.icon}
                            {nestedRoute.label}
                          </NavLink>
                        </nestedRoute.render>
                      ) : (
                        <NavLink
                          data-qat={`sidenav_${_snakeCase(nestedRoute.label)}`}
                          activeStyle={ACTIVE_LINK_NESTED}
                          style={{ ...LINK_NESTED }}
                          to={nestedRoute.link ?? nestedRoute.path}
                          isActive={(_match, locationActive) => {
                            if (locationActive?.pathname) {
                              if (nestedRoute.link) {
                                return locationActive?.pathname?.includes(nestedRoute?.prefix)
                              } else {
                                return locationActive?.pathname?.includes(nestedRoute?.path)
                              }
                            }
                            return false
                          }}
                        >
                          {nestedRoute.icon}
                          {nestedRoute.label}
                        </NavLink>
                      )}
                    </li>
                  )
                })}
              </Collapse>
            </ul>
          )
        })}

        {supportRoutes.map((topLevelRoute: any, index: number) => {
          const isOpen = openedDrawer === topLevelRoute.path

          if (
            !permissions?.permissions.some((p) => p.code === 'can_create_quiz') &&
            topLevelRoute.name === 'support'
          ) {
            return null
          }

          const disabled = Boolean(
            topLevelRoute.name === 'settings' &&
              !permissions?.permissions.some((p) => p.code === 'can_edit_workspace_settings'),
          )

          if (disabled) {
            return null
          }

          if (!topLevelRoute.routes) {
            return (
              <ul
                key={`ROUTE_LIST_${index}`}
                style={{
                  listStyleType: 'none',
                  paddingLeft: 0,
                  margin: 0,
                  marginBottom: 8,
                }}
              >
                <li className={classes.navItem} key={`ROUTE_${index}`}>
                  <div
                    onKeyPress={(e) => {
                      const event = new MouseEvent('click')
                      e.target.dispatchEvent(event)
                    }}
                    onClick={() => {
                      setOpenedDrawer(topLevelRoute.path)
                      history.push(topLevelRoute.path)
                    }}
                    style={{
                      ...(isOpen ? ACTIVE_LINK_TOP : LINK),
                      color: isOpen ? color.palette.blueLink : color.shade70,
                    }}
                    role="link"
                    tabIndex={0}
                  >
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                      <div
                        style={{
                          ...LEFT_BORDER,
                          backgroundColor: isOpen ? color.palette.blueLink : 'initial',
                        }}
                      />

                      {topLevelRoute.icon(isOpen)}
                      <Text
                        variant="body1Medium"
                        text={topLevelRoute.label}
                        style={{
                          minWidth: 77,
                          color: isOpen ? color.palette.blueLink : color.shade70,
                          userSelect: 'none',
                        }}
                      />
                    </div>
                  </div>
                </li>
              </ul>
            )
          }
          return (
            <ul
              key={`ROUTE_LIST_${index}`}
              style={{
                listStyleType: 'none',
                paddingLeft: 0,
                margin: 0,
                marginBottom: 8,
              }}
            >
              <li className={classes.navItem} key={`ROUTE_${index}`}>
                <div
                  onKeyPress={(e) => {
                    const event = new MouseEvent('click')
                    e.target.dispatchEvent(event)
                  }}
                  onClick={() => {
                    if (isOpen) {
                      setOpenedDrawer(undefined)
                    } else {
                      setOpenedDrawer(topLevelRoute.path)
                    }
                  }}
                  data-qat={`sidenav_${_snakeCase(topLevelRoute.label)}`}
                  style={{
                    ...(isOpen ? ACTIVE_LINK_TOP : LINK),
                    color: isOpen ? color.palette.blueLink : color.shade70,
                  }}
                  role="link"
                  tabIndex={0}
                >
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <div
                      style={{
                        ...LEFT_BORDER,
                        backgroundColor: isOpen ? color.palette.blueLink : 'initial',
                      }}
                    />

                    {topLevelRoute.icon(isOpen)}
                    <Text
                      variant="body1Medium"
                      text={topLevelRoute.label}
                      style={{
                        color: isOpen ? color.palette.blueLink : color.shade70,
                        userSelect: 'none',
                      }}
                    />
                  </div>

                  <div
                    style={{
                      width: '100%',
                      display: 'flex',
                      justifyContent: 'flex-end',
                      paddingRight: 8,
                    }}
                  >
                    {isOpen ? (
                      <ExpandLessRounded
                        style={{
                          color: color.shade20,
                        }}
                      />
                    ) : (
                      <ExpandMoreRounded
                        style={{
                          color: color.shade20,
                        }}
                      />
                    )}
                  </div>
                </div>
              </li>
              <Collapse in={isOpen} timeout="auto" unmountOnExit>
                {topLevelRoute.routes.map((nestedRoute: any, subIndex: number) => {
                  return (
                    <li
                      className={classes.navItem}
                      style={{
                        padding: 0,
                        userSelect: 'none',
                      }}
                      key={`ROUTE_NESTED_${subIndex}`}
                    >
                      <NavLink
                        data-qat={`sidenav_${_snakeCase(nestedRoute.label)}`}
                        activeStyle={ACTIVE_LINK_NESTED}
                        style={{
                          ...LINK_NESTED,
                          color: color.shade70,
                        }}
                        to={nestedRoute.link ?? nestedRoute.path}
                      >
                        {nestedRoute.icon}
                        {nestedRoute.label}
                      </NavLink>
                    </li>
                  )
                })}
              </Collapse>
            </ul>
          )
        })}
      </ul>
      <Text
        style={{ fontSize: 12, position: 'absolute', bottom: 5, left: 10, color: color.lightGrey }}
        text={`v${process.env.REACT_APP_VERSION}`}
      />
      <Flytrap />
      <AppInfoDialog open={showAppInfo} onClose={() => setShowAppInfo(false)} />
    </nav>
  )
})
