import React, { useEffect, useState } from 'react'
import { DateTime } from 'luxon'
import { observer } from 'mobx-react-lite'
import { TextField, Box } from '@mui/material'
import { ChevronLeftRounded } from '@mui/icons-material'
import { color } from '@trivie/ui-web/theme'

import { Dialog } from '@trivie/ui-web/components/dialogs/dialog'
import { Text } from '@trivie/ui-web/components/text'
import { Button } from '@trivie/ui-web/components/button'
import { UserGroupSelector } from '@trivie/ui-web/components/user-group-selector'
import {
  useStores,
  PostSurveyAssignDetailsRequestType,
  PostSurveyAssignDetailsResponseType,
  GetSurveyAssignDetailsResponseType,
} from '@trivie/core'
import { useHistory } from 'react-router'
import ReviewAssignment from './ReviewAssign'
import { Switch } from '@trivie/ui-web/components/switch/switch'

export interface IFormInputs {
  title: string
  start_date: string
  start_time: string
  end_date: string
  end_time: string
  start_time_zone: string
  end_time_zone: string
  is_anonymous: boolean
  notify: boolean
}

export interface IAssignSurveyDialog {
  requestClose: () => void
  open: boolean
}

export const AssignSurveyDialog = observer((props: IAssignSurveyDialog) => {
  const { open, requestClose } = props
  const [alertMessage, setAlertMessage] = useState('')
  const [showDialog, setShowDialog] = useState(open)
  const history = useHistory()

  const handleClose = (stringMessage: string) => {
    setAlertMessage(stringMessage)
    setShowDialog(false)
  }

  const handleAlert = () => {
    setAlertMessage('')
    requestClose()
    if (window.location.pathname.includes('/create')) {
      history.push('/activities/surveys/live')
    }
  }

  return (
    <>
      <Dialog onClose={handleClose} maxWidth="sm" title="Assign Survey" open={showDialog}>
        <AssignForm requestClose={handleClose} />
      </Dialog>
      {alertMessage && (
        <Dialog
          disableBackdropClick
          open={!!alertMessage}
          title="Assign Survey"
          maxWidth="xs"
          dialogText={alertMessage}
          confirmBtnLabel="Close"
          onConfirm={handleAlert}
          onClose={handleAlert}
        />
      )}
    </>
  )
})

export interface IAssignForm {
  requestClose: (str: string) => void
}
export interface IAssigneeOptions {
  type: string
  name: string
  id: number
  number_users: number | undefined
}

export interface IAssigneeResponse {
  group_id?: number
  label?: string
  number_users?: number
  user_id?: number
  display_name?: string
  photo_url?: string
  email?: string
}

const AssignForm = observer((props: IAssignForm) => {
  const { requestClose } = props
  const { surveyStore } = useStores()
  const {
    surveyErrorMessage,
    setSurveyErrorMessage,
    selectedSurveyId,
    getSurveyAssignDetails,
    postSurveyAssignDetails,
  } = surveyStore
  const [showReview, setShowReview] = useState<boolean>(false)
  const [showDone, setShowDone] = useState<boolean>(false)
  const [assigneeOptions, setAssigneeOptions] = useState<any[]>([])
  const [selectedOptions, setSelectedOptions] = useState<any[]>([])
  const [userCount, setUserCount] = useState(0)

  // const cal = useCalStyles()

  const [fd, setFd] = useState<IFormInputs>({
    title: '',
    start_date: DateTime.local().toFormat('yyyy-MM-dd'),
    start_time: DateTime.now().toLocaleString(DateTime.TIME_24_SIMPLE),
    end_date: DateTime.local().plus({ days: 30 }).toFormat('yyyy-MM-dd'),
    end_time: '23:59',
    start_time_zone: '',
    end_time_zone: '',
    is_anonymous: false,
    notify: false,
  })

  const handleAddUserGroup = (selected: IAssigneeOptions[]) => {
    setSelectedOptions(selected)
    setUserCount(
      selected.reduce(
        (acc, selection) => (selection?.number_users ? acc + selection?.number_users : acc + 1),
        0,
      ),
    )
  }

  const onSubmit = (e: React.FormEvent) => {
    e.preventDefault()
    const data: PostSurveyAssignDetailsRequestType = {
      id: selectedSurveyId!,
      title: fd.title,
      is_anonymous: fd.is_anonymous,
      start_datetime: DateTime.fromFormat(`${fd.start_date} ${fd.start_time}`, 'yyyy-MM-dd hh:mm')
        .toUTC()
        .toISO(),
      end_datetime: DateTime.fromFormat(`${fd.end_date} ${fd.end_time}`, 'yyyy-MM-dd hh:mm')
        .toUTC()
        .toISO(),
      groups: selectedOptions
        .filter((val) => {
          if (val.type == 'Groups') return val.id
        })
        .map((m) => m.id) as any,
      users: selectedOptions
        .filter((val) => {
          if (val.type == 'Users') return val.id
        })
        .map((m) => m.id) as any,
      notify: !!fd.notify,
    }

    postSurveyAssignDetails(selectedSurveyId!, data)
      .then((resp: PostSurveyAssignDetailsResponseType) => {
        setShowReview(false)
        requestClose(
          `The survey “${
            fd.title
          }” has been assigned to ${userCount} users and will be sent out on ${DateTime.fromFormat(
            `${fd.start_date} ${fd.start_time}`,
            'yyyy-MM-dd hh:mm',
          ).toFormat('MM.dd.yyyy hh:mm')}`,
        )
      })
      .catch((error: any) => {
        throw error
      })
  }

  useEffect(() => {
    getSurveyAssignDetails(selectedSurveyId!).then((resp: GetSurveyAssignDetailsResponseType) => {
      if (resp) {
        const users = resp.users.map((val) => {
          return {
            type: 'Users',
            id: val.user_id,
            subLabel: val.email,
            label: val.display_name,
          }
        })
        const groups = resp.groups.map((val) => {
          return {
            type: 'Groups',
            id: val.group_id,
            label: val.label,
            subLabel: '',
            number_users: val.number_users,
          }
        })

        const assigneeItems = [...users, ...groups]

        setAssigneeOptions(assigneeItems.sort((a: any, b: any) => a.label.localeCompare(b.label)))
        const data: IFormInputs = {
          ...fd,
          title: resp?.title,
          start_date: DateTime.fromISO(resp.start_datetime || DateTime.now().toString()).toFormat(
            'yyyy-MM-dd',
          ),
          start_time: DateTime.fromISO(resp.start_datetime || DateTime.now().toString()).toFormat(
            'HH:mm',
          ),
          start_time_zone: '',
          end_date: DateTime.fromISO(
            resp.end_datetime || DateTime.now().plus({ days: 30 }).toString(),
          ).toFormat('yyyy-MM-dd'),
          end_time: DateTime.fromISO(resp.end_datetime || DateTime.now().toString()).toFormat(
            'HH:mm',
          ),
          end_time_zone: '',
          is_anonymous: resp.is_anonymous,
          notify: resp.notify,
        }
        setFd(data)
      }
    })
  }, [])

  const [touched, setTouched] = useState({
    title: false,
    start_date: false,
    start_time: false,
    end_date: false,
    end_time: false,
    start_time_zone: false,
    end_time_zone: false,
  })

  const validate = (data: any) => {
    const errors: any = {}
    if (!data.title) {
      errors.title = {
        type: 'required',
        message: 'Name is required',
      }
    }
    // no need to validate beyond this since we're missing required fields
    if (Object.keys(errors).length) {
      return errors
    }
    return errors
  }

  let startTimeMax = '23:59'

  if (
    DateTime.fromISO(fd.start_date).toFormat('yyyy-MM-dd') ===
    DateTime.fromISO(fd.end_date).toFormat('yyyy-MM-dd')
  ) {
    startTimeMax = DateTime.fromISO(`${fd.end_date}T${fd.end_time}:00`).toFormat('HH:mm')
  }
  let endTimeMin = '00:00'
  if (
    DateTime.fromISO(fd.start_date).toFormat('yyyy-MM-dd') ===
    DateTime.fromISO(fd.end_date).toFormat('yyyy-MM-dd')
  ) {
    endTimeMin = DateTime.fromISO(`${fd.start_date}T${fd.start_time}:00`).toFormat('HH:mm')
  }

  const errors = validate(fd)

  const MinDate = DateTime.local().toFormat('yyyy-MM-dd')
  const MaxDate = DateTime.fromISO(fd.end_date).toFormat('yyyy-MM-dd')

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        width: '100%',
      }}
    >
      <form
        id="hook-form"
        onSubmit={(e) => onSubmit(e)}
        style={{
          justifyContent: 'space-evenly',
          width: '100%',
        }}
      >
        {!showDone && !showReview && (
          <div>
            <div style={{ flexDirection: 'row', width: '100%', marginBottom: 12 }}>
              <TextField
                required
                fullWidth
                error={touched.title && !!errors.title}
                helperText={touched.title && errors.title?.message}
                label="Name"
                variant="outlined"
                placeholder="Name"
                onChange={(e) => {
                  setFd({ ...fd, title: e.target.value })
                  setTouched({ ...touched, title: true })
                }}
                onBlur={() => {
                  setTouched({ ...touched, title: true })
                }}
                value={fd.title}
              />
            </div>
            <div
              style={{
                flexDirection: 'row',
                width: '100%',
                marginBottom: 42,
              }}
            >
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  marginBottom: 12,
                }}
              >
                <Text
                  variant="titleEmphasized"
                  text="Assignees"
                  style={{ ...SUBTEXT, marginRight: 8 }}
                />
              </div>
              <Box>
                <UserGroupSelector
                  total={userCount}
                  listHeight="29vh"
                  options={assigneeOptions}
                  selectedOptions={selectedOptions}
                  handleAddUserGroup={handleAddUserGroup}
                />
              </Box>
            </div>
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                flexDirection: 'row',
                width: '100%',
                marginBottom: 42,
              }}
            >
              <TextField
                error={!!errors.start_date}
                helperText={errors.start_date?.message}
                variant="outlined"
                label="Start Date"
                inputProps={{
                  min: MinDate,
                  max: MaxDate,
                }}
                size="small"
                style={{
                  width: '100%',
                  marginRight: 8,
                }}
                type="date"
                onChange={(e) => {
                  if (!e.target.value) {
                    return
                  }
                  let _value = e.target.value
                  if (_value < MinDate) {
                    _value = MinDate
                  }

                  setFd({
                    ...fd,
                    start_date: _value,
                  })
                  setTouched({ ...touched, start_date: true })
                }}
                onBlur={() => {
                  setTouched({ ...touched, start_date: true })
                }}
                value={fd.start_date}
              />
              <TextField
                error={!!errors.start_time}
                type="time"
                size="small"
                variant="outlined"
                {...{
                  inputProps: {
                    max: startTimeMax,
                  },
                }}
                style={{ width: '100%', marginRight: 8 }}
                onChange={(e) => {
                  setFd({
                    ...fd,
                    start_time: e.target.value,
                  })
                  setTouched({ ...touched, start_time: true })
                }}
                onBlur={() => {
                  setTouched({ ...touched, start_time: true })
                }}
                value={fd.start_time}
              />
              <Text
                style={{ padding: 8, paddingRight: 0 }}
                variant="body1"
                text={Intl.DateTimeFormat().resolvedOptions().timeZone}
              />
            </div>
            <div style={DATE_CONTAINER}>
              <TextField
                // className={cal.root}
                error={touched.end_date && !!errors.end_date}
                helperText={touched.end_date && errors.end_date?.message}
                variant="outlined"
                label="End Date"
                {...{
                  inputProps: {
                    min: DateTime.fromISO(fd.start_date).toFormat('yyyy-MM-dd'),
                  },
                }}
                size="small"
                style={{ width: '100%', marginRight: 8 }}
                type="date"
                onChange={(e) => {
                  setTouched({ ...touched, end_date: true })

                  if (!e.target.value) {
                    return
                  }

                  setFd({
                    ...fd,
                    end_date: e.target.value,
                  })
                }}
                onBlur={() => {
                  setTouched({ ...touched, end_date: true })
                }}
                value={fd.end_date}
              />

              <TextField
                error={!!errors.end_time}
                type="time"
                size="small"
                variant="outlined"
                {...{
                  inputProps: {
                    min: endTimeMin,
                  },
                }}
                style={{ width: '100%', marginRight: 8 }}
                onChange={(e) => {
                  setFd({
                    ...fd,
                    end_time: e.target.value,
                  })

                  setTouched({ ...touched, end_time: true })
                }}
                onBlur={() => {
                  setTouched({ ...touched, end_time: true })
                }}
                value={fd.end_time}
              />
              <Text
                style={{ padding: 8, paddingRight: 0 }}
                variant="body1"
                text={Intl.DateTimeFormat().resolvedOptions().timeZone}
              />
            </div>
            <div style={SWITCH_CONTAINER}>
              <div style={SWITCH_ROW}>
                <div style={SWITCH_TEXT}>
                  <Text style={{ marginBottom: 8 }} variant="body1Medium" text="Notify Learners" />
                  <Text
                    variant="body2Medium"
                    text="Send learners a notification when they receive the survey"
                    style={{ color: color.shade50, marginRight: 60 }}
                  />
                </div>

                <Switch
                  color="success"
                  checked={fd.notify}
                  onChange={(e) => setFd({ ...fd, notify: !fd.notify })}
                  style={{ paddingRight: 30 }}
                />
              </div>

              <div style={SWITCH_ROW}>
                <div style={SWITCH_TEXT}>
                  <Text
                    style={{ marginBottom: 8 }}
                    variant="body1Medium"
                    text="Anonymize Responses"
                  />
                  <Text
                    variant="body2Medium"
                    text="Hide names from server responses for administrators"
                    style={{ color: color.shade50, marginRight: 60 }}
                  />
                </div>
                <Switch
                  color="success"
                  checked={fd.is_anonymous}
                  onChange={() => setFd({ ...fd, is_anonymous: !fd.is_anonymous })}
                />
              </div>
            </div>
          </div>
        )}

        <Box maxHeight={500} overflow="auto">
          {showReview && <ReviewAssignment fd={fd} assignees={selectedOptions} />}
        </Box>
      </form>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          flex: 1,
          alignItems: 'flex-end',
          marginTop: '20px',
        }}
      >
        <div>
          {showReview && (
            <Button
              onClick={() => setShowReview(false)}
              variant="text"
              startIcon={<ChevronLeftRounded />}
              text="Back"
              color="info"
            />
          )}
        </div>
        <div style={{ display: 'flex', gap: 12 }}>
          {!showReview && !showDone && (
            <Button
              text="Cancel"
              variant="outlined"
              color="info"
              onClick={() => requestClose('')}
            />
          )}

          {!showReview && !showDone && (
            <Button
              onClick={() => setShowReview(true)}
              disabled={Object.keys(errors).length > 0}
              text="Review"
            />
          )}
          {showReview && (
            <Button
              type="submit"
              disabled={Object.keys(errors).length > 0}
              form="hook-form"
              text="Assign"
            />
          )}
        </div>
      </div>
      <Dialog
        open={!!surveyErrorMessage}
        title="Upload Failed"
        dialogText={surveyErrorMessage || ''}
        confirmBtnLabel="Okay"
        onClose={() => {
          setSurveyErrorMessage('')
        }}
        onConfirm={() => {
          setSurveyErrorMessage('')
        }}
      />
    </div>
  )
})

const SUBTEXT: React.CSSProperties = {
  fontSize: 14,
  fontStyle: 'normal',
  fontWeight: 'normal',
  color: 'rgba(0, 0, 0, 0.7)',
  fontFamily: 'inter',
  marginRight: 8,
}

const DATE_CONTAINER: React.CSSProperties = {
  display: 'flex',
  justifyContent: 'space-between',
  flexDirection: 'row',
  width: '100%',
}

const SWITCH_CONTAINER: React.CSSProperties = {
  justifyContent: 'space-between',
  flexDirection: 'row',
  width: '100%',
  paddingTop: 60,
}

const SWITCH_ROW: React.CSSProperties = {
  display: 'flex',
  marginBottom: 22,
  alignItems: 'center',
  justifyContent: 'space-between',
}

const SWITCH_TEXT: React.CSSProperties = {
  flex: 1,
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
}
