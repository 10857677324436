import React from 'react'
import { Instance } from 'mobx-state-tree'
import { color } from '@trivie/ui-web/theme'
import { Avatar } from '@trivie/ui-web/components/avatar'
import { Text } from '@trivie/ui-web/components/text'
import { SurveyGroup } from '@trivie/core/src/models/survey/SurveyUserGroup'

export function GroupItem({ group }: { group: Instance<typeof SurveyGroup> }) {
  const { label, number_users } = group
  return (
    <div style={{ display: 'flex', alignItems: 'center' }}>
      <Avatar name={label} />
      <div style={{ marginLeft: 16 }}>
        <Text
          style={{ textAlign: 'left', overflowWrap: 'break-word', maxWidth: '150px' }}
          variant="body1Emphasized"
          text={label}
        />
        <Text
          variant="body2"
          text={`${number_users} Users`}
          style={{ color: color.shade50, overflowWrap: 'break-word', maxWidth: '150px' }}
        />
      </div>
    </div>
  )
}
