import React, { useEffect, useState } from 'react'
import { observer } from 'mobx-react-lite'
import { Close, Link, NewReleases, PersonAdd } from '@mui/icons-material'
import { DialogActions, Divider, IconButton } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { Box, Tab, Tabs } from '@mui/material'

import { Dialog } from '@trivie/ui-web/components/dialogs/dialog'
import { Text } from '@trivie/ui-web/components/text'
import { color } from '@trivie/ui-web/theme'
import { Button } from '@trivie/ui-web/components/button'
import { UserApi } from '@trivie/core/src/services/api-objects'
import { UserGroupSelector } from '@trivie/ui-web/components/user-group-selector'
import { EmailSelect } from '@trivie/ui-web/components/email-select'
import Tooltip, { tooltipClasses, TooltipProps } from '@mui/material/Tooltip'
import styled from '@emotion/styled'

export const a11yProps = (index: number) => {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  }
}

export const useTabsStyles = makeStyles({
  fixed: {
    display: 'flex',
    justifyContent: 'flex-start',
  },
})

export const useTabStyles = makeStyles({
  // Dropdown container
  root: {
    '&.Mui-selected': {
      color: '#000000',
    },
    position: 'relative',
    padding: '6px 12px',
    overflow: 'hidden',
    maxWidth: '264px',
    minWidth: '0px',
    boxSizing: 'border-box',
    minHeight: '48px',
    textAlign: 'center',
    flexShrink: 0,
    whiteSpace: 'normal',
    textTransform: 'none',
    justifyContent: 'center',
  },
})

interface ShareQuizTabProps {
  children?: React.ReactNode
  index: number
  value: number
}

export const ShareQuizTab = observer((props: ShareQuizTabProps) => {
  const { children, value, index, ...other } = props

  return (
    <div role="tabpanel" hidden={value !== index} {...other}>
      {value === index && <>{children}</>}
    </div>
  )
})

const ShareQuizTabs = observer((props: any) => {
  const {
    value,
    setValue,
    selectedOptions,
    selectedEmails,
    setSelectedEmails,
    selectUsers,
    assigneeOptions,
    onClose,
    shareLink,
    share,
    freeTier,
  } = props

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue)
  }

  const tabsClasses = useTabsStyles()
  const tabClasses = useTabStyles()

  const [hasErrors, setHasErrors] = useState(false)

  const onChange = (emails) => {
    setSelectedEmails(emails)
  }

  useEffect(() => {
    let errors = false
    selectedEmails.map((email: string) => {
      if (!email.includes('@')) {
        errors = true
      }
    })
    setHasErrors(errors)
  }, [selectedEmails.length])

  useEffect(() => {
    if (selectedEmails.length > 0) {
      setSelectedEmails(selectedEmails.join(';').split(/[,; ]/))
    }
  }, [selectedEmails.length])

  return (
    <Box sx={{ width: '100%' }}>
      <Box mb={3}>
        <Tabs
          selectionFollowsFocus
          variant="standard"
          indicatorColor="primary"
          classes={{ ...tabsClasses }}
          value={value}
          onChange={handleChange}
          aria-labelledby="a11y-tabs-automatic-label"
        >
          <Tab
            disableRipple
            classes={tabClasses}
            label="Email or Link"
            style={{ marginRight: 8 }}
            {...a11yProps(0)}
          />
          <Tab disableRipple classes={tabClasses} label="Existing Users" {...a11yProps(1)} />
        </Tabs>
        <Divider />
      </Box>
      <ShareQuizTab value={value} index={0}>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'flex-start',
            width: '100%',
            overflow: 'hidden',
          }}
        >
          <Text style={{ marginBottom: 12 }} variant="body2Emphasized" text="Share Via Email" />
          <Text
            style={{ marginBottom: 20, color: color.shade50 }}
            variant="body2"
            text="We'll email these users a link to take this quiz. You can paste in multiple email addresses separated by commas or spaces."
          />
          <EmailSelect style={{ marginBottom: 20 }} selected={selectedEmails} onChange={onChange} />
          <Button
            disabled={hasErrors || (selectedEmails?.length === 0 && selectedOptions?.length === 0)}
            type="submit"
            onClick={() => {
              share(selectedEmails, [])
              onClose()
            }}
            text="Continue"
          />
          <Text
            style={{ marginTop: 16, marginBottom: 8 }}
            variant="body2Emphasized"
            text="Share Via Link"
          />
          <div style={{ display: 'flex', flexDirection: 'row' }}>
            <Text
              style={{ marginBottom: 8, marginRight: 4, color: color.shade50 }}
              variant="body2"
              text={`Quiz link: `}
            />
            <a>
              <Text
                style={{ marginBottom: 8, color: color.blue }}
                variant="body2"
                text={shareLink}
              />
            </a>
          </div>

          {freeTier && (
            <Text
              style={{ marginBottom: 8, color: color.shade50 }}
              variant="body2"
              text="Want more control over who takes your quizzes? Upgrade to Standard and customize your quiz links with expiration dates, usage limits, and more."
            />
          )}
        </div>
      </ShareQuizTab>
      <ShareQuizTab value={value} index={1}>
        <Box
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'flex-start',
            width: '100%',
            overflow: 'hidden',
          }}
          height={500}
        >
          <UserGroupSelector
            open
            options={assigneeOptions}
            total={3}
            defaultOptions={[]}
            selectedOptions={selectedOptions}
            handleAddUserGroup={selectUsers}
          />
        </Box>
      </ShareQuizTab>
    </Box>
  )
})

export const ShareQuizDialog = observer((props: any) => {
  const { open, freeTier, share, onClose, status, toggle, shareLink } = props
  const [value, setValue] = React.useState(0)
  const [selectedOptions, setSelectedOptions] = useState<any>([])
  const [assigneeOptions, setAssigneeOptions] = useState<any>([])

  const selectUsers = (selected: any[]) => {
    setSelectedOptions(selected)
  }

  useEffect(() => {
    const opts = [] as any
    UserApi.getUsers(
      'users',
      false,
      [{ field: 'first_name' }, { field: 'last_name' }, { field: 'photo_url' }],
      'list_users',
    ).then((resp: any) => {
      resp.data.rows.map((user) => {
        const first_name = user.columns.find((column) => column.field === 'first_name')
        const last_name = user.columns.find((column) => column.field === 'last_name')
        const photo_url = user.columns.find((column) => column.field === 'photo_url')
        opts.push({
          type: 'Users',
          name: `${first_name.value} ${last_name.value}`,
          label: `${first_name.value} ${last_name.value}`,
          number_users: undefined,
          photo_url: photo_url.value,
          id: user.id,
        })
      })
      setAssigneeOptions(opts.sort((a, b) => a.name?.localeCompare(b?.name)))
    })
  }, [])

  const selectAll = () => {
    setSelectedOptions(assigneeOptions)
  }

  const [selectedEmails, setSelectedEmails] = useState([])
  const [isCopied, setIsCopied] = useState(false)

  return (
    <Dialog
      maxWidth="md"
      onClose={onClose}
      title="Share Quiz"
      open={open}
      actions={
        <div style={ACTIONS}>
          {status === 'Draft' ? (
            <div style={{ flex: 1 }} />
          ) : (
            <DescriptionTooltip
              open={isCopied}
              placement="top"
              title="Quiz link copied to keyboard!"
            >
              <Button
                color="white"
                startIcon={value === 0 ? <Link /> : <PersonAdd />}
                text={value === 0 ? 'Copy Link' : 'Select All'}
                onClick={
                  value === 1
                    ? selectAll
                    : () => {
                        navigator.clipboard.writeText(shareLink)
                        setIsCopied(true)
                        setTimeout(() => {
                          setIsCopied(false)
                        }, 2000)
                      }
                }
              />
            </DescriptionTooltip>
          )}
          <Button
            disabled={value === 1 && selectedOptions?.length === 0}
            text={status === 'Draft' ? 'Set and Share' : value === 1 ? 'Share' : 'Done'}
            onClick={() => {
              if (status === 'Draft') {
                toggle()
              } else if (value === 1) {
                share(
                  [],
                  selectedOptions.map((user) => user.id),
                )
                onClose()
              } else {
                onClose()
              }
            }}
          />
        </div>
      }
    >
      {status === 'Draft' ? (
        <div
          style={{
            flexDirection: 'row',
            display: 'flex',
            alignItems: 'center',
            padding: 20,
            height: 'calc(100% - 72)',
          }}
        >
          <NewReleases style={{ marginRight: 16, fontSize: 32 }} htmlColor={color.blue} />
          <div style={{ flexDirection: 'column' }}>
            <Text
              style={{ marginBottom: 12 }}
              variant="body1"
              text="Your quiz is currently in Draft."
            />
            <Text
              variant="body1"
              text="Before you can share, you need to set it Live. Would you like to set your quiz to Live now so you can share your quiz?"
            />
          </div>
        </div>
      ) : (
        <ShareQuizTabs
          onClose={onClose}
          share={share}
          shareLink={shareLink}
          selectedOptions={selectedOptions}
          selectUsers={selectUsers}
          assigneeOptions={assigneeOptions}
          setAssigneeOptions={setAssigneeOptions}
          value={value}
          freeTier={freeTier}
          setSelectedEmails={setSelectedEmails}
          selectedEmails={selectedEmails}
          setValue={setValue}
        />
      )}
    </Dialog>
  )
})

const DescriptionTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} arrow classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: '#162142',
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: '#162142',
    padding: 16,
    maxWidth: 250,
    top: '0px !important',
  },
}))

const ACTIONS: React.CSSProperties = {
  justifyContent: 'space-between',
  display: 'flex',
  flex: 1,
  flexDirection: 'row',
}
