import { types } from 'mobx-state-tree'
import { Category } from './Category'

export const RawUserActivity = types.model('RawUserActivity').props({
  id: types.number,
  action: types.string,
  category: Category,
  datetime_occurred: types.string,
  object_id: types.maybeNull(types.number),
  object_type: types.string,
  source_user_photo_url: types.maybeNull(types.string),
  text_map_values: types.frozen(),
})
export const LinkModel = types.model('LinkModel').props({
  name: types.string,
  firstPart: types.string,
  link: types.maybeNull(types.string),
  linkLabel: types.maybeNull(types.string),
  lastPart: types.maybeNull(types.string),
})

export const EnchancedUserActivity = types.model('EnchancedUserActivity').props({
  id: types.number,
  elapsedTime: types.maybeNull(types.string),
  name: types.string,
  photo: types.string,
  datetime_occurred: types.string,
  link: types.maybeNull(LinkModel),
})
