import React from 'react'
import { GetApp } from '@mui/icons-material'
import html2pdf from 'html2pdf.js'

import { Button } from '../button'

interface GenerateReportProps {
  filename: string
  type?: string
  format?: string
  orientation?: string
}

export const GenerateReport = (props: GenerateReportProps) => {
  const { filename = 'report', type = 'jpeg', format = 'a4', orientation = 'landscape' } = props

  const getPdf = async () => {
    const opt = {
      margin: [0, -0.25],
      filename: `${filename}.pdf`,
      image: { type, quality: 0.98 },
      html2canvas: { scale: 2, useCORS: true },
      jsPDF: { unit: 'in', format, orientation },
      pagebreak: { mode: 'avoid-all' },
    }
    const main = document.querySelector('main > div')
    html2pdf().set(opt).from(main, 'element').save()
  }

  return (
    <Button
      color="info"
      variant="text"
      disableElevation
      startIcon={<GetApp />}
      onClick={getPdf}
      data-html2canvas-ignore
      text=" Generate Report"
      data-qat="generate_report"
    />
  )
}
