import React, { useEffect, useState } from 'react'
import { observer } from 'mobx-react-lite'
import { cast, Instance } from 'mobx-state-tree'
import { IconButton, InputAdornment, MenuItem, Select, Stack } from '@mui/material'
import { AddCircleOutlineOutlined, Close, Group } from '@mui/icons-material'
import { useParams } from 'react-router-dom'

import { GroupHelper, useStores } from '@trivie/core'
import { TagApi } from '@trivie/core/src/services/api-objects/TagApi'
import { NewGroup, TagRule } from '@trivie/core/src/models/NewGroup'
import { Dialog } from '../dialogs/dialog'
import { TextField } from '../text-field'
import { color } from '../../theme'
import { Button } from '../button'
import { TagSelector } from '../tag-selector'
import { Text } from '../text'

export interface CreateGroupProps {
  open: boolean
  initialTags?: any
  onClose: () => void
  edit?: boolean
  reload: any
}

export const CreateGroup = observer((props: CreateGroupProps) => {
  const { open, onClose, initialTags, edit, reload } = props
  const { groupStore } = useStores()
  const { getMemberCount, editGroup, getRulesets, createGroup, memberCount, setMemberCount } =
    groupStore

  const [group, setGroup] = useState<any>(GroupHelper.createNewGroup())

  // @ts-ignore
  const { id } = useParams()

  const [availableTags, setAvailableTags] = useState<any>([])
  const [formattedTags, setFormattedTags] = useState({})

  useEffect(() => {
    TagApi.getAllTags('tags', false, [], 'list_tags').then((resp) => {
      if (resp.ok) {
        // @ts-ignore
        const available = resp.data.rows.map((tag) => {
          const key = tag.columns.find((column) => column.field === 'tag_key')
          const value = tag.columns.find((column) => column.field === 'tag_value')
          return { id: tag.id, key: key.value, value: value.value }
        })
        setAvailableTags(
          // @ts-ignore
          available,
        )
        const t = {}
        available.map((tag) => {
          t[tag.key] = { values: [] }
        })
        available.map((tag) => {
          t[tag.key].values.push(tag)
        })

        setFormattedTags(t)
      }
    })
  }, [])

  useEffect(() => {
    ;(async () => {
      if (edit) {
        const editGroup = await getRulesets(id)
        if (editGroup) {
          // Some cases (initial tags found) return value instead of tag_value / key instead of tag_key
          editGroup.rulesets.map((ruleset) => {
            ruleset.tag_rules.map((rule) => {
              // @ts-ignore
              rule.value = rule.tag_value
              // @ts-ignore
              delete rule.tag_value

              // @ts-ignore
              rule.key = rule.tag_key
              // @ts-ignore
              delete rule.tag_key
            })
          })
          const g = {
            group_id: editGroup.group_id,
            label: editGroup.label,
            user_count: editGroup.user_count,
            rulesets: editGroup.rulesets.map((r) => {
              return {
                method: r.method,
                ruleset_id: r.ruleset_id,
                tag_rules: r.tag_rules.map((tr) => {
                  return { key: tr.key, id: tr.tag_id, value: tr.value }
                }),
              }
            }),
          }
          setGroup(NewGroup.create(g))
          getMemberCount(GroupHelper.memberCountPayload(g.rulesets))
        }
      } else if (initialTags) {
        group.rulesets[0].setTagRules(initialTags)
        getMemberCount(GroupHelper.memberCountPayload(group.rulesets))
      }
    })()
  }, [initialTags])

  const handleCreate = () => {
    if (edit) {
      editGroup(GroupHelper.toPayload(group), id).then(() => {
        reload()
      })
    } else {
      createGroup(GroupHelper.toPayload(group)).then(() => {
        reload()
      })
    }
    handleClose()
  }

  const handleClose = () => {
    setMemberCount(0)
    setGroup(GroupHelper.createNewGroup())
    onClose()
  }

  return (
    <Dialog
      disableConfirm={group.hasEmptyRuleset || !group.label}
      confirmBtnLabel={edit ? 'Edit Group' : 'Create Group'}
      onConfirm={handleCreate}
      onCancel={handleClose}
      onClose={handleClose}
      title={edit ? 'Edit Group' : 'Create Group'}
      maxWidth="sm"
      open={open}
    >
      <TextField
        size="small"
        fullWidth
        variant="outlined"
        placeholder="Group Name"
        onChange={(e) => group.setLabel(e.target.value)}
        value={group.label}
        sx={{ mt: 1 }}
        label="Group Name"
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <Group htmlColor={color.palette.grey} />
            </InputAdornment>
          ),
        }}
      />
      <Stack mt={1} direction="row" justifyContent="flex-end">
        <Text variant="body2" color="text.secondary" text={`${group.label.length} / 250`} />
      </Stack>
      <GroupRulesets
        availableTags={availableTags}
        formattedTags={formattedTags}
        initialTags={initialTags}
        group={group}
      />
    </Dialog>
  )
})

interface GroupRulesetsProps {
  group: Instance<typeof NewGroup>
  initialTags?: Array<number>
  availableTags?: any
  formattedTags?: any
}

const GroupRulesets = observer((props: GroupRulesetsProps) => {
  const { groupStore } = useStores()
  const { getMemberCount } = groupStore
  const { group, availableTags, formattedTags } = props
  const { rulesets } = group

  return (
    <div style={CONTAINER}>
      {rulesets.map((ruleset, index: number) => {
        return (
          <>
            <div style={RULESET}>
              <div style={METHOD_SELECT}>
                <Text
                  variant="body1"
                  text={index === 0 ? 'Users with' : 'with'}
                  style={TAGLINE_HEAD}
                />
                <Select
                  variant="standard"
                  disableUnderline
                  value={ruleset.method}
                  onChange={(e) => ruleset.setMethod(e.target.value as 'any' | 'all' | 'none')}
                >
                  <MenuItem value="all">
                    <Text variant="body1Emphasized" style={DROPDOWN_TXT} text="All" />
                  </MenuItem>
                  <MenuItem value="any">
                    <Text variant="body1Emphasized" style={DROPDOWN_TXT} text="Any" />
                  </MenuItem>
                  <MenuItem value="none">
                    <Text variant="body1Emphasized" style={DROPDOWN_TXT} text="None" />
                  </MenuItem>
                </Select>
                <Text
                  variant="body1"
                  text={
                    index !== rulesets.length - 1
                      ? 'of these tags...'
                      : 'of these tags will belong to the group'
                  }
                  style={TAGLINE_TAIL}
                />
              </div>
              {index !== 0 && (
                <IconButton
                  style={{ bottom: 2 }}
                  onClick={() => {
                    ruleset.remove()
                    getMemberCount(GroupHelper.memberCountPayload(group.rulesets))
                  }}
                  size="small"
                >
                  <Close htmlColor={color.shade70} />
                </IconButton>
              )}
            </div>
            <TagSelector
              formattedTags={formattedTags}
              tags={availableTags}
              onChange={(tags: Array<Instance<typeof TagRule>>) => {
                ruleset.setTagRules(cast(tags))
                getMemberCount(GroupHelper.memberCountPayload(group.rulesets))
              }}
              selectedTags={ruleset.tag_rules}
            />
            {index === rulesets.length - 1 && (
              <div style={ADD_RULESET}>
                <Button
                  text="Add Ruleset"
                  variant="text"
                  color="info"
                  onClick={group.addRuleset}
                  size="small"
                  endIcon={<AddCircleOutlineOutlined />}
                />
              </div>
            )}
            {index !== rulesets.length - 1 && (
              <Text component="div" style={AND} text="AND" variant="titleEmphasized" />
            )}
          </>
        )
      })}
    </div>
  )
})

// Ruleset styles
const ADD_RULESET: React.CSSProperties = {
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'flex-end',
  paddingTop: 10,
}
const AND: React.CSSProperties = { marginTop: 16, marginBottom: 16 }
const TAGLINE_TAIL: React.CSSProperties = { color: color.shade70, marginLeft: 4 }
const DROPDOWN_TXT: React.CSSProperties = { color: color.blue }
const METHOD_SELECT: React.CSSProperties = {
  alignItems: 'center',
  flexDirection: 'row',
  display: 'flex',
  marginBottom: 4,
}
const CONTAINER: React.CSSProperties = {
  alignItems: 'center',
  height: 600,
  overflowY: 'scroll',
}
const RULESET: React.CSSProperties = {
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
}
const TAGLINE_HEAD: React.CSSProperties = { color: color.shade70, marginRight: 8 }
