import { observer } from 'mobx-react-lite'
import React, { useEffect, useState } from 'react'
import { Paper } from '@mui/material'
import { Mail } from '@mui/icons-material'
import { Skeleton } from '@mui/lab'

import { useStores } from '@trivie/core'
import { Text } from '@trivie/ui-web/components/text'
import { Button } from '@trivie/ui-web/components/button'
import { ReactComponent as ExcelFile } from '@trivie/ui-mobile/assets/icons/svg/ExcelFile.svg'
import { EmailReport } from '../../../insights/reports/email-report'

const Reports = observer((props: { assignmentID: number }) => {
  const { assignmentID } = props
  const { assignmentStore, reportStore, manageUserStore } = useStores()
  const { getExportableReports, exportableReports } = assignmentStore

  const { emailReport } = reportStore
  const { userProfile } = manageUserStore
  const [showEmailDialog, setShowEmailDialog] = useState(false)
  const [selectedReport, setSelectedReport] = useState() as any

  const sendReport = (email: string, report: any) => {
    emailReport(email, report.report_id, Number(assignmentID))
  }

  useEffect(() => {
    getExportableReports()
  }, [])

  return (
    <>
      {exportableReports ? (
        exportableReports.map((report: any, index: number) => {
          return (
            <Paper
              key={index}
              variant="outlined"
              style={{
                marginBottom: 20,
                paddingTop: 16,
                paddingBottom: 16,
                paddingLeft: 23,
                paddingRight: 23,
              }}
            >
              <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <ExcelFile />
                  <div style={{ display: 'flex', flexDirection: 'column', marginLeft: 19 }}>
                    <Text variant="body1Emphasized" text={report.title} />
                    <Text variant="body2" text={report.description} />
                  </div>
                </div>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <Button
                    variant="outlined"
                    color="info"
                    startIcon={<Mail />}
                    onClick={() => {
                      setSelectedReport(report)
                      setShowEmailDialog(true)
                    }}
                    text="Email"
                  />
                </div>
              </div>
            </Paper>
          )
        })
      ) : (
        <>
          <Skeleton variant="rectangular" height={65} width="100%" style={{ marginBottom: 20 }} />
          <Skeleton variant="rectangular" height={65} width="100%" style={{ marginBottom: 20 }} />
          <Skeleton variant="rectangular" height={65} width="100%" />
        </>
      )}
      <EmailReport
        onClose={() => setShowEmailDialog(false)}
        open={showEmailDialog}
        report={selectedReport}
        userEmail={userProfile?.email}
        onSend={sendReport}
      />
    </>
  )
})

export const AssignmentsReportsTab = (props: any) => {
  const { id, mainTab } = props
  return {
    name: 'Reports',
    path: `/activities/assignments/${mainTab}/${id}/reports`,
    component: () => <Reports assignmentID={id} />,
  }
}
