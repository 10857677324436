/* eslint-disable */

import { Instance, types } from 'mobx-state-tree'
import { SurveyAssigneesGroupModel } from './SurveyAssigneesGroupModel'
import { SurveyAssigneesUserModel } from './SurveyAssigneesUserModel'

/**
 * AssignmentUserRead Model
 */
export const SurveyAssignCategoryModel = types.model('SurveyAssignCategoryModel').props({
  full_url: types.string,
  is_archived: types.boolean,
  label: types.string,
})

export const AssignedSurvey = types.model('AssignedSurvey').props({
  survey_id: types.number,
  title: types.string,
  is_anonymous: types.boolean,
  end_datetime: types.maybeNull(types.string),
  category: types.maybeNull(SurveyAssignCategoryModel),
})

export interface GetAssignedSurveysForUserResponseType
  extends Instance<typeof AssignedSurvey.Type> {}

export interface SurveyAssignCategoryType extends Instance<typeof SurveyAssignCategoryModel.Type> {}
