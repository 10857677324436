import { types } from 'mobx-state-tree'

/**
 * TagBase Model
 */
export const TagBaseModel = types.model('TagBase').props({
  __typename: types.optional(types.literal('TagBase'), 'TagBase'),
  tag_id: types.number,
  key: types.string,
  value: types.string,
  user_count: types.number,
  group_count: types.number,
})
