import React, { useEffect, useState } from 'react'
import { IconButton } from '@mui/material'
import { color } from '@trivie/ui-web/theme'
import { Button } from '@trivie/ui-web/components/button'
import { Text } from '@trivie/ui-web/components/text'
import { ConfirmDialog } from '@trivie/ui-web/components/confirm-dialog'
import { ArrowBack, CheckRounded, ReportProblemRounded } from '@mui/icons-material'
import { observer } from 'mobx-react-lite'
import { useHistory } from 'react-router-dom'
import { useStores } from '@trivie/core'

interface SurveyCreationHeaderProps {
  onBack: () => void
  onPublish: () => void
  publishMessage: string
  titleHeader: React.ReactElement
  disablePublish: boolean
  publishBtnText?: string
  errorCount: number
  showErrorCount: boolean | undefined
}
const SurveyCreationHeader = observer((props: SurveyCreationHeaderProps) => {
  const {
    onBack,
    onPublish,
    publishMessage,
    titleHeader,
    disablePublish,
    publishBtnText = 'Publish',
    errorCount,
    showErrorCount,
  } = props

  const { contentCreationStore } = useStores()

  const [showUnsavedChanges, setShowUnsavedChanges] = useState(false)

  const history = useHistory()

  useEffect(() => {
    const { onbeforeunload } = window
    window.onbeforeunload = () => {
      return 'dont go'
    }

    return () => {
      window.onbeforeunload = onbeforeunload
    }
  }, [])

  const handleSubmit = () => {
    handlePublish()
  }

  const handlePublish = async () => {
    // onPublish overrides the functionality here
    if (onPublish) {
      onPublish()
      return
    }
  }

  const handleBack = () => {
    setShowUnsavedChanges(true)
  }

  return (
    <div style={ROOT}>
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
        }}
      >
        {!!onBack && (
          <IconButton onClick={handleBack} size="large">
            <ArrowBack htmlColor={color.black} />
          </IconButton>
        )}
        {titleHeader}
        {showErrorCount && errorCount > 0 ? (
          <div style={INVALID}>
            <ReportProblemRounded style={{ marginRight: 5, fontSize: 12 }} htmlColor={color.red} />
            <Text
              variant="body2Medium"
              style={{ color: color.red }}
              text={`${errorCount} Error${
                errorCount > 1 ? 's were' : ' was'
              } found. Please correct before saving!`}
            />
          </div>
        ) : (
          showErrorCount && (
            <div style={VALID}>
              <CheckRounded
                style={{ marginRight: 5, fontSize: 12, color: 'rgba(43, 188, 127, 1)' }}
              />
              <Text
                variant="body2Medium"
                style={{ color: 'rgba(43, 188, 127, 1)' }}
                text="Success! You are ready to save!"
              />
            </div>
          )
        )}
      </div>
      <div style={{ display: 'flex', gap: 16 }}>
        <Button color="info" variant="outlined" onClick={onBack} text="Cancel" />
        <Button disabled={disablePublish} onClick={handleSubmit} text={publishBtnText} />
      </div>
      <ConfirmDialog
        danger
        open={showUnsavedChanges}
        title="Unsaved Changes"
        message={
          publishMessage
            ? publishMessage
            : 'Your survey has not been published. Are you sure you want to exit without saving?'
        }
        confirmButton="Exit Without Saving"
        onConfirm={() => {
          setShowUnsavedChanges(false)
          history.goBack()
        }}
        onClose={() => setShowUnsavedChanges(false)}
        handleCancel={() => setShowUnsavedChanges(false)}
        onPublish={publishMessage ? handleSubmit : null}
      />
    </div>
  )
})

export default SurveyCreationHeader

const ROOT: React.CSSProperties = {
  display: 'flex',
  justifyContent: 'space-between',
  paddingTop: 10,
  paddingBottom: 10,
  paddingRight: 20,
  paddingLeft: 20,
  backgroundColor: color.white,
  borderBottom: '1px solid',
  borderBottomColor: color.palette.paperBorder,
  alignItems: 'center',
}
const ERROR_STATE: React.CSSProperties = {
  marginLeft: 18,
  width: 334,
  height: 26,
  borderRadius: 8,
  paddingTop: 5,
  paddingBottom: 5,
  paddingLeft: 6,
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
}
const INVALID: React.CSSProperties = {
  ...ERROR_STATE,
  backgroundColor: 'rgba(255, 62, 19, 0.1)',
}
const VALID: React.CSSProperties = {
  ...ERROR_STATE,
  backgroundColor: 'rgba(31, 224, 143, 0.15)',
}
