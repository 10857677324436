import React, { useEffect, useState } from 'react'
import { useMediaQuery } from '@mui/material'
import { useStores } from '@trivie/core'
import { color } from '@trivie/ui-web/theme/color'
import { Text } from '@trivie/ui-web/components/text'
import { Button } from '@trivie/ui-web/components/button'
import { observer } from 'mobx-react-lite'
import { ReactComponent as Blob } from '../blob.svg'
import preview from '../preview.png'
import SentimentVeryDissatisfiedIcon from '@mui/icons-material/SentimentVeryDissatisfied'
import { ChevronLeftRounded } from '@mui/icons-material'
import { useHistory } from 'react-router'
import { Redirect, useLocation, useParams } from 'react-router-dom'
import { Logo } from '../logo'
import { OtpEntry } from '@trivie/ui-web/components/otp-entry/otp-entry'

const useQuery = () => {
  const { search } = useLocation()

  return React.useMemo(() => new URLSearchParams(search), [search])
}

export const SSOEntryScreen: React.FunctionComponent = observer(() => {
  const { manageUserStore, uiStore } = useStores()
  const { quantum } = uiStore
  const { getAccessCodeDetails, ssoWorkspace } = manageUserStore

  const { accessCode } = useParams() as any
  const query = useQuery()

  const [otp, setOtp] = useState(accessCode ?? '')
  const [notFound, setNotFound] = useState(false)

  const [forceLogin, setForceLogin] = useState(query.get('redirect'))

  const history = useHistory()

  useEffect(() => {
    if (forceLogin === 'true') {
      getCodeDetails(otp)
    } else if (ssoWorkspace) {
      getCodeDetails(ssoWorkspace.access_code)
      manageUserStore.setSSOWorkspace(undefined)
    }
  }, [])

  const getCodeDetails = async (access: string) => {
    const redirect = await getAccessCodeDetails(access)
    if (redirect) {
      window.location.replace(redirect)
    } else {
      setNotFound(true)
      setOtp('')
    }
  }

  const matches = useMediaQuery('(min-width: 940px)')

  const onChange = (value: string) => {
    setNotFound(false)
    setOtp(value)
  }

  return localStorage.getItem('token') ? (
    <Redirect to={{ pathname: '/insights/knowledge' }} />
  ) : (
    <div style={ROOT}>
      <div
        style={{
          ...LEFT_CONTAINER,
          width: matches ? '50%' : '100%',
          paddingLeft: matches ? 120 : 20,
          paddingRight: matches ? 82 : 20,
        }}
      >
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            // maxHeight: 734,
          }}
        >
          <div style={{ position: 'absolute', left: 100, top: 40 }}>
            <Logo quantum={quantum} width={148.9} height={32} />
          </div>
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <Text
              style={{ marginBottom: 32, textAlign: 'center' }}
              variant="headline"
              text="Log In with SSO"
            />
            <Text variant="titleMedium" style={TAGLINE} text="Enter your workspace's access code" />
            <OtpEntry initialValue={accessCode} numInputs={6} onChange={onChange} />
            {notFound && (
              <div style={ERROR_STATE}>
                <SentimentVeryDissatisfiedIcon
                  htmlColor={color.red}
                  style={{ marginRight: 12, fontSize: 20 }}
                />
                <Text
                  style={{
                    fontWeight: 700,
                    fontSize: 12,
                    lineHeight: '14px',
                    color: 'rgba(255, 0, 0, 0.8)',
                  }}
                >
                  We couldn't find your workspace.
                  <span
                    style={{
                      fontSize: 12,
                      fontWeight: 500,
                      lineHeight: '14px',
                      color: 'rgba(255, 0, 0, 0.8)',
                    }}
                  >
                    &nbsp;Please try again, or contact your training administrator for assistance.
                  </span>
                </Text>
              </div>
            )}
            <Button
              disabled={otp.length !== 6}
              onClick={() => getCodeDetails(otp)}
              text="Continue with SSO"
              fullWidth
              sx={{ marginBottom: '60px', marginTop: '32px' }}
              size="large"
            />
            <Text
              text="If you don't know your access code or workspace URL, contact your training administrator for help."
              variant="body1"
              style={NEED_HELP}
            />
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                flexDirection: 'row',
                marginTop: 40,
              }}
            >
              <Button
                variant="text"
                color="info"
                onClick={() => history.replace('/login')}
                startIcon={<ChevronLeftRounded />}
                text="Back"
              />
            </div>
          </div>
        </div>
      </div>
      <div
        style={{
          width: '50%',
          display: matches ? 'flex' : 'none',
          alignItems: 'center',
          justifyContent: 'center',
          flexDirection: 'column',
        }}
      >
        <div
          style={{ display: 'flex', flexDirection: 'column', paddingBottom: 20, paddingTop: 20 }}
        >
          <img
            style={{ alignSelf: 'center' }}
            alt="app preview"
            src={preview}
            width="420"
            height="567.33"
          />
          <Text
            text="Know what your employees know."
            style={{ textAlign: 'center', marginTop: 40, fontSize: 24, color: color.white }}
            variant="headline"
          />
          <Text
            text="View metrics and reports with actionable insights to help your employees retain the information they need."
            style={{
              width: 366,
              textAlign: 'center',
              fontSize: 16,
              marginTop: 20,
              marginRight: 0,
              color: color.white,
              alignSelf: 'center',
            }}
            variant="titleMedium"
          />
        </div>

        <div style={{ position: 'absolute', zIndex: -1, height: '100vh', width: '50%' }}>
          <Blob width="100%" height="100%" />
        </div>
      </div>
    </div>
  )
})

const ROOT: React.CSSProperties = {
  display: 'flex',
  flexDirection: 'row',
  width: '100%',
  overflowY: 'auto',
}
const LEFT_CONTAINER: React.CSSProperties = {
  width: '50%',
  paddingTop: 20,
  paddingBottom: 20,
  alignItems: 'center',
  backgroundColor: color.white,
  display: 'flex',
  justifyContent: 'center',
}
const NEED_HELP: React.CSSProperties = {
  textAlign: 'center',
  alignSelf: 'center',
  width: 330,
  color: color.shade50,
  fontSize: 14,
}
const ERROR_STATE: React.CSSProperties = {
  width: 349,
  height: 64,
  marginBottom: 25,
  borderRadius: 16,
  backgroundColor: 'rgba(249, 234, 234, 1)',
  paddingTop: 10,
  paddingBottom: 10,
  paddingRight: 20,
  display: 'flex',
  flexDirection: 'row',
  paddingLeft: 20,
  alignItems: 'center',
  alignSelf: 'center',
  marginTop: 32,
}
const TAGLINE: React.CSSProperties = {
  opacity: 0.5,
  textAlign: 'center',
  marginBottom: 40,
}
