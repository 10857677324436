import { types } from 'mobx-state-tree'

export const UserUpdateModel = types.model('UserUpdate').props({
  email: types.string,
  first_name: types.string,
  last_name: types.string,
  job_title: types.string,
  sounds_enabled: types.boolean,
  music_enabled: types.boolean,
  hourly_response: types.boolean,
  show_walkthrough: types.boolean,
  push_enabled: types.boolean,
  push_notify_likes: types.boolean,
  push_notify_quizzes: types.string,
  push_notify_tests: types.string,
  push_notifications_allowed: types.string,
  feedback_email_enabled: types.boolean,
  reminder_email_enabled: types.boolean,
  product_email_enabled: types.boolean,
  support_email_enabled: types.boolean,
})
