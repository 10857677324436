import { types } from 'mobx-state-tree'
import { LikeableClassEnum } from './LikeableClassEnum'
import { UserShortSchemaModel } from '../user/UserShortSchemaModel'

/**
 * LikeReadBase
 */
export const LikeReadModel = types.model('LikeRead').props({
  __typename: types.optional(types.literal('LikeRead'), 'LikeRead'),
  created_at: types.string,
  id: types.integer,
  object_id: types.maybeNull(types.integer),
  /** An enumeration. */
  object_type: types.maybeNull(LikeableClassEnum),
  user: types.late(() => UserShortSchemaModel),
})
