import React from 'react'
import { Text } from '@trivie/ui-web/components/text'
import { ReactComponent as SuccessRoundedIcon } from '@trivie/ui-web/assets/icons/SuccessCheckRounded.svg'
import { ReactComponent as ErrorRoundedIcon } from '@trivie/ui-web/assets/icons/ErrorRounded.svg'
import { ReactComponent as WarningRoundedIcon } from '@trivie/ui-web/assets/icons/WarningRounded.svg'
import { ReactComponent as FlashIcon } from '@trivie/ui-web/assets/icons/Flash.svg'
import { observer } from 'mobx-react-lite'
import { Instance } from 'mobx-state-tree'
import { Quiz } from '@trivie/core/src/models/Quiz'
import { useStores } from '@trivie/core'
import { Skeleton } from '@mui/material'

type QuizValidationRowProps = {
  quiz: Instance<typeof Quiz>
}

export const QuizValidationRow = observer((props: QuizValidationRowProps) => {
  const { quiz } = props
  const { contentCreationStore } = useStores()
  const { validating } = contentCreationStore

  if (!quiz) {
    return null
  }

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'row',
        padding: 10,
        margin: 10,
        backgroundColor: '#F6F8FB',
        borderRadius: 12,
        alignItems: 'center',
      }}
    >
      <FlashIcon />
      <div style={{ display: 'flex', flexDirection: 'column', flex: 1, padding: '0px 10px' }}>
        <Text
          variant="body1Emphasized"
          text={quiz?.title ?? 'No Quiz Name'}
          style={quiz?.title ? {} : { fontStyle: 'italic', opacity: 0.5 }}
        />
        <Text
          variant="body2Medium"
          text={`${quiz.questions?.length ?? 0} ${
            quiz.questions?.length === 1 ? 'Question' : 'Questions'
          }`}
        />
      </div>
      {validating && <Skeleton variant="circular" width={40} height={40} />}
      {!validating && quiz.hasDeepErrors && <ErrorRoundedIcon />}
      {!validating && !quiz.hasDeepErrors && quiz.hasDeepWarnings && <WarningRoundedIcon />}
      {!validating && !quiz.hasDeepErrors && !quiz.hasDeepWarnings && <SuccessRoundedIcon />}
    </div>
  )
})
