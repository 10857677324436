import React, { useEffect, useState } from 'react'
import { Close, Delete, Download, ListAltRounded, LocalOfferRounded } from '@mui/icons-material'
import { useStores } from '@trivie/core'
import { DataTable, TableHelper } from '@trivie/ui-web/components/data-table/data-table'
import { observer } from 'mobx-react-lite'
import { CircularProgress } from '@mui/material'
import { Toolbar } from '@trivie/ui-web/components/table-toolbar'
import { AssignmentCreateDialog } from '../screens/activities/assignments/create-assignment/assignment-create'
import { TopicApi } from '@trivie/core/src/services/api-objects/TopicApi'
import { AddToTopicDialog } from '@trivie/ui-web/components/add-quiz-to-topic-dialog'
import { useConfirm } from '@trivie/ui-web/components/dialogs/confirmation-dialog'

interface QuizTableProps {
  tableHelper: TableHelper
  openFilter?: boolean
  exportFilename?: string
}

export const QuizTable = observer((props: QuizTableProps) => {
  const { tableHelper, exportFilename, openFilter } = props

  const confirm = useConfirm()

  const { manageUserStore, manageQuizStore, topicsStore } = useStores()
  const { deleteQuizzes, checkDeleteQuizzes, exportQuizzes } = manageQuizStore
  const { gdpr, permissions } = manageUserStore
  const { removeQuizzesFromTopic } = topicsStore

  const [selectedRows, setSelectedRows] = useState<any>([])
  const [loading, setLoading] = useState<boolean>(tableHelper.custom ? false : true)

  // Dialogs
  const [deleteCheck, setDeleteCheck] = useState<any>()
  const [showAssignWizard, setShowAssignWizard] = useState(false)
  const [showTopicAdd, setShowTopicAdd] = useState(false)
  const [topics, setTopics] = useState([])

  const getActions = () => {
    switch (tableHelper.parent) {
      case 'groups':
        return []
      case 'tags':
        return []
      case 'users':
        return []
      case 'topics':
        return [
          {
            label: 'Assign',
            icon: ListAltRounded,
            onClick: () => setShowAssignWizard(true),
            disabled: !permissions?.permissions.some((p) => p.code === 'can_create_assignment'),
          },
          {
            label: 'Remove From Topic',
            icon: Close,
            onClick: () => confirmRemove(false),
            disabled: !permissions?.permissions.some((p) => p.code === 'can_edit_topic'),
          },
        ]
      default:
        return [
          {
            label: 'Add To Topic',
            icon: LocalOfferRounded,
            onClick: () => {
              TopicApi.getAllTopics('topics', false, [], 'list_topics').then((resp) => {
                if (resp.ok) {
                  const t = resp.data.rows.map((topic) => {
                    const title = topic.columns.find((column) => column.field === 'topic_title')
                    const photo = topic.columns.find((column) => column.field === 'photo_url')
                    return { id: topic.id, topic_title: title.value, photo_url: photo.value }
                  })
                  setTopics(t)
                }
              })
              setShowTopicAdd(true)
            },
            disabled: !permissions?.permissions.some((p) => p.code === 'can_create_assignment'),
          },
          {
            label: 'Assign',
            icon: ListAltRounded,
            onClick: () => setShowAssignWizard(true),
            disabled: !permissions?.permissions.some((p) => p.code === 'can_create_assignment'),
          },
          {
            label: 'Export Content',
            icon: Download,
            disabled: !permissions?.permissions.some((p) => p.code === 'can_create_quiz'),
            onClick: async () => {
              const { file_url } = (await exportQuizzes(selectedRows)) as any
              setSelectedRows([])
              window.open(file_url)
            },
          },
          {
            label: 'Delete',
            icon: Delete,
            onClick: () => {
              checkDeleteQuizzes(selectedRows).then((data: any) => {
                setDeleteCheck(data)
                confirmRemove(true)
              })
            },
            disabled: !permissions?.permissions.some((p) => p.code === 'can_delete_quiz'),
          },
        ]
    }
  }

  const [tableActions, setTableActions] = useState<any>(getActions())

  useEffect(() => {
    if (permissions) {
      setTableActions(getActions())
    }
  }, [permissions?.permissions])

  useEffect(() => {
    setTableActions(getActions())
  }, [selectedRows])

  const [init] = useState(false)
  useEffect(() => {
    if (!init) {
      load()
    }
  }, [])

  const load = () => {
    if (!tableHelper.custom) {
      setLoading(true)
      tableHelper.load().then(() => setLoading(false))
    }
  }

  const save = async (columns: any, filter: any) => {
    setLoading(true)
    tableHelper.save(columns, filter).then(() => setLoading(false))
  }

  const confirmRemove = (deletion?: boolean) => {
    confirm({
      danger: true,
      title: 'Remove Quizzes',
      confirmationMessage: `${selectedRows.length} quiz${
        selectedRows.length > 1 ? 'zes have' : ' has'
      } been removed from ${deletion ? 'the system' : 'this topic'}.`,
      dialogText:
        deleteCheck && typeof deleteCheck.message !== 'undefined'
          ? deleteCheck.message
          : `Are you sure you want to ${deletion ? 'delete' : 'remove'} ${selectedRows.length} quiz${
              selectedRows.length > 1 ? 'zes' : ''
            }${deletion ? '' : ' from this topic'}?`,
    })
      .then(() => {
        if (deletion) {
          deleteQuizzes(selectedRows).then(() => load())
        } else {
          removeQuizzesFromTopic(tableHelper.id, { quizzes: selectedRows }).then(() => load())
        }
        setSelectedRows([])
      })
      .catch(() => {})
  }

  return loading ? (
    <div style={LOADER}>
      <CircularProgress />
    </div>
  ) : (
    <>
      <DataTable
        gdpr={gdpr}
        allowNewReport
        openFilter={openFilter}
        columnVisibilityModel={tableHelper.columnVisibilityModel}
        hideAddColumn={true}
        tableHelper={tableHelper}
        filterModel={tableHelper.filterModel}
        sortModel={tableHelper.sortModel}
        searchPlaceholder="Search Quizzes"
        rows={tableHelper.rows}
        columns={tableHelper.columns}
        onSaveLayout={tableHelper?.saveLayout}
        onSaveFilters={save}
        toolbar={<Toolbar selected={selectedRows} actions={tableActions} />}
        exportFilename={
          exportFilename
            ? exportFilename
            : tableHelper.parent !== 'quizzes'
              ? `${tableHelper.parent}_quizzes`
              : 'quizzes'
        }
        rowSelectionModel={selectedRows}
        onRowSelectionModelChange={(param) => setSelectedRows(param)}
      />
      {showAssignWizard && (
        <AssignmentCreateDialog
          open={showAssignWizard}
          initialQuizzes={selectedRows}
          requestClose={() => setShowAssignWizard(false)}
        />
      )}
      {showTopicAdd && (
        <AddToTopicDialog
          topics={topics}
          open={showTopicAdd}
          quizIDs={selectedRows}
          onClose={() => {
            setSelectedRows([])
            load()
            setShowTopicAdd(false)
          }}
        />
      )}
    </>
  )
})

const LOADER: React.CSSProperties = {
  display: 'flex',
  flex: 1,
  justifyContent: 'center',
  flexDirection: 'column',
  alignItems: 'center',
  height: window.innerHeight / 1.5,
}
