import { Resource } from '../models/Resource'
import { getParentOfType, Instance } from 'mobx-state-tree'
import { Topic } from '../models/Topic'
import { Quiz } from '../models/Quiz'
import { TopicHelper } from './TopicHelper'
import { QuizHelper } from './QuizHelper'
import { ResourceApi } from '../services/api-objects/ResourceApi'

export class ResourceHelper {
  static fetchedResourcesToResources(fetchedResources: any): Array<Instance<typeof Resource>> {
    if (!fetchedResources?.length) {
      console.warn(`objectsToResources was given an array of length 0`)
      return [] as Array<Instance<typeof Resource>>
    }

    const identityBreaker = Math.random() * 999999999

    return fetchedResources.map((fr) => {
      return Resource.create({
        resource_id: fr.resource_id,
        created_at: fr.created_at,
        filename_or_url: fr.filename_or_url,
        is_required: false,
        title: fr.title,
        file_size: fr.file_size,
        mime_type: fr.mime_type,
        identityBreaker,
        extension: fr.extension,
        processing_status: fr.processing_status,
      })
    })
  }

  static getResourceReadToResource(fetchedResources: any): any {
    if (!fetchedResources?.length) {
      console.warn(`objectsToResources was given an array of length 0`)
      return [] as Array<Instance<typeof Resource>>
    }

    const identityBreaker = Math.random() * 999999999

    const fetched = fetchedResources.map((fr: any) => {
      const resource_title = fr.columns.find((column) => column.field === 'resource_title')
      const resource_created_date = fr.columns.find(
        (column) => column.field === 'resource_created_date',
      )
      const resource_filename_url = fr.columns.find(
        (column) => column.field === 'resource_filename_url',
      )
      const resource_file_size = fr.columns.find((column) => column.field === 'resource_file_size')
      const resource_file_type = fr.columns.find((column) => column.field === 'resource_file_type')

      return Resource.create({
        resource_id: fr.id,
        created_at: resource_created_date.value,
        filename_or_url: resource_filename_url.value,
        is_required: false,
        title: resource_title.value,
        file_size: resource_file_size.value,
        mime_type: resource_file_type.value,
        identityBreaker,
      })
    })

    return fetched
  }

  static getQuizResourceToResources(fetchedResources: any): Array<Instance<typeof Resource>> {
    if (!fetchedResources?.length) {
      console.warn(`objectsToResources was given an array of length 0`)
      return [] as Array<Instance<typeof Resource>>
    }

    const identityBreaker = Math.random() * 999999999

    const fetched = fetchedResources.map((fr) => {
      const resource_title = fr.columns.find((column) => column.field === 'resource_title')
      const resource_created_date = fr.columns.find(
        (column) => column.field === 'resource_created_date',
      )
      const resource_filename_url = fr.columns.find(
        (column) => column.field === 'resource_filename_url',
      )
      const resource_file_size = fr.columns.find((column) => column.field === 'resource_file_size')
      const resource_file_type = fr.columns.find((column) => column.field === 'resource_file_type')
      const topic_resource_required = fr.columns.find(
        (column) => column.field === 'topic_resource_required',
      )
      const quiz_resource_required = fr.columns.find(
        (column) => column.field === 'quiz_resource_required',
      )
      const processing_status = fr.columns.find(
        (column) => column.field === 'resource_processing_status',
      )

      return Resource.create({
        resource_id: fr.id,
        created_at: resource_created_date.value,
        filename_or_url: resource_filename_url.value,
        is_required: quiz_resource_required.value ?? false,
        title: resource_title.value,
        file_size: resource_file_size.value,
        mime_type: resource_file_type.value,
        processing_status: processing_status.value,
        identityBreaker,
      })
    })
    return fetched
  }

  static updateResource(resource: Instance<typeof Resource>) {
    const updateResourcePromise = ResourceApi.updateResource(resource)

    let parentTopic = null as any
    let parentQuiz = null as any
    try {
      parentTopic = getParentOfType(resource, Topic)
      return TopicHelper.updateResources(parentTopic, resource)
    } catch {
      // do nothing
    }

    try {
      if (parentTopic) {
        return
      }
      parentQuiz = getParentOfType(resource, Quiz)
      return QuizHelper.updateResources(parentQuiz, resource)
    } catch {
      // do nothing
    }

    return updateResourcePromise
  }

  static async getTopicsQuizsIds(resourceIds: number[]) {
    const topicsResponse = resourceIds.map((resourceId) => {
      return ResourceApi.getTopicsOfResource(resourceId)
    })
    const quizzesResponse = resourceIds.map((resourceId) => {
      return ResourceApi.getQuizzesOfResource(resourceId)
    })
    const [topics, quizzes] = await Promise.all([
      Promise.all(topicsResponse),
      Promise.all(quizzesResponse),
    ])
    const topicIds = [
      ...new Set(
        topics
          .map((topic) => topic.data)
          .flat()
          .map((topic) => topic?.topic_id),
      ),
    ]
    const quizIds = [
      ...new Set(
        quizzes
          .map((quiz) => quiz.data)
          .flat()
          .map((quiz) => quiz?.quiz_id),
      ),
    ]
    return [topicIds, quizIds]
  }
}
