import React, { useState } from 'react'
import { TextField } from '@mui/material'
import { color } from '@trivie/ui-web/theme'
import { Button } from '@trivie/ui-web/components/button'
import { Text } from '@trivie/ui-web/components/text'
import { observer } from 'mobx-react-lite'
import { useHistory } from 'react-router-dom'
import SurveyCreationHeader from './survey-creation-header'
import { AddRounded } from '@mui/icons-material'
import { RadioQuestion } from './radio-question'
import { ShortAnswerQuestion } from './short-answer-question'
import { RangeQuestion } from './range-question'
import { TrueFalseQuestion } from './true-false-question'
import { useStores } from '@trivie/core'
import { AssignSurveyDialog } from '../assign-survey/assign-survey'
import { SurveyHelper } from '@trivie/core/src/helpers'
import { getNodeId, Instance } from 'mobx-state-tree'
import { SurveyQuestion } from '@trivie/core/src/models/Survey'
import { ConfirmDialog } from '@trivie/ui-web/components/confirm-dialog'
import { ConfirmationDialog } from '@trivie/ui-web/components/dialogs/confirmation-dialog'

export const CreateSurveyScreen: React.FunctionComponent = observer(() => {
  const history = useHistory()
  const { surveyStore } = useStores()

  const [survey] = useState(SurveyHelper.createNewSurvey())

  const [showAssignDialog, setShowAssignDialog] = useState(false)
  const [showConfirmation, setShowConfirmation] = useState(false)
  const [showCancelDialog, setShowCancelDialog] = useState(false)

  const handlePublish = async () => {
    survey.setShowErrors(true)
    survey.setShowTitleError(true)
    if (survey.valid) {
      surveyStore.createSurvey(survey).then(() => {
        setShowConfirmation(true)
      })
    }
  }

  const [draggingQuestionID, setDraggingQuestionID] = useState<null | number>(null)

  const handleDrag = (question: Instance<typeof SurveyQuestion>) =>
    setDraggingQuestionID(getNodeId(question))

  const handleDrop = (question: Instance<typeof SurveyQuestion>) => {
    const { questions } = survey
    const dragQuestion = questions?.find((q) => getNodeId(q) === draggingQuestionID)
    const dropQuestion = questions?.find((q) => getNodeId(q) === getNodeId(question))

    if (dragQuestion && dropQuestion) {
      survey.reorder(questions?.indexOf(dragQuestion) ?? 0, questions?.indexOf(dropQuestion) ?? 0)
    }
  }

  return (
    <>
      {showCancelDialog && (
        <ConfirmDialog
          open={showCancelDialog}
          title="Back"
          message="Are you sure you want to go back? Your content will not be published."
          confirmButton="Confirm"
          onConfirm={history.goBack}
          onClose={() => setShowCancelDialog(false)}
          handleCancel={() => setShowCancelDialog(false)}
        />
      )}
      {showAssignDialog && (
        <AssignSurveyDialog
          open={showAssignDialog}
          requestClose={() => setShowAssignDialog(false)}
        />
      )}
      {showConfirmation && (
        <ConfirmationDialog
          open={showConfirmation}
          onCancel={() => {
            history.push(`/activities/surveys/unscheduled`)
          }}
          onClose={() => {
            history.push(`/activities/surveys/unscheduled`)
          }}
          onConfirm={() => {
            setShowAssignDialog(true)
            setShowConfirmation(false)
          }}
          dialogText="Your survey has been saved. Would you like to assign this survey now?"
          confirmBtnLabel="Assign Now"
          cancelBtnLabel="Assign Later"
          title={survey.title}
          confirmationMessage={''}
        />
      )}
      <div style={ROOT}>
        <SurveyCreationHeader
          showErrorCount={survey.showErrors}
          onBack={() => setShowCancelDialog(true)}
          errorCount={survey.errorCount}
          publishMessage="Your survey has not been published. Are you sure you want to exit without saving?"
          onPublish={handlePublish}
          titleHeader={<Text text="Create Survey" variant="labelEmphasized" />}
          publishBtnText="Save"
          disablePublish={survey.questions?.length === 0}
        />
        <div style={CONTAINER}>
          <div style={FORM}>
            <TextField
              variant="standard"
              multiline
              onBlur={() => survey.setShowTitleError(true)}
              inputProps={{
                'aria-label': 'Survey Title',
                style: {
                  ...SURVEY_TITLE_INPUT,
                  color:
                    survey.showTitleError && (!survey.hasTitle || survey.longTitle)
                      ? color.red
                      : color.black,
                },
              }}
              size="medium"
              placeholder="Enter a survey name"
              value={survey.title}
              onChange={(e: any) => survey.setTitle(e.target.value)}
            />
            {survey.questions &&
              survey?.questions.map((question) => {
                switch (question.question_type) {
                  case 'open_text':
                    return (
                      <ShortAnswerQuestion
                        handleDrop={() => handleDrop(question)}
                        handleDrag={() => handleDrag(question)}
                        question={question}
                      />
                    )
                  case 'range_response':
                    return (
                      <RangeQuestion
                        handleDrop={() => handleDrop(question)}
                        handleDrag={() => handleDrag(question)}
                        question={question}
                      />
                    )
                  case 'true_false':
                    return (
                      <TrueFalseQuestion
                        handleDrop={() => handleDrop(question)}
                        handleDrag={() => handleDrag(question)}
                        question={question}
                      />
                    )
                  default:
                    return (
                      <RadioQuestion
                        handleDrop={() => handleDrop(question)}
                        handleDrag={() => handleDrag(question)}
                        question={question}
                      />
                    )
                }
              })}
            <Button
              startIcon={<AddRounded />}
              onClick={survey.addNewQuestion}
              text="Add Question"
              color="black"
              sx={{ marginTop: '32px' }}
            />
          </div>
        </div>
      </div>
    </>
  )
})

const ROOT: React.CSSProperties = {
  position: 'absolute',
  top: 0,
  left: 0,
  bottom: 0,
  right: 0,
  background: '#fff',
  display: 'flex',
  flexDirection: 'column',
}
const CONTAINER: React.CSSProperties = {
  display: 'flex',
  justifyContent: 'center',
  flex: 1,
  overflowY: 'auto',
  paddingBottom: 20,
}
const FORM: React.CSSProperties = {
  marginTop: 25,
  maxWidth: 660,
  display: 'flex',
  flexDirection: 'column',
  flex: 1,
}
const SURVEY_TITLE_INPUT: React.CSSProperties = {
  fontSize: '28px',
  fontWeight: 600,
  lineHeight: '34px',
  fontStyle: 'normal',
}
