import React from 'react'
import { Text } from '../text'
import { Link } from 'react-router-dom'

const LINK_STYLE: React.CSSProperties = {
  marginLeft: 4,
  textDecoration: 'none',
}

export const ActivityItem = (props: any) => {
  const { data, withName, marginFix } = props
  const name = withName ? data.name + ' ' : ''

  return data.fullLink ? (
    <Link style={LINK_STYLE} to={data.fullLink || '#'}>
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          flexWrap: 'nowrap',
          marginLeft: marginFix ? 0 : -4,
        }}
      >
        <Text variant="body2" text={`${name}${data.firstPart}`} />
        <Text
          variant="body2Emphasized"
          text={`${data.linkLabel}`}
          style={{ marginRight: 4, marginLeft: 4 }}
        />
        {data.lastPart ? <Text variant="body2" text={data.lastPart} /> : null}
      </div>
    </Link>
  ) : (
    <div style={{ display: 'flex', alignItems: 'center', flexWrap: 'nowrap' }}>
      <Text variant="body2" text={`${name}${data.firstPart}`} />
      {data.linkLabel && (
        <Link
          to={data.link || '#'}
          style={{ ...LINK_STYLE, position: 'relative', bottom: 1, marginLeft: marginFix ? 0 : 4 }}
        >
          <Text variant="body2Emphasized" text={`${data.linkLabel}`} />
        </Link>
      )}
      {data.lastPart ? (
        <Text variant="body2" text={data.lastPart} style={{ marginLeft: 4 }} />
      ) : null}
    </div>
  )
}
