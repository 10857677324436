import React, { useEffect, useState } from 'react'
import { TextField } from '@mui/material'

import { Dialog } from '../dialogs/dialog'
import { Text } from '../text'

export interface FlytrapDialogProps {
  open: boolean
  onSubmit: (message: string) => void
  onClose: () => void
}

export const FlytrapDialog = (props: FlytrapDialogProps) => {
  const { open, onSubmit, onClose } = props

  const [message, setMessage] = useState<string>('')
  const handleConfirm = () => {
    onSubmit(message!)
  }

  return (
    <Dialog
      confirmBtnLabel="Submit"
      disableConfirm={!message}
      onConfirm={handleConfirm}
      onClose={(e) => {
        e?.stopPropagation()
        onClose()
      }}
      title="Help Us Improve Trivie"
      open={open}
      maxWidth="sm"
    >
      <Text variant="body1" text="How can we improve?" mb={2} />
      <TextField
        multiline
        rows={9}
        placeholder="Please include as much information as possible..."
        variant="outlined"
        fullWidth
        onChange={(e) => setMessage(e.target.value)}
      />
    </Dialog>
  )
}
