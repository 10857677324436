import React, { useState } from 'react'
import { observer } from 'mobx-react-lite'
import { useRouteMatch } from 'react-router-dom'

import { TableHelper } from '../../../../tables'
import { UserTable } from '../../../../tables/user-table'

const Users = observer(() => {
  const match = useRouteMatch<any>('/content/quizzes/:id/users')
  const quizID = Number(match?.params?.id)

  const [tableHelper] = useState(new TableHelper('list_quiz_users', 'users', 'quizzes', quizID))

  return <UserTable tableHelper={tableHelper} />
})

export const QuizzesUsersTab = (props: any) => {
  const { id } = props
  return {
    name: 'Users',
    path: `/content/quizzes/${id}/users`,
    component: () => <Users />,
  }
}
