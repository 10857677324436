import React, { useEffect, useState } from 'react'

import { CircularProgress, IconButton, InputAdornment, TextField } from '@mui/material'
import { observer } from 'mobx-react-lite'
import { ArrowForward, Close, Language } from '@mui/icons-material'

import { useStores } from '@trivie/core'
import { color } from '@trivie/ui-web/theme'

interface WebResourceTabProps {
  quiz?: any
  inputType?: any
  onUpload?: any
  onboarding?: any
  standalone?: any
  topic?: any
  onSelect?: any
  selected?: any
}

export const WebResourceTab = observer((props: WebResourceTabProps) => {
  const { onboarding, onSelect, standalone, onUpload, quiz, inputType } = props
  const { resourceStore } = useStores()
  const { getAvailableResources, addResource } = resourceStore

  const [url, setUrl] = useState('')
  const [showWarning, setShowWarning] = useState(false)
  const [uploading, setUploading] = useState(false)

  const uploadResource = async () => {
    setUploading(true)

    const result = await addResource((currentProgress: any) => {}, { title: url, url })

    if (!result) {
      // handle failed resource upload
      // throw new Error(`Failed to upload url resource`)
      setUploading(false)
    } else {
      const resourcesAfterAdd = await getAvailableResources()
      const addedResource = resourcesAfterAdd?.find(
        (r: any) => r.resource_id === result.resource_id,
      )
      if (standalone) {
        onSelect(addedResource)
        return
      }
      if (addedResource) {
        setUploading(false)
        quiz.setMercuryResource(addedResource)
        resourceStore?.getResourceDetails(addedResource.resource_id)
        quiz.addResource(addedResource)
        if (Boolean(onUpload)) {
          onUpload()
        }
      }
    }
  }

  const handleKeyup = async (e) => {
    if ((e.type === 'keyup' && e.code !== 'Enter') || !e.target.value || showWarning) {
      return
    }
    uploadResource()
  }

  const handleChange = (e) => {
    setUrl(e.target.value)
  }

  useEffect(() => {
    const rgx =
      /^((https?):\/\/)?([w|W]{3}\.)*[a-zA-Z0-9\-\.]{3,}\.[a-zA-Z]{2,}(\.[a-zA-Z]{2,})?(\/[^\s]*)?$/
    if (url && !rgx.test(url)) {
      setShowWarning(true)
    } else {
      setShowWarning(false)
    }
  }, [url])

  return quiz?.mercuryResource && !standalone ? null : (
    <TextField
      autoFocus
      fullWidth
      onKeyUp={handleKeyup}
      disabled={uploading}
      id="url-input"
      placeholder="https://"
      variant={inputType ? inputType : 'outlined'}
      value={url}
      onChange={handleChange}
      helperText={showWarning ? 'This looks like an invalid URL. Please double check.' : ''}
      size="small"
      style={{
        backgroundColor: inputType !== 'standard' || onboarding ? '#fff' : color.transparent,
        borderTopLeftRadius: onboarding ? 10 : 0,
        borderTopRightRadius: onboarding ? 10 : 0,
      }}
      sx={{
        '& .MuiOutlinedInput-notchedOutline': {
          borderColor: showWarning ? `${color.yellow}` : '',
        },
      }}
      InputProps={{
        style: { padding: onboarding ? 4 : 0 },
        startAdornment: (
          <InputAdornment style={{ backgroundColor: '#fff' }} position="start">
            <Language style={{ position: 'relative', left: 4 }} htmlColor={color.shade50} />
          </InputAdornment>
        ),
        endAdornment: url ? (
          <InputAdornment style={{ backgroundColor: '#fff' }} position="end">
            <IconButton onClick={() => setUrl('')} size="small">
              <Close htmlColor={color.shade50} />
            </IconButton>
            <IconButton
              disabled={uploading || !url || showWarning}
              onClick={uploadResource}
              size="small"
            >
              {uploading ? (
                <CircularProgress style={{ width: 24, height: 24 }} />
              ) : (
                <ArrowForward htmlColor={showWarning ? color.shade20 : color.palette.blueLink} />
              )}
            </IconButton>
          </InputAdornment>
        ) : null,
      }}
    />
  )
})
