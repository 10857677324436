import { types, getRoot, flow } from 'mobx-state-tree'
import { TaskApi } from '../../services/api-objects/TaskApi'
import { DateTime } from 'luxon'

const TaskResult = types.model('TaskResult').props({
  rows: types.number,
  url: types.string,
})

const wait = (ms) => new Promise((res) => setTimeout(res, ms))

export const AsyncTaskModel = types
  .model('AsyncTask')
  .props({
    task_id: types.string,
    object_id: types.number,
    rows: types.maybeNull(types.number),
    status: types.enumeration(['started', 'completed', 'failed']),
    object_type: types.enumeration(['CustomDataView']),
    date_completed: types.maybeNull(types.string),
    date_started: types.maybeNull(types.string),
    results: types.maybe(TaskResult),
    loadingTask: types.maybe(types.boolean),
    failure: types.maybe(types.boolean),
    abort: types.maybe(types.boolean),
  })
  .actions((self) => ({
    afterCreate() {},
    replace(task: typeof self) {
      self.task_id = task.task_id
      self.object_id = task.object_id
      self.rows = task.rows
      self.status = task.status
      self.object_type = task.object_type
      self.date_completed = task.date_completed
      self.date_started = task.date_started
      self.results = undefined
      self.loadingTask = false
      self.failure = false
    },
    setDateCompleted(date: string) {
      self.date_completed = date
    },
    setTaskID(id) {
      self.task_id = id
    },
    setAbort(abort) {
      self.abort = abort
    },
    setStatus(status) {
      self.status = status
    },
    setResults(results) {
      self.results = results
    },
    setLoading(loading) {
      self.loadingTask = loading
    },
    setFailed(failed) {
      self.loadingTask = failed
    },
  }))
  .views((self) => ({
    get stale() {
      if (!self.date_completed) {
        return true
      }

      const now = DateTime.now()

      const lastCompleted = DateTime.fromISO(self.date_completed)
      const diff = now.diff(lastCompleted, 'minutes').toObject()
      const { minutes } = diff
      return minutes && minutes > 5
    },
    get completed() {
      return self.status === 'completed'
    },
    get failed() {
      return self.status === 'failed' || self.failure
    },
    get started() {
      return self.status === 'started'
    },
    get loading() {
      return (
        (self.status === 'started' || self.loadingTask) &&
        !self.failure &&
        self.status !== 'failed' &&
        self.status !== 'completed'
      )
    },
  }))
  .actions((self) => ({
    poll: async (fn, attempts = 0) => {
      const MAX_ATTEMPTS = 25
      try {
        return await fn()
      } catch (e) {
        if (attempts > MAX_ATTEMPTS - 2) {
          // Max attempts reached with no success
          // console.log('max attempts reached')
          self.setLoading(false)
          self.setStatus('failed')
          self.setFailed(true)

          // remove self from task map
          const root = getRoot(self) as any
          const { taskStore } = root
          taskStore.removeTask(`${self.object_type}:${self.object_id}`)
          return e
        }
        // wait and retry
        await wait(2 ** attempts * 1000)
        return (self as any).poll(fn, attempts + 1)
      }
    },
    getResults: async (id?: string, direct?: boolean) => {
      // console.log('fetching results')

      self.setLoading(true)
      self.setFailed(false)
      await wait(1000)

      return new Promise((res, rej) => {
        return TaskApi.getResults(id ?? self.task_id)
          .then((resp: any) => {
            if (resp.ok) {
              if (self.abort) {
                throw new Error('abort')
              }

              // success
              // console.log('success', resp)

              // if task aborted reject
              self.setLoading(false)
              self.setStatus('completed')
              self.setFailed(false)

              // console.log(self.stale, self.loading, self.status)

              if (!direct) {
                self.setDateCompleted(DateTime.now().toISO())
              }

              // remove self from task map
              // const root = getRoot(self) as any
              // const { taskStore } = root
              // console.log(root)
              // taskStore?.removeTask(`${self.object_type}:${self.object_id}`)

              return res(resp)
            } else {
              // console.log('failure?')
              self.setLoading(false)
              self.setFailed(true)

              // remove self from task map
              // const root = getRoot(self) as any
              // const { taskStore } = root
              // taskStore.removeTask(`${self.object_type}:${self.object_id}`)

              return rej()
            }
          })
          .catch((e) => {
            // console.log('failure caught', e)
            if (e.kind === 'wait') {
              // reject and retry
              if (e.message?.status === 'failed') {
                // console.log('failed')

                self.setLoading(false)
                self.setFailed(true)
                self.setStatus('failed')

                // const root = getRoot(self) as any
                // const { taskStore } = root
                // console.log(root, self, taskStore)
                // taskStore?.removeTask(`${self.object_type}:${self.object_id}`)
                // return 'failed out'
                return e
              } else {
                return rej()
              }
            } else {
              // console.log(e)

              // failed due to non-420 status code
              self.setLoading(false)
              self.setFailed(true)
              self.setStatus('failed')

              const root = getRoot(self) as any
              const { taskStore } = root
              taskStore?.removeTask(`${self.object_type}:${self.object_id}`)

              throw e
            }
          })
      })
    },
  }))

export const TaskStoreModel = types
  .model('TaskStore')
  .props({
    status: types.optional(types.enumeration(['idle', 'pending', 'done', 'error']), 'idle'),
    taskManager: types.map(AsyncTaskModel),
  })
  .actions((self) => ({
    setStatus(value?: 'idle' | 'pending' | 'done' | 'error') {
      self.status = value || 'idle'
    },
    setTask(key: any, task) {
      self.taskManager.set(key, task)
    },
    updateTasks(tasks: any) {
      tasks.map((task) => self.taskManager.set(`${task.object_type}:${task.object_id}`, task))
    },
    removeTask(taskName: string) {
      self.taskManager.delete(taskName)
    },
  }))
  .views((self) => ({
    get rootStore(): any {
      return getRoot(self) as any
    },
  }))
  .actions((self) => ({
    saveTask: flow(function* (tasks: [{ object_id: number; object_type: 'CustomDataView' }]) {
      self.setStatus('pending')
      try {
        const result: Awaited<ReturnType<typeof TaskApi.saveTasks>> = yield TaskApi.saveTasks(tasks)
        // console.log(result)
        if (result.ok && result.data) {
          self.setStatus('done')
          self.updateTasks(result.data.data)
          return result.data
        } else {
          self.setStatus('error')
        }
      } catch {
        self.setStatus('error')
      }
    }),
  }))

type TaskStoreType = typeof TaskStoreModel.Type
export interface TaskStore extends TaskStoreType {}
type TaskStoreSnapshotType = typeof TaskStoreModel.SnapshotType
export interface TaskStoreSnapshot extends TaskStoreSnapshotType {}
