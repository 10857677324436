import React, { useEffect, useState } from 'react'
import { Route, Switch, useRouteMatch } from 'react-router-dom'
import { Edit } from '@mui/icons-material'
import { DateTime } from 'luxon'
import { observer } from 'mobx-react-lite'
import { Instance } from 'mobx-state-tree'

import { useStores } from '@trivie/core'
import { Header } from '@trivie/ui-web/components/header'
import { NavigationTabs } from '@trivie/ui-web/components/navigation-tabs'
import { Dialog } from '@trivie/ui-web/components/dialogs/dialog'
import { CreateMessageDialog } from './create-message/create-message-dialog'
import { CreateMessageForm } from '@trivie/core/src/models/CreateMessage'
import { AllMessagesTab } from './tabs/messages-all-tab'
import { ScheduledMessagesTab } from './tabs/messages-scheduled-tab'
import { SentMessagesTab } from './tabs/messages-sent-tab'
import { ReviewMessage } from './create-message/review-message'
import { ConfirmationDialog } from '@trivie/ui-web/components/dialogs/confirmation-dialog'

export const MessagesScreen: React.FunctionComponent = observer(() => {
  const { params } = useRouteMatch()
  // @ts-ignore
  const { mainTab } = params

  const { messageStore, manageUserStore } = useStores()
  const {
    deleteMessage,
    selectedMessage,
    selectedMessageID,
    setSelectedMessage,
    setEditing,
    reviewing,
    setReviewing,
    editing,
  } = messageStore
  const { permissions } = manageUserStore

  const [showCreateForm, setShowCreateForm] = useState(false)
  const [showReview, setShowReview] = useState(false)
  const [showConfirm, setShowConfirm] = useState(false)
  const [showConfirmDelete, setShowConfirmDelete] = useState(false)
  const [date, setDate] = useState('')
  const [time, setTime] = useState('')
  const [total, setTotal] = useState(0)
  const [reviewForm, setReviewForm] = useState<Instance<typeof CreateMessageForm>>()

  useEffect(() => {
    if (editing) {
      setShowCreateForm(true)
    } else if (reviewing && selectedMessage) {
      const form = CreateMessageForm.create({
        start_date: DateTime.fromISO(selectedMessage.send_datetime ?? '').toFormat('yyyy-MM-dd'),
        start_time: DateTime.fromISO(selectedMessage.send_datetime ?? '').toLocaleString(
          DateTime.TIME_24_SIMPLE,
        ),
        subject: selectedMessage.subject,
        message: selectedMessage.message_text,
        users: selectedMessage?.users?.map(
          (user) =>
            ({
              type: 'Users',
              name: user.display_name ?? '',
              id: user.user_id,
              number_users: undefined,
            }) ?? [],
        ),
        groups: selectedMessage?.groups?.map(
          (group) =>
            ({
              type: 'Groups',
              name: group.label,
              id: group.group_id,
              number_users: group.number_users,
            }) ?? [],
        ),
        push: selectedMessage.send_push ?? false,
        email: selectedMessage.send_email ?? false,
        schedule: true,
        message_type: 'generic',
      })
      setReviewForm(form)
      setShowReview(true)
    }
  }, [selectedMessageID, editing, reviewing])

  const handleClose = (sendDate: string, sendTime: string, users: number) => {
    setEditing(false)
    setReviewing(false)
    setShowCreateForm(false)
    setShowConfirm(true)
    setSelectedMessage(undefined)
    setDate(sendDate)
    setTime(sendTime)
    setTotal(users)
  }

  const handleDelete = () => {
    setShowCreateForm(false)
    setShowConfirmDelete(true)
  }

  const confirmDelete = () => {
    if (editing && selectedMessageID) {
      deleteMessage(selectedMessageID).then(() => {
        setSelectedMessage(undefined)
        setEditing(false)
        setShowCreateForm(false)
      })
    }
  }

  const handleCancel = () => {
    setEditing(false)
    setReviewing(false)
    setShowConfirmDelete(false)
    setShowCreateForm(false)
  }

  const handleOpenCreate = () => setShowCreateForm(true)

  const primary = {
    disabled: !permissions?.permissions.some((p) => p.code === 'can_message_user'),
    startIcon: <Edit />,
    onClick: handleOpenCreate,
    text: 'Send Message',
  }

  return (
    <Switch>
      <Route exact path="/activities/messages/:mainTab">
        <Header
          title={`${mainTab.charAt(0).toUpperCase() + mainTab.slice(1)} Messages`}
          primaryAction={primary}
        />
        <NavigationTabs tabList={[AllMessagesTab, ScheduledMessagesTab, SentMessagesTab]} />
        {showCreateForm && (
          <CreateMessageDialog
            handleDelete={handleDelete}
            selectedMessageID={selectedMessageID}
            open={showCreateForm}
            requestClose={handleClose}
            cancel={handleCancel}
          />
        )}
        {showReview && reviewing && (
          <Dialog
            onClose={() => {
              setReviewing(false)
              setShowReview(false)
            }}
            maxWidth="md"
            title="Review"
            open={showReview && reviewing}
          >
            {reviewForm && <ReviewMessage form={reviewForm} />}
          </Dialog>
        )}
        {showConfirmDelete && (
          <ConfirmationDialog
            open={showConfirmDelete}
            title="Delete Message"
            dialogText="Are you sure you want to delete this message?"
            confirmationMessage="This message was deleted."
            onConfirm={confirmDelete}
            onClose={() => setShowConfirmDelete(false)}
            onCancel={() => {
              setShowConfirmDelete(false)
              if (editing) {
                setShowCreateForm(true)
              }
            }}
            danger
          />
        )}
        {showConfirm && (
          <Dialog
            maxWidth="xs"
            open={showConfirm}
            title="Send Message"
            dialogText={`Your message will be sent to ${total} user${
              total !== 1 ? 's' : ''
            } on ${date} at ${time}`}
            confirmBtnLabel="Close"
            onConfirm={() => setShowConfirm(false)}
            onClose={() => setShowConfirm(false)}
          />
        )}
      </Route>
    </Switch>
  )
})
