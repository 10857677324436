import { types } from 'mobx-state-tree'

/**
 * QuizForStart Model
 */
export const QuizForStartModel = types.model('QuizForStart').props({
  __typename: types.optional(types.literal('QuizForStart'), 'QuizForStart'),
  id: types.integer,
  name: types.string,
})
