import React from 'react'
import { color } from '../../theme'

export interface CircleProps {
  size: number
  max?: number
  style?: React.CSSProperties
}

export function Circle(props: CircleProps) {
  const { max, style } = props
  let { size } = props

  const calcSize = (val: number) => {
    // take val and scale to 40px
    const final = val / (max ?? 3600)
    return Math.round(final * 40)
  }

  size = calcSize(size)

  return size === 0 ? (
    <div style={{ ...OPEN_CIRCLE, ...style }}></div>
  ) : (
    <div style={{ height: size, width: size, ...CIRCLE_STYLE, ...style }}></div>
  )
}

const OPEN_CIRCLE: React.CSSProperties = {
  borderRadius: '50%',
  borderWidth: 2,
  borderColor: color.palette.lightBlue,
  borderStyle: 'solid',
  height: 8,
  width: 8,
  margin: 5,
}
const CIRCLE_STYLE: React.CSSProperties = {
  borderRadius: 1000,
  backgroundColor: color.palette.lightBlue,
  margin: 5,
}
