import React, { useEffect, useRef, useState, useMemo, forwardRef } from 'react'
import { color } from '@trivie/ui-web/theme'
import { BadgeAvatar } from '@trivie/ui-web/components/badge-avatar'
import { FileInput } from '@trivie/ui-web/components/file-input'
import { Header } from '@trivie/ui-web/components/header'
import { NavItem } from '@trivie/ui-web/components/nav-item'
import { Text } from '@trivie/ui-web/components/text'
import { TextField } from '@trivie/ui-web/components/text-field'
import { TagKeySelector } from '@trivie/ui-web/components/tag-key-selector'
import { useQRCode } from 'next-qrcode'
import { Tooltip } from '@trivie/ui-web/components/tooltip'
import { useHistory, useLocation } from 'react-router-dom'
import {
  Checkbox,
  Divider,
  Grid,
  IconButton,
  Paper,
  createStyles,
  Collapse,
  InputBase,
  Stack,
} from '@mui/material'
import { Dialog } from '@trivie/ui-web/components/dialogs/dialog'
import { Button } from '@trivie/ui-web/components/button'
import { makeStyles } from '@mui/styles'
import {
  Add,
  BarChart,
  Delete,
  EmojiEvents,
  Notifications,
  OfflineBoltRounded,
  Person,
  Security,
  Whatshot,
  VpnKey,
  GetApp,
  Login,
} from '@mui/icons-material'
import { Controller, useForm } from 'react-hook-form'
import { observer } from 'mobx-react-lite'
import { UserLevelModelType, useStores, APIKeyType } from '@trivie/core'
import { ReactComponent as CopyClipboardIcon } from '@trivie/ui-web/assets/icons/CopyClipboard.svg'
import { ImportBadgeDialog } from './import-badge'
import { ReactComponent as ZapierIcon } from '@trivie/ui-web/assets/icons/zapierIcon.svg'
import { AdminsCard } from './admin-card'
import html2canvas from 'html2canvas'
import { Paywall } from '@trivie/ui-web/components/paywall/paywall'
import { Switch } from '@trivie/ui-web/components/switch/switch'
const _snakeCase = require('lodash/snakeCase')

const SWITCH_ROW: React.CSSProperties = {
  display: 'flex',
  marginBottom: 31,
  alignItems: 'center',
}

const SWITCH_TEXT: React.CSSProperties = {
  flex: 1,
  display: 'flex',
  flexDirection: 'column',
}

export interface SettingCardProps {
  id: string
  title: string
  onCancel?: () => void
  onSubmit?: (data: any) => any
  containerStyle?: React.CSSProperties
  initialValues?: any
  onSubmitSuccess?: (data?: any) => any
  isDefaultDirty?: boolean
  isVisible?: boolean
  children: React.ReactNode
}

const useLevelStyles = makeStyles(() =>
  createStyles({
    levelRow: {
      padding: '10px 20px',
      '&:hover': {
        backgroundColor: 'rgba(0, 0, 0, 0.04)',
      },
    },
    helperText: {
      width: '100%',
      marginTop: 8,
      marginLeft: 0,
      marginRight: 0,
    },
    helperTextFocused: {
      width: '100%',
      marginTop: 8,
      marginLeft: 0,
      marginRight: 0,
      textAlign: 'right',
    },
  }),
)

const APIKeyCard = observer(
  forwardRef((props: any, ref: any) => {
    const { id, title, subtitle } = props

    const { manageUserStore } = useStores()
    const { zapierAPIKey, getZapierAPIKey, createZapierAPIKey, deleteZapierAPIKey, permissions } =
      manageUserStore

    const [zapier, setZapier] = useState<APIKeyType>()
    const [isCopied, setIsCopied] = useState(false)

    useEffect(() => {
      getZapierAPIKey()
    }, [])

    useEffect(() => {
      if (zapierAPIKey?.keys) {
        const zapierKey = zapierAPIKey.keys.filter((f) => f.key_name === 'zapier')[0] || undefined
        setZapier(zapierKey)
      } else setZapier(undefined)
    }, [zapierAPIKey])

    const handleCreateKey = () => {
      createZapierAPIKey('zapier').then(() => {
        getZapierAPIKey().then((res) => {
          if (res) {
            const { keys } = res
            const zapierKey = keys?.filter((f) => f.key_name === 'zapier')[0] || undefined
            setZapier(zapierKey)
          }
        })
      })
    }

    const renderAPIKeyContent = () => {
      if (zapier) {
        return (
          <>
            <div
              style={{
                display: 'flex',
                flexWrap: 'wrap',
                alignItems: 'center',
                borderStyle: 'solid',
                borderWidth: 1,
                borderColor: color.palette.paperBorder,
                borderRadius: 6,
                backgroundColor: color.palette.white,
                paddingLeft: 13,
                paddingRight: 13,
                flex: 1,
                marginRight: 20,
              }}
            >
              <Tooltip
                onDismiss={() => setIsCopied(false)}
                open={isCopied}
                placement="top"
                title="API Code copied to keyboard!"
                titleStyle={{ fontSize: 14 }}
              >
                <InputBase
                  disabled={
                    !permissions?.permissions.some((p) => p.code === 'can_edit_workspace_settings')
                  }
                  value={zapier?.key}
                  placeholder="API KEY"
                  style={{ marginLeft: 12, flex: 1, width: 600 }}
                  readOnly
                />
              </Tooltip>
              <CopyClipboardIcon
                onClick={() => {
                  navigator.clipboard.writeText(zapier?.key || '')
                  setIsCopied(true)
                  setTimeout(() => {
                    setIsCopied(false)
                  }, 3000)
                }}
              />
            </div>
            <Button
              disabled={
                !permissions?.permissions.some((p) => p.code === 'can_edit_workspace_settings')
              }
              color="error"
              onClick={() => deleteZapierAPIKey(zapier?.key || '')}
              text="Revoke"
            />
          </>
        )
      } else {
        return (
          <Button
            disabled={
              !permissions?.permissions.some((p) => p.code === 'can_edit_workspace_settings')
            }
            onClick={handleCreateKey}
            text="Request API Key"
          />
        )
      }
    }

    return (
      <Paper id={id} variant="outlined" elevation={0} style={{ marginBottom: 24 }} ref={ref}>
        <div style={{ paddingBottom: 5, paddingLeft: 20 }}>
          <div style={{ display: 'flex', paddingTop: 25, paddingBottom: 5, alignItems: 'center' }}>
            <ZapierIcon />
            <Text variant="labelEmphasized" text={title} />
          </div>
          <Text
            variant="body2Medium"
            text={subtitle}
            style={{ color: color.shade50, paddingLeft: 5 }}
          />
        </div>

        <Divider />

        <div style={{ display: 'flex', marginBottom: 20, alignItems: 'flex-end' }}>
          <div
            style={{
              paddingLeft: 20,
              paddingTop: 40,
              flex: 1,
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            <Text style={{ marginBottom: 8 }} variant="body1Medium" text="Your Key" />
            <Text
              variant="body2Medium"
              text="Request an API key to start integrating."
              style={{ color: color.shade50 }}
            />
          </div>
          <div
            style={{
              display: 'flex',
              justifyContent: 'flex-end',
              paddingLeft: 20,
              paddingRight: 20,
              paddingTop: 16,
            }}
          >
            {renderAPIKeyContent()}
          </div>
        </div>
      </Paper>
    )
  }),
)

const SettingCard = observer(
  forwardRef((props: SettingCardProps, ref: any) => {
    const {
      id,
      children,
      onCancel,
      title,
      containerStyle,
      initialValues,
      onSubmit,
      onSubmitSuccess,
      isDefaultDirty,
      isVisible = true,
    } = props
    const { manageUserStore } = useStores()
    const { saveSettings, levels, addNewLevel, maxLevelReached, removeLevel, permissions } =
      manageUserStore

    if (!isVisible) {
      return null
    }

    const { control, handleSubmit, setValue, formState, reset, watch } = useForm()
    const { errors } = formState

    const classes = useLevelStyles() as any
    const [showBadgeUpload, setBadgeUpload] = useState(false)
    const [iconLevel, setIconLevel] = useState<UserLevelModelType | null>(null)
    const [focusedLevel, setFocusedLevel] = useState<number | null>(null)

    const [showSavedDialog, setShowSavedDialog] = useState(false)
    let isDirty = formState.isDirty

    if (isDefaultDirty === false || isDefaultDirty === true) {
      isDirty = isDefaultDirty
    }

    useEffect(() => {
      Object.entries(initialValues ?? {}).forEach((entry) => {
        setValue(entry[0], entry[1])
      })
    }, [initialValues])

    const handleCancel = () => {
      reset(initialValues)
      if (onCancel) {
        onCancel()
      }
    }

    const onFormSubmit = (data: any) => {
      if (onSubmit) {
        onSubmit(data)
      } else {
        saveSettings(data).then((updatedSettings) => {
          reset(updatedSettings)
        })
      }

      if (onSubmitSuccess) {
        onSubmitSuccess(data)
      }
      setShowSavedDialog(true)
    }

    const data = watch()

    return (
      <Paper id={id} variant="outlined" elevation={0} style={{ marginBottom: 24 }} ref={ref}>
        <div style={{ paddingLeft: 20, paddingTop: 25, paddingBottom: 25 }}>
          <Text variant="labelEmphasized" text={title} />
        </div>
        <Divider />
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            marginTop: 36,
            paddingLeft: 20,
            paddingRight: 20,
            ...containerStyle,
          }}
        >
          {/* @ts-ignore */}
          {children(control, data, errors)}
        </div>

        <Divider />
        <div
          style={{
            display: 'flex',
            justifyContent: 'flex-end',
            paddingLeft: 20,
            paddingRight: 20,
            paddingTop: 16,
            paddingBottom: 16,
            gap: 12,
          }}
        >
          <Button
            disabled={!isDirty}
            variant="outlined"
            onClick={handleCancel}
            color="info"
            text="Cancel"
            data-qat={`cancel_${_snakeCase(title)}`}
          />
          <Button
            disabled={!isDirty}
            onClick={handleSubmit(onFormSubmit)}
            text="Save Changes"
            data-qat={`save_${_snakeCase(title)}`}
          />
        </div>

        <Dialog
          onClose={() => setShowSavedDialog(false)}
          onConfirm={() => setShowSavedDialog(false)}
          confirmBtnLabel="Close"
          title={title}
          maxWidth="xs"
          open={showSavedDialog}
          dialogText="Settings have been saved."
        />
      </Paper>
    )
  }),
)

const SettingsList = (props) => {
  const { listData } = props

  return (
    <Paper
      variant="outlined"
      elevation={0}
      style={{
        width: 230,
        marginRight: 23,
        height: 'min-content',
        position: 'sticky',
        top: 20,
      }}
    >
      <ul style={{ listStyleType: 'none', paddingLeft: 0, marginBottom: 8 }}>
        {listData.map((e) => {
          if (e.isVisible)
            return (
              <NavItem
                icon={e.icon}
                label={e.label}
                hash={`#${e.label.toLocaleLowerCase('en-US').replaceAll(' ', '-')}`}
                onClick={e.onClick}
              />
            )
        })}
      </ul>
    </Paper>
  )
}

export const Settings = observer(() => {
  const { manageUserStore } = useStores()
  const {
    getSettings,
    getUserLevels,
    updateUserLevels,
    settings,
    submitCompanyLogo,
    resetOriginalLevels,
    levels,
    permissions,
    ssoWorkspace,
    ssoRedirect,
    getAccessCodeDetails,
    saveSettings,
    addNewLevel,
    maxLevelReached,
    removeLevel,
    freeTier,
    enterpriseTier,
    user,
    loadUser,
  } = manageUserStore

  const { formState } = useForm()
  const { errors } = formState

  const classes = useLevelStyles() as any
  const [showBadgeUpload, setBadgeUpload] = useState(false)
  const [iconLevel, setIconLevel] = useState<UserLevelModelType | null>(null)
  const [focusedLevel, setFocusedLevel] = useState<number | null>(null)

  const scrollViaClickRef = useRef(false)
  const generalRef = useRef(null)
  const userNotificationRef = useRef(null)
  const loginRef = useRef(null)
  const userProfilesRef = useRef(null)
  const socialRef = useRef(null)
  const playRef = useRef(null)
  const leaderboardRef = useRef(null)
  const pointsRef = useRef(null)
  const levelsRef = useRef(null)
  const adminRef = useRef(null)
  const webhookRef = useRef(null)
  const apikeyRef = useRef(null)
  const [showFile, setShowFile] = useState(false)
  const [toggleLogo, setToggleLogo] = useState(false)
  const [isUserLevelDirty, setUserLevelDirty] = useState(false)
  const location = useLocation()
  const history = useHistory()
  const [qrCodeUrl, setQrCodeUrl] = useState('')
  const [isCopied, setIsCopied] = useState(false)

  const handleCompanyLogo = (data: any) => {
    saveSettings(data).then(() => {
      if (data.company_logo) {
        submitCompanyLogo(data.company_logo)
      }
    })

    return {
      company_name: data.company_name,
      display_cobranding: data.display_cobranding,
    }
  }

  useEffect(() => {
    getSettings()
    getUserLevels()
    if (location?.hash) {
      scrollViaClickRef.current = true
    }
    setTimeout(() => {
      if (location?.hash) {
        handleTabClick(location.hash)
      }
    }, 1000)
  }, [])

  useEffect(() => {
    if (location && !location?.hash) {
      history.replace(`${location.pathname}#general`)
    }
  }, [location])

  const main = document.getElementsByClassName('rootContainer')[0]
  const scrollCallback = (entries: any) => {
    const [entry] = entries
    if (entry.isIntersecting && !scrollViaClickRef.current) {
      history.replace(`${location.pathname}${entry?.target?.id}`)
    }
  }

  useEffect(() => {
    const intersectionObserver = new IntersectionObserver(scrollCallback, {
      root: main,
      threshold: 0.5,
    })

    if (generalRef?.current) {
      intersectionObserver.observe(generalRef.current as any)
    }
    if (userNotificationRef?.current) {
      intersectionObserver.observe(userNotificationRef.current as any)
    }
    if (loginRef?.current) {
      intersectionObserver.observe(loginRef.current as any)
    }
    if (userProfilesRef?.current) {
      intersectionObserver.observe(userProfilesRef.current as any)
    }
    if (socialRef?.current) {
      intersectionObserver.observe(socialRef.current as any)
    }
    if (playRef?.current) {
      intersectionObserver.observe(playRef.current as any)
    }
    if (leaderboardRef?.current) {
      intersectionObserver.observe(leaderboardRef.current as any)
    }
    if (pointsRef?.current) {
      intersectionObserver.observe(pointsRef.current as any)
    }
    if (levelsRef?.current) {
      intersectionObserver.observe(levelsRef.current as any)
    }
    if (adminRef?.current) {
      intersectionObserver.observe(adminRef.current as any)
    }
    if (webhookRef?.current) {
      intersectionObserver.observe(webhookRef.current as any)
    }
    if (apikeyRef?.current) {
      intersectionObserver.observe(apikeyRef.current as any)
    }

    return () => {
      if (generalRef?.current) {
        intersectionObserver.unobserve(generalRef.current as any)
      }
      if (userNotificationRef?.current) {
        intersectionObserver.unobserve(userNotificationRef.current as any)
      }
      if (loginRef?.current) {
        intersectionObserver.unobserve(loginRef.current as any)
      }
      if (userProfilesRef?.current) {
        intersectionObserver.unobserve(userProfilesRef.current as any)
      }
      if (socialRef?.current) {
        intersectionObserver.unobserve(socialRef.current as any)
      }
      if (playRef?.current) {
        intersectionObserver.unobserve(playRef.current as any)
      }
      if (leaderboardRef?.current) {
        intersectionObserver.unobserve(leaderboardRef.current as any)
      }
      if (pointsRef?.current) {
        intersectionObserver.unobserve(pointsRef.current as any)
      }
      if (levelsRef?.current) {
        intersectionObserver.unobserve(levelsRef.current as any)
      }
      if (adminRef?.current) {
        intersectionObserver.unobserve(adminRef.current as any)
      }
      if (webhookRef?.current) {
        intersectionObserver.unobserve(webhookRef.current as any)
      }
      if (apikeyRef?.current) {
        intersectionObserver.unobserve(apikeyRef.current as any)
      }
    }
  }, [
    generalRef,
    userNotificationRef,
    loginRef,
    userProfilesRef,
    socialRef,
    playRef,
    leaderboardRef,
    pointsRef,
    levelsRef,
    adminRef,
    webhookRef,
    apikeyRef,
  ])

  const handleTabClick = (tab: string) => {
    scrollViaClickRef.current = true
    setTimeout(() => {
      scrollViaClickRef.current = false
    }, 1500)

    if (tab === '#general') {
      ;(generalRef.current as any).scrollIntoView({ behavior: 'smooth' })
    } else if (tab === '#admins') {
      ;(adminRef.current as any).scrollIntoView({ behavior: 'smooth' })
    } else if (tab === '#play') {
      ;(playRef.current as any).scrollIntoView({ behavior: 'smooth' })
    } else if (tab === '#user-notifications') {
      ;(userNotificationRef.current as any).scrollIntoView({ behavior: 'smooth' })
    } else if (tab === '#login') {
      ;(loginRef.current as any).scrollIntoView({ behavior: 'smooth' })
    } else if (tab === '#userprofiles') {
      ;(userProfilesRef.current as any).scrollIntoView({ behavior: 'smooth' })
    } else if (tab === '#social') {
      ;(socialRef.current as any).scrollIntoView({ behavior: 'smooth' })
    } else if (tab === '#leaderboards') {
      ;(leaderboardRef.current as any).scrollIntoView({ behavior: 'smooth' })
    } else if (tab === '#points') {
      ;(pointsRef.current as any).scrollIntoView({ behavior: 'smooth' })
    } else if (tab === '#user-levels') {
      ;(levelsRef.current as any).scrollIntoView({ behavior: 'smooth' })
    } else if (tab === '#webhook') {
      ;(webhookRef.current as any).scrollIntoView({ behavior: 'smooth' })
    } else if (tab === '#api-key') {
      ;(apikeyRef.current as any).scrollIntoView({ behavior: 'smooth' })
    }
  }

  const handleChangeEffect = () => {
    const hasError = levels.some((level: UserLevelModelType, index: number) => {
      return (
        level.hasError ||
        level.incorrectMinPoints(levels[index - 1]?.min_points, levels[index + 1]?.min_points)
      )
    })
    setUserLevelDirty(!hasError)
  }

  const { Image } = useQRCode()

  useEffect(() => {
    const accessCode = localStorage.getItem('access_code')
    if (accessCode) {
      getAccessCodeDetails(accessCode).then((redirect) => {
        if (redirect) {
          setQrCodeUrl(`https://engage.trivie.com/login/sso/${accessCode}?redirect=true`)
        } else {
          setQrCodeUrl('https://engage.trivie.com')
        }
      })
    }
  }, [])

  const handleDownloadQR = () => {
    const el = document.getElementById('divQRWrapper')
      ?.children as HTMLCollectionOf<HTMLImageElement>
    if (el && el.length > 0) {
      const a = document.createElement('a')
      a.href = el[0].src
      a.download = 'qrcode.jpg'
      a.click()
    }
  }

  const handleCopyQR = async () => {
    setIsCopied(true)
    setTimeout(() => {
      setIsCopied(false)
    }, 3000)
    const el = document.getElementById('divQRWrapper')
      ?.children as HTMLCollectionOf<HTMLImageElement>
    if (el && el.length > 0) {
      html2canvas(el[0]).then((canvas) =>
        canvas.toBlob((blob) => {
          if (blob) {
            navigator.clipboard.write([new ClipboardItem({ 'image/png': blob })])
          }
        }),
      )
    }
  }

  const listData = useMemo(
    () => [
      {
        label: 'General',
        onClick: handleTabClick,
        icon: <Person color="inherit" />,
        isVisible: true,
      },
      {
        label: 'Admins',
        onClick: handleTabClick,
        icon: <Security color="inherit" />,
        isVisible: true,
      },
      {
        label: 'User Notifications',
        onClick: handleTabClick,
        icon: <Notifications color="inherit" />,
        isVisible: enterpriseTier,
      },
      {
        label: 'Login',
        onClick: handleTabClick,
        icon: <Login color="inherit" />,
        isVisible: enterpriseTier,
      },
      // {
      //   label: 'User Profiles',
      //   onClick: handleTabClick,
      //   icon: <AccountCircle color="inherit" />,
      //   isVisible: freeTier,
      // },
      {
        label: 'Play',
        onClick: handleTabClick,
        icon: <OfflineBoltRounded color="inherit" />,
        isVisible: true,
      },
      {
        label: 'Leaderboards',
        onClick: handleTabClick,
        icon: <BarChart color="inherit" />,
        isVisible: true,
      },
      {
        label: 'Points',
        onClick: handleTabClick,
        icon: <Whatshot color="inherit" />,
        isVisible: enterpriseTier,
      },
      {
        label: 'User Levels',
        onClick: handleTabClick,
        icon: <EmojiEvents color="inherit" />,
        isVisible: enterpriseTier,
      },

      {
        label: 'API Key',
        onClick: handleTabClick,
        icon: <VpnKey color="inherit" />,
        isVisible: enterpriseTier,
      },
    ],
    [],
  )

  return (
    <>
      <Header divider title="Settings" />
      <div style={{ display: 'flex' }}>
        <SettingsList listData={listData} />

        <div style={{ flex: 1, overflow: 'hidden' }}>
          {/* @ts-ignore */}
          <SettingCard
            initialValues={settings}
            title="General"
            ref={generalRef}
            onSubmit={handleCompanyLogo}
            onSubmitSuccess={() => setShowFile(false)}
            isDefaultDirty={showFile || toggleLogo}
            id="#general"
          >
            {/* @ts-ignore */}
            {(control: any, data: any) => (
              <div>
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    marginBottom: 15,
                  }}
                >
                  <Text variant="body1Medium" text="Company Logo" />
                  <div
                    style={{
                      flex: 1,
                      display: 'flex',
                      justifyContent: 'center',
                      objectFit: 'contain',
                      paddingLeft: 10,
                      paddingRight: 10,
                    }}
                  >
                    {data.company_logo ? (
                      <img
                        src={URL.createObjectURL(data.company_logo)}
                        height="64"
                        alt="updated company logo"
                      />
                    ) : settings?.logo_path ? (
                      <img src={settings.logo_path} height="64" alt="existing company logo" />
                    ) : null}
                  </div>
                  <Controller
                    render={({ onChange, value }) => (
                      <FileInput
                        disabled={
                          !permissions?.permissions.some(
                            (p) => p.code === 'can_edit_workspace_settings',
                          )
                        }
                        accept="image/png;image/jpeg;image/gif;image/webp;"
                        style={{
                          paddingTop: 15,
                          paddingBottom: 15,
                          maxWidth: 600,
                          whiteSpace: 'nowrap',
                          overflow: 'hidden',
                          textOverflow: 'ellipsis',
                        }}
                        file={showFile ? value : null}
                        helperText="It works for JPG, PNG, GIF - up to 5 MB"
                        onChange={(file: any) => {
                          onChange(file)
                          setShowFile(true)
                        }}
                      />
                    )}
                    name="company_logo"
                    control={control}
                    defaultValue=""
                  />
                </div>
                <div style={{ display: 'flex', alignItems: 'center', marginBottom: 35 }}>
                  <Text variant="body1Medium" text="Company Name" />
                  <div style={{ flex: 1 }} />
                  <Controller
                    render={({ onChange, value }) => (
                      <TextField
                        disabled={
                          !permissions?.permissions.some(
                            (p) => p.code === 'can_edit_workspace_settings',
                          )
                        }
                        size="small"
                        variant="outlined"
                        onChange={(e) => {
                          setShowFile(true)
                          onChange(e)
                        }}
                        value={value}
                      />
                    )}
                    name="company_name"
                    control={control}
                    defaultValue=""
                  />
                </div>
                <div style={SWITCH_ROW}>
                  <div style={SWITCH_TEXT}>
                    <Text
                      style={{ marginBottom: 8 }}
                      variant="body1Medium"
                      text={`Display Logo on ${settings?.whitelabel_name || 'Trivie Learn'}`}
                    />
                    <Text
                      variant="body2Medium"
                      text={`Show your company's logo on the ${
                        settings?.whitelabel_name || 'Trivie Learn'
                      } app`}
                      style={{ color: color.shade50 }}
                    />
                  </div>

                  <Controller
                    render={({ onChange, value }) => (
                      <Switch
                        disabled={
                          !permissions?.permissions.some(
                            (p) => p.code === 'can_edit_workspace_settings',
                          )
                        }
                        color="success"
                        checked={value}
                        onChange={(e) => {
                          onChange(e.target.checked)
                          setToggleLogo(true)
                        }}
                      />
                    )}
                    name="display_cobranding"
                    control={control}
                    defaultValue=""
                  />
                </div>
              </div>
            )}
          </SettingCard>
          <AdminsCard
            // @ts-ignore
            id="#admins"
            ref={adminRef}
          />
          {/* @ts-ignore */}
          <SettingCard
            isVisible={enterpriseTier}
            title="User Notifications"
            ref={userNotificationRef}
            initialValues={settings}
            containerStyle={{ paddingLeft: 0, paddingRight: 0, marginTop: 0 }}
            id="#user-notifications"
          >
            {/* @ts-ignore */}
            {(control: any) => (
              <div
                style={{
                  display: 'grid',
                  gridTemplateColumns: '1fr 70px 70px',
                  gridTemplateRows: 'repeat(4, auto)',
                }}
              >
                <div
                  style={{
                    paddingBottom: 20,
                    paddingTop: 20,
                    paddingLeft: 20,
                    backgroundColor: color.palette.selectionSidebar,
                  }}
                >
                  <Text
                    variant="body2Emphasized"
                    text="Type"
                    style={{ color: color.palette.quizSummary }}
                  />
                </div>
                <div
                  style={{
                    paddingBottom: 20,
                    paddingTop: 20,
                    backgroundColor: color.palette.selectionSidebar,
                  }}
                >
                  <Text
                    variant="body2Emphasized"
                    text="EMAIL"
                    style={{ color: color.palette.quizSummary }}
                  />
                </div>
                <div
                  style={{
                    paddingBottom: 20,
                    paddingTop: 20,
                    backgroundColor: color.palette.selectionSidebar,
                  }}
                >
                  <Text
                    variant="body2Emphasized"
                    text="MOBILE"
                    style={{ color: color.palette.quizSummary }}
                  />
                </div>

                <div style={{ paddingTop: 20, paddingBottom: 20, paddingLeft: 20 }}>
                  <Text variant="body1Medium" text="Overall Notifications" />
                </div>
                <div
                  style={{
                    alignItems: 'center',
                    flex: 1,
                    justifyContent: 'flex-start',
                    display: 'flex',
                  }}
                >
                  <Controller
                    render={({ onChange, value }) => (
                      <Checkbox
                        disabled={
                          !permissions?.permissions.some(
                            (p) => p.code === 'can_edit_workspace_settings',
                          )
                        }
                        color="success"
                        onChange={(e) => onChange(e.target.checked)}
                        checked={value}
                      />
                    )}
                    name="email_enabled"
                    control={control}
                    defaultValue=""
                  />
                </div>
                <div
                  style={{
                    alignItems: 'center',
                    flex: 1,
                    justifyContent: 'flex-start',
                    display: 'flex',
                  }}
                >
                  <Controller
                    render={({ onChange, value }) => (
                      <Checkbox
                        disabled={
                          !permissions?.permissions.some(
                            (p) => p.code === 'can_edit_workspace_settings',
                          )
                        }
                        color="success"
                        onChange={(e) => onChange(e.target.checked)}
                        checked={value}
                      />
                    )}
                    name="push_enabled"
                    control={control}
                    defaultValue=""
                  />
                </div>

                <div style={{ paddingTop: 20, paddingBottom: 20, paddingLeft: 20 }}>
                  <Text variant="body1Medium" text="Assignment Complete" />
                </div>
                <div>
                  <Controller
                    render={({ onChange, value }) => (
                      <Checkbox
                        color="success"
                        onChange={(e) => onChange(e.target.checked)}
                        checked={value}
                      />
                    )}
                    name="assignment_completed_enabled"
                    control={control}
                    defaultValue=""
                  />
                </div>
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    paddingLeft: 9,
                    paddingBottom: 14,
                  }}
                >
                  <Text variant="body1Medium" text="N/A" style={{ color: color.shade50 }} />
                </div>

                <div style={{ paddingTop: 20, paddingBottom: 20, paddingLeft: 20 }}>
                  <Text variant="body1Medium" text="Reinforcement Quizzes" />
                </div>
                <div>
                  <Controller
                    render={({ onChange, value }) => (
                      <Checkbox
                        disabled={
                          !permissions?.permissions.some(
                            (p) => p.code === 'can_edit_workspace_settings',
                          )
                        }
                        color="success"
                        onChange={(e) => onChange(e.target.checked)}
                        checked={value}
                      />
                    )}
                    name="adaptive_email_enabled"
                    control={control}
                    defaultValue=""
                  />
                </div>
                <div>
                  <Controller
                    render={({ onChange, value }) => (
                      <Checkbox
                        disabled={
                          !permissions?.permissions.some(
                            (p) => p.code === 'can_edit_workspace_settings',
                          )
                        }
                        color="success"
                        onChange={(e) => onChange(e.target.checked)}
                        checked={value}
                      />
                    )}
                    name="adaptive_push_enabled"
                    control={control}
                    defaultValue=""
                  />
                </div>
              </div>
            )}
          </SettingCard>
          {/* @ts-ignore */}
          <SettingCard
            isVisible={enterpriseTier}
            id="#login"
            title="Login"
            ref={loginRef}
            initialValues={settings}
          >
            {/* @ts-ignore */}
            {(control: any, data: any, err1: any) => {
              return (
                <>
                  {settings?.vanity_url && (
                    <div style={SWITCH_ROW}>
                      <div style={SWITCH_TEXT}>
                        <Text style={{ marginBottom: 8 }} variant="body1Medium" text="Custom URL" />
                        <Text
                          variant="body2Medium"
                          text="This URL directs users to the sign-in page for this workspace."
                          style={{ color: color.shade50 }}
                        />
                      </div>
                      <Stack flex={1} alignItems="center" direction="row" gap={1} useFlexGap>
                        <Controller
                          render={({ onChange, value }) => (
                            <TextField
                              disabled={true}
                              fullWidth
                              rows={7}
                              placeholder={settings?.company_name
                                ?.replace(/\s/g, '')
                                ?.toLowerCase()}
                              variant="outlined"
                              label="URL"
                              onChange={onChange}
                              value={value}
                              sx={{
                                '& .MuiFormLabel-root': {
                                  color: color.shade50,
                                },
                              }}
                              size="small"
                            />
                          )}
                          name="vanity_url"
                          control={control}
                          defaultValue=""
                        />
                        <Text variant="body1" text=".trivie.com" />
                      </Stack>
                    </div>
                  )}
                  <div style={SWITCH_ROW}>
                    <div style={SWITCH_TEXT}>
                      <Text style={{ marginBottom: 8 }} variant="body1Medium" text="QR Code" />
                      <Text
                        variant="body2Medium"
                        text="Users who scan this QR code will be directed to the sign-in page for this workspace."
                        style={{ color: color.shade50 }}
                      />
                    </div>

                    <div id="divQRWrapper" style={{ display: 'flex' }}>
                      <Tooltip
                        onDismiss={() => setIsCopied(false)}
                        open={isCopied}
                        placement="top"
                        title="Copied to clipboard"
                        titleStyle={{ fontSize: 14 }}
                      >
                        <div>
                          {qrCodeUrl && (
                            <Image
                              text={qrCodeUrl}
                              options={{
                                type: 'image/png',
                                quality: 0.3,
                                // level: 'M',
                                margin: 1,
                                width: 75,
                              }}
                            />
                          )}
                        </div>
                      </Tooltip>
                      <div
                        style={{
                          display: 'flex',
                          flexDirection: 'column',
                          justifyContent: 'space-between',
                        }}
                      >
                        <IconButton style={{ paddingTop: 0 }} onClick={() => handleDownloadQR()}>
                          <GetApp />
                        </IconButton>
                        <IconButton style={{ paddingBottom: 5 }} onClick={() => handleCopyQR()}>
                          <CopyClipboardIcon />
                        </IconButton>
                      </div>
                    </div>
                  </div>
                  <div style={SWITCH_ROW}>
                    <div style={SWITCH_TEXT}>
                      <Text
                        style={{ marginBottom: 8 }}
                        variant="body1Medium"
                        text="Non-Salaried Worker Warning"
                      />
                      <Text
                        variant="body2Medium"
                        text="Show users a warning about hourly workers not being compensated for time spent in game."
                        style={{ color: color.shade50 }}
                      />
                    </div>
                    <Controller
                      render={({ onChange, value }) => (
                        <Switch
                          disabled={
                            !permissions?.permissions.some(
                              (p) => p.code === 'can_edit_workspace_settings',
                            )
                          }
                          color="success"
                          checked={value}
                          onChange={(e) => onChange(e.target.checked)}
                        />
                      )}
                      name="hourly_warning"
                      control={control}
                      defaultValue=""
                    />
                  </div>
                  <Controller
                    render={({ onChange, value }) => (
                      <>
                        <TextField
                          disabled={
                            !permissions?.permissions.some(
                              (p) => p.code === 'can_edit_workspace_settings',
                            )
                          }
                          id="standard-multiline-static"
                          multiline
                          rows={7}
                          placeholder="Enter any other organization-wide policy here, or paste in a URL and we'll link out to it."
                          defaultValue="Default Value"
                          variant="outlined"
                          onChange={onChange}
                          value={value}
                          style={{ marginBottom: 30 }}
                          InputProps={{
                            style: { fontSize: 12, marginTop: 23 },
                          }}
                          error={err1?.hourly_override}
                        />

                        {err1?.hourly_override ? (
                          <Text
                            style={{ marginBottom: 20, color: color.palette.red }}
                            variant="body2"
                            text={err1?.hourly_override?.message}
                          />
                        ) : null}
                      </>
                    )}
                    name="hourly_override"
                    control={control}
                    defaultValue=""
                    rules={{
                      maxLength: {
                        value: 1000,
                        message: 'Must be less than 1000 characters',
                      },
                    }}
                  />
                  <div style={SWITCH_ROW}>
                    <div style={SWITCH_TEXT}>
                      <Text style={{ marginBottom: 8 }} variant="body1Medium" text="Other Policy" />
                      <Text
                        variant="body2Medium"
                        text="Enter your privacy policy here, or paste in a URL and we'll link out to it."
                        style={{ color: color.shade50 }}
                      />
                    </div>

                    <Controller
                      render={({ onChange, value }) => (
                        <Switch
                          disabled={
                            !permissions?.permissions.some(
                              (p) => p.code === 'can_edit_workspace_settings',
                            )
                          }
                          color="success"
                          checked={value}
                          onChange={(e) => onChange(e.target.checked)}
                        />
                      )}
                      name="policy"
                      control={control}
                      defaultValue=""
                    />
                  </div>

                  <Controller
                    render={({ onChange, value }) => (
                      <TextField
                        disabled={
                          !permissions?.permissions.some(
                            (p) => p.code === 'can_edit_workspace_settings',
                          )
                        }
                        multiline
                        rows={4}
                        placeholder="Enter your privacy policy here, or paste in a url and we’ll link out to it."
                        defaultValue="Default Value"
                        variant="outlined"
                        onChange={onChange}
                        value={value}
                        style={{ marginBottom: 20 }}
                      />
                    )}
                    name="policy_text"
                    control={control}
                    defaultValue=""
                  />
                </>
              )
            }}
          </SettingCard>
          {/* @ts-ignore */}
          {/* <SettingCard
            id="#userprofiles"
            title="User Profiles"
            ref={userProfilesRef}
            initialValues={settings}
          >
            {(control: any, err2: any) => (
              <>
                <div style={SWITCH_ROW}>
                  <div style={SWITCH_TEXT}>
                    <Text style={{ marginBottom: 8 }} variant="body1Medium" text="Preferred Name" />
                    <Text
                      variant="body2Medium"
                      text="Users can edit their preferred names in profile settings"
                      style={{ color: color.shade50 }}
                    />
                  </div>
                  <Controller
                    render={({ onChange, value }) => (
                      <Switch
                        disabled={
                          !permissions?.permissions.some(
                            p => p.code === 'can_edit_workspace_settings',
                          )
                        }
                        color="success"
                        checked={value}
                        onChange={e => onChange(e.target.checked)}
                      />
                    )}
                    name="allow_change_name"
                    control={control}
                    defaultValue=""
                  />
                </div>
                <div style={SWITCH_ROW}>
                  <div style={SWITCH_TEXT}>
                    <Text style={{ marginBottom: 8 }} variant="body1Medium" text="Email Address" />
                    <Text
                      variant="body2Medium"
                      text="Users can update email address in profile setting"
                      style={{ color: color.shade50 }}
                    />
                  </div>
                  <Controller
                    render={({ onChange, value }) => (
                      <Switch
                        disabled={
                          !permissions?.permissions.some(
                            p => p.code === 'can_edit_workspace_settings',
                          )
                        }
                        color="success"
                        checked={value}
                        onChange={e => onChange(e.target.checked)}
                      />
                    )}
                    name="allow_change_email"
                    control={control}
                    defaultValue=""
                  />
                </div>
              </>
            )}
          </SettingCard> */}
          {/* @ts-ignore */}
          <SettingCard id="#play" title="Play" ref={playRef} initialValues={settings}>
            {/* @ts-ignore */}
            {(control: any, err2: any) => (
              <>
                <div style={SWITCH_ROW}>
                  <div style={SWITCH_TEXT}>
                    <Text style={{ marginBottom: 8 }} variant="body1Medium" text="Cooldown" />
                    <Text
                      variant="body2Medium"
                      text="Users must wait one hour before retaking a quiz."
                      style={{ color: color.shade50 }}
                    />
                  </div>
                  <Controller
                    render={({ onChange, value }) => (
                      <Switch
                        disabled={
                          !permissions?.permissions.some(
                            (p) => p.code === 'can_edit_workspace_settings',
                          )
                        }
                        color="success"
                        checked={value}
                        onChange={(e) => onChange(e.target.checked)}
                      />
                    )}
                    name="cooldown_required"
                    control={control}
                    defaultValue=""
                  />
                </div>
                <div style={SWITCH_ROW}>
                  <div style={SWITCH_TEXT}>
                    <Text style={{ marginBottom: 8 }} variant="body1Medium" text="Sounds" />
                    <Text
                      variant="body2Medium"
                      text="Sounds are enabled by default for new users."
                      style={{ color: color.shade50 }}
                    />
                  </div>
                  <Controller
                    render={({ onChange, value }) => (
                      <Switch
                        disabled={
                          !permissions?.permissions.some(
                            (p) => p.code === 'can_edit_workspace_settings',
                          )
                        }
                        color="success"
                        checked={value}
                        onChange={(e) => onChange(e.target.checked)}
                      />
                    )}
                    name="sounds_default"
                    control={control}
                    defaultValue=""
                  />
                </div>
                <div style={SWITCH_ROW}>
                  <div style={SWITCH_TEXT}>
                    <Text style={{ marginBottom: 8 }} variant="body1Medium" text="Music" />
                    <Text
                      variant="body2Medium"
                      text="Music is enabled by default for new users."
                      style={{ color: color.shade50 }}
                    />
                  </div>
                  <Controller
                    render={({ onChange, value }) => (
                      <Switch
                        disabled={
                          !permissions?.permissions.some(
                            (p) => p.code === 'can_edit_workspace_settings',
                          )
                        }
                        color="success"
                        checked={value}
                        onChange={(e) => onChange(e.target.checked)}
                      />
                    )}
                    name="music_default"
                    control={control}
                    defaultValue=""
                  />
                </div>
                <div style={SWITCH_ROW}>
                  <div style={SWITCH_TEXT}>
                    <Text
                      style={{ marginBottom: 8 }}
                      variant="body1Medium"
                      text="Always Show Feedback"
                    />
                    <Text
                      variant="body2Medium"
                      text="When enabled, learners will see feedback whether they answer a question correctly or incorrectly."
                      style={{ color: color.shade50 }}
                    />
                  </div>
                  <Controller
                    render={({ onChange, value }) => (
                      <Switch
                        disabled={
                          !permissions?.permissions.some(
                            (p) => p.code === 'can_edit_workspace_settings',
                          )
                        }
                        color="success"
                        checked={value}
                        onChange={(e) => onChange(e.target.checked)}
                      />
                    )}
                    name="always_show_feedback"
                    control={control}
                    defaultValue=""
                  />
                </div>
                <div style={SWITCH_ROW}>
                  <div style={SWITCH_TEXT}>
                    <Text
                      style={{ marginBottom: 8 }}
                      variant="body1Medium"
                      text="Show Confirmation Popups"
                    />
                    <Text
                      variant="body2Medium"
                      text="When enabled, learners will see popups when they answer questions without feedback."
                      style={{ color: color.shade50 }}
                    />
                  </div>
                  <Controller
                    render={({ onChange, value }) => (
                      <Switch
                        disabled={
                          !permissions?.permissions.some(
                            (p) => p.code === 'can_edit_workspace_settings',
                          )
                        }
                        color="success"
                        checked={value}
                        onChange={(e) => onChange(e.target.checked)}
                      />
                    )}
                    name="show_confirmation_popup"
                    control={control}
                    defaultValue=""
                  />
                </div>
                {enterpriseTier && (
                  <div style={SWITCH_ROW}>
                    <div style={SWITCH_TEXT}>
                      <Text
                        style={{ marginBottom: 8 }}
                        variant="body1Medium"
                        text="Reinforcement Quiz Question Cap"
                      />
                      <Text
                        variant="body2Medium"
                        text="Limit the number of questions that can appear in one reinforcement quiz."
                        style={{ color: color.shade50 }}
                      />
                      {err2?.reinforcement_question_limit > 50 ||
                      err2?.reinforcement_question_limit <= 0 ? (
                        <Text
                          style={{ paddingTop: 5, color: color.palette.red }}
                          variant="body2"
                          text="Please choose a number between 1 and 50."
                        />
                      ) : null}
                    </div>
                    <Controller
                      render={({ onChange, value }) => (
                        <>
                          <TextField
                            disabled={
                              !permissions?.permissions.some(
                                (p) => p.code === 'can_edit_workspace_settings',
                              )
                            }
                            type="number"
                            size="small"
                            variant="outlined"
                            onChange={onChange}
                            value={value}
                            style={{ width: 60 }}
                            error={
                              err2?.reinforcement_question_limit > 50 ||
                              err2?.reinforcement_question_limit <= 0
                            }
                          />
                        </>
                      )}
                      name="reinforcement_question_limit"
                      control={control}
                      defaultValue=""
                      rules={{
                        max: {
                          value: 50,
                          message: 'Please choose a number between 1 and 50',
                        },
                        min: {
                          value: 1,
                          message: 'Please choose a number between 1 and 50',
                        },
                      }}
                    />
                  </div>
                )}
              </>
            )}
          </SettingCard>
          {/* @ts-ignore */}
          <SettingCard
            id="#leaderboards"
            title="Leaderboards"
            ref={leaderboardRef}
            initialValues={settings}
            containerStyle={{ paddingLeft: 0, paddingRight: 0, marginTop: 0 }}
          >
            {/* @ts-ignore */}
            {(control: any) => (
              <>
                <div style={{ display: 'grid', gridTemplateColumns: '1fr 70px 70px 70px 70px' }}>
                  <div
                    style={{
                      paddingBottom: 20,
                      paddingTop: 20,
                      backgroundColor: color.palette.selectionSidebar,
                    }}
                  >
                    <Text
                      variant="body2Emphasized"
                      text="Type"
                      style={{ color: color.palette.quizSummary, paddingLeft: 20 }}
                    />
                  </div>
                  <div
                    style={{
                      paddingBottom: 20,
                      paddingTop: 20,
                      backgroundColor: color.palette.selectionSidebar,
                    }}
                  >
                    <Text
                      variant="body2Emphasized"
                      text="Daily"
                      style={{ color: color.palette.quizSummary }}
                    />
                  </div>
                  <div
                    style={{
                      paddingBottom: 20,
                      paddingTop: 20,
                      backgroundColor: color.palette.selectionSidebar,
                    }}
                  >
                    <Text
                      variant="body2Emphasized"
                      text="Weekly"
                      style={{ color: color.palette.quizSummary }}
                    />
                  </div>
                  <div
                    style={{
                      paddingBottom: 20,
                      paddingTop: 20,
                      backgroundColor: color.palette.selectionSidebar,
                    }}
                  >
                    <Text
                      variant="body2Emphasized"
                      text="Monthly"
                      style={{ color: color.palette.quizSummary }}
                    />
                  </div>
                  <div
                    style={{
                      paddingBottom: 20,
                      paddingTop: 20,
                      backgroundColor: color.palette.selectionSidebar,
                    }}
                  >
                    <Text
                      variant="body2Emphasized"
                      text="All Time"
                      style={{ color: color.palette.quizSummary }}
                    />
                  </div>

                  <div
                    style={{
                      paddingTop: 20,
                      paddingBottom: 20,
                      paddingLeft: 20,
                      display: 'flex',
                      flexDirection: 'column',
                    }}
                  >
                    <Text
                      variant="body1Medium"
                      text="User Leaderboard"
                      style={{ marginBottom: 8 }}
                    />
                    <Text
                      variant="body2Medium"
                      text="Display a leaderboard of all users"
                      style={{ color: color.shade50 }}
                    />
                  </div>
                  <div
                    style={{
                      alignItems: 'center',
                      flex: 1,
                      justifyContent: 'flex-start',
                      display: 'flex',
                    }}
                  >
                    <Controller
                      render={({ onChange, value }) => (
                        <Checkbox
                          disabled={
                            !permissions?.permissions.some(
                              (p) => p.code === 'can_edit_workspace_settings',
                            )
                          }
                          color="success"
                          onChange={(e) => onChange(e.target.checked)}
                          checked={value}
                        />
                      )}
                      name="user_leaderboard_daily_enabled"
                      control={control}
                      defaultValue=""
                    />
                  </div>
                  <div
                    style={{
                      alignItems: 'center',
                      flex: 1,
                      justifyContent: 'flex-start',
                      display: 'flex',
                    }}
                  >
                    <Controller
                      render={({ onChange, value }) => (
                        <Checkbox
                          disabled={
                            !permissions?.permissions.some(
                              (p) => p.code === 'can_edit_workspace_settings',
                            )
                          }
                          color="success"
                          onChange={(e) => onChange(e.target.checked)}
                          checked={value}
                        />
                      )}
                      name="user_leaderboard_weekly_enabled"
                      control={control}
                      defaultValue=""
                    />
                  </div>
                  <div
                    style={{
                      alignItems: 'center',
                      flex: 1,
                      justifyContent: 'flex-start',
                      display: 'flex',
                    }}
                  >
                    <Controller
                      render={({ onChange, value }) => (
                        <Checkbox
                          disabled={
                            !permissions?.permissions.some(
                              (p) => p.code === 'can_edit_workspace_settings',
                            )
                          }
                          color="success"
                          onChange={(e) => onChange(e.target.checked)}
                          checked={value}
                        />
                      )}
                      name="user_leaderboard_monthly_enabled"
                      control={control}
                      defaultValue=""
                    />
                  </div>
                  <div
                    style={{
                      alignItems: 'center',
                      flex: 1,
                      justifyContent: 'flex-start',
                      display: 'flex',
                    }}
                  >
                    <Controller
                      render={({ onChange, value }) => (
                        <Checkbox
                          disabled={
                            !permissions?.permissions.some(
                              (p) => p.code === 'can_edit_workspace_settings',
                            )
                          }
                          color="success"
                          onChange={(e) => onChange(e.target.checked)}
                          checked={value}
                        />
                      )}
                      name="user_leaderboard_alltime_enabled"
                      control={control}
                      defaultValue=""
                    />
                  </div>
                  <div
                    style={{
                      paddingTop: 20,
                      paddingBottom: 20,
                      paddingLeft: 20,
                      display: !enterpriseTier ? 'none' : 'flex',
                      flexDirection: 'column',
                    }}
                  >
                    <Text
                      variant="body1Medium"
                      text="User Leaderboard Max Length"
                      style={{ marginBottom: 8 }}
                    />
                    <Text
                      variant="body2Medium"
                      text="Display only the top # users on the User Leaderboard."
                      style={{ color: color.shade50 }}
                    />
                  </div>
                  <div />
                  <div />
                  <div
                    style={{
                      paddingRight: 20,
                      alignItems: 'center',
                      justifyContent: 'center',
                      display: !enterpriseTier ? 'none' : 'flex',
                    }}
                  >
                    <Controller
                      render={(props) => {
                        const { onChange, value } = props
                        return (
                          <TextField
                            disabled={
                              (typeof control.getValues().user_leaderboard_max_length_enabled ===
                                'boolean' &&
                                !control.getValues().user_leaderboard_max_length_enabled) ||
                              !permissions?.permissions.some(
                                (p) => p.code === 'can_edit_workspace_settings',
                              )
                            }
                            placeholder="#"
                            size="small"
                            variant="outlined"
                            onChange={onChange}
                            value={value === null ? '' : value}
                          />
                        )
                      }}
                      name="user_leaderboard_max_length"
                      control={control}
                      // defaultValue={settings.user_leaderboard_max_length}
                    />
                  </div>
                  <div
                    style={{
                      display: !enterpriseTier ? 'none' : 'flex',
                      justifyContent: 'center',
                      flex: 1,
                      right: 18,
                      position: 'relative',
                      alignItems: 'center',
                    }}
                  >
                    <Controller
                      render={({ onChange, value }) => {
                        return (
                          <Switch
                            color="success"
                            disabled={
                              !permissions?.permissions.some(
                                (p) => p.code === 'can_edit_workspace_settings',
                              )
                            }
                            checked={value}
                            onChange={(e) => {
                              if (
                                e.target.checked &&
                                !control.getValues().user_leaderboard_max_length
                              ) {
                                // if no max length set, but toggled on - default to 10
                                control.setValue('user_leaderboard_max_length', 10)
                              } else if (!e.target.checked) {
                                control.setValue('user_leaderboard_max_length', null)
                              }
                              onChange(e.target.checked)
                            }}
                          />
                        )
                      }}
                      name="user_leaderboard_max_length_enabled"
                      control={control}
                      defaultValue={Boolean(
                        control.getValues().user_leaderboard_max_length_enabled,
                      )}
                    />
                  </div>

                  {enterpriseTier && (
                    <div
                      style={{
                        gridColumnStart: 1,
                        gridColumnEnd: 6,
                        display: !enterpriseTier ? 'none' : 'flex',
                      }}
                    >
                      <div
                        style={{
                          paddingTop: 20,
                          paddingBottom: 20,
                          paddingLeft: 20,
                          maxWidth: 400,
                          display: 'flex',
                          flexDirection: 'column',
                        }}
                      >
                        <Text
                          variant="body1Medium"
                          text="Tag Leaderboard Categories"
                          style={{ marginBottom: 8 }}
                        />
                        <Text
                          variant="body2Medium"
                          text="These categories will appear as leaderboards to users with at least one tag from the category."
                          style={{ color: color.shade50 }}
                        />
                      </div>
                      {enterpriseTier && (
                        <div style={{ flex: 1, padding: 20, alignSelf: 'center' }}>
                          <Controller
                            render={({ onChange, value }) => (
                              <TagKeySelector
                                disabled={
                                  !permissions?.permissions.some(
                                    (p) => p.code === 'can_edit_workspace_settings',
                                  )
                                }
                                selectedTagKeys={value}
                                onChange={(selected) => onChange(selected)}
                              />
                            )}
                            name="group_leaderboard_categories"
                            control={control}
                            defaultValue={[]}
                          />
                        </div>
                      )}
                    </div>
                  )}
                </div>
              </>
            )}
          </SettingCard>
          {enterpriseTier ? (
            <>
              {/* @ts-ignore */}
              <SettingCard id="#points" title="Points" ref={pointsRef} initialValues={settings}>
                {/* @ts-ignore */}
                {(control: any) => (
                  <div style={{ position: 'relative' }}>
                    <div style={!enterpriseTier ? POINTS_WRAPPER : {}}>
                      <div style={SWITCH_ROW}>
                        <div style={SWITCH_TEXT}>
                          <Text
                            style={{ marginBottom: 8 }}
                            variant="body1Medium"
                            text="Quiz Answers"
                          />
                          <Text
                            variant="body2Medium"
                            text="Award points for correct answers on regular quizzes."
                            style={{ color: color.shade50 }}
                          />
                        </div>
                        <Controller
                          render={({ onChange, value }) => (
                            <TextField
                              disabled={
                                !permissions?.permissions.some(
                                  (p) => p.code === 'can_edit_workspace_settings',
                                )
                              }
                              size="small"
                              type="number"
                              variant="outlined"
                              onChange={onChange}
                              value={value}
                              style={{ width: 60 }}
                            />
                          )}
                          name="points_learn_standard"
                          control={control}
                          defaultValue=""
                        />
                      </div>
                      <div style={SWITCH_ROW}>
                        <div style={SWITCH_TEXT}>
                          <Text
                            style={{ marginBottom: 8 }}
                            variant="body1Medium"
                            text="Reinforcement Answers"
                          />
                          <Text
                            variant="body2Medium"
                            text="Award points for correct answers on Reinforcement quizzes"
                            style={{ color: color.shade50 }}
                          />
                        </div>
                        <Controller
                          render={({ onChange, value }) => (
                            <TextField
                              disabled={
                                !permissions?.permissions.some(
                                  (p) => p.code === 'can_edit_workspace_settings',
                                )
                              }
                              size="small"
                              type="number"
                              variant="outlined"
                              onChange={onChange}
                              value={value}
                              style={{ width: 60 }}
                            />
                          )}
                          name="points_reinforce_standard"
                          control={control}
                          defaultValue=""
                        />
                      </div>
                      <div style={SWITCH_ROW}>
                        <div style={SWITCH_TEXT}>
                          <Text style={{ marginBottom: 8 }} variant="body1Medium" text="Tests" />
                          <Text
                            variant="body2Medium"
                            text="Award points to users for correct answers on pre- and post-tests."
                            style={{ color: color.shade50 }}
                          />
                        </div>
                        <Controller
                          render={({ onChange, value }) => (
                            <TextField
                              disabled={
                                !permissions?.permissions.some(
                                  (p) => p.code === 'can_edit_workspace_settings',
                                )
                              }
                              size="small"
                              type="number"
                              variant="outlined"
                              onChange={onChange}
                              value={value}
                              style={{ width: 60 }}
                            />
                          )}
                          name="points_test_standard"
                          control={control}
                          defaultValue=""
                        />
                      </div>
                      <div style={SWITCH_ROW}>
                        <div style={SWITCH_TEXT}>
                          <Text style={{ marginBottom: 8 }} variant="body1Medium" text="Survey" />
                          <Text
                            variant="body2Medium"
                            text="Award points for questions answered on surveys."
                            style={{ color: color.shade50 }}
                          />
                        </div>
                        <Controller
                          render={({ onChange, value }) => (
                            <TextField
                              disabled={
                                !permissions?.permissions.some(
                                  (p) => p.code === 'can_edit_workspace_settings',
                                )
                              }
                              size="small"
                              type="number"
                              variant="outlined"
                              onChange={onChange}
                              value={value}
                              style={{ width: 60 }}
                            />
                          )}
                          name="points_survey"
                          control={control}
                          defaultValue=""
                        />
                      </div>
                      <div style={SWITCH_ROW}>
                        <div style={SWITCH_TEXT}>
                          <Text style={{ marginBottom: 8 }} variant="body1Medium" text="Posts" />
                          <Text
                            variant="body2Medium"
                            text="Award points when a user creates a new post in Feed."
                            style={{ color: color.shade50 }}
                          />
                        </div>
                        <Controller
                          render={({ onChange, value }) => (
                            <TextField
                              disabled={
                                !permissions?.permissions.some(
                                  (p) => p.code === 'can_edit_workspace_settings',
                                )
                              }
                              size="small"
                              type="number"
                              variant="outlined"
                              onChange={onChange}
                              value={value}
                              style={{ width: 60 }}
                            />
                          )}
                          name="points_post"
                          control={control}
                          defaultValue=""
                        />
                      </div>
                      <div style={SWITCH_ROW}>
                        <div style={SWITCH_TEXT}>
                          <Text style={{ marginBottom: 8 }} variant="body1Medium" text="Replies" />
                          <Text
                            variant="body2Medium"
                            text="Award points when a user replies to another post in Feed."
                            style={{ color: color.shade50 }}
                          />
                        </div>
                        <Controller
                          render={({ onChange, value }) => (
                            <TextField
                              disabled={
                                !permissions?.permissions.some(
                                  (p) => p.code === 'can_edit_workspace_settings',
                                )
                              }
                              size="small"
                              type="number"
                              variant="outlined"
                              onChange={onChange}
                              value={value}
                              style={{ width: 60 }}
                            />
                          )}
                          name="points_reply"
                          control={control}
                          defaultValue=""
                        />
                      </div>
                      <div style={SWITCH_ROW}>
                        <div style={SWITCH_TEXT}>
                          <Text style={{ marginBottom: 8 }} variant="body1Medium" text="Badges" />
                          <Text
                            variant="body2Medium"
                            text="Award points when a user earns a new badge."
                            style={{ color: color.shade50 }}
                          />
                        </div>
                        <Controller
                          render={({ onChange, value }) => (
                            <TextField
                              disabled={
                                !permissions?.permissions.some(
                                  (p) => p.code === 'can_edit_workspace_settings',
                                )
                              }
                              size="small"
                              type="number"
                              variant="outlined"
                              onChange={onChange}
                              value={value}
                              style={{ width: 60 }}
                            />
                          )}
                          name="points_badge"
                          control={control}
                          defaultValue=""
                        />
                      </div>
                    </div>
                    {!enterpriseTier && (
                      <Paywall
                        style={{ bottom: '43%', display: 'flex', justifyContent: 'center' }}
                        plan="Pro"
                        tagline="to customize your scoring and leaderboards."
                      />
                    )}
                  </div>
                )}
              </SettingCard>
            </>
          ) : null}
          {!freeTier ? (
            <>
              {/* @ts-ignore */}
              <SettingCard
                isVisible={!freeTier}
                id="#user-levels"
                title="User Levels"
                ref={levelsRef}
                initialValues={settings}
                onSubmitSuccess={(data: any) => {
                  if (data.user_levels_enabled) {
                    updateUserLevels()
                  }
                }}
                onCancel={() => {
                  resetOriginalLevels()
                  setUserLevelDirty(false)
                }}
                isDefaultDirty={isUserLevelDirty}
              >
                {/* @ts-ignore */}
                {(control: any, data: any) => (
                  <>
                    <div style={SWITCH_ROW}>
                      <div style={SWITCH_TEXT}>
                        <Text
                          style={{ marginBottom: 8 }}
                          variant="body1Medium"
                          text="Enable User Levels"
                        />
                        <Text
                          variant="body2Medium"
                          text="Allow learners to earn badges and titles for reaching certain point thresholds."
                          style={{ color: color.shade50 }}
                        />
                      </div>
                      <Controller
                        render={({ onChange, value }) => (
                          <Switch
                            disabled={
                              !permissions?.permissions.some(
                                (p) => p.code === 'can_edit_workspace_settings',
                              )
                            }
                            color="success"
                            checked={value}
                            onChange={(e) => {
                              onChange(e.target.checked)
                              handleChangeEffect()
                            }}
                          />
                        )}
                        name="user_levels_enabled"
                        control={control}
                        defaultValue=""
                      />
                    </div>
                    <Collapse in={data?.user_levels_enabled} timeout="auto" unmountOnExit>
                      <div style={SWITCH_ROW}>
                        <div style={SWITCH_TEXT}>
                          <Text
                            style={{ marginBottom: 8 }}
                            variant="body1Medium"
                            text="Customize User Levels"
                          />
                          <Text
                            variant="body2Medium"
                            text="You can add or remove levels, change titles, modify the score learners for each level, and upload custom level badges."
                            style={{ color: color.shade50 }}
                          />
                        </div>
                      </div>
                      <Paper
                        square
                        variant="outlined"
                        elevation={0}
                        ref={levelsRef}
                        style={{ marginLeft: -20, marginRight: -20 }}
                      >
                        <Grid
                          container
                          spacing={0}
                          style={{ backgroundColor: color.palette.selectionSidebar, padding: 20 }}
                        >
                          <Grid item xs={1}>
                            <Text
                              variant="body2Emphasized"
                              text="Badge"
                              style={{ color: color.palette.quizSummary }}
                            />
                          </Grid>
                          <Grid item xs={6}>
                            <Text
                              variant="body2Emphasized"
                              text="Title"
                              style={{ color: color.palette.quizSummary }}
                            />
                          </Grid>
                          <Grid item xs={4}>
                            <Text
                              variant="body2Emphasized"
                              text="Score"
                              style={{ color: color.palette.quizSummary }}
                            />
                          </Grid>
                          <Grid item xs={1} />
                        </Grid>
                        {levels?.map((level, index) => (
                          <Grid
                            container
                            spacing={0}
                            className={classes.levelRow}
                            key={level?.level}
                          >
                            <Grid item xs={1} style={{ display: 'flex', alignItems: 'center' }}>
                              <BadgeAvatar
                                size={36}
                                name={`${index + 1}`}
                                url={level.icon}
                                onClick={() => {
                                  setIconLevel(level)
                                  setBadgeUpload(true)
                                }}
                              />
                            </Grid>
                            <Grid item xs={6} style={{ display: 'flex', alignItems: 'center' }}>
                              <TextField
                                disabled={
                                  !permissions?.permissions.some(
                                    (p) => p.code === 'can_edit_workspace_settings',
                                  )
                                }
                                size="small"
                                variant="outlined"
                                placeholder="Level Name"
                                value={level.label}
                                style={{ width: '80%' }}
                                onChange={(e) => {
                                  level.setLabel(e.target.value)
                                  handleChangeEffect()
                                }}
                                error={level.emptyLabel || level.tooLongLabel}
                                onFocus={() => setFocusedLevel(level.level)}
                                onBlur={() => setFocusedLevel(null)}
                                helperTextClass={
                                  level.hasError ? classes.helperText : classes.helperTextFocused
                                }
                                helperText={
                                  level.emptyLabel
                                    ? level.emptyLabelText
                                    : level.tooLongLabel
                                      ? level.tooLongText
                                      : focusedLevel === level.level
                                        ? `${level.label?.length ?? 0}/40`
                                        : ''
                                }
                              />
                            </Grid>
                            <Grid item xs={4} style={{ display: 'flex', alignItems: 'center' }}>
                              {index === 0 ? (
                                <Text style={{ color: color.shade50 }} variant="body1" text="n/a" />
                              ) : (
                                <TextField
                                  disabled={
                                    !permissions?.permissions.some(
                                      (p) => p.code === 'can_edit_workspace_settings',
                                    )
                                  }
                                  size="small"
                                  type="number"
                                  variant="outlined"
                                  placeholder={`${index + 1}`}
                                  value={`${level.min_points}`}
                                  style={{ width: '80%' }}
                                  onChange={(e) => {
                                    if (e.target?.value && !isNaN(Number(e.target.value))) {
                                      level.setMinPoints(Number(e.target.value))
                                      handleChangeEffect()
                                    }
                                  }}
                                  error={level.incorrectMinPoints(
                                    levels[index - 1]?.min_points,
                                    levels[index + 1]?.min_points,
                                  )}
                                  helperText={
                                    level.incorrectMinPoints(
                                      levels[index - 1]?.min_points,
                                      levels[index + 1]?.min_points,
                                    )
                                      ? level.incorrectMinPointsText
                                      : ''
                                  }
                                />
                              )}
                            </Grid>
                            <Grid item xs={1} style={{ display: 'flex', alignItems: 'center' }}>
                              <IconButton
                                disabled={
                                  !permissions?.permissions.some(
                                    (p) => p.code === 'can_edit_workspace_settings',
                                  )
                                }
                                onClick={() => {
                                  removeLevel(index)
                                  handleChangeEffect()
                                }}
                              >
                                <Delete />
                              </IconButton>
                            </Grid>
                          </Grid>
                        ))}
                        {maxLevelReached ? (
                          <div
                            style={{
                              padding: 10,
                              marginTop: 5,
                              marginBottom: 15,
                              textAlign: 'center',
                            }}
                          >
                            <Text
                              style={{ color: color.red }}
                              variant="body2Emphasized"
                              text="Maximum Level Reached"
                            />
                          </div>
                        ) : (
                          <Grid container spacing={0}>
                            <Grid item xs={1}></Grid>
                            <Grid item xs={3}>
                              <Button
                                disabled={
                                  !permissions?.permissions.some(
                                    (p) => p.code === 'can_edit_workspace_settings',
                                  )
                                }
                                onClick={() => {
                                  addNewLevel()
                                  handleChangeEffect()
                                }}
                                startIcon={<Add />}
                                text="Add Level"
                                variant="text"
                                color="info"
                                style={{ marginTop: 5, marginBottom: 15 }}
                              />
                            </Grid>
                          </Grid>
                        )}
                        <ImportBadgeDialog
                          level={iconLevel}
                          open={showBadgeUpload}
                          onClose={() => setBadgeUpload(false)}
                        />
                      </Paper>
                    </Collapse>
                  </>
                )}
              </SettingCard>
            </>
          ) : null}
          {enterpriseTier && (
            <APIKeyCard
              // @ts-ignore
              id="#api-key"
              title="Zapier API Key"
              ref={apikeyRef}
              subtitle="Generate an API key to connect with Zapier and integrate with Slack, MS Teams, and thousands of other apps."
            />
          )}
        </div>
      </div>
    </>
  )
})

const POINTS_WRAPPER: React.CSSProperties = {
  pointerEvents: 'none',
  opacity: 0.3,
}
